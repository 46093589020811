import { withStyleSystem } from 'hocs/withStyleSystem';
import React, { useMemo } from 'react';

import { Icon } from '@atoms/Icon';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';
import { SimpleTitleProps } from '@atoms/Title/types';

import { cn, cx } from 'utils/classNames';

import './FeatureItem.scss';
import { FeatureItemProps } from './types';

const bem = cn('feature-item');

const ExtendedTitle = withStyleSystem<SimpleTitleProps>(Title);

const FeatureItem = (props: FeatureItemProps) => {
  const { id, title, description, button, premiumFlag, icon, descriptionTitle } = props;
  const { alignment, variant, colors, showPremiumFlag } = props;
  const { disableFocus } = props;

  const listItems = description.replaceAll(
    '<li>',
    "<li><span class='blte-feature-item__description' aria-hidden='true'><i class='blte-icon  blte-icon__check-circle'></i></span>"
  );

  const style: any = useMemo(
    () => ({
      '--feature-item-background-color': colors?.background,
      '--feature-item-title-color': colors?.title,
      '--feature-item-description-color': colors?.description,
      '--feature-item-icon-color': colors?.icon?.icon,
      '--feature-item-icon-background-color': colors?.icon?.background,
      '--feature-item-checkmark-color': colors?.checkmark?.icon,
      '--feature-item-checkmark-background-color': colors?.checkmark?.background,
      '--feature-item-flag-text-color': colors?.flag?.text,
      '--feature-item-flag-background-color': colors?.flag?.background,
    }),
    [colors]
  );

  return (
    <li
      id={id}
      className={bem({ variant, background: !!colors?.background, alignment, premium: !!premiumFlag })}
      style={style}
    >
      {showPremiumFlag && !!premiumFlag && <div className={bem('flag')}>{premiumFlag}</div>}
      <div className={bem('content')} role="group" aria-label="item">
        {(icon?.name || icon?.externalIcon) && (
          <span className={bem('icon')} aria-hidden="true">
            <Icon {...icon} externalIconAlt="contact" />
          </span>
        )}
        <div className={bem('title-content')}>
          {variant === 'checkmark' && (
            <span className={bem('checkmark')} aria-hidden="true">
              <Icon name="check" />
            </span>
          )}
          <ExtendedTitle {...title} customClassName={cx(bem('title'))} />
        </div>
        {descriptionTitle && (
          <div className={bem('description-title')}>
            <Text text={descriptionTitle} as="h6" />
          </div>
        )}
        <div className={bem('description')}>
          <Text text={listItems} />
        </div>
        {button && button.label && (
          <div className={bem('cta')}>
            <LinkButton {...button} variant="big" disableFocus={disableFocus} />
          </div>
        )}
      </div>
    </li>
  );
};

export default FeatureItem;
