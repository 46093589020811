import React, { useEffect, useRef } from 'react';

import { cn } from 'utils/classNames';

import './tabs.scss';

export const bem = cn('tabs');

export const TabButton = props => {
  const { label, focus, activeTabIndex, index, handleTabClick, handleKeyDown, touched, disabled } = props;

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focus && ref.current && touched) ref.current?.focus();
  }, [focus, touched]);

  return (
    <button
      ref={ref}
      role="tab"
      tabIndex={activeTabIndex === index ? 0 : -1}
      aria-selected={activeTabIndex === index}
      id={`tab-${index}`}
      aria-controls={`panel-${index}`}
      onKeyDown={handleKeyDown}
      onClick={handleTabClick(index)}
      className={bem('button', { active: activeTabIndex === index })}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
