import _ from 'lodash';
import { useMemo } from 'react';
import { useStores } from 'stores';

import { ExtraItemConfig } from './types';

export const useExtrasLogic = (products, type, labels) => {
  const { bookingStore, cfStore } = useStores();
  const { setActiveModalCF } = bookingStore.extras;
  const trip = bookingStore.rti.provisionalBooking?.tripDetails?.trip;
  const cfLabels = cfStore.generic;

  const hasItems = useMemo(
    () =>
      products.some(product =>
        product.passengers.some(
          passenger =>
            !!passenger.outboundSelectionParameters?.currentQuantitySelected ||
            !!passenger.inboundSelectionParameters?.currentQuantitySelected
        )
      ),
    [products]
  );

  const isSoldOut = passengers =>
    passengers.reduce((soldOut, passenger) => {
      !!passenger?.outboundSelectionParameters &&
        (soldOut += passenger.outboundSelectionParameters.maxQuantitySelectable);
      !!passenger?.inboundSelectionParameters &&
        (soldOut += passenger.inboundSelectionParameters.maxQuantitySelectable);

      return soldOut !== 0;
    }, 0);

  const items: ExtraItemConfig[] = useMemo(
    () =>
      products.map(product => {
        product.passengers?.forEach(passenger => {
          if (!passenger) return;

          if (trip?.outboundRoute?.isCancelled) passenger.outboundSelectionParameters = undefined;

          if (trip?.inboundRoute?.isCancelled) passenger.inboundSelectionParameters = undefined;
        });

        return {
          title: product.name,
          legs: product.passengers.every(passenger =>
            _.isEqual(passenger.outboundSelectionParameters, passenger.inboundSelectionParameters)
          )
            ? cfLabels.bothTripsLabel
            : product.passengers?.[0]?.outboundSelectionParameters?.currentQuantitySelected
            ? cfLabels.departureLabel
            : cfLabels.returnLabel,
          numPassengers: product.passengers.filter(
            passenger =>
              !!passenger.outboundSelectionParameters?.currentQuantitySelected ||
              !!passenger.inboundSelectionParameters?.currentQuantitySelected
          ).length,
          total: product.passengers.reduce((total, passenger) => {
            let tot = 0;
            if (passenger.outboundSelectionParameters)
              tot +=
                passenger.outboundSelectionParameters.currentQuantitySelected *
                passenger.outboundSelectionParameters.pricePerItem;

            if (passenger.inboundSelectionParameters)
              tot +=
                passenger.inboundSelectionParameters.currentQuantitySelected *
                passenger.inboundSelectionParameters.pricePerItem;

            return total + tot;
          }, 0),
          isSoldOut: isSoldOut(product.passengers),
        };
      }),
    [trip, products, cfLabels]
  );

  const minPrice = useMemo(
    () =>
      products.reduce((acc, product) => {
        const minProduct = product.passengers.reduce((lacc, passenger) => {
          let minPricePerProduct;

          if (passenger.inboundSelectionParameters && passenger.outboundSelectionParameters)
            minPricePerProduct = Math.min(
              passenger.inboundSelectionParameters.pricePerItem || 0,
              passenger.outboundSelectionParameters.pricePerItem || 0
            );
          else if (passenger.inboundSelectionParameters)
            minPricePerProduct = passenger.inboundSelectionParameters.pricePerItem;
          else if (passenger.outboundSelectionParameters)
            minPricePerProduct = passenger.outboundSelectionParameters.pricePerItem;
          else return lacc;

          if (lacc === undefined || lacc > minPricePerProduct) return minPricePerProduct;

          return lacc;
        }, undefined);
        if (acc === undefined || acc > minProduct) return minProduct;

        return acc;
      }, undefined) || 0,
    [products]
  );

  const total = useMemo(() => {
    const tot = items?.reduce((total, item) => total + item.total, 0);

    return tot ? tot : 0;
  }, [items]);

  const handleShowModal = () => {
    setActiveModalCF(labels);
  };

  return {
    hasItems,
    items,
    minPrice,
    total,
    handleShowModal,
  };
};
