import axios from 'axios';

export const getXfUrlFromPath = (xfPath?: string) => {
  if (!xfPath) return '';

  const originUrl = window.location.origin;

  if (xfPath.startsWith(originUrl)) xfPath = xfPath.replace(originUrl, '');

  if (!xfPath.endsWith('content.html')) xfPath += '.content.html';

  return xfPath;
};

export const fetchXfHTML = async (xfPath: string) => {
  if (!xfPath) return '';

  const xfUrl = getXfUrlFromPath(xfPath);

  try {
    const htmlResponse = await axios.get(xfUrl);

    return htmlResponse.data;
  } catch (e) {
    console.error(e);

    return '';
  }
};
