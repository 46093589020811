import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { RouteItemProps } from '@account/routes/MyTrips/types';

import { cn, cx } from 'utils/classNames';
import { TIME_FORMAT } from 'utils/constants';
import { isDelayExperienceEnabled } from 'utils/metas';

import { Tag } from '../Tag';
import { TrainStatusPill } from '../TrainStatusPIll/TrainStatusPill';
import './RouteItem.scss';

const bem = cn('account-route-item');

export const RouteItem = observer((props: RouteItemProps) => {
  const { classType, origin, destination, productId, isOutbound } = props;
  const { departureDateTime, arrivalDateTime, referenceNumber } = props;
  const { previousTimes } = props;
  const {
    cfStore,
    accountStore: {
      trips: { upcoming },
    },
  } = useStores();

  const trip = upcoming.find(trip => trip.referenceNumber == referenceNumber);
  const trainStatus = isOutbound ? trip?.outboundRoute.trainStatus : trip?.inboundRoute?.trainStatus;
  const { trips } = cfStore.account;
  const { classTypes } = trips;

  const delayExperienceEnabled = isDelayExperienceEnabled();

  const classTagLabel = useMemo(() => {
    const matchingClassType = classTypes.find(item => item.classTypeName?.toLowerCase() === productId?.toLowerCase());

    return matchingClassType?.classTypeLabel || productId || classType || '';
  }, [classTypes, classType, productId]);

  const isCompleted = delayExperienceEnabled && trainStatus == 'completed';

  return (
    <div className={cx(bem({ isCompleted }))}>
      <div className={bem('departure-date')}>
        <div className={bem('date')}>{moment(departureDateTime).tz(origin.timeZone).format('MMMM D')}</div>
        <TrainStatusPill status={trainStatus} />
      </div>
      <div className={bem('schedule')}>
        <div className={bem('time')}>{moment(departureDateTime).tz(origin.timeZone).format(TIME_FORMAT)}</div>
        <div className={bem('divider')} />
        <div className={bem('time')}>{moment(arrivalDateTime).tz(destination.timeZone).format(TIME_FORMAT)}</div>
      </div>
      {previousTimes?.departureTime && (
        <div className={bem('old')}>
          <span className={bem('departure')}>
            {moment(previousTimes?.departureTime).tz(origin.timeZone).format(TIME_FORMAT)}
          </span>
          {previousTimes.arrivalDateTime && (
            <span className={bem('arrival')}>
              {moment(previousTimes.arrivalDateTime).tz(destination.timeZone).format(TIME_FORMAT)}
            </span>
          )}
        </div>
      )}
      <div className={bem('stations')}>
        <span className={bem('origin')}>{origin.name}</span>
        <span className={bem('destination')}>{destination.name}</span>
      </div>
      <Tag variant={productId?.toLowerCase()} label={classTagLabel} size="small" className={bem('class-tag')} />
    </div>
  );
});
