import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Text } from '@atoms/Text';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';
import { color_brand_yellow } from 'utils/colors';

import './PolicyItemComponent/PolicyItem.scss';

const bem = cn('rti-policy-item');

export const useTermsOfServicesKiosk = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [xfContent, setXFContent] = useState('');
  const {
    kioskStore: { termsOfServiceModal },
  } = useStores();

  const fetchXFContent = async () => {
    try {
      const response = await fetch(termsOfServiceModal.experienceFragment);

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const data = await response.text();
      setXFContent(data);
    } catch (error) {
      console.error('Error fetching Experience Fragment content:', error);
    }
  };

  const termsOfService = sentence => (
    <>
      <Text text={sentence} className={bem('policy')} />
      <span className={bem('terms')} onClick={() => setModalVisible(true)}>{`${termsOfServiceModal.title}.`}</span>
      {modalVisible && (
        <Modal variant="no-space-scroll">
          <div className={bem('modalHeader')}>
            <Button
              colors={{ backgroundOrBorder: color_brand_yellow }}
              label="close"
              onClick={() => setModalVisible(false)}
            />
          </div>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(xfContent) }} />
        </Modal>
      )}
    </>
  );

  return {
    fetchXFContent,
    termsOfService,
  };
};
