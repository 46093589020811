import React from 'react';

import { useInputLogic } from 'utils/useInputLogic';

import { Select } from './Select';
import { SelectFieldProps } from './types';

const SelectField = (props: SelectFieldProps) => {
  const { name, value } = props;
  const { helpers, field, isInvalid, error } = useInputLogic({ ...props, name });
  const { setValue } = helpers;

  return (
    <Select
      {...props}
      {...field}
      setValue={setValue}
      name={name}
      error={isInvalid}
      errorMessage={error}
      value={value}
    />
  );
};

export default SelectField;
