import { useEffect, useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { rootStore, useStores } from 'stores';
import { DefaultProduct } from 'stores/BookingStore/sections/Tickets/types.journey';

import { RouteItemProps } from '@booking/routes/TrainSelection/components/RouteItem/types';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { scrollToTop } from 'utils/isKiosk';
import { mediaQueryList } from 'utils/mediaQueries';

export const useRouteItemLogic = (props: RouteItemProps) => {
  const { journey, isRoundTrip } = props;

  const { defaultPremiumProduct, defaultSmartProduct, defaultSmartSaverProduct, seatProducts } = journey || {};

  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const { bookingStore } = useStores();
  const { tickets } = bookingStore;
  const { premiumOnly, outboundTicket, setDrawerVisibility, fareSelectionTable, setIsFareSelected } = tickets;
  const { confirmTicket, selectItem, brandedFaresSwitch, setFareSelectionTable } = tickets;

  //Hide the Fare Compare table when switching to mobile
  useEffect(() => {
    if (!isMobile) return;

    setFareSelectionTable(undefined);
  }, [isMobile, setFareSelectionTable]);

  const smartSaverProduct = useMemo(
    () => seatProducts?.find(el => el.id === defaultSmartSaverProduct?.productId) || {},
    [defaultSmartSaverProduct, seatProducts]
  );

  const smartProduct = useMemo(() => seatProducts?.find(el => el.id === 'SMART_STANDARD') || {}, [seatProducts]);

  const premiumProduct = useMemo(
    () => seatProducts?.find(el => el.id === defaultPremiumProduct?.productId) || {},
    [defaultPremiumProduct, seatProducts]
  );

  const productConfig = useMemo(() => {
    let fareProductId =
      !defaultSmartSaverProduct?.productId && !defaultSmartProduct?.productId
        ? null
        : defaultSmartSaverProduct?.productId || 'SMART_STANDARD';
    let defaultCheapestProduct = defaultSmartProduct;
    let cheapestProduct = smartProduct;

    const smartSaverPrice = smartSaverProduct?.displayedPrice;
    const smartPrice = smartProduct?.displayedPrice || 0;
    const premiumPrice = premiumProduct?.displayedPrice || 0;

    const smartConfig = {
      defaultProduct: {
        productId: fareProductId || '',
        unavailableMessage:
          defaultSmartSaverProduct?.unavailableMessage || defaultSmartProduct?.unavailableMessage || '',
      },
      product: smartProduct,
    };

    if (smartSaverPrice) {
      const minPriceAll = Math.min(smartSaverPrice, smartPrice, premiumPrice);
      if (minPriceAll === smartSaverPrice) {
        fareProductId = smartSaverProduct.id || '';
        defaultCheapestProduct = defaultSmartSaverProduct;
        cheapestProduct = smartSaverProduct;
      } else if (minPriceAll === premiumPrice && !!minPriceAll) {
        fareProductId = premiumProduct.id || '';
        defaultCheapestProduct = defaultPremiumProduct;
        cheapestProduct = premiumProduct;
      }
      if (Math.min(smartSaverPrice, smartPrice) === smartSaverPrice) {
        cheapestProduct = smartSaverProduct;
        defaultCheapestProduct = defaultSmartSaverProduct;
        smartConfig.defaultProduct.productId = smartSaverProduct?.id || '';
        smartConfig.defaultProduct.unavailableMessage = defaultSmartSaverProduct?.unavailableMessage || '';
        smartConfig.product = smartSaverProduct;
      }
    } else if (Math.min(smartPrice, premiumPrice) === premiumPrice && !!Math.min(smartPrice, premiumPrice)) {
      fareProductId = premiumProduct.id || '';
      defaultCheapestProduct = defaultPremiumProduct;
      cheapestProduct = premiumProduct;
    }

    const cheapestProductConfig = {
      defaultProduct: {
        productId: fareProductId,
        unavailableMessage: defaultCheapestProduct?.unavailableMessage || '',
      },
      product: cheapestProduct,
    };

    const premiumConfig = {
      defaultProduct: defaultPremiumProduct,
      product: premiumProduct,
    };

    if (!isMobile) return smartConfig;

    return premiumOnly ? premiumConfig : cheapestProductConfig;
  }, [
    isMobile,
    defaultSmartSaverProduct,
    smartSaverProduct,
    defaultSmartProduct,
    smartProduct,
    defaultPremiumProduct,
    premiumProduct,
    premiumOnly,
  ]);

  const isSmartTravelPassEligible = useMemo(
    () =>
      seatProducts?.find(el => el.id == 'SMART_SAVER')?.isTravelPassEligible ||
      seatProducts?.find(el => el.id == 'SMART_STANDARD')?.isTravelPassEligible ||
      false,
    [seatProducts]
  );

  const getPromoCode = () => {
    const hasLocationState = location.state && location.state.ticket;
    const searchParams = hasLocationState
      ? location.state.ticket
      : Object.fromEntries(new URLSearchParams(location.search));
    const { promo_code } = searchParams;

    return promo_code || '';
  };

  const navigateToRTI = bookingResponse => {
    const promo_code = getPromoCode();
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: 'Go to RTI page',
        customLink: promo_code ? `/rti?promo_code=${promo_code}` : '/rti',
        externalLink: '',
        linkType: 'linkClick',
      },
      tripManagement: {
        PNR: bookingResponse?.tripDetails?.trip.referenceNumber,
      },
    });
    setFareSelectionTable(undefined);
    if (promo_code) navigate(`/rti?promo_code=${promo_code}`);
    else navigate('/rti');
  };

  const handleTicketSelect = (defaultProduct: DefaultProduct) => {
    if (brandedFaresSwitch) {
      if (!journey) return;

      if (!isMobile)
        if (
          fareSelectionTable?.journeyID === journey.id &&
          fareSelectionTable.defaultProduct.productId === defaultProduct.productId
        )
          setFareSelectionTable(undefined);
        else
          setFareSelectionTable({
            journeyID: journey?.id,
            journey,
            defaultProduct,
          });
      else {
        selectItem(journey, defaultProduct);
        setIsFareSelected(true);
        setDrawerVisibility(true);
      }

      if (isMobile) {
        selectItem(journey, defaultProduct);
        setIsFareSelected(true);
        setDrawerVisibility(true);
      }

      return;
    }

    if (!outboundTicket && isRoundTrip) scrollToTop();

    selectItem(journey, defaultProduct);

    if (isMobile) setDrawerVisibility(true);
    else confirmTicket(navigateToRTI);
  };

  const handleFareCompareTableButtonClicked = (defaultProduct: DefaultProduct) => {
    if (!outboundTicket && isRoundTrip) scrollToTop();

    selectItem(journey, defaultProduct);

    if (isMobile) setDrawerVisibility(true);
    else confirmTicket(navigateToRTI);
  };

  return {
    smartProduct,
    premiumProduct,
    productConfig,
    isSmartTravelPassEligible,
    handleTicketSelect,
    handleFareCompareTableButtonClicked,
  };
};
