import React from 'react';

import { cn } from 'utils/classNames';

import './DateSelection.scss';
import { DateRangePicker } from './components/DateRangePicker/DateRangePicker';

const bem = cn('date-selection');

export const DateSelection = props => (
  <div className={bem()}>
    <DateRangePicker {...props} />
  </div>
);
