import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Modal } from '@molecules/Modal';

import UpsellContentDesktop from './UpsellContentDesktop/UpsellContentDesktop';
import { UpsellModalComponentProps } from './types';

const UpsellModal = (props: UpsellModalComponentProps) => {
  const { handleOnContinue, handleOnUpgrade, selectedFare } = props;
  const { showUpsellModal, journey } = props;

  const { cfStore } = useStores();
  const { trainSelection } = cfStore;
  const { upsell } = trainSelection;
  const { upsellOptions } = upsell;

  const upsellOption = useMemo(
    () =>
      selectedFare === 'SMART_SAVER'
        ? upsellOptions.find(opt => opt.option === 'toSmart')
        : upsellOptions.find(opt => opt.option === 'toPremium'),
    [upsellOptions, selectedFare]
  );

  if (!upsellOption) return null;

  if (showUpsellModal)
    return (
      <Modal variant="upsell">
        <UpsellContentDesktop
          title={upsellOption.title}
          description={upsellOption.description}
          continueWithButtonLabel={upsellOption.continueWithButtonLabel}
          upgradeToButtonLabel={upsellOption.upgradeToButtonLabel}
          handleOnContinue={handleOnContinue}
          handleOnUpgrade={handleOnUpgrade}
          selectedFare={selectedFare}
          journey={journey}
        />
      </Modal>
    );

  return null;
};

export default UpsellModal;
