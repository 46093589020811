import * as Yup from 'yup';

import { isLoyaltyEnabled } from 'utils/metas';

export const getValidationSchema = i18n => {
  const schema = {
    firstName: Yup.string().required(i18n.requiredInputLabel),
    lastName: Yup.string().required(i18n.requiredInputLabel),
    dateOfBirth: Yup.date().required(i18n.requiredInputLabel),
    email: Yup.string()
      .test('email', i18n.requiredInputLabel, email => !!email)
      .email(i18n.invalidInputLabel),
    phoneNumber: Yup.object().test('phoneNumber', i18n.requiredInputLabel, phoneNumber => !!phoneNumber?.number),
    wheelchairAccommodation: Yup.string(),
    zipCode: Yup.string(),
  };

  if (isLoyaltyEnabled())
    schema.zipCode = Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, i18n.invalidInputLabel)
      .required(i18n.requiredInputLabel);

  return Yup.object().shape(schema);
};
