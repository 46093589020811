import React from 'react';

import { cn } from 'utils/classNames';

import './Avatar.scss';
import { AvatarProps } from './types';

const bem = cn('avatar');

export const Avatar = ({ label }: AvatarProps) => <div className={bem()}>{label}</div>;
