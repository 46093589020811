import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useStores } from 'stores';

import { OverlayContainer } from '@account/components/OverlayContainer';
import { useRestoreScroll } from '@account/hooks/useRestoreScroll';
import { useSimpleSelectHandlers } from '@account/hooks/useSimpleSelectHandlers';
import '@adyen/adyen-web/dist/adyen.css';
import { Icon } from '@atoms/Icon/Icon';

import { cn, cx } from 'utils/classNames';

import PaymentToken from './PaymentToken';
import './PaymentTokens.scss';
import { SavedPaymentTokenProps } from './types';

const bem = cn('account-tokens');

const EditableToken = observer((props: SavedPaymentTokenProps) => {
  const { token } = props;

  const { cfStore, accountStore } = useStores();
  const { account } = cfStore;
  const { paymentsCF } = account;
  const { paymentTokensStore } = accountStore;
  const { deleteToken } = paymentTokensStore;

  const { setScrollPosition } = useRestoreScroll();

  const [menuOpened, setMenuOpened] = useState(false);

  const [modalDeleteOpened, setModalDeleteOpened] = useState(false);

  const moreRef = useRef<HTMLButtonElement>(null);
  const deleteRef = useRef<HTMLButtonElement>(null);

  const handleToggleMenuOpen = () => {
    if (!menuOpened) setScrollPosition({ x: window.pageXOffset, y: window.pageYOffset });

    setMenuOpened(!menuOpened);
  };

  const handleToggleDelete = () => {
    setModalDeleteOpened(!modalDeleteOpened);
  };

  const handleDeleteCard = () => {
    deleteToken(token, handleToggleDelete);
  };

  const hookProps = useSimpleSelectHandlers({ toggleMenu: handleToggleMenuOpen, nrItems: 1 });
  const { focus, handleKeyDown } = hookProps;

  const handleClickOutsideWrapper = () => {
    if (menuOpened) handleToggleMenuOpen();
  };

  useEffect(() => {
    if (focus.index === 1) deleteRef.current?.focus();
  }, [focus.index]);

  return (
    <>
      <PaymentToken token={token} />
      <div className={bem('actions-container')}>
        <button
          ref={moreRef}
          onClick={handleToggleMenuOpen}
          className={cx(bem('edit', { opened: menuOpened }), bem('actions-btn'))}
          aria-label="more"
        >
          <Icon name="more" />
        </button>

        <OverlayContainer
          title={paymentsCF.deleteCardConfirmationTitle}
          isVisible={modalDeleteOpened}
          cancelButtonLabel={paymentsCF.cancelCtaLabel}
          submitButtonLabel={paymentsCF.deleteCtaLabel}
          onCancel={handleToggleDelete}
          onConfirm={handleDeleteCard}
        >
          <div>{paymentsCF.deleteCardConfirmationText}</div>
        </OverlayContainer>

        <OutsideClickHandler onOutsideClick={handleClickOutsideWrapper}>
          <div className={bem('actions', { visible: menuOpened })} onKeyDown={handleKeyDown}>
            {paymentsCF.deleteCardLabel && (
              <button ref={deleteRef} className={cx(bem('delete'), bem('actions-btn'))} onClick={handleToggleDelete}>
                {paymentsCF.deleteCardLabel}
              </button>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
});

export default EditableToken;
