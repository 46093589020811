import React, { useMemo } from 'react';

import { Icon } from '@atoms/Icon';

import { appDownloadEvent } from 'utils/adobeDataLayer';
import { cn, cx } from 'utils/classNames';

import './ImageButton.scss';
import { ImageButtonProps } from './types';

const bem = cn('img-btn');

const ImageButton = (props: ImageButtonProps) => {
  const { id, socialMediaType, link, disabled, image = '', imageAlt = ' ', ariaLabel, colors, className } = props;
  const { url, target } = link || {};
  const { appDownloadButton } = props;
  const altId = useMemo(() => `social-media-${socialMediaType}`, [socialMediaType]);

  const vars: any = useMemo(
    () =>
      colors
        ? {
            '--border-color': colors?.border?.inactive,
            '--border-focus-color': colors?.border?.focus,
            '--border-hover-color': colors?.border?.hover,
            '--border-disabled-color': colors?.border?.disabled,
            '--icon-color': colors?.icon?.inactive,
            '--icon-hover-color': colors?.icon?.hover,
            '--icon-focus-color': colors?.icon?.focus,
            '--icon-disabled-color': colors?.icon?.disabled,
          }
        : {},
    [colors]
  );

  const handleClick = () => {
    const urlObj = new URL(url || '');
    const isExternal = urlObj.host !== window.location.host;

    if (appDownloadButton)
      appDownloadEvent({
        appDownload: {
          pageNameClickTracking: document.title,
          navigationLinkName: ariaLabel || imageAlt,
          customLink: url || null,
          externalLink: isExternal ? url : null,
        },
      });
  };

  return (
    <>
      {socialMediaType ? (
        <a
          id={id}
          href={url}
          target={target}
          className={cx(bem({ social: true, disabled, socialMedia: image }), className)}
          aria-disabled={disabled}
          style={vars}
          aria-label={ariaLabel || altId}
          onClick={handleClick}
        >
          {Icon && (
            <Icon
              externalIcon={image}
              externalIconAlt={imageAlt}
              name={socialMediaType}
              className={image ? 'imageSocialMedia' : undefined}
            />
          )}
        </a>
      ) : (
        <a
          id={id}
          href={url}
          target={target}
          className={cx(bem({ image: true, disabled }), className)}
          style={vars}
          aria-label={ariaLabel}
          data-blte-app-button={appDownloadButton}
          onClick={handleClick}
        >
          <img src={image} alt={imageAlt} className={bem('img')} />
        </a>
      )}
    </>
  );
};

export default ImageButton;
