import React from 'react';

import { color_base_medium_light_grey } from 'utils/colors';

export const ErrorIcon = props => {
  const { fillColor = color_base_medium_light_grey, className } = props;

  return (
    <svg width="58" height="40" viewBox="0 0 58 40" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M38.4045 5.44234C36.58 4.78263 34.612 4.52323 32.1935 4.52323H11.1089C7.11943 4.52323 5.13431 6.5665 5.13431 10.653V29.274L25.5251 14.6679V13.2973C25.5251 12.0954 26.1996 11.3942 27.356 11.3942H30.0953L38.4045 5.44234Z"
        fill={fillColor}
      />
      <path
        d="M13.416 36.3141H48.2672C54.6658 36.3141 58 32.4279 58 27.8405C58 25.0361 56.7858 21.9912 54.184 19.3269L47.1962 12.1172L43.369 14.8586C43.3957 14.8855 43.4223 14.9126 43.4489 14.9399L47.8817 19.5272C49.0959 20.7893 48.3828 22.512 47.0722 22.512H32.6844L13.416 36.3141Z"
        fill={fillColor}
      />
      <path
        d="M53.5418 3.2121C53.553 3.20413 53.564 3.19604 53.5749 3.18785C54.31 2.63404 54.4944 1.56055 53.9832 0.769404C53.5941 0.167261 52.9126 -0.100307 52.2744 0.0339738C52.1233 0.0657874 51.9745 0.120157 51.8326 0.197977C51.7749 0.229646 51.7183 0.265199 51.6632 0.304696L0.730744 36.7879C0.719613 36.7959 0.708612 36.804 0.697722 36.8122C0.597223 36.8879 0.507015 36.9733 0.427471 37.0664C-0.0748108 37.6542 -0.151921 38.5476 0.289392 39.2306C0.354237 39.331 0.427196 39.422 0.506733 39.5035C1.06351 40.0737 1.94217 40.1732 2.60938 39.6953L53.5418 3.2121Z"
        fill={fillColor}
      />
    </svg>
  );
};
