import { observer } from 'mobx-react-lite';
import React from 'react';

import { Table } from '@atoms/Table/Table';
import { FareSelectionTableProps } from '@organisms/FareSelectionTable/types';
import UpsellModal from '@organisms/UpsellModal/UpsellModal';

import { cn } from 'utils/classNames';
import { isAuthorMode } from 'utils/metas';

import './FareSelectionTable.scss';
import { useFareSelectionTableLogic } from './useFareSelectionTableLogic';

const bem = cn('fare-compare-table');

export const FareSelectionTable = observer((props: FareSelectionTableProps) => {
  const fareSelectionTableLogic = useFareSelectionTableLogic(props);

  const { fareSelectionTable, isPremiumProduct, tableConfig } = fareSelectionTableLogic;
  const { attachEventListeners, cleanupEventListeners, upsellModal } = fareSelectionTableLogic;
  const { authorViewMockData } = fareSelectionTableLogic;

  const { journey, defaultProduct } = isAuthorMode() ? authorViewMockData : fareSelectionTable || {};

  if (!journey || !defaultProduct || !tableConfig) return null;

  return (
    <div className={bem()}>
      <Table
        {...tableConfig}
        tableContainerCN={bem('table-container')}
        eventListenersAttacher={attachEventListeners}
        cleanupListeners={cleanupEventListeners}
      />
      {!!upsellModal && <UpsellModal {...upsellModal} />}
      {isPremiumProduct && <div className={bem('top-right-background')} />}
    </div>
  );
});
