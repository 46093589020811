import { useState } from 'react';

import { safetyPledgeForm } from 'utils/adobeDataLayer';
import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';

export const useServerLogic = () => {
  const [serverStatus, setServerStatus] = useState('initial');
  const [pledgeCounter, setPledgecounter] = useState();

  const getCounter = async () => {
    try {
      const url = `${requestUrls.getRestUrl(requestUrls.configs.pledgeCounter)}.xjson`;
      const response = await api.get(url);
      setPledgecounter(response.data.counter);
    } catch (e: any) {
      console.error(e);
    }
  };

  const updateCounter = async () => {
    try {
      const url = `${requestUrls.getRestUrl(requestUrls.configs.pledgeCounter)}.xjson`;
      await api.put(url);
      await getCounter();
    } catch (e: any) {
      console.error(e);
    }
  };

  const onSubmit = async data => {
    setServerStatus('loading');
    try {
      await new Promise(resolve => setTimeout(resolve, 500));

      data.disclaimerCheckbox &&
        safetyPledgeForm({
          safetyPledgeForm: {
            formName: 'safetyPledgeForm',
            formSubmitAll: true,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            postalCode: data.postalCode,
            disclaimerCheckbox: data.disclaimerCheckbox,
          },
        });

      updateCounter();

      setServerStatus('success');
    } catch (error) {
      setServerStatus('error');
    }
  };

  const resetServerStatus = () => setServerStatus('initial');

  return { onSubmit, serverStatus, resetServerStatus, getCounter, updateCounter, pledgeCounter };
};
