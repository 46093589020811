import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Column, Grid } from '@atoms/Grid';
import Logo from '@atoms/Logo/Logo';
import { logos } from '@atoms/Logo/constants';
import { validatePromoCodeResponse } from '@molecules/PromoCode/utils';
import { TopNav } from '@organisms/GlobalHeader/components/TopNav';

import { cn } from 'utils/classNames';

import { DepartureTripSummary } from '../../routes/TrainSelection/components/DepartureTripSummary';
import { BookingTicketsForm } from '../BookingTicketsForm';
import './Header.scss';
import { DateCarousel } from './components/DateCarousel';
import { PromoCodeAppliedBanner } from './components/PromoCodeAlertBanner';
import { SearchBar } from './components/SearchBar';
import { HeaderProps } from './types';
import { useDataFromQueryParams } from './useDataFromQueryParams';

const bem = cn('booking-header');

export const Header = observer((props: HeaderProps) => {
  const { isKiosk } = props;
  const { displaySearchWidget } = useDataFromQueryParams(props);

  const { ticketsFormStore, bookingStore, cfStore, authStore, accountStore, kioskStore } = useStores();
  const { header, trainSelection } = cfStore;
  const { promoCodeBanner } = trainSelection;
  const { isPanelVisible, incompleteSearch } = ticketsFormStore;
  const { data, loading, outboundTicket } = bookingStore.tickets;
  const { profile } = accountStore;
  const { fetchUserProfile, clearUserProfile, userProfile } = profile;
  const { isAuthenticated } = authStore;
  const { displaySessionClearanceModal } = kioskStore;

  const showPromoCodeBanner = useMemo(() => {
    const formValues = ticketsFormStore.formValues;

    if (!promoCodeBanner.enablePromoCodeBanners || !formValues.promoCode || !formValues.promoCodeDetails) return false;

    const earliestJourneyDate = moment(data.journeys[0]?.departureTime);

    const promoCodeValidation = validatePromoCodeResponse(
      formValues.promoCodeDetails,
      header.ticketsForm.promoCode.i18n,
      earliestJourneyDate,
      undefined
    );

    return displaySearchWidget && !ticketsFormStore.isPanelVisible && promoCodeValidation.valid;
  }, [
    promoCodeBanner,
    header.ticketsForm.promoCode,
    ticketsFormStore.formValues,
    ticketsFormStore.isPanelVisible,
    displaySearchWidget,
    data.journeys,
  ]);

  const handleSkipToMain = () => {
    const mainContent = document.getElementById('main');
    if (mainContent) mainContent?.focus();
  };

  useEffect(() => {
    if (isAuthenticated && !userProfile) fetchUserProfile();
    if (!isAuthenticated && userProfile) clearUserProfile();
  }, [isAuthenticated, userProfile, fetchUserProfile, clearUserProfile]);

  if (!header) return null;

  return (
    <header className={bem()}>
      <Button
        color="yellow"
        size="small"
        label={header.skipToContentBtnLabel}
        className={bem('skip-link')}
        onClick={handleSkipToMain}
      />
      <Button
        color="yellow"
        size="small"
        label={header.skipToCookieBannerBtnLabel}
        link={{ url: '#footer_alert_banner' }}
        className={bem('skip-link')}
      />
      <Grid>
        <Column>
          <div className={bem('container')}>
            <Logo
              logo={header.logo || logos.black}
              logoAlt={header.logoAlt}
              link={document.getElementById('kiosk-root') ? undefined : '/'}
            />
            {displaySearchWidget && <SearchBar editSearchButtonLabel={header.editSearchButtonLabel} />}
            <div className={bem('navigation', { absolute: isPanelVisible })}>
              {isKiosk ? (
                <Button color="yellow" label={header.endSessionLabel} onClick={displaySessionClearanceModal} />
              ) : (
                <TopNav {...header.topNav} isResponsive={false} />
              )}
            </div>
          </div>
        </Column>
      </Grid>
      <div>{showPromoCodeBanner && <PromoCodeAppliedBanner />}</div>
      <div className={bem('tickets-container')}>
        <div className={bem('tickets-items', { absolute: isPanelVisible })}>
          {isPanelVisible && displaySearchWidget && (
            <div className={bem('form-container')}>
              <Grid>
                <Column cols={10} offset={1}>
                  <BookingTicketsForm />
                </Column>
              </Grid>
            </div>
          )}
          {!incompleteSearch && displaySearchWidget && <DateCarousel items={data?.dateItems} loading={loading} />}
          {!incompleteSearch && outboundTicket && displaySearchWidget && (
            <div className={bem('trip-summary')}>
              <Grid>
                <Column>
                  <DepartureTripSummary />
                </Column>
              </Grid>
            </div>
          )}
        </div>
      </div>
    </header>
  );
});
