import { Journey } from 'stores/BookingStore/sections/Tickets/types.journey';
import { FareCompareItem, TableConfig } from 'stores/CFStore/types.header';

export const findTableConfigByJourney = (
  fareVariant: string,
  fareCompareItems: FareCompareItem[],
  journey?: Journey
) => {
  const originID = journey?.origin.id;
  const destinationID = journey?.destination.id;

  if (!originID || !destinationID) return undefined;

  const containsOriginAndDestinationPair = originAndDestinationPair =>
    originAndDestinationPair?.find(
      odPair =>
        (odPair.originStation === originID && odPair.destinationStation === destinationID) ||
        (odPair.originStation === destinationID && odPair.destinationStation === originID)
    );

  const parsedTables = fareCompareItems
    .filter(item => item.fareVariant === fareVariant)
    .map(item => JSON.parse(item.json || '{}') as TableConfig);

  return (
    parsedTables.find(itemJson => containsOriginAndDestinationPair(itemJson.originDestinationTableConfig)) ||
    parsedTables.find(itemJson => itemJson.defaultTableConfiguration)
  );
};

export const findTableConfigByOriginAndDestination = (
  fareVariant: string,
  fareCompareItems: FareCompareItem[],
  originStationID: string,
  destinationStationID: string
) => {
  if (!originStationID || !destinationStationID) return undefined;

  const containsOriginAndDestinationPair = originAndDestinationPair =>
    originAndDestinationPair?.find(
      odPair =>
        (odPair.originStation === originStationID && odPair.destinationStation === destinationStationID) ||
        (odPair.originStation === destinationStationID && odPair.destinationStation === originStationID)
    );

  const parsedTables = fareCompareItems
    .filter(item => item.fareVariant === fareVariant)
    .map(item => JSON.parse(item.json || '{}') as TableConfig);

  return (
    parsedTables.find(itemJson => containsOriginAndDestinationPair(itemJson.originDestinationTableConfig)) ||
    parsedTables.find(itemJson => itemJson.defaultTableConfiguration)
  );
};
