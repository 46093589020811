import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import './CostSummary.scss';

const bem = cn('cost-summary-total');

export const CostSummaryTotal = observer(() => {
  const { bookingStore, cfStore } = useStores();

  const { getTotal, provisionalBooking, modifyTripType } = bookingStore.rti;
  const { generic } = cfStore;

  const costSummary = provisionalBooking?.tripDetails?.costSummary;

  const total: any = useMemo(getTotal, [
    provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid,
    getTotal,
  ]);

  const totalLabel: string = useMemo(() => {
    const totalToBePaid = costSummary?.bookingTotal?.totalToBePaid;

    if (!modifyTripType || totalToBePaid === undefined) return generic.totalLabel;

    return totalToBePaid < 0 ? generic.totalReturnAsCreditLabel : generic.totalDueLabel;
  }, [generic, modifyTripType, costSummary]);

  return (
    <div className={bem('content')}>
      <div className={bem('total')}>{totalLabel} (USD)</div>
      <div>${total.toFixed(2)}</div>
    </div>
  );
});
