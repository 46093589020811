import moment from 'moment';
import React, { useState } from 'react';
import { useStores } from 'stores';

import { Tag } from '@account/routes/MyTrips/components/UpcomingTrips/components/TripItem/components/Tag';
import { Checkbox } from '@atoms/Checkbox';
import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';
import { TIME_FORMAT } from 'utils/constants';

const bem = cn('cost-summary-sections');

export const PartialCancellation = (props: any) => {
  const { tripName, tripType, setTripType } = props;
  const {
    cfStore: { account },
    bookingStore,
  } = useStores();
  const { classTypes } = account.tripDetails;
  const { provisionalBooking } = bookingStore.rti;
  const { trip } = provisionalBooking.tripDetails;

  const leg = tripName === 'Departure' ? trip?.outboundRoute : trip?.inboundRoute;

  const { cancelMessage, departedMessage } = account.tripDetails.navigation.cancelTrip;

  const disabled = leg?.isCancelled || (leg?.departureDateTime && new Date(leg?.departureDateTime) < new Date());

  const disabledMessage = leg.isCancelled ? cancelMessage : departedMessage;

  const dateDeparture = moment(leg?.departureDateTime);

  const dateArrival = moment(leg?.arrivalDateTime);

  const formattedDepartureTime = dateDeparture.tz(leg?.origin?.timeZone || 'America/New_York').format(TIME_FORMAT);

  const formattedArrivalTime = dateArrival.tz(leg?.origin?.timeZone || 'America/New_York').format(TIME_FORMAT);

  const formattedDate = dateDeparture.format('ddd, MMM DD');

  const [select, setSelect] = useState(false);

  const matchingClassType = classTypes.find(
    item => item.classTypeName?.toLowerCase() === leg?.productId?.toLowerCase()
  );

  const handleSelect = () => {
    select ? setSelect(false) : setSelect(true);
    if (tripName === 'Departure')
      setTripType(prevState => ({
        ...prevState,
        departure: !tripType.departure,
      }));
    if (tripName === 'Return')
      setTripType(prevState => ({
        ...prevState,
        return: !tripType.return,
      }));
  };

  return (
    <div
      className={bem('container', { disabledClass: disabled, select: select })}
      onClick={() => {
        if (!disabled) handleSelect();
      }}
    >
      <div className={bem('header')}>
        <p className={bem('title')}>{tripName}</p>
        <div className={bem('date-check')}>
          <p className={bem('date')}>{formattedDate}</p>
          <Checkbox
            name=""
            label=""
            isChecked={select}
            onChange={() => {
              null;
            }}
            disabled={disabled}
          />
        </div>
      </div>
      <Tag
        variant={leg?.productId?.toLowerCase()}
        label={matchingClassType?.classTypeLabel.toUpperCase() || ''}
        size="small"
        className={bem('type-tag')}
      />
      <div className={bem('from-to-select')}>
        <div className={bem('info-section')}>
          <span>{formattedDepartureTime}</span>
          <span>{leg?.origin?.city}</span>
        </div>
        <svg width="80" height="30" viewBox="0 0 80 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.859375 15H79.1406" stroke="#E1E1E1" strokeWidth="2" />
          <circle
            cx="4.85938"
            cy="15"
            r="3"
            transform="rotate(-90 4.85938 15)"
            fill="#E1E1E1"
            stroke="#E1E1E1"
            strokeWidth="2"
          />
          <rect x="71.8594" y="18.5" width="7" height="7" transform="rotate(-90 71.8594 18.5)" fill="#E1E1E1" />
        </svg>

        <div className={bem('info-section')}>
          <span>{formattedArrivalTime}</span>
          <span>{leg?.destination?.city}</span>
        </div>
      </div>
      {disabled && (
        <div className={bem('disabled-message')}>
          <Icon name="circle-info" />
          <span>{disabledMessage}</span>
        </div>
      )}
    </div>
  );
};
