import useFocusTrap from 'hooks/useFocusTrap';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';

import { cn, cx } from 'utils/classNames';

import { DesktopCalendarProps } from '../types';
import './DesktopCalendar.scss';

const bem = cn('events-desktop-calendar');
const bemDateRange = cn('events-date-range-picker');

export const DesktopCalendar = observer((props: DesktopCalendarProps) => {
  const {
    onFocusChange,
    handleCloseButton,
    closeButtonLabel,
    clearButtonLabel,
    handleClearDates,
    isOutsideRange,
    datesRestrictions,
    isSameDay,
    isOpen,
    activeButtonFilter,
    setActiveButtonFilter,
    onDatesChange,
    focusedInput,
    selectedStartDate,
    selectedEndDate,
  } = props;

  const { cfStore } = useStores();
  const { events } = cfStore;
  const {
    weekdaysFilterLabel,
    weekdaysFilterValue,
    weekendsFilterLabel,
    weekendsFilterValue,
    thisWeekFilterLabel,
    thisWeekFilterValue,
    nextWeekFilterLabel,
    nextWeekFilterValue,
    thisMonthFilterLabel,
    thisMonthFilterValue,
    nextMonthFilterLabel,
    nextMonthFilterValue,
  } = events.calendarFilter;

  const buttonOptions = [
    { label: weekdaysFilterLabel, value: weekdaysFilterValue },
    { label: weekendsFilterLabel, value: weekendsFilterValue },
    { label: thisWeekFilterLabel, value: thisWeekFilterValue },
    { label: nextWeekFilterLabel, value: nextWeekFilterValue },
    { label: thisMonthFilterLabel, value: thisMonthFilterValue },
    { label: nextMonthFilterLabel, value: nextMonthFilterValue },
  ];

  const maxDate = datesRestrictions ? moment.utc(datesRestrictions.latestDate) : null;

  const renderNavButton = (pos = 'prev') => (
    <button className={bemDateRange('navButton', { pos })}>
      <Icon name="arrow-left" />
    </button>
  );

  const startDateMoment = moment.isMoment(selectedStartDate)
    ? selectedStartDate
    : selectedStartDate
    ? moment(selectedStartDate)
    : null;
  const endDateMoment = moment.isMoment(selectedEndDate)
    ? selectedEndDate
    : selectedEndDate
    ? moment(selectedEndDate)
    : null;

  const renderDayContents = day => <div className={bemDateRange('day', { sameDay: isSameDay })}>{day.date()}</div>;

  const dialogRef = useFocusTrap(isOpen);

  const handleButtonClick = ({ value, label }) => {
    setActiveButtonFilter(prev => (prev.value === value ? { value: '', label: '' } : { value, label }));
    handleCloseButton(false);
  };

  return (
    <div ref={dialogRef} className={cx(bem('calendar'), bemDateRange('calendar'))} tabIndex={0}>
      <div className={bem('active-buttons-wrapper')}>
        {buttonOptions.map(option => (
          <button
            key={option.value}
            className={bem('active-button', { isActive: activeButtonFilter?.value === option.value })}
            onClick={() => handleButtonClick(option)}
          >
            {option.label}
          </button>
        ))}
      </div>
      <DayPickerRangeController
        numberOfMonths={2}
        onFocusChange={onFocusChange}
        startDate={startDateMoment}
        endDate={endDateMoment}
        daySize={46}
        minimumNights={0}
        minDate={moment()}
        navPrev={renderNavButton()}
        navNext={renderNavButton('next')}
        renderDayContents={renderDayContents}
        enableOutsideDays={false}
        isOutsideRange={isOutsideRange(moment(), maxDate)}
        transitionDuration={0}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel
      />
      <div className={bem('footer')}>
        <div className={bem('footer-right')}>
          <Button
            className={bem('button-clear')}
            label={clearButtonLabel}
            color="white"
            onClick={() => handleClearDates(true)}
            size="medium"
            type="button"
          />
          <Button
            className={bem('button-close')}
            label={closeButtonLabel}
            onClick={() => handleCloseButton(false)}
            size="medium"
            color="blue"
          />
        </div>
      </div>
    </div>
  );
});
