import moment from 'moment';
import React from 'react';
import { DayPickerRangeController } from 'react-dates';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';

import { cn, cx } from 'utils/classNames';

import { START_DATE } from '../constants';
import './MobileCalendar.scss';

const bem = cn('events-mobile-calendar');
const bemDateRange = cn('events-date-range-picker');

export const MobileCalendar = props => {
  const {
    isSameDay,
    containerWidth,
    isOutsideRange,
    focusedInput = START_DATE,
    handleDatesChange,
    handleFocusChange,
    datesRestrictions,
    handleCloseButton,
    activeButtonFilter,
    setActiveButtonFilter,
    selectedStartDate,
    selectedEndDate,
  } = props;

  const { cfStore } = useStores();
  const { events } = cfStore;
  const {
    weekdaysFilterLabel,
    weekdaysFilterValue,
    weekendsFilterLabel,
    weekendsFilterValue,
    thisWeekFilterLabel,
    thisWeekFilterValue,
    nextWeekFilterLabel,
    nextWeekFilterValue,
    thisMonthFilterLabel,
    thisMonthFilterValue,
    nextMonthFilterLabel,
    nextMonthFilterValue,
  } = events.calendarFilter;

  const buttonOptions = [
    { label: weekdaysFilterLabel, value: weekdaysFilterValue },
    { label: weekendsFilterLabel, value: weekendsFilterValue },
    { label: thisWeekFilterLabel, value: thisWeekFilterValue },
    { label: nextWeekFilterLabel, value: nextWeekFilterValue },
    { label: thisMonthFilterLabel, value: thisMonthFilterValue },
    { label: nextMonthFilterLabel, value: nextMonthFilterValue },
  ];

  const inputToFocus = focusedInput !== '' ? focusedInput : START_DATE;

  const daySize = containerWidth ? Math.trunc(containerWidth / 7) : 35;

  const vars: any = {
    '--line-height': `${daySize - 5}px`,
    '--font-size': `${daySize - daySize / 5}px`,
  };

  const maxDate = datesRestrictions ? moment.utc(datesRestrictions.latestDate) : null;

  const startDateMoment = moment.isMoment(selectedStartDate)
    ? selectedStartDate
    : selectedStartDate
    ? moment(selectedStartDate)
    : null;
  const endDateMoment = moment.isMoment(selectedEndDate)
    ? selectedEndDate
    : selectedEndDate
    ? moment(selectedEndDate)
    : null;

  const renderNavButton = (pos = 'prev') => (
    <button className={bemDateRange('navButton', { pos })}>
      <Icon name="arrow-left" />
    </button>
  );

  const handleButtonClick = ({ value, label }) => {
    setActiveButtonFilter(prev => (prev.value === value ? { value: '', label: '' } : { value, label }));
    handleCloseButton(false);
  };

  const renderDayContents = day => <div className={bemDateRange('day', { sameDay: isSameDay })}>{day.date()}</div>;

  return (
    <div className={cx(bem('calendar'), bemDateRange('calendar'))} style={vars}>
      <div className={bem('active-buttons-wrapper')}>
        {buttonOptions.map(option => (
          <button
            key={option.value}
            className={bem('active-button', { isActive: activeButtonFilter?.value === option.value })}
            onClick={() => handleButtonClick(option)}
          >
            {option.label}
          </button>
        ))}
      </div>
      <DayPickerRangeController
        onFocusChange={handleFocusChange}
        startDate={startDateMoment}
        endDate={endDateMoment}
        minimumNights={0}
        daySize={daySize}
        minDate={moment()}
        navPrev={renderNavButton()}
        focusedInput={inputToFocus}
        navNext={renderNavButton('next')}
        renderDayContents={renderDayContents}
        onDatesChange={handleDatesChange}
        enableOutsideDays={false}
        isOutsideRange={isOutsideRange(moment(), maxDate)}
        hideKeyboardShortcutsPanel
      />
    </div>
  );
};
