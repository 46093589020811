import moment from 'moment-timezone';
import { rootStore } from 'stores';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { selectKioskRoute } from 'utils/isKiosk';

import { passengerTypes } from '../PassengersInfo/constants';
import { TicketStateModel } from './types';

export const editTripDetails = (
  navigate,
  provisionalBooking,
  formValues,
  labelText,
  resetGiftCards,
  setTpNotApplicableDueToGiftCardPayment
) => {
  const dateFormat = 'YYYY-MM-DD';
  const outboundRoute = provisionalBooking.tripDetails?.trip?.outboundRoute;
  const inboundRoute = provisionalBooking.tripDetails?.trip?.inboundRoute;

  const passengers = provisionalBooking?.tripDetails?.passengerSeatingDetails || [];

  const adultsArray = passengers.filter(p => p.passenger.type === passengerTypes.adult);
  const adults = adultsArray.length;

  const kidsArray = passengers.filter(p => p.passenger.type === passengerTypes.child);
  const kids = kidsArray.length;

  const infants = passengers.filter(p => p.passenger.type === passengerTypes.infant).length;

  const wc_adults = adultsArray.filter(p => p.passenger.wheelchairAccommodation).length;

  const wc_children = kidsArray.filter(p => p.passenger.wheelchairAccommodation).length;

  const { origin, destination, departureDateTime } = outboundRoute;
  const isRoundTrip = !!inboundRoute;

  const promo_code = formValues?.promoCode;

  let ticket: TicketStateModel = {
    trip: isRoundTrip ? 1 : 0,
    from: origin.id,
    to: destination.id,
    start_date: moment(departureDateTime).tz(outboundRoute.origin.timeZone).isBefore(moment.utc(), 'day')
      ? moment.utc().format(dateFormat)
      : moment(departureDateTime).tz(outboundRoute.origin.timeZone).format(dateFormat),
    adults,
  };

  if (kids) ticket.kids = kids;
  if (infants) ticket.infants = infants;
  if (wc_adults) ticket.wc_adults = wc_adults;
  if (wc_children) ticket.wc_children = wc_children;
  if (promo_code) ticket.promo_code = promo_code;

  if (isRoundTrip) {
    const end_date = moment(inboundRoute?.departureDateTime)
      .tz(inboundRoute.origin.timeZone)
      .isBefore(moment.utc(), 'day')
      ? moment.utc().format(dateFormat)
      : moment(inboundRoute?.departureDateTime).tz(inboundRoute.origin.timeZone).format(dateFormat);

    ticket = {
      ...ticket,
      end_date,
    };
  }

  resetGiftCards && resetGiftCards();
  setTpNotApplicableDueToGiftCardPayment && setTpNotApplicableDueToGiftCardPayment(false);

  clickAnalyticsEvent(rootStore, {
    linkClick: {
      pageNameClickTracking: document.title,
      navigationLinkName: labelText,
      customLink: '/train-selection',
      externalLink: '',
      linkType: 'linkClick',
    },
  });
  navigate(selectKioskRoute('/train-selection', '/'), { state: { ticket } });
};
