import axios from 'axios';
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';
import { get } from 'lodash';
import { rootStore } from 'stores';

import { errorEvent } from './adobeDataLayer';
import { stopDatadogSession } from './datadog';
import { clearPublicBookingToken, setLoggedOutFlag, setLoginRedirectURL, setLogoutRedirectURL } from './localstorage';
import { getAuth0ClientId } from './metas';

/**
 * Send analytics error event
 * @param error - Axios error object
 */
const sendAnalyticsErrorEvent = error => {
  const errorMessage = `${get(error, 'message')}`;
  const errorStatusCode = get(error, 'response.status');
  const errorStatusText = get(error, 'response.statusText');
  const eventInfo = {
    error: {
      text: errorMessage,
      code: `${errorStatusCode} ${errorStatusText}`,
      type: 'error',
    },
  };

  errorEvent(rootStore, eventInfo);
};

// If the "cache" parameter exists, clear the Axios Cache
export const verifyCacheBreakerParameterInUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has('cache')) clearTempCachedApi(undefined);
};

/**
 *
 * @param key to clear. if undefined clears all
 */
export const clearTempCachedApi = (key = '') => {
  const keyPrefix = `blte-cache-${key}`;

  for (let i = localStorage.length - 1; i >= 0; i--) {
    const localstorageKey = localStorage.key(i);
    if (localstorageKey && localstorageKey.startsWith(keyPrefix)) localStorage.removeItem(localstorageKey);
  }
};

export const oneDayCachedApi = setupCache(axios.create(), {
  storage: buildWebStorage(localStorage, 'blte-cache-'),
  ttl: 24 * 60 * 60 * 1000,
});

export const fiveMinutesCachedApi = setupCache(axios.create(), {
  storage: buildWebStorage(localStorage, 'blte-cache-'),
  ttl: 5 * 60 * 1000,
});

/**
 * intercept and send analytics info on server errors
 */
oneDayCachedApi.interceptors.response.use(
  response => response,
  error => {
    sendAnalyticsErrorEvent(error);

    return Promise.reject(error);
  }
);

export const api = axios.create({ baseURL: '/' });

api.interceptors.request.use(async config => {
  const { auth0Client } = rootStore.authStore;

  const clientId = getAuth0ClientId();
  const auth0Key = Object.keys(localStorage).find(
    key => key.includes('auth0') && key.includes(clientId) && key.includes('offline_access')
  );

  if (auth0Key) {
    const dataString = localStorage.getItem(auth0Key);
    const dataParsed = dataString ? JSON.parse(dataString) : {};

    let token = '';
    const tokenExpiration = get(dataParsed, 'expiresAt', Number.MAX_VALUE);

    //If token validity is less then 15 minutes - refresh the token
    if (tokenExpiration * 1000 < Date.now() + 900000)
      try {
        token = await auth0Client.getTokenSilently({
          cacheMode: 'off',
          authorizationParams: {
            audience: 'Brightline BFF',
            scope: 'openid profile email offline_access',
          },
        });
      } catch (e) {
        console.error(e);
      }

    if (!token) token = get(dataParsed, 'body.access_token', null);

    if (token) config.headers.Authorization = `Bearer ${token || 'Anonymous'}`;
  } else config.headers.Authorization = 'Bearer Anonymous';

  return config;
}, Promise.reject);

api.interceptors.response.use(
  response => response,
  error => {
    const { auth0Client, isAuthenticated } = rootStore.authStore;

    sendAnalyticsErrorEvent(error);

    if (window.location.pathname.startsWith('/booking')) setLogoutRedirectURL(window.location.href);

    if (get(error, 'response.status') == 401 && isAuthenticated) {
      setLoginRedirectURL(window.location.href);
      setLoggedOutFlag();
      stopDatadogSession();
      clearPublicBookingToken();
      auth0Client.logout({ logoutParams: { returnTo: `${window.location.origin}/account` } });
    }

    return Promise.reject(error);
  }
);

export const publicApi = axios.create({ baseURL: '/' });

/**
 * intercept and send analytics info on server errors
 */
publicApi.interceptors.response.use(
  response => response,
  error => {
    sendAnalyticsErrorEvent(error);

    return Promise.reject(error);
  }
);
