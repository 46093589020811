import React from 'react';

import TeaserV2 from '@molecules/TeaserV2/TeaserV2';
import { TeaserV2Props } from '@molecules/TeaserV2/types';

import { cn } from 'utils/classNames';

import { MobileItemsProps } from '../types';
import useCarousel from '../useCarousel';

const bem = cn('teasers-list');

const MobileItems = ({ items = [], variant }: MobileItemsProps) => {
  const { getRefFromHandlers, handlers } = useCarousel(items, variant);

  return (
    <ul className={bem('items')} {...handlers} ref={getRefFromHandlers} role="list">
      {items.map((item: TeaserV2Props, index: number) => {
        const key = `item-${item.id ? item.id : index}`;

        return <TeaserV2 key={key} {...item} />;
      })}
    </ul>
  );
};

export default MobileItems;
