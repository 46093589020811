import React from 'react';

import { cn } from 'utils/classNames';

import { PastTripsListProps } from './types';

const bem = cn('trips-list-past');

export const PastTripsListPlaceholder = (props: PastTripsListProps) => {
  const { variant } = props;
  const group = (
    <div className={bem('placeholders-item')}>
      <div />
      <div />
    </div>
  );

  return (
    <div className={bem('placeholders')}>
      <div className={bem('placeholders-title', { variant })} />
      {group}
      {group}
      {group}
      {group}
    </div>
  );
};
