import { useEffect, useState } from 'react';
import { useStores } from 'stores';
import { WalletPassItem } from 'stores/AccountStore/sections';

import { countries } from '@molecules/PaymentForm/countryAndStates';
import { useValidationSchema } from '@organisms/ParkingPasses/useValidationSchema';

export const useEditPass = (props: WalletPassItem) => {
  const { cfStore, accountStore } = useStores();
  const { updateParkingPass, setPassToUpdate } = accountStore.wallet;
  const { account } = cfStore;
  const { wallet } = account;
  const { station, licensePlateInformation, ticketNumber } = props;

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [initialFormValues, setInitialFormValues] = useState({
    ppStationName: '',
    ppLicenseState: 'Florida',
    ppLicenseNumber: '',
  });

  const stateOptions =
    countries
      .find(country => country.country == 'United States')
      .states?.map(state => ({
        label: state,
        value: state,
      })) || [];

  const validationSchema = useValidationSchema({
    licensePlateTextFieldInvalidLabel: wallet.editParkingPass.licensePlateTextFieldInvalidLabel,
  });

  useEffect(() => {
    setInitialFormValues({
      ppStationName: station?.name || '',
      ppLicenseState: licensePlateInformation?.state || 'Florida',
      ppLicenseNumber: licensePlateInformation?.number || '',
    });
  }, [licensePlateInformation, station]);

  const onSubmit = async values => {
    if (
      values.ppLicenseState !== initialFormValues.ppLicenseState ||
      values.ppLicenseNumber !== initialFormValues.ppLicenseNumber
    ) {
      setIsLoading(true);
      setHasError(false);

      const body = {
        licensePlate: {
          number: values.ppLicenseNumber,
          state: values.ppLicenseState,
        },
      };

      setPassToUpdate(props);

      const updateOutcome = await updateParkingPass(ticketNumber || '', body, () => {
        setIsLoading(false);
        hideModal();
        setPassToUpdate(null);
      });

      if (!updateOutcome) {
        setHasError(true);
        setIsLoading(false);
      }

      setPassToUpdate(null);
    }

    setIsLoading(false);
  };

  const hideModal = () => {
    setEditModalVisible(false);
    setHasError(false);
    setIsLoading(false);
  };

  const showModal = () => {
    setEditModalVisible(true);
  };

  return {
    editModalVisible,
    hideModal,
    showModal,
    initialFormValues,
    onSubmit,
    validationSchema,
    wallet,
    stateOptions,
    isLoading,
    hasError,
  };
};
