import React from 'react';

import { WidgetInput } from '@atoms/WidgetInput/WidgetInput';

import { cn } from 'utils/classNames';
import { useInputLogic } from 'utils/useInputLogic';

const bem = cn('widget-input');

const TextInputField = props => {
  const { name, allUppercase, isRequired = false } = props;

  const { helpers, field, isInvalid, error } = useInputLogic({ ...props, name });
  const { setValue, setTouched } = helpers;

  const handleInputChange = e => {
    setTouched(true);
    setValue(e.target.value, true);
  };

  const handleClearPress = () => {
    setValue('', true);
  };

  return (
    <div className={bem('wrapper')}>
      <WidgetInput
        {...props}
        {...field}
        onClearClick={handleClearPress}
        onInputChange={handleInputChange}
        name={name}
        value={allUppercase ? (field?.value || '').toUpperCase() : field?.value}
        isInvalid={isInvalid}
        errorMessage={error}
        isRequired={isRequired}
      />
    </div>
  );
};

export default TextInputField;
