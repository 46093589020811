import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { PromoFlag } from '../../../../PromoFlag/PromoFlag';
import { PriceItemProps, TagProps } from '../../../types';
import { TravelPassEligible } from '../../TravelPassEligible';

const bem = cn('price-item-desktop');

export const DesktopLayout = observer((props: PriceItemProps) => {
  const { id = '', tags, product, numberOfSeatsLeftAtPrice } = props;
  const { defaultProduct, journey } = props;
  const { displayedPrice, seatingClassType, fares } = product;
  const { cfStore, bookingStore } = useStores();
  const { modifyTripProps, fareSelectionTable } = bookingStore.tickets;
  const { trainSelection, generic } = cfStore;
  const { routeItem } = trainSelection;

  const isPremiumSelected =
    fareSelectionTable &&
    fareSelectionTable?.journeyID === journey?.id &&
    fareSelectionTable?.defaultProduct.productId === product.id &&
    product.id === 'PREMIUM_STANDARD';

  const hidePassEligible = fareSelectionTable && fareSelectionTable?.journeyID === journey?.id;

  const seatsMessage = useMemo(
    () => (numberOfSeatsLeftAtPrice && numberOfSeatsLeftAtPrice > 1 ? routeItem.seatsLeft : routeItem.seatLeft),
    [numberOfSeatsLeftAtPrice, routeItem.seatLeft, routeItem.seatsLeft]
  );

  const priceLabels = useMemo(() => {
    if (modifyTripProps) {
      const pricePrefix = (displayedPrice || 0) < 0 ? '-' : '+';

      return {
        priceLabel: `${pricePrefix}$${Math.abs(displayedPrice || 0).toFixed(2)}`,
      };
    }

    return {
      priceLabel: `$${displayedPrice?.toFixed(2)}`,
      originalPriceLabel: `$${fares?.[0]?.originalPrice?.toFixed(2)}`,
      hasOriginalPrice: displayedPrice !== fares?.[0]?.originalPrice,
    };
  }, [modifyTripProps, displayedPrice, fares]);

  if (!defaultProduct?.productId)
    return (
      <div className={bem({ unavailable: !defaultProduct?.productId })}>
        <div className={bem('no-seats')}>{defaultProduct?.unavailableMessage}</div>
      </div>
    );

  return (
    <div id={id} className={bem({ class: seatingClassType })}>
      <div className={bem('priceWrapper')}>
        {generic.fromLabelDesktopView && (
          <h4 className={bem('fromLabel', { isPremiumSelected })}>{generic.fromLabelDesktopView}</h4>
        )}
        <div className={bem('price')}>
          <h3 className={bem('displayedPrice', { isPremiumSelected })}>{priceLabels?.priceLabel}</h3>
          {priceLabels?.hasOriginalPrice && (
            <div className={bem('originalPrice', { isPremiumSelected })}>{priceLabels?.originalPriceLabel}</div>
          )}
          <h4 className={bem('person', { isPremiumSelected })}>{generic.perPassengerShort}</h4>
        </div>
      </div>

      <div className={bem('bottom')}>
        {!hidePassEligible && <TravelPassEligible {...props} />}

        <div className={bem('tags')}>
          {tags?.map((tag: TagProps, index: number) => {
            const key = `price-item-tag-${tag.type}-${index}`;
            const type = tag?.type?.replace(/ +/g, '');

            return <PromoFlag key={key} text={tag?.description} tooltipText="" type={type} />;
          })}
        </div>
        {!!numberOfSeatsLeftAtPrice && numberOfSeatsLeftAtPrice > 0 && (
          <div className={bem('seats')}>
            {numberOfSeatsLeftAtPrice} {seatsMessage}
          </div>
        )}
      </div>
    </div>
  );
});
