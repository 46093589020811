import { useMemo } from 'react';
import * as Yup from 'yup';
import { default as dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateConfig } from './constants';

export const useValidationSchema = data =>
  useMemo(() => {
    const postalCodeRegex = /^[a-zA-Z0-9]+$/;
    const alphabeticWithSpecialCharactersRegex = /^[\p{L}\s.'-]+$/u;

    return Yup.object().shape({
      firstName: Yup.string()
        .matches(alphabeticWithSpecialCharactersRegex, data.invalidInputLabel),
      lastName: Yup.string()
        .matches(alphabeticWithSpecialCharactersRegex, data.invalidInputLabel),
      email: Yup.string().email(data.invalidInputLabel).required(data.requiredInputLabel),
      postalCode: Yup.string().matches(postalCodeRegex, data.invalidInputLabel),
      dateOfBirth: Yup.date()
        .transform((value, originalValue) => {
          dayjs.extend(customParseFormat);
          const result = dayjs(originalValue, dateConfig.dateFormat, true);

          return result.toDate();
        })
        .typeError(data.invalidInputLabel)
        .min(dateConfig.minDate, data.invalidInputLabel)
        .max(dateConfig.maxDate, data.invalidInputLabel),
    });
  }, [data]);
