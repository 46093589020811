import { useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { MenuItemProps } from '@atoms/MenuItem/types';
import { HookProps, SelectProps } from '@atoms/Select/types';

export const useSelectHandlers = (props: SelectProps): HookProps => {
  const { optionsData = [], onChange, disabled, setValue, customOnChange } = props;
  const [displayList, setListVisibility] = useState(false);
  const [accessibilityFocus, setAccessibilityFocus] = useState(false);

  const [focus, setFocus] = useState({
    index: 0,
    preventScroll: false,
  });

  const headerRef = useRef<HTMLInputElement>(null);

  const handleHeaderClick = e => {
    e.preventDefault();
    headerRef?.current?.focus();
    if (!disabled) setListVisibility(!displayList);
  };

  const handleItemClick = (e, item: MenuItemProps, itemIndex: number) => {
    e.preventDefault();
    if (item?.disabled) return;

    setFocus({ index: itemIndex, preventScroll: false });

    if (setValue) setValue(item?.value);
    if (onChange) onChange(item?.value);
    if (customOnChange) customOnChange(item?.value);

    setListVisibility(false);
    headerRef?.current?.focus();
  };

  const handleClickOutside = e => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.find(el => el === headerRef.current)) setListVisibility(false);
    setAccessibilityFocus(false);
  };

  const handleChangeNativeSelect = e => {
    // setValue is used to detect if the select is used with formik or not
    if (onChange && setValue) onChange(e);
    else if (onChange) onChange(e.target.value);

    headerRef?.current?.focus();
  };

  const handleKeyDown = e => {
    if (!isMobileOnly)
      switch (e.key) {
        case 'Enter':
          if (!displayList) setListVisibility(true);
          else if (focus.index > -1)
            handleItemClick(
              e,
              {
                value: optionsData?.find((item, index) => focus.index === index)?.value,
                disabled: optionsData?.find((item, index) => focus.index === index)?.disabled,
              },
              focus.index
            );

          e.preventDefault();
          e.stopPropagation();
          break;
        case 'ArrowDown':
          if (!displayList) setListVisibility(true);

          if (optionsData && optionsData[focus.index + 1]) setFocus({ index: focus.index + 1, preventScroll: false });
          e.preventDefault();
          e.stopPropagation();
          break;
        case 'ArrowUp':
          if (optionsData && optionsData[focus.index - 1]) setFocus({ index: focus.index - 1, preventScroll: false });
          e.preventDefault();
          e.stopPropagation();
          break;
        case 'Escape':
          setListVisibility(false);
          if (!displayList) setAccessibilityFocus(false);
          break;
        case 'Tab':
          if (displayList) {
            setAccessibilityFocus(true);
            e.preventDefault();
          } else setAccessibilityFocus(false);

          break;
      }
  };

  return {
    focus,
    setFocus,
    optionsData,
    headerRef,
    handleHeaderClick,
    handleItemClick,
    handleClickOutside,
    handleChangeNativeSelect,
    handleKeyDown,
    displayList,
    accessibilityFocus,
    setAccessibilityFocus,
  };
};
