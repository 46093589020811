import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { InfoTilesContainer } from '@account/components/InfoTilesContainer';
import { MyAccount } from '@account/components/MyAccount';
import { ProfileV2 } from '@account/components/Profile/v2';
import { Rewards } from '@account/components/Rewards';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import { UpcomingTripsCard } from '../components/Cards';
import { DashboardWallet } from '../components/DashboardWallet';
import { PassengersCard } from '../components/PassengersCard';
import { PaymentTokensCard } from '../components/PaymentsCard';
import './DashboardV2.scss';
import { useDashboardLogic } from './useDashboardLogic';

const bem = cn('account-dashboardv2');
const DashboardV2 = observer(() => {
  const { cfStore, accountStore } = useStores();
  const { dashboard } = cfStore.account;
  const { displayLoyaltyComponents } = accountStore.profile;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const { merchandisingXfForDesktopHTML, merchandisingXfForMobileHTML } = useDashboardLogic();

  const merchandisingContent = isMobile ? merchandisingXfForMobileHTML : merchandisingXfForDesktopHTML;

  return (
    <div className={bem()}>
      {isMobile && (
        <div className={bem('user-account')}>
          <MyAccount />
        </div>
      )}

      <div className={bem('user-and-rewards')}>
        <ProfileV2 />
        {displayLoyaltyComponents && <Rewards />}
      </div>

      <div className={bem('row')}>
        <div className={bem('column-single')}>
          <UpcomingTripsCard />
        </div>
      </div>

      <div className={bem('row')}>
        <div className={bem('column')}>
          <DashboardWallet />
        </div>

        <div className={bem('column')}>
          <div className={bem('row')}>
            <div className={bem('column-single')}>
              <PaymentTokensCard />
            </div>
          </div>
          <div className={bem('row')}>
            <div className={bem('column-single')}>
              <PassengersCard />
            </div>
          </div>
        </div>
      </div>

      {merchandisingContent && displayLoyaltyComponents && (
        <div dangerouslySetInnerHTML={{ __html: merchandisingContent }} className={bem('merchandising')} />
      )}

      <InfoTilesContainer {...dashboard} />
    </div>
  );
});

export default DashboardV2;
