import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Image from '@atoms/Image/Image';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';

import '../CardsList.scss';
import { CardProps } from '../types';

const bem = cn('cardslist');

const Card = (props: CardProps) => {
  const {
    showSideLine,
    index,
    title,
    titleType,
    imageAspectRatio = '4/3',
    titleAlignment,
    titleColor,
    description,
    descriptionAlignment,
    descriptionColor,
    image,
    imageLink,
    primaryButton,
    secondaryButton,
    showPrimaryButton,
    showSecondaryButton,
    openPrimaryCtaInNewWindow,
    openSecondaryCtaInNewWindow,
  } = props;

  const style: any = useMemo(
    () => ({
      '--card-image-aspect-ratio': imageAspectRatio,
      '--card-title-alignment': titleAlignment,
      '--card-title-color': titleColor,
      '--card-description-alignment': descriptionAlignment,
      '--card-description-color': descriptionColor,
    }),
    [titleAlignment, titleColor, descriptionAlignment, descriptionColor, imageAspectRatio]
  );

  const handleNewWindowClick = () => {
    window.open(imageLink?.linkUrl, '_blank', 'width=800,height=600');
  };

  const handlePrimaryCtaNewWindowClick = () => {
    window.open(primaryButton?.link?.url, '_blank', 'width=800,height=600');
  };

  const handleSecondaryCtaNewWindowClick = () => {
    window.open(secondaryButton?.link?.url, '_blank', 'width=800,height=600');
  };

  return (
    <div style={style} className={bem('card')}>
      {showSideLine && (
        <div className={bem('vertical-line-container')}>
          <div className={bem('vertical-line')} />
          <div className={bem('horizontal-dot-container')}>
            <div className={bem('horizontal-line')} />
            <div className={bem('corner-dot')} />
          </div>
        </div>
      )}
      <div className={cx(bem('card-image', { 'add-margin-mobile': index === 0 }))}>
        {imageLink?.linkUrl ? (
          <Link
            to={imageLink.linkUrl}
            target={imageLink.linkTarget}
            {...(imageLink.openLinkInNewWindow && { onClick: handleNewWindowClick })}
          >
            <Image {...image} />
          </Link>
        ) : (
          <Image {...image} />
        )}
      </div>
      <div className={bem('card-content')}>
        <Text className={bem('card-title')} text={title} as={titleType} />
        <Text className={bem('card-description')} text={description} />
        <div className={bem('card-buttons')}>
          {showPrimaryButton && primaryButton?.label && (
            <LinkButton
              className={bem('card-button')}
              {...primaryButton}
              {...(openPrimaryCtaInNewWindow && { onClick: handlePrimaryCtaNewWindowClick })}
            />
          )}
          {showSecondaryButton && secondaryButton?.label && (
            <LinkButton
              className={bem('card-button', 'secondary')}
              {...secondaryButton}
              {...(openSecondaryCtaInNewWindow && { onClick: handleSecondaryCtaNewWindowClick })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
