import React from 'react';

import { Image } from '@atoms/Image';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './InfoTilesContainer.scss';
import { InfoTilesContainerProps } from './types';

const bem = cn('account-info-tiles-container');

export const InfoTilesContainer = (props: InfoTilesContainerProps) => {
  const {
    title,
    firstContainerImage,
    firstContainerTitle,
    firstContainerDescription,
    secondContainerImage,
    secondContainerTitle,
    secondContainerDescription,
  } = props;

  return (
    <div className={bem()}>
      <Text className={bem('title')} text={title} />
      <div className={bem('row')}>
        <div className={bem('column')}>
          <Image className={bem('tile-image')} {...firstContainerImage} />
          <div className={bem('text-wrapper')}>
            <Text className={bem('tile-title')} text={firstContainerTitle} />
            <Text className={bem('tile-description')} text={firstContainerDescription} />
          </div>
        </div>
        <div className={bem('column')}>
          <Image className={bem('tile-image')} {...secondContainerImage} />
          <div className={bem('text-wrapper')}>
            <Text className={bem('tile-title')} text={secondContainerTitle} />
            <Text className={bem('tile-description')} text={secondContainerDescription} />
          </div>
        </div>
      </div>
    </div>
  );
};
