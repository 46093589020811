import { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { HookProps, SelectHandlerProps } from './types';

export const useSelectHandlers = (props: SelectHandlerProps): HookProps => {
  const { items, onChange, setValue, resetActive } = props;
  const { onHeaderClick, selectIndex, textInput } = props;

  const [focus, setFocus] = useState({
    index: -1,
    preventScroll: false,
  });

  const handleItemClick = item => e => {
    e.preventDefault();
    setFocus({ index: -1, preventScroll: false });
    if (setValue && item.id) setValue(item.id);
    if (onChange && item.id) onChange(item.id);
    resetActive();
    if (selectIndex === 1) onHeaderClick(2);
  };

  const handleKeyDown = e => {
    if (!isMobileOnly)
      switch (e.key) {
        case 'Enter':
          if (focus.index > -1)
            handleItemClick(
              items.filter(item => item.name.toLowerCase().indexOf(textInput.toLowerCase()) > -1)[focus.index]
            )(e);
          break;
        case 'ArrowDown':
          if (items[focus.index + 1]) setFocus({ index: focus.index + 1, preventScroll: false });
          break;
        case 'ArrowUp':
          if (items[focus.index - 1] || focus.index === 0) setFocus({ index: focus.index - 1, preventScroll: false });
          break;
        case 'Escape':
        case 'Tab':
          resetActive();
          break;
      }
  };

  return {
    focus,
    setFocus,
    items,
    handleItemClick,
    handleKeyDown,
  };
};
