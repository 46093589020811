export const CLASSES = {
  xfContainerElement: 'blte-xf-container__element',
  innerWrapper: 'blte-xf-container__inner-wrapper',
  outerWrapper: 'blte-xf-container__outer-wrapper',
  bannerWrapper: 'blte-banners-container',
  alertBanner: 'blte-alert-banner',
  alertBannerSticky: 'blte-alert-banner--sticky',
  alertBannerHidden: 'blte-alert-banner--hidden',
  sticky: 'blte-xf-container__inner-wrapper--is-sticky',
  hidden: 'blte-xf-container__inner-wrapper--is-hidden',
  visible: 'visible',
  xfContainerElementPositionRelative: `blte-xf-container__element--position-relative`,
  xfContainerElementHidden: `blte-xf-container__element--hidden`,
  headerAlertBanner: 'delays-experience-banner',
};

export const SELECTORS = {
  innerWrapper: `.${CLASSES.innerWrapper}`,
  innerWrapperHeader: `.${CLASSES.innerWrapper}--header`,
  outerWrapper: `.${CLASSES.outerWrapper}`,
  bannerWrapper: `.${CLASSES.bannerWrapper}`,
  headerBannerWrapper: `.${CLASSES.innerWrapper}--header .${CLASSES.bannerWrapper}`,
  alertBanner: `.${CLASSES.alertBanner}`,
  alertBannerSticky: `.${CLASSES.alertBannerSticky}`,
  alertBannerHidden: `.${CLASSES.alertBannerHidden}`,
  stickyBanners: `.${CLASSES.innerWrapper}--header .${CLASSES.bannerWrapper} .${CLASSES.alertBannerSticky}`,
  visible: `.${CLASSES.visible}`,
  xfContainerElementPositionRelative: `.${CLASSES.xfContainerElementPositionRelative}`,
  headerAlertBannerId: `#${CLASSES.headerAlertBanner}`,
};

export const THROTTLE_TIME = 60;
