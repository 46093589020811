import { useEffect } from 'react';
import { useStores } from 'stores';

import { getLoginRedirectURL, setLoginRedirectURL } from 'utils/localstorage';
import { getAuth0RedirectURI } from 'utils/metas';

export const useLogin = (forceLogin = false, isPublicPage = false) => {
  const { authStore } = useStores();
  const { auth0Client, isAuthenticated } = authStore;

  useEffect(() => {
    if (!auth0Client || isPublicPage) return;

    const login = () => {
      auth0Client.loginWithRedirect({
        authorizationParams: {
          redirect_uri: getAuth0RedirectURI(),
          ui_locales: document.documentElement.lang,
          data: { is_domain_connection: true },
          audience: 'Brightline BFF',
        },
      });
    };

    // on login page we always call the Auth0 login method in order to let
    // the Auth0 server script to decide what to do next:
    // - redirect to /dashboard web page or
    // - redirect back to mobile native app
    if (forceLogin) login();
    // this custom hook it is also used on private pages like profile, my-trips, etc
    // so if the user is not authenticated, to be redirected to auth0
    else if (!isAuthenticated) {
      if (!getLoginRedirectURL()) setLoginRedirectURL(window.location.href);
      login();
    }
  }, [auth0Client, isAuthenticated, forceLogin, isPublicPage]);
};
