import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './ExitConfirmationModal.scss';
import { ExitConfirmationModalProps } from './types';

const bem = cn('seat-exit-confirmation-modal');

export const ExitConfirmationModal = observer((props: ExitConfirmationModalProps) => {
  const { handleCancel, handleConfirmation } = props;

  const { cfStore } = useStores();

  const {
    rti: { seats: seatsCF },
  } = cfStore;

  return (
    <Modal variant="mini">
      <div className={bem()}>
        <div className={bem('header')}>{seatsCF.discardChangesTitle}</div>
        <div className={bem('description')}>{seatsCF.discardChangesDescription}</div>
        <div className={bem('actions')}>
          <Button label={seatsCF.cancelCtaLabel} variant="secondary" color="charcoal" onClick={handleCancel} />
          <Button
            label={seatsCF.discardChangesCtaLabel}
            variant="primary"
            color="charcoal"
            onClick={handleConfirmation}
          />
        </div>
      </div>
    </Modal>
  );
});
