import moment from 'moment-timezone';

export const getFooterDate = (outbound, inbound) => {
  if (!outbound) return '';

  const startDate = moment(outbound.departureDateTime).tz(outbound.origin.timeZone);

  if (!inbound) return `${startDate.format('MMMM')} ${startDate.format('D')}`;

  const endDate = moment(inbound.arrivalDateTime).tz(inbound.destination.timeZone);

  if (startDate.month() === endDate.month())
    return `${startDate.format('MMMM')} ${startDate.format('D')}-${endDate.format('D')}`;

  return `${startDate.format('MMMM')} ${startDate.format('D')} - ${endDate.format('MMMM')} ${endDate.format('D')}`;
};
