import { useThrottle } from 'hooks/useThrottle';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useSwipeable } from 'react-swipeable';
import { useStores } from 'stores';
import { DateCarouselItem } from 'stores/BookingStore/sections/Tickets/types';

import { mediaQueryList } from 'utils/mediaQueries';

export const useDateCarouselLogic = props => {
  const {
    items = [],
    slidesToShowBreakpoints = {
      mobile: 3,
      tabletLarge: 5,
      desktop: 7,
    },
    colors,
  } = props;

  const [data, setData] = useState(items?.length ? items : Array(7).fill({ loading: true }));
  const [disablePrev, setDisablePrev] = useState(false);
  const [currentTranslateValue, setCurrentTranslateValue] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [activeSlide, setActiveSlide] = useState(3);
  const [slidesToShow, setSlidesToShow] = useState(slidesToShowBreakpoints?.desktop);
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);
  const isTabletLarge = useMediaPredicate(mediaQueryList.tabletLarge);
  const isDesktop = useMediaPredicate(mediaQueryList.desktop);

  const styles: any = useMemo(
    () => ({
      '--header-date-carousel-day-color': colors?.text?.inactive,
      '--header-date-carousel-day-hover-color': colors?.text?.hover,
      '--header-date-carousel-day-active-color': colors?.text?.active,
      '--header-date-carousel-day-color-cheapest': colors?.cheapest?.inactive,
      '--header-date-carousel-day-underline-color': colors?.underline?.inactive,
      '--header-date-carousel-day-border-focus-color': colors?.border?.focus,
      '--header-date-carousel-day-background-color': colors?.background?.inactive,
      '--header-date-carousel-day-background-hover-color': colors?.background?.hover,
      '--header-date-carousel-day-background-active-color': colors?.background?.active,
    }),
    [colors]
  );

  const { bookingStore, ticketsFormStore } = useStores();
  const { searchTickets, outboundTicket, setItemsPerViewPort, itemsPerViewPort, activeDate, setActiveDate } =
    bookingStore.tickets;
  const { searchTicketsModifyTrip, modifyTripProps } = bookingStore.tickets;
  const { formValues } = ticketsFormStore;

  const carousel: any = useRef(null);
  const track: any = useRef(null);
  const prev: any = useRef(null);
  const next: any = useRef(null);

  const halfSlides = useMemo(() => Math.floor(slidesToShow / 2), [slidesToShow]);

  const handleSwipe = swipeProps => {
    const { dir, absX } = swipeProps;

    if (absX > slideWidth)
      if (dir?.toLowerCase() === 'right') {
        // if swipe is to the right, then user want to see previous data, otherwise see next data
        const selectedItem = data[activeSlide - 1];
        // if we have to the left more than half of the slides, go back one step
        handleClickSlide(activeSlide - 1, selectedItem)();
      } else {
        // if to the right we have more that half of slides (last half not selectable), go further one step
        const selectedItem = data[activeSlide + 1];
        handleClickSlide(activeSlide + 1, selectedItem)();
      }
  };

  const handlers = useSwipeable({
    onSwiping: ({ deltaX }) => {
      track.current.style.transform = `translate3d(${currentTranslateValue + deltaX}px, 0, 0)`;
    },
    onSwipedRight: handleSwipe,
    onSwipedLeft: handleSwipe,
    onSwiped: ({ absX }) => {
      if (absX < slideWidth) track.current.style.transform = `translate3d(${currentTranslateValue}px, 0, 0)`;
    },
    trackTouch: true,
    trackMouse: true,
    preventScrollOnSwipe: true,
  });

  const refPassThrough = el => {
    handlers.ref(el);
    // set track ref so we can access it
    track.current = el;
  };

  const handleSlideUpdate = useCallback(
    slidesNumber => {
      const newHalfSlides = Math.floor(slidesNumber / 2);
      if (newHalfSlides > itemsPerViewPort.leftRight)
        if (modifyTripProps) searchTicketsModifyTrip(modifyTripProps);
        else searchTickets(formValues);

      setItemsPerViewPort({
        leftRight: newHalfSlides,
        visibleItems: slidesNumber,
      });
      const newActiveSlide = activeSlide - (halfSlides - newHalfSlides);

      setSlidesToShow(slidesNumber);

      if (items && items.length) {
        const bookingCarouselItems = items.map(item => ({
          disabled: moment(item.date).isBefore(moment(), 'day') || item.disabled || item.price === null,
          date: item.date,
          price: item.price,
          originalPrice: item.originalPrice,
        }));
        setData(bookingCarouselItems);
      }
      const newActiveSlideDate = items[newActiveSlide]?.date;
      const firstSlideDate = items?.length ? items[0].date : moment();
      const beforeDateValue = outboundTicket ? moment(outboundTicket.departureDate) : moment();
      const isBeforeToday = moment(firstSlideDate).isBefore(beforeDateValue, 'day');
      const isBeforeOutBoundArrivalDay =
        (outboundTicket &&
          moment(firstSlideDate).isBefore(moment.utc(outboundTicket?.arrivalTime).subtract(1, 'day'), 'day')) ||
        false;

      setSlideWidth(carousel.current.offsetWidth / slidesNumber);
      setActiveDate(newActiveSlideDate);

      setDisablePrev(isBeforeToday || isBeforeOutBoundArrivalDay);
      setActiveSlide(newActiveSlide);

      const newTranslationX = -(activeSlide - newHalfSlides) * slideWidth;
      track.current.style.transform = `translate3d(${newTranslationX}px, 0, 0)`;
    },
    [
      setActiveDate,
      activeSlide,
      formValues,
      halfSlides,
      items,
      itemsPerViewPort.leftRight,
      outboundTicket,
      modifyTripProps,
      searchTickets,
      searchTicketsModifyTrip,
      setItemsPerViewPort,
      slideWidth,
    ]
  );

  const handleClickSlide = (newSlide: number, slideItem: DateCarouselItem) => async () => {
    if (!slideItem || slideItem.disabled || slideItem.loading) return;

    const selectedDateMoment = moment(slideItem.date);
    const activeDateMoment = moment(activeDate);

    if (selectedDateMoment.isSame(activeDateMoment, 'day')) return;

    const dayToSend = outboundTicket ? { endDate: slideItem.date } : { startDate: slideItem.date };

    if (!modifyTripProps) await searchTickets({ ...formValues, ...dayToSend });
    else await searchTicketsModifyTrip({ ...modifyTripProps, ...dayToSend });

    setActiveDate(slideItem.date);
  };

  const handleNextClick = async () => {
    const activeSlideDate = moment(activeDate);
    const date = activeSlideDate.add(itemsPerViewPort.visibleItems, 'days').format('YYYY-MM-DD');
    const dayToSend = outboundTicket ? { endDate: date } : { startDate: date };
    if (!modifyTripProps) await searchTickets({ ...formValues, ...dayToSend });
    else await searchTicketsModifyTrip({ ...modifyTripProps, ...dayToSend });
  };

  const handlePrevClick = async () => {
    const activeSlideDate = moment(activeDate);
    let date = activeSlideDate.subtract(itemsPerViewPort.visibleItems, 'days');
    if (
      moment(date).isBefore(moment(), 'day') ||
      (outboundTicket && moment(date).isBefore(moment.utc(outboundTicket?.arrivalTime).subtract(1, 'day'), 'day'))
    )
      date = outboundTicket ? moment.utc(outboundTicket?.arrivalTime) : moment();

    const dayToSend = outboundTicket
      ? { endDate: date.format('YYYY-MM-DD') }
      : { startDate: date.format('YYYY-MM-DD') };

    if (!modifyTripProps) await searchTickets({ ...formValues, ...dayToSend });
    else await searchTicketsModifyTrip({ ...modifyTripProps, ...dayToSend });
  };

  const handleResizeCells = () => {
    const newSlideWidth = carousel.current.offsetWidth / slidesToShow;
    setSlideWidth(newSlideWidth);
    const newTranslationX = 0 - (activeSlide - halfSlides) * newSlideWidth;
    setCurrentTranslateValue(newTranslationX);
    track.current.style.transform = `translate3d(${newTranslationX}px, 0, 0)`;
  };

  const handleThrottledResize = useThrottle(handleResizeCells, 60, [
    carousel.current,
    slidesToShow,
    activeSlide,
    halfSlides,
  ]);

  useEffect(() => {
    if (isDesktop) handleSlideUpdate(slidesToShowBreakpoints.desktop);
    else if (isTabletLarge) handleSlideUpdate(slidesToShowBreakpoints.tabletLarge);
    else if (isMobile) handleSlideUpdate(slidesToShowBreakpoints.mobile);
  }, [
    isTabletLarge,
    isMobile,
    isDesktop,
    activeSlide,
    halfSlides,
    items,
    handleSlideUpdate,
    slidesToShowBreakpoints.desktop,
    slidesToShowBreakpoints.tabletLarge,
    slidesToShowBreakpoints.mobile,
  ]);

  useEffect(() => {
    window.addEventListener('resize', handleThrottledResize);

    return () => {
      window.removeEventListener('resize', handleThrottledResize);
    };
  }, [items, slidesToShow, activeSlide, handleThrottledResize]);

  return {
    handleClickSlide,
    handleNextClick,
    handlePrevClick,
    activeSlide,
    prev,
    carousel,
    refPassThrough,
    handlers,
    data,
    slideWidth,
    next,
    styles,
    disablePrev,
  };
};
