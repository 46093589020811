import { makeAutoObservable } from 'mobx';
import { RootStore } from 'stores';

import { cartEvent, constructAnalyticsItems } from 'utils/adobeDataLayer';
import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';

import { ExtrasConfig as ExtrasConfigCF } from './../../../CFStore/types.rti';
import { ExtrasCategory, ExtrasConfig } from './types';

export class Extras {
  private readonly rootStore: RootStore;

  data: ExtrasCategory[] = [];
  loading = false;
  error = false;

  activeModalCF: undefined | ExtrasConfigCF = undefined;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  setExtras(data: ExtrasConfig) {
    this.data = data.categories;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setError(error) {
    this.error = error;
  }

  setActiveModalCF(modalCF?: ExtrasConfigCF) {
    this.activeModalCF = modalCF;
  }

  resetExtras() {
    this.setExtras({
      categories: [],
    });
    this.setActiveModalCF(undefined);
  }

  async fetchExtras(referenceNumber, bookingSession) {
    try {
      this.setLoading(true);
      this.setError(null);
      const url = `${requestUrls.getRestUrl(requestUrls.extras)}.${referenceNumber}.xjson`;

      const response = await api.get(url, {
        headers: {
          'Booking-Session-Access-Token': bookingSession?.accessToken,
          'Booking-Session-Refresh-Token': bookingSession?.refreshToken,
        },
      });
      this.setExtras(response.data);

      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      this.setLoading(false);
    }
  }

  async addBookingExtras(body, type: string, cb?, initialReqBody?) {
    const referenceNumber =
      this.rootStore.bookingStore.rti.provisionalBooking.tripDetails?.trip?.referenceNumber ||
      this.rootStore.accountStore.tripDetails.trip?.referenceNumber;
    const bookingSession =
      this.rootStore.bookingStore.rti.provisionalBooking.bookingSession ||
      this.rootStore.accountStore.tripDetails.bookingSession;

    this.setLoading(true);

    try {
      const url = `${requestUrls.getRestUrl(requestUrls.extras)}.${referenceNumber}.xjson`;

      const response = await api.put(url, body, {
        headers: {
          'Booking-Session-Access-Token': bookingSession?.accessToken,
          'Booking-Session-Refresh-Token': bookingSession?.refreshToken,
        },
      });

      this.rootStore.bookingStore.rti.setProvisionalBooking({
        bookingSession: response.data.bookingSession,
        tripDetails: response.data.tripDetails,
      });

      if (this.rootStore.bookingStore.rti.modifyTripType === 'extras')
        this.rootStore.bookingStore.rti.createApplicablePasses(response.data, true);

      if (this.rootStore.bookingStore.rti.modifyTripType === 'trip')
        this.rootStore.bookingStore.rti.createApplicablePasses(response.data);

      this.setExtras({ categories: [...response.data.extrasCategories] });

      this.rootStore.bookingStore.rti.setIsUpsell(true);

      const analyticsItems = constructAnalyticsItems(initialReqBody.items, body.items);

      if (analyticsItems?.length) cartEvent('updateCart', analyticsItems, response, type);

      // eslint-disable-next-line callback-return
      if (cb) cb(response.data);
    } catch (e: any) {
      console.error(e);
    }

    this.setLoading(false);
  }
}
