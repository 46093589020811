export const parseRteIcons = (rteContent?: string) => {
  if (!rteContent) return '';

  if (!rteContent.startsWith('<p>')) rteContent = `<p>${rteContent}</p>`;

  const regex = /\{([a-zA-Z0-9-_]+)}/g;

  let match: RegExpMatchArray | null;
  const indexes: number[] = [];

  while ((match = regex.exec(rteContent))) match && match.index !== undefined && indexes.push(match.index);

  let indexAdjuster = 0;
  for (let openingTagIndex of indexes) {
    openingTagIndex += indexAdjuster;

    const closingTagIndex = rteContent.indexOf('}', openingTagIndex);

    if (closingTagIndex !== -1) {
      const iconName = rteContent.slice(openingTagIndex + 1, closingTagIndex);

      rteContent = `${rteContent.slice(0, openingTagIndex)}${rteContent.slice(closingTagIndex + 1)}`;

      const htmlTagIndex = rteContent.lastIndexOf('p', openingTagIndex);
      const htmlTagClassName = `class="rte-content-with-icon rte-content-icon__${iconName}"`;

      if (htmlTagIndex !== -1)
        rteContent = `${rteContent.slice(0, htmlTagIndex + 1)} ${htmlTagClassName}${rteContent.slice(
          htmlTagIndex + 1
        )}`;

      indexAdjuster += htmlTagClassName.length - (closingTagIndex - openingTagIndex);
    }
  }

  return rteContent;
};
