import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { PrivacyRequestFormStatusBannerProps } from './../../types';
import './PrivacyRequestFormStatusBanner.scss';

const bem = cn('privacy-request-form-error-banner');

export const PrivacyRequestFormStatusBanner = observer((props: PrivacyRequestFormStatusBannerProps) => {
  const { isSuccess = false, isError, successMessage, errorMessage } = props;

  const bannerRef = useRef<HTMLDivElement>(null);

  const bannerMessage = (isSuccess && successMessage) || (isError && errorMessage) || '';

  useEffect(() => {
    if (isError === isSuccess || !bannerRef.current) return;

    bannerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [isError, isSuccess]);

  if (isError === isSuccess) return null;

  return (
    <div ref={bannerRef} className={bem()}>
      <div className={bem('banner', { error: isError, success: isSuccess })}>
        {isError && <Icon name="warning" className={bem('icon', { error: true })} />}
        {isSuccess && <Icon name="warning" className={bem('icon', { success: true })} />}
        <Text text={bannerMessage} className={bem('text')} />
      </div>
    </div>
  );
});
