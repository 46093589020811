import { useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useStores } from 'stores';

const directions = {
  left: 'left',
  right: 'right',
};

const useCarousel = () => {
  const { bookingStore } = useStores();

  const { seats } = bookingStore.rti;

  const { passengersSeats, setActivePassenger, findActivePassengerIndex } = seats;

  const innerRef: any = useRef<HTMLDivElement>(null);

  const handleSwipe = direction => () => {
    const activePassengerIndex = findActivePassengerIndex();
    if (direction === directions.right) {
      if (activePassengerIndex === 0) return;

      setActivePassenger(passengersSeats[activePassengerIndex - 1]);
    } else {
      if (activePassengerIndex === passengersSeats.length - 1) return;

      setActivePassenger(passengersSeats[activePassengerIndex + 1]);
    }
  };

  const handlers = useSwipeable({
    onSwipedRight: handleSwipe(directions.right),
    onSwipedLeft: handleSwipe(directions.left),
    trackMouse: true,
  });

  const getRefFromHandlers = (el: HTMLDivElement) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set myRef el so you can access it yourself
    innerRef.current = el;
  };

  return {
    innerRef,
    handlers,
    getRefFromHandlers,
  };
};

export default useCarousel;
