import { Form, Formik } from 'formik';
import React from 'react';

import Button from '@atoms/Button/Button';
import { InputField } from '@atoms/Input';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { isAuthorMode, isEditMode } from 'utils/metas';

import './ErrorCodesBrowser.scss';
import { ErrorCodesBrowserProps } from './types';
import { useErrorCodesLogic } from './useErrorCodesLogic';

const bem = cn('error-codes-browser');

export const ErrorCodesBrowser = (props: ErrorCodesBrowserProps) => {
  const errorCodesLogic = useErrorCodesLogic(props);

  const { initialFormValues, filteredErrorCodes, handleSubmit } = errorCodesLogic;
  const { searchTerms, highlightMatch } = errorCodesLogic;

  if (!isAuthorMode()) return null;

  return (
    <div className={bem()}>
      {isEditMode() && <Text text='Please use in "View as Published" mode' className={bem('view-in-publish')} />}
      <Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
        <Form>
          <div className={bem('search-panel')}>
            <InputField name="code" label="Error code" displayTrailingIcon />
            <InputField name="header" label="Error header" displayTrailingIcon />
            <InputField name="body" label="Error body" displayTrailingIcon />
            <Button label="Search" type="submit" variant="primary" color="blue" />
          </div>
        </Form>
      </Formik>
      <div className={bem('results-panel')}>
        <div className={bem('results-header')}>
          <Text text="Error code" />
          <Text text="Error header" />
          <Text text="Error body" />
        </div>
        {filteredErrorCodes.map((errorCode, index) => (
          <div key={index} className={bem('result')}>
            <Text text={highlightMatch(errorCode.errorCode, searchTerms.code)} />
            <Text text={highlightMatch(errorCode.errorHeader, searchTerms.header)} />
            <Text text={highlightMatch(errorCode.errorBody, searchTerms.body)} />
          </div>
        ))}
        {!filteredErrorCodes.length && (
          <Text text="No errors found with the given search criteria" className={bem('no-results')} />
        )}
      </div>
    </div>
  );
};
