import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStores } from 'stores';

import { useCMSContent } from '@account/hooks/useCMSContent';
import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import { ErrorView } from '@account/routes/TripDetails/components/ErrorView';
import { Extras } from '@account/routes/TripDetails/components/Extras';
import { HeaderInfo } from '@account/routes/TripDetails/components/HeaderInfo';
import { Passengers } from '@account/routes/TripDetails/components/Passengers';
import { Trips } from '@account/routes/TripDetails/components/Trips';

import { cn } from 'utils/classNames';
import { scrollToTop } from 'utils/isKiosk';

import './TripDetails.scss';

const bem = cn('trip-details');

export const TripDetails = observer(() => {
  useCMSContent('tripDetails');
  const { accountStore } = useStores();
  const { tripDetails } = accountStore;
  const { fetchTripData, resetTripData, hasError, setError, shouldFetchTripDetails } = tripDetails;
  const { setShouldFetchTripDetails } = tripDetails;
  const { rn, fn, ln } = useTripDetailsConfig();

  useEffect(() => {
    if (!shouldFetchTripDetails) return undefined;

    document.body.classList.remove('hasHiddenSidebar');

    if (rn) fetchTripData(rn, fn, ln);
    else setError(true);

    setShouldFetchTripDetails(false);

    return resetTripData;
  }, [fetchTripData, fn, ln, resetTripData, rn, setError, shouldFetchTripDetails, setShouldFetchTripDetails]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className={bem({ hasError })}>
      {hasError ? (
        <ErrorView />
      ) : (
        <div className={bem('kiosk')}>
          <HeaderInfo kioskMode />
          <Trips />
          <Passengers />
          <Extras />
        </div>
      )}
    </div>
  );
});
