import React, { useMemo } from 'react';

import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';

import './CardsList.scss';
import Card from './component/Card';
import { CardsListProps } from './types';

const bem = cn('cardslist');
const font = cn('font');

const CardsList = (props: CardsListProps) => {
  const {
    cardListDescription,
    cardListDescriptionAlignment,
    cardListTitle,
    cardListTitleAlignment,
    items,
    cardListTitleColor,
    cardListDescriptionColor,
    cardListTitleType,
    cardListTitleFontVariant,
  } = props;

  const style: any = useMemo(
    () => ({
      '--cardslist-title-alignment': cardListTitleAlignment,
      '--cardslist-title-color': cardListTitleColor,
      '--cardslist-description-alignment': cardListDescriptionAlignment,
      '--cardslist-description-color': cardListDescriptionColor,
      '--display-line': items && items?.length < 2 ? 'none' : 'flex',
      '--visibility-last-line': items && items.length > 1 ? 'hidden' : 'visible',
    }),
    [cardListTitleAlignment, cardListDescriptionAlignment, items, cardListDescriptionColor, cardListTitleColor]
  );

  return (
    <div className={bem()} style={style}>
      {cardListTitle && (
        <Text
          className={cx(
            bem('title', { showTitle: !!cardListTitle, addFontSize: !cardListTitleFontVariant }),
            font({ variant: cardListTitleFontVariant })
          )}
          text={cardListTitle}
          as={cardListTitleType}
        />
      )}
      {cardListDescription && <Text className={bem('description')} text={cardListDescription} />}
      {items?.map((item, index) => (
        <Card key={index} {...item} index={index} showSideLine />
      ))}
    </div>
  );
};

export default CardsList;
