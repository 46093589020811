import React from 'react';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { StationLabelProps } from './types';

const bemCard = cn('pass-card');

export const StationLabel = ({ originStation, destinationStation, isRoundTrip, type }: StationLabelProps) => {
  if (type === 'parking') return <span>{originStation?.name}</span>;

  return (
    <>
      <span className={bemCard('station')}>{originStation?.abbreviation}</span>
      <Icon name={isRoundTrip ? 'arrow-double' : 'arrow-from-to'} className={bemCard('trip-icon')} />
      <span className={bemCard('station')}>{destinationStation?.abbreviation}</span>
    </>
  );
};
