import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useStores } from 'stores';

import { Card } from '../Card';
import { CardStates } from '../Card/constants';
import { CardActions } from '../CardActions';
import { stepNames } from '../constants';
import { ExtrasContent } from './ExtrasContent';
import { ExtrasProps } from './types';

export const Extras = observer((props: ExtrasProps) => {
  const { mode, stepNr } = props;
  const { cfStore, bookingStore } = useStores();
  const { extras } = cfStore.rti;
  const { setLockedStep, setActiveStep, activeStep } = bookingStore.rti;
  const ref = useRef<HTMLDivElement>(null);

  const handleEdit = () => {
    setLockedStep(activeStep, true);
    setLockedStep(stepNames.extras, false);
    setActiveStep(stepNames.extras);
  };

  const handleSave = () => {
    //to be removed
  };

  return (
    <Card
      title={extras.cardTitle}
      stepNr={stepNr}
      mode={mode}
      editBtnLabel={cfStore.generic.editLabel}
      onEdit={handleEdit}
    >
      <div ref={ref}>
        <ExtrasContent expanded />
        {mode === CardStates.active && <CardActions saveLabel="" onSave={handleSave} showEdit={false} />}
      </div>
    </Card>
  );
});
