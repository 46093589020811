import moment from 'moment/moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStores } from 'stores';

import { fetchXfHTML } from 'utils/xfUtils';

import { Pagination } from '../../../../stores/AccountStore/sections';
import { FilterItem } from '../../../../stores/CFStore/types.events';
import { EventItemConfig } from './types';

export const useEventsBrowserLogic = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { cfStore } = useStores();
  const { events } = cfStore;
  const {
    weekdaysFilterLabel,
    weekdaysFilterValue,
    weekendsFilterLabel,
    weekendsFilterValue,
    thisWeekFilterLabel,
    thisWeekFilterValue,
    nextWeekFilterLabel,
    nextWeekFilterValue,
    thisMonthFilterLabel,
    thisMonthFilterValue,
    nextMonthFilterLabel,
    nextMonthFilterValue,
  } = events.calendarFilter;

  const activeButtonOptions = useMemo(
    () => [
      { label: weekdaysFilterLabel, value: weekdaysFilterValue },
      { label: weekendsFilterLabel, value: weekendsFilterValue },
      { label: thisWeekFilterLabel, value: thisWeekFilterValue },
      { label: nextWeekFilterLabel, value: nextWeekFilterValue },
      { label: thisMonthFilterLabel, value: thisMonthFilterValue },
      { label: nextMonthFilterLabel, value: nextMonthFilterValue },
    ],
    [
      weekdaysFilterLabel,
      weekdaysFilterValue,
      weekendsFilterLabel,
      weekendsFilterValue,
      thisWeekFilterLabel,
      thisWeekFilterValue,
      nextWeekFilterLabel,
      nextWeekFilterValue,
      thisMonthFilterLabel,
      thisMonthFilterValue,
      nextMonthFilterLabel,
      nextMonthFilterValue,
    ]
  );

  const [carouselHTML, setCarouselHTML] = useState('');
  const [titleSubtitleHTML, setTitleSubtitleHTML] = useState('');
  const [newsletterHTML, setNewsletterHTML] = useState('');
  const [offersCardsHTML, setOffersCardsHTML] = useState('');
  const [topExperienceFragmentHTML, setTopExperienceFragmentHTML] = useState('');
  const [bottomExperienceFragmentHTML, setBottomExperienceFragmentHTML] = useState('');

  const [eventsLoading, setEventsLoading] = useState(true);
  const [filteredEvents, setFilteredEvents] = useState<EventItemConfig[]>([]);
  const [paginatedEvents, setPaginatedEvents] = useState<EventItemConfig[]>([]);

  const [selectedFilters, setSelectedFilters] = useState(['All']);
  const [selectedStations, setSelectedStations] = useState(['All']);

  const [cityPopoverOpen, setCityPopoverOpen] = useState(false);

  const [pagination, setPagination] = useState<Pagination>({
    total: 0,
    itemsPerPage: 0,
    pageCount: 0,
    pageNumber: 1,
  });

  const [urlParamsLoaded, setUrlParamsLoaded] = useState(false);

  const eventsWrapperRef = useRef<HTMLDivElement>(null);

  const [datePopoverOpen, setDatePopoverOpen] = useState(false);
  const [datesRestrictionsData, setRestrictionsData] = useState({ latestDate: '' });
  const [calendarStartDate, setCalendarStartDate] = useState<moment.Moment | ''>('');
  const [calendarEndDate, setCalendarEndDate] = useState<moment.Moment | ''>('');
  const [activeButtonFilter, setActiveButtonFilter] = useState({
    value: '',
    label: '',
  });
  const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(null);

  const formatDateRange = (startDate, endDate) => {
    const formattedStart = startDate ? startDate.format('MMMM D') : '';
    const formattedEnd = endDate ? endDate.format('MMMM D') : '';

    return `${formattedStart} - ${formattedEnd}`;
  };

  const getActiveButtonOption = useCallback(
    date =>
      date === 'All'
        ? { value: '', label: '' }
        : activeButtonOptions.find(option => option.value === date) || { value: '', label: '' },
    [activeButtonOptions]
  );

  const filterByWeekdays = (startDate: moment.Moment, endDate: moment.Moment) => {
    const isWeekday = (date: moment.Moment) => date.isoWeekday() <= 5;

    return startDate.week() === endDate.week() && isWeekday(startDate) && isWeekday(endDate);
  };

  const filterByWeekends = (startDate: moment.Moment, endDate: moment.Moment) => {
    const isWeekend = (date: moment.Moment) => date.isoWeekday() >= 6;

    return startDate.week() === endDate.week() && isWeekend(startDate) && isWeekend(endDate);
  };

  const filterByThisWeek = (startDate: moment.Moment, endDate: moment.Moment) => {
    const currentWeekStart = moment().startOf('isoWeek');
    const currentWeekEnd = moment().endOf('isoWeek');

    return (
      startDate.isBetween(currentWeekStart, currentWeekEnd, null, '[]') &&
      endDate.isBetween(currentWeekStart, currentWeekEnd, null, '[]') &&
      startDate.isSameOrAfter(currentWeekStart) &&
      endDate.isSameOrBefore(currentWeekEnd)
    );
  };

  const filterByNextWeek = (startDate: moment.Moment, endDate: moment.Moment) => {
    const nextWeekStart = moment().add(1, 'week').startOf('isoWeek');
    const nextWeekEnd = moment().add(1, 'week').endOf('isoWeek');

    return (
      startDate.isBetween(nextWeekStart, nextWeekEnd, null, '[]') &&
      endDate.isBetween(nextWeekStart, nextWeekEnd, null, '[]') &&
      startDate.isSameOrAfter(nextWeekStart) &&
      endDate.isSameOrBefore(nextWeekStart)
    );
  };

  const filterByThisMonth = (startDate: moment.Moment, endDate: moment.Moment) => {
    const currentMonthStart = moment().startOf('month');
    const currentMonthEnd = moment().endOf('month');

    return (
      startDate.isBetween(currentMonthStart, currentMonthEnd, null, '[]') &&
      endDate.isBetween(currentMonthStart, currentMonthEnd, null, '[]') &&
      startDate.isSameOrAfter(currentMonthStart) &&
      endDate.isSameOrBefore(currentMonthEnd)
    );
  };

  const filterByNextMonth = (startDate: moment.Moment, endDate: moment.Moment) => {
    const nextMonthStart = moment().add(1, 'month').startOf('month');
    const nextMonthEnd = moment().add(1, 'month').endOf('month');

    return (
      startDate.isBetween(nextMonthStart, nextMonthEnd, null, '[]') &&
      endDate.isBetween(nextMonthStart, nextMonthEnd, null, '[]') &&
      startDate.isSameOrAfter(nextMonthStart) &&
      endDate.isSameOrBefore(nextMonthEnd)
    );
  };

  const isEventWithinRange = (
    startDate: moment.Moment,
    endDate: moment.Moment,
    rangeStart: moment.Moment,
    rangeEnd: moment.Moment
  ) =>
    startDate.isBetween(rangeStart, rangeEnd, null, '[]') &&
    endDate.isBetween(rangeStart, rangeEnd, null, '[]') &&
    startDate.isSameOrAfter(rangeStart) &&
    endDate.isSameOrBefore(rangeEnd);

  useEffect(() => {
    if (urlParamsLoaded) return;

    const searchParams = new URLSearchParams(location.search);

    const filters: string[] = [];
    const stations: string[] = [];
    let date = '';
    let startDate = '';
    let endDate = '';

    searchParams.forEach((val, key) => {
      if (key.startsWith('filter')) filters.push(val);
      else if (key.startsWith('station')) stations.push(val);
      else if (key.startsWith('calendarFilter')) date = val;
      else if (key.startsWith('startDate')) startDate = val;
      else if (key.startsWith('endDate')) endDate = val;
    });

    setSelectedFilters(filters.length ? filters : ['All']);
    setSelectedStations(stations.length ? stations : ['All']);
    setActiveButtonFilter(getActiveButtonOption(date));
    setCalendarStartDate(startDate.length > 0 ? moment(startDate, 'YYYY-MM-DD') : '');
    setSelectedStartDate(startDate.length > 0 ? moment(startDate, 'YYYY-MM-DD') : '');
    setCalendarEndDate(endDate.length > 0 ? moment(endDate, 'YYYY-MM-DD') : '');
    setSelectedEndDate(endDate.length > 0 ? moment(endDate, 'YYYY-MM-DD') : '');
    setPagination(val => ({
      ...val,
      pageNumber: Number(searchParams.get('page')) || 1,
    }));

    setUrlParamsLoaded(true);

    const currentTime = new Date();
    const latestDate = new Date(currentTime.setFullYear(currentTime.getFullYear() + 1));
    latestDate.setHours(4, 0, 0, 0);
    setRestrictionsData({ latestDate: latestDate.toISOString() });
  }, [location.search, urlParamsLoaded, getActiveButtonOption]);

  useEffect(() => {
    if (!urlParamsLoaded) return;

    const searchParams = new URLSearchParams();

    selectedFilters.forEach((filterID, idx) => {
      searchParams.set(`filter.${idx}`, filterID);
    });

    selectedStations.forEach((stationID, idx) => {
      searchParams.set(`station.${idx}`, stationID);
    });

    if (activeButtonFilter.value) searchParams.set('calendarFilter', activeButtonFilter.value);
    else if (calendarStartDate && calendarEndDate) {
      searchParams.set('startDate', calendarStartDate.format('YYYY-MM-DD'));
      searchParams.set('endDate', calendarEndDate.format('YYYY-MM-DD'));
    } else searchParams.set('calendarFilter', 'All');

    searchParams.set('page', pagination.pageNumber.toString());

    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }, [
    selectedFilters,
    selectedStations,
    pagination.pageNumber,
    navigate,
    location.pathname,
    urlParamsLoaded,
    activeButtonFilter,
    calendarStartDate,
    calendarEndDate,
  ]);

  const unfilteredEvents = useMemo(
    () =>
      JSON.parse(
        JSON.stringify(
          events.eventItems.map((ev: EventItemConfig) => {
            ev.categories = [];
            ev.allFilter && ev.categories.push('All');
            ev.artsAndCultureFilter && ev.categories.push('ArtsAndCulture');
            ev.beachesFilter && ev.categories.push('Beaches');
            ev.festivalsAndExperiencesFilter && ev.categories.push('FestivalsAndExperiences');
            ev.freeThingsToDoFilter && ev.categories.push('FreeThingsToDo');
            ev.rooftopsAndBrunchFilter && ev.categories.push('RooftopsAndBrunch');
            ev.sportsAndConcertsAndShowsFilter && ev.categories.push('SportsAndConcertsAndShows');
            ev.themeParksAndAttractionsFilter && ev.categories.push('ThemeParksAndAttractions');

            return ev as EventItemConfig;
          })
        )
      ),
    [events.eventItems]
  );

  useEffect(() => {
    if (!filteredEvents) return;

    setPagination(val => ({
      total: filteredEvents.length,
      itemsPerPage: Number(cfStore.events.itemsPerPage || 0),
      pageCount: Math.ceil(filteredEvents.length / Number(cfStore.events.itemsPerPage || 0)),
      pageNumber: val.pageNumber,
    }));
  }, [cfStore.events.itemsPerPage, filteredEvents]);

  // Fetch SPA XFs
  useEffect(() => {
    const fetchXfs = async () => {
      events.carousel && setCarouselHTML(await fetchXfHTML(events.carousel));
      events.titleAndSubtitle && setTitleSubtitleHTML(await fetchXfHTML(events.titleAndSubtitle));
      events.newsletterForm && setNewsletterHTML(await fetchXfHTML(events.newsletterForm));
      events.offersCards && setOffersCardsHTML(await fetchXfHTML(events.offersCards));
      events.topExperienceFragment && setTopExperienceFragmentHTML(await fetchXfHTML(events.topExperienceFragment));
      events.bottomExperienceFragment &&
        setBottomExperienceFragmentHTML(await fetchXfHTML(events.bottomExperienceFragment));
    };

    fetchXfs();
  }, [
    events.carousel,
    events.newsletterForm,
    events.titleAndSubtitle,
    events.offersCards,
    events.topExperienceFragment,
    events.bottomExperienceFragment,
  ]);

  // Filter events
  useEffect(() => {
    try {
      setEventsLoading(true);

      const filteredEvents = unfilteredEvents
        .filter((ev: EventItemConfig) => {
          if (ev.eventDateAndTime) {
            const evTimestamp = moment({
              year: ev.eventEndDate.year,
              month: ev.eventEndDate.month,
              day: ev.eventEndDate.dayOfMonth,
              hour: ev.eventEndDate.hourOfDay,
              minute: ev.eventEndDate.minute,
              second: ev.eventEndDate.second,
            }).tz('America/New_York');

            // Discard past events
            if (moment().tz('America/New_York').isAfter(evTimestamp)) return false;
          }

          //if we are are using dateFiltering, remove events that don't have DateAndTime
          if (activeButtonFilter.value || calendarStartDate || calendarEndDate)
            if (!ev.eventDateAndTime || !ev.eventEndDate) return false;

          const startDate = moment({
            year: ev.eventDateAndTime.year,
            month: ev.eventDateAndTime.month,
            day: ev.eventDateAndTime.dayOfMonth,
            hour: ev.eventDateAndTime.hourOfDay,
            minute: ev.eventDateAndTime.minute,
            second: ev.eventDateAndTime.second,
          }).tz('America/New_York');

          const endDate = ev.eventEndDate
            ? moment({
                year: ev.eventEndDate.year,
                month: ev.eventEndDate.month,
                day: ev.eventEndDate.dayOfMonth,
                hour: ev.eventEndDate.hourOfDay,
                minute: ev.eventEndDate.minute,
                second: ev.eventEndDate.second,
              }).tz('America/New_York')
            : startDate;

          if (activeButtonFilter.value) {
            if (activeButtonFilter.value === 'Weekdays' && !filterByWeekdays(startDate, endDate)) return false;
            if (activeButtonFilter.value === 'Weekends' && !filterByWeekends(startDate, endDate)) return false;
            if (activeButtonFilter.value === 'This-Week' && !filterByThisWeek(startDate, endDate)) return false;
            if (activeButtonFilter.value === 'Next-Week' && !filterByNextWeek(startDate, endDate)) return false;
            if (activeButtonFilter.value === 'This-Month' && !filterByThisMonth(startDate, endDate)) return false;
            if (activeButtonFilter.value === 'Next-Month' && !filterByNextMonth(startDate, endDate)) return false;
          }

          if (!activeButtonFilter.value && calendarStartDate && calendarEndDate)
            if (!isEventWithinRange(startDate, endDate, calendarStartDate, calendarEndDate)) return false;

          // Discard events not matching category
          if (!selectedFilters.includes('All') && !ev.categories?.some(cat => selectedFilters.includes(cat)))
            return false;

          // Discard events not matching station
          return !(!selectedStations.includes('All') && !selectedStations.includes(ev.stationId));
        })
        ?.sort((a: EventItemConfig, b: EventItemConfig) => {
          if (!a.eventDateAndTime || !b.eventDateAndTime) return 1;

          const aEventTimestamp = moment({
            year: a.eventDateAndTime.year,
            month: a.eventDateAndTime.month,
            day: a.eventDateAndTime.dayOfMonth,
            hour: a.eventDateAndTime.hourOfDay,
            minute: a.eventDateAndTime.minute,
            second: a.eventDateAndTime.second,
          }).tz('America/New_York');
          const bEventTimestamp = moment({
            year: b.eventDateAndTime.year,
            month: b.eventDateAndTime.month,
            day: b.eventDateAndTime.dayOfMonth,
            hour: b.eventDateAndTime.hourOfDay,
            minute: b.eventDateAndTime.minute,
            second: b.eventDateAndTime.second,
          }).tz('America/New_York');

          return aEventTimestamp.valueOf() - bEventTimestamp.valueOf();
        });

      setFilteredEvents(filteredEvents);
      setPaginatedEvents(
        filteredEvents?.slice(
          (pagination.pageNumber - 1) * pagination.itemsPerPage,
          pagination.pageNumber * pagination.itemsPerPage
        )
      );
    } catch (e) {
      console.error(e);
    }

    setTimeout(() => setEventsLoading(false), 750);
  }, [
    pagination.itemsPerPage,
    pagination.pageNumber,
    selectedFilters,
    selectedStations,
    unfilteredEvents,
    activeButtonFilter,
    calendarStartDate,
    calendarEndDate,
  ]);

  const handleFilterClicked = (filterItem: FilterItem) => {
    let filterCategory = '';

    if (!filterItem) filterCategory = 'All';
    else if (filterItem.allFilter) filterCategory = 'All';
    else if (filterItem.artsAndCultureFilter) filterCategory = 'ArtsAndCulture';
    else if (filterItem.beachesFilter) filterCategory = 'Beaches';
    else if (filterItem.festivalsAndExperiencesFilter) filterCategory = 'FestivalsAndExperiences';
    else if (filterItem.freeThingsToDoFilter) filterCategory = 'FreeThingsToDo';
    else if (filterItem.rooftopsAndBrunchFilter) filterCategory = 'RooftopsAndBrunch';
    else if (filterItem.sportsAndConcertsAndShowsFilter) filterCategory = 'SportsAndConcertsAndShows';
    else if (filterItem.themeParksAndAttractionsFilter) filterCategory = 'ThemeParksAndAttractions';

    if (!filterCategory) return;

    setSelectedFilters(val => {
      if (val.includes(filterCategory)) {
        if (filterCategory !== 'All' || val.length !== 1) val = val.filter(el => el !== filterCategory);
        if (!val.length) val.push('All');
      } else if (filterCategory !== 'All') {
        val = val.filter(el => el !== 'All');
        val.push(filterCategory);
      } else val = ['All'];

      return [...val];
    });

    resetPagination();
  };

  const isFilterSelected = (filterItem: FilterItem) => {
    if (filterItem.allFilter) return selectedFilters.includes('All');
    else if (filterItem.artsAndCultureFilter) return selectedFilters.includes('ArtsAndCulture');
    else if (filterItem.beachesFilter) return selectedFilters.includes('Beaches');
    else if (filterItem.festivalsAndExperiencesFilter) return selectedFilters.includes('FestivalsAndExperiences');
    else if (filterItem.freeThingsToDoFilter) return selectedFilters.includes('FreeThingsToDo');
    else if (filterItem.rooftopsAndBrunchFilter) return selectedFilters.includes('RooftopsAndBrunch');
    else if (filterItem.sportsAndConcertsAndShowsFilter) return selectedFilters.includes('SportsAndConcertsAndShows');
    else if (filterItem.themeParksAndAttractionsFilter) return selectedFilters.includes('ThemeParksAndAttractions');

    return false;
  };

  const getFilterItem = (filterID: string) => {
    if (filterID === 'All') return events.filterItems.find(el => el.allFilter);
    else if (filterID === 'ArtsAndCulture') return events.filterItems.find(el => el.artsAndCultureFilter);
    else if (filterID === 'Beaches') return events.filterItems.find(el => el.beachesFilter);
    else if (filterID === 'FestivalsAndExperiences')
      return events.filterItems.find(el => el.festivalsAndExperiencesFilter);
    else if (filterID === 'FreeThingsToDo') return events.filterItems.find(el => el.freeThingsToDoFilter);
    else if (filterID === 'RooftopsAndBrunch') return events.filterItems.find(el => el.rooftopsAndBrunchFilter);
    else if (filterID === 'SportsAndConcertsAndShows')
      return events.filterItems.find(el => el.sportsAndConcertsAndShowsFilter);
    else if (filterID === 'ThemeParksAndAttractions')
      return events.filterItems.find(el => el.themeParksAndAttractionsFilter);

    return undefined;
  };

  const getFilterLabel = (filterID: string) => {
    const filterItem = getFilterItem(filterID);

    return filterItem?.filterLabel || '';
  };

  const handleStationClicked = (stationID: string) => {
    setSelectedStations(val => {
      if (val.includes(stationID)) {
        if (stationID !== 'All' || val.length !== 1) val = val.filter(el => el !== stationID);
        if (!val.length) val.push('All');
      } else if (stationID !== 'All') {
        val = val.filter(el => el !== 'All');
        val.push(stationID);
      } else val = ['All'];

      return [...val];
    });

    resetPagination();
  };

  const handleClearFilters = () => {
    setSelectedFilters(['All']);
    setSelectedStations(['All']);
    setActiveButtonFilter({
      value: '',
      label: '',
    });
    setCalendarStartDate('');
    setCalendarEndDate('');
    setSelectedStartDate('');
    setSelectedEndDate('');
    resetPagination();
  };

  const handlePageNumberChanged = useCallback(pageNumber => {
    if (pageNumber < 1) return;

    setPagination(val => ({
      ...val,
      pageNumber,
    }));
    if (window.scrollY > (eventsWrapperRef.current?.getBoundingClientRect().top || 0))
      window.scrollTo({
        top: (eventsWrapperRef.current?.getBoundingClientRect().top || 0) + window.scrollY,
        behavior: 'smooth',
      });
  }, []);

  const resetPagination = () => {
    setPagination(val => ({
      ...val,
      pageNumber: 1,
    }));
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    carouselHTML,
    titleSubtitleHTML,
    newsletterHTML,
    eventsWrapperRef,
    selectedFilters,
    handleFilterClicked,
    isFilterSelected,
    getFilterItem,
    getFilterLabel,
    selectedStations,
    handleStationClicked,
    handleClearFilters,
    eventsLoading,
    filteredEvents,
    paginatedEvents,
    cityPopoverOpen,
    setCityPopoverOpen,
    pagination,
    handlePageNumberChanged,
    windowSize,
    offersCardsHTML,
    topExperienceFragmentHTML,
    bottomExperienceFragmentHTML,
    showTopPicksSection: events.showTopPicksSection,
    topPicksTitle: events.topPicksTitle,
    topPicksDescription: events.topPicksDescription,
    topPicksTitleType: events.topPicksTitleType,
    topPicksTitleFontStyle: events.topPicksTitleFontStyle,
    topPicksDescriptionType: events.topPicksDescriptionType,
    topPicksDescriptionFontStyle: events.topPicksDescriptionFontStyle,
    datesRestrictionsData,
    setRestrictionsData,
    datePopoverOpen,
    setDatePopoverOpen,
    calendarStartDate,
    setCalendarStartDate,
    calendarEndDate,
    setCalendarEndDate,
    activeButtonFilter,
    setActiveButtonFilter,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    formatDateRange,
  };
};
