import { Formik } from 'formik';
import React from 'react';

import { Button } from '@atoms/Button';
import { InputField } from '@atoms/Input';
import { Title } from '@atoms/Title';
import SelectField from '@organisms/ParkingPasses/SelectGarage/SelectFieldGarage';
import useDailyAndParkingPassesLogic from '@organisms/ParkingPasses/useDailyAndParkingPassesLogic';

import { EditPassModalProps } from './../../types';
import { ErrorBanner } from './../ErrorBanner';

export const EditPassModal = ({ useEditPass, bem }: EditPassModalProps) => {
  const { hideModal, onSubmit, validationSchema, hasError } = useEditPass;
  const { initialFormValues, wallet, stateOptions, isLoading } = useEditPass;
  const { editParkingPass } = wallet;

  const handleCapitalizeParkingPasses = useDailyAndParkingPassesLogic();

  return (
    <>
      <div className={bem('title')}>
        <Title size="h4" text={editParkingPass.editParkingPassModalTitle} />
      </div>
      {!!hasError && <ErrorBanner />}
      <div className={bem('form')}>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          enableReinitialize
        >
          {({ submitForm, values, setFieldValue }) => (
            <div>
              <SelectField
                errorVariant="inline"
                name="ppStationName"
                label={editParkingPass.chooseGarageLabel}
                optionsData={[{ value: initialFormValues.ppStationName, label: initialFormValues.ppStationName }]}
                useDesktopVariant
                disabled
              />
              <SelectField
                errorVariant="inline"
                name="ppLicenseState"
                label={editParkingPass.chooseStateLabel}
                optionsData={stateOptions}
                useDesktopVariant
                displayTrailingIcon
              />
              <InputField
                type="text"
                name="ppLicenseNumber"
                errorVariant="inline"
                label={editParkingPass.enterLicensePlateNumberLabel}
                onChange={e => handleCapitalizeParkingPasses(e.target.value, 'ppLicenseNumber', setFieldValue)}
                displayTrailingIcon
              />
              <div className={bem('row')}>
                <Button label={editParkingPass.cancelButtonLabel} onClick={hideModal} variant="secondary" />
                <Button
                  label={editParkingPass.updateButtonLabel}
                  loading={isLoading}
                  disabled={
                    values?.ppLicenseState === initialFormValues?.ppLicenseState &&
                    values?.ppLicenseNumber === initialFormValues?.ppLicenseNumber
                  }
                  onClick={submitForm}
                  variant="primary"
                />
              </div>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};
