import React, { useMemo } from 'react';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';

import './AlertWithButton.scss';
import { AlertWithButtonProps } from './types';

const bem = cn('alertWithButton');

const AlertWithButton = (props: AlertWithButtonProps) => {
  const { description, icon, className, colors, onClick, text } = props;

  const styles: any = useMemo(
    () => ({
      '--alert-description-color': colors?.description?.inactive,
      '--alert-background-color': colors?.background?.inactive,
      '--alert-border-focus-color': colors?.border?.focus,
    }),
    [colors]
  );

  return (
    <div className={cx(bem(), className)} style={styles}>
      {description && <Text className={bem('description')} text={description} />}
      <button onClick={onClick} className={bem('button')}>
        {icon ? <Icon name={icon} className={bem('icon')} /> : text}
      </button>
    </div>
  );
};

export default AlertWithButton;
