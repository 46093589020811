import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';

import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { ExtrasSelectField } from '../../../../ExtrasSelect/ExtrasSelectField';
import './PassengerItem.scss';
import { PassengerItemProps } from './types';

const bem = cn('passenger-item');

export const PassengerItem = observer((props: PassengerItemProps) => {
  const { itemCardIndex, passengerID, passenger, direction, extrasModalLogic } = props;

  const { handleFormFieldChanged } = extrasModalLogic;

  const { values: formValues } = useFormikContext();
  const values = formValues as any;

  const dropdownConfig = useMemo(() => {
    const items = passenger[`${direction}Items`];

    const dropdownName = `${direction}${itemCardIndex}-${passengerID}`;

    const dropdownItems = items.map(item => ({
      label: item.label,
      value: item.value,
    }));

    const numSelectedItems = values[dropdownName];

    const dropdownLabel = dropdownItems.find(option => option.value === numSelectedItems)?.label;

    return {
      dropdownName,
      dropdownItems,
      numSelectedItems,
      dropdownLabel,
    };
  }, [direction, itemCardIndex, passengerID, passenger, values]);

  useEffect(() => {
    handleFormFieldChanged();
  }, [values, handleFormFieldChanged]);

  return (
    <div className={bem()}>
      <Text text={passenger.passengerName} className={bem('passenger-name')} />
      <div className={bem('dropdown-wrapper')}>
        <ExtrasSelectField
          optionsData={dropdownConfig.dropdownItems}
          name={dropdownConfig.dropdownName}
          className={bem('dropdown')}
          headerLabel={dropdownConfig.dropdownLabel}
          useDesktopVariant
        />
      </div>
    </div>
  );
});
