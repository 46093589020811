import moment from 'moment/moment';
import { DefaultProduct, Journey } from 'stores/BookingStore/sections/Tickets/types.journey';

export const defaultJourney: Journey = {
  id: '0:RDAOD|5709|BL|2024-02-17|2024-02-17T05:00|2024-02-17T07:08|MCO|WPT',
  name: '5709',
  type: 'BL',
  service: {
    id: '0:RDAOD|5709|BL|2024-02-17|2024-02-17T05:00|2024-02-17T07:08|MCO|WPT',
    name: '5709',
    type: 'BL',
  },
  departureTime: moment('2024-02-17T10:00:00.000Z'),
  arrivalTime: moment('2024-02-17T12:08:00.000Z'),
  origin: {
    id: 'MCO',
    name: 'Orlando',
    abbreviation: 'ORL',
    city: 'Orlando',
    timeZone: 'America/New_York',
    nameLong: 'Orlando',
  },
  destination: {
    id: 'WPT',
    name: 'West Palm Beach',
    abbreviation: 'WPB',
    city: 'West Palm Beach',
    timeZone: 'America/New_York',
    nameLong: 'West Palm Beach',
  },
  duration: 128,
  numberOfStops: 0,
  isAvailable: true,
  seatProducts: [
    {
      id: 'SMART_SAVER',
      seatingClassType: 'smart',
      name: 'SMART SAVER',
      description: '',
      productFamilyId: 'SV',
      productCode: 'SMART_SAVER',
      fares: [
        {
          passengerId: 'passenger_1',
          passengerType: 'adult',
          tariffCode: 'SV1',
          price: 59,
          originalPrice: 59,
          isSmartSaver: true,
        },
      ],
      displayedPrice: 59,
      tags: [
        {
          type: 'promotional',
          description: 'Smart Saver',
          imageUrl: '',
        },
      ],
    },
    {
      id: 'SMART_STANDARD',
      seatingClassType: 'smart',
      name: 'SMART',
      description:
        'Comfortable business-class option, with an array of food and beverages available for purchase.\n\n+ Complementary on-board WiFi\n+ Hand-stitched leather seats\n+ Power and USB outlets at every seat',
      productFamilyId: 'SS',
      productCode: 'SMART_STANDARD',
      fares: [
        {
          passengerId: 'passenger_1',
          passengerType: 'adult',
          tariffCode: 'SS1',
          price: 79,
          originalPrice: 79,
          isSmartSaver: false,
        },
      ],
      displayedPrice: 79,
      tags: [],
    },
    {
      id: 'PREMIUM_STANDARD',
      seatingClassType: 'premium',
      name: 'PREMIUM',
      description:
        'First-class experience with added amenities to take care of your every need. Includes SMART, plus:\n\n+ Complimentary drinks and snacks\n+ Premium lounge access\n+ Extra seat space\n+ Complimentary ride share within a 5 mile radius (South Florida only)',
      productFamilyId: 'PS',
      productCode: 'PREMIUM_STANDARD',
      fares: [
        {
          passengerId: 'passenger_1',
          passengerType: 'adult',
          tariffCode: 'PS1',
          price: 149,
          originalPrice: 149,
          isSmartSaver: false,
        },
      ],
      displayedPrice: 149,
      tags: [],
    },
  ],
  defaultSmartSaverProduct: {
    productId: 'SMART_SAVER',
  },
  defaultSmartProduct: {
    productId: 'SMART_STANDARD',
  },
  defaultPremiumProduct: {
    productId: 'PREMIUM_STANDARD',
  },
  tags: [
    {
      type: 'promotional',
      description: 'Smart Saver',
      imageUrl: '',
    },
  ],
  serviceTypeTag: {
    type: '',
    description: '',
    imageUrl: '',
  },
};

export const smartSaverDefaultProduct: DefaultProduct = {
  productId: 'SMART_SAVER',
};

export const smartDefaultProduct: DefaultProduct = {
  productId: 'SMART_STANDARD',
};

export const premiumDefaultProduct: DefaultProduct = {
  productId: 'PREMIUM_STANDARD',
};
