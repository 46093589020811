import cx from 'classnames';
import React from 'react';

import { cn } from 'utils/classNames';

import './PDFLoader.scss';
import { PDFLoaderProps } from './types';

const bem = cn('pdf-loader');

const PDFLoader = (props: PDFLoaderProps) => {
  const { pdfPageURL, pdfLink, height, className, ...rest } = props;

  return (
    <iframe
      className={cx(bem(), className)}
      title="Pdf Viewer"
      src={`${pdfPageURL}?file=${pdfLink}`}
      height={height}
      {...rest}
    />
  );
};

export default PDFLoader;
