import React, { Suspense } from 'react';
import { useStores } from 'stores';

import { CancelTripProps } from './types';
import { CancellationModal } from '../CancellationModal/CancellationModal';
import { CancellationDefaultModal } from './CancellationDefaultModal';
import { useCancelTripLogic } from '../Navigation/CancelTrip/useCancelTripLogic';

export const CancelTripModal = (props: CancelTripProps) => {
  const { onCancelTrip } = props;

  const { cfStore } = useStores();
  const { navigation } = cfStore.account.tripDetails;
  const { title, cancellationGroupMessageText, confirmButtonLabel, cancelButtonLabel } = navigation.cancelTrip;
  const { cancelTripNotPossibleTitle, cancelTripNotPossibleMessage } = navigation.cancelTrip
  const { isGroupTrip, isTripCancellable, origin, destination } = useCancelTripLogic();

  return (
    <Suspense fallback={<div />}>
        {isTripCancellable &&
          (isGroupTrip ?
            <CancellationDefaultModal 
              title={title} 
              text={cancellationGroupMessageText} 
              buttons={[{label: cancelButtonLabel, onClick: onCancelTrip }, {label: confirmButtonLabel}]}    
            /> : <CancellationModal onCancelTrip={onCancelTrip} />)}
        {!isTripCancellable && 
          <CancellationDefaultModal 
            title={cancelTripNotPossibleTitle}
            text={cancelTripNotPossibleMessage
                .replace('<originStation>', `${origin}`)
                .replace('<destinationStation>', `${destination}`)}
            buttons={[{label: cancelButtonLabel, onClick: onCancelTrip}]}
          />}
    </Suspense>
  );
};
