import React, { useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './TeasersList.scss';
import DesktopItems from './components/DesktopItems';
import MobileItems from './components/MobileItems';
import { TeaserListProps } from './types';

const bem = cn('teasers-list');

const splitItems = (items, itemsPerRow) => {
  const copyItems = JSON.parse(JSON.stringify(items));

  return [...Array(Math.ceil(copyItems.length / itemsPerRow))].map(() => copyItems.splice(0, itemsPerRow));
};

export const TeasersList = (props: TeaserListProps) => {
  const { width = 'narrow', items = [], variant = 'stack', eyebrow, id, itemsPerRow = 4 } = props;
  const { title, button, colors, titleAndEyebrowAlignment } = props;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const style: any = useMemo(
    () => ({
      '--teaser-list-background-color': colors?.background,
      '--teaser-list-eyebrow-color': colors?.eyebrow,
      '--teaser-list-title-color': colors?.title,
      '--teaser-list-title-background-color': colors?.titleBackground,
    }),
    [colors]
  );

  const rowList = useMemo(() => splitItems(items, itemsPerRow), [items, itemsPerRow]);

  return (
    <div id={id} className={bem()} style={style}>
      <div className={bem('content', { variant, alignment: titleAndEyebrowAlignment, width })}>
        <div className={bem('top')}>
          <div className={bem('top-content')}>
            <div className={bem('top-eyebrow')}>
              <Text as="h6" text={eyebrow} />
            </div>
            <div className={bem('top-title')}>
              <Text as="h2" text={title} />
            </div>
          </div>
        </div>
        <div className={bem('items-wrapper')}>
          {isMobile ? (
            <MobileItems items={items} variant={variant} />
          ) : (
            <DesktopItems items={items} rowList={rowList} itemsPerRow={itemsPerRow} variant={variant} width={width} />
          )}
        </div>
        <div className={bem('bottom')}>{button && <Button {...button} />}</div>
      </div>
    </div>
  );
};
