import { observer } from 'mobx-react-lite';
import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useStores } from 'stores';

import { TopLoadingBar } from '@atoms/TopLoadingBar';
import { Header as SearchHeader } from '@booking/components/Header';
import { ConfirmSessionEndingModal } from '@kiosk/components/ConfirmSessionEndingModal';
import { Header } from '@kiosk/components/Header';
import { PrintErrorModal } from '@kiosk/components/PrintErrorModal';
import { TimeoutModal } from '@kiosk/components/TimeoutModal';

import { cn } from 'utils/classNames';

import './Root.scss';

const bem = cn('kiosk');
const bemPage = cn('kiosk-page');

export const Root = observer(() => {
  const location = useLocation();

  const { ticketsFormStore } = useStores();
  const { isPanelVisible } = ticketsFormStore;

  useEffect(() => {
    if (isPanelVisible) document.body.classList.add('blockScroll');
    else document.body.classList.remove('blockScroll');

    return () => {
      document.body.classList.remove('blockScroll');
    };
  }, [isPanelVisible]);

  return (
    <Suspense fallback={<div />}>
      <div className={bem()}>
        <TopLoadingBar />

        {['/', '/confirmation'].includes(location.pathname) ? <Header /> : <SearchHeader validateOnMount isKiosk />}

        {location.pathname !== '/' && (
          <>
            <TimeoutModal />
            <ConfirmSessionEndingModal />
            <PrintErrorModal />
          </>
        )}

        <div className={bemPage({ overlay: isPanelVisible })}>
          <Outlet />
        </div>
      </div>
    </Suspense>
  );
});
