import React from 'react';
import { useRouteError } from 'react-router-dom';

import ErrorContent from './ErrorContent';

export const ErrorPage = ({ titleLabel }: { titleLabel?: string }) => {
  const error: any = useRouteError();

  return (
    <div id="error-page">
      <ErrorContent error={error} titleLabel={titleLabel} />
    </div>
  );
};
