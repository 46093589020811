import { useModalTabLogic } from 'hooks/useModalTabLogic';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Icon } from '@atoms/Icon';
import VideoPlayer from '@atoms/Video/VideoPlayer';
import { VideoModalProps } from '@molecules/VideoModal/types';

import { cn } from 'utils/classNames';

import './VideoModal.scss';

const bem = cn('video-modal');

const VideoModal = ({ playerIcon, ...rest }: VideoModalProps) => {
  const [playerOpen, setPlayerOpen] = useState<boolean>(false);

  const openPlayer = () => setPlayerOpen(true);

  const closePlayer = () => setPlayerOpen(false);

  const style: any = useMemo(
    () => ({
      '--video-modal-player-icon-color': playerIcon?.inactive,
    }),
    [playerIcon]
  );

  const { modalRef } = useModalTabLogic({ playerOpen, closePlayer });

  const closeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (playerOpen) document.body.style.position = 'fixed';
    else document.body.style.removeProperty('position');
    if (closeRef?.current) closeRef?.current?.focus();

    return () => {
      document.body.style.removeProperty('position');
    };
  }, [playerOpen]);

  return (
    <div ref={modalRef} className={bem()} style={style} role="dialog" aria-modal="true" aria-label="video player">
      {playerOpen && (
        <>
          <div className={bem('background')} />
          <div className={bem('player')}>
            <button ref={closeRef} aria-label="Close" className={bem('player-close')} onClick={closePlayer}>
              <Icon name="close2" />
            </button>
            <div className={bem('player-content')}>
              <VideoPlayer {...rest} width="100%" height="100%" />
            </div>
          </div>
        </>
      )}
      <button className={bem('play')} onClick={openPlayer} aria-label="open player">
        <Icon name="play" />
      </button>
    </div>
  );
};

export default VideoModal;
