import moment from 'moment/moment';
import { useCallback, useMemo } from 'react';
import { useStores } from 'stores';
import { PassengerProps } from 'stores/types/types.passengers';

import { defaultPassenger } from '@booking/routes/RTI/constants.storybook';

import { dateConfig, passengerTypes } from './constants';
import { PassengerCmpProps } from './types';

export const usePassengerLogic = (props: PassengerCmpProps) => {
  const { setFieldValue, currentPassengers = [], savedPassengers = [] } = props;
  const { index = 0, passenger, isModifyPassenger } = props;
  const { isPrimaryPassenger } = props;

  const { type } = passenger;

  const { cfStore, accountStore } = useStores();
  const { userProfile } = accountStore.profile;
  const { passengers } = cfStore.rti;
  const { managePassengers } = cfStore.account.tripDetails.navigation;
  const passengersLabels = isModifyPassenger ? managePassengers : passengers;

  const isAdult = useMemo(() => type === passengerTypes.adult, [type]);
  const isChild = useMemo(() => type === passengerTypes.child, [type]);
  const isInfant = useMemo(() => type === passengerTypes.infant, [type]);
  const isPrimary = useMemo(() => isPrimaryPassenger === true, [isPrimaryPassenger]);

  const convertToOptionsData = (p: PassengerProps, index: number) => ({
    label: `${p.firstName} ${p.lastName}`,
    value: index.toString(),
  });

  // array containing saved adult passengers
  const savedAdultsPassengers = useMemo(
    () =>
      savedPassengers?.filter(
        p =>
          p.firstName &&
          p.lastName &&
          p.type === passengerTypes.adult &&
          !currentPassengers.find(f => f.passenger.firstName === p.firstName && f.passenger.lastName === p.lastName)
      ),
    [savedPassengers, currentPassengers]
  );

  // array containing saved children passengers
  const savedChildrenPassengers = useMemo(
    () =>
      savedPassengers?.filter(
        p =>
          p.firstName &&
          p.lastName &&
          p.type === passengerTypes.child &&
          !currentPassengers.find(f => f.passenger.firstName === p.firstName && f.passenger.lastName === p.lastName)
      ),
    [savedPassengers, currentPassengers]
  );

  // array containing saved infant passengers
  const savedInfantPassengers = useMemo(
    () =>
      savedPassengers?.filter(
        p =>
          p.firstName &&
          p.lastName &&
          p.type === passengerTypes.infant &&
          !currentPassengers.find(f => f.passenger.firstName === p.firstName && f.passenger.lastName === p.lastName)
      ),
    [savedPassengers, currentPassengers]
  );

  const getDropdownData = useCallback((): any => {
    if (isAdult) return savedAdultsPassengers.map(convertToOptionsData);
    if (isChild) return savedChildrenPassengers.map(convertToOptionsData);

    return savedInfantPassengers.map(convertToOptionsData);
  }, [isAdult, isChild, savedAdultsPassengers, savedChildrenPassengers, savedInfantPassengers]);

  const handlePopulate = (selectedIndex: string) => {
    let populateElement = savedAdultsPassengers[selectedIndex];

    if (isChild) populateElement = savedChildrenPassengers[selectedIndex];
    else if (isInfant) populateElement = savedInfantPassengers[selectedIndex];

    const primaryPassenger =
      isPrimary && isModifyPassenger
        ? {
            email: currentPassengers[index].passenger.email,
            phoneNumber: currentPassengers[index].passenger.phoneNumber,
          }
        : {};

    setFieldValue(`passengers.${index}.passenger`, {
      ...defaultPassenger,
      ...populateElement,
      dateOfBirth: populateElement.dateOfBirth
        ? moment.utc(populateElement.dateOfBirth).format(dateConfig.dateFormat)
        : undefined,
      wheelchairAccommodation: currentPassengers[index].passenger.wheelchairAccommodation || undefined,
      ...primaryPassenger,
    });
  };

  const showPopulateDropdown = useMemo(
    () =>
      (isAdult && savedAdultsPassengers?.length > 0) ||
      (isChild && savedChildrenPassengers?.length > 0) ||
      (isInfant && savedInfantPassengers?.length > 0),
    [isAdult, isChild, isInfant, savedAdultsPassengers, savedChildrenPassengers, savedInfantPassengers]
  );

  return {
    passengers,
    passengersLabels,
    isAdult,
    isChild,
    isInfant,
    showPopulateDropdown,
    managePassengers,
    getDropdownData,
    userProfile,
    handlePopulate,
  };
};
