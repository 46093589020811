import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { Navigation } from '@account/components/Navigation';
import { Profile } from '@account/components/Profile';
import { useCMSContent } from '@account/hooks/useCMSContent';
import { DashboardWalletMobile } from '@account/routes/Dashboard/components/DashboardWallet/DashboardWalletMobile';
import XFContainerHandler from '@organisms/XFContainer/XFContainerHandler';

import { loyaltyLoadAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { isDelayExperienceEnabled, isLoyaltyEnabled } from 'utils/metas';

import './Dashboard.scss';
import { UpcomingTripsCard } from './components/Cards';
import { DashboardWallet } from './components/DashboardWallet';
import { PassengersCard } from './components/PassengersCard';
import { PaymentTokensCard } from './components/PaymentsCard';
import DashboardV2 from './v2/DashboardV2';

const bem = cn('account-dashboard');
const Dashboard = observer(() => {
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);
  useCMSContent('dashboard');
  const { cfStore, authStore, accountStore } = useStores();
  const { profile, rewardsSummary } = accountStore;
  const { dashboard } = cfStore.account;
  const { accountDashboardTitle } = dashboard;

  const loyaltyEnabled = isLoyaltyEnabled();

  const pushLoyaltyDataLayer = useCallback(async () => {
    if (!profile.userProfile && authStore.isAuthenticated) await profile.fetchUserProfile();

    if (
      !rewardsSummary.summary &&
      authStore.isAuthenticated &&
      profile.userProfile?.loyaltyPrograms?.some(
        program => program.program.toLowerCase() === 'brightline' && program.active
      )
    )
      await rewardsSummary.fetchRewardsSummaryData();

    const { userProfile } = profile;
    const { summary } = rewardsSummary;

    const loyaltyProgram = userProfile?.loyaltyPrograms?.find(
      program => program.program.toLowerCase() === 'brightline'
    );

    loyaltyLoadAnalyticsEvent({
      loyalty: {
        joinDate: loyaltyProgram?.optInDateTime || '',
        loyaltyID: [loyaltyProgram?.memberNumber || ''],
        points: summary?.spendable || 0,
        pointsExpiration: [summary?.expirationDate || ''],
        pointsRedeemed: summary?.spent || 0,
        program: loyaltyProgram?.program || '',
        status: loyaltyProgram?.active.toString() || 'false',
      },
    });
  }, [profile, rewardsSummary, authStore]);

  useEffect(() => {
    if (loyaltyEnabled) pushLoyaltyDataLayer();
  }, [loyaltyEnabled, pushLoyaltyDataLayer]);

  return (
    <div className={bem()}>
      {/* Calculate header height with delay experience banners in account  */}
      {isDelayExperienceEnabled() && <XFContainerHandler scope="header" />}
      {loyaltyEnabled ? (
        <DashboardV2 />
      ) : (
        <>
          {isMobile ? (
            <>
              <Profile />
              <DashboardWalletMobile />
              <Navigation />
            </>
          ) : (
            <>
              {accountDashboardTitle && <h1 className={bem('title')}>{accountDashboardTitle}</h1>}
              <div className={bem('row')}>
                <div className={bem('column')}>
                  <DashboardWallet />
                </div>
                <div className={bem('column')}>
                  <div className={bem('row')}>
                    <div className={bem('column-single')}>
                      <PaymentTokensCard />
                    </div>
                  </div>
                  <div className={bem('row')}>
                    <div className={bem('column-single')}>
                      <PassengersCard />
                    </div>
                  </div>
                </div>
              </div>
              <div className={bem('row')}>
                <div className={bem('column-single')}>
                  <UpcomingTripsCard />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
});

export default Dashboard;
