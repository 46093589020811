import React from 'react';
import { CreditPass } from 'stores/AccountStore/sections';

import { cn } from 'utils/classNames';

import { usePassCard } from '../../usePassCard';
import './CreditPassCard.scss';

const bem = cn('credit-pass');

export const CreditPassCard = (props: CreditPass) => {
  const { passName, creditAvailable } = props;

  const { cardRef } = usePassCard({});

  return (
    <div ref={cardRef} className={bem()}>
      <h4 className={bem('header')}>{passName}</h4>
      <div className={bem('footer')}>${creditAvailable?.toFixed(2)}</div>
    </div>
  );
};
