import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import './PassengerInfo.scss';
import { passengerTypes } from './constants';
import { PassengerCmpProps } from './types';

const bem = cn('passenger-info');

const PassengerComplete = observer((props: PassengerCmpProps) => {
  const { passenger, setFieldValue, index = 0, isModifyPassenger, isPastDepartureTime } = props;
  const { bookingStore } = useStores();
  const { setChangesHaveBeenSaved, setError } = bookingStore.rti;
  const { type, firstName, lastName } = passenger;
  const { email, phoneNumber, dateOfBirth } = passenger;

  const { cfStore } = useStores();
  const { passengers } = cfStore.rti;
  const { managePassengers } = cfStore.account.tripDetails.navigation;

  const passengersLabels = isModifyPassenger ? managePassengers : passengers;

  const isAdult = useMemo(() => type === passengerTypes.adult, [type]);
  const isChild = useMemo(() => type === passengerTypes.child, [type]);
  const isInfant = useMemo(() => type === passengerTypes.infant, [type]);

  const handleChangeEditMode = () => {
    setFieldValue(`passengers.${index}.editMode`, true);
    setFieldValue(`passengers.${index}.savePassenger`, false);
    setChangesHaveBeenSaved(false);
    setError(false);
  };

  return (
    <div>
      <div className={bem('header', { 'no-top': index > 0 })}>
        <div className={bem('desc')}>
          <h4 className={bem('passenger-info-guest')}>
            {passengersLabels.passengerHeaderLabel.replace('{index}', (index + 1).toString())}
            &#x2022;
            {isAdult ? passengersLabels.adultLabel : ''}
            {isChild ? passengersLabels.childLabel : ''}
            {isInfant ? passengersLabels.infantLabel : ''}
          </h4>
        </div>
        {index === 0 && (
          <div className={bem('primary')}>
            <h4 className={bem('passenger-info-primary-guest')}>
              {passengersLabels.passengerPrimaryLabel}
              {passenger.wheelchairAccommodation && ` (${passengers.wheelchairLabel})`}
            </h4>
          </div>
        )}
        {index > 0 && passenger.wheelchairAccommodation && (
          <div className={bem('primary')}>
            <h4 className={bem('passenger-info-wheelchair')}>{` (${passengers.wheelchairLabel})`}</h4>
          </div>
        )}
      </div>

      <div className={bem('readonly')}>
        <div>
          <div className={bem('ckeck-icon')} aria-hidden="true">
            <Icon name="check" />
          </div>
        </div>
        <div className={bem('info')}>
          <span className={bem('info-name')}>
            {firstName} {lastName}
          </span>
          <span>{dateOfBirth}</span>
          <span>{email}</span>
          {phoneNumber?.number && (
            <span>
              {`${phoneNumber?.countryCode}-${phoneNumber?.number?.slice(0, 3)}-${phoneNumber?.number?.slice(
                3,
                6
              )}-${phoneNumber?.number?.slice(6)}`}
            </span>
          )}
        </div>
        <div>
          <button className={bem('change')} onClick={handleChangeEditMode}>
            {!isPastDepartureTime && passengersLabels.changeLabel}
          </button>
        </div>
      </div>
    </div>
  );
});

export default PassengerComplete;
