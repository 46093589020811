import React from 'react';

export const WarningIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="4" fill="#FF9029" />
    <path
      d="M10.134 4.5C10.5189 3.83333 11.4811 3.83333 11.866 4.5L17.9282 15C18.3131 15.6667 17.832 16.5 17.0622 16.5H4.93782C4.16802 16.5 3.6869 15.6667 4.0718 15L10.134 4.5Z"
      fill="white"
    />
    <line x1="11" y1="8" x2="11" y2="11" stroke="#FF7E00" strokeWidth="2" strokeLinecap="round" />
    <circle cx="11" cy="14" r="0.5" fill="#FF7E00" stroke="#FF7E00" />
  </svg>
);
