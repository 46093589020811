import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useStores } from 'stores';

import { CardPlaceholder } from '@account/components/Card/components';
import { ContentHeader } from '@account/components/ContentHeader';
import { MyAccount } from '@account/components/MyAccount';
import { CarouselDots, PassesCarousel } from '@account/components/PassesCarousel';
import { useCarousel } from '@account/components/PassesCarousel/useCarousel';
import { createEmptySlides } from '@account/components/PassesCarousel/utils';
import { EditPass } from '@account/routes/Wallet/components/EditPass';
import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';
import { isLoyaltyEnabled } from 'utils/metas';

import { AutoRenew } from '../../components/AutoRenew';
import { PassDetails } from '../../components/PassDetails';
import { EmptyPassProps } from '../PassCards/components/EmptyPass/types';
import './WalletMobile.scss';

const bem = cn('account-wallet-mobile');

export const WalletMobile = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { wallet } = accountStore;
  const { passes, isLoading } = wallet;
  const { mobileActiveSlideToRestore, setMobileActiveSlideToRestore } = wallet;
  const { account } = cfStore;
  const { wallet: i18n } = account;

  const hasPasses = !!passes.length;

  const emptySlides: EmptyPassProps[] = useMemo(() => createEmptySlides(i18n), [i18n]);

  const { getRefFromHandlers, handlers, selectedPass, activeSlide, swipeCarouselToSlide } = useCarousel(
    hasPasses ? passes : emptySlides,
    hasPasses
  );

  const loyaltyEnabled = isLoyaltyEnabled();

  useEffect(() => {
    if (mobileActiveSlideToRestore) {
      swipeCarouselToSlide(mobileActiveSlideToRestore);
      setMobileActiveSlideToRestore(null);
    }
  }, [mobileActiveSlideToRestore, setMobileActiveSlideToRestore, swipeCarouselToSlide]);

  if (isLoading) return <CardPlaceholder variant="large" />;

  return (
    <>
      {loyaltyEnabled && (
        <div className={bem('user-account')}>
          <MyAccount />
        </div>
      )}
      <ContentHeader pageTitle={i18n.pageTitle} />

      <div className={bem()}>
          <PassesCarousel
            items={passes}
            emptySlides={emptySlides}
            handlers={handlers}
            getRefFromHandlers={getRefFromHandlers}
            activeSlide={activeSlide}
          />
        <div className={bem('content')}>
          <div className={bem('actions')}>
            <div className={bem('slides-indicators')}>
              <CarouselDots items={hasPasses ? passes : emptySlides} activeSlide={activeSlide} hasPasses={hasPasses} />
            </div>
            {hasPasses && (
              <div className={bem('book-action')}>
                {selectedPass?.type !== 'parking' && (
                  <Button
                    label={i18n.creditCtaLabel}
                    icon="train"
                    variant="secondary"
                    color="blue"
                    size="small"
                    link={{ url: i18n.creditCtaLink }}
                  />
                )}
              </div>
            )}
          </div>
          <div className={bem('details')}>
            <PassDetails item={selectedPass || { type: 'train' }} empty={!hasPasses} />
          </div>
        </div>

        {selectedPass && selectedPass.type !== 'credit' && (
          <div className={bem('renew')}>
            <AutoRenew selectedPass={selectedPass} empty={!hasPasses} />
            {selectedPass.type === 'parking' && (
              <>
                <div className={bem('divider')} />
                <EditPass label={i18n.modifyPassCtaLabel} item={selectedPass} />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
});
