import React, { useMemo } from 'react';

import { Button } from '@atoms/Button';
import { Text } from '@atoms/Text';
import { UpsellModalSectionsConfig } from '@organisms/UpsellModal/types';

import { cn } from 'utils/classNames';

import UpsellContentDescription from '../UpsellContentDescription/UpsellContentDescription';
import './UpsellContentDesktop.scss';

const bem = cn('upsell-content-desktop');

const UpsellContentDesktop = (props: UpsellModalSectionsConfig) => {
  const { title, continueWithButtonLabel, upgradeToButtonLabel, handleOnContinue } = props;
  const { handleOnUpgrade, description, journey, selectedFare } = props;

  const upgradeButtonLabel = useMemo(() => {
    const smartSaverPrice =
      journey.seatProducts.find(seatProduct => seatProduct.id === 'SMART_SAVER')?.displayedPrice || 0;
    const smartPrice =
      journey.seatProducts.find(seatProduct => seatProduct.id === 'SMART_STANDARD')?.displayedPrice || 0;
    const premiumPrice =
      journey.seatProducts.find(seatProduct => seatProduct.id === 'PREMIUM_STANDARD')?.displayedPrice || 0;

    let upgradePrice = '';
    if (selectedFare === 'SMART_SAVER') upgradePrice = `$${(smartPrice - smartSaverPrice).toFixed(2)}`;
    else if (selectedFare === 'SMART_STANDARD') upgradePrice = `$${(premiumPrice - smartPrice).toFixed(2)}`;

    return upgradeToButtonLabel.replace(/\{price}/g, upgradePrice);
  }, [journey, upgradeToButtonLabel, selectedFare]);

  return (
    <div className={bem()}>
      <Text className={bem('title')} text={title} />
      <UpsellContentDescription description={description} />
      <div className={bem('buttons')}>
        <Button label={continueWithButtonLabel} onClick={handleOnContinue} />
        <Button label={upgradeButtonLabel} onClick={handleOnUpgrade} />
      </div>
    </div>
  );
};

export default UpsellContentDesktop;
