import { useMemo } from 'react';
import * as Yup from 'yup';

import { WifiSplashFormProps } from '@molecules/WifiSplashForm/types';

export const useValidationSchema = (props: WifiSplashFormProps) =>
  useMemo(
    () =>
      Yup.object().shape({
        firstName: props.firstNameRequired ? Yup.string().required(props.inputRequired) : Yup.string(),
        lastName: props.lastNameRequired ? Yup.string().required(props.inputRequired) : Yup.string(),
        email: props.emailRequired
          ? Yup.string().email(props.invalidEmail).required(props.inputRequired)
          : Yup.string().email(props.invalidEmail),
        postalCode: props.postalCodeRequired ? Yup.string().required(props.inputRequired) : Yup.string(),
      }),
    [props]
  );
