import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { RootStore } from 'stores';

import { cartEvent, constructAnalyticsItems } from 'utils/adobeDataLayer';
import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';

import { ParkingConfig, ParkingRequestBody } from './types';

export class Parking {
  private readonly rootStore: RootStore;

  data: ParkingConfig | null = null;
  loading = false;
  error = false;

  addParkingError = false;
  modalVisible = false;
  modalSelectedPassengers: string[] = [];
  previouslySelectedPassengers: string[] = [];
  modalNumberOfDays: {
    passengerId: string;
    numberOfDays: number;
  }[] = [];

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  setParking(data: ParkingConfig) {
    this.data = data;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setError(error) {
    this.error = error;
  }

  resetParking() {
    this.data = null;
    this.modalVisible = false;
    this.modalSelectedPassengers = [];
    this.previouslySelectedPassengers = [];
    this.addParkingError = false;
    this.modalNumberOfDays = [];
  }

  showModal = () => {
    this.addParkingError = false;
    this.modalVisible = true;
  };

  hideModal = () => {
    this.modalVisible = false;
  };

  toggleSelectedPassenger(id: string, formValues) {
    if (this.modalSelectedPassengers.includes(id)) {
      this.modalSelectedPassengers = this.modalSelectedPassengers.filter(passengerId => passengerId !== id);
      this.modalNumberOfDays = this.modalNumberOfDays.filter(passenger => passenger.passengerId !== id);
    } else {
      const temp = [...this.modalSelectedPassengers];
      temp.push(id);
      this.modalSelectedPassengers = temp;

      const temp2 = [...this.modalNumberOfDays];
      temp2.push({
        passengerId: id,
        numberOfDays: formValues[`${id}_numberOfDays`],
      });
      this.modalNumberOfDays = temp2;
    }

    return !!this.data?.eligibleDrivers.some(
      driver =>
        (!!driver.currentSelections && !this.modalSelectedPassengers.includes(driver.passengerId)) ||
        (!driver.currentSelections && this.modalSelectedPassengers.includes(driver.passengerId)) ||
        (!!driver.currentSelections &&
          driver.currentSelections.licensePlate?.number !== formValues[`${driver.passengerId}_license`]) ||
        (!!driver.currentSelections &&
          driver.currentSelections.licensePlate?.state !== formValues[`${driver.passengerId}_state`]) ||
        (!!driver.currentSelections &&
          driver.currentSelections.numberOfDays !== formValues[`${driver.passengerId}_numberOfDays`])
    );
  }

  changeNumberOfDaysPassenger(formValues) {
    this.modalNumberOfDays = this.modalNumberOfDays.map(driver => ({
      ...driver,
      numberOfDays: formValues[`${driver.passengerId}_numberOfDays`],
    }));
  }

  setModalSelectedPassengers(selectedPassengers: string[]) {
    this.modalSelectedPassengers = selectedPassengers;
  }

  setPreviouslySelectedPassengers(selectedPassengers: string[]) {
    this.previouslySelectedPassengers = selectedPassengers;
  }

  setModalNumberOfDays(modalNumberOfDays: { passengerId: string; numberOfDays: number }[]) {
    this.modalNumberOfDays = modalNumberOfDays;
  }

  getTotalPrice() {
    if (!this.data) return 0.0;

    return this.data?.eligibleDrivers.reduce((total, next) => {
      const { currentSelections } = next;
      if (currentSelections) {
        const startDate = moment.utc(currentSelections.startDate).set({ hour: 4, minute: 0 });
        const endDate = moment.utc(currentSelections.endDate).set({ hour: 3, minute: 59 });

        const diff = endDate.diff(startDate, 'days', false) + 1;

        return total + next.pricePerDay * diff;
      }

      return total;
    }, 0.0);
  }

  async fetchParking(referenceNumber, bookingSession) {
    try {
      this.setLoading(true);
      this.setError(null);
      const url = `${requestUrls.getRestUrl(requestUrls.parking)}.${referenceNumber}.xjson`;

      const response = await api.get(url, {
        headers: {
          'Booking-Session-Access-Token': bookingSession?.accessToken,
          'Booking-Session-Refresh-Token': bookingSession?.refreshToken,
        },
      });
      this.setParking(response.data);
      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      this.setLoading(false);
    }
  }

  async addBookingParking(body: ParkingRequestBody[], referenceNumber, bookingSession, cb?, previousBody?) {
    try {
      this.setLoading(true);
      this.setError(null);
      const url = `${requestUrls.getRestUrl(requestUrls.parking)}.${referenceNumber}.xjson`;

      const response = await api.put(
        url,
        { items: body },
        {
          headers: {
            'Booking-Session-Access-Token': bookingSession?.accessToken,
            'Booking-Session-Refresh-Token': bookingSession?.refreshToken,
          },
        }
      );

      this.rootStore.bookingStore.rti.setIsUpsell(true);

      const getResponse = await api.get(url, {
        headers: {
          'Booking-Session-Access-Token': bookingSession?.accessToken,
          'Booking-Session-Refresh-Token': bookingSession?.refreshToken,
        },
      });

      this.setParking(getResponse.data);

      if (this.rootStore.bookingStore.rti.modifyTripType === 'extras')
        this.rootStore.bookingStore.rti.createApplicablePasses(response.data, true);

      if (this.rootStore.bookingStore.rti.modifyTripType === 'trip')
        this.rootStore.bookingStore.rti.createApplicablePasses(response.data);

      this.setLoading(false);

      const analyticsItems = previousBody ? constructAnalyticsItems(previousBody, body) : '';

      if (analyticsItems.length) cartEvent('updateCart', analyticsItems, response, 'parking');

      this.setPreviouslySelectedPassengers(this.modalSelectedPassengers);

      // eslint-disable-next-line callback-return
      if (cb) cb(response.data);
    } catch (e: any) {
      console.error(e);
      this.setLoading(false);
      this.addParkingError = true;
    }
  }
}
