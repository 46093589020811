import { Form, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { Button } from '@atoms/Button';
import { Checkbox } from '@atoms/Checkbox';
import { InputField } from '@atoms/Input';
import SelectField from '@atoms/Select/SelectField';
import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import { useServerLogic } from '../useServerLogic';
import { SweepstakesFormProps } from './../types';
import { SweepstakesFormValues } from './../types';
import { useSweepstakesFormLogic } from './../useSweepstakesFormLogic';
import './SweepstakesFormContent.scss';

const bem = cn('sweepstakes-form-content');

export const SweepstakesFormContent = (props: SweepstakesFormProps) => {
  const {
    firstNameLabel,
    lastNameLabel,
    emailLabel,
    addressLabel,
    cityLabel,
    stateLabel,
    zipCodeLabel,
    disclaimerLabel,
    submitButton,
  } = props;
  const { stateOptions } = useSweepstakesFormLogic();
  const { serverStatus } = useServerLogic();
  const [isAddressClicked, setIsAddressClicked] = useState(false);
  const { values, setFieldValue } = useFormikContext<SweepstakesFormValues>();

  const isTabletLarge = useMediaPredicate(mediaQueryList.maxTabletLarge);

  useEffect(() => {
    if (values.address) setIsAddressClicked(true);
  }, [values.address]);

  const handleAddressClick = () => {
    setIsAddressClicked(true);
  };

  useEffect(() => {
    setFieldValue('disclaimerCheckbox', true);
  }, [setFieldValue]);

  return (
    <div className={bem()}>
      <div className={bem('content-body')}>
        <Form>
          <div className={bem('row')}>
            <div className={cx(bem('column'), bem('field'))}>
              <InputField
                errorVariant="inline"
                name="firstName"
                label={firstNameLabel}
                error={serverStatus === 'error'}
                showAsterisk
                displayTrailingIcon
                required
              />
            </div>
            <div className={cx(bem('column'), bem('field'))}>
              <InputField
                errorVariant="inline"
                name="lastName"
                label={lastNameLabel}
                error={serverStatus === 'error'}
                showAsterisk
                displayTrailingIcon
                required
              />
            </div>
          </div>
          <div className={bem('row')}>
            <div className={bem('field')}>
              <InputField
                errorVariant="inline"
                error={serverStatus === 'error'}
                name="email"
                label={emailLabel}
                displayTrailingIcon
                required
              />
            </div>
          </div>
          <div className={bem('row')}>
            <div className={bem('field')}>
              <InputField
                errorVariant="inline"
                error={serverStatus === 'error'}
                name="address"
                label={addressLabel}
                maxCharacters={50}
                onFocus={handleAddressClick}
                displayTrailingIcon
              />
            </div>
          </div>
          {(isAddressClicked || values?.address) && (
            <>
              <div className={bem('row')}>
                <div className={bem('field')}>
                  <InputField
                    errorVariant="inline"
                    error={serverStatus === 'error'}
                    name="city"
                    label={cityLabel}
                    maxCharacters={50}
                    displayTrailingIcon
                  />
                </div>
              </div>
              <div className={bem('row')}>
                <div className={cx(bem('column'), bem('field'), bem('column-state'))}>
                  <SelectField
                    errorVariant="inline"
                    error={serverStatus === 'error'}
                    name="state"
                    label={stateLabel}
                    optionsData={stateOptions}
                    useDesktopVariant
                    displayTrailingIcon
                  />
                </div>
                <div className={cx(bem('column'), bem('field'), bem('zipCode'), bem('column-zip'))}>
                  <InputField
                    errorVariant="inline"
                    error={serverStatus === 'error'}
                    name="zipCode"
                    label={zipCodeLabel}
                    maxCharacters={10}
                    displayTrailingIcon
                  />
                </div>
              </div>
            </>
          )}
          <div className={bem('row')}>
            <div className={bem('disclaimer-wrapper')}>
              <Checkbox name="checkbox" label="" isChecked disabled />
              <Text text={disclaimerLabel} className={bem('disclaimer')} />
            </div>
          </div>
          <div className={bem('row')}>
            <Button
              color={submitButton?.color}
              size={submitButton?.size}
              variant={submitButton?.variant}
              label={submitButton?.label || 'Submit'}
              width={isTabletLarge ? 'full' : 'normal'}
              link={submitButton?.link}
              type="submit"
              className={bem('btn')}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
