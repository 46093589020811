import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './LayoutWithSidebar.scss';
import { LayoutWithSidebarProps } from './types';

const bem = cn('account-layout-with-sidebar');

export const LayoutWithSidebar = observer(({ children, className = '' }: LayoutWithSidebarProps) => {
  const { cfStore } = useStores();

  const { account } = cfStore;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  return (
    <div className={cx(bem(), className)}>
      <div className={bem('body')}>
        {React.Children.map(children, (child, index) =>
          isMobile && index === 0
            ? null
            : React.cloneElement(child, {
                className: bem(index === 0 ? 'sidebar' : 'content', {
                  noHorizontalPaddings:
                    isMobile &&
                    (window.location.pathname.includes(account.dashboard?.link) ||
                      window.location.pathname.includes(account.wallet.link)),
                }),
              })
        )}
      </div>
    </div>
  );
});
