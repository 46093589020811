import moment from 'moment/moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useStores } from 'stores';

import { DisplayTrainsProps } from '@booking/routes/TrainSelection/components/DisplayTrains/types';

import { scrollToTop } from 'utils/isKiosk';

export const useDisplayTrainsLogic = (props: DisplayTrainsProps) => {
  const { data, outboundTicket } = props;
  const { bookingStore, ticketsFormStore, cfStore } = useStores();
  const { activeDate, setActiveDate, searchTickets, searchTicketsModifyTrip, modifyTripProps, sortValue } =
    bookingStore.tickets;
  const { generic } = cfStore;
  const { formValues } = ticketsFormStore;

  const dayTrainsDate = data?.[data?.length / 2]?.departureTime;

  const condition = journey =>
    moment(journey?.departureTime)
      .tz(journey?.origin?.timeZone || '')
      ?.format('YYYY-MM-DD') ==
    moment(dayTrainsDate)
      .tz(journey?.origin?.timeZone || '')
      ?.format('YYYY-MM-DD');
  const journeys = data?.filter(condition);
  const midnightTrains = data?.filter(journey => !condition(journey));

  const sortJourneys = useCallback(
    journeys => {
      if (sortValue === 2) [journeys[0], journeys[1]] = [journeys[1], journeys[0]];

      return journeys;
    },
    [sortValue]
  );

  const sortedJourneys = useMemo(
    () =>
      sortJourneys([
        {
          id: 'day-trains',
          data: journeys,
          label: generic.resultsForLabel,
          displayLabel: sortValue === 2 && !!midnightTrains.length,
          displayDate: moment(activeDate).isSame(moment(), 'days')
            ? generic.todayLabel
            : moment(activeDate).format('ddd, MMM D'),
          hasData: !!journeys.length,
        },
        {
          id: 'midnight-trains',
          data: midnightTrains,
          label: generic.earlyMorningResultsLabel,
          displayLabel: !!midnightTrains.length,
          displayDate: moment(activeDate).add('1', 'days').format('ddd, MMM D'),
          hasData: !!midnightTrains.length,
        },
      ]),
    [journeys, midnightTrains, activeDate, generic, sortJourneys, sortValue]
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleViewNextDayTrains = async () => {
    const nextDay = moment(activeDate).add('1', 'days');
    const dayToSend = outboundTicket ? { endDate: nextDay } : { startDate: nextDay };

    if (!modifyTripProps) await searchTickets({ ...formValues, ...dayToSend });
    else await searchTicketsModifyTrip({ ...modifyTripProps, ...dayToSend });

    setActiveDate(nextDay.format('YYYY-MM-DD'));
  };

  return {
    sortedJourneys,
    midnightTrains,
    handleViewNextDayTrains,
  };
};
