import React from 'react';
import { RectShape } from 'react-placeholder/lib/placeholders';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './../../EventsBrowser.scss';

const bem = cn('events-browser');
export const eventsSkeleton = (
  <div className={bem('events-wrapper')}>
    {[...Array(16)].map((_, idx) => (
      <div key={idx} className={bem('event-card-wrapper')}>
        <RectShape color={color_base_medium_light_grey} style={{ display: 'block', width: '100%', height: '240px' }} />
        <div className={bem('event-card-text-wrapper')}>
          <RectShape color={color_base_medium_light_grey} style={{ display: 'block', width: '70%', height: '24px' }} />
          <RectShape color={color_base_medium_light_grey} style={{ display: 'block', width: '100%', height: '68px' }} />
          <RectShape color={color_base_medium_light_grey} style={{ display: 'block', width: '55%', height: '24px' }} />
          <RectShape color={color_base_medium_light_grey} style={{ display: 'block', width: '55%', height: '24px' }} />
        </div>
      </div>
    ))}
  </div>
);
