import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate } from 'react-router-dom';
import { rootStore, useStores } from 'stores';
import { NavigationItemConfig } from 'stores/CFStore/types.account';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { isLoyaltyEnabled } from 'utils/metas';

import { NavItem } from './NavItem';
import './Navigation.scss';

const bem = cn('account-navigation-menus');

export const Navigation = observer(() => {
  const { authStore, cfStore, accountStore } = useStores();
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const { auth0Client } = authStore;

  const { account } = cfStore;
  const { sidebarNavigation, rewardsGeneral, commissions } = account;
  const { primaryNavigation, secondaryNavigation } = sidebarNavigation;

  const { profile } = accountStore;
  const { isLoading, userProfile, displayLoyaltyComponents } = profile;

  const loyaltyEnabled = isLoyaltyEnabled();

  const navigate = useNavigate();

  const handleClick = (navItem: NavigationItemConfig) => async e => {
    e.preventDefault();

    const { link, actionId } = navItem;

    if (actionId && actionId === 'logout') {
      await auth0Client.logout({ logoutParams: { returnTo: window.location.origin } });

      localStorage.removeItem('zendeskToken');
      window.zE?.('messenger', 'logoutUser');

      return;
    }

    if (link) {
      const isRewardsLink = link === rewardsGeneral.rewardsPage.link;

      if (isRewardsLink)
        clickAnalyticsEvent(rootStore, {
          linkClick: {
            pageNameClickTracking: 'Account',
            navigationLinkName: navItem.pageTitle,
            customLink: '/account/rewards',
            externalLink: '',
            linkType: 'linkClickLoyalty',
          },
        });

      if (!isRewardsLink)
        clickAnalyticsEvent(rootStore, {
          linkClick: {
            pageNameClickTracking: document.title,
            navigationLinkName: navItem.navigationTitle,
            customLink: link,
            externalLink: '',
            linkType: 'linkClick',
          },
        });
      if (link.startsWith('/account')) navigate(link.split('/account')[1]);
      else window.location.href = link;
    }
  };

  return (
    <nav aria-label="Account sidebar navigation">
      <p className="sr-only">Activating this element will cause content on the page to be updated</p>
      <ul className="sr-list">
        <li>
          <ul className={bem('navigation', { placeholders: isLoading, isEngagement: loyaltyEnabled })}>
            {primaryNavigation.map(navItem => {
              if (navItem.link?.includes('/dashboard') && !loyaltyEnabled && isMobile) return null;

              if (navItem.link === rewardsGeneral.rewardsPage.link && !displayLoyaltyComponents) return null;
              if (navItem.link === commissions.link && !userProfile?.travelAgentCode) return null;

              return (
                <NavItem
                  key={navItem.navigationTitle || navItem.pageTitle}
                  navItem={navItem}
                  handleItemClick={handleClick(navItem)}
                />
              );
            })}
          </ul>
        </li>
        <li>
          <ul className={bem('subNavigation', { placeholders: isLoading, isEngagement: loyaltyEnabled })}>
            {secondaryNavigation.map(navItem => (
              <NavItem
                key={navItem.navigationTitle || navItem.pageTitle}
                navItem={navItem}
                handleItemClick={handleClick(navItem)}
              />
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
});
