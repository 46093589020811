import { ManageTripsForm } from '@molecules/ManageTripsForm';
import { TicketsForm } from '@molecules/TicketsForm';

import { TrainStatusForm } from './TrainStatusForm';

export const formComponents = {
  tickets: TicketsForm,
  trips: ManageTripsForm,
  status: TrainStatusForm,
};
