import { useEffect } from 'react';
import { useStores } from 'stores';

export const useCMSContent = name => {
  const { authStore, cfStore } = useStores();
  const { isAuthenticated } = authStore;

  const { account } = cfStore;

  const { showBottomCards } = account[name] || {};

  useEffect(() => {
    if (isAuthenticated && showBottomCards) document.body.classList.add('hasVisibleCMSContent');
    else document.body.classList.remove('hasVisibleCMSContent');

    return () => {
      document.body.classList.remove('hasVisibleCMSContent');
    };
  }, [isAuthenticated, showBottomCards]);
};
