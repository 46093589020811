export const isKiosk = () => document.body.classList.contains('kiosk');

export const selectKioskRoute = (kioskRoute, defaultRoute) => {
  if (isKiosk()) return kioskRoute;

  return defaultRoute;
};

export interface ScrollPosition {
  x: number;
  y: number;
}

export const scrollToTop = (): ScrollPosition => {
  const y = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
  const x = window.scrollX || window.pageXOffset || document.documentElement.scrollLeft;

  if (isKiosk()) document.querySelector('.blte-kiosk-page')?.scrollTo(0, 0);
  else window.scrollTo(0, 0);

  return { x, y };
};
