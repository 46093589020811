import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';
import { CostSummaryProps } from 'stores/BookingStore/sections/RTI/types.costSummary';

import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';
import { mediaQueryList } from 'utils/mediaQueries';

import { bem as rtiBem } from '../RTI';
import { CostSummaryContent } from './CostSummaryContent';
import { Timer } from './Timer/Timer';

const bem = cn('cost-summary');

export const CostSummary = observer(
  ({ children, isRtiDrawer, minutesRemaining, secondsRemaining, formattedTime }: CostSummaryProps) => {
    const {
      cfStore,
      bookingStore: { rti },
    } = useStores();
    const { costSummary } = cfStore.rti;

    const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

    return (
      <div className={bem()}>
        <aside>
          <div className={cx(rtiBem('card', { isRtiDrawer }))}>
            <div className={bem('title-wrapper')}>
              <h3 className={bem('title')}>
                {rti.modifyTripType !== null ? costSummary?.modifySummaryTitle : costSummary?.cardTitle}
              </h3>
              {!isMobile && !isKiosk() && (
                <Timer
                  minutesRemaining={minutesRemaining}
                  secondsRemaining={secondsRemaining}
                  formattedTime={formattedTime}
                />
              )}
            </div>

            {children}

            <CostSummaryContent />
          </div>
        </aside>
        <Text className={bem('help')} text={costSummary?.needHelpLabel} />
      </div>
    );
  }
);
