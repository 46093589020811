import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { WalletPassItemsProps } from '../WalletDesktop/types';

const bem = cn('account-pass-details');

export const TrainPassDetails = observer((props: WalletPassItemsProps) => {
  const { cfStore, accountStore } = useStores();
  const { account } = cfStore;
  const { wallet } = account;
  const { profile } = accountStore;
  const { userProfile } = profile;

  const { item, empty } = props;

  if (!item) return null;

  const { passType } = item;

  const passTypeLabel = useMemo(
    () => (passType === 'redeemable_rides' ? wallet.redeemableRidesLabel : wallet.trainPassLabel),
    [passType, wallet.redeemableRidesLabel, wallet.trainPassLabel]
  );

  return (
    <div className={bem('info', { empty })}>
      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.passTypeLabel}</h5>
        <div className={bem('value')}>{empty ? wallet.noApplicableLabel : passTypeLabel}</div>
      </div>

      {userProfile && (
        <div className={bem('info-row')}>
          <h5 className={bem('label')}>{wallet.passengerNameLabel}</h5>
          <div className={bem('value')}>
            {empty ? wallet.noApplicableLabel : `${userProfile.firstName} ${userProfile.lastName}`}
          </div>
        </div>
      )}
      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.dateIssuedLabel}</h5>
        <div className={bem('value')}>
          {empty ? wallet.noApplicableLabel : moment.utc(item?.issuedDate).format('MMM DD, YYYY')}
        </div>
      </div>

      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.shareableLabel}</h5>
        <div className={bem('value')}>
          {empty ? wallet.noApplicableLabel : item.isShareable ? wallet.shareableValueYes : wallet.shareableValueNo}
        </div>
      </div>

      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.codeLabel}</h5>
        <div className={bem('value')}>{empty ? wallet.noApplicableLabel : item.code}</div>
      </div>
    </div>
  );
});
