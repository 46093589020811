import React from 'react';

import { Image } from '@atoms/Image';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { WifiSplashFormHeaderProps } from './../../types';
import './WifiSplashFormHeader.scss';

const bem = cn('wifi-splash-form-header');

export const WifiSplashFormHeader = (props: WifiSplashFormHeaderProps) => {
  const { logoImage, introText } = props;

  if (!logoImage && !introText) return null;

  return (
    <div className={bem()}>
      {logoImage && <Image {...logoImage} className={bem('logo')} />}
      {introText && <Text text={introText} className={bem('introText')} />}
    </div>
  );
};
