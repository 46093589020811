import { observer } from 'mobx-react-lite';
import React from 'react';

import { NavItem } from '@molecules/NavItem/NavItem';
import { useNavItemHandler } from '@molecules/NavItem/useNavItemHandler';
import { useNavItemStyles } from '@molecules/NavItem/useNavItemStyles';

import { cn } from 'utils/classNames';

import { CustomIcon } from '../CustomIcon/CustomIcon';
import './TopNav.scss';
import AccountState from './components/AccountState/AccountState';
import Language from './components/Language/Language';
import { TopNavProps } from './types';

const bemGlobal = cn('global-header');

export const TopNav = observer((props: TopNavProps) => {
  const { data = [], colors, isResponsive = true } = props;
  const { handleAccountItemsClick } = useNavItemHandler();

  const styles = useNavItemStyles(colors);

  return (
    <nav
      className={bemGlobal('top-nav', { isResponsive, isDesktop: !isResponsive })}
      aria-label="top-nav"
      style={styles}
    >
      {data.map(item => {
        switch (item?.scope) {
          case 'login':
            return (
              <AccountState
                key={`top-nav-menu-item-${item.label}`}
                {...item}
                scope={item.scope}
                customIcon={item.svgSource || item.customIcon ? <CustomIcon {...item} /> : undefined}
                isResponsive={isResponsive}
              />
            );
          case 'language':
            return <Language key={`top-nav-menu-item-${item.label}`} {...item} isResponsive={isResponsive} />;
          case 'account':
            return (
              <NavItem
                key={`top-nav-menu-item-${item.label}`}
                {...item}
                customIcon={item.svgSource || item.customIcon ? <CustomIcon {...item} /> : undefined}
                size="small"
                isResponsive={isResponsive}
                onClick={handleAccountItemsClick(item)}
              />
            );
          default:
            return (
              <NavItem
                key={`top-nav-menu-item-${item.label}`}
                {...item}
                customIcon={item.svgSource || item.customIcon ? <CustomIcon {...item} /> : undefined}
                size="small"
                isResponsive={isResponsive}
              />
            );
        }
      })}
    </nav>
  );
});
