import React from 'react';

export const TrainIcon = () => (
  <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.232422 15.7871V4.45508C0.232422 3.17383 0.572266 2.19727 1.25195 1.52539C1.93164 0.845703 2.91992 0.505859 4.2168 0.505859H17.2012C18.123 0.505859 18.959 0.572266 19.709 0.705078C20.459 0.837891 21.1699 1.06055 21.8418 1.37305C22.5137 1.67773 23.1855 2.08789 23.8574 2.60352C24.5371 3.11914 25.2715 3.76367 26.0605 4.53711L30.9004 9.3418C31.7207 10.1465 32.334 11.002 32.7402 11.9082C33.1465 12.8145 33.3496 13.7012 33.3496 14.5684C33.3496 15.5371 33.1074 16.4121 32.623 17.1934C32.1465 17.9746 31.4473 18.5918 30.5254 19.0449C29.6035 19.5059 28.4824 19.7363 27.1621 19.7363H4.2168C2.91992 19.7363 1.93164 19.3965 1.25195 18.7168C0.572266 18.0449 0.232422 17.0684 0.232422 15.7871ZM13.416 10.1504C13.416 10.4629 13.5059 10.7129 13.6855 10.9004C13.873 11.0801 14.1309 11.1699 14.459 11.1699H25.6152C25.8652 11.1699 26.0645 11.0801 26.2129 10.9004C26.3613 10.7207 26.4277 10.502 26.4121 10.2441C26.4043 9.98633 26.2871 9.74414 26.0605 9.51758L23.5645 7.0332C23.1504 6.62695 22.7402 6.29102 22.334 6.02539C21.9355 5.75977 21.498 5.55273 21.0215 5.4043C20.5449 5.25586 19.9902 5.15039 19.3574 5.08789C18.7324 5.01758 17.9902 4.98242 17.1309 4.98242H14.459C13.7637 4.98242 13.416 5.33008 13.416 6.02539V10.1504Z"
      fill="#363636"
    />
  </svg>
);
