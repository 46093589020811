import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { InfoTilesContainer } from '@account/components/InfoTilesContainer';
import { MyAccount } from '@account/components/MyAccount';
import { RewardsSummary } from '@account/routes/Rewards/RewardsSummary';
import TransactionsTable from '@account/routes/Rewards/TransactionsTable/TransactionsTable';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './RewardsActivity.scss';
import { useRewardsActivityLogic } from './useRewardsActivityLogic';

const bem = cn('account-rewards-activity');

const RewardsActivity = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { rewardsGeneral } = cfStore.account;
  const { displayLoyaltyComponents, userProfile, isLoading, fetchUserProfile } = accountStore.profile;

  const { merchandisingXfForDesktopHTML, merchandisingXfForMobileHTML } = useRewardsActivityLogic();

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const merchandisingContent = isMobile ? merchandisingXfForMobileHTML : merchandisingXfForDesktopHTML;

  useEffect(() => {
    const checkRedirectCondition = async () => {
      if (!isLoading) {
        if (!userProfile) await fetchUserProfile();

        if (userProfile && !displayLoyaltyComponents) window.location.href = '/404';
      }
    };

    checkRedirectCondition();
  }, [isLoading, userProfile, displayLoyaltyComponents, fetchUserProfile]);

  if (!displayLoyaltyComponents) return null;

  return (
    <div className={bem()}>
      {isMobile && (
        <div className={bem('user-account')}>
          <MyAccount />
        </div>
      )}
      <RewardsSummary />
      <TransactionsTable />
      {merchandisingContent && <div dangerouslySetInnerHTML={{ __html: merchandisingContent }} />}
      <InfoTilesContainer {...rewardsGeneral.loyaltyOffers} />
    </div>
  );
});

export default RewardsActivity;
