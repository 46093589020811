import { getBffEndpoint, getLocale } from './metas';

export const TIME_FORMAT = 'hh:mm A';

export const FONT_VARIANTS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'body-extra-large-400',
  'body-extra-large-500',
  'body-extra-large-600',
  'body-large-400',
  'body-large-500',
  'body-large-600',
  'body-medium-400',
  'body-medium-500',
  'body-medium-600',
  'body-small-400',
  'body-small-500',
  'body-small-600',
  'tiny-400',
  'tiny-500',
  'tiny-600',
];

export const spacingOptions = ['', 'sm', 'md', 'lg', 'xl'];

export const storageKeys = {
  logoutUrl: 'logoutRedirectURL',
  loggedOutFlag: 'userLoggedOutFlag',
  showLoyaltyOptInModalFlag: 'showLoyaltyOptInModalFlag',
  loginUrl: 'loginRedirectURL',
  wifiData: 'wifiData',
  alertBanners: 'alertBannner',
  hideModalComponent: 'hideModalComponent',
  hafasUserId: 'hafasUserId',
  datadogUserData: 'datadogUserData',
  bookingCreationTime: 'bookingCreationTime',
};

const bffEndpoint = getBffEndpoint();

export const requestUrls = {
  getRestUrl: route => `/rest/api.${route}.${getLocale() || 'en-us'}`,
  getBffUrl: (route: string, noLang?: boolean) => {
    if (noLang) return `${bffEndpoint}/${route}`;

    return `${bffEndpoint}/${getLocale() || 'en-us'}/${route}`;
  },
  getParametrizedRestUrl: (route: string, params: any): string => {
    const url = `/rest/api.${route}`;
    if (!params?.locale) params.locale = getLocale() || 'en-us';

    return Object.keys(params).reduce((acc, key) => acc.replace(`{${key}}`, params[key]), url);
  },
  bookingSearch: {
    stations: 'stations',
    lastDate: 'dates',
    journeys: 'journeys',
    promoCodeDetails: 'booking-promo-codes-details',
    bffStationsSelection: 'public/booking/stations/selection',
  },
  passengers: 'passenger',
  payments: 'payment-tokens',
  userProfile: 'user-profile',
  parking: 'booking-parking',
  extras: 'booking-extras',
  iomobBookingRides: 'booking-rides',

  rti: {
    booking: 'booking',
    bookingConfirmation: 'booking-confirmation',
    kioskConfirmation: 'kiosk-confirmation',
    bookingPassengers: 'booking-passengers',
    searchTrip: 'search-trip',
    seats: 'booking-seats',
    travelPasses: 'booking-travel-passes',
    creditPasses: 'booking-credit-passes',
    bookingPromoCodes: 'booking-promo-codes',
    revertProvisionalChanges: 'booking-revert',
    giftCard: 'public/booking/{booking_number}/gift-cards',
    rewards: 'public/booking/{booking_number}/loyalty',
  },
  account: {
    onboarding: 'register',
    trips: 'trips',
    tripDetails: 'trip-details',
    cards: 'payment-tokens',
    walletPasses: 'wallet-passes',
    walletAutoRenewTravelPass: `wallet-travel-passes.{locale}.{code}.auto-renew.{action}.xjson`,
    walletAutoRenewParkingPass: `wallet-parking-passes.{locale}.{ticketNumber}.auto-renew.{action}.xjson`,
    walletUpdateParkingPass: 'wallet-parking-pass',
    passengers: 'passenger',
    userProfile: 'settings',
    commissionsData: 'travel-agent/commissions',
    shareTripEmails: 'public/trips/{booking_number}/send-email',
    invoice: 'public/booking/{booking_number}/invoice',
    loyaltyOptOut: 'user/loyalty/{loyaltyProgramId}',
    loyaltyOptIn: 'user/loyalty',
    trainSchedule: 'schedules/details',
  },
  passes: {
    createCart: 'cart-create',
    checkoutCart: 'cart-checkout',
    saveCard: 'payment-save',
    savedCards: 'payment-tokens',
    paymentMethods: 'payment-methods',
    applyPromoCode: 'promo-code',
    deletePromoCode: 'promo-code',
    giftCard: 'products/cart/{booking_number}/gift-cards',
    rewards: 'products/cart/{booking_number}/loyalty-points',
  },
  products: {
    parkingPassStations: 'parking-pass-conf',
    parkingPassOptions: 'parking-pass-opt',
    trainPassCategories: 'train-pass-cat',
    trainPassOptions: 'train-pass-opt',
  },
  notifications: {
    pushNotification: 'notifications/push/token',
    cancelNotification: 'notifications/push/token',
  },
  loyalty: {
    estimatedRewardsPoints: 'loyalty/estimate',
    rewardsSummary: 'loyalty/summary',
    rewardsActivity: 'loyalty/history',
  },
  configs: {
    pledgeCounter: 'configs',
  },
};
