import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { mediaQueryList } from 'utils/mediaQueries';

export const usePassCard = ({ endDate }: { endDate?: string | undefined }) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const isTabletLarge = useMediaPredicate(mediaQueryList.maxTabletLarge);

  const expireDateTime = moment.utc(endDate).subtract(5, 'hours');
  const today = moment().startOf('day');
  const isTodayLastDay = today.isSame(expireDateTime, 'day');

  useEffect(() => {
    const currentRefDiv = cardRef.current;

    if (currentRefDiv) {
      const cardWidth = currentRefDiv.offsetWidth;
      const cardHeight = cardWidth / 1.6;

      currentRefDiv.style.height = `${cardHeight}px`;
    }

    return () => {
      if (currentRefDiv && currentRefDiv.style) currentRefDiv.style.removeProperty('height');
    };
  }, [isTabletLarge]);

  return {
    cardRef,
    isTodayLastDay,
    expireDateTime,
  };
};
