import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { Pagination } from '@molecules/Pagination/Pagination';

import { mediaQueryList } from 'utils/mediaQueries';

export const PaginationWrapper = observer(() => {
  const { cfStore, accountStore } = useStores();
  const { account } = cfStore;
  const { trips: cfTrips } = account;
  const { pagination: paginationLabels } = cfTrips;

  const { trips } = accountStore;
  const { pastTripsPagination, setPastTripsPageNumber } = trips;
  const { itemsPerPage, pageCount, pageNumber, total } = pastTripsPagination;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  return (
    <Pagination
      {...paginationLabels}
      itemsPerPage={itemsPerPage}
      pageCount={pageCount}
      pageNumber={pageNumber}
      total={total}
      siblingCount={isMobile ? 0 : 1}
      onPageChange={setPastTripsPageNumber}
    />
  );
});
