import { makeAutoObservable } from 'mobx';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';

import { TransactionItemConfig } from './types';

export class TransactionHistory {
  history: TransactionItemConfig[] | null;
  paginatedHistory: TransactionItemConfig[] | null;
  isLoading = false;
  hasError = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setTransactionHistory(history) {
    this.history = history;
  }

  clearTransactionHistoryData() {
    this.history = null;
  }

  setPaginatedTransactionHistory(paginatedHistory) {
    this.paginatedHistory = paginatedHistory;
  }

  clearPaginatedTransactionHistoryData() {
    this.paginatedHistory = null;
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setError(hasError: boolean) {
    this.hasError = hasError;
  }

  async fetchTransactionHistoryData(
    offset?: number,
    limit?: number,
    id?: string[],
    status?: string,
    isPaginated?: boolean
  ) {
    try {
      this.setLoading(true);
      const url = requestUrls.getBffUrl(requestUrls.loyalty.rewardsActivity);
      if (isPaginated) {
        const response = await api.get(url, {
          params: {
            offset,
            limit,
            id,
            status,
          },
        });
        this.setPaginatedTransactionHistory(response.data.history);
      } else {
        const response = await api.get(url, {
          params: {
            id,
            status,
          },
        });
        this.setTransactionHistory(response.data.history);
      }
      this.setLoading(false);

      return isPaginated ? this.paginatedHistory : this.history;
    } catch (e) {
      console.error('Failed to fetch loyalty transaction history data ', e);
      isPaginated ? this.setPaginatedTransactionHistory(null) : this.setTransactionHistory(null);
      this.setError(true);
      this.setLoading(false);

      return null;
    }
  }
}
