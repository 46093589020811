import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import { Text } from '@atoms/Text';
import { AlertIcon } from '@molecules/TicketsForm/components/AlertBanner/Icons/AlertIcon';
import { GiftIcon } from '@molecules/TicketsForm/components/AlertBanner/Icons/GiftIcon';
import { InfoIcon } from '@molecules/TicketsForm/components/AlertBanner/Icons/InfoIcon';
import { WarningIcon } from '@molecules/TicketsForm/components/AlertBanner/Icons/WarningIcon';

import { cn } from 'utils/classNames';

import './AlertBanner.scss';

const bem = cn('tickets-form-alert-banner');

export const AlertBanner = ({ bookingOffers }) => {
  const { values: formValues } = useFormikContext();
  const values = formValues as any;

  const bannerConfig = useMemo(() => {
    if (!values.from || !values.to || !bookingOffers.length) return undefined;

    return bookingOffers.find(bookingOffer =>
      bookingOffer.originDestinations.some(
        odPair =>
          (odPair.originStation === values.from && odPair.destinationStation === values.to) ||
          (odPair.destinationStation === values.from && odPair.originStation === values.to)
      )
    );
  }, [values, bookingOffers]);

  return (
    <div className={bem({ variant: bannerConfig?.alertType, hidden: !bannerConfig })}>
      {bannerConfig && (
        <>
          {bannerConfig.alertType === 'info' && (
            <div className={bem('icon')}>
              <InfoIcon />
            </div>
          )}
          {bannerConfig.alertType === 'notice' && (
            <div className={bem('icon')}>
              <AlertIcon />
            </div>
          )}
          {bannerConfig.alertType === 'warning' && (
            <div className={bem('icon')}>
              <WarningIcon />
            </div>
          )}
          {bannerConfig.alertType === 'promotion' && (
            <div className={bem('icon')}>
              <GiftIcon />
            </div>
          )}
          <Text text={bannerConfig.message} className={bem('text')} />
        </>
      )}
    </div>
  );
};
