import React from 'react';
import { cn } from 'utils/classNames';
import { TrainStatusProps } from './types';
import LinkButton from '@atoms/LinkButton/LinkButton';
import './TrainStatus.scss';

const bem = cn('train-status');

export const TrainStatusForm = (props: TrainStatusProps) => {
  const {
    description,
    departureButtonLabel,
    arrivalButtonLabel,
    departurePageLink,
    arrivalPageLink
  } = props;

  return (
    <div className={bem()}>

      <p className={bem('description')}>{description}</p>

      <div className={bem('buttons')}>
        {departureButtonLabel && (
          <LinkButton
            label={departureButtonLabel}
            className={bem('button')} 
            colors={{
              text: { inactive: '#003366'}, 
              border: { focus: '#094273' },
            }} 
            border="2px solid #094273"
            link={{ url: departurePageLink?.url, target: departurePageLink?.target }}
            iconClass="arrow-right"
            variant='medium'
            showArrowIcon
          />
        )}

        {arrivalButtonLabel && (
          <LinkButton
            label={arrivalButtonLabel}
            className={bem('button')} 
            colors={{
              text: { inactive: '#003366'}, 
              border: { focus: '#094273' },
            }} 
            border="2px solid #094273"
            link={{ url: arrivalPageLink?.url, target: arrivalPageLink?.target }}
            iconClass="arrow-right"
            variant='medium'
            showArrowIcon
          />
        )}
      </div>
    </div>
  );
};
