import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';
import { Title } from '@atoms/Title';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './GiftCard.scss';

const bem = cn('gift-card_modal');

export const GiftCardModal = ({ onClose, currentCard }) => {
  const { cfStore, bookingStore } = useStores();
  const { rti, passes } = cfStore;
  const { deleteGiftCard } = bookingStore.rti;

  const reviewAndPay = useMemo(() => {
    if (rti.reviewAndPay.giftCardTitle) return rti.reviewAndPay;

    return passes.reviewAndPay;
  }, [passes, rti]);

  const onRemove = () => {
    deleteGiftCard(currentCard);
    onClose();
  };

  return (
    <Modal variant="mini">
      <div className={bem('gift-card_modal-container')}>
        <Title text={reviewAndPay.giftCardModalText} size="h3" />
        <Text text={reviewAndPay.giftCardModalSubtitle} />
        <div className={bem('buttons', { twoButtons: true })}>
          <Button
            color="charcoal"
            variant="secondary"
            label={reviewAndPay.giftCardModalRemoveButton}
            onClick={onRemove}
          />
          <Button color="charcoal" variant="primary" label={reviewAndPay.giftCardModalCancelButton} onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};
