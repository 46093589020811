import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import Checkbox from '@atoms/Checkbox/Checkbox';
import { Icon } from '@atoms/Icon';
import Image from '@atoms/Image/Image';
import { Input } from '@atoms/Input';
import { Text } from '@atoms/Text';
import { useRewardsLogic } from '@booking/routes/RTI/PaymentInfo/components/PaymentInfoEntry/components/Rewards/useRewardsLogic';

import { cn } from 'utils/classNames';

import './Rewards.scss';

const bem = cn('rewards-payment');

export const Rewards = observer(() => {
  const { accountStore, bookingStore, passesStore } = useStores();
  const { isLoading, summary } = accountStore.rewardsSummary;
  const { appliedRewardsPoints, deleteRewardsPoints, displayLoyaltyComponents, updateLoyaltyDisplayStatus } =
    bookingStore.rti;
  const { checkout } = passesStore;

  const totalToBePaid =
    bookingStore.rti?.provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid || 0;
  const cartAmountToBePaid = checkout?.costSummary?.bookingTotal?.totalToBePaid || 0;

  const logic = useRewardsLogic();
  const { expanded, toggleExpanded, handleSubmit, reviewAndPay } = logic;
  const { availableUserPoints, appliedUserPoints, handleInputChanged } = logic;

  const disabled = !appliedRewardsPoints && (!summary?.spendable || (totalToBePaid <= 0 && cartAmountToBePaid <= 0));

  useEffect(() => {
    updateLoyaltyDisplayStatus();
  }, [updateLoyaltyDisplayStatus]);

  if (!displayLoyaltyComponents) return null;

  return (
    <Formik
      initialValues={{ expanded: false, numberPoints: appliedRewardsPoints ? appliedRewardsPoints.toString() : '' }}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, values, setFieldValue, setFieldError, isSubmitting, resetForm }) => (
        <div
          className={bem({ expanded: expanded && !!appliedUserPoints })}
          onClick={() => toggleExpanded(resetForm, setFieldValue)}
        >
          <div className={bem('content', { disabled })}>
            <div className={bem('icon')}>
              <Image src={reviewAndPay.rewardPointsIcon?.src} alt="Rewards" className={bem('image')} />
            </div>
            <Form className={bem('main-panel')}>
              <div className={bem('upper-panel')}>
                <div className={bem('labels-wrapper')}>
                  <Text text={reviewAndPay.rewardPointsTitle} className={bem('title')} />
                  <Text text={availableUserPoints} className={bem('available-points-text')} />
                </div>
                <div className={bem('checkbox-wrapper')}>
                  {!appliedRewardsPoints && (
                    <div onClick={e => e.stopPropagation()}>
                      <Checkbox
                        name="expand"
                        isChecked={expanded}
                        label=""
                        disabled={disabled}
                        onChange={() => toggleExpanded(resetForm, setFieldValue)}
                      />
                    </div>
                  )}
                  {!!appliedRewardsPoints && (
                    <>
                      <div role="button" onClick={deleteRewardsPoints}>
                        <Icon name="close" className={bem('remove-button')} />
                      </div>
                      <Text text={appliedUserPoints} className={bem('applied-points-text')} />
                    </>
                  )}
                </div>
              </div>
              {expanded && !!appliedUserPoints && (
                <div className={bem('bottom-panel')} onClick={e => e.stopPropagation()}>
                  <Input
                    value={values.numberPoints}
                    name="numberPoints"
                    placeholder={reviewAndPay.numberOfPointsInputPlaceholder}
                    error={!!errors.numberPoints}
                    errorMessage={errors.numberPoints}
                    errorVariant="inline"
                    className={bem('input')}
                    mode="numeric"
                    onChange={e => handleInputChanged(e, setFieldValue, setFieldError)}
                    afterErrorFocus
                  />
                  <Button
                    label={reviewAndPay.applyButtonLabel}
                    type="submit"
                    color="black"
                    className={bem('button')}
                    disabled={isLoading || Number(values.numberPoints || 0) <= 0}
                    loading={isSubmitting}
                  />
                </div>
              )}
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
});
