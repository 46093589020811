import React, { useMemo } from 'react';

import { Icon } from '@atoms/Icon';

import { cn, cx } from 'utils/classNames';

import './IconWithLabel.scss';
import { IconWithLabelProps } from './types';

const bem = cn('icon-label');
const IconWithLabel = (props: IconWithLabelProps) => {
  const {
    icon,
    iconPosition = 'left',
    label,
    link,
    colors,
    externalIcon,
    externalIconAlt,
    contentPosition,
    labelFontClassName = 'blte-font--variant-body-medium-500',
  } = props;
  const { url, target } = link || {};

  const styles: any = useMemo(
    () => ({
      '--text-color': colors?.text?.inactive,
      '--text-hover-color': colors?.text?.hover,
      '--text-focus-color': colors?.text?.focus,
      '--border-focus-color': colors?.border?.focus,
    }),
    [colors]
  );
  const LinkWrapper = useMemo(() => (url ? 'a' : 'div'), [url]);

  return (
    <LinkWrapper
      className={bem({ position: iconPosition, contentPosition: contentPosition })}
      href={url}
      target={target}
      style={styles}
    >
      <span className={bem('icon')}>
        {externalIcon ? <img src={externalIcon} alt={externalIconAlt} /> : <Icon name={icon || ''} />}
      </span>
      <span className={cx(bem('label'), labelFontClassName)}>{label}</span>
    </LinkWrapper>
  );
};

export default IconWithLabel;
