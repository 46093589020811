import moment, { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { END_DATE, START_DATE } from './constants';
import { CalendarDateProps, DateRangePickerProps } from './types';

export interface UseDateRangePickerRes {
  isSameDay: boolean;
  focusedInput: string;
  handleClearDates: (val?: boolean) => void;
  handleCloseButton: () => void;
  handleInputClick: (val: string) => void;
  handleFocusChange: (focusedInputElement: string) => void;
  handleDatesChange: ({ startDate, endDate }: any) => void;
  isOutsideRange: (minDay: Moment, maxDay: Moment) => (day: Moment) => void;
  setFocusedInput: (val?: any) => void;
  selectedStartDate: any;
  setSelectedStartDate: (val?: any) => void;
  selectedEndDate: any;
  setSelectedEndDate: (val?: any) => void;
}

export const useDateRangePicker = (props: DateRangePickerProps): UseDateRangePickerRes => {
  const {
    isMobile,
    setCalendarStartDate,
    setCalendarEndDate,
    activeButtonFilter,
    setActiveButtonFilter,
    focusedInput,
    setFocusedInput,
    setDatePopoverOpen,
    datePopoverOpen,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
  } = props;

  const [isSameDay, setIsSameDay] = useState(false);

  const handleCloseButton = () => {
    setDatePopoverOpen(false);
    setFocusedInput('');
  };

  const handleClearDates = useCallback(
    (clearActiveButton = false) => {
      setCalendarStartDate('');
      setCalendarEndDate('');
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      setFocusedInput(START_DATE);
      if (clearActiveButton) {
        setActiveButtonFilter({ value: '', label: '' });
        setDatePopoverOpen(false);
      }
    },
    [
      setCalendarStartDate,
      setCalendarEndDate,
      setSelectedStartDate,
      setSelectedEndDate,
      setFocusedInput,
      setActiveButtonFilter,
      setDatePopoverOpen,
    ]
  );

  const handleInputClick = useCallback(
    (val: string) => {
      setFocusedInput(val);
      if (!datePopoverOpen) setDatePopoverOpen(true);
    },
    [datePopoverOpen, setDatePopoverOpen, setFocusedInput]
  );

  const handleFocusChange = (focusedInputElement: string) => {
    if (isMobile && !focusedInputElement) return setFocusedInput(END_DATE);

    if (!focusedInputElement) {
      handleCloseButton();
      
return null;
    }

    return setFocusedInput(focusedInputElement || START_DATE);
  };

  const handleDatesChange = useCallback(
    ({ startDate, endDate }: CalendarDateProps) => {
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
      setIsSameDay(moment(startDate).isSame(moment(endDate)));
      if (startDate && endDate) {
        setActiveButtonFilter({ value: '', label: '' });
        setCalendarStartDate(startDate);
        setCalendarEndDate(endDate);
      }
    },
    [setSelectedStartDate, setSelectedEndDate, setActiveButtonFilter, setCalendarEndDate, setCalendarStartDate]
  );

  const isOutsideRange = (minDay, maxDay) => day => {
    if (!maxDay) return moment(day).isBefore(minDay, 'day');

    return moment(day).isBefore(minDay, 'day') || moment(day).isAfter(maxDay, 'day');
  };

  useEffect(() => {
    setIsSameDay(moment(selectedStartDate).isSame(moment(selectedEndDate)));

    return () => setIsSameDay(false);
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (isMobile) setFocusedInput(START_DATE);
  }, [isMobile, setFocusedInput]);

  useEffect(() => {
    if (activeButtonFilter?.value) handleClearDates();
  }, [activeButtonFilter, handleClearDates]);

  return {
    isSameDay,
    focusedInput,
    handleCloseButton,
    handleInputClick,
    handleFocusChange,
    handleDatesChange,
    isOutsideRange,
    handleClearDates,
    setFocusedInput,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
  };
};
