import { FormikContextType, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';

import { WidgetInput } from '@atoms/WidgetInput/WidgetInput';
import { PartialFromProps } from '@molecules/TicketsForm/components/OriginAndDestination/types';

import { cn } from 'utils/classNames';
import { useInputLogic } from 'utils/useInputLogic';

import { DATE_FORMAT, DEFAULT_I18N, END_DATE, START_DATE } from './constants';
import { DateInputsProps } from './types';

const bem = cn('date-range-picker');

export const DateInputs = (props: DateInputsProps) => {
  const { i18n = DEFAULT_I18N } = props;
  const {
    handleInputClick,
    handleClearDate,
    focusedInput,
    handleCloseButton,
    tabType,
    start_date,
    end_date,
    labels,
    isOneWay,
    isFirstLegTraveled,
    isSecondLegTraveled,
  } = props;
  const { startPlaceholder, endPlaceholder } = i18n;

  const formikProps: FormikContextType<PartialFromProps> = useFormikContext();
  const { values, setFieldValue } = formikProps;

  useEffect(() => {
    if (isOneWay) setFieldValue('startDate', start_date, true);
    else {
      setFieldValue('startDate', start_date, true);
      setFieldValue('endDate', end_date, true);
    }
  }, [end_date, isOneWay, setFieldValue, start_date]);

  const { isInvalid: isStartDateInvalid, error: errStartDate } = useInputLogic({
    name: START_DATE,
  });
  const { isInvalid: isEndDateInvalid, error: errEndDate } = useInputLogic({ name: END_DATE });

  const handleStartDate = (e: any, inputName: string) => {
    handleInputClick(inputName);
  };

  const handleEndDate = (e: any, inputName: string) => {
    handleInputClick(inputName);
  };

  const handleKeyDown = (e: any, inputName: string) => {
    if (['Enter', 'Space'].includes(e.key)) handleInputClick(inputName);

    if (e.key === 'Escape') handleCloseButton();
  };

  const handleClearStart = () => {
    handleClearDate(START_DATE);
  };

  const handleClearEnd = () => {
    handleClearDate(END_DATE);
  };

  const startDateValue = useMemo(
    () => (values.startDate ? moment(values.startDate).format(DATE_FORMAT) : ''),
    [values]
  );

  const endDateValue = useMemo(
    () => (values.endDate ? moment(values.endDate).format(DATE_FORMAT) : ''),
    [values.endDate]
  );

  return (
    <>
      <div className={bem('inputs', { oneWay: isOneWay })}>
        <div className={bem('input-left')}>
          <WidgetInput
            isInvalid={isStartDateInvalid}
            errorMessage={errStartDate}
            label={labels.departuredatelabel}
            placeholder={startPlaceholder}
            onInputClick={handleStartDate}
            onInputKeyDown={handleKeyDown}
            name={START_DATE}
            active={focusedInput === START_DATE}
            onClearClick={handleClearStart}
            value={startDateValue}
            position="left"
            disabled={tabType == 'return' || isFirstLegTraveled}
          />
        </div>
        {!isOneWay && (
          <div className={bem('input-right')}>
            <WidgetInput
              label={labels.returndatelabel}
              placeholder={endPlaceholder}
              name={END_DATE}
              onInputKeyDown={handleKeyDown}
              onInputClick={handleEndDate}
              active={focusedInput === END_DATE}
              onClearClick={handleClearEnd}
              value={endDateValue}
              isInvalid={isEndDateInvalid}
              errorMessage={errEndDate}
              position="right"
              disabled={tabType == 'departure' || isSecondLegTraveled}
            />
          </div>
        )}
      </div>
    </>
  );
};
