import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStores } from 'stores';

import { classTypes } from '@booking/routes/RTI/constants';

import { cn } from 'utils/classNames';
import {
  color_base_black,
  color_black,
  color_brand_yellow,
  color_light_silver,
  color_maximum_blue,
} from 'utils/colors';

import { PremiumSeat, SmartSeat } from '../SeatIcon';
import './SeatMapItem.scss';
import { SeatMapItemProps } from './types';

const bem = cn('seat-map-item');

export const SeatMapItem = observer((props: SeatMapItemProps) => {
  const { seat, seatStyle, unavailable, selected, active, authorizedPassengers } = props;
  const { width, height, pristineSeatSelection, isAccountFlow } = props;
  const [hoverColor, setHoverColor] = useState<string | null>(null);
  const ref = useRef<HTMLButtonElement>(null);

  const { coach, facingDirection, number } = seat;
  const { bookingStore } = useStores();

  const { seats } = bookingStore.rti;
  const { selectSeat } = seats;

  const handleSeatClick = () => {
    if (selected || unavailable) return;

    selectSeat(seat, authorizedPassengers);
  };

  useEffect(() => {
    if (ref && ref.current && active) {
      ref.current.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
      if (!pristineSeatSelection) ref.current.focus();
    }
  }, [ref, active, pristineSeatSelection]);

  const seatColor = useMemo(
    () => (coach.classType.toLowerCase() === classTypes.premium ? color_base_black : color_maximum_blue),
    [coach.classType]
  );

  const handleMouseEnter = () => {
    if (unavailable) return;
    setHoverColor(coach.classType.toLowerCase() === classTypes.premium ? color_black : '#0B8FA7');
  };

  const handleMouseLeave = () => {
    if (active) return;
    setHoverColor(null);
  };

  let accessible = '';
  switch (seat.coach.coachNumber) {
    case '1':
      if (seat.number === '17A') accessible = ' wheelchair-accessible seat';
      break;
    case '2':
      if (seat.number === '1C' || seat.number === '1D') accessible = ' wheelchair-accessible seat';
      break;
    case '3':
      if (seat.number === '17A' || seat.number === '17B') accessible = ' wheelchair-accessible seat';
      break;
    case '4':
      if (seat.number === '1C' || seat.number === '1D') accessible = ' wheelchair-accessible seat';
      break;
    default:
      accessible = '';
  }

  const ariaLabelMessage = !selected
    ? `Change seat to class ${coach.classType}, seat number ${seat.number}, direction ${facingDirection}${
        accessible ? ', wheelchair-accessible seat' : ''
      }`
    : `Your currently selected seat is class ${coach.classType}, seat number ${
        seat.number
      }, direction ${facingDirection}${accessible ? ', wheelchair-accessible seat' : ''}`;

  return (
    <button
      key={seat.number}
      ref={active ? ref : null}
      style={seatStyle}
      className={bem({ facingDirection, active, class: seat.coach.classType, isAccountFlow })}
      tabIndex={unavailable ? -1 : 0}
      onClick={handleSeatClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      aria-label={ariaLabelMessage}
      aria-current={active}
      aria-disabled={unavailable}
    >
      {coach.classType.toLowerCase() === classTypes.smart ? (
        <SmartSeat
          className={bem('icon')}
          fillColor={unavailable ? color_light_silver : selected ? color_brand_yellow : hoverColor || seatColor}
          width={width}
          height={height}
        />
      ) : (
        <PremiumSeat
          className={bem('icon')}
          fillColor={unavailable ? color_light_silver : selected ? color_brand_yellow : hoverColor || seatColor}
          width={width}
          height={height}
        />
      )}
      {selected && <span className={bem('number')}>{number}</span>}
    </button>
  );
});
