import { useCallback, useEffect, useState } from 'react';

import { ImageProps } from '@atoms/Image/types';
import { TrainPassUrlParams } from '@organisms/TrainPasses/TrainPassesCard/types';
import {
  CategoriesConfig,
  MobileTrainPassUrlParams,
  PassesConfig,
  StationsConfig,
  TrainPassOptionConfig,
} from '@organisms/TrainPasses/types';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { base64Decode } from 'utils/helpers';

export const useTrainPassLogic = () => {
  const [initialFormValues, setInitialFormValues] = useState({
    travelFrom: '',
    travelTo: '',
    autoRenew: false,
    category: '',
    option: '',
  });

  const [step, setStep] = useState(0);
  const [total, setTotal] = useState(0);
  const [discountedTotal, setDiscountedTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [stations, setStations] = useState<StationsConfig[]>([]);
  const [categories, setCategories] = useState<CategoriesConfig[]>([]);
  const [options, setOptions] = useState<TrainPassOptionConfig[]>([]);
  const [configuredPassOptions, setConfiguredPassOptions] = useState<TrainPassOptionConfig[]>([]);

  const [parsedMobileUrlData, setParsedMobileUrlData] = useState<undefined | MobileTrainPassUrlParams>(undefined);

  const fetchTrainPassCategories = useCallback(async () => {
    try {
      setLoading(true);
      setError(false);
      const url = `${requestUrls.getRestUrl(requestUrls.products.trainPassCategories)}.xjson`;
      const response = await api.get(url);

      setStations(response.data.stations);
      setCategories(response.data.categories);
      setLoading(false);

      return response.data.categories;
    } catch (e: any) {
      console.error('error', e);
      setError(true);
      setLoading(false);
    }

    return {};
  }, []);

  const fetchTrainPassOptions = async (originStationId, destinationStationId, category) => {
    const params = {
      originStationId,
      destinationStationId,
      category,
    };
    setLoading(true);
    setError(false);
    try {
      const url = `${requestUrls.getRestUrl(requestUrls.products.trainPassOptions)}.xjson`;
      const response = await api.get(url, { params });
      setOptions(response.data.options);
      setLoading(false);

      return response.data.options;
    } catch (e: any) {
      console.error('error', e);
      setError(true);
      setLoading(false);
    }

    return {};
  };

  const fetchOptionsAndSetFormValues = useCallback(
    async initialData => {
      const trainPassCategories = await fetchTrainPassCategories();

      const category = trainPassCategories.find(category => category.name === initialData.category);
      if (category) {
        const trainPassOptions = await fetchTrainPassOptions(
          initialData.tpOriginID,
          initialData.tpDestinationID,
          category.code
        );
        const option = trainPassOptions.find(opt => opt.name === initialData.tpOriginalOptionName);

        setTotal(option.priceInCents / 100);

        if (!(initialData.autoRenew === 'true') || !option || !option.autoRenewOption) 
          setDiscountedTotal(0);
         else 
          setDiscountedTotal(option.autoRenewOption.priceInCents / 100);
        

        setInitialFormValues({
          travelFrom: initialData.tpOriginName,
          travelTo: initialData.tpDestinationName,
          category: initialData.category,
          autoRenew: initialData.autoRenew === 'true',
          option: initialData.tpOriginalOptionName,
        });
        setStep(3);
      }
    },
    [fetchTrainPassCategories]
  );

  useEffect(() => {
    let initialData: TrainPassUrlParams;
    const dataParam = new URL(location.href).searchParams.get('data');
    if (dataParam) 
      try {
        initialData = JSON.parse(base64Decode(dataParam));
        if (
          !initialData ||
          !initialData.totalPrice ||
          !initialData.tpOriginID ||
          !initialData.tpDestinationID ||
          !initialData.category ||
          !initialData.tpOriginalOptionName
        ) {
          console.error(`Invalid URL params ${initialData}`);
          fetchTrainPassCategories();
        } else 
          fetchOptionsAndSetFormValues(initialData);
        

        setParsedMobileUrlData({
          jwt: initialData.jwt,
          mobileEmail: initialData.mobileEmail,
          mobileCallbackURL: initialData.mobileCallbackURL,
        });
      } catch (e) {
        console.error(e);
      }
     else 
      fetchTrainPassCategories();
    
  }, [fetchOptionsAndSetFormValues, fetchTrainPassCategories]);

  const getTrainPassImage = (values: any, passesConfigurations: PassesConfig[], defaultImage: ImageProps) => {
    if (step < 3 || !values.option) return defaultImage;

    const selectedOption = options.find(option => option.name === values.option);
    const selectedPassConfig = passesConfigurations.find(option => option.passItem.pairKey === selectedOption?.code);

    if (!!selectedPassConfig && !!selectedPassConfig.passItem?.image?.src) 
      return selectedPassConfig.passItem.image;
    

    return defaultImage;
  };

  return {
    initialFormValues,
    step,
    total,
    discountedTotal,
    loading,
    error,
    stations,
    categories,
    options,
    parsedMobileUrlData,
    setStep,
    setTotal,
    setDiscountedTotal,
    fetchTrainPassCategories,
    fetchTrainPassOptions,
    configuredPassOptions,
    setConfiguredPassOptions,
    getTrainPassImage,
  };
};
