import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';
import moment from "moment";

import { cn } from 'utils/classNames';

import { WalletPassItemsProps } from '../WalletDesktop/types';

const bem = cn('account-pass-details');

export const CreditPassDetails = observer((props: WalletPassItemsProps) => {
  const { cfStore } = useStores();
  const { account } = cfStore;
  const { wallet } = account;
  const { item } = props;

  const expirationDate = item.expirationDate ? moment(item.expirationDate).format("MMMM D, YYYY") : wallet.noExpirationDateLabel ;

  if (!item) return null;

  const { creditAvailable, code } = item;
  
  return (
    <div className={bem('info')}>
      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.passTypeLabel}</h5>
        <div className={bem('value')}>{wallet.brightlineCreditLabel}</div>
      </div>
      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.creditIdLabel}</h5>
        <div className={bem('value')}>{code}</div>
      </div>
      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.creditRemainingLabel}</h5>
        <div className={bem('value')}>${creditAvailable?.toFixed(2)}</div>
      </div>
      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.expirationDateLabel}</h5>
        <div className={bem('value')}>{expirationDate}</div>
      </div>
    </div>
  );
});
