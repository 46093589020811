import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStores } from 'stores';

import LinkButton from '@atoms/LinkButton/LinkButton';
import { PrivacyRequestFormModal } from '@organisms/PrivacyRequestForm/components/PrivacyRequestFormModal/PrivacyRequestFormModal';
import { usePrivacyRequestFormLogic } from '@organisms/PrivacyRequestForm/usePrivacyRequestFormLogic';

import { isAuthorMode, isEditMode } from 'utils/metas';

import './PrivacyRequestForm.scss';
import PrivacyRequestSuccessfulComponent from './components/PrivacyRequestFormModal/PrivacyRequestSuccessfulComponent/PrivacyRequestSuccessfulComponent';
import { PrivacyRequestFormProps } from './types';

const PrivacyRequestForm = observer((props: PrivacyRequestFormProps) => {
  const { openModalButtonLabel, submitAnotherRequestButton } = props;

  const { authStore, accountStore } = useStores();

  const { fetchUserProfile } = accountStore.profile;

  const privacyRequestFormLogic = usePrivacyRequestFormLogic(props);

  const { isModalOpen, openModal, openModalButtonRef, submitMessage } = privacyRequestFormLogic;

  const showInAuthor = isAuthorMode() && !isEditMode();

  if (!authStore.isAuthenticated && !showInAuthor) return null;

  const { currentStep } = privacyRequestFormLogic;

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  return (
    <div>
      <LinkButton label={openModalButtonLabel} onClick={openModal} externalBtnRef={openModalButtonRef} underline />
      {isModalOpen &&
        (currentStep === 2 ? (
          <PrivacyRequestSuccessfulComponent
            {...props}
            submitAnotherRequestButton={submitAnotherRequestButton}
            submitSuccessMessage={submitMessage}
            privacyRequestFormLogic={privacyRequestFormLogic}
            showInAuthor={showInAuthor}
          />
        ) : (
          <PrivacyRequestFormModal
            {...props}
            privacyRequestFormLogic={privacyRequestFormLogic}
            showInAuthor={showInAuthor}
          />
        ))}
    </div>
  );
});

export default PrivacyRequestForm;
