import React from 'react';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import Image from '@atoms/Image/Image';
import { Title } from '@atoms/Title';
import VideoModal from '@molecules/VideoModal/VideoModal';
import { TextAndMediaProps } from '@organisms/TextAndMedia/types';

import { cn } from 'utils/classNames';

const bem = cn('text-and-media__media');

export const Media = (props: TextAndMediaProps) => {
  const { image, video, colors, imageTitle, imageDescription, imageButton, imageIcon } = props;
  const { playerIcon } = colors || {};

  if (image)
    return (
      <div className={bem()}>
        <div className={bem('attachment')}>
          <Image {...image} />
        </div>
        <div className={bem('imageContent')}>
          {imageIcon && imageIcon.name && <Icon className={bem('imageContent_imageIcon')} {...imageIcon} />}
          {imageTitle && imageTitle.text && <Title {...imageTitle} color={colors?.imageTitle} />}
          {imageDescription && imageDescription.text && (
            <div className={bem('imageContent_imageDescription')}>
              <Title {...imageDescription} />
            </div>
          )}
          {imageButton && imageButton.link && (
            <div className={bem('image-content-button')}>
              <Button {...imageButton} variant={imageButton.variant} />
            </div>
          )}
        </div>
      </div>
    );

  if (video)
    return (
      <div className={bem('attachment')}>
        <Image {...video.thumbnail} />
        <VideoModal {...video} playerIcon={playerIcon} />
      </div>
    );

  return null;
};
