import React from 'react';

import { cn } from 'utils/classNames';

import { AccountCardPlaceholderProps } from '../types';

const bem = cn('account-card');

export const CardPlaceholder = (props: AccountCardPlaceholderProps) => {
  const { variant, isWallet } = props;

  return (
    <div className={bem('placeholders')}>
      <div>
        <div />
        {!isWallet && <div />}
      </div>
      <div className={bem('placeholders-content', { variant: variant })}>
        <div />
        {variant === 'medium' && <div />}
      </div>
      {isWallet && <div className={bem('placeholders-button')} />}
    </div>
  );
};
