import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import { Navigation } from '../Navigation';
import './MyAccount.scss';

const bem = cn('my-account');

export const MyAccount = observer(() => {
  const { cfStore } = useStores();

  const { navigationMenuLabel, navigationMenuIcon, closeSidebarNavigationIcon } = cfStore.account.sidebarNavigation;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => {
    setIsModalVisible(true);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setIsModalVisible(false);
    }, 1000);
  };

  return (
    <>
      <div className={bem()} onClick={openModal}>
        <Icon className={bem('user-icon')} externalIcon={navigationMenuIcon.src} />
        <Text className={bem('title')} text={navigationMenuLabel} />
      </div>

      {isModalVisible && (
        <Modal
          className={bem('nav-modal', { isModalOpening: isModalOpen, isModalClosing: !isModalOpen })}
          onClose={closeModal}
        >
          <div className={bem('content')}>
            <div className={bem('close-button')} onClick={closeModal}>
              <Icon className={bem('close-icon')} externalIcon={closeSidebarNavigationIcon.src} />
            </div>
            <Navigation />
          </div>
        </Modal>
      )}
    </>
  );
});
