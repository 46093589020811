import React, { useEffect, useRef } from 'react';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import TitleWithStyleHOC from '@atoms/Title/TitleWithStyleHOC';

import { cn, cx } from 'utils/classNames';

import './Accordion.scss';
import { CLASSES } from './constants';
import { AccordionItemProps } from './types';

const bem = cn('accordion-item');

const AccordionItem = (props: AccordionItemProps) => {
  const { title, content, expanded, tabIndex, onToggle, size, fontVariant, id } = props;

  const contentRef = useRef<HTMLInputElement>(null);

  const trailingIcon = expanded ? 'arrow-up' : 'arrow-down';

  const handleKeyDown = e => {
    const enterPressed = e.keyCode === 13;
    if (enterPressed && onToggle) onToggle(e);
  };

  const contentContainsAsterisk = content.includes('*');

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = '';

      if (expanded) {
        contentRef.current.focus();
        contentRef.current.classList.add(CLASSES.autoHeight);
        const testHeight = `${contentRef.current.clientHeight + 8}px`;
        contentRef.current.classList.remove(CLASSES.autoHeight);
        contentRef.current.classList.add(CLASSES.contentExpanded);
        contentRef.current.style.maxHeight = testHeight;
      }
    }
  }, [expanded]);

  return (
    <div className={bem()} aria-expanded={expanded}>
      <h3>
        <button
          id={id}
          type="button"
          className={bem('title')}
          aria-controls={`accordion-content-${id}`}
          aria-expanded={expanded}
          tabIndex={tabIndex}
          onClick={onToggle}
          onKeyDown={handleKeyDown}
        >
          <TitleWithStyleHOC
            size={size}
            fontVariant={fontVariant}
            customClassName={bem('title__element')}
            text={title}
          />
          <span className={bem('trailing-icon')} aria-hidden="true">
            <Icon name={trailingIcon} />
          </span>
        </button>
      </h3>
      <Text
        ref={contentRef}
        as={contentContainsAsterisk ? 'a' : 'div'}
        hidden={!expanded}
        style={{ display: expanded ? 'block' : 'none' }}
        role="region"
        id={`accordion-content-${id}`}
        aria-labelledby={id}
        text={content}
        className={cx(bem('content', { expanded }), 'blte-font--variant-body-medium-400')}
        tabIndex={expanded ? -1 : 0}
      />
    </div>
  );
};

export default AccordionItem;
