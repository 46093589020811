import { isEmpty } from 'lodash';
import { makeAutoObservable } from 'mobx';
import moment from 'moment-timezone';

import { DropdownItem } from '@atoms/Dropdown/types';
import { TicketsFormValues } from '@molecules/TicketsForm/types';
import { UpsellModalComponentProps } from '@organisms/UpsellModal/types';

import { ANALYTICS_DATE_FORMAT, selectTrainEvent } from 'utils/adobeDataLayer';
import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { isAxiosGracefulError } from 'utils/helpers';
import { isBrandedFaresEnabled, isForcedLoginEnabled } from 'utils/metas';

import {
  clearPublicBookingToken,
  setPublicBookingToken,
  getPublicBookingToken,
  setBookingCreationTime,
} from '../../../../utils/localstorage';
import {
  createDatesPayload,
  getJourneySortingFunction,
  getPreviousFetchedDays,
  mapResponse,
} from './journeysSearchUtils';
import {
  FareSelectionTableConfig,
  ForcedLoginModalConfig,
  ModifyTripConfig,
  SearchRequestModifyTripPayload,
  SearchRequestPayload,
  SelectedItemConfig,
  SelectedTicket,
  TrainSavedItem,
  TrainSelectionErrorConfig,
  TrainSelectionItem,
} from './types';
import { Journey } from './types.journey';

export class Tickets {
  loading = true;
  data: TrainSelectionItem = {
    dateItems: [],
    journeys: [],
  };

  itemsPerViewPort = {
    leftRight: 3,
    visibleItems: 7,
  };

  itemsByDate: TrainSavedItem = {};
  inboundTicket: SelectedTicket | null = null;
  outboundTicket: SelectedTicket | null = null;
  selectedItem: SelectedItemConfig | null = null;
  outboundJourney: Journey | null = null;
  drawerVisible = false;
  premiumOnly = false;
  prevSearchBody: TicketsFormValues;
  activeDate = null;
  isFareSelected = false;
  fareTableDrawerVisible = false;
  farePriceDifference = 0;

  hasLoadedQueryParams = false;

  sortValue = 1;
  sortingAnimation = false;
  sortItems: DropdownItem[] = [];

  modifyTripProps: ModifyTripConfig | undefined = undefined;

  //Data for Branded Fares
  brandedFaresSwitch = isBrandedFaresEnabled();
  fareSelectionTable: FareSelectionTableConfig | undefined = undefined;
  upsellModal: UpsellModalComponentProps | undefined = undefined;

  //Data for Graceful Errors
  trainSelectionError: TrainSelectionErrorConfig | undefined = undefined;

  //Data for Forced Login
  forcedLoginModal: ForcedLoginModalConfig | undefined = undefined;
  forcedLoginError = false;

  private readonly rootStore;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  populateSortingDropdown(sortingLabels) {
    if (!sortingLabels || !sortingLabels.optionToSelectByDefault) return;

    this.sortValue = Number(sortingLabels.optionToSelectByDefault);
    if (isNaN(this.sortValue)) this.sortValue = 1;

    const dropdownItems = [
      { text: sortingLabels.sortByEarliestDepartureTimeLabel, value: 1 },
      { text: sortingLabels.sortByLatestDepartureTimeLabel, value: 2 },
      { text: sortingLabels.sortByLowestPriceLabel, value: 3 },
      { text: sortingLabels.sortByHighestPriceLabel, value: 4 },
    ];
    let defaultItemIndex = dropdownItems.findIndex(item => item.value === this.sortValue);
    if (defaultItemIndex === -1) defaultItemIndex = 0;

    this.sortItems = [
      dropdownItems[defaultItemIndex],
      ...dropdownItems.slice(0, defaultItemIndex),
      ...dropdownItems.slice(defaultItemIndex + 1),
    ];
  }

  setData({ dateItems, journeys }) {
    this.data = {
      dateItems,
      journeys,
    };
  }

  setPremiumOnly(premiumOnly) {
    this.premiumOnly = premiumOnly;
  }

  setModifyTripProps(modifyTripProps) {
    this.modifyTripProps = modifyTripProps;
  }

  setPrevSearchBody(prevSearchBody) {
    this.prevSearchBody = prevSearchBody;
  }

  setHasLoadedQueryParams(hasLoadedQueryParams) {
    this.hasLoadedQueryParams = hasLoadedQueryParams;
  }

  setActiveDate(activeDate) {
    this.activeDate = activeDate;
  }

  setIsFareSelected(isFareSelected) {
    this.isFareSelected = isFareSelected;
  }

  setItemsPerViewPort(itemsPerViewPort) {
    this.itemsPerViewPort = itemsPerViewPort;
  }

  setItemsByDate(itemsByDate) {
    this.itemsByDate = itemsByDate;
  }

  setOutboundTicket(outboundTicket) {
    this.outboundTicket = outboundTicket;
  }

  setOutboundJourney(outboundJourney) {
    this.outboundJourney = outboundJourney;
  }

  setSortValue(sortValue: number) {
    this.sortValue = sortValue;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setBrandedFaresSwitch(brandedFareSwitch: boolean) {
    this.brandedFaresSwitch = brandedFareSwitch;
  }

  setFareSelectionTable(fareSelectionTable?: FareSelectionTableConfig) {
    this.fareSelectionTable = fareSelectionTable;
  }

  setUpsellModal(upsellModal?: UpsellModalComponentProps) {
    this.upsellModal = upsellModal;
  }

  setForcedLoginModal(forcedLoginModal?: ForcedLoginModalConfig) {
    this.forcedLoginModal = forcedLoginModal;
  }

  setForcedLoginError(forcedLoginError: boolean) {
    this.forcedLoginError = forcedLoginError;
  }

  setFarePriceDifference(fareVariant, prices) {
    this.farePriceDifference =
      fareVariant === 'SMART_STANDARD'
        ? prices.PREMIUM_STANDARD - prices[fareVariant]
        : prices.SMART_STANDARD - prices[fareVariant];
  }

  resetData() {
    this.itemsByDate = {};
    this.outboundTicket = null;
    this.inboundTicket = null;
    this.outboundJourney = null;
    this.fareSelectionTable = undefined;
    this.data = {
      dateItems: [],
      journeys: [],
    };
  }

  setDrawerVisibility(state) {
    this.drawerVisible = state;
  }

  setFareTableDrawerVisible(state) {
    this.fareTableDrawerVisible = state;
  }

  setTrainSelectionError(trainSelectionError?: any) {
    if (!trainSelectionError) this.trainSelectionError = undefined;
    else if (!isAxiosGracefulError(trainSelectionError))
      this.trainSelectionError = {
        header: '',
        body: '',
        code: '',
      };
    else
      this.trainSelectionError = {
        header: '',
        body: '',
        code: trainSelectionError?.response?.data?.code?.toString(),
      };
  }

  async searchTickets(body: TicketsFormValues) {
    this.setPrevSearchBody(body);
    const travelDirection = this.outboundTicket ? 'inbound' : 'outbound';
    const isOutbound = travelDirection === 'outbound';

    const dayToProcess = isOutbound ? moment(body.startDate) : moment(body.endDate);

    const { startDate, endDate, days, unavailableDates } = createDatesPayload(
      dayToProcess,
      this.itemsPerViewPort.leftRight, //num items on left and on right for carousel
      this.itemsByDate,
      this.outboundTicket
    );

    const fetchedDays: TrainSavedItem[] = getPreviousFetchedDays(
      this.itemsByDate,
      startDate,
      endDate,
      unavailableDates
    );

    const params: SearchRequestPayload = {
      originID: isOutbound ? body.from : body.to,
      destinationID: isOutbound ? body.to : body.from,
      dates: days,
      travelDirection,
      promoCode: body.promoCode,
      adults: body.adults,
      children: body.kids,
      infants: body.infants,
      wcAdults: body.wcAdults,
      wcChildren: body.wcChildren,
      contractAccessCode: body.contractAccessCode || '',
    };

    this.setLoading(true);

    try {
      const publicBookingToken = getPublicBookingToken();

      const response = days.length
        ? await api.post(`${requestUrls.getRestUrl(requestUrls.bookingSearch.journeys)}.xjson`, params, {
            headers: !isEmpty(publicBookingToken)
              ? {
                  'Booking-Session-Access-Token': publicBookingToken.accessToken,
                  'Booking-Session-Refresh-Token': publicBookingToken.refreshToken,
                }
              : {},
          })
        : null;

      const { newItemsByDate, dateItems, journeys } = mapResponse(
        this.itemsByDate,
        response?.data || {},
        dayToProcess,
        fetchedDays,
        this.outboundTicket
      );

      this.setItemsByDate(newItemsByDate);

      const sortingFunction = getJourneySortingFunction(this.sortValue);
      const sortedJourneys = journeys.sort(sortingFunction);

      this.setData({
        dateItems,
        journeys: sortedJourneys,
      });

      const lastDay = days[days.length - 1];
      if (newItemsByDate[lastDay]?.bookingSession?.tokenType?.toLowerCase() === 'public')
        setPublicBookingToken(newItemsByDate[lastDay].bookingSession);

      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      clearPublicBookingToken();
      this.setLoading(false);
      this.setTrainSelectionError(e);
    }
  }

  async searchTicketsModifyTrip(body: ModifyTripConfig) {
    this.setPrevSearchBody(body);

    let travelDirection: 'inbound' | 'outbound';
    if (body.startDate && body.endDate) travelDirection = this.outboundTicket ? 'inbound' : 'outbound';
    else if (body.startDate && !body.endDate) travelDirection = 'outbound';
    else if (!body.startDate && body.endDate) travelDirection = 'inbound';
    else throw 'Start date or end date are mandatory params when modifying a trip';

    const isOutbound = travelDirection === 'outbound';

    const dayToProcess = isOutbound ? moment(body.startDate) : moment(body.endDate);

    const { startDate, endDate, days, unavailableDates } = createDatesPayload(
      dayToProcess,
      this.itemsPerViewPort.leftRight,
      this.itemsByDate,
      this.outboundTicket,
      {
        tripDetails: body.tripDetails,
        startDate: body.startDate,
        endDate: body.endDate,
      }
    );

    const fetchedDays: TrainSavedItem[] = getPreviousFetchedDays(
      this.itemsByDate,
      startDate,
      endDate,
      unavailableDates
    );

    const reqBody: SearchRequestModifyTripPayload = {
      dates: days,
    };

    this.setLoading(true);

    try {
      const response = days.length
        ? await api.put(`${requestUrls.getRestUrl(requestUrls.bookingSearch.journeys)}.${body.rn}.xjson`, reqBody, {
            params: {
              travelDirection,
            },
            headers: {
              'Booking-Session-Access-Token': body.bookingSession.accessToken,
              'Booking-Session-Refresh-Token': body.bookingSession.refreshToken,
            },
          })
        : null;

      const { newItemsByDate, dateItems, journeys } = mapResponse(
        this.itemsByDate,
        response?.data || {},
        dayToProcess,
        fetchedDays,
        this.outboundTicket,
        {
          tripDetails: body.tripDetails,
          startDate: body.startDate,
          endDate: body.endDate,
        }
      );

      this.setItemsByDate(newItemsByDate);

      const sortingFunction = getJourneySortingFunction(this.sortValue);
      const sortedJourneys = journeys.sort(sortingFunction);

      this.setData({
        dateItems,
        journeys: sortedJourneys,
      });

      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      this.setLoading(false);
      this.setTrainSelectionError(e);
    }
  }

  handleSortingAnimationEnded() {
    this.sortingAnimation = false;
  }

  selectTrainAnalyticsEvent(outboundTicket, outboundJourney, inboundTicket?, inboundJourney?) {
    const departureTrip = {
      class: outboundTicket.seatingClassType,
      productId: outboundTicket.productId,
      date: moment(outboundTicket.departureDate).tz(outboundTicket.originTimeZone).format(ANALYTICS_DATE_FORMAT),
      destinationCity: outboundJourney.destination.name,
      destinationState: null,
      originCity: outboundJourney.origin.name,
      originState: null,
      price: parseFloat(outboundTicket.displayedPrice.toFixed(2)),
      time: moment(outboundTicket.departureDate).tz(outboundTicket.originTimeZone).format('h:mma'),
    };

    const returnTrip = inboundTicket
      ? {
          class: inboundTicket.seatingClassType,
          productId: inboundTicket.productId,
          date: moment(inboundTicket.departureDate).tz(inboundTicket.destinationTimeZone).format(ANALYTICS_DATE_FORMAT),
          destinationCity: inboundJourney.destination.name,
          destinationState: null,
          originCity: inboundJourney.origin.name,
          originState: null,
          price: parseFloat(inboundTicket.displayedPrice.toFixed(2)),
          time: moment(inboundTicket.departureDate).tz(inboundTicket.originTimeZone).format('h:mma'),
        }
      : null;

    const eventInfo = {
      trainSelection: {
        departureTrip,
        returnTrip,
      },
    };
    selectTrainEvent(eventInfo, this.rootStore.authStore.isAuthenticated);
  }

  selectItem(journey, defaultProduct) {
    const { seatProducts } = journey;
    const seatProduct = seatProducts.find(el => el.id === defaultProduct?.productId);
    const prices = seatProducts.reduce(
      (prev, cur) => ({
        ...prev,
        [cur.id]: cur.displayedPrice,
        [`original${cur.id}`]: cur.displayedOriginalPrice,
      }),
      {}
    );

    this.selectedItem = {
      journey,
      ticket: {
        id: journey.id,
        productId: defaultProduct.productId,
        originId: journey.origin.id,
        destinationId: journey.destination.id,
        destinationTimeZone: journey.destination.timeZone,
        originTimeZone: journey.origin.timeZone,
        departureDateTimeZone: moment(journey.departureTime).tz(journey.origin.timeZone).format(),
        departureDate: journey.departureTime,
        seatingClassType: seatProduct?.seatingClassType,
        displayedPrice: seatProduct?.displayedPrice,
        displayedOriginalPrice: seatProduct?.displayedOriginalPrice,
        displayedChildPrice: seatProduct?.displayedChildPrice,
        departureTime: journey.departureTime,
        arrivalTime: journey.arrivalTime,
        serviceType: journey.type,
        serviceName: journey.name,
        serviceId: journey.id,

        passengers: seatProduct?.fares?.map(fare => ({
          passengerId: fare.passengerId,
          type: fare.passengerType,
          tariffCode: fare.tariffCode,
          wheelchairAccommodation: fare.wheelchairAccommodation,
        })),
      },
      prices,
    };

    //Currently fare price difference is only needed for smart saver, the implementation is general in case it is needed for other fares in the future
    defaultProduct?.productId == 'SMART_SAVER' && this.setFarePriceDifference(defaultProduct?.productId, prices);
  }

  async confirmTicket(cb) {
    try {
      const { ticket, journey } = this.selectedItem as SelectedItemConfig;
      const { ticketsFormStore, bookingStore } = this.rootStore;
      const { formValues } = ticketsFormStore;
      const { createProvisionalBooking } = bookingStore.rti;
      const { modifyExistingTrip } = bookingStore.rti;

      const isRoundTrip = this.modifyTripProps
        ? this.modifyTripProps.isRoundTrip
        : !!(formValues.startDate && formValues.endDate);

      if (isRoundTrip)
        if (!this.outboundTicket) {
          //ROUND TRIP - OUTBOUND ticket selected
          this.setOutboundTicket(ticket);
          this.setOutboundJourney(journey);

          this.setItemsByDate({});

          const searchDate = moment((journey as Journey).departureTime, 'YYYY-MM-DD')
            .tz((journey as Journey).origin.timeZone)
            .isAfter(moment(formValues?.endDate, 'YYYY-MM-DD'), 'day')
            ? moment((journey as Journey).departureTime)
                .tz((journey as Journey).origin.timeZone)
                .format('YYYY-MM-DD')
            : moment(formValues.endDate).format('YYYY-MM-DD');

          this.setDrawerVisibility(false);

          if (!this.modifyTripProps) await this.searchTickets({ ...formValues, endDate: searchDate });
          else await this.searchTicketsModifyTrip(this.modifyTripProps);
        } else {
          //ROUND TRIP - INBOUND ticket selected
          this.setDrawerVisibility(false);

          let bookingResponse;

          // CREATE BOOKING FLOW
          if (!this.modifyTripProps) {
            this.selectTrainAnalyticsEvent(this.outboundTicket, this.outboundJourney, ticket, journey);

            // FORCED LOGIN FLOW
            if (isForcedLoginEnabled() && !this.rootStore.authStore.isAuthenticated) {
              this.setForcedLoginModal({
                outboundTicket: this.outboundTicket,
                inboundTicket: ticket,
                promoCode: formValues.promoCode,
              });

              return;
            }

            // GUEST FLOW
            bookingResponse = await createProvisionalBooking(
              this.outboundTicket,
              ticket as SelectedTicket,
              formValues.promoCode
            );

            setBookingCreationTime({
              bookingNumber: bookingResponse?.tripDetails?.trip.referenceNumber,
              createdAt: Date.now(),
            });
          }
          // MODIFY TRIP FLOW
          else
            bookingResponse = await modifyExistingTrip(
              this.modifyTripProps,
              this.outboundTicket,
              ticket as SelectedTicket
            );

          this.setHasLoadedQueryParams(false);

          // eslint-disable-next-line callback-return
          if (cb) cb(bookingResponse);
        }
      //SINGLE TICKET SELECTED
      else {
        this.setDrawerVisibility(false);

        let bookingResponse;

        // CREATE BOOKING FLOW
        if (!this.modifyTripProps) {
          // FORCED LOGIN FLOW
          this.selectTrainAnalyticsEvent(ticket, journey);
          if (isForcedLoginEnabled() && !this.rootStore.authStore.isAuthenticated) {
            this.setForcedLoginModal({
              outboundTicket: ticket as SelectedTicket,
              promoCode: formValues.promoCode,
            });

            return;
          }

          // GUEST FLOW
          bookingResponse = await createProvisionalBooking(ticket as SelectedTicket, undefined, formValues.promoCode);

          setBookingCreationTime({
            bookingNumber: bookingResponse?.tripDetails?.trip.referenceNumber,
            createdAt: Date.now(),
          });
        }
        // MODIFY TRIP FLOW
        else {
          const outboundTicket = this.modifyTripProps.startDate ? ticket : undefined;
          const inboundTicket = this.modifyTripProps.endDate ? ticket : undefined;
          bookingResponse = await modifyExistingTrip(this.modifyTripProps, outboundTicket, inboundTicket);
        }

        this.setHasLoadedQueryParams(false);

        // eslint-disable-next-line callback-return
        if (cb && !this.trainSelectionError) cb(bookingResponse);
      }
    } catch (e) {
      console.error(e);
      this.setTrainSelectionError(e);
    }
  }

  modifyTicket() {
    try {
      const { ticketsFormStore } = this.rootStore;
      const { formValues } = ticketsFormStore;

      this.setOutboundTicket(null);
      this.setItemsByDate({});
      if (!this.modifyTripProps) this.searchTickets(formValues);
      else this.searchTicketsModifyTrip(this.modifyTripProps);
    } catch (e) {
      console.error(e);
      this.setTrainSelectionError(e);
    }
  }

  applySort() {
    try {
      const journeySortFunction = getJourneySortingFunction(this.sortValue);
      const sortedJourneys = [...this.data.journeys].sort(journeySortFunction);

      this.sortingAnimation = true;

      //Delay setting of sorted data to have old data on slide up animation and new data on slide down animation
      setTimeout(() => {
        this.setData({
          dateItems: this.data.dateItems,
          journeys: sortedJourneys,
        });
      }, 500);
    } catch (e) {
      console.error(e);
      this.setTrainSelectionError(e);
    }
  }
}
