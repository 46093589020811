import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { passengerTypes } from '@booking/routes/RTI/PassengersInfo/constants';
import { SeatingNotAvailable } from '@booking/routes/RTI/SeatSelection/components/SeatingNotAvailable';

import { cn } from 'utils/classNames';

import { TripContentProps } from '../../types';
import { TripLine } from '../TripLine';
import { TripSegment } from '../TripSegment';
import './TripContent.scss';

export const bem = cn('trip-content');

export const TripContent = observer((props: TripContentProps) => {
  const { trip, passengerSeatingDetails, mode, editLabel = 'Edit Seats', saveLabel = 'Save and Continue' } = props;
  const { coachTextLabel, forwardFacingLabel, backwardFacingLabel, windowLabel, seatLabel, aisleLabel } = props;

  const { bookingStore } = useStores();
  const { provisionalBooking } = bookingStore.rti;
  const { isOutboundSeatingAvailable, isInboundSeatingAvailable } = bookingStore.rti.seats;

  const { outboundRoute, inboundRoute } = provisionalBooking?.tripDetails?.trip || {};

  const isSeatingNotAvailable = !isOutboundSeatingAvailable && !isInboundSeatingAvailable;
  const isSmartSaver =
    outboundRoute?.productId === 'SMART_SAVER' && (inboundRoute ? inboundRoute?.productId === 'SMART_SAVER' : true);

  const validPassengers = useMemo(
    () => passengerSeatingDetails?.filter(item => item.passenger.type !== passengerTypes.infant),
    [passengerSeatingDetails]
  );

  return (
    <div className={bem({ state: mode })}>
      {isSeatingNotAvailable && <SeatingNotAvailable mode={mode} isSmartSaver={isSmartSaver} />}
      {!isSeatingNotAvailable && trip?.outboundRoute?.id && (
        <TripSegment key="outbound" route={trip.outboundRoute} status={trip.outboundRouteStatus}>
          {validPassengers?.map((item, index) => {
            if (!isOutboundSeatingAvailable && index !== 0) return <></>;

            return (
              <TripLine
                key={`${item.passenger.email}-${index}`}
                passenger={item.passenger}
                seat={item.outboundSeat}
                seatingAvailable={isOutboundSeatingAvailable}
                isSmartSaver={outboundRoute?.productId == 'SMART_SAVER'}
                coachTextLabel={coachTextLabel}
                forwardFacingLabel={forwardFacingLabel}
                backwardFacingLabel={backwardFacingLabel}
                windowLabel={windowLabel}
                seatLabel={seatLabel}
                aisleLabel={aisleLabel}
                editLabel={editLabel}
                saveLabel={saveLabel}
              />
            );
          })}
        </TripSegment>
      )}
      {!isSeatingNotAvailable && trip?.inboundRoute?.id && (
        <TripSegment key="inbound" route={trip.inboundRoute} status={trip.inboundRouteStatus}>
          {validPassengers?.map((item, index) => {
            if (!isInboundSeatingAvailable && index !== 0) return <></>;

            return (
              <TripLine
                key={`${item.passenger.email}-${index}`}
                passenger={item.passenger}
                seat={item.inboundSeat}
                seatingAvailable={isInboundSeatingAvailable}
                isSmartSaver={inboundRoute?.productId == 'SMART_SAVER'}
                coachTextLabel={coachTextLabel}
                forwardFacingLabel={forwardFacingLabel}
                backwardFacingLabel={backwardFacingLabel}
                windowLabel={windowLabel}
                seatLabel={seatLabel}
                aisleLabel={aisleLabel}
                editLabel={editLabel}
                saveLabel={saveLabel}
              />
            );
          })}
        </TripSegment>
      )}
    </div>
  );
});
