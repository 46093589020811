import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo, useRef } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';
import { ParkingModal } from '@booking/routes/RTI/Extras/components/Parking/components/Modal';
import { ParkingItemProps } from '@booking/routes/RTI/Extras/types';

import { cn } from 'utils/classNames';

const bem = cn('rti-extras');

export const ParkingItem = observer((props: ParkingItemProps) => {
  const { type, expanded } = props;
  const { cfStore, bookingStore } = useStores();
  const { extras } = cfStore.rti;
  const { data, modalVisible, showModal, getTotalPrice } = bookingStore.parking;

  const cfParking = extras.typeLabels.find(item => item.type.endsWith(type));
  const onCloseRef = useRef<HTMLButtonElement>(null);

  const minPrice = useMemo(() => {
    if (!data) return 0;

    const { eligibleDrivers } = data;

    return eligibleDrivers.reduce((lacc, driver) => {
      if (!lacc || lacc > driver.pricePerDay) return driver.pricePerDay;

      return lacc;
    }, 0);
  }, [data]);

  if (!data) return null;

  if (data.station.id === 'MCO') return null;

  const hasEligibleDrivers = useMemo(() => {
    const { eligibleDrivers } = data;

    return eligibleDrivers.some(driver => driver.currentSelections);
  }, [data]);

  const config = useMemo(
    () => ({
      buttonLabel: hasEligibleDrivers ? cfStore.generic.editLabel : cfStore.generic.addLabel,
      buttonIcon: hasEligibleDrivers ? '' : 'plus-slim',
    }),
    [cfStore.generic.addLabel, cfStore.generic.editLabel, hasEligibleDrivers]
  );

  const calculateDays = currentSelections => {
    if (!currentSelections) return 0;

    const startTime = moment.utc(currentSelections.startDate);
    const endTime = moment.utc(currentSelections.endDate);
    const diff = endTime.diff(startTime, 'days', false) + 1;

    return diff < 1 ? 1 : diff;
  };

  const showStartEndDate = currentSelections => {
    const timeZone = data?.station.timeZone || 'America/New_York';
    const startDate = moment(currentSelections?.startDate).tz(timeZone);
    const endDate = moment(currentSelections?.endDate).tz(timeZone);

    if (startDate.month() == endDate.month()) return `${startDate.format('MMM D')}-${endDate.format('D')}`;

    return `${startDate.format('MMM D')}-${endDate.format('MMM D')}`;
  };

  const showPassengerDetails = () => (
    <div className={bem('items-list no-gap')}>
      <Text text={data.station.name} className={bem('item-title')} />
      {data.eligibleDrivers.map(driver => {
        if (!driver.currentSelections) return null;

        return (
          <div key={driver.passengerId} className={bem('item-row')}>
            <div className={bem('item-details-row')}>
              <div className={bem('item-details')}>
                <Text text={showStartEndDate(driver.currentSelections)} className={bem('item-details-text')} />
                <Text text={driver.passengerName} className={bem('item-details-text')} />
                <Text text={driver.currentSelections?.licensePlate?.number} className={bem('item-details-text')} />
              </div>
              <Text
                text={`$${driver.pricePerDay.toFixed(2)} x ${calculateDays(driver.currentSelections)} days`}
                className={bem('item-details-text')}
              />
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <div
        className={bem('item-card', { collapsed: !expanded, completed: hasEligibleDrivers })}
        role="group"
        aria-label="parking item"
      >
        {expanded && (
          <div className={bem('item-card-image')}>
            {cfParking?.image && <Image {...cfParking.image} alt={cfParking.imageAlt} />}
          </div>
        )}

        {expanded && hasEligibleDrivers && (
          <div className={bem('completed-icon')}>
            <p className="sr-only">{`There are currently items of type ${cfParking?.label} added to the cart`}</p>
            <Icon name="check-circle" />
          </div>
        )}

        <div className={bem('item-info', { expanded })}>
          <div>
            <div className={bem('item-info-title')}>
              <div className={bem('item-info-title-row')}>
                <Text text={cfParking?.label} className={bem('item-info-title-text')} />
                {!expanded && !hasEligibleDrivers && (
                  <div className={bem('item-offer-price')}>
                    <div>{cfStore.generic.fromLabel}</div>
                    <div className={bem('item-offer-price-amount')}>${minPrice.toFixed(2)}</div>
                  </div>
                )}
              </div>
              {!expanded && !hasEligibleDrivers && (
                <Button
                  icon={config.buttonIcon}
                  label={config.buttonLabel}
                  color={expanded || !hasEligibleDrivers ? 'black' : 'blue'}
                  variant={expanded || !hasEligibleDrivers ? 'primary' : 'secondary'}
                  size="small"
                  onClick={showModal}
                  externalBtnRef={onCloseRef}
                />
              )}
            </div>
            {!hasEligibleDrivers && expanded && <div>{cfParking?.description}</div>}
            {hasEligibleDrivers && showPassengerDetails()}
          </div>
          {(expanded || hasEligibleDrivers) && (
            <div className={bem('item-offer', { collapsed: !expanded })}>
              {hasEligibleDrivers ? (
                <div className={bem('item-offer-price')}>
                  <div>{cfStore.generic.totalLabel}</div>
                  <div className={bem('item-offer-price-amount')}>${getTotalPrice().toFixed(2)}</div>
                </div>
              ) : (
                <div className={bem('item-offer-price')}>
                  <div>{cfStore.generic.fromLabel}</div>
                  <div className={bem('item-offer-price-amount')}>${minPrice.toFixed(2)}</div>
                </div>
              )}
              {(expanded || hasEligibleDrivers) && (
                <Button
                  icon={config.buttonIcon}
                  label={config.buttonLabel}
                  color={expanded || !hasEligibleDrivers ? 'black' : 'blue'}
                  variant={expanded || !hasEligibleDrivers ? 'primary' : 'secondary'}
                  size="small"
                  onClick={showModal}
                  externalBtnRef={onCloseRef}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {modalVisible && <ParkingModal onCloseRef={onCloseRef} />}
    </>
  );
});
