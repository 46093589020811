import React, { useId } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { WidgetInput } from '@atoms/WidgetInput/WidgetInput';

import { cn, cx } from 'utils/classNames';

import LocationItem from '../LocationItem/LocationItem';
import { MobileSelectProps } from '../types';
import './MobileSelect.scss';

const bem = cn('od-mobile-select');
const bemField = cn('od-select');

const MobileSelect = (props: MobileSelectProps) => {
  const labelID = useId();

  const { selectIndex, value, items, focus, active, label, placeholder, locationHint } = props;
  const { textInput = '', error, onHeaderClick, handleItemClick } = props;
  const { resetActive, onChangeTextInput, onClearClick, setFocus, position } = props;
  const { isRequired = false, addErrorId } = props;

  const shouldFocus = itemIndex => focus?.index === itemIndex && !focus?.preventScroll && active;
  const shouldFocusInput = active && focus?.index === -1;
  const selectedItem = items.find(item => item.id === value);
  const filteredItems = items.filter(item => item.name.toLowerCase().indexOf(textInput.toLowerCase()) > -1) || [];

  const handleResetIndex = () => active && resetActive();

  const handleHeaderClick = () => {
    if (setFocus) setFocus({ index: -1, preventScroll: false });
    onHeaderClick(selectIndex);
  };

  const handleClearInHeader = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClearClick && onClearClick(e);
  };

  return (
    <div className={cx(bem(), bemField())}>
      <WidgetInput
        isInvalid={error}
        label={label}
        onInputClick={handleHeaderClick}
        onInputChange={onChangeTextInput}
        onClearClick={handleClearInHeader}
        value={selectedItem?.name || textInput}
        placeholder={placeholder}
        focus={shouldFocusInput}
        position={position}
        isRequired={isRequired}
        addErrorId={addErrorId}
        ariaAutocomplete="list"
        ariaControls={`results-${labelID}`}
        ariaHaspopup="listbox"
        ariaDescribedbyWidget={`instructions-${labelID}`}
        ariaExpanded={active}
        ariaActivedescendant={selectedItem ? `loc-item-${selectIndex}-${selectedItem.id}` : ''}
        generatedId={labelID}
        readOnly
        includeAriaAttributes
      />

      <div id={`instructions-${labelID}`} className="sr-only">
        Use up and down arrow keys to navigate results, Enter to select.
      </div>
      <div className="sr-only" aria-live="assertive">
        {active && !filteredItems.length && `No results found`}
        {active && filteredItems.length === 1 && `One result found`}
        {active && filteredItems.length > 1 && `${filteredItems.length} results found`}
      </div>

      <div className={bem('list', { active: active })}>
        <OutsideClickHandler onOutsideClick={handleResetIndex}>
          <ul
            className={bem('listItems')}
            id={`results-${labelID}`}
            role="listbox"
            aria-labelledby={`input-${labelID}`}
          >
            {filteredItems.map((option, itemIndex) => (
              <LocationItem
                key={`loc-item-${option.id}`}
                id={`loc-item-${selectIndex}-${option.id}`}
                locationHint={locationHint}
                focus={shouldFocus(itemIndex)}
                selected={selectedItem?.id === option.id}
                option={option}
                itemIndex={itemIndex}
                handleItemClick={handleItemClick}
              />
            ))}
          </ul>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default MobileSelect;
