import React from 'react';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { ZendeskButtonProps } from './types';
import { useZendeskLogic } from './useZendeskLogic';

const bem = cn('zendesk');

export const ZendeskButton = (props: ZendeskButtonProps) => {
  const { zendeskDesktopVisibility, zendeskMobileVisibility, zendeskText } = props;
  const zendeskLogic = useZendeskLogic({ zendeskDesktopVisibility, zendeskMobileVisibility });
  const { unreadCount, openWidget, closeWidget, showZendeskButton } = zendeskLogic;

  return (
    <div
      className={bem({
        zendeskDesktopVisibility: showZendeskButton ? 'show' : 'hide',
        zendeskMobileVisibility: showZendeskButton ? 'show' : 'hide',
      })}
    >
      <button id="open-zendesk-button" onClick={openWidget}>
        <Icon name="zendesk-question" className={bem('questionmark')} ariaHidden />
        <span className={bem('need-help-label')}>{zendeskText}</span>
        <span id="unread-indicator" style={{ display: 'none' }}>
          {unreadCount > 0 ? unreadCount : ''}
        </span>
      </button>
      <button id="close-zendesk-button" style={{ display: 'none' }} onClick={closeWidget}>
        <Icon name="arrow-down" className={bem('close-icon')} ariaHidden />
      </button>
    </div>
  );
};
