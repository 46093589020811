import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PhoneNumberProps } from 'stores/types/types.passengers';
import * as Yup from 'yup';

import { dateConfig } from './constants';

export const getValidationSchema = i18n =>
  Yup.object().shape({
    firstName: Yup.string().required(i18n.requiredInputLabel),
    lastName: Yup.string().required(i18n.requiredInputLabel),
    type: Yup.string().required(i18n.requiredInputLabel),
    dateOfBirth: Yup.date()
      .transform((_, originalValue) => {
        dayjs.extend(customParseFormat);
        const result = dayjs(originalValue, dateConfig.dateFormat, true);
        if (!result.isValid()) return dateConfig.maxDate.add(1, 'day').toDate();

        return result.toDate();
      })
      .typeError(i18n.invalidInputLabel)
      .max(dateConfig.maxDate, i18n.requiredInputLabel),
    email: Yup.string()
      .test('email', i18n.requiredInputLabel, (email, meta) => {
        if (meta.path.indexOf('passengers[0]') > -1) return !!email;

        return true;
      })
      .email(i18n.invalidInputLabel),
    phoneNumber: Yup.object().test('phoneNumber', i18n.invalidInputLabel, (value: PhoneNumberProps) => {
      if (value.number) return value.number.length > 3;

      return true;
    }),
  });
