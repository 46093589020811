import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { isKiosk } from 'utils/isKiosk';
import { getHafasUserId, setHafasUserId } from 'utils/localstorage';

export class Notifications {
  async sendDelayEmailNotifiactions(
    bookingNumber: string,
    isAuthenticated: boolean,
    isModify: boolean,
    smsNotifications: boolean
  ) {
    try {
      const payload = {
        bookingNumber,
        platform: 'web',
        optInSMS: smsNotifications,
      };

      let response;

      if (!isAuthenticated) {
        const headers =
          !isKiosk() && getHafasUserId()
            ? {
                Authorization: 'Bearer anonymous',
                hafasUserId: getHafasUserId(),
              }
            : {
                Authorization: 'Bearer anonymous',
              };

        response = isModify
          ? await api.put(requestUrls.getBffUrl(requestUrls.notifications.pushNotification, true), payload, { headers })
          : await api.post(requestUrls.getBffUrl(requestUrls.notifications.pushNotification, true), payload, {
              headers,
            });
      } else
        response = isModify
          ? await api.put(requestUrls.getBffUrl(requestUrls.notifications.pushNotification, true), payload)
          : await api.post(requestUrls.getBffUrl(requestUrls.notifications.pushNotification, true), payload);

      !isKiosk() && response?.data?.hafasUser?.hafasUserId && setHafasUserId(response.data.hafasUser.hafasUserId);
    } catch (error: any) {
      console.error('Failed to send notification:', error);
    }
  }

  async cancelDelayEmailNotifiactions(bookingNumber, isAuthenticated, smsNotifications) {
    try {
      const payload = {
        bookingNumber: bookingNumber,
        platform: 'web',
        otInSMS: smsNotifications,
      };

      if (!isAuthenticated) {
        const headers = getHafasUserId()
          ? {
              Authorization: 'Bearer anonymous',
              hafasUserId: getHafasUserId(),
            }
          : {
              Authorization: 'Bearer anonymous',
            };

        await api.delete(requestUrls.getBffUrl(requestUrls.notifications.cancelNotification, true), {
          headers,
          data: payload,
        });
      } else
        await api.delete(requestUrls.getBffUrl(requestUrls.notifications.cancelNotification, true), { data: payload });
    } catch (error) {
      console.error('Failed to delete notification:', error);
    }
  }
}
