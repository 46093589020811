import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { TrainStatusPill } from '@account/routes/MyTrips/components/UpcomingTrips/components/TripItem/components/TrainStatusPIll/TrainStatusPill';
import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';
import { TIME_FORMAT } from 'utils/constants';
import { mediaQueryList } from 'utils/mediaQueries';
import { isDelayExperienceEnabled } from 'utils/metas';

import './TripDetailsRoute.scss';
import { TripDetailsRouteProps } from './types';

const bem = cn('trip-details-route');

export const TripDetailsRoute = observer((props: TripDetailsRouteProps) => {
  const { route } = props;
  const { isReaccommodated, previousTimes, productId } = route;
  const { cfStore } = useStores();
  const {
    tripDetails,
    tripDetails: { classTypes },
  } = cfStore.account;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);
  const delayExperienceEnabled = isDelayExperienceEnabled();

  const disabled = route?.isCancelled || (route?.departureDateTime && new Date(route?.departureDateTime) < new Date());
  const disabledMessage = route.isCancelled ? tripDetails.cancelMessage : tripDetails.departedMessage;

  const travelDuration = useMemo(() => {
    const startTime = moment(route.arrivalDateTime);
    const endTime = moment(route.departureDateTime);

    // calculate total duration
    const duration = moment.duration(startTime.diff(endTime));

    // duration in hours
    const hours = Math.abs(parseInt(duration.asHours().toString()));

    // duration in minutes
    const minutes = Math.abs(parseInt(duration.asMinutes().toString()) % 60);

    return hours > 0 ? `${hours}h${minutes}min` : `${minutes}min`;
  }, [route]);

  const boardingCloseTimeValue = useMemo(
    () =>
      `${cfStore.account.tripDetails.boardingCloseLabel} ${moment(route.departureDateTime)
        .tz(route.origin.timeZone)
        .subtract(5, 'minutes')
        .format(TIME_FORMAT)}`,
    [route, cfStore.account.tripDetails]
  );

  const classTagLabel = useMemo(() => {
    const matchingClassType = classTypes.find(item => item.classTypeName?.toLowerCase() === productId?.toLowerCase());

    return matchingClassType?.classTypeLabel || productId || '';
  }, [classTypes, productId]);

  const isCompleted = delayExperienceEnabled && route.trainStatus == 'completed';

  return (
    <div className={bem()}>
      <div className={bem('content', { disabledClass: disabled || isCompleted })}>
        <div className={bem('date')}>
          <div className={bem('day')}>{moment(route.departureDateTime).tz(route.origin.timeZone).format('DD')}</div>
          <div className={bem('month')}>{moment(route.departureDateTime).tz(route.origin.timeZone).format('MMM')}</div>
        </div>
        <div className={bem('content')}>
          <div className={bem('details')}>
            <div className={bem('train')} />
            <TrainStatusPill status={route.trainStatus} className={bem('status-tag')} />
            <div className={bem('hours')}>
              <div className={bem('departure')}>
                {moment(route.departureDateTime).tz(route.origin.timeZone).format(TIME_FORMAT)}
              </div>
              <div className={bem('duration')}>
                <div className={bem('border')} />
                <div className={bem('duration-value')}> {travelDuration}</div>
              </div>
              <div className={bem('arrival')}>
                {moment(route.arrivalDateTime).tz(route.destination.timeZone).format(TIME_FORMAT)}
              </div>
            </div>
            {isReaccommodated && !!previousTimes && (
              <div className={bem('previous-times')}>
                <span>{moment(previousTimes?.departureTime).tz(route.origin.timeZone).format(TIME_FORMAT)}</span>
                <span>{moment(previousTimes?.arrivalDateTime).tz(route.origin.timeZone).format(TIME_FORMAT)}</span>
              </div>
            )}
            <div className={bem('stations')}>
              <div className={bem('station')}>{isMobile ? route.origin.abbreviation : route.origin.name}</div>
              <div className={bem('duration')} />
              <div className={bem('station')}>{isMobile ? route.destination.abbreviation : route.destination.name}</div>
            </div>
            <div className={bem('boarding-info')}>
              <div className={bem({ class: productId?.toLowerCase() })}>{classTagLabel}</div>
              <div className={bem('boarding-close')}>{boardingCloseTimeValue}</div>
            </div>
          </div>
        </div>
      </div>
      {disabled && (
        <div className={bem('canceled')}>
          <Icon name="cancel" />
          <p>{disabledMessage}</p>
        </div>
      )}
    </div>
  );
});
