import React from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Drawer } from '@molecules/Drawer';
import FareCompareModalV2 from '@organisms/FareCompareModal/v2/FareCompareModalV2';

import { findTableConfigByJourney } from 'utils/brandedFaresUtils';
import { cn } from 'utils/classNames';

import './FareCompareTableDrawer.scss';
import { FareCompareTableDrawerProps } from './types';

const bem = cn('fare-compare-drawer');

const FareCompareTableDrawer = (props: FareCompareTableDrawerProps) => {
  const { isVisible, onClose, travelLabels, journey, fareCompareLabel } = props;

  const { cfStore } = useStores();
  const { mobileFareCompareItems } = cfStore.header.brandedFares;

  const smartSaverTableConfig = findTableConfigByJourney('smart_saver', mobileFareCompareItems, journey);
  const smartTableConfig = findTableConfigByJourney('smart', mobileFareCompareItems, journey);
  const premiumTableConfig = findTableConfigByJourney('premium', mobileFareCompareItems, journey);

  return (
    <Drawer isVisible={isVisible} onClose={onClose} isBackgroundGrey>
      <div className={bem('header-drawer')}>
        <button onClick={onClose} className={bem('close-table')}>
          <Icon name="arrow-to-from" size="small" className={bem('table-arrow-left')} />
        </button>
        <div className={bem('header')}>
          <h3 className={bem('title')}>{fareCompareLabel}</h3>
          <div className={bem('trip')}>
            <span className={bem('origin')}>{travelLabels.from}</span>
            <Icon name="arrow-from-to" />
            <span className={bem('destination')}>{travelLabels.to}</span>
          </div>
        </div>
      </div>
      <div className={bem('table-content')}>
        {journey?.defaultSmartSaverProduct && smartSaverTableConfig && (
          <FareCompareModalV2 renderAs="showAlways" {...smartSaverTableConfig} />
        )}
        {journey?.defaultSmartProduct && smartTableConfig && (
          <FareCompareModalV2 renderAs="showAlways" {...smartTableConfig} />
        )}
        {journey?.defaultPremiumProduct && premiumTableConfig && (
          <FareCompareModalV2 renderAs="showAlways" {...premiumTableConfig} />
        )}
      </div>
    </Drawer>
  );
};

export default FareCompareTableDrawer;
