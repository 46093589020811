import { observer } from 'mobx-react-lite';
import React from 'react';

import CheckboxField from '@atoms/Checkbox/CheckboxField';
import { InputField } from '@atoms/Input';
import { Select } from '@atoms/Select';
import { Text } from '@atoms/Text';
import { InlineDateInputField } from '@molecules/InlineDateInput';
import PhoneNumber from '@molecules/PhoneNumber/PhoneNumber';

import { cn, cx } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';

import PassengerComplete from './PassengerComplete';
import './PassengerInfo.scss';
import { dateConfig } from './constants';
import { PassengerCmpProps } from './types';
import { usePassengerLogic } from './usePassengerLogic';

const bem = cn('passenger-info');

export const Passenger = observer((props: PassengerCmpProps) => {
  const { index = 0, passenger, editMode, isModifyPassenger } = props;
  const { isPrimaryPassenger, firstFieldFocus } = props;

  const passengerLogic = usePassengerLogic(props);

  const { passengers, passengersLabels, isAdult } = passengerLogic;
  const { isChild, isInfant, showPopulateDropdown, managePassengers } = passengerLogic;
  const { getDropdownData, userProfile, handlePopulate } = passengerLogic;

  if (!editMode) return <PassengerComplete {...props} />;

  return (
    <fieldset className={bem('fieldset')}>
      <legend className={bem('header', { 'no-top': index > 0 })}>
        <div className={bem('desc')}>
          <h4 className={bem('passenger-info-guest')}>
            {passengersLabels.passengerHeaderLabel.replace('{index}', (index + 1).toString())}
            &#x2022;
            {isAdult ? passengersLabels.adultLabel : ''}
            {isChild ? passengersLabels.childLabel : ''}
            {isInfant ? passengersLabels.infantLabel : ''}
          </h4>
        </div>
        {index === 0 && (
          <div className={bem('primary')}>
            <h4 className={bem('passenger-info-primary-guest')}>
              {passengersLabels.passengerPrimaryLabel}{' '}
              {passenger.wheelchairAccommodation && ` (${passengers.wheelchairLabel})`}
            </h4>
          </div>
        )}
        {index > 0 && passenger.wheelchairAccommodation && (
          <div className={bem('primary')}>
            <h4 className={bem('passenger-info-wheelchair')}>{` (${passengers.wheelchairLabel})`}</h4>
          </div>
        )}
      </legend>

      {showPopulateDropdown && (
        <div className={bem('row')}>
          <div className={bem('field')}>
            <Select
              leadingIcon="profile"
              setValue={handlePopulate}
              name="populatePassenger"
              label={managePassengers?.swapGuestLabel || passengers?.addSavedPassengerLabel}
              optionsData={getDropdownData()}
              useDesktopVariant
              afterErrorFocus
            />
          </div>
        </div>
      )}
      <Text as="p" text={passengersLabels.requiredFieldLabel} className={bem('required-field-label')} />
      <div className={bem('row')}>
        <div className={cx(bem('column'), bem('field'))}>
          <InputField
            errorVariant="inline"
            name={`passengers.${index}.passenger.firstName`}
            label={passengersLabels.firstNameInputLabel}
            autocomplete={!isKiosk() ? 'given-name' : 'off'}
            afterErrorFocus={firstFieldFocus('firstName')}
            ariaLabel="clear first name"
            showAsterisk
            displayTrailingIcon
            required
          />
        </div>
        <div className={cx(bem('column'), bem('field'))}>
          <InputField
            errorVariant="inline"
            name={`passengers.${index}.passenger.lastName`}
            label={passengersLabels.lastNameInputLabel}
            autocomplete={!isKiosk() ? 'family-name' : 'off'}
            afterErrorFocus={firstFieldFocus('lastName')}
            ariaLabel="clear last name"
            showAsterisk
            displayTrailingIcon
            required
          />
        </div>
      </div>

      <div className={bem('row')}>
        <div className={bem('field')}>
          <InlineDateInputField
            dateFormat={dateConfig.dateFormat}
            label={passengersLabels.dateOfBirthInputLabel}
            placeholder={dateConfig.dateFormat}
            name={`passengers.${index}.passenger.dateOfBirth`}
            errorVariant="inline"
            showAsterisk={index === 0}
            autocomplete={!isKiosk() ? 'bday' : 'off'}
            afterErrorFocus={firstFieldFocus('dateOfBirth')}
            ariaLabel="clear date"
            required
            maxCharactersDisabled
          />
        </div>
      </div>

      <div className={bem('row')}>
        <div className={bem('field')}>
          <InputField
            errorVariant="inline"
            name={`passengers.${index}.passenger.email`}
            label={passengersLabels.emailInputLabel}
            showAsterisk={index === 0}
            type="email"
            disabled={index === 0 && isModifyPassenger}
            autocomplete={!isKiosk() ? 'email' : 'off'}
            required={isPrimaryPassenger}
            afterErrorFocus={firstFieldFocus('email')}
            ariaLabel="clear email"
            displayTrailingIcon
          />
        </div>
      </div>

      <div className={bem('row')}>
        <div className={bem('field')}>
          <PhoneNumber
            name={`passengers.${index}.passenger.phoneNumber`}
            label={passengersLabels.phoneNumberInputLabel}
            searchCountryCodePlaceholder={passengersLabels.searchCountryCodePlaceholder}
            showAsterisk={index === 0}
            disabled={index === 0 && isModifyPassenger}
            afterErrorFocus={firstFieldFocus('phoneNumber')}
            required={isPrimaryPassenger}
            ariaLabel="clear phone number"
          />
        </div>
      </div>

      {(!!userProfile || isModifyPassenger) && (
        <div className={bem('row')}>
          <CheckboxField
            name={`passengers.${index}.savePassenger`}
            label={passengersLabels.savePassengerLabel}
            className={bem('field')}
          />
        </div>
      )}
    </fieldset>
  );
});
