import { makeAutoObservable } from 'mobx';

import { RTI, Tickets, Parking, Extras } from './sections';

export default class BookingStore {
  private readonly rootStore;

  tickets: Tickets;
  rti: RTI;
  parking: Parking;
  extras: Extras;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.rootStore = rootStore;

    this.tickets = new Tickets(rootStore);
    this.rti = new RTI(rootStore);
    this.parking = new Parking(rootStore);
    this.extras = new Extras(rootStore);
  }
}
