import { observer } from 'mobx-react-lite';
import React, { Suspense } from 'react';

import { TopLoadingBar } from '@atoms/TopLoadingBar';

import { cn } from 'utils/classNames';

import './PublicContainer.scss';
import { PublicContainerProps } from './types';

const bem = cn('account-info');

export const PublicContainer = observer(({ children }: PublicContainerProps) => {
  document.body.classList.add('hasVisibleXFContainers', 'hasHiddenSidebar');

  return (
    <div className={bem()}>
      <TopLoadingBar />
      <div className={bem('body')}>
        <Suspense fallback={<div />}>{children}</Suspense>
      </div>
    </div>
  );
});
