import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { OverlayContainer } from '@account/components/OverlayContainer';

import { EditDailyParkingForm } from '../EditDailyParkingForm';

export const EditDailyParkingOverlay = observer(() => {
  const { accountStore } = useStores();
  const { setEditDailyParkingDialogClose } = accountStore.tripDetails;

  const onCloseEditDailyParkingDialog = () => {
    setEditDailyParkingDialogClose();
  };

  return (
    <OverlayContainer title="" onCancel={onCloseEditDailyParkingDialog} isVisible>
      <EditDailyParkingForm onCancel={onCloseEditDailyParkingDialog} />
    </OverlayContainer>
  );
});
