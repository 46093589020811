import dayjs from 'dayjs';
import { useNetwork } from 'hooks/useNetwork';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { rootStore, useStores } from 'stores';

import { accountCreateSuccessEvent, clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { publicApi } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { setShowLoyaltyOptInModalFlag } from 'utils/localstorage';
import { getURLParams, removeURLParam } from 'utils/queries';

import { isLoyaltyEnabled } from '../../../../utils/metas';

export const useAccountFlowLogic = () => {
  const { cfStore } = useStores();
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState<any>(null);
  const [auth0State, setAuth0State] = useState<string | null>(null);
  const [sessionToken, setSessionToken] = useState<string | null>(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loayltyLoadingButton, setLoayltyLoadingButton] = useState(false);
  const navigate = useNavigate();

  const isLoyaltyOptIn = useRef<boolean | undefined>(undefined);

  const { setupPhone, setupName, setupErrors, setupLoyalty } = cfStore.account.registration;
  const isOnline = useNetwork();

  const networkError = isOnline ? null : { message: setupErrors.connectionError };

  const loyaltyEnabled = isLoyaltyEnabled();

  const [consents, setConsents] = useState({
    automatedTextsConsent: true,
    exclusivesConsent: loyaltyEnabled,
  });

  const checkboxOnChange = e => {
    const { name } = e.target;

    setConsents(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const submitDataToBFF = async data => {
    const formattedDate = dayjs(data?.dateOfBirth).format('YYYY-MM-DD');
    const onboardingEndpoint = `${requestUrls.getRestUrl(requestUrls.account.onboarding)}.xjson`;
    const requestData = {
      ...data,
      dateOfBirth: formattedDate,
      ...consents,
    };

    const isLoyaltyOptingIn = data?.loyaltyOptIn === true;

    try {
      setLoadingButton(true);

      if (isLoyaltyOptingIn) setLoayltyLoadingButton(true);

      const response = await publicApi.post(onboardingEndpoint, {
        ...requestData,
        termsOfServiceConsent: true,
        sessionToken,
      });
      const status = get(response, 'data.status');

      if (status === 'completed') {
        navigate('/done', { state: auth0State });

        loyaltyEnabled && setShowLoyaltyOptInModalFlag('false');

        accountCreateSuccessEvent(requestData, sessionToken);
      }
    } catch (e) {
      setError(setupErrors.connectionError);
      setLoadingButton(false);
      setLoayltyLoadingButton(false);
      console.error(e);
    }
  };

  const handleLoyaltyButtonsClick = (isAccept: boolean) => {
    isLoyaltyOptIn.current = isAccept;

    if (isAccept)
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: 'createAccount',
          navigationLinkName: setupLoyalty.modalJoinButtonLabel,
          customLink: 'brightlineRewardsModal',
          externalLink: '',
          linkType: 'linkClickLoyalty',
        },
      });
    else
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: 'createAccount',
          navigationLinkName: setupLoyalty.modalDeclineButtonLabel,
          customLink: 'brightlineRewardsModal',
          externalLink: '',
          linkType: 'linkClickLoyalty',
        },
      });
  };

  const handleSubmit = (data, actions) => {
    if (currentStep === 1) {
      setCurrentStep(2);
      actions.setTouched({});
      actions.setSubmitting(false);
    }

    if (currentStep === 2)
      if (loyaltyEnabled) setCurrentStep(3);
      else submitDataToBFF(data);

    if (currentStep === 3) {
      if (isLoyaltyOptIn !== undefined) data = { ...data, loyaltyOptIn: isLoyaltyOptIn.current };
      submitDataToBFF(data);
    }
  };

  useEffect(() => {
    const params = getURLParams();
    const stateParam = params.get('state');
    const sessionToken = params.get('session_token');

    if (stateParam && sessionToken) {
      setAuth0State(stateParam);
      setSessionToken(sessionToken);
      removeURLParam('state');
      removeURLParam('session_token');
    } else navigate('/');
  }, [navigate]);

  return {
    handleSubmit,
    currentStep,
    sessionToken,
    auth0State,
    error,
    setCurrentStep,
    loadingButton,
    setupPhone,
    setupErrors,
    setupName,
    setupLoyalty,
    networkError,
    loyaltyEnabled,
    preventRendering: !(sessionToken && auth0State),
    checkboxOnChange,
    consents,
    handleLoyaltyButtonsClick,
    loyaltyButtonState: loayltyLoadingButton,
  };
};
