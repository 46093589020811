import React from 'react';

import { TableCellProps } from '@atoms/Table/types';

import { cn, cx } from 'utils/classNames';

const bem = cn('table');

export const TableCell = (props: TableCellProps) => {
  const { cell, isHeader, cellCN = '', cellNumber } = props;
  const { stickyColIndex, modalVariant } = props;

  const { colSpan, rowSpan, contentToRender } = cell;

  const isStickyCol = cellNumber + 1 === stickyColIndex;

  const cellProps = {
    rowSpan,
    colSpan,
    className: cx(bem('cell', { isStickyCol, modalVariant }), {
      [cellCN]: !!cellCN,
    }),
  };

  if (!contentToRender) return null;

  if (isHeader) return <th {...cellProps}>{contentToRender}</th>;

  return <td {...cellProps}>{contentToRender}</td>;
};
