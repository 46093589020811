import { observer } from 'mobx-react-lite';
import React from 'react';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';
import { isLoyaltyEnabled } from 'utils/metas';

import { ConfigNavItemProps } from '../ConfigContainer/types';

const bem = cn('account-navigation-menus');

export const NavItem = observer(({ navItem, handleItemClick }: ConfigNavItemProps) => {
  const loyaltyEnabled = isLoyaltyEnabled();

  return (
    <li
      key={navItem.navigationTitle || navItem.pageTitle}
      aria-label={navItem.navigationTitle || navItem.pageTitle}
      aria-current={!!(navItem.link && window.location.pathname.includes(navItem.link))}
      onClick={handleItemClick}
      className={bem('navItem', {
        [navItem.actionId || '']: !!navItem.actionId,
        active: navItem.link && window.location.pathname.includes(navItem.link),
        isEngagement: loyaltyEnabled,
      })}
    >
      <span aria-hidden="true">{navItem.navigationIcon && <Icon name={navItem.navigationIcon} />} </span>
      <span>{navItem.navigationTitle || navItem.pageTitle}</span>
    </li>
  );
});
