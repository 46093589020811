import React from 'react';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { SweepstakesFormProps } from '@organisms/Sweepstakes/types';

import { cn } from 'utils/classNames';

import { useServerLogic } from '../useServerLogic';
import './SweepstakesFormSubmissionMessage.scss';

const bem = cn('sweepstakes-form-submission-message');

export const SweepstakesFormSubmissionMessage = (props: SweepstakesFormProps) => {
  const { submitSuccessIcon, submitAnotherRequestButton, submitErrorMessage, submitSuccessMessage, onResetForm } =
    props;

  const { serverStatus } = useServerLogic();

  const handleButtonClick = () => {
    if (submitAnotherRequestButton?.link && typeof submitAnotherRequestButton.link.url === 'string')
      window.location.href = submitAnotherRequestButton.link.url;
    else onResetForm();
  };

  return (
    <div className={bem()}>
      <div className={bem('form-submission-message')}>
        {submitSuccessIcon && <Icon className={bem('check-icon')} {...submitSuccessIcon} />}
        <div className={bem('item')}>
          {serverStatus === 'error' ? (
            <Text className={bem('errorMessage')} text={submitErrorMessage} />
          ) : (
            <Text className={bem('submitAnotherFormMesssage')} text={submitSuccessMessage} />
          )}
        </div>
        <div className={bem('item')}>
          <Button
            color={submitAnotherRequestButton?.color}
            size={submitAnotherRequestButton?.size}
            variant={submitAnotherRequestButton?.variant}
            label={submitAnotherRequestButton?.label || 'Submit'}
            link={submitAnotherRequestButton?.link}
            type="button"
            className={bem('btn')}
            onClick={handleButtonClick}
          />
        </div>
      </div>
    </div>
  );
};
