import React from 'react';

import { cn } from 'utils/classNames';

import './CardV2.scss';

const bem = cn('cardV2');

const CardV2Placeholder = ({ isSneakPeak, hasInfoText, hasDate }) => (
  <div className={bem({ placeholders: true, isNotSneakPeak: !isSneakPeak })}>
    <div className={bem('divider-placeholder')} />
    <div className={bem('content', { placeholders: true })}>
      <div className={bem('row', { placeholders: true })}>
        <div className={bem('row-items')}>
          <div className={bem('row-item-placeholder', { title: true })} />
          <div className={bem('row-item-placeholder')} />
        </div>
        <div className={bem(isSneakPeak ? 'qr-code-placeholder' : 'icon-placeholder')} />
      </div>
      {!isSneakPeak && hasInfoText && <div className={bem('row-item-placeholder')} />}
      <div className={bem('row', { placeholders: true })}>
        {!isSneakPeak && hasDate && (
          <div className={bem('row-items')}>
            <div className={bem('row-item-placeholder')} />
            <div className={bem('row-item-placeholder')} />
          </div>
        )}
        {!isSneakPeak && (
          <div className={bem('qr-code-container')}>
            <div className={bem('qr-code-placeholder')} />
          </div>
        )}
      </div>
    </div>
  </div>
);

export default CardV2Placeholder;
