import { observer } from 'mobx-react-lite';
import React from 'react';

import UserIcon from '@atoms/UserIcon/UserIcon';
import { NavItem } from '@molecules/NavItem';

import { cn } from 'utils/classNames';

import { TopNavMenuProps } from '../../types';
import { useAccountStateLogic } from './useAccountStateLogic';

const bem = cn('global-header');

const AccountState = observer((props: TopNavMenuProps) => {
  const { scope, customIcon, isResponsive, uuid } = props;
  const { accountMenuItems, isAuthenticated } = useAccountStateLogic(props);
  const authenticatedIcon = isAuthenticated ? <UserIcon /> : undefined;
  const customAccountIcon = customIcon || authenticatedIcon;

  return (
    <NavItem
      {...accountMenuItems}
      scope={scope}
      displayDropDownIcon={!!accountMenuItems?.subitems?.length}
      size="small"
      customClassName={bem(scope || '', { auth: isAuthenticated })}
      customIcon={customAccountIcon}
      isResponsive={isResponsive}
      uuid={uuid}
    />
  );
});

export default AccountState;
