export const coachDescriptions: Record<string, string> = {
  '1': 'This is Coach 1. Exits are located at the front and back of the coach. Luggage racks are positioned near the front. Restrooms are situated at the front. There is a wheelchair-accessible seat located in Row 1, Seat C. The direction of travel is indicated, and seats are numbered from the front to the back of the coach.',

  '2': 'This is Coach 2. Exits are positioned at both the front and back. Luggage racks are located near the back, providing storage for large items. Restrooms are situated at the back of the coach. Wheelchair-accessible seating is arranged in Row 1, Seats C and D. This coach is designed for both general seating and passengers with limited mobility.',

  '3': 'This is Coach 3. Exits are available at both the front and back of the coach. Luggage racks are located at the front, near the entrance. Restrooms are also positioned at the front. Wheelchair-accessible seating is available in Row 17, Seats A and B, providing extra space and easy access.',

  '4': 'This is Coach 4. Exits are located at the front and back of the coach. Luggage racks are positioned at the back for passengers seated towards the rear. Restrooms are also located at the back. This coach features general seating and does not have specific wheelchair-accessible seats.',

  '5': 'This is Coach 5. Exits are located at both the front and back of the coach. Luggage racks are available near the front. The restrooms are positioned at the back. Wheelchair-accessible seating is available in Row 1. Seats are arranged in pairs, and the direction of travel is indicated at the top of the map. This coach supports both general seating and passengers with accessibility needs.',
};
