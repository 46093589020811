import { makeAutoObservable } from 'mobx';

import { Checkout } from './sections';

export default class PassesStore {
  private readonly rootStore;

  checkout: Checkout;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;

    this.checkout = new Checkout(rootStore);
  }
}
