import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { Suspense } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';
import { Title } from '@atoms/Title';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './ManagePassengers.scss';

const bem = cn('manage-passengers');

export const SaveAndClose = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { managePassengers } = cfStore.account.tripDetails.navigation;
  const { setShowManagePassengersModal, showCloseNotSavedModal, setShowCloseNotSavedModal } = accountStore.tripDetails;
  const { setIsCloseNotSaved } = accountStore.tripDetails;

  const formValues = useFormikContext();
  const { handleSubmit } = formValues;

  const onSaveAndClose = () => {
    setShowCloseNotSavedModal(false);
    handleSubmit();
    handleSubmit();
  };

  const onDiscardChanges = () => {
    setIsCloseNotSaved(false);
    setShowCloseNotSavedModal(false);
    setShowManagePassengersModal(false);
  };

  if (!showCloseNotSavedModal) return null;

  return (
    <Suspense>
      <Modal variant="mini">
        <div className={bem()}>
          <Title text={managePassengers.closeModalTitle} size="h3" />
          <Text text={managePassengers.closeModalDescription} />
          <div className={bem('buttons')}>
            <Button label={managePassengers.discardChangesButtonLabel} width="full" onClick={onDiscardChanges} />
            <Button
              type="submit"
              label={managePassengers.saveAndCloseButtonLabel}
              width="full"
              onClick={onSaveAndClose}
            />
          </div>
        </div>
      </Modal>
    </Suspense>
  );
});
