import { useEffect } from 'react';
import { useStores } from 'stores';

import { getAuth0RedirectURI } from 'utils/metas';

export const useSignup = () => {
  const { authStore } = useStores();
  const { auth0Client, isAuthenticated } = authStore;

  useEffect(() => {
    if (!auth0Client) return;

    const signup = () => {
      auth0Client.loginWithRedirect({
        authorizationParams: {
          redirect_uri: getAuth0RedirectURI(),
          ui_locales: document.documentElement.lang,
          data: { is_domain_connection: true },
          audience: 'Brightline BFF',
          screen_hint: 'signup',
        },
      });
    };

    // so if the user is not authenticated, to be redirected to auth0 signup page
    if (!isAuthenticated) signup();
  }, [auth0Client, isAuthenticated]);
};
