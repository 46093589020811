import { FormikContextType, useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';

import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';

import './OriginAndDestination.scss';
import SelectField from './Select/SelectField';
import { OriginAndDestinationProps, PartialFromProps } from './types';

const bem = cn('origin-and-destination');

export const OriginAndDestination = (props: OriginAndDestinationProps) => {
  const { stations = [], i18n, showPanel, isMobile, defaultSelectedIndex } = props;

  const containerRef = useRef<HTMLInputElement>(null);
  const [activeInput, setActiveInput] = useState(defaultSelectedIndex || null);

  const formikProps: FormikContextType<PartialFromProps> = useFormikContext();

  const fromChange = ev => {
    formikProps.setFieldValue('from', ev.target.value);
  };

  const toChange = ev => {
    formikProps.setFieldValue('to', ev.target.value);
  };

  const handleSetIndex = val => {
    if (showPanel) return showPanel();

    return setActiveInput(val);
  };

  const handleResetActive = () => {
    handleSetIndex(null);
  };

  const swap = () => {
    if (containerRef?.current) {
      const locationElements = containerRef.current.querySelectorAll('.blte-od-select');
      locationElements[0].classList.toggle('reverse-right');
      locationElements[1].classList.toggle('reverse-left');
      setTimeout(() => {
        const tempTo = formikProps.values?.to;
        formikProps.setFieldValue('to', formikProps.values?.from);
        formikProps.setFieldValue('from', tempTo);
        locationElements[0].classList.toggle('reverse-right');
        locationElements[1].classList.toggle('reverse-left');
        setTimeout(formikProps.validateForm, 100);
      }, 300);
    }
  };

  return (
    <div ref={containerRef} className={bem({ 'is-mobile': isMobile })}>
      <SelectField
        isMobile={isMobile}
        selectIndex={1}
        resetActive={handleResetActive}
        onHeaderClick={handleSetIndex}
        active={activeInput === 1}
        label={i18n.fromLabel}
        placeholder={i18n.fromPlaceholder}
        locationHint={i18n.locationHint}
        errorMessage={i18n.selectOriginError}
        name="from"
        items={stations.filter(station => station.id !== formikProps.values?.to) || []}
        onChangeTextInput={fromChange}
        onBlur={fromChange}
        position={isMobile ? 'top' : activeInput === 1 ? undefined : 'left'}
        isRequired
        addErrorId
      />
      <div className={bem('swap-container')}>
        <Button iconPosition="right" icon="swap" size="small" label="swap" onClick={swap} className={bem('swap-btn')} />
      </div>
      <SelectField
        isMobile={isMobile}
        selectIndex={2}
        resetActive={handleResetActive}
        onHeaderClick={handleSetIndex}
        active={activeInput === 2}
        label={i18n.toLabel}
        placeholder={i18n.toPlaceholder}
        locationHint={i18n.locationHint}
        errorMessage={i18n.selectDestinationError}
        name="to"
        items={stations.filter(station => station.id !== formikProps.values?.from) || []}
        onChangeTextInput={toChange}
        onBlur={toChange}
        position={isMobile ? 'bottom' : activeInput === 2 ? undefined : 'right'}
        isRequired
        addErrorId
      />
    </div>
  );
};
