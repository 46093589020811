import { observer } from 'mobx-react-lite';
import React from 'react';

import { ExtrasModalLoaderProps } from '@booking/routes/RTI/Extras/components/ExtrasModal/ModalLoader/types';
import { useExtrasModalLoaderLogic } from '@booking/routes/RTI/Extras/components/ExtrasModal/ModalLoader/useExtrasModalLoaderLogic';

import { ExtrasModal } from './../Modal';

export const ExtrasModalLoader = observer((props: ExtrasModalLoaderProps) => {
  const { modalConfig, cfData } = useExtrasModalLoaderLogic(props);
  const { onCloseRef } = props;

  if (!modalConfig) return null;

  return (
    <ExtrasModal
      initialFormValues={modalConfig.initialValues}
      infoCards={modalConfig.infoCardArray}
      itemCards={modalConfig.itemCardConfigs}
      cfLabels={cfData}
      isUpdateButton={modalConfig.isUpdateButton}
      onCloseRef={onCloseRef}
    />
  );
});
