import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useStores } from 'stores';

import { OverlayContainer } from '@account/components/OverlayContainer';
import { useRestoreScroll } from '@account/hooks/useRestoreScroll';
import { useSimpleSelectHandlers } from '@account/hooks/useSimpleSelectHandlers';
import { Icon } from '@atoms/Icon/Icon';

import { cn, cx } from 'utils/classNames';

import Passenger from './Passenger';
import './Passengers.scss';
import { PassengerForm } from './components/PassengerForm/PassengerForm';
import { SavedPassengerProps } from './types';

const bem = cn('account-passengers');

const EditablePassenger = observer((props: SavedPassengerProps) => {
  const { passenger, size } = props;

  const { cfStore, accountStore } = useStores();
  const { account } = cfStore;
  const { passengersCF } = account;
  const { passengersStore } = accountStore;
  const { isLoading, savePassenger, deletePassenger } = passengersStore;

  const { setScrollPosition } = useRestoreScroll();

  const [menuOpened, setMenuOpened] = useState(false);

  const [modalEditOpened, setModalEditOpened] = useState(false);
  const [modalDeleteOpened, setModalDeleteOpened] = useState(false);

  const moreRef = useRef<HTMLButtonElement>(null);
  const editRef = useRef<HTMLButtonElement>(null);
  const deleteRef = useRef<HTMLButtonElement>(null);

  const handleToggleMenuOpen = () => {
    if (!menuOpened) setScrollPosition({ x: window.pageXOffset, y: window.pageYOffset });
    setMenuOpened(!menuOpened);
  };

  const handleToggleEdit = () => {
    setModalEditOpened(!modalEditOpened);
  };

  const handleToggleDelete = () => {
    setModalDeleteOpened(!modalDeleteOpened);
  };

  const handleDeletePassenger = () => {
    deletePassenger(passenger, handleToggleDelete);
  };

  const handleSavePassenger = values => {
    savePassenger(values, handleToggleEdit);
  };

  const hookProps = useSimpleSelectHandlers({ toggleMenu: handleToggleMenuOpen, nrItems: 2 });

  const handleClickOutsideWrapper = () => {
    if (menuOpened) handleToggleMenuOpen();
  };

  const { focus, handleKeyDown } = hookProps;

  useEffect(() => {
    if (focus.index === 0) editRef.current?.focus();
    if (focus.index === 1) deleteRef.current?.focus();
  }, [focus.index]);

  return (
    <>
      <Passenger size={size} passenger={passenger} />

      <div className={bem('actions-container')}>
        <button
          ref={moreRef}
          onClick={handleToggleMenuOpen}
          className={cx(bem('edit', { opened: menuOpened }), bem('actions-btn'))}
          aria-label="more"
        >
          <Icon name="more" />
        </button>

        <OverlayContainer
          onCancel={handleToggleEdit}
          title={passengersCF.editPassengerTitle}
          isVisible={modalEditOpened}
          cancelButtonLabel=""
          submitButtonLabel=""
        >
          <PassengerForm
            i18n={passengersCF}
            passenger={passenger}
            onToggleEdit={handleToggleEdit}
            onSubmit={handleSavePassenger}
            isLoading={isLoading}
            cancelButtonLabel={passengersCF.cancelCtaLabel}
            submitButtonLabel={passengersCF.saveCtaLabel}
          />
        </OverlayContainer>

        <OverlayContainer
          title={passengersCF.deletePassengerConfirmationTitle}
          isVisible={modalDeleteOpened}
          cancelButtonLabel={passengersCF.cancelCtaLabel}
          submitButtonLabel={passengersCF.deleteCtaLabel}
          onCancel={handleToggleDelete}
          onConfirm={handleDeletePassenger}
        >
          <div>{passengersCF.deletePassengerConfirmationText}</div>
        </OverlayContainer>

        <OutsideClickHandler onOutsideClick={handleClickOutsideWrapper}>
          <div className={bem('actions', { visible: menuOpened })} onKeyDown={handleKeyDown}>
            {passengersCF.editPassengerLabel && (
              <button ref={editRef} className={bem('actions-btn')} onClick={handleToggleEdit} autoFocus>
                {passengersCF.editPassengerLabel}
              </button>
            )}
            {passengersCF.deletePassengerLabel && (
              <button ref={deleteRef} className={cx(bem('delete'), bem('actions-btn'))} onClick={handleToggleDelete}>
                {passengersCF.deletePassengerLabel}
              </button>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
});

export default EditablePassenger;
