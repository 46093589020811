import _ from 'lodash';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Text } from '@atoms/Text';
import { ErrorCodesConfig } from '@molecules/ErrorCodesBrowser/types';

import { cn } from 'utils/classNames';

const bem = cn('gift-card');

export const Error = ({ errors }) => {
  const { bookingStore, cfStore } = useStores();
  const { giftCardError } = bookingStore.rti;

  const reviewAndPay = useMemo(() => {
    if (cfStore.rti.reviewAndPay.giftCardTitle) return cfStore.rti.reviewAndPay;

    return cfStore.passes.reviewAndPay;
  }, [cfStore.passes.reviewAndPay, cfStore.rti.reviewAndPay]);

  const gracefulErrors: ErrorCodesConfig | null = useMemo(
    () => JSON.parse(cfStore.header.gracefulErrors || cfStore.passesHeader.gracefulErrors || 'null'),
    [cfStore.header.gracefulErrors, cfStore.passesHeader.gracefulErrors]
  );

  const hasError = !_.isEmpty(giftCardError);

  const hasValidationError = !_.isEmpty(errors);

  const gcError = gracefulErrors?.errorCodesList?.find(
    error => error?.errorCode === giftCardError?.errorCode || error.errorCode === `CL-${giftCardError?.code}`
  );

  const error = hasValidationError
    ? reviewAndPay.giftCardErrorMessageLabel
    : gcError?.errorBody || reviewAndPay?.giftCardGeneralMessageLabel;

  if (!hasValidationError && !hasError) return null;

  return <Text className={bem('error-message')} text={error} />;
};
