import React from 'react';

import Button from '@atoms/Button/Button';
import { NavItem } from '@molecules/NavItem/NavItem';
import { useNavItemStyles } from '@molecules/NavItem/useNavItemStyles';
import { AddToCart } from '@organisms/GlobalHeader/components/AddToCart';

import { cn } from 'utils/classNames';

import { CustomIcon } from '../CustomIcon/CustomIcon';
import './BottomNav.scss';
import { BottomNavProps } from './types';

const bemGlobal = cn('global-header');

export const BottomNav = (props: BottomNavProps) => {
  const { onClick, data = [], buyTicketsButton, colors, addToCartButton } = props;

  const styles = useNavItemStyles(colors);

  return (
    <nav className={bemGlobal('bottom-nav')} style={styles}>
      {data.map(item => (
        <NavItem
          key={`nav-item-${item.label}`}
          {...item}
          customIcon={item.svgSource || item.customIcon ? <CustomIcon {...item} /> : undefined}
          size="large"
        />
      ))}
      {buyTicketsButton?.label && (
        <Button
          size="medium"
          color="yellow"
          label={buyTicketsButton.label}
          onClick={onClick}
          className={bemGlobal('buy-tickets')}
          link={buyTicketsButton.link}
        />
      )}
      {addToCartButton && addToCartButton.link && (
        <AddToCart {...addToCartButton} className={bemGlobal('add-to-cart')} preventFetchOnMobile />
      )}
    </nav>
  );
};
