import { Form, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { Button } from '@atoms/Button';
import { Checkbox } from '@atoms/Checkbox';
import { InputField } from '@atoms/Input';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';

import '../SafetyPledgeForm.scss';
import { SafetyPledgeContentProps, SafetyPledgeFormValues } from '../types';
import { useServerLogic } from '../useServerLogic';

const bem = cn('safety-pledge');

export const SafetyPledgeFormContent = (props: SafetyPledgeContentProps) => {
  const {
    firstName,
    lastName,
    email,
    postalCode,
    subtitle,
    disclaimerText,
    submitButton,
    handleCheckboxChange,
    checkbox,
  } = props;
  const [isformValid, setIsFormValid] = useState(false);
  const { serverStatus } = useServerLogic();
  const formik = useFormikContext<SafetyPledgeFormValues>();

  useEffect(() => {
    const validation =
      !!formik.values.email &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formik.values.email) &&
      !!formik.values.firstName &&
      /^[\p{L}\s.'-]+$/u.test(formik.values.firstName) &&
      !!formik.values.lastName &&
      /^[\p{L}\s.'-]+$/u.test(formik.values.lastName) &&
      !!formik.values.postalCode &&
      /^[a-zA-Z0-9]+$/.test(formik.values.postalCode);
    setIsFormValid(validation);
  }, [formik.values.email, formik.values.firstName, formik.values.lastName, formik.values.postalCode]);

  return (
    <div>
      <Form noValidate>
        <div>
          <Text className={bem('subtitle')} text={subtitle} as="h3" />
          <div className={bem('form-row')}>
            <div className={bem('field')}>
              <InputField
                errorVariant="inline"
                error={serverStatus === 'error'}
                name="firstName"
                label={firstName}
                autocomplete={!isKiosk() ? 'given-name' : 'off'}
                ariaLabel="clear first name"
                fieldsetClassName={bem('input-field')}
                displayTrailingIcon
                required
              />
            </div>
          </div>
          <div className={bem('form-row')}>
            <div className={bem('field')}>
              <InputField
                errorVariant="inline"
                error={serverStatus === 'error'}
                name="lastName"
                label={lastName}
                autocomplete={!isKiosk() ? 'family-name' : 'off'}
                ariaLabel="clear last name"
                fieldsetClassName={bem('input-field')}
                displayTrailingIcon
                required
              />
            </div>
          </div>
          <div className={bem('form-row')}>
            <div className={bem('field')}>
              <InputField
                errorVariant="inline"
                error={serverStatus === 'error'}
                name="email"
                label={email}
                type="email"
                autocomplete={!isKiosk() ? 'email' : 'off'}
                ariaLabel="clear email"
                fieldsetClassName={bem('input-field')}
                displayTrailingIcon
                required
              />
            </div>
          </div>
          <div className={bem('form-row')}>
            <div className={bem('field', { 'postal-code': true })}>
              <InputField
                errorVariant="inline"
                error={serverStatus === 'error'}
                autocomplete="postalCode"
                type="text"
                name="postalCode"
                label={postalCode}
                maxCharacters={10}
                fieldsetClassName={bem('input-field')}
                displayTrailingIcon
                required
              />
            </div>
          </div>
          <div className={bem('disclaimer-row')}>
            <div className={bem('disclaimer-wrapper')}>
              <Checkbox name="disclaimerCheckbox" label="" isChecked={checkbox} onChange={handleCheckboxChange} />
              <Text text={disclaimerText} className={bem('disclaimer')} />
            </div>
          </div>
          <div>
            <Button
              type="submit"
              width="full"
              label={submitButton.label}
              color={submitButton.color}
              disabled={!isformValid}
              link={submitButton?.link}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
