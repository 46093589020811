import React, { useMemo } from 'react';

import Image from '@atoms/Image/Image';
import VideoModal from '@molecules/VideoModal/VideoModal';

import { cn, cx } from 'utils/classNames';

import './VideoLayer.scss';
import { VideoLayerProps } from './types';

const bem = cn('video-layer');

const VideoLayer = ({ thumbnail, imageAspectRatio, id, className, ...rest }: VideoLayerProps) => {
  const style: any = useMemo(
    () => ({
      '--video-layer-thumbnail-aspect-ratio': imageAspectRatio,
    }),
    [imageAspectRatio]
  );

  return (
    <div id={id} className={cx(className, bem())} style={style}>
      <div className={bem('thumbnail')}>
        <Image {...thumbnail} />
        <VideoModal {...rest} />
      </div>
    </div>
  );
};

export default VideoLayer;
