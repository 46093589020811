import { FieldArray, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { SaveAndClose } from '@account/routes/TripDetails/components/Navigation/ManageTrip/ManagePassengers/SaveAndClose';
import Button from '@atoms/Button/Button';
import { Divider } from '@atoms/Divider';

import { cn } from 'utils/classNames';

import { Card } from '../Card';
import ConfirmNewsletter from './ConfirmNewsletter';
import { Passenger } from './Passenger';
import PassengersInfoComplete from './PassengersInfoComplete';
import { PassengersInfoProps } from './types';
import { usePassengersInfoLogic } from './usePassengersInfoLogic';
import { getValidationSchema } from './validator';

const bem = cn('passenger-info');

export const PassengersInfo = observer((props: PassengersInfoProps) => {
  const { mode, stepNr, isModifyPassenger } = props;

  const passengersLogic = usePassengersInfoLogic(props);

  const { passengers, lockedSteps, passengersLabels } = passengersLogic;
  const { initialValues, errorLabels, changesHaveBeenSaved } = passengersLogic;
  const { isPastDepartureTime, managePassengers } = passengersLogic;
  const { smsNotifications, confirmNewsletter, isCloseNotSaved } = passengersLogic;
  const { loading, firstFieldFocus, handleEdit, handleSubmitForm, setErrors } = passengersLogic;
  const { renderError, renderConfirmation, handleChange, ref } = passengersLogic;
  const { handleConfirmSmsNotification, handleConfirmNewsletter } = passengersLogic;

  if (lockedSteps.passengerInfo) 
    return (
      <Card
        title={passengersLabels.cardTitle}
        stepNr={stepNr}
        mode={mode}
        onEdit={handleEdit}
        editBtnLabel={passengersLabels.editBtnLabel}
        hideEditBtn={false}
      >
        <PassengersInfoComplete bookedPassengers={initialValues.passengers} />
      </Card>
    );
  

  return (
    <div ref={ref}>
      <Card
        title={passengersLabels.cardTitle}
        stepNr={stepNr}
        mode={mode}
        editBtnLabel={passengersLabels.editBtnLabel}
        isModifyPassenger={isModifyPassenger}
        hideEditBtn
        hideError
      >
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema(errorLabels)}
          onSubmit={handleSubmitForm}
          enableReinitialize
        >
          {({ values, errors, getFieldMeta, isSubmitting }) => {
            if (isSubmitting) setErrors(errors);

            return (
              <Form autoComplete="off" noValidate>
                {renderError(errors?.passengers, getFieldMeta, isSubmitting)}
                {isModifyPassenger && changesHaveBeenSaved && renderConfirmation()}
                {handleChange(values)}
                <FieldArray
                  name="passengers"
                  render={({ form: { setFieldValue } }) => (
                    <div className={bem('wrapper')}>
                      {values.passengers && values.passengers.length > 0 ? (
                        values.passengers.map((passenger, index) => (
                          <div key={index}>
                            <Passenger
                              {...passenger}
                              savedPassengers={passengers}
                              currentPassengers={values.passengers}
                              index={index}
                              setFieldValue={setFieldValue}
                              isModifyPassenger={isModifyPassenger}
                              isPastDepartureTime={isPastDepartureTime}
                              firstFieldFocus={firstFieldFocus}
                            />
                            {index === 0 && !isModifyPassenger && (
                              <>
                                <ConfirmNewsletter
                                  confirmNewsletter={confirmNewsletter}
                                  setConfirmNewsletter={handleConfirmNewsletter}
                                  smsNotifications={smsNotifications}
                                  setSmsNotifications={handleConfirmSmsNotification}
                                />
                              </>
                            )}
                            {index < values.passengers.length - 1 && <Divider alignment="horizontal" />}
                          </div>
                        ))
                      ) : (
                        <div />
                      )}
                      <div className={bem('actions-container')}>
                        <div className={bem('actions')}>
                          <Button
                            type="submit"
                            width="full"
                            label={
                              isModifyPassenger ? managePassengers.saveButtonLabel : passengersLabels.submitButtonLabel
                            }
                            color={isModifyPassenger ? 'yellow' : 'blue'}
                            disabled={isModifyPassenger && !isCloseNotSaved}
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                />
                {isModifyPassenger && <SaveAndClose />}
              </Form>
            );
          }}
        </Formik>
      </Card>
    </div>
  );
});
