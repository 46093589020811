import { useState } from 'react';

import { emailOptInWest } from 'utils/adobeDataLayer';

export const useServerLogic = () => {
  const [serverStatus, setServerStatus] = useState('initial');

  const onSubmit = async data => {
    setServerStatus('loading');
    try {
      await new Promise(resolve => setTimeout(resolve, 500));

      emailOptInWest({
        newsletterForm: {
          formName: 'emailOptInWest',
          formSubmitAll: true,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          postalCode: data.postalCode,
          dataOfBirth: data.dateOfBirth,
          disclaimerCheckbox: data.disclaimerCheckbox,
        },
      });

      setServerStatus('success');
    } catch (error) {
      console.error('Error during form submission:', error);
      setServerStatus('error');
    }
  };

  const resetServerStatus = () => setServerStatus('initial');

  return { onSubmit, serverStatus, resetServerStatus };
};
