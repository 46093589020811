import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { WalletPassItemsProps } from '../WalletDesktop/types';

const bem = cn('account-pass-details');

export const ParkingPassDetails = observer((props: WalletPassItemsProps) => {
  const { cfStore } = useStores();
  const { account } = cfStore;
  const { wallet } = account;
  const { item } = props;

  if (!item) return null;

  const { station, licensePlateInformation } = item;

  return (
    <div className={bem('info')}>
      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.passTypeLabel}</h5>
        <div className={bem('value')}>{wallet.parkingPassLabel}</div>
      </div>

      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.parkingPassLocation}</h5>
        {station && <div className={bem('value')}>{station.name}</div>}
      </div>
      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.parkingPassLicensePlateLabel}</h5>
        {licensePlateInformation && <div className={bem('value')}>{licensePlateInformation?.number}</div>}
      </div>
      <div className={bem('info-row')}>
        <h5 className={bem('label')}>{wallet.licensePlateStateLabel}</h5>
        {licensePlateInformation && <div className={bem('value')}>{licensePlateInformation?.state}</div>}
      </div>
    </div>
  );
});
