import _ from 'lodash';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { stepNames } from '@booking/routes/RTI/constants';
import { defaultPassenger } from '@booking/routes/RTI/constants.storybook';
import { hasTouchedFieldErrors } from '@booking/routes/RTI/utils';

import { cn, cx } from 'utils/classNames';
import { enrichDatadogSessionUserDataUnauthenticated } from 'utils/datadog';

import { isDelayNotificationsEnabled, isSmsDelayNotificationsEnabled } from '../../../../../utils/metas';
import './PassengerInfo.scss';
import { dateConfig, passengerTypes } from './constants';
import { PassengersInfoProps } from './types';

const bem = cn('passenger-info');

export const usePassengersInfoLogic = (props: PassengersInfoProps) => {
  const { isModifyPassenger, passengersTypeNumber, isModifyExtras } = props;
  const { adults, kids, infants } = passengersTypeNumber;

  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const { bookingStore, cfStore, accountStore, authStore } = useStores();

  const { passengers: passengersLabels } = cfStore.rti;
  const { managePassengers } = cfStore.account.tripDetails.navigation;
  const errorLabels = isModifyPassenger ? managePassengers : passengersLabels;

  const { confirmNewsletter, setConfirmNewsletter, setSmsNotifications, smsNotifications } = bookingStore.rti;
  const { bookedPassengers } = bookingStore.rti.passengersInfoData;
  const { profile, passengersStore, tripDetails } = accountStore;
  const { isCloseNotSaved, setIsCloseNotSaved, setShowCloseNotSavedModal } = tripDetails;
  const { tripPassengers, trip } = tripDetails;
  const { userProfile } = profile;
  const { passengers, fetchSavedPassengers } = passengersStore;
  const { saveBookingPassengers, setActiveStep, setLockedStep, setError, loading } = bookingStore.rti;
  const { setCompletedStep, changesHaveBeenSaved, confirmBooking } = bookingStore.rti;
  const { lockedSteps, activeStep, errorState, provisionalBooking, modifyTripType } = bookingStore.rti;

  const { isAuthenticated } = authStore;

  const hasError = errorState && errorState.error && errorState.stepNr === 1;

  const [errors, setErrors] = useState({});
  const { bookingSession } = provisionalBooking;

  const { fetchExtras } = bookingStore.extras;
  const { fetchParking } = bookingStore.parking;

  useEffect(() => {
    if (
      !provisionalBooking?.bookingSession &&
      window.location.href.indexOf('story') === -1 &&
      !isModifyPassenger &&
      !isModifyExtras
    )
      window.location.href = window.location.origin;
  }, [provisionalBooking?.bookingSession, navigate, isModifyPassenger, isModifyExtras]);

  useEffect(() => {
    if (isModifyPassenger) fetchSavedPassengers();
  }, [isModifyPassenger, fetchSavedPassengers]);

  useEffect(() => {
    if (hasError) {
      const errLvl = document.getElementsByClassName('active-error');
      if (errLvl[0]) errLvl[0].scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [hasError]);

  const fieldOrder = useMemo(() => ['firstName', 'lastName', 'dateOfBirth', 'email', 'phoneNumber'], []);

  const sortInputErrorsByOrder = useCallback(
    errors => {
      const sortedInputErrors = {};
      if (errors?.passengers?.length > 0)
        for (let i = 0; i < errors.passengers.length; i++) {
          const passengerErrors = errors?.passengers[i]?.passenger;

          if (passengerErrors)
            fieldOrder.forEach(field => {
              if (passengerErrors[field]) sortedInputErrors[field] = passengerErrors[field];
            });
        }

      return sortedInputErrors;
    },
    [fieldOrder]
  );

  // primary passenger does not exist if we are not logged in
  // if we are logged in it's either the first user from the bookedPassengers or the logged in user
  const primaryPassengerArray = useMemo(() => {
    const primaryPassenger = {
      passenger: { ...userProfile, type: passengerTypes.adult, isPrimaryPassenger: true },
    };

    if (userProfile) return bookedPassengers?.length ? [{ ...bookedPassengers[0] }] : [primaryPassenger];

    return [];
  }, [userProfile, bookedPassengers]);

  const isPastDepartureTime = useMemo(() => trip && moment().isAfter(trip.outboundRoute.departureDateTime), [trip]);

  const filterPassengersType = (passengers, type) => passengers.filter(p => p.passenger.type === type);

  const initialValues = useMemo(() => {
    const currentPassengers =
      isModifyPassenger && tripPassengers?.length
        ? [...tripPassengers]
        : bookedPassengers?.length
        ? [...bookedPassengers]
        : [...primaryPassengerArray];

    const initialAdultPassengers = filterPassengersType(currentPassengers, passengerTypes.adult);
    const initialChildPassengers = filterPassengersType(currentPassengers, passengerTypes.child);
    const initialInfantPassengers = filterPassengersType(currentPassengers, passengerTypes.infant);

    //fill with empty in case we have an empty array with just the primary passenger
    for (let index = initialAdultPassengers.length; index < adults; index += 1)
      initialAdultPassengers.push({ passenger: { ...defaultPassenger, type: passengerTypes.adult }, editMode: true });

    for (let index = initialChildPassengers.length; index < kids; index += 1)
      initialChildPassengers.push({ passenger: { ...defaultPassenger, type: passengerTypes.child }, editMode: true });

    for (let index = initialInfantPassengers.length; index < infants; index += 1)
      initialInfantPassengers.push({ passenger: { ...defaultPassenger, type: passengerTypes.infant }, editMode: true });

    return {
      passengers: [...initialAdultPassengers, ...initialChildPassengers, ...initialInfantPassengers]
        .map((p, index) => ({
          ...p,
          passenger: {
            ...defaultPassenger,
            ...p.passenger,
            dateOfBirth: p.passenger.dateOfBirth
              ? moment.utc(p.passenger.dateOfBirth).format(dateConfig.dateFormat)
              : undefined,
            wheelchairAccommodation: provisionalBooking.tripDetails?.passengerSeatingDetails?.length
              ? provisionalBooking?.tripDetails?.passengerSeatingDetails[index]?.passenger?.wheelchairAccommodation
              : undefined,
          },
          isPrimaryPassenger: index === 0,
          savePassenger: false,
          editMode: !p.passenger.firstName && !p.passenger.lastName,
        }))
        .slice(0, adults + kids + infants),
    };
  }, [
    bookedPassengers,
    primaryPassengerArray,
    adults,
    kids,
    infants,
    provisionalBooking.tripDetails?.passengerSeatingDetails,
    isModifyPassenger,
    tripPassengers,
  ]);

  const handleChange = values => {
    const initialValuesPassengers = initialValues?.passengers?.map(pass => pass?.passenger);
    const valuesPassengers = values?.passengers?.map(pass => pass?.passenger);
    setIsCloseNotSaved(!_.isEqual(initialValuesPassengers, valuesPassengers));

    return null;
  };

  const handleEdit = () => {
    //when hitting edit button, we should mark the step as not completed so that it becomes editable and set the active step to this step
    setLockedStep(activeStep, true);

    setLockedStep(stepNames.passengerInfo, false);
    setActiveStep(stepNames.passengerInfo);
  };

  const handleConfirmSmsNotification = () => {
    if (!isDelayNotificationsEnabled() || !isSmsDelayNotificationsEnabled()) setSmsNotifications(false);
    else setSmsNotifications(!smsNotifications);
  };

  const handleConfirmNewsletter = () => {
    setConfirmNewsletter(!confirmNewsletter);
  };

  const handleSubmitForm = async (values, actions) => {
    const confirmBookingCallback = () => {
      setIsCloseNotSaved(false);
      setShowCloseNotSavedModal(false);
    };

    const passengerModifyCallback = () => {
      confirmBooking({}, confirmBookingCallback, errorCallback);
    };

    const passengerNotModifyCallback = () => {
      setLockedStep(stepNames.passengerInfo, true);
      setCompletedStep(stepNames.passengerInfo, true);
      setActiveStep(stepNames.passengerInfo);
      setActiveStep(stepNames.extras);
      setActiveStep(stepNames.seatAssignment);
    };

    const errorCallback = (error = '') => {
      setError({ stepNr: 1, error });
    };

    const { tripDetails } = provisionalBooking;

    const primaryPassenger = values.passengers.find(val => val.isPrimaryPassenger) || values.passengers[0];

    if (values.passengers.find(p => p.editMode)) {
      values.passengers.map(p => (p.editMode = false));
      actions.setValues(values);
      await saveBookingPassengers(
        values,
        isModifyPassenger ? passengerModifyCallback : passengerNotModifyCallback,
        isModifyPassenger ? errorCallback : undefined,
        modifyTripType
      );

      if (
        tripDetails?.trip?.outboundRoute?.origin?.id !== 'MCO' &&
        tripDetails?.trip?.outboundRoute?.destination?.id !== 'MCO'
      )
        fetchParking(tripDetails?.trip?.referenceNumber, bookingSession);
      fetchExtras(tripDetails?.trip?.referenceNumber, bookingSession);

      //DATADOG unauthenticated user session data enrichment
      if (!isAuthenticated && primaryPassenger && tripDetails?.trip?.referenceNumber)
        enrichDatadogSessionUserDataUnauthenticated(primaryPassenger.passenger, tripDetails.trip.referenceNumber);

      return;
    }

    await saveBookingPassengers(
      values,
      isModifyPassenger ? passengerModifyCallback : passengerNotModifyCallback,
      isModifyPassenger ? errorCallback : undefined,
      modifyTripType
    );

    fetchParking(tripDetails?.trip?.referenceNumber, bookingSession);
    fetchExtras(tripDetails?.trip?.referenceNumber, bookingSession);

    //DATADOG unauthenticated user session data enrichment
    if (!isAuthenticated && primaryPassenger && tripDetails?.trip?.referenceNumber)
      enrichDatadogSessionUserDataUnauthenticated(primaryPassenger.passenger, tripDetails.trip.referenceNumber);
  };

  const renderError = (errors, getFieldMeta, isSubmitting) => {
    let errorMessage: string;

    const equalGuestNumberError = errors?.some(
      error => !!error && Object.keys(error?.passenger).includes('missingPassenger')
    );
    const birthdateError = errors?.some(
      error =>
        !!error && Object.keys(error?.passenger).includes('dateOfBirth') && error?.passenger?.dateOfBirth.trim() == ''
    );

    if (isPastDepartureTime) errorMessage = managePassengers.passengerCannotBeModifiedLabel;
    else if (hasError) errorMessage = errorLabels.serverErrorLabel;
    else if (equalGuestNumberError && isModifyPassenger) errorMessage = managePassengers.changeGuestsErrorLabel;
    else if (birthdateError) errorMessage = managePassengers.dateOfBirthErrorLabel;
    else errorMessage = errorLabels.generalRequiredError;

    if (hasError || isPastDepartureTime || hasTouchedFieldErrors(errors, getFieldMeta, 'passengers', 'passenger')) {
      if (ref.current && isSubmitting)
        ref.current.scrollIntoView({
          behavior: 'smooth',
        });

      return (
        <div className={cx(bem('server-error'), 'active-error')} role="alert">
          <Icon name="warning" />
          <span className={bem('error-description')}>{errorMessage}</span>
        </div>
      );
    }

    return null;
  };

  const inputKeys = useMemo(() => Object.keys(sortInputErrorsByOrder(errors)), [errors, sortInputErrorsByOrder]);

  const firstFieldFocus = (name: string) => inputKeys[0] === name;

  const renderConfirmation = () => (
    <div className={bem('server-error', { variant: 'modified' })}>
      <Icon name="warning" />
      <span className={bem('error-description')}>{managePassengers.savedChangesLabel}</span>
    </div>
  );

  return {
    ref,
    passengers,
    lockedSteps,
    passengersLabels,
    initialValues,
    errorLabels,
    changesHaveBeenSaved,
    isPastDepartureTime,
    managePassengers,
    smsNotifications,
    confirmNewsletter,
    isCloseNotSaved,
    loading,
    firstFieldFocus,
    handleEdit,
    handleSubmitForm,
    setErrors,
    renderError,
    renderConfirmation,
    handleChange,
    handleConfirmSmsNotification,
    handleConfirmNewsletter,
  };
};
