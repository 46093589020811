import { observer } from 'mobx-react-lite';
import React from 'react';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import './CreateNewItem.scss';
import { CreateNewItemProps } from './types';

const bem = cn('create-item');

const CreateNewItem = observer((props: CreateNewItemProps) => {
  const { label, onClick, modalOnCloseRef } = props;

  return (
    <div className={bem('add-container')}>
      <div className={bem('add-item')}>{label}</div>
      <button ref={modalOnCloseRef} onClick={onClick} className={bem('add-btn')} aria-label="more">
        <Icon name="plus-slim" />
      </button>
    </div>
  );
});

export default CreateNewItem;
