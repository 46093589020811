import React, { useMemo } from 'react';

import { Icon } from '@atoms/Icon';
import { NavItemHeaderProps } from '@molecules/NavItem/types';

import { cn } from 'utils/classNames';

const bem = cn('nav-item-header');
const NavItemHeader = (props: NavItemHeaderProps) => {
  const { displayDropDownIcon, customIcon, subitems, onClick, ariaControls } = props;
  const { label, icon, expanded, setExpanded, link, ariaLabel } = props;
  const { navButtonRef } = props;
  const { url, target } = link || {};
  const LinkWrapper = props.link ? 'a' : 'button';

  const linkWrapperProps = useMemo(
    () =>
      url
        ? {
            href: url,
            target: target || undefined,
            onClick,
          }
        : { onClick },
    [url, onClick, target]
  );

  const trailingIcon = displayDropDownIcon ? (expanded ? 'arrow-up' : 'arrow-down') : null;

  const handleOnClick = () => {
    setExpanded && setExpanded(!expanded);
  };

  const handleFocus = event => {
    event.stopPropagation();
  };

  const handleKeyDown = event => {
    if (!setExpanded) return;

    if (event.key === 'Enter') {
      if (link) return;

      if (!expanded) setExpanded(true);
    } else if (event.key === 'Escape') {
      setExpanded(false);
      navButtonRef?.current?.focus();
    } else return;

    event.preventDefault();
  };

  return (
    <LinkWrapper
      {...linkWrapperProps}
      ref={navButtonRef}
      type={link ? undefined : 'button'}
      className={bem({ expanded: expanded && !!subitems?.length })}
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onFocusCapture={handleFocus}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      aria-expanded={expanded}
    >
      <span className={bem('leading-content')}>
        {customIcon ? (
          <span className={bem('leading-icon')} aria-hidden="true">
            {customIcon}
          </span>
        ) : (
          icon && (
            <span className={bem('leading-icon')} aria-hidden="true">
              <Icon name={icon} />
            </span>
          )
        )}
        <span className={bem('label')}>{label}</span>
      </span>
      {trailingIcon && (
        <span className={bem('trailing-icon')}>
          <Icon name={trailingIcon} />
        </span>
      )}
    </LinkWrapper>
  );
};

export default NavItemHeader;
