import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Input } from '@atoms/Input';

import { cn } from 'utils/classNames';

const bem = cn('profile-settings');

export const DeleteAccount = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { settings: i18n } = cfStore.account;
  const { isLoading, setDeleteDialogClose, deleteAccount } = accountStore.profile;

  const [step, setStep] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const [readyToSubmit, setReadyToSubmit] = useState(false);

  useEffect(() => {
    setReadyToSubmit(inputValue === i18n.deleteInputKeyword);
  }, [inputValue, i18n.deleteInputKeyword]);

  const handleClickDeleteAccountButton = () => {
    setStep(2);
  };

  const handleChange = e => {
    setInputValue(e.target.value);
  };

  const handleClickCancel = () => {
    setDeleteDialogClose();
    setStep(1);
  };

  const handleClickConfirmDeletion = () => {
    if (readyToSubmit) deleteAccount();
  };

  return (
    <div className={bem('delete-account')}>
      {step === 1 && (
        <>
          <div className={bem('delete-account-description')}>{i18n.deleteAccountConfirm1Text}</div>
          <div className={bem('delete-account-actions')}>
            <Button variant="secondary" label={i18n.cancelCtaLabel} color="charcoal" onClick={handleClickCancel} />
            <Button
              className={bem('delete-account-submit-action')}
              variant="primary"
              label={i18n.deleteCtaLabel}
              color="red"
              onClick={handleClickDeleteAccountButton}
            />
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div className={bem('delete-account-description')}>{i18n.deleteAccountConfirm2Text}</div>
          <div className={bem('delete-account-input')}>
            <Input
              value={inputValue}
              name="deleteAccount"
              placeholder={i18n.deleteInputKeyword}
              onChange={handleChange}
            />
          </div>
          <div className={bem('delete-account-actions')}>
            <Button variant="secondary" label={i18n.cancelCtaLabel} color="charcoal" onClick={handleClickCancel} />
            <Button
              variant="primary"
              label={i18n.deleteCtaLabel}
              color="red"
              disabled={!readyToSubmit}
              onClick={handleClickConfirmDeletion}
              loading={isLoading}
            />
          </div>
        </>
      )}
    </div>
  );
});
