import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { useStores } from 'stores';

import { Column, Grid } from '@atoms/Grid';

import { cn } from 'utils/classNames';

import './DateCarousel.scss';
import { DateCarouselProps } from './types';
import { useDateCarouselLogic } from './useDateCarouselLogic';

const bem = cn('header-date-carousel');

export const DateCarousel = observer((props: DateCarouselProps) => {
  const { loading } = props;
  const {
    handleClickSlide,
    activeSlide,
    prev,
    carousel,
    refPassThrough,
    handlers,
    data = [],
    slideWidth,
    next,
    styles,
    handleNextClick,
    handlePrevClick,
    disablePrev,
  } = useDateCarouselLogic(props);

  const {
    cfStore: { header },
    bookingStore,
  } = useStores();

  const { modifyTripProps } = bookingStore.tickets;

  const carouselItems = useMemo(
    () =>
      data?.map(item => {
        const itemPrice = item?.price;
        const itemOriginalPrice = item?.originalPrice;

        let priceLabel;
        let originalPriceLabel;
        if (modifyTripProps)
          if (!item?.disabled && !itemPrice !== null) {
            const priceSuffix = itemPrice < 0 ? '-' : '+';
            priceLabel = `${priceSuffix}$${Math.abs(itemPrice).toFixed(2)}`;
          } else priceLabel = header.noTrainsLabel;
        else {
          priceLabel =
            !item.disabled && itemPrice !== null && itemPrice >= 0
              ? `$${(itemPrice || 0).toFixed(2)}`
              : header.noTrainsLabel;
          originalPriceLabel =
            !item.disabled && itemOriginalPrice !== null && itemOriginalPrice !== itemPrice
              ? `$${(itemOriginalPrice || 0).toFixed(2)}`
              : 0;
        }

        return {
          date: item.date,
          disabled: item.disabled,
          loading: item.loading,
          cheapest: item.cheapest,
          priceLabel,
          originalPriceLabel,
        };
      }),
    [data, header, modifyTripProps]
  );

  return (
    <div style={styles} className={bem('outer-wrapper')}>
      <Grid>
        <Column>
          <div role="region" aria-label="carousel">
            <p className="sr-only">This is a carousel that allows you to navigate through multiple alternative dates for your journey. 
              Use the Next and Previous buttons to navigate the dates.</p>
            <div style={styles} className={bem()}>
              <button
                ref={prev}
                className={bem('prev')}
                aria-label="Previous Period"
                onClick={handlePrevClick}
                disabled={disablePrev}
              />
              <div className={bem('carousel-wrapper')}>
                <div ref={carousel} className={bem('carousel')}>
                  <div className={bem('inner-carousel')}>
                    <div
                      {...handlers}
                      ref={refPassThrough}
                      className={bem('track')}
                    >
                      {carouselItems?.map((item, index) => (
                        <div
                          key={`key-${index}`}
                          className={bem('card-container', { active: activeSlide === index })}
                          style={{ width: slideWidth }}
                          aria-current={activeSlide === index}
                        >
                          <ReactPlaceholder
                            type="text"
                            rows={2}
                            ready={!loading}
                            widths={[70, 20]}
                            className={bem('placeholder')}
                            aria-label={moment(item?.date).format('ddd, D MMM')}
                            showLoadingAnimation
                          >
                            <button
                              className={bem('day', { disabled: item?.disabled })}
                              tabIndex={item.disabled ? -1 : 0}
                              disabled={item.disabled || item.loading}
                              onClick={handleClickSlide(index, item)}
                            >
                              <div className={bem('date')}>{moment(item?.date).format('ddd, D MMM')}</div>
                              <div className={bem('price', { cheapest: item?.cheapest })}>{item.priceLabel}</div>
                              {!!item?.originalPriceLabel && (
                                <div className={bem('original-price')}>{item.originalPriceLabel}</div>
                              )}
                            </button>
                          </ReactPlaceholder>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <button ref={next} className={bem('next')} aria-label="Next Period" onClick={handleNextClick} />
            </div>
          </div>
        </Column>
      </Grid>
    </div>
  );
});
