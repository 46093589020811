import { useMemo } from 'react';
import { useStores } from 'stores';
import { SelectedItemConfig } from 'stores/BookingStore/sections/Tickets/types';

import { parseRteIcons } from 'utils/rteUtils';
import { FareCompareItemProps } from './types';

export const useFareCompareItemLogic = (props: FareCompareItemProps) => {
  const { isItemAvailable: { isAvailable }, fareVariant, fareDescription } = props;

  const { bookingStore } = useStores();
  const { selectedItem, selectItem, modifyTripProps, setIsFareSelected, farePriceDifference } = bookingStore.tickets;

  const { journey } = (selectedItem as SelectedItemConfig) || {}

  const seatProduct = useMemo(() => {
    switch (fareVariant) {
      case 'SMART_SAVER':
        return selectedItem?.journey?.seatProducts?.find(seatProduct => seatProduct.productCode === 'SMART_SAVER');
      case 'SMART_STANDARD':
        return selectedItem?.journey?.seatProducts?.find(seatProduct => seatProduct.productCode === 'SMART_STANDARD');
      case 'PREMIUM_STANDARD':
        return selectedItem?.journey?.seatProducts?.find(seatProduct => seatProduct.productCode === 'PREMIUM_STANDARD');
      default:
        return undefined;
    }
  }, [selectedItem, fareVariant]);

  const descriptionWithIcons = useMemo(() => {
    if (!fareDescription) return '';

    return parseRteIcons(fareDescription);
  }, [fareDescription]);

  const getFormattedPrice = price => {
    if (modifyTripProps) {
      const pricePrefix = (price || 0) < 0 ? '-' : '+';

      return `${pricePrefix}$${Math.abs(price || 0).toFixed(2)}`;
    }

    return `$${price?.toFixed(2)}`;
  };

  const handleSelected = () => {
    if (isAvailable && journey) {
      const defaultFareProduct = {
        productId: seatProduct?.productCode,
        price: farePriceDifference,
      };

      selectItem(journey, defaultFareProduct);
    }
    setIsFareSelected(true);
  };

  return {
    handleSelected,
    seatProduct,
    descriptionWithIcons,
    getFormattedPrice,
  };
};
