import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import { cn } from 'utils/classNames';

import './SafetyPledgeCounterCircle.scss';

const bem = cn('safety-pledge-counter-circle');

const formatNumber = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const SafetyPledgeCounterCircle = ({ pledges, isFormSubmitted }) => {
  const [prevPledges, setPrevPledges] = useState(0);

  // Animation for number counting
  const { number } = useSpring({
    from: { number: prevPledges },
    number: pledges,
    delay: 200,
    config: { duration: 1500 },
    onRest: () => setPrevPledges(pledges),
  });

  return (
    <div className={bem('pledge-counter', { isFormSubmitted: isFormSubmitted })}>
      {isFormSubmitted && <div className={bem('confetti')} />}
      <div className={bem('circle', { isFormSubmitted: isFormSubmitted })}>
        <animated.div className={bem('number')}>{number.to(n => formatNumber(n.toFixed(0)))}</animated.div>
      </div>
    </div>
  );
};

export default SafetyPledgeCounterCircle;
