import { FieldArray, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { useDailyParkingValidationSchema } from '@organisms/ParkingPasses/useValidationSchema';

import { cn } from 'utils/classNames';

import { EditDailyParking } from './EditDailyParking';
import './EditDailyParkingForm.scss';
import { EditDailyParkingFormProps } from './types';

const bem = cn('edit-daily-parking-form');
const bemModal = cn('overlay-container');

export const EditDailyParkingForm = observer((props: EditDailyParkingFormProps) => {
  const { onCancel } = props;

  const { accountStore, cfStore, bookingStore } = useStores();
  const { settings: i18n, tripDetails } = cfStore.account;
  const { setEditDailyParkingDialogClose, trip, bookingSession } = accountStore.tripDetails;
  const ticketNumber = trip?.referenceNumber || '';
  const { loading, data, addBookingParking } = bookingStore.parking;
  const { confirmBooking } = bookingStore.rti;

  const ref = useRef<HTMLDivElement>(null);

  const [initialFormValues, setInitialFormValues] = useState({});

  useEffect(() => {
    const initialValues = {
      drivers: data?.eligibleDrivers
        ? data.eligibleDrivers
            .filter(eligibleDriver => eligibleDriver?.currentSelections?.licensePlate?.state)
            .map(eligibleDriver => ({
              ppStationName: data?.station?.name || 'Miami',
              ppLicenseState: eligibleDriver?.currentSelections?.licensePlate?.state || 'Florida',
              ppLicenseNumber: eligibleDriver?.currentSelections?.licensePlate?.number || '',
              ppPassengerId: eligibleDriver?.passengerId,
              ppPassengerName: eligibleDriver?.passengerName,
            }))
        : [],
    };
    setInitialFormValues(initialValues);
  }, [data?.station, data?.eligibleDrivers]);

  const validationSchema = useDailyParkingValidationSchema({
    licensePlateTextFieldInvalidLabel: tripDetails.editDailyParkingLicensePlateTextFieldInvalidLabel,
  });

  const handleSubmitForm = async values => {
    const body = data?.eligibleDrivers
      .filter(eligibleDriver => eligibleDriver?.currentSelections?.licensePlate?.state)
      .map(eligibleDriver => ({
        passengerId: eligibleDriver.passengerId,
        licensePlateInformation: {
          number:
            values.drivers.find(driver => driver.ppPassengerId === eligibleDriver.passengerId)?.ppLicenseNumber || '',
          state:
            values.drivers.find(driver => driver.ppPassengerId === eligibleDriver.passengerId)?.ppLicenseState || '',
        },
        dateSelectionInterval: {
          startDate: eligibleDriver.currentSelections?.startDate,
          endDate: eligibleDriver.currentSelections?.endDate,
        },
        productCode: eligibleDriver.productCode,
        tariffCode: eligibleDriver.tariffCode,
        quantity: 1,
      }));

    if (body && body.length > 0)
      await addBookingParking(body, ticketNumber, bookingSession, () => {
        confirmBooking({}, () => {
          //This function dooes nothing, but it's required to be passed.
        });
      }).then(() => {
        setEditDailyParkingDialogClose();
      });
    else setEditDailyParkingDialogClose();
  };

  return (
    <div ref={ref}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
        enableReinitialize
      >
        {({ values }) => (
          <Form noValidate>
            <p className={bem('required-field-label')}>{i18n.requiredFieldLabel}</p>
            <FieldArray name="drivers">
              {() => (
                <div>
                  {values.drivers &&
                    values.drivers.length > 0 &&
                    values.drivers.length < 2 &&
                    values?.drivers.map((eligibleDriver, index) => <EditDailyParking key={index} index={index} />)}
                  {values.drivers &&
                    values.drivers.length > 1 &&
                    values?.drivers.map((eligibleDriver, index) => (
                      <EditDailyParking key={index} index={index} passengerName={eligibleDriver?.ppPassengerName} />
                    ))}
                </div>
              )}
            </FieldArray>
            <div className={bemModal('buttons')}>
              <Button
                color="charcoal"
                variant="secondary"
                label={tripDetails.editDailyParkingCancelCtaLabel}
                onClick={onCancel}
              />
              <Button
                color="charcoal"
                label={tripDetails.editDailyParkingSaveCtaLabel}
                type="submit"
                loading={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
});
