import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { useNavigationLogic } from './../useNavigationLogic';
import './ManageTrip.scss';

const bem = cn('trip-details-navigation-manage-trip');

export const ManageTrip = observer(() => {
  const { cfStore } = useStores();
  const { manageTripsSection } = cfStore.account.tripDetails.navigation;

  const { renderUpgradeTripButton, onAddEditExtras, onManagePassengers, handleClick } = useNavigationLogic();

  return (
    <div className={bem()}>
      <div className={bem('icon')}>
        <Icon name="edit2" />
      </div>
      <div className={bem('content')}>
        <div className={bem('title')}>{manageTripsSection.title}</div>
        <div className={bem('items')}>
          {renderUpgradeTripButton()}
          {manageTripsSection.addEditExtras && (
            <button onClick={onAddEditExtras}>{manageTripsSection.addEditExtras}</button>
          )}
          {manageTripsSection.managePassengers && (
            <button onClick={onManagePassengers}>{manageTripsSection.managePassengers}</button>
          )}
          {manageTripsSection.requestAssistance && (
            <button onClick={handleClick}>{manageTripsSection.requestAssistance}</button>
          )}
        </div>
      </div>
    </div>
  );
});
