import React from 'react';

import { Icon } from '@atoms/Icon';
import FeatureItemV2 from '@molecules/FeatureItem/v2/FeatureItemV2';
import { FeatureItemProps } from '@molecules/FeatureItem/v2/types';
import useDesktopCarousel from '@organisms/FeaturesGrid/v2/useDesktopCarousel';

import { cn } from 'utils/classNames';

import { DesktopItemsProps, FeatureRowItems } from '../types';
import { CarouselDots } from './CarouselDots';

const bem = cn('features-grid-v2');

const DesktopItems = ({ rowList, variant }: DesktopItemsProps) => {
  const carouselLogic = useDesktopCarousel(rowList, 0, variant);

  const { getRefFromHandlers, handleCarrotClick, handleCarrotKeyPress, carouselRef } = carouselLogic;
  const { directions, activeSlide, leftCarrotRef, rightCarrotRef } = carouselLogic;

  const isDesktopCarousel = variant === 'carousel';
  const showLeftCarrot = variant === 'carousel' && activeSlide > 1;
  const showRightCarrot = variant === 'carousel' && activeSlide < rowList.length;

  return (
    <div ref={carouselRef} className={bem('desktop-carousel-wrapper')} role="region">
      <div className={bem('desktop-carousel-content')}>
        {showLeftCarrot && (
          <div
            ref={leftCarrotRef}
            className={bem('carrot-wrapper')}
            role="button"
            tabIndex={0}
            aria-label="Move carousel to the left"
            onClick={handleCarrotClick(directions.left)}
            onKeyDown={handleCarrotKeyPress}
          >
            <Icon name="chevron-left" className={bem('carrot')} />
          </div>
        )}
        <div
          ref={getRefFromHandlers}
          className={bem('items-wrapper', { isDesktopCarousel })}
          role="group"
          aria-live="polite"
        >
          {rowList?.map((row: FeatureRowItems, index: number) => {
            const key = `row-${index}`;
            const hasItemsWithPremiumFlag = row.featureItems.find(el => el.premiumFlag && el.showPremiumFlag);
            const hasItemsWithBackground = row.featureItems.find(el => el.colors?.background);
            const isCurrentSlide = variant !== 'carousel' || activeSlide === index + 1;

            return (
              <ul
                key={key}
                className={bem('items', {
                  length: row.featureItems.length,
                  premium: !!hasItemsWithPremiumFlag,
                  background: !!hasItemsWithBackground,
                  isDesktopCarousel,
                })}
                aria-setsize={row.featureItems.length}
                aria-posinset={index + 1}
              >
                {row.featureItems.map((item: FeatureItemProps, index: number) => {
                  const key = `row-item-${item.id ? item.id : index}`;

                  return <FeatureItemV2 key={key} {...item} disableFocus={!isCurrentSlide} />;
                })}
              </ul>
            );
          })}
        </div>
        {showRightCarrot && (
          <div
            ref={rightCarrotRef}
            className={bem('carrot-wrapper')}
            role="button"
            tabIndex={0}
            aria-label="Move carousel to the right"
            onClick={handleCarrotClick(directions.right)}
            onKeyDown={handleCarrotKeyPress}
          >
            <Icon name="chevron-right" className={bem('carrot')} />
          </div>
        )}
      </div>

      {variant === 'carousel' && <CarouselDots rowList={rowList} selectedDot={activeSlide} />}
    </div>
  );
};
export default DesktopItems;
