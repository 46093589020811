import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { OverlayContainer } from '@account/components/OverlayContainer';
import { DeleteAccount } from '@account/routes/Settings/components/DeleteAccount';

import { cn } from 'utils/classNames';

const bem = cn('delete-account-overlay');

export const DeleteAccountOverlay = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { settings: i18n } = cfStore.account;
  const { isDeleteDialogOpen, setDeleteDialogClose } = accountStore.profile;

  return (
    <OverlayContainer
      title={i18n.deleteAccountConfirmTitle}
      isVisible={isDeleteDialogOpen}
      onCancel={setDeleteDialogClose}
      className={bem()}
    >
      <DeleteAccount />
    </OverlayContainer>
  );
});
