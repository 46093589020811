import { useAdyen } from 'hooks/useAdyen';
import moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores';
import { PassengerProps } from 'stores/types/types.passengers';

import { makeDelay } from '@booking/routes/RTI/utils';

import {
  ANALYTICS_DATE_FORMAT,
  consentAnalyticsEvent,
  emailOptInAnalyticsEvent,
  orderSubmission,
  transactionDetails,
} from 'utils/adobeDataLayer';
import { getCartItems } from 'utils/getCartItems';
import { base64Encode } from 'utils/helpers';
import { isKiosk } from 'utils/isKiosk';
import { getAdyenPOIID, getKioskID, isDelayNotificationsEnabled, isLoyaltyEnabled } from 'utils/metas';

import { TransactionDetailItem, TransactionDetailsEventInfo } from '../../types';
import { NEXT_STEP_DELAY } from './constants';

export const usePaymentInfoEntryLogic = () => {
  const { bookingStore, authStore, accountStore, cfStore, kioskStore } = useStores();
  const { isAuthenticated } = authStore;

  const { applicablePasses, provisionalBooking, confirmBooking } = bookingStore.rti;
  const { selectedPaymentMethod, setSelectedPaymentMethod, confirmNewsletter, smsNotifications } = bookingStore.rti;
  const { getBookingPaymentStatus, modifyTripType, setError, clearError } = bookingStore.rti;
  const { paymentInProgress, setPaymentInProgress, loyaltyRewardsPoints } = bookingStore.rti;
  const { sendDelayEmailNotifiactions } = bookingStore.rti.notifications;

  const {
    displayPaymentRequestModal,
    hidePaymentRequestModal,
    displayPaymentProcessingModal,
    displayPaymentErrorModal,
    hidePaymentProcessingModal,
  } = kioskStore;

  const { paymentTokensStore, profile } = accountStore;
  const { userProfile } = profile;
  const { fetchData, paymentTokens, saveToken } = paymentTokensStore;
  const { rti, generic } = cfStore;
  const { reviewAndPay } = rti;
  const { costSummary } = provisionalBooking.tripDetails || {};
  const navigate = useNavigate();

  const [cardInstance, setCardInstance] = useState<any>(null);
  const [cardData, setCardData] = useState<any>(null);
  const [saveCardChecked, setSaveCardChecked] = useState(false);

  const paymentContainerRef = useRef(null);

  const adyenPOIID = getAdyenPOIID();
  const kioskID = getKioskID();

  const delayNotificationsEnabled = isDelayNotificationsEnabled();

  const showRewardsOnConfirmationPage = isLoyaltyEnabled() && !modifyTripType && !userProfile?.travelAgentCode;

  const { initializeAdyenCard } = useAdyen({
    isOpen: true,
    paymentContainer: paymentContainerRef,
    setCardInstance,
    setCardData,
  });

  const handleToggleSaveCard = () => {
    setSaveCardChecked(!saveCardChecked);
  };

  const pushTransactionDetailsEvent = useCallback(
    tripDetails => {
      if (!tripDetails) return;

      const { costSummary, trip } = tripDetails;
      const { outboundRoute, inboundRoute, referenceNumber } = trip;
      const transactionDetailsItems: TransactionDetailItem[] = [];
      const isRoundTrip = !!inboundRoute;

      const { sections, bookingTotal } = costSummary;
      const outboundTripSectionName = `${outboundRoute.origin.abbreviation}-${outboundRoute.destination.abbreviation}`;
      const passenger = tripDetails?.passengerSeatingDetails ? tripDetails?.passengerSeatingDetails[0].passenger : {};
      const rewardsApplied = Number(localStorage.getItem('rewardsApplied')) || 0;

      sections.forEach(section => {
        const { name, items } = section;
        if (name.toLowerCase() === 'other') return;

        const isOutbound = name.toLowerCase() === outboundTripSectionName.toLowerCase();

        const departureDate = moment(outboundRoute.departureDateTime)
          .tz(outboundRoute.origin.timeZone)
          .format(ANALYTICS_DATE_FORMAT);

        const returnDate = isRoundTrip
          ? moment(inboundRoute.departureDateTime).tz(inboundRoute.origin.timeZone).format(ANALYTICS_DATE_FORMAT)
          : null;

        items.forEach(item => {
          transactionDetailsItems.push({
            price: parseFloat(item.totalPrice.toFixed(2)),
            originalPrice: parseFloat(item.totalPrice.toFixed(2)),
            productInfo: {
              category: item.productCode,
              class: isOutbound ? outboundRoute.classType : inboundRoute.classType,
              productId: isOutbound ? outboundRoute.productId : inboundRoute.productId,
              destinationCity: isOutbound ? outboundRoute.destination.name : inboundRoute.destination.name,
              destinationState: null,
              dates: {
                departure: departureDate,
                return: returnDate,
              },
              originCity: outboundRoute.origin.name,
              originState: null,
              productName: item.productName,
              sku: item.productCode,
            },
            quantity: item.quantity,
          });
        });
      });

      const eventInfo: TransactionDetailsEventInfo = {
        cart: {},
        tripManagement: {
          PNR: referenceNumber,
        },
        transaction: {
          cartID: null,
          item: transactionDetailsItems,
          transactionTotal: parseFloat(bookingTotal.totalPaid.toFixed(2)),
          ...(isLoyaltyEnabled() && { totalRewardsapplied: rewardsApplied }),
          transactionID: trip.referenceNumber,
        },
      };

      transactionDetails(eventInfo, authStore.isAuthenticated, passenger);
      localStorage.removeItem('rewardsApplied');
    },
    [authStore.isAuthenticated]
  );

  const pushDataLayerEvent = useCallback(
    (passenger: PassengerProps | undefined) => {
      if (confirmNewsletter) {
        emailOptInAnalyticsEvent({
          emailOptIn: {
            formName: 'emailOptIn',
            formSubmitAll: true,
            email: passenger?.email,
          },
        });
        consentAnalyticsEvent({ email: passenger?.email });
      }
    },
    [confirmNewsletter]
  );

  const handleGoToConfirmPage = useCallback(
    async (tripDetails?) => {
      const bookingNumber = tripDetails?.trip?.referenceNumber;
      const passenger = tripDetails?.passengerSeatingDetails ? tripDetails?.passengerSeatingDetails[0].passenger : {};

      pushDataLayerEvent(passenger);
      if (delayNotificationsEnabled) {
        setPaymentInProgress(true);

        await sendDelayEmailNotifiactions(bookingNumber, isAuthenticated, !!modifyTripType, smsNotifications);

        setPaymentInProgress(false);
      }

      const queryParams = {
        first_name: passenger?.firstName || '',
        last_name: passenger?.lastName || '',
        reference_number: bookingNumber || '',
        reward_points: showRewardsOnConfirmationPage ? loyaltyRewardsPoints : undefined,
        loyalty_user_status: (await profile.getLoyaltyUserStatus()) || '',
      };

      const base64urlParams = base64Encode(JSON.stringify(queryParams));

      if (isKiosk()) navigate(`/confirmation?data=${base64urlParams}&rn=${bookingNumber}`);
      else window.location.href = `${reviewAndPay.confirmationPageUrl}?data=${base64urlParams}&rn=${bookingNumber}`;
    },
    [
      navigate,
      pushDataLayerEvent,
      sendDelayEmailNotifiactions,
      reviewAndPay.confirmationPageUrl,
      delayNotificationsEnabled,
      isAuthenticated,
      modifyTripType,
      setPaymentInProgress,
      loyaltyRewardsPoints,
      showRewardsOnConfirmationPage,
      smsNotifications,
      profile,
    ]
  );

  const handlePay = async () => {
    clearError();
    let cardType;
    if (provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid === 0) cardType = undefined;
    else if (
      isAuthenticated &&
      !(modifyTripType && bookingStore.rti?.provisionalBooking?.tripDetails?.costSummary?.refundMethods?.length) &&
      paymentTokens.length > 0 &&
      (!selectedPaymentMethod || Object.keys(selectedPaymentMethod).length === 0)
    ) {
      setPaymentInProgress(false);
      setError({ stepNr: 4, error: generic.selectPaymentMethodErrorLabel });

      return;
    } else if (selectedPaymentMethod?.tokenId) cardType = selectedPaymentMethod.name;
    else cardType = cardInstance?.state?.selectedBrandValue;

    orderSubmission(
      cardType,
      {
        cartID: provisionalBooking?.tripDetails?.trip?.referenceNumber,
        cartTotal: parseFloat(costSummary?.bookingTotal?.total.toFixed(2) || ''),
        item: [...getCartItems(provisionalBooking)],
      },
      isAuthenticated
    );

    if (selectedPaymentMethod?.tokenId)
      confirmBooking({ paymentToken: { id: selectedPaymentMethod.tokenId } }, handleGoToConfirmPage);
    else if (
      provisionalBooking?.tripDetails?.costSummary?.bookingTotal &&
      provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid <= 0
    )
      confirmBooking({}, handleGoToConfirmPage);
    else if (!cardData?.isValid && cardInstance?.componentRef) cardInstance.componentRef.showValidation();
    // We can confirm a booking (when the total to pay is 0 - he has a pass or something) using {}.
    // The Bff /{language}/public/booking/{bookingNumber}/confirm API takes 2 optional
    // params (paymentToken - if you pay with saved card, and encryptedCardInformation if you pay with new one).
    // If total is 0, BFF expects empty object.
    else {
      if (saveCardChecked)
        await saveToken({
          ...cardInstance.state.data,
          platform: 'web',
          billingAddress: {
            ...cardInstance.state.billingAddress,
          },
        });

      confirmBooking(
        {
          encryptedCardInformation: {
            number: cardInstance.state.data.encryptedCardNumber,
            expiryMonth: cardInstance.state.data.encryptedExpiryMonth,
            expiryYear: cardInstance.state.data.encryptedExpiryYear,
            securityCode: cardInstance.state.data.encryptedSecurityCode,
          },
          billingAddress: {
            street: cardInstance.state.billingAddress.street,
            houseNumberOrName: cardInstance.state.billingAddress.houseNumberOrName,
            postalCode: cardInstance.state.billingAddress.postalCode,
            city: cardInstance.state.billingAddress.city,
            stateOrProvince:
              cardInstance.state.billingAddress.stateOrProvince !== 'N/A'
                ? cardInstance.state.billingAddress.stateOrProvince
                : '',
            country: cardInstance.state.billingAddress.country,
          },
          cardHolderName: cardInstance.state.data.holderName,
        },
        handleGoToConfirmPage
      );
    }
  };

  const checkPaymentStatus = useCallback(async () => {
    const response = await getBookingPaymentStatus();

    if (response === 'success') {
      hidePaymentRequestModal();
      displayPaymentProcessingModal();
      await makeDelay(NEXT_STEP_DELAY);
      hidePaymentProcessingModal();
      handleGoToConfirmPage(provisionalBooking?.tripDetails);
    } else if (response === 'failed') {
      hidePaymentRequestModal();
      displayPaymentErrorModal();
    } else {
      await makeDelay(NEXT_STEP_DELAY);
      await checkPaymentStatus();
    }
  }, [
    displayPaymentErrorModal,
    displayPaymentProcessingModal,
    getBookingPaymentStatus,
    handleGoToConfirmPage,
    hidePaymentProcessingModal,
    hidePaymentRequestModal,
    provisionalBooking?.tripDetails,
  ]);

  const handleOpenPaymentRequestModal = async confirmBookingResponse => {
    if (confirmBookingResponse) {
      displayPaymentRequestModal();
      await makeDelay(NEXT_STEP_DELAY);
      await checkPaymentStatus();
    } else displayPaymentErrorModal();
  };

  const handleKioskPay = async () => {
    await confirmBooking(
      {
        paymentTerminal: {
          id: adyenPOIID,
          kioskId: kioskID,
        },
      },
      handleOpenPaymentRequestModal
    );
  };

  useEffect(() => {
    if (isAuthenticated) fetchData();
  }, [fetchData, isAuthenticated]);

  useEffect(() => {
    initializeAdyenCard();
  }, [initializeAdyenCard]);

  return {
    saveCardChecked,
    handleToggleSaveCard,
    cardData,
    setCardData,
    cardInstance,
    setCardInstance,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    pushTransactionDetailsEvent,
    handlePay,
    isAuthenticated,
    handleKioskPay,
    applicablePasses,
    paymentTokens,
    paymentContainer: paymentContainerRef,
    reviewAndPay,
    generic,
    costSummary,
    provisionalBooking,
    initializeAdyenCard,
    modifyTripType,
    paymentInProgress,
  };
};
