import { FormikContextType, useFormikContext } from 'formik';
import useFocusTrap from 'hooks/useFocusTrap';
import moment from 'moment';
import React, { useMemo } from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import '@molecules/TicketsForm/components/DateSelection/components/DateRangePicker/DesktopCalendar/DesktopCalendar.scss';
import { PartialFromProps } from '@molecules/TicketsForm/components/OriginAndDestination/types';

import { cn, cx } from 'utils/classNames';

import { DesktopCalendarProps } from '../types';

const bem = cn('desktop-calendar');
const bemDateRange = cn('date-range-picker');

export const DesktopCalendar = (props: DesktopCalendarProps) => {
  const {
    onFocusChange,
    handleCloseButton,
    handleClearDates,
    isOutsideRange,
    datesRestrictions,
    isSameDay,
    labels,
    tabType,
    isOpen,
    focusedInput,
    onDatesChange,
  } = props;

  const formikProps: FormikContextType<PartialFromProps> = useFormikContext();
  const { values } = formikProps;

  const maxDate = datesRestrictions ? moment.utc(datesRestrictions.latestDate) : null;

  const renderNavButton = (pos = 'prev') => (
    <button className={bemDateRange('navButton', { pos })}>
      <Icon name="arrow-left" />
    </button>
  );

  const startDateMoment = useMemo(() => {
    if (tabType === 'return') return null;

    if (moment.isMoment(values.startDate)) return values.startDate;
    else if (values.startDate) return moment(values.startDate);

    return null;
  }, [values.startDate, tabType]);

  const endDateMoment = useMemo(() => {
    if (tabType === 'departure') return null;

    if (moment.isMoment(values.endDate)) return values.endDate;
    else if (values.endDate) return moment(values.endDate);

    return null;
  }, [values.endDate, tabType]);

  const renderDayContents = day => <div className={bemDateRange('day', { sameDay: isSameDay })}>{day.date()}</div>;

  const dialogRef = useFocusTrap(isOpen);

  return (
    <div ref={dialogRef} className={cx(bem('calendar'), bemDateRange('calendar'))} tabIndex={0}>
      <DayPickerRangeController
        numberOfMonths={2}
        onFocusChange={onFocusChange}
        startDate={startDateMoment}
        endDate={endDateMoment}
        daySize={46}
        minimumNights={0}
        minDate={moment()}
        navPrev={renderNavButton()}
        navNext={renderNavButton('next')}
        renderDayContents={renderDayContents}
        enableOutsideDays={false}
        isOutsideRange={isOutsideRange(moment(), maxDate)}
        transitionDuration={0}
        focusedInput={focusedInput}
        onDatesChange={onDatesChange}
        hideKeyboardShortcutsPanel
      />
      <div className={bem('footer')}>
        <div className={bem('footer-right')}>
          <Button
            className={bem('button-clear')}
            label={labels.calendarClearDatesButtonLabel}
            color="white"
            onClick={handleClearDates}
            size="medium"
          />
          <Button
            className={bem('button-close')}
            label={labels.calendarCloseButtonLabel}
            onClick={handleCloseButton}
            size="medium"
            color="blue"
          />
        </div>
      </div>
    </div>
  );
};
