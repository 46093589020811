import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { Pagination } from '@molecules/Pagination';

import { cn } from 'utils/classNames';

import './EventsBrowser.scss';
import { EventsBrowserFiltersDrawer } from './components/EventBrowserFiltersDrawer/EventsBrowserFiltersDrawer';
import { EventsBrowserFiltersDesktop } from './components/EventsBrowserFilters/EventsBrowserFilters.desktop';
import { EventsCard } from './components/EventsCard/EventsCard';
import { eventsSkeleton } from './components/Skeleton/EventsSkeleton';
import TopPicksSection from './components/TopPick/TopPicksSection';
import { useEventsBrowserLogic } from './useEventsBrowserLogic';

const bem = cn('events-browser');

export const EventsBrowser = observer(() => {
  const { cfStore } = useStores();
  const {
    upcomingEventsLabel,
    noResultsMessage,
    noResultsTitle,
    noResultsIcon,
    noResultsTitleNoFiltersApplied,
    noResultsMessageNoFiltersApplied,
    noResultsIconNoFiltersApplied,
  } = cfStore.events;

  const logic = useEventsBrowserLogic();

  const { titleSubtitleHTML, carouselHTML, newsletterHTML, offersCardsHTML } = logic;
  const { eventsWrapperRef, topExperienceFragmentHTML, bottomExperienceFragmentHTML } = logic;
  const { eventsLoading, paginatedEvents, selectedFilters, selectedStations } = logic;
  const { setCityPopoverOpen, windowSize, setDatePopoverOpen, filteredEvents } = logic;

  const topPickEvents = useMemo(() => filteredEvents.filter(event => event.isTopPick), [filteredEvents]);

  const { width } = windowSize;
  const {
    pagination,
    handlePageNumberChanged,
    showTopPicksSection,
    topPicksTitle,
    topPicksTitleType,
    topPicksTitleFontStyle,
    topPicksDescription,
    topPicksDescriptionType,
    topPicksDescriptionFontStyle,
  } = logic;

  const { itemsPerPage, pageCount, pageNumber, total } = pagination;

  const [isScrollingDown, setIsScrollingDown] = useState<boolean>(true);
  const prevScrollY = useRef<number>(window.scrollY);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    const scrollingDown = currentScrollY > prevScrollY.current;

    if (scrollingDown !== isScrollingDown) setIsScrollingDown(scrollingDown);

    prevScrollY.current = currentScrollY;

    if (width > 575) {
      setCityPopoverOpen(false);
      setDatePopoverOpen(false);
    }
  }, [isScrollingDown, setCityPopoverOpen, setDatePopoverOpen, width]);

  const updateTopProperty = useCallback(() => {
    let topProperty = 0;
    const headerBannerContainer = document.querySelector('.blte-xf-container__inner-wrapper--header');

    if (headerBannerContainer) {
      const headerBanner = headerBannerContainer.querySelector('.blte-alert-banner--sticky') as HTMLElement;
      if (headerBanner) topProperty = headerBanner.offsetHeight;
    }

    const filterComponent: any = document.querySelector('.blte-events-browser__filters');

    if (filterComponent)
      if (isScrollingDown) filterComponent.style.top = `${topProperty}px`;
      else {
        const globalHeader = document.querySelector('.blte-global-header') as HTMLElement;
        if (globalHeader) topProperty = topProperty + globalHeader.offsetHeight;

        filterComponent.style.top = `${topProperty}px`;
      }
  }, [isScrollingDown]);

  useEffect(() => {
    updateTopProperty();
  });

  useEffect(() => {
    updateTopProperty();
  }, [isScrollingDown, updateTopProperty]);

  useEffect(() => {
    const headerBannerContainer = document.querySelector('.blte-xf-container__inner-wrapper--header');
    let observer: MutationObserver | null = null;

    if (headerBannerContainer) {
      observer = new MutationObserver(() => {
        updateTopProperty();
      });

      observer.observe(headerBannerContainer, { childList: true, subtree: true });
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      if (observer) observer.disconnect();

      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, updateTopProperty]);

  const isTablet = width > 768 && width <= 1024;
  const isMobile = width < 768;
  const isDesktop = width > 1024;

  return (
    <div className={bem()}>
      {topExperienceFragmentHTML && (
        <div
          dangerouslySetInnerHTML={{ __html: topExperienceFragmentHTML }}
          className={bem('topExperienceFragmentHTML')}
        />
      )}
      <div className={bem('headerXfs')}>
        {titleSubtitleHTML && <div dangerouslySetInnerHTML={{ __html: titleSubtitleHTML }} className={bem('title')} />}
        {carouselHTML && <div dangerouslySetInnerHTML={{ __html: carouselHTML }} className={bem('carousel')} />}
      </div>
      <div ref={eventsWrapperRef} className={bem('body-wrapper')}>
        <div className={bem('filters')}>
          <EventsBrowserFiltersDesktop logic={logic} />
        </div>
        <div className={bem('events-section')}>
          <div className={bem('events-title')}>{upcomingEventsLabel}</div>
          <ReactPlaceholder ready={!eventsLoading} customPlaceholder={eventsSkeleton} showLoadingAnimation>
            {!!paginatedEvents.length && (
              <div className={bem('events-wrapper')}>
                {paginatedEvents.map((filteredEvent, idx) => {
                  const shouldInsertTopPicksMiddle =
                    showTopPicksSection &&
                    (((isDesktop || isMobile) && idx === 7) ||
                      (isTablet && idx === 5) ||
                      (isTablet && paginatedEvents.length < 6 && idx === paginatedEvents.length - 1) ||
                      ((isMobile || isDesktop) && paginatedEvents.length < 8 && idx === paginatedEvents.length - 1));

                  return (
                    <React.Fragment key={idx}>
                      <EventsCard event={filteredEvent} />
                      {shouldInsertTopPicksMiddle && (
                        <TopPicksSection
                          topPickEvents={topPickEvents}
                          topPicksTitle={topPicksTitle}
                          topPicksTitleType={topPicksTitleType}
                          topPicksTitleFontStyle={topPicksTitleFontStyle}
                          topPicksDescription={topPicksDescription}
                          topPicksDescriptionType={topPicksDescriptionType}
                          topPicksDescriptionFontStyle={topPicksDescriptionFontStyle}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            {!paginatedEvents.length && (
              <div className={bem('events-empty')}>
                {!selectedFilters.includes('All') || !selectedStations.includes('All') ? (
                  <div>
                    {noResultsIcon && (
                      <div className={bem('icon-parent')}>
                        <Icon
                          className={bem('icon')}
                          externalIcon={noResultsIcon.src}
                          externalIconAlt={noResultsIcon.alt}
                        />
                      </div>
                    )}
                    {noResultsTitle && <Text text={noResultsTitle} className={bem('no-results-title')} />}
                    {noResultsMessage && <Text text={noResultsMessage} className={bem('no-results-description')} />}
                  </div>
                ) : (
                  <div>
                    {noResultsIconNoFiltersApplied && (
                      <div className={bem('icon-parent')}>
                        <Icon
                          className={bem('icon')}
                          externalIcon={noResultsIconNoFiltersApplied.src}
                          externalIconAlt={noResultsIconNoFiltersApplied.alt}
                        />
                      </div>
                    )}
                    {noResultsTitleNoFiltersApplied && (
                      <Text text={noResultsTitleNoFiltersApplied} className={bem('no-results-title')} />
                    )}
                    {noResultsMessageNoFiltersApplied && (
                      <Text text={noResultsMessageNoFiltersApplied} className={bem('no-results-description')} />
                    )}
                  </div>
                )}
              </div>
            )}
            {!!paginatedEvents.length && (
              <div className={bem('pagination-wrapper')}>
                <Pagination
                  {...cfStore.events}
                  itemsPerPage={itemsPerPage}
                  pageCount={pageCount}
                  pageNumber={pageNumber}
                  total={total}
                  siblingCount={1}
                  onPageChange={handlePageNumberChanged}
                />
              </div>
            )}
          </ReactPlaceholder>
        </div>

        <EventsBrowserFiltersDrawer logic={logic} />
      </div>
      {offersCardsHTML && <div dangerouslySetInnerHTML={{ __html: offersCardsHTML }} className={bem('offersCards')} />}
      {bottomExperienceFragmentHTML && (
        <div
          dangerouslySetInnerHTML={{ __html: bottomExperienceFragmentHTML }}
          className={bem('bottomExperienceFragmentHTML')}
        />
      )}
      {newsletterHTML && <div dangerouslySetInnerHTML={{ __html: newsletterHTML }} className={bem('newsletter')} />}
    </div>
  );
});
