import React from 'react';

import { TicketBreak } from '@account/routes/MyTrips/components/UpcomingTrips/components/TripItem/components/TicketBreak';

import { cn } from 'utils/classNames';

const bem = cn('trips-list');

export const UpcomingTripsPlaceholder = () => {
  const group = (
    <>
      <div>
        <div />
        <div />
        <div />
      </div>
      <TicketBreak />
      <div>
        <div />
        <div />
      </div>
    </>
  );

  return (
    <div className={bem('placeholders')}>
      <div />
      {group}
    </div>
  );
};
