import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStores } from 'stores';

import { useCMSContent } from '@account/hooks/useCMSContent';
import { EmptyTrips } from '@account/routes/MyTrips/components/EmptyTrips';
import { PastTripsList } from '@account/routes/MyTrips/components/PastTripsList';
import { Column, Grid } from '@atoms/Grid';

import { cn } from 'utils/classNames';

import './PastTrips.scss';
import { PastTripsPlaceholders } from './PastTripsPlaceholder';
import { PaginationWrapper } from './components';

const bem = cn('past-trips');

const PastTrips = observer(() => {
  useCMSContent('trips');
  const { accountStore, cfStore } = useStores();
  const { trips } = accountStore;
  const { fetchAccountTripsData, resetTripsData, pastTripsItems } = trips;

  const { account } = cfStore;
  const { trips: i18n } = account;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [trips.isLoading]);

  useEffect(() => {
    fetchAccountTripsData();

    return resetTripsData;
  }, [fetchAccountTripsData, resetTripsData]);

  return (
    <Grid className={bem()}>
      <Column cols={12} mq={{ desktop: { cols: 10, offset: 1 } }}>
        <div className={bem('content')}>
          {trips.isLoading ? (
            <PastTripsPlaceholders />
          ) : (
            <>
              <div className={bem('header')}>{i18n.pageTitle}</div>
              {!pastTripsItems.length && <EmptyTrips variant="past" />}
              {!!pastTripsItems.length && (
                <>
                  <PastTripsList variant="long" />
                  <PaginationWrapper />
                </>
              )}
            </>
          )}
        </div>
      </Column>
    </Grid>
  );
});

export default PastTrips;
