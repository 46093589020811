import moment from 'moment/moment';

import { CostSummary } from '../stores/PassesStore/sections';
import { ParkingPass, TravelPass } from '../stores/PassesStore/types';
import { ANALYTICS_DATE_FORMAT } from './adobeDataLayer';

export const isTravelPass = (object: any): object is TravelPass => 'originStation' in object;

export const getPassesCartItems = (
  pass: TravelPass | ParkingPass | undefined,
  costSummary: CostSummary,
  category: string | undefined
) => {
  if (!pass) return [];

  const items: any = [];

  let destinationCity;
  let originCity;

  if (isTravelPass(pass)) {
    destinationCity = pass.destinationStation.city;
    originCity = pass.originStation.city;
  } else {
    destinationCity = pass.station.city;
    originCity = null;
  }

  costSummary?.sections.forEach(section => {
    if (section?.items[0] && section?.items[0].totalPrice)
      items.push({
        price: parseFloat(section?.items[0].totalPrice.toFixed(2)),
        productInfo: {
          class: category || '',
          destinationCity: destinationCity,
          destinationState: 'fl',
          dates: {
            departure: moment(pass.startDate).format(ANALYTICS_DATE_FORMAT),
            return: null,
          },
          originCity: originCity,
          originState: 'fl',
          productName: section?.items[0].productName,
        },
        quantity: section?.items[0].quantity,
      });
  });

  return items;
};

export const getTransactionDetailsItems = (pass: TravelPass | ParkingPass | undefined, costSummary: CostSummary) => {
  if (!pass) return [];

  const transactionDetailsItems: any = [];

  let destinationCity;
  let originCity;

  if (isTravelPass(pass)) {
    destinationCity = pass.destinationStation.city;
    originCity = pass.originStation.city;
  } else {
    destinationCity = pass.station.city;
    originCity = null;
  }

  costSummary?.sections.forEach(section => {
    const { name, items } = section;
    if (name.toLowerCase() === 'other') return;

    items.forEach(item => {
      transactionDetailsItems.push({
        price: parseFloat(item.totalPrice.toFixed(2)),
        originalPrice: parseFloat(item.totalOriginalPrice.toFixed(2)),
        productInfo: {
          category: item.productCode,
          class: item.productName,
          destinationCity: destinationCity,
          destinationState: 'fl',
          dates: {
            departure: moment(pass.startDate).format(ANALYTICS_DATE_FORMAT),
            return: null,
          },
          originCity: originCity,
          originState: 'fl',
          productName: item.productName,
          sku: item.productCode,
        },
        quantity: item.quantity,
      });
    });
  });

  return transactionDetailsItems;
};
