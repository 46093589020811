import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Card } from '../Card';
import './PaymentInfo.scss';
import { PaymentInfoEntry } from './components/PaymentInfoEntry';
import { PaymentInfoProps } from './types';

export const PaymentInfo = observer((props: PaymentInfoProps) => {
  const { stepNr, mode } = props;
  const { bookingStore, cfStore } = useStores();

  const { provisionalBooking } = bookingStore.rti;
  const { rti, generic } = cfStore;

  const { reviewAndPay } = rti;
  const totalToBePaid = provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid;

  return (
    <Card title={totalToBePaid && totalToBePaid > 0? reviewAndPay.cardTitle : reviewAndPay.cardTitleNotPaying} stepNr={stepNr} mode={mode} editBtnLabel={generic.editLabel}>
      <PaymentInfoEntry />
    </Card>
  );
});
