export const color_base_disabled = '#fefefe';
export const color_base_light_grey = '#f2f2f4';
export const color_base_medium_light_grey = '#e1e1e1';
export const color_base_medium_grey = '#a3a3a3';
export const color_base_medium_dark_grey = '#8d8d8d';
export const color_base_dark_grey = '#6e6e6e';
export const color_granite_gray = '#636366';
export const color_base_black = '#363636';
export const color_black = '#000';
export const color_brand_yellow = '#ffdb00';
export const color_brand_blue = '#094273';
export const color_brand_teal = '#2e6a6e';
export const color_brand_seafoam = '#e8f2f1';
export const color_warning_red = '#c00';
export const color_white = '#fff';
export const color_dark_blue = '#042c4f';
export const color_fuchsia = '#CB2B99';
export const color_success_green = '#137e00';
export const input_helper_color = color_base_dark_grey;
export const color_maximum_blue = '#239FB6';
export const color_light_silver = '#d8d8d8';
export const color_pale_turquoise = '#e6f2f2';
export const color_bluish_purple = '#d3beff';
export const color_pinkish_purple = '#d885ff';
export const smart_passes_background_color = (mainColor: string, radialColor: string) =>
  `radial-gradient(87.45% 120.57% at 19.27% 108.91%, ${mainColor} 61.42%, ${radialColor} 100%)`;
export const premium_passes_background_color = (mainColor: string, radialColor: string) =>
  `radial-gradient(87.45% 120.57% at 19.27% 108.91%, ${mainColor} 39.34%, ${radialColor} 100%)`;

export const hexToRGB = (hex, alpha) => {
  const newHex = hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))
    .join(' ');

  if (alpha) return `rgb(${newHex} / ${alpha}%)`;

  return `rgb(${newHex})`;
};
