import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { InputField } from '@atoms/Input';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './PromoCode.scss';
import { PromoCodeFieldProps } from './types';
import { usePromoCode } from './usePromoCode';

const bem = cn('promo-code');

export const PromoCodeField = observer((props: PromoCodeFieldProps) => {
  const { i18n, deprecatedSearch, name = 'promoCode', value } = props;

  const formikContext = useFormikContext();

  const { values, setFieldValue, errors } = formikContext;

  const {
    handleAddCodeClick,
    handleApplyCode,
    handleRemoveCode,
    isLoading,
    showInput,
    inputRef,
    handleCapitalizePromoCode,
  } = usePromoCode(props);

  const button = {
    label: i18n.label,
    variant: 'medium',
    underline: true,
    showArrowIcon: false,
  };

  const showApplyButton = !deprecatedSearch;

  const handleApplyButtonClicked = () => {
    const promo = values?.[name];
    handleApplyCode(promo, values, name, i18n, deprecatedSearch);
  };
  const handleRemoveButtonClicked = () => {
    setFieldValue(name, '');
    handleRemoveCode();
  };

  return (
    <div className={bem({ input: showInput })}>
      {value && (
        <div className={bem('promo-btn')}>
          <div className={bem('promo-btn-code')}>
            <Icon name="check-circle" className={bem('promo-btn-icon-left')} />
            <Text text={value} className={bem('promo-btn-text')} />
          </div>
          <div className={bem('promo-btn-remove')} onClick={handleRemoveButtonClicked}>
            <Icon name="close2" className={bem('promo-btn-icon-right')} />
          </div>
        </div>
      )}
      {!value && showInput && errors && (
        <>
          <InputField
            forwardedRef={inputRef}
            className={bem('input')}
            type="text"
            name={name}
            errorVariant="inline"
            error={!!errors[name]}
            errorMessage={errors[name]}
            label={i18n.inputFloatingLabel}
            onChange={e => handleCapitalizePromoCode(e.target.value, name, setFieldValue)}
            displayTrailingIcon
          />
          {showApplyButton && (
            <Button
              className={bem('promo-btn-apply')}
              label={i18n.applyButtonLabel}
              loading={isLoading}
              color="blue"
              type="button"
              onClick={handleApplyButtonClicked}
            />
          )}
        </>
      )}
      {!value && !showInput && i18n.label && (
        <span className={bem('add-code')}>
          <LinkButton {...button} className={bem('button')} onClick={handleAddCodeClick} />
        </span>
      )}
    </div>
  );
});
