import { observer } from 'mobx-react-lite';
import React, { Suspense, useEffect } from 'react';
import { useStores } from 'stores';

import { Navigation } from '@account/components/Navigation';
import { useLogin } from '@account/hooks/useLogin';
import { TopLoadingBar } from '@atoms/TopLoadingBar';

import { cn } from 'utils/classNames';
import { isLoyaltyEnabled } from 'utils/metas';

import { LayoutWithSidebar } from '../LayoutWithSidebar';
import { Profile } from '../Profile';
import { ConfigContainerProps } from './types';

const bem = cn('account-dashboard-container');

export const ConfigContainer = observer(({ children }: ConfigContainerProps) => {
  const { authStore, accountStore, passesStore } = useStores();

  const { isAuthenticated } = authStore;
  const { fetchPaymentMethodsAndSavedCards } = passesStore.checkout;

  const { fetchAllData, clearAllData, fetchLoyaltyData, clearLoyaltyData } = accountStore;
  const { displayLoyaltyComponents } = accountStore.profile;

  const loyaltyEnabled = isLoyaltyEnabled();

  useLogin();

  // useEffect cannot be used because we need this to happen before first rendering
  if (isAuthenticated) {
    document.body.classList.add('hasVisibleXFContainers');
    document.body.classList.remove('hasHiddenSidebar');
  } else document.body.classList.remove('hasVisibleXFContainers', 'hasVisibleSidebar');

  useEffect(() => {
    // Fetch all the data necessary by the Config routes
    if (isAuthenticated) {
      fetchAllData();
      fetchPaymentMethodsAndSavedCards();
    }

    return clearAllData;
  }, [fetchAllData, clearAllData, isAuthenticated, fetchPaymentMethodsAndSavedCards]);

  useEffect(() => {
    if (isAuthenticated && displayLoyaltyComponents) fetchLoyaltyData();

    return clearLoyaltyData;
  }, [isAuthenticated, displayLoyaltyComponents, fetchLoyaltyData, clearLoyaltyData]);

  if (!isAuthenticated) return null;

  return (
    <>
      <TopLoadingBar />
      <LayoutWithSidebar className={bem()}>
        <div>
          <div>
            {!loyaltyEnabled && <Profile />}
            <Navigation />
          </div>
        </div>
        <div>
          <Suspense fallback={<div />}>{children}</Suspense>
        </div>
      </LayoutWithSidebar>
    </>
  );
});
