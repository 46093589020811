import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useStores } from 'stores';

import { CardV2 } from '@account/components/CardV2';
import { Button } from '@atoms/Button';
import { ImageButton } from '@atoms/ImageButton';
import { Text } from '@atoms/Text';
import { Drawer } from '@molecules/Drawer';

import { cn } from 'utils/classNames';

import { RewardsPointsTracker } from '../RewardsPointsTracker';
import { useRewardsSummaryLogic } from './useRewardsSummaryLogic';

const bem = cn('rewards-summary');

const RewardsSummaryMobile = observer(() => {
  const { cfStore } = useStores();

  const { rewardsGeneral, dashboard } = cfStore.account;
  const {
    redeemPointsButtonLabel,
    redeemPointsButtonLink,
    startEarningButtonLabel,
    startEarningButtonLink,
    pendingPointsColorZero,
    pendingPointsColorPositive,
  } = rewardsGeneral.rewardsTracker;

  const { downloadCardButtonLabel } = rewardsGeneral.rewardsCard;

  const logic = useRewardsSummaryLogic();
  const { isHistoryLoading, isSummaryLoading, isProfileLoading } = logic;
  const { handleClick, toggleDrawer, formattedMemberSinceDate, history, summary } = logic;
  const { isCardExpanded, addToWalletImg, addToWalletLink } = logic;

  const { spendable, pending, expirationDate, membership_id } = summary || {};
  const total = (spendable || 0) + (pending || 0);
  const formattedExpirationDate =
    expirationDate && moment(expirationDate).isValid() ? moment(expirationDate).format('MMM D, YYYY') : '';

  return (
    <div className={bem()}>
      <div className={bem('title-date-container')}>
        <Text className={bem('title')} text={rewardsGeneral.rewardsPage.pageTitle} />
        {formattedMemberSinceDate && (
          <Text
            className={bem('membership', { placeholder: isProfileLoading })}
            text={`${dashboard.memberSinceLabel} <span class=${bem('date')}>${formattedMemberSinceDate}</span>`}
          />
        )}
      </div>
      <div className={bem('tracker-card-container')}>
        <RewardsPointsTracker
          currentProgress={spendable || 0}
          totalProgress={total}
          progressBarBackgroundColor={total > 0 ? pendingPointsColorPositive : pendingPointsColorZero}
          expiryDate={formattedExpirationDate}
          isLoading={isSummaryLoading}
          {...rewardsGeneral.rewardsTracker}
        />
        <div className={bem('card-container')}>
          <CardV2
            {...rewardsGeneral.rewardsCard}
            cardId={membership_id || ''}
            isLoading={isProfileLoading || isSummaryLoading}
            sneakPeakLayout
          />
        </div>
      </div>
      <div className={bem('buttons-container')}>
        <div className={bem('button-container', { placeholder: isProfileLoading || isSummaryLoading })}>
          {!(isProfileLoading || isSummaryLoading) && (
            <Button
              label={downloadCardButtonLabel}
              className={bem('action-button')}
              size="small"
              color="blue"
              variant="primary"
              role="link"
              onClick={toggleDrawer}
            />
          )}
        </div>
        <div className={bem('button-container', { placeholder: isSummaryLoading || isHistoryLoading })}>
          {!(isSummaryLoading || isHistoryLoading) && history && history.length > 0 && (
            <Button
              label={(spendable || 0) > 0 ? redeemPointsButtonLabel : startEarningButtonLabel}
              className={bem('action-button')}
              size="small"
              color="blue"
              variant="secondary"
              role="link"
              onClick={() => handleClick((spendable || 0) > 0 ? redeemPointsButtonLink : startEarningButtonLink)}
            />
          )}
        </div>
      </div>
      <Drawer isVisible={isCardExpanded} onClose={toggleDrawer} isMobileRewards={isCardExpanded}>
        <div className={bem('expanded-view')}>
          <CardV2
            {...rewardsGeneral.rewardsCard}
            cardId={membership_id || ''}
            isLoading={isProfileLoading || isSummaryLoading}
          />
          {addToWalletImg && addToWalletLink && (
            <div onClick={() => setTimeout(toggleDrawer, 500)}>
              <ImageButton label="" image={addToWalletImg} link={{ url: addToWalletLink, target: '_blank' }} />
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
});

export default RewardsSummaryMobile;
