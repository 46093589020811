import React, { useMemo } from 'react';

import { Icon } from '@atoms/Icon';

import { cn, cx } from 'utils/classNames';

import './LinkButton.scss';
import { LinkButtonProps } from './types';

const bem = cn('link-button');

const LinkButton = (props: LinkButtonProps) => {
  const {
    variant = 'small',
    label,
    id,
    link,
    colors,
    border,
    showArrowIcon,
    underline,
    openLinkInNewWindow,
    isMobileRti,
    iconClass='long-arrow-right',
  } = props;
  const { onClick, className, disableFocus, externalBtnRef, hideAriaLabel } = props;
  const { url = '#', target } = link || {};

  const ButtonWrapper = !!link && url ? 'a' : 'button';

  const buttonWrapperProps =
    !!link && url && openLinkInNewWindow
      ? {
          target,
          ref: externalBtnRef,
          onClick,
        }
      : !!link && url
      ? {
          href: url,
          target,
          ref: externalBtnRef,
        }
      : { 'aria-labelledby': id, onClick, ref: externalBtnRef };

  const vars: any = useMemo(
    () =>
      colors
        ? {
            '--text-color': colors.text?.inactive,
            '--text-hover-color': colors.text?.hover,
            '--border-focus-color': colors.border?.focus,
            '--border-style': border
          }
        : {},
    [colors, border]
  );

  return (
    <ButtonWrapper
      {...buttonWrapperProps}
      className={cx(bem({ variant, underline }), className)}
      style={vars}
      tabIndex={disableFocus ? -1 : undefined}
      aria-label={hideAriaLabel ? undefined : label}
    >
      <span className={cx(bem('label', { isMobileRti: isMobileRti }))}>{label}</span>
      {showArrowIcon && (
        <span className={bem('icon')} aria-hidden="true">
          <Icon name={iconClass} />
        </span>
      )}
    </ButtonWrapper>
  );
};

export default LinkButton;
