export const isAuthorMode = () => !!document.querySelector("meta[name='is-author-mode']");

export const isEditMode = () => isAuthorMode() && !!document.querySelector("meta[name='is-edit-mode']");

export const getLocale = (addSlash = false) => {
  const locale = document.querySelector("meta[name='blte-locale']");
  const content = locale?.getAttribute('content') || '';

  return addSlash && !!content ? `${content}/` : content;
};

export const getLocaleOrDefault = () => {
  const locale = getLocale();

  return locale || 'en-us';
};

export const getContext = () => {
  const context = document.querySelector("meta[name='blte-context']");

  return context?.getAttribute('content') || '';
};

export const getAuth0Domain = () => {
  const authDomain = document.querySelector("meta[name='auth0-domain']");

  return authDomain && authDomain.getAttribute('content') ? authDomain.getAttribute('content') || '' : '';
};

export const getAuth0ClientId = () => {
  const clientId = document.querySelector("meta[name='auth0-client-id']");

  return clientId && clientId.getAttribute('content') ? clientId.getAttribute('content') || '' : '';
};

export const getAdyenKey = () => {
  const adyenKey = document.querySelector("meta[name='adyen-key']");

  return adyenKey && adyenKey.getAttribute('content') ? adyenKey.getAttribute('content') || '' : '';
};

export const getAdyenEnvironment = () => {
  const adyenEnv = document.querySelector("meta[name='adyen-environment']");

  return adyenEnv && adyenEnv.getAttribute('content') ? adyenEnv.getAttribute('content') || '' : '';
};

export const getCartToken = () => {
  const cartToken = document.querySelector("meta[name='cart-token']");

  return cartToken && cartToken.getAttribute('content') ? cartToken.getAttribute('content') || '' : '';
};
export const getCartAPIDomain = () => {
  const cartToken = document.querySelector("meta[name='cart-domain']");

  return cartToken && cartToken.getAttribute('content') ? cartToken.getAttribute('content') || '' : '';
};

export const getLegacyAPIDomain = () => {
  const cartToken = document.querySelector("meta[name='legacy-api-domain']");

  return cartToken && cartToken.getAttribute('content') ? cartToken.getAttribute('content') || '' : '';
};

export const getAuth0RedirectURI = () => {
  const redirectURI = document.querySelector("meta[name='auth0-redirect-uri']");

  return redirectURI && redirectURI.getAttribute('content') ? redirectURI.getAttribute('content') || '' : '';
};

export const getAdyenPOIID = () => {
  const redirectURI = document.querySelector("meta[name='adyen-poi-id']");

  return redirectURI?.getAttribute('content') || '';
};

export const getKioskID = () => {
  const redirectURI = document.querySelector("meta[name='blte-kiosk-id']");

  return redirectURI?.getAttribute('content') || '';
};

export const isBrandedFaresEnabled = () => {
  const enableBrandedFares = document.querySelector("meta[name='enable-branded-fares']");

  return enableBrandedFares?.getAttribute('content') === 'true';
};

export const isBrandedFaresV2Enabled = () => {
  const enableBrandedFaresV2 = document.querySelector("meta[name='enable-branded-fares-v2']");

  return enableBrandedFaresV2?.getAttribute('content') === 'true';
};

export const isLoyaltyEnabled = () => {
  const enableLoyalty = document.querySelector("meta[name='enable-engagement']");

  return enableLoyalty?.getAttribute('content') === 'true';
};

export const isGiftCardEnabled = () => {
  const enableGiftCard = document.querySelector("meta[name='enable-gift-card']");

  return enableGiftCard?.getAttribute('content') === 'true';
};

export const getBffEndpoint = () => {
  const bffEndpoint = document.querySelector("meta[name='bff-endpoint']");

  return bffEndpoint?.getAttribute('content') || '';
};

export const isDirectBffCallsEnabled = () => {
  const enableDirectBffCalls = document.querySelector("meta[name='enable-direct-bff-calls']");

  return enableDirectBffCalls?.getAttribute('content') === 'true';
};

export const isDelayNotificationsEnabled = () => {
  const enableDelayNotifications = document.querySelector("meta[name='enable-delay-notifications']");

  return enableDelayNotifications?.getAttribute('content') === 'true';
};

export const isSmsDelayNotificationsEnabled = () => {
  const enableSmsDelayNotifications = document.querySelector("meta[name='enable-sms-delay-notifications']");

  return enableSmsDelayNotifications?.getAttribute('content') === 'true';
};

export const isDelayExperienceEnabled = () => {
  const enableDelayExperience = document.querySelector("meta[name='enable-delays-experience']");

  return enableDelayExperience?.getAttribute('content') === 'true';
};

export const isForcedLoginEnabled = () => {
  const forcedLogin = document.querySelector("meta[name='enable-forced-login']");

  return forcedLogin?.getAttribute('content') === 'true';
};

export const isContinueAsGuestEnabled = () => {
  const continueAsGuest = document.querySelector("meta[name='enable-continue-as-guest']");

  return continueAsGuest?.getAttribute('content') === 'true';
};
