import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';

import './UpcomingTrips.scss';
import { TripItem } from './components/TripItem';

const bem = cn('trips-list');

export const UpcomingTrips = observer(() => {
  const { cfStore, accountStore } = useStores();
  const { account } = cfStore;
  const { trips: i18n } = account;
  const { trips } = accountStore;
  const { loadMoreUpcoming, upcomingTripsItems } = trips;

  return (
    <div className={bem()}>
      <h1 className={bem('header')}>{i18n.upcomingTripsLabel}</h1>
      <div className={bem('list')}>
        {!!trips.upcoming.length &&
          upcomingTripsItems.map(trip => (
            <TripItem
              key={trip.referenceNumber}
              {...trip}
              seeTicketsLabel={i18n.seeTicketsLabel}
              tripDetailsLabel={i18n.tripDetailsLabel}
            />
          ))}
      </div>
      <div className={bem('paging')}>
        <div className={bem('paging-text')}>
          {upcomingTripsItems.length} {i18n.outOfLabel} {trips.upcoming.length} {i18n.upcomingItemsLabel}
        </div>

        {upcomingTripsItems.length !== trips.upcoming.length && (
          <Button label={i18n.loadMoreLabel} size="medium" variant="primary" color="black" onClick={loadMoreUpcoming} />
        )}
      </div>
    </div>
  );
});
