import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { Text } from '@atoms/Text';
import { RewardsPoints } from '@molecules/RewardsPoints/RewardsPoints';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './CostSummary.scss';
import { CostSummaryItem } from './components/CostSummaryItem';
import { CostSummaryItemProps } from './components/CostSummaryItem/types';
import { PromoCode } from './components/PromoCode';

const bem = cn('passes-cost-summary');

export const CostSummary = observer(() => {
  const { cfStore, passesStore, authStore } = useStores();
  const { costSummary, urlParams, promoCode, promoCodeError, loadingPromoCode } = passesStore.checkout;
  const passesCostSummary = cfStore.passes.costSummary;
  const { isAuthenticated: isWebAuthenticated } = authStore;
  const cfLabels = cfStore.passes.costSummary;
  const { updateLoyaltyDisplayStatus } = passesStore.checkout;

  const isMobileAuthenticated = urlParams.mobileData.jwt;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const isAuthenticated = useMemo(
    () => isWebAuthenticated || isMobileAuthenticated,
    [isWebAuthenticated, isMobileAuthenticated]
  );

  const costSummaryPassItemsConfig = useMemo(() => {
    const itemsArray: CostSummaryItemProps[] = [];

    if (urlParams.travelPass)
      itemsArray.push({
        itemName: urlParams.travelPass && urlParams.parkingPass ? cfLabels.trainPassLabel : cfLabels.subtotalLabel,
        itemPrice: urlParams.travelPass.price ? urlParams.travelPass.price : 0,
      });

    if (urlParams.parkingPass)
      itemsArray.push({
        itemName: urlParams.travelPass && urlParams.parkingPass ? cfLabels.parkingPassLabel : cfLabels.subtotalLabel,
        itemPrice: urlParams.parkingPass?.price ? urlParams.parkingPass?.price : 0,
      });

    const discount = urlParams.totalPrice - urlParams.totalPriceDiscounted;
    if (discount)
      itemsArray.push({
        itemName: cfLabels.saveWithAutoRenewLabel,
        itemPrice: discount,
        isDiscount: true,
      });

    if (isAuthenticated)
      costSummary.sections
        .find(section => section.name.toLowerCase() === 'other')
        ?.items.filter(item => item.productName.toLowerCase() !== 'taxes and fees' && item.totalPrice < 0)
        .forEach(item => {
          itemsArray.push({
            itemName: item.productName,
            itemPrice: Math.abs(item.totalPrice),
            isDiscount: true,
          });
        });

    //Gift Cards in cost summary
    costSummary?.sections
      ?.find(section => section.name.toLowerCase() === 'other')
      ?.items?.filter(item => item.productName.startsWith('****'))
      ?.forEach(item =>
        itemsArray.push({
          itemName: item.productName,
          itemPrice: Number(item.totalPrice.toFixed(2)),
          isDiscount: true,
        })
      );

    costSummary?.sections
      ?.find(section => section.name.toLowerCase() === 'other')
      ?.items?.filter(item => item.productName.startsWith('Brightline Rewards'))
      ?.forEach(item =>
        itemsArray.push({
          itemName: item.productName,
          itemPrice: Number(item.totalPrice.toFixed(2)),
          isDiscount: true,
        })
      );

    if (!isAuthenticated)
      itemsArray.push({
        itemName: cfLabels.taxesAndFeesLabel,
        itemPrice: 0.0,
      });
    else {
      const taxes = costSummary.items.find(item => item.productName.toLowerCase() === 'taxes and fees');
      if (taxes)
        itemsArray.push({
          itemName: cfLabels.taxesAndFeesLabel,
          itemPrice: taxes.totalPrice,
        });
      else
        itemsArray.push({
          itemName: cfLabels.taxesAndFeesLabel,
          itemPrice: 0.0,
        });
    }

    return itemsArray;
  }, [
    cfLabels,
    isAuthenticated,
    costSummary,
    urlParams.parkingPass,
    urlParams.travelPass,
    urlParams.totalPrice,
    urlParams.totalPriceDiscounted,
  ]);

  const costSummaryTotalConfig = useMemo(() => {
    if (isAuthenticated) return costSummary.bookingTotal.totalToBePaid;

    return urlParams.totalPriceDiscounted;
  }, [isAuthenticated, costSummary, urlParams.totalPriceDiscounted]);

  useEffect(() => {
    updateLoyaltyDisplayStatus();
  }, [updateLoyaltyDisplayStatus]);

  return (
    <div className={bem({ marginTop: !isAuthenticated && isMobile })}>
      <div className={bem('card', { padding: !isAuthenticated && isMobile })}>
        <Text text={cfLabels.costSummaryTitle} className={bem('title')} />
        <div className={bem('items')}>
          {costSummaryPassItemsConfig.map((item, index) => (
            <CostSummaryItem key={index} {...item} />
          ))}
        </div>
        <div className={bem('separator')} />
        <CostSummaryItem itemName={cfLabels.totalLabel} itemPrice={costSummaryTotalConfig} isTotal />
        <RewardsPoints {...passesCostSummary} totalToBePaid={costSummaryTotalConfig} isPasses />
        <PromoCode promoCode={promoCode} loadingPromoCode={loadingPromoCode} promoCodeError={promoCodeError} />
        {!isAuthenticated && isMobile && <Text text={cfLabels.needHelpLabel} className={bem('help-text')} />}
      </div>
      {!isMobile && <Text text={cfLabels.needHelpLabel} className={bem('help-text')} />}
    </div>
  );
});
