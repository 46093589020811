import moment from 'moment/moment';
import React from 'react';

import Alert from '@atoms/Alert/Alert';
import { AlertColors } from '@atoms/Alert/types';
import { Icon } from '@atoms/Icon';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { EventPills } from '../../../apps/events/routes/EventsBrowser/components/EventsCard/components/EventPills';
import { color_warning_red, color_white } from '../../../utils/colors';
import './EventDetails.scss';

const bem = cn('event-details');

export const EventDetails = (props: any) => {
  const { eventLabel, title, eventDateAndTime, timeLabel, contactLabel } = props;
  const { phoneNumber, linkLabel, linkValue, howToGetFromStationLabel } = props;
  const { addressLabel, addressText, eventPills, eventExpired, eventExpiredMessage } = props;
  const { bullet1Text, bullet2Text, bullet3Text, bullet1LinkButtonLabel } = props;
  const { bullet2LinkButtonLabel, bullet3LinkButtonLabel, bullet1LinkButtonLink } = props;
  const { bullet2LinkButtonLink, bullet3LinkButtonLink } = props;

  const formattedEventTime = moment().format('ddd, MMM D · h:mmA');

  const alertColors: AlertColors = {
    icon: { inactive: color_warning_red },
    text: { inactive: color_white },
    background: { inactive: color_warning_red },
    summary: { inactive: color_white },
  };

  return (
    <div className={bem()}>
      <div className={bem('title-wrapper')}>
        <Text text={eventLabel} className={bem('title-label')} />
        <Text text={title} className={bem('title')} />
      </div>
      {eventExpired && eventExpiredMessage && (
        <Alert icon="warning" summary={eventExpiredMessage} colors={alertColors} />
      )}
      <div className={bem('body')}>
        {eventPills.length && (
          <div className={bem('event-pills')}>
            {eventPills.map((pill, pillIndex) => (
              <EventPills
                key={pillIndex}
                backgroundColor={pill.backgroundColor}
                labelColor={pill.labelColor}
                label={pill.label}
              />
            ))}
          </div>
        )}
        {timeLabel && eventDateAndTime && (
          <div className={bem('info-wrapper')}>
            <div className={bem('info-icon')}>
              <Icon name="clock" />
            </div>
            <div className={bem('info-details')}>
              <Text text={timeLabel} className={bem('info-title')} />
              <Text text={formattedEventTime} className={bem('info-text')} />
            </div>
          </div>
        )}
        {addressLabel && addressText && (
          <div className={bem('info-wrapper')}>
            <div className={bem('info-icon')}>
              <Icon name="map-pin" />
            </div>
            <div className={bem('info-details')}>
              <Text text={addressLabel} className={bem('info-title')} />
              <Text text={addressText} className={bem('info-text')} />
            </div>
          </div>
        )}

        {howToGetFromStationLabel && (bullet1Text || bullet2Text || bullet3Text) && (
          <div className={bem('station-wrapper')}>
            <Text text={howToGetFromStationLabel} className={bem('station-text')} />
            <ul className={bem('station-list')}>
              {bullet1Text && (
                <li>
                  <div className={bem('station-bullet-wrapper')}>
                    <Text text={bullet1Text} className={bem('station-bullet-text')} />
                    {bullet1LinkButtonLabel && bullet1LinkButtonLink && (
                      <LinkButton
                        label={bullet1LinkButtonLabel}
                        variant="big"
                        className={bem('link-button')}
                        link={{ url: bullet1LinkButtonLink, target: '_self' }}
                      />
                    )}
                  </div>
                </li>
              )}
              {bullet2Text && (
                <li>
                  <div className={bem('station-bullet-wrapper')}>
                    <Text text={bullet2Text} className={bem('station-bullet-text')} />
                    {bullet2LinkButtonLabel && bullet2LinkButtonLink && (
                      <LinkButton
                        label={bullet2LinkButtonLabel}
                        variant="big"
                        link={{ url: bullet2LinkButtonLink, target: '_self' }}
                      />
                    )}
                  </div>
                </li>
              )}
              {bullet3Text && (
                <li>
                  <div className={bem('station-bullet-wrapper')}>
                    <Text text={bullet3Text} className={bem('station-bullet-text')} />
                    {bullet3LinkButtonLabel && bullet3LinkButtonLink && (
                      <LinkButton
                        label={bullet3LinkButtonLabel}
                        variant="big"
                        link={{ url: bullet3LinkButtonLink, target: '_self' }}
                      />
                    )}
                  </div>
                </li>
              )}
            </ul>
          </div>
        )}

        {contactLabel && phoneNumber && (
          <div className={bem('info-wrapper')}>
            <div className={bem('info-icon')}>
              <Icon name="phone" />
            </div>
            <div className={bem('info-details')}>
              <Text text={contactLabel} className={bem('info-title')} />
              <Text text={phoneNumber} className={bem('info-text')} />
            </div>
          </div>
        )}

        {linkLabel && linkValue && (
          <div className={bem('info-wrapper')}>
            <div className={bem('info-icon')}>
              <Icon name="anchor" />
            </div>
            <div className={bem('info-details')}>
              <Text text={linkLabel} className={bem('info-title')} />
              <a href={linkValue} className={bem('info-text')}>
                {linkValue}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
