import React, { useMemo } from 'react';

import { PictureSourceProps } from './types';

export const PictureSource = (props: PictureSourceProps) => {
  const { media, width, height, src, lazyLoaded } = props;

  const hydratedProps = useMemo(() => (lazyLoaded ? { srcSet: src } : {}), [lazyLoaded, src]);

  return <source media={media} width={width} height={height} data-srcset={src} {...hydratedProps} />;
};
