import { useEffect, useRef } from 'react';

const useFocusTrap = (isOpen: boolean) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const previouslyFocusedElement = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const dialog = dialogRef.current;

    if (dialog && isOpen) {
      previouslyFocusedElement.current = document.activeElement as HTMLElement;
      dialog.focus();

      const focusableElements = Array.from(
        dialog.querySelectorAll<HTMLElement>('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
      );

      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];

      const handleTabKey = (event: KeyboardEvent) => {
        if (event.key !== 'Tab') return;

        const activeElement = document.activeElement as HTMLElement;
        const currentIndex = focusableElements.indexOf(activeElement);

        if (event.shiftKey)
          if (activeElement === firstFocusableElement || currentIndex === -1) {
            // Shift + Tab
            event.preventDefault();
            lastFocusableElement.focus();
          } else {
            event.preventDefault();
            focusableElements[currentIndex - 1]?.focus();
          }
        // Tab
        else if (activeElement === lastFocusableElement || currentIndex === -1) {
          event.preventDefault();
          firstFocusableElement.focus();
        } else {
          event.preventDefault();
          focusableElements[currentIndex + 1]?.focus();
        }
      };

      document.addEventListener('keydown', handleTabKey);

      return () => {
        document.removeEventListener('keydown', handleTabKey);
      };
    }

    return () => {
      if (previouslyFocusedElement.current) previouslyFocusedElement.current.focus();
    };
  }, [isOpen]);

  return dialogRef;
};

export default useFocusTrap;
