import { observer } from 'mobx-react-lite';
import React from 'react';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './ErrorBanner.scss';
import { ErrorBannerProps } from './types';

const bem = cn('trip-details-error-banner');

export const ErrorBanner = observer((props: ErrorBannerProps) => (
  <div className={bem('error-banner')}>
    <Icon name="warning" />
    <div>
      <Text className={bem('error-title')} text={props.title} />
      <span className={bem('error-description')}>{props.description}</span>
    </div>
  </div>
));
