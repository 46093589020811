import React, { useState } from 'react';

export const useInputHandlers = (inputRef, setFocus, handleKeyDown, focus, setSearchTerm, filteredOptions) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const onSearchCountryCode = e => {
    setSearchTerm(e.target.value);
    if (inputRef.current) {
      inputRef.current.focus();
      setIsInputFocused(true);
      setFocus?.({ index: -1, preventScroll: false });
    }
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
    if (setFocus) setFocus({ index: -1, preventScroll: false });
  };

  const handleInputBlur = () => {
    if (focus?.index !== -1) setIsInputFocused(false);
  };

  const handleInputMouseOver = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      setIsInputFocused(true);

      setFocus?.({ index: -1, preventScroll: false });
    }
  };

  const handleInputMouseLeave = () => {
    if (inputRef.current) {
      inputRef.current.blur();
      setIsInputFocused(false);
    }
  };

  const handleKeyDownWrapper = (e: React.KeyboardEvent) => {
    const { key } = e;

    if (key === 'ArrowDown') {
      e.preventDefault();
      if (focus?.index === -1) {
        setFocus({ index: 0 });
        setIsInputFocused(false);
      } else if (focus?.index !== undefined && focus.index < filteredOptions.length - 1)
        setFocus({ index: focus.index + 1 });
    }

    if (key === 'ArrowUp') {
      e.preventDefault();
      if (focus?.index > 0) setFocus({ index: focus.index - 1 });
      else if (focus?.index === 0) {
        inputRef.current?.focus();
        setIsInputFocused(true);
        setFocus({ index: -1 });
      }
    }

    if (handleKeyDown) handleKeyDown(e);
  };

  return {
    isInputFocused,
    onSearchCountryCode,
    handleInputFocus,
    handleInputBlur,
    handleInputMouseOver,
    handleInputMouseLeave,
    handleKeyDownWrapper,
  };
};
