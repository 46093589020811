import { observer } from 'mobx-react-lite';
import React from 'react';

import { DateInputs } from '../DateInputs';
import { DesktopDateRangePickerProps } from '../types';
import { DesktopCalendar } from './DesktopCalendar';
import { DEFAULT_I18N } from './constants';

export const DesktopDateRangePicker = observer((props: DesktopDateRangePickerProps) => {
  const { toggleItems, isRoundTrip, tabType, labels } = props;
  const { isOpen, focusedInput, handleCloseButton } = props;
  const { handleFocusChange, handleDatesChange, isOutsideRange } = props;
  const { handleClearDates, isSameDay, datesRestrictions } = props;

  return (
    <>
      {isOpen && (
        <DesktopCalendar
          datesRestrictions={datesRestrictions}
          closeButtonLabel={DEFAULT_I18N.closeButtonLabel}
          clearButtonLabel={DEFAULT_I18N.clearButtonLabel}
          onFocusChange={handleFocusChange}
          focusedInput={focusedInput}
          handleCloseButton={handleCloseButton}
          onDatesChange={handleDatesChange}
          isOutsideRange={isOutsideRange}
          handleClearDates={handleClearDates}
          toggleItems={toggleItems}
          isSameDay={isSameDay}
          isOneWay={!isRoundTrip}
          tabType={tabType || ''}
          labels={labels}
          isOpen={isOpen}
        />
      )}
      <DateInputs {...props} isOneWay={!isRoundTrip} />
    </>
  );
});
