import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';

import { ContentHeader } from '@account/components/ContentHeader';
import { ProfileDetailsProps } from '@account/routes/Settings/components/ProfileDetails/types';
import AlertWithButton from '@atoms/AlertWithButton/AlertWithButton';
import { LinkButton } from '@atoms/LinkButton';

import { cn, cx } from 'utils/classNames';
import { color_brand_seafoam, color_brand_yellow, color_warning_red } from 'utils/colors';
import { isLoyaltyEnabled } from 'utils/metas';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';

import { SettingEntry } from '../SettingEntry';
import { SettingEntryTravelAgent } from '../SettingEntryTravelAgent';
import { SettingEntryWithAction } from '../SettingEntryWithAction';

const bem = cn('profile-settings');

export const ProfileDetails = observer((props: ProfileDetailsProps) => {
  const { modalOnCloseRef } = props;

  const { accountStore, cfStore } = useStores();
  const { settings: i18n } = cfStore.account;
  const { userProfile, setDeleteDialogOpen, setEditDialogOpen, setResetDialogOpen, setEditDialogTravelAgentOpen } =
    accountStore.profile;

  const [showModifyTripAlert, setShowModifyTripAlert] = useState(false);
  const travelAgentCodeLength: number = userProfile?.travelAgentCode?.length || 0;
  const travelAgentCodeInvalid: boolean = travelAgentCodeLength < 9;

  useEffect(() => {
    if (travelAgentCodeLength > 0) setShowModifyTripAlert(true);
  }, [travelAgentCodeLength]);

  return (
    <>
      <ContentHeader pageTitle={i18n.pageTitle} />

      {showModifyTripAlert && (
        <div className={bem('alert-section')}>
          <AlertWithButton
            description={
              travelAgentCodeInvalid ? i18n.travelAgentCodeInvalidDescription : i18n.travelAgentCodeValidDescription
            }
            icon="clear"
            onClick={() => setShowModifyTripAlert(false)}
            colors={
              travelAgentCodeInvalid
                ? { background: { inactive: color_brand_seafoam } }
                : { background: { inactive: color_brand_yellow } }
            }
          />
        </div>
      )}

      <div className={bem('section-header')}>
        <h2 className={bem('section-title')}>{i18n.profileDetailsTitle}</h2>
        <LinkButton
          externalBtnRef={modalOnCloseRef}
          className={bem('section-action')}
          label={i18n.editDetailsCtaLabel}
          variant="big"
          onClick={setEditDialogOpen}
          underline
        />
      </div>

      <SettingEntry label={i18n.nameLabel} value={`${userProfile?.firstName} ${userProfile?.lastName}`} />

      <SettingEntry label={i18n.dateOfBirthLabel} value={moment(userProfile?.dateOfBirth).utc().format('MM/DD/YYYY')} />

      <SettingEntry label={i18n.emailLabel} value={userProfile?.email} />

      {isLoyaltyEnabled() && <SettingEntry label={i18n.zipCodeLabel} value={userProfile?.zipCode} />}

      <SettingEntry
        label={i18n.phoneNumberLabel}
        value={formatPhoneNumber(userProfile?.phoneNumber.countryCode, userProfile?.phoneNumber.number)}
      />

      <SettingEntry
        label={i18n.accessibilityLabel}
        value={
          userProfile?.wheelchairAccommodation === 'accessible_space'
            ? i18n.wheelchairAccessibilityLabel
            : i18n.noAccessibilityDescription
        }
      />

      <div className={bem('section-header')}>
        <h2 className={bem('section-title')}>{i18n.travelAgentCodeTitle}</h2>
        <LinkButton
          className={bem('section-action')}
          label={i18n.editDetailsCtaLabel}
          variant="big"
          onClick={setEditDialogTravelAgentOpen}
          underline
        />
      </div>

      <SettingEntryTravelAgent label={i18n.travelAgentCodeLabel} value={i18n.nineDigitTravelCodeLabel} />

      <div className={cx(bem('section-header', bem('has-top-margin')))}>
        <h3 className={bem('section-title')}>{i18n.securityHeader}</h3>
      </div>

      {!i18n.hideResetPassword && (
        <SettingEntryWithAction
          label={i18n.passwordLabel}
          value={i18n.resetPasswordDescription}
          ctaLabel={i18n.resetCtaLabel}
          onClick={setResetDialogOpen}
        />
      )}

      <SettingEntryWithAction
        label={i18n.deleteAccountLabel}
        value={i18n.deleteAccountDescription}
        ctaLabel={i18n.deleteAccountCtaLabel}
        ctaColors={{
          text: {
            inactive: color_warning_red,
            hover: color_warning_red,
          },
          border: {
            focus: color_warning_red,
          },
        }}
        onClick={setDeleteDialogOpen}
      />
    </>
  );
});
