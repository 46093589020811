import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { mediaQueryList } from 'utils/mediaQueries';

import { TicketsFormDesktop } from './ModifyTripForm.desktop';
import { TicketsFormMobile } from './ModifyTripForm.mobile';
import './ModifyTripForm.scss';
import { TicketsFormProps } from './types';

export const ModifyTripForm = observer((props: TicketsFormProps) => {
  const { title = '', deprecatedSearchResultsPage, ...rest } = props;
  const {
    hideFormOnMobile,
    validateOnMount,
    isRoundTrip,
    inboundRoute,
    outboundRoute,
    pnr,
    tabType,
    labels,
    isFirstLegTraveled,
    isSecondLegTraveled,
  } = props;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const { ticketsFormStore } = useStores();
  const { formValues } = ticketsFormStore;

  const timeZone = outboundRoute?.origin?.timeZone;
  const departureTime = moment(outboundRoute?.departureDateTime)?.tz(timeZone || '') ?? '';
  const returnTime = inboundRoute && isRoundTrip ? moment(inboundRoute?.departureDateTime).tz(timeZone || '') : '';

  return isMobile ? (
    <TicketsFormMobile
      {...rest}
      title={title}
      hideFormOnMobile={hideFormOnMobile}
      stations={ticketsFormStore.stationsData}
      datesRestrictions={ticketsFormStore.datesRestrictionsData}
      initialFormValues={formValues}
      fromCity={outboundRoute?.origin?.name ?? ''}
      toCity={outboundRoute?.destination?.name ?? ''}
      departureTime={departureTime}
      returnTime={returnTime}
      tabType={tabType}
      pnr={pnr}
      originId={outboundRoute?.origin?.id ?? ''}
      destinationId={outboundRoute?.destination?.id ?? ''}
      isRoundTrip={isRoundTrip}
      validateOnMount={validateOnMount}
      deprecatedSearchResultsPage={deprecatedSearchResultsPage}
      labels={labels}
      isFirstLegTraveled={isFirstLegTraveled}
      isSecondLegTraveled={isSecondLegTraveled}
    />
  ) : (
    <TicketsFormDesktop
      {...rest}
      stations={ticketsFormStore.stationsData}
      datesRestrictions={ticketsFormStore.datesRestrictionsData}
      initialFormValues={formValues}
      fromCity={outboundRoute?.origin?.name ?? ''}
      toCity={outboundRoute?.destination?.name ?? ''}
      departureTime={departureTime}
      returnTime={returnTime}
      tabType={tabType}
      pnr={pnr}
      originId={outboundRoute?.origin?.id ?? ''}
      destinationId={outboundRoute?.destination?.id ?? ''}
      isRoundTrip={isRoundTrip}
      validateOnMount={validateOnMount}
      deprecatedSearchResultsPage={deprecatedSearchResultsPage}
      labels={labels}
      isFirstLegTraveled={isFirstLegTraveled}
      isSecondLegTraveled={isSecondLegTraveled}
    />
  );
});
