import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { rootStore, useStores } from 'stores';
import { SelectedTicket } from 'stores/BookingStore/sections/Tickets/types';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import Image from '@atoms/Image/Image';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';
import { useFareSelectionDrawerLogic } from '@booking/routes/TrainSelection/components/FareSelectionDrawer/useFareSelectionDrawerLogic';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';
import { base64Encode } from 'utils/helpers';
import { setBookingCreationTime, setForcedLoginAnalytics, setLoginRedirectURL } from 'utils/localstorage';
import { isContinueAsGuestEnabled, isForcedLoginEnabled } from 'utils/metas';

import { clickAnalyticsEvent } from '../../../../../../utils/adobeDataLayer';
import './ForcedLoginModal.scss';

const bem = cn('forced-login-modal');

export const ForcedLoginModal = observer(() => {
  const { bookingStore, authStore, cfStore } = useStores();
  const { isAuthenticated } = authStore;
  const { forcedLoginModal, setForcedLoginModal, setHasLoadedQueryParams } = bookingStore.tickets;
  const { createProvisionalBooking, loading } = bookingStore.rti;
  const { forcedLogin } = cfStore.trainSelection;

  const { navigateToRTI } = useFareSelectionDrawerLogic();

  const continueAsGuestEnabled = isContinueAsGuestEnabled();

  const generateTrainSelectionReturnURL = useCallback(() => {
    const ticketData = base64Encode(
      JSON.stringify({
        outboundTicket: forcedLoginModal?.outboundTicket,
        inboundTicket: forcedLoginModal?.inboundTicket,
        promoCode: forcedLoginModal?.promoCode,
      })
    );

    const url = new URL(window.location.href.replaceAll('trip=1', 'trip=0'));
    url.searchParams.set('ticketData', ticketData);

    return url.href;
  }, [forcedLoginModal?.inboundTicket, forcedLoginModal?.outboundTicket, forcedLoginModal?.promoCode]);

  const onLoginClick = useCallback(() => {
    const url = generateTrainSelectionReturnURL();

    setForcedLoginAnalytics({
      customLink: 'forcedLoginModal',
      externalLink: '',
      linkType: 'linkClickForcedLogin',
      navigationLinkName: 'login',
      pageNameClickTracking: 'booking|trainSelection',
    });

    setLoginRedirectURL(url);
    window.location.href = `${window.location.origin}/account/login`;
  }, [generateTrainSelectionReturnURL]);

  const onRegisterClick = useCallback(() => {
    const url = generateTrainSelectionReturnURL();

    setForcedLoginAnalytics({
      customLink: 'forcedLoginModal',
      externalLink: '',
      linkType: 'linkClickForcedLogin',
      navigationLinkName: 'createAccount',
      pageNameClickTracking: 'booking|trainSelection',
    });

    setLoginRedirectURL(url);
    window.location.href = `${window.location.origin}/account/signup`;
  }, [generateTrainSelectionReturnURL]);

  const onContinueAsGuestClick = useCallback(async () => {
    if (!forcedLoginModal || loading) return;

    clickAnalyticsEvent(rootStore, {
      linkClick: {
        customLink: 'forcedLoginModal',
        externalLink: '',
        linkType: 'linkClickForcedLogin',
        navigationLinkName: 'continueAsGuest',
        pageNameClickTracking: 'booking|trainSelection',
      },
    });

    const bookingResponse = await createProvisionalBooking(
      forcedLoginModal?.outboundTicket as SelectedTicket,
      forcedLoginModal?.inboundTicket as SelectedTicket,
      forcedLoginModal?.promoCode as string
    );

    setBookingCreationTime({
      bookingNumber: bookingResponse?.tripDetails?.trip.referenceNumber,
      createdAt: Date.now(),
    });

    setHasLoadedQueryParams(false);

    navigateToRTI(bookingResponse);

    // eslint-disable-next-line
  }, [createProvisionalBooking, forcedLoginModal]);

  const handleCloseModal = () => {
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        customLink: 'forcedLoginModal',
        externalLink: '',
        linkType: 'linkClickForcedLogin',
        navigationLinkName: 'exitModal',
        pageNameClickTracking: 'booking|trainSelection',
      },
    });

    setForcedLoginModal(undefined);
  };

  if (!isForcedLoginEnabled() || !forcedLoginModal || isAuthenticated) return null;

  return (
    <Modal variant="no-space" className={bem('modal')}>
      <div className={bem()}>
        <div className={bem('close-button')} onClick={handleCloseModal}>
          <Icon name="close2" className={bem('close-icon')} />
        </div>
        {forcedLogin?.modalImage?.src && (
          <div className={bem('image-container')}>
            <Image {...forcedLogin.modalImage} className={bem('image')} />
          </div>
        )}
        <div className={bem('content')}>
          {forcedLogin?.brightlineLogoImage?.src && (
            <Image {...forcedLogin.brightlineLogoImage} className={bem('logo')} />
          )}
          <Text text={forcedLogin.modalText} className={bem('text')} />
          <div className={bem('buttons')}>
            <Button label={forcedLogin.loginButtonLabel} color="black" onClick={onLoginClick} />
            <Button
              variant="secondary"
              colors={{
                text: '#000000',
                backgroundOrBorder: '#000000',
              }}
              className={bem('register-button')}
              label={forcedLogin.registerButtonLabel}
              onClick={onRegisterClick}
            />
          </div>
          {continueAsGuestEnabled && (
            <LinkButton
              label={forcedLogin.continueAsGuestButtonLabel}
              onClick={onContinueAsGuestClick}
              variant="big"
              underline
            />
          )}
        </div>
      </div>
    </Modal>
  );
});
