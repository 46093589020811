import React from 'react';

import { cn } from 'utils/classNames';

const bem = cn('trip-details-header-info');

export const HeaderInfoPlaceholders = () => (
  <div className={bem('placeholders')}>
    <div />
    <div>
      <div />
      <div />
    </div>
  </div>
);
