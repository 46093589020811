import React from 'react';

export const AlertIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="4" fill="#D41818" />
    <path
      d="M13.3017 4.54583C13.5669 4.54735 13.8207 4.65416 14.0071 4.84276L17.1914 8.06359C17.3778 8.25219 17.4817 8.50714 17.4802 8.77236L17.4543 13.3014C17.4528 13.5666 17.346 13.8204 17.1574 14.0068L13.9366 17.1911C13.748 17.3775 13.493 17.4815 13.2278 17.4799L8.69876 17.4541C8.43355 17.4526 8.1798 17.3457 7.99334 17.1571L4.80909 13.9363C4.62263 13.7477 4.51873 13.4928 4.52024 13.2275L4.54611 8.69848C4.54763 8.43326 4.65444 8.17952 4.84304 7.99305L8.06387 4.80881C8.25247 4.62235 8.50742 4.51845 8.77264 4.51996L13.3017 4.54583Z"
      fill="white"
    />
    <line x1="11" y1="8" x2="11" y2="11" stroke="#D41818" strokeWidth="2" strokeLinecap="round" />
    <circle cx="11" cy="14" r="0.5" fill="#D41818" stroke="#D41818" />
  </svg>
);
