import React from 'react';

import Button from '@atoms/Button/Button';
import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';
import { WelcomeToLoyaltyProps } from '@organisms/LoaltyOptInModal/types';

import { cn } from 'utils/classNames';

import './../LoyaltyOptInModal.scss';

const bem = cn('loyalty-optin-modal');

export const WelcomeToLoyalty = (props: WelcomeToLoyaltyProps) => {
  const { welcomeModalBrandImage, welcomeModalTitle, welcomeModalSubtitle } = props;
  const { welcomeModalButtonLabel, welcomeModalButtonLink = '/account' } = props;

  return (
    <div className={bem('welcome-loyalty')}>
      <div className={bem('welcome-loyalty-header')}>
        <Text text={welcomeModalTitle} className={bem('welcome-loyalty-title')} />
        <Text text={welcomeModalSubtitle} className={bem('welcome-loyalty-subtitle')} />
      </div>
      {welcomeModalBrandImage?.src && <Image {...welcomeModalBrandImage} className={bem('welcome-loyalty-image')} />}
      <Button
        label={welcomeModalButtonLabel}
        link={{ url: welcomeModalButtonLink, target: '_self' }}
        width="full"
        color="yellow"
      />
    </div>
  );
};
