import React from 'react';

import { cn } from 'utils/classNames';

import './MobilePlaceholder.scss';

const bem = cn('seat-map-mobile-placeholder');

export const MobilePlaceholder = () => (
  <div className={bem()}>
    <div className={bem('top')}>
      <div />
    </div>
    <div className={bem('content')}>
      <div />
    </div>
    <div className={bem('footer')}>
      <div className={bem('footer-first')}>
        <div className={bem('passenger')} />
        <div className={bem('site-info')}>
          <div className={bem('coach')}>
            <div />
            <div />
          </div>
          <div className={bem('direction')} />
        </div>
      </div>
      <div className={bem('footer-second')}>
        <div />
      </div>
    </div>
  </div>
);
