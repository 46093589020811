import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { CancelTripModal } from '../../CancelTripModal/CancelTripModal';
import { CancellationDefaultModal } from '../../CancelTripModal/CancellationDefaultModal';
import { useNavigationLogic } from './../useNavigationLogic';
import './CancelTrip.scss';
import { useCancelTripLogic } from './useCancelTripLogic';

const bem = cn('trip-details-navigation-cancel-trip');

export const CancelTrip = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { tripDetails } = accountStore;
  const { navigation } = cfStore.account.tripDetails;

  const { trip, showConfirmationModal, hasCancelTripError, showCancellationModal } = tripDetails;

  const { confirmationModalContent, errorModalContent } = useCancelTripLogic();

  const { onCancelTrip } = useNavigationLogic();

  const modalContent = useMemo(
    () => (hasCancelTripError ? errorModalContent : confirmationModalContent),
    [confirmationModalContent, errorModalContent, hasCancelTripError]
  );

  return (
    <div className={bem({ isCancellable: trip?.outboundRoute?.isCancelled && trip?.inboundRoute?.isCancelled })}>
      <Icon name="cancel" />
      <button onClick={onCancelTrip}>{navigation.cancelTripLabel}</button>
      {showCancellationModal && <CancelTripModal onCancelTrip={onCancelTrip} />}
      {showConfirmationModal && <CancellationDefaultModal {...modalContent} />}
    </div>
  );
});
