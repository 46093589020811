import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Checkbox from '@atoms/Checkbox/Checkbox';
import Image from '@atoms/Image/Image';

import { cn } from 'utils/classNames';

import '../PassItem.scss';
import { CreditPassProps } from '../types';

const bem = cn('rti-wallet-pass');

export const CreditPass = observer((props: CreditPassProps) => {
  const { bookingStore, cfStore } = useStores();
  const { rti } = cfStore;
  const {
    reviewAndPay: { brightlineWallet },
  } = rti;

  const { applyCreditPass, removeCreditPass } = bookingStore.rti;
  const { creditPass, checked, currentAmountApplied, disabledPass } = props;
  const { creditAvailable, passName, code } = creditPass;

  const disabled = useMemo(
    () => !checked && (creditAvailable <= 0 || disabledPass),
    [checked, creditAvailable, disabledPass]
  );

  const creditAvailableLabel = useMemo(() => {
    let roundedCreditAvailable = creditAvailable;

    if (creditAvailable === 0) return brightlineWallet.noCreditRemainingLabel;

    if (Number.isFinite(creditAvailable) && !Number.isInteger(creditAvailable))
      roundedCreditAvailable = parseFloat(creditAvailable.toFixed(2));

    return brightlineWallet.availableCreditLabel.replace('{availableCredit}', roundedCreditAvailable.toString());
  }, [brightlineWallet.availableCreditLabel, brightlineWallet.noCreditRemainingLabel, creditAvailable]);

  const image = useMemo(
    () =>
      creditAvailable === 0
        ? brightlineWallet.disabledBrightlineCreditPassIcon
        : brightlineWallet.brightlineCreditPassIcon,
    [brightlineWallet.brightlineCreditPassIcon, brightlineWallet.disabledBrightlineCreditPassIcon, creditAvailable]
  );

  const handleCreditPassClick = () => {
    if (disabled) return;

    if (checked) removeCreditPass(code);
    else applyCreditPass(code);
  };

  const handleCheckboxChange = () => null;

  return (
    <button className={bem({ disabled, active: checked })} onClick={handleCreditPassClick}>
      <div className={bem('image')}>
        <Image {...image} />
      </div>
      <div className={bem('data')}>
        <div className={bem('info')}>
          <div className={bem('details')}>
            <div className={bem('pass-name')}>{passName}</div>
          </div>
          <div className={bem('remain-items')}>{creditAvailableLabel}</div>
        </div>
        <div className={bem('checkbox-data')}>
          <Checkbox name={passName} label="" isChecked={checked} disabled={disabled} onChange={handleCheckboxChange} />
          {checked && (
            <span>{brightlineWallet.appliedCreditLabel.replace('{usedCredit}', currentAmountApplied.toString())}</span>
          )}
        </div>
      </div>
    </button>
  );
});
