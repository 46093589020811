import React from 'react';
import { EventPill } from 'stores/CFStore/types.events';

import { cn } from 'utils/classNames';

import './EventPills.scss';

const bem = cn('event-pills');

export const EventPills = (props: EventPill) => {
  const { backgroundColor, label, labelColor } = props;

  const pillsStyle = {
    backgroundColor: backgroundColor,
    color: labelColor,
  };

  return (
    <div className={bem()}>
      <span className={bem('pills')} style={{ ...pillsStyle }}>
        {label}
      </span>
    </div>
  );
};
