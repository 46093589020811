import React from 'react';

import FeatureItemV2 from '@molecules/FeatureItem/v2/FeatureItemV2';
import { FeatureItemProps } from '@molecules/FeatureItem/v2/types';

import { cn } from 'utils/classNames';

import { MobileItemsProps } from '../types';
import useMobileCarousel from '../useMobileCarousel';
import { CarouselDots } from './CarouselDots';

const bem = cn('features-grid-v2');

const MobileItems = ({ items = [], variant, colors }: MobileItemsProps) => {
  const { getRefFromHandlers, handlers, activeSlide } = useMobileCarousel(items, variant);

  return (
    <div className={bem('items-wrapper')}>
      <ul className={bem('items')} {...handlers} ref={getRefFromHandlers}>
        {items.map((item: FeatureItemProps, index: number) => {
          const key = `item-${item.id ? item.id : index}`;
          const backgroundColor = item?.colors?.background || colors?.backgroundItem;
          const itemColors = {
            ...item.colors,
            background: backgroundColor,
          };

          return <FeatureItemV2 key={key} {...item} colors={itemColors} />;
        })}
      </ul>
      {variant === 'carousel' && <CarouselDots items={items} selectedDot={activeSlide} />}
    </div>
  );
};

export default MobileItems;
