import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { OverlayContainer } from '@account/components/OverlayContainer';
import { useCMSContent } from '@account/hooks/useCMSContent';
import { useRestoreScroll } from '@account/hooks/useRestoreScroll';

import './Passengers.scss';
import { PassengerForm } from './components/PassengerForm/PassengerForm';
import { AddPassengerProps } from './types';

const AddPassenger = observer((props: AddPassengerProps) => {
  const { isOpen, setIsOpen, onCloseRef } = props;
  useCMSContent('passengersCF');
  const { accountStore, cfStore } = useStores();

  const { passengersStore } = accountStore;
  const { account } = cfStore;
  const { passengersCF } = account;
  const { isLoading, savePassenger } = passengersStore;
  const { setScrollPosition } = useRestoreScroll();

  const handleToggleAdd = () => {
    if (!isOpen) setScrollPosition({ x: window.pageXOffset, y: window.pageYOffset });
    else onCloseRef.current && onCloseRef.current.focus();

    setIsOpen(!isOpen);
  };

  return (
    <>
      <OverlayContainer
        onCancel={handleToggleAdd}
        title={passengersCF.createNewPassengerLabel}
        isVisible={isOpen}
        cancelButtonLabel=""
        submitButtonLabel=""
        ariaLabel="create new guest"
      >
        <PassengerForm
          i18n={passengersCF}
          onToggleEdit={handleToggleAdd}
          onSubmit={savePassenger}
          isLoading={isLoading}
          cancelButtonLabel={passengersCF.cancelCtaLabel}
          submitButtonLabel={passengersCF.saveCtaLabel}
        />
      </OverlayContainer>
    </>
  );
});

export default AddPassenger;
