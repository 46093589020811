import React from 'react';

import { cn } from 'utils/classNames';

import './ImageFlag.scss';
import { ImageFlagProps } from './types';

const bem = cn('image-flag');

const ImageFlag = (props: ImageFlagProps) => {
  const { imageUrl, description } = props;

  return <img src={imageUrl} alt={description} className={bem()} />;
};

export default ImageFlag;
