import React from 'react';

import DesktopSelect from '@atoms/Select/Desktop/DesktopSelect';
import { MobileSelect } from '@atoms/Select/Mobile/MobileSelect';
import SelectHeader from '@atoms/Select/SelectHeader';
import { SelectProps } from '@atoms/Select/types';

import { useSelectHandlers } from './useSelectHandlers';

export const Select = (props: SelectProps) => {
  const { useDesktopVariant } = props;
  const hookProps = useSelectHandlers(props);

  if (!useDesktopVariant)
    return (
      <SelectHeader {...props} {...hookProps}>
        <MobileSelect {...props} {...hookProps} />
      </SelectHeader>
    );

  return <DesktopSelect {...props} {...hookProps} />;
};
