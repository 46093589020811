import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStores } from 'stores';

import { getAlertBanners, setAlertBanners } from 'utils/localstorage';
import { isDelayExperienceEnabled, isEditMode } from 'utils/metas';

export const useBanners = (
  alertKey,
  isAuthoring,
  text,
  sticky,
  profile,
  hideOnLogIn,
  hideOnLogOut,
  id,
  isAuthenticated
) => {
  const [isVisible, setVisibility] = useState(false);
  const [hideAction, setHideAction] = useState(false);

  const {
    accountStore: {
      trips: { tripHasStatus },
    },
  } = useStores();

  const getHiddenAlertBannerKeys = () => {
    const localStorageBanners = getAlertBanners();

    return localStorageBanners ? localStorageBanners.split(',') : [];
  };

  const handleCloseBanner = () => {
    setHideAction(true);
    setVisibility(false);

    const existingBanners = getHiddenAlertBannerKeys();
    const banners = existingBanners.length > 0 ? [...existingBanners, alertKey] : [alertKey];
    id != 'delays-experience-banner' && setAlertBanners(banners);
  };

  const handleActions = action => () => {
    if (action?.name === 'close') handleCloseBanner();
  };

  const hideDelayExperienceBanner = useCallback(() => {
    const hide = !tripHasStatus[alertKey];

    return !isAuthoring ? !((isAuthenticated || !hideOnLogOut) && !hide) : false;
  }, [isAuthoring, hideOnLogOut, isAuthenticated, alertKey, tripHasStatus]);

  const shouldHideBanner = useCallback(() => {
    if (id === 'alertBanner')
      return (
        isAuthoring !== 'true' &&
        ((profile.isAuthenticatedZendesk && hideOnLogIn) ||
          (!profile.isAuthenticatedZendesk && hideOnLogOut) ||
          (hideOnLogOut && hideOnLogIn))
      );

    if (id == 'delays-experience-banner')
      return isDelayExperienceEnabled() || isAuthoring ? hideDelayExperienceBanner() : true;

    return false;
  }, [isAuthoring, profile, hideOnLogIn, hideOnLogOut, id, hideDelayExperienceBanner]);

  const bannerText = text => {
    if (id != 'delays-experience-banner' || isAuthoring) return text;

    const messages = text.replaceAll('.html', '');
    const element = document.createElement('div');
    element.innerHTML = messages;
    const paragraph = element.querySelectorAll('li');

    const message = Array.from(paragraph).map(p => p.innerHTML);
    const obj = message.reduce((acc, item) => {
      const [key, value] = item.split(':').map(str => str.trim());
      acc[key] = value;

      return acc;
    }, {} as { [key: string]: string });

    if (window.location.pathname == '/') return `<p>${obj?.homePageMessage}<p/>`;

    return `<p>${obj?.dashboardPageMessage}<p/>`;
  };

  const hiddenElement = useMemo(() => {
    const shouldHide = shouldHideBanner();

    return (!isVisible || shouldHide) && !isEditMode();
  }, [isVisible, shouldHideBanner]);

  useEffect(() => {
    const banners = getHiddenAlertBannerKeys();
    const visibility = !banners.length || !banners.includes(alertKey);

    setVisibility(visibility);
  }, [alertKey]);

  useEffect(() => {
    const banners = getHiddenAlertBannerKeys();

    if (hideAction && banners.includes(alertKey)) window.dispatchEvent(new Event('xf_container_storage'));
  }, [alertKey, hideAction]);

  useEffect(() => {
    window.dispatchEvent(new Event('recalculateXfContainerHeight'));
  }, [text, sticky, hiddenElement]);

  return {
    hiddenElement,
    handleActions,
    handleCloseBanner,
    bannerText,
  };
};
