import { useMemo } from 'react';

import { useNavItemStyles } from '@molecules/NavItem/useNavItemStyles';

import { GlobalHeaderColorProps } from './types';

export const useGlobalHeaderStyles: any = (colors: GlobalHeaderColorProps) => {
  const navColors = useNavItemStyles(colors);

  return useMemo(
    () => ({
      ...navColors,
      '--header-container-background-color': colors?.container?.background?.inactive,
      '--header-container-border-color': colors?.container?.border?.inactive,
    }),
    [colors, navColors]
  );
};
