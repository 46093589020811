import { CelebrationBannerProps } from '@molecules/CelebrationBanner/types';

import {
  color_base_black,
  color_brand_seafoam,
  color_white,
  color_brand_yellow,
  color_base_dark_grey,
} from 'utils/colors';

import {
  BannerProps,
  ConfirmEndSessionProps,
  HomepageProps,
  TimeoutModalProps,
  PrintErrorModalProps,
  PaymentErrorModalProps,
  PaymentRequestModalProps,
  PaymentProcessingModalProps,
  TermsOfServiceModal,
} from './types';

export const defaultHomepageProps: HomepageProps = {
  backgroundColor: '',
  welcomeMessage: {
    backgroundColor: '',
    text: '',
    textColor: '',
  },
  errorTitle: '',
  errorMessage: '',
  errorCTA: '',
};

export const defaultBannerProps: BannerProps = {
  id: '',
  title: '',
  description: '=',
  img: {
    src: '',
    alt: '',
  },
  colors: {
    background: '',
    text: '',
  },
  firstCta: {
    image: '',
    imageAlt: '',
    link: {
      url: '',
      target: '_blank',
    },
    label: '',
  },
  secondCta: {
    image: '',
    imageAlt: '',
    link: {
      url: '',
      target: '_blank',
    },
    label: '',
  },
  isHydrated: false,
};

export const defaultTimeoutModalProps: TimeoutModalProps = {
  description: '',
  cancelLabel: '',
  confirmLabel: '',
  promptBeforeIdle: 1680,
  timeout: 1800,
  title: '',
};

export const defaultConfirmEndSessionProps: ConfirmEndSessionProps = {
  title: '',
  description: '',
  cancelLabel: '',
  confirmLabel: '',
  isVisible: false,
};

export const defaultPrintErrorModalProps: PrintErrorModalProps = {
  title: '',
  description: '',
  cancelLabel: '',
  isVisible: false,
};

export const defaultPaymentErrorModalProps: PaymentErrorModalProps = {
  icon: '',
  title: '',
  description: '',
  cancelLabel: '',
  confirmLabel: '',
  isVisible: false,
};

export const defaultPaymentRequestModalProps: PaymentRequestModalProps = {
  icon: '',
  title: '',
  description: '',
  cancelLabel: '',
  isVisible: false,
};

export const defaultPaymentProcessingModalProps: PaymentProcessingModalProps = {
  icon: '',
  title: '',
  isVisible: false,
};

export const defaultTermsOfServiceModal: TermsOfServiceModal = {
  experienceFragment: '',
  title: '',
};

export const defaultCelebrationArgs: CelebrationBannerProps = {
  ctaList: [],
  bottomText: '',
  image: {
    lazy: false,
    alt: '',
    title: '',
    src: '',
  },
  id: '',
  title: '',
  bookingLabel: '',
  ticketsSentLabel: '',
  ticketsModifiedLabel: '',
  showLessLabel: '',
  andOtherEmailsLabel: '',
  otherEmailsJoinSeparator: '',
  colors: {
    text: {
      inactive: color_base_black,
    },
    background: {
      inactive: color_brand_seafoam,
    },
    cardsBackground: {
      inactive: color_white,
    },
    bubbleBorder: {
      inactive: color_brand_yellow,
    },
    bubbleBackground: {
      inactive: color_brand_yellow,
    },
    couldHaveEarnedText: {
      inactive: color_white,
    },
    couldHaveEarnedBackground: {
      inactive: color_base_dark_grey,
    },
  },
};
