import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';

import TrainStatusMessage from '@account/components/TrainStatusMessage/TrainStatusMessage';
import { Tag } from '@account/routes/MyTrips/components/UpcomingTrips/components/TripItem/components/Tag';
import { ShareTripModal } from '@account/routes/TripDetails/components/ShareTripModal/ShareTripModal';
import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { usePrintingLogic } from '@molecules/CelebrationBanner/usePrintingLogic';

import { cn } from 'utils/classNames';
import { isDelayExperienceEnabled } from 'utils/metas';
import { getTicketsLink } from 'utils/tickets';

import { ErrorBanner } from '../ErrorBanner';
import './HeaderInfo.scss';
import { HeaderInfoPlaceholders } from './HeaderInfoPlacehoders';
import { HeaderInfoProps } from './types';

const bem = cn('trip-details-header-info');

export const HeaderInfo = observer(({ kioskMode }: HeaderInfoProps) => {
  const [shareTripModalOpen, setShareTripModalOpen] = useState(false);

  const { accountStore, cfStore } = useStores();
  const { tripDetails, profile } = accountStore;
  const { isLoading, trip, hasCancelTripError, ticketDocumentDetails } = tripDetails;
  const { addPrintButtonProps } = usePrintingLogic({});

  if (!trip) return null;

  const { account } = cfStore;
  const { tripDetails: tripDetailsLabels } = account;

  const { outboundRoute, inboundRoute, isCancelled } = trip;
  const { cancelledStatusLabel, updatedStatusLabel } = tripDetailsLabels.delaysExperienceMessages;

  const isReaccommodated = inboundRoute?.isReaccommodated || outboundRoute.isReaccommodated;
  const cancelTripError = tripDetailsLabels?.navigation?.cancelTrip?.serverErrorLabel;

  const tagVariant = isCancelled ? 'cancelled' : 'delayed';
  const tagLabel = isCancelled ? cancelledStatusLabel : updatedStatusLabel;
  const tripHasStatus = isCancelled || isReaccommodated;

  const bannerTitle = isCancelled
    ? tripDetailsLabels?.cancellationNotReaccBannerTitle
    : tripDetailsLabels?.cancellationReaccBannerTitle;
  const bannerDescription = isCancelled
    ? tripDetailsLabels?.cancellationNotReaccBannerDescription
    : tripDetailsLabels?.cancellationReaccBannerDescription || '';

  const handleShareTripClicked = () => {
    if (!profile?.userProfile?.travelAgentCode) return;

    setShareTripModalOpen(true);
  };

  const handleShareTripModalClose = () => {
    setShareTripModalOpen(false);
  };

  return (
    <div className={bem()}>
      {isLoading ? (
        <HeaderInfoPlaceholders />
      ) : (
        <>
          {tripHasStatus && !!bannerTitle && (
            <ErrorBanner title={hasCancelTripError ? cancelTripError : bannerTitle} description={bannerDescription} />
          )}
          {isDelayExperienceEnabled() && (
            <>
              {outboundRoute?.trainStatus && (
                <TrainStatusMessage variant={outboundRoute.trainStatus} route={outboundRoute} />
              )}
              {inboundRoute?.trainStatus && (
                <TrainStatusMessage variant={inboundRoute.trainStatus} route={inboundRoute} />
              )}
            </>
          )}
          <div className={bem('header-container')}>
            <div className={bem('stations')}>
              {outboundRoute && <h2>{outboundRoute.origin.name}</h2>}
              <span className={bem('from-to-icon')}>
                <Icon name={inboundRoute ? 'arrow-double' : 'arrow-from-to'} />
              </span>
              {outboundRoute && <h2>{outboundRoute.destination.name}</h2>}
            </div>

            {tripHasStatus && <Tag variant={tagVariant} label={tagLabel} size="small" />}
          </div>

          <div className={bem('action-buttons')}>
            {kioskMode ? (
              <Button
                variant="primary"
                color="charcoal"
                icon="ticket"
                {...addPrintButtonProps(tripDetailsLabels.printTicketsLabel)}
              />
            ) : (
              <>
                {tripDetailsLabels.seeTicketsLabel && (
                  <Button
                    label={tripDetailsLabels.seeTicketsLabel}
                    variant="primary"
                    color="charcoal"
                    icon="ticket"
                    link={getTicketsLink(ticketDocumentDetails)}
                  />
                )}
                {!!profile?.userProfile?.travelAgentCode && tripDetailsLabels.shareTripLabel && (
                  <Button
                    label={tripDetailsLabels.shareTripLabel}
                    variant="secondary"
                    color="charcoal"
                    icon="external"
                    onClick={handleShareTripClicked}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
      {shareTripModalOpen && <ShareTripModal handleShareTripModalClose={handleShareTripModalClose} />}
    </div>
  );
});
