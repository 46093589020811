import { merge } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { fiveMinutesCachedApi } from 'utils/api';

import { RootStore } from '../index';
import {
  defaultBannerProps,
  defaultCelebrationArgs,
  defaultConfirmEndSessionProps,
  defaultHomepageProps,
  defaultPaymentErrorModalProps,
  defaultPaymentProcessingModalProps,
  defaultPaymentRequestModalProps,
  defaultPrintErrorModalProps,
  defaultTermsOfServiceModal,
  defaultTimeoutModalProps,
} from './constants';

export default class KioskStore {
  private readonly rootStore: RootStore;

  banner = defaultBannerProps;
  homepage = defaultHomepageProps;
  timeoutModal = defaultTimeoutModalProps;
  confirmEndSessionModal = defaultConfirmEndSessionProps;
  celebration = defaultCelebrationArgs;
  printErrorModal = defaultPrintErrorModalProps;
  paymentErrorModal = defaultPaymentErrorModalProps;
  paymentRequestModal = defaultPaymentRequestModalProps;
  paymentProcessingModal = defaultPaymentProcessingModalProps;
  termsOfServiceModal = defaultTermsOfServiceModal;
  isLoading = true;
  error = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });

    this.init();
  }

  cancelPayment() {
    this.hidePaymentRequestModal();
  }

  displayPaymentProcessingModal() {
    this.paymentProcessingModal.isVisible = true;
  }

  hidePaymentProcessingModal() {
    this.paymentProcessingModal.isVisible = false;
  }

  hidePaymentRequestModal() {
    this.paymentRequestModal.isVisible = false;
  }

  displayPaymentRequestModal() {
    this.paymentRequestModal.isVisible = true;
  }

  displayPaymentErrorModal() {
    this.paymentErrorModal.isVisible = true;
  }

  hidePaymentErrorModal() {
    this.paymentErrorModal.isVisible = false;
  }

  displayPrintErrorModal() {
    this.printErrorModal.isVisible = true;
  }

  hidePrintErrorModal() {
    this.printErrorModal.isVisible = false;
  }

  displaySessionClearanceModal() {
    this.confirmEndSessionModal.isVisible = true;
  }

  hideSessionClearanceModal() {
    this.confirmEndSessionModal.isVisible = false;
  }

  confirmSessionClearance() {
    const { bookingStore } = this.rootStore;
    const { rti, tickets } = bookingStore;

    this.hideSessionClearanceModal();
    rti.resetRtiData();
    tickets.resetData();
    window.location.reload();
  }

  setKioskData(data) {
    if (data && data.banner) this.banner = merge({}, this.banner, data.banner);
    if (data && data.homepage) this.homepage = merge({}, this.homepage, data.homepage);
    if (data && data.celebration) this.celebration = merge({}, this.celebration, data.celebration);
    if (data && data.timeoutModal) this.timeoutModal = merge({}, this.timeoutModal, data.timeoutModal);
    if (data && data.confirmEndSessionModal)
      this.confirmEndSessionModal = merge({}, this.confirmEndSessionModal, data.confirmEndSessionModal);

    if (data && data.printErrorModal) this.printErrorModal = merge({}, this.printErrorModal, data.printErrorModal);
    if (data && data.paymentErrorModal)
      this.paymentErrorModal = merge({}, this.paymentErrorModal, data.paymentErrorModal);

    if (data && data.paymentRequestModal)
      this.paymentRequestModal = merge({}, this.paymentRequestModal, data.paymentRequestModal);

    if (data && data.paymentProcessingModal)
      this.paymentProcessingModal = merge({}, this.paymentProcessingModal, data.paymentProcessingModal);

    if (data && data.termsOfServiceModal)
      this.termsOfServiceModal = merge({}, this.termsOfServiceModal, data.termsOfServiceModal);
  }

  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  setError(error) {
    this.error = error;
  }

  async init() {
    try {
      const el = document.getElementById('kiosk-root');
      const { cfStore } = this.rootStore;

      if (!el) return;

      const { endpoint } = el.dataset;

      if (endpoint) {
        const result = await fiveMinutesCachedApi.get(endpoint, { id: endpoint });

        this.setKioskData(result.data);
        cfStore.setCFData(result.data);
      }

      this.setLoading(false);
    } catch (e) {
      console.error(e);
      this.setLoading(false);
      this.setError(true);
    }
  }
}
