import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { ConfigContainer } from '../../components/ConfigContainer';
import { InfoContainer } from '../../components/InfoContainer';
import { PublicContainer } from '../../components/PublicContainer';
import { RegistrationContainer } from '../../components/RegistrationContainer';
import { useRootRedirections } from './useRootRedirections';

export const Root = observer(() => {
  useRootRedirections();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  // don't use any container for the login/signup because will redirect to auth0 anyway
  if (location.pathname.includes('login') || location.pathname.includes('signup') || location.pathname === '/')
    return <Outlet />;

  if (location.pathname.includes('trip-details'))
    return (
      <PublicContainer>
        <Outlet />
      </PublicContainer>
    );

  if (['continue', 'done'].some(s => location.pathname.includes(s)))
    return (
      <RegistrationContainer>
        <Outlet />
      </RegistrationContainer>
    );

  if (['my-trips', 'past-trips'].some(s => location.pathname.includes(s)))
    return (
      <InfoContainer>
        <Outlet />
      </InfoContainer>
    );

  return (
    <ConfigContainer>
      <Outlet />
    </ConfigContainer>
  );
});
