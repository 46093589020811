import { useThrottle } from 'hooks/useThrottle';
import { useEffect, useRef, useState } from 'react';

import { FeatureItemProps } from '@molecules/FeatureItem/v1/types';

const useMobileCarousel = (items: FeatureItemProps[][], itemsPerRow: number, variant: string) => {
  const [currentTranslateValue, setCurrentTranslateValue] = useState(0);
  const [activeSlide, setActiveSlide] = useState(1);

  const carouselRef: any = useRef<HTMLDivElement>(null);
  const innerRef: any = useRef<HTMLDivElement>(null);
  const leftCarrotRef: any = useRef<HTMLDivElement>(null);
  const rightCarrotRef: any = useRef<HTMLDivElement>(null);

  const directions = {
    left: 'left',
    right: 'right',
  };

  const getElementSizeAndSpaces = element => element?.offsetWidth || 0;

  const changeSlide = (newSlideIndex: number, movingValue: number) => {
    if (newSlideIndex > items.length || newSlideIndex < 1) return;

    innerRef.current.style.transform = `translate3d(${movingValue}px, 0, 0)`;
    setCurrentTranslateValue(movingValue);
  };

  const handleCarrotClick = direction => () => {
    if (variant === 'stack') return;

    const element: any = innerRef?.current?.childNodes[activeSlide - 1];
    const elementWidth = getElementSizeAndSpaces(element);

    if (direction === directions.right && activeSlide < items.length) {
      const newIndex = activeSlide + 1;
      const rightMoveValue = currentTranslateValue - elementWidth;
      changeSlide(newIndex, rightMoveValue);
      setActiveSlide(newIndex);
    } else if (direction === directions.left && activeSlide > 1) {
      const newIndex = activeSlide - 1;
      const elementWidth = getElementSizeAndSpaces(element);
      const leftMoveValue = currentTranslateValue + elementWidth;
      changeSlide(newIndex, leftMoveValue);
      setActiveSlide(newIndex);
    }
  };

  function handleCarrotKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.target.click(); // Simulate a click on the focused div
    }
  }

  const getRefFromHandlers = (el: HTMLDivElement) => {
    // set myRef el so you can access it yourself
    innerRef.current = el;
  };

  const isElementInView = () => {
    if (carouselRef.current) {
      const rect = carouselRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      // Check if the entire element is fully in view
      return rect.top >= 0 && rect.bottom <= windowHeight;
    }

    return false;
  };

  const handleResizeSlides = () => {
    const element: any = innerRef?.current?.childNodes[activeSlide - 1];
    const elementWidth = getElementSizeAndSpaces(element);

    const movingValue = elementWidth * (activeSlide - 1);

    innerRef.current.style.transform = `translate3d(${-movingValue}px, 0, 0)`;
    setCurrentTranslateValue(-movingValue);
  };

  const handleThrottledResize = useThrottle(handleResizeSlides, 50, [items, itemsPerRow, activeSlide]);

  useEffect(() => {
    if (variant === 'stack') return;

    if (innerRef?.current?.style?.transform) {
      innerRef?.current?.style?.removeProperty('transform');
      setCurrentTranslateValue(0);
      setActiveSlide(1);
    }
  }, [variant]);

  useEffect(() => {
    if (variant === 'stack') return undefined;

    const handleKeyDown = event => {
      if (!isElementInView()) return;

      if (event.key === 'ArrowLeft') leftCarrotRef?.current?.click();
      else if (event.key === 'ArrowRight') rightCarrotRef?.current?.click();
    };

    // Attach the event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [variant]);

  //Window RESIZE listener
  useEffect(() => {
    window.addEventListener('resize', handleThrottledResize);

    return () => {
      window.removeEventListener('resize', handleThrottledResize);
    };
  }, [items, itemsPerRow, activeSlide, handleThrottledResize]);

  return {
    carouselRef,
    innerRef,
    handleCarrotClick,
    handleCarrotKeyPress,
    getRefFromHandlers,
    directions,
    activeSlide,
    leftCarrotRef,
    rightCarrotRef,
  };
};

export default useMobileCarousel;
