import React, { useEffect, useId, useMemo } from 'react';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';

import './Input.scss';
import { InputProps } from './types';

const bem = cn('input');

export const Input = (props: InputProps) => {
  const generatedId = useId();

  const { name, className = '', fieldsetClassName = '', placeholder, id = `input-${generatedId}`, ariaHidden } = props;
  const { errorMessage, externalIcon, afterErrorFocus, autoFocus } = props;
  const { type, required, leadingIcon, label, helperText } = props;
  const { disabled, showAsterisk, autocomplete, inputFieldPlace } = props;
  const { displayTrailingIcon, onChange, onKeyDown, onBlur } = props;
  const { maxCharacters, forwardedRef, showMaxCharacters = true, onClickTrailingIcon, mode = 'text' } = props;
  const { onFocus, value = '', trailingIcon = 'close', errorVariant, ariaLabel } = props;
  const { error, maxCharactersDisabled, labelCN = 'blte-font--variant-body-medium-500' } = props;

  useEffect(() => {
    if (afterErrorFocus && error) document?.getElementById(id)?.focus();
  }, [error, afterErrorFocus, id]);

  const shouldDisplayTrailingIcon = useMemo(
    () => displayTrailingIcon && value?.length > 0,
    [displayTrailingIcon, value]
  );

  const inputCount = useMemo(() => {
    if (showMaxCharacters && maxCharacters) {
      const maxCount = value?.length < maxCharacters ? value?.length : maxCharacters;

      return maxCharacters ? maxCount : value?.length;
    }

    return null;
  }, [maxCharacters, value, showMaxCharacters]);

  return (
    <div
      className={cx(
        bem('fieldset', {
          'phone-number': label === 'Phone Number',
          parking: inputFieldPlace === 'parkingDetails',
          'promo-code': name === 'promo-code',
        }),
        {
          [fieldsetClassName]: !!fieldsetClassName,
        }
      )}
    >
      <div className={bem()}>
        <div
          className={cx(
            bem({
              disabled,
              required,
              'has-value': Boolean(value),
              'phone-number': label === 'Phone Number',
              'has-error': !!error,
            }),
            {
              [className]: !!className,
            }
          )}
        >
          {label && (
            <label
              id={`${id}-label`}
              className={cx(
                bem('label', { hasLeadingIcon: !!leadingIcon, 'promo-code': name === 'promo-code' }),
                labelCN
              )}
              htmlFor={id}
            >
              {label} {(required || showAsterisk) && <span className={bem('label-required')}>*</span>}
            </label>
          )}
          {name === 'promo-code' && (
            <div className={bem('coupon')}>
              <Icon name="coupon" />
            </div>
          )}
          <input
            ref={forwardedRef}
            required={required}
            autoComplete={autocomplete}
            autoFocus={autoFocus}
            aria-errormessage={`error-${id}`}
            aria-labelledby={`${id}-label`}
            aria-invalid={Boolean(error)}
            id={id}
            value={value}
            onFocus={onFocus}
            name={name}
            type={type}
            disabled={disabled}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            placeholder={placeholder}
            maxLength={maxCharacters}
            inputMode={mode}
            {...(!maxCharactersDisabled && maxCharacters && value?.length
              ? { 'aria-describedby': `character-counter-${generatedId}` }
              : {})}
            className={cx(
              bem('input', {
                hasLeadingIcon: !!leadingIcon,
                hasTrailingIcon: shouldDisplayTrailingIcon,
                hasExternalLeadingIcon: !!externalIcon,
                'promo-code': name === 'promo-code',
                'travel-agent-error': error && name === 'editDetailsTravelAgent',
              }),
              'blte-font--variant-body-medium-500'
            )}
          />
          {(leadingIcon || externalIcon) && (
            <span className={bem('leadingIcon', { external: !!externalIcon })} onClick={onFocus}>
              {externalIcon ? <Icon {...externalIcon} /> : <Icon name={leadingIcon} />}
            </span>
          )}
          {shouldDisplayTrailingIcon && (
            <button
              className={bem('trailingIcon', { error: !!error })}
              onClick={onClickTrailingIcon}
              aria-label={ariaLabel}
            >
              <Icon name={trailingIcon} ariaHidden={ariaHidden} />
            </button>
          )}
        </div>
        {(error || helperText || maxCharacters) && (
          <div
            className={cx(
              bem('helper-container', {
                'error-inline': errorVariant === 'inline',
                'applied-promo-code': name === 'promo-code',
              }),
              'blte-font--variant-tiny-500'
            )}
          >
            {!error && typeof helperText === 'string' && (
              <span className={cx(bem('helper-text', { 'promo-code': name === 'promo-code' }))} role="alert">
                {helperText}
              </span>
            )}
            {!error && typeof helperText === 'object' && (
              <Text {...helperText} className={bem('helper-text')} role="alert" />
            )}

            {error && errorMessage && (
              <span id={`error-${id}`} className={cx(bem('error'), 'blte-font--variant-tiny-500')}>
                {errorMessage}
              </span>
            )}

            {!maxCharactersDisabled && maxCharacters && value?.length ? (
              <span
                id={`character-counter-${generatedId}`}
                className={bem('characterCount')}
                aria-live="polite"
                aria-atomic="true"
              >
                {showMaxCharacters ? `${inputCount}/${maxCharacters}` : ''}
              </span>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
