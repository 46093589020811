import React from 'react';

import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';

import './SafetyPledgeFormSuccessMessage.scss';
import { SafetyPledgeSuccessMessageProps } from './types';

const bem = cn('safety-pledge-form-success-message');

const SafetyPledgeFormSuccessMessage = (props: SafetyPledgeSuccessMessageProps) => {
  const { completedPledgeSubtitle, congratulationsLabel } = props;

  return (
    <div className={bem()}>
      <div className={bem('form-submission-message')}>
        <div className={cx(bem('title'), bem('text-wrapper'))}>
          <Text text={congratulationsLabel} className={bem('title')} />
        </div>
        <div className={cx(bem('subtitle'), bem('text-wrapper'))}>
          <Text className={bem('subtitle')} text={completedPledgeSubtitle} />
        </div>
      </div>
    </div>
  );
};

export default SafetyPledgeFormSuccessMessage;
