import { observer } from 'mobx-react-lite';
import React from 'react';

import { DesktopDateRangePickerProps } from '../types';
import { DesktopCalendar } from './DesktopCalendar';

export const DesktopDateRangePicker = observer((props: DesktopDateRangePickerProps) => {
  const { toggleItems, labels } = props;
  const {
    focusedInput,
    calendarStartDate,
    setCalendarStartDate,
    calendarEndDate,
    setCalendarEndDate,
    activeButtonFilter,
    setActiveButtonFilter,
    datePopoverOpen,
    setDatePopoverOpen,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
  } = props;
  const { handleFocusChange, handleDatesChange, isOutsideRange } = props;
  const { handleClearDates, isSameDay, datesRestrictions } = props;

  const { closeButtonLabel, clearButtonLabel } = labels;

  return (
    <DesktopCalendar
      datesRestrictions={datesRestrictions}
      closeButtonLabel={closeButtonLabel}
      clearButtonLabel={clearButtonLabel}
      onFocusChange={handleFocusChange}
      focusedInput={focusedInput}
      handleCloseButton={setDatePopoverOpen}
      onDatesChange={handleDatesChange}
      isOutsideRange={isOutsideRange}
      handleClearDates={handleClearDates}
      toggleItems={toggleItems}
      isSameDay={isSameDay}
      isOpen={datePopoverOpen}
      calendarStartDate={calendarStartDate}
      setCalendarStartDate={setCalendarStartDate}
      calendarEndDate={calendarEndDate}
      setCalendarEndDate={setCalendarEndDate}
      activeButtonFilter={activeButtonFilter}
      setActiveButtonFilter={setActiveButtonFilter}
      selectedStartDate={selectedStartDate}
      setSelectedStartDate={setSelectedStartDate}
      selectedEndDate={selectedEndDate}
      setSelectedEndDate={setSelectedEndDate}
    />
  );
});
