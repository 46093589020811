import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import { Drawer } from '@molecules/Drawer';

import { cn } from 'utils/classNames';

import './FareSelectionDrawer.scss';
import { FareCompareItem } from './components/FareCompareItem/FareCompareItem';
import FareCompareTableDrawer from './components/FareCompareTable/FareCompareTableDrawer';
import { FareItem } from './components/FareItem';
import { FareSelectionDrawerProps } from './types';
import { useFareSelectionDrawerLogic } from './useFareSelectionDrawerLogic';

const bem = cn('fare-selection-drawer');

export const FareSelectionDrawer = observer((props: FareSelectionDrawerProps) => {
  const { travelLabels } = props;
  const { cfStore, bookingStore } = useStores();
  const { trainSelection } = cfStore;
  const { faresCompare, brandedFaresSelection } = trainSelection;
  const { drawerTitle, options } = faresCompare;

  const { selectedItem, premiumOnly } = bookingStore.tickets;

  const { journey } = selectedItem || {};

  const drawerLogic = useFareSelectionDrawerLogic();

  const { firstButtonProps, secondButtonProps, fareCompareItems, handleConfirm } = drawerLogic;
  const { handleClose, isVisible, goBackButton } = drawerLogic;
  const { handleOpenFareTableDrawer, fareTableDrawerVisible, handleCloseFareTableDrawer, isBFEnabled } = drawerLogic;
  const { getFareCompareItemAvailability, smartSaverSelected } = drawerLogic;

  return (
    <Drawer
      className={bem('drawer')}
      buttonProps={firstButtonProps}
      secondButtonProps={secondButtonProps}
      onClose={handleClose}
      isVisible={isVisible}
      isBackgroundGrey={isBFEnabled}
    >
      <div className={bem()}>
        <div className={bem('header')}>
          {goBackButton && <Button {...goBackButton} className={bem('go-back-button')} />}
          <div className={bem('header-text')}>
            <h3 className={bem('title')}>{isBFEnabled ? fareCompareItems?.[0]?.selectFareLabel : drawerTitle}</h3>
            <div className={bem('trip')}>
              <span className={bem('origin')}>{travelLabels.from}</span>
              <Icon name="arrow-from-to" />
              <span className={bem('destination')}>{travelLabels.to}</span>
            </div>
          </div>
        </div>
        {isBFEnabled && brandedFaresSelection.length && (
          <div className={bem('wrapper')}>
            {fareCompareItems.map((item, index) => (
              <FareCompareItem
                key={`${item.fareTypeLabel}-${index}`}
                {...item}
                isItemAvailable={getFareCompareItemAvailability(item)}
                handleConfirm={handleConfirm}
                smartSaverSelected={smartSaverSelected}
              />
            ))}
          </div>
        )}
        {!isBFEnabled && options.length && (
          <div className={bem('wrapper')}>
            {options
              .filter(option => (premiumOnly ? option.variant === 'PREMIUM_STANDARD' : option))
              .map((item, index) => (
                <FareItem
                  key={`${item.variant}-${index}`}
                  {...item}
                  isItemAvailable={getFareCompareItemAvailability(item)}
                />
              ))}
          </div>
        )}
      </div>
      {isBFEnabled && (
        <div className={bem('fareCompare')}>
          <button onClick={handleOpenFareTableDrawer}>{brandedFaresSelection?.[0]?.fareCompareLabel}</button>
        </div>
      )}
      <FareCompareTableDrawer
        isVisible={fareTableDrawerVisible}
        onClose={handleCloseFareTableDrawer}
        travelLabels={travelLabels}
        fareCompareLabel={brandedFaresSelection?.[0]?.fareCompareLabel}
        journey={journey}
      />
    </Drawer>
  );
});
