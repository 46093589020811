import * as Yup from 'yup';

const testLettersAndNumbersOnly = value => /[a-zA-Z0-9]{6}/.test(value || '');

export const getTicketsFormValidationSchema = (args, oneWay = false) => {
  const { selectOriginError, selectDestinationError } = args;
  const { startDateRequiredError, endDateRequiredError, stations } = args;

  return [
    Yup.object().shape({
      from: Yup.string()
        .required(selectOriginError)
        .test('from', selectOriginError, value => stations?.filter(station => station.id === value).length > 0),
      to: Yup.string()
        .required(selectDestinationError)
        .test('to', selectOriginError, value => stations?.filter(station => station.id === value).length > 0),
    }),
    oneWay
      ? Yup.object().shape({
          startDate: Yup.date().required(startDateRequiredError).nullable(),
        })
      : Yup.object().shape({
          startDate: Yup.date().required(startDateRequiredError).nullable(),
          endDate: Yup.date().required(endDateRequiredError).nullable(),
        }),
    Yup.object().shape({
      adults: Yup.number().min(1),
    }),
  ];
};

export const getManageTripsFormValidationSchema = (args, deprecatedManageTripsPage) => {
  const { errorRequiredField, errorInvalidField } = args;

  const commonValidations = {
    referenceNumber: Yup.string()
      .required(errorRequiredField)
      .length(6, errorInvalidField)
      .test('referenceNumber', errorInvalidField, testLettersAndNumbersOnly),
    lastName: Yup.string().required(errorRequiredField),
  };

  if (deprecatedManageTripsPage) return Yup.object().shape(commonValidations);

  return Yup.object().shape({
    ...commonValidations,
    firstName: Yup.string().required(errorRequiredField),
  });
};
