import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './ConfirmSessionEndingModal.scss';

const bem = cn('confirm-session-ending-modal');

export const ConfirmSessionEndingModal = observer(() => {
  const { kioskStore } = useStores();
  const { confirmEndSessionModal, hideSessionClearanceModal, confirmSessionClearance } = kioskStore;
  const { title, description, confirmLabel, cancelLabel, isVisible } = confirmEndSessionModal;

  if (!isVisible) return null;

  return (
    <Modal variant="mini">
      <div className={bem()}>
        <div className={bem('title')}>{title}</div>
        <div className={bem('description')}>{description}</div>

        <div className={bem('buttons')}>
          <Button
            colors={{ backgroundOrBorder: color_base_medium_light_grey }}
            label={cancelLabel}
            onClick={hideSessionClearanceModal}
          />
          <Button color="yellow" label={confirmLabel} onClick={confirmSessionClearance} />
        </div>
      </div>
    </Modal>
  );
});
