import React, { useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './FeaturesGridV2.scss';
import DesktopItems from './components/DesktopItems';
import MobileItems from './components/MobileItems';
import { FeaturesGridProps } from './types';

const bem = cn('features-grid-v2');

export const FeaturesGridV2 = (props: FeaturesGridProps) => {
  const { variant = 'stack', eyebrow, id, featureRowItems = []} = props;
  const { title, button, colors, titleAndEyebrowAlignment, description } = props;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const style: any = useMemo(
    () => ({
      '--features-grid-v2-background-color': colors?.background,
      '--features-grid-v2-eyebrow-color': colors?.eyebrow,
      '--features-grid-v2-title-color': colors?.title,
      '--features-grid-v2-description-color': colors?.description,
    }),
    [colors]
  );

  const isStack = variant === 'stack';

  const itemsLength = useMemo(() => featureRowItems?.length == 1 ? 2 : featureRowItems?.length, [featureRowItems]);

  const items = useMemo(() => featureRowItems.map(featureRowItem => featureRowItem.featureItems).flat(), [featureRowItems]);

  return (
    <div id={id} className={bem()} style={style}>
      <div className={bem('content', { variant, alignment: titleAndEyebrowAlignment, length: itemsLength })}>
        <div className={bem('top')} role="group" aria-label="item">
          <div className={bem('top-content', { length: itemsLength })}>
            {!!eyebrow && (
              <div className={bem('top-eyebrow', { noTitleAndDesc: !title && !description })}>
                <Text as="h6" text={eyebrow} />
              </div>
            )}
            {!!title && (
              <div className={bem('top-title', { noDesc: !description })}>
                <Text as="h2" text={title} />
              </div>
            )}
            {!!description && (
              <div className={bem('top-description')}>
                <Text text={description} />
              </div>
            )}
          </div>
        </div>

        {isMobile ? (
          <MobileItems items={items} variant={variant} colors={colors} />
        ) : (
          <DesktopItems rowList={featureRowItems} variant={variant} />
        )}
        <div className={bem('bottom', { isStack })}>{button && <Button {...button} />}</div>
      </div>
    </div>
  );
};
