import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Divider from '@atoms/Divider/Divider';
import { PromoCodeV2 } from '@molecules/PromoCode';
import { RewardsPoints } from '@molecules/RewardsPoints/RewardsPoints';

import { cn } from 'utils/classNames';
import { requestUrls } from 'utils/constants';
import { isBrandedFaresV2Enabled, isLoyaltyEnabled } from 'utils/metas';

import { CostSummarySections } from './CostSummarySections';
import { CostSummaryTotal } from './CostSummaryTotal';
import { ModifyCostSummaryContent } from './ModifyCostSummaryContent';

const bem = cn('cost-summary-content');

export const CostSummaryContent = observer(() => {
  const { authStore, bookingStore, cfStore } = useStores();
  const { costSummary } = cfStore.rti;
  const { isAuthenticated } = authStore;

  const { provisionalBooking, getTaxesTotal, setProvisionalBooking, modifyTripType } = bookingStore.rti;
  const isBrandedFaresV2 = isBrandedFaresV2Enabled();

  const taxesTotal: any = useMemo(getTaxesTotal, [
    provisionalBooking?.tripDetails?.costSummary?.bookingTotal,
    getTaxesTotal,
  ]);

  const totalToBePaid = bookingStore.rti.getTotal();

  return (
    <>
      <div className={bem()}>
        <CostSummarySections />
        <div className={bem('taxes')}>
          <div>{costSummary.taxesAndFeesLabel}</div>
          <div>${Math.abs(taxesTotal).toFixed(2)}</div>
        </div>
        <Divider />
        {isBrandedFaresV2 && modifyTripType !== null ? <ModifyCostSummaryContent /> : <CostSummaryTotal />}
        {isAuthenticated && isLoyaltyEnabled() && <Divider />}
        <RewardsPoints {...costSummary} totalToBePaid={totalToBePaid} isModification={modifyTripType !== null} />
        <div className={bem('promo-content')}>
          <PromoCodeV2
            requestOptions={{
              headers: {
                'Booking-Session-Access-Token': provisionalBooking?.bookingSession?.accessToken,
                'Booking-Session-Refresh-Token': provisionalBooking?.bookingSession?.refreshToken,
              },
            }}
            promoButton={{ label: costSummary.promoButtonLabel }}
            floatingLabel={costSummary.promoCodeLabelInputFloatingLabel}
            errorLabel={costSummary.invalidPromoCodeLabel}
            applyButton={{ variant: 'primary', label: costSummary.promoApplyLabel, color: 'blue' }}
            endpoint={`${requestUrls.getRestUrl(requestUrls.rti.bookingPromoCodes)}.${
              provisionalBooking?.tripDetails?.trip?.referenceNumber
            }.xjson`}
            onSuccess={setProvisionalBooking}
            required
          />
        </div>
      </div>
    </>
  );
});
