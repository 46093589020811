import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './ErrorBanner.scss';

const bem = cn('account-edit-parking-pass-error-banner');

export const ErrorBanner = observer(() => {
  const { cfStore } = useStores();
  const errorLabel = cfStore.account.wallet.editParkingPass.serverErrorLabel;

  return (
    <div className={bem()}>
      <div className={bem('banner')}>
        <Icon name="warning" className={bem('icon')} />
        <Text text={errorLabel} className={bem('text')} />
      </div>
    </div>
  );
});
