import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Toggle } from '@atoms/Toggle';

import { cn } from 'utils/classNames';

import { DateInputs } from '../DateInputs';
import { MobileDesktopDateRangePickerProps } from '../types';
import { MobileCalendar } from './MobileCalendar';

const bem = cn('date-range-picker');

export const MobileDateRangePicker = observer((props: MobileDesktopDateRangePickerProps) => {
  const { focusedInput, toggleItems, isOutsideRange, containerRef } = props;
  const { handleFocusChange, handleDatesChange } = props;
  const { ticketsFormStore } = useStores();
  const { changeTripValue, trip } = ticketsFormStore;

  return (
    <div className={bem('mobile')}>
      <div className={bem('mobile-header')}>
        <Toggle name="trip" defaultChecked={trip} onChange={changeTripValue} items={toggleItems} />
      </div>

      <DateInputs {...props} isOneWay={trip === 0} />
      <MobileCalendar
        {...props}
        containerRef={containerRef}
        handleFocusChange={handleFocusChange}
        focusedInput={focusedInput}
        handleDatesChange={handleDatesChange}
        isOutsideRange={isOutsideRange}
      />
    </div>
  );
});
