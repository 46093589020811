import { isEmpty } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import moment from 'moment';
import { RootStore } from 'stores';
import { defaultPassengersInfoData } from 'stores/types/types.passengers';

import { passengerTypes } from '@booking/routes/RTI/PassengersInfo/constants';
import { stepNames } from '@booking/routes/RTI/constants';

import { tripManagementAnalyticsEvent } from 'utils/adobeDataLayer';
import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { isKiosk } from 'utils/isKiosk';

import { clearPublicBookingToken, getPublicBookingToken, setPublicBookingToken } from '../../../../utils/localstorage';
import { isDelayNotificationsEnabled, isLoyaltyEnabled, isSmsDelayNotificationsEnabled } from '../../../../utils/metas';
import {
  GiftCardConfig,
  GiftCardErrorProps,
  LoyaltyPaymentConfig,
  ModifyTripConfig,
  RewardsApplicationErrorProps,
  SelectedTicket,
} from '../Tickets/types';
import { defaultActiveStep, defaultCompletedSteps, defaultError, defaultLockedSteps } from './constants';
import { Notifications } from './sections/Notifications/Notifications';
import SeatsStore from './sections/Seats/SeatsStore';
import {
  ApplicableCreditPass,
  ApplicableTravelPass,
  CreateProvisionalPayload,
  defaultConfirmedBooking,
  defaultProvisionalBooking,
  ModifyTripRequestConfig,
  ProvisionalBookingProps,
} from './types.provisionalBooking';
import { SavedCard } from './types.reviewAndPay';

export class RTI {
  seats: SeatsStore;
  notifications: Notifications;
  loading = false;
  paymentInProgress = false;
  errorState = defaultError;
  lockedSteps = defaultLockedSteps;
  completedSteps = defaultCompletedSteps;
  activeStep = defaultActiveStep;
  provisionalBooking = defaultProvisionalBooking;
  confirmedBooking = defaultConfirmedBooking;
  passengersInfoData = defaultPassengersInfoData;
  selectedPaymentMethod: SavedCard = {};
  applicablePasses: ApplicableTravelPass[] | ApplicableCreditPass[] = [];
  travelPassCodes: string[] = [];
  creditPassCode: string[] = [];
  smsNotifications = isDelayNotificationsEnabled() && isSmsDelayNotificationsEnabled();
  confirmNewsletter = true;
  changesHaveBeenSaved = false;
  giftCards: GiftCardConfig[] = [];
  giftCardError: GiftCardErrorProps = {};
  tpNotApplicableDueToGiftCardPayment = false;
  appliedRewardsPointsError: RewardsApplicationErrorProps = {};
  loyaltyPayment: LoyaltyPaymentConfig;
  loyaltyRewardsPoints = 0;
  appliedRewardsPoints = 0;
  displayLoyaltyComponents = false;

  modifyTripType: null | 'extras' | 'seats' | 'trip' = null;

  isOutboundSeatingAvailable = true;
  isInboundSeatingAvailable = true;

  isUpsell = false;

  private readonly rootStore: RootStore;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'bookingStore',
      properties: isKiosk() ? [] : ['passengersInfoData'],
      storage: window.localStorage,
    });
    this.rootStore = rootStore;

    this.seats = new SeatsStore(rootStore);
    this.notifications = new Notifications();
  }

  clearError() {
    this.setError(defaultError);
  }

  setError(error) {
    this.errorState = error;
  }

  setSmsNotifications(smsNotifications) {
    this.smsNotifications = smsNotifications;
  }

  setConfirmNewsletter(confirmNewsletter) {
    this.confirmNewsletter = confirmNewsletter;
  }

  setProvisionalBooking(booking) {
    this.provisionalBooking = booking;

    if (booking?.bookingSession?.tokenType?.toLowerCase() === 'public') setPublicBookingToken(booking.bookingSession);
  }

  setConfirmedBooking(booking) {
    this.confirmedBooking = booking;
  }

  setCompletedStep(step, val) {
    this.completedSteps[step] = val;
  }

  setCompletedSteps(vals) {
    this.completedSteps = vals;
  }

  setLockedStep(step, val) {
    this.lockedSteps[step] = val;
  }

  setLockedSteps(vals) {
    this.lockedSteps = vals;
  }

  setActiveStep(step) {
    this.activeStep = step;
  }

  setBookedPassengers(bookedPassengers) {
    this.passengersInfoData.bookedPassengers = bookedPassengers;
  }

  setSelectedPaymentMethod(selectedPaymentMethod) {
    this.selectedPaymentMethod = selectedPaymentMethod;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setPaymentInProgress(paymentInProgress) {
    this.paymentInProgress = paymentInProgress;
  }

  setChangesHaveBeenSaved(saved) {
    this.changesHaveBeenSaved = saved;
  }

  setModifyTripType(modifyTripType: null | 'extras' | 'trip' | 'seats') {
    this.modifyTripType = modifyTripType;
  }

  setOutboundSeatingAvailability(seatingAvailable) {
    this.isOutboundSeatingAvailable = seatingAvailable;
  }

  setInboundSeatingAvailability(seatingAvailable) {
    this.isInboundSeatingAvailable = seatingAvailable;
  }

  setIsUpsell(isUpsell: boolean) {
    this.isUpsell = isUpsell;
  }

  setGiftCards(giftCard) {
    this.giftCards = giftCard;
  }

  addGiftCard(giftCard) {
    this.giftCards.push(giftCard);
  }

  setGiftCardError(error) {
    this.giftCardError = error;
  }

  setAppliedRewardsPoints(appliedRewardsPoint) {
    this.appliedRewardsPoints = appliedRewardsPoint;
  }

  setAppliedRewardsPointsError(appliedRewardsPointsError) {
    this.appliedRewardsPointsError = appliedRewardsPointsError;
  }

  setLoyaltyPayment(loyaltyPayment) {
    this.loyaltyPayment = loyaltyPayment;
  }

  resetGiftCards() {
    this.giftCards = [];
    this.giftCardError = {};
  }

  removeGiftCard(cardNumber) {
    this.giftCards = this.giftCards.filter((card: GiftCardConfig) => card?.giftCard?.cardNumber !== cardNumber);
  }

  setTpNotApplicableDueToGiftCardPayment(tpNotApplicableDueToGiftCardPayment: boolean) {
    this.tpNotApplicableDueToGiftCardPayment = tpNotApplicableDueToGiftCardPayment;
  }

  setDisplayLoyaltyComponents(displayLoyaltyComponents) {
    this.displayLoyaltyComponents = displayLoyaltyComponents;
  }

  resetRtiData = () => {
    this.loading = false;
    this.clearError();
    this.setLockedSteps(defaultLockedSteps);
    this.setCompletedSteps(defaultCompletedSteps);
    this.setActiveStep(defaultActiveStep);
    this.setProvisionalBooking(null);
    this.setBookedPassengers(defaultPassengersInfoData.bookedPassengers);
    this.setApplicablePasses([]);
    this.setTravelPassCodes([]);
    this.setCreditPassCode('');
    this.setModifyTripType(null);
    this.setOutboundSeatingAvailability(true);
    this.setInboundSeatingAvailability(true);
    this.giftCards = [];
    this.setTpNotApplicableDueToGiftCardPayment(false);
    this.rootStore.bookingStore.parking.resetParking();
    this.rootStore.bookingStore.extras.resetExtras();
    this.loyaltyRewardsPoints = 0;
    this.appliedRewardsPoints = 0;
    this.setDisplayLoyaltyComponents(false);
  };

  getTotal = () => {
    if (this.provisionalBooking?.tripDetails?.costSummary?.bookingTotal)
      return Math.abs(this.provisionalBooking.tripDetails.costSummary.bookingTotal.totalToBePaid);

    return 0;
  };

  getTaxesTotal = () =>
    (this.provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid || 0) -
    ((this.provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.total || 0) -
      (this.provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalPaid || 0));

  setApplicablePasses(applicablePasses) {
    this.applicablePasses = applicablePasses;
  }

  createApplicablePasses(bookingResponse, discardTravelPasses?: boolean) {
    const { applicableTravelPasses = [], applicableCreditPasses = [] } = bookingResponse;

    let travelPasses = applicableTravelPasses;
    if (discardTravelPasses) travelPasses = [];

    const appliedTravelPassCodes = travelPasses
      .map(el => (el.currentQuantityApplied > 0 ? el.travelPass.code : null))
      .filter(el => el !== null);

    this.setTravelPassCodes(appliedTravelPassCodes);

    const availableCreditPasses = applicableCreditPasses.map(el => ({
      ...el,
      isApplicableToBooking: el.creditPass.creditAvailable > 0,
      type: 'credit',
    }));

    const allPassesSorted = [...availableCreditPasses, ...travelPasses.map(el => ({ ...el, type: 'travel' }))].sort(
      (pass1, pass2) =>
        pass2.type.localeCompare(pass1.type) && pass2.isApplicableToBooking - pass1.isApplicableToBooking ? -1 : 1
    );

    this.setApplicablePasses(allPassesSorted);
  }

  async createProvisionalBooking(outbound: SelectedTicket, inbound: SelectedTicket, promocode: string) {
    const { accountStore, bookingStore } = this.rootStore;
    const { profile } = accountStore;
    const { setTrainSelectionError } = bookingStore.tickets;
    const { userProfile } = profile;
    const customer = userProfile ? userProfile : undefined;
    const payload: CreateProvisionalPayload = inbound
      ? { outbound, inbound, customer, promocode }
      : { outbound, customer, promocode };

    const publicBookingToken = getPublicBookingToken();

    try {
      this.setLoading(true);
      this.clearError();

      const response = await api.post(
        `${requestUrls.getRestUrl(requestUrls.rti.booking)}.xjson${isKiosk() ? '?platform=kiosk' : ''}`,
        payload,
        {
          headers: !isEmpty(publicBookingToken)
            ? {
                'Booking-Session-Access-Token': publicBookingToken.accessToken,
                'Booking-Session-Refresh-Token': publicBookingToken.refreshToken,
              }
            : {},
        }
      );
      const data = response.data;

      if (data?.bookingSession?.tokenType?.toLowerCase() === 'public') setPublicBookingToken(data.bookingSession);

      this.createApplicablePasses(data);

      this.setProvisionalBooking(data);

      this.checkSeatingAvailability(data);

      this.setLoading(false);

      return data;
    } catch (error: any) {
      console.error(error);
      this.setError({ error });
      clearPublicBookingToken();
      setTrainSelectionError(error);
      this.setLoading(false);
    }

    return null;
  }

  async modifyExistingTrip(modifyTripProps: ModifyTripConfig, outbound?: SelectedTicket, inbound?: SelectedTicket) {
    if (!outbound && !inbound) throw 'At least 1 ticket is required to modify a trip';

    const reqBody: ModifyTripRequestConfig = {
      routes: [],
    };

    [outbound, inbound].forEach((ticket, index) => {
      if (!ticket || !ticket.passengers.length) return;

      reqBody.routes.push({
        direction: index === 0 ? 'outbound' : 'inbound',
        departureDate: ticket.departureDate.toString(),
        serviceId: ticket.serviceId,
        serviceName: ticket.serviceName,
        serviceType: ticket.serviceType,
        startValidityDate: ticket.departureDate.toString(),
        seatProductTariffCode: ticket.passengers[0].tariffCode,
      });
    });

    if (!reqBody.routes.length) throw 'At least 1 ticket is required to modify a trip';

    const rn = modifyTripProps.rn;

    try {
      this.setLoading(true);
      this.clearError();
      const { data } = await api.put(
        `${requestUrls.getRestUrl(requestUrls.account.tripDetails)}.${rn}.xjson`,
        reqBody,
        {
          headers: {
            'Booking-Session-Access-Token': modifyTripProps?.bookingSession?.accessToken,
            'Booking-Session-Refresh-Token': modifyTripProps?.bookingSession?.refreshToken,
          },
        }
      );

      this.setBookedPassengers(
        data.tripDetails.passengerSeatingDetails.map(passenger => ({
          passenger: passenger.passenger,
        }))
      );

      this.createApplicablePasses(data);

      this.setProvisionalBooking(data);

      this.checkSeatingAvailability(data);

      this.setModifyTripType('trip');

      this.setLoading(false);

      return data;
    } catch (error: any) {
      console.error(error);
      this.setError({ error });
      this.setLoading(false);
    }

    return null;
  }

  async getExistingBookingInformation(
    referenceNumber: string,
    firstName: string,
    lastName: string,
    modifyType: string
  ) {
    //No try-catch here to throw exception to avoid RTI blank screen

    this.setLoading(true);
    this.clearError();
    const { data: tripDetails } = await api.get(`${requestUrls.getRestUrl(requestUrls.rti.searchTrip)}.xjson`, {
      params: {
        reference_number: referenceNumber.toUpperCase(),
        first_name: firstName,
        last_name: lastName,
      },
    });

    this.setProvisionalBooking(tripDetails);

    this.checkSeatingAvailability(tripDetails);

    if (modifyType === 'extras') {
      this.setModifyTripType(modifyType);
      this.setCompletedSteps([stepNames.passengerInfo, stepNames.seatAssignment]);
      this.setActiveStep(stepNames.extras);
    }

    if (modifyType === 'seats') {
      this.setModifyTripType(modifyType);
      this.setCompletedStep(stepNames.passengerInfo, false);
      this.setActiveStep(stepNames.seatAssignment);
    }

    this.setLoading(false);
  }

  async confirmBooking(payload, cb, errorCb?) {
    try {
      this.setLoading(true);
      this.setPaymentInProgress(true);

      const { tripDetails } = this.rootStore.accountStore;

      const totalToBePaid = this.provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid || 0;

      const refNr = tripDetails?.trip?.referenceNumber || this.provisionalBooking?.tripDetails?.trip?.referenceNumber;
      const url = `${requestUrls.getRestUrl(
        isKiosk() && totalToBePaid ? requestUrls.rti.kioskConfirmation : requestUrls.rti.bookingConfirmation
      )}.${refNr}.xjson`;

      const giftCardsPayload = this.giftCards.map(card => ({
        cardNumber: card.giftCard.cardNumber,
        pin: card.giftCard.pin,
        amount: card.amountPaid,
      }));

      const reqBody =
        totalToBePaid || isKiosk() || giftCardsPayload.length != 0 ? { ...payload, giftCards: giftCardsPayload } : {};

      const response = await api.post(url, reqBody, {
        headers: {
          'Booking-Session-Access-Token':
            tripDetails?.bookingSession?.accessToken || this.provisionalBooking?.bookingSession?.accessToken,
          'Booking-Session-Refresh-Token':
            tripDetails?.bookingSession?.accessToken || this.provisionalBooking?.bookingSession?.refreshToken,
        },
      });

      if (!!this.modifyTripType && this.isUpsell)
        tripManagementAnalyticsEvent({
          nameChangeSuccess: false,
          seatChange: false,
          tripCancelSuccess: false,
          upsellClick: true,
        });

      this.setLoading(false);
      this.setPaymentInProgress(false);

      this.setChangesHaveBeenSaved(true);

      if (cb) {
        cb(response?.data?.tripDetails);

        return;
      }
    } catch (error) {
      this.setLoading(false);
      this.setPaymentInProgress(false);
      this.setError({ stepNr: 4, error });
      console.error(error);

      if (errorCb) errorCb(error);
    }
  }

  async savePassengerToAccount(body) {
    try {
      this.setLoading(true);
      this.clearError();
      const url = `${requestUrls.getRestUrl(requestUrls.passengers)}${body.id ? `.${body.id}` : ''}.xjson`;

      if (body.id) await api.put(url, body);
      else await api.post(url, body);

      this.setLoading(false);
    } catch (error: any) {
      console.error(error);
      this.setError({ stepNr: 1, error });
      this.setLoading(false);
    }
  }

  fillSeatsInformation() {
    const bookingPassengers =
      this.provisionalBooking.tripDetails?.passengerSeatingDetails?.filter(
        item => item.passenger.type !== passengerTypes.infant
      ) || [];

    const activeOutboundCoach = bookingPassengers[0].outboundSeat?.coach?.coachNumber;
    const activeInboundCoach = bookingPassengers[0].inboundSeat?.coach?.coachNumber;

    this.seats.setActiveCoach({ activeOutboundCoach, activeInboundCoach });
    this.seats.setActivePassenger(bookingPassengers[0]);
    this.seats.setPassengersSeats(bookingPassengers);
  }

  async saveBookingPassengers(body, callbk, errorCb?, modifyTripType?) {
    const { accountStore } = this.rootStore;
    const { profile, passengersStore, tripDetails } = accountStore;
    const { setTripPassengers } = tripDetails;
    const { userProfile } = profile;
    try {
      this.setLoading(true);
      this.clearError();
      await body.passengers.forEach((p, index) => {
        if (p.passenger.dateOfBirth) p.passenger.dateOfBirth = moment.utc(p.passenger.dateOfBirth).format();
        else delete p.passenger.dateOfBirth;

        if (!p.passenger.phoneNumber?.number) delete p.passenger.phoneNumber;
        if (!p.passenger.email) delete p.passenger.email;

        this.setBookedPassengers(body.passengers);
        setTripPassengers(body.passengers);
        if (p.savePassenger && (userProfile || tripDetails?.trip)) {
          this.savePassengerToAccount(p.passenger);
          p.editMode = false;
        }
        const passengerSeatingDetails = this.provisionalBooking?.tripDetails?.passengerSeatingDetails;
        if (passengerSeatingDetails?.length) p.passengerId = passengerSeatingDetails[index].passengerId;
      });

      const refNr =
        modifyTripType && tripDetails?.trip?.referenceNumber
          ? tripDetails?.trip?.referenceNumber
          : this.provisionalBooking?.tripDetails?.trip?.referenceNumber;
      const url = `${requestUrls.getRestUrl(requestUrls.rti.bookingPassengers)}.${refNr}.xjson`;
      const response = await api.put(url, body, {
        headers: {
          'Booking-Session-Access-Token':
            modifyTripType && tripDetails?.bookingSession?.accessToken
              ? tripDetails?.bookingSession?.accessToken
              : this.provisionalBooking?.bookingSession?.accessToken,
          'Booking-Session-Refresh-Token':
            modifyTripType && tripDetails?.bookingSession?.refreshToken
              ? tripDetails?.bookingSession?.refreshToken
              : this.provisionalBooking?.bookingSession?.refreshToken,
          Authorization: 'Bearer Anonymous',
        },
      });

      if (userProfile) await passengersStore.fetchSavedPassengers();

      tripDetails?.trip
        ? tripDetails.setTripDetails(response.data.tripDetails)
        : this.setProvisionalBooking(response.data);

      if (tripDetails?.trip)
        tripManagementAnalyticsEvent({
          nameChangeSuccess: true,
          seatChange: false,
          tripCancelSuccess: false,
          upsellClick: false,
        });

      this.setLoading(false);
      callbk && callbk();
    } catch (error: any) {
      this.setError({ stepNr: 1, error });
      this.setLoading(false);
      console.error(error);

      errorCb && errorCb(error);
    }
  }

  async searchTrip(rn?: string, fn?: string | null, ln?: string | null) {
    const params = {
      reference_number: rn,
      first_name: fn,
      last_name: ln,
    };
    try {
      this.setLoading(true);
      this.clearError();
      const response = await api.get(`${requestUrls.getRestUrl(requestUrls.rti.searchTrip)}.xjson`, { params });

      this.setConfirmedBooking(response.data);
      this.setLoading(false);
    } catch (error: any) {
      this.setLoading(false);
      this.setError({ error });
    }
  }

  async getBookingPaymentStatus(): Promise<string> {
    try {
      this.setLoading(true);
      this.clearError();
      const refNr = this.provisionalBooking?.tripDetails?.trip?.referenceNumber;
      const response = await api.get(`${requestUrls.getRestUrl(requestUrls.rti.booking)}.${refNr}.xjson`, {
        headers: {
          'Booking-Session-Access-Token': this.provisionalBooking?.bookingSession?.accessToken,
          'Booking-Session-Refresh-Token': this.provisionalBooking?.bookingSession?.refreshToken,
          Authorization: 'Bearer Anonymous',
        },
      });

      this.setLoading(false);

      return response.data?.paymentStatus?.status || 'failed'; // Added default 'failed' string return if 'status' is undefined, to prevent infinite loop
    } catch (error: any) {
      this.setLoading(false);
      this.setError({ error });

      return 'failed';
    }
  }

  setNewCreditPassCode(newCode) {
    this.creditPassCode = newCode;
  }

  setCreditPassCode(creditPassCode: string) {
    this.creditPassCode.push(creditPassCode);
  }

  setTravelPassCodes(codes) {
    this.travelPassCodes = codes;
  }

  async applyTravelPass(passCode: string) {
    try {
      this.setLoading(true);
      this.clearError();
      const travelPassCodes = [...this.travelPassCodes, passCode];

      const refNr = this.provisionalBooking?.tripDetails?.trip?.referenceNumber;
      const url = `${requestUrls.getRestUrl(requestUrls.rti.travelPasses)}.${refNr}.xjson`;

      const response = await api.post(
        url,
        { travelPassCodes },
        {
          headers: {
            'Booking-Session-Access-Token': this.provisionalBooking?.bookingSession?.accessToken,
            'Booking-Session-Refresh-Token': this.provisionalBooking?.bookingSession?.refreshToken,
          },
        }
      );
      this.setLoading(false);
      this.setTravelPassCodes(travelPassCodes);
      this.setProvisionalBooking(response.data);
      this.createApplicablePasses(response.data);
    } catch (error) {
      this.setLoading(false);
      this.setError({ stepNr: 4, error });
      console.error(error);
    }
  }

  async removeTravelPass(passCode: string) {
    try {
      this.setLoading(true);
      this.clearError();
      const travelPassCodes = this.travelPassCodes.filter(el => el !== passCode);

      const refNr = this.provisionalBooking?.tripDetails?.trip?.referenceNumber;
      const url = `${requestUrls.getRestUrl(requestUrls.rti.travelPasses)}.${refNr}.xjson`;

      const headers = {
        'Booking-Session-Access-Token': this.provisionalBooking?.bookingSession?.accessToken,
        'Booking-Session-Refresh-Token': this.provisionalBooking?.bookingSession?.refreshToken,
      };

      const response = await api.delete(url, {
        data: { travelPassCodes: [passCode] },
        headers,
      });

      this.setLoading(false);
      this.setTravelPassCodes(travelPassCodes);

      this.setProvisionalBooking(response.data);
      this.createApplicablePasses(response.data);
    } catch (error) {
      this.setLoading(false);
      this.setError({ stepNr: 4, error });
      console.error(error);
    }
  }

  async applyCreditPass(creditPassCode: string) {
    try {
      this.setLoading(true);
      this.clearError();

      const refNr = this.provisionalBooking?.tripDetails?.trip?.referenceNumber;
      const url = `${requestUrls.getRestUrl(requestUrls.rti.creditPasses)}.${refNr}.xjson`;

      const headers = {
        'Booking-Session-Access-Token': this.provisionalBooking?.bookingSession?.accessToken,
        'Booking-Session-Refresh-Token': this.provisionalBooking?.bookingSession?.refreshToken,
      };

      const response = await api.post(
        url,
        { creditPassCode },
        {
          headers,
        }
      );
      this.setLoading(false);
      this.creditPassCode.push(creditPassCode);

      this.setProvisionalBooking(response.data);
      this.createApplicablePasses(response.data);
    } catch (error) {
      this.setLoading(false);
      this.setError({ stepNr: 4, error });
      console.error(error);
    }
  }

  async removeCreditPass(creditPassCode: string) {
    try {
      this.setLoading(true);
      this.clearError();

      const refNr = this.provisionalBooking?.tripDetails?.trip?.referenceNumber;
      const url = `${requestUrls.getRestUrl(requestUrls.rti.creditPasses)}.${refNr}.xjson`;

      const headers = {
        'Booking-Session-Access-Token': this.provisionalBooking?.bookingSession?.accessToken,
        'Booking-Session-Refresh-Token': this.provisionalBooking?.bookingSession?.refreshToken,
      };
      const data = {
        creditPassCode,
      };

      const response = await api.delete(url, {
        data,
        headers,
      });

      this.setLoading(false);
      this.creditPassCode = this.creditPassCode.filter(code => code !== creditPassCode);
      this.setProvisionalBooking(response.data);

      this.createApplicablePasses(response.data);
    } catch (error) {
      this.setLoading(false);
      this.setError({ stepNr: 4, error });
      console.error(error);
    }
  }

  checkSeatingAvailability(provisionalBooking: ProvisionalBookingProps) {
    const trip = provisionalBooking.tripDetails.trip;

    if (!trip) {
      this.setOutboundSeatingAvailability(false);
      this.setInboundSeatingAvailability(false);

      return;
    }

    if (trip.outboundRoute) {
      const isOutboundCancelled = !!trip.outboundRoute?.isCancelled;
      const isOutboundSmart = trip.outboundRoute?.classType === 'smart';
      const isOrlandoOutbound =
        trip.outboundRoute?.origin?.id === 'MCO' || trip.outboundRoute?.destination?.id === 'MCO';

      this.setOutboundSeatingAvailability(!isOutboundCancelled && (!isOutboundSmart || isOrlandoOutbound));
    } else this.setOutboundSeatingAvailability(false);

    if (trip.inboundRoute) {
      const isInboundCancelled = !!trip.inboundRoute?.isCancelled;
      const isInboundSmart = trip.inboundRoute?.classType === 'smart';
      const isOrlandoInbound = trip.inboundRoute?.origin?.id === 'MCO' || trip.inboundRoute?.destination?.id === 'MCO';

      this.setInboundSeatingAvailability(!isInboundCancelled && (!isInboundSmart || isOrlandoInbound));
    } else this.setInboundSeatingAvailability(false);
  }

  async applyGiftCard(cardNumber, pin, fn) {
    try {
      this.clearError();
      this.setLoading(true);
      const { tripDetails } = this.rootStore.accountStore;

      const refNr =
        tripDetails?.trip?.referenceNumber ||
        this.provisionalBooking?.tripDetails?.trip?.referenceNumber ||
        this.rootStore?.passesStore?.checkout?.cartNumber;

      const url = requestUrls
        .getBffUrl(
          tripDetails?.trip?.referenceNumber || this.provisionalBooking?.tripDetails?.trip?.referenceNumber
            ? requestUrls.rti.giftCard
            : requestUrls.passes.giftCard
        )
        .replace('{booking_number}', refNr);

      const headers = {
        'Booking-Session-Access-Token':
          this.provisionalBooking?.bookingSession?.accessToken ||
          this.rootStore?.passesStore?.checkout?.bookingSession?.accessToken,
        'Booking-Session-Refresh-Token':
          this.provisionalBooking?.bookingSession?.refreshToken ||
          this.rootStore?.passesStore?.checkout?.bookingSession?.refreshToken,
      };

      const payload = {
        cardNumber,
        pin,
      };

      const data = await api.post(url, payload, { headers });

      this.addGiftCard({ ...data?.data?.giftCardPayment, giftCard: { ...data?.data?.giftCardPayment.giftCard, pin } });

      if (data.data?.tripDetails)
        this.setProvisionalBooking({
          tripDetails: { ...data?.data?.tripDetails },
          bookingSession: { ...data?.data?.bookingSession },
        });
      // this.createApplicablePasses(data.data, this.modifyTripType === 'extras');
      else this.rootStore.passesStore.checkout.setCostSummary(data?.data?.cart?.costSummary);

      fn(false);
      this.setLoading(false);
    } catch (error: any) {
      this.setLoading(false);
      this.setGiftCardError(error?.response?.data);
      console.error('Failed to apply gift card:', error);
    }
  }

  async deleteGiftCard(currentCard) {
    try {
      const { tripDetails } = this.rootStore.accountStore;

      const refNr =
        tripDetails?.trip?.referenceNumber ||
        this.provisionalBooking?.tripDetails?.trip?.referenceNumber ||
        this.rootStore?.passesStore?.checkout?.cartNumber;

      const url = requestUrls
        .getBffUrl(
          tripDetails?.trip?.referenceNumber || this.provisionalBooking?.tripDetails?.trip?.referenceNumber
            ? requestUrls.rti.giftCard
            : requestUrls.passes.giftCard
        )
        .replace('{booking_number}', refNr);

      const headers = {
        'Booking-Session-Access-Token':
          this.provisionalBooking?.bookingSession?.accessToken ||
          this.rootStore?.passesStore?.checkout?.bookingSession?.accessToken,
        'Booking-Session-Refresh-Token':
          this.provisionalBooking?.bookingSession?.refreshToken ||
          this.rootStore?.passesStore?.checkout?.bookingSession?.refreshToken,
      };

      const data = {
        cardNumber: currentCard.giftCard.cardNumber,
      };

      const response = await api.delete(url, {
        data,
        headers,
      });

      this.removeGiftCard(currentCard.giftCard.cardNumber);
      if (response.data?.tripDetails)
        this.setProvisionalBooking({
          tripDetails: { ...response?.data?.tripDetails },
          bookingSession: { ...response?.data?.bookingSession },
        });
      // this.createApplicablePasses(response.data, this.modifyTripType === 'extras');
      else this.rootStore.passesStore.checkout.setCostSummary(response?.data?.cart?.costSummary);
    } catch (error) {
      console.error('Failed to remove gift card:', error);
    }
  }

  async applyRewardsPoints(points, fn) {
    try {
      this.clearError();
      this.setLoading(true);
      const { tripDetails } = this.rootStore.accountStore;

      const refNr =
        tripDetails?.trip?.referenceNumber ||
        this.provisionalBooking?.tripDetails?.trip?.referenceNumber ||
        this.rootStore?.passesStore?.checkout?.cartNumber;

      const url = requestUrls
        .getBffUrl(
          tripDetails?.trip?.referenceNumber || this.provisionalBooking?.tripDetails?.trip?.referenceNumber
            ? requestUrls.rti.rewards
            : requestUrls.passes.rewards
        )
        .replace('{booking_number}', refNr);

      const headers = {
        'Booking-Session-Access-Token':
          this.provisionalBooking?.bookingSession?.accessToken ||
          this.rootStore?.passesStore?.checkout?.bookingSession?.accessToken,
        'Booking-Session-Refresh-Token':
          this.provisionalBooking?.bookingSession?.refreshToken ||
          this.rootStore?.passesStore?.checkout?.bookingSession?.refreshToken,
      };

      const response = await api.post(url, { points }, { headers });

      if (response.data?.tripDetails)
        this.setProvisionalBooking({
          tripDetails: { ...response.data?.tripDetails },
          bookingSession: { ...response.data?.bookingSession },
        });
      else this.rootStore.passesStore.checkout.setCostSummary(response?.data?.cart?.costSummary);

      this.setLoyaltyPayment(response.data?.loyaltyPayment);
      this.setAppliedRewardsPoints(response.data?.loyaltyPayment?.pointsApplied);
      this.setLoading(false);

      localStorage.setItem('rewardsApplied', response.data?.loyaltyPayment?.pointsApplied);

      fn(false);
    } catch (e: any) {
      this.setLoading(false);
      this.setAppliedRewardsPoints(0);
      this.setAppliedRewardsPointsError(e?.response?.data);
      console.error('Failed to apply rewards points: ', e);
    }
  }

  async deleteRewardsPoints() {
    const currentAppliedPoints = this.appliedRewardsPoints;
    const currentLoyaltyPayment = this.loyaltyPayment;

    try {
      this.setLoading(true);
      const { tripDetails } = this.rootStore.accountStore;
      const refNr =
        tripDetails?.trip?.referenceNumber ||
        this.provisionalBooking?.tripDetails?.trip?.referenceNumber ||
        this.rootStore?.passesStore?.checkout?.cartNumber;

      const url = requestUrls
        .getBffUrl(
          tripDetails?.trip?.referenceNumber || this.provisionalBooking?.tripDetails?.trip?.referenceNumber
            ? requestUrls.rti.rewards
            : requestUrls.passes.rewards
        )
        .replace('{booking_number}', refNr);

      const data = {
        externalReference: this.loyaltyPayment?.externalReference,
      };

      const headers = {
        'Booking-Session-Access-Token':
          this.provisionalBooking?.bookingSession?.accessToken ||
          this.rootStore?.passesStore?.checkout?.bookingSession?.accessToken,
        'Booking-Session-Refresh-Token':
          this.provisionalBooking?.bookingSession?.refreshToken ||
          this.rootStore?.passesStore?.checkout?.bookingSession?.refreshToken,
      };

      const response = await api.delete(url, { data, headers });

      if (response.data?.tripDetails)
        this.setProvisionalBooking({
          tripDetails: { ...response.data?.tripDetails },
          bookingSession: { ...response.data?.bookingSession },
        });
      else this.rootStore.passesStore.checkout.setCostSummary(response?.data?.cart?.costSummary);

      this.setAppliedRewardsPoints(0);
      this.setLoyaltyPayment({});
      this.setLoading(false);
    } catch (e: any) {
      this.setAppliedRewardsPoints(currentAppliedPoints);
      this.setLoyaltyPayment(currentLoyaltyPayment);
      this.setLoading(false);
      console.error('Failed to remove rewards points: ', e);
    }
  }

  async fetchEstimatedLoyaltyRewardsPoints(totalToBePaidByCreditCard: number) {
    try {
      const url = requestUrls.getBffUrl(requestUrls.loyalty.estimatedRewardsPoints);
      const response = await api.get(url, {
        params: {
          total: totalToBePaidByCreditCard,
        },
      });

      this.loyaltyRewardsPoints = Number(response.data.points);
    } catch (e: any) {
      console.error('Failed to fetch estimated rewards points: ', e);
      this.loyaltyRewardsPoints = 0;
    }
  }

  async updateLoyaltyDisplayStatus() {
    const { accountStore, authStore } = this.rootStore;
    const { fetchUserProfile } = accountStore.profile;
    const { isAuthenticated } = authStore;

    if (!isAuthenticated) {
      this.setDisplayLoyaltyComponents(false);

      return;
    }
    await fetchUserProfile();

    this.setDisplayLoyaltyComponents(
      isLoyaltyEnabled() &&
        isAuthenticated &&
        !accountStore.profile.userProfile?.travelAgentCode &&
        accountStore.profile.userProfile?.loyaltyPrograms?.some(
          program => program.program.toLowerCase() === 'brightline' && program.active
        )
    );
  }
}
