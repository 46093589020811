import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';
import { GiftCardConfig } from 'stores/BookingStore/sections/Tickets/types';

import Checkbox from '@atoms/Checkbox/Checkbox';
import { Icon } from '@atoms/Icon';
import Image from '@atoms/Image/Image';

import { cn } from 'utils/classNames';

import './GiftCard.scss';
import { GiftCardForm } from './GiftCardForm';
import { GiftCardModal } from './GiftCardModal';
import { useGiftCardLogic } from './useGiftCardLogic';

const bem = cn('gift-card');

export const GiftCard = observer(() => {
  const { bookingStore } = useStores();
  const { giftCards, setGiftCardError } = bookingStore.rti;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCard, setCurrentCard] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const { handleBoxClick, openModal, message, balanceMessage, reviewAndPay, isAddCardDisabled } = useGiftCardLogic({
    setCurrentCard,
    setIsModalOpen,
    setIsChecked,
    isChecked,
  });

  return (
    <div className={bem()}>
      <>
        {isModalOpen && <GiftCardModal currentCard={currentCard} onClose={() => setIsModalOpen(false)} />}

        {giftCards?.map((card: GiftCardConfig) => (
          <div key={card?.giftCard?.cardNumber} className={bem('applied')}>
            <div className={bem('applied-info')}>
              <div className={bem('top_content')}>
                <Image src={reviewAndPay.giftCardIcon.src} alt="Gift Card" className={bem('image')} />
                <div className={bem('details')}>
                  <div className={bem('title')}>{reviewAndPay.giftCardTitle}</div>
                  <div onClick={() => openModal(card)}>
                    <Icon className={bem('icon')} name="close" size="extraLarge" />
                  </div>
                </div>
              </div>
              <div className={bem('bottom_content')}>
                <div className={bem('description')}>{`'${card?.giftCard?.cardNumber}' ${balanceMessage(
                  card.giftCard.balance
                )}`}</div>
                <div className={bem('balance')}>{`$${(card?.amountPaid || 0).toFixed(2)} ${
                  reviewAndPay.giftCardAppliedSecondText
                }`}</div>
              </div>
            </div>
          </div>
        ))}
        <div className={bem('content', { active: isChecked })} onClick={handleBoxClick}>
          <div className={bem('content-wrapper', { gray: isAddCardDisabled })}>
            <div className={bem('top_content')}>
              <Image src={reviewAndPay.giftCardIcon.src} alt="Gift Card" className={bem('image')} />
              <div className={bem('text')}>
                <div className={bem('title')}>{reviewAndPay.giftCardTitle}</div>
                <div className={bem('subtitle')}>{message}</div>
              </div>
            </div>
            <Checkbox
              name="giftCard"
              label=""
              className={bem('checkbox')}
              isChecked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
                setGiftCardError({});
              }}
              disabled={isAddCardDisabled}
            />
          </div>
          {isChecked && <GiftCardForm setIsChecked={setIsChecked} />}
        </div>
      </>
    </div>
  );
});
