import React from 'react';

import { TagProps } from '@account/routes/MyTrips/types';

import { cn, cx } from 'utils/classNames';

import './Tag.scss';

const bem = cn('trips-tag');

export const Tag = (props: TagProps) => {
  const { label, variant, size, className } = props;

  return <span className={cx(bem({ variant, size }), className)}>{label}</span>;
};
