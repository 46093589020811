import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { AutoRenew } from '../../AutoRenew';
import { EditPass } from '../../EditPass';
import { CreditPassCard, PassCard } from '../../PassCards';
import { PassDetails } from '../../PassDetails/PassDetails';
import { WalletPassItemsProps } from '../types';
import './WalletPassItemDesktop.scss';

const bem = cn('wallet-pass-item-desktop');

export const WalletPassItemDesktop = observer((props: WalletPassItemsProps) => {
  const [expanded, setExpanded] = useState(false);
  const { cfStore } = useStores();
  const { account } = cfStore;
  const { wallet } = account;

  const { item } = props;
  const { type, isAutoRenewable } = item;

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const showAutoRenewOption = () => {
    if (type === 'train' && isAutoRenewable) return wallet.showTrainPassAutoRenewOption;
    else if (type === 'parking') return wallet.showParkingPassAutoRenewOption;

    return false;
  };

  return (
    <li className={bem()}>
      <div role="group" aria-label="Brightline Pass">
        <div className={bem('pass-content')}>
          <div className={bem('pass-details')}>
            {type !== 'credit' ? (
              <PassCard
                {...item}
                originStation={item?.station || item?.originStation}
                showAutoRenewIcon={showAutoRenewOption()}
                isBlWallet
              />
            ) : (
              <CreditPassCard {...item} />
            )}
            <div className={bem('content')}>
              <PassDetails item={item} />
            </div>
          </div>
          <div className={bem('action')}>
            {type !== 'parking' && (
              <Button
                label={wallet.creditCtaLabel}
                icon="train"
                variant="secondary"
                color="blue"
                size="small"
                link={{ url: wallet.creditCtaLink }}
                ariaHidden
              />
            )}
          </div>
        </div>
        {type !== 'credit' && showAutoRenewOption() && (
          <div className={bem('manage-pass', { expanded })}>
            <button className={bem('manage-pass-button')} onClick={toggleExpanded}>
              <div className={bem('manage-pass-label')}>{wallet.managePassLabel}</div>
              <Icon name="chevron-down" className={bem('manage-pass-icon')} />
            </button>
            {expanded && (
              <>
                <div className={bem('renew-content')}>
                  <div className={bem('auto-renew-wrapper')}>
                    <AutoRenew selectedPass={item} />
                  </div>
                  {type === 'parking' && <EditPass label={wallet.modifyPassCtaLabel} item={item} />}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </li>
  );
});
