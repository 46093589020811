import { observer } from 'mobx-react-lite';
import React, { Fragment, useMemo, useRef } from 'react';
import { useStores } from 'stores';

import { ExtrasModalLoader } from '@booking/routes/RTI/Extras/components/ExtrasModal/ModalLoader/ExtrasModalLoader';

import { cn } from 'utils/classNames';

import './Extras.scss';
import { ExtrasItem } from './ExtrasItem';
import { ParkingItem } from './ParkingItem';
import { ExtrasContentProps } from './types';

const bem = cn('rti-extras');

export const ExtrasContent = observer((props: ExtrasContentProps) => {
  const { bookingStore, cfStore } = useStores();
  const { expanded } = props;
  const { extras, parking } = bookingStore;
  const { activeModalCF } = extras;

  const extrasModalOnCloseRef = useRef<HTMLButtonElement>(null);

  const extrasItems = useMemo(
    () =>
      cfStore.rti.extras.typeLabels
        .filter(extraType => !extraType.isHidden)
        .map((extraTypeCF, index) => {
          if (
            extraTypeCF.type.endsWith('luggage') ||
            extraTypeCF.type.endsWith('pet') ||
            extraTypeCF.type.endsWith('specialty')
          ) {
            const extraData = extras.data.find(extrasData => extraTypeCF.type.endsWith(extrasData.type));

            if (!extraData) return <Fragment key={index} />;

            return (
              <ExtrasItem
                key={extraTypeCF.type}
                {...extraData}
                labels={extraTypeCF}
                expanded={expanded}
                onCloseRef={extrasModalOnCloseRef}
              />
            );
          } else if (extraTypeCF.type.endsWith('parking') && parking.data)
            return <ParkingItem key={extraTypeCF.type} type="parking" labels={extraTypeCF} expanded={expanded} />;

          return <Fragment key={index} />;
        }),
    [extras.data, cfStore.rti.extras.typeLabels, parking.data, expanded]
  );

  return (
    <div className={bem()}>
      <ul className={bem('extras-items-list')} role="list" aria-label="extras list">
        {extrasItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      {activeModalCF && <ExtrasModalLoader cfExtra={activeModalCF} onCloseRef={extrasModalOnCloseRef} />}
    </div>
  );
});
