import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Coach } from '@atoms/Coach';
import { Text } from '@atoms/Text';
import { facingDirections } from '@booking/routes/RTI/SeatSelection/constants';

import { cn } from 'utils/classNames';

import { TripLineProps } from '../../types';
import { Seat } from '../Seat';
import './TripLine.scss';

const bem = cn('trip-line');

export const TripLine = observer((props: TripLineProps) => {
  const { passenger, seat, seatingAvailable, isSmartSaver } = props;
  const { coachTextLabel, forwardFacingLabel, backwardFacingLabel, windowLabel, seatLabel, aisleLabel } = props;
  const { facingDirection, isWindow } = seat || {};

  const { cfStore } = useStores();
  const { seatingNotAvailableMessage, seatingNotAvailableSmartSaverMessage } = cfStore.rti.seats;

  const description = useMemo(
    () => `${facingDirection === facingDirections.forwards ? forwardFacingLabel : backwardFacingLabel}
    ${isWindow ? windowLabel : aisleLabel} ${seatLabel}`,
    [aisleLabel, backwardFacingLabel, facingDirection, forwardFacingLabel, isWindow, seatLabel, windowLabel]
  );

  return (
    <>
      {!seatingAvailable && (
        <div className={bem()}>
          <Text
            text={isSmartSaver ? seatingNotAvailableSmartSaverMessage : seatingNotAvailableMessage}
            className={bem('seating-unavailable-text')}
          />
        </div>
      )}
      {seatingAvailable && (seat.number || seat.inventoryType) && (
        <div className={bem()}>
          <div className={bem('passenger')}>
            {passenger.firstName} {passenger.lastName}
          </div>
          <div className={bem('seat')}>
            <div className={bem('location')}>
              <Coach {...seat.coach} text={coachTextLabel} />
              <Seat number={seat.number} facingDirection={seat.facingDirection} />
            </div>
            <div className={bem('description')}>{description}</div>
          </div>
        </div>
      )}
    </>
  );
});
