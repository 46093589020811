import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import { Text } from '@atoms/Text';
import { ManageTripsFormDesktop } from './ManageTripsForm.desktop';
import { ManageTripsFormMobile } from './ManageTripsForm.mobile';
import './ManageTripsForm.scss';
import { DEFAULT_I18N } from './constants';
import { ManageTripsFormProps } from './types';

const bem = cn('manage-trips-form-container');

export const ManageTripsForm = observer((props: ManageTripsFormProps) => {
  const { i18n = DEFAULT_I18N } = props;
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  return (
    <div className={bem()}>
      <Text as="p" text={i18n.requiredFieldLabel} className={bem('required-field-label')} />
      <h5 className={bem('title')}>{i18n.title}</h5>
      <h6 className={bem('description')}>{i18n.description}</h6>

      {isMobile ? <ManageTripsFormMobile {...props} /> : <ManageTripsFormDesktop {...props} />}
    </div>
  );
});
