import React, { useCallback, useMemo, useState } from 'react';

import { cn } from 'utils/classNames';

import './ContentTabs.scss';
import HeaderTab from './HeaderTab';
import { ContentTabsProps } from './types';
import { useContentTabHandlers } from './useContentTabHandlers';

const bem = cn('content-tabs');

const ContentTabs = (props: ContentTabsProps) => {
  const { items, swapDuration = 1, colors } = props;

  const [swapping, setSwapping] = useState(false);

  const style: any = useMemo(
    () => ({
      '--tab-header-border-focus-color': colors?.border?.focus,
      '--tab-header-text-color': colors?.text?.inactive,
      '--tab-header-text-focus-color': colors?.text?.focus,
      '--tab-header-text-disabled-color': colors?.text?.disabled,
      '--tab-header-text-active-color': colors?.text?.active,
      '--tab-header-background-color': colors?.background?.inactive,
      '--tab-header-background-disabled-color': colors?.background?.disabled,
      '--tab-header-background-active-color': colors?.background?.active,
    }),
    [colors]
  );

  const handleOnSelect = (id, index) => {
    handleClickTabHeader(id, index)();
  };

  const hookProps = useContentTabHandlers({ items: items, onSelect: handleOnSelect });
  const { handleKeyDown, focus, setFocus, touched } = hookProps;

  const handleClickTabHeader = useCallback(
    (id, index) => () => {
      if (!swapping) {
        setFocus({ index: index });
        setSwapping(true);
        const nextSelectedElement = document.querySelector(`#${id}`);
        const parentWrapper = nextSelectedElement?.closest(`.${bem('content_wrapper')}`);
        const currentSelectedElement = parentWrapper?.querySelector(`.${bem('visible')}`);

        currentSelectedElement?.classList.remove(bem('visible'));

        setTimeout(() => {
          currentSelectedElement?.classList.add(bem('hidden'));

          nextSelectedElement?.classList.remove(bem('hidden'));
          nextSelectedElement?.classList.add(bem('visible'));
          setSwapping(false);
        }, swapDuration * 1000);
      }
    },
    [swapDuration, swapping, setFocus]
  );

  return (
    <div className={bem('header')} style={style}>
      <div className={bem('header_item_wrapper')} role="tablist" onKeyDown={handleKeyDown}>
        {items.map((item, index) => (
          <HeaderTab
            key={`btn-content-tab-${item.contentId}`}
            item={item}
            index={index}
            shouldFocus={focus.index === index}
            focus={focus}
            handleClickTabHeader={handleClickTabHeader}
            touched={touched}
          />
        ))}
      </div>
    </div>
  );
};

export default ContentTabs;
