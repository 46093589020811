import { observer } from 'mobx-react-lite';
import React from 'react';

import { cn } from 'utils/classNames';

import { MobileDesktopDateRangePickerProps } from '../types';
import { MobileCalendar } from './MobileCalendar';

const bem = cn('events-date-range-picker');

export const MobileDateRangePicker = observer((props: MobileDesktopDateRangePickerProps) => {
  const { focusedInput, isOutsideRange, containerRef } = props;
  const { handleFocusChange, handleDatesChange } = props;

  return (
    <div className={bem('mobile')}>
      <MobileCalendar
        {...props}
        containerRef={containerRef}
        handleFocusChange={handleFocusChange}
        focusedInput={focusedInput}
        handleDatesChange={handleDatesChange}
        isOutsideRange={isOutsideRange}
      />
    </div>
  );
});
