import { createContext, useContext } from 'react';

import AccountStore from './AccountStore/AccountStore';
import AuthStore from './AuthStore';
import BookingStore from './BookingStore/BookingStore';
import CFStore from './CFStore/CFStore';
import HeaderStore from './HeaderStore';
import KioskStore from './KioskStore/KioskStore';
import ManageTripsFormStore from './ManageTripsFormStore';
import PassesStore from './PassesStore/PassesStore';
import PromoStore from './PromoCodeStore/PromoCodeStore';
import TicketsFormStore from './TicketsFormStore';

export class RootStore {
  promoStore = new PromoStore(this);
  ticketsFormStore = new TicketsFormStore();
  bookingStore = new BookingStore(this);
  manageTripsFormStore = new ManageTripsFormStore();
  cfStore = new CFStore();
  authStore = new AuthStore(this);
  headerStore = new HeaderStore(this);
  accountStore = new AccountStore(this);
  passesStore = new PassesStore(this);
  kioskStore = new KioskStore(this);
}

export const rootStore = new RootStore();

const StoresContext = createContext<RootStore>(rootStore);

export const useStores = () => useContext(StoresContext);
