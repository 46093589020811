import React from 'react';
import { useNavigate } from 'react-router-dom';
import { rootStore, useStores } from 'stores';

import { Icon } from '@atoms/Icon/Icon';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { isLoyaltyEnabled } from 'utils/metas';

import './ContentHeader.scss';
import { ContentHeaderProps } from './types';

const bem = cn('content-header');

export const ContentHeader = (props: ContentHeaderProps) => {
  const { pageTitle, actions } = props;

  const { cfStore } = useStores();
  const { account } = cfStore;
  const { dashboard } = account;
  const navigate = useNavigate();

  const loyaltyEnabled = isLoyaltyEnabled();

  const handleNavigateToDashboard = () => {
    if (dashboard.link) {
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: dashboard.navigationTitle,
          customLink: dashboard.link,
          externalLink: '',
          linkType: 'linkClick',
        },
      });
      if (dashboard.link.startsWith('/account')) navigate(dashboard.link.split('/account')[1]);
      else window.location.href = dashboard.link;
    }
  };

  return (
    <div className={bem()}>
      <div className={bem('content')}>
        {!loyaltyEnabled && (
          <button onClick={handleNavigateToDashboard} className={bem('back')} aria-label="more">
            <Icon name="chevron-left" />
          </button>
        )}
        <h1 className={bem('title')}>{pageTitle}</h1>
      </div>
      <div className={bem('actions')}>{actions} </div>
    </div>
  );
};
