import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { color_success_green, color_white } from 'utils/colors';

import './PromoCodeAppliedBanner.scss';

const bem = cn('promo-code-applied-banner');

export const PromoCodeAppliedBanner = observer(() => {
  const [hideBanner, setHideBanner] = useState(false);

  const { cfStore, ticketsFormStore, bookingStore } = useStores();

  const { outboundTicket } = bookingStore.tickets;

  const promoCodesBannerLabels = cfStore.trainSelection.promoCodeBanner;

  const promoCode = ticketsFormStore.formValues.promoCode;

  const promoCodeMessage = promoCodesBannerLabels.promoCodeMessage.replace('%promocode%', promoCode);

  const promoCodeIcon = promoCodesBannerLabels.promoCodeIcon;

  const handleHideBanner = () => {
    setHideBanner(true);
  };

  useEffect(() => {
    setHideBanner(false);
  }, [outboundTicket]);

  if (hideBanner) return null;

  return (
    <div className={bem()}>
      <div
        className={bem('banner')}
        style={{ backgroundColor: promoCodesBannerLabels.promoCodeMessageBackgroundColor || color_success_green }}
      >
        <div className={bem('banner-content')}>
          {promoCodeIcon && (
            <Icon className={bem('icon')} externalIcon={promoCodeIcon.src} externalIconAlt={promoCodeIcon.alt} />
          )}
          <Text
            text={promoCodeMessage}
            className={bem('text')}
            style={{ color: promoCodesBannerLabels.promoCodeMessageTextColor || color_white }}
          />
        </div>
        <div
          role="button"
          className={bem('close-icon')}
          style={{ color: promoCodesBannerLabels.promoCodeMessageTextColor || color_white }}
          onClick={handleHideBanner}
        >
          <Icon name="close2" className={bem('text')} />
        </div>
      </div>
    </div>
  );
});
