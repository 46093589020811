import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './ReviewAndPay.scss';
import { SavedCardsPlaceholder } from './components/PaymentCardSelector/SavedCardsPlaceholder';
import { PaymentFormPlaceholder } from './components/PaymentForm/PaymentFormPlaceholder';

const bem = cn('passes-payment');

export const ReviewAndPayPlaceholder = observer(() => (
  <ReactPlaceholder
    ready={false}
    customPlaceholder={
      <div className={bem()}>
        <RectShape
          color={color_base_medium_light_grey}
          style={{ width: 100, height: 32, marginBottom: 2, borderRadius: 8 }}
        />
        <SavedCardsPlaceholder />
        <PaymentFormPlaceholder />
      </div>
    }
    showLoadingAnimation
  >
    <div />
  </ReactPlaceholder>
));
