import React, { useId } from 'react';

import { cn, cx } from 'utils/classNames';

import './Checkbox.scss';
import { CheckboxProps } from './types';

const bem = cn('checkbox');

const Checkbox = (props: CheckboxProps) => {
  const {
    name,
    value,
    disabled,
    label,
    className = '',
    labelClassName = 'blte-font--variant-body-medium-500',
    errorClassName = 'blte-font--variant-tiny-500',
    isChecked,
    isInvalid,
    errorMessage,
    onChange,
    onBlur,
  } = props;

  const generatedId = useId();

  const id = props.id || generatedId;

  return (
    <div
      className={cx(bem({ 'is-disabled': disabled, 'has-error': isInvalid }), {
        [className]: !!className,
      })}
    >
      <input
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        type="checkbox"
        className={bem('input')}
        name={name}
        value={value}
        checked={isChecked}
        disabled={disabled}
      />
      <label htmlFor={id} className={cx(bem('label'), labelClassName)}>
        <h3 className={bem('label-description')}>{label}</h3>
      </label>
      {isInvalid && errorMessage && <span className={cx(bem('error'), errorClassName)}>{errorMessage}</span>}
    </div>
  );
};

export default Checkbox;
