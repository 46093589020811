import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';
import { SelectedItemConfig } from 'stores/BookingStore/sections/Tickets/types';

import { Text } from '@atoms/Text';
import { TravelPassEligible } from '@booking/routes/TrainSelection/components/RouteItem/components/TravelPassEligible';

import { cn, cx } from 'utils/classNames';

import './FareCompareItem.scss';
import { FareCompareItemProps } from './types';
import UpsellContentDescription from '@organisms/UpsellModal/UpsellContentDescription/UpsellContentDescription';
import { useFareCompareItemLogic } from './useFareCompareItemLogic';

const bem = cn('fare-compare-selection-item');
export const FareCompareItem = observer((props: FareCompareItemProps) => {
  const { fareTypeLabel, perGuestLabel, fareContentTitle, isItemAvailable, smartSaverSelected } = props;
  const { fareVariant, fareColor, upsellDescription, upsellTitleLabel } = props;

  const { seatProduct, handleSelected, descriptionWithIcons, getFormattedPrice } = useFareCompareItemLogic(props);
  
  const { bookingStore } = useStores();
  const { isAvailable, message } = isItemAvailable;
  
  const { selectedItem, isFareSelected } = bookingStore.tickets;
  const { journey, prices, ticket } = (selectedItem as SelectedItemConfig) || {};

  if (!(journey && ticket && prices)) return null; 

  return (
    <>
      <div
        className={cx(
          bem({ fareVariant }),
          bem(fareVariant, { isFareSelected: isFareSelected && selectedItem?.ticket?.productId === fareVariant })
        )}
        onClick={handleSelected}
      >
        {!message ? <>
          <div
            className={bem('item', { isPremium: fareVariant == 'PREMIUM_STANDARD' })}
            style={{ background: fareColor }}
          >
            <div className={bem('cost')}>
              <div className={bem('fare-wrapper')}>
                <div className={bem('fare-title')}>{fareTypeLabel?.toLocaleUpperCase()}</div>
                {seatProduct?.isTravelPassEligible && <TravelPassEligible isTravelPassEligible={seatProduct?.isTravelPassEligible} />}
              </div>
              <div>
                <div className={bem('price')}>{getFormattedPrice(prices?.[fareVariant])}</div>
                {prices?.[fareVariant] !== prices?.[`original${fareVariant}`] && (
                  <h4 className={bem('originalPrice', { isPremium: fareVariant == 'PREMIUM_STANDARD' })}>
                    {getFormattedPrice(prices[`original${fareVariant}`])}
                  </h4>
                )}
                <div className={bem('per-guest')}>{perGuestLabel}</div>
              </div>
            </div>
          </div>
          <div className={bem('content')}>
            <div className={bem('title')}>{fareContentTitle}</div>
            <div className={bem('horizontal-line')} />
            <Text text={descriptionWithIcons} />
          </div>
          </> : <>
            <div
              className={bem('item')}
              style={{ background: "#E1E1E1" }}
            >
              <div className={bem('cost')}>
                <div className={bem('fare-wrapper')}>
                  <div className={bem('fare-title', { isNotAvailable: !isAvailable })}>{fareTypeLabel?.toLocaleUpperCase()}</div>
                </div>
              </div>
            </div>
            <div className={bem('content')}>
              <Text text={message} className={bem("sold-out-message", { isSmartSaver : fareVariant == 'SMART_SAVER'})} />
            </div>
          </>}
      </div>
      {smartSaverSelected && <UpsellContentDescription description={upsellDescription} title={upsellTitleLabel} isMobile />}
    </>
  );
});
