import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { SeatSelectionMapProps } from '@booking/routes/RTI/SeatSelection/components/SeatSelectionModal/SeatSelectionMap/types';

import { cn } from 'utils/classNames';

import { CoachItem } from './CoachItem';
import './SeatSelectionMap.scss';

const bem = cn('seat-selection-map');
export const SeatSelectionMap = observer((props: SeatSelectionMapProps) => {
  const { bookingStore } = useStores();

  const { seats } = bookingStore.rti;

  const { coaches, displayedCoach } = seats;

  if (!coaches.length) return null;

  return <div className={bem()}>{!!coaches.length && displayedCoach && <CoachItem {...props} />}</div>;
});
