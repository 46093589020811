import jwtDecode from 'jwt-decode';

export const parseEmailFromSessionToken = (token: string) => {
  try {
    return (jwtDecode(token) as any).email;
  } catch (e) {
    console.error(e);

    return null;
  }
};
