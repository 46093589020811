import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './MobileFooter.scss';

const bem = cn('passes-mobile-footer');

export const MobileFooter = observer(() => {
  const { cfStore, passesStore, authStore } = useStores();
  const { costSummary, urlParams } = passesStore.checkout;
  const { isAuthenticated: isWebAuthenticated } = authStore;
  const cfLabels = cfStore.passes.costSummary;

  const isMobileAuthenticated = urlParams.mobileData.jwt;

  const isAuthenticated = useMemo(
    () => isWebAuthenticated || isMobileAuthenticated,
    [isWebAuthenticated, isMobileAuthenticated]
  );

  const costSummaryTotalConfig = useMemo(() => {
    if (isAuthenticated) return costSummary.bookingTotal.totalToBePaid;

    return urlParams.totalPriceDiscounted;
  }, [isAuthenticated, urlParams.totalPriceDiscounted, costSummary]);

  return (
    <div className={bem()}>
      <Text text={cfLabels.totalLabel} className={bem('label')} />
      <Text text={`$${costSummaryTotalConfig.toFixed(2)}`} className={bem('price')} />
    </div>
  );
});
