import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Text } from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './ExtrasModal.scss';
import { ErrorBanner } from './components/ErrorBanner';
import { InfoCard } from './components/InfoCard/InfoCard';
import { Item } from './components/Item/Item';
import { ModalFooter } from './components/ModalFooter/ModalFooter';
import { ExtrasModalProps } from './types';
import { useExtrasModalLogic } from './useExtrasModalLogic';

const bem = cn('extras-modal');

export const ExtrasModal = observer((props: ExtrasModalProps) => {
  const { initialFormValues, itemCards, infoCards, cfLabels, isUpdateButton, onCloseRef } = props;

  const { bookingStore } = useStores();

  const { error } = bookingStore.extras;

  const extrasModalLogic = useExtrasModalLogic(initialFormValues, itemCards, cfLabels.analyticsEventType);
  const { formRef, handleSubmitExtras, hideModal } = extrasModalLogic;

  const onCloseModal = () => {
    onCloseRef?.current && onCloseRef.current.focus();
    hideModal();
  };

  return (
    <Modal variant="default-no-space" title={cfLabels.modalTitle} onClose={onCloseModal}>
      <div className={bem()}>
        <div className={bem('content')}>
          {error && <ErrorBanner errorMessage={cfLabels.errorMessage} />}

          <div className={bem('heading')}>
            <Title text={cfLabels.title} size="h2" />
            <Text className={bem('content-main')} text={cfLabels.subtitle} />
          </div>

          {!!infoCards && !!infoCards.length && (
            <div className={bem('info-cards')}>
              {infoCards.map((infoCard, index) => (
                <InfoCard key={index} {...infoCard} numCards={infoCards.length} />
              ))}
            </div>
          )}

          <Formik
            initialValues={initialFormValues}
            onSubmit={handleSubmitExtras}
            enableReinitialize={false}
            innerRef={formRef}
          >
            <div className={bem('item-list')}>
              {itemCards.map((itemCardConfig, index) => (
                <Item
                  key={index}
                  itemCardIndex={index}
                  itemCard={itemCardConfig}
                  modalLabels={cfLabels}
                  extrasModalLogic={extrasModalLogic}
                />
              ))}
            </div>
          </Formik>

          {!!cfLabels.disclaimer && (
            <Text text={cfLabels.disclaimer.replaceAll('&nbsp;', ' ')} className={bem('disclaimer')} />
          )}
        </div>
        <ModalFooter
          cfLabels={cfLabels.footer}
          isUpdateButton={isUpdateButton}
          extrasModalLogic={extrasModalLogic}
          onCloseRef={onCloseRef}
        />
      </div>
    </Modal>
  );
});
