import React from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { Button } from '@atoms/Button';
import CheckboxField from '@atoms/Checkbox/CheckboxField';
import { InputField } from '@atoms/Input';
import { Text } from '@atoms/Text';
import SelectField from '@organisms/ParkingPasses/SelectGarage/SelectFieldGarage';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import { MonthlyParkingPassesFormProps } from '../types';
import useDailyAndParkingPassesLogic from '../useDailyAndParkingPassesLogic';
import './MonthlyParkingPassesForm.scss';
import { useMonthlyParkingPassesLogic } from './useMonthlyParkingPasssesLogic';

const bem = cn('monthly-parking-passes-form');

export const MonthlyParkingPassesForm = (props: MonthlyParkingPassesFormProps) => {
  const { labels, isLoading, stationsData } = props;

  const isTabletLarge = useMediaPredicate(mediaQueryList.maxTabletLarge);

  const { stateOptions, totalPriceConfig, handleStationChanged, errors, submitForm, values, setFieldValue } =
    useMonthlyParkingPassesLogic(props);

  const handleCapitalizeParkingPasses = useDailyAndParkingPassesLogic();

  return (
    <div className={bem()}>
      <div className={bem('body')}>
        <Text as="h1" text={labels?.title} className={bem('title')} />
        <Text as="h2" text={labels?.subtitle} className={bem('subtitle')} />
        <div className={bem('input')}>
          <SelectField
            disabled={isLoading}
            errorVariant="inline"
            name="ppStationName"
            label={labels?.garageSelectLabel}
            optionsData={stationsData}
            error={!!errors.ppStationName}
            errorMessage={errors.ppStationName}
            customOnChange={handleStationChanged}
            useDesktopVariant
            useSpan
          />
        </div>
        <Text as="h3" text={labels?.garageNotes} className={bem('text', { garageNote: true })} />
        <Text as="h2" text={labels?.vehicleRegistrationTitle} className={bem('subtitle')} />
        <Text as="p" text={labels?.requiredFieldLabel} className={bem('required-field-label')} />
        <div className={bem('input')}>
          <SelectField
            errorVariant="inline"
            name="ppLicenseState"
            label={labels?.stateSelectLabel}
            optionsData={stateOptions}
            useDesktopVariant
            displayTrailingIcon
          />
        </div>
        <div className={bem('input')}>
          <form noValidate>
            <InputField
              type="text"
              name="ppLicenseNumber"
              errorVariant="inline"
              onChange={e => handleCapitalizeParkingPasses(e.target.value, 'ppLicenseNumber', setFieldValue)}
              label={labels?.licensePlateTextFieldPlaceholder}
              ariaLabel="clear"
              displayTrailingIcon
              showAsterisk
              required
            />
          </form>
        </div>
        <div className={bem('autoRenew')}>
          <CheckboxField label={labels?.autoRenewCheckboxLabel} name="autoRenew" checked={values.autoRenew} />
        </div>
        <Text as="h3" text={labels?.autoRenewNote} className={bem('text', { renewNote: true })} />
        <div className={bem('row')}>
          <div className={bem('btn-wrapper')}>
            <Button
              {...labels.primaryButton}
              link={undefined}
              width={isTabletLarge ? 'full' : 'normal'}
              onClick={submitForm}
              loading={isLoading}
              className={bem('btn')}
            />
          </div>
          <div className={bem('price-row')}>
            {!!totalPriceConfig.totalDiscounted && (
              <>
                <Text text={`$${totalPriceConfig.total}`} className={bem('total', { discount: true })} />
                <Text text={`$${totalPriceConfig.totalDiscounted}`} className={bem('total')} />
              </>
            )}
            {!totalPriceConfig.totalDiscounted && <Text text={`$${totalPriceConfig.total}`} className={bem('total')} />}
          </div>
        </div>
      </div>
    </div>
  );
};
