import { noop } from 'lodash';
import { useEffect, useRef } from 'react';

export const useModalTabLogic = props => {
  const { playerOpen, closePlayer, isModalOpened, ...rest } = props;

  const modalRef = useRef<HTMLDivElement>(null);

  const checkClickOutside = e => {
    if (isModalOpened && modalRef.current && !modalRef?.current?.contains(e.target)) closePlayer();
  };

  useEffect(() => {
    modalRef.current?.focus();
  }, []);

  useEffect(() => {
    if (playerOpen && modalRef.current) {
      const modalElement = modalRef.current;
      const handleTabKeyPress = event => {
        const focusableElements = modalElement.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement: any = focusableElements[0];
        const lastElement: any = focusableElements[focusableElements.length - 1];

        if (event.key === 'Tab')
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
      };

      const handleEscapeKeyPress = event => {
        if (event.key === 'Escape') closePlayer();
      };

      document.body.classList.add('blockScroll');
      modalElement.addEventListener('keydown', handleTabKeyPress);
      modalElement.addEventListener('keydown', handleEscapeKeyPress);

      return () => {
        document.body.classList.remove('blockScroll');
        modalElement.removeEventListener('keydown', handleTabKeyPress);
        modalElement.removeEventListener('keydown', handleEscapeKeyPress);
      };
    }

    return () => noop;
  }, [playerOpen, closePlayer, rest]);

  useEffect(() => {
    document.addEventListener('mousedown', checkClickOutside);

    return () => document.removeEventListener('mousedown', checkClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpened]);

  return {
    modalRef,
  };
};
