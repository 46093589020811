import React from 'react';

import { useInputLogic } from 'utils/useInputLogic';

import { Input } from './Input';
import { defaultInputName } from './constants';
import { InputFieldProps } from './types';

export const InputField = (props: InputFieldProps) => {
  const { type, name, errorMessage, errorVariant } = props;
  const nullCheckName = name || defaultInputName;

  const { handleClickTrailingIcon, field, isInvalid, error } = useInputLogic({
    ...props,
    name: nullCheckName,
  });

  return (
    <Input
      {...field}
      {...props}
      errorVariant={errorVariant}
      name={nullCheckName}
      onClickTrailingIcon={handleClickTrailingIcon}
      type={type}
      error={isInvalid}
      errorMessage={errorMessage || error}
    />
  );
};
