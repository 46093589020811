import cx from 'classnames';
import React from 'react';

export const Grid = ({ horizontalPadding = 'contained', children, className = '', ...rest }) => {
  const classNames = cx(className, {
    'blte-padding--horizontal-contained': horizontalPadding === 'contained',
    'blte-padding--horizontal-hero': horizontalPadding === 'hero',
  });

  return (
    <div className={classNames} {...rest}>
      <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 aem-Grid--tabletSmall--12 aem-Grid--mobile--12 aem-Grid--tabletLarge--12">
        {children}
      </div>
    </div>
  );
};
