import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';

import { cn } from 'utils/classNames';

import './Header.scss';

const bem = cn('kiosk-header');

export const Header = observer(() => {
  const location = useLocation();
  const { cfStore, kioskStore } = useStores();
  const { header } = cfStore;
  const { displaySessionClearanceModal } = kioskStore;

  return (
    <div className={bem()}>
      <img src={header.logo} alt={header.logoAlt} />
      {location.pathname !== '/' && (
        <Button color="yellow" label={header.endSessionLabel} onClick={displaySessionClearanceModal} />
      )}
    </div>
  );
});
