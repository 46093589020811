import { useEffect, useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useSwipeable } from 'react-swipeable';

import { FeatureItemProps } from '@molecules/FeatureItem/v2/types';

import { mediaQueryList } from 'utils/mediaQueries';

const directions = {
  left: 'left',
  right: 'right',
};

const useMobileCarousel = (items: FeatureItemProps[], variant: string) => {
  const [currentTranslateValue, setCurrentTranslateValue] = useState(0);
  const [activeSlide, setActiveSlide] = useState(1);
  const [prevMargins, setPrevMargins] = useState(0);

  const isMobile = useMediaPredicate(mediaQueryList.maxMobile);
  const isTablet = useMediaPredicate(mediaQueryList.maxTablet);

  const innerRef: any = useRef<HTMLDivElement>(null);

  const getElementSizeAndSpaces = element => {
    const elementWidth: any = element?.offsetWidth;
    const elementStyle = window.getComputedStyle(element);
    const elementMarginRight = parseInt(elementStyle.marginRight);
    const elementMarginLeft = parseInt(elementStyle.marginLeft);
    const horizontalSpacings = elementMarginLeft + elementMarginRight;
    const elementSpacing = horizontalSpacings > prevMargins ? horizontalSpacings : prevMargins;

    return { elementWidth, elementSpacing };
  };

  const changeSlide = (newSlideIndex: number, movingValue: number) => {
    if (!(isMobile || isTablet) || newSlideIndex > items.length || newSlideIndex < 1) return;
    innerRef.current.style.transform = `translate3d(${movingValue}px, 0, 0)`;
    setCurrentTranslateValue(movingValue);
  };

  const handleSwipe = direction => () => {
    if (variant === 'stack') return;

    const element: any = innerRef?.current?.childNodes[activeSlide - 1];
    const { elementWidth, elementSpacing } = getElementSizeAndSpaces(element);
    if (direction === directions.left && activeSlide < items.length) {
      const newIndex = activeSlide + 1;
      const leftMoveValue = currentTranslateValue - elementWidth - elementSpacing;
      changeSlide(newIndex, leftMoveValue);
      setPrevMargins(elementSpacing);
      setActiveSlide(newIndex);
    } else if (direction === directions.right && activeSlide > 1) {
      const newIndex = activeSlide - 1;
      const rightMoveValue = currentTranslateValue + elementWidth + elementSpacing;
      changeSlide(newIndex, rightMoveValue);
      setActiveSlide(newIndex);
    }
  };

  const handlers = useSwipeable({
    onSwiping: ({ deltaX }) => {
      if (variant === 'stack') return;

      if ((isMobile || isTablet) && activeSlide < items.length && activeSlide > 1)
        innerRef.current.style.transform = `translate3d(${currentTranslateValue + deltaX}px, 0, 0)`;
    },
    onSwipedRight: handleSwipe(directions.right),
    onSwipedLeft: handleSwipe(directions.left),
    trackMouse: true,
  });

  const getRefFromHandlers = (el: HTMLUListElement) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set myRef el so you can access it yourself
    innerRef.current = el;
  };

  useEffect(() => {
    if ((innerRef?.current?.style?.transform && !isMobile && !isTablet) || isTablet || isMobile) {
      innerRef?.current?.style?.removeProperty('transform');
      setCurrentTranslateValue(0);
      setActiveSlide(1);
    }
  }, [isMobile, isTablet]);

  return {
    innerRef,
    handlers,
    getRefFromHandlers,
    activeSlide,
  };
};

export default useMobileCarousel;
