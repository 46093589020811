import { Form } from 'formik';
import React from 'react';

import { Button } from '@atoms/Button';
import { InputField } from '@atoms/Input';
import SelectField from '@atoms/Select/SelectField';
import { InlineDateInputField } from '@molecules/InlineDateInput';
import { PhoneNumber } from '@molecules/PhoneNumber';

import { cn } from 'utils/classNames';

import { PrivacyRequestFormContentProps } from './../../types';

const bem = cn('privacy-request-form-content');

export const PrivacyRequestFormContent = (props: PrivacyRequestFormContentProps) => {
  const { firstNameLabel, lastNameLabel, dateOfBirthLabel, emailLabel } = props;
  const { phoneNumberLabel, requestTypeDropdownLabel, primaryButton } = props;
  const { privacyRequestFormLogic } = props;
  const { showInAuthor } = props;

  const { optionsData, isFormSubmitting, submitFormStatus } = privacyRequestFormLogic;

  return (
    <Form>
      <div className={bem()}>
        <InputField name="firstName" label={firstNameLabel} fieldsetClassName={bem('input')} required />
        <InputField name="lastName" label={lastNameLabel} fieldsetClassName={bem('input')} required />
        <InlineDateInputField
          dateFormat="MM/DD/YYYY"
          name="dob"
          label={dateOfBirthLabel}
          showMaxCharacters={false}
          fieldsetClassName={bem('input')}
          required
        />
        <InputField name="email" label={emailLabel} fieldsetClassName={bem('input')} required disabled />
        <PhoneNumber name="phoneNumber" label={phoneNumberLabel} className={bem('phone-number-input')} required />
        <SelectField
          name="requestType"
          label={requestTypeDropdownLabel}
          optionsData={optionsData}
          fieldsetClassName={bem('input')}
          required
          useDesktopVariant
          isPrivacyRequest
        />
        <div className={bem('button-row')}>
          <Button
            color={primaryButton.color}
            size={primaryButton.size}
            width={primaryButton.width}
            variant={primaryButton.variant}
            label={primaryButton.buttonConfirmLabel}
            loading={isFormSubmitting}
            disabled={submitFormStatus === 'success' || showInAuthor}
            type="submit"
          />
        </div>
      </div>
    </Form>
  );
};
