import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import Button from '@atoms/Button/Button';
import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';
import { CelebrationBannerRewards } from '@molecules/CelebrationBanner/CelebrationBannerRewards';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './CelebrationBanner.scss';
import { CelebrationBannerEmails } from './CelebrationBannerEmails';
import { CelebrationBannerProps } from './types';
import { usePrintingLogic } from './usePrintingLogic';
import { usePushLoyaltyDataLayerLogic } from './usePushLoyaltyDataLayerLogic';

const bem = cn('celebration-banner');

export const CelebrationBanner = observer((props: CelebrationBannerProps) => {
  const { image, ctaList, bottomText, colors, isHydrated = true, isKiosk } = props;
  const { id, title, bookingLabel, bottomTextPasses, isBlRewardsFlow } = props;
  const { rewards } = props;

  usePushLoyaltyDataLayerLogic();

  const isDesktop = window.matchMedia(mediaQueryList.desktop).matches;

  const { rn } = useTripDetailsConfig();

  const { addPrintButtonProps, bookingLabelFormated } = usePrintingLogic({ bookingLabel });

  const withImgVariant = image && image.src;

  const style: any = useMemo(
    () => ({
      '--celebration-banner-background-color': colors?.background.inactive,
      '--celebration-banner-text-color': colors?.text?.inactive,
      '--celebration-banner-cardsBackground-color': colors?.cardsBackground?.inactive,
      '--celebration-banner-bubbleBorder-color': colors?.bubbleBorder?.inactive,
      '--celebration-banner-bubbleBackground-color': colors?.bubbleBackground?.inactive,
      '--celebration-banner-couldHaveEarnedText-color': colors?.couldHaveEarnedText?.inactive,
      '--celebration-banner-couldHaveEarnedBackground-color': colors?.couldHaveEarnedBackground?.inactive,
    }),
    [colors]
  );

  const Buttons = ctaList.map(cta => <Button key={cta.label} {...addPrintButtonProps(cta)} role="link" />);

  return (
    <>
      <div id={id} className={bem(withImgVariant ? '' : 'banner-no-image')} style={style}>
        <div className={bem(withImgVariant ? 'details' : 'details-no-image')}>
          {!isKiosk && !isBlRewardsFlow && (
            <div className={bem('booking')}>
              {bookingLabelFormated}
              {rn}
            </div>
          )}
          <div id="celebrationBannerTitle" className={bem('title')}>
            <Title size="h1" text={title} />
          </div>

          {isBlRewardsFlow && rewards && <CelebrationBannerRewards {...props} />}
          {!isBlRewardsFlow && (
            <>
              {!bottomTextPasses && (
                <div className={bem('emails')}>
                  <CelebrationBannerEmails {...props} />
                </div>
              )}

              {!bottomTextPasses && !isKiosk && <div className={bem('buttons')}>{Buttons}</div>}
              {!bottomTextPasses && !isBlRewardsFlow && (
                <Text
                  id="celebrationBannerBottomText"
                  text={bottomText}
                  className={cx(bem('bottom'), 'hide-on-login')}
                />
              )}
              {!!bottomTextPasses && !isBlRewardsFlow && <Text text={bottomTextPasses} className={cx(bem('bottom'))} />}
              {!!bottomTextPasses && <div className={bem('buttons')}>{Buttons}</div>}
            </>
          )}
        </div>
        {withImgVariant && !isBlRewardsFlow && (
          <div className={bem('image')}>
            <Image {...image} lazyLoaded={isHydrated && isDesktop} />
          </div>
        )}
      </div>
      {!bottomTextPasses && isKiosk && <div className={bem('kiosk-buttons')}>{Buttons}</div>}
    </>
  );
});
