import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';
import { Title } from '@atoms/Title';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';
import { base64Encode } from 'utils/helpers';

import './RevertProvisionalChanges.scss';

const bem = cn('revert-provisional-changes');

export const RevertProvisionalChanges = () => {
  const { accountStore, cfStore } = useStores();
  const { tripDetails } = accountStore;
  const { revertProvisionalChanges } = cfStore.account.tripDetails.navigation;
  const { trip, revertTripProvisionalChanges, passengerSeatingDetails } = tripDetails;

  const onContinue = () => {
    if (!trip || !passengerSeatingDetails) return;

    const primaryPassenger = passengerSeatingDetails[0].passenger;

    if (!primaryPassenger) return;

    const data = {
      rn: trip?.referenceNumber,
      fn: primaryPassenger.firstName,
      ln: primaryPassenger.lastName,
      type: 'extras',
    };

    const encodedData = base64Encode(JSON.stringify(data));

    window.location.href = `/booking/rti?data=${encodedData}`;
  };

  const onRevert = () => {
    if (!trip) return;

    revertTripProvisionalChanges(trip?.referenceNumber);
  };

  return (
    <Modal variant="mini">
      <div className={bem()}>
        <Title text={revertProvisionalChanges.titleLabel} size="h3" />
        <Text text={revertProvisionalChanges.textLabel} />
        <div className={bem('buttons', { twoButtons: !!revertProvisionalChanges.continueModificationButtonLabel })}>
          {revertProvisionalChanges.continueModificationButtonLabel && (
            <Button label={revertProvisionalChanges.continueModificationButtonLabel} onClick={onContinue} />
          )}
          <Button label={revertProvisionalChanges.discardChangesButtonLabel} onClick={onRevert} />
        </div>
      </div>
    </Modal>
  );
};
