import { Formik } from 'formik';
import React, { useMemo } from 'react';

import { Column, Grid } from '@atoms/Grid';
import Image from '@atoms/Image/Image';
import Title from '@atoms/Title/Title';
import { Footer } from '@organisms/TrainPasses/TrainPassesCard/Footer/Footer';
import { FromTo } from '@organisms/TrainPasses/TrainPassesCard/FromTo/FromTo';
import { TrainPassesOptions } from '@organisms/TrainPasses/TrainPassesCard/TrainPassesOptions/TrainPassesOptions';
import { useTrainPassLogic } from '@organisms/TrainPasses/useTrainPassLogic';

import { cn } from 'utils/classNames';
import { color_base_black } from 'utils/colors';
import { base64Encode } from 'utils/helpers';

import './TrainPassesCard.scss';
import TrainPassesCategories from './TrainPassesCategories/TrainPassesCategories';
import TrainPassesSummary from './TrainPassesSummary/TrainPassesSummary';
import { TrainPassesCardProps, TrainPassUrlParams } from './types';

const bem = cn('train-passes-card');

const TrainPassesCard = (props: TrainPassesCardProps) => {
  const { colors, image, stationAdaptions, i18n, passesConfigurations } = props;

  const trainPassLogic = useTrainPassLogic();
  const { step, total, discountedTotal, initialFormValues } = trainPassLogic;
  const { stations, categories, options, parsedMobileUrlData } = trainPassLogic;
  const { getTrainPassImage } = trainPassLogic;

  const vars: any = useMemo(
    () => (colors?.background ? { '--background-color': colors.background?.inactive } : {}),
    [colors]
  );

  const handleSubmit = values => {
    if (!stations.length || !categories.length || !options.length) return;

    const fromStation = stations.find(station => station.name === values.travelFrom);
    const toStation = stations.find(station => station.name === values.travelTo);
    let option: any = options.find(option => option.name === values.option);
    const originalOptionName = option.name;
    if (values.autoRenew && option?.autoRenewOption) option = option.autoRenewOption;

    if (!fromStation || !toStation || !option) return;

    const trainPassData: TrainPassUrlParams = {
      tpOriginID: fromStation.id,
      tpOriginName: fromStation.name,
      tpDestinationID: toStation.id,
      tpDestinationName: toStation.name,
      tpOriginalOptionName: originalOptionName,
      tpProductName: option.name,
      tpTariffCode: option.tariffCode,
      category: values.category,
      tpPrice: total.toFixed(2),
      totalPrice: total.toFixed(2),
      totalPriceDiscounted: discountedTotal ? discountedTotal.toFixed(2) : total.toFixed(2),
      autoRenew: values.autoRenew.toString(),
      jwt: parsedMobileUrlData?.jwt,
      mobileEmail: parsedMobileUrlData?.mobileEmail,
      mobileCallbackURL: parsedMobileUrlData?.mobileCallbackURL,
    };

    if (i18n.primaryButton.link && i18n.primaryButton.link.url) {
      const base64urlParams = base64Encode(JSON.stringify(trainPassData));
      const checkoutLink = new URL(i18n.primaryButton.link.url);
      checkoutLink.searchParams.append('data', base64urlParams);
      window.open(checkoutLink, i18n.primaryButton.link.target ? i18n.primaryButton.link.target : '_self');
    }
  };

  return (
    <Grid className={bem('grid-container')} style={vars}>
      <Column cols={12} mq={{ desktop: { cols: 10, offset: 1 } }}>
        <Formik onSubmit={handleSubmit} validateOnBlur={false} initialValues={initialFormValues} enableReinitialize>
          {({ values, setFieldValue }) => (
            <div className={bem()}>
              <div className={bem('image')}>
                {getTrainPassImage(values, passesConfigurations, image)?.src && (
                  <Image {...getTrainPassImage(values, passesConfigurations, image)} lazy={false} lazyLoaded={false} />
                )}
              </div>
              <div className={bem('content-wrapper')} style={vars}>
                <div className={bem('content')}>
                  <div className={bem('header')}>
                    <div className={bem('title')}>
                      <Title text={i18n.title} size="h1" color={color_base_black} />
                    </div>
                  </div>
                  <form className={bem('form')}>
                    {step == 0 && (
                      <div className={bem('subtitle')}>
                        <Title
                          className="subtitle-text"
                          size="h2"
                          text={`${i18n?.startingPriceSubtitle}`}
                          color={color_base_black}
                        />
                      </div>
                    )}
                    {step >= 1 && step < 10 && (
                      <FromTo i18n={i18n} stationAdaptions={stationAdaptions} useTrainPassLogic={trainPassLogic} />
                    )}
                    {step >= 2 && step < 10 && <TrainPassesCategories i18n={i18n} useTrainPassLogic={trainPassLogic} />}
                    {step >= 3 && step < 10 && (
                      <TrainPassesOptions
                        i18n={i18n}
                        useTrainPassLogic={trainPassLogic}
                        passesConfigurations={passesConfigurations}
                      />
                    )}
                    {step >= 10 && (
                      <TrainPassesSummary
                        i18n={i18n}
                        useTrainPassLogic={trainPassLogic}
                        passesConfigurations={passesConfigurations}
                      />
                    )}
                    <Footer i18n={i18n} useTrainPassLogic={trainPassLogic} setFieldValue={setFieldValue} />
                  </form>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Column>
    </Grid>
  );
};

export default TrainPassesCard;
