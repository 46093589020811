import React from 'react';

import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import { EditPassModal } from '../EditPassModal';
import './EditPassModalDesktop.scss';

const bem = cn('wallet-edit-pass-modal-desktop');

export const EditPassModalDesktop = ({ useEditPass }) => {
  const { hideModal } = useEditPass;

  return (
    <Modal onClose={hideModal} variant="mini">
      <EditPassModal useEditPass={useEditPass} bem={bem} />
    </Modal>
  );
};
