import React, { useEffect, useId, useRef } from 'react';

import { cn, cx } from 'utils/classNames';

import './WidgetInput.scss';
import { WidgetInputProps } from './types';

const bem = cn('modify-trip-widget-input');

export const WidgetInput = (props: WidgetInputProps) => {
  const generatedId = useId();
  const { isInvalid, errorMessage, placeholder, label, id = `input-${generatedId}` } = props;
  const { onInputChange, onInputClick, onInputKeyDown, focus } = props;
  const { value, disabled, readOnly, active, name, position = '' } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputClick = e => {
    if (onInputClick) onInputClick(e, name);
  };

  const handleInputKeyDown = e => {
    if (onInputKeyDown) onInputKeyDown(e, name);
  };

  const handleInputChange = e => {
    if (onInputChange) onInputChange(e);
  };

  useEffect(() => {
    if (focus) inputRef?.current?.focus();
  }, [focus]);

  return (
    <>
      <div className={bem({ position, disabled, 'has-error': !!isInvalid, active })}>
        <label className={bem('label')} htmlFor={id}>
          {label}
        </label>
        <input
          ref={inputRef}
          disabled={disabled}
          autoComplete="off"
          className={bem('input')}
          id={id}
          placeholder={placeholder}
          onChange={handleInputChange}
          onClick={handleInputClick}
          onKeyDown={handleInputKeyDown}
          value={value}
          readOnly={readOnly}
        />
      </div>
      {isInvalid && errorMessage && <span className={cx(bem('error'))}>{errorMessage}</span>}
    </>
  );
};
