import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { MobileSelectProps } from '../types';
import './MobileSelect.scss';

const bem = cn('coach-select-mobile');

export const MobileSelect = observer((props: MobileSelectProps) => {
  const { name, multiple, disabled, required, handleKeyDown } = props;
  const { optionsData, handleChangeNativeSelect, handleOnClick } = props;

  const { cfStore } = useStores();

  const {
    rti: { seats: seatsCF },
  } = cfStore;

  return (
    <select
      id="coach-select"
      multiple={multiple}
      className={bem()}
      onChange={handleChangeNativeSelect}
      onKeyDown={handleKeyDown}
      onClick={handleOnClick}
      name={name}
      disabled={disabled}
      required={required}
      aria-label="Choose a Coach"
    >
      {optionsData?.map((item, itemIndex) => {
        const { label, value, disabled } = item;
        const capitalizeLabel = `${label?.charAt(0)?.toUpperCase()}${label?.slice(1)}`;

        return (
          <option key={`${label}-${itemIndex}`} value={value} disabled={disabled}>
            {`${seatsCF.coachTextLabel} ${value} | ${capitalizeLabel}`}
          </option>
        );
      })}
    </select>
  );
});
