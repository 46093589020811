import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { RootStore } from 'stores';

export default class PromoStore {
  private readonly rootStore: RootStore;

  inputValue = '';
  serverStatus = '';
  showInput = false;
  appliedPromoCode = '';

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: 'AuthStore',
      properties: ['inputValue', 'serverStatus', 'showInput'],
      stringify: true,
      storage: window.localStorage,
    });

    this.rootStore = rootStore;
  }

  setInputValue(val) {
    this.inputValue = val;
  }

  setServerStatus(val) {
    this.serverStatus = val;
  }

  setAppliedPromoCode(val) {
    this.appliedPromoCode = val;
  }

  setShowInput(val) {
    this.showInput = val;
  }

  resetPromoData() {
    this.setInputValue('');
    this.setServerStatus('');
    this.setShowInput(false);
  }
}
