import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { useStores } from 'stores';

import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { TIME_FORMAT } from 'utils/constants';

import { PromoFlag } from '../../../PromoFlag/PromoFlag';
import { TagProps } from '../../types';
import ImageFlag from '../ImageFlag/ImageFlag';
import TrainFlag from '../TrainFlag/TrainFlag';
import './TimeItem.scss';
import { TimeItemProps } from './types';

const bem = cn('time-item');

const convertDuration = duration => {
  const momentDuration = moment.utc(moment.duration(duration * 60, 'seconds').asMilliseconds());

  const hours = momentDuration.hours();
  const minutes = momentDuration.minutes();
  const hoursMessage = hours > 0 ? `${hours}h` : '';

  return `${hoursMessage} ${minutes}min`;
};

export const TimeItem = observer((props: TimeItemProps) => {
  const { arrivalTime, departureTime, origin, destination, duration, numberOfStops } = props;
  const { tags = [], footerTags = [], smartProduct, premiumProduct, loading, isMobile, serviceTypeTag } = props;

  const { cfStore, bookingStore } = useStores();
  const { trainSelection } = cfStore;
  const { premiumOnly, activeDate } = bookingStore.tickets;
  const { routeItem, faresCompare } = trainSelection;

  const smartTitle = faresCompare.options?.find(el => el.variant === 'SMART_STANDARD')?.title;
  const premiumTitle = faresCompare.options?.find(el => el.variant === 'PREMIUM_STANDARD')?.title;

  const durationValue = useMemo(() => convertDuration(duration), [duration]);

  const footerTagsByType = useMemo(() => {
    const imageTags = footerTags.filter(el => el.type === 'image');
    const markdownTags = footerTags.filter(el => el.type !== 'image');

    return {
      imageTags,
      markdownTags,
    };
  }, [footerTags]);

  const directMessage = useMemo(
    () =>
      numberOfStops === 0
        ? routeItem.direct
        : numberOfStops && numberOfStops > 1
        ? `${numberOfStops} ${routeItem.stops}`
        : `${numberOfStops} ${routeItem.stop}`,
    [numberOfStops, routeItem.direct, routeItem.stop, routeItem.stops]
  );

  const productClass = useMemo(() => {
    const productToShow = isMobile && premiumOnly ? premiumProduct : smartProduct;
    const tags = productToShow?.tags || [];

    const smartSeatsNumber = smartProduct?.numberOfSeatsLeftAtPrice;
    const premiumSeatsNumber = premiumProduct?.numberOfSeatsLeftAtPrice;

    let seatsMessage = smartSeatsNumber && smartSeatsNumber > 0 ? `${smartSeatsNumber} ${smartTitle}` : '';

    seatsMessage += seatsMessage
      ? premiumSeatsNumber && premiumSeatsNumber > 0
        ? `, ${premiumSeatsNumber} ${premiumTitle} ${routeItem.atPrice}`
        : ` ${routeItem.atPrice}`
      : '';

    return {
      product: smartProduct ? smartProduct : premiumProduct,
      tags,
      seatsMessage,
    };
  }, [isMobile, premiumOnly, premiumProduct, smartProduct, smartTitle, premiumTitle, routeItem.atPrice]);

  return (
    <div className={bem()}>
      <ReactPlaceholder
        type="text"
        rows={2}
        ready={!loading}
        widths={[70, 20]}
        className={bem('placeholder')}
        showLoadingAnimation
      >
        <div className={bem('header')}>
          {tags.map(tag => {
            const type = tag?.type?.replace(/ +/g, '');

            return (
              <div key={tag?.description} className={bem('header-tag')}>
                <TrainFlag text={tag?.description} type={type} />
              </div>
            );
          })}
        </div>
        <div className={bem('content')}>
          <div className={bem('details')}>
            <div className={bem('origin')}>
              <h3 className={bem('hour')}>
                {moment(departureTime)
                  .tz(origin?.timeZone || '')
                  ?.format(TIME_FORMAT)}
              </h3>
              {moment(departureTime)
                .tz(origin?.timeZone || '')
                ?.format('YYYY-MM-DD') !== activeDate && (
                <div className={bem('midnight-train')}>
                  {moment(departureTime)
                    .tz(origin?.timeZone || '')
                    ?.format('ddd, D MMM')}
                </div>
              )}
              <h4 className={bem('station')}>{origin?.name}</h4>
            </div>
            <div className={bem('duration-wrapper')}>
              <div className={bem('duration-border')} />
            </div>

            <div className={bem('destination')}>
              <h3 className={bem('hour')}>
                {moment(arrivalTime)
                  .tz(destination?.timeZone || '')
                  ?.format(TIME_FORMAT)}
              </h3>
              {moment(arrivalTime)
                .tz(destination?.timeZone || '')
                ?.format('YYYY-MM-DD') !== activeDate && (
                <div className={bem('midnight-train')}>
                  {moment(arrivalTime)
                    .tz(origin?.timeZone || '')
                    ?.format('ddd, D MMM')}
                </div>
              )}
              <div className={bem('station')}> {destination?.name}</div>
            </div>
          </div>
          <div className={bem('data')}>
            <div className={bem('duration')}>
              <div className={bem('duration-value')}> {durationValue} </div>
              <div className={bem('duration-separator')} />
              <div className={bem('duration-type')}>{directMessage}</div>
            </div>
            {!!productClass && <div className={bem('seats')}>{productClass?.seatsMessage}</div>}
          </div>
        </div>

        {!!productClass.tags && (
          <div className={bem('class-tags')}>
            {productClass.tags?.map((tag: TagProps, index: number) => {
              const key = `class-item-tag-${tag.type}-${index}`;
              const type = tag?.type?.replace(/ +/g, '');

              return <PromoFlag key={key} text={tag?.description} type={type} />;
            })}
          </div>
        )}
        <div className={bem('footer')}>
          <div className={bem('footer-left')}>
            {footerTagsByType?.imageTags?.map(tag => (
              <div key={tag.description} className={bem('footer-tag')}>
                <ImageFlag {...tag} />
              </div>
            ))}
          </div>
          <div className={bem('footer-right')}>
            {footerTagsByType?.markdownTags?.map((tag, index) => {
              const key = `${tag.type}-${index}`;

              return <Text key={key} text={tag.description} className={bem('footer-markdown')} />;
            })}
          </div>

          {serviceTypeTag && (
            <div className={bem('event-train')}>
              <img
                src={serviceTypeTag.imageUrl}
                alt={serviceTypeTag.description}
                className={bem('event-train-image')}
              />
              <Text text={serviceTypeTag.description} className={bem('event-train-description')} />
            </div>
          )}
        </div>
      </ReactPlaceholder>
    </div>
  );
});
