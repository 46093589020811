import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import React, { useEffect, useMemo, useRef } from 'react';
import { useStores } from 'stores';

import { EditDeatilsFormProps } from '@account/routes/Settings/components/EditDetailsForm/types';
import { Button } from '@atoms/Button';
import CheckboxField from '@atoms/Checkbox/CheckboxField';
import { InputField } from '@atoms/Input';
import { InlineDateInputField } from '@molecules/InlineDateInput';
import { PhoneNumber } from '@molecules/PhoneNumber';

import { cn, cx } from 'utils/classNames';
import { isLoyaltyEnabled } from 'utils/metas';

import { dateConfig } from '../../constants';
import { getValidationSchema } from '../../validator';
import './EditDetailsForm.scss';

const bem = cn('edit-details-form');
const bemModal = cn('overlay-container');

export const EditDetailsForm = observer((props: EditDeatilsFormProps) => {
  const { onCancel } = props;

  const { accountStore, cfStore } = useStores();
  const { settings: i18n } = cfStore.account;
  const { isLoading, userProfile, updateUserProfile, setEditDialogClose } = accountStore.profile;
  const ref = useRef<HTMLDivElement>(null);

  const loyaltyEnabled = isLoyaltyEnabled();

  const initialFormValues = useMemo(() => {
    const initialValues = {
      id: userProfile?.brightlineId || null,
      firstName: userProfile?.firstName || '',
      lastName: userProfile?.lastName || '',
      dateOfBirth: userProfile?.dateOfBirth ? moment(userProfile?.dateOfBirth).format(dateConfig.dateFormat) : '',
      email: userProfile?.email || '',
      phoneNumber: {
        countryCode: userProfile?.phoneNumber?.countryCode || '+1',
        number: userProfile?.phoneNumber?.number || '',
      },
      wheelchairAccommodation: userProfile?.wheelchairAccommodation === 'accessible_space' || false,
    };

    if (loyaltyEnabled)
      return {
        ...initialValues,
        zipCode: userProfile?.zipCode || '',
      };

    return initialValues;
  }, [
    userProfile?.brightlineId,
    userProfile?.firstName,
    userProfile?.lastName,
    userProfile?.dateOfBirth,
    userProfile?.email,
    userProfile?.zipCode,
    userProfile?.phoneNumber.countryCode,
    userProfile?.phoneNumber.number,
    userProfile?.wheelchairAccommodation,
    loyaltyEnabled,
  ]);

  const handleSubmitForm = values => {
    const valuesWithTravelAgent = {
      ...values,
      birthDate: values?.dateOfBirth,
      ...(userProfile?.travelAgentCode !== '' && { travelAgentCode: userProfile?.travelAgentCode }),
    };
    updateUserProfile(valuesWithTravelAgent);
    setEditDialogClose();
  };

  useEffect(() => {
    const firstInput = document.querySelector('input[name="firstName"]') as HTMLInputElement;
    if (firstInput) firstInput.focus();
  }, []);

  return (
    <div ref={ref}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={getValidationSchema(i18n)}
        onSubmit={handleSubmitForm}
        enableReinitialize
      >
        {() => (
          <Form noValidate>
            <p className={bem('required-field-label')}>{i18n.requiredFieldLabel}</p>
            <div className={bem('row')}>
              <div className={cx(bem('column'), bem('field'))}>
                <InputField
                  errorVariant="inline"
                  name="firstName"
                  label={i18n.firstNameLabel}
                  ariaLabel={`Remove ${i18n.firstNameLabel}`}
                  showAsterisk
                  displayTrailingIcon
                  required
                />
              </div>
              <div className={cx(bem('column'), bem('field'))}>
                <InputField
                  errorVariant="inline"
                  name="lastName"
                  label={i18n.lastNameLabel}
                  ariaLabel={`Remove ${i18n.lastNameLabel}`}
                  showAsterisk
                  displayTrailingIcon
                  required
                />
              </div>
            </div>

            <div className={bem('row')}>
              <div className={bem('field')}>
                <InlineDateInputField
                  dateFormat={dateConfig.dateFormat}
                  label={i18n.dateOfBirthLabel}
                  placeholder={dateConfig.dateFormat}
                  name="dateOfBirth"
                  errorVariant="inline"
                  displayTrailingIcon
                  showAsterisk
                  maxCharactersDisabled
                  required
                />
              </div>
            </div>

            <div className={bem('row')}>
              <div className={bem('field')}>
                <InputField errorVariant="inline" name="email" label={i18n.emailLabel} showAsterisk disabled required />
              </div>
            </div>

            {loyaltyEnabled && (
              <div className={bem('row')}>
                <div className={bem('field')}>
                  <InputField
                    errorVariant="inline"
                    name="zipCode"
                    label={i18n.zipCodeLabel}
                    ariaLabel={`Remove ${i18n.zipCodeLabel}`}
                    showAsterisk
                    required
                    displayTrailingIcon
                  />
                </div>
              </div>
            )}

            <div className={bem('row')}>
              <div className={bem('field')}>
                <PhoneNumber
                  name="phoneNumber"
                  label={i18n.phoneNumberLabel}
                  searchCountryCodePlaceholder={i18n.searchCountryCodePlaceholder}
                  ariaLabel={`Remove ${i18n.phoneNumberLabel}`}
                  showAsterisk
                  displayTrailingIcon
                  required
                />
              </div>
            </div>

            <div className={bem('row')}>
              <CheckboxField
                name="wheelchairAccommodation"
                label={i18n.wheelchairAccessibilityLabel}
                className={bem('field', { 'no-bottom': true })}
              />
            </div>

            <div className={bemModal('buttons')}>
              <Button color="charcoal" variant="secondary" label={i18n.cancelCtaLabel} onClick={onCancel} />
              <Button color="charcoal" label={i18n.saveCtaLabel} type="submit" loading={isLoading} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
});
