import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { getManageTripsFormValidationSchema } from '@organisms/BookingSearch/validator';

import { cn } from 'utils/classNames';

import './ManageTripsForm.scss';
import ManageAction from './components/ManageAction/ManageAction';
import { Modal, useModalLogic } from './components/Modal';
import TextInputField from './components/TextInputField/TextInputField';
import { DEFAULT_I18N, DEFAULT_INITIAL_VALUES, DEPRECATED_INITIAL_VALUES } from './constants';
import { ManageTripsFormProps } from './types';

const bem = cn('manage-trips-form');

export const ManageTripsFormMobile = observer((props: ManageTripsFormProps) => {
  const { initialFormValues = DEFAULT_INITIAL_VALUES, i18n = DEFAULT_I18N } = props;
  const { role, ariaLabelledBy, id } = props;
  const { deprecatedManageTripsPage } = props;

  const { manageTripsFormStore } = useStores();
  const { isPanelVisible, showPanel } = manageTripsFormStore;

  const modalLogicProps = useModalLogic(props);
  const { handleSubmit, doHandleModalClose } = modalLogicProps;

  const sch = getManageTripsFormValidationSchema(i18n, deprecatedManageTripsPage);

  const defaultValues =
    initialFormValues || (deprecatedManageTripsPage ? DEPRECATED_INITIAL_VALUES : DEFAULT_INITIAL_VALUES);

  return (
    <Formik onSubmit={handleSubmit} initialValues={defaultValues} validationSchema={sch} enableReinitialize>
      {({ setValues, setTouched, isValid, dirty, handleSubmit: formikSubmit, submitForm }) => (
        <form onSubmit={formikSubmit} className={bem('first-row')} role={role} aria-labelledby={ariaLabelledBy} id={id}>
          {!isPanelVisible ? (
            <TextInputField
              name="referenceNumber"
              label={i18n.ticketNumberLabel}
              placeholder={i18n.ticketNumberPlaceholderLabel}
              onInputClick={showPanel}
              allUppercase
              readOnly
            />
          ) : (
            <Modal title={i18n.title} onClose={doHandleModalClose(setValues, setTouched, defaultValues)}>
              <div className={bem('content')}>
                <TextInputField
                  name="referenceNumber"
                  label={i18n.ticketNumberLabel}
                  placeholder={i18n.ticketNumberPlaceholderLabel}
                  allUppercase
                  isRequired
                />
                {!deprecatedManageTripsPage && (
                  <TextInputField
                    name="firstName"
                    label={i18n.firstNameLabel}
                    placeholder={i18n.firstNamePlaceholderLabel}
                    isRequired
                  />
                )}
                <TextInputField
                  name="lastName"
                  label={i18n.lastNameLabel}
                  placeholder={i18n.lastNamePlaceholderLabel}
                  isRequired
                />
                <ManageAction i18n={i18n} deprecatedManageTripsPage={deprecatedManageTripsPage} />
              </div>
              {dirty && isValid && (
                <div className={bem('actions')}>
                  <Button label={i18n.submitFormLabel} type="submit" color="yellow" width="full" onClick={submitForm} />
                </div>
              )}
            </Modal>
          )}
        </form>
      )}
    </Formik>
  );
});
