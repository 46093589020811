import React from 'react';

import { cn } from 'utils/classNames';

import './Coach.scss';
import { CoachProps } from './types';

const bem = cn('trip-coach');

export const Coach = (props: CoachProps) => {
  const { coachNumber, classType, text } = props;

  return (
    <div className={bem({ type: classType })}>
      {text} {coachNumber}
    </div>
  );
};
