import React, { useMemo } from 'react';

import { Column, Grid } from '@atoms/Grid';

import { cn } from 'utils/classNames';

import './TextAndMedia.scss';
import { Content } from './components/Content';
import { Media } from './components/Media';
import { defaultAspectRatio, defaultVariant } from './constants';
import { TextAndMediaProps } from './types';
import { useColumnsLogic } from './useColumnsLogic';

const bem = cn('text-and-media');

export const TextAndMedia = (props: TextAndMediaProps) => {
  const { colors, variant = defaultVariant, textAlignment, removeDefaultSpacings = false, imageTextAlignment } = props;
  const { imageAspectRatio = defaultAspectRatio } = props;

  const { alignment, columns } = useColumnsLogic(props);

  const style: any = useMemo(
    () => ({
      '--text-and-media-background-color': colors?.background?.inactive,
      '--text-and-media-eyebrow-color': colors?.eyebrow,
      '--text-and-media-title-color': colors?.title,
      '--text-and-media-description-color': colors?.description,
      '--text-and-media-image-title-color': colors?.imageTitle,
      '--text-and-media-image-description-color': colors?.imageDescription,
      '--text-and-media-image-icon-color': colors?.imageIcon,
      '--text-and-media-player-icon-color': colors?.playerIcon?.inactive,
      '--text-and-media-player-icon-hover-color': colors?.playerIcon?.hover,
      '--text-and-media-aspect-ratio': imageAspectRatio,
    }),
    [colors, imageAspectRatio]
  );

  return (
    <div
      className={bem({ alignment, variant, textAlignment, removeDefaultSpacings, imageTextAlignment })}
      style={style}
    >
      <Grid>
        <Column {...columns[0]}>{alignment === 'left' ? <Media {...props} /> : <Content {...props} />}</Column>
        <Column {...columns[1]}>{alignment === 'right' ? <Media {...props} /> : <Content {...props} />}</Column>
      </Grid>
    </div>
  );
};
