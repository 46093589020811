import { FONT_VARIANTS } from 'utils/constants';
import { FontSizesType, FontVariantsType } from 'utils/types';

export const DEFAULT_SIZE: FontSizesType = 'h1';
export const DEFAULT_FONT_VARIANT: FontVariantsType = 'h1';

export const SIZES = [DEFAULT_SIZE, 'h2', 'h3', 'h4', 'h5', 'h6'];

export const defaultTitleArgs = {
  size: DEFAULT_SIZE,
  fontVariant: DEFAULT_FONT_VARIANT,
  hideExpandAll: false,
  items: [
    {
      id: 'item1',
      title: 'Title Acc 1',
      content:
        'Title Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any how',
    },
    {
      id: 'item2',
      title:
        'Title Acc 2Title Acc 2Title Acc 2Title Acc 2Title Acc 2Title Acc 2Title Acc 2Title Acc 2Title Acc 2Title Acc 2Title Acc 2Title Acc 2',
      content:
        'Title Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any how',
    },
    {
      id: 'item3',
      title: 'Title Acc 3',
      content:
        'Title Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any how',
    },
    {
      id: 'item4',
      title: 'Title Acc 4',
      content:
        'Title Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any how',
    },
    {
      id: 'item5',
      title: 'Title Acc 5',
      content:
        'Title Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any how',
    },
    {
      id: 'item6',
      title: 'Title Acc 6',
      content:
        'Title Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any how',
    },
    {
      id: 'item7',
      title: 'Title Acc 7',
      content:
        'Title Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any howTitle Acc 1 much text here, any how',
    },
  ],
};

export const defaultTitleArgTypes = {
  size: { options: SIZES, control: { type: 'radio' } },
  fontVariant: { options: FONT_VARIANTS, control: { type: 'radio' } },
};
