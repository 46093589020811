import { observer } from 'mobx-react-lite';
import React from 'react';

import Image from '@atoms/Image/Image';
import Radio from '@atoms/Radio/Radio';

import { cn } from 'utils/classNames';

import { VoucherProps } from '../../types';
import './Voucher.scss';

const bem = cn('voucher');

export const Voucher = observer((props: VoucherProps) => {
  const { type, title, description, note, kicker, icon } = props;
  const { isSelected, numOccurrences, travelPassRidesReturnedLabel } = props;

  return (
    <div className={bem('refund-methods')}>
      <button className={bem({ active: isSelected })}>
        <div className={bem('image')}>
          <Image {...icon} />
        </div>
        <div className={bem('data')}>
          <div className={bem('info')}>
            <div className={bem('details')}>
              <div className={bem('refund-name')}>
                <span>{title}</span>
                {kicker && <span>{kicker}</span>}
              </div>
            </div>
            {type === 'voucher' && <div className={bem('remain-items')}>{description}</div>}
            <div className={bem('note')}>
              {type === 'voucher' && note}
              {type !== 'voucher' && `${numOccurrences} ${travelPassRidesReturnedLabel}`}
            </div>
          </div>
          <div className={bem('radio-data')}>
            <Radio name={type} label="" checked={isSelected} id={type} disabled />
          </div>
        </div>
      </button>
    </div>
  );
});
