import React from 'react';

import { Button } from '@atoms/Button';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/TitleWithStyleHOC';
import { TextAndMediaProps } from '@organisms/TextAndMedia/types';

import { cn } from 'utils/classNames';

const bem = cn('text-and-media__content');

export const Content = (props: TextAndMediaProps) => {
  const { eyebrow, title, description, primaryButton, secondaryButton } = props;

  return (
    <div className={bem()}>
      {eyebrow && (
        <div className={bem('eyebrow')}>
          <h6>{eyebrow}</h6>
        </div>
      )}

      {title && title.text && <Title {...title} />}

      {description && description.text && (
        <div className={bem('description')}>
          <Text {...description} />
        </div>
      )}

      <div className={bem('buttons')}>
        {primaryButton && primaryButton.link && <Button {...primaryButton} />}
        {secondaryButton && secondaryButton.link && <Button {...secondaryButton} variant="secondary" />}
      </div>
    </div>
  );
};
