import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './ModalFooter.scss';
import { ModalFooterProps } from './types';

const bem = cn('extras-modal-footer-buttons');

export const ModalFooter = observer((props: ModalFooterProps) => {
  const { cfLabels, extrasModalLogic, isUpdateButton, onCloseRef } = props;

  const { bookingStore } = useStores();
  const { loading } = bookingStore.extras;

  const { total, isFormModified, formRef, hideModal } = extrasModalLogic;

  const handleOnClick = () => {
    formRef.current?.handleSubmit();
  };

  const onCloseModal = () => {
    onCloseRef?.current && onCloseRef.current.focus();
    hideModal();
  };

  return (
    <div className={bem()}>
      <div className={bem('footer')}>
        <div className={bem('footer-total')}>
          <Text text={cfLabels.totalLabel} className={bem('footer-total-label')} />
          <Text text={`$${total.toFixed(2)}`} className={bem('footer-total-price')} />
        </div>
        <div className={bem('footer-buttons')}>
          <Button
            label={cfLabels.cancelButtonLabel}
            colors={{
              backgroundOrBorder: color_base_medium_light_grey,
            }}
            onClick={onCloseModal}
            focusColor="red"
          />
          <Button
            label={isUpdateButton ? cfLabels.updateButtonLabel : cfLabels.addToTripButtonLabel}
            color="yellow"
            loading={loading}
            disabled={!isFormModified}
            onClick={handleOnClick}
          />
        </div>
      </div>
    </div>
  );
});
