import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';
import { Link } from 'react-router-dom';
import { useStores } from 'stores';

import { MyAccount } from '@account/components/MyAccount';
import PageLoadingPlaceholders from '@account/components/PageLoadingPlaceholder/PageLoadingPlaceholders';
import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { HeaderCellSort } from '@table-library/react-table-library/sort';
import { Body, Cell, Header, HeaderCell, HeaderRow, Row, Table } from '@table-library/react-table-library/table';

import { cn, cx } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';
import { mediaQueryList } from 'utils/mediaQueries';

import { ErrorPage } from '../ErrorPage';
import './CommissionStatements.scss';
import { useCommissionStatementsLogic } from './useCommissionStatementsLogic';

const bem = cn('account-admin-panel');

const CommissionStatements = observer(() => {
  const { cfStore, accountStore } = useStores();
  const { commissions } = cfStore.account;
  const { commissionsStore, profile } = accountStore;
  const { isLoading: isCommissionLoading, commissionsData } = commissionsStore;
  const { isLoading: isProfileLoading } = profile;
  const { displayBannerPerViewport, bannerTitle, bannerSubtext, bannerSecondSubtext } = commissions;
  const { bannerLink, commissionTableTitle, downloadIcon, offersForYouTitle, commissionTableDisclaimer } = commissions;
  const { emptyTableTitle, emptyTableMessaging, emptyTableCtaLabel, pageNotFoundLabel } = commissions;

  const logic = useCommissionStatementsLogic();
  const { isTravelAgent, bottomExperienceFragmentHTML, bottomSecondExperienceFragmentHTML, theme } = logic;
  const { headerConfig, handleCsvDownload, sort, formatCurrency, handleEmptyTableCtaClicked } = logic;
  const { dummyCommissionsData } = logic;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  return (
    <div className={bem()}>
      {isProfileLoading ? (
        <PageLoadingPlaceholders />
      ) : !isTravelAgent ? (
        <ErrorPage titleLabel={pageNotFoundLabel} />
      ) : (
        <>
          {isMobile && (
            <div className={bem('user-account')}>
              <MyAccount />
            </div>
          )}
          {(bannerTitle || bannerSubtext || bannerSecondSubtext) && (
            <div className={bem('cross-linking', { displayBannerPerViewport })}>
              <div className={bem('cross-linking-left')}>
                {bannerTitle && (
                  <div className={bem('cross-linking-title')}>
                    <Text className={bem('section-title')} text={bannerTitle} />
                  </div>
                )}
                <div className={bem('cross-linking-description')}>
                  {bannerSubtext && <Text className={bem('cross-linking-description-subtext')} text={bannerSubtext} />}
                  {bannerSecondSubtext && (
                    <span className={bem('cross-linking-description-second-text')}>{bannerSecondSubtext}</span>
                  )}
                </div>
              </div>
              {bannerLink && (
                <Link className={bem('right-arrow')} to={bannerLink}>
                  <Icon name="arrow-right" />
                </Link>
              )}
            </div>
          )}
          <div className={bem('table')}>
            <div className={bem('title')}>
              <div>
                <span className={bem('commission-title')}>{commissionTableTitle}</span>
              </div>
              <div>
                <button
                  onClick={handleCsvDownload}
                  className={bem('download', { disabled: !commissionsData?.length })}
                  disabled={!commissionsData?.length}
                >
                  <Icon
                    name={downloadIcon}
                    className={bem('downloadIcon', { disabled: !commissionsData?.length })}
                    size="large"
                  />
                </button>
              </div>
            </div>
            {commissionTableDisclaimer && 
              <div className={bem('table-disclaimer')}>
                <Text className={bem('table-disclaimer-text')} text={commissionTableDisclaimer} />
              </div>
            }
            <br />
            <Table
              data={{ nodes: isCommissionLoading ? dummyCommissionsData : commissionsData }}
              theme={theme}
              layout={{ custom: true, horizontalScroll: true }}
              sort={sort}
            >
              {tableList => (
                <>
                  <Header>
                    <HeaderRow>
                      {headerConfig.map(({ name, sortKey, sortable }, index) =>
                        sortable && commissionsData && commissionsData?.length > 1 ? (
                          <HeaderCellSort key={index} sortKey={sortKey} className={bem('header-cell')}>
                            {name}
                          </HeaderCellSort>
                        ) : (
                          <HeaderCell key={index} className={bem('header-cell')}>
                            {name}
                          </HeaderCell>
                        )
                      )}
                    </HeaderRow>
                  </Header>
                  {isCommissionLoading && (
                    <ReactPlaceholder
                      ready={false}
                      customPlaceholder={
                        <Body>
                          {tableList?.map(item => (
                            <Row key={item.id} item={item}>
                              <Cell className={bem('body-cell')}>
                                <RectShape
                                  color={color_base_medium_light_grey}
                                  style={{ width: 130, height: 24, borderRadius: 4 }}
                                />
                              </Cell>
                              <Cell className={bem('body-cell')}>
                                <RectShape
                                  color={color_base_medium_light_grey}
                                  style={{ width: 80, height: 24, borderRadius: 4 }}
                                />
                              </Cell>
                              <Cell className={bem('body-cell')}>
                                <RectShape
                                  color={color_base_medium_light_grey}
                                  style={{ width: 55, height: 24, borderRadius: 4 }}
                                />
                              </Cell>
                              <Cell className={bem('body-cell')}>
                                <RectShape
                                  color={color_base_medium_light_grey}
                                  style={{ width: 90, height: 24, borderRadius: 4 }}
                                />
                              </Cell>
                              <Cell className={bem('body-cell')}>
                                <RectShape
                                  color={color_base_medium_light_grey}
                                  style={{ width: 50, height: 24, borderRadius: 4 }}
                                />
                              </Cell>
                              <Cell className={bem('body-cell')}>
                                <RectShape
                                  color={color_base_medium_light_grey}
                                  style={{ width: 50, height: 24, borderRadius: 4 }}
                                />
                              </Cell>
                            </Row>
                          ))}
                        </Body>
                      }
                      showLoadingAnimation
                    >
                      <></>
                    </ReactPlaceholder>
                  )}
                  {!isCommissionLoading && commissionsData?.length > 0 && (
                    <Body>
                      {tableList?.map(item => (
                        <Row key={item.id} item={item}>
                          <Cell className={cx(bem('body-cell'), bem('name-cell'))}>{item.name}</Cell>
                          <Cell className={bem('body-cell')}>{item.bookDate}</Cell>
                          <Cell className={cx(bem('body-cell'), bem('confirmation-cell'))}>{item.confirmation}</Cell>
                          <Cell className={bem('body-cell')}>{item.travelDate}</Cell>
                          <Cell className={bem('body-cell')}>$ {formatCurrency(item?.total)}</Cell>
                          <Cell className={bem('body-cell')}>$ {formatCurrency(item?.commission)}</Cell>
                        </Row>
                      ))}
                    </Body>
                  )}
                </>
              )}
            </Table>
            {!isCommissionLoading && !commissionsData?.length && (
              <div className={bem('empty-wrapper')}>
                <Icon name="file" className={bem('empty-icon')} />
                <div className={bem('empty-text')}>
                  <Text text={emptyTableTitle} className={bem('empty-title')} />
                  <Text text={emptyTableMessaging} className={bem('empty-messaging')} />
                </div>
                <Button label={emptyTableCtaLabel} color="charcoal" onClick={handleEmptyTableCtaClicked} />
              </div>
            )}
          </div>
          {offersForYouTitle && (
            <div className={bem('offers-title')}>
              <span>{offersForYouTitle}</span>
            </div>
          )}
          {bottomExperienceFragmentHTML && (
            <div
              dangerouslySetInnerHTML={{ __html: bottomExperienceFragmentHTML }}
              className={bem('bottom-experience-fragment')}
            />
          )}
          {bottomSecondExperienceFragmentHTML && (
            <div
              dangerouslySetInnerHTML={{ __html: bottomSecondExperienceFragmentHTML }}
              className={bem('bottom-experience-fragment')}
            />
          )}
        </>
      )}
    </div>
  );
});

export default CommissionStatements;
