import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { facingDirections } from '@booking/routes/RTI/SeatSelection/constants';

import { cn } from 'utils/classNames';

import './PassengerSeat.scss';
import { PassengerSeatProps } from './types';

const bem = cn('rti-passenger-seat');

export const PassengerSeat = observer((props: PassengerSeatProps) => {
  const { passenger, seat, active, index, passengerId } = props;
  const { updatePristineSeatSelection, isAccountFlow } = props;

  const { facingDirection, number, coach } = seat;
  const { firstName, lastName } = passenger;

  const { bookingStore, cfStore } = useStores();

  const seatsCF = isAccountFlow ? cfStore.account.tripDetails.seats : cfStore.rti.seats;

  const { seats } = bookingStore.rti;
  const { setActivePassenger, passengersSeats, activePassenger, removeSeatSelection } = seats;

  const handlePassengerClick = () => {
    const passengerClicked = passengersSeats[index];

    if (passengerClicked.passengerId === activePassenger?.passengerId) return;
    updatePristineSeatSelection();
    setActivePassenger(passengerClicked);
  };

  const handlePassengerKeyDown = e => {
    switch (e.key) {
      case 'Enter':
        handlePassengerClick();
    }
  };

  const showCancel = useMemo(
    () => passengerId === activePassenger?.passengerId && seat.updated,
    [activePassenger?.passengerId, passengerId, seat?.updated]
  );

  const handleRevertClick = () => {
    removeSeatSelection(passengerId);
  };

  const directionLabel = useMemo(
    () =>
      facingDirection?.toLowerCase() === facingDirections.forwards
        ? seatsCF.forwardFacingSeatLabel
        : seatsCF.backwardFacingSeatLabel,
    [facingDirection, seatsCF.backwardFacingSeatLabel, seatsCF.forwardFacingSeatLabel]
  );

  return (
    <div className={bem()}>
      <div
        className={bem({ active, updated: showCancel })}
        onKeyDown={handlePassengerKeyDown}
        onClick={handlePassengerClick}
        tabIndex={0}
        role="button"
        aria-current={active}
        aria-label={`Passenger ${firstName} ${lastName}, seat number ${number}, seat type ${seatsCF.coachTextLabel} ${coach.coachNumber}, direction ${directionLabel} `}
      >
        <div className={bem('seat')}>
          <div className={bem('seat-number')}>{number}</div>
        </div>
        <div className={bem('details')}>
          <div className={bem('name')}>
            {firstName} {lastName}
          </div>
          <div className={bem('coach')}>
            <div className={bem('coach-data')}>
              {seatsCF.coachTextLabel} {coach.coachNumber}
            </div>
            <div className={bem('seat-direction')}>{directionLabel}</div>
          </div>
        </div>
      </div>
      {showCancel && (
        <button className={bem('revert')} onClick={handleRevertClick} aria-label="remove seat selection">
          <Icon name="close2" className={bem('close')} />
        </button>
      )}
    </div>
  );
});
