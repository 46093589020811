import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Portal } from 'react-portal';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Drawer } from '@molecules/Drawer';

import { cn } from 'utils/classNames';

import { Navigation } from '../Navigation';
import './NavigationDrawer.scss';

const bem = cn('trip-details-navigation-drawer');

export const NavigationDrawer = observer(() => {
  const [isVisible, setVisibility] = useState(false);
  const { accountStore, cfStore } = useStores();
  const { tripDetails } = accountStore;
  const { isLoading } = tripDetails;
  const { navigation } = cfStore.account.tripDetails;

  const onClose = () => {
    setVisibility(false);
  };

  const handleClick = () => {
    setVisibility(true);
  };

  if (isLoading) return null;

  return (
    <>
      <Drawer isVisible={isVisible} onClose={onClose}>
        <Navigation />
      </Drawer>
      <Portal>
        <div className={bem()}>
          <Button label={navigation.modifyTrip} onClick={handleClick} width="full" />
        </div>
      </Portal>
    </>
  );
});
