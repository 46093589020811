import { makeAutoObservable } from 'mobx';
import { RootStore } from 'stores/index';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { isAxiosError } from 'utils/helpers';

import { PaymentTokenConfig } from './types';

export class PaymentTokens {
  isLoading = false;
  hasError = false;
  saveTokenErrorMessage = '';
  paymentTokens: PaymentTokenConfig[] = [];
  private readonly rootStore: RootStore;

  constructor(rootStore?) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  setHasError(hasError) {
    this.hasError = hasError;
  }

  setSaveTokenErrorMessage(saveTokenErrorMessage) {
    this.saveTokenErrorMessage = saveTokenErrorMessage;
  }

  setPaymentTokens(tokens) {
    this.paymentTokens = tokens;
  }

  async fetchData() {
    const { bookingStore } = this.rootStore;
    const { rti } = bookingStore;

    try {
      this.setLoading(true);
      this.setHasError(false);
      rti.clearError();

      const url = `${requestUrls.getRestUrl(requestUrls.account.cards)}.xjson`;
      const response = await api.get(url);
      this.setPaymentTokens(response.data.paymentTokens);

      this.setLoading(false);
    } catch (error) {
      console.error(error);
      this.setHasError(true);
      rti.setError({ stepNr: 4, error });
      this.setLoading(false);
    }
  }

  async saveToken(payload, cb?) {
    try {
      this.setLoading(true);
      const url = `${requestUrls.getRestUrl(requestUrls.account.cards)}.xjson`;
      const response = await api.post(url, payload);

      await this.fetchData();
      this.setLoading(false);
      if (cb) {
        cb(response);

        return;
      }
    } catch (e: any) {
      console.error(e);
      this.setHasError(true);
      this.setLoading(false);

      if (isAxiosError(e) && e?.response?.data?.code === 'payment.error')
        this.setSaveTokenErrorMessage(e?.response?.data?.message);
      else this.setSaveTokenErrorMessage('');
    }
  }

  async deleteToken(payload, cb) {
    try {
      this.setLoading(true);
      const url = `${requestUrls.getRestUrl(requestUrls.account.cards)}.${payload.tokenId}.xjson`;
      await api.delete(url, {});
      await this.fetchData();
      this.setLoading(false);
      if (cb) {
        cb();

        return;
      }
    } catch (e: any) {
      console.error(e);
      this.setHasError(true);
      this.setLoading(false);
    }
  }

  clearData() {
    this.setLoading(false);
    this.setHasError(false);
    this.setPaymentTokens([]);
  }
}
