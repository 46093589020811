import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { rootStore, useStores } from 'stores';

import { Card } from '@account/components/Card';
import { sortCardPassesByType } from '@account/routes/Dashboard/components/DashboardWallet/utils';
import { CreditPassCard, PassCard } from '@account/routes/Wallet/components/PassCards';
import { EmptyPassDesktop } from '@account/routes/Wallet/components/PassCards/components/EmptyPass';
import { Button } from '@atoms/Button';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { isLoyaltyEnabled } from 'utils/metas';

import './DashboardWallet.scss';

const bem = cn('dashboard-wallet');

export const DashboardWallet = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { wallet } = accountStore;
  const { passes, isLoading } = wallet;

  const { account } = cfStore;

  const showPasses = useMemo(() => {
    const passesCopy = JSON.parse(JSON.stringify(passes));

    if (passesCopy.length > 3) return sortCardPassesByType(passesCopy);

    return passesCopy;
  }, [passes]);

  const loyaltyEnabled = isLoyaltyEnabled();

  const navigate = useNavigate();

  const handleClickViewWallet = () => {
    if (account.wallet.link) {
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: account.wallet.pageTitle,
          customLink: account.wallet.link,
          externalLink: '',
          linkType: 'linkClick',
        },
      });
      if (account.wallet.link.startsWith('/account')) navigate(account.wallet.link.split('/account')[1]);
      else window.location.href = account.wallet.link;
    }
  };

  return (
    <Card
      title={account.dashboard.brightlineWallet.pageTitle}
      count={passes.length}
      placeholderVariant="large"
      isLoading={isLoading}
      routeToNavigate={account.wallet.link}
      className={bem('card')}
      isWallet={loyaltyEnabled}
    >
      <>
        <div className={bem('passes')}>
          <div className={bem({ items: showPasses.length })}>
            {showPasses.length ? (
              showPasses?.reverse().map((pass, index) => {
                const { type, station, originStation } = pass;
                const key = `pass-${type}-${index}`;
                if (type === 'credit') return <CreditPassCard key={key} {...pass} />;

                return <PassCard key={key} {...pass} originStation={originStation || station} />;
              })
            ) : (
              <EmptyPassDesktop />
            )}
          </div>
        </div>
        {loyaltyEnabled && (
          <Button
            label={account.dashboard.viewWalletButtonLabel}
            className={bem('action')}
            size="small"
            color="blue"
            variant="secondary"
            role="link"
            onClick={handleClickViewWallet}
          />
        )}
      </>
    </Card>
  );
});
