import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';

import './EmptyUpcomingTripsCard.scss';

const bem = cn('empty-trips-card');

export const EmptyUpcomingTripsCard = observer(() => {
  const { cfStore } = useStores();

  const { account } = cfStore;
  const { trips: i18n } = account;

  return (
    <div className={bem()}>
      <div className={bem('title')}>{i18n.noUpcomingTitleLabel}</div>
      <div className={bem('description')}>{i18n.noUpcomingDescriptionLabel}</div>
      <Button
        label={i18n.noUpcomingTripsButtonLabel}
        link={{
          url: account.trips.noUpcomingTripsButtonLink,
          target: account.trips.openNoUpcomingTripsButtonLinkInNewTab ? '_blank' : '_self',
        }}
        variant="primary"
        className={bem('action')}
      />
    </div>
  );
});
