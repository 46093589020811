import React from 'react';

import { LinkButton } from '@atoms/LinkButton';

import { cn } from 'utils/classNames';

import './SettingEntryWithAction.scss';
import { SettingEntryWithActionProps } from './types';

const bem = cn('setting-entry-with-action');
export const SettingEntryWithAction = (props: SettingEntryWithActionProps) => {
  const { label, value, ctaLabel, ctaColors, onClick } = props;

  if (!label) return null;

  return (
    <div className={bem()}>
      <span className={bem('setting-wrapper')}>
        <span className={bem('setting-label')}>{label}</span>
        <span className={bem('setting-value')}>{value}</span>
      </span>
      <LinkButton
        className={bem('action-button')}
        label={ctaLabel}
        variant="big"
        onClick={onClick}
        colors={ctaColors}
        underline
      />
    </div>
  );
};
