import React from 'react';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import './EditSection.scss';

const bem = cn('edit-section');

export const EditSection = ({ children, onClick }) => (
  <div className={bem()}>
    {children}
    <button className={bem('button')} onClick={onClick} aria-label="edit">
      <Icon name="edit" />
    </button>
  </div>
);
