import { useEffect, useState } from 'react';

import { usePagination } from './usePagination';

export const usePaginationComponent = (pageNumber, total, itemsPerPage, pageCount, siblingCount, onPageChange) => {
  const [interval, setInterval] = useState('');
  const [currentPage, setCurrentPage] = useState(pageNumber || 0);

  const range = usePagination({
    totalCount: total,
    pageSize: itemsPerPage,
    siblingCount: siblingCount,
    currentPage: currentPage,
  });

  const changePage = value => () => {
    if (value >= 1 && value <= pageCount) setCurrentPage(value);
  };

  const onNext = changePage(currentPage + 1);

  const onPrevious = changePage(currentPage - 1);

  useEffect(() => {
    if (onPageChange) onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage + 1;
    const end = Math.min(currentPage * itemsPerPage, total);
    setInterval(`${start}-${end}`);
  }, [itemsPerPage, total, currentPage, pageCount]);

  return {
    interval,
    onPrevious,
    currentPage,
    range,
    changePage,
    onNext,
  };
};
