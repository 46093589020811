import { observer } from 'mobx-react-lite';
import React, { Fragment, useMemo } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Tooltip } from '@atoms/Tooltip';

import { cn } from 'utils/classNames';
import { requestUrls } from 'utils/constants';

import { CostDetailItem } from './CostDetailItem';

const bem = cn('cost-summary-sections');

export const CostSummarySectionItem = observer((props: any) => {
  const { items, name, isCancelation } = props;
  const {
    bookingStore,
    cfStore: { account },
    accountStore,
  } = useStores();
  const { provisionalBooking, setProvisionalBooking } = bookingStore.rti;
  const { cancellationFeeLabel, ssCancellationFeeMessage, smartCancellationFeeMessage, premiumCancellationFeeMessage } =
    account.tripDetails.navigation.cancelTrip;
  const { bookingRules, trip } = accountStore.tripDetails;

  const isOther = name.toLowerCase() === 'other';

  const outBoundCancellationFees = bookingRules?.outBoundRules ? bookingRules?.outBoundRules?.additionalFeesTotal : 0;
  const inBoundCancellationFees = bookingRules?.inBoundRules ? bookingRules?.inBoundRules?.additionalFeesTotal : 0;

  const sectionName = useMemo(() => {
    if (isOther) return name;
    const processedName = name.split('-');
    const origin = processedName[0];
    const destination = processedName[1];

    return {
      origin,
      destination,
    };
  }, [isOther, name]);

  const isOutbound =
    trip?.outboundRoute?.origin?.abbreviation && trip?.outboundRoute?.origin?.abbreviation == sectionName?.origin;
  const cancellationFee = isOutbound ? outBoundCancellationFees.toFixed(2) : inBoundCancellationFees.toFixed(2);

  const description = el =>
    el.productCode == 'SMART_SAVER'
      ? ssCancellationFeeMessage
      : el.productCode == 'SMART'
      ? smartCancellationFeeMessage
      : premiumCancellationFeeMessage;

  const filteredItems = useMemo(
    () =>
      items.filter(
        item =>
          !!item.productCode &&
          !item.productCode.toLowerCase().startsWith('uber') &&
          !item.productCode.toLowerCase().startsWith('mears') &&
          item.productCode.toLowerCase() !== 'parking'
      ),
    [items]
  );

  return (
    <div className={bem('item')}>
      {isOther ? (
        <div className={bem('name')}>{sectionName}</div>
      ) : (
        <div className={bem('from-to')}>
          <span>{sectionName.origin}</span>
          <div className={bem('arrow')}>
            <Icon name="long-arrow-right" />
          </div>
          <span>{sectionName.destination}</span>
        </div>
      )}
      {filteredItems.map(el => (
        <Fragment key={el.productCode}>
          <CostDetailItem
            quantity={el.quantity}
            productName={el.productName}
            price={el.totalOriginalPrice}
            isOther={isOther}
            productCode={el.productCode}
            isPromoCode={el.isPromoCode}
            endpoint={`${requestUrls.getRestUrl(requestUrls.rti.bookingPromoCodes)}.${
              provisionalBooking?.tripDetails?.trip?.referenceNumber
            }.xjson`}
            requestOptions={{
              headers: {
                'Booking-Session-Access-Token': provisionalBooking?.bookingSession?.accessToken,
                'Booking-Session-Refresh-Token': provisionalBooking?.bookingSession?.refreshToken,
              },
            }}
            onSuccess={setProvisionalBooking}
            isCancelation={isCancelation}
            isBlRewards={el.productName.toLowerCase().includes('brightline rewards')}
          />
          {(el.productCode == 'SMART_SAVER' ||
            el.productCode == 'SMART_STANDARD' ||
            el.productCode == 'PREMIUM_STANDARD') &&
            isCancelation && (
              <div className={bem('cancelation-fee')}>
                <div>
                  {cancellationFeeLabel}
                  {description(el) && (
                    <Tooltip
                      text={description(el)}
                      renderTooltipContent={() => (
                        <span className={bem('info-icon')}>
                          <Icon theme="circled" name="info" size="extraSmall" />
                        </span>
                      )}
                    />
                  )}
                </div>
                <div>{cancellationFee !== '0.00' ? `-$${cancellationFee}` : `$${cancellationFee}`}</div>
              </div>
            )}
        </Fragment>
      ))}
    </div>
  );
});
