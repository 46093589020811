import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useStores } from 'stores';

import { TrainStatusPill } from '@account/routes/MyTrips/components/UpcomingTrips/components/TripItem/components/TrainStatusPIll/TrainStatusPill';
import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import FooterDateAndBookingNumber from '@molecules/FooterDateAndBookingNumber/FooterDateAndBookingNumber';

import { cn } from 'utils/classNames';

import { TripCardContentProps } from '../../types';

const bem = cn('upcoming-trips-card');

export const TripCardContet = observer((props: TripCardContentProps) => {
  const { trip, onSeeDetails, onSeeTickets } = props;
  const { referenceNumber, outboundRoute, inboundRoute } = trip;

  // Compare the dates to find the closest
  const diff1 = Math.abs(moment().diff(outboundRoute.departureDateTime));
  const diff2 = Math.abs(moment().diff(inboundRoute?.departureDateTime));

  const trainStatus =
    !inboundRoute || diff1 < diff2 ? trip?.outboundRoute?.trainStatus : trip?.inboundRoute?.trainStatus;

  const {
    cfStore: { account },
  } = useStores();
  const { trips: i18n } = account;
  const isRoundTrip = inboundRoute?.origin?.name;

  return (
    <div key={referenceNumber} className={bem('trip-list-item')}>
      <div className={bem('content-wrapper')}>
        <div className={bem('direction')}>
          <span className={bem('from')}>{outboundRoute.origin.name}</span>
          <Icon
            name={isRoundTrip ? 'arrow-double' : 'arrow-from-to'}
            size="middle"
            className={bem('direction-icon', { type: isRoundTrip ? 'arrow-double' : 'arrow-from-to' })}
          />
          <span className={bem('to')}>{outboundRoute.destination.name}</span>
        </div>
        <FooterDateAndBookingNumber
          referenceNumber={referenceNumber}
          outboundRoute={outboundRoute}
          inboundRoute={inboundRoute}
          variant="upcoming-trips-card"
        />
        <TrainStatusPill status={trainStatus} />
      </div>

      {(i18n.tripDetailsLabel || i18n.seeTicketsLabel) && (
        <div className={bem('actions')}>
          {i18n.seeTicketsLabel && (
            <Button
              label={i18n.seeTicketsLabel}
              className={bem('action')}
              size="small"
              color="blue"
              variant="primary"
              onClick={onSeeTickets(referenceNumber)}
            />
          )}
          {i18n.tripDetailsLabel && (
            <Button
              label={i18n.tripDetailsLabel}
              className={bem('action')}
              size="small"
              color="blue"
              variant="secondary"
              onClick={onSeeDetails(referenceNumber)}
            />
          )}
        </div>
      )}
    </div>
  );
});
