import { cloneDeep, merge } from 'lodash';
import { Moment } from 'moment';
import moment from 'moment-timezone';

import { TripDetailsProps } from '../RTI/types.provisionalBooking';
import { DateCarouselItem, ResponseByDay, SelectedTicket, BookingSession } from './types';
import { Journey } from './types.journey';

const DATE_FORMAT = 'YYYY-MM-DD';

const sortResponse = (a, b) =>
  a.seatProducts.sort((x, y) => (x?.displayedPrice > y?.displayedPrice ? 1 : -1))[0]?.displayedPrice -
  b.seatProducts.sort((x, y) => (x?.displayedPrice > y?.displayedPrice ? 1 : -1))[0]?.displayedPrice;

const getJourneyMinMaxPrice = (journey: Journey, min: boolean, max: boolean) => {
  let ticketValue = min ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;

  //SMART ticket/journey
  if (journey?.defaultSmartProduct?.productId) {
    const foundTicket = journey?.seatProducts.find(
      seatProduct => seatProduct.productCode === journey.defaultSmartProduct.productId
    );
    if (foundTicket && !isNaN(Number(foundTicket.displayedPrice))) ticketValue = Number(foundTicket.displayedPrice);
  }

  //PREMIUM ticket/journey
  if (journey?.defaultPremiumProduct?.productId) {
    const foundTicket = journey?.seatProducts.find(
      seatProduct => seatProduct.productCode === journey.defaultPremiumProduct.productId
    );
    if (foundTicket && !isNaN(Number(foundTicket.displayedPrice)))
      if (min && Number(foundTicket.displayedPrice) < ticketValue) ticketValue = Number(foundTicket.displayedPrice);
      else if (max && Number(foundTicket.displayedPrice) > ticketValue)
        ticketValue = Number(foundTicket.displayedPrice);
  }

  return ticketValue;
};

export const getPreviousFetchedDays = (itemsByDate, startDate, endDate, unavailableDates) => {
  let day = moment(startDate, DATE_FORMAT);

  const foundItems = [...unavailableDates];

  while (day.isSameOrBefore(moment(endDate, DATE_FORMAT), 'day')) {
    if (itemsByDate[day.format(DATE_FORMAT)] && !foundItems.some(el => el.key === day.format(DATE_FORMAT)))
      foundItems.push({
        key: day.format(DATE_FORMAT),
        [day.format(DATE_FORMAT)]: {
          date: itemsByDate[day.format(DATE_FORMAT)],
          dateCarousel: itemsByDate[day.format(DATE_FORMAT)].dateCarousel,
        },
        journeys: itemsByDate[day.format(DATE_FORMAT)]?.journeys,
        dateCarousel: itemsByDate[day.format(DATE_FORMAT)].dateCarousel,
      });
    day = day.clone().add(1, 'd');
  }

  return foundItems;
};

export const createDatesPayload = (dayToProcess, itemsLeftRight, itemsByDate, outboundTicket, modifyTripConfig?) => {
  const DEFAULT_TIME_ZONE = 'America/New_York';

  const startDate = moment(dayToProcess).subtract(itemsLeftRight, 'days');
  const endDate = moment(dayToProcess).add(itemsLeftRight, 'days');

  const days: string[] = [];
  const unavailableDates: string[] = [];

  let day: Moment = moment(startDate, DATE_FORMAT);

  const isModifyTripSingleLeg = modifyTripConfig && (!modifyTripConfig.startDate || !modifyTripConfig.endDate);

  let unavailableBeforeDateValue = outboundTicket
    ? moment(outboundTicket.departureTime).tz(outboundTicket.originTimeZone).format(DATE_FORMAT)
    : moment();

  let unavailableAfterDateValue: string | undefined = undefined;

  if (isModifyTripSingleLeg) {
    const tripDetails = modifyTripConfig.tripDetails as TripDetailsProps;

    const isRoundTrip = tripDetails?.trip?.inboundRoute && tripDetails?.trip?.outboundRoute;

    if (isRoundTrip && !modifyTripConfig.endDate) {
      //MODIFY OUTBOUND trip only
      const tz = tripDetails?.trip?.inboundRoute?.origin?.timeZone || DEFAULT_TIME_ZONE;
      const inboundDepartureDate = moment(tripDetails?.trip?.inboundRoute?.departureDateTime).tz(tz);
      unavailableAfterDateValue = moment.min([endDate, inboundDepartureDate]).format(DATE_FORMAT);
    } else if (isRoundTrip && !modifyTripConfig.startDate) {
      //MODIFY INBOUND trip only
      const tz = tripDetails?.trip?.outboundRoute?.origin?.timeZone || DEFAULT_TIME_ZONE;
      const outboundArrivalDate = moment(tripDetails?.trip?.outboundRoute?.arrivalDateTime).tz(tz);
      unavailableBeforeDateValue = moment.max([startDate, outboundArrivalDate]).format(DATE_FORMAT);
    }
  }

  while (day <= dayToProcess) {
    if (
      (!itemsByDate[day.format(DATE_FORMAT)] && day.isSameOrAfter(unavailableBeforeDateValue)) ||
      (!itemsByDate[day.format(DATE_FORMAT)] && day.isSameOrAfter(moment(), 'day'))
    )
      days.push(day.format(DATE_FORMAT));
    else if (
      day.isBefore(unavailableBeforeDateValue, 'day') ||
      (unavailableAfterDateValue && day.isAfter(unavailableAfterDateValue, 'day'))
    )
      unavailableDates.push(day.format(DATE_FORMAT));

    day = day.add(1, 'day');
  }

  day = dayToProcess.clone().add(1, 'day');

  while (day <= endDate) {
    if (!itemsByDate[day.format(DATE_FORMAT)] && day.isAfter(unavailableBeforeDateValue, 'day'))
      days.push(day.format(DATE_FORMAT));
    else if (
      day.isBefore(unavailableBeforeDateValue, 'day') ||
      (unavailableAfterDateValue && day.isAfter(unavailableAfterDateValue, 'day'))
    )
      unavailableDates.push(day.format(DATE_FORMAT));

    day = day.add(1, 'day');
  }

  return {
    startDate,
    endDate,
    days: days.map(dayItem => moment(dayItem).format(DATE_FORMAT)),
    unavailableDates: unavailableDates.map(dayItem => {
      const formattedDay = moment(dayItem).format(DATE_FORMAT);

      return {
        key: formattedDay,
        [formattedDay]: {},
        dateCarousel: {
          date: formattedDay,
          price: null,
        },
      };
    }),
  };
};

export const mapResponse = (
  itemsByDate,
  response,
  activeDate,
  foundItems: any[] = [],
  outboundTicket: SelectedTicket | null,
  modifyTripConfig?
) => {
  const activeDateFormatted = activeDate.format(DATE_FORMAT);

  const responseValues = fillJourneyResponseGaps(response?.values || {});

  let newValues = {};

  const responseByDay = foundItems;

  if (Object.keys(responseValues).length)
    responseByDay.push(
      ...Object.entries(responseValues).map(([date, values]: [string, { journeys: Journey[], bookingSession: BookingSession }]) => {
        const { journeys, bookingSession } = values;

        const copyJourneys = cloneDeep(journeys);

        let processedJourneys: Journey[];

        const isJourneyAfterOutboundTrip =
          outboundTicket &&
          moment(outboundTicket.departureDateTimeZone, DATE_FORMAT)
            .tz(outboundTicket.originTimeZone)
            .isSameOrBefore(moment(activeDate, DATE_FORMAT).tz(outboundTicket.originTimeZone), 'day');

        const isModifyTripSingleLeg = modifyTripConfig && (!modifyTripConfig.startDate || !modifyTripConfig.endDate);
        const isOriginalTripRoundTrip =
          modifyTripConfig &&
          modifyTripConfig.tripDetails?.trip?.inboundRoute &&
          modifyTripConfig.tripDetails?.trip?.outboundRoute;

        if (isJourneyAfterOutboundTrip)
          processedJourneys = copyJourneys.map(journey => {
            const journeyDepartureTime = moment(journey.departureTime);

            const outboundArrivalTime = moment(outboundTicket.arrivalTime);

            return {
              ...journey,
              isUnavailable: journeyDepartureTime.isBefore(outboundArrivalTime),
            };
          });
        else if (isModifyTripSingleLeg && isOriginalTripRoundTrip) {
          const tripDetails = modifyTripConfig.tripDetails as TripDetailsProps;

          if (!modifyTripConfig.endDate) {
            //MODIFY OUTBOUND trip only
            const inboundDepartureTime = moment(tripDetails?.trip?.inboundRoute?.departureDateTime);

            processedJourneys = copyJourneys.map(journey => {
              const journeyArrivalTime = moment(journey.arrivalTime);

              return {
                ...journey,
                isUnavailable: journeyArrivalTime.isAfter(inboundDepartureTime),
              };
            });
          } else {
            //MODIFY INBOUND trip only
            const outboundArrivalDate = moment(tripDetails?.trip?.outboundRoute?.arrivalDateTime);

            processedJourneys = copyJourneys.map(journey => {
              const journeyDepartureTime = moment(journey.departureTime);

              return {
                ...journey,
                isUnavailable: journeyDepartureTime.isBefore(outboundArrivalDate),
              };
            });
          }
        } else processedJourneys = copyJourneys;

        const sortedJourneys = copyJourneys.filter(el => !!el.seatProducts?.length).sort(sortResponse);

        const dateCarousel = {
          date,
          disabled: processedJourneys.every(el => el.isUnavailable),
          price: sortedJourneys[0]?.seatProducts[0]?.displayedPrice,
          originalPrice:
            sortedJourneys[0]?.seatProducts[0]?.fares && sortedJourneys[0]?.seatProducts[0]?.fares[0]?.originalPrice,
        };

        const responseItem = {
          [date]: {
            journeys: processedJourneys,
            dateCarousel,
            bookingSession
          },
        };
        newValues = {
          ...newValues,
          ...responseItem,
        };

        return {
          [date]: {
            journeys: processedJourneys,
          },
          journeys: processedJourneys,
          dateCarousel,
        };
      })
    );

  const sortedRes = responseByDay.sort((a: ResponseByDay, b: ResponseByDay) =>
    moment(a?.dateCarousel?.date).isAfter(moment(b?.dateCarousel?.date)) ? 1 : -1
  );

  const dateCarouselItems: DateCarouselItem[] = sortedRes.map((el: ResponseByDay) => ({
    date: el?.dateCarousel?.date,
    price: el?.dateCarousel?.price,
    disabled: el?.dateCarousel?.disabled,
    originalPrice: el?.dateCarousel?.originalPrice,
  }));

  const newItemsByDate = merge(itemsByDate, newValues);

  const activeDayResObj = sortedRes.find((el: ResponseByDay) => el?.dateCarousel?.date === activeDateFormatted) || {
    journeys: [],
  };

  return {
    newItemsByDate,
    dateItems: dateCarouselItems,
    journeys: activeDayResObj?.journeys,
  };
};

export const getJourneySortingFunction = (filterValue: number) => {
  if (filterValue === 1 || filterValue > 4) return (a, b) => a?.departureTime.localeCompare(b?.departureTime);

  if (filterValue === 2) return (a, b) => b?.departureTime.localeCompare(a?.departureTime);

  return (a, b) => {
    const valueTicketA = getJourneyMinMaxPrice(a, filterValue === 3, filterValue === 4);
    const valueTicketB = getJourneyMinMaxPrice(b, filterValue === 3, filterValue === 4);

    return filterValue === 3 ? valueTicketA - valueTicketB : valueTicketB - valueTicketA;
  };
};

const fillJourneyResponseGaps = (journeys: any) => {
  const journeysArray: Record<string, any>[] = [];

  //Sorted journeys array -> used for manipulations below
  Object.entries(journeys)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .forEach(journey => {
      journeysArray.push({
        [journey[0]]: journey[1] as any[],
      });
    });

  const filledJourneys: Record<string, any> = {};

  for (let i = 0; i < journeysArray.length - 1; i++) {
    const currentDate = moment(Object.keys(journeysArray[i])[0]);
    const nextDate = moment(Object.keys(journeysArray[i + 1])[0]);

    filledJourneys[Object.keys(journeysArray[i])[0]] = Object.values(journeysArray[i])[0];

    while (currentDate.add(1, 'days').diff(nextDate) < 0)
      filledJourneys[currentDate.format('YYYY-MM-DD')] = { journeys: [] };
  }

  if (journeysArray.length && journeysArray[journeysArray.length - 1])
    filledJourneys[Object.keys(journeysArray[journeysArray.length - 1])[0]] = Object.values(
      journeysArray[journeysArray.length - 1]
    )[0];

  return filledJourneys;
};
