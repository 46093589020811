import moment from 'moment';
import React, { useMemo } from 'react';

import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './../../EventsBrowser.scss';

const bem = cn('events-browser');
const font = cn('font');

const TopPick = ({ event, isActive }: any) => {
  const {
    image,
    populateTitleFromEventTitle,
    topPickEventTitle,
    topPickEventTitleType,
    topPickEventTitleFontStyle,
    eventName,
    populateDescriptionFromEventDescription,
    eventDescription,
    topPickEventDescriptionType,
    topPickEventDescriptionFontStyle,
    topPickEventDescription,
    selectCardColor = 'blue',
    numberOfOpacity = '60',
    populateImageFromEventImage,
    topPickImage,
    selectImageSize = '23:34',
    populateDateTimeFromEvent,
    eventDateAndTime,
    topPickEventDateAndTime,
    populateLocationFromEvent,
    locationName,
    topPickLocationName,
    offersUrl,
  } = event;

  const formatDate = ({ year, month, dayOfMonth, hourOfDay, minute, second = 0 }) => {
    const formattedDate = moment({ year, month: month, date: dayOfMonth, hour: hourOfDay, minute, second });

    return formattedDate.format('ddd, MMM D · h:mm A');
  };

  const colorMap: { [key: string]: string } = {
    blue: '#146CB8',
    yellow: '#A5A90F',
    pink: '#A431B7',
    green: '#00B669',
  };

  const backgroundColor = colorMap[selectCardColor];
  const opacity = Math.max(0, Math.min(100, parseInt(numberOfOpacity))) / 100;

  const styles: any = useMemo(
    () => ({
      '--top-pick-bg-color': backgroundColor,
      '--top-pick-opacity': opacity,
      '--top-pick-image-aspect-ratio': selectImageSize,
    }),
    [backgroundColor, opacity, selectImageSize]
  );

  return (
    <a href={offersUrl} className={bem('top-pick-wrapper')} style={styles} tabIndex={!isActive ? -1 : undefined}>
      <div className={bem('top-pick-card-image')}>
        <Image
          {...(populateImageFromEventImage ? { ...image } : { ...topPickImage })}
          alt={image.alt || image.title || 'Top Picks image'}
        />
      </div>
      <div className={bem('top-pick-card-overlay')} />
      <div className={bem('top-pick-card-text')}>
        <div className={bem('top-pick-card-title')}>
          <Text
            className={font({ variant: topPickEventTitleFontStyle })}
            as={topPickEventTitleType}
            text={populateTitleFromEventTitle ? eventName : topPickEventTitle}
          />
        </div>
        <div className={bem('top-pick-card-bottom')}>
          <div className={bem('top-pick-card-description')}>
            <Text
              className={font({ variant: topPickEventDescriptionFontStyle })}
              as={topPickEventDescriptionType}
              text={populateDescriptionFromEventDescription ? eventDescription : topPickEventDescription}
            />
          </div>
          {(eventDateAndTime || topPickEventDateAndTime) && (
            <div className={bem('top-pick-card-time')}>
              {populateDateTimeFromEvent || !topPickEventDateAndTime
                ? formatDate(eventDateAndTime)
                : formatDate(topPickEventDateAndTime)}
            </div>
          )}
          <div className={bem('top-pick-card-location')}>
            {populateLocationFromEvent ? locationName : topPickLocationName}
          </div>
        </div>
      </div>
    </a>
  );
};

export default TopPick;
