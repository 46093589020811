import React, { useEffect, useId, useRef, useState } from 'react';
import Popover from 'react-popover';

import MenuItem from '@atoms/MenuItem/MenuItem';
import { DesktopSelectProps } from '@atoms/Select/types';

import { cn, cx } from 'utils/classNames';

import SelectHeader from '../SelectHeader';
import './DesktopSelect.scss';
import { useInputHandlers } from './useInputHandlers';
import { useFilteredOptions } from './utils';

const bem = cn('desktop-select');

const DesktopSelect = (props: DesktopSelectProps) => {
  const { displayList, handleHeaderClick, value, optionsData } = props;
  const { handleClickOutside, handleItemClick, focus, setFocus, handleKeyDown } = props;
  const { headerRef, disabled, inputFieldPlace, fieldsetClassName = '' } = props;
  const { phoneNumberList = false, searchCountryCodePlaceholder = '', ariaLabel } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpening, setDropdownOpening] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<any>(null);
  const focusedItemRef = useRef<any>(null);

  const filteredOptions = useFilteredOptions(optionsData, searchTerm, phoneNumberList);
  const generatedId = useId();
  const inputId = `${generatedId}-search-input`;

  const {
    isInputFocused,
    onSearchCountryCode,
    handleInputFocus,
    handleInputBlur,
    handleInputMouseOver,
    handleInputMouseLeave,
    handleKeyDownWrapper,
  } = useInputHandlers(inputRef, setFocus, handleKeyDown, focus, setSearchTerm, filteredOptions);

  const shouldFocus = (itemIndex: number | 'input') => {
    if (itemIndex === 'input') return displayList;

    return focus?.index !== undefined && focus.index === itemIndex && displayList;
  };

  const prevFocusIndexRef = useRef<number | undefined>(undefined);

  const getAriaActiveDescendant = () => {
    const focusIndex = focus?.index;
    if (focusIndex === undefined) return '';
    if (focusIndex === -1) return inputId;
    if (focusIndex > -1) return `${generatedId}-list-option-${focusIndex}`;

    return '';
  };

  useEffect(() => {
    if (displayList && isDropdownOpening && inputRef.current) {
      inputRef.current.focus();
      setFocus?.({ index: -1, preventScroll: false });
      setDropdownOpening(false);
    }
  }, [displayList, isDropdownOpening, setFocus]);

  useEffect(() => {
    if (focus?.index !== undefined && focus.index !== -1 && focusedItemRef?.current) {
      const focusedItem = focusedItemRef.current;
      const prevFocusIndex = prevFocusIndexRef.current;
      let blockOption: ScrollLogicalPosition = 'nearest';
      if (prevFocusIndex !== undefined)
        if (focus.index > prevFocusIndex) blockOption = 'end';
        else if (focus.index < prevFocusIndex) blockOption = 'nearest';

      focusedItem.scrollIntoView({ block: blockOption });
      prevFocusIndexRef.current = focus.index;
    }
  }, [focus?.index, focusedItemRef]);

  const handleHeaderClickWrapper = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDropdownOpening(true);
    if (handleHeaderClick) handleHeaderClick(e);
  };

  return (
    <fieldset
      className={cx(bem('fieldset', { parking: inputFieldPlace === 'parkingDetails' }), {
        [fieldsetClassName]: !!fieldsetClassName,
      })}
    >
      <legend className={bem('legend')}>{value}</legend>
      <Popover
        className={bem('popover')}
        onOuterAction={handleClickOutside}
        isOpen={displayList && !disabled}
        tipSize={0.01}
        preferPlace="below"
        enterExitTransitionDurationMs={10}
        body={
          <div
            id={`${generatedId}-list`}
            className={bem('list', { phoneNumberList: phoneNumberList })}
            style={{ width: headerRef?.current?.offsetWidth }}
            onKeyDown={handleKeyDownWrapper}
          >
            {phoneNumberList && (
              <input
                ref={inputRef}
                type="text"
                name="Country Code"
                id={inputId}
                placeholder={searchCountryCodePlaceholder}
                value={searchTerm}
                onChange={onSearchCountryCode}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onMouseOver={handleInputMouseOver}
                onMouseLeave={handleInputMouseLeave}
                className={cx(bem('search-country-code'), { 'input-focused': isInputFocused })}
                aria-label="Search country"
                autoComplete="off"
                tabIndex={0}
              />
            )}
            <ul
              ref={listRef}
              className={bem('listItems', { phoneNumberList: phoneNumberList })}
              role="listbox"
              aria-activedescendant={getAriaActiveDescendant()}
              aria-labelledby={`${generatedId}-label`}
              id={`select-${generatedId}`}
            >
              {filteredOptions.map((option, itemIndex) => (
                <MenuItem
                  key={option.value || itemIndex}
                  id={`${generatedId}-list-option-${itemIndex}`}
                  active={option.value === value}
                  itemIndex={itemIndex}
                  value={option.value}
                  label={option.label}
                  setFocus={setFocus}
                  focus={shouldFocus(itemIndex)}
                  onClick={handleItemClick}
                  disabled={option.disabled}
                  role="option"
                  ariaSelected={focus?.index === itemIndex}
                  focusedItemRef={focusedItemRef}
                />
              ))}
            </ul>
          </div>
        }
        target={headerRef?.current}
        {...props}
      >
        <SelectHeader
          {...props}
          id={generatedId}
          headerRef={headerRef}
          onClick={handleHeaderClickWrapper}
          ariaLabel={ariaLabel}
        />
      </Popover>
    </fieldset>
  );
};

export default DesktopSelect;
