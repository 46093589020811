import React from 'react';

import { cn, cx } from 'utils/classNames';
import { color_base_black } from 'utils/colors';

import '../../../../../../booking/routes/RTI/CostSummary/CostSummary.scss';
import './CancellationDetail.scss';

const bem = cn('cancellation-detail');

export const AfterSalesDetailItem = ({
  label,
  price,
  isGray = false,
  isBold = false,
  className = '',
  priceColor = color_base_black,
}) => (
  <div className={cx(bem({ isGray, isBold }), className)}>
    <div>
      <span>{label}</span>
    </div>
    <span style={{ color: isGray ? '' : priceColor }}>{price}</span>
  </div>
);

export default AfterSalesDetailItem;
