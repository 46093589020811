import React from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './EditPass.scss';
import { EditPassModalDesktop } from './components/EditPassModalDesktop';
import { EditPassModalMobile } from './components/EditPassModalMobile';
import { EditPassProps } from './types';
import { useEditPass } from './useEditPass';

const bem = cn('wallet-edit-pass');

export const EditPass = ({ label, item }: EditPassProps) => {
  const editPass = useEditPass(item);
  const { showModal, editModalVisible } = editPass;
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  return (
    <>
      <button className={bem()} onClick={showModal}>
        <Icon name="edit2" className={bem('icon')} />
        &nbsp;<span>{label}</span>
      </button>
      {editModalVisible && !isMobile && <EditPassModalDesktop useEditPass={editPass} />}
      {editModalVisible && isMobile && <EditPassModalMobile useEditPass={editPass} />}
    </>
  );
};
