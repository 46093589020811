import moment from 'moment';
import AccountStore from 'stores/AccountStore/AccountStore';

import { base64Encode } from 'utils/helpers';

export const editTripdetailsRtiRedirect = (accountStore: AccountStore, type: 'extras' | 'seats') => {
  const { trip, passengerSeatingDetails, setExtrasInfoModalOpen } = accountStore.tripDetails;
  const isOutboundAvailable =
    moment(trip?.outboundRoute?.departureDateTime).isAfter(moment()) && !trip?.outboundRoute?.isCancelled;
  const isInboundAvailable =
    moment(trip?.inboundRoute?.departureDateTime).isAfter(moment()) && !trip?.inboundRoute?.isCancelled;

  if (!isOutboundAvailable && !isInboundAvailable) {
    setExtrasInfoModalOpen();

    return;
  }

  if (!trip || !passengerSeatingDetails?.length) return;

  const primaryPassenger = passengerSeatingDetails[0].passenger;

  if (!primaryPassenger) return;

  const data = {
    rn: trip.referenceNumber,
    fn: primaryPassenger.firstName,
    ln: primaryPassenger.lastName,
    type,
  };

  const encodedData = base64Encode(JSON.stringify(data));

  window.location.href = `/booking/rti?data=${encodedData}&rn=${trip.referenceNumber}`;
};
