import { observer } from 'mobx-react-lite';
import React from 'react';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { useNavigationLogic } from './../useNavigationLogic';
import './TripNotModifiableMessage.scss';

const bem = cn('trip-details-navigation-trip-not-modifiable-message');

export const TripNotModifiableMessageMobile = observer(() => {
  const { messageForCantCancelModify } = useNavigationLogic();

  return (
    <div className={bem('smart-saver-banner-mobile')}>
      <Icon name="info" className={bem('info-icon')} />
      <span className={bem('smart-saver-label')}>{messageForCantCancelModify}</span>
    </div>
  );
});
