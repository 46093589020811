import { useThrottle } from 'hooks/useThrottle';
import { useCallback, useEffect, useRef } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { XFContainerHandlerProps } from '@organisms/XFContainer/types';

import { mediaQueryList } from 'utils/mediaQueries';

import './XFContainer.scss';
import { CLASSES, SELECTORS, THROTTLE_TIME } from './constants';

const XFContainerHandler = ({ scope }: XFContainerHandlerProps) => {
  const outerWrapper = useRef<HTMLDivElement | null>(null);
  const innerWrapper = useRef<HTMLDivElement | null>(null);

  const isMobile: boolean = useMediaPredicate(mediaQueryList.maxSmall);
  const prevScrollY = useRef<number>(window.scrollY);

  const getBannersInfo = () => {
    const allBanners = innerWrapper.current?.querySelectorAll(SELECTORS.alertBanner);
    const stickyBanners = innerWrapper.current?.querySelectorAll(SELECTORS.stickyBanners);
    const stickyBanner = innerWrapper.current?.querySelector(SELECTORS.stickyBanners);
    const delaysExperienceBanner = innerWrapper.current?.querySelectorAll(SELECTORS.headerAlertBannerId);
    const onlyStickyBanners = allBanners?.length === stickyBanners?.length;
    const onlyNormalBanners = stickyBanners?.length === 0;

    const isStickyBanner = !!stickyBanners?.length;

    return {
      allBanners,
      stickyBanners,
      onlyStickyBanners,
      onlyNormalBanners,
      isStickyBanner,
      stickyBanner,
      delaysExperienceBanner,
    };
  };

  const sortBannerByStickyProperty = (
    allBanners?: NodeList,
    stickyBanner?: Element | null,
    onlyStickyBanners?: boolean
  ) =>
    (stickyBanner || onlyStickyBanners) && allBanners
      ? Array.from(allBanners).sort((a: any, b: any) =>
          !a?.classList?.contains(CLASSES.alertBannerSticky) && b?.classList?.contains(CLASSES.alertBannerSticky)
            ? 1
            : -1
        )
      : allBanners;

  const processBannersVisibility = useCallback(
    (
      allBanners?: NodeList,
      stickyBanner?: Element | null,
      onlyStickyBanners?: boolean,
      onlyNormalBanners?: boolean,
      delaysExperienceBanner?: NodeList | null,
      fromCloseAction?: boolean
    ) => {
      if (onlyNormalBanners && !fromCloseAction && !delaysExperienceBanner) return;
      let shownBanners = 0;
      const alertHiddenClass = CLASSES.alertBannerHidden;

      //Sort banners by sticky property in order to be the first shown.
      const banners = sortBannerByStickyProperty(allBanners, stickyBanner, onlyStickyBanners) || [];

      banners.forEach(banner => {
        if (shownBanners > 0 && !delaysExperienceBanner) {
          banner.classList.add(alertHiddenClass);

          return;
        } else if (
          (allBanners && allBanners.length >= shownBanners && banner.classList.contains(alertHiddenClass)) ||
          banner.id == 'delays-experience-banner'
        )
          banner.classList.remove(alertHiddenClass);

        if (
          banner.id == 'delays-experience-banner' &&
          (window.location.pathname.includes('/trip-details') || window.location.pathname.includes('/my-trips'))
        )
          banner.classList.add(alertHiddenClass);

        banner.classList.add(CLASSES.visible);
        shownBanners++;
      });
    },
    []
  );

  const handleScrollForAllStickyBanners = (scrollY: number, bannerWrapper: HTMLDivElement) => {
    const lastElement: any = innerWrapper.current?.lastElementChild;
    if (lastElement.classList.contains(CLASSES.bannerWrapper)) return;

    const sticky = scrollY > lastElement.offsetHeight;

    const bannerVisible: HTMLDivElement | null | undefined = innerWrapper.current?.querySelector(SELECTORS.visible);
    const lastElementHidden = lastElement.classList.contains(CLASSES.hidden);
    const lastElementHeight =
      bannerVisible && outerWrapper.current
        ? outerWrapper.current?.offsetHeight - bannerVisible?.offsetHeight
        : outerWrapper.current?.offsetHeight;

    if ((prevScrollY.current > scrollY || scrollY === 0) && bannerVisible) {
      // scrolling up
      lastElement.classList.remove(CLASSES.hidden);
      innerWrapper.current?.classList.remove(CLASSES.hidden);
      lastElement.style.height = `${lastElementHidden ? -bannerVisible.offsetHeight || 0 : lastElementHeight}px`;

      if (scrollY > bannerWrapper.offsetHeight) {
        lastElement.style.top = `${bannerVisible?.offsetHeight}px`;
        lastElement.classList.add(CLASSES.sticky);
        lastElement.classList.remove(CLASSES.xfContainerElementPositionRelative);
      } else {
        lastElement.classList.remove(CLASSES.sticky);
        lastElement.classList.remove(CLASSES.hidden);
        lastElement.classList.add(CLASSES.xfContainerElementPositionRelative);
        lastElement.style.top = `${bannerVisible?.offsetHeight}px`;
      }
    } else {
      lastElement.classList.add(CLASSES.sticky);
      if (sticky) {
        lastElement.classList.add(CLASSES.hidden);
        lastElement.style.top = `0px`;
      }
    }
  };

  const handleScrollForNonStickyBanners = (bannerWrapper: HTMLDivElement, scrollY: number) => {
    //scroll up
    const lastElement: any = innerWrapper.current?.lastElementChild;

    if (lastElement.classList.contains(CLASSES.bannerWrapper)) {
      bannerWrapper.classList.remove(CLASSES.xfContainerElementHidden);
      bannerWrapper.classList.remove(CLASSES.hidden);
      innerWrapper.current?.classList.remove(CLASSES.sticky);

      return;
    }

    if ((prevScrollY.current >= scrollY || scrollY === 0) && innerWrapper.current && outerWrapper.current) {
      innerWrapper.current?.classList.add(CLASSES.sticky);
      innerWrapper.current?.classList.remove(CLASSES.hidden);
      innerWrapper.current.style.display = 'block';
      if (scrollY <= innerWrapper.current?.offsetHeight) {
        if (innerWrapper.current?.offsetHeight > outerWrapper.current?.offsetHeight)
          outerWrapper.current.style.height = `${innerWrapper.current?.offsetHeight}px`;

        innerWrapper.current?.classList.remove(CLASSES.sticky);
        bannerWrapper.classList.remove(CLASSES.xfContainerElementHidden);
        bannerWrapper.classList.remove(CLASSES.hidden);
      } else if (scrollY > 0 && scrollY > (innerWrapper.current?.offsetHeight || 0)) {
        bannerWrapper.classList.add(CLASSES.xfContainerElementHidden);
        bannerWrapper.classList.add(CLASSES.hidden);
        innerWrapper.current?.classList.add(CLASSES.sticky);
        lastElement.classList.remove(CLASSES.hidden);
      }
    }
    //scroll down
    else {
      const sticky = scrollY > (outerWrapper.current?.offsetHeight || 0);

      if (sticky) {
        bannerWrapper.classList.add(CLASSES.hidden);
        innerWrapper.current?.classList.add(CLASSES.hidden);
      } else {
        lastElement.classList.remove(CLASSES.sticky);
        innerWrapper.current?.classList.remove(CLASSES.sticky);
      }
    }
  };

  const handleScrollWithBanners = useCallback(
    (bannerWrapper: HTMLDivElement, scrollY: number) => {
      const { onlyStickyBanners, allBanners, isStickyBanner, stickyBanner, onlyNormalBanners, delaysExperienceBanner } =
        getBannersInfo();

      if (onlyStickyBanners || isStickyBanner) {
        processBannersVisibility(
          allBanners,
          stickyBanner,
          onlyStickyBanners,
          onlyNormalBanners,
          delaysExperienceBanner
        );
        handleScrollForAllStickyBanners(scrollY, bannerWrapper);
      } else handleScrollForNonStickyBanners(bannerWrapper, scrollY);
    },
    [processBannersVisibility]
  );

  const calculateWrapperHeightWithBanners = useCallback(
    (bannerWrapper: any, closeAction?: boolean) => {
      const lastElement: any = innerWrapper.current?.lastElementChild;
      const headerHeight = innerWrapper.current?.lastElementChild?.querySelector('header')?.offsetHeight;
      const { onlyStickyBanners, allBanners, onlyNormalBanners, isStickyBanner, stickyBanner, delaysExperienceBanner } =
        getBannersInfo();

      if (
        (onlyStickyBanners || (allBanners && allBanners.length >= 1) || isStickyBanner || delaysExperienceBanner) &&
        outerWrapper.current
      ) {
        if (delaysExperienceBanner?.length && window.location.pathname == '/')
          outerWrapper.current.style.marginBottom = '24px';
        if (closeAction && bannerWrapper?.classList.contains(CLASSES.xfContainerElementHidden))
          bannerWrapper.classList.remove(CLASSES.xfContainerElementHidden);
        processBannersVisibility(
          allBanners,
          stickyBanner,
          onlyStickyBanners,
          onlyNormalBanners,
          delaysExperienceBanner,
          closeAction
        );
        const delaysExperienceBannerHeight =
          delaysExperienceBanner && delaysExperienceBanner?.length > 0
            ? Array.from(delaysExperienceBanner).reduce((sum, element) => {
                const height = (element as HTMLElement)?.offsetHeight;

                return sum + height;
              }, 0)
            : 0;

        const bannerVisible: any = innerWrapper.current?.querySelector(SELECTORS.visible);

        if (bannerVisible && innerWrapper.current && innerWrapper.current?.children.length > 1 && isStickyBanner) {
          lastElement.style.height = `${headerHeight || lastElement.offsetHeight}px`;
          lastElement.style.top = `${bannerVisible.offsetHeight}px`;
          lastElement.classList.add(CLASSES.xfContainerElementPositionRelative);
          innerWrapper.current?.classList.remove(CLASSES.hidden);
          outerWrapper.current.style.height = `${
            bannerVisible.offsetHeight + (headerHeight || lastElement.offsetHeight) + delaysExperienceBannerHeight
          }px`;
        } else if (
          innerWrapper.current &&
          innerWrapper.current?.children.length > 1 &&
          !isStickyBanner &&
          !onlyNormalBanners
        ) {
          outerWrapper.current.style.height = `${lastElement.offsetHeight + delaysExperienceBannerHeight}px`;
          lastElement.style.top = `0px`;
        } else if (innerWrapper.current?.children.length === 1) {
          if (lastElement.classList.contains(CLASSES.bannerWrapper))
            outerWrapper.current.style.height = `${bannerVisible.offsetHeight + delaysExperienceBannerHeight}px`;
          else outerWrapper.current.style.height = `${lastElement.offsetHeight + delaysExperienceBannerHeight}px`;

          lastElement.style.top = `0px`;
        } else if (onlyNormalBanners) {
          lastElement.style.height = `${headerHeight || lastElement.offsetHeight}px`;
          outerWrapper.current.style.height = `${
            bannerWrapper.offsetHeight + lastElement.offsetHeight + delaysExperienceBannerHeight
          }px`;

          lastElement.classList.remove(CLASSES.xfContainerElementPositionRelative);
          lastElement.style.removeProperty('top');
        }
      } else if (!onlyNormalBanners && outerWrapper.current) {
        outerWrapper.current.style.height = `${outerWrapper.current?.offsetHeight + bannerWrapper.offsetHeight}px`;
        if (delaysExperienceBanner?.length) outerWrapper.current.style.marginBottom = '24px';
      }
    },
    [processBannersVisibility]
  );

  const calculateWrapperHeight = useCallback(
    (closeAction?: boolean) => {
      if (outerWrapper.current && innerWrapper.current) {
        const bannerWrapper: any = innerWrapper.current?.querySelector(SELECTORS.bannerWrapper);
        const lastElement: any = innerWrapper.current?.lastElementChild;
        const headerHeight = innerWrapper.current?.lastElementChild?.querySelector('header')?.offsetHeight;
        const { allBanners } = getBannersInfo();
        if (bannerWrapper && !!allBanners?.length) calculateWrapperHeightWithBanners(bannerWrapper, closeAction);
        else {
          if (closeAction) {
            const { allBanners } = getBannersInfo();
            if (!allBanners?.length) lastElement.classList.remove(CLASSES.hidden);
          }
          outerWrapper.current.style.height = `${headerHeight || lastElement.offsetHeight}px`;
          lastElement.style.removeProperty('top');
        }
      }
    },
    [calculateWrapperHeightWithBanners]
  );

  const calculate = useCallback(() => {
    if (scope === 'footer') return;

    const bannerWrapper: any = innerWrapper.current?.querySelector(SELECTORS.headerBannerWrapper);
    const scrollY = Math.max(0, window.scrollY);
    const lastElement: any = innerWrapper.current?.lastElementChild;
    const { allBanners } = getBannersInfo();

    if (bannerWrapper && allBanners && allBanners.length > 0) handleScrollWithBanners(bannerWrapper, scrollY);
    else if (!bannerWrapper || !allBanners?.length) {
      const sticky = scrollY > lastElement?.offsetHeight;

      if (prevScrollY.current > scrollY || scrollY === 0) {
        // scrolling up
        innerWrapper.current?.classList.remove(CLASSES.hidden);
        lastElement?.classList.remove(CLASSES.hidden);
        if (!sticky) {
          innerWrapper.current?.classList.remove(CLASSES.sticky);
          lastElement?.classList.remove(CLASSES.sticky);
        }
        if (sticky) innerWrapper.current?.classList.add(CLASSES.sticky);
      } else if (sticky && !!allBanners?.length)
        // scrolling down
        innerWrapper.current?.classList.add(CLASSES.hidden);
      else if (sticky) {
        // scrolling down
        if (innerWrapper.current?.classList.contains(CLASSES.sticky))
          innerWrapper.current?.classList.remove(CLASSES.sticky);
        if (lastElement?.classList.contains(CLASSES.sticky)) lastElement?.classList.remove(CLASSES.sticky);
        innerWrapper.current?.classList.add(CLASSES.hidden);
        lastElement?.classList.add(CLASSES.hidden);
      }
    }
    prevScrollY.current = scrollY;
  }, [handleScrollWithBanners, scope]);

  const throttleCalculate = useThrottle(calculate, THROTTLE_TIME);

  const throttleCalculateWrapperHeight = useThrottle(calculateWrapperHeight, THROTTLE_TIME);

  const calculateWrapperWhenCloseBanner = useCallback(() => {
    calculateWrapperHeight(true);
  }, [calculateWrapperHeight]);

  useEffect(() => {
    outerWrapper.current = document.querySelector(SELECTORS.outerWrapper);
    innerWrapper.current = document.querySelector(SELECTORS.innerWrapper);

    if (scope === 'header') {
      window.addEventListener('scroll', throttleCalculate);
      window.addEventListener('recalculateXfContainerHeight', throttleCalculateWrapperHeight);
      window.addEventListener('xf_container_storage', calculateWrapperWhenCloseBanner);
      window.addEventListener('load', throttleCalculateWrapperHeight);
      window.addEventListener('resize', throttleCalculateWrapperHeight);

      calculate();
    }

    return () => {
      window.removeEventListener('scroll', throttleCalculate);
      window.removeEventListener('recalculateXfContainerHeight', throttleCalculateWrapperHeight);
      window.removeEventListener('xf_container_storage', calculateWrapperWhenCloseBanner);
      window.removeEventListener('load', throttleCalculateWrapperHeight);
      window.removeEventListener('resize', throttleCalculateWrapperHeight);
    };
  }, [calculate, calculateWrapperWhenCloseBanner, scope, throttleCalculate, throttleCalculateWrapperHeight]);

  useEffect(() => {
    if (scope === 'footer') return;
    calculateWrapperHeight();
  }, [outerWrapper, isMobile, scope, calculateWrapperHeight]);

  return null;
};

export default XFContainerHandler;
