import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text/Text';
import { Modal } from '@molecules/Modal/Modal';

import { cn } from 'utils/classNames';
import { isValidURL } from 'utils/helpers';
import { clearLoggedOutFlag, getLoggedOutFlag } from 'utils/localstorage';

import './LoggedOutModal.scss';
import { LoggedOutModalProps } from './types';

const bem = cn('logged-out-modal');

export const LoggedOutModal = observer((props: LoggedOutModalProps) => {
  const { title, ariaLabel, loginButton } = props;

  const { authStore } = useStores();
  const { isAuthenticated } = authStore;

  const [isModalOpen, setIsModalOpen] = useState(true);

  const onModalClose = () => {
    clearLoggedOutFlag();
    setIsModalOpen(false);
  };

  const onLoginButtonClick = () => {
    //dummy arrow function
  };

  useEffect(() => {
    if (isModalOpen && getLoggedOutFlag() && !isAuthenticated) {
      clearLoggedOutFlag();

      const loginURL = loginButton.link?.url;

      // setLoginRedirectURL(window.location.href);

      if (isValidURL(loginURL)) window.location.href = loginURL || '';
      else if (loginURL && loginURL.startsWith('/')) window.location.href = `${window.location.origin}${loginURL}`;
      else if (loginURL && !loginURL.startsWith('/')) window.location.href = `${window.location.origin}/${loginURL}`;
      else window.location.href = `${window.location.origin}/account/login`;
    }
  }, [isAuthenticated, isModalOpen, loginButton.link?.url]);

  return null; //if (!isModalOpen || !getLoggedOutFlag() || isAuthenticated)

  // removing implementation of LoggedOutModal, instead the experience will be when the user gets logged out it will directly redirect to the login page without showing the modal.
  // The component is needed and should not be removed in the front-end or in the back-end, because when the setLoggedOutFlag() is set in the local storage it needs a component
  // to be on all pages that when the component re-renders, it checks if the flag is there and then executes the code inside the useEffect above. The LoggedOutModal is the perfect component
  // for that because it appears on all pages, and we need it inside the Experience Fragment.

  return (
    <Modal title={title} ariaLabel={ariaLabel} variant="mini" onClose={onModalClose}>
      <div className={bem()}>
        <div className={bem('header-wrapper')}>
          <h1>
            <Text className={bem('title')} text={title} />
          </h1>
          <button className={bem('icon-wrapper')} onClick={onModalClose}>
            <Icon name="close2" className={bem('icon')} />
          </button>
        </div>
        {loginButton && (
          <div className={bem('button-wrapper')}>
            <Button {...loginButton} onClick={onLoginButtonClick} />
          </div>
        )}
      </div>
    </Modal>
  );
});
