import React from 'react';
import LoadingBar from 'react-top-loading-bar';

import { api } from 'utils/api';
import { color_brand_blue } from 'utils/colors';

export const TopLoadingBar = () => {
  // loading bar component ref
  const ref: any = React.useRef(null);

  React.useEffect(() => {
    // Add a request interceptor
    api.interceptors.request.use(
      config => {
        if (ref?.current) ref.current.continuousStart();

        return config;
      },
      error => Promise.reject(error)
    );

    // Add a response interceptor
    api.interceptors.response.use(
      response => {
        if (ref?.current) ref.current.complete();

        return response;
      },
      error => {
        if (ref?.current) ref.current.complete();

        return Promise.reject(error);
      }
    );
  }, []);

  return <LoadingBar ref={ref} color={color_brand_blue} height={3} shadow={false} />;
};
