import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { passengerTypes } from '@booking/routes/RTI/PassengersInfo/constants';
import { SeatingDetailsProps } from '@booking/routes/RTI/SeatSelection/components/SeatSelectionModal/SeatingDetails/types';

import { TripRoute } from '../TripRoute';

export const SeatingDetails = observer((props: SeatingDetailsProps) => {
  const { updatePristineSeatSelection, isAccountFlow } = props;

  const { bookingStore } = useStores();

  const { provisionalBooking, seats } = bookingStore.rti;
  const { tripDetails } = provisionalBooking;
  const { trip } = tripDetails;
  const { travelDirection, passengersSeats } = seats;

  const validPassengers = useMemo(
    () => passengersSeats?.filter(item => item.passenger.type !== passengerTypes.infant),
    [passengersSeats]
  );

  const isRoundTrip = useMemo(
    () => !!trip.outboundRoute.id && !!trip.inboundRoute?.id,
    [trip.inboundRoute?.id, trip.outboundRoute.id]
  );

  return (
    <>
      {!!trip.outboundRoute.id && (
        <TripRoute
          updatePristineSeatSelection={updatePristineSeatSelection}
          passengers={validPassengers}
          route={trip.outboundRoute}
          direction="outbound"
          roundTrip={isRoundTrip}
          expanded={travelDirection === 'outbound'}
          isAccountFlow={isAccountFlow}
        />
      )}
      {!!trip.inboundRoute?.id && (
        <TripRoute
          updatePristineSeatSelection={updatePristineSeatSelection}
          passengers={validPassengers}
          route={trip.inboundRoute}
          roundTrip={isRoundTrip}
          expanded={travelDirection === 'inbound'}
          direction="inbound"
          isAccountFlow={isAccountFlow}
        />
      )}
    </>
  );
});
