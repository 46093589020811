import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { Fragment } from 'react';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { Pagination } from '@molecules/Pagination';

import { cn } from 'utils/classNames';

import './TransactionsTable.scss';
import TransactionsTablePlaceholders from './TransactionsTablePlaceholders';
import { useTransactionsTableLogic } from './useTransactionsTableLogic';

const bem = cn('account-transactions-table');

const TransactionsTable = observer(() => {
  const { cfStore } = useStores();
  const { rewardsGeneral } = cfStore.account;

  const logic = useTransactionsTableLogic();
  const { activityLoading, paginationLoading, handleStartEarningButtonClick, pagination } = logic;
  const { paginatedTransactions, handlePageNumberChanged } = logic;
  const { handleConfirmationLinkClicked } = logic;
  const { itemsPerPage, pageCount, pageNumber, total } = pagination;

  const loading = activityLoading || paginationLoading;

  return (
    <div className={bem()}>
      <Text text={rewardsGeneral.rewardsActivity.title} className={bem('title')} />
      <ReactPlaceholder
        ready={!loading}
        customPlaceholder={
          <TransactionsTablePlaceholders itemsPerPage={itemsPerPage} addBottomButton={paginationLoading} />
        }
        showLoadingAnimation
      >
        {!loading && !paginatedTransactions.length && (
          <div className={bem('no-results')}>
            <div>
              <Text text={rewardsGeneral.rewardsActivity.noRewardsActivitiesLabel} className={bem('main-subtitle')} />
              <Text text={rewardsGeneral.rewardsActivity.letsChangeThatLabel} className={bem('second-subtitle')} />
            </div>
            <Button label={rewardsGeneral.rewardsTracker.startEarningButtonLabel} color="yellow" onClick={handleStartEarningButtonClick} />
          </div>
        )}
        {!loading && !!paginatedTransactions.length && (
          <div className={bem('table-wrapper')}>
            <Text text={rewardsGeneral.rewardsActivity.activityLabelTableHeader} className={bem('table-cell', { header: true })} />
            <Text text={rewardsGeneral.rewardsActivity.confirmationLabelTableHeader} className={bem('table-cell', { header: true })} />
            <Text
              text={rewardsGeneral.rewardsActivity.rewardsLabelTableHeader}
              className={bem('table-cell', { header: true, alignRight: true })}
            />
            <div className={bem('table-divider')} />
            {paginatedTransactions
              .filter(transaction => 
                (transaction.rewards.earned && transaction.rewards.earned > 0) || 
                (transaction.rewards.redeemed && transaction.rewards.redeemed > 0) || 
                (transaction.rewards.pending && transaction.rewards.pending > 0)
              )
              .map((transaction, idx) => (
              <Fragment key={`${idx}-${transaction.booking.bookingNumber}`}>
                <div className={bem('activity')}>
                  <div className={bem('od')}>
                    {transaction.booking.type === 'one-way' || transaction.booking.type === 'round-trip' ? (
                      <>
                        <Text text={transaction.booking.origin} className={bem('od-item')} />
                        <Icon name={transaction.booking.type === 'round-trip' ? 'arrow-double' : 'arrow-from-to'} />
                        <Text text={transaction.booking.destination} className={bem('od-item')} />
                      </>
                    ) : (
                      <Text
                        text={transaction.booking.type === 'travel-pass' ? 'Multi-ride' : 'Parking Pass'}
                        className={bem('od-item')}
                      />
                    )}
                  </div>
                  <div className={bem('date')}>
                    {moment(transaction.booking.bookingDate).tz('America/New_York').format('MMM DD, YYYY')}
                  </div>
                </div>
                {transaction.booking.type === 'one-way' || transaction.booking.type === 'round-trip' ? (
                  <div
                    className={bem('confirmation')}
                    onClick={() => handleConfirmationLinkClicked(transaction.booking.bookingNumber)}
                  >
                    <Text text={transaction.booking.bookingNumber} />
                  </div>
                ) : (
                  <div className={`${bem('confirmation')} ${bem('passes-confirmation')}`}>
                    <Text text={transaction.booking.bookingNumber} />
                  </div>
                )}
                <div className={bem('rewards')}>
                  {transaction.rewards.status?.toLowerCase() === 'pending' && (
                    <div className={bem('pending-awarded')}>
                      {!!transaction.rewards.pending && (
                        <>
                          <Text text={rewardsGeneral.rewardsActivity.pendingLabelTableBody} className={bem('pending')} />
                          <Text
                            text={`+ ${transaction.rewards.pending} ${rewardsGeneral.rewardsActivity.ptsLabelTableBody}`}
                            className={bem('awarded')}
                          />
                        </>
                      )}
                    </div>
                  )}
                  {transaction.rewards.status?.toLowerCase() === 'accepted' && (
                    <Text
                      text={`+ ${transaction.rewards.totalEarned} ${rewardsGeneral.rewardsActivity.ptsLabelTableBody}`}
                      className={bem('awarded')}
                    />
                  )}
                  {!!transaction.rewards.redeemed && (
                    <div className={bem('redeemed')}>
                      <Text text={rewardsGeneral.rewardsActivity.redeemedLabelTableBody} className={bem('redeemed-label')} />
                      <Text
                        text={`- ${transaction.rewards.redeemed} ${rewardsGeneral.rewardsActivity.ptsLabelTableBody}`}
                        className={bem('redeemed-pts')}
                      />
                    </div>
                  )}
                </div>
                <div className={bem('table-divider', { row: true })} />
              </Fragment>
            ))}
          </div>
        )}
      </ReactPlaceholder>
      {!paginationLoading && !!paginatedTransactions.length && (
        <div className={bem('pagination-wrapper')}>
          <Pagination
            activePageLabel={rewardsGeneral.rewardsActivity.activePageLabel}
            pageLabel={rewardsGeneral.rewardsActivity.paginationPageLabel}
            morePagesLabel={rewardsGeneral.rewardsActivity.morePagesLabel}
            nextPageLabel={rewardsGeneral.rewardsActivity.paginationNextPageLabel}
            previousPageLabel={rewardsGeneral.rewardsActivity.paginationPreviousPageLabel}
            itemsPerPage={itemsPerPage}
            pageCount={pageCount}
            pageNumber={pageNumber}
            total={total}
            siblingCount={1}
            onPageChange={handlePageNumberChanged}
          />
        </div>
      )}
    </div>
  );
});

export default TransactionsTable;
