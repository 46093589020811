export const ModifyTripsDefaultArgs = (modifyTripSection: any) => ({
  initialFormValues: {},
  stationsSelection: {
    i18n: {
      fromLabel: modifyTripSection.fromDropdownLabel,
      fromPlaceholder: modifyTripSection.fromPlaceholder,
      toLabel: modifyTripSection.toDropdownLabel,
      toPlaceholder: modifyTripSection.toPlaceholder,
      locationHint: modifyTripSection.locationHint,
      selectOriginError: modifyTripSection.selectOriginError,
      selectDestinationError: modifyTripSection.selectDestinationError,
    },
  },
  tripSelection: {
    toggleItems: [
      {
        value: 'one-way',
        label: 'One-way',
      },
      {
        value: 'round-trip',
        label: 'Round trip',
      },
    ],
    orLabel: 'OR',
    buildYourPass: {
      label: 'Build your Pass',
      link: {
        url: 'http://google.com',
        target: '_new',
      },
    },
  },
  datesSelection: {
    i18n: {
      startLabel: modifyTripSection.departuredatelabel,
      startPlaceholder: 'Add departure',
      endLabel: modifyTripSection.returndatelabel,
      endPlaceholder: 'Add return',
      closeButtonLabel: modifyTripSection.calendarCloseButtonLabel,
      clearButtonLabel: modifyTripSection.calendarClearDatesButtonLabel,
      startDateRequiredError: 'Departure Date Required',
      endDateRequiredError: 'Return Date Required',
    },
  },
  i18n: {
    submitFormLabel: modifyTripSection.searchTicketsButtonLabel,
    continueLabel: 'Continue',
    cancelLabel: 'Cancel',
    saveLabel: 'Save',
  },
  title: modifyTripSection.widgetTitleLabel,
  searchResultsPage: '/booking',
});
