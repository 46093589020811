import { makeAutoObservable } from 'mobx';

import { api, oneDayCachedApi } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { isDirectBffApiCall } from 'utils/helpers';
import { ScrollPosition, scrollToTop } from 'utils/isKiosk';

import { PaymentTokens } from '../PaymentTokens';
import { PaymentCard, WalletPassesConfig, WalletPassItem } from './types';

export class Wallet {
  walletPasses: WalletPassesConfig | null = null;
  passes: WalletPassItem[] = [];
  passToRenew: WalletPassItem | null = null;
  passToUpdate: WalletPassItem | null = null;
  selectedRenewPaymentMethod: PaymentCard | null = null;
  paymentTokensStore: PaymentTokens = new PaymentTokens();
  isLoading = true;
  hasError = false;
  scrollPositionToRestore: ScrollPosition | null = null;
  mobileActiveSlideToRestore: number | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  setHasError(error) {
    this.hasError = error;
  }

  setPasses(passes) {
    this.passes = passes;
  }

  setWalletPasses(walletPasses) {
    this.walletPasses = walletPasses;
  }

  setPassToRenew(passToRenew) {
    this.passToRenew = passToRenew;
  }

  setPassToUpdate(passToUpdate) {
    this.passToUpdate = passToUpdate;
  }

  setSelectedRenewPaymentMethod(selectedRenewPaymentMethod) {
    this.selectedRenewPaymentMethod = selectedRenewPaymentMethod;
  }

  setScrollPositionToRestore(scrollPosition) {
    this.scrollPositionToRestore = scrollPosition;
  }

  setMobileActiveSlideToRestore(mobileActiveSlideToRestore) {
    this.mobileActiveSlideToRestore = mobileActiveSlideToRestore;
  }

  async toggleAutoRenew(tokenId?: string) {
    if (this.passToRenew?.type === 'train' && this.passToRenew?.code)
      await this.updateTravelPassAutoRenew(this.passToRenew.code, tokenId);
    else if (this.passToRenew?.type === 'parking' && this.passToRenew?.ticketNumber)
      await this.updateParkingPassAutoRenew(this.passToRenew.ticketNumber, tokenId);
    else throw new Error('Pass type not supported');

    if (!this.hasError) {
      this.setPassToRenew(null);
      this.setSelectedRenewPaymentMethod(null);
    }
  }

  async updateTravelPassAutoRenew(code: string, tokenId?: string) {
    const action = tokenId ? 'enable' : 'disable';
    const url = requestUrls.getParametrizedRestUrl(requestUrls.account.walletAutoRenewTravelPass, { code, action });
    const payload = { paymentToken: { id: tokenId } };
    await this.updatePass(url, payload, undefined);
  }

  async updateParkingPassAutoRenew(ticketNumber: string, tokenId?: string) {
    const action = tokenId ? 'enable' : 'disable';
    const url = requestUrls.getParametrizedRestUrl(requestUrls.account.walletAutoRenewParkingPass, {
      ticketNumber,
      action,
    });
    const payload = { paymentToken: { id: tokenId } };
    await this.updatePass(url, payload, undefined);
  }

  updateParkingPass(ticketNumber: string, body, callback) {
    const url = `${requestUrls.getRestUrl(requestUrls.account.walletUpdateParkingPass)}.${ticketNumber}.xjson`;

    return this.updatePass(url, body, callback);
  }

  private async updatePass(url: string, payload, cb?) {
    if (!this.passToRenew && !this.passToUpdate) return false;

    let originalScroll: undefined | ScrollPosition = undefined;

    if (this.passToRenew) {
      this.setMobileActiveSlideToRestore(this.passes.indexOf(this.passToRenew) + 1);
      originalScroll = scrollToTop();
    }

    try {
      if (!cb) this.setLoading(true);
      this.setHasError(false);

      await api.put(url, payload);
    } catch (e) {
      console.error(e);

      return false;
    }

    if (this.passToUpdate) {
      this.setMobileActiveSlideToRestore(this.passes.indexOf(this.passToUpdate) + 1);
      originalScroll = scrollToTop();
    }

    try {
      this.setLoading(true);

      await this.fetchData();

      this.setLoading(false);
    } catch (e) {
      console.error(e);
      this.setHasError(true);
      this.setLoading(false);
    }

    if (originalScroll) this.setScrollPositionToRestore(originalScroll);

    cb && cb();

    return true;
  }

  createAllPasses(walletPasses) {
    if (!walletPasses) this.setPasses([]);
    const { travelPasses, parkingPasses, creditPasses }: WalletPassesConfig = walletPasses;
    const allPasses: WalletPassItem[] = [];

    allPasses.push(...travelPasses.map((pass: WalletPassItem) => ({ ...pass, type: 'train' })));
    allPasses.push(...parkingPasses.map((pass: WalletPassItem) => ({ ...pass, type: 'parking' })));
    allPasses.push(...creditPasses.map((pass: WalletPassItem) => ({ ...pass, type: 'credit' })));

    this.setPasses(allPasses);
  }

  async fetchStationsData() {
    try {
      let response;

      if (isDirectBffApiCall)
        response = await oneDayCachedApi.get(requestUrls.getBffUrl(requestUrls.bookingSearch.bffStationsSelection), {
          headers: {
            Authorization: 'Bearer anonymous',
          },
          id: 'train-stations',
        });
      else
        response = await oneDayCachedApi.get(`${requestUrls.getRestUrl(requestUrls.bookingSearch.stations)}.xjson`, {
          id: 'train-stations',
        });

      const responseData = response.data;

      return isDirectBffApiCall ? responseData : responseData.stations;
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  async fetchData() {
    try {
      this.setLoading(true);
      this.setHasError(false);

      const url = `${requestUrls.getRestUrl(requestUrls.account.walletPasses)}.xjson`;
      const response = await api.get(url);

      this.setWalletPasses(response.data);
      this.createAllPasses(response.data);

      this.setLoading(false);
    } catch (e) {
      console.error(e);
      this.setHasError(true);
      this.setLoading(false);
    }
  }

  clearData() {
    this.setLoading(false);
    this.setHasError(false);
    this.setWalletPasses({});
    this.setPasses([]);
  }
}
