import React, { useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './FeaturesGrid.scss';
import DesktopItems from './components/DesktopItems';
import MobileItems from './components/MobileItems';
import { FeaturesGridProps } from './types';

const bem = cn('features-grid');

const splitItems = (items, itemsPerRow) => {
  const copyItems = JSON.parse(JSON.stringify(items));

  return [...Array(Math.ceil(copyItems.length / itemsPerRow))].map(() =>
    copyItems.splice(0, itemsPerRow).map(item => ({ ...item, colors: { ...item.colors } }))
  );
};

export const FeaturesGrid = (props: FeaturesGridProps) => {
  const { items = [], variant = 'stack', eyebrow, id, itemsPerRow = 4 } = props;
  const { title, button, colors, titleAndEyebrowAlignment, description } = props;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const style: any = useMemo(
    () => ({
      '--features-grid-background-color': colors?.background,
      '--features-grid-eyebrow-color': colors?.eyebrow,
      '--features-grid-title-color': colors?.title,
      '--features-grid-description-color': colors?.description,
    }),
    [colors]
  );

  const isStack = variant === 'stack';

  const rowList = useMemo(() => splitItems(items, itemsPerRow), [items, itemsPerRow]);

  const itemsLength = useMemo(() => (itemsPerRow > items.length ? items.length : itemsPerRow), [items, itemsPerRow]);

  return (
    <div id={id} className={bem()} style={style}>
      <div className={bem('content', { variant, alignment: titleAndEyebrowAlignment, length: itemsLength })}>
        <div className={bem('top')} role="group" aria-label="item">
          <div className={bem('top-content', { length: itemsPerRow })}>
            {!!eyebrow && (
              <div className={bem('top-eyebrow', { noTitleAndDesc: !title && !description })}>
                <Text as="h3" text={eyebrow} />
              </div>
            )}
            {!!title && (
              <div className={bem('top-title', { noDesc: !description })}>
                <Text as="h2" text={title} />
              </div>
            )}
            {!!description && (
              <div className={bem('top-description')}>
                <Text text={description} />
              </div>
            )}
          </div>
        </div>

        {isMobile ? (
          <MobileItems items={items} variant={variant} colors={colors} />
        ) : (
          <DesktopItems rowList={rowList} itemsPerRow={itemsPerRow} variant={variant} />
        )}
        <div className={bem('bottom', { isStack })}>{button && <Button {...button} />}</div>
      </div>
    </div>
  );
};
