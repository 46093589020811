export const logos = {
  charcoal: './logo-story/charcoal.svg',
  charcoal2: './logo-story/charcoal2.svg',
  black: './logo-story/black.svg',
  white: './logo-story/white.svg',
  white2: './logo-story/white2.svg',
  yellow: './logo-story/yellow.svg',
};

export const logoOptions = ['charcoal', 'charcoal2', 'black', 'white', 'white2', 'yellow'];
