import React from 'react';

import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './PaymentCardItem.scss';
import { PaymentCardItemsProps } from './types';

const bem = cn('payment-card-item');

export const PaymentCardItem = (props: PaymentCardItemsProps) => {
  const { cardImage, cardLabel, expirationLabel, expirationDate } = props;
  const { savedCardsLimitReached } = props;

  return (
    <>
      <Image className={bem('image')} src={cardImage?.image?.src} alt={cardImage?.title} />
      <div className={bem('label')}>
        <Text text={cardLabel} className={bem('card-type-number', { disabled: savedCardsLimitReached })} />
        {expirationDate && (
          <div className={bem('card-expiration')}>
            {expirationLabel} {expirationDate}
          </div>
        )}
      </div>
    </>
  );
};
