import { Pagination, UpcomingPagination } from './index';

export const initialPastPagination: Pagination = {
  total: 0,
  itemsPerPage: 10,
  pageCount: 0,
  pageNumber: 0,
};

export const initialUpcomingPagination: UpcomingPagination = {
  total: 0,
  firstPageCount: 3,
  itemsPerPage: 3,
  pageNumber: 0,
  pageCount: 0,
};
