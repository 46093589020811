import React, { useCallback } from 'react';
import { useStores } from 'stores';

import { api } from 'utils/api';

export const usePromoCodeV2 = (endpoint: string, options: object = {}, onSuccess) => {
  const { promoStore, bookingStore } = useStores();
  const { applicablePasses, createApplicablePasses, creditPassCode, setNewCreditPassCode } = bookingStore.rti;
  const {
    inputValue,
    setInputValue,
    serverStatus,
    setServerStatus,
    showInput,
    setShowInput,
    setAppliedPromoCode,
    appliedPromoCode,
  } = promoStore;

  const resetPromoData = () => {
    setInputValue('');
    setServerStatus('');
  };

  const handleAddCodeClick = () => {
    setShowInput(!showInput);
  };

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const trimmedValue = event.target.value.trim();
      setInputValue(trimmedValue.toUpperCase());
    },
    [setInputValue]
  );

  const handleTrailingIconClick = async (promoCode: string) => {
    if (serverStatus !== 'error') {
      setServerStatus('loading');
      const payload = {
        promoCode,
      };
      const response = await api.delete(endpoint, { ...options, data: payload }).catch(setError);
      if (response?.data) {
        resetPromoData();
        onSuccess && onSuccess(response.data);

        applicablePasses.forEach(blCredit => {
          if (blCredit?.creditPass?.code === promoCode) {
            setNewCreditPassCode(creditPassCode.filter(code => code !== promoCode));
            createApplicablePasses(response.data);
          }
        });
      }
    } else resetPromoData();
  };

  const setSuccess = response => {
    setServerStatus('success');
    onSuccess && onSuccess(response.data);
  };

  const setError = () => {
    setServerStatus('error');
  };

  const handleApplyCode = async () => {
    setServerStatus('loading');
    const payload = {
      promoCode: inputValue,
    };
    const response = await api.post(endpoint, payload, options).catch(setError);
    if (response?.data) {
      const codeApplied = response.data.tripDetails?.costSummary?.sections.find(section =>
        section.items.find(item => item.productName === inputValue)
      );
      if (!codeApplied) setError();
      else {
        setAppliedPromoCode(inputValue);
        setSuccess(response);

        applicablePasses.forEach(blCredit => {
          if (blCredit?.creditPass?.code === inputValue) {
            creditPassCode.push(inputValue);
            createApplicablePasses(response.data);
          }
        });
        setInputValue('')
      }
    }
  };

  return {
    handleApplyCode,
    serverStatus,
    handleAddCodeClick,
    showInput,
    handleInputChange,
    inputValue,
    handleTrailingIconClick,
    setInputValue,
    appliedPromoCode,
    resetPromoData,
  };
};