import React from 'react';

import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './InfoCard.scss';
import { InfoCardProps } from './types';

const bem = cn('extras-modal-card');

export const InfoCard = (props: InfoCardProps) => {
  const { image, title, text, numCards } = props;

  const twoColumnWidth = numCards < 3;

  return (
    <div className={bem({ twoColumnWidth })}>
      <div className={bem('image-card')}>
        <Image {...image} className={bem('image')} />
      </div>
      <div className={bem('details')}>
        <Text as="h4" text={title} className={bem('details-title')} />
        <Text text={text.replaceAll('&nbsp;', ' ')} className={bem('details-text')} />
      </div>
    </div>
  );
};
