import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './InfoModal.scss';
import { ErrorModalProps } from './types';

const bem = cn('trip-details-info-modal');

const InfoModal = observer((props: ErrorModalProps) => {
  const { buttonAction } = props;

  const { cfStore } = useStores();
  const { navigation } = cfStore.account.tripDetails;
  const { manageTripsSection } = navigation;

  return (
    <Modal variant="mini">
      <Text className={bem('title')} text={manageTripsSection.modifyTripModalTitle} />
      <Text className={bem('description')} text={manageTripsSection.modifyTripModalDescription} />
      <Button color="yellow" label={manageTripsSection.modifyTripModalButtonLabel} onClick={buttonAction} />
    </Modal>
  );
});

export default InfoModal;
