import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useStores } from 'stores';

import { useNavigationLogic } from '@account/routes/TripDetails/components/Navigation/useNavigationLogic';
import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { CoachSelect } from '../CoachSelect';
import { ErrorItem } from '../ErrorItem';
import { ExitConfirmationModal } from '../ExitConfirmationModal';
import { MobilePlaceholder } from '../MobilePlaceholder';
import { PassengersCarousel } from '../PassengersCarousel';
import { SeatSelectionMap } from '../SeatSelectionMap';
import { SeatSelectionProps } from '../types';
import './SeatSelectionMobile.scss';

const bem = cn('seat-selection-mobile');

export const SeatSelectionMobile = observer((props: SeatSelectionProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);

  const { handleCloseModal, innerRef, saveLabel, totalCost } = props;
  const { isAccountFlow } = props;

  const { bookingStore, cfStore, accountStore } = useStores();
  const { tripDetails: accountTripDetails } = accountStore;

  const seatsCF = isAccountFlow ? cfStore.account.tripDetails.seats : cfStore.rti.seats;
  const { provisionalBooking, seats } = bookingStore.rti;
  const { isOutboundSeatingAvailable, isInboundSeatingAvailable } = bookingStore.rti.seats;
  const { isOutboundSeatingVisible, isInboundSeatingVisible } = bookingStore.rti.seats;
  const { tripDetails } = provisionalBooking;
  const { trip } = tripDetails;
  const { travelDirection, selectedCoach, switchDirection, coachOptions, loading, passengersSeats } = seats;
  const { error, handleSelectCoach, updateError, resetData, updateBookingSeats } = seats;
  const navigationLogic = useNavigationLogic();
  const { isFirstLegTraveled, isSecondLegTraveled } = navigationLogic;

  const isRoundTrip = trip.outboundRoute && trip.inboundRoute;

  const isDeparture = travelDirection === 'outbound';

  useEffect(() => {
    if (hasInitialized) return;

    const seatingAvailabilityDetermined = isAccountFlow
      ? isOutboundSeatingVisible !== undefined && isInboundSeatingVisible !== undefined
      : isOutboundSeatingAvailable !== undefined && isInboundSeatingAvailable !== undefined;

    if (!seatingAvailabilityDetermined) 
      return;
    

    if (isAccountFlow) {
      if (isRoundTrip && travelDirection === 'outbound' && !isOutboundSeatingVisible) switchDirection();
      if (isRoundTrip && travelDirection === 'inbound' && !isInboundSeatingVisible) switchDirection();
    } else {
      if (isRoundTrip && travelDirection === 'outbound' && !isOutboundSeatingAvailable) switchDirection();
      if (isRoundTrip && travelDirection === 'inbound' && !isInboundSeatingAvailable) switchDirection();
    }

    setHasInitialized(true);
  }, [
    isAccountFlow,
    hasInitialized,
    travelDirection,
    isOutboundSeatingAvailable,
    isInboundSeatingAvailable,
    isRoundTrip,
    isOutboundSeatingVisible,
    isInboundSeatingVisible,
    switchDirection,
  ]);

  const outboundRoute = accountTripDetails?.trip?.outboundRoute;
  const inboundRoute = accountTripDetails?.trip?.inboundRoute;
  const isLongHaul = outboundRoute?.origin?.id === 'MCO' || outboundRoute?.destination?.id === 'MCO';

  const canEditSeatsAccontFlow = useMemo(() => {
    const isNotSmartSaverOutbound = travelDirection === 'outbound' && outboundRoute?.productId !== 'SMART_SAVER';
    const isNotSmartSaverInbound = travelDirection === 'inbound' && inboundRoute?.productId !== 'SMART_SAVER';
    const isOutboundPremium = travelDirection === 'outbound' && outboundRoute?.classType === 'premium';
    const isInboundPremium = travelDirection === 'inbound' && inboundRoute?.classType === 'premium';
    const isFirstLegModifiable = travelDirection === 'outbound' && !isFirstLegTraveled;
    const isSecondLegModifiable = travelDirection === 'inbound' && !isSecondLegTraveled;

    return (
      ((isLongHaul && (isNotSmartSaverOutbound || isNotSmartSaverInbound)) || isOutboundPremium || isInboundPremium) &&
      (isFirstLegModifiable || isSecondLegModifiable)
    );
  }, [outboundRoute, inboundRoute, travelDirection, isLongHaul, isFirstLegTraveled, isSecondLegTraveled]);

  const stations = useMemo(
    () =>
      isDeparture
        ? {
            origin: trip.outboundRoute.origin,
            destination: trip.outboundRoute.destination,
          }
        : {
            origin: trip.inboundRoute?.origin,
            destination: trip.inboundRoute?.destination,
          },
    [
      isDeparture,
      trip.inboundRoute?.destination,
      trip.inboundRoute?.origin,
      trip.outboundRoute.destination,
      trip.outboundRoute.origin,
    ]
  );

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const closeModal = () => {
    resetData();
    handleCloseModal();
  };

  const handleConfirmClick = () => {
    closeConfirmModal();
    closeModal();
  };

  const handleBackClick = () => {
    if (totalCost && totalCost > 0) setShowConfirmModal(true);
    else handleCloseModal();
  };

  const handleCoachValueChange = coachValue => {
    const coachToSet = coachOptions.find(el => el.value === coachValue);
    handleSelectCoach(coachToSet);
  };

  const saveSelection = (isAccountFlow = false) => {
    updateBookingSeats(closeModal, isAccountFlow, accountStore);
  };

  const saveSelectionAccountFlow = () => {
    saveSelection(isAccountFlow);
  };

  const hasSeatDeparted =
    (!tripDetails?.trip?.outboundRoute?.isCancelled && tripDetails?.trip?.outboundRouteStatus !== 'past') ||
    travelDirection !== 'outbound';

  return (
    <>
      <div className={bem()} role="region" aria-label="Seating map">
        <>
          <div className={bem('header')}>
            <button className={bem('back')} aria-label="Close" onClick={handleBackClick}>
              <Icon name="long-arrow-right" />
            </button>
            <div className={bem('data')}>
              <h1 className={bem('title')}>{seatsCF.seatSelectionTitle}</h1>
              <div className={bem('stations')}>
                <div className={bem('station')}>{stations.origin.name}</div>
                <Icon name="arrow-from-to" className={bem('from-to')} />
                <div className={bem('station')}>{stations.destination.name}</div>
              </div>
            </div>
          </div>
          {hasSeatDeparted ? (
            loading ? (
              <MobilePlaceholder />
            ) : error ? (
              <ErrorItem />
            ) : (
              <>
                {trip.inboundRoute && trip.outboundRoute && (
                  <div className={bem('routes-wrapper')}>
                    <div className={bem('routes')}>
                      <button
                        className={bem('route', { active: isDeparture, disabled: !isOutboundSeatingAvailable })}
                        onClick={() => switchDirection()}
                        disabled={isAccountFlow ? !isOutboundSeatingVisible : !isOutboundSeatingAvailable}
                      >
                        {seatsCF.departureLabel}
                      </button>
                      <button
                        className={bem('route', { active: !isDeparture, disabled: !isInboundSeatingAvailable })}
                        onClick={() => switchDirection()}
                        disabled={isAccountFlow ? !isInboundSeatingVisible : !isInboundSeatingAvailable}
                      >
                        {seatsCF.returnLabel}
                      </button>
                    </div>
                  </div>
                )}
                {selectedCoach && (
                  <div className={bem('select-wrapper')}>
                    <CoachSelect
                      optionsData={coachOptions}
                      selectedCoach={selectedCoach}
                      name="coach-mobile"
                      onChange={handleCoachValueChange}
                      isAccountFlow={isAccountFlow}
                      canEditSeatsAccontFlow={canEditSeatsAccontFlow}
                    />
                  </div>
                )}
                <div
                  ref={innerRef}
                  className={bem('seat-map', { carousel: passengersSeats.length > 1 })}
                  role="region"
                  aria-label="Seating map"
                >
                  <SeatSelectionMap isAccountFlow={isAccountFlow} canEditSeatsAccontFlow={canEditSeatsAccontFlow} />
                </div>

                {updateError && (
                  <div className={bem('update-error')}>
                    <Icon name="warning" className={bem('update-error-icon')} />
                    <div className={bem('update-error-message')} role="alert">
                      {seatsCF.updateSeatsServerErrorMessage}
                    </div>
                  </div>
                )}
                <div className={bem('footer')}>
                  <div className={bem('passenger-details')}>
                    <PassengersCarousel isAccountFlow={isAccountFlow} />
                  </div>
                  {(!isAccountFlow || canEditSeatsAccontFlow) && (
                    <div className={bem('btn-container')}>
                      <Button
                        label={saveLabel}
                        color="yellow"
                        variant="primary"
                        onClick={!isAccountFlow ? () => saveSelection() : () => saveSelectionAccountFlow()}
                      />
                    </div>
                  )}
                </div>
              </>
            )
          ) : (
            <>
              {trip.inboundRoute && trip.outboundRoute && (
                <div className={bem('routes-wrapper')}>
                  <div className={bem('routes')}>
                    <button
                      className={bem('route', { active: isDeparture, disabled: !isOutboundSeatingAvailable })}
                      onClick={() => switchDirection()}
                      disabled={isAccountFlow ? !isOutboundSeatingVisible : !isOutboundSeatingAvailable}
                    >
                      {seatsCF.departureLabel}
                    </button>
                    <button
                      className={bem('route', { active: !isDeparture, disabled: !isInboundSeatingAvailable })}
                      onClick={() => switchDirection()}
                      disabled={isAccountFlow ? !isInboundSeatingVisible : !isInboundSeatingAvailable}
                    >
                      {seatsCF.returnLabel}
                    </button>
                  </div>
                </div>
              )}
              <div className={bem('cancel-message')}>
                <Icon name="disabled-seat" className={bem('disabled-seat-icon')} />
                <p className={bem('disabled-seat-header')}>{seatsCF.notModifiableTitle}</p>
                <p className={bem('disabled-seat-text')}>{seatsCF.notModifiableText}</p>
              </div>
            </>
          )}
        </>
      </div>

      {showConfirmModal && (
        <ExitConfirmationModal handleCancel={closeConfirmModal} handleConfirmation={handleConfirmClick} />
      )}
    </>
  );
});
