import moment from 'moment';

import { PromoCodeDetailsResponse } from '@molecules/PromoCode/types';

export const validatePromoCodeResponse = (
  response: PromoCodeDetailsResponse,
  i18n,
  outboundTrainDate,
  inboundTrainDate
) => {
  if (!response.active || response.voucherType !== 'PROMO' || !response.isWithinSalesPeriod)
    return {
      valid: false,
      message: i18n.invalidPromoCodeErrorMessage,
    };

  const travelPeriodStartDate = moment(response?.travelPeriodStart).utc();
  const travelPeriodEndDate = moment(response?.travelPeriodEnd).utc().add(1, 'days');
  const outboundDate = outboundTrainDate ? moment(outboundTrainDate).utc() : null;
  const inboundDate = inboundTrainDate ? moment(inboundTrainDate).utc() : null;

  const isOutboundValid =
    !outboundDate ||
    (outboundDate.isSameOrAfter(travelPeriodStartDate) && outboundDate.isSameOrBefore(travelPeriodEndDate));
  const isInboundValid =
    !inboundDate ||
    (inboundDate.isSameOrAfter(travelPeriodStartDate) && inboundDate.isSameOrBefore(travelPeriodEndDate));

  if (isOutboundValid || isInboundValid)
    return {
      valid: true,
      message: '',
    };

  return {
    valid: false,
    message: i18n.expiredPromoCodeErrorMessage,
  };
};
