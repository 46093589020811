import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { useStores } from 'stores';

import { Card } from '@account/components/Card';
import CreateNewItem from '@account/components/CreateNewItem/CreateNewItem';
import AddPassenger from '@account/routes/Passengers/AddPassenger';
import Passenger from '@account/routes/Passengers/Passenger';

import { cn } from 'utils/classNames';

import './PassengersCard.scss';

const bem = cn('passengers-card');

export const PassengersCard = observer(() => {
  const { accountStore, cfStore } = useStores();
  const [modalAddOpened, setModalAddOpened] = useState(false);

  const { account } = cfStore;
  const { passengersCF } = account;
  const { passengersStore } = accountStore;
  const { passengers, isLoading } = passengersStore;

  const modalOnCloseRef = useRef<HTMLButtonElement>(null);

  const handleAddPassenger = () => {
    setModalAddOpened(!modalAddOpened);
  };

  return (
    <Card
      title={passengersCF.dashboardTitle || passengersCF.pageTitle}
      count={passengers.length}
      isLoading={isLoading}
      routeToNavigate={passengersCF.link}
      placeholderVariant="small"
    >
      <AddPassenger isOpen={modalAddOpened} setIsOpen={handleAddPassenger} onCloseRef={modalOnCloseRef} />
      {passengers.length ? (
        <div className={bem()}>
          {passengers.slice(0, 2).map((passenger, index) => (
            <div key={`passenger-${index}`} className={bem('item', { single: passengers.length === 1 })}>
              <Passenger size="small" passenger={passenger} />
            </div>
          ))}
        </div>
      ) : (
        <CreateNewItem
          label={passengersCF.createNewPassengerLabel}
          onClick={handleAddPassenger}
          modalOnCloseRef={modalOnCloseRef}
        />
      )}
    </Card>
  );
});
