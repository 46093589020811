import { useCallback, useMemo } from "react";
import { useStores } from "stores";

export const usePolicyItemLogic = (props) => {
  const { originAndDestinationStations } = props;
  const { bookingStore: { rti }, cfStore } = useStores();
  const { trip } = rti.provisionalBooking.tripDetails;
  const { classTypes } = cfStore.rti;

  const outboundRoute = trip?.outboundRoute?.productId;
  const inboundRoute = trip?.inboundRoute ? trip?.inboundRoute?.productId : '';
  
  const outboundStationId = trip?.outboundRoute?.origin?.id;
  const inboundStationId = trip?.outboundRoute?.destination?.id;

  const outboundStation = trip?.outboundRoute;
  const inboundStation = trip?.inboundRoute;

  let routePair;

  if (!inboundRoute || outboundRoute == inboundRoute)
    routePair = [
      outboundRoute
    ];
  else routePair = [
    outboundRoute.concat(inboundRoute),
    inboundRoute.concat(outboundRoute)
  ]

  const isPolicyFareAvailable = useMemo(() => routePair?.some(item => !!props?.[item]), [routePair, props])

  const isPolicyOdAvailable = useMemo(() => {
      const originAndDestinationPair = [
        `${outboundStationId}-${inboundStationId}`,
        `${inboundStationId}-${outboundStationId}`];

      return originAndDestinationStations ? originAndDestinationStations.some(oditem => originAndDestinationPair.includes(oditem)) : true},
  [originAndDestinationStations, inboundStationId, outboundStationId]);

  const classTypeTitle = useCallback((classType) => {
    const matchingClassType = classTypes.find(
      item => item.classTypeName?.toLowerCase() === classType.toLowerCase()
    );

    return matchingClassType?.classTypeLabel || '';
  }, [classTypes]);

  const converBulletpoints = (bulletpoints) => `<ul>${bulletpoints?.map(item => `<li>${item}</li>\n`)}</ul>`.replaceAll(',','');

  const replaceDescription = (description, bulletpoints) => {
    if (!!description && description?.length != 0) return description;
  
    if (!!bulletpoints && bulletpoints?.length != 0) 
      return converBulletpoints(bulletpoints)
    
return '';
  };

  return {
    isPolicyFareAvailable,
    isPolicyOdAvailable,
    replaceDescription,
    converBulletpoints,
    classTypeTitle,
    outboundStation,
    inboundStation,
    outboundRoute,
    inboundRoute,
  }
} 