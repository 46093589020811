import React from 'react';

import { cn } from 'utils/classNames';

import './SettingEntry.scss';
import { SettingEntryProps } from './types';

const bem = cn('setting-entry');
export const SettingEntry = (props: SettingEntryProps) => {
  const { label, value } = props;

  if (!label) return null;

  return (
    <div className={bem()}>
      <span className={bem('setting-label')}>{label}</span>
      <span className={bem('setting-value')}>{value}</span>
    </div>
  );
};
