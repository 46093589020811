import { FormikHelpers } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useStores, rootStore } from 'stores';

import { ErrorCodesConfig } from '@molecules/ErrorCodesBrowser/types';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { isAxiosGracefulError } from 'utils/helpers';

export const useRewardsLogic = () => {
  const { accountStore, cfStore, bookingStore, passesStore } = useStores();
  const { summary, fetchRewardsSummaryData } = accountStore.rewardsSummary;
  const { appliedRewardsPoints, applyRewardsPoints } = bookingStore.rti;
  const { checkout } = passesStore;

  const totalToBePaid =
    bookingStore.rti?.provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid || 0;
  const cartAmountToBePaid = checkout?.costSummary?.bookingTotal?.totalToBePaid || 0;

  const displayLoyaltyComponents = accountStore?.profile?.displayLoyaltyComponents;

  const reviewAndPay = useMemo(() => {
    if (cfStore.rti.reviewAndPay.rewardPointsTitle) return cfStore.rti.reviewAndPay;

    return cfStore.passes.reviewAndPay;
  }, [cfStore.passes.reviewAndPay, cfStore.rti.reviewAndPay]);

  const gracefulErrors: ErrorCodesConfig | null = useMemo(
    () => JSON.parse(cfStore.header.gracefulErrors || cfStore.passesHeader.gracefulErrors || 'null'),
    [cfStore.header.gracefulErrors, cfStore.passesHeader.gracefulErrors]
  );

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    displayLoyaltyComponents && fetchRewardsSummaryData();
  }, [fetchRewardsSummaryData, displayLoyaltyComponents]);

  const availableUserPoints = useMemo(
    () => reviewAndPay.availablePointsLabel.replaceAll('$numberPoints', (summary?.spendable || 0).toString()),
    [reviewAndPay.availablePointsLabel, summary?.spendable]
  );

  const appliedUserPoints = useMemo(
    () => reviewAndPay.appliedPointsLabel.replaceAll('$numberPoints', appliedRewardsPoints.toString()),
    [appliedRewardsPoints, reviewAndPay.appliedPointsLabel]
  );

  const toggleExpanded = (resetForm: any, setFieldValue: any) => {
    setExpanded(val => {
      if (!summary?.spendable || appliedRewardsPoints || (totalToBePaid <= 0 && cartAmountToBePaid <= 0)) {
        setFieldValue && setFieldValue('expanded', false);

        return false;
      }

      setFieldValue && setFieldValue('expanded', !val);

      return !val;
    });

    resetForm && resetForm();
  };

  const handleInputChanged = (e: any, setFieldValue: any, setFieldError: any) => {
    setFieldValue('numberPoints', (e?.target?.value || '').replace(/^0|[^0-9]/g, ''));
    setFieldError('numberPoints', '');
  };

  const handleSubmit = async (
    values: { expanded: boolean; numberPoints: string },
    formikHelpers: FormikHelpers<{ expanded: boolean; numberPoints: string }>
  ) => {
    if (Number(values.numberPoints) > (summary?.spendable || 0)) {
      formikHelpers.setFieldTouched('numberPoints');
      formikHelpers.setFieldError('numberPoints', reviewAndPay.insufficientAvailablePointsErrorMessage);

      return true;
    }

    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: 'RTI',
        navigationLinkName: reviewAndPay.applyButtonLabel,
        customLink: 'brightlineRewardsApply',
        totalRewardsapplied: values.numberPoints,
        externalLink: '',
        linkType: 'linkClickLoyalty',
      },
    });

    try {
      await applyRewardsPoints(values.numberPoints, setExpanded);
      if (bookingStore.rti.appliedRewardsPointsError) {
        const responseMessage = bookingStore.rti.appliedRewardsPointsError.message
          ? JSON.parse(bookingStore.rti.appliedRewardsPointsError.message || '')
          : '';
        if (bookingStore.rti.appliedRewardsPointsError.status === 400 && responseMessage?.hasEnoughPoints === false)
          formikHelpers.setFieldError('numberPoints', reviewAndPay.insufficientAvailablePointsErrorMessage);
        else formikHelpers.setFieldError('numberPoints', reviewAndPay.rewardPointsGenericErrorMessage);
      }
    } catch (e: any) {
      console.error(e);

      if (isAxiosGracefulError(e)) {
        const gcError =
          gracefulErrors?.errorCodesList?.find(error => error?.errorCode === e?.response?.data?.code?.toString())
            ?.errorBody || reviewAndPay.rewardPointsGenericErrorMessage;
        formikHelpers.setFieldError('numberPoints', gcError);
      } else formikHelpers.setFieldError('numberPoints', reviewAndPay.rewardPointsGenericErrorMessage);
    }

    return true;
  };

  return {
    availableUserPoints,
    appliedUserPoints,
    expanded,
    toggleExpanded,
    handleInputChanged,
    handleSubmit,
    reviewAndPay,
  };
};
