import React from 'react';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { TripSegmentProps } from '../../types';
import './TripSegment.scss';

const bem = cn('trip-segment');

export const TripSegment = (props: TripSegmentProps) => {
  const { route, status, children } = props;

  return (
    <div className={bem({ status: status })}>
      <div className={bem('direction')}>
        <h3 className={bem('title')}>
          <span className={bem('from')}>{route.origin.name}</span>
          <Icon name="arrow-from-to" />
          <span className={bem('to')}>{route.destination.name}</span>
        </h3>
      </div>
      {children}
    </div>
  );
};
