import { useEffect } from 'react';
import { useStores } from 'stores';

import { loyaltyLoadAnalyticsEvent } from 'utils/adobeDataLayer';
import { isLoyaltyEnabled } from 'utils/metas';

export const usePushLoyaltyDataLayerLogic = () => {
  const { authStore, accountStore } = useStores();
  const { profile, rewardsSummary } = accountStore;
  const { isAuthenticated } = authStore;

  const pushLoyaltyDataLayer = async () => {
    if (!profile.userProfile && isAuthenticated) await profile.fetchUserProfile();
    if (
      !rewardsSummary.summary &&
      isAuthenticated &&
      profile.userProfile?.loyaltyPrograms?.find(
        program => program.program.toLowerCase() === 'brightline' && program.active
      )
    )
      await rewardsSummary.fetchRewardsSummaryData();

    const { userProfile } = profile;
    const { summary } = rewardsSummary;

    const loyaltyProgram = userProfile?.loyaltyPrograms?.find(
      program => program.program.toLowerCase() === 'brightline'
    );

    loyaltyLoadAnalyticsEvent({
      loyalty: {
        joinDate: loyaltyProgram?.optInDateTime || '',
        loyaltyID: [loyaltyProgram?.memberNumber || ''],
        points: summary?.spendable || 0,
        pointsExpiration: [summary?.expirationDate || ''],
        pointsRedeemed: summary?.spent || 0,
        program: loyaltyProgram?.program || '',
        status: loyaltyProgram?.active.toString() || 'false',
      },
    });
  };

  useEffect(() => {
    isLoyaltyEnabled() && pushLoyaltyDataLayer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
