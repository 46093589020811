import React from 'react';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import './Seat.scss';
import { ChairProps } from './types';

const bem = cn('trip-seat');

export const Seat = (props: ChairProps) => {
  const { number, facingDirection } = props;

  const iconName = facingDirection ? `seat-${facingDirection}` : 'seat';

  return (
    <div className={bem()}>
      <div className={bem('number')}>{number}</div>
      <Icon name={iconName} size="large" />
    </div>
  );
};
