import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { Column, Grid } from '@atoms/Grid';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './Checkout.scss';
import { CostSummaryPlaceholderDesktop } from './components/CostSummary/CostSummaryPlaceholder.desktop';
import { MobileFooterPlaceholder } from './components/MobileFooter/MobileFooterPlaceholder';
import { PassesReviewPlaceholderDesktop } from './components/PassesReview/PassesReviewPlaceholder.desktop';
import { PassesReviewPlaceholderMobile } from './components/PassesReview/PassesReviewPlaceholder.mobile';
import { ReviewAndPayPlaceholder } from './components/ReviewAndPay/ReviewAndPayPlaceholder';

const bem = cn('passes-checkout');

export const CheckoutPlaceholder = observer(() => {
  const isMaxTablet = useMediaPredicate(mediaQueryList.maxTablet);

  return (
    <div className={bem()}>
      {isMaxTablet ? (
        <>
          <PassesReviewPlaceholderMobile />
          <ReviewAndPayPlaceholder />
          <MobileFooterPlaceholder />
        </>
      ) : (
        <Grid>
          <Column cols={8} customClassName={bem('left-column')}>
            <div className={bem('wrapper')}>
              <PassesReviewPlaceholderDesktop />
              <ReviewAndPayPlaceholder />
            </div>
          </Column>
          <Column cols={4} customClassName={bem('right-column')}>
            <div className={bem('wrapper')}>
              <CostSummaryPlaceholderDesktop />
            </div>
          </Column>
        </Grid>
      )}
    </div>
  );
});
