import {
  color_base_black,
  color_base_light_grey,
  color_base_medium_grey,
  color_base_medium_light_grey,
  color_black,
  color_brand_blue,
  color_white,
  hexToRGB,
} from 'utils/colors';

export const defaultArgs = {
  colors: {
    background: {
      inactive: color_base_light_grey,
      hover: color_brand_blue,
      focus: color_base_light_grey,
      disabled: color_base_medium_light_grey,
    },
    text: {
      inactive: color_brand_blue,
      hover: color_white,
      focus: color_brand_blue,
      disabled: color_base_medium_grey,
    },
    shadow: {
      inactive: hexToRGB(color_black, 80),
    },
    border: {
      focus: color_base_black,
    },
  },
  hasShadow: false,
  hasIcon: true,
  disabled: false,
};
