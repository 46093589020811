export const createEmptySlides = labels => [
  {
    description: labels.emptyState.trainPassBodyLabel,
    buttonLink: labels.addTrainPassCtaLink,
    buttonLabel: labels.addTrainPassCtaLabel,
  },
  {
    description: labels.emptyState.parkingPassBodyLabel,
    buttonLink: labels.addParkingPassCtaLink,
    buttonLabel: labels.addParkingPassCtaLabel,
  },
];
