import { makeAutoObservable } from 'mobx';
import moment from 'moment/moment';
import { RootStore } from 'stores';

import { api, publicApi } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { stopDatadogSession } from 'utils/datadog';
import { isLoyaltyEnabled } from 'utils/metas';

import { clearPublicBookingToken } from '../../../../utils/localstorage';
import { UserProfileConfig } from './types';

export class UserProfile {
  private readonly rootStore: RootStore;

  userProfile: UserProfileConfig | null;
  isLoading = false;
  hasError = false;
  isEditDialogOpen = false;
  isEditDialogTravelAgentOpen = false;
  isDeleteDialogOpen = false;
  isResetDialogOpen = false;
  isAuthenticatedZendesk = false;
  isBadRequest = false;
  displayLoyaltyComponents = false;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  resetData() {
    this.userProfile = null;
    this.isLoading = false;
    this.hasError = false;
    this.isEditDialogOpen = false;
    this.isEditDialogTravelAgentOpen = false;
    this.isDeleteDialogOpen = false;
    this.isResetDialogOpen = false;
    this.displayLoyaltyComponents = false;
  }

  setEditDialogOpen() {
    this.isEditDialogOpen = true;
  }

  setEditDialogClose() {
    this.isEditDialogOpen = false;
  }

  setEditDialogTravelAgentOpen() {
    this.isEditDialogTravelAgentOpen = true;
  }

  setEditDialogTravelAgentClose() {
    this.isEditDialogTravelAgentOpen = false;
    this.resetBadRequest();
  }

  setDeleteDialogOpen() {
    this.isDeleteDialogOpen = true;
  }

  setDeleteDialogClose() {
    this.isDeleteDialogOpen = false;
  }

  setResetDialogOpen() {
    this.isResetDialogOpen = true;
    alert('Reset password');
  }

  setResetDialogClose() {
    this.isResetDialogOpen = false;
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setError(hasError) {
    this.hasError = hasError;
  }

  setUserProfile(userProfile) {
    this.userProfile = userProfile;
  }

  clearUserProfile() {
    this.userProfile = null;
  }

  resetBadRequest() {
    this.isBadRequest = false;
  }

  setBadRequest(value) {
    this.isBadRequest = value;
  }

  authenticateWithZendesk(token: string) {
    window.zE?.('messenger', 'loginUser', callback => {
      callback(token);
    });
  }

  setIsAuthenticatedZendesk(isAuthenticatedZendesk) {
    this.isAuthenticatedZendesk = isAuthenticatedZendesk;
  }

  setDisplayLoyaltyComponents(displayLoyaltyComponents) {
    this.displayLoyaltyComponents = displayLoyaltyComponents;
  }

  async fetchUserProfile(authorizationToken?: string) {
    try {
      this.setLoading(true);

      const url = `${requestUrls.getRestUrl(requestUrls.userProfile)}.xjson`;
      const response = authorizationToken
        ? await publicApi.get(url, {
            headers: { Authorization: authorizationToken },
          })
        : await api.get(url);

      this.setUserProfile(response.data.profile);

      const zendeskToken = response.data?.zendeskToken;

      if (zendeskToken) {
        localStorage.setItem('zendeskToken', zendeskToken);

        if (!this.isAuthenticatedZendesk) {
          this.setIsAuthenticatedZendesk(true);
          this.authenticateWithZendesk(zendeskToken);
        }
      }

      this.updateLoyaltyDisplayStatus();
      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      this.setError(e);
      this.setLoading(false);
    }
  }

  async updateUserProfile(data) {
    try {
      this.setLoading(true);

      const url = `${requestUrls.getRestUrl(requestUrls.userProfile)}.xjson`;
      const finalData = {
        ...data,
        wheelchairAccommodation: data.wheelchairAccommodation ? 'accessible_space' : 'standard_seat',
        dateOfBirth: data.dateOfBirth ? moment.utc(data.dateOfBirth).format() : undefined,
      };
      const response = await api.put(url, finalData);
      this.setUserProfile(response.data);

      const zendeskToken = response.data?.zendeskToken;

      if (zendeskToken) localStorage.setItem('zendeskToken', zendeskToken);

      this.updateLoyaltyDisplayStatus();
      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      this.setError(e);
      if (e.response?.status === 400 && e.response.data.message === 'Invalid travel agent code. Please try again.')
        this.setBadRequest(true);

      this.setLoading(false);
      throw e;
    }
  }

  async deleteAccount() {
    try {
      this.setLoading(true);

      const url = `${requestUrls.getRestUrl(requestUrls.userProfile)}.xjson`;

      await api.delete(url);

      this.setDeleteDialogClose();

      localStorage.removeItem('zendeskToken');
      stopDatadogSession();
      clearPublicBookingToken();

      await this.rootStore.authStore.auth0Client.logout({ logoutParams: { returnTo: window.location.origin } });

      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      this.setError(e);
      this.setDeleteDialogClose();

      localStorage.removeItem('zendeskToken');
      stopDatadogSession();
      clearPublicBookingToken();

      await this.rootStore.authStore.auth0Client.logout({ logoutParams: { returnTo: window.location.origin } });

      this.setLoading(false);
    }
  }

  async saveLoyaltyProgram(program: string, memberId?: string) {
    try {
      this.setLoading(true);

      const url = requestUrls.getBffUrl(requestUrls.account.loyaltyOptIn);
      const finalData = {
        program: program,
        memberNumber: memberId,
      };

      const response = await api.post(url, finalData);
      this.setUserProfile(response.data);
      this.updateLoyaltyDisplayStatus();

      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      this.setLoading(false);
      throw e;
    }
  }

  async updateLoyaltyProgram(program: string, memberId?: string) {
    try {
      this.setLoading(true);

      const url = requestUrls.getBffUrl(requestUrls.account.loyaltyOptIn);
      const finalData = {
        program: program,
        memberNumber: memberId,
      };

      const response = await api.put(url, finalData);
      this.setUserProfile(response.data);
      this.updateLoyaltyDisplayStatus();

      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      this.setLoading(false);
      throw e;
    }
  }

  async deleteLoyaltyProgram(loyaltyProgramId) {
    try {
      this.setLoading(true);

      const url = requestUrls
        .getBffUrl(requestUrls.account.loyaltyOptOut)
        .replace('{loyaltyProgramId}', loyaltyProgramId);

      const response = await api.delete(url);
      this.setUserProfile(response.data);
      this.updateLoyaltyDisplayStatus();

      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      this.setLoading(false);
      throw e;
    }
  }

  updateLoyaltyDisplayStatus() {
    this.setDisplayLoyaltyComponents(
      isLoyaltyEnabled() &&
        !this.userProfile?.travelAgentCode &&
        this.userProfile?.loyaltyPrograms?.some(
          program => program.program.toLowerCase() === 'brightline' && program.active
        )
    );
  }

  async getLoyaltyUserStatus() {
    let user_status = '';
    const mobileJwt = localStorage.getItem('mobileJwt');

    await this.fetchUserProfile(mobileJwt ? mobileJwt : '');
    if (this.userProfile?.loyaltyPrograms) {
      const brightlineProgram = this.userProfile.loyaltyPrograms.find(
        program => program.program?.toLowerCase() === 'brightline'
      );

      if (brightlineProgram) user_status = brightlineProgram.active ? 'optin' : 'optout';
    }
    // localStorage.removeItem("mobileJwt")

    return user_status;
  }
}
