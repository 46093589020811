import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import OutsideClickHandler from 'react-outside-click-handler';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './Breadcrumbs.scss';
import BreadcrumbItem from './BreadcrumbsItem';
import { BreadcrumbsProps } from './types';

const bem = cn('breadcrumbs');

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { items = [], id, hidden, colors, breakpointLimitItems } = props;
  const { hiddenMobile, hiddenTablet, hiddenTabletLarge, hiddenDesktop } = props;

  const [expandedBreadcrumbs, setExpandedBreadcrumbs] = useState(false);
  const [loading, setLoading] = useState(true);

  const moreRef = useRef(null);

  useEffect(() => setLoading(false), []);

  const shownBreadcrumbs = () => {
    setExpandedBreadcrumbs(true);
  };

  const hideBreadcrumbs = () => {
    setExpandedBreadcrumbs(false);
  };

  const vars: any = useMemo(
    () => ({
      '--text-color-inactive': colors?.inactive?.inactive,
      '--text-hover-color-inactive': colors?.inactive?.hover,
      '--text-color-active': colors?.active,
      '--icon-color': colors?.iconColor,
      '--border-focus-color': colors?.border?.focus,
    }),
    [colors]
  );

  const visibleItems: any = useMemo(() => items.filter(item => !item.hidden), [items]);

  const isMobile = useMediaPredicate(mediaQueryList.maxMobile);
  const isTablet = useMediaPredicate(mediaQueryList.maxTablet);
  const isTabletLarge = useMediaPredicate(mediaQueryList.maxTabletLarge);

  const limitPerBreakpoint = useMemo(
    () => ({
      [mediaQueryList.maxMobile]: breakpointLimitItems.mobile,
      [mediaQueryList.maxTablet]: breakpointLimitItems.tablet,
      [mediaQueryList.maxTabletLarge]: breakpointLimitItems.tabletLarge,
      [mediaQueryList.maxDesktop]: breakpointLimitItems.desktop,
      [mediaQueryList.desktopLarge]: breakpointLimitItems.desktopLarge,
    }),
    [breakpointLimitItems]
  );

  const nrOfVisibleItems = useMemo(() => {
    if (isMobile) return limitPerBreakpoint[mediaQueryList.maxMobile];
    else if (isTablet) return limitPerBreakpoint[mediaQueryList.maxTablet];
    else if (isTabletLarge) return limitPerBreakpoint[mediaQueryList.maxTabletLarge];

    return limitPerBreakpoint[mediaQueryList.maxDesktop];
  }, [isMobile, isTablet, isTabletLarge, limitPerBreakpoint]);

  const firstItems = useMemo(
    () => (visibleItems.length > nrOfVisibleItems ? [visibleItems[0]] : []),
    [visibleItems, nrOfVisibleItems]
  );

  const middleItems = useMemo(
    () => (visibleItems.length > nrOfVisibleItems ? visibleItems.slice(1, visibleItems.length - 1) : visibleItems),
    [visibleItems, nrOfVisibleItems]
  );

  const lastItems = useMemo(
    () => (visibleItems.length > nrOfVisibleItems ? [visibleItems[visibleItems.length - 1]] : []),
    [visibleItems, nrOfVisibleItems]
  );

  const renderMiddleItems = useMemo(() => {
    if (visibleItems.length > nrOfVisibleItems)
      return (
        <li className={bem('expand-wrapper')}>
          <OutsideClickHandler onOutsideClick={hideBreadcrumbs}>
            <button ref={moreRef} className={bem('more-breadcrumbs')} onClick={shownBreadcrumbs}>
              <span>...</span>
            </button>
            <ol className={bem('popover-body', { hidden: !expandedBreadcrumbs })}>
              {middleItems.map(breadcrumb => (
                <BreadcrumbItem {...breadcrumb} key={`middle-item-${breadcrumb.id}`} colors={colors} />
              ))}
            </ol>
          </OutsideClickHandler>
        </li>
      );

    return middleItems.map(breadcrumb => (
      <BreadcrumbItem {...breadcrumb} key={`middle-item-${breadcrumb.id}`} colors={colors} />
    ));
  }, [visibleItems, expandedBreadcrumbs, middleItems, colors, nrOfVisibleItems]);

  return (
    <nav
      className={bem({
        hidden,
        hidden_mobile: hiddenMobile,
        hidden_tablet: hiddenTablet,
        hidden_tablet_large: hiddenTabletLarge,
        hidden_desktop: hiddenDesktop,
      })}
      id={id}
      style={vars}
      aria-label="breadcrumbs"
    >
      {!!items.length && (
        <ol className={bem('list')}>
          {loading ? (
            visibleItems.map(breadcrumb => (
              <BreadcrumbItem {...breadcrumb} key={`first-item-${breadcrumb.id}`} colors={colors} />
            ))
          ) : (
            <>
              {firstItems.map(breadcrumb => (
                <BreadcrumbItem {...breadcrumb} key={`first-item-${breadcrumb.id}`} colors={colors} />
              ))}
              {renderMiddleItems}
              {lastItems.map(breadcrumb => (
                <BreadcrumbItem {...breadcrumb} key={`last-item-${breadcrumb.id}`} colors={colors} />
              ))}
            </>
          )}
        </ol>
      )}
    </nav>
  );
};

export default Breadcrumbs;
