import React from 'react';

import { getFooterDate } from '@account/routes/MyTrips/utils';

import { cn, cx } from 'utils/classNames';

import './FooterDateAndBookingNumber.scss';
import { FooterDateAndBookingNumberProps } from './types';

const bem = cn('footer-date-and-booking-number');

const FooterDateAndBookingNumber = (props: FooterDateAndBookingNumberProps) => {
  const { referenceNumber, outboundRoute, inboundRoute, variant = 'upcoming-trip' } = props;

  return (
    <div className={cx(bem({ variant }))}>
      <span className={bem('footer-date')}>{getFooterDate(outboundRoute, inboundRoute)}</span>
      <span className={bem('referenceNumber')}>#{referenceNumber}</span>
    </div>
  );
};

export default FooterDateAndBookingNumber;
