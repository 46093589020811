import { Form, useFormikContext } from 'formik';
import React from 'react';

import { Button } from '@atoms/Button';
import { InputField } from '@atoms/Input';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { WifiSplashFormFormikProps } from './../../types';
import './WifiSplashFormFormik.scss';

const bem = cn('wifi-splash-form-formik');

export const WifiSplashFormFormik = (props: WifiSplashFormFormikProps) => {
  const { firstName, firstNameRequired, lastName, lastNameRequired, email, emailRequired } = props;
  const { postalCode, postalCodeRequired, marketingOptIn, cta } = props;

  const { isSubmitting, submitForm, setTouched } = useFormikContext();

  const onLoginButtonClicked = () => {
    setTouched(true, true);
    submitForm && submitForm();
  };

  return (
    <Form>
      <div className={bem()}>
        <div className={bem('formik-wrapper')}>
          <InputField
            name="firstName"
            label={firstName}
            errorVariant="inline"
            fieldsetClassName={bem('input')}
            required={firstNameRequired}
          />
          <InputField
            name="lastName"
            label={lastName}
            errorVariant="inline"
            fieldsetClassName={bem('input')}
            required={lastNameRequired}
          />
          <InputField
            name="email"
            label={email}
            errorVariant="inline"
            fieldsetClassName={bem('input')}
            required={emailRequired}
          />
          <InputField
            name="postalCode"
            label={postalCode}
            errorVariant="inline"
            fieldsetClassName={bem('input')}
            required={postalCodeRequired}
          />
        </div>
        {marketingOptIn && <Text text={marketingOptIn} className={bem('marketing')} />}
        <div className={bem('button-row')}>
          <Button {...cta} link={undefined} loading={isSubmitting} onClick={onLoginButtonClicked} type="button" />
        </div>
      </div>
    </Form>
  );
};
