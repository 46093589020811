import { observer } from 'mobx-react-lite';
import React from 'react';
import { rootStore, useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Text } from '@atoms/Text';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';
import { isForcedLoginEnabled } from 'utils/metas';

import { clickAnalyticsEvent } from '../../../../../../utils/adobeDataLayer';
import './ForcedLoginErrorModal.scss';

const bem = cn('forced-login-error-modal');

export const ForcedLoginErrorModal = observer(() => {
  const { bookingStore, cfStore } = useStores();
  const { forcedLoginError, setForcedLoginError } = bookingStore.tickets;
  const { forcedLogin } = cfStore.trainSelection;

  const handleCloseModal = () => {
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        customLink: 'interstitialModal',
        externalLink: '',
        linkType: 'interstitialModal',
        navigationLinkName: 'searchAgain',
        pageNameClickTracking: 'booking|trainSelection',
      },
    });

    setForcedLoginError(false);
  };

  if (!isForcedLoginEnabled() || !forcedLoginError) return null;

  return (
    <Modal variant="mini">
      <div className={bem()}>
        <Text text={forcedLogin.errorModalText} className={bem('text')} />
        <Button label={forcedLogin.searchAgainButtonLabel} color="black" onClick={handleCloseModal} />
      </div>
    </Modal>
  );
});
