import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

import SelectField from '@atoms/Select/SelectField';
import Title from '@atoms/Title/Title';
import { TrainPassFromToProps } from '@organisms/TrainPasses/types';

import { cn } from 'utils/classNames';
import { color_base_black } from 'utils/colors';

const bem = cn('train-passes-from-to');

export const FromTo = observer((props: TrainPassFromToProps) => {
  const { i18n, stationAdaptions, useTrainPassLogic } = props;

  const { stations, setStep, loading, setTotal } = useTrainPassLogic;
  const [priceUpdateText, setPriceUpdateText] = useState('');

  const { values: formValues, setFieldValue } = useFormikContext();
  const values = formValues as any;

  const fromStationsData = useMemo(
    () =>
      stations.map(station => ({
        id: station.id,
        label: station.name,
        value: station.name,
      })),
    [stations]
  );

  const toStationsData = useMemo(() => {
    if (!values.travelFrom) return [];

    const fromStation = stations.find(station => station.name === values.travelFrom);
    if (!fromStation) return [];

    return fromStation.routes.map(route => ({
      id: route.id,
      label: route.name,
      value: route.name,
    }));
  }, [stations, values.travelFrom]);

  const handleFromStationSelected = () => {
    setFieldValue('travelTo', '');
    setPriceUpdateText('');
    setTotal(0);
    setStep(1);
  };

  const checkCombinationWithPriceUpdate = value => {
    const travelToAbb = stations.find(station => station.name === value).abbreviation;
    const travelFromAbb = stations.find(station => station.name === values.travelFrom).abbreviation;
    const travelFromOld = values.travelFrom;
    let hasUpdate = false;

    const updateTravelStations = replacementStations => {
      setFieldValue(
        'travelFrom',
        stations.find(station => station.abbreviation === replacementStations.newOriginStation).name
      );
      setFieldValue(
        'travelTo',
        stations.find(station => station.abbreviation === replacementStations.newDestinationStation).name
      );
      hasUpdate = true;
    };

    const replacement = stationAdaptions?.find(
      obj => obj.oldOriginStation === travelFromAbb && obj.oldDestinationStation === travelToAbb
    );

    if (replacement) updateTravelStations(replacement);

    if (hasUpdate) {
      const finalText = i18n.priceUpdateLabel?.replace('{station1}', travelFromOld).replace('{station2}', value);
      setPriceUpdateText(finalText || '');
    }
  };

  const handleStationsChanged = value => {
    setTotal(0);
    setFieldValue('option', '');
    setPriceUpdateText('');
    checkCombinationWithPriceUpdate(value);
    setFieldValue('category', '');
    setStep(2);
  };

  return (
    <div className={bem()}>
      <div className={bem('subtitle')}>
        <Title size="h3" text={i18n?.destinationSubtitle} color={color_base_black} />
      </div>
      <div className={bem('row')}>
        <SelectField
          name="travelFrom"
          label={i18n.travelFromLabel}
          optionsData={fromStationsData}
          disabled={loading}
          customOnChange={handleFromStationSelected}
          useDesktopVariant
        />
        <SelectField
          name="travelTo"
          label={i18n.travelToLabel}
          optionsData={toStationsData}
          disabled={loading}
          customOnChange={handleStationsChanged}
          useDesktopVariant
        />
      </div>
      {priceUpdateText && <div className={bem('price-update')}>{priceUpdateText}</div>}
    </div>
  );
});
