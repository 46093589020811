import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';
import { SelectedItemConfig } from 'stores/BookingStore/sections/Tickets/types';
import { SeatProduct } from 'stores/BookingStore/sections/Tickets/types.journey';

import LinkButton from '@atoms/LinkButton/LinkButton';
import Radio from '@atoms/Radio/Radio';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './FareItem.scss';
import { FareItemProps } from './types';

const bem = cn('fare-selection-item');

export const FareItem = observer((props: FareItemProps) => {
  const { title, description, shortDescription, variant, isItemAvailable: { isAvailable } } = props;
  const [isOpen, setIsOpen] = useState(false);

  const { cfStore, bookingStore } = useStores();
  const { trainSelection, generic } = cfStore;
  const { faresCompare } = trainSelection;
  const { learnMore, hideDetails, fareUnavailable } = faresCompare;
  const { selectedItem, selectItem, modifyTripProps } = bookingStore.tickets;
  const { journey, prices, ticket } = (selectedItem as SelectedItemConfig) || {};

  const toggleDetails = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const replaceText = text =>
    text
      .replaceAll('{Including}', `<p class='sr-only'>including</p>`)
      .replaceAll('{AdditionalFee}', `<p class='sr-only'>for an additional fee</p>`);

  const handleSelect = () => {
    if (isAvailable && journey) {
      const { defaultPremiumProduct, seatProducts } = journey;

      const smartSeatProduct = seatProducts.find(el => el.productCode === 'SMART_STANDARD') as SeatProduct | undefined;

      const defaultProduct =
        variant === 'SMART_STANDARD' ? { productId: smartSeatProduct?.productCode } : defaultPremiumProduct;
      selectItem(journey, defaultProduct);
    }
  };

  const getFormattedPrice = price => {
    if (modifyTripProps) {
      const pricePrefix = (price || 0) < 0 ? '-' : '+';

      return `${pricePrefix}$ ${Math.abs(price || 0).toFixed(2)}`;
    }

    return `$ ${price?.toFixed(2)}`;
  };

  if (!(journey && ticket && prices)) return null;

  const isSelected = ticket.productId === variant;
  const fareVariant = variant === 'SMART_STANDARD' ? 'smart' : 'premium';

  return (
    <div
      className={bem({ fareVariant, unavailable: !isAvailable, selected: isSelected, expanded: isOpen })}
      onClick={handleSelect}
    >
      <div className={bem('header')}>
        <h4 className={bem('title')}>{title}</h4>
        <Radio
          name={fareVariant}
          label=""
          className={bem('radio')}
          checked={isSelected}
          disabled={!isAvailable}
          onChange={handleSelect}
        />
      </div>
      {!isAvailable && (
        <div className={bem('content')}>
          <p className={bem('description')}>{fareUnavailable}</p>
        </div>
      )}
      {isAvailable && (
        <div className={bem('content')}>
          <div className={bem('cost')}>
            <h3 className={bem('price')}>{getFormattedPrice(prices[variant])}</h3>
            {prices[variant] !== prices[`original${variant}`] && (
              <h4 className={bem('originalPrice')}>{getFormattedPrice(prices[`original${variant}`])}</h4>
            )}
            <span className={bem('passenger')}>{generic.perPassengerLong}</span>
          </div>
          <p className={bem('description')}>{shortDescription}</p>
          <div className={bem('details')}>
            <LinkButton
              label={isOpen ? hideDetails : learnMore}
              className={bem('more')}
              variant="big"
              onClick={toggleDetails}
              underline
            />
            <Text text={replaceText(description)} className={bem('fareClassOptions')} />
          </div>
        </div>
      )}
    </div>
  );
});
