import React from 'react';

import { colors } from '@atoms/LoadingIcon/constants';

import { LoadingIconProps } from './types';

const LoadingIcon = (props: LoadingIconProps) => {
  const { fillColorCircle = colors.colorBaseMediumGrey, fillColorProgress = colors.colorBaseBlack, className } = props;
  const { width = 16, height = 16 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.7459 8C1.7459 11.454 4.54596 14.2541 8 14.2541C11.454 14.2541 14.2541 11.454 14.2541 8C14.2541 4.54596 11.454 1.7459 8 1.7459C4.54596 1.7459 1.7459 4.54596 1.7459 8Z"
        fill={fillColorCircle}
      />
      <path
        d="M8 0C9.05058 1.2528e-08 10.0909 0.206926 11.0615 0.608964C12.0321 1.011 12.914 1.60028 13.6569 2.34315C14.3997 3.08602 14.989 3.96793 15.391 4.93853C15.7931 5.90914 16 6.94943 16 8H14.2541C14.2541 7.1787 14.0923 6.36544 13.778 5.60666C13.4637 4.84788 13.0031 4.15843 12.4223 3.57769C11.8416 2.99694 11.1521 2.53627 10.3933 2.22197C9.63456 1.90767 8.8213 1.7459 8 1.7459V0Z"
        fill={fillColorProgress}
      />
    </svg>
  );
};

export default LoadingIcon;
