import { useFormikContext } from 'formik';
import moment from 'moment';
import { useEffect, useMemo } from 'react';

import { countries } from '@molecules/PaymentForm/countryAndStates';

import { MonthlyParkingPassesFormProps } from '../types';

export const useMonthlyParkingPassesLogic = (props: MonthlyParkingPassesFormProps) => {
  const { options, stations, fetchParkingPassOptions, parsedUrlData, stationsData } = props;

  const { values: formValues, setFieldValue, submitForm, errors: formErrors } = useFormikContext();
  const values = formValues as any;
  const errors = formErrors as any;

  useEffect(() => {
    let fieldValue;
    if (parsedUrlData) fieldValue = stationsData.find(stationData => stationData.value === parsedUrlData.ppStationName);
    else fieldValue = stationsData.find(stationData => stationData.value === 'Miami');

    if (fieldValue) setFieldValue('ppStationName', fieldValue.value);
    else if (stationsData.length) setFieldValue('ppStationName', stationsData[0].value);
  }, [stationsData, parsedUrlData, setFieldValue]);

  const stateOptions =
    countries
      .find(country => country.country == 'United States')
      .states?.map(state => ({
        label: state,
        value: state,
      })) || [];

  const option = useMemo(() => {
    if (!options.length) return undefined;

    return options[0];
  }, [options]);

  const totalPriceConfig = useMemo(() => {
    if (!option)
      return {
        total: 0,
        totalDiscounted: 0,
      };

    return {
      total: option.price.toFixed(2),
      totalDiscounted: values.autoRenew && option.autoRenewProduct ? option.autoRenewProduct.price.toFixed(2) : 0,
    };
  }, [option, values.autoRenew]);

  const handleStationChanged = value => {
    if (!value) return;

    const station = stations.find(station => station.name === value);

    if (station) fetchParkingPassOptions(station.id, moment().add(2, 'days').startOf('day').toISOString());
  };

  return {
    stateOptions,
    totalPriceConfig,
    handleStationChanged,
    errors,
    submitForm,
    values,
    setFieldValue,
  };
};
