import { useState } from 'react';

export const useTabHandlers = (props: any) => {
  const { items, onSelect } = props;

  const [touched, setTouched] = useState(false);

  const [focus, setFocus] = useState({
    index: 0,
  });

  const handleItemClick = (newIndex?: number) => e => {
    e.preventDefault();
    if (onSelect) onSelect(newIndex || 0)(e);
  };

  const handleKeyDown = e => {
    switch (e.key) {
      case 'Enter':
        handleItemClick()(e);
        break;
      case 'ArrowRight':
        if (items[focus.index + 1]) handleItemClick(focus.index + 1)(e);
        break;
      case 'ArrowLeft':
        if (items[focus.index - 1]) handleItemClick(focus.index - 1)(e);
        break;
    }
  };

  return {
    focus,
    setFocus,
    items,
    handleItemClick,
    handleKeyDown,
    touched,
    setTouched,
  };
};
