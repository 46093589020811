import React from 'react';

import { Icon } from '@atoms/Icon';
import '@molecules/TicketsForm/components/EditSection/EditSection.scss';

import { cn } from 'utils/classNames';

const bem = cn('edit-section');

interface EditSectionProps {
  children: any;
  onClick?: any;
  disabled?: boolean;
}

export const EditSection = ({ children, onClick, disabled }: EditSectionProps) => (
  <div className={bem()}>
    {children}
    <button className={bem('button')} onClick={onClick} aria-label="edit" disabled={disabled}>
      {!disabled && <Icon name="edit" />}
    </button>
  </div>
);
