export const hasTouchedFieldErrors = (errors, getFieldMeta, parentFieldName, subFieldName) => {
  let hasTouchedError = false;
  if (errors?.length)
    errors.forEach((error, index) => {
      if (error && error[subFieldName])
        for (const errFieldName of Object.keys(error[subFieldName])) {
          const meta = getFieldMeta(`${parentFieldName}[${index}].${subFieldName}.${errFieldName}`);
          if (meta.touched) {
            hasTouchedError = true;
            break;
          }
        }
    });

  return hasTouchedError;
};

export const getPriceDataForProduct = (product, reduceFn) => {
  const reducedVal = product.passengers.reduce(reduceFn, 0);

  if (!reducedVal) return null;

  const price = (product.passengers[0].outboundSelectionParameters || product.passengers[0].inboundSelectionParameters)
    ?.pricePerItem;

  return {
    amount: reducedVal,
    price: price,
  };
};

export const makeDelay = ms => new Promise(resolve => setTimeout(resolve, ms));
