import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useStores } from 'stores';
import { ModifyTripConfig } from 'stores/BookingStore/sections/Tickets/types';

import { useFareSelectionDrawerLogic } from '@booking/routes/TrainSelection/components/FareSelectionDrawer/useFareSelectionDrawerLogic';
import { PromoCodeDetailsResponse } from '@molecules/PromoCode/types';
import { validatePromoCodeResponse } from '@molecules/PromoCode/utils';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { base64Decode } from 'utils/helpers';
import { mediaQueryList } from 'utils/mediaQueries';

import { setBookingCreationTime } from '../../../../utils/localstorage';
import { HeaderProps } from './types';

export const useDataFromQueryParams = ({ validateOnMount, displaySearchWidget: displaySearch }: HeaderProps) => {
  const [loaded, setLoaded] = useState(false);

  const { navigateToRTI } = useFareSelectionDrawerLogic();

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);
  const isTabletLarge = useMediaPredicate(mediaQueryList.maxTabletLarge);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const location = useLocation();

  const { ticketsFormStore, bookingStore, cfStore, authStore, accountStore } = useStores();
  const { stationsData, fetchStationsData, formValues, setFormValues, changeTripValue } = ticketsFormStore;
  const { showPanel, setIncompleteSearch } = ticketsFormStore;
  const { searchTickets, searchTicketsModifyTrip, setLoading, setItemsPerViewPort } = bookingStore.tickets;
  const { hasLoadedQueryParams, setHasLoadedQueryParams, setModifyTripProps, setPremiumOnly } = bookingStore.tickets;
  const { setTrainSelectionError, setForcedLoginError } = bookingStore.tickets;
  const { createProvisionalBooking } = bookingStore.rti;
  const { fetchUserProfile } = accountStore.profile;
  const promoCodeI18n = cfStore.header.ticketsForm.promoCode.i18n;

  const displaySearchWidget = useMemo(
    () => location.pathname === '/' || location.pathname === '/train-selection' || displaySearch,
    [location, displaySearch]
  );

  const getDataFromQueryParams = useCallback(async () => {
    try {
      const hasLocationState = location.state && location.state.ticket;
      const searchParams = hasLocationState
        ? location.state.ticket
        : Object.fromEntries(new URLSearchParams(location.search));

      const DEFAULT_TIME_ZONE = 'America/New_York';

      const {
        from = '',
        to = '',
        adults = '1',
        kids = '0',
        infants = '0',
        promo_code,
        wc_adults = '0',
        wc_children = '0',
        contract_access_code = '',
      } = searchParams;
      const { trip, start_date, end_date, ticketData } = searchParams;

      let parsedTicketData;
      if (ticketData)
        try {
          parsedTicketData = JSON.parse(base64Decode(ticketData));
        } catch (e) {
          console.error(e);
        }

      const stations = stationsData.length ? stationsData : await fetchStationsData();
      const tz = from ? stations.find(el => el.id === from)?.timeZone : DEFAULT_TIME_ZONE;
      const pastStartDate = start_date && moment(start_date).isBefore(moment(), 'day');
      const pastEndDate = end_date && moment(end_date).isBefore(moment(), 'day');
      const minDate = moment().tz(tz).format('YYYY-MM-DD');

      setSearchParams(searchParams => {
        searchParams.delete('ticketData');

        return searchParams;
      });

      if (pastStartDate && pastEndDate)
        setSearchParams({
          ...searchParams,
          start_date: pastStartDate ? minDate : start_date,
          end_date: pastEndDate ? minDate : end_date,
        });
      else if (pastStartDate)
        setSearchParams({
          ...searchParams,
          start_date: pastStartDate ? minDate : start_date,
        });
      else if (pastEndDate)
        setSearchParams({
          ...searchParams,
          end_date: pastStartDate ? minDate : end_date,
        });
      else if (hasLocationState) setSearchParams(searchParams);

      const start_date_moment = pastStartDate ? moment().tz(tz) : moment(start_date);
      const end_date_moment = pastEndDate ? moment().tz(tz) : moment(end_date);

      const newFormValues: {
        infants: number;
        endDate: moment.Moment | string;
        adults: number;
        from: string;
        promoCode: string;
        promoCodeDetails?: PromoCodeDetailsResponse;
        to: string;
        startDate: moment.Moment | string;
        kids: number;
        wcAdults: number;
        wcChildren: number;
        rn?: string;
        contractAccessCode?: string;
      } = {
        from,
        to,
        startDate: start_date ? start_date_moment : '',
        endDate: end_date ? end_date_moment : '',
        promoCode: promo_code,
        promoCodeDetails: undefined,
        adults: parseInt(adults),
        kids: parseInt(kids),
        infants: parseInt(infants),
        wcAdults: parseInt(wc_adults),
        wcChildren: parseInt(wc_children),
        contractAccessCode: contract_access_code,
      };

      if (newFormValues.promoCode)
        try {
          const promoCodeDetails = await api.get<PromoCodeDetailsResponse>(
            `${requestUrls.getRestUrl(requestUrls.bookingSearch.promoCodeDetails)}.xjson`,
            {
              params: {
                promoCode: newFormValues.promoCode,
              },
            }
          );

          const validationResult = validatePromoCodeResponse(
            promoCodeDetails.data,
            promoCodeI18n,
            newFormValues?.startDate,
            newFormValues?.endDate
          );

          if (!validationResult.valid) newFormValues.promoCode = '';
          else newFormValues.promoCodeDetails = promoCodeDetails.data;
        } catch (e) {
          newFormValues.promoCode = '';
        }

      const areFieldsValid = params =>
        !['from', 'to', 'adults'].find(field => !Object.keys(params).includes(field) || !params[field]);

      const validParams = validateOnMount ? areFieldsValid(searchParams) : true;

      if (authStore.isAuthenticated) await fetchUserProfile();

      if (validParams) {
        setIncompleteSearch(false);
        setModifyTripProps(undefined);
        if (ticketData && parsedTicketData && parsedTicketData.outboundTicket && authStore.isAuthenticated) {
          const bookingResponse = await createProvisionalBooking(
            parsedTicketData.outboundTicket,
            parsedTicketData.inboundTicket,
            parsedTicketData.promoCode
          );
          if (!bookingResponse) {
            setForcedLoginError(true);
            await searchTickets(newFormValues);
          } else {
            setFormValues(newFormValues);

            if (parseInt(trip) === 0 || parseInt(trip) === 1) changeTripValue({ selectedIndex: parseInt(trip) });

            setBookingCreationTime({
              bookingNumber: bookingResponse?.tripDetails?.trip.referenceNumber,
              createdAt: Date.now(),
            });

            setLoading(false);
            setHasLoadedQueryParams(false);
            navigateToRTI(bookingResponse);

            return;
          }
        } else await searchTickets(newFormValues);
      } else {
        setIncompleteSearch(true);
        showPanel();
        setSearchParams(searchParams => {
          searchParams.delete('ticketData');

          return searchParams;
        });
      }

      setFormValues(newFormValues);

      if (parseInt(trip) === 0 || parseInt(trip) === 1) changeTripValue({ selectedIndex: parseInt(trip) });

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setTrainSelectionError(e);
    }
    // eslint-disable-next-line
  }, [
    authStore.isAuthenticated,
    createProvisionalBooking,
    changeTripValue,
    location.search,
    location.state,
    searchTickets,
    setFormValues,
    setModifyTripProps,
    setIncompleteSearch,
    setLoading,
    setSearchParams,
    showPanel,
    validateOnMount,
    fetchStationsData,
    stationsData,
    promoCodeI18n,
    setTrainSelectionError,
    setForcedLoginError,
    setHasLoadedQueryParams,
  ]);

  const getDataFromQueryParamsModifyTrip = useCallback(async () => {
    try {
      const searchParams = JSON.parse(base64Decode(Object.fromEntries(new URLSearchParams(location.search)).data));

      const DEFAULT_TIME_ZONE = 'America/New_York';

      const { rn, start_date, end_date, fn, ln } = searchParams;

      if (!fn && !ln) throw 'Passenger firstname and lastname are mandatory params when modifying a trip';
      if (!start_date && !end_date) throw 'Start date or end date are mandatory params when modifying a trip';

      const tripDetailsResult = await api.get(`${requestUrls.getRestUrl(requestUrls.rti.searchTrip)}.xjson`, {
        params: {
          reference_number: rn.toUpperCase(),
          first_name: fn,
          last_name: ln,
        },
      });
      const tz = tripDetailsResult?.data?.tripDetails?.trip?.outboundRoute?.origin?.timeZone || DEFAULT_TIME_ZONE;

      const pastStartDate = start_date && moment(start_date).isBefore(moment(), 'day');
      const pastEndDate = end_date && moment(end_date).isBefore(moment(), 'day');

      const minDate = moment().tz(tz).format('YYYY-MM-DD');

      if (pastStartDate && pastEndDate)
        setSearchParams({
          ...searchParams,
          start_date: pastStartDate ? minDate : start_date,
          end_date: pastEndDate ? minDate : end_date,
        });
      else if (pastStartDate)
        setSearchParams({
          ...searchParams,
          start_date: pastStartDate ? minDate : start_date,
        });
      else if (pastEndDate)
        setSearchParams({
          ...searchParams,
          end_date: pastStartDate ? minDate : end_date,
        });

      const start_date_moment = pastStartDate ? moment().tz(tz) : moment(start_date);
      const end_date_moment = pastEndDate ? moment().tz(tz) : moment(end_date);

      const newFormValues: ModifyTripConfig = {
        ...formValues,
        startDate: start_date ? start_date_moment : '',
        endDate: end_date ? end_date_moment : '',
        rn,
        fn,
        ln,
        tripDetails: tripDetailsResult.data.tripDetails,
        bookingSession: tripDetailsResult.data.bookingSession,
        isRoundTrip: !!start_date && !!end_date,
      };

      const trip = newFormValues.tripDetails?.trip;

      if (newFormValues.isRoundTrip || !newFormValues.endDate)
        //MODIFYING ONLY OUTBOUND

        setPremiumOnly(trip?.outboundRoute?.classType === 'premium');
      //MODIFYING ONLY INBOUND
      else setPremiumOnly(trip?.inboundRoute?.classType === 'premium');

      setIncompleteSearch(false);
      setModifyTripProps(newFormValues);
      await searchTicketsModifyTrip(newFormValues);

      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setTrainSelectionError(e);
    }
  }, [
    location.search,
    searchTicketsModifyTrip,
    formValues,
    setModifyTripProps,
    setIncompleteSearch,
    setLoading,
    setSearchParams,
    setPremiumOnly,
    setTrainSelectionError,
  ]);

  useEffect(() => {
    if (!displaySearchWidget) return;

    const leftRight = isMobile ? 1 : isTabletLarge ? 2 : 3;
    const visibleSlides = isMobile ? 3 : isTabletLarge ? 5 : 7;

    setItemsPerViewPort({ leftRight, visibleSlides });
  }, [displaySearchWidget, isMobile, isTabletLarge, setItemsPerViewPort]);

  useEffect(() => {
    if (displaySearchWidget && !stationsData.length) fetchStationsData();
  }, [displaySearchWidget, stationsData.length, fetchStationsData]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!displaySearchWidget) return;

    if (loaded && !hasLoadedQueryParams) {
      const searchParams = Object.fromEntries(new URLSearchParams(location.search));

      setHasLoadedQueryParams(true);

      const { data } = searchParams;

      if (!data) getDataFromQueryParams();
      else getDataFromQueryParamsModifyTrip();
    }
  }, [
    displaySearchWidget,
    getDataFromQueryParams,
    getDataFromQueryParamsModifyTrip,
    loaded,
    hasLoadedQueryParams,
    setHasLoadedQueryParams,
    location.search,
  ]);

  return {
    displaySearchWidget,
  };
};
