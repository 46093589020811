import { observer } from 'mobx-react-lite';
import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { TopLoadingBar } from '@atoms/TopLoadingBar';

import { cn } from 'utils/classNames';
import { clearLoginRedirectURL, getLoggedOutFlag } from 'utils/localstorage';

const bem = cn('passes');
const bemPage = cn('passes-page');

export const Root = observer(() => {
  const handleUnload = () => {
    const activeElement = document?.activeElement as HTMLAnchorElement;
    if (
      (!activeElement ||
        !activeElement.href ||
        (!activeElement.href.toLowerCase().endsWith('login') &&
          !activeElement.href.toLowerCase().endsWith('account'))) &&
      !getLoggedOutFlag()
    )
      clearLoginRedirectURL();
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  return (
    <div className={bem()}>
      <TopLoadingBar />
      <div className={bemPage()}>
        <Suspense fallback={<div />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
});
