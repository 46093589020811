import { useEffect, useState } from 'react';

export const useRestoreScroll = () => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    window.scrollTo(scrollPosition.x, scrollPosition.y);
  }, [scrollPosition.x, scrollPosition.y]);

  return {
    scrollPosition,
    setScrollPosition,
  };
};
