import React from 'react';

import Image from '@atoms/Image/Image';

import { cn } from 'utils/classNames';

import { ContentHeader } from '../ContentHeader';
import './EmptyView.scss';
import { EmptyViewProps } from './types';

const bem = cn('empty-view');

export const EmptyView = (props: EmptyViewProps) => {
  const { pageTitle, icon, title, subtitle, actions } = props;

  return (
    <div className={bem()}>
      <ContentHeader pageTitle={pageTitle} />
      <div className={bem('content')}>
        <Image {...icon} alt={icon?.alt || ''} />
        <div className={bem('title')}>{title}</div>
        <div className={bem('subtitle')}>{subtitle}</div>
        {actions}
      </div>
    </div>
  );
};
