import cx from 'classnames';
import React from 'react';

import { ColumnProps } from './types';

export const Column = ({
  cols = 12,
  offset = 0,
  hide = false,
  mq = {},
  customClassName,
  isBookingTripSelection,
  children,
}: ColumnProps) => {
  const defaultColumnsClassName = `aem-GridColumn aem-GridColumn--default--${cols}`;
  const defaultOffsetClassName = `aem-GridColumn--offset--default--${offset}`;
  const defaultVisibility = `aem-GridColumn--default--${hide ? 'hide' : 'none'}`;

  const breakpointClassNames = Object.keys(mq).reduce((arr: string[], key: string) => {
    if (mq[key].cols) arr.push(`aem-GridColumn--${key}--${mq[key].cols}`);

    if (mq[key].offset || mq[key].offset === 0) arr.push(`aem-GridColumn--offset--${key}--${mq[key].offset}`);

    if (mq[key].hide === true) arr.push(`aem-GridColumn--${key}--hide`);
    else if (mq[key].hide === false) arr.push(`aem-GridColumn--${key}--none`);

    return arr;
  }, []);

  const classNames = cx(
    customClassName,
    defaultColumnsClassName,
    defaultOffsetClassName,
    defaultVisibility,
    breakpointClassNames
  );

  return isBookingTripSelection ? (
    <ul className={classNames} style={{ listStyle: 'none', paddingLeft: 0, marginTop: 0, marginBottom: 0 }}>
      {children}
    </ul>
  ) : (
    <div className={classNames}>{children}</div>
  );
};
