import React from 'react';

import { cn, cx } from 'utils/classNames';

import './ContentHeader.scss';

const bem = cn('content-header');

export const ContentHeaderPlaceholder = () => (
  <div className={cx(bem(), bem('placeholders'))}>
    <button className={bem('back')} />
    <div className={bem('content')} />
  </div>
);
