import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { CreditPass } from './Passes/CreditPass';
import { TravelPass } from './Passes/TravelPass';
import './WalletItems.scss';

const bem = cn('rti-wallet-items');

const bemPaymentEntry = cn('payment-info-entry');

export const WalletItems = observer(() => {
  const { bookingStore, cfStore } = useStores();
  const { applicablePasses, travelPassCodes, creditPassCode } = bookingStore.rti;
  const { provisionalBooking } = bookingStore.rti;
  const { brightlineWallet } = cfStore.rti.reviewAndPay;

  const disabledPass = useMemo(
    () => provisionalBooking.tripDetails.costSummary?.bookingTotal.totalToBePaid === 0,
    [provisionalBooking.tripDetails.costSummary?.bookingTotal.totalToBePaid]
  );

  return (
    <div className={bem()}>
      <div className={bemPaymentEntry('section-header')}>
        <span className={bemPaymentEntry('section-header-title')}>{brightlineWallet.title}</span>
      </div>
      <div className={bem('passes')}>
        {applicablePasses?.map(el => {
          const { type } = el;

          if (type === 'travel') 
            return (
              <TravelPass
                key={`${el.travelPass.code}-${el.travelPass.passName}`}
                {...el}
                checked={travelPassCodes.includes(el.travelPass.code)}
                disabledPass={disabledPass}
              />
            );
          

          return (
            <CreditPass
              key={`${el.creditPass.code}-${el.creditPass.passName}`}
              {...el}
              checked={creditPassCode.includes(el.creditPass.code)}
              disabledPass={disabledPass}
            />
          );
        })}
      </div>
    </div>
  );
});
