export const breakpointList = [
  { name: 'mobile', width: 0 },
  { name: 'tablet', width: 576 },
  { name: 'tabletLarge', width: 768 },
  { name: 'desktop', width: 1024 },
  { name: 'desktopLarge', width: 1172 },
];

export interface MediaQueryListProps {
  maxMobile?: string;
  mobile?: string;
  maxTablet?: string;
  tablet?: string;
  maxTabletLarge?: string;
  tabletLarge?: string;
  maxDesktop?: string;
  desktop?: string;
  desktopLarge?: string;
}

export const mediaQueryList: any = breakpointList.reduce(
  (acc: MediaQueryListProps, item, idx) => ({
    ...acc,
    ...(item.width > 0 && { [item.name]: `screen and (min-width : ${item.width}px)` }),
    ...(breakpointList[idx + 1] && {
      [`max${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`]: `screen and (max-width : ${
        breakpointList[idx + 1].width - 1
      }px)`,
    }),
  }),
  {}
);
