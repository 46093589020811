import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Text } from '@atoms/Text';
import { SeatingNotAvailableProps } from '@booking/routes/RTI/SeatSelection/components/SeatingNotAvailable/types';

import { cn } from 'utils/classNames';

import './SeatingNotAvailable.scss';

const bem = cn('trip-seating-not-available');

export const SeatingNotAvailable = observer((props: SeatingNotAvailableProps) => {
  const { mode, isSmartSaver } = props;

  const { cfStore } = useStores();
  const { seatingNotAvailableMessage, seatingNotAvailableSmartSaverMessage } = cfStore.rti.seats;

  return (
    <div className={bem({ state: mode })}>
      <Text
        text={isSmartSaver ? seatingNotAvailableSmartSaverMessage : seatingNotAvailableMessage}
        className={bem('text')}
      />
    </div>
  );
});
