import React, { Children, useEffect, useMemo, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { PillButton } from '@atoms/PillButton';
import { TabsProps } from '@atoms/Tabs/types';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import { TabButton } from './TabButton';
import './tabs.scss';
import { useTabHandlers } from './useTabHandlers';

export const bem = cn('tabs');

export const Tabs = (props: TabsProps) => {
  const handleTabClick = index => e => {
    e.preventDefault();
    setActiveTabIndex(index);
    setFocus({
      index: index,
    });
    setTouched(true);
  };

  const { pillButtons, pillButtonsPosition, labels, defaultActiveTabIndex, children, tabsNotAccessible } = props;
  const { id, extraPillButton, design } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(defaultActiveTabIndex || 0);
  const isDesktopHD = useMediaPredicate(mediaQueryList.desktopLarge);
  const hasHeaders = useMemo(() => labels.some(label => label), [labels]);
  const hookProps = useTabHandlers({ items: labels, onSelect: handleTabClick });
  const { focus, handleKeyDown, setFocus, touched, setTouched } = hookProps;

  useEffect(() => {
    if (tabsNotAccessible?.length)
      for (const tab of tabsNotAccessible)
        if (tab.disabled) {
          setActiveTabIndex(tab.type === 'departure' ? 1 : 0);
          break;
        }
  }, [tabsNotAccessible]);

  const tabColors: any = useMemo(
    () => ({
      '--tabs-background-color': design?.tabs?.colors?.background?.inactive,
      '--tabs-background-hover-color': design?.tabs?.colors?.background?.hover,
      '--tabs-background-active-color': design?.tabs?.colors?.background?.active,
      '--tabs-text-color': design?.tabs?.colors?.text?.inactive,
      '--tabs-text-hover-color': design?.tabs?.colors?.text?.hover,
      '--tabs-text-active-color': design?.tabs?.colors?.text?.active,
      '--tabs-border-focus-color': design?.tabs?.colors?.border?.focus,
      '--pill-button-background-color': design?.pillButtons?.colors.background?.inactive,
      '--pill-button-background-hover-color': design?.pillButtons?.colors.background?.hover,
      '--pill-button-background-focus-color': design?.pillButtons?.colors.background?.focus,
      '--pill-button-background-disabled-color': design?.pillButtons?.colors.background?.disabled,
      '--pill-button-text-color': design?.pillButtons?.colors.text?.inactive,
      '--pill-button-text-hover-color': design?.pillButtons?.colors.text?.hover,
      '--pill-button-text-focus-color': design?.pillButtons?.colors.text?.focus,
      '--pill-button-text-disabled-color': design?.pillButtons?.colors.text?.disabled,
      '--pill-button-border-focus-color': design?.pillButtons?.colors.border?.focus,
      '--pill-button-shadow-color': design?.pillButtons?.colors.shadow?.inactive,
      '--pill-button-shadow-visibility': design?.pillButtons?.hasShadow ? 'block' : 'none',
      '--pill-button-icon-visibility': design?.pillButtons?.hasIcon ? 'inline' : 'none',
      '--tabs-no-headers-border-radius': hasHeaders ? undefined : '16px',
    }),
    [design, hasHeaders]
  );

  const displayPillsOnTop = useMemo(
    () => pillButtonsPosition === 'top' && isDesktopHD,
    [pillButtonsPosition, isDesktopHD]
  );

  const displayPillsOnBottom = useMemo(
    () => (isDesktopHD && pillButtonsPosition === 'bottom') || (pillButtonsPosition && !isDesktopHD),
    [isDesktopHD, pillButtonsPosition]
  );

  const PillButtons = useMemo(
    () => pillButtons.map(pillProps => <PillButton key={`link-item-${pillProps.label}`} {...pillProps} />),
    [pillButtons]
  );

  return (
    <div id={id} className={bem()} style={tabColors}>
      {hasHeaders && (
        <div className={bem('header')}>
          <div className={bem('buttons')} role="tablist">
            {labels.map((label, index) => (
              <TabButton
                key={label}
                focus={focus?.index === index}
                handleTabClick={handleTabClick}
                handleKeyDown={handleKeyDown}
                index={index}
                activeTabIndex={activeTabIndex}
                label={label}
                touched={touched}
                disabled={tabsNotAccessible && tabsNotAccessible.length > 0 ? tabsNotAccessible[index].disabled : false}
              />
            ))}
          </div>
          {pillButtons && displayPillsOnTop && <div className={bem('pills')}>{PillButtons}</div>}
        </div>
      )}
      <div className={bem('content')}>{Children.toArray(children)[activeTabIndex]}</div>
      {pillButtons && displayPillsOnBottom && (
        <div className={bem('pills', { footer: true })}>
          {PillButtons}
          {extraPillButton && <PillButton {...extraPillButton} />}
        </div>
      )}
    </div>
  );
};
