import { useMemo } from 'react';
import * as Yup from 'yup';

export const useValidationSchema = i18n =>
  useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email(i18n.invalidEmailLabel).required(i18n.requiredInputLabel),
        postalCode: Yup.string().matches(/^[\w\d-]{0,10}$/, i18n.invalidPostalCodeLabel),
      }),
    [i18n]
  );
