import React from 'react';

import Button from '@atoms/Button/Button';
import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './HeroImage.scss';
import { HeroImageProps } from './types';

const bem = cn('hero');

const HeroImage = (props: HeroImageProps) => {
  const { image, button, alignment, textBackgroundColor, textColor, text, textSize, isActive } = props;
  const { subtitle, subtitleColor, subtitleBackgroundColor, subtitleSize } = props;
  const { imageHeightDesktop, imageHeightTabletLg, imageHeightTabletSm, imageHeightMobile } = props;

  const vars: any = {
    '--text-background-color': textBackgroundColor,
    '--text-color': textColor,
    '--subtitle-background-color': subtitleBackgroundColor,
    '--subtitle-color': subtitleColor,
    '--image-height-desktop': `${imageHeightDesktop || 520}px`,
    '--image-height-tablet-lg': `${imageHeightTabletLg || 580}px`,
    '--image-height-tablet-sm': `${imageHeightTabletSm || 500}px`,
    '--image-height-mobile': `${imageHeightMobile || 320}px`,
  };

  return (
    <div className={bem()} style={vars}>
      <div className={bem('image')}>
        <Image {...image} />
      </div>
      <div className={bem('text', { alignment })}>
        <div className={bem('text-wrapper')}>
          {text && (
            <h1 className={bem('text-value')}>
              <Text as="span" text={text} className={bem('text-value-span', { heading: textSize ? textSize : 'h1' })} />
            </h1>
          )}
          {subtitle && (
            <h2 className={bem('text-subtitle')}>
              <Text
                as="span"
                text={subtitle}
                className={bem('text-subtitle-span', { heading: subtitleSize ? subtitleSize : 'h3' })}
              />
            </h2>
          )}
          {button && button.link && (
            <div className={bem('text-button')}>
              <Button {...button} addTabIndex={!isActive} tabIndex={-1} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroImage;
