import React, { useMemo } from 'react';

import { Divider } from '@atoms/Divider';
import { Icon } from '@atoms/Icon';
import { Image } from '@atoms/Image';
import { Text } from '@atoms/Text';
import TooltipV2 from '@atoms/Tooltip/v2/TooltipV2';

import { cn } from 'utils/classNames';
import { color_base_black } from 'utils/colors';

import './CardV2.scss';
import CardV2Placeholder from './CardV2Placeholder';
import { CardProps } from './types';

const bem = cn('cardV2');

const CardV2 = (props: CardProps) => {
  const {
    cardTitleImage,
    cardBackgroundImage,
    cardIcon,
    cardId,
    cardInfoText,
    cardTextColor = color_base_black,
    dateDescriptionLabel,
    date,
    tooltipText,
    sneakPeakLayout,
    isLoading,
  } = props;

  const styles: any = useMemo(
    () => ({
      '--card-background-image': `url(${cardBackgroundImage?.src})`,
      '--card-text-color': cardTextColor,
    }),
    [cardBackgroundImage, cardTextColor]
  );

  const tooltipColors = {
    info: {},
    content: {
      text: {
        inactive: cardTextColor,
        hover: cardTextColor,
      },
    },
  };

  if (isLoading)
    return (
      <CardV2Placeholder isSneakPeak={sneakPeakLayout} hasInfoText={cardInfoText} hasDate={dateDescriptionLabel} />
    );

  return (
    <div className={bem({ isNotSneakPeak: !sneakPeakLayout })} style={styles}>
      <Divider alignment="vertical" color={cardTextColor} width={2} margin={16} />
      <div className={bem('content')}>
        <div className={bem('row')}>
          <div className={bem('card-header-text')}>
            <Image className={bem('card-title_img')} src={cardTitleImage?.src} />
            <Text className={bem('card-id')} text={cardId} />
          </div>
          {cardIcon?.src && !sneakPeakLayout && (
            <div className={bem('icon-wrapper')}>
              <Icon externalIcon={cardIcon.src} />
            </div>
          )}
        </div>
        {cardInfoText && !sneakPeakLayout && (
          <div className={bem('row')}>
            <Text className={bem('card-info-text')} text={cardInfoText} />
          </div>
        )}
        {!sneakPeakLayout && (
          <div className={bem('row')}>
            {date && dateDescriptionLabel && (
              <div className={bem('date-container')}>
                <div className={bem('date-description')}>
                  <Text className={bem('description-label')} text={dateDescriptionLabel} />
                  {tooltipText && <TooltipV2 text={tooltipText} colors={tooltipColors} />}
                </div>
                <div className={bem('date')}>
                  <Text text={date} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardV2;
