import { observer } from 'mobx-react-lite';
import React, { useMemo, useEffect } from 'react';
import { useStores } from 'stores';

import Checkbox from '@atoms/Checkbox/Checkbox';
import Dropdown from '@atoms/Dropdown/Dropdown';
import { Column, Grid } from '@atoms/Grid';
import { Icon } from '@atoms/Icon';
import { Error } from '@booking/routes/TrainSelection/components/Error';
import { ForcedLoginErrorModal } from '@booking/routes/TrainSelection/components/ForcedLoginErrorModal/ForcedLoginErrorModal';
import { ForcedLoginModal } from '@booking/routes/TrainSelection/components/ForcedLoginModal/ForcedLoginModal';
import FareCompareButtons from '@molecules/FareCompareButtons/FareCompareButtons';
import FareCompareModal from '@organisms/FareCompareModal/v1/FareCompareModal';
import { FareCompareModalV2 } from '@organisms/FareCompareModal/v2';

import { findTableConfigByOriginAndDestination } from 'utils/brandedFaresUtils';
import { cn } from 'utils/classNames';
import { isLoyaltyEnabled } from 'utils/metas';

import './TrainSelection.scss';
import { DepartureTripSummary } from './components/DepartureTripSummary';
import { DisplayTrains } from './components/DisplayTrains/DisplayTrains';
import { FareSelectionDrawer } from './components/FareSelectionDrawer';
import { NoResults } from './components/NoResults';
import { RouteItem } from './components/RouteItem';
import { useTrainSelectionLogic } from './useTrainSelection';

const bem = cn('train-selection-body');

export const TrainSelection = observer(() => {
  const hookProps = useTrainSelectionLogic();
  const { data, loading, showPanel, tripSummary, isRoundTrip, travelLabels, outboundTicket } = hookProps;
  const { isPanelVisible, selectDirection, incompleteSearch, premiumFaresLabel, pushLoyaltyDataLayer } = hookProps;
  const { handleFilterList, getFoundItemsMessage, noResultsProps, faresCompare, handleFilterByClass } = hookProps;

  const { bookingStore, cfStore } = useStores();
  const { tickets } = bookingStore;
  const { sortItems, sortingAnimation, handleSortingAnimationEnded, premiumOnly } = tickets;
  const { modifyTripProps, prevSearchBody, trainSelectionError } = tickets;
  const { desktopFareCompareItems } = cfStore.header.brandedFares;

  const fareCompareModalV2Props = useMemo(() => {
    const originID = prevSearchBody?.from;
    const destinationID = prevSearchBody?.to;

    if (!originID || !destinationID) return undefined;

    return findTableConfigByOriginAndDestination('combined', desktopFareCompareItems, originID, destinationID);
  }, [desktopFareCompareItems, prevSearchBody]);

  useEffect(() => {
    isLoyaltyEnabled() && pushLoyaltyDataLayer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loading && incompleteSearch && noResultsProps)
    return (
      <div className={bem({ overlay: isPanelVisible })}>
        <Grid>
          <Column cols={12} mq={{ desktop: { cols: 10, offset: 1 } }}>
            <div className={bem('incomplete', { overlay: isPanelVisible })}>
              <NoResults i18n={noResultsProps} />
            </div>
          </Column>
        </Grid>
      </div>
    );

  return (
    <div className={bem({ overlay: isPanelVisible })}>
      <Grid>
        <Column cols={12} mq={{ desktop: { cols: 10, offset: 1 } }}>
          {isRoundTrip && outboundTicket && (
            <div className={bem('departure-trip')}>
              <div className={bem('departure-trip-label')}>{tripSummary.departure}</div>
              <div className={bem('departure-trip-wrapper')}>
                <DepartureTripSummary />
              </div>
            </div>
          )}
        </Column>
      </Grid>

      <div className={bem('train-results', { 'outbound-ticket-selected': !!outboundTicket })}>
        <Grid>
          <Column cols={12} mq={{ desktop: { cols: 10, offset: 1 } }}>
            <div className={bem('details')}>
              <div className={bem('travel-info')}>
                <div className={bem('top-info')}>
                  <h1 className={bem('select-route')}>{selectDirection}</h1>
                  {!modifyTripProps && (
                    <button className={bem('edit-search')} onClick={showPanel}>
                      {tripSummary.editSearch}
                    </button>
                  )}
                </div>
                {travelLabels.from && travelLabels.to && (
                  <div className={bem('stations')}>
                    <h2 className={bem('origin')}>{travelLabels.from}</h2>
                    <div className={bem('to-icon')}>
                      <Icon name="long-arrow-right" />
                    </div>
                    <h2 className={bem('destination')}>{travelLabels.to}</h2>
                  </div>
                )}
              </div>
              <div className={bem('filters')}>
                {!!sortItems.length && <Dropdown ariaLabel="Sort by" items={sortItems} onSelect={handleFilterList} />}
                <div className={bem('premium-filter')}>
                  <Checkbox
                    name="premium-only"
                    label={premiumFaresLabel}
                    isChecked={premiumOnly}
                    onChange={handleFilterByClass}
                  />
                </div>
              </div>
            </div>
          </Column>
        </Grid>

        {!trainSelectionError && (loading || !!data?.journeys?.length) && (
          <Grid>
            <Column
              customClassName={bem('found-wrapper')}
              cols={2}
              offset={1}
              mq={{ mobile: { hide: true }, tabletSmall: { hide: true }, tabletLarge: { offset: 0 } }}
            >
              {!loading && !!data?.journeys?.length && <h3 className={bem('found-items')}>{getFoundItemsMessage()}</h3>}
            </Column>
            <Column
              cols={5}
              offset={3}
              mq={{ mobile: { hide: true }, tabletSmall: { hide: true }, tabletLarge: { cols: 6, offset: 4 } }}
            >
              <FareCompareButtons buttons={faresCompare.options} width="full" />
              <FareCompareModal
                title={faresCompare.modalTitle}
                sections={faresCompare.options}
                defaultVisible={false}
              />
              {fareCompareModalV2Props && <FareCompareModalV2 renderAs="showAsModal" {...fareCompareModalV2Props} />}
            </Column>
          </Grid>
        )}

        {!trainSelectionError && (
          <div
            className={bem('route-items', { filtering: sortingAnimation })}
            onAnimationEnd={handleSortingAnimationEnded}
          >
            <Grid>
              <Column cols={12} mq={{ desktop: { cols: 10, offset: 1 } }} isBookingTripSelection>
                {loading &&
                  [...Array(6)].map((item, index) => (
                    <RouteItem key={`route-item-loading-${index}`} isRoundTrip={isRoundTrip} />
                  ))}
                {!loading && (
                  <DisplayTrains data={data?.journeys} outboundTicket={outboundTicket} isRoundTrip={isRoundTrip} />
                )}
                {!loading && !data?.journeys?.length && <NoResults i18n={noResultsProps} />}
              </Column>
            </Grid>
          </div>
        )}
      </div>

      {!trainSelectionError && <FareSelectionDrawer travelLabels={travelLabels} />}

      {trainSelectionError && (
        <Grid>
          <Column cols={12} mq={{ desktop: { cols: 10, offset: 1 } }}>
            <div className={bem('incomplete')}>
              <Error />
            </div>
          </Column>
        </Grid>
      )}

      <ForcedLoginModal />
      <ForcedLoginErrorModal />
    </div>
  );
});
