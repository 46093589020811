import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { ANALYTICS_DATE_FORMAT, searchTripsSuccess } from 'utils/adobeDataLayer';
import { mediaQueryList } from 'utils/mediaQueries';

import { cn } from 'utils/classNames';

import { Text } from '@atoms/Text';
import { TicketsFormDesktop } from './TicketsForm.desktop';
import { TicketsFormMobile } from './TicketsForm.mobile';
import './TicketsForm.scss';
import { TicketsFormProps } from './types';

export const TicketsForm = observer((props: TicketsFormProps) => {
  const { title = '', deprecatedSearchResultsPage, ...rest } = props;
  const { hideFormOnMobile, validateOnMount, tripSelection, isBookingFlow, i18n} = props;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const { ticketsFormStore, authStore, accountStore } = useStores();
  const { formValues, updateInitialTouchFields, fetchStationsData, setDatesRestrictionsData } = ticketsFormStore;
  const { setTripValue, trip } = ticketsFormStore;

  const { isAuthenticated } = authStore;
  const { profile } = accountStore;
  const { fetchUserProfile, userProfile, clearUserProfile } = profile;

  const bem = cn('tickets-form-container');

  useEffect(() => {
    fetchStationsData(deprecatedSearchResultsPage ? props.legacyStations : undefined);
    if (!deprecatedSearchResultsPage) setDatesRestrictionsData();

    if (trip < 0) setTripValue(tripSelection.trip);

    if (validateOnMount) updateInitialTouchFields(true);

    return () => updateInitialTouchFields(false);
  }, [
    deprecatedSearchResultsPage,
    setDatesRestrictionsData,
    fetchStationsData,
    props.legacyStations,
    setTripValue,
    trip,
    tripSelection.trip,
    updateInitialTouchFields,
    validateOnMount,
  ]);

  useEffect(() => {
    if (isAuthenticated && !userProfile && !isBookingFlow) fetchUserProfile();
    if (!isAuthenticated && userProfile) clearUserProfile();
  }, [isAuthenticated, userProfile, isBookingFlow, fetchUserProfile, clearUserProfile]);

  const sendSearchAnalyticsEvent = values => {
    let origin;
    let originTimeZone;
    let destination;
    let destinationTimeZone;

    ticketsFormStore.stationsData.forEach(station => {
      station.routes &&
        station.routes.forEach(route => {
          if (route.id === values.from) {
            origin = route.name;
            originTimeZone = route.timeZone;
          }
          if (route.id === values.to) {
            destination = route.name;
            destinationTimeZone = route.timeZone;
          }
        });
    });

    const eventInfo = {
      searchTrips: {
        originCity: origin,
        originState: null,
        destinationCity: destination,
        destinationState: null,
        dates: {
          departure: moment(values.startDate).tz(originTimeZone).format(ANALYTICS_DATE_FORMAT),
          return: values.endDate ? moment(values.endDate).tz(destinationTimeZone).format(ANALYTICS_DATE_FORMAT) : null,
        },

        PNR: null,
        tripType: values.endDate ? 'roundtrip' : 'one-way',
      },
      tripManagement: {
        PNR: null,
      },
    };

    searchTripsSuccess(eventInfo, authStore.isAuthenticated);
  };

  return (
    <div className={bem()}>
      <Text as="p" text={i18n?.requiredFieldLabel} className={bem('required-field-label')} />
      {isMobile ? (
        <TicketsFormMobile
          {...rest}
          title={title}
          hideFormOnMobile={hideFormOnMobile}
          stations={ticketsFormStore.stationsData}
          datesRestrictions={ticketsFormStore.datesRestrictionsData}
          initialFormValues={formValues}
          validateOnMount={validateOnMount}
          deprecatedSearchResultsPage={deprecatedSearchResultsPage}
          sendSearchAnalyticsEvent={sendSearchAnalyticsEvent}
        />
      ) : (
        <TicketsFormDesktop
          {...rest}
          stations={ticketsFormStore.stationsData}
          datesRestrictions={ticketsFormStore.datesRestrictionsData}
          initialFormValues={formValues}
          validateOnMount={validateOnMount}
          deprecatedSearchResultsPage={deprecatedSearchResultsPage}
          sendSearchAnalyticsEvent={sendSearchAnalyticsEvent}
        />
      )}
    </div>
  );
});
