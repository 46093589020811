import { observer } from 'mobx-react-lite';
import React from 'react';

import { cn } from 'utils/classNames';

import './ProfileV2.scss';

const bem = cn('profileV2-placeholders');

export const ProfileV2Placeholders = observer(() => (
  <div className={bem('profile-banner')}>
    <div className={bem('user-avatar')} />
    <div className={bem('user-info')}>
      <div className={bem('user-name')} />
      <div className={bem('membership')} />
    </div>
  </div>
));
