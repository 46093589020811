import moment from 'moment';
import { isMobileOnly, isTablet } from 'react-device-detect';

import { ParkingTravelPassDatalayerProps } from '../apps/passes/routes/Checkout/types';
import { rootStore } from '../stores';
import { clearForcedLoginAnalytics, getForcedLoginAnalytics } from './localstorage';
import { getContext, getLocaleOrDefault } from './metas';
import { parseEmailFromSessionToken } from './parseHelpers';

export const ANALYTICS_DATE_FORMAT = 'YYYY-MM-DD';

const getIndexOrNone = (paths: string[], index: number) => (paths[index] ? paths[index] : '');

export const constructAnalyticsItems = (initialItems, currentItems) => {
  const analyticsItems: any = [];

  currentItems.forEach((item, index) => {
    if (item.quantity === initialItems[index].quantity) return;

    const pushedItem = item.quantity ? item : initialItems[index];
    analyticsItems.push({ ...pushedItem, quantity: item.quantity - initialItems[index]?.quantity });
  });

  return analyticsItems;
};

const triggerEvent = (name: string, isAuthenticated: boolean, eventInfo: any = {}, passenger: any = {}) => {
  try {
    const paths = window.location.pathname.split('/');
    const event = {
      event: name,
      pageInfo: {
        pageName: document.title,
        server: '',
        siteSectionsLevel1: getIndexOrNone(paths, 1),
        siteSectionsLevel2: getIndexOrNone(paths, 2),
        siteSectionsLevel3: getIndexOrNone(paths, 3),
        pageType: 'h1',
        referrer: document.referrer,
        url: window.location.href,
        utm: window.location.search.substring(1),
      },
      visitorProfile: {
        userProfileStateAuthentication: isAuthenticated,
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        email: passenger.email,
        phone: passenger.phoneNumber,
      },
      websiteID: {
        languageSelected: getLocaleOrDefault(),
        siteName: getContext(),
        country: 'us',
        region: 'us',
      },
      device: getDeviceInfo(),
    };

    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({ ...event, ...eventInfo });
  } catch (e) {
    console.error(e);
  }
};

const getDeviceInfo = () => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const deviceWidth = screen.width;
  const deviceHeight = screen.height;
  const deviceOrientation = windowHeight > windowWidth ? 'portrait' : 'landscape';

  let deviceType = '';
  if (window.matchMedia('(pointer: coarse)').matches) {
    if (isMobileOnly) deviceType = 'mobile';
    else if (isTablet || (windowWidth <= 1024 && windowWidth > 576)) deviceType = 'tablet';
    else if (windowWidth > 1024) deviceType = 'desktop';
  } else deviceType = 'desktop';

  return {
    deviceType,
    deviceHeight,
    deviceWidth,
    deviceOrientation,
  };
};

export const userLoginSuccess = (location, userEmail) => {
  if (location.search && location.search.indexOf('state') !== -1 && location.search.indexOf('code') !== -1)
    triggerEvent('loginSuccess', true, {
      loginSuccess: {
        email: userEmail,
        userRegistrationSuccess: true,
      },
    });
};

export const orderSubmission = (cardType: string | undefined, cart: any, isAuthenticated: boolean) => {
  triggerEvent('orderSubmission', isAuthenticated, {
    orderSubmission: {
      cardType: cardType,
    },
    cart: cart,
  });
};

export const transactionDetails = (eventInfo, isAuthenticated, passenger?: any) => {
  triggerEvent('transactionDetails', isAuthenticated, eventInfo, passenger);
};

export const searchTripsSuccess = (eventInfo, isAuthenticated) => {
  triggerEvent('searchTrips', isAuthenticated, eventInfo);
};

export const selectTrainEvent = (eventInfo, isAuthenticated) => {
  triggerEvent('trainSelection', isAuthenticated, eventInfo);
};

export const wifiLoginEvent = (eventInfo, isAuthenticated) => {
  triggerEvent('wifiLogin', isAuthenticated, eventInfo);
};

export const accountCreateSuccessEvent = (requestData, sessionToken) => {
  const email = parseEmailFromSessionToken(sessionToken);

  if (email && requestData.firstName && requestData.lastName)
    triggerEvent('createAccount', true, {
      createAccount: {
        email,
        firstName: requestData.firstName,
        lastName: requestData.lastName,
        telephone: requestData.phoneNumber,
        emailOptIn: requestData.exclusivesConsent,
        zipCode: requestData.zipCode,
      },
    });
};

export const pageLoadEvent = (rootStore, assetType?: any, assetName?: any, isClosed?: boolean, isStarted?: boolean) => {
  const eventInfo = {
    ...(assetType && { assetType }),
    ...(assetName && { assetName }),
    ...(isClosed !== undefined && { isClosed }),
    ...(isStarted !== undefined && { isStarted }),
  };

  triggerEvent('pageLoad', rootStore.authStore.isAuthenticated, eventInfo);
};

export const pushParkingTravelPassEvent = (props: ParkingTravelPassDatalayerProps) => {
  triggerEvent('cartUpdate', true, {
    cartUpdate: {
      cartID: props.cartId,
      cartTotal: parseFloat(props.cartTotal.toFixed(2)),
      item: [
        {
          autoRenew: props.autoRenew,
          garage: props.garage,
          licensePlateNumber: props.licensePlateNumber,
          licensePlateState: props.licensePlateState,
          price: parseFloat(props.price?.toFixed(2) || ''),
          productInfo: {
            category: '',
            class: props.category,
            destinationCity: props.destination,
            destinationState: null,
            dates: {
              departure: '',
              return: '',
            },
            originCity: props.origin,
            originState: null,
            productName: props.productName,
            sku: props.productCode,
          },
          quantity: 1,
        },
      ],
      tripManagement: '',
    },
  });
};

export const clickAnalyticsEvent = (rootStore, linkObj) => {
  triggerEvent('linkClick', rootStore.authStore.isAuthenticated, linkObj);
};

export const cartEvent = (evtName, items, response, type) => {
  const cartObject = {
    cartUpdate: {
      cartID: response.data.tripDetails?.trip?.referenceNumber,
      cartTotal: parseFloat(response.data.tripDetails?.costSummary?.bookingTotal?.totalToBePaid),
      item: items.map(item => {
        const product = response.data.tripDetails.costSummary.sections
          .map(section => section.items)
          .flat()
          .find(
            summaryItem =>
              summaryItem.productCode === item.productCode || summaryItem.productCode === item.seatSelectionProductCode
          );

        const seatProduct =
          item?.direction === 'inbound'
            ? response.data.tripDetails.trip.inboundRoute || response.data.tripDetails.trip.outboundRoute
            : response.data.tripDetails.trip.outboundRoute;

        const inboundRoute = response.data.tripDetails.trip.inboundRoute;

        const priceQuantityItem = product || item;

        return {
          productInfo: {
            price: Math.abs(
              priceQuantityItem?.totalPrice ? priceQuantityItem.totalPrice / (priceQuantityItem?.quantity || 1) : 0
            ),
            category: type,
            class: seatProduct.classType,
            destinationCity: seatProduct.destination.city,
            destinationState: null,
            dates: {
              departure: moment(seatProduct.departureDateTime)
                .tz(seatProduct.origin.timeZone)
                .format(ANALYTICS_DATE_FORMAT),
              return: inboundRoute?.departureDateTime
                ? moment(inboundRoute.departureDateTime)
                    .tz(inboundRoute.destination.timeZone)
                    .format(ANALYTICS_DATE_FORMAT)
                : null,
            },
            originCity: seatProduct.origin.city,
            originState: null,
            productName: item.productName || priceQuantityItem.productName,
            sku: item.productCode || item.seatSelectionProductCode,
          },
          quantity: priceQuantityItem.quantity,
        };
      }),
      tripManagement: 'true',
      visitorProfile: {
        userProfileStateAuthentication: !!rootStore.accountStore.profile.userProfile,
      },
    },
  };
  triggerEvent(evtName, rootStore.authStore.isAuthenticated, cartObject);
};

export const errorEvent = (rootStore, eventInfo) => {
  triggerEvent('error', rootStore.authStore.isAuthenticated, eventInfo);
};

export const appDownloadEvent = eventInfo => {
  triggerEvent('appDownload', rootStore.authStore.isAuthenticated, eventInfo);
};

export const emailOptInAnalyticsEvent = eventInfo => {
  triggerEvent('emailOptIn', rootStore.authStore.isAuthenticated, eventInfo);
};

export const sweepstakesFormEvent = eventInfo => {
  triggerEvent('sweepstakesForm', rootStore.authStore.isAuthenticated, eventInfo);
};

export const emailOptInWest = eventInfo => {
  triggerEvent('emailOptInWest', rootStore.authStore.isAuthenticated, eventInfo);
};

export const safetyPledgeForm = eventInfo => {
  triggerEvent('safetyPledgeForm', rootStore.authStore.isAuthenticated, eventInfo);
};

export const loyaltyLoadAnalyticsEvent = loyaltyObj => {
  triggerEvent('loyalty', rootStore.authStore.isAuthenticated, loyaltyObj);
};

export const consentAnalyticsEvent = ({ email }) => {
  const date = moment();

  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    action: 'accept',
    category: 'marketing',
    consent_DateTime: date.toISOString(),
    email: email,
    event: 'consent',
    event_type: 'consent',
    source: 'Website',
    timestamp: date.toISOString(),
    valid_until: 'unlimited',
  });
};

export const tripManagementAnalyticsEvent = tripManagementBody => {
  triggerEvent('tripManagement', rootStore.authStore.isAuthenticated, tripManagementBody);
};

export const handleForcedLoginAnalyticsSubmission = rootStore => {
  if (!getForcedLoginAnalytics()) return;

  if (!['/account', '/account/login', '/account/signup'].includes(window.location.pathname)) {
    clickAnalyticsEvent(rootStore, {
      linkClick: getForcedLoginAnalytics(),
    });
    clearForcedLoginAnalytics();
  }
};
