import React, { useEffect, useId, useRef } from 'react';

import { Icon } from '@atoms/Icon';

import { cn, cx } from 'utils/classNames';

import './WidgetInput.scss';
import { WidgetInputProps } from './types';

const bem = cn('widget-input');

export const WidgetInput = (props: WidgetInputProps) => {
  const locallyGeneratedId = useId();

  const {
    isInvalid,
    errorMessage,
    placeholder,
    label,
    generatedId,
    addErrorId,
    ariaDescribedbyWidget,
    isKiosk,
    renderAs,
  } = props;
  const { onClearClick, onInputChange, onInputClick, onInputKeyDown, onInputFocus, role, type } = props;
  const { focus, value, disabled, active, name, position = '' } = props;
  const {
    ariaActivedescendant,
    ariaAutocomplete,
    ariaControls,
    ariaHaspopup,
    ariaExpanded,
    noAriaHidden,
    includeAriaAttributes,
  } = props;

  const ariaDescribedby = ariaDescribedbyWidget
    ? ariaDescribedbyWidget
    : isInvalid
    ? `error-${generatedId || locallyGeneratedId}`
    : undefined;

  const inputRef = useRef<HTMLInputElement>(null);

  const { isRequired = false } = props;

  const ariaAttributes = includeAriaAttributes
    ? {
        role: 'combobox',
        'aria-haspopup': ariaHaspopup,
        'aria-expanded': ariaExpanded,
        'aria-controls': ariaControls,
        'aria-describedby': ariaDescribedby,
        'aria-labelledby': `label-${generatedId || locallyGeneratedId}${ariaDescribedby ? ` ${ariaDescribedby}` : ''}`,
        'aria-activedescendant': ariaActivedescendant,
      }
    : {};

  const handleClearClick = e => {
    if (onClearClick) onClearClick(e);
    if (handleInputClick) handleInputClick(e);
  };

  const handleInputClick = e => {
    if (onInputClick) onInputClick(e, name);
  };

  const handleInputKeyDown = e => {
    if (onInputKeyDown) onInputKeyDown(e, name);
  };

  const handleInputChange = e => {
    if (onInputChange) onInputChange(e);
  };

  const handleInputFocus = e => {
    if (onInputFocus) onInputFocus(e);
  };

  useEffect(() => {
    if (focus) inputRef?.current?.focus();
  }, [focus]);

  useEffect(() => {
    if (isInvalid && inputRef.current && addErrorId) {
      inputRef.current.setAttribute('aria-describedby', `error-${generatedId || locallyGeneratedId}`);
      inputRef.current.focus();
    }
  }, [addErrorId, generatedId, locallyGeneratedId, isInvalid]);

  return (
    <>
      <div className={bem({ position, disabled, 'has-error': !!isInvalid, active, isKiosk })} {...ariaAttributes}>
        <label
          className={bem('label')}
          htmlFor={`input-${generatedId || locallyGeneratedId}`}
          id={`label-${generatedId || locallyGeneratedId}`}
        >
          {label}
        </label>
        {isKiosk || renderAs === 'button' ? (
          <button
            type="button"
            className={cx(bem('input', { button: true, buttonIsPlaceHolder: !value }))}
            id={`input-${generatedId || locallyGeneratedId}`}
            onClick={handleInputClick}
            onKeyDown={handleInputKeyDown}
            onFocus={handleInputFocus}
            aria-describedby={ariaDescribedby}
            aria-labelledby={
              isInvalid ? `error-${generatedId || locallyGeneratedId}` : `label-${generatedId || locallyGeneratedId}`
            }
            aria-hidden={!noAriaHidden ? !focus : undefined}
            aria-autocomplete={ariaAutocomplete}
            aria-controls={ariaControls}
            aria-haspopup={ariaHaspopup}
            aria-expanded={ariaExpanded}
            aria-activedescendant={ariaActivedescendant || undefined}
            disabled={disabled}
          >
            {value ? value : placeholder}
          </button>
        ) : (
          <input
            ref={inputRef}
            role={role}
            disabled={disabled}
            autoComplete="off"
            className={bem('input')}
            id={`input-${generatedId || locallyGeneratedId}`}
            placeholder={placeholder}
            onChange={handleInputChange}
            onClick={handleInputClick}
            onKeyDown={handleInputKeyDown}
            onFocus={handleInputFocus}
            value={value}
            required={isRequired}
            aria-autocomplete={ariaAutocomplete}
            type={type}
          />
        )}
        {!!value && (
          <button type="button" disabled={disabled} className={bem('clear')} onClick={handleClearClick}>
            <span className="sr-only">clear</span>
            <span aria-hidden="true">
              <Icon name="close" />
            </span>
          </button>
        )}
      </div>
      {isInvalid && errorMessage && (
        <span id={`error-${generatedId || locallyGeneratedId}`} className={cx(bem('error'))}>
          {errorMessage}
        </span>
      )}
    </>
  );
};
