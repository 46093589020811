import React from 'react';

import './CoachSelect.scss';
import { CoachSelectHeader } from './CoachSelectHeader';
import { MobileSelect } from './Mobile/MobileSelect';
import { CoachSelectProps } from './types';
import { useSelectHandlers } from './useSelectHandlers';

export const CoachSelect = (props: CoachSelectProps) => {
  const { isAccountFlow, canEditSeatsAccontFlow } = props;

  const hookProps = useSelectHandlers(props);

  return (
    <CoachSelectHeader
      {...props}
      {...hookProps}
      onClick={hookProps.handleHeaderClick}
      canEditSeatsAccontFlow={canEditSeatsAccontFlow}
      isAccountFlow={isAccountFlow}
      isMobileDevice
    >
      <MobileSelect {...props} {...hookProps} />
    </CoachSelectHeader>
  );
};
