import cx from 'classnames';
import React from 'react';

import { MobileSelectProps } from '@atoms/Select/types';

import { cn } from 'utils/classNames';

import './MobileSelect.scss';

const bem = cn('nativeSelect');

export const MobileSelect = (props: MobileSelectProps) => {
  const { name, multiple, disabled, required, fieldsetClassName = '' } = props;
  const { optionsData, handleChangeNativeSelect } = props;

  return (
    <fieldset
      className={cx(bem('fieldset'), {
        [fieldsetClassName]: !!fieldsetClassName,
      })}
    >
      <legend className={bem('legend')}>{name}</legend>
      <select
        multiple={multiple}
        className={bem()}
        onChange={handleChangeNativeSelect}
        name={name}
        disabled={disabled}
        required={required}
      >
        {optionsData?.map((item, itemIndex) => (
          <option key={`${item.label}-${itemIndex}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </fieldset>
  );
};
