import React from 'react';

import { TrainPassesComponentProps } from '@organisms/TrainPasses/types';

import TrainPassesCard from './TrainPassesCard/TrainPassesCard';

export const TrainPasses = (props: TrainPassesComponentProps) => {
  const {
    title,
    startingPriceSubtitle,
    destinationSubtitle,
    travelFromLabel,
    travelToLabel,
    experienceSubtitle,
    learnMoreTooltipText,
    passTypeTitle,
    parkingTitle,
    parkingDescription,
    licensePlateStateTitle,
    licensePlateStateDropdownPlaceholder,
    licensePlateNumberTitle,
    licensePlateNumberTextPlaceholder,
    overviewTrainPassTitle,
    fareLinkUrl,
    fareLinkTarget,
    overviewParkingPassTitle,
    autoRenewCheckboxLabel,
    priceUpdateLabel,
    autoRenewNote,
    colors,
    primaryButton,
    image,
    enterValidPlateNumber,
    editLabel,
    removeLabel,
    stationAdaptions,
    passesConfigurations,
  } = props;

  const i18n = {
    title,
    experienceSubtitle,
    startingPriceSubtitle,
    travelFromLabel,
    destinationSubtitle,
    travelToLabel,
    learnMoreTooltipText,
    fareLinkUrl,
    fareLinkTarget,
    passTypeTitle,
    parkingTitle,
    priceUpdateLabel,
    parkingDescription,
    licensePlateStateTitle,
    licensePlateStateDropdownPlaceholder,
    licensePlateNumberTitle,
    licensePlateNumberTextPlaceholder,
    overviewTrainPassTitle,
    overviewParkingPassTitle,
    autoRenewCheckboxLabel,
    autoRenewNote,
    primaryButton,
    enterValidPlateNumber,
    editLabel,
    removeLabel,
  };

  return (
    <TrainPassesCard
      colors={colors}
      i18n={i18n}
      image={image}
      stationAdaptions={stationAdaptions}
      passesConfigurations={passesConfigurations}
    />
  );
};
