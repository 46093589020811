import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import Checkbox from '@atoms/Checkbox/Checkbox';
import { InputField } from '@atoms/Input';
import SelectField from '@atoms/Select/SelectField';
import { Text } from '@atoms/Text';
import useDailyAndParkingPassesLogic from '@organisms/ParkingPasses/useDailyAndParkingPassesLogic';

import { cn, cx } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import { NumberOfDaysMobile } from '../NumberOfDaysMobile/NumberOfDaysMobile';
import './DriverDetails.scss';
import { DriverDetailsProps } from './types';

const bem = cn('parking-driver-details');

export const DriverDetails = observer((props: DriverDetailsProps) => {
  const { driver, states, useParkingModalLogic, increaseNumberOfDays, decreaseNumberOfDays, numberOfDays } = props;
  const { cfStore, bookingStore } = useStores();
  const { provisionalBooking } = bookingStore.rti;
  const booking = provisionalBooking.tripDetails?.trip;
  const isOneWayTrip = !booking.inboundRoute;
  const [isSelected, setIsSelected] = useState(false);
  const [isStateFieldMandatory, setIsStateFieldMandatory] = useState(false);
  const [isLicenseFieldMandatory, setSsLicenseFieldMandatory] = useState(false);
  const { requiredStates, setIsFormModified } = useParkingModalLogic;
  const { parking } = bookingStore;
  const [driverNumberOfDays, setDriverNumberOfDays] = useState(1);
  const handleCapitalizeParkingPasses = useDailyAndParkingPassesLogic();

  const parkingCf = cfStore.rti.parking;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const { errors, values, setFieldValue } = useFormikContext();

  const hasFormErrors = useMemo(
    () =>
      Object.entries(errors)
        .filter(([key]) => key.startsWith(driver.passengerId))
        .some(([, value]) => !!value),
    [errors, driver]
  );

  useEffect(() => {
    const stationId = parking.data?.station.id;
    if (stationId && requiredStates.includes(stationId)) {
      setIsStateFieldMandatory(true);
      setSsLicenseFieldMandatory(true);
    }
  }, [parking.data?.station.id, requiredStates]);

  useEffect(() => {
    if (parking.modalSelectedPassengers.includes(driver.passengerId)) setIsSelected(true);

    setDriverNumberOfDays(parking?.modalNumberOfDays[0]?.numberOfDays || 1);
  }, [driver.passengerId, parking?.modalNumberOfDays, parking.modalSelectedPassengers]);

  const handleCheckboxClick = (target, values) => {
    const isFormModified = parking.toggleSelectedPassenger(target.value, values);
    setIsSelected(target.checked);

    setIsFormModified(isFormModified);
  };

  const classNames = cx(bem('card', { selected: isSelected }));

  return (
    <div className={classNames}>
      <Checkbox
        className={bem('driver-name')}
        name={`${driver.passengerId}_name`}
        label={driver.passengerName}
        isChecked={parking.modalSelectedPassengers.includes(driver.passengerId)}
        value={driver.passengerId}
        onChange={event => handleCheckboxClick(event.target, values)}
        legendDescription={parkingCf.legendDescription}
      />
      {isMobile && (
        <Text
          className={bem('driver-total')}
          text={`$${(driver.pricePerDay * (isSelected ? driverNumberOfDays : 1)).toFixed(2)}`}
        />
      )}

      {isSelected && !isMobile && (
        <div className={bem('driver-details-desktop')}>
          <SelectField
            optionsData={states}
            errorVariant={hasFormErrors ? 'inline' : undefined}
            name={`${driver.passengerId}_state`}
            label={parkingCf.stateLabel}
            showAsterisk={isStateFieldMandatory}
            inputFieldPlace="parkingDetails"
            readOnly
            useDesktopVariant
          />
          <InputField
            type="text"
            name={`${driver.passengerId}_license`}
            onChange={e =>
              handleCapitalizeParkingPasses(e.target.value, `${driver.passengerId}_license`, setFieldValue)
            }
            errorVariant={hasFormErrors ? 'inline' : undefined}
            label={parkingCf.licensePlateLabel}
            required={isLicenseFieldMandatory}
            inputFieldPlace="parkingDetails"
          />
          {isOneWayTrip && (
            <div className={bem('item')}>
              <div className={bem('left')}>
                <div className={bem('title')}>{parkingCf.numberOfDaysLabel}</div>
              </div>
              <div className={bem('right')}>
                <button type="button" onClick={decreaseNumberOfDays} />
                <div className={bem('numberOfDays')}>
                  <span>{numberOfDays}</span>
                </div>
                <button type="button" onClick={increaseNumberOfDays} />
              </div>
            </div>
          )}
        </div>
      )}

      {isSelected && isMobile && (
        <div className={bem('driver-details')}>
          <SelectField
            optionsData={states}
            type="text"
            name={`${driver.passengerId}_state`}
            errorVariant="inline"
            label={parkingCf.stateLabel}
            showAsterisk={isStateFieldMandatory}
            readOnly
          />
          <InputField
            type="text"
            name={`${driver.passengerId}_license`}
            label={parkingCf.licensePlateLabel}
            required={isLicenseFieldMandatory}
            onChange={e =>
              handleCapitalizeParkingPasses(e.target.value, `${driver.passengerId}_license`, setFieldValue)
            }
            errorVariant="inline"
          />
          {isOneWayTrip && (
            <NumberOfDaysMobile
              type="text"
              name={`${driver.passengerId}_numberOfDays`}
              errorVariant="inline"
              label="Number Of Days"
              showAsterisk={isStateFieldMandatory}
              value={numberOfDays}
              numberOfDaysLabel="Number Of Days"
              numberOfDays={numberOfDays}
              increaseNumberOfDays={increaseNumberOfDays}
              decreaseNumberOfDays={decreaseNumberOfDays}
              parking="parking"
              readOnly
            />
          )}
        </div>
      )}
    </div>
  );
});
