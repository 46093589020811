const delimiter = '__';

const getControlInfo = (argKey: string, groupName?: string) => {
  const [_root, group, subcategory, keyName] = argKey.split(delimiter);

  return {
    table: {
      expanded: false,
      category: groupName || group,
    },
    group,
    subcategory: keyName ? subcategory : null,
    keyName: !keyName ? subcategory : keyName,
    root: _root,
  };
};

const getBaseControlInfo = (argKey: string, groupName?: string) => {
  const controlInfo = getControlInfo(argKey, groupName);

  return {
    name: controlInfo.keyName,
    table: controlInfo.table,
  };
};

export const mapAtomArgTypes = (root: string, group: string, argsTypes: object, groupName?: string) => {
  let constructedArgTypes = {};
  for (const [key, value] of Object.entries(argsTypes)) {
    let argKey = `${root}${delimiter}`;

    if (group) argKey += `${group}${delimiter}${key}`;
    else argKey += `${key}`;

    constructedArgTypes = {
      ...constructedArgTypes,
      [argKey]: {
        ...getBaseControlInfo(argKey, groupName),
        ...value,
        name: value?.name || key,
      },
    };
  }

  return constructedArgTypes;
};

export const getStorybookPublicPath = () => {
  const origin = window.location.origin;

  if (origin.includes('localhost')) return origin;

  return window.location.href.split('/iframe.html')[0];
};

export const getFinalStorybookEndpoint = endpoint => getStorybookPublicPath() + endpoint;

export { delimiter, getControlInfo, getBaseControlInfo };
