import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { Button } from '@atoms/Button';
import { Text } from '@atoms/Text';
import { TrainPassStepsProps } from '@organisms/TrainPasses/types';

import { cn } from 'utils/classNames';

const bem = cn('train-passes-footer');

export const Footer = observer((props: TrainPassStepsProps) => {
  const { i18n, useTrainPassLogic, setFieldValue } = props;
  const { step, stations, categories, setStep, loading, fetchTrainPassOptions, total, discountedTotal } =
    useTrainPassLogic;

  const { values: formValues, submitForm } = useFormikContext();
  const values = formValues as any;

  const buttonConfig = useMemo(() => {
    switch (step) {
      case 0: {
        return {
          label: i18n.primaryButton?.buttonStartLabel,
          disabled: false,
          loading: false,
        };
      }
      case 1: {
        return {
          label: i18n.primaryButton?.buttonContinueLabel,
          disabled: !values.travelFrom || !values.travelTo,
          loading: loading,
        };
      }
      case 2: {
        return {
          label: i18n.primaryButton?.buttonContinueLabel,
          disabled: !values.category,
          loading,
        };
      }
      case 3: {
        return {
          label: i18n.primaryButton?.buttonContinueLabel,
          disabled: !values.option,
          loading: false,
        };
      }
      default: {
        return {
          label: i18n.primaryButton?.buttonCheckoutLabel,
          disabled: false,
          loading: false,
        };
      }
    }
  }, [step, i18n, values.travelFrom, values.travelTo, values.category, values.option, loading]);

  const handleNextStep = async (setFieldValue: any) => {
    //Reset autorenew checkbox when switching pass choice
    if (step < 10) setFieldValue && setFieldValue('autoRenew', false);

    if (step === 2) {
      const originStation = stations.find(station => station.name === values.travelFrom);
      const dstStation = stations.find(station => station.name === values.travelTo);
      const category = categories.find(category => category.name === values.category);
      if (!originStation || !dstStation || !category) return;

      await fetchTrainPassOptions(originStation.id, dstStation.id, category.code);
    }

    if (step === 3) {
      setStep(10);

      return;
    }

    if (step === 10) {
      submitForm();

      return;
    }

    setStep(val => val + 1);
  };

  return (
    <div className={bem()}>
      <div className={bem('continueButton')}>
        <Button
          {...i18n.primaryButton}
          link={undefined}
          label={buttonConfig.label || ''}
          onClick={() => handleNextStep(setFieldValue)}
          disabled={buttonConfig.disabled}
          loading={buttonConfig.loading}
        />
      </div>
      <div>
        {!!total && step < 4 && <Text className={bem('total')} text={`$${total.toFixed(2)}`} />}
        {!total && step != 0 && <Text className={bem('total')} text="$0" />}
        {!!total && !discountedTotal && step > 3 && <Text className={bem('total')} text={`$${total.toFixed(2)}`} />}
        {!!total && !!discountedTotal && total !== discountedTotal && step > 3 && (
          <Text className={bem('total strike')} text={`$${total.toFixed(2)}`} />
        )}
        {!!discountedTotal && step > 3 && <Text className={bem('total')} text={`$${discountedTotal.toFixed(2)}`} />}
      </div>
    </div>
  );
});
