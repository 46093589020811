import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './PaymentErrorModal.scss';
import { PaymentErrorModalProps } from './types';

const bem = cn('payment-error-modal');

export const PaymentErrorModal = observer((props: PaymentErrorModalProps) => {
  const { kioskStore } = useStores();
  const { paymentErrorModal, hidePaymentErrorModal } = kioskStore;
  const { title, description, isVisible, cancelLabel, confirmLabel } = paymentErrorModal;

  const { retryPayment } = props;
  const handleClick = () => {
    hidePaymentErrorModal();
    retryPayment();
  };

  if (!isVisible) return null;

  return (
    <Modal variant="mini">
      <div className={bem()}>
        <div className={bem('icon')}>
          <Icon name="close" />
        </div>
        <div className={bem('title')}>{title}</div>
        <div className={bem('description')}>{description}</div>

        <div className={bem('buttons')}>
          <Button variant="secondary" color="charcoal" label={cancelLabel} onClick={hidePaymentErrorModal} />
          <Button variant="primary" color="blue" label={confirmLabel} onClick={handleClick} />
        </div>
      </div>
    </Modal>
  );
});
