import { observer } from 'mobx-react-lite';
import React, { useMemo, useRef } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import OutsideClickHandler from 'react-outside-click-handler';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './DateRangePicker.scss';
import { DesktopDateRangePicker } from './DesktopCalendar';
import { MobileDateRangePicker } from './MobileCalendar';
import { DateRangePickerProps } from './types';
import { useDateRangePicker } from './useDateRangePicker';

const bem = cn('date-range-picker');

export const DateRangePicker = observer((props: DateRangePickerProps) => {
  const { isMobile } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobileDevice = useMediaPredicate(mediaQueryList.maxTablet);

  const hookProps = useDateRangePicker(props);
  const { handleCloseButton } = hookProps;

  const shouldHaveMobileLayout = useMemo(() => isMobile || isMobileDevice, [isMobile, isMobileDevice]);

  return (
    <OutsideClickHandler onOutsideClick={handleCloseButton}>
      <div ref={containerRef} className={bem()}>
        {shouldHaveMobileLayout ? (
          <MobileDateRangePicker containerRef={containerRef} {...props} {...hookProps} />
        ) : (
          <DesktopDateRangePicker {...props} {...hookProps} />
        )}
      </div>
    </OutsideClickHandler>
  );
});
