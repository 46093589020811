import { FormikContextType, useFormikContext } from 'formik';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PartialFromProps } from '../../../OriginAndDestination/types';
import { END_DATE, START_DATE } from './constants';
import { CalendarDateProps, DateRangePickerProps } from './types';

export interface UseDateRangePickerRes {
  isOpen: boolean;
  isSameDay: boolean;
  focusedInput: string;
  handleClearDate: (field: string) => void;
  handleClearDates: () => void;
  handleCloseButton: () => void;
  handleInputClick: (val: string) => void;
  handleFocusChange: (focusedInputElement: string) => void;
  handleDatesChange: ({ startDate, endDate }: any) => void;
  isOutsideRange: (minDay: Moment, maxDay: Moment) => (day: Moment) => void;
}

export const useDateRangePicker = (props: DateRangePickerProps): UseDateRangePickerRes => {
  const formikProps: FormikContextType<PartialFromProps> = useFormikContext();

  const { isMobile, isRoundTrip, tabType, isFirstLegTraveled } = props;
  const { setFieldValue, values } = formikProps;

  const [isOpen, setIsOpen] = useState(false);
  const [isSameDay, setIsSameDay] = useState(false);
  const [focusedInput, setFocusedInput] = useState<string>('');

  const isOneWay = useMemo(() => !isRoundTrip, [isRoundTrip]);

  const handleCloseButton = () => {
    setIsOpen(false);
    setFocusedInput('');
  };

  const handleClearDate = (field: string) => {
    setFieldValue(field, null, true);
    setIsSameDay(false);
  };

  const handleClearDates = () => {
    if (tabType === 'departure') {
      setFieldValue(START_DATE, null, true);
      setFocusedInput(START_DATE);
    } else if (tabType === 'return') {
      setFieldValue(END_DATE, null, true);
      setFocusedInput(END_DATE);
    } else if (isFirstLegTraveled) {
      setFieldValue(END_DATE, null, true);
      setFocusedInput(END_DATE);
    } else {
      setFieldValue(START_DATE, null, true);
      setFieldValue(END_DATE, null, true);
      setFocusedInput(START_DATE);
    }
  };

  const handleInputClick = useCallback(
    (val: string) => {
      setFocusedInput(val);
      if (!isOpen) setIsOpen(true);
    },
    [isOpen]
  );

  const handleFocusChange = (focusedInputElement: string) => {
    if (isMobile && !focusedInputElement)
      return setFocusedInput(tabType === 'return' ? END_DATE : isFirstLegTraveled ? END_DATE : START_DATE);

    if (!focusedInputElement || tabType === 'departure' || tabType === 'return') return handleCloseButton();

    return setFocusedInput(focusedInputElement || START_DATE);
  };

  const handleDatesChange = useCallback(
    ({ startDate, endDate }: CalendarDateProps) => {
      if (tabType === 'departure') {
        setFieldValue(START_DATE, startDate, true);

        return;
      } else if (tabType === 'return') {
        setFieldValue(END_DATE, endDate, true);

        return;
      }

      setFieldValue(START_DATE, startDate, true);
      setFieldValue(END_DATE, endDate, true);

      setIsSameDay(moment(startDate).isSame(moment(endDate)));
    },
    [setFieldValue, tabType]
  );

  const isOutsideRange = (minDay, maxDay) => day => {
    if (moment(day).isBefore(minDay, 'day') || (maxDay && moment(day).isAfter(maxDay, 'day'))) return true;

    if (tabType === 'departure' && values.endDate) return moment(day).isAfter(moment(values.endDate), 'day');

    if (tabType === 'return' && values.startDate) return moment(day).isBefore(moment(values.startDate), 'day');

    return false;
  };

  useEffect(() => {
    setIsSameDay(moment(values.startDate).isSame(moment(values.endDate)));

    return () => setIsSameDay(false);
  }, [values.endDate, values.startDate]);

  useEffect(() => {
    if (isOneWay) setFieldValue(END_DATE, null);
    if (isMobile && isOneWay) setFocusedInput(START_DATE);
  }, [isOneWay, isMobile, setFieldValue]);

  return {
    isOpen,
    isSameDay,
    focusedInput,
    handleCloseButton,
    handleInputClick,
    handleFocusChange,
    handleDatesChange,
    isOutsideRange,
    handleClearDates,
    handleClearDate,
  };
};
