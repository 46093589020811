import React from 'react';

import SelectHeader from '@atoms/Select/SelectHeader';
import { SelectProps } from '@atoms/Select/types';
import { useSelectHandlers } from '@atoms/Select/useSelectHandlers';

import './NumberOfDaysMobile.scss';
import { ParkingMobileSelect } from './ParkingMobileSelect';
import { NumberOfDaysMobileProps } from './types';

export const NumberOfDaysMobile = (props: SelectProps & NumberOfDaysMobileProps) => {
  const hookProps = useSelectHandlers(props);

  return (
    <div style={{ marginTop: '16px' }}>
      <SelectHeader {...props} {...hookProps}>
        <ParkingMobileSelect {...props} {...hookProps} />
      </SelectHeader>
    </div>
  );
};
