import { FormikProps, FormikValues } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './ParkingModal.scss';
import { ModalFooter } from './components/ModalFooter/ModalFooter';
import { ParkingReview } from './components/ParkingReview/ParkingReview';
import { ParkingModalProps } from './types';
import { useParkingModalLogic } from './useParkingModalLogic';

const bem = cn('parking-modal');

export const ParkingModal = observer(({ onCloseRef }: ParkingModalProps) => {
  const { cfStore, bookingStore } = useStores();
  const parking = cfStore.rti.parking;
  const { hideModal, addParkingError } = bookingStore.parking;
  const formRef = useRef<FormikProps<FormikValues>>(null);

  const parkingModalLogic = useParkingModalLogic(onCloseRef);

  const onCloseModal = () => {
    hideModal();
    onCloseRef && onCloseRef.current?.focus();
  };

  return (
    <Modal variant="default-no-space" title={parking.modalTitle} onClose={onCloseModal}>
      <div className={bem()}>
        <div className={bem('content')}>
          {addParkingError && (
            <div className={bem('server-error')}>
              <Icon name="warning" />
              <span className={bem('error-description')}>There was a connection error, please try again.</span>
            </div>
          )}
          <div>
            <Title text={parking.parkingStationTitle} size="h2" />
            <Text className={bem('content-main')} text={parking.parkingStationInfoLabel} />
            <div className={bem('icons')}>
              {parkingModalLogic.iconsList.map((icon, index) => (
                <div key={index} className={bem('icon-container')}>
                  <Icon name={icon.name} className={bem('icon')} />
                  <Text text={icon.label} className={bem('icon-label')} />
                </div>
              ))}
            </div>
          </div>
          <div className={bem('separator')} />
          <ParkingReview formRef={formRef} useParkingModalLogic={parkingModalLogic} />
          <div className={bem('separator')} />
          <Text text={parking.legalDisclaimer.replaceAll('&nbsp;', ' ')} className={bem('disclaimer')} />
        </div>
        <ModalFooter formRef={formRef} useParkingModalLogic={parkingModalLogic} onCloseModal={onCloseModal} />
      </div>
    </Modal>
  );
});
