import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { useDisplayTrainsLogic } from '@booking/routes/TrainSelection/components/DisplayTrains/useDisplayTrainsLogic';
import { FareSelectionTable } from '@organisms/FareSelectionTable/FareSelectionTable';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import { RouteItem } from '../RouteItem';
import './DisplayTrains.scss';
import { DisplayTrainsProps } from './types';

const bem = cn('display-trains');

export const DisplayTrains = observer((props: DisplayTrainsProps) => {
  const { isRoundTrip } = props;

  const { bookingStore, cfStore } = useStores();
  const { activeDate, fareSelectionTable } = bookingStore.tickets;
  const { generic } = cfStore;
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const { sortedJourneys, midnightTrains, handleViewNextDayTrains } = useDisplayTrainsLogic(props);

  return (
    <>
      {sortedJourneys.map(
        (journeys, key) =>
          journeys.hasData &&
          !!activeDate && (
            <div key={`key-${key}`} className={bem('train-selection-list')}>
              {journeys.displayLabel && (
                <div className={bem('separator')}>
                  <div>
                    <span>{journeys.label}</span>
                    <span>{journeys.displayDate}</span>
                  </div>
                </div>
              )}
              {journeys.data?.map(journey => (
                <Fragment key={journey.id}>
                  <RouteItem journey={journey} isRoundTrip={isRoundTrip} />
                  {fareSelectionTable && fareSelectionTable.journeyID === journey.id && <FareSelectionTable />}
                </Fragment>
              ))}
            </div>
          )
      )}
      {!!midnightTrains?.length && (
        <Button
          onClick={handleViewNextDayTrains}
          width={isMobile ? 'full' : 'normal'}
          className={bem('view-next-button')}
          label={`${generic.viewAllLabel} ${moment(activeDate).add('1', 'days').format('MMM D')}`}
        />
      )}
    </>
  );
});
