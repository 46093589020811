import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { InfoModal } from '@account/routes/TripDetails/components/InfoModal';
import { CancelTrip } from '@account/routes/TripDetails/components/Navigation/CancelTrip/CancelTrip';
import { ManageTrip } from '@account/routes/TripDetails/components/Navigation/ManageTrip/ManageTrip';
import { TicketsAndReceipts } from '@account/routes/TripDetails/components/Navigation/TicketsAndReceipts/TicketsAndReceipts';
import { TripNotModifiableMessageDesktop } from '@account/routes/TripDetails/components/Navigation/TripNotModifiableMessage/TripNotModifiableMessage.desktop';
import { TripNotModifiableMessageMobile } from '@account/routes/TripDetails/components/Navigation/TripNotModifiableMessage/TripNotModifiableMessage.mobile';
import { useNavigationLogic } from '@account/routes/TripDetails/components/Navigation/useNavigationLogic';
import { SeatSelectionModal } from '@booking/routes/RTI/SeatSelection/components/SeatSelectionModal';

import { cn } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';
import { mediaQueryList } from 'utils/mediaQueries';

import { ModifyTrip } from '../ModifyTrip/ModifyTrip';
import { ModifyTripProps } from '../ModifyTrip/types';
import ManagePassengers from './ManageTrip/ManagePassengers/ManagePassengers';
import './Navigation.scss';
import { NavigationPlaceholders } from './NavigationPlaceholders';

const bem = cn('trip-details-navigation');

export const Navigation = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { tripDetails } = accountStore;
  const { navigation } = cfStore.account.tripDetails;
  const { manageTripsSection, ticketsAndReceiptsSection } = navigation;

  const { isLoading, trip } = tripDetails;
  const { showManagePassengersModal, showModifyTripWidget, showSeatSelectionModal } = tripDetails;

  const navigationLogic = useNavigationLogic();
  const { showModifyTripInfoModal, setShowModifyTripInfoModal, handleSeatSelectionModalClose } = navigationLogic;
  const { messageForCantCancelModify, isCancellableTrip, modifyTripData } = navigationLogic;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  if (isLoading) return <NavigationPlaceholders />;

  return (
    <div className={bem()}>
      {showModifyTripInfoModal && <InfoModal buttonAction={() => setShowModifyTripInfoModal(false)} />}

      {!!messageForCantCancelModify && <TripNotModifiableMessageDesktop />}

      {manageTripsSection.title && <ManageTrip />}

      {ticketsAndReceiptsSection.title && <TicketsAndReceipts />}

      {navigation.cancelTripLabel && isCancellableTrip && !isKiosk() && <CancelTrip />}

      {!!messageForCantCancelModify && isMobile && <TripNotModifiableMessageMobile />}

      {showManagePassengersModal && <ManagePassengers />}

      {showSeatSelectionModal && <SeatSelectionModal handleCloseModal={handleSeatSelectionModalClose} isAccountFlow />}

      {showModifyTripWidget && !trip?.isCancelled && trip?.outboundRoute?.id && (
        <ModifyTrip {...(modifyTripData as ModifyTripProps)} />
      )}
    </div>
  );
});
