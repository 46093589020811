import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { useSwipeable } from 'react-swipeable';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { DrawerProps } from '@molecules/Drawer/types';

import { cn, cx } from 'utils/classNames';

import './Drawer.scss';

const bem = cn('drawer');

export const Drawer = ({
  isVisible,
  onClose,
  children,
  buttonProps,
  secondButtonProps,
  isBackgroundGrey,
  isMobileRti,
  isMobileRewards,
  costSummaryItemsLength,
  disableAutoZoom,
  className = '',
}: DrawerProps) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [y, setY] = useState(0);
  const { bookingStore } = useStores();
  const { drawerVisible, fareTableDrawerVisible } = bookingStore.tickets;

  const handlers = useSwipeable({
    preventScrollOnSwipe: false,
    trackTouch: true,
    trackMouse: true,
    onSwiping(e) {
      if (bodyRef && bodyRef.current && bodyRef.current.contains(e.event.target as any)) return;
      if (e.deltaY > 0) setY(e.deltaY);
    },
    onTouchEndOrOnMouseUp() {
      if (y > 0 && onClose) onClose();
    },
  });

  const styles: any = useMemo(() => {
    let drawerHeight = costSummaryItemsLength ? 75 + (costSummaryItemsLength - 3) * 4 : 75;
    drawerHeight = Math.min(drawerHeight, 92);

    return {
      '--drawewr-height': `${drawerHeight}%`,
      transform: `translateY(${y}px)`,
    };
  }, [costSummaryItemsLength, y]);

  useEffect(() => {
    if (fareTableDrawerVisible || drawerVisible || isMobileRewards) {
      document.body.classList.add('blockScroll');
      setY(0);
    } else document.body.classList.remove('blockScroll');

    const viewportMetaTag = document.querySelector('meta[name="viewport"]');
    const originalContent = viewportMetaTag?.getAttribute('content');
    if (viewportMetaTag)
      if (disableAutoZoom)
        viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0');
      else
        viewportMetaTag.setAttribute(
          'content',
          originalContent || 'width=device-width, initial-scale=1.0, maximum-scale=5.0'
        );

    return () => {
      document.body.classList.remove('blockScroll');
      if (viewportMetaTag)
        viewportMetaTag.setAttribute(
          'content',
          originalContent || 'width=device-width, initial-scale=1.0, maximum-scale=5.0'
        );
    };
  }, [fareTableDrawerVisible, drawerVisible, disableAutoZoom, isMobileRewards]);

  return (
    <Portal>
      <div className={cx(bem({ isVisible }), className)} {...handlers}>
        <div className={bem('background')} onClick={onClose} />
        <div className={bem('content', { hasButton: !!buttonProps, isBackgroundGrey, isMobileRti })} style={styles}>
          <div className={bem('header')}>
            <div className={bem('handler')} />
          </div>
          <div ref={bodyRef} className={bem('body')}>
            {children}
          </div>
          {(buttonProps || secondButtonProps) && (
            <div className={bem('footer', { hasSecondButton: !!secondButtonProps })}>
              {buttonProps && <Button {...buttonProps} className={bem('action')} />}
              {secondButtonProps && <Button {...secondButtonProps} className={bem('action')} />}
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};
