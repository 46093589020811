import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Timer } from '@kiosk/components/TimeoutModal/Timer';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './TimeoutModal.scss';

const bem = cn('timeout-modal');

export const TimeoutModal = observer(() => {
  const location = useLocation();
  const [isModalVisible, setModalVisibility] = useState(false);

  const { kioskStore } = useStores();
  const { timeoutModal, confirmSessionClearance } = kioskStore;

  const onIdle = () => {
    setModalVisibility(false);
    idleTimer.reset();
    confirmSessionClearance();
  };

  const onPrompt = () => {
    setModalVisibility(true);
  };

  const handleKeepSession = () => {
    setModalVisibility(false);
    idleTimer.reset();
  };

  const idleTimer = useIdleTimer({
    promptBeforeIdle: 1000 * timeoutModal.promptBeforeIdle,
    timeout: 1000 * timeoutModal.timeout,
    debounce: 1000,
    onPrompt,
    onIdle,
  });

  useEffect(() => {
    if (location.pathname === '/') idleTimer.pause();
    else idleTimer.resume();
  }, [idleTimer, location.pathname]);

  if (!isModalVisible) return null;

  return (
    <Modal variant="mini">
      <div className={bem()}>
        <Timer remainingTime={idleTimer.getRemainingTime()} />
        <div className={bem('title')}>{timeoutModal.title}</div>
        <div className={bem('description')}>{timeoutModal.description}</div>

        <div className={bem('buttons')}>
          <Button
            colors={{ backgroundOrBorder: color_base_medium_light_grey }}
            label={timeoutModal.cancelLabel}
            onClick={onIdle}
          />
          <Button color="yellow" label={timeoutModal.confirmLabel} onClick={handleKeepSession} />
        </div>
      </div>
    </Modal>
  );
});
