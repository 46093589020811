import { useMemo } from 'react';
import * as Yup from 'yup';

export const useValidationSchema = (invalidInputLabel: string, requiredInputLabel: string) =>
  useMemo(() => {
    const postalCodeRegex = /^[a-zA-Z0-9]+$/;
    const alphabeticWithSpecialCharactersRegex = /^[\p{L}\s.'-]+$/u;

    return Yup.object().shape({
      firstName: Yup.string()
        .matches(alphabeticWithSpecialCharactersRegex, invalidInputLabel)
        .required(requiredInputLabel),
      lastName: Yup.string()
        .matches(alphabeticWithSpecialCharactersRegex, invalidInputLabel)
        .required(requiredInputLabel),
      email: Yup.string().email(invalidInputLabel).required(requiredInputLabel),
      postalCode: Yup.string().matches(postalCodeRegex, invalidInputLabel).required(requiredInputLabel),
    });
  }, [invalidInputLabel, requiredInputLabel]);
