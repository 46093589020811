import { Formik } from 'formik';
import { useQueries } from 'hooks/useQueries';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { rootStore, useStores } from 'stores';

import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import Button from '@atoms/Button/Button';
import { getManageTripsFormValidationSchema } from '@organisms/BookingSearch/validator';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { setFromHomePageFlag } from 'utils/storage';

import './ManageTripsForm.scss';
import ManageAction from './components/ManageAction/ManageAction';
import TextInputField from './components/TextInputField/TextInputField';
import { DEFAULT_I18N, DEFAULT_INITIAL_VALUES } from './constants';
import { ManageTripsFormProps } from './types';

const bem = cn('manage-trips-form');

export const ManageTripsFormDesktop = observer((props: ManageTripsFormProps) => {
  const { initialFormValues = DEFAULT_INITIAL_VALUES, i18n = DEFAULT_I18N, manageTripsPage } = props;
  const { deprecatedManageTripsPage, tripDetailsPage } = props;
  const { role, ariaLabelledBy, id, tabIndex } = props;
  const { manageTripsFormStore } = useStores();
  const { appendQueries } = useQueries();
  const { getDefaultRoute } = useTripDetailsConfig();

  const { setFormValues } = manageTripsFormStore;

  const handleSubmit = async values => {
    setFormValues(values);
    setFromHomePageFlag();
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: i18n?.submitFormLabel,
        customLink: getDefaultRoute(tripDetailsPage),
        externalLink: '',
        linkType: 'linkClick',
      },
    });
    const formattedValues = {
      reference_number: values.referenceNumber,
      first_name: values.firstName,
      last_name: values.lastName,
    };
    await appendQueries(
      deprecatedManageTripsPage ? manageTripsPage : getDefaultRoute(tripDetailsPage),
      deprecatedManageTripsPage,
      formattedValues,
      '',
      true
    );
  };

  const sch = getManageTripsFormValidationSchema(i18n, deprecatedManageTripsPage);

  return (
    <div className={bem()} role={role} aria-labelledby={ariaLabelledBy} id={id} tabIndex={tabIndex}>
      <Formik onSubmit={handleSubmit} initialValues={initialFormValues} validationSchema={sch} enableReinitialize>
        {({ handleSubmit: formikSubmit, submitForm }) => (
          <form onSubmit={formikSubmit}>
            <div className={bem('first-row')}>
              <TextInputField
                name="referenceNumber"
                label={i18n.ticketNumberLabel}
                placeholder={i18n.ticketNumberPlaceholderLabel}
                allUppercase
                isRequired
              />
              {!deprecatedManageTripsPage && (
                <TextInputField
                  name="firstName"
                  label={i18n.firstNameLabel}
                  placeholder={i18n.firstNamePlaceholderLabel}
                  isRequired
                />
              )}
              <TextInputField
                name="lastName"
                label={i18n.lastNameLabel}
                placeholder={i18n.lastNamePlaceholderLabel}
                isRequired
              />
            </div>

            <div className={bem('second-row')}>
              <ManageAction i18n={i18n} deprecatedManageTripsPage={deprecatedManageTripsPage} />
              <Button color="yellow" type="submit" label={i18n?.submitFormLabel || 'Submit'} onClick={submitForm} />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
});
