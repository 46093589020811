import { useCallback, useEffect, useState } from 'react';

import { OptionsData } from '@atoms/Select/types';
import {
  MobileParkingPassUrlParams,
  MonthlyParkingPassesProps,
  ParkingPassOptionsConfig,
  ParkingPassStationConfig,
  ParkingPassUrlParams,
} from '@organisms/ParkingPasses/types';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { base64Decode } from 'utils/helpers';

export const useParkingPassLogic = (props: MonthlyParkingPassesProps) => {
  const {
    title,
    requiredFieldLabel,
    subtitle,
    licensePlateTextFieldInvalidLabel,
    garageSelectLabel,
    garageNotes,
    vehicleRegistrationTitle,
    stateSelectLabel,
    autoRenewCheckboxLabel,
    autoRenewNote,
    licensePlateTextFieldPlaceholder,
    primaryButton,
    data,
    image,
    colors,
  } = props;

  const [initialFormValues, setInitialFormValues] = useState({
    ppStationName: '',
    ppLicenseState: 'Florida',
    ppLicenseNumber: '',
    autoRenew: false,
  });

  const [parsedUrlData, setParsedUrlData] = useState<undefined | ParkingPassUrlParams>(undefined);
  const [parsedMobileUrlData, setParsedMobileUrlData] = useState<undefined | MobileParkingPassUrlParams>(undefined);

  const [loadingStations, setLoadingStations] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [error, setError] = useState(false);
  const [stations, setStations] = useState<ParkingPassStationConfig[]>([]);
  const [stationsData, setStationsData] = useState<OptionsData[]>([]);
  const [passesConfig, setPassesConfig] = useState<ParkingPassOptionsConfig[]>([]);

  const labels: MonthlyParkingPassesProps = {
    title,
    requiredFieldLabel,
    subtitle,
    licensePlateTextFieldInvalidLabel,
    garageSelectLabel,
    garageNotes,
    vehicleRegistrationTitle,
    stateSelectLabel,
    autoRenewCheckboxLabel,
    autoRenewNote,
    licensePlateTextFieldPlaceholder,
    primaryButton,
    data,
    colors,
    image,
  };

  const fetchParkingPassStations = useCallback(async initialStationID => {
    setLoadingStations(true);
    setError(false);
    try {
      const url = `${requestUrls.getRestUrl(requestUrls.products.parkingPassStations)}.xjson`;

      const response = await api.get(url);

      const responseStations = response.data.stations.filter(station => station.id !== 'MCO');

      setStations(responseStations);
      setStationsData(
        responseStations.map(station => ({
          value: station.name,
          label: station.name,
        }))
      );

      if (initialStationID) fetchParkingPassOptions(initialStationID);
      else if (response.data.stations.length) {
        const miamiStation = responseStations.find(station => station.id === 'EKW');
        if (miamiStation) fetchParkingPassOptions(miamiStation.id);
        else fetchParkingPassOptions(responseStations[0].id);
      }
    } catch (e: any) {
      console.error('error', e);
      setError(true);
    }

    setLoadingStations(false);
  }, []);

  useEffect(() => {
    let initialData: ParkingPassUrlParams;
    if (data) {
      initialData = JSON.parse(base64Decode(data));
      try {
        if (
          !initialData ||
          !initialData.ppStationID ||
          !initialData.ppStationName ||
          !initialData.ppLicenseState ||
          !initialData.ppLicenseNumber ||
          !initialData.autoRenew
        ) {
          if (!initialData.jwt || !initialData.mobileEmail || !initialData.mobileCallbackURL)
            console.error(`Invalid URL params ${initialData}`);
          fetchParkingPassStations(undefined);
          setParsedUrlData(undefined);
        } else {
          fetchParkingPassStations(initialData.ppStationID);
          setInitialFormValues({
            ppStationName: initialData.ppStationName,
            ppLicenseState: initialData.ppLicenseState,
            ppLicenseNumber: initialData.ppLicenseNumber,
            autoRenew: initialData.autoRenew === 'true',
          });
          setParsedUrlData(initialData);
        }
      } catch (e) {
        console.error(e);
      }

      setParsedMobileUrlData({
        jwt: initialData.jwt,
        mobileEmail: initialData.mobileEmail,
        mobileCallbackURL: initialData.mobileCallbackURL,
      });
    } else {
      fetchParkingPassStations(undefined);
      setParsedUrlData(undefined);
    }
  }, [fetchParkingPassStations, data]);

  const fetchParkingPassOptions = async stationId => {
    setLoadingOptions(true);
    setError(false);
    try {
      const url = `${requestUrls.getRestUrl(requestUrls.products.parkingPassOptions)}.xjson`;

      const response = await api.get(url, {
        params: {
          stationId: stationId,
        },
      });

      if (response?.data?.products) setPassesConfig([...response.data.products]);
      else setPassesConfig([]);
    } catch (e: any) {
      console.error('error', e);
      setError(true);
    }
    setLoadingOptions(false);
  };

  return {
    labels,
    initialFormValues,
    parsedUrlData,
    parsedMobileUrlData,
    loadingStations,
    loadingOptions,
    error,
    stations,
    stationsData,
    passesConfig,
    fetchParkingPassOptions,
  };
};
