import { useField } from 'formik';
import { useEffect, useState } from 'react';

import { cn } from 'utils/classNames';

const bem = cn('phone-number');

export const usePhoneNumberLogic = props => {
  const { name } = props;
  const [numberFormat, setNumberFormat] = useState<string | null>(null);
  const [field, meta, helpers] = useField({ name, className: bem() });
  const { countryCode = '+1', number } = field.value || {};

  const { setValue } = helpers;
  const cleanNumber = (number: string): string | undefined => (number ? number.replace(/[() -]/g, '') : undefined);

  const handleClickTrailingIcon = () => {
    setValue('');
  };
  const updateNumberFormat = prefix => {
    setNumberFormat(prefix === '+1' ? '(999) 999-9999' : '99999999999999999999');
  };

  const onPrefixChange = value => {
    updateNumberFormat(value);
    helpers.setValue({ countryCode: value, number: cleanNumber(number) });
  };

  const onSuffixChange = event => {
    helpers.setValue({
      countryCode: countryCode,
      number: cleanNumber(event.target.value),
    });
  };

  useEffect(() => {
    updateNumberFormat(countryCode);
  }, [countryCode]);

  return {
    handleClickTrailingIcon,
    onPrefixChange,
    onSuffixChange,
    onBlur: field.onBlur,
    numberFormat,
    countryCode,
    meta,
    number,
    field,
  };
};
