import { default as dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as Yup from 'yup';

import { isLoyaltyEnabled } from '../../../../utils/metas';

export const dateConfig = {
  dateFormat: 'MM/DD/YYYY',
  minDate: '10/10/1900',
  intermediateDate: '10/10/2010',
  maxDate: dayjs().subtract(1, 'day'),
};

export const initialFormValues = {
  phoneNumber: {
    countryCode: '+1',
    number: '',
  },
  automatedTextsConsent: true,
  exclusivesConsent: isLoyaltyEnabled(),
  firstName: '',
  lastName: '',
  zipCode: isLoyaltyEnabled() ? '' : undefined,
  dateOfBirth: '',
};

export const getFormValidationSchema = errors => {
  const { requiredError, minLengthError, maxLengthError, invalidDate, maxDateError, minDateError } = errors;

  return [
    Yup.object().shape({
      phoneNumber: Yup.object()
        .test('phoneNumber', requiredError, value => value.countryCode && value.number)
        .required(requiredError),
    }),
    Yup.object().shape({
      firstName: Yup.string().min(2, minLengthError).max(50, maxLengthError).required(requiredError),
      lastName: Yup.string().min(2, minLengthError).max(50, maxLengthError).required(requiredError),
      zipCode: isLoyaltyEnabled()
        ? Yup.string()
            .min(1, minLengthError)
            .max(15, maxLengthError)
            .matches(/^[a-zA-Z0-9]+$/)
            .required(requiredError)
        : Yup.string().notRequired(),
      dateOfBirth: Yup.date()
        .required(requiredError)
        .transform((value, originalValue) => {
          dayjs.extend(customParseFormat);
          const result = dayjs(originalValue, dateConfig.dateFormat, true);

          return result.toDate();
        })
        .typeError(invalidDate)
        .min(dateConfig.minDate, minDateError)
        .max(dateConfig.maxDate, maxDateError),
    }),
  ];
};
