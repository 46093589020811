import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import LoadingIcon from '@atoms/LoadingIcon/LoadingIcon';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './PaymentProcessingModal.scss';

const bem = cn('payment-processing-modal');

export const PaymentProcessingModal = observer(() => {
  const { kioskStore } = useStores();
  const { paymentProcessingModal } = kioskStore;
  const { title, isVisible } = paymentProcessingModal;

  if (!isVisible) return null;

  return (
    <Modal variant="mini">
      <div className={bem()}>
        <div className={bem('icon')}>
          <LoadingIcon width={42} height={42} className={bem('loading-icon')} />
        </div>
        <div className={bem('title')}>{title}</div>
      </div>
    </Modal>
  );
});
