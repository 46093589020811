import { Formik } from 'formik';
import React from 'react';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { Title } from '@atoms/Title';
import { Modal } from '@molecules/Modal';
import { useValidationSchema } from '@organisms/PrivacyRequestForm/useValidationSchema';

import { cn } from 'utils/classNames';

import { PrivacyRequestFormModalProps } from './../../types';
import { PrivacyRequestFormContent } from './../PrivacyRequestFormContent';
import { PrivacyRequestFormStatusBanner } from './../PrivacyRequestFormStatusBanner';

const bem = cn('privacy-request-form-modal');

export const PrivacyRequestFormModal = (props: PrivacyRequestFormModalProps) => {
  const { componentTitle, componentSubtitle, privacyRequestFormLogic } = props;
  const { submitSuccessMessage, submitErrorMessage } = props;

  const { submitForm, submitFormStatus, closeModal, initialValues } = privacyRequestFormLogic;

  const validationSchema = useValidationSchema(props);

  return (
    <Modal variant="no-space-scroll" onClose={closeModal} className={bem('modal')}>
      <div className={bem()}>
        <div className={bem('header')}>
          <h1 className={bem('title')}>
            <Title text={componentTitle} size="h2" />
          </h1>
          <Text className={bem('text')} text={componentSubtitle} />
          <button className={bem('icon-wrapper')} onClick={closeModal}>
            <Icon name="close2" className={bem('icon')} />
          </button>
        </div>
        <PrivacyRequestFormStatusBanner
          isError={submitFormStatus === 'error'}
          errorMessage={submitErrorMessage}
          successMessage={submitSuccessMessage}
        />
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitForm}>
          <PrivacyRequestFormContent {...props} />
        </Formik>
      </div>
    </Modal>
  );
};
