import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { Input } from '@atoms/Input';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';
import { Modal } from '@molecules/Modal';

import { api } from 'utils/api';
import { cn } from 'utils/classNames';
import { requestUrls } from 'utils/constants';
import { mediaQueryList } from 'utils/mediaQueries';

import './ShareTripModal.scss';

const bem = cn('trip-details-share-trip-modal');

export const ShareTripModal = observer(({ handleShareTripModalClose }: any) => {
  const [emails, setEmails] = useState(['']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAllEmail, setShowAllEmails] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const { cfStore, accountStore } = useStores();
  const referenceNumber = accountStore.tripDetails?.trip?.referenceNumber;
  const { shareTripTitle, shareTripSubtitle, emailInputPlaceholder } = cfStore.account.tripDetails;
  const { addRecipientLabel, cancelButtonLabel, sendEmailButtonLabel } = cfStore.account.tripDetails;
  const { tripDetailsHaveBeenSentToLabel, andOtherEmailsLabel, closeButtonLabel } = cfStore.account.tripDetails;
  const { serverErrorMessage } = cfStore.account.tripDetails;

  const isTablet = useMediaPredicate(mediaQueryList.maxTablet);

  const handleEmailChanged = (e, idx) => {
    setSubmitError(false);
    setEmails(val => {
      val[idx] = e.target.value;

      return [...val];
    });
  };

  const handleAddEmail = () => {
    setEmails(val => {
      val.push('');

      return [...val];
    });
  };

  const handleSubmit = async () => {
    formRef?.current?.reportValidity();
    const isValid = formRef?.current?.checkValidity();

    if (!isValid) return;

    try {
      setIsSubmitting(true);
      setSubmitError(false);

      await api.post(
        requestUrls.getBffUrl(requestUrls.account.shareTripEmails).replace('{booking_number}', referenceNumber || ''),
        { emails },
        {
          headers: {
            'Booking-Session-Access-Token': accountStore.tripDetails?.bookingSession?.accessToken,
            'Booking-Session-Refresh-Token': accountStore.tripDetails?.bookingSession?.refreshToken,
          },
        }
      );

      setShowSuccess(true);
      setIsSubmitting(false);
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
      setShowSuccess(false);
      setSubmitError(true);
      contentRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  const handleShowAllEmails = () => {
    setShowAllEmails(true);
  };

  return (
    <Modal
      variant={isTablet ? 'no-space' : 'mini'}
      className={isTablet ? bem('modal-mobile') : bem('modal')}
      onClose={handleShareTripModalClose}
    >
      <div ref={contentRef} className={bem({ withPadding: isTablet })}>
        <button
          className={bem('close-button', { isMobile: isTablet })}
          aria-label="Close"
          onClick={handleShareTripModalClose}
        >
          <Icon name="close2" />
        </button>
        {!showSuccess && (
          <>
            <Text text={shareTripTitle} className={bem('title')} />
            {submitError && (
              <div className={bem('error-banner')}>
                <Icon name="warning" />
                <Text text={serverErrorMessage} />
              </div>
            )}
            <Text text={shareTripSubtitle} className={bem('subtitle')} />
            <form ref={formRef} className={bem('emails-form')}>
              {emails.map((email, idx) => (
                <Input
                  key={idx}
                  type="email"
                  value={email}
                  name={`email-${idx}`}
                  className={bem('input')}
                  placeholder={emailInputPlaceholder}
                  onChange={e => handleEmailChanged(e, idx)}
                  required
                />
              ))}
            </form>
            {addRecipientLabel && emails.every(email => !!email) && (
              <LinkButton label={addRecipientLabel} onClick={handleAddEmail} />
            )}
            <div className={bem('footer', { isMobile: isTablet })}>
              {cancelButtonLabel && (
                <Button
                  label={cancelButtonLabel}
                  variant="secondary"
                  color="charcoal"
                  disabled={isSubmitting}
                  onClick={handleShareTripModalClose}
                />
              )}
              {sendEmailButtonLabel && (
                <Button
                  label={sendEmailButtonLabel}
                  variant="primary"
                  color="charcoal"
                  onClick={handleSubmit}
                  loading={isSubmitting}
                />
              )}
            </div>
          </>
        )}
        {showSuccess && (
          <div className={bem('success-wrapper', { isMobile: isTablet })}>
            <Icon name="check-circle" className={bem('icon')} />
            <div className={bem('success-first-row')}>
              <Text text={tripDetailsHaveBeenSentToLabel} className={bem('success-light-text')} />
              <Text text={emails[0]} className={bem('success-bold-text')} />
            </div>
            {emails.length > 1 && (
              <div className={bem('success-second-row')}>
                {!showAllEmail && andOtherEmailsLabel && (
                  <LinkButton
                    label={andOtherEmailsLabel?.replaceAll('$numberEmails', (emails.length - 1).toString())}
                    onClick={handleShowAllEmails}
                    variant="big"
                  />
                )}
                {showAllEmail &&
                  emails
                    .slice(1)
                    .map((email, idx) => <Text key={idx} text={email} className={bem('success-bold-text')} />)}
              </div>
            )}
            {closeButtonLabel && (
              <div className={bem('success-footer')}>
                <Button
                  label={closeButtonLabel}
                  onClick={handleShareTripModalClose}
                  variant="primary"
                  color="charcoal"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
});
