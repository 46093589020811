import React from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { TravelPassEligibleProps } from '../../types';
import './TravelPassEligible.scss';

const bem = cn('travel-pass-eligible');

export const TravelPassEligible = (props: TravelPassEligibleProps) => {
  const { isTravelPassEligible, isTableHeader } = props;
  const { cfStore, bookingStore } = useStores();
  const { brandedFaresSwitch } = bookingStore.tickets;
  const { passEligible } = cfStore.trainSelection.routeItem;

  if (!isTravelPassEligible || !brandedFaresSwitch) return null;

  return (
    <div className={bem({ isTableHeader })}>
      <Icon name="check" className={bem('check-icon')} />
      <Text text={passEligible} className={bem('pass-eligible-label')} />
    </div>
  );
};
