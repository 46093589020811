import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Checkbox from '@atoms/Checkbox/Checkbox';
import { Icon } from '@atoms/Icon';
import Image from '@atoms/Image/Image';

import { cn } from 'utils/classNames';

import '../PassItem.scss';
import { TravelPassProps } from '../types';

const bem = cn('rti-wallet-pass');

export const TravelPass = observer((props: TravelPassProps) => {
  const { bookingStore, cfStore } = useStores();
  const { rti } = cfStore;
  const {
    reviewAndPay: { brightlineWallet },
  } = rti;

  const { applyTravelPass, removeTravelPass, travelPassCodes, giftCards } = bookingStore.rti;
  const { setTpNotApplicableDueToGiftCardPayment } = bookingStore.rti;
  const costSummary = bookingStore.rti.provisionalBooking.tripDetails.costSummary;

  const { travelPass, isApplicableToBooking, checked, currentQuantityApplied, disabledPass } = props;
  const { passName, code, originStation, destinationStation, isRoundTrip, availableRides } = travelPass;

  const applyPassDisabledDueToGC = useMemo(() => {
    if (!giftCards.length) return false;

    let travelPassCost = 0;
    if (travelPass.classType == costSummary?.sections?.[0]?.items?.[0]?.productName?.toLowerCase())
      travelPassCost += costSummary?.sections[0].items[0].totalOriginalPrice;
    if (travelPass.classType == costSummary?.sections?.[1]?.items?.[0]?.productName.toLowerCase())
      travelPassCost += costSummary?.sections[1].items[0].totalOriginalPrice;

    return costSummary && travelPassCost > costSummary.bookingTotal.totalToBePaid;
  }, [costSummary, giftCards.length, travelPass.classType]);

  const disabled = useMemo(
    () =>
      !checked &&
      (!isApplicableToBooking ||
        availableRides?.remaining <= 0 ||
        disabledPass ||
        (applyPassDisabledDueToGC && giftCards?.length > 0)),
    [availableRides?.remaining, checked, disabledPass, isApplicableToBooking, applyPassDisabledDueToGC, giftCards]
  );

  const image = useMemo(() => {
    if (travelPass.classType === 'smart')
      return !isApplicableToBooking ? brightlineWallet.disabledTravelPassIcon : brightlineWallet.travelPassIcon;

    return !isApplicableToBooking ? brightlineWallet.disabledPremiumPassIcon : brightlineWallet.premiumPassIcon;
  }, [
    brightlineWallet.disabledTravelPassIcon,
    brightlineWallet.travelPassIcon,
    isApplicableToBooking,
    brightlineWallet.disabledPremiumPassIcon,
    brightlineWallet.premiumPassIcon,
    travelPass.classType,
  ]);

  const availableRidesMessage = useMemo(
    () =>
      disabled
        ? brightlineWallet.notApplicableLabel
        : availableRides?.remaining > 1
        ? brightlineWallet.multiplePassesApplicableLabel.replace('{passesAmount}', availableRides?.remaining.toString())
        : brightlineWallet.onePassApplicableLabel,
    [
      availableRides?.remaining,
      brightlineWallet.multiplePassesApplicableLabel,
      brightlineWallet.notApplicableLabel,
      brightlineWallet.onePassApplicableLabel,
      disabled,
    ]
  );

  const handleTravelPassClick = () => {
    if (applyPassDisabledDueToGC) setTpNotApplicableDueToGiftCardPayment(true);
    else setTpNotApplicableDueToGiftCardPayment(false);

    if (disabled) return;

    if (checked) removeTravelPass(code);
    else applyTravelPass(code);
  };

  const handleCheckboxChange = () => null;

  return (
    <button className={bem({ disabled, active: checked })} onClick={handleTravelPassClick}>
      <div className={bem('image')}>
        <Image {...image} />
      </div>
      <div className={bem('data')}>
        <div className={bem('info')}>
          <div className={bem('details')}>
            <div className={bem('pass-name')}>{passName}</div>
            <div className={bem('stations')}>
              {originStation.abbreviation}
              <Icon name={isRoundTrip ? 'arrow-double' : 'arrow-from-to'} className={bem('stations-icon')} />
              {destinationStation.abbreviation}
            </div>
          </div>
          {availableRides?.remaining <= 5 && <div className={bem('remain-items')}>{availableRidesMessage}</div>}
        </div>
        <div className={bem('checkbox-data')}>
          <Checkbox
            name={passName}
            label=""
            isChecked={travelPassCodes.includes(code)}
            disabled={!!disabled}
            onChange={handleCheckboxChange}
          />
          {checked && currentQuantityApplied > 0 && (
            <span>
              {brightlineWallet.multipleTravelPassesAppliedLabel.replace(
                '{passesAmount}',
                currentQuantityApplied.toString()
              )}
            </span>
          )}
        </div>
      </div>
    </button>
  );
});
