import { Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';

import { NewsletterFormV2Content } from './NewsletterFormV2Content/NewsletterFormV2Content';
import NewsletterFormV2SuccessMessage from './NewsletterFormV2SuccessMessage/NewsletterFormV2SuccessMessage';
import { initialFormValues } from './constants';
import { NewsletterFormV2Props } from './types';
import { useServerLogic } from './useServerLogic';
import { useValidationSchema } from './useValidationSchema';

const bem = cn('newsletter-v2');

const NewsletterFormV2 = (props: NewsletterFormV2Props) => {
  const {
    image,
    title,
    colors,
    submitAnotherRequestButton,
    formSubmissionSuccessTitle,
    formSubmissionSuccessDescription,
    invalidInputLabel,
    requiredInputLabel,
  } = props;

  const { onSubmit, serverStatus } = useServerLogic();
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const validationSchema = useValidationSchema({ invalidInputLabel, requiredInputLabel });

  useEffect(() => {
    if (serverStatus === 'success') setIsSubmittedSuccessfully(true);
  }, [serverStatus]);

  const handleSubmit = async (values, { setSubmitting }) => {
    await onSubmit(values);
    setSubmitting(false);
    if (serverStatus === 'success') setIsSubmittedSuccessfully(true);
  };

  const style: any = useMemo(
    () => ({
      '--newsletter-form-v2-background-color': colors?.background,
    }),
    [colors]
  );

  const ref = useRef<HTMLDivElement>(null);

  const resetFormAndSubmitAnother = () => {
    setIsSubmittedSuccessfully(false);
  };
  const backgroundImageStyle = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '15px',
  };

  return (
    <div className={bem()} style={style}>
      <div className={bem('content')}>
        <Text text={title} className={bem('title')} as="h2" />
        <div className={bem('row')}>
          {image && (
            <div
              className={cx(bem('column'), bem('image'))}
              style={{ backgroundImage: `url(${image?.src})`, ...backgroundImageStyle }}
            />
          )}
          {isSubmittedSuccessfully ? (
            <NewsletterFormV2SuccessMessage
              formSubmissionSuccessTitle={formSubmissionSuccessTitle}
              formSubmissionSuccessDescription={formSubmissionSuccessDescription}
              submitAnotherRequestButton={submitAnotherRequestButton}
              onResetForm={resetFormAndSubmitAnother}
            />
          ) : (
            <div ref={ref} className={bem('column')}>
              <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                <NewsletterFormV2Content {...props} />
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterFormV2;
