import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './MobileFooter.scss';

const bem = cn('passes-mobile-footer');

export const MobileFooterPlaceholder = observer(() => (
  <ReactPlaceholder
    ready={false}
    customPlaceholder={
      <div className={bem()}>
        <RectShape color={color_base_medium_light_grey} style={{ width: 100, height: 26, borderRadius: 8 }} />
        <RectShape color={color_base_medium_light_grey} style={{ width: 55, height: 26, borderRadius: 8 }} />
      </div>
    }
    showLoadingAnimation
  >
    <div />
  </ReactPlaceholder>
));
