import React, { Suspense, useCallback, useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { ModifyTripForm } from '@account/routes/TripDetails/components/ModifyTrip/components/ModifyTripForm/ModifyTripForm';
import { Modal } from '@account/routes/TripDetails/components/ModifyTrip/components/ModifyTripForm/components/Modal';
import { Tabs } from '@atoms/Tabs';

import { mediaQueryList } from 'utils/mediaQueries';

import './ModifyTrip.scss';
import { ModifyTripsDefaultArgs } from './constants';
import { ModifyTabProps, ModifyTripProps } from './types';

export const ModifyTrip = (props: ModifyTripProps) => {
  const { id, pillButtonsPosition, pillButtons = [], tabs = [], design, labels } = props;

  const { accountStore } = useStores();
  const { setShowModifyTripWidget } = accountStore.tripDetails;

  const onCloseModifyTripModal = useCallback(() => {
    setShowModifyTripWidget(false);
  }, [setShowModifyTripWidget]);

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const visibleTabs: ModifyTabProps[] = useMemo(() => tabs.filter(tab => !tab?.disabled || !!tab?.label), [tabs]);

  const extraPillButton = useMemo(() => {
    const buildYourPass = visibleTabs.find(tab => tab.type === 'tickets')?.data?.tripSelection?.buildYourPass;

    if (!buildYourPass || !isMobile) return undefined;

    return buildYourPass;
  }, [visibleTabs, isMobile]);

  const defaulModifyTripArgs = ModifyTripsDefaultArgs(labels);

  const tabsNotAccessible = useMemo(
    () =>
      visibleTabs.map(tab => ({
        disabled: tab?.disabled || false,
        type: tab.type,
      })),
    [visibleTabs]
  );

  return (
    <div>
      <Suspense>
        <Modal title={isMobile && defaulModifyTripArgs.title} onClose={onCloseModifyTripModal}>
          <Tabs
            id={id}
            labels={visibleTabs.map(tab => tab.label)}
            pillButtons={pillButtons}
            extraPillButton={extraPillButton}
            pillButtonsPosition={pillButtonsPosition}
            design={design}
            tabsNotAccessible={tabsNotAccessible}
          >
            {visibleTabs.map(tab => {
              const tabType = tab.type;
              const isFirstLegTraveled =
                tab.type === 'departure' || tab.type === 'both' ? tab?.isFirstLegTraveled : undefined;
              const isSecondLegTraveled =
                tab.type === 'return' || tab.type === 'both' ? tab?.isSecondLegTraveled : undefined;
              const title = tab.label;
              const isRoundTrip = tab.isRoundTrip;

              return (
                <ModifyTripForm
                  key={tabType}
                  tabType={tab.type}
                  isFirstLegTraveled={isFirstLegTraveled}
                  isSecondLegTraveled={isSecondLegTraveled}
                  ariaLabelledBy="tab-0"
                  id="panel-0"
                  role="tabpanel"
                  {...tab.data}
                  {...defaulModifyTripArgs}
                  title={title}
                  labels={labels}
                  isRoundTrip={isRoundTrip}
                />
              );
            })}
          </Tabs>
        </Modal>
      </Suspense>
    </div>
  );
};
