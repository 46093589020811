import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { InputField } from '@atoms/Input';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './PromoCode.scss';
import { PassesPromoCodeProps } from './types';

const bem = cn('passes-promo-code');

export const PromoCode = observer((props: PassesPromoCodeProps) => {
  const { promoCode, loadingPromoCode, promoCodeError } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const { cfStore, passesStore } = useStores();
  const { applyPromoCode, deletePromoCode } = passesStore.checkout;
  const cfLabels = cfStore.passes.costSummary;

  const handleSubmitPromoCode = values => {
    applyPromoCode(values.promoCode);
  };

  const handleDeletePromoCode = () => {
    deletePromoCode();
  };

  const handleExpandPromoCode = () => {
    setIsExpanded(true);
  };

  return (
    <div className={bem()}>
      {!!promoCode && (
        <div className={bem('promo-btn')}>
          <div className={bem('promo-btn-code')}>
            <Icon name="check-circle" className={bem('promo-btn-icon-left')} />
            <Text text={promoCode} className={bem('promo-btn-text')} />
          </div>
          <div className={bem('promo-btn-remove')} onClick={handleDeletePromoCode}>
            <Icon name="close2" className={bem('promo-btn-icon-right')} />
          </div>
        </div>
      )}
      {!promoCode && !isExpanded && (
        <Text text={cfLabels.promoButtonLabel} className={bem('text')} onClick={handleExpandPromoCode} />
      )}
      {!promoCode && isExpanded && (
        <Formik
          initialValues={{
            promoCode: '',
          }}
          onSubmit={handleSubmitPromoCode}
        >
          {({ submitForm, values }) => (
            <div className={bem('promo-input')}>
              <InputField
                name="promoCode"
                label={cfLabels.promoCodeLabelInputFloatingLabel}
                error={promoCodeError}
                className={bem('promo-input-field')}
                required
                displayTrailingIcon
                afterErrorFocus
              />
              <Button
                label="Apply"
                color="blue"
                disabled={!values?.promoCode}
                loading={loadingPromoCode}
                onClick={submitForm}
              />
            </div>
          )}
        </Formik>
      )}
    </div>
  );
});
