import React, { useMemo } from 'react';

import { cn } from 'utils/classNames';

import './TrainFlag.scss';
import { TrainFlagProps } from './types';

const bem = cn('train-flag');

const TrainFlag = ({ text, colors, type }: TrainFlagProps) => {
  const style: any = useMemo(() => {
    const textColorKey = `--${type}-train-flag-text-color`;
    const backgroundColorKey = `--${type}-train-flag-background-color`;

    return {
      [textColorKey]: colors?.text?.inactive,
      [backgroundColorKey]: colors?.background?.inactive,
    };
  }, [colors?.background?.inactive, colors?.text?.inactive, type]);

  return (
    <span className={bem({ type })} style={style}>
      {text}
    </span>
  );
};

export default TrainFlag;
