import _ from 'lodash';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import AlertWithButton from '@atoms/AlertWithButton/AlertWithButton';
import { Column } from '@atoms/Grid';
import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

export const bem = cn('rti-step');
export const ben = cn('train-selection-body');

const ModifyTripHeaderRTI = props => {
  const { showModifyTripAlert, setShowModifyTripAlert, modifyTripType } = props;
  const { bookingStore, cfStore } = useStores();

  const { provisionalBooking } = bookingStore.rti;
  const { rti } = cfStore;

  const isMaxTablet = useMediaPredicate(mediaQueryList.maxTablet);

  const outboundRoute = provisionalBooking?.tripDetails?.trip?.outboundRoute;
  const inboundRoute = provisionalBooking?.tripDetails?.trip?.inboundRoute;
  const totalToBePaid = provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid;
  const pricePrefix = totalToBePaid && totalToBePaid < 0 ? '-' : '+';

  const isOutboundCancelled = provisionalBooking?.tripDetails?.trip?.outboundRoute?.isCancelled;

  if (!modifyTripType && modifyTripType !== 'trip') return null;

  return isMaxTablet ? (
    <div>
      {showModifyTripAlert && !_.isUndefined(totalToBePaid) && (
        <AlertWithButton
          description={rti.modifyTripAlertText.replace(
            '{price}',
            `${pricePrefix}$${Math.abs(totalToBePaid).toFixed(2)}`
          )}
          icon="clear"
          onClick={() => setShowModifyTripAlert(false)}
          className={bem('update-trip-alert')}
        />
      )}
      <div className={bem('travel-info-mobile')}>
        <div className={ben('stations')}>
          {isOutboundCancelled ? (
            <h2 className={ben('origin')}>{inboundRoute?.origin?.abbreviation}</h2>
          ) : (
            <h2 className={ben('origin')}>{outboundRoute.origin.abbreviation}</h2>
          )}
          <div className={ben('to-icon')}>
            <Icon name="long-arrow-right" />
          </div>
          {isOutboundCancelled ? (
            <h2 className={ben('destination')}>{inboundRoute?.destination?.abbreviation}</h2>
          ) : (
            <h2 className={ben('destination')}>{outboundRoute.destination.abbreviation}</h2>
          )}
        </div>
      </div>
    </div>
  ) : (
    <>
      {showModifyTripAlert && !_.isUndefined(totalToBePaid) && (
        <Column cols={12} mq={{ desktop: { cols: 10, offset: 1 } }} customClassName={bem('update-trip-alert')}>
          <AlertWithButton
            description={rti?.modifyTripAlertText.replace(
              '{price}',
              `${pricePrefix}$${Math.abs(totalToBePaid).toFixed(2)}`
            )}
            icon="clear"
            onClick={() => setShowModifyTripAlert(false)}
          />
        </Column>
      )}
      <Column cols={12} mq={{ desktop: { cols: 10, offset: 1 } }}>
        <div className={bem('travel-info')}>
          <div className={ben('top-info')}>
            <h1 className={ben('select-route')}>{rti?.modifyTripText}</h1>
          </div>
          <div className={ben('stations')}>
            {isOutboundCancelled ? (
              <h2 className={ben('origin')}>{inboundRoute?.origin?.name}</h2>
            ) : (
              <h2 className={ben('origin')}>{outboundRoute?.origin?.name}</h2>
            )}
            <div className={ben('to-icon')}>
              <Icon name="long-arrow-right" />
            </div>
            {isOutboundCancelled ? (
              <h2 className={ben('destination')}>{inboundRoute?.destination?.name}</h2>
            ) : (
              <h2 className={ben('destination')}>{outboundRoute?.destination?.name}</h2>
            )}
          </div>
        </div>
      </Column>
    </>
  );
};

export default ModifyTripHeaderRTI;
