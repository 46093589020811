import React from 'react';
import { TripConfig } from 'stores/AccountStore/sections';

import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import { Icon } from '@atoms/Icon';
import FooterDateAndBookingNumber from '@molecules/FooterDateAndBookingNumber/FooterDateAndBookingNumber';

import { cn } from 'utils/classNames';

import './PastTripItem.scss';

const bem = cn('account-trip-item-past');

export const PastTripItem = (props: TripConfig) => {
  const { referenceNumber, outboundRoute, inboundRoute } = props;
  const isRoundTrip = inboundRoute?.origin?.name;
  const { navigateToTripDetailsPage, setShouldFetchTripDetails, resetTripData } = useTripDetailsConfig();

  const onSeeDetails = () => {
    setShouldFetchTripDetails(true);
    resetTripData();
    navigateToTripDetailsPage({ referenceNumber }, `#${referenceNumber}`);
  };

  return (
    <div
      className={bem()}
      onClick={onSeeDetails}
      role="button"
      aria-label={`past-trip-item-${referenceNumber}`}
      tabIndex={0}
    >
      <div className={bem('content')}>
        <div className={bem('direction')}>
          <span className={bem('from')}>{outboundRoute.origin.name}</span>
          <Icon
            name={isRoundTrip ? 'arrow-double' : 'arrow-from-to'}
            size="middle"
            className={bem('direction-icon', { type: isRoundTrip ? 'arrow-double' : 'arrow-from-to' })}
          />
          <span className={bem('to')}>{outboundRoute.destination.name}</span>
        </div>
        <FooterDateAndBookingNumber
          referenceNumber={referenceNumber}
          outboundRoute={outboundRoute}
          inboundRoute={inboundRoute}
          variant="past-trip"
        />
      </div>
      <span className={bem('arrow-wrapper')}>
        <Icon name="arrow-right" size="large" className={bem('action-icon')} />
      </span>
    </div>
  );
};
