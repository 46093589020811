import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useStores } from 'stores';
import { SelectedTicket } from 'stores/BookingStore/sections/Tickets/types';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';
import { TIME_FORMAT } from 'utils/constants';

import './DepartureTripSummary.scss';

const bem = cn('departure-trip-summary');

export const DepartureTripSummary = observer(() => {
  const { bookingStore, cfStore } = useStores();
  const { tickets } = bookingStore;
  const { outboundTicket, modifyTicket, modifyTripProps } = tickets;
  const { trainSelection, generic, rti } = cfStore;
  const { tripSummary } = trainSelection;
  const { classTypes } = rti;

  if (!outboundTicket) return null;

  const {
    departureTime,
    arrivalTime,
    displayedPrice,
    originTimeZone,
    destinationTimeZone,
    productId,
  }: SelectedTicket = outboundTicket;

  const classTagLabel = useMemo(
    () => classTypes.find(item => item.classTypeName?.toLowerCase() === productId?.toLowerCase())?.classTypeLabel,
    [classTypes, productId]
  );

  const priceConfig = useMemo(() => {
    if (modifyTripProps) {
      const priceSuffix = (displayedPrice || 0) < 0 ? '-' : '+';

      return `${priceSuffix}$${Math.abs(displayedPrice || 0).toFixed(2)}`;
    }

    return `${(displayedPrice || 0).toFixed(2)}`;
  }, [displayedPrice, modifyTripProps]);

  return (
    <div className={bem()}>
      <div className={bem('departure')}>
        <Icon name="check-circle" />
        <div className={bem('label')}> {tripSummary.departure}</div>
      </div>

      <div className={bem('details')}>
        <div className={bem('departureDate')}>{moment(departureTime).tz(originTimeZone).format('ddd, D MMM')}</div>
        <div className={bem('departureHour')}>{moment(departureTime).tz(originTimeZone).format(TIME_FORMAT)}</div>
        <div className={bem('duration-border')} />
        <div className={bem('arrivalHour')}>{moment(arrivalTime).tz(destinationTimeZone).format(TIME_FORMAT)}</div>
        <div className={bem({ class: productId?.toLowerCase() })}>{classTagLabel}</div>
        <div className={bem('price')}>{priceConfig}</div>
        <div className={bem('passenger')}> {generic.perPassengerLong}</div>
      </div>

      <button className={bem('modify')} onClick={modifyTicket}>
        {tripSummary.modify}
      </button>
    </div>
  );
});
