import moment from 'moment';

import { camelToSnakeCase } from 'utils/stringUtils';

export const mapParams = params => {
  const { trip } = params;
  let newParams = {};
  Object.entries(params).forEach(([key, val]: [string, string]) => {
    if (val) {
      const value = key.includes('Date') ? moment(val).format('YYYY-MM-DD') : val;
      newParams = {
        ...newParams,
        [camelToSnakeCase(key)]: value,
      };
    }
  });

  if (trip === 0 || trip === 1)
    newParams = {
      ...newParams,
      trip,
    };

  return newParams;
};
