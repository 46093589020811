import React, { useMemo } from 'react';
import { useStores } from 'stores';
import { CostDetailItemProps } from 'stores/BookingStore/sections/RTI/types.costSummary';

import { Icon } from '@atoms/Icon';
import { usePromoCodeV2 } from '@molecules/PromoCode/usePromoCodeV2';

import { cn } from 'utils/classNames';

import './CostSummary.scss';

const bem = cn('cost-summary-detail-item');

export const CostDetailItem = (props: CostDetailItemProps) => {
  const { bookingStore } = useStores();
  const { modifyTripType } = bookingStore.rti;

  const {
    quantity,
    price = 0,
    productName,
    isOther,
    isCancelation,
    isPromoCode,
    endpoint,
    requestOptions,
    onSuccess,
    productCode,
    isBlRewards,
  } = props;

  const otherPriceLabel = useMemo(() => {
    if (price === 0) return `$${price.toFixed(2)}`;

    return `-$${Math.abs(price).toFixed(2)}`;
  }, [price]);

  const { handleTrailingIconClick } = usePromoCodeV2(endpoint, requestOptions, onSuccess);

  const onTrailingIconClick = () => {
    handleTrailingIconClick(productCode);
  };

  return (
    <div className={bem({ isOther })}>
      <div>
        {isPromoCode ? (
          <div>
            {!isCancelation && modifyTripType === null && (
              <button className={bem('trailingIcon')} onClick={onTrailingIconClick} aria-hidden="true">
                <Icon name="close" ariaHidden />
              </button>
            )}
            <span
              className={`${bem('type')} ${isCancelation || modifyTripType !== null ? bem('no-padding-left') : ''}`}
            >
              {productName}
            </span>
          </div>
        ) : (
          <>
            {!isBlRewards && <span>{quantity}x</span>}
            <span className={bem(!isBlRewards ? 'type' : '')}>{productName}</span>
          </>
        )}
      </div>
      {isOther ? <div> {otherPriceLabel}</div> : <span>${price.toFixed(2)}</span>}
    </div>
  );
};
