import { makeAutoObservable } from 'mobx';
import { RootStore } from 'stores';

export default class HeaderStore {
  private readonly rootStore: RootStore;

  isMenuVisible: Boolean = false;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.rootStore = rootStore;
  }

  authenticateWithZendesk(token: string) {
    window.zE?.('messenger', 'loginUser', callback => {
      callback(token);
    });
  }

  setMenuVisibility(isMenuVisible: boolean) {
    this.isMenuVisible = isMenuVisible;
  }
}
