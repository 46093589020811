import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { Card } from '../Card';
import './TripDetails.scss';
import { TripDetailsItems } from './TripDetailsItems';
import { TripDetailsProps } from './types';
import { editTripDetails } from './utils';

const bem = cn('rti-trip-details');

export const TripDetails = observer((props: TripDetailsProps) => {
  const { mode } = props;
  const navigate = useNavigate();

  const { cfStore, bookingStore, ticketsFormStore } = useStores();
  const { generic } = cfStore;

  const { provisionalBooking, resetGiftCards, setTpNotApplicableDueToGiftCardPayment } = bookingStore.rti;

  const handleEdit = () => {
    //go back
    editTripDetails(
      navigate,
      provisionalBooking,
      ticketsFormStore.formValues,
      generic.editLabel,
      resetGiftCards,
      setTpNotApplicableDueToGiftCardPayment
    );
  };

  return (
    <Card title={generic.tripDetailsLabel} mode={mode} editBtnLabel={generic.editLabel} onEdit={handleEdit}>
      <div className={bem()}>
        <TripDetailsItems />
      </div>
    </Card>
  );
});
