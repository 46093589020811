import React, { useEffect, useRef } from 'react';

import { cn } from 'utils/classNames';

import './ContentTabs.scss';
import { HeaderTabProps } from './types';

const bem = cn('content-tabs');

const HeaderTab = (props: HeaderTabProps) => {
  const { item, index, handleClickTabHeader, focus, shouldFocus, touched } = props;

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (shouldFocus && ref.current && touched) ref.current?.focus();
  }, [shouldFocus, touched]);

  return (
    <button
      ref={ref}
      id={item.tabId}
      aria-controls={item.contentId}
      role="tab"
      tabIndex={index === focus.index ? 0 : -1}
      aria-selected={index === focus.index}
      className={bem('header_item', { active: index === focus.index, disabled: item.disabled })}
      disabled={item.disabled}
      onClick={handleClickTabHeader(item.contentId, index)}
    >
      {item.text}
    </button>
  );
};

export default HeaderTab;
