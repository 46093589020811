import React, { useEffect, useRef } from 'react';

import { cn } from 'utils/classNames';

const bem = cn('dropdown');

const DropdownItem = (props: any) => {
  const { item, focus, onItemClick, onKeyDown, icon, role, ariaExpanded, ariaHaspopup, ariaControls, ariaLabel, id } =
    props;
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (focus && ref.current) ref.current?.focus();
  }, [focus]);

  return (
    <div
      ref={ref}
      className={bem('item')}
      onClick={onItemClick(item)}
      onKeyDown={onKeyDown}
      tabIndex={focus ? 0 : -1}
      role={role}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHaspopup}
      aria-controls={ariaControls}
      aria-label={ariaLabel}
      id={id}
    >
      {item.text}
      {icon}
    </div>
  );
};

export default DropdownItem;
