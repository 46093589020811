import React from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import { TripProps } from '@account/routes/MyTrips/types';
import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import FooterDateAndBookingNumber from '@molecules/FooterDateAndBookingNumber/FooterDateAndBookingNumber';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './TripItem.scss';
import { RouteItem } from './components/RouteItem';
import { TicketBreak } from './components/TicketBreak';

const bem = cn('account-trip-item');

export const TripItem = (props: TripProps) => {
  const { referenceNumber, outboundRoute, inboundRoute } = props;
  const { outboundRouteStatus, inboundRouteStatus, isCancelled } = props;
  const { seeTicketsLabel, tripDetailsLabel } = props;
  const { navigateToTripDetailsPage, setShouldFetchTripDetails, resetTripData } = useTripDetailsConfig();

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);
  const isRoundTrip = inboundRoute?.origin?.name;

  const onSeeTickets = () => {
    alert('');
  };
  const onSeeDetails = () => {
    setShouldFetchTripDetails(true);
    resetTripData();
    navigateToTripDetailsPage({ referenceNumber }, tripDetailsLabel);
  };

  return (
    <div className={bem()}>
      <div className={bem('content')}>
        <div className={bem('direction')}>
          <span className={bem('from')}>
            {isMobile ? outboundRoute.origin.abbreviation : outboundRoute.origin.name}
          </span>
          <Icon
            name={isRoundTrip ? 'arrow-double' : 'arrow-from-to'}
            size="middle"
            className={bem('direction-icon', { type: isRoundTrip ? 'arrow-double' : 'arrow-from-to' })}
          />
          <span className={bem('to')}>
            {isMobile ? outboundRoute.destination.abbreviation : outboundRoute.destination.name}
          </span>
        </div>
        <div className={bem('routes')}>
          {outboundRoute?.origin.id && (
            <RouteItem
              key={outboundRoute.origin.id}
              {...outboundRoute}
              status={outboundRouteStatus}
              isCancelled={isCancelled}
              isOutboundReaccommodated={outboundRoute.isReaccommodated}
              isInboundReaccommodated={inboundRoute?.isReaccommodated}
              referenceNumber={referenceNumber}
              isOutbound
            />
          )}
          {inboundRoute?.origin.id && (
            <RouteItem
              key={inboundRoute.origin.id}
              {...inboundRoute}
              status={inboundRouteStatus || ''}
              isCancelled={isCancelled}
              isOutboundReaccommodated={outboundRoute.isReaccommodated}
              isInboundReaccommodated={inboundRoute?.isReaccommodated}
              referenceNumber={referenceNumber}
            />
          )}
        </div>
      </div>
      <TicketBreak />
      <div className={bem('footer')}>
        <FooterDateAndBookingNumber
          referenceNumber={referenceNumber}
          outboundRoute={outboundRoute}
          inboundRoute={inboundRoute}
          variant="upcoming-trip"
        />
        <div className={bem('actions')}>
          {seeTicketsLabel && (
            <Button
              label={seeTicketsLabel}
              className={bem('action')}
              size="medium"
              color="yellow"
              variant="primary"
              onClick={onSeeTickets}
            />
          )}
          <Button
            label={tripDetailsLabel}
            className={bem('action')}
            size="medium"
            color="yellow"
            variant="secondary"
            onClick={onSeeDetails}
            role="link"
          />
        </div>
      </div>
    </div>
  );
};
