import React, { useMemo } from 'react';
import QRCode from 'react-qr-code';

import Image from '@atoms/Image/Image';
import ImageButton from '@atoms/ImageButton/ImageButton';
import { Text } from '@atoms/Text';
import { AppBannerProps } from '@molecules/AppBanner/types';

import { cn } from 'utils/classNames';

import './AppBanner.scss';

const bem = cn('app-banner');

export const AppBanner = (props: AppBannerProps) => {
  const { id, title, description, img, isHydrated = true, qr } = props;
  const { firstCta, secondCta, colors } = props;

  const style: any = useMemo(
    () => ({
      '--app-banner-title-color': colors?.text,
      '--app-banner-background-color': colors?.background,
      '--app-banner-qr-background-color': colors?.qrBackground,
      '--app-banner-qr-text-color': colors?.qrTextColor,
      '--app-banner-qr-border-color': colors?.qrBorder,
    }),
    [colors]
  );

  return (
    <div id={id} className={bem()} style={style}>
      <div className={bem('content')}>
        <h3 className={bem('title')}>{title}</h3>
        <div className={bem('actions')}>
          <ImageButton {...firstCta} />
          <ImageButton {...secondCta} />
        </div>
      </div>
      <div className={bem('media')}>
        <Text as="div" text={description} className={bem('description')} />

        {qr ? (
          <div className={bem('qr')}>
            <div>
              <div>{qr.title}</div>
              <div>{qr.description}</div>
            </div>
            <QRCode value={qr.url} size={128} />
          </div>
        ) : (
          <div className={bem('img-wrapper')}>
            <Image {...img} lazyLoaded={isHydrated} />
          </div>
        )}
      </div>
    </div>
  );
};
