import { useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { MenuItemProps } from '@atoms/MenuItem/types';

import { HookProps, SelectProps } from './types';

export const useSelectHandlers = (props: SelectProps): HookProps => {
  const { optionsData, onChange, disabled, setValue, customOnChange, value } = props;
  const [displayList, setListVisibility] = useState(false);
  const [accessibilityFocus, setAccessibilityFocus] = useState(false);

  const [focus, setFocus] = useState({
    index: 0,
    preventScroll: false,
  });

  const headerRef = useRef<HTMLInputElement>(null);

  const handleHeaderClick = e => {
    e.preventDefault();
    headerRef?.current?.focus();
    if (!disabled) {
      setListVisibility(!displayList);
      if (!displayList) setFocus({ index: -1, preventScroll: false });
    }
  };

  const handleItemClick = (e, item: MenuItemProps, itemIndex: number) => {
    e.preventDefault();
    if (item?.disabled) return;

    setFocus({ index: itemIndex, preventScroll: false });

    if (setValue) setValue(item?.value);
    if (onChange) onChange(item?.value);
    if (customOnChange) customOnChange(item?.value);

    setListVisibility(false);
    headerRef?.current?.focus();
  };

  const handleClickOutside = e => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.find(el => el === headerRef.current)) setListVisibility(false);
    setAccessibilityFocus(false);
  };

  const handleChangeNativeSelect = e => {
    // setValue is used to detect if the select is used with formik or not
    if (onChange && setValue) onChange(e);
    else if (onChange) onChange(e.target.value);

    headerRef?.current?.focus();
  };

  const handleKeyDown = e => {
    if (!isMobileOnly)
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (!displayList) setListVisibility(true);
          else if (focus.index === -1 || focus.index === null) {
            const currentIndex = optionsData.findIndex(option => option.value === value);
            setFocus({ index: currentIndex >= 0 ? currentIndex : 0, preventScroll: false });
          } else if (focus.index < optionsData.length - 1) setFocus({ index: focus.index + 1, preventScroll: false });

          break;
        case 'ArrowUp':
          e.preventDefault();
          if (displayList)
            if (focus.index > 0) setFocus({ index: focus.index - 1, preventScroll: false });
            else if (focus.index === 0) setFocus({ index: -1, preventScroll: false });

          break;
        case 'Escape':
          setListVisibility(false);
          setAccessibilityFocus(false);
          setFocus({ index: -1, preventScroll: false });
          break;
        case 'Enter':
        case ' ':
          e.preventDefault();
          if (!displayList) setListVisibility(true);
          else if (focus.index >= 0) handleItemClick(e, optionsData[focus.index], focus.index);
          else {
            setListVisibility(false);
            setAccessibilityFocus(false);
            setFocus({ index: -1, preventScroll: false });
          }

          break;
        default:
          break;
      }
  };

  return {
    focus,
    setFocus,
    optionsData,
    headerRef,
    handleHeaderClick,
    handleItemClick,
    handleClickOutside,
    handleChangeNativeSelect,
    handleKeyDown,
    displayList,
    accessibilityFocus,
    setAccessibilityFocus,
  };
};
