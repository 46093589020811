import React from 'react';

import { Button } from '@atoms/Button';
import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';

import './NewsletterFormV2SuccessMessage.scss';
import { NewsletterSuccessMessageProps } from './types';

const bem = cn('newsletter-form-v2-success-message');

const NewsletterFormV2SuccessMessage = (props: NewsletterSuccessMessageProps) => {
  const { formSubmissionSuccessTitle, formSubmissionSuccessDescription, submitAnotherRequestButton, onResetForm } =
    props;

  const handleButtonClick = () => {
    if (submitAnotherRequestButton?.link && typeof submitAnotherRequestButton.link.url === 'string')
      window.location.href = submitAnotherRequestButton.link.url;
    else onResetForm();
  };

  return (
    <div className={bem()}>
      <div className={bem('form-submission-message')} role="alert">
        <div className={cx(bem('item'), bem('title'))}>
          <Text text={formSubmissionSuccessTitle} className={bem('title')} />
        </div>
        <div className={cx(bem('item'), bem('subtitle'))}>
          <Text className={bem('subtitle')} text={formSubmissionSuccessDescription} />
        </div>
        <div className={cx(bem('item'), bem('button'))}>
          <Button size="small" {...submitAnotherRequestButton} onClick={handleButtonClick} />
        </div>
      </div>
    </div>
  );
};

export default NewsletterFormV2SuccessMessage;
