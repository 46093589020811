import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { ContentHeader } from '@account/components/ContentHeader';
import { EmptyView } from '@account/components/EmptyView';
import { MyAccount } from '@account/components/MyAccount';
import PageLoadingPlaceholders from '@account/components/PageLoadingPlaceholder/PageLoadingPlaceholders';
import { useCMSContent } from '@account/hooks/useCMSContent';
import '@adyen/adyen-web/dist/adyen.css';
import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { isLoyaltyEnabled } from 'utils/metas';

import AddPaymentToken from './AddPaymentToken';
import EditablePayment from './EditablePaymentToken';
import './PaymentTokens.scss';

const bem = cn('account-tokens');

const PaymentTokens = observer(() => {
  useCMSContent('paymentsCF');
  const { accountStore, cfStore } = useStores();

  const [modalAddOpened, setModalAddOpened] = useState(false);

  const { paymentTokensStore } = accountStore;
  const { account } = cfStore;
  const { paymentsCF } = account;
  const { paymentTokens, isLoading } = paymentTokensStore;

  const isTablet = useMediaPredicate(mediaQueryList.maxTablet);

  const pageTitle = paymentsCF.customPageTitle === '' ? paymentsCF.pageTitle : paymentsCF.customPageTitle;

  const isAddNewCardButtonDisabled = paymentTokens.length >= 5;

  const loyaltyEnabled = isLoyaltyEnabled();

  const handleToggleAdd = () => {
    setModalAddOpened(!modalAddOpened);
  };

  if (isLoading) return <PageLoadingPlaceholders />;

  if (!paymentTokens.length)
    return (
      <>
        {loyaltyEnabled && isTablet && (
          <div className={bem('user-account')}>
            <MyAccount />
          </div>
        )}

        <AddPaymentToken isOpen={modalAddOpened} setIsOpen={handleToggleAdd} />
        <EmptyView
          icon={paymentsCF.emptyStateIcon}
          pageTitle={pageTitle}
          title={paymentsCF.emptyStateHeader}
          subtitle={paymentsCF.emptyStateBodyLabel}
          actions={
            <Button
              color="yellow"
              width={isTablet ? 'full' : 'normal'}
              size="medium"
              label={paymentsCF.addNewCardLabel}
              onClick={handleToggleAdd}
            />
          }
        />
      </>
    );

  return (
    <>
      {loyaltyEnabled && isTablet && (
        <div className={bem('user-account')}>
          <MyAccount />
        </div>
      )}

      <ContentHeader pageTitle={pageTitle} />
      <div className={bem('list')}>
        {paymentTokens.map((token, index) => (
          <div key={`token-${index}`} className={bem('list-row')}>
            <EditablePayment token={token} />
          </div>
        ))}
      </div>
      <AddPaymentToken isOpen={modalAddOpened} setIsOpen={handleToggleAdd} />
      <div className={bem('add-to-list')}>
        <Button
          color="yellow"
          width={isTablet ? 'full' : 'normal'}
          size="medium"
          label={paymentsCF.addNewCardLabel}
          onClick={handleToggleAdd}
          disabled={isAddNewCardButtonDisabled}
        />
      </div>
    </>
  );
});

export default PaymentTokens;
