import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { useStores } from 'stores';

import { Card } from '@account/components/Card';
import CreateNewItem from '@account/components/CreateNewItem/CreateNewItem';
import AddPaymentToken from '@account/routes/PaymentTokens/AddPaymentToken';
import PaymentToken from '@account/routes/PaymentTokens/PaymentToken';

import { cn } from 'utils/classNames';

import './PaymentTokensCard.scss';

const bem = cn('payment-tokens-card');

export const PaymentTokensCard = observer(() => {
  const { accountStore, cfStore } = useStores();
  const [modalAddOpened, setModalAddOpened] = useState(false);

  const { account } = cfStore;
  const { paymentsCF } = account;
  const { paymentTokensStore } = accountStore;
  const { paymentTokens, isLoading } = paymentTokensStore;

  const modalOnCloseRef = useRef<HTMLButtonElement>(null);

  const handleToggleAdd = () => {
    setModalAddOpened(!modalAddOpened);
  };

  return (
    <Card
      title={paymentsCF.dashboardTitle || paymentsCF.pageTitle}
      count={paymentTokens.length}
      isLoading={isLoading}
      routeToNavigate={paymentsCF.link}
      placeholderVariant="small"
    >
      <AddPaymentToken isOpen={modalAddOpened} setIsOpen={handleToggleAdd} />
      {paymentTokens.length ? (
        <div className={bem()}>
          <div className={bem('item')}>
            <PaymentToken token={paymentTokens[0]} />
          </div>
        </div>
      ) : (
        <CreateNewItem label={paymentsCF.addNewCardLabel} onClick={handleToggleAdd} modalOnCloseRef={modalOnCloseRef} />
      )}
    </Card>
  );
});
