import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import moment from 'moment-timezone';

import { ValProps } from '@atoms/Toggle/types';
import { DatesRestrictionsProps } from '@molecules/TicketsForm/components/DateSelection/components/DateRangePicker/types';
import { SavedDataProps } from '@molecules/TicketsForm/components/Modal/types';
import { StationProps } from '@molecules/TicketsForm/components/OriginAndDestination/types';
import { LegacyStation, TicketsFormValues } from '@molecules/TicketsForm/types';

import { oneDayCachedApi } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { isDirectBffApiCall } from 'utils/helpers';
import { isKiosk } from 'utils/isKiosk';

const DEFAULT_TIME_ZONE = 'America/New_York';

export interface TicketsFormStoreModel {
  trip: number;
  isPanelVisible: boolean;
  currentStep: number;
  editModeData: SavedDataProps | null;
  stationsData: StationProps[];
  datesRestrictionsData: DatesRestrictionsProps | null;
  formValues: TicketsFormValues;
}

const initialFormValue = {
  from: '',
  to: '',
  adults: 1,
  kids: 0,
  infants: 0,
  wcAdults: 0,
  wcChildren: 0,
  promoCode: '',
  promoCodeDetails: undefined,
  startDate: '',
  endDate: '',
  contractAccessCode: '',
};

export default class TicketsFormStore implements TicketsFormStoreModel {
  trip = -1;
  isPanelVisible = false;
  incompleteSearch = false;
  currentStep = 0;
  editModeData = null;
  stationsData: any = [];
  datesRestrictionsData = null;
  formValues = initialFormValue;
  initialTouchFields = {
    from: false,
    to: false,
    adults: false,
    kids: false,
    infants: false,
    wcAdults: false,
    wcChildren: false,
    promoCode: false,
    startDate: false,
    endDate: false,
  };

  private getKioskMinDate(stations, value) {
    const stationTimezone = stations?.find(station => station.id === value.from)?.timeZone || DEFAULT_TIME_ZONE;

    return moment().tz(stationTimezone);
  }

  async setFormData() {
    if (!this.formValues.startDate) {
      const stationsValues = this.stationsData.length ? this.stationsData : await this.fetchStationsData();
      const minDate = isKiosk() ? this.getKioskMinDate(stationsValues, this.formValues) : this.formValues.startDate;

      this.setFormValues({
        ...this.formValues,
        startDate: minDate,
      });
    }
  }

  private saveToLocalStorage(formValues: TicketsFormValues) {
    const dataToSave = {
      trip: this.trip,
      formValues,
    };
    window.localStorage.setItem('TicketsFormStore', JSON.stringify(dataToSave));
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.formValues = {
      from: '',
      to: '',
      adults: 1,
      kids: 0,
      infants: 0,
      wcAdults: 0,
      wcChildren: 0,
      promoCode: '',
      promoCodeDetails: undefined,
      startDate: '',
      endDate: '',
      contractAccessCode: '',
    };

    if (isKiosk())
      makePersistable(this, {
        name: 'TicketsFormStore',
        properties: [
          'formValues',
          {
            key: 'formValues',
            serialize: value => value,
            deserialize: (value): any => {
              if (this.stationsData.length) {
                const minDate = this.getKioskMinDate(this.stationsData, value);

                return {
                  ...initialFormValue,
                  startDate: minDate,
                  from: value.from ? value.from : '',
                };
              }

              this.fetchStationsData().then(stations => {
                const minDate = this.getKioskMinDate(stations, value);
                this.setFormValues({
                  ...initialFormValue,
                  startDate: minDate,
                  from: value.from ? value.from : '',
                });
              });

              return {
                ...initialFormValue,
                startDate: moment().tz(DEFAULT_TIME_ZONE),
                from: value.from ? value.from : '',
              };
            },
          },
        ],
        storage: window.localStorage,
      });

    this.saveToLocalStorage(this.formValues);
    this.setFormData();
  }

  changeTripValue(selectedTripData: ValProps) {
    this.trip = selectedTripData.selectedIndex;
  }

  setTripValue(trip: number) {
    this.trip = trip;
  }

  hidePanel() {
    this.isPanelVisible = false;
  }

  showPanel() {
    this.currentStep = 0;
    this.isPanelVisible = true;
  }

  setCurrentStep(val) {
    this.currentStep = val;
  }

  setEditModeData(val) {
    this.editModeData = val;
  }

  setFormValues(formValues) {
    this.formValues = formValues;
  }

  setIncompleteSearch(incompleteSearch) {
    this.incompleteSearch = incompleteSearch;
  }

  setStationsData(data) {
    this.stationsData = data;
  }

  setRestrictionsData(data) {
    this.datesRestrictionsData = data;
  }

  updateInitialTouchFields(touched: boolean) {
    this.initialTouchFields = {
      from: touched,
      to: touched,
      adults: touched,
      kids: touched,
      infants: touched,
      wcAdults: touched,
      wcChildren: touched,
      promoCode: touched,
      startDate: touched,
      endDate: touched,
    };
  }

  async fetchStationsData(stations?: LegacyStation[]) {
    if (stations) this.stationsData = stations;
    else
      try {
        let response;

        if (isDirectBffApiCall)
          response = await oneDayCachedApi.get(requestUrls.getBffUrl(requestUrls.bookingSearch.bffStationsSelection), {
            headers: {
              Authorization: 'Bearer anonymous',
            },
            id: 'train-stations',
          });
        else
          response = await oneDayCachedApi.get(`${requestUrls.getRestUrl(requestUrls.bookingSearch.stations)}.xjson`, {
            id: 'train-stations',
          });

        const responseData = response.data;

        this.setStationsData(isDirectBffApiCall ? responseData : responseData.stations);

        return isDirectBffApiCall ? responseData : responseData.stations;
      } catch (e) {
        console.error(e);

        return null;
      }

    return stations;
  }

  setDatesRestrictionsData() {
    const currentTime = new Date();
    const latestDate = new Date(currentTime.setFullYear(currentTime.getFullYear() + 1));
    latestDate.setHours(4, 0, 0, 0);
    this.setRestrictionsData({ latestDate: latestDate.toISOString() });
  }
}
