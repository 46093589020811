import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';

import './NoResults.scss';
import { NoResultsIcon } from './NoResultsIcon';
import { NoResultsProps } from './types';

const bem = cn('no-results');

export const NoResults = observer((props: NoResultsProps) => {
  const { i18n, button } = props;

  const { title, description, buttonLabel } = i18n;

  const { ticketsFormStore } = useStores();
  const { showPanel } = ticketsFormStore;

  return (
    <div className={bem()}>
      <NoResultsIcon className={bem('icon')} />
      <div className={bem('title')}>{title}</div>
      <div className={bem('description')}>{description}</div>
      <div className={bem('button')}>
        <Button label={buttonLabel} color={button?.color || 'charcoal'} onClick={showPanel} />
      </div>
    </div>
  );
});
