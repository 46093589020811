import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './PrintErrorModal.scss';

const bem = cn('print-error-modal');
export const PrintErrorModal = observer(() => {
  const { kioskStore } = useStores();
  const { printErrorModal, hidePrintErrorModal } = kioskStore;
  const { title, description, isVisible, cancelLabel } = printErrorModal;

  if (!isVisible) return null;

  return (
    <Modal variant="mini">
      <div className={bem()}>
        <div className={bem('icon')}>
          <Icon name="warning" />
        </div>
        <div className={bem('title')}>{title}</div>
        <div className={bem('description')}>{description}</div>

        <Button variant="secondary" color="charcoal" label={cancelLabel} onClick={hidePrintErrorModal} />
      </div>
    </Modal>
  );
});
