import React from 'react';
import { useStores } from 'stores';

import { cn, cx } from 'utils/classNames';

import ExpiredSessionModal from './ExpiredSessionModal';
import { TimerProps } from './types';

const bem = cn('rti-timer');

export const Timer = (props: TimerProps) => {
  const { minutesRemaining = 0, secondsRemaining = 0, formattedTime = '' } = props;
  const { cfStore } = useStores();
  const { timer } = cfStore.rti;
  const { sessionEndsInLabel } = timer;

  const buttonAction = () => (window.location.href = '/');

  return (
    <div className={bem('wrapper')}>
      <div className={cx(bem({ 'session-expired': minutesRemaining < 5 }))}>
        {sessionEndsInLabel} <span className={bem('countdown')}>{formattedTime}</span>
      </div>
      {minutesRemaining <= 0 && secondsRemaining <= 0 && <ExpiredSessionModal buttonAction={buttonAction} />}
    </div>
  );
};
