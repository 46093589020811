import React, { useMemo } from 'react';

import LinkButton from '@atoms/LinkButton/LinkButton';
import { LinkButtonListProps } from '@molecules/LinkButtonList/types';

import { cn } from 'utils/classNames';

import './LinkButtonList.scss';

const bem = cn('link-button-list');

const LinkButtonList = ({ items = [], maxItems = 8, title, variant = 'vertical', colors }: LinkButtonListProps) => {
  const vars: any = useMemo(
    () =>
      colors
        ? {
            '--text-color': colors.text?.inactive,
            '--text-hover-color': colors.text?.hover,
            '--border-focus-color': colors.border?.focus,
          }
        : {},
    [colors]
  );

  return (
    <ul className={bem({ variant })} style={vars}>
      {title && <h3 className={bem('title')}>{title}</h3>}
      {items.map((item, index) => {
        if (index >= maxItems) return null;

        const key = `${item?.label}-${index}`;

        return (
          <li key={key} className={bem('item')}>
            <LinkButton {...item} />
          </li>
        );
      })}
    </ul>
  );
};

export default LinkButtonList;
