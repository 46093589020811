import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Coach } from '@atoms/Coach';
import { Seat } from '@atoms/Seat';
import { PassengerCarouselProps } from '@booking/routes/RTI/SeatSelection/components/SeatSelectionModal/PassengersCarousel/types';
import { facingDirections } from '@booking/routes/RTI/SeatSelection/constants';

import { cn } from 'utils/classNames';

import './PassengersCarousel.scss';

const bem = cn('rti-seat-passengers-carousel-item');

export const PassengerCarouselItem = observer((props: PassengerCarouselProps) => {
  const { isAccountFlow } = props;

  const { bookingStore, cfStore } = useStores();
  const seatsCF = isAccountFlow ? cfStore.account.tripDetails.seats : cfStore.rti.seats;

  const { seats } = bookingStore.rti;

  const { activePassenger, travelDirection } = seats;

  const userSeat = useMemo(
    () =>
      travelDirection === 'outbound'
        ? { coach: activePassenger?.outboundSeat?.coach, seat: activePassenger?.outboundSeat }
        : { coach: activePassenger?.inboundSeat?.coach, seat: activePassenger?.inboundSeat },
    [activePassenger?.inboundSeat, activePassenger?.outboundSeat, travelDirection]
  );

  const seatDirection = useMemo(
    () =>
      userSeat.seat?.facingDirection?.toLocaleLowerCase() === facingDirections.forwards
        ? seatsCF.forwardFacingSeatLabel
        : seatsCF.backwardFacingSeatLabel,
    [seatsCF.backwardFacingSeatLabel, seatsCF.forwardFacingSeatLabel, userSeat.seat?.facingDirection]
  );

  return (
    <div className={bem()}>
      <div className={bem('passenger-name')}>
        {activePassenger?.passenger.firstName} {activePassenger?.passenger.lastName}
      </div>
      <div className={bem('seat-info')}>
        <div className={bem('location')}>
          <Coach
            coachNumber={userSeat.coach?.coachNumber || ''}
            classType={userSeat.coach?.classType || ''}
            text={seatsCF.coachTextLabel}
          />
          <Seat number={userSeat.seat?.number || ''} facingDirection={userSeat.seat?.facingDirection || ''} />
        </div>
        <div className={bem('description')}>{seatDirection}</div>
      </div>
    </div>
  );
});
