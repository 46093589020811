import { useFormikContext } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useStores } from 'stores';

import { PromoCodeDetailsResponse, PromoCodeFieldProps } from '@molecules/PromoCode/types';
import { validatePromoCodeResponse } from '@molecules/PromoCode/utils';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';

export const usePromoCode = (props: PromoCodeFieldProps) => {
  const { i18n, name = 'promoCode' } = props;

  const {
    ticketsFormStore: { setFormValues, formValues: formValuesTicketStore },
  } = useStores();

  const [showInput, setShowInput] = useState(!!formValuesTicketStore.promoCode);
  const [isLoading, setIsLoading] = useState(false);
  const [codeApplied, setCodeApplied] = useState(!!formValuesTicketStore.promoCode);
  const [codeError, setCodeError] = useState('');
  const [lastValidatedDates, setLastValidatedDates] = useState({
    startDate: '',
    endDate: '',
  });
  const inputRef: any = useRef(null);

  const { values, setFieldError, errors } = useFormikContext<any>();

  const updateFormValues = useCallback(
    (promoCode: string, promoCodeDetails?: PromoCodeDetailsResponse) => {
      const newFormValues = {
        ...formValuesTicketStore,
        promoCode,
        promoCodeDetails,
      };

      setFormValues(newFormValues);
    },
    [formValuesTicketStore, setFormValues]
  );

  //Enforce error on Formik and set field as touched to display error
  useEffect(() => {
    const formikError = errors[name] || '';

    if (!errors || formikError === codeError) return;

    setFieldError(name, codeError);
  }, [codeError, errors, setFieldError, name]);

  //Re-validate the promo code every time an input changes in the booking search widget
  useEffect(() => {
    if (!codeApplied || !values || !formValuesTicketStore.promoCodeDetails) {
      if (values?.startDate !== lastValidatedDates.startDate || values?.endDate !== lastValidatedDates.endDate) {
        setCodeError('');
        setLastValidatedDates({
          startDate: values?.startDate,
          endDate: values?.endDate,
        });
      }

      return;
    }

    if (values?.startDate === lastValidatedDates.startDate && values?.endDate === lastValidatedDates.endDate) return;

    const validationResult = validatePromoCodeResponse(
      formValuesTicketStore.promoCodeDetails,
      i18n,
      values?.startDate,
      values?.endDate
    );

    if (!validationResult.valid) {
      updateFormValues('', formValuesTicketStore.promoCodeDetails);
      setCodeApplied(false);
      setCodeError(validationResult.message);
    } else setCodeError('');

    setLastValidatedDates({
      startDate: values?.startDate,
      endDate: values?.endDate,
    });
  }, [values, i18n, formValuesTicketStore.promoCodeDetails, updateFormValues, name, codeApplied, lastValidatedDates]);

  const handleAddCodeClick = () => {
    setShowInput(!showInput);
  };

  const handleApplyCode = async (inputValue, formValues, name, i18n, deprecatedSearch) => {
    if (deprecatedSearch) {
      updateFormValues(inputValue);
      setCodeApplied(true);

      return;
    }

    setIsLoading(true);
    setCodeError('');

    try {
      const promoCodeDetails = await api.get<PromoCodeDetailsResponse>(
        `${requestUrls.getRestUrl(requestUrls.bookingSearch.promoCodeDetails)}.xjson`,
        {
          params: {
            promoCode: inputValue,
          },
        }
      );

      const validationResult = validatePromoCodeResponse(
        promoCodeDetails.data,
        i18n,
        formValues?.startDate,
        formValues?.endDate
      );

      if (validationResult.valid) {
        updateFormValues(inputValue, promoCodeDetails.data);
        setCodeApplied(true);
      } else {
        updateFormValues('', undefined);
        setCodeApplied(false);
        setCodeError(validationResult.message);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setCodeError(i18n.invalidPromoCodeErrorMessage);
      updateFormValues('', undefined);

      return;
    }

    setIsLoading(false);
  };

  const handleRemoveCode = () => {
    updateFormValues('', undefined);
    setCodeApplied(false);
  };

  const handleCapitalizePromoCode = (value, fieldName, setFieldValue) => {
    if (!setFieldValue) return;

    if (!value) setFieldValue(fieldName, '');
    else setFieldValue(fieldName, value.toUpperCase());
  };

  useEffect(() => {
    if (showInput && inputRef) inputRef?.current?.focus();
  }, [showInput]);

  return {
    isLoading,
    codeApplied,
    showInput,
    handleCapitalizePromoCode,
    handleAddCodeClick,
    handleApplyCode,
    handleRemoveCode,
    inputRef,
  };
};
