import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import LinkButton from '@atoms/LinkButton/LinkButton';

import { cn } from 'utils/classNames';
import { TIME_FORMAT } from 'utils/constants';

import './TripDetailsItem.scss';
import { TripDetailsItemProps } from './types';

const bem = cn('rti-trip-details-item');

export const TripDetailsItem = observer((props: TripDetailsItemProps) => {
  const { tripItem, onEditClick, isOutbound } = props;

  const { origin, destination, productId, departureDateTime, arrivalDateTime } = tripItem;

  const { cfStore } = useStores();
  const { bookingStore } = useStores();

  const { rti, generic } = cfStore;

  const { classTypes } = rti;
  const { provisionalBooking } = bookingStore.rti;

  const { passengerSeatingDetails } = provisionalBooking.tripDetails;

  const classTypeTitle = useMemo(() => {
    const passenger = passengerSeatingDetails?.[0];
    const seatingProduct = isOutbound ? passenger?.outboundSeatProduct : passenger?.inboundSeatProduct;

    const matchingClassType = classTypes.find(
      item => item.classTypeName?.toLowerCase() === seatingProduct?.code?.toLowerCase()
    );

    return matchingClassType?.classTypeLabel || seatingProduct?.code || '';
  }, [classTypes, passengerSeatingDetails, isOutbound]);

  return (
    <div className={bem()}>
      <div className={bem('info')}>
        <div className={bem('stations')}>
          <span className={bem('from')}>{origin.name}</span>
          <Icon name="arrow-from-to" />
          <span className={bem('to')}>{destination.name}</span>
        </div>
        <div className={bem('details')}>
          <div className={bem(productId.toLowerCase())}>{classTypeTitle}</div>
          <div className={bem('dates')}>
            <div className={bem('departureDate')}>
              {moment(departureDateTime).tz(origin.timeZone).format('ddd, MMM D')}
            </div>
            <div className={bem('departureHour')}>
              {moment(departureDateTime).tz(origin.timeZone).format(TIME_FORMAT)} -{' '}
              {moment(arrivalDateTime).tz(destination.timeZone).format(TIME_FORMAT)}
            </div>
          </div>
        </div>
      </div>

      <div className={bem('edit')}>
        <LinkButton label={generic.editLabel} onClick={onEditClick} hideAriaLabel />
      </div>
    </div>
  );
});
