import { color_brand_blue, color_white } from 'utils/colors';

export const tooltipColors = {
  content: {
    text: {
      inactive: color_white,
    },
    background: {
      inactive: color_brand_blue,
    },
  },
  info: {},
};

export const dateConfig = {
  dateFormat: 'MM/DD/YYYY',
};

export const passengerTypes = {
  adult: 'adult',
  child: 'child',
  infant: 'infant',
};

export const passengersInfoMocks = {
  userProfile: {
    profile: {
      firstName: 'Adrian',
      lastName: 'Tudor',
      type: 'adult',
      email: 'test@adrian.com',
      phoneNumber: {
        countryCode: '+1',
        number: '12345678',
      },
      dateOfBirth: '11/22/2000',
    },
    numberOfRides: 0,
  },
  passengers: [
    {
      passenger: {
        firstName: 'Mike',
        lastName: 'Trex',
        type: 'adult',
        email: 'mike@trex.com',
        phoneNumber: {
          countryCode: '+1',
          number: '111111111',
        },
        id: '1111-1111-1111-1111',
        dateOfBirth: '12/23/2000',
      },
      editMode: false,
    },
    {
      passenger: {
        firstName: 'Alex',
        lastName: 'Kid',
        type: 'child',
        email: 'alex@kid.com',
        phoneNumber: {
          countryCode: '+1',
          number: '3333333333',
        },
        dateOfBirth: '11/22/2010',
      },
      editMode: false,
    },
  ],
  provisionaBookingResponse: {
    tripDetails: {
      trip: {
        referenceNumber: 'T2PR4H',
        outboundRoute: {
          id: 'segment_1',
          service: {
            id: '0:RDA|BL5507|BL|2023-04-22|2023-04-22T06:36|2023-04-22T07:16',
            name: 'BL5507',
            type: 'BL',
          },
          origin: {
            id: 'WPT',
            name: 'West Palm Beach',
            abbreviation: 'WPB',
            city: 'West Palm Beach',
            timeZone: 'America/New_York',
            nameLong: 'West Palm Beach',
          },
          destination: {
            id: 'FBT',
            name: 'Fort Lauderdale',
            abbreviation: 'FTL',
            city: 'Fort Lauderdale',
            timeZone: 'America/New_York',
            nameLong: 'Fort Lauderdale',
          },
          departureDateTime: '2023-04-22T10:36:00.000Z',
          arrivalDateTime: '2023-04-22T11:16:00.000Z',
          classType: 'smart',
        },
        outboundRouteStatus: 'upcoming',
        inboundRoute: {
          id: 'segment_2',
          service: {
            id: '0:RDA|BL5101|BL|2023-05-22|2023-05-22T05:06|2023-05-22T05:46',
            name: 'BL5101',
            type: 'BL',
          },
          origin: {
            id: 'FBT',
            name: 'Fort Lauderdale',
            abbreviation: 'FTL',
            city: 'Fort Lauderdale',
            timeZone: 'America/New_York',
            nameLong: 'Fort Lauderdale',
          },
          destination: {
            id: 'WPT',
            name: 'West Palm Beach',
            abbreviation: 'WPB',
            city: 'West Palm Beach',
            timeZone: 'America/New_York',
            nameLong: 'West Palm Beach',
          },
          departureDateTime: '2023-05-22T09:06:00.000Z',
          arrivalDateTime: '2023-05-22T09:46:00.000Z',
          classType: 'smart',
        },
        inboundRouteStatus: 'upcoming',
        isCancelled: false,
      },
      status: 'upcoming',
      passengerSeatingDetails: [
        {
          passenger: {
            firstName: 'Test',
            lastName: 'Tester',
            type: 'adult',
          },
          outboundSeat: {
            number: '7C',
            facingDirection: 'forwards',
            inventoryType: 'SM',
            coach: {
              coachNumber: '4',
              classType: 'smart',
            },
          },
          outboundSeatProduct: {
            name: 'Smart',
            code: 'SMART_STANDARD',
            familyCode: 'SS',
            type: 'ST',
            tariffCode: 'SV1',
            routeId: 'segment_1',
            inventoryCode: 'SM',
            passengerId: 'passenger_1',
            itemReference: 82728674,
          },
          inboundSeat: {
            number: '13C',
            facingDirection: 'backwards',
            inventoryType: 'SM',
            coach: {
              coachNumber: '4',
              classType: 'smart',
            },
          },
          inboundSeatProduct: {
            name: 'Smart',
            code: 'SMART_STANDARD',
            familyCode: 'SS',
            type: 'ST',
            tariffCode: 'SV1',
            routeId: 'segment_2',
            inventoryCode: 'SM',
            passengerId: 'passenger_1',
            itemReference: 82728676,
          },
          isPrimaryPassenger: true,
          passengerId: 'passenger_1',
        },
        {
          passenger: {
            firstName: 'Tester',
            lastName: 'Test',
            type: 'child',
          },
          outboundSeat: {
            number: '7D',
            facingDirection: 'forwards',
            inventoryType: 'SM',
            coach: {
              coachNumber: '4',
              classType: 'smart',
            },
          },
          outboundSeatProduct: {
            name: 'Smart',
            code: 'SMART_STANDARD',
            familyCode: 'SS',
            type: 'ST',
            tariffCode: 'SV1',
            routeId: 'segment_1',
            inventoryCode: 'SM',
            passengerId: 'passenger_2',
            itemReference: 82728675,
          },
          inboundSeat: {
            number: '13D',
            facingDirection: 'backwards',
            inventoryType: 'SM',
            coach: {
              coachNumber: '4',
              classType: 'smart',
            },
          },
          inboundSeatProduct: {
            name: 'Smart',
            code: 'SMART_STANDARD',
            familyCode: 'SS',
            type: 'ST',
            tariffCode: 'SV1',
            routeId: 'segment_2',
            inventoryCode: 'SM',
            passengerId: 'passenger_2',
            itemReference: 82728677,
          },
          isPrimaryPassenger: false,
          passengerId: 'passenger_2',
        },
      ],
      contact: {
        email: '',
        phoneNumber: {
          countryCode: '',
          number: '',
        },
      },
      products: [
        {
          name: 'Smart',
          code: 'SMART_STANDARD',
          familyCode: 'SS',
          type: 'ST',
          tariffCode: 'SV1',
          routeId: 'segment_1',
          inventoryCode: 'SM',
          passengerId: 'passenger_1',
          itemReference: 82728674,
        },
        {
          name: 'Smart',
          code: 'SMART_STANDARD',
          familyCode: 'SS',
          type: 'ST',
          tariffCode: 'SV1',
          routeId: 'segment_1',
          inventoryCode: 'SM',
          passengerId: 'passenger_2',
          itemReference: 82728675,
        },
        {
          name: 'Smart',
          code: 'SMART_STANDARD',
          familyCode: 'SS',
          type: 'ST',
          tariffCode: 'SV1',
          routeId: 'segment_2',
          inventoryCode: 'SM',
          passengerId: 'passenger_1',
          itemReference: 82728676,
        },
        {
          name: 'Smart',
          code: 'SMART_STANDARD',
          familyCode: 'SS',
          type: 'ST',
          tariffCode: 'SV1',
          routeId: 'segment_2',
          inventoryCode: 'SM',
          passengerId: 'passenger_2',
          itemReference: 82728677,
        },
      ],
      costSummary: {
        items: [
          {
            productCode: 'SMART_STANDARD',
            productName: 'Smart',
            quantity: 4,
            totalPrice: 30,
            totalOriginalPrice: 30,
          },
        ],
        bookingTotal: {
          total: 30,
          totalPaid: 0,
          totalToBePaid: 30,
        },
      },
    },
    bookingSession: {
      accessToken:
        'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiczMtYXBpIl0sImV4cCI6MTY4MDg2NjM2MSwianRpIjoiMzNhYzU0ZGItMTMyNy00ZDIxLWIzYjgtY2MyNDg2MDBmZTQ4IiwiaWF0IjoxNjgwODY0NTYxLCJpc3MiOiJodHRwczovL2JyaWdodGxpbmUtYWNjLmNsb3VkLnNxaWxscy5jb20iLCJzMyI6eyJjbG4iOiI5ejIxZDBrNGg3eHFtZXA5Nmk0anZzdDg0aDQ4M2EzOThzNnY5N3IzOGIwNHEwMzQ0aSIsInJscyI6WyJST0xFX1VTRVIiLCJST0xFX1BVQkxJQyJdLCJzbGMiOiJJTlRFUk5FVCIsInNpZCI6ImYwYjZjN2Q5LWJlOTAtNGZkYy1iNjI0LTE1ZjBiOTIzNmVkNyIsInNsdCI6WyJCMkJfVE8iLCJCMkJfQ09SUCIsIktJT1NLIiwiSU5URVJORVQiLCJNT0JJTEVfQVBQIl19fQ.DjwjlOsoSi7hn8t5q6FvK14X99Ia8u1n-9wYlMT2OhJLpypE3Mdy0rp_Uaq8u4nlQreklUuqBzGzBsx-xpWQk78sus_none4ge7-rdsCJXzbUh8vBeo7abihAh5xxhddKdPRN0P2YdZxYRgz1r3vIPjnnsiIC-pBqDIstAsCd-20PtPyqL1mFy2kRI57I0dT6X3CrpLoGfY6g9dl0gLqrr9l3BkyG-Cpv0ykwX46Xq6FeIIy4IPHuRZKELSuaQQqm2NiTT6v97wR4gGViDSy8URY382b3-fjM9v3loZlwawrUhXyHeY13Y2u0Oxx5I56-cURG2OFnLnRY9Dz9qw9WA',
      refreshToken: 'HHpuZTGAfJwrRZrPUbgQsxUVyLQONtSRQOiGYrfSHbUrbheYUQmRuWAcehldvvQFhdwAVZJHzcIpbPNLJCmfBl',
    },
  },
  realErrorResponse: {
    message: 'Error Occured',
  },
};
