import { useMemo } from 'react';

import { countries } from '@molecules/PaymentForm/countryAndStates';

export const useSweepstakesFormLogic = () => {
  const initialFormValues = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      disclaimerCheckbox: true,
    }),
    []
  );

  const stateOptions =
    countries
      .find(country => country.country == 'United States')
      .states?.map(state => ({
        label: state,
        value: state,
      })) || [];

  return {
    initialFormValues,
    stateOptions,
  };
};
