import React from 'react';

import { defaultInputName } from '@atoms/Input/constants';
import { SelectFieldProps } from '@atoms/Select/types';

import { useInputLogic } from 'utils/useInputLogic';

import { Select } from './Select';

const SelectField = (props: SelectFieldProps) => {
  const { active, name, error, errorMessage } = props;
  const nullCheckName = name || defaultInputName;
  const { helpers, field } = useInputLogic({ ...props, name: nullCheckName });
  const { setValue } = helpers;

  const trailingIcon = active ? 'arrow-up' : 'arrow-dowøn';

  return (
    <Select
      {...props}
      {...field}
      setValue={setValue}
      name={nullCheckName}
      trailingIcon={trailingIcon}
      error={error}
      errorMessage={errorMessage}
    />
  );
};

export default SelectField;
