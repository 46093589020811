import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useStores } from 'stores';

import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import Button from '@atoms/Button/Button';
import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './CelebrationBanner.scss';
import { CelebrationBannerEmails } from './CelebrationBannerEmails';
import { CelebrationBannerProps } from './types';
import { usePrintingLogic } from './usePrintingLogic';

const bem = cn('celebration-banner');

export const CelebrationBannerRewards = observer((props: CelebrationBannerProps) => {
  const { bookingLabel, ctaList, bottomTextPasses, isBlRewardsFlow, isKiosk, rewards } = props;
  const { accountStore } = useStores();
  const { profile } = accountStore;

  const { rn, rp } = useTripDetailsConfig();
  const { addPrintButtonProps, bookingLabelFormated } = usePrintingLogic({ bookingLabel });

  const Buttons = ctaList.map(cta => <Button key={cta.label} {...addPrintButtonProps(cta)} role="link" />);

  const [isOptedOutOrNeverOptedInState, setIsOptedOutOrNeverOptedInState] = useState<boolean>(false);

  const isOptedOutOrNeverOptedIn = useCallback(async () => {
    const status = await profile.getLoyaltyUserStatus();

    return status === 'optout' || status === '';
  }, [profile]);

  useEffect(() => {
    const fetchUserStatus = async () => {
      const status = await isOptedOutOrNeverOptedIn();
      setIsOptedOutOrNeverOptedInState(status);
    };

    fetchUserStatus();
  }, [isOptedOutOrNeverOptedIn]);

  if (!isBlRewardsFlow || !rewards) return null;

  return (
    <div className={bem('rewards')}>
      <hr className={bem('rewards-divider')} />
      <div className={bem('rewards-content')}>
        {rewards.bubbleImage?.src && (
          <div className={bem('rewards-bubble-img')}>
            <Image {...rewards.bubbleImage} />
            <div className={bem('rewards-bubble-overlay')}>
              {isOptedOutOrNeverOptedInState && rewards.couldHaveEarnedLabel && (
                <div className={bem('rewards-bubble-text')}>{rewards.couldHaveEarnedLabel}</div>
              )}
              <div className={bem('rewards-bubble-points')}>
                <span className={bem('rewards-bubble-points-point')}>{rp}</span>
                <span className={bem('rewards-bubble-points-text')}>{rewards.pointsLabel || ''}</span>
              </div>
            </div>
          </div>
        )}
        <div className={bem('rewards-column')}>
          {rewards.brandingImage?.src && (
            <div className={bem('rewards-branding-img')}>
              <Image {...rewards.brandingImage} />
            </div>
          )}
          <hr className={bem('rewards-divider')} />
          <div className={bem('rewards-details')}>
            <div className={bem('rewards-rn')}>
              {bookingLabelFormated}
              <b>{rn}</b>
            </div>
            {!bottomTextPasses && (
              <div className={bem('emails')}>
                <CelebrationBannerEmails {...props} />
              </div>
            )}
            {!!bottomTextPasses && <Text text={bottomTextPasses} className={bem('bottom')} />}
          </div>
        </div>
      </div>
      {!isOptedOutOrNeverOptedInState && <hr className={bem('rewards-divider')} />}
      {Buttons.length && (
        <div className={bem('rewards-buttons')}>{!isKiosk && <div className={bem('buttons')}>{Buttons}</div>}</div>
      )}
      {Buttons.length && (rewards.cards.length || rewards.signUpButtonLabel) && !isOptedOutOrNeverOptedInState && (
        <hr className={bem('rewards-divider')} />
      )}
      {isOptedOutOrNeverOptedInState && (
        <>
          <Text text={rewards.potentialEarnedPoints} className={bem('rewards-text')} />
          {!!rewards.cards.length && (
            <div className={bem('rewards-cards')}>
              {rewards.cards.map((card, idx) => (
                <div key={idx} className={bem('rewards-card')}>
                  {card.image?.src && <Image {...card.image} />}
                  <Text text={card.title} className={bem('rewards-card-title')} />
                  <Text text={card.text} className={bem('rewards-card-text')} />
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {rewards.signUpButtonLabel && (
        <Button
          className="hide-on-login"
          label={rewards.signUpButtonLabel}
          color="yellow"
          link={{ url: '/account/login', target: '_self' }}
        />
      )}
    </div>
  );
});
