import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { Suspense, useMemo, useRef, useState } from 'react';

import { Button } from '@atoms/Button';
import CheckboxField from '@atoms/Checkbox/CheckboxField';
import { InputField } from '@atoms/Input';
import { Text } from '@atoms/Text';
import { ExtrasSelectField } from '@booking/routes/RTI/Extras/components/ExtrasModal/ExtrasSelect';
import { passengerTypes } from '@booking/routes/RTI/PassengersInfo/constants';
import { InlineDateInputField } from '@molecules/InlineDateInput';
import { PhoneNumber } from '@molecules/PhoneNumber';

import { cn, cx } from 'utils/classNames';

import { dateConfig } from '../../constants';
import { getValidationSchema } from '../../validator';
import './PassengerForm.scss';
import { PassengerFormProps } from './types';

const bem = cn('passenger-form');
const bemModal = cn('overlay-container');

export const PassengerForm = observer((props: PassengerFormProps) => {
  const { passenger, onToggleEdit, cancelButtonLabel, submitButtonLabel, isLoading, onSubmit, i18n } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [errors, setErrors] = useState({});

  const initialFormValues = useMemo(
    () => ({
      id: passenger?.id,
      firstName: passenger?.firstName,
      requiredFieldLabel: passenger?.requiredFieldLabel,
      lastName: passenger?.lastName,
      type: passenger?.type,
      dateOfBirth: passenger?.dateOfBirth ? moment(passenger?.dateOfBirth).format(dateConfig.dateFormat) : '',
      email: passenger?.email,
      phoneNumber: {
        countryCode: passenger?.phoneNumber?.countryCode || '+1',
        number: passenger?.phoneNumber?.number,
      },
      wheelchairAccommodation: !!passenger?.wheelchairAccommodation,
    }),
    [passenger]
  );

  const handleSubmitForm = values => {
    onSubmit(values);
    onToggleEdit();
  };

  const resetWheelchairIfInfant = setFieldValue => val => {
    if (val === passengerTypes.infant) {
      setFieldValue('wheelchairAccommodation', false);
      setFieldValue('type', passengerTypes.infant);
    }
  };

  const optionsData = useMemo(
    () => [
      {
        label: i18n.adultLabel,
        value: passengerTypes.adult,
      },
      {
        label: i18n.childLabel,
        value: passengerTypes.child,
      },
      {
        label: i18n.infantLabel,
        value: passengerTypes.infant,
      },
    ],
    [i18n]
  );

  const fieldOrder = ['firstName', 'lastName', 'type'];

  const sortInputErrorsByOrder = errors => {
    const sortedInputErrors = {};
    fieldOrder.forEach(field => {
      if (errors[field]) sortedInputErrors[field] = errors[field];
    });

    return sortedInputErrors;
  };

  const inputKeys = Object.keys(sortInputErrorsByOrder(errors));

  const firstFieldFocus = (name: string) => inputKeys[0] === name;

  return (
    <Suspense fallback={<div />}>
      <div ref={ref}>
        <Formik
          initialValues={initialFormValues}
          validationSchema={getValidationSchema(i18n)}
          onSubmit={handleSubmitForm}
          enableReinitialize
        >
          {({ values, setFieldValue, errors }) => {
            setErrors(errors);

            return (
              <Form>
                <Text as="p" text={i18n.requiredFieldLabel} className={bem('required-field-label')} />
                <div className={bem('row')}>
                  <div className={cx(bem('column'), bem('field'))}>
                    <InputField
                      errorVariant="inline"
                      name="firstName"
                      label={i18n.firstNameLabel}
                      afterErrorFocus={firstFieldFocus('firstName')}
                      autocomplete="given-name"
                      showAsterisk
                      displayTrailingIcon
                      required
                      autoFocus
                    />
                  </div>
                  <div className={cx(bem('column'), bem('field'))}>
                    <InputField
                      errorVariant="inline"
                      name="lastName"
                      label={i18n.lastNameLabel}
                      afterErrorFocus={firstFieldFocus('lastName')}
                      autocomplete="family-name"
                      showAsterisk
                      displayTrailingIcon
                      required
                    />
                  </div>
                </div>

                <div className={bem('row')}>
                  <div className={bem('field')}>
                    <ExtrasSelectField
                      errorVariant="inline"
                      name="type"
                      label={i18n.passengerTypeLabel}
                      customOnChange={resetWheelchairIfInfant(setFieldValue)}
                      headerLabel={optionsData.find(option => option.value === values.type)?.label}
                      optionsData={optionsData}
                      afterErrorFocus={firstFieldFocus('type')}
                      useDesktopVariant
                      showAsterisk
                      required
                    />
                  </div>
                </div>
                <div className={bem('row')}>
                  <div className={bem('field')}>
                    <InlineDateInputField
                      dateFormat={dateConfig.dateFormat}
                      label={i18n.dateOfBirthLabel}
                      placeholder={dateConfig.dateFormat}
                      autocomplete="bday"
                      name="dateOfBirth"
                      errorVariant="inline"
                      maxCharactersDisabled
                    />
                  </div>
                </div>

                <div className={bem('row')}>
                  <div className={bem('field')}>
                    <InputField
                      errorVariant="inline"
                      name="email"
                      label={i18n.emailLabel}
                      autocomplete="email"
                      displayTrailingIcon
                    />
                  </div>
                </div>

                <div className={bem('row')}>
                  <div className={bem('field')}>
                    <PhoneNumber
                      name="phoneNumber"
                      label={i18n.phoneNumberLabel}
                      autocomplete="tel"
                      searchCountryCodePlaceholder={i18n.searchCountryCodePlaceholder}
                    />
                  </div>
                </div>

                {values.type !== passengerTypes.infant && (
                  <div className={bem('row')}>
                    <CheckboxField
                      name="wheelchairAccommodation"
                      label={i18n.accessibilityLabel}
                      className={bem('field', { 'no-bottom': true })}
                    />
                  </div>
                )}

                <div className={bemModal('buttons')}>
                  <Button color="charcoal" variant="secondary" label={cancelButtonLabel} onClick={onToggleEdit} />
                  <Button color="charcoal" label={submitButtonLabel} type="submit" loading={isLoading} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Suspense>
  );
});
