import { useMemo } from 'react';

import {
  columnSettings,
  defaultContainedContentSplit,
  defaultContentSplit,
  defaultimageAlignment,
  defaultMobileCols,
} from './constants';
import { TextAndMediaProps } from './types';

export const useColumnsLogic = (props: TextAndMediaProps) => {
  const { variant, imageAlignment } = props;
  const { contentSplit = defaultContentSplit, containedContentSplit = defaultContainedContentSplit } = props;
  const alignment = useMemo(() => imageAlignment || defaultimageAlignment[variant], [imageAlignment, variant]);

  const columns = useMemo(() => {
    let mq0;
    let mq1;

    switch (variant) {
      case 'contained': {
        mq0 = columnSettings.contained[containedContentSplit][0];
        mq1 = columnSettings.contained[containedContentSplit][1];
        break;
      }
      case 'banner': {
        mq0 = columnSettings.banner[alignment][0];
        mq1 = columnSettings.banner[alignment][1];
        break;
      }
      default: {
        mq0 = columnSettings.default[alignment][contentSplit][0];
        mq1 = columnSettings.default[alignment][contentSplit][1];
      }
    }

    return [
      { cols: defaultMobileCols, mq: mq0 },
      { cols: defaultMobileCols, mq: mq1 },
    ];
  }, [variant, alignment, contentSplit, containedContentSplit]);

  return { alignment, columns };
};
