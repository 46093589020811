import moment from 'moment/moment';
import { ProvisionalBookingProps } from 'stores/BookingStore/sections/RTI/types.provisionalBooking';

import { ANALYTICS_DATE_FORMAT } from './adobeDataLayer';

export const getCartItems = (provisionalBooking: ProvisionalBookingProps) => {
  const items: any = [];
  const costSummary = provisionalBooking.tripDetails.costSummary;
  costSummary?.sections.forEach(section => {
    if (section?.items[0] && section?.items[0].totalPrice)
      items.push({
        price: parseFloat(section?.items[0].totalPrice.toFixed(2)),
        productInfo: {
          class: section?.items[0].productName,
          destinationCity: provisionalBooking.tripDetails?.trip?.outboundRoute?.destination?.city,
          destinationState: 'fl',
          dates: {
            departure: moment(provisionalBooking.tripDetails?.trip?.outboundRoute?.serviceDate).format(
              ANALYTICS_DATE_FORMAT
            ),
            return: provisionalBooking.tripDetails?.trip?.inboundRoute
              ? moment(provisionalBooking.tripDetails?.trip?.inboundRoute?.serviceDate).format(ANALYTICS_DATE_FORMAT)
              : null,
          },
          originCity: provisionalBooking.tripDetails?.trip.outboundRoute?.origin?.city,
          originState: 'fl',
          productName: section.name,
        },
        quantity: section?.items[0].quantity,
      });
  });

  return items;
};
