import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { rootStore, useStores } from 'stores';
import { SetupLoyalty } from 'stores/CFStore/types.account';

import ErrorContent from '@account/routes/ErrorPage/ErrorContent';
import { Modal } from '@molecules/Modal';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { clearShowLoyaltyOptInModalFlag, getShowLoyaltyOptInModalFlag } from 'utils/localstorage';

import './LoyaltyOptInModal.scss';
import { LoyaltyOptIn } from './components/LoyaltyOptIn';
import { WelcomeToLoyalty } from './components/WelcomeToLoyalty';

const bem = cn('loyalty-optin-modal');

const LoyaltyOptInModal = observer((props: SetupLoyalty) => {
  const { modalBrandImage, modalLoyaltyBenefitsCards, modalLoyaltyBenefitsBackgroundColor } = props;
  const { modalDisclaimerAndTosText, modalJoinButtonLabel, modalDeclineButtonLabel } = props;
  const { modalTitleLoyaltyText, modalTitle, setupErrors } = props;

  const { accountStore, authStore } = useStores();
  const { userProfile, isLoading, fetchUserProfile, saveLoyaltyProgram } = accountStore.profile;
  const { isAuthenticated } = authStore;

  const [showModal, setShowModal] = useState(false);
  const [optingIn, setOptingIn] = useState(false);
  const [optinError, setOptinError] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (isLoading || !isAuthenticated || !getShowLoyaltyOptInModalFlag()) return;
  
    const checkIfModalIsToBeShown = async () => {  
      let profile = userProfile;
      
      if (!profile) {
        await fetchUserProfile();
        profile = userProfile;
      }
  
      if (
        profile && 
        !profile?.travelAgentCode &&
        (!profile?.loyaltyPrograms ||
          profile?.loyaltyPrograms?.every(program => program.program?.toLowerCase() !== 'brightline'))
      ) 
        setShowModal(true);
      
  
      clearShowLoyaltyOptInModalFlag();
    };
  
    checkIfModalIsToBeShown();
  }, [fetchUserProfile, isAuthenticated, isLoading, userProfile]);
  
  

  const handleJoinButtonClicked = async () => {
    setOptingIn(true);
    setOptinError(false);

    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: 'Account',
        navigationLinkName: modalJoinButtonLabel,
        customLink: 'brightlineRewardsModal',
        externalLink: '',
        linkType: 'linkClickLoyalty',
      },
    });

    try {
      await saveLoyaltyProgram('brightline');
    } catch (e) {
      console.error(e);
      setOptingIn(false);
      setOptinError(true);

      return;
    }

    setOptingIn(false);

    setCurrentStep(2);
  };

  const handleDeclineButtonClicked = () => {
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: 'Account',
        navigationLinkName: modalDeclineButtonLabel,
        customLink: 'brightlineRewardsModal',
        externalLink: '',
        linkType: 'linkClickLoyalty',
      },
    });

    setShowModal(false);
  };

  const renderError = useMemo(() => {
    if (!optinError || !setupErrors) return null;

    return (
      <ErrorContent
        className={bem('error')}
        error={setupErrors.connectionError}
        color="#c00"
        title={setupErrors.title}
      />
    );
  }, [optinError, setupErrors]);

  if (!showModal) return null;

  return (
    <Modal variant="mini" className={bem()}>
      {currentStep === 1 && (
        <LoyaltyOptIn
          modalTitle={modalTitle}
          brandImage={modalBrandImage}
          titleLoyaltyText={modalTitleLoyaltyText}
          loyaltyBenefitsCards={modalLoyaltyBenefitsCards}
          loyaltyBenefitsBackgroundColor={modalLoyaltyBenefitsBackgroundColor}
          disclaimerAndTosText={modalDisclaimerAndTosText}
          joinButtonLabel={modalJoinButtonLabel}
          declineButtonLabel={modalDeclineButtonLabel}
          joinButtonLoading={optingIn}
          renderError={renderError}
          handleJoinButtonClicked={handleJoinButtonClicked}
          handleDeclineButtonClicked={handleDeclineButtonClicked}
        />
      )}
      {currentStep === 2 && <WelcomeToLoyalty {...props} />}
    </Modal>
  );
});

export default LoyaltyOptInModal;
