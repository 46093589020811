import React from 'react';

import { TableRow } from '@atoms/Table/TableRow';
import { TableProps } from '@atoms/Table/types';
import { useTableLogic } from '@atoms/Table/useTableLogic';

import { cn, cx } from 'utils/classNames';

import './Table.scss';

const bem = cn('table');

export const Table = (props: TableProps) => {
  const { tableCN = '', rowCN, cellCN } = props;
  const { isStickyHeader, stickyColIndex, modalVariant } = props;

  const { table, isTableCollapsed, tableStyle } = useTableLogic(props);

  const hasColGroup = !!table.colGroupWidths.length;
  const hasHeader = !!table.headerElements.length;
  const hasBody = !!table.bodyElements.length;

  const isStickyCol = (stickyColIndex || -1) > 0;

  return (
    <>
      <div className={bem('table-header-container', { isStickyHeader, isStickyCol, modalVariant })} style={tableStyle}>
        <table
          className={cx(bem('table'), {
            [tableCN]: !!tableCN,
          })}
        >
          {hasColGroup && (
            <colgroup>
              {table.colGroupWidths.map((colGroupWidth, index) => (
                <col key={`col-${index}`} width={`${colGroupWidth}%`} />
              ))}
            </colgroup>
          )}
          {hasHeader && (
            <thead>
              {table.headerElements.map((headerRow, index) => (
                <TableRow
                  key={`header-row-${index}`}
                  cells={headerRow}
                  rowNumber={index}
                  stickyColIndex={stickyColIndex}
                  rowCN={rowCN}
                  cellCN={cellCN}
                  modalVariant={modalVariant}
                  isHeader
                />
              ))}
            </thead>
          )}
        </table>
      </div>
      <div className={bem('table-body-container', { isStickyCol })} style={tableStyle}>
        <table
          className={cx(bem('table'), {
            [tableCN]: !!tableCN,
          })}
        >
          {hasColGroup && (
            <colgroup>
              {table.colGroupWidths.map((colGroupWidth, index) => (
                <col key={`col-${index}`} width={`${colGroupWidth}%`} />
              ))}
            </colgroup>
          )}
          {hasBody && (
            <tbody>
              {!isTableCollapsed &&
                table.bodyElements.map((bodyRow, index) => (
                  <TableRow
                    key={`body-row-${index}`}
                    cells={bodyRow}
                    isHeader={false}
                    stickyColIndex={stickyColIndex}
                    rowNumber={index}
                    rowCN={rowCN}
                    modalVariant={modalVariant}
                  />
                ))}
              {isTableCollapsed && table.bodyElements.length && (
                <TableRow
                  key="body-row-0}"
                  cells={table.bodyElements[0]}
                  isHeader={false}
                  stickyColIndex={stickyColIndex}
                  rowNumber={0}
                  rowCN={rowCN}
                  modalVariant={modalVariant}
                />
              )}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};
