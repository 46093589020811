import React, { useMemo } from 'react';

import Tooltip from '@atoms/Tooltip/Tooltip';

import { cn } from 'utils/classNames';

import './PromoFlag.scss';
import { PromoFlagProps } from './types';

const bem = cn('promo-flag');

export const PromoFlag = (props: PromoFlagProps) => {
  const { colors, text, tooltipText, type } = props;

  const vars: any = useMemo(
    () => ({
      '--promo-flag-text-color': colors?.flag?.text?.inactive,
      '--promo-flag-background-color': colors?.flag?.background?.inactive,
      '--promo-flag-border-focus-color': colors?.flag?.border?.focus,
    }),
    [colors]
  );
  const renderContent = () => <>{text}</>;

  return (
    <div style={vars} className={bem({ type })}>
      <Tooltip
        text={tooltipText || ''}
        renderTooltipContent={renderContent}
        className={bem()}
        colors={colors?.tooltip}
      />
    </div>
  );
};
