import React, { useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Icon } from '@atoms/Icon';
import { SelectProps } from '@atoms/Select/types';

import { cn, cx } from 'utils/classNames';

import './Select.scss';

const bem = cn('select');

const SelectHeader = (props: SelectProps) => {
  const { name, required, leadingIcon, disabled, error, onBlur, onFocus, value, isPrivacyRequest, useSpan } = props;
  const { displayList, handleKeyDown, children, label, onClick, headerRef } = props;
  const { accessibilityFocus, setAccessibilityFocus, errorMessage, errorVariant } = props;
  const { readOnly, showAsterisk, type = 'button', id, ariaLabel } = props;
  const { labelCN = 'blte-font--variant-body-medium-500', className = '', parking } = props;
  const focusedElement = props.focus;

  const [focus, setFocus] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutsideWrapper = () => {
    if (setAccessibilityFocus) {
      setAccessibilityFocus(false);
      setFocus(false);
    }
  };

  const handleFocus = e => {
    setFocus(true);
    if (setAccessibilityFocus) setAccessibilityFocus(true);

    if (onFocus) onFocus(e);
  };

  const handleBlur = e => {
    setFocus(false);
    if (onBlur) onBlur(e);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutsideWrapper}>
      <button
        ref={wrapperRef}
        id="trigger"
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={displayList}
        aria-controls={`${id}-list`}
        aria-labelledby={`${id}-label`}
        aria-label={ariaLabel}
        aria-activedescendant={displayList ? `${id}-list-option-${focusedElement?.index}` : ''}
        className={cx(
          bem({
            disabled: disabled,
            required: required,
            'has-value': !!value,
            'has-error': !!error,
            'has-focus': focus,
            'read-only': readOnly,
            'accessibility-focus': accessibilityFocus,
            'error-inline': errorVariant === 'inline',
            'privacy-request': !!isPrivacyRequest,
          }),
          { [className]: !!className }
        )}
        style={{ border: 'none' }}
        onKeyDown={handleKeyDown}
        onClick={onClick}
      >
        {label && (
          <label
            className={cx(bem('label', { hasLeadingIcon: !!leadingIcon }), labelCN)}
            htmlFor={id}
            id={`${id}-label`}
          >
            {label} {required || (showAsterisk && <span className={bem('label-required')}>*</span>)}
          </label>
        )}
        {!useSpan && (
          <input
            ref={headerRef}
            type={type}
            name={name}
            id={id}
            disabled={disabled}
            value={value || ''}
            onFocus={handleFocus}
            onBlur={handleBlur}
            aria-describedby={`error-${id}`}
            aria-invalid={Boolean(error)}
            role="combobox"
            aria-haspopup="listbox"
            aria-expanded="false"
            aria-controls={`${id}-list`}
            tabIndex={-1}
            className={cx(
              bem('select', {
                hasLeadingIcon: !!leadingIcon,
                'privacy-request': !!isPrivacyRequest,
              }),
              'blte-font--variant-body-medium-500'
            )}
            readOnly={readOnly}
            required={required}
          />
        )}
        {useSpan && (
          <span
            ref={headerRef}
            data-name={name}
            id={id}
            aria-disabled={disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            aria-describedby={`error-${id}`}
            aria-invalid={Boolean(error)}
            role="combobox"
            aria-haspopup="listbox"
            aria-expanded="false"
            aria-controls={`${id}-list`}
            tabIndex={-1}
            className={cx(
              bem('select', {
                hasLeadingIcon: !!leadingIcon,
                'privacy-request': !!isPrivacyRequest,
              }),
              'blte-font--variant-body-medium-500'
            )}
            aria-readonly={readOnly}
            aria-required={required}
          >
            {value}
          </span>
        )}
        {leadingIcon && (
          <span className={cx(bem('leadingIcon'), { parking: parking === 'parking' })}>
            <Icon name={leadingIcon} />
          </span>
        )}
        <div className={bem('trailingIcon')}>
          <Icon name={displayList ? 'arrow-up' : 'arrow-down'} />
        </div>
        {error && errorMessage && (
          <span className={cx(bem('error'), 'blte-font--variant-tiny-500')} id={`error-${id}`}>
            {errorMessage}
          </span>
        )}
        {children}
      </button>
    </OutsideClickHandler>
  );
};

export default SelectHeader;
