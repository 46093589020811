import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import './ErrorItem.scss';

const bem = cn('seat-map-error');

export const ErrorItem = observer(() => {
  const { bookingStore, cfStore } = useStores();
  const { seats } = bookingStore.rti;
  const {
    rti: { seats: seatsCF },
  } = cfStore;

  const { setError, getSeats, travelDirection } = seats;

  const handleRetryClick = () => {
    setError(null);
    getSeats(travelDirection);
  };

  return (
    <div className={bem()}>
      <Icon name="warning" className={bem('icon')} />
      <div className={bem('messages')}>
        <div className={bem('title')}>{seatsCF.loadingSeatsErrorTitle}</div>
        <div className={bem('subtitle')}>{seatsCF.loadingSeatsErrorDescription}</div>
      </div>
      <Button label={seatsCF.retryCtaLabel} variant="primary" color="charcoal" onClick={handleRetryClick} />
    </div>
  );
});
