import { observer } from 'mobx-react-lite';
import React from 'react';

import { UserAvatar } from '@atoms/UserAvatar/UserAvatar';

import { cn } from 'utils/classNames';

import { SavedPassengerProps } from './types';

const bem = cn('account-passengers');

const Passenger = observer((props: SavedPassengerProps) => {
  const { passenger, size } = props;

  if (!passenger) return null;

  return (
    <div className={bem('user')}>
      <UserAvatar firstName={passenger.firstName} lastName={passenger.lastName} src={passenger.picture} size={size} />
    </div>
  );
});

export default Passenger;
