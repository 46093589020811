import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

export const bem = cn('rti-step');

export const TpDisabledDueToGCBanner = observer(() => {
  const { bookingStore, cfStore } = useStores();
  const { removeGcToAddTravelPassMessage } = cfStore.rti.reviewAndPay;
  const { tpNotApplicableDueToGiftCardPayment, setTpNotApplicableDueToGiftCardPayment } = bookingStore.rti;

  const bannerTopDistance = useMemo(() => {
    const topBanners = document.querySelectorAll<HTMLElement>('.blte-alert-banner');

    let totalBannerHeight = 0;
    topBanners.forEach(banner => {
      totalBannerHeight += banner.offsetHeight;
    });

    return totalBannerHeight + 24;
  }, []);

  if (!tpNotApplicableDueToGiftCardPayment) return null;

  return (
    <div className={bem('tp-disabled-banner')} style={{ top: bannerTopDistance }}>
      <div className={bem('tp-disabled-banner-wrapper')}>
        <Icon name="warning" />
        <Text text={removeGcToAddTravelPassMessage} className={bem('tp-disabled-banner-text')} />
        <div className={bem('tp-disabled-banner-close')} onClick={() => setTpNotApplicableDueToGiftCardPayment(false)}>
          <Icon name="close2" />
        </div>
      </div>
    </div>
  );
});
