import React from 'react';

import { WalletPassItemsProps } from '../WalletDesktop/types';
import { CreditPassDetails } from './CreditPassDetails';
import { ParkingPassDetails } from './ParkingPassDetaills';
import './PassDetails.scss';
import { TrainPassDetails } from './TrainPassDetails';

export const PassDetails = (props: WalletPassItemsProps) => {
  const { item, empty } = props;

  if (item?.type === 'train') return <TrainPassDetails item={item} empty={empty} />;
  if (item?.type === 'parking') return <ParkingPassDetails item={item} />;

  return <CreditPassDetails item={item} />;
};
