import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './CostSummary.scss';
import { CostSummaryItem } from './components/CostSummaryItem';

const bem = cn('passes-cost-summary');

export const CostSummaryPlaceholderMobile = observer(() => (
  <ReactPlaceholder
    ready={false}
    customPlaceholder={
      <div className={bem()}>
        <div className={bem('card')}>
          <RectShape color={color_base_medium_light_grey} style={{ width: 150, height: 26, borderRadius: 8 }} />
          <div className={bem('items')}>
            <CostSummaryItem itemName="" itemPrice={0} isPlaceholder />
            <CostSummaryItem itemName="" itemPrice={0} isPlaceholder />
            <CostSummaryItem itemName="" itemPrice={0} isPlaceholder />
          </div>
          <div className={bem('separator')} />
          <CostSummaryItem itemName="" itemPrice={0} isTotal isPlaceholder />
        </div>
      </div>
    }
    showLoadingAnimation
  >
    <div />
  </ReactPlaceholder>
));
