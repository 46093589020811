import { observer } from 'mobx-react-lite';
import React from 'react';
import { rootStore, useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { color_base_black, color_brand_yellow } from 'utils/colors';
import { base64Encode } from 'utils/helpers';
import { isLoyaltyEnabled } from 'utils/metas';

import './Footer.scss';

const bem = cn('passes-payment-footer');

export const Footer = observer(() => {
  const { cfStore, passesStore, authStore, bookingStore, accountStore } = useStores();
  const { urlParams, checkoutCart, cartNumber, costSummary, selectedCard, loadingCheckoutCart } = passesStore.checkout;
  const { loyaltyRewardsPoints } = bookingStore.rti;
  const { user } = authStore;
  const { profile } = accountStore;
  const cfLabels = cfStore.passes.reviewAndPay;
  const { celebrationBannerLink, celebrationBannerLinkForMobile } = cfStore.passes.reviewAndPay;

  const handlePayButtonClicked = async () => {
    const checkoutResult = await checkoutCart(cartNumber, selectedCard, undefined, undefined);

    if (checkoutResult) {
      const searchParams = {
        reference_number: cartNumber,
        first_name: urlParams.mobileData.mobileFirstName || profile.userProfile?.firstName || '',
        last_name: urlParams.mobileData.mobileLastName || profile.userProfile?.lastName || '',
        email: urlParams.mobileData.mobileEmail || user.email || '',
        mobileCallbackUrl: urlParams.mobileData.mobileCallbackURL,
        loyalty_user_status: (await profile.getLoyaltyUserStatus()) || '',
        reward_points: isLoyaltyEnabled() ? loyaltyRewardsPoints : undefined,
      };
      const base64searchParams = base64Encode(JSON.stringify(searchParams));
      const successBannerLink = new URL(
        urlParams.mobileData.jwt ? celebrationBannerLinkForMobile : celebrationBannerLink
      );
      successBannerLink.searchParams.append('data', base64searchParams);

      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: `${cfLabels.payAndBookButtonLabel} - $${costSummary.bookingTotal.totalToBePaid.toFixed(
            2
          )}`,
          customLink: successBannerLink.toString(),
          externalLink: '',
          linkType: 'linkClick',
        },
      });
      window.location.href = successBannerLink.toString();
    }
  };

  return (
    <div className={bem()}>
      <div className={bem('tos')}>
        <Text text={cfLabels.passesAreNotRefundableLabel} className={bem('tos-text')} />
        <Text text={cfLabels.disclaimerLabel} className={bem('tos-text')} />
      </div>
      <Button
        label={`${cfLabels.payAndBookButtonLabel} - $${costSummary.bookingTotal.totalToBePaid.toFixed(2)}`}
        width="full"
        onClick={handlePayButtonClicked}
        colors={{
          text: color_base_black,
          backgroundOrBorder: color_brand_yellow,
        }}
        loading={loadingCheckoutCart}
      />
    </div>
  );
});
