import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './NoModificationAllowedModal.scss';
import { NoModificationAllowedModalProps } from './types';

const bem = cn('no-modification-allowed-modal');

const NoModificationAllowedModal = observer((props: NoModificationAllowedModalProps) => {
  const { buttonLabel, buttonAction } = props;

  const { accountStore, cfStore } = useStores();
  const { trip, bookingRules } = accountStore.tripDetails;
  const { inBoundRules, outBoundRules } = bookingRules || {};
  const { noModificationAllowedReasons, genericNoModificationsAllowedLabel } = cfStore.account.tripDetails;

  const cantModifyReason = useMemo(() => {
    const isRoundTrip = trip?.outboundRoute && trip.inboundRoute;
    if (isRoundTrip) {
      const isPastOutboundTrip = moment().isSameOrAfter(trip?.outboundRoute.departureDateTime);
      const showMessageForCantModify = !bookingRules?.isModifiable;

      if (isPastOutboundTrip || showMessageForCantModify)
        return (
          noModificationAllowedReasons.find(
            reason => reason.cantModifyReasonKey.toLowerCase() === inBoundRules?.cantModifyReason.toLowerCase()
          )?.cantModifyReasonLabel || genericNoModificationsAllowedLabel
        );
    }

    return (
      noModificationAllowedReasons.find(
        reason => reason.cantModifyReasonKey.toLowerCase() === outBoundRules?.cantModifyReason.toLowerCase()
      )?.cantModifyReasonLabel || genericNoModificationsAllowedLabel
    );
  }, [
    trip,
    noModificationAllowedReasons,
    genericNoModificationsAllowedLabel,
    inBoundRules,
    outBoundRules,
    bookingRules,
  ]);

  return (
    <Modal variant="mini">
      <Text className={bem('description')} text={cantModifyReason} />
      <div className={bem('button')}>
        <Button label={buttonLabel} onClick={buttonAction} />
      </div>
    </Modal>
  );
});

export default NoModificationAllowedModal;
