import moment from 'moment-timezone';
import { useMemo } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { rootStore, useStores } from 'stores';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { base64Decode } from 'utils/helpers';
import { isKiosk } from 'utils/isKiosk';
import { camelToSnakeCaseObject } from 'utils/stringUtils';

export const useTripDetailsConfig = () => {
  const { accountStore } = useStores();
  const { tripDetails } = accountStore;
  const { trip, setShouldFetchTripDetails, resetTripData } = tripDetails;

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { rn, fn, ln, rp } = useMemo(() => {
    const data = searchParams.get('data');
    if (!data)
      return {
        rn: searchParams.get('reference_number'),
        fn: null,
        ln: null,
        rp: 0,
      };
    try {
      const parsedData = JSON.parse(base64Decode(data));

      return {
        rn: parsedData?.reference_number,
        fn: parsedData?.first_name,
        ln: parsedData?.last_name,
        rp: parsedData?.reward_points,
      };
    } catch (e) {
      console.error(e);

      return {
        rn: null,
        fn: null,
        ln: null,
        rp: null,
      };
    }
  }, [searchParams]);

  const isPublicPage = useMemo(() => {
    const isTripDetails = location.pathname.includes('trip-details');

    return isTripDetails && !!rn && !!fn && !!ln;
  }, [fn, ln, location.pathname, rn]);

  const defaultRoute = isKiosk() ? '/trip-details' : '/account/trip-details';

  const getDefaultRoute = (route?: string) => (isKiosk() ? '/trip-details' : route || '/account/trip-details');

  const navigateToTripDetailsPage = (queryParams, labelText) => {
    const state: any = camelToSnakeCaseObject(queryParams);

    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: labelText,
        customLink: `/trip-details?${createSearchParams(state)}`,
        externalLink: '',
        linkType: 'linkClick',
      },
      tripManagement: {
        PNR: rn,
      },
    });
    navigate({ pathname: '/trip-details', search: `?${createSearchParams(state)}` });
  };

  const isPastTrip = useMemo(() => {
    if (trip?.inboundRoute && trip.outboundRoute) {
      const { outboundRoute, inboundRoute } = trip;

      return (
        moment(outboundRoute.departureDateTime).tz(outboundRoute.origin.timeZone) <
          moment().tz(outboundRoute.origin.timeZone) &&
        moment(inboundRoute.departureDateTime).tz(inboundRoute.origin.timeZone) <
          moment().tz(inboundRoute.origin.timeZone)
      );
    } else if (trip?.outboundRoute) {
      const { outboundRoute } = trip;

      return (
        moment(outboundRoute.departureDateTime).tz(outboundRoute.origin.timeZone) <
        moment().tz(outboundRoute.origin.timeZone)
      );
    }

    return false;
  }, [trip]);

  return {
    isPublicPage,
    rn,
    fn,
    ln,
    rp,
    defaultRoute,
    getDefaultRoute,
    navigateToTripDetailsPage,
    isEditable: !isPastTrip && !isKiosk() && !trip?.isCancelled,
    setShouldFetchTripDetails,
    resetTripData,
  };
};
