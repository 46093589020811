import { useFormikContext } from 'formik';
import React from 'react';

import { Button } from '@atoms/Button';
import LinkButton from '@atoms/LinkButton/LinkButton';
import Title from '@atoms/Title/Title';
import Tooltip from '@atoms/Tooltip/Tooltip';
import { tooltipColors } from '@organisms/TrainPasses/TrainPassesCard/constants';
import { TrainPassStepsProps } from '@organisms/TrainPasses/types';

import { cn } from 'utils/classNames';
import { color_base_black } from 'utils/colors';

import './TrainPassesCategories.scss';

const bem = cn('train-passes-categories');

export const TrainPassesCategories = (props: TrainPassStepsProps) => {
  const { i18n, useTrainPassLogic } = props;

  const { categories, setStep, fetchTrainPassOptions, stations } = useTrainPassLogic;

  const { values: formValues, setFieldValue } = useFormikContext();
  const values = formValues as any;

  const handleButtonClicked = async item => {
    if (values.category === item.name) return;

    setFieldValue('category', item.name);
    setFieldValue('option', '');

    setStep(2);

    const originStation = stations.find(station => station.name === values.travelFrom);
    const dstStation = stations.find(station => station.name === values.travelTo);
    const category = categories.find(category => category.name === item.name);
    if (!originStation || !dstStation || !category) return;

    await fetchTrainPassOptions(originStation.id, dstStation.id, category.code);

    setStep(3);
  };

  return (
    <div className={bem('fields')}>
      <div className={bem('subtitle')}>
        <Title size="h3" text={i18n?.experienceSubtitle} color={color_base_black} />
      </div>
      {i18n.fareLinkUrl && (
        <div className={bem('learn-more')}>
          <LinkButton
            link={{ url: i18n.fareLinkUrl, target: i18n.fareLinkTarget }}
            variant="medium"
            label={i18n.learnMoreTooltipText || ''}
            underline
          />
          <div className={bem('info-icon', 'container')}>
            <Tooltip colors={tooltipColors} text={i18n.learnMoreTooltipText || ''} />
          </div>
        </div>
      )}
      {!!categories.length && (
        <div className={bem('row')}>
          {categories.map((item, index) => (
            <Button
              key={index}
              width="full"
              color={values.category === item.name ? 'blue' : 'white'}
              label={item.name}
              onClick={() => handleButtonClicked(item)}
              className={bem('button-item')}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TrainPassesCategories;
