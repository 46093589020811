import { FormikContextType, useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';

import { TicketsFormValues } from '@molecules/TicketsForm/types';

import { PassengerSelectionProps } from './types';

export const usePassengerSelectionLogic = (props: PassengerSelectionProps) => {
  const { i18n } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { values }: FormikContextType<TicketsFormValues> = useFormikContext();

  const hideBody = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    setIsOpen(val => !val);
  };

  const totalPassengersLabel = useMemo(() => {
    if (!values) return '';
    const value = values.adults + values.kids;
    const sgLabel = values.kids > 0 ? i18n.passenger : i18n.adult;
    const plLabel = values.kids > 0 ? i18n.passengers : i18n.adults;

    return `${value} ${!value || value > 1 ? plLabel : sgLabel}`;
  }, [i18n.adult, i18n.adults, i18n.passenger, i18n.passengers, values]);

  useEffect(() => {
    const handleKeyDown = e => e.key === 'Escape' && hideBody();

    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return {
    isOpen,
    handleClick,
    hideBody,
    totalPassengersLabel,
  };
};
