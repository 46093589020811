import { Button } from "@atoms/Button";
import { Text } from "@atoms/Text";
import React, { useMemo } from "react"
import { MerchandisingBannerProps } from "./types";
import './MerchandisingBanner.scss';
import { cn } from "utils/classNames";

const bem = cn('merchandising-banner');

const MerchandisingBanner = (props: MerchandisingBannerProps) => {
    const {bannerText, buttonText, buttonBackgroundColor = '#094273', bannerBackgroundColor, link, onClick} = props;

    const styles: any = useMemo(
        () => ({
          '--merchandising-banner-background-color': bannerBackgroundColor,
        }),
        [bannerBackgroundColor]
      );

    return <div className={bem()} style={styles}>
        <Text text={bannerText} className={bem('description')}/>
        {buttonText && <Button label={buttonText} link={link} onClick={onClick} colors={{backgroundOrBorder: buttonBackgroundColor, text: '#fff'}}/>}
    </div>
}

export default MerchandisingBanner;