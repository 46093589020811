import React from 'react';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
// import { Colors } from '@atoms/Button/types';
import { Image } from '@atoms/Image';
import { Text } from '@atoms/Text';
import Tooltip from '@atoms/Tooltip/Tooltip';
import { TravelPassEligible } from '@booking/routes/TrainSelection/components/RouteItem/components/TravelPassEligible';
import { FareSelectionTableCellContent } from '@organisms/FareSelectionTable/types';

import { cn, cx } from 'utils/classNames';
import { color_base_black, color_base_dark_grey, color_base_medium_light_grey, color_white } from 'utils/colors';

import './FareSelectionTable.scss';

const bem = cn('fare-compare-table');

export const TableCellContentRenderer = (
  content: FareSelectionTableCellContent = {
    cellColor: '#FFFFFF',
    cellTooltip: '',
    cellContent: '',
    columnWidthPercentage: 100,
  }
) => {
  const { cellColor, cellContent, cellTooltip, image, selectButton } = content;
  const { farePrice, isCollapseControlCell, isCollapsed } = content;
  const { toggleTableCollapse, expandLabel, collapseLabel } = content;
  const { passEligibilityData, isBottomLeftElement, isBottomRightElement } = content;
  const { isNotBottomLeftElement, isNotBottomRightElement } = content;

  const { buttonId, /*buttonColor,*/ buttonLabel } = selectButton || {};

  const isItemUnavailable = !!farePrice && !!farePrice.isUnavailableMessage;

  const classNames = [
    isBottomLeftElement ? 'bottomLeftElement' : '',
    isBottomRightElement ? 'bottomRightElement' : '',
    isNotBottomLeftElement ? 'notBottomLeftElement' : '',
    isNotBottomRightElement ? 'notBottomRightElement' : '',
  ];

  const cellColorStyle = {
    backgroundColor: cellColor || '#FFFFFF',
  };

  const tooltipColors = {
    info: {
      text: {
        inactive: color_base_black,
      },
      background: {
        inactive: color_white,
      },
    },
    content: {
      text: {
        inactive: isItemUnavailable ? color_base_medium_light_grey : color_base_dark_grey,
      },
      background: {
        inactive: cellColor || color_white,
      },
      border: {
        focus: color_base_dark_grey,
      },
    },
  };

  return (
    <div style={cellColorStyle} className={cx(bem('cell-content-wrapper'), ...classNames)}>
      {passEligibilityData && (
        <div className={bem('pass-eligibility-wrapper')}>
          <TravelPassEligible isTravelPassEligible={passEligibilityData?.isTravelPassEligible || false}  isTableHeader />
        </div>
      )}
      {image && <Image className={bem('image')} {...image} />}
      {!image && (
        <div className={bem('cell-content')}>
          {farePrice && (
            <div className={bem('fare-content')}>
              {!farePrice.isUnavailableMessage && (
                <div className={bem('fare-price-wrapper')}>
                  <Text className={bem('fare-price')} text={farePrice.price} />
                  {farePrice.originalPrice && (
                    <Text className={bem('fare-price-strikethrough')} text={farePrice.originalPrice} />
                  )}
                  <Text className={bem('fare-label')} text={farePrice.perGuestLabel} />
                </div>
              )}
              {farePrice.isUnavailableMessage && (
                <Text className={bem('fare-price-unavailable')} text={farePrice.isUnavailableMessage} />
              )}
              <div className={bem('regular-content', { isItemUnavailable })}>
                <Text text={cellContent} />
                {cellTooltip && (
                  <div className={bem('cell-tooltip-wrapper')}>
                    <Tooltip
                      text={cellTooltip}
                      className={bem('tooltip', { isItemUnavailable })}
                      colors={tooltipColors}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {!farePrice && cellContent && (
            <div className={bem('regular-content')}>
              <Text text={cellContent} />
              {cellTooltip && (
                <div className={bem('cell-tooltip-wrapper')}>
                  <Tooltip text={cellTooltip} className={bem('tooltip')} colors={tooltipColors} />
                </div>
              )}
            </div>
          )}
          {selectButton && selectButton.buttonLabel && (
            <Button
              className={bem('button')}
              width="full"
              label={buttonLabel || ''}
              id={buttonId || ''}
              disabled={isItemUnavailable}
              color={/*TODO: (buttonColor as Colors) ||*/ 'blue'}
            />
          )}
        </div>
      )}
      {isCollapseControlCell && (
        <div className={bem('collapsable-wrapper')} onClick={toggleTableCollapse}>
          <Text text={isCollapsed ? expandLabel : collapseLabel} className={bem('collapsable-label')} />
          <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} className={bem('collapsable-icon')} />
        </div>
      )}
    </div>
  );
};
