import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { editTripdetailsRtiRedirect } from '../../utils';
import { EditDailyParkingOverlay } from './EditDailyParkingOverlay';
import './Extras.scss';
import { ParkingItemProps } from './types';

const bem = cn('trip-details-extras');

export const ParkingItem = observer((props: ParkingItemProps) => {
  const { expanded, type, labels } = props;
  const { cfStore, bookingStore, accountStore } = useStores();
  const { isEditDailyParkingDialogOpen, setEditDailyParkingDialogOpen } = accountStore.tripDetails;
  const { setNoModificationAllowedModalOpen, bookingRules } = accountStore.tripDetails;
  const { isExtrasModifiable } = bookingRules || {};
  const { tripDetails } = cfStore.account;
  const { data } = bookingStore.parking;
  const { isEditable } = useTripDetailsConfig();

  const driverSelections = useMemo(() => {
    if (!data) return [];

    const { eligibleDrivers } = data;

    return eligibleDrivers.filter(driver => !!driver.currentSelections);
  }, [data]);

  const getDatePeriodString = selections => {
    const startDate = moment.utc(selections.startDate);
    const endDate = moment.utc(selections.endDate).add(selections?.numberOfDays - 1, 'days');
    const monthStartName = startDate.format('MMMM');
    const monthStartDay = startDate.format('DD');

    const monthEndName = endDate.format('MMMM');
    const monthEndDay = endDate.format('DD');

    const daysDifference = endDate.diff(startDate, 'days', false) + 1;

    const nightsLabel = daysDifference > 1 ? tripDetails.nightsLabel : tripDetails.nightLabel;

    if (monthStartName === monthEndName)
      return `${monthStartName} ${monthStartDay}-${monthEndDay} (${daysDifference} ${nightsLabel})`;

    return `${monthStartName} ${monthStartDay} - ${monthEndName} ${monthEndDay} (${daysDifference} ${nightsLabel})`;
  };

  const handleEdit = () => {
    if (!isExtrasModifiable) setNoModificationAllowedModalOpen();
    else editTripdetailsRtiRedirect(accountStore, 'extras');
  };

  const handleEditDailyParking = () => {
    if (!bookingRules?.isExtrasModifiable) setNoModificationAllowedModalOpen();
    else setEditDailyParkingDialogOpen();
  };

  if (!driverSelections.length || !data) return null;

  return (
    <div className={bem('item-card', { collapsed: !expanded, completed: true })}>
      {isEditDailyParkingDialogOpen && <EditDailyParkingOverlay />}
      <div className={bem('item-header')}>
        <div className={bem('item-title-wrapper')}>
          <Icon name={type} />
          <div className={bem('item-info-title')}>{labels?.label}</div>
        </div>
      </div>
      <div className={bem('parking-item-content')}>
        <div className={bem('item-content-parking')}>
          <div className={bem('item-content-parking-title')}>{data.station.nameLong}</div>
          {driverSelections.map((driver, index) => (
            <div key={`${driver.passengerId}-${index}`} className={bem('item-content-parking-driver')}>
              <span>{getDatePeriodString(driver.currentSelections)}</span>
              <span>{driver.passengerName}</span>
              <span>{driver.currentSelections?.licensePlate?.number}</span>
            </div>
          ))}
        </div>
        {isEditable && tripDetails.editLabel && !labels.hideEditLink && (
          <div className={bem('parking-item-edit')}>
            <span onClick={handleEdit}>{tripDetails.editLabel}</span>
            <span onClick={handleEditDailyParking}>{tripDetails?.editDailyParkingLicensePlate}</span>
          </div>
        )}
      </div>
    </div>
  );
});
