import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStores } from 'stores';

import {
  clearLogoutRedirectURL,
  getLoginRedirectURL,
  getLogoutRedirectURL,
  setLoginRedirectURL,
  setLogoutRedirectURL,
} from 'utils/localstorage';
import { getLocale } from 'utils/metas';

export const useAccountStateLogic = props => {
  const { icon, label, link, subitems = [], scope = '' } = props;
  const { authStore } = useStores();
  const { isAuthenticated, auth0Client, user } = authStore;

  const { nickname } = user || {};
  const [loginStatus, setLoginStatus] = useState<any>([false, 'auth0', {}]);
  const { accountStore } = useStores();
  const { profile } = accountStore;
  const { setUserProfile } = profile;

  const resetStoredData = useCallback(() => {
    setUserProfile(null);
  }, [setUserProfile]);

  const handleLogout = useCallback(() => {
    resetStoredData();

    if (!getLogoutRedirectURL()) setLogoutRedirectURL(window.location.href);

    if (loginStatus[1] === 'auth0') {
      const isInAccountPage = window.location.href.includes('/account');
      const returnTo = `${window.location.origin}/${getLocale()}${isInAccountPage ? '' : 'account'}`;

      auth0Client.logout({ logoutParams: { returnTo } });
    } else {
      localStorage.removeItem('bl-user');
      clearLogoutRedirectURL();
      window.location.reload();
    }
  }, [auth0Client, loginStatus, resetStoredData]);

  const mapSubItem = useCallback(
    (el: any) => {
      if (el.scope && el.scope.toLowerCase() === 'logout') el.onClick = handleLogout;

      return el;
    },
    [handleLogout]
  );

  const handleLogin = useCallback(() => {
    resetStoredData();
    if (scope && scope.toLowerCase() === 'login' && !getLoginRedirectURL()) setLoginRedirectURL(window.location.href);
  }, [resetStoredData, scope]);

  const accountMenuItems = useMemo(() => {
    if (loginStatus[0])
      return {
        icon: 'user',
        label: loginStatus[1] === 'localstorage' ? loginStatus[2].first_name : nickname,
        subitems: subitems.map(mapSubItem),
      };

    return { label, link, icon, onClick: handleLogin };
  }, [loginStatus, nickname, subitems, mapSubItem, label, link, icon, handleLogin]);

  useEffect(() => {
    const localStorageUser = localStorage.getItem('bl-user');

    if (localStorageUser) setLoginStatus([true, 'localstorage', JSON.parse(localStorageUser)]);
    else setLoginStatus([isAuthenticated, 'auth0']);
  }, [isAuthenticated]);

  return { accountMenuItems, isAuthenticated: loginStatus[0] };
};
