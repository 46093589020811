import { clone } from 'lodash';
import { useState } from 'react';

import { ErrorCodesBrowserProps } from './types';

export const useErrorCodesLogic = (props: ErrorCodesBrowserProps) => {
  const { genericError, errorCodesList } = props;

  const initialFormValues = {
    code: '',
    header: '',
    body: '',
  };

  const [allErrorCodes] = useState([genericError, ...errorCodesList]);
  const [filteredErrorCodes, setFilteredErrorCodes] = useState(allErrorCodes);
  const [searchTerms, setSearchTerms] = useState(initialFormValues);

  const handleSubmit = values => {
    const errorCodes = clone(allErrorCodes);

    const filterCriteria = {
      code: values.code?.toLowerCase() || '',
      header: values.header?.toLowerCase() || '',
      body: values.body?.toLowerCase() || '',
    };

    setSearchTerms(filterCriteria);

    setFilteredErrorCodes(
      errorCodes.filter(errorCode => {
        if (!filterCriteria.code && !filterCriteria.header && !filterCriteria.body) return true;

        return (
          errorCode.errorCode.toLowerCase().includes(filterCriteria.code) &&
          errorCode.errorHeader.toLowerCase().includes(filterCriteria.header) &&
          errorCode.errorBody.toLowerCase().includes(filterCriteria.body)
        );
      })
    );
  };

  const highlightMatch = (haystack, needle) => {
    if (!haystack || !needle) return haystack;

    const regex = new RegExp(needle, 'gi');

    return haystack.replace(regex, '<b>$&</b>');
  };

  return {
    initialFormValues,
    filteredErrorCodes,
    searchTerms,
    handleSubmit,
    highlightMatch,
  };
};
