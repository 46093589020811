import React, { useCallback, useEffect, useId, useRef } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { Portal } from 'react-portal';

import Title from '@atoms/Title/Title';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './Modal.scss';

const bem = cn('modify-trip-form-modal');

export const Modal = ({ children, title, onClose }) => {
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const titleID = useId();

  const modalRef = useRef<HTMLDivElement>(null);
  const closeRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  const focusModal = useCallback(
    e => {
      const firstFocusableElement = closeRef.current;
      const lastFocusableElements = contentRef?.current?.getElementsByClassName(
        'blte-btn blte-btn--size-medium blte-btn--width-normal blte-btn--variant-primary'
      ) as HTMLCollectionOf<HTMLButtonElement>;
      const lastFocusableElement = lastFocusableElements?.[1]?.disabled
        ? lastFocusableElements?.[0]
        : lastFocusableElements?.[1];

      if (e.target == closeRef.current && e.key == 'Tab' && e.shiftKey) {
        e.preventDefault();
        lastFocusableElement?.focus();
      } else if (e.target == lastFocusableElement && e.key == 'Tab' && !e.shiftKey) {
        e.preventDefault();
        firstFocusableElement?.focus();
      }
    },
    [contentRef]
  );

  useEffect(() => {
    if (closeRef && closeRef.current) closeRef.current.focus();
  }, [closeRef]);

  useEffect(() => {
    modalRef.current?.addEventListener('keydown', focusModal);
    const dialog = modalRef.current;

    return () => {
      dialog?.removeEventListener('keydown', focusModal);
    };
  }, [focusModal, modalRef]);

  return (
    <Portal>
      <div
        ref={modalRef}
        className={bem({ mobile: isMobile })}
        role="dialog"
        aria-modal="true"
        aria-labelledby={titleID}
      >
        <div className={bem('header')}>
          <Title id={titleID} size="h2" text={title} />
          <button ref={closeRef} className={bem('button')} onClick={onClose} aria-label="close">
            Close
          </button>
        </div>
        <div ref={contentRef}>
          {!isMobile && <div className={bem('body')}>{children}</div>}
          {isMobile && children}
        </div>
      </div>
    </Portal>
  );
};
