import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { ErrorCodesConfig } from '@molecules/ErrorCodesBrowser/types';

import { cn } from 'utils/classNames';

import './Error.scss';
import { ErrorIcon } from './ErrorIcon';

const bem = cn('train-selection-error');

export const Error = observer(() => {
  const { bookingStore, cfStore } = useStores();
  const { trainSelectionError, setTrainSelectionError } = bookingStore.tickets;

  const gracefulErrors: ErrorCodesConfig | null = JSON.parse(cfStore.header.gracefulErrors || 'null');

  const errorConfig = useMemo(() => {
    const foundError = trainSelectionError?.code
      ? gracefulErrors?.errorCodesList.find(
          err => err.errorCode.toString().toLowerCase() === trainSelectionError.code.toString().toLowerCase()
        )
      : undefined;

    return {
      code: trainSelectionError?.code && gracefulErrors?.errorCodePattern.replaceAll('$code', trainSelectionError.code),
      header: foundError?.errorHeader || gracefulErrors?.genericError.errorHeader || trainSelectionError?.header,
      body: foundError?.errorBody || gracefulErrors?.genericError.errorBody || trainSelectionError?.body,
      primaryCtaLabel: foundError
        ? foundError?.primaryCtaLabel || ''
        : gracefulErrors?.genericError.primaryCtaLabel || '',
      secondaryCtaLabel: foundError
        ? foundError?.secondaryCtaLabel || ''
        : gracefulErrors?.genericError.secondaryCtaLabel || '',
    };
  }, [trainSelectionError, gracefulErrors]);

  const handlePrimaryCtaClick = () => {
    setTrainSelectionError(undefined);
    // Reload current page to restart booking process
    location.reload();
  };

  const handleSecondaryCtaClick = () => {
    setTrainSelectionError(undefined);
    // Navigate to the homepage
    window.location.href = '/';
  };

  return (
    <div className={bem()}>
      <ErrorIcon className={bem('icon')} />
      <div className={bem('text')}>
        <div className={bem('title')}>{errorConfig.header}</div>
        <div className={bem('description')}>{errorConfig.body}</div>
        {trainSelectionError?.code && <div className={bem('code')}>{errorConfig.code}</div>}
      </div>
      <div className={bem('buttons')}>
        {errorConfig.primaryCtaLabel && (
          <Button label={errorConfig.primaryCtaLabel} color="charcoal" onClick={handlePrimaryCtaClick} />
        )}
        {errorConfig.secondaryCtaLabel && (
          <LinkButton
            className={bem('secondary-button')}
            label={errorConfig.secondaryCtaLabel}
            variant="secondary"
            onClick={handleSecondaryCtaClick}
          />
        )}
      </div>
    </div>
  );
});
