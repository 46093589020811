import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import './Profile.scss';

const bem = cn('account-navigation');

export const Profile = observer(() => {
  const { accountStore } = useStores();

  const { profile } = accountStore;
  const { userProfile, isLoading } = profile;

  return (
    <div className={bem('profile', { placeholders: isLoading })}>
      <div className={bem('avatar')}>
        {userProfile?.firstName[0]} {userProfile?.lastName[0]}
      </div>
      <h1 className={bem('name')}>
        {userProfile?.firstName} {userProfile?.lastName}
      </h1>
    </div>
  );
});
