import { useEffect, useState } from 'react';

export const useFareCompareModalLogic = defaultVisible => {
  const [visible, setVisibility] = useState(defaultVisible);

  const hideModal = () => {
    setVisibility(false);
  };

  const showModal = e => {
    e.stopPropagation();
    setVisibility(true);
  };

  const replaceText = text =>
    text
      .replaceAll('<li>{Including}', `<li class='fare-option-check-circle'><p class='sr-only'>including</p>`)
      .replaceAll('<li>{AdditionalFee}', `<li class='fare-option-money'><p class='sr-only'>for an additional fee</p>`);

  useEffect(() => {
    const buttons = document.querySelectorAll('button[id^="fareCompareButton"]');
    window.addEventListener('showFareCompareModal', showModal);

    buttons.forEach(button => {
      button.addEventListener('click', showModal);
    });

    return () => {
      window.removeEventListener('showFareCompareModal', showModal);

      buttons.forEach(button => {
        button.removeEventListener('click', showModal);
      });
    };
  }, []);

  return {
    hideModal,
    visible,
    replaceText,
  };
};
