import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { AlertBannerProps } from '@molecules/AlertBanner/types';
import { useBanners } from '@molecules/AlertBanner/useBanners';

import { cn } from 'utils/classNames';

import './AlertBanner.scss';

const bem = cn('alert-banner');
const AlertBanner = observer((props: AlertBannerProps) => {
  const { accountStore, authStore } = useStores();
  const { profile } = accountStore;
  const { isAuthenticated } = authStore;
  const { id, alertKey, leadingIcon, text, sticky, primaryButton, contentAlignment, hideOnLogIn, hideOnLogOut } = props;
  const { hideCloseButton = false, secondaryButton, colors, isAuthoring, ariaLabel } = props;
  const { hiddenElement, handleActions, handleCloseBanner, bannerText } = useBanners(
    alertKey,
    isAuthoring,
    text,
    sticky,
    profile,
    hideOnLogIn,
    hideOnLogOut,
    id,
    isAuthenticated
  );

  const styles: any = useMemo(
    () => ({
      '--alert-banner-text-color': colors?.text?.inactive,
      '--alert-banner-background-color': colors?.background?.inactive,
      '--alert-banner-icon-color': colors?.icon?.inactive,
      '--alert-banner-link-text-color': colors?.link?.text?.inactive,
      '--alert-banner-link-border-focus-color': colors?.link?.border?.focus,
      '--alert-banner-close-button-border-focus-color': colors?.closeButton?.border?.focus,
      '--alert-banner-close-button-icon-color': colors?.closeButton?.icon?.inactive,
    }),
    [colors]
  );

  if (hiddenElement) return null;

  return (
    <div
      role="region"
      aria-label={ariaLabel || ''}
      className={bem({ hidden: hiddenElement, sticky: !hiddenElement && sticky })}
      style={styles}
      id={id}
    >
      <div className={bem('container')}>
        <div className={bem('content', { alignment: contentAlignment })}>
          <div className={bem('text-content')}>
            {(leadingIcon?.externalIcon || leadingIcon?.name) && (
              <span className={bem('leading-icon')}>
                <Icon {...leadingIcon} />
              </span>
            )}
            <Text as="span" text={bannerText(text)} className={bem('text')} />
          </div>

          <div className={bem('buttons')}>
            {[primaryButton, secondaryButton].map(button =>
              button ? (
                <div key={`button-${button.label}`} className={bem('alert-button')}>
                  <Button
                    {...button}
                    size="small"
                    onClick={button?.action ? handleActions(button.action) : undefined}
                  />
                </div>
              ) : null
            )}
          </div>
        </div>

        {!hideCloseButton && (
          <button className={bem('close')} onClick={handleCloseBanner}>
            <Icon name="clear" />
          </button>
        )}
      </div>
    </div>
  );
});

export default AlertBanner;
