import React from 'react';

import { Text } from '@atoms/Text';
import Tooltip from '@atoms/Tooltip/Tooltip';
import { TableCellContent } from '@organisms/FareCompareModal/v2/types';

import { cn, cx } from 'utils/classNames';
import { color_base_black, color_base_dark_grey, color_white } from 'utils/colors';

import './FareCompareModalV2.scss';

const bem = cn('fare-compare-modal-v2');

export const TableCellContentRenderer = (
  content: TableCellContent = {
    cellColor: '#FFFFFF',
    cellTooltip: '',
    cellContent: '',
  }
) => {
  const { cellColor, cellContent, cellTooltip, isBottomLeftElement } = content;
  const { isBottomRightElement, isNotBottomLeftElement } = content;
  const { isNotBottomRightElement } = content;

  const classNames = [
    isBottomLeftElement ? 'bottomLeftElement' : '',
    isBottomRightElement ? 'bottomRightElement' : '',
    isNotBottomLeftElement ? 'notBottomLeftElement' : '',
    isNotBottomRightElement ? 'notBottomRightElement' : '',
  ];

  const cellColorStyle = {
    backgroundColor: cellColor || '#FFFFFF',
  };

  const tooltipColors = {
    info: {
      text: {
        inactive: color_base_black,
      },
      background: {
        inactive: color_white,
      },
    },
    content: {
      text: {
        inactive: color_base_dark_grey,
      },
      background: {
        inactive: cellColor || color_white,
      },
      border: {
        focus: color_base_dark_grey,
      },
    },
  };

  return (
    <div style={cellColorStyle} className={cx(bem('cell-content-wrapper'), ...classNames)}>
      {cellContent && <Text text={cellContent} />}
      {cellTooltip && (
        <div className={bem('cell-tooltip-wrapper')}>
          <Tooltip text={cellTooltip} className={bem('tooltip')} colors={tooltipColors} />
        </div>
      )}
    </div>
  );
};
