import { useCallback, useEffect, useState } from 'react';
import { useStores } from 'stores';
import { Pagination } from 'stores/AccountStore/sections';

export const useTransactionsTableLogic = () => {
  const { cfStore, accountStore } = useStores();
  const { rewardsGeneral } = cfStore.account;
  const { transactionHistory } = accountStore;
  const { history, fetchTransactionHistoryData } = transactionHistory;

  // Reserved for future use
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPNRs, setSelectedPNRs] = useState<string[]>([]);
  // Reserved for future use
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedStatus, setSelectedStatus] = useState('');
  const [allTransactions, setAllTransactions] = useState<Record<number, TransactionItemConfig[]>>({});
  const [paginatedTransactions, setPaginatedTransactions] = useState<TransactionItemConfig[]>([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>({
    total: 0,
    itemsPerPage: Number(rewardsGeneral.rewardsActivity.itemsPerPage) || 10,
    pageCount: 0,
    pageNumber: 1,
  });

  useEffect(() => {
    setPagination(val => ({
      total: 0,
      itemsPerPage: Number(rewardsGeneral.rewardsActivity.itemsPerPage || 10),
      pageCount: 0,
      pageNumber: val.pageNumber,
    }));
  }, [rewardsGeneral.rewardsActivity.itemsPerPage]);

  useEffect(() => {
    if (!pagination.itemsPerPage) return;

    const fetchActivityData = async () => {
      if (!Object.entries(allTransactions).length) {
        setActivityLoading(true);
        setPaginationLoading(true);
      } else setActivityLoading(true);

      if (allTransactions[pagination.pageNumber - 1])
        setPaginatedTransactions(allTransactions[pagination.pageNumber - 1]);
      else {
        const offset = (pagination.pageNumber - 1) * pagination.itemsPerPage;
        const limit = pagination.itemsPerPage;
        const id = selectedPNRs.length ? selectedPNRs : undefined;
        const status = selectedStatus ? selectedStatus : undefined;

        try {
          let totalTransactions = 0;
          if (!history) {
            const historyResponse = await fetchTransactionHistoryData(undefined, undefined, id, status, false);

            totalTransactions = historyResponse?.length || 0;
          } else totalTransactions = history.length;

          const paginatedHistory = await fetchTransactionHistoryData(offset, limit, id, status, true);

          setAllTransactions(val => {
            val[pagination.pageNumber - 1] = paginatedHistory || [];

            return val;
          });

          setPaginatedTransactions(paginatedHistory || []);
          setPagination(val => {
            val.pageCount = Math.ceil(totalTransactions / Number(pagination.itemsPerPage || 0));
            val.total = totalTransactions;

            return { ...val };
          });
        } catch (e) {
          console.error(e);
        }
      }

      setActivityLoading(false);
      setPaginationLoading(false);
    };

    fetchActivityData();
  }, [
    allTransactions,
    pagination.itemsPerPage,
    pagination.pageNumber,
    selectedPNRs,
    selectedStatus,
    history,
    transactionHistory,
    fetchTransactionHistoryData,
  ]);

  const handleStartEarningButtonClick = () => {
    window.location.href = rewardsGeneral.rewardsTracker.startEarningButtonLink || '/booking';
  };

  const handlePageNumberChanged = useCallback(pageNumber => {
    if (pageNumber < 1) return;

    setPagination(val => ({
      ...val,
      pageNumber,
    }));
  }, []);

  const handleConfirmationLinkClicked = refNr => {
    localStorage.setItem('confirmationClick', 'true');
    localStorage.setItem('confirmationRefNr', refNr);

    const baseURL = rewardsGeneral.rewardsActivity.tripDetailsPageLink || '/account/trip-details';

    setTimeout(() => {
      window.location.href = `${baseURL}?reference_number=${refNr}`;
    }, 300);
  };

  return {
    paginatedTransactions,
    activityLoading,
    paginationLoading,
    pagination,
    handleStartEarningButtonClick,
    handlePageNumberChanged,
    handleConfirmationLinkClicked,
  };
};
