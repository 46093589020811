import { FormikContextType, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import Checkbox from '@atoms/Checkbox/Checkbox';
import Divider from '@atoms/Divider/Divider';
import { TicketsFormValues } from '@molecules/TicketsForm/types';

import { cn } from 'utils/classNames';

import '../PassengerSelection.scss';
import { passengerUrlTypes } from './constants';
import { PassengerSelectionBodyProps } from './types';
import { usePassengerSelectionBodyLogic } from './usePassengerSelectionBodyLogic';

const bem = cn('passenger-selection');

export const PassengerSelectionBody = (props: PassengerSelectionBodyProps) => {
  const { i18n, onClickSubmitButton, bodyPosition = 'right', isMobile } = props;
  const { showWheelchair = true } = props;
  const hookProps = usePassengerSelectionBodyLogic(props);

  const { checkDisabledFirstButton, checkDisabledSecondButton, increasePassengers, decreasePassengers } = hookProps;
  const { infoMessage, values, checkDisabledWcFirstButton, checkDisabledWcSecondButton } = hookProps;
  const { increaseWeelchairPassengers, decreaseWheelchairPassengers } = hookProps;

  const { values: passengers, setFieldValue }: FormikContextType<TicketsFormValues> = useFormikContext();

  const [wheelchairSeatsActive, setWheelchairSeatsActive] = React.useState(false);
  const { accountStore } = useStores();
  const { userProfile } = accountStore.profile;

  const toggleWheelchairSeatsActive = () => {
    setWheelchairSeatsActive(!wheelchairSeatsActive);
    setFieldValue('wcAdults', 0);
    setFieldValue('wcChildren', 0);
  };

  useEffect(() => {
    if (values.wcAdults || values.wcChildren) setWheelchairSeatsActive(true);
  }, [values.wcAdults, values.wcChildren]);

  useEffect(() => {
    if (userProfile?.wheelchairAccommodation === 'accessible_space') {
      setWheelchairSeatsActive(true);
      setFieldValue('wcAdults', 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.wheelchairAccommodation]);

  return (
    <ul className={bem('body', { position: bodyPosition, 'is-mobile': isMobile })}>
      {showWheelchair && (
        <Checkbox
          labelClassName="blte-font--variant-body-medium-400"
          name="wheelchairSeats"
          label={i18n.wheelchairSeatsLabel}
          isChecked={wheelchairSeatsActive}
          onChange={toggleWheelchairSeatsActive}
        />
      )}
      {[passengerUrlTypes.adults, passengerUrlTypes.kids, passengerUrlTypes.infants].map((type, index) => (
        <li key={`wc${type}`}>
          {(showWheelchair || index > 0) && <Divider />}

          <div key={type} className={bem('item')}>
            <div className={bem('left')}>
              <div className={bem('title')}>
                <h4 className={bem('passenger-selection')}>{i18n[type]}</h4>
              </div>
              <div className={bem('description')}>{i18n[`${type}Desc`]}</div>
            </div>
            <div className={bem('right')}>
              <button
                aria-label={`decrease number of ${i18n[type]}`}
                type="button"
                onClick={decreasePassengers(type)}
                disabled={checkDisabledFirstButton(type)}
              />
              <span>{passengers[type]}</span>
              <button
                aria-label={`increase number of ${i18n[type]}`}
                type="button"
                onClick={increasePassengers(type)}
                disabled={checkDisabledSecondButton(type)}
              />
            </div>
          </div>

          {showWheelchair && (
            <div className={bem('item-wheelchair')}>
              {!!(type !== passengerUrlTypes.infants && wheelchairSeatsActive && values[type]) && (
                <div className={bem('item-wheelchair-wrapper')}>
                  <div className={bem('title')}>{i18n.wheelchairAccommodationsLabel}</div>

                  <div className={bem('right')}>
                    <button
                      aria-label={`decrease number of ${i18n.wheelchairAccommodationsLabel}`}
                      type="button"
                      onClick={decreaseWheelchairPassengers(type)}
                      disabled={checkDisabledWcFirstButton(type)}
                    />
                    <span>{type === passengerUrlTypes.adults ? passengers.wcAdults : passengers.wcChildren}</span>
                    <button
                      aria-label={`increase number of ${i18n.wheelchairAccommodationsLabel}`}
                      type="button"
                      onClick={increaseWeelchairPassengers(type)}
                      disabled={checkDisabledWcSecondButton(type)}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </li>
      ))}
      <div className={bem('footer')}>
        <div className={bem('message')}>{infoMessage}</div>
        {onClickSubmitButton && (
          <Button color="blue" label={i18n.submitButtonLabel} size="small" onClick={onClickSubmitButton} />
        )}
      </div>
    </ul>
  );
};
