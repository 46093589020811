import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';
import { scrollToTop } from 'utils/isKiosk';

import './ErrorPage.scss';

const bem = cn('booking-error-page');

export const ErrorPage = observer(() => {
  const error: any = useRouteError();
  const { cfStore } = useStores();
  const { generic } = cfStore;
  const { serverErrorLabel, serverErrorTitle, serverErrorCTALabel } = generic;

  if (error) console.error(error);

  const goHome = () => {
    window.location.href = window.location.origin;
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className={bem()}>
      <div className={bem('exclamation')}>!</div>
      <div className={bem('title')}>{serverErrorTitle}</div>
      <div className={bem('description')}>{serverErrorLabel}</div>
      <Button className={bem('button')} label={serverErrorCTALabel} onClick={goHome} />
    </div>
  );
});
