import { Formik } from 'formik';
import { useAdyen } from 'hooks/useAdyen';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { rootStore, useStores } from 'stores';

import '@adyen/adyen-web/dist/adyen.css';
import Button from '@atoms/Button/Button';
import CheckboxField from '@atoms/Checkbox/CheckboxField';
import { Text } from '@atoms/Text';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { color_base_black, color_brand_yellow } from 'utils/colors';
import { base64Encode } from 'utils/helpers';
import { mediaQueryList } from 'utils/mediaQueries';
import { isLoyaltyEnabled } from 'utils/metas';

import { CostSummary } from '../../../CostSummary';
import './PaymentForm.scss';

const bem = cn('passes-payment-form');

export const PaymentForm = observer(() => {
  const { cfStore, passesStore, authStore, bookingStore, accountStore } = useStores();
  const { savedCards } = passesStore.checkout;
  const { checkoutCart, costSummary, loadingCheckoutCart, cartNumber, urlParams } = passesStore.checkout;
  const { loyaltyRewardsPoints } = bookingStore.rti;
  const { user } = authStore;
  const { profile } = accountStore;
  const { cardInformation, disclaimerLabel, passesAreNotRefundableLabel, payAndBookButtonLabel, savedCardsLimitLabel } =
    cfStore.passes.reviewAndPay;
  const { celebrationBannerLink, celebrationBannerLinkForMobile } = cfStore.passes.reviewAndPay;

  const paymentContainer = useRef(null);
  const [cardInstance, setCardInstance] = useState<any>(null);
  const [cardData, setCardData] = useState<any>(null);
  const [saveCard, setSaveCard] = useState(urlParams.autoRenews);

  const { initializeAdyenCard } = useAdyen({
    isOpen: true,
    paymentContainer,
    setCardInstance,
    setCardData,
  });

  useEffect(() => {
    initializeAdyenCard();
  }, [initializeAdyenCard]);

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const noAmountToPay = useMemo(() => costSummary?.bookingTotal?.totalToBePaid === 0, [costSummary]);

  const handleSubmit = async () => {
    if (!noAmountToPay && !cardData.isValid) {
      cardInstance.componentRef.showValidation();

      return;
    }

    const cardState = noAmountToPay ? undefined : cardInstance?.state;
    const saveCardParam = noAmountToPay ? false : saveCard;

    const checkoutResult = await checkoutCart(cartNumber, undefined, cardState, saveCardParam);

    if (checkoutResult) {
      const searchParams = {
        reference_number: cartNumber,
        first_name: urlParams.mobileData.mobileFirstName || profile.userProfile?.firstName || '',
        last_name: urlParams.mobileData.mobileLastName || profile.userProfile?.lastName || '',
        email: urlParams.mobileData.mobileEmail || user.email || '',
        mobileCallbackUrl: urlParams.mobileData.mobileCallbackURL,
        reward_points: isLoyaltyEnabled() ? loyaltyRewardsPoints : undefined,
        loyalty_user_status: (await profile.getLoyaltyUserStatus()) || '',
      };
      const base64searchParams = base64Encode(JSON.stringify(searchParams));
      const successBannerLink = new URL(
        urlParams.mobileData.jwt ? celebrationBannerLinkForMobile : celebrationBannerLink
      );
      successBannerLink.searchParams.append('data', base64searchParams);
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: `${payAndBookButtonLabel} - $${costSummary.bookingTotal.totalToBePaid.toFixed(2)}`,
          customLink: successBannerLink.toString(),
          externalLink: '',
          linkType: 'linkClick',
        },
      });
      window.location.href = successBannerLink.toString();
    }
  };

  const handleSaveCardChanged = e => {
    setSaveCard(e.target.checked);
  };

  const isSavedCardsLimitReached = savedCards.length >= 5;
  const checkboxLabel = isSavedCardsLimitReached ? savedCardsLimitLabel : cardInformation.saveCardLabel;

  return (
    <div className={bem()}>
      {!noAmountToPay && (
        <div className="payment-container">
          <Text text={cardInformation.cardInfoLabel} className="adyen-checkout__fieldset__title" />
          <div ref={paymentContainer} className="payment" />
          <Formik
            onSubmit={() => undefined}
            initialValues={{
              saveCard,
            }}
            enableReinitialize={false}
          >
            {({ values }) => (
              <form className={bem('form')} onChange={handleSaveCardChanged}>
                <CheckboxField
                  name="saveCard"
                  label={checkboxLabel}
                  checked={values.saveCard}
                  disabled={urlParams.autoRenews || isSavedCardsLimitReached}
                />
              </form>
            )}
          </Formik>
        </div>
      )}
      {isMobile && (
        <>
          <div className={bem('separator')} />
          <CostSummary />
        </>
      )}
      {!noAmountToPay && <div className={bem('separator')} />}

      <div className={bem('footer')}>
        <div className={bem('tos')}>
          <Text text={passesAreNotRefundableLabel} className={bem('tos-text')} />
          <Text text={disclaimerLabel} className={bem('tos-text')} />
        </div>
        <Button
          label={`${payAndBookButtonLabel} - $${costSummary.bookingTotal.totalToBePaid.toFixed(2)}`}
          width="full"
          onClick={handleSubmit}
          colors={{
            text: color_base_black,
            backgroundOrBorder: color_brand_yellow,
          }}
          loading={loadingCheckoutCart}
        />
      </div>
    </div>
  );
});
