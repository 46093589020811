import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { editTripdetailsRtiRedirect } from '@account/routes/TripDetails/utils';
import { Avatar } from '@atoms/Avatar';
import { Coach } from '@atoms/Coach';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Seat } from '@atoms/Seat';

import { cn } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';

import './PassengersDetails.scss';
import { PassengerDetailsProps } from './types';

const bem = cn('trip-details-passenger');

export const PassengersDetails = observer((props: PassengerDetailsProps) => {
  const { passengerDetails } = props;

  if (!passengerDetails) return null;

  const { cfStore, accountStore, bookingStore } = useStores();

  const { setShowSeatSelectionModal } = accountStore.tripDetails;
  const { isOutboundSeatingVisible, isInboundSeatingVisible } = bookingStore.rti.seats;
  const { tripDetails } = cfStore.account;
  const { addLuggageLabel, includedCarryOnLabel, departureLabel, editSeatsLabel } = tripDetails;
  const { returnLabel, coachLabel, primaryPassengerTypeLabel } = tripDetails;
  const { passenger, isPrimaryPassenger, outboundSeat, inboundSeat } = passengerDetails;
  const { inboundCarryOnBagsAdded, outboundCarryOnBagsAdded } = passengerDetails;
  const { firstName, lastName, type } = passenger;
  const { setNoModificationAllowedModalOpen, bookingRules, setExtrasInfoModalOpen } = accountStore.tripDetails;
  const { isModifiable } = bookingRules || {};

  const departureDateTime = moment(accountStore?.tripDetails?.trip?.outboundRoute?.departureDateTime);

  const canViewSeats = isOutboundSeatingVisible || isInboundSeatingVisible;

  const avatarLabel = useMemo(() => `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase(), [firstName, lastName]);

  const passengerTypeLabel = useMemo(
    () => (isPrimaryPassenger ? primaryPassengerTypeLabel : tripDetails[`${type}PassengerTypeLabel`]),
    [type, primaryPassengerTypeLabel, isPrimaryPassenger, tripDetails]
  );

  const onEditLuggage = () => {
    if (departureDateTime.isBefore(moment())) setExtrasInfoModalOpen();
    else if (!isModifiable) setNoModificationAllowedModalOpen();
    else editTripdetailsRtiRedirect(accountStore, 'extras');
  };

  const onEditSeats = () => {
    setShowSeatSelectionModal(true);
  };

  return (
    <div className={bem()}>
      <div className={bem('passenger-info')}>
        <div className={bem('avatar')}>
          <Avatar label={avatarLabel} />
        </div>
        <div className={bem('name')}>
          {firstName} {lastName} {isPrimaryPassenger}
          <span className={bem('separator')} />
          <span className={bem('passenger-type')}>{passengerTypeLabel}</span>
        </div>
      </div>
      {(outboundSeat || inboundSeat) && (
        <div className={bem('details')}>
          <div className={bem('seats')}>
            {outboundSeat && (
              <div className={bem('seat')}>
                <div className={bem('route-title')}>{departureLabel}</div>
                <div className={bem('seat-details')}>
                  <Coach {...outboundSeat.coach} text={coachLabel} />
                  <Seat number={outboundSeat.number} facingDirection={outboundSeat.facingDirection} />
                </div>
                {outboundCarryOnBagsAdded > 0 && (
                  <div className={bem('luggage')}>
                    {outboundCarryOnBagsAdded} {includedCarryOnLabel}
                  </div>
                )}
              </div>
            )}

            {inboundSeat && (
              <div className={bem('seat')}>
                <div className={bem('route-title')}>{returnLabel}</div>

                <div className={bem('seat-details')}>
                  <Coach {...inboundSeat.coach} text={coachLabel} />
                  <Seat number={inboundSeat.number} facingDirection={inboundSeat.facingDirection} />
                </div>
                {inboundCarryOnBagsAdded > 0 && (
                  <div className={bem('luggage')}>
                    {inboundCarryOnBagsAdded} {includedCarryOnLabel}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className={bem('actions')}>
            {editSeatsLabel && canViewSeats && <LinkButton label={editSeatsLabel} onClick={onEditSeats} underline />}
            {!isKiosk() && addLuggageLabel && <LinkButton label={addLuggageLabel} onClick={onEditLuggage} underline />}
          </div>
        </div>
      )}
    </div>
  );
});
