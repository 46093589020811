import React, { useId } from 'react';

import { cn } from 'utils/classNames';

import './Toggle.scss';
import { ToggleProps } from './types';
import { useToggleLogic } from './useToggleLogic';

const bem = cn('toggle');

export const Toggle = (props: ToggleProps) => {
  const { items } = props;

  const { handleClick, handleKeyDown, toggleRef, selectedIndex } = useToggleLogic(props);

  const id = useId();

  return (
    <div ref={toggleRef} className={bem()}>
      {items.map((item, index) => (
        <button
          key={`${id}-${item.value}`}
          className={bem('item', { checked: selectedIndex === index })}
          value={item.value}
          onClick={handleClick(index)}
          onKeyDown={handleKeyDown}
          aria-pressed={selectedIndex === index}
          aria-controls={`id-${id}`}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};
