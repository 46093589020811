import { useEffect, useMemo } from 'react';
import { useStores } from 'stores';

import { loyaltyLoadAnalyticsEvent } from 'utils/adobeDataLayer';
import { scrollToTop } from 'utils/isKiosk';

import { getStationById } from '../../components/Header/components/SearchBar/utils';

export const useTrainSelectionLogic = () => {
  const { bookingStore, ticketsFormStore, cfStore, accountStore, authStore } = useStores();
  const { loading, data, outboundTicket, resetData, setPremiumOnly, setSortValue, applySort } = bookingStore.tickets;
  const { modifyTripProps, populateSortingDropdown } = bookingStore.tickets;
  const { resetRtiData } = bookingStore.rti;
  const { sortingLabels } = cfStore.trainSelection;
  const { profile, rewardsSummary } = accountStore;

  const { incompleteSearch, isPanelVisible, formValues, stationsData, showPanel, trip } = ticketsFormStore;

  const { trainSelection, generic } = cfStore;
  const { premiumFaresLabel } = generic;
  const { tripSummary, searchError, noResults, faresCompare } = trainSelection;

  const isRoundTrip = trip === 1 || modifyTripProps?.isRoundTrip;

  useEffect(() => {
    populateSortingDropdown(sortingLabels);
  }, [populateSortingDropdown, sortingLabels]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleFilterList = (value: number) => {
    setSortValue(value);
    applySort();
  };

  const travelLabels = useMemo(() => {
    if (modifyTripProps) {
      const isModifyReturnTrip = !modifyTripProps.startDate && modifyTripProps.endDate;

      const trip = modifyTripProps.tripDetails?.trip;

      if (isModifyReturnTrip)
        return {
          from: trip?.inboundRoute?.origin?.name,
          to: trip?.inboundRoute?.destination?.name,
        };

      return {
        from: !outboundTicket ? trip?.outboundRoute?.origin?.name : trip?.inboundRoute?.origin?.name,
        to: !outboundTicket ? trip?.outboundRoute?.destination?.name : trip?.inboundRoute?.destination?.name,
      };
    }

    return {
      from: getStationById(!outboundTicket ? formValues.from : formValues.to, stationsData),
      to: getStationById(!outboundTicket ? formValues.to : formValues.from, stationsData),
    };
  }, [modifyTripProps, formValues, outboundTicket, stationsData]);

  const selectDirection = useMemo(() => {
    const isModifyReturnTrip = modifyTripProps && !modifyTripProps.startDate && modifyTripProps.endDate;

    if (isModifyReturnTrip) return tripSummary.selectReturn;

    return !outboundTicket ? tripSummary.selectDeparture : tripSummary.selectReturn;
  }, [modifyTripProps, outboundTicket, tripSummary]);

  const noResultsProps = incompleteSearch ? searchError : noResults;

  const getFoundItemsMessage = () => {
    const itemsLength = data?.journeys?.length;

    return itemsLength > 1 ? `${itemsLength} ${tripSummary.trainsFound}` : `${itemsLength} ${tripSummary.trainFound}`;
  };

  const handleFilterByClass = event => {
    const { checked } = event.target;
    setPremiumOnly(checked);
  };

  //reset saved journeys and tickets from store when the user leaves the train selection page
  useEffect(() => {
    // when train selection page is mounted, reset the RTI page data if there is any (e.g. on history back)
    resetRtiData();

    // before onmount (e.g. change to RTI route), reset the train selection data
    return resetData;
  }, [resetData, resetRtiData]);

  const pushLoyaltyDataLayer = async () => {
    if (!profile.userProfile && authStore.isAuthenticated) await profile.fetchUserProfile();
    if (
      !rewardsSummary.summary &&
      authStore.isAuthenticated &&
      profile.userProfile?.loyaltyPrograms?.find(
        program => program.program.toLowerCase() === 'brightline' && program.active
      )
    )
      await rewardsSummary.fetchRewardsSummaryData();

    const { userProfile } = profile;
    const { summary } = rewardsSummary;

    const loyaltyProgram = userProfile?.loyaltyPrograms?.find(
      program => program.program.toLowerCase() === 'brightline'
    );

    loyaltyLoadAnalyticsEvent({
      loyalty: {
        joinDate: loyaltyProgram?.optInDateTime || '',
        loyaltyID: [loyaltyProgram?.memberNumber || ''],
        points: summary?.spendable || 0,
        pointsExpiration: [summary?.expirationDate || ''],
        pointsRedeemed: summary?.spent || 0,
        program: loyaltyProgram?.program || '',
        status: loyaltyProgram?.active.toString() || 'false',
      },
    });
  };

  return {
    loading,
    incompleteSearch,
    isPanelVisible,
    isRoundTrip,
    outboundTicket,
    tripSummary,
    selectDirection,
    showPanel,
    travelLabels,
    handleFilterList,
    data,
    getFoundItemsMessage,
    trainSelection,
    noResultsProps,
    faresCompare,
    handleFilterByClass,
    premiumFaresLabel,
    pushLoyaltyDataLayer,
  };
};
