import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { rootStore, useStores } from 'stores';

import { Card } from '@account/components/Card';
import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { isLoyaltyEnabled } from 'utils/metas';
import { getTicketsLink } from 'utils/tickets';

import { TripCardContet } from './TripsCardContent';
import './UpcomingTripsCard.scss';
import { EmptyUpcomingTripsCard } from './components';

const bem = cn('upcoming-trips-card');

export const UpcomingTripsCard = observer(() => {
  const { accountStore, cfStore } = useStores();
  const isTablet = useMediaPredicate(mediaQueryList.maxTablet);
  const { navigateToTripDetailsPage } = useTripDetailsConfig();
  const { account } = cfStore;
  const { trips: i18n } = account;

  const { trips, tripDetails } = accountStore;
  const { fetchTripData } = tripDetails;

  const loyaltyEnabled = isLoyaltyEnabled();

  if (!loyaltyEnabled && isTablet) return null;

  const onSeeTickets = referenceNumber => async () => {
    const response = await fetchTripData(referenceNumber);
    const ticketDocumentDetails = response?.ticketDocumentDetails;

    if (ticketDocumentDetails) {
      const link = getTicketsLink(ticketDocumentDetails);
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: i18n.seeTicketsLabel,
          customLink: link?.url,
          externalLink: link?.url,
          linkType: 'linkClick',
        },
        tripManagement: {
          PNR: referenceNumber,
        },
      });

      window.open(link?.url, '_blank');
    }
  };

  const onSeeDetails = referenceNumber => () => {
    navigateToTripDetailsPage({ referenceNumber }, i18n.tripDetailsLabel);
  };

  return (
    <Card
      title={i18n.dashboardTitle}
      seeAllLabel={i18n.seeAllCount}
      routeToNavigate="/account/my-trips"
      count={trips.upcoming.length}
      placeholderVariant="medium"
      isLoading={trips.isLoading}
    >
      <div className={bem()}>
        {!trips.upcoming.length && !trips.isLoading && <EmptyUpcomingTripsCard />}
        {trips.upcoming.slice(0, 2).map((trip, key) => (
          <TripCardContet key={key} trip={trip} onSeeDetails={onSeeDetails} onSeeTickets={onSeeTickets} />
        ))}
      </div>
    </Card>
  );
});
