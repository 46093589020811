import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { PassengerSeat } from '../PassengerSeat';
import './TripRoute.scss';
import { TripRouteProps } from './types';

const bem = cn('rti-seat-route');

export const TripRoute = observer((props: TripRouteProps) => {
  const { passengers, route, expanded, direction, roundTrip } = props;
  const { updatePristineSeatSelection, isAccountFlow } = props;

  const { bookingStore, accountStore, cfStore } = useStores();

  const seatsCF = isAccountFlow ? cfStore.account.tripDetails.seats : cfStore.rti.seats;

  const { provisionalBooking } = bookingStore.rti;
  const { isOutboundSeatingAvailable, isInboundSeatingAvailable } = bookingStore.rti.seats;
  const { isOutboundSeatingVisible, isInboundSeatingVisible } = bookingStore.rti.seats;
  const { activePassenger } = bookingStore.rti.seats;

  const { outboundRoute, inboundRoute } =
    provisionalBooking?.tripDetails?.trip || accountStore?.tripDetails?.trip || {};

  const outboundProductId = outboundRoute?.productId;
  const inboundProductId = inboundRoute?.productId;

  const isSeatingAvailable = useMemo(() => {
    if (isAccountFlow) {
      if (direction === 'outbound')
        return {
          isAvailable: isOutboundSeatingVisible,
          isSmartSaver: outboundProductId == 'SMART_SAVER',
        };

      return { isAvailable: isInboundSeatingVisible, isSmartSaver: inboundProductId == 'SMART_SAVER' };
    }
    if (direction === 'outbound')
      return {
        isAvailable: isOutboundSeatingAvailable,
        isSmartSaver: outboundProductId == 'SMART_SAVER',
      };

    return { isAvailable: isInboundSeatingAvailable, isSmartSaver: inboundProductId == 'SMART_SAVER' };
  }, [
    isOutboundSeatingAvailable,
    isInboundSeatingAvailable,
    isOutboundSeatingVisible,
    isInboundSeatingVisible,
    direction,
    outboundProductId,
    inboundProductId,
    isAccountFlow,
  ]);

  return (
    <div className={bem()}>
      {roundTrip && (
        <h2 className={bem('direction')}>{direction === 'outbound' ? seatsCF.departureLabel : seatsCF.returnLabel}</h2>
      )}
      <div className={bem('stations')}>
        <span>{route.origin.name}</span>
        <Icon name="arrow-from-to" className={bem('icon')} />
        <span>{route.destination.name}</span>
      </div>
      {!isSeatingAvailable?.isAvailable && (
        <div className={bem('passengers')}>
          <Text
            text={
              isSeatingAvailable?.isSmartSaver
                ? seatsCF.seatingNotAvailableSmartSaverMessage
                : seatsCF.seatingNotAvailableMessage
            }
            className={bem('seating-unavailable-text')}
          />
        </div>
      )}
      {isSeatingAvailable && expanded && !!passengers.length && (
        <div className={bem('passengers')}>
          {passengers?.map((item, index) => (
            <PassengerSeat
              key={`${item.passenger.email}-${index}`}
              updatePristineSeatSelection={updatePristineSeatSelection}
              index={index}
              passenger={item.passenger}
              passengerId={item.passengerId}
              active={item.passengerId === activePassenger?.passengerId}
              seat={direction === 'outbound' && !!item.outboundSeat ? item.outboundSeat : item.inboundSeat}
              isAccountFlow={isAccountFlow}
            />
          ))}
        </div>
      )}
    </div>
  );
});
