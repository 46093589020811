import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { PassengerCarouselProps } from '@booking/routes/RTI/SeatSelection/components/SeatSelectionModal/PassengersCarousel/types';

import { cn } from 'utils/classNames';

import { PassengerCarouselItem } from './PassengerCarouselItem';
import './PassengersCarousel.scss';
import useCarousel from './useCarousel';

const bem = cn('rti-seat-passengers-carousel');

export const PassengersCarousel = observer((props: PassengerCarouselProps) => {
  const { isAccountFlow } = props;

  const { bookingStore } = useStores();

  const { seats } = bookingStore.rti;

  const { passengersSeats, activePassenger, setActivePassenger } = seats;

  const { handlers, getRefFromHandlers } = useCarousel();
  const handleIndicatorClick = passenger => () => {
    setActivePassenger(passenger);
  };

  return (
    <div {...handlers} ref={getRefFromHandlers} className={bem()}>
      {activePassenger && <PassengerCarouselItem isAccountFlow={isAccountFlow} />}
      {activePassenger && passengersSeats.length > 1 && (
        <div className={bem('pagination')}>
          {passengersSeats.map(el => (
            <button
              key={el.passengerId}
              className={bem('indicator', { active: el.passengerId === activePassenger.passengerId })}
              onClick={handleIndicatorClick(el)}
            />
          ))}
        </div>
      )}
    </div>
  );
});
