import React from 'react';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import { Title } from '@atoms/Title';
import { Modal } from '@molecules/Modal';

import { cn, cx } from 'utils/classNames';

import { PrivacyRequestSuccessfulComponentProps } from '../../../types';
import './PrivacyRequestSuccessfulComponent.scss';

const bem = cn('privacy-request-success-modal');

const PrivacyRequestSuccessfulComponent = (props: PrivacyRequestSuccessfulComponentProps) => {
  const {
    submitSuccessMessage = 'Submitted Successfully!',
    privacyRequestFormLogic,
    submitAnotherRequestButton,
  } = props;

  const { closeModal, resetForm } = privacyRequestFormLogic;

  const colorClassMap = {
    blue: 'background-color-blue',
    red: 'background-color-red',
  };

  const additionalClassName = colorClassMap.blue || '';

  const submitAnotherRequestHandle = e => {
    e.preventDefault();
    e.stopPropagation();
    resetForm();
  };

  return (
    <Modal variant="no-space-scroll" onClose={closeModal} className={cx(bem('modal'), bem(additionalClassName))}>
      <div className={bem()}>
        <div className={bem('header')}>
          <button className={bem('icon-wrapper')} onClick={closeModal}>
            <Icon name="close2" className={bem('icon')} />
          </button>
        </div>
        <div className={bem('content')}>
          <div className={bem('success-message')}>
            <span className={bem('blue-icon')}>
              <Icon name="check-circle" className={bem('check-icon')} />
            </span>
            <div className={bem('title')}>
              <Title text={submitSuccessMessage} size="h3" />
            </div>
            <div className={bem('button-row')}>
              <Button
                onClick={submitAnotherRequestHandle}
                color={submitAnotherRequestButton?.color || 'blue'}
                size={submitAnotherRequestButton?.size || 'medium'}
                width={submitAnotherRequestButton?.width || 'normal'}
                variant={submitAnotherRequestButton?.variant || 'primary'}
                label={submitAnotherRequestButton?.buttonConfirmLabel || 'Submit Another Request'}
                type="button"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PrivacyRequestSuccessfulComponent;
