import React, { useMemo } from 'react';

import { Text } from '@atoms/Text';
import TooltipV2 from '@atoms/Tooltip/v2/TooltipV2';

import { cn } from 'utils/classNames';
import { color_base_black, color_base_medium_dark_grey, color_white } from 'utils/colors';

import { CircularProgressBar } from '../CircularProgressBar';
import './RewardsPointsTracker.scss';
import { RewardsTrackerPlaceholder } from './RewardsPointsTrackerPlaceholder';
import { RewardsSummaryProps } from './types';

const bem = cn('rewards-tracker');

const RewardsPointsTracker = (props: RewardsSummaryProps) => {
  const {
    backgroundImage,
    totalProgress,
    currentProgress,
    availablePointsLabel,
    availablePointsLabelColor,
    pendingPointsLabel,
    pendingLabelColor,
    pendingPointsColor,
    pointsLabel,
    expiryDate,
    expiresOnLabel,
    expiresTooltipText,
    isLoading,
  } = props;

  const styles: any = useMemo(
    () => ({
      '--rewards-background-image': `url(${backgroundImage?.src})`,
      '--available-points-label-color': availablePointsLabelColor,
      '--pending-points-label-color': pendingLabelColor,
      '--pending-points-color': pendingPointsColor,
    }),
    [backgroundImage, availablePointsLabelColor, pendingLabelColor, pendingPointsColor]
  );

  const expiresTooltipColors = {
    info: {
      text: {
        inactive: color_base_medium_dark_grey,
      },
    },
    content: {
      text: {
        inactive: color_base_black,
        hover: color_base_black,
      },
    },
  };

  const progressTooltipColors = {
    info: {
      text: {
        inactive: availablePointsLabelColor,
      },
    },
    content: {
      text: {
        inactive: currentProgress === totalProgress && currentProgress > 0 ? color_white : pendingPointsColor,
        hover: currentProgress === totalProgress && currentProgress > 0 ? color_white : pendingPointsColor,
      },
    },
  };

  if (isLoading) return <RewardsTrackerPlaceholder />;

  return (
    <div className={bem()} style={styles}>
      <div className={bem('progress-bar')}>
        <CircularProgressBar {...props} progressTooltipColors={progressTooltipColors} />
        <div className={bem('points-container')}>
          <div className={bem('spendable')}>
            <Text className={bem('spendable-points')} text={`${currentProgress}`} />
            <Text className={bem('spendable-label')} text={`${availablePointsLabel}`} />
          </div>
          <div className={bem('pending')}>
            <Text
              className={bem('pending-points')}
              text={`${totalProgress - currentProgress > 0 ? '+' : ''}${
                totalProgress - currentProgress
              } ${pointsLabel}`}
            />
            <Text className={bem('pending-label')} text={`${pendingPointsLabel}`} />
          </div>
        </div>
      </div>
      {totalProgress > 0 && expiryDate && (
        <div className={bem('expiry-date')}>
          <div className={bem('info-text')}>
            <Text className={bem('label')} text={expiresOnLabel} />
            {expiresTooltipText && (
              <TooltipV2 className={bem('tooltip')} text={`${expiresTooltipText}`} colors={expiresTooltipColors} />
            )}
          </div>
          <div className={bem('date')}>{expiryDate}</div>
        </div>
      )}
    </div>
  );
};

export default RewardsPointsTracker;
