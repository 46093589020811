import { useEffect, useState } from 'react';

import { getHideModalComponent, setHideModalComponent } from 'utils/localstorage';

export const useModalComponentLogic = props => {
  const { displayOnPageLoad, cacheModal, id, experienceFragmentPath } = props;
  const initialDisplay = displayOnPageLoad && !getHideModalComponent();

  const [visible, setVisibility] = useState(initialDisplay);
  const [xfContent, setXFContent] = useState('');

  const fetchXFContent = async () => {
    try {
      const response = await fetch(experienceFragmentPath);

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const data = await response.text();
      setXFContent(data);
    } catch (error) {
      console.error('Error fetching Experience Fragment content:', error);
    }
  };

  const hideModal = () => {
    setVisibility(false);
    displayOnPageLoad && !cacheModal && setHideModalComponent(true);
  };

  const showModal = e => {
    e.stopPropagation();
    setVisibility(true);
  };

  useEffect(() => {
    if (fetchXFContent) fetchXFContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const buttons = document.querySelectorAll(`[id^="${id}"]`);
    window.addEventListener('showModal', showModal);

    buttons.forEach(button => {
      button.addEventListener('click', showModal);
    });

    return () => {
      window.removeEventListener('showModal', showModal);

      buttons.forEach(button => {
        button.removeEventListener('click', showModal);
      });
    };
  }, [id]);

  return {
    hideModal,
    visible,
    xfContent,
  };
};
