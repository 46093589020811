import React from 'react';

import { cn, cx } from 'utils/classNames';

import { AddToCartProps } from './types';
import { useCartLogic } from './useCartLogic';

const bemGlobal = cn('global-header');

export const AddToCart = (props: AddToCartProps) => {
  const { link, label, className, preventFetchOnMobile } = props;
  const count = useCartLogic(preventFetchOnMobile);

  return (
    <a href={link?.url} target={link?.target} aria-label={label} className={cx(bemGlobal('cart-button'), className)}>
      {!!count && count > 0 && <span>{count}</span>}
    </a>
  );
};
