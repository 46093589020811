import { useFormikContext } from 'formik';
import React from 'react';
import { useStores } from 'stores';

import { InputField } from '@atoms/Input';
import Title from '@atoms/Title/Title';
import { countries } from '@molecules/PaymentForm/countryAndStates';
import SelectField from '@organisms/ParkingPasses/SelectGarage/SelectFieldGarage';
import useDailyAndParkingPassesLogic from '@organisms/ParkingPasses/useDailyAndParkingPassesLogic';

import { cn } from 'utils/classNames';
import { useInputLogic } from 'utils/useInputLogic';

import './EditDailyParkingForm.scss';

const bem = cn('edit-daily-parking-form');

export const EditDailyParking = props => {
  const { index } = props;
  const { passengerName = '' } = props;
  const { cfStore } = useStores();
  const { tripDetails } = cfStore.account;
  const { setFieldValue } = useFormikContext();
  const stateOptions =
    countries
      .find(country => country.country == 'United States')
      .states?.map(state => ({
        label: state,
        value: state,
      })) || [];

  const { field } = useInputLogic({ ...props, name: `drivers.${index}.ppStationName` });

  const handleCapitalizeParkingPasses = useDailyAndParkingPassesLogic();

  const isRequired = field.value !== 'Miami';

  const title = passengerName
    ? `${tripDetails?.editDailyParkingTitle} · ${passengerName}`
    : tripDetails?.editDailyParkingTitle;

  return (
    <>
      <Title size="h4" text={title} />
      <div className={bem('form')}>
        <div className={bem('row')}>
          <div className={bem('field')}>
            <SelectField
              errorVariant="inline"
              name={`drivers.${index}.ppStationName`}
              label={tripDetails?.editDailyParkingGarageSelectLabel}
              disabled
              useDesktopVariant
            />
          </div>
        </div>

        <div className={bem('row')}>
          <div className={bem('field')}>
            <SelectField
              errorVariant="inline"
              name={`drivers.${index}.ppLicenseState`}
              label={tripDetails?.editDailyParkingStateSelectLabel}
              optionsData={stateOptions}
              useDesktopVariant
              displayTrailingIcon
            />
          </div>
        </div>

        <div className={bem('row')}>
          <div className={bem('field')}>
            <InputField
              type="text"
              name={`drivers.${index}.ppLicenseNumber`}
              errorVariant="inline"
              onChange={e =>
                handleCapitalizeParkingPasses(e.target.value, `drivers.${index}.ppLicenseNumber`, setFieldValue)
              }
              label={tripDetails?.editDailyParkingLicensePlateTextFieldPlaceholder}
              required={isRequired}
              showAsterisk={isRequired}
              displayTrailingIcon
            />
          </div>
        </div>
      </div>
    </>
  );
};
