import React from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { mediaQueryList } from 'utils/mediaQueries';

import { ExtrasDesktopSelect } from './Desktop/ExtrasDesktopSelect';
import './ExtrasSelect.scss';
import { ExtrasSelectHeader } from './ExtrasSelectHeader';
import { ExtrasMobileSelect } from './Mobile/ExtrasMobileSelect';
import { SelectProps } from './types';
import { useSelectHandlers } from './useSelectHandlers';

export const ExtrasSelect = (props: SelectProps) => {
  const { useDesktopVariant } = props;
  const hookProps = useSelectHandlers(props);
  const isMobileDevice = useMediaPredicate(mediaQueryList.maxMobile);

  if (isMobileDevice && !useDesktopVariant)
    return (
      <ExtrasSelectHeader {...props} {...hookProps}>
        <ExtrasMobileSelect {...props} {...hookProps} />
      </ExtrasSelectHeader>
    );

  return <ExtrasDesktopSelect {...props} {...hookProps} handleKeyDown={hookProps.handleKeyDown} />;
};
