import React from 'react';

import { cn, cx } from 'utils/classNames';

import './UserAvatar.scss';
import { UserAvatarProps } from './types';

const bem = cn('user-avatar');

export const UserAvatar = ({ firstName = '', lastName = '', src, size = 'small' }: UserAvatarProps) => (
  <div className={bem()}>
    <div className={cx(bem('emblem'), bem(size))}>
      {src ? (
        <img alt="picture" src={src} />
      ) : (
        <span>
          {firstName[0]}
          {lastName[0]}
        </span>
      )}
    </div>
    <div className={bem('name')}>
      {firstName} {lastName}
    </div>
  </div>
);
