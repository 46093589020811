import { ErrorStateProps } from './types.rti';

export const defaultLockedSteps = {
  passengerInfo: false,
  seatAssignment: false,
  extras: false,
  paymentInfo: false,
};

export const defaultCompletedSteps = {
  passengerInfo: true,
  seatAssignment: false,
  extras: false,
  paymentInfo: false,
};

export const defaultActiveStep = 'passengerInfo';

export const defaultError: ErrorStateProps = {
  error: null,
};
