import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';

import Button from '@atoms/Button/Button';
import Checkbox from '@atoms/Checkbox/Checkbox';
import Divider from '@atoms/Divider/Divider';
import { Icon } from '@atoms/Icon';
import { ReturnAsCredit } from '@booking/routes/RTI/PaymentInfo/components/PaymentInfoEntry/components/ReturnAsCredit';
import { Rewards } from '@booking/routes/RTI/PaymentInfo/components/PaymentInfoEntry/components/Rewards/Rewards';
import { PaymentErrorModal } from '@kiosk/components/PaymentErrorModal';
import { PaymentProcessingModal } from '@kiosk/components/PaymentProcessingModal';
import { PaymentRequestModal } from '@kiosk/components/PaymentRequestModal';

import { cn } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';
import { isGiftCardEnabled, isLoyaltyEnabled } from 'utils/metas';

import './PaymentInfoEntry.scss';
import { CreditCardItems } from './components/CreditCardItems';
import { GiftCard } from './components/GiftCard/GiftCard';
import { PolicyItems } from './components/PolicyItems/PolicyItems';
import { WalletItems } from './components/WalletItems';
import { usePaymentInfoEntryLogic } from './usePaymentInfoEntryLogic';

const bem = cn('payment-info-entry');

export const PaymentInfoEntry = observer(() => {
  const hookProps = usePaymentInfoEntryLogic();
  const { provisionalBooking, costSummary, reviewAndPay, initializeAdyenCard } = hookProps;
  const { handlePay, isAuthenticated, selectedPaymentMethod, setSelectedPaymentMethod, modifyTripType } = hookProps;
  const { setCardInstance, setCardData, paymentContainer, generic } = hookProps;
  const { saveCardChecked, handleToggleSaveCard, paymentTokens, applicablePasses, handleKioskPay } = hookProps;
  const { paymentInProgress } = hookProps;

  const giftCardEnabled = isGiftCardEnabled();

  if (!provisionalBooking) return null;

  const showPaymentOptions = useMemo(
    () => costSummary?.bookingTotal && costSummary?.bookingTotal?.totalToBePaid > 0,
    [costSummary]
  );

  const showPassesOptions = useMemo(
    () => isAuthenticated && !!applicablePasses?.length,
    [isAuthenticated, applicablePasses]
  );

  const showReturnAsCredit = useMemo(
    () => modifyTripType && costSummary?.bookingTotal && costSummary.bookingTotal.totalToBePaid < 0,
    [modifyTripType, costSummary]
  );

  const confirmBookingButtonLabel = useMemo(() => {
    const totalToBePaid = costSummary?.bookingTotal?.totalToBePaid;

    if (!modifyTripType || totalToBePaid === undefined)
      return isKiosk() ? reviewAndPay.submitBtnLabelKiosk : reviewAndPay.submitBtnLabel;

    return totalToBePaid < 0 ? generic.totalReturnAsCreditLabel : generic.totalDueLabel;
  }, [costSummary, modifyTripType, reviewAndPay, generic]);

  const isSavedCardsLimitReached = paymentTokens.length >= 5;

  const showSaveCardCheckbox =
    showPaymentOptions && (selectedPaymentMethod?.isNewCard || !paymentTokens.length) && isAuthenticated;

  useEffect(() => {
    if (showPaymentOptions) initializeAdyenCard();
  }, [initializeAdyenCard, showPaymentOptions]);

  return (
    <>
      {!isKiosk() && (
        <div className={bem()}>
          {showPassesOptions && <WalletItems />}
          {isLoyaltyEnabled() && <Rewards />}
          {giftCardEnabled && <GiftCard />}
          {showReturnAsCredit && <ReturnAsCredit />}
          {showPaymentOptions && (
            <>
              {isAuthenticated && !!paymentTokens.length ? (
                <CreditCardItems
                  title={reviewAndPay.cardInformation.creditCardLabel}
                  setCardInstance={setCardInstance}
                  setCardData={setCardData}
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                />
              ) : (
                <div className="payment-container">
                  <div ref={paymentContainer} className="payment" />
                </div>
              )}
            </>
          )}
          {showSaveCardCheckbox && (
            <Checkbox
              className={bem('save-card')}
              name="saveCard"
              label={
                isSavedCardsLimitReached
                  ? reviewAndPay.cardInformation.savedCardsLimitLabel
                  : reviewAndPay.cardInformation.saveCardLabel
              }
              isChecked={saveCardChecked}
              onChange={handleToggleSaveCard}
              disabled={isSavedCardsLimitReached}
            />
          )}
        </div>
      )}
      {isKiosk() && (
        <>
          {giftCardEnabled && <GiftCard />}
          <div className={bem('kiosk-notification')}>
            <Icon name="warning" />
            <div className={bem('kiosk-notification-text')}>{reviewAndPay.kioskNotificationText}</div>
          </div>
        </>
      )}
      <div className={bem('divider')}>
        <Divider />
      </div>
      <PolicyItems />
      <Button
        width="full"
        color="yellow"
        label={`${confirmBookingButtonLabel} - $${Math.abs(costSummary?.bookingTotal.totalToBePaid || 0).toFixed(2)}`}
        loading={paymentInProgress}
        onClick={isKiosk() ? handleKioskPay : handlePay}
      />
      {isKiosk() && (
        <>
          <PaymentRequestModal />
          <PaymentProcessingModal />
          <PaymentErrorModal retryPayment={handleKioskPay} />
        </>
      )}
    </>
  );
});
