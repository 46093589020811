import React from 'react';

import { TableCell } from '@atoms/Table/TableCell';
import { TableRowProps } from '@atoms/Table/types';

import { cn, cx } from 'utils/classNames';

const bem = cn('table');

export const TableRow = (props: TableRowProps) => {
  const { cells, rowNumber, isHeader, rowCN = '', cellCN } = props;
  const { stickyColIndex, modalVariant } = props;

  return (
    <tr
      className={cx(bem('row'), {
        [rowCN]: !!rowCN,
      })}
    >
      {cells.map((cell, index) => (
        <TableCell
          key={`cell-${isHeader ? 'header' : 'body'}-${rowNumber}-${index}`}
          cell={cell}
          cellNumber={index}
          isHeader={isHeader}
          stickyColIndex={stickyColIndex}
          cellCN={cx({ cellCN: !!cellCN, [bem('cell-first-column')]: index === 0, [bem('cell-header')]: isHeader })}
          modalVariant={modalVariant}
        />
      ))}
    </tr>
  );
};
