import { observer } from 'mobx-react-lite';
import React, { Suspense } from 'react';
import { useStores } from 'stores';

import { CardStates } from '@booking/routes/RTI/Card/constants';
import { PassengersInfo } from '@booking/routes/RTI/PassengersInfo';
import { Modal } from '@molecules/Modal';

import './ManagePassengers.scss';

const ManagePassengers = observer(() => {
  const { accountStore, cfStore, bookingStore } = useStores();
  const { setChangesHaveBeenSaved, setError, changesHaveBeenSaved } = bookingStore.rti;
  const { setShowManagePassengersModal, setShowCloseNotSavedModal, isCloseNotSaved } = accountStore.tripDetails;
  const { passengerSeatingDetails, setShouldFetchTripDetails } = accountStore.tripDetails;
  const { manageTripsSection } = cfStore.account.tripDetails.navigation;

  const onCloseModifyPassengerModal = () => {
    isCloseNotSaved ? setShowCloseNotSavedModal(true) : setShowManagePassengersModal(false);
    changesHaveBeenSaved && setShouldFetchTripDetails(true);
    setChangesHaveBeenSaved(false);
    setError(false);
  };

  const passengersNumber = passengerSeatingDetails?.reduce(
    (counts, passenger) => {
      passenger?.passenger?.type === 'adult' && counts.adults++;
      passenger?.passenger?.type === 'child' && counts.kids++;
      passenger?.passenger?.type === 'infant' && counts.infants++;

      return counts;
    },
    { adults: 0, kids: 0, infants: 0 }
  ) || { adults: 0, kids: 0, infants: 0 };

  return (
    <div>
      <Suspense>
        <Modal
          variant="default-no-space"
          title={manageTripsSection.managePassengers}
          onClose={onCloseModifyPassengerModal}
        >
          <PassengersInfo mode={CardStates.active} passengersTypeNumber={passengersNumber} isModifyPassenger />
        </Modal>
      </Suspense>
    </div>
  );
});

export default ManagePassengers;
