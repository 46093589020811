import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStores } from 'stores';

import { useNavigationLogic } from '@account/routes/TripDetails/components/Navigation/useNavigationLogic';
import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { CoachSelect } from '../CoachSelect';
import { ErrorItem } from '../ErrorItem';
import { FooterPlaceholder } from '../FooterPlaceholder';
import { LegendItem } from '../LegendItem';
import { SeatMapPlaceholder } from '../SeatMapPlaceholder';
import { SeatSelectionMap } from '../SeatSelectionMap';
import { SeatingDetails } from '../SeatingDetails';
import { SeatSelectionProps } from '../types';
import './SeatSelectionDesktop.scss';

const bem = cn('seat-selection-desktop');
export const SeatSelectionDesktop = observer((props: SeatSelectionProps) => {
  const { handleCloseModal, saveLabel, id, isAccountFlow } = props;
  const { bookingStore, cfStore, accountStore } = useStores();

  const { tripDetails } = accountStore;

  const [pristineSeatSelection, setPristineSeatSelection] = useState(true);
  const { seats, provisionalBooking } = bookingStore.rti;
  const { isOutboundSeatingAvailable, isInboundSeatingAvailable } = bookingStore.rti.seats;
  const { isOutboundSeatingVisible, isInboundSeatingVisible } = bookingStore.rti.seats;
  const closeRef = useRef<HTMLButtonElement>(null);
  const navigationLogic = useNavigationLogic();
  const { isFirstLegTraveled, isSecondLegTraveled } = navigationLogic;

  useEffect(() => {
    if (closeRef && closeRef.current) closeRef.current.focus();
  }, []);

  const seatsCF = isAccountFlow ? cfStore.account.tripDetails.seats : cfStore.rti.seats;

  const { trip } = provisionalBooking.tripDetails;
  const { loading, error, switchDirection, resetData, updateError } = seats;
  const { travelDirection, coachOptions, selectedCoach, updateBookingSeats, activePassenger, handleSelectCoach } =
    seats;

  const outboundRoute = tripDetails?.trip?.outboundRoute;
  const inboundRoute = tripDetails?.trip?.inboundRoute;
  const isLongHaul = outboundRoute?.origin?.id === 'MCO' || outboundRoute?.destination?.id === 'MCO';

  const canEditSeatsAccontFlow = useMemo(() => {
    const isNotSmartSaverOutbound = travelDirection === 'outbound' && outboundRoute?.productId !== 'SMART_SAVER';
    const isNotSmartSaverInbound = travelDirection === 'inbound' && inboundRoute?.productId !== 'SMART_SAVER';
    const isOutboundPremium = travelDirection === 'outbound' && outboundRoute?.classType === 'premium';
    const isInboundPremium = travelDirection === 'inbound' && inboundRoute?.classType === 'premium';
    const isFirstLegModifiable = travelDirection === 'outbound' && !isFirstLegTraveled;
    const isSecondLegModifiable = travelDirection === 'inbound' && !isSecondLegTraveled;

    return (
      ((isLongHaul && (isNotSmartSaverOutbound || isNotSmartSaverInbound)) || isOutboundPremium || isInboundPremium) &&
      (isFirstLegModifiable || isSecondLegModifiable)
    );
  }, [outboundRoute, inboundRoute, travelDirection, isLongHaul, isFirstLegTraveled, isSecondLegTraveled]);

  const isRoundTrip = !!trip.outboundRoute && !!trip.inboundRoute;

  const isSwitchDirectionAvailable = useMemo(() => {
    if (isAccountFlow) return isOutboundSeatingVisible && isInboundSeatingVisible;

    return isOutboundSeatingAvailable && isInboundSeatingAvailable;
  }, [
    isAccountFlow,
    isOutboundSeatingAvailable,
    isOutboundSeatingVisible,
    isInboundSeatingAvailable,
    isInboundSeatingVisible,
  ]);

  useEffect(() => {
    if (!isAccountFlow && isRoundTrip && travelDirection === 'outbound' && !isOutboundSeatingAvailable)
      switchDirection();
    if (isAccountFlow && isRoundTrip && travelDirection === 'outbound' && !isOutboundSeatingVisible)
      switchDirection(isAccountFlow);
  }, [
    isAccountFlow,
    travelDirection,
    isOutboundSeatingAvailable,
    isOutboundSeatingVisible,
    isRoundTrip,
    switchDirection,
  ]);

  const displaySubtitle = useMemo(
    () =>
      travelDirection === 'outbound'
        ? activePassenger?.outboundSeat?.coach?.classType === 'smart'
        : activePassenger?.inboundSeat?.coach?.classType === 'smart',
    [activePassenger?.inboundSeat?.coach?.classType, activePassenger?.outboundSeat?.coach?.classType, travelDirection]
  );

  const closeModal = () => {
    resetData();
    handleCloseModal();
  };

  const saveSelection = (isAccountFlow = false) => {
    updateBookingSeats(closeModal, isAccountFlow, accountStore);
  };

  const updatePristineSeatSelection = () => {
    setPristineSeatSelection(false);
  };

  const handleCoachValueChange = coachValue => {
    const coachToSet = coachOptions.find(el => el.value === coachValue);
    if (!coachToSet) return;
    handleSelectCoach(coachToSet);
  };

  const saveSelectionAccountFlow = () => {
    saveSelection(isAccountFlow);
  };

  let description = '';
  switch (seats.displayedCoach?.coach.coachNumber) {
    case '1':
      description =
        'Exits are located at the start and end of the area, with exits on the right and left sides. Luggage racks are situated at the start, on the right side. Restrooms are positioned at the start, on the left side.';
      break;
    case '2':
      description =
        'Exits are located at the start and end of the area, with exits on the right and left sides. Luggage racks are situated at the end, on the left. Restrooms are positioned at the end, on the right side.';
      break;
    case '3':
      description =
        'Exits are located at the start and end of the area, with exits on the right and left sides. Luggage racks are situated at the start, on the right side. Restrooms are positioned at the start, on the left side.';
      break;
    case '4':
      description =
        'Exits are located at the start and end of the area, with exits on the right and left sides. Luggage racks are situated at the end, on the left. Restrooms are positioned at the end, on the right side.';
      break;
    default:
      description = '';
  }

  const hasSeatDeparted =
    (!tripDetails?.trip?.outboundRoute?.isCancelled && tripDetails?.trip?.outboundRouteStatus !== 'past') ||
    travelDirection !== 'outbound';

  return (
    <>
      <div className={bem()}>
        <div className={bem('left')}>
          <h1 id={`title-${id}`} className={bem('title')}>
            {seatsCF.seatSelectionTitle}
          </h1>
          {displaySubtitle && <div className={bem('subtitle')}>{seatsCF.seatSelectionSubtitle}</div>}
          <div className={bem('seats')}>
            <p className="sr-only">Activating this element will cause content on the page to be updated</p>
            <SeatingDetails updatePristineSeatSelection={updatePristineSeatSelection} isAccountFlow={isAccountFlow} />
          </div>
        </div>
        <div className={bem('right')}>
          <button ref={closeRef} className={bem('close-modal')} aria-label="Close" onClick={closeModal}>
            <Icon name="close2" className={bem('close-modal-icon')} />
          </button>
          {hasSeatDeparted ? (
            loading ? (
              <SeatMapPlaceholder />
            ) : error ? (
              <ErrorItem />
            ) : (
              <>
                <div className={bem('header')}>
                  <div className={bem('travel')}>
                    <div className={bem('direction-icon')}>
                      <Icon name="long-arrow-right" />
                    </div>
                    <span>{seatsCF.directionOfTravelLabel}</span>
                    <div className={bem('direction-icon')}>
                      <Icon name="long-arrow-right" />
                    </div>
                  </div>

                  {selectedCoach && (
                    <div className={bem('coach-select')}>
                      <CoachSelect
                        selectedCoach={selectedCoach}
                        optionsData={coachOptions}
                        onChange={handleCoachValueChange}
                        name="coach-select"
                        isAccountFlow={isAccountFlow}
                        canEditSeatsAccontFlow={canEditSeatsAccontFlow}
                      />
                    </div>
                  )}
                </div>
                <div className={bem('seat-map')} role="region" aria-label="Seating map">
                  <p className={bem('sr-only')} role="alert">
                    {description}
                  </p>
                  <SeatSelectionMap
                    pristineSeatSelection={pristineSeatSelection}
                    isAccountFlow={isAccountFlow}
                    canEditSeatsAccontFlow={canEditSeatsAccontFlow}
                  />
                </div>
              </>
            )
          ) : (
            <div className={bem('cancel-message')}>
              <Icon name="disabled-seat" className={bem('disabled-seat-icon')} />
              <p className={bem('disabled-seat-header')}>{seatsCF.notModifiableTitle}</p>
              <p className={bem('disabled-seat-text')}>{seatsCF.notModifiableText}</p>
            </div>
          )}
        </div>
        {updateError && (
          <div className={bem('update-error')}>
            <Icon name="warning" className={bem('update-error-icon')} />
            <div className={bem('update-error-message')} role="alert">
              {seatsCF.updateSeatsServerErrorMessage}
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <FooterPlaceholder />
      ) : (
        !error && (
          <div className={bem('footer')}>
            <div className={bem('legend')}>
              <LegendItem color="yellow" label={seatsCF.selectedSeatLabel} />
              <LegendItem color="maxBlue" label={seatsCF.smartSeatLabel} />
              <LegendItem color="black" label={seatsCF.premiumSeatLabel} />
              <LegendItem color="silver" label={seatsCF.unavailableSeatLabel} />
            </div>
            <div className={bem('actions')}>
              {isSwitchDirectionAvailable && (
                <Button
                  label={travelDirection === 'outbound' ? seatsCF.nextTripCtaLabel : seatsCF.previousTripCtaLabel}
                  color="charcoal"
                  variant="primary"
                  onClick={() => switchDirection(isAccountFlow)}
                />
              )}
              {(!isAccountFlow || canEditSeatsAccontFlow) && (
                <Button
                  label={saveLabel}
                  color="yellow"
                  variant="primary"
                  onClick={!isAccountFlow ? () => saveSelection() : () => saveSelectionAccountFlow()}
                />
              )}
            </div>
          </div>
        )
      )}
    </>
  );
});
