import DOMPurify from 'dompurify';
import { withStyleSystem } from 'hocs/withStyleSystem';
import React, { useMemo } from 'react';

import { cn } from 'utils/classNames';

import { DEFAULT_SIZE } from './constants';
import { TitleProps } from './types';

const bem = cn('title');

const Title = ({ text = '', link, size, color, tabIndex }: TitleProps) => {
  const { url, target } = link || {};
  const El = size || DEFAULT_SIZE;
  const LinkWrapper = link ? 'a' : 'span';

  const linkWrapperProps = useMemo(
    () =>
      url
        ? {
            href: url,
            target,
          }
        : {},
    [url, target]
  );
  const elProps = color ? { style: { color }, className: bem() } : { className: bem() };

  return (
    <El {...elProps}>
      <LinkWrapper
        {...linkWrapperProps}
        tabIndex={tabIndex}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text, { ADD_ATTR: ['target'] }) }}
      />
    </El>
  );
};

export const ExtendedTitle = withStyleSystem(Title);

export default Title;
