import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import { ModalFormProps } from '../../types';
import './ModalFooter.scss';

const bem = cn('parking-modal-footer-buttons');

export const ModalFooter = observer((props: ModalFormProps) => {
  const { formRef, useParkingModalLogic, onCloseModal } = props;
  const { cfStore, bookingStore } = useStores();
  const parking = cfStore.rti.parking;
  const { totalPrice, isUpdateButton, isFormModified, setButtonClick } = useParkingModalLogic;

  const { loading } = bookingStore.parking;

  const handleOnClick = () => {
    setButtonClick(true)
    formRef.current?.handleSubmit();
  };

  return (
    <div className={bem()}>
      <div className={bem('footer')}>
        <div className={bem('footer-total')}>
          <Text text={cfStore.generic.totalLabel} className={bem('footer-total-label')} />
          <Text text={`$${totalPrice().toFixed(2)}`} className={bem('footer-total-price')} />
        </div>
        <div className={bem('footer-buttons')}>
          <Button
            label={parking.cancelLabel}
            colors={{
              backgroundOrBorder: color_base_medium_light_grey,
            }}
            onClick={onCloseModal}
          />
          <Button
            disabled={!isFormModified}
            label={isUpdateButton ? parking.updateLabel : parking.addTripLabel}
            color="yellow"
            loading={loading}
            onClick={handleOnClick}
          />
        </div>
      </div>
    </div>
  );
});
