import { FormikContextType, useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
import { DayPickerRangeController } from 'react-dates';

import { Icon } from '@atoms/Icon';
import { PartialFromProps } from '@molecules/TicketsForm/components/OriginAndDestination/types';

import { cn, cx } from 'utils/classNames';

import { START_DATE } from '../constants';
import './MobileCalendar.scss';

const bem = cn('mobile-calendar');
const bemDateRange = cn('date-range-picker');

export const MobileCalendar = props => {
  const {
    isSameDay,
    isOutsideRange,
    focusedInput = START_DATE,
    containerRef,
    handleDatesChange,
    handleFocusChange,
    datesRestrictions,
  } = props;

  const formikProps: FormikContextType<PartialFromProps> = useFormikContext();
  const { values } = formikProps;

  const inputToFocus = focusedInput !== '' ? focusedInput : START_DATE;

  const containerWidth = containerRef.current?.offsetWidth;
  const daySize = containerWidth ? Math.trunc(containerWidth / 7) : 35;

  const vars: any = {
    '--line-height': `${daySize - 5}px`,
    '--font-size': `${daySize - daySize / 5}px`,
  };

  const maxDate = datesRestrictions ? moment.utc(datesRestrictions.latestDate) : null;

  const startDateMoment = moment.isMoment(values.startDate)
    ? values.startDate
    : values.startDate
    ? moment(values.startDate)
    : null;
  const endDateMoment = moment.isMoment(values.endDate)
    ? values.endDate
    : values.endDate
    ? moment(values.endDate)
    : null;

  const renderNavButton = (pos = 'prev') => (
    <button className={bemDateRange('navButton', { pos })}>
      <Icon name="arrow-left" />
    </button>
  );

  const renderDayContents = day => <div className={bemDateRange('day', { sameDay: isSameDay })}>{day.date()}</div>;

  return (
    <div className={cx(bem('calendar'), bemDateRange('calendar'))} style={vars}>
      <DayPickerRangeController
        onFocusChange={handleFocusChange}
        startDate={startDateMoment}
        endDate={endDateMoment}
        minimumNights={0}
        daySize={daySize}
        minDate={moment()}
        navPrev={renderNavButton()}
        focusedInput={inputToFocus}
        navNext={renderNavButton('next')}
        renderDayContents={renderDayContents}
        onDatesChange={handleDatesChange}
        enableOutsideDays={false}
        isOutsideRange={isOutsideRange(moment(), maxDate)}
        hideKeyboardShortcutsPanel
      />
    </div>
  );
};
