import React, { Fragment, useEffect } from 'react';

import { Icon } from '@atoms/Icon';
import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';

import { usePolicyItemLogic } from '../usePolicyItemLogic';
import { useTermsOfServicesKiosk } from '../useTermsOfServicesKiosk';
import './PolicyItem.scss';
import { PolicyItemProps } from './types';

const bem = cn('rti-policy-item');

const ben = cn('rti-trip-details-item');

export const PolicyItem = (props: PolicyItemProps) => {
  const { title, description, images, policyImage, bulletpoints, contentReference } = props;

  const { termsOfService, fetchXFContent } = useTermsOfServicesKiosk();
  const { isPolicyFareAvailable, isPolicyOdAvailable, replaceDescription } = usePolicyItemLogic(props);
  const { outboundStation, inboundStation, converBulletpoints, classTypeTitle } = usePolicyItemLogic(props);

  useEffect(() => {
    if (fetchXFContent && title === 'Brightline Terms of Service') fetchXFContent();
  }, [fetchXFContent, title]);

  if (!isPolicyFareAvailable || !isPolicyOdAvailable) return <></>;

  return (
    <>
      <div className={bem('divider')} />
      <div className={bem('policy-item', { isTermsOfService: title.includes('Brightline Terms of Service') })}>
        <div className={bem('icon')}>
          <Image src={policyImage?.src} />
        </div>
        <div className={bem()}>
          <div className={bem('title')}>{title}</div>
          {title === 'Brightline Terms of Service' && isKiosk() ? (
            termsOfService(description)
          ) : (
            <Text className={bem('description')} text={replaceDescription(description, bulletpoints)} />
          )}
          {contentReference &&
            [outboundStation, inboundStation]?.map((route, index) => {
              const fareContent = contentReference.find(item => item.fareType == route?.productId);

              if (!fareContent) return <></>;

              return (
                <Fragment key={index}>
                  <div className={bem('sub-title')}>
                    <div className={ben(fareContent?.fareType?.toLocaleLowerCase())}>
                      {classTypeTitle(fareContent?.fareType)}
                    </div>
                    <div className={bem('stations')}>
                      <span>{route?.origin?.abbreviation}</span>
                      <Icon name="arrow-from-to" />
                      <span>{route?.destination?.abbreviation}</span>
                    </div>
                  </div>
                  <Text className={bem('description')} text={converBulletpoints(fareContent.bulletpoints)} />
                </Fragment>
              );
            })}
          {!!images?.length && (
            <ul className={bem('images')}>
              {images.map(item => (
                <li key={item?.title}>
                  <Image {...item.image} alt={`${item?.title}-image`} />
                  <span>{item?.title}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
