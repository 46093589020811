import React, { useId, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import './Dropdown.scss';
import DropdownItem from './DropdownItem';
import { DropdownProps } from './types';
import { useDropdownHandlers } from './useDropdownHandlers';

const bem = cn('dropdown');

const Dropdown = (props: DropdownProps) => {
  const generatedId = useId();
  const { colors, onSelect, ariaLabel } = props;

  const hookProps = useDropdownHandlers(props);
  const { handleKeyDown, handleItemClick, handleKeyDownHeader, selectedItem } = hookProps;
  const { expanded, setExpanded, focus, itemsWithoutSelected } = hookProps;

  const style: any = useMemo(
    () => ({
      '--dropdown-text-color': colors?.text?.inactive,
      '--dropdown-text-hover-color': colors?.text?.hover,
      '--dropdown-text-focus-color': colors?.text?.focus,
      '--dropdown-background-color': colors?.background?.inactive,
      '--dropdown-background-hover-color': colors?.background?.hover,
      '--dropdown-background-focus-color': colors?.background?.focus,
      '--dropdown-border-hover-color': colors?.border?.hover,
      '--dropdown-border-focus-color': colors?.border?.focus,
    }),
    [colors]
  );

  const handleCloseSubmenu = () => {
    setExpanded(false);
  };

  const handleToggleSubmenu = () => () => {
    setExpanded(!expanded);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleCloseSubmenu}>
      <div className={bem({ open: expanded })} style={style}>
        <div className={bem('item-wrap')}>
          <DropdownItem
            role="combobox"
            id={generatedId}
            ariaExpanded={expanded}
            ariaHaspopup={expanded}
            ariaControls={`${generatedId}-dropdown-list`}
            ariaLabel={ariaLabel}
            focus={focus?.index === -1}
            onItemClick={handleToggleSubmenu}
            onKeyDown={handleKeyDownHeader}
            item={selectedItem}
            icon={<Icon name={expanded ? 'arrow-up' : 'arrow-down'} />}
          />
        </div>
        {!!itemsWithoutSelected?.length && (
          <ul id={`${generatedId}-dropdown-list`} className={bem('menu')} role="listbox">
            {itemsWithoutSelected?.map((item, index) => (
              <li
                key={`${generatedId}-dropdown-item-${item.value}`}
                role="option"
                id={`option-${index}`}
                aria-selected={focus?.index === index}
              >
                <DropdownItem
                  onItemClick={handleItemClick}
                  onKeyDown={handleKeyDown}
                  onSelect={onSelect}
                  item={item}
                  focus={focus?.index === index}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
