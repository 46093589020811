import React, { useEffect, useMemo, useRef } from 'react';

import '@molecules/TicketsForm/components/OriginAndDestination/Select/LocationItem/LocationItem.scss';

import { cn, cx } from 'utils/classNames';

import { LocationItemType } from './type';

const bemFont = cn('font');
const bem = cn('location-item');

const LocationItem = (props: LocationItemType) => {
  const { option, itemIndex, handleItemClick, focus, locationHint, selected } = props;
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focus && ref.current) ref?.current?.focus();
  }, [focus]);

  const shouldRenderLocationHint = useMemo(
    () => option.distanceFromCurrentLocation && itemIndex === 0,
    [option, itemIndex]
  );

  return (
    <span
      ref={ref}
      key={option.name}
      onClick={handleItemClick(option, itemIndex)}
      className={bem({ selected: selected })}
      tabIndex={0}
    >
      <div className={bem('description')}>
        <div className={cx(bem('label'), bemFont({ variant: 'body-large-500' }))}>{option.name}</div>
        {!!shouldRenderLocationHint && (
          <span className={cx(bem('info'), bemFont({ variant: 'tiny-500' }))}>{locationHint}</span>
        )}
      </div>
      {!!option.distanceFromCurrentLocation && (
        <div
          className={cx(bem('units'), bemFont({ variant: 'tiny-500' }))}
        >{`${option.distanceFromCurrentLocation} mi`}</div>
      )}
    </span>
  );
};

export default LocationItem;
