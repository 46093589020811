const useDailyAndParkingPassesLogic = () => {
  const handleCapitalizeParkingPasses = (
    value: string,
    fieldName: string,
    setFieldValue: (field: string, value: string) => void
  ) => {
    if (!setFieldValue) return;
    const trimmedValue = value.replace(/\s/g, '');
    if (!value) setFieldValue(fieldName, '');
    else setFieldValue(fieldName, trimmedValue.toUpperCase());
  };

  return handleCapitalizeParkingPasses;
};

export default useDailyAndParkingPassesLogic;
