import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { Navigate, useLocation } from 'react-router-dom';
import { rootStore, useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Text } from '@atoms/Text';
import { Title } from '@atoms/Title';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { getAuth0Domain } from 'utils/metas';

const bem = cn('account-registration');

export const FinalPage = observer(() => {
  const location = useLocation();
  const { cfStore } = useStores();
  const { setupConfirmation } = cfStore.account.registration;
  const isMobile = useMediaPredicate(mediaQueryList.maxMobile);
  const [loadingButton, setLoadingButton] = useState(false);

  const resumeAuth0Flow = () => {
    setLoadingButton(true);
    const authDomain = getAuth0Domain();
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: 'Continue authentication',
        customLink: `https://${authDomain}/continue?state=${location.state}`,
        externalLink: `https://${authDomain}/continue?state=${location.state}`,
        linkType: 'linkClick',
      },
    });

    window.location.href = `https://${authDomain}/continue?state=${location.state}`;
  };

  if (!location.state) return <Navigate to="/" />;

  return (
    <div className={bem('done')}>
      <img
        src={isMobile ? setupConfirmation.xsImage.src : setupConfirmation.lgImage.src}
        alt={setupConfirmation.title}
        className={bem('confirmation-image')}
      />
      <div className={bem('form')}>
        <div className={bem('last-step')}>
          <Title size="h3" text={setupConfirmation.title} />
          <Text text={setupConfirmation.text} className={bem('subheading')} />
        </div>
        <Button
          label={setupConfirmation.buttonLabel}
          onClick={resumeAuth0Flow}
          loading={loadingButton}
          color="yellow"
          width="full"
        />
      </div>
    </div>
  );
});
