import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { isLoyaltyEnabled } from 'utils/metas';

import { RewardsPointsDesktopAndMobile } from './RewardsPointsDesktopAndMobile';
import { RewardsPointsTablet } from './RewardsPointsTablet';
import { RewardsPointsProps } from './types';

export const RewardsPoints = observer((props: RewardsPointsProps) => {
  const { totalToBePaid, isModification, isPasses } = props;

  const { bookingStore, passesStore } = useStores();
  const { loyaltyRewardsPoints, fetchEstimatedLoyaltyRewardsPoints } = bookingStore.rti;
  const displayLoyaltyComponentsInRTI = bookingStore.rti.displayLoyaltyComponents;
  const displayLoyaltyComponentsInPasses = passesStore.checkout.displayLoyaltyComponents;

  const loyaltyEnabled = isLoyaltyEnabled();

  useEffect(() => {
    if (!loyaltyEnabled || isModification) return;

    fetchEstimatedLoyaltyRewardsPoints(totalToBePaid || 0);
  }, [totalToBePaid, fetchEstimatedLoyaltyRewardsPoints, loyaltyRewardsPoints, isModification, loyaltyEnabled]);

  const isTablet = useMediaPredicate('(min-width: 576px) and (max-width: 1024px)');

  if ((!isPasses && !displayLoyaltyComponentsInRTI) || (isPasses && !displayLoyaltyComponentsInPasses)) return null;

  return isTablet ? (
    <RewardsPointsTablet {...props} rewardsPoints={loyaltyRewardsPoints} />
  ) : (
    <RewardsPointsDesktopAndMobile {...props} rewardsPoints={loyaltyRewardsPoints} />
  );
});
