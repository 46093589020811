import moment from 'moment';
import React from 'react';

import { Icon } from '@atoms/Icon';
import { Image } from '@atoms/Image';

import { cn } from 'utils/classNames';

import './EventsCard.scss';
import { EventPills } from './components/EventPills';

const bem = cn('events-card');

export const EventsCard = ({ event }: any) => {
  const formatDate = ({ year, month, dayOfMonth, hourOfDay, minute, second = 0 }) => {
    const formattedDate = moment({ year, month: month, date: dayOfMonth, hour: hourOfDay, minute, second });

    return formattedDate.format('ddd, MMM D · h:mm A');
  };

  const formatDateForDifferentDays = ({ year, month, dayOfMonth }) => {
    const formattedDate = moment({ year, month: month, date: dayOfMonth });

    return formattedDate.format('ddd, MMM D');
  };

  const compareStartDateAndEndDate = (eventDateAndTime, eventEndDate) =>
    eventDateAndTime.year !== eventEndDate.year ||
    eventDateAndTime.month !== eventEndDate.month ||
    eventDateAndTime.dayOfMonth !== eventEndDate.dayOfMonth;

  const areDatesDifferent = compareStartDateAndEndDate(event.eventDateAndTime, event.eventEndDate);

  return (
    <a href={event.offersUrl} style={{ textDecoration: 'none', color: 'inherit' }}>
      <div className={bem('event-cards-items')}>
        {event.image && (
          <div className="image-container" style={{ position: 'relative' }}>
            <Image
              {...event.image}
              className={bem('image')}
              alt={event.image.alt || event.image.title || 'Event image'}
            />
            {event.image && event.image.src && event.eventPills && event.eventPills.length > 0 && (
              <div className={bem('event-pills')}>
                {event.eventPills.map((pill, pillIndex) => (
                  <EventPills
                    key={pillIndex}
                    backgroundColor={pill.backgroundColor}
                    labelColor={pill.labelColor}
                    label={pill.label}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        <div className={bem('content')}>
          <span
            className={bem('distanceFromStation')}
            style={{ backgroundColor: event.distanceFromStationBackgroundColor }}
          >
            {event.distanceFromStationIcon && (
              <span>
                <Icon name={event.distanceFromStationIcon} />
              </span>
            )}
            {event.distanceFromStationLabel && (
              <h5 className={bem('distanceFromStationLabel')} style={{ color: event.distanceFromStationTextColor }}>
                {event.distanceFromStationLabel}
              </h5>
            )}
          </span>
          {event.eventName && (
            <h2 className={bem('title')} style={{ textAlign: event.eventNameAlignment, color: event.eventNameColor }}>
              {event.eventName}
            </h2>
          )}
          {event.eventDateAndTime && !event.hideDateAndTime && (
            <h4
              className={bem('dateAndTime')}
              style={{ textAlign: event.eventDateAndTimeAlignment, color: event.eventDateAndTimeColor }}
            >
              {areDatesDifferent
                ? `${formatDateForDifferentDays(event.eventDateAndTime)} - ${formatDateForDifferentDays(
                    event.eventEndDate
                  )}`
                : formatDate(event.eventDateAndTime)}
            </h4>
          )}
          {event.locationName && (
            <span
              className={bem('location')}
              style={{ textAlign: event.locationNameAlignment, color: event.locationNameColor }}
            >
              {event.locationName}
            </span>
          )}
        </div>
      </div>
    </a>
  );
};
