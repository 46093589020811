import React from 'react';

import { cn } from 'utils/classNames';

import './LegendItem.scss';

const bem = cn('seat-legend-item');
export const LegendItem = ({ color, label }: any) => (
  <div className={bem({ color })}>
    <div className={bem('color')} />
    <div className={bem('label')}> {label}</div>
  </div>
);
