export const DATE_FORMAT = 'ddd, MMM D';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const DEFAULT_I18N = {
  startLabel: 'Departure Date',
  startPlaceholder: 'Enter Departure Date',
  endLabel: 'Return Date',
  endPlaceholder: 'Enter Return Date',
};
