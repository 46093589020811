import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { dateConfig, getFormValidationSchema, initialFormValues } from '@account/routes/ContinuePage/constants';
import ErrorContent from '@account/routes/ErrorPage/ErrorContent';
import Button from '@atoms/Button/Button';
import Checkbox from '@atoms/Checkbox/Checkbox';
import { InputField } from '@atoms/Input/InputField';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';
import { InlineDateInputField } from '@molecules/InlineDateInput/InlineDateInputField';
import PhoneNumber from '@molecules/PhoneNumber/PhoneNumber';
import { LoyaltyOptIn } from '@organisms/LoaltyOptInModal/components/LoyaltyOptIn';

import { cn } from 'utils/classNames';

import { useAccountFlowLogic } from './useAccountFlowLogic';

const bem = cn('account-registration');

export const ContinuePage = observer(() => {
  const hookProps = useAccountFlowLogic();
  const { error, handleSubmit, currentStep, preventRendering, setupName, checkboxOnChange, consents } = hookProps;
  const { loyaltyEnabled, handleLoyaltyButtonsClick, loyaltyButtonState } = hookProps;
  const { loadingButton, setupPhone, setupLoyalty, setupErrors, networkError } = hookProps;

  if (preventRendering) return null;

  const renderError = (error, networkError, formErrors) => {
    const errorMessage = Object.entries(formErrors).length ? setupErrors.dataError : error || networkError;

    return (
      errorMessage && (
        <ErrorContent className={bem('error')} error={errorMessage} color="#c00" title={setupErrors.title} />
      )
    );
  };

  return (
    <>
      {currentStep < 3 && (
        <>
          <Title size="h3" text={setupPhone.title} />
          <div className={bem('subheading')}>{setupPhone.subTitle}</div>
        </>
      )}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialFormValues}
        validationSchema={getFormValidationSchema(setupErrors)[currentStep - 1]}
      >
        {({ errors, handleSubmit: formikSubmit, submitCount }) => (
          <form onSubmit={formikSubmit} className={bem('form', { currentStep })}>
            {currentStep === 1 && (
              <>
                <div className={bem('phone-wrapper')}>
                  <PhoneNumber
                    name="phoneNumber"
                    helperText={setupPhone.phoneLabel}
                    label={setupPhone.phonePlaceholder}
                    searchCountryCodePlaceholder={setupPhone.searchCountryCodePlaceholder}
                  />
                </div>

                <div className={bem('disclaimers')}>
                  <Checkbox
                    labelClassName="blte-font--variant-body-medium-400"
                    id="automatedTextsConsent"
                    name="automatedTextsConsent"
                    label={setupPhone.automatedTextsConsent}
                    isChecked={consents.automatedTextsConsent}
                    onChange={checkboxOnChange}
                  />
                  <Checkbox
                    labelClassName="blte-font--variant-body-medium-400"
                    id="exclusivesConsent"
                    name="exclusivesConsent"
                    label={setupPhone.termsOfServiceConsent}
                    isChecked={consents.exclusivesConsent}
                    onChange={checkboxOnChange}
                  />
                  <div className={bem('footer')}>
                    {submitCount > 0 && renderError(error, networkError, errors)}
                    <Button type="submit" label={setupPhone.buttonLabel} color="yellow" width="full" />
                  </div>
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <div className={bem('names')}>
                  <InputField type="text" name="firstName" label={setupName.firstName} errorVariant="inline" />
                  <InputField type="text" name="lastName" label={setupName.lastName} errorVariant="inline" />
                  {loyaltyEnabled && (
                    <InputField type="text" name="zipCode" label={setupName.zipCode} errorVariant="inline" />
                  )}
                  <InlineDateInputField
                    errorVariant="inline"
                    dateFormat={dateConfig.dateFormat}
                    label={dateConfig.dateFormat}
                    name="dateOfBirth"
                    helperText={setupName.note}
                  />
                </div>
                <div className={bem('footer')}>
                  {submitCount > 1 && renderError(error, networkError, errors)}
                  <Text text={setupName.tosLabel} className={bem('footer-info')} />
                  <Button
                    type="submit"
                    label={loyaltyEnabled ? setupName.continueButtonLabel : setupName.buttonLabel}
                    loading={loadingButton}
                    color="yellow"
                    width="full"
                  />
                </div>
              </>
            )}
            {loyaltyEnabled && currentStep === 3 && (
              <LoyaltyOptIn
                {...setupLoyalty}
                joinButtonLoading={loyaltyButtonState}
                renderError={submitCount > 2 && renderError(error, networkError, errors)}
                modalTitle={undefined}
                handleJoinButtonClicked={() => handleLoyaltyButtonsClick(true)}
                handleDeclineButtonClicked={() => handleLoyaltyButtonsClick(false)}
              />
            )}
          </form>
        )}
      </Formik>
    </>
  );
});
