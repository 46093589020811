import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Text } from '@atoms/Text';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './FareCompareModal.scss';
import { FareCompareModalProps } from './types';
import { useFareCompareModalLogic } from './useFareCompareModalLogic';

const bem = cn('fare-compare-modal');

const FareCompareModal = (props: FareCompareModalProps) => {
  const { title, sections, defaultVisible, id } = props;
  const { visible, hideModal, replaceText } = useFareCompareModalLogic(defaultVisible);

  return (
    <CSSTransition in={visible} classNames="fade" timeout={500} unmountOnExit>
      <Modal title={title} onClose={hideModal} ariaLabel="class options">
        <div id={id} className={bem()}>
          {sections.map(section => (
            <div key={section.title} className={bem('section', { variant: section.variant })}>
              <div className={bem('header')}>
                <h2 className={bem('title')}>{section.title}</h2>
                <h3 className={bem('subtitle')}>{section.subtitle}</h3>
              </div>
              <Text text={replaceText(section.description)} className={bem('body')} as="div" />
            </div>
          ))}
        </div>
      </Modal>
    </CSSTransition>
  );
};

export default FareCompareModal;
