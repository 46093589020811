import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { PriceItemProps } from '../../../types';

const bem = cn('price-item-mobile');

export const MobileLayout = observer((props: PriceItemProps) => {
  const { defaultProduct, product } = props;
  const { displayedPrice, seatingClassType, fares } = product;

  const { bookingStore, cfStore } = useStores();
  const { trainSelection, generic } = cfStore;
  const { routeItem } = trainSelection;
  const { modifyTripProps, premiumOnly } = bookingStore.tickets;

  const isPremiumSelected = product.id === 'PREMIUM_STANDARD' || premiumOnly;

  const priceLabel = useMemo(() => {
    if (modifyTripProps) {
      const pricePrefix = (displayedPrice || 0) < 0 ? '-' : '+';

      return {
        priceLabel: `${pricePrefix}$${Math.abs(displayedPrice || 0).toFixed(2)}`,
      };
    }

    return {
      priceLabel: `$${displayedPrice?.toFixed(2)}`,
      originalPriceLabel: `$${fares?.[0]?.originalPrice?.toFixed(2)}`,
      hasOriginalPrice: displayedPrice !== fares?.[0]?.originalPrice,
    };
  }, [modifyTripProps, displayedPrice, fares]);

  if (!defaultProduct?.productId)
    return (
      <div className={bem({ unavailable: !defaultProduct?.productId })}>
        <div className={bem('no-seats')}>{defaultProduct?.unavailableMessage}</div>
      </div>
    );

  return (
    <div className={bem({ class: seatingClassType })}>
      <span>{routeItem.from}</span>
      <div className={bem('price')}>{priceLabel.priceLabel}</div>
      {priceLabel?.hasOriginalPrice && (
        <div className={bem('originalPrice', { isPremiumSelected })}>{priceLabel?.originalPriceLabel}</div>
      )}
      <span>{generic.perPassengerLong}</span>
    </div>
  );
});
