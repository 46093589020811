import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { RectShape, RoundShape } from 'react-placeholder/lib/placeholders';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './SavedCardItem.scss';

const bem = cn('passes-card-item');

export const SavedCardItemPlaceholder = observer(() => (
  <ReactPlaceholder
    ready={false}
    customPlaceholder={
      <div className={bem({ placeholder: true })}>
        <RectShape color={color_base_medium_light_grey} style={{ width: 38, height: 24, borderRadius: 8 }} />
        <div className={bem('card-details')}>
          <RectShape color={color_base_medium_light_grey} style={{ width: 70, height: 26, borderRadius: 8 }} />
          <RoundShape color={color_base_medium_light_grey} style={{ width: 24, height: 24 }} />
        </div>
      </div>
    }
    showLoadingAnimation
  >
    <div />
  </ReactPlaceholder>
));
