import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { rootStore, useStores } from 'stores';

import { useCMSContent } from '@account/hooks/useCMSContent';
import { useLogin } from '@account/hooks/useLogin';
import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import { InfoModal } from '@account/routes/TripDetails/components/InfoModal';
import NoModificationAllowedModal from '@account/routes/TripDetails/components/NoExtrasModificationModal/NoModificationAllowedModal';
import MerchandisingBanner from '@atoms/MerchandisingBanner/MerchandisingBanner';
import XFContainerHandler from '@organisms/XFContainer/XFContainerHandler';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { isDelayExperienceEnabled } from 'utils/metas';
import { fetchXfHTML } from 'utils/xfUtils';

import { LayoutWithSidebar } from '../../components/LayoutWithSidebar';
import './TripDetails.scss';
import { CardTitle } from './components/CardTitle';
import { ErrorView } from './components/ErrorView';
import { Extras } from './components/Extras';
import { HeaderInfo } from './components/HeaderInfo';
import { Navigation } from './components/Navigation';
import { NavigationDrawer } from './components/NavigationDrawer';
import { Passengers } from './components/Passengers';
import { Trips } from './components/Trips';

const bem = cn('trip-details');

const TripDetails = observer(() => {
  useCMSContent('tripDetails');

  const { accountStore, cfStore, authStore } = useStores();
  const { tripDetails } = accountStore;
  const { isAuthenticated } = authStore;
  const { fetchUserProfile, userProfile } = accountStore.profile;
  const { fetchTripData, resetTripData, hasError, setError, shouldFetchTripDetails } = tripDetails;
  const { noModificationAllowedModalOpen, setNoModificationAllowedModalClose, isExtrasInfoModalOpen } = tripDetails;
  const { setExtrasInfoModalClose, setShouldFetchTripDetails } = tripDetails;
  const {
    travelAgentModalCancelCta,
    bottomExperienceFragment,
    mobilityTitle,
    merchandisingBannerText,
    merchandisingCtaText,
    merchandisingCtaLink,
    ctaOpenInNewTab,
    ctaOpenInNewWindow,
    ctaBackgroundColor,
    merchandisingBannerBackgroundColor,
  } = cfStore.account.tripDetails;

  const [bottomExperienceFragmentHTML, setBottomExperienceFragmentHTML] = useState('');

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const { isPublicPage, rn, fn, ln } = useTripDetailsConfig();

  useLogin(false, isPublicPage);

  useEffect(() => {
    if (!shouldFetchTripDetails) return undefined;

    document.body.classList.remove('hasHiddenSidebar');
    if (rn) fetchTripData(rn, fn, ln);
    else setError(true);

    setShouldFetchTripDetails(false);

    return resetTripData;
  }, [fetchTripData, resetTripData, setError, rn, fn, ln, shouldFetchTripDetails, setShouldFetchTripDetails]);

  useEffect(() => {
    if (isAuthenticated && !userProfile) fetchUserProfile();
  }, [userProfile, fetchUserProfile, isAuthenticated]);

  useEffect(() => {
    const fetchXfs = async () => {
      bottomExperienceFragment && setBottomExperienceFragmentHTML(await fetchXfHTML(bottomExperienceFragment));
    };

    fetchXfs();
  }, [bottomExperienceFragment]);

  const handleCancelTravelAgentModal = () => {
    setNoModificationAllowedModalClose();
  };

  const handleMerchandisingBannerCtaClick = () => {
    window.open(merchandisingCtaLink, '_blank', 'width=800,height=600');
  };

  const checkAndSendAnalyticsEvent = () => {
    const hasClicked = localStorage.getItem('confirmationClick');
    const refNr = localStorage.getItem('confirmationRefNr');

    if (hasClicked && refNr) {
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: 'Account',
          navigationLinkName: refNr,
          customLink: window.location.href,
          externalLink: '',
          linkType: 'linkClickLoyalty',
        },
      });

      localStorage.removeItem('confirmationClick');
      localStorage.removeItem('confirmationRefNr');
    }
  };

  useEffect(() => {
    checkAndSendAnalyticsEvent();
  }, []);

  return (
    <div className={bem({ hasError })}>
      {/* Calculate header height without delay experience banners in account */}
      {isDelayExperienceEnabled() && <XFContainerHandler scope="header" />}
      {hasError && <ErrorView />}
      {!hasError && (
        <>
          {isExtrasInfoModalOpen && <InfoModal buttonAction={setExtrasInfoModalClose} />}
          {noModificationAllowedModalOpen && (
            <NoModificationAllowedModal
              buttonLabel={travelAgentModalCancelCta}
              buttonAction={handleCancelTravelAgentModal}
            />
          )}
          <LayoutWithSidebar>
            <div>
              <Navigation />
            </div>
            <div>
              <HeaderInfo />
              {merchandisingBannerText && (
                <MerchandisingBanner
                  bannerText={merchandisingBannerText}
                  buttonText={merchandisingCtaText}
                  buttonBackgroundColor={ctaBackgroundColor}
                  bannerBackgroundColor={merchandisingBannerBackgroundColor}
                  link={{ url: merchandisingCtaLink, target: ctaOpenInNewTab ? '_blank' : undefined }}
                  {...(ctaOpenInNewWindow && merchandisingCtaLink && { onClick: handleMerchandisingBannerCtaClick })}
                />
              )}
              <Trips />
              <Passengers />
              <Extras />
              {(mobilityTitle || bottomExperienceFragmentHTML) && (
                <div className={bem('mobility')}>
                  {mobilityTitle && <CardTitle title={mobilityTitle} noMargin />}
                  {bottomExperienceFragmentHTML && (
                    <div
                      dangerouslySetInnerHTML={{ __html: bottomExperienceFragmentHTML }}
                      className={bem('bottomExperienceFragmentHTML')}
                    />
                  )}
                </div>
              )}
            </div>
          </LayoutWithSidebar>
          {isMobile && <NavigationDrawer />}
        </>
      )}
    </div>
  );
});

export default TripDetails;
