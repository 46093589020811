import { useMemo, useState } from 'react';

import { ExtrasModalLogicProps } from '@booking/routes/RTI/Extras/components/ExtrasModal/Modal/components/Item/types';

export const useExtrasModalItemLogic = (props: ExtrasModalLogicProps) => {
  const { itemCard, itemCardIndex } = props;

  const { cfLabels, isUnvailable, passengers } = itemCard;

  const { headerTitle, fromLabel, addedLabel, applyToEntireTripLabel, soldOutLabel } = cfLabels;
  const { notAvailableLabel } = cfLabels;

  const [expanded, setExpanded] = useState(false);

  const handleHeaderClick = () => {
    if (isUnvailable) return;
    setExpanded(val => !val);
  };

  const config = useMemo(() => {
    const fromPrice = Object.values(passengers).reduce((acc, curr) => {
      const itemsArr = [
        ...curr.inboundItems.slice(1).map(item => item.price),
        ...curr.outboundItems.slice(1).map(item => item.price),
      ];

      const passengerMin = Math.min(...itemsArr);

      return passengerMin < acc ? passengerMin : acc;
    }, Number.MAX_SAFE_INTEGER);

    return {
      title: headerTitle,
      soldOut: soldOutLabel,
      notAvailable: notAvailableLabel,
      from: fromLabel,
      added: addedLabel,
      applyToEntireTrip: applyToEntireTripLabel,
      checkboxName: `applyAll_${itemCardIndex}`,
      fromPrice: fromPrice !== Number.MAX_SAFE_INTEGER ? fromPrice : 0,
    };
  }, [
    soldOutLabel,
    headerTitle,
    fromLabel,
    addedLabel,
    applyToEntireTripLabel,
    notAvailableLabel,
    passengers,
    itemCardIndex,
  ]);

  return {
    expanded,
    config,
    handleHeaderClick,
  };
};
