import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';
import { mediaQueryList } from 'utils/mediaQueries';

import { CostSummaryPlaceholderMobile } from '../../../CostSummary/CostSummaryPlaceholder.mobile';
import './PaymentForm.scss';

const bem = cn('passes-payment-form');

export const PaymentFormPlaceholder = observer(() => {
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  return (
    <ReactPlaceholder
      ready={false}
      customPlaceholder={
        <div className={bem('form')}>
          <div className={bem('sections-wrapper')}>
            <div className={bem('section')}>
              <div className={bem('section-header', { cards: true })}>
                <RectShape color={color_base_medium_light_grey} style={{ width: 75, height: 28, borderRadius: 8 }} />

                <span className={bem('cards')}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: 38, height: 24, margin: 0, borderRadius: 8 }}
                  />
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: 38, height: 24, margin: 0, borderRadius: 8 }}
                  />
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: 38, height: 24, margin: 0, borderRadius: 8 }}
                  />
                </span>
              </div>
              <div className={bem('row')}>
                <div className={bem('input')}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: '100%', height: 48, borderRadius: 8 }}
                  />
                </div>
              </div>

              <div className={bem('row')}>
                <div className={bem('input')}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: '100%', height: 48, borderRadius: 8 }}
                  />
                </div>
              </div>

              <div className={bem('row', { lastCardRow: true })}>
                <div className={bem('input', { lastRow: true })}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: '100%', height: 48, borderRadius: 8 }}
                  />
                </div>
                <div className={bem('input', { lastRow: true })}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: '100%', height: 48, borderRadius: 8 }}
                  />
                </div>
              </div>
            </div>
            <div className={bem('section')}>
              <div className={bem('section-header')}>
                <RectShape color={color_base_medium_light_grey} style={{ width: 75, height: 26, borderRadius: 8 }} />
              </div>

              <div className={bem('row')}>
                <div className={bem('input')}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: '100%', height: 48, borderRadius: 8 }}
                  />
                </div>
                <div className={bem('input')}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: '100%', height: 48, borderRadius: 8 }}
                  />
                </div>
              </div>
              <div className={bem('row')}>
                <div className={bem('input')}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: '100%', height: 48, borderRadius: 8 }}
                  />
                </div>
              </div>
              <div className={bem('row')}>
                <div className={bem('input')}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: '100%', height: 48, borderRadius: 8 }}
                  />
                </div>
                <div className={bem('input')}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: '100%', height: 48, borderRadius: 8 }}
                  />
                </div>
              </div>
              <div className={bem('row')}>
                <div className={bem('input')}>
                  <RectShape color={color_base_medium_light_grey} style={{ width: 150, height: 24, borderRadius: 8 }} />
                </div>
              </div>
            </div>
          </div>
          {isMobile && (
            <>
              <div className={bem('separator')} />
              <CostSummaryPlaceholderMobile />
            </>
          )}
          <div className={bem('separator')} />
          <div className={bem('footer')}>
            <div className={bem('tos')}>
              <RectShape
                color={color_base_medium_light_grey}
                style={{ width: 250, height: 22, borderRadius: 8, marginBottom: 2 }}
              />
              <RectShape
                color={color_base_medium_light_grey}
                style={{ width: 250, height: 22, borderRadius: 8, marginTop: 2 }}
              />
            </div>
            <RectShape
              color={color_base_medium_light_grey}
              style={{ width: '100%', height: 42, borderRadius: 8, marginRight: 0 }}
            />
          </div>
        </div>
      }
      showLoadingAnimation
    >
      <div />
    </ReactPlaceholder>
  );
});
