import React from 'react';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';

import { cn } from 'utils/classNames';

import { useCancelTripLogic } from '../../Navigation/CancelTrip/useCancelTripLogic';
import { ConfirmCancellationProps } from '../types';
import './ConfirmCancellation.scss';

const bem = cn('confirm-cancellation');

export const ConfirmCancellation = (props: ConfirmCancellationProps) => {
  const { className } = props;
  const {
    accountStore,
    cfStore: { account },
  } = useStores();
  const { tripDetails } = accountStore;
  const {
    tripDetails: { navigation },
  } = account;
  const { setShowConfirmationModal } = tripDetails;

  const { onRevert } = useCancelTripLogic();

  return (
    <div className={bem(className || '')}>
      <Button
        label={navigation?.cancelTrip?.confirmButtonLabel}
        onClick={() => setShowConfirmationModal(true)}
        type="button"
      />
      <Button
        label={navigation?.cancelTrip?.neverMindButtonLabel}
        onClick={onRevert}
        variant="secondary"
        colors={{ backgroundOrBorder: 'black' }}
      />
    </div>
  );
};
