import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { WidgetInput } from '@atoms/WidgetInput/WidgetInput';
import '@molecules/TicketsForm/components/OriginAndDestination/Select/Desktop/DesktopSelect.scss';

import { cn, cx } from 'utils/classNames';

import LocationItem from '../LocationItem/LocationItem';
import { DesktopSelectProps } from '../types';

const bem = cn('od-desktop-select');
const bemField = cn('od-select');

const DesktopSelect = (props: DesktopSelectProps) => {
  const { placeholder, locationHint, onChangeTextInput, onClearClick, textInput = '' } = props;
  const { label, error, errorMessage, onHeaderClick, selectIndex, resetActive } = props;
  const { value, items, focus, active, handleItemClick, handleKeyDown, position } = props;

  const shouldFocus = itemIndex => focus?.index === itemIndex && !focus?.preventScroll && active;
  const shouldFocusInput = active && focus?.index === -1;
  const selectedItem = items.find(item => item.id === value);
  const filteredItems = items.filter(item => item.name.toLowerCase().indexOf(textInput.toLowerCase()) > -1) || [];

  const handleResetIndex = () => {
    if (active) resetActive();
  };

  const handleHeaderClick = () => onHeaderClick(selectIndex);

  const handleKeyDownSelect = (e: React.KeyboardEvent<HTMLElement>) => {
    e.key === 'Enter' && onHeaderClick(selectIndex);
  };

  const handleClearInHeader = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClearClick && onClearClick(e);
  };

  return (
    <div className={cx(bem(), bemField())}>
      <WidgetInput
        isInvalid={error}
        errorMessage={errorMessage}
        onInputClick={handleHeaderClick}
        onClearClick={handleClearInHeader}
        onInputKeyDown={handleKeyDownSelect}
        label={label}
        placeholder={placeholder}
        value={selectedItem?.name || textInput}
        position={position}
        readOnly
        disabled
      />

      <div className={bem('list', { active: active })} onKeyDown={handleKeyDown}>
        <OutsideClickHandler onOutsideClick={handleResetIndex}>
          <WidgetInput
            label={label}
            placeholder={placeholder}
            value={selectedItem?.name || textInput}
            onInputChange={onChangeTextInput}
            onClearClick={onClearClick}
            focus={shouldFocusInput}
            position={position}
          />
          <div className={bem('listItems')}>
            {filteredItems.map((option, itemIndex) => (
              <LocationItem
                key={`loc-item-${option.id}`}
                locationHint={locationHint}
                selected={selectedItem?.id === option.id}
                focus={shouldFocus(itemIndex)}
                option={option}
                itemIndex={itemIndex}
                handleItemClick={handleItemClick}
              />
            ))}
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default DesktopSelect;
