import DOMPurify from 'dompurify';
import React, { useState } from 'react';

import { Modal } from '@molecules/Modal';
import { WifiPlashFormModalProps } from '@molecules/WifiSplashForm/types';

import './WifiSplashFormFooter.scss';

export const useWifiSplashFormModals = (modalLabels?: WifiPlashFormModalProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [xfContent, setXFContent] = useState('');

  const fetchXFContent = async () => {
    try {
      if (!modalLabels || !modalLabels.experienceFragmentPath) {
        console.error('Experience fragment path is undefined');

        return;
      }

      const response = await fetch(modalLabels?.experienceFragmentPath);

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const data = await response.text();
      setXFContent(data);
    } catch (error) {
      console.error('Error fetching Experience Fragment content:', error);
    }
  };

  const showModal = event => {
    fetchXFContent();
    setModalVisible(true);
    event.preventDefault();
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const renderModal = title => (
    <>
      {modalVisible && (
        <Modal title={title} onClose={closeModal} variant="default">
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(xfContent) }} />
        </Modal>
      )}
    </>
  );

  return {
    showModal,
    renderModal,
    fetchXFContent,
  };
};
