import React from 'react';

import { ContentHeaderPlaceholder } from '@account/components/ContentHeader/ContentHeaderPlaceholder';

import { cn } from 'utils/classNames';

import './PageLoadingPlaceholders.scss';

const bem = cn('page-placeholder');

const PageLoadingPlaceholders = () => (
  <div className={bem()}>
    <ContentHeaderPlaceholder />
    <div className={bem('list')}>
      <div className={bem('list-row')}>
        <div className={bem('list-row-content')} />
      </div>
      <div className={bem('list-row')}>
        <div className={bem('list-row-content')} />
      </div>
      <div className={bem('list-row')}>
        <div className={bem('list-row-content')} />
      </div>
    </div>
  </div>
);

export default PageLoadingPlaceholders;
