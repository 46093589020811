import React from 'react';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { FooterRightDataProps } from './types';

const bemCard = cn('pass-card');

export const FooterRightData = ({ passType, availableRides, redeemableRidesLabel }: FooterRightDataProps) => {
  if (availableRides && passType !== 'unlimited_pass')
    return (
      <>
        <div className={bemCard('footer-right-label')}>{redeemableRidesLabel}</div>
        <div className={bemCard('footer-right-data')}>{availableRides.purchased - availableRides.remaining}</div>
      </>
    );

  return <Icon name="infinite" className={bemCard('footer-right-icon')} />;
};
