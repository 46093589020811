import React from 'react';

export const InfoIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="22" height="22" rx="4" fill="#007981" />
    <circle cx="11" cy="11" r="7" fill="white" />
    <line x1="11" y1="8" x2="11" y2="11" stroke="#007981" strokeWidth="2" strokeLinecap="round" />
    <circle cx="11" cy="14" r="0.5" fill="#007981" stroke="#007981" />
  </svg>
);
