import { UpcomingTrips } from 'apps/account/routes/MyTrips/components/UpcomingTrips';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { MyAccount } from '@account/components/MyAccount';
import { useCMSContent } from '@account/hooks/useCMSContent';
import { EmptyTrips } from '@account/routes/MyTrips/components/EmptyTrips';
import { Column, Grid } from '@atoms/Grid';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { isLoyaltyEnabled } from 'utils/metas';

import './MyTrips.scss';
import { MyTripsPlaceholders } from './MyTripsPlaceholders';
import { PastTripsList } from './components/PastTripsList';

const bem = cn('my-trips');

const MyTrips = observer(() => {
  useCMSContent('trips');
  const { accountStore, cfStore } = useStores();
  const { trips } = accountStore;
  const { fetchAccountTripsData, resetTripsData } = trips;
  useCMSContent('trips');

  const { account } = cfStore;
  const { trips: i18n } = account;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const loyaltyEnabled = isLoyaltyEnabled();

  useEffect(() => {
    fetchAccountTripsData();

    return resetTripsData;
  }, [fetchAccountTripsData, resetTripsData]);

  return (
    <Grid className={bem()}>
      {loyaltyEnabled && isMobile && (
        <div className={bem('user-account')}>
          <MyAccount />
        </div>
      )}

      <Column cols={12} mq={{ desktop: { cols: 10, offset: 1 } }}>
        <div className={bem('content')}>
          {trips.isLoading ? (
            <MyTripsPlaceholders />
          ) : (
            <>
              <div className={bem('header')}>{i18n.pageTitle}</div>
              {!trips.upcoming.length && !trips.past.length && <EmptyTrips />}
              {!!trips.upcoming.length && <UpcomingTrips />}
              {!trips.upcoming.length && !!trips.past.length && <EmptyTrips variant="upcoming" />}
              {!!trips.past.length && <PastTripsList variant="short" />}
              {!!trips.upcoming.length && !trips.past.length && <EmptyTrips variant="past" />}
            </>
          )}
        </div>
      </Column>
    </Grid>
  );
});

export default MyTrips;
