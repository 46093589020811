import { useMemo } from 'react';
import * as Yup from 'yup';

const testLicensePlateNumber = value => /^[A-Z0-9.]{2,7}$/.test(value || '');

export const useValidationSchema = data =>
  useMemo(
    () =>
      Yup.object().shape({
        ppStationName: Yup.string().required('This field is required'),
        ppLicenseState: Yup.string().required('This field is required'),
        ppLicenseNumber: Yup.string()
          .required(data?.licensePlateTextFieldInvalidLabel)
          .test('ppLicenseNumber', data?.licensePlateTextFieldInvalidLabel, value => testLicensePlateNumber(value)),
      }),
    [data]
  );

export const useDailyParkingValidationSchema = data =>
  useMemo(
    () =>
      Yup.object().shape({
        drivers: Yup.array().of(
          Yup.object().shape({
            ppStationName: Yup.string().required('Station name is required'),
            ppLicenseState: Yup.string().required('License state is required'),
            ppLicenseNumber: Yup.string().when('ppStationName', {
              is: (stationName: string) => stationName !== 'Miami',
              then: Yup.string().required(data?.licensePlateTextFieldInvalidLabel),
              otherwise: Yup.string(),
            }),
          })
        ),
      }),
    [data]
  );
