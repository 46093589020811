import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import Image from '@atoms/Image/Image';
import { useParkingPassLogic } from '@organisms/ParkingPasses/useParkingPassLogic';

import { cn } from 'utils/classNames';
import { base64Encode } from 'utils/helpers';
import { mediaQueryList } from 'utils/mediaQueries';

import './MonthlyParkingPasses.scss';
import { MonthlyParkingPassesForm } from './MonthlyParkingPassesForm/MonthlyParkingPassesForm';
import { MonthlyParkingPassesProps, ParkingPassUrlParams } from './types';
import { useValidationSchema } from './useValidationSchema';

const bem = cn('monthly-parking-passes');

export const MonthlyParkingPasses = (props: MonthlyParkingPassesProps) => {
  const { primaryButton, image, colors } = props;

  const parkingPassLogic = useParkingPassLogic(props);

  const { labels, stations, fetchParkingPassOptions, initialFormValues, stationsData, passesConfig } = parkingPassLogic;
  const { loadingStations, loadingOptions, parsedUrlData, parsedMobileUrlData } = parkingPassLogic;

  const isDesktop = useMediaPredicate(mediaQueryList.maxDesktop);
  const isTabletLarge = useMediaPredicate(mediaQueryList.maxTabletLarge);
  const isTabletSmall = useMediaPredicate(mediaQueryList.maxTablet);

  const imageStyle: any = useMemo(() => {
    if (!colors?.background?.inactive) return { '--background-color': 'transparent' };

    return { '--background-color': colors.background.inactive };
  }, [colors]);

  const contentStyle: any = useMemo(() => {
    if (!colors?.background?.inactive) return { '--background-color': 'transparent' };
    if (isTabletSmall) return { '--background-color': colors.background.inactive };
    if (isTabletLarge) return { '--background-color': 'transparent' };
    if (isDesktop) return { '--background-color': colors.background.inactive };

    return { '--background-color': colors.background.inactive };
  }, [colors, isTabletSmall, isTabletLarge, isDesktop]);

  const onSubmit = values => {
    if (!passesConfig?.length) return;

    const selectedStation = stations.find(station => station.name === values.ppStationName);
    if (!selectedStation) return;

    const passParkingPassData: ParkingPassUrlParams = {
      ppStationID: selectedStation.id,
      ppStationName: values.ppStationName,
      ppTariffCode: values.autoRenew ? passesConfig[0].autoRenewProduct?.tariffCode : passesConfig[0].tariffCode,
      ppProductName: values.autoRenew ? passesConfig[0].autoRenewProduct?.name : passesConfig[0].name,
      ppLicenseState: values.ppLicenseState,
      ppLicenseNumber: values.ppLicenseNumber,
      ppPrice: passesConfig[0].price.toString(),
      totalPrice: passesConfig[0].price.toString(),
      totalPriceDiscounted: values.autoRenew
        ? passesConfig[0].autoRenewProduct?.price.toString()
        : passesConfig[0].price.toString(),
      autoRenew: values.autoRenew.toString(),
      jwt: parsedMobileUrlData?.jwt,
      mobileEmail: parsedMobileUrlData?.mobileEmail,
      mobileCallbackURL: parsedMobileUrlData?.mobileCallbackURL,
    };

    if (primaryButton.link && primaryButton.link.url) {
      const base64urlParams = base64Encode(JSON.stringify(passParkingPassData));
      const checkoutLink = new URL(primaryButton.link.url);
      checkoutLink.searchParams.append('data', base64urlParams);
      window.open(checkoutLink, primaryButton.link.target ? primaryButton.link.target : '_self');
    }
  };

  const validationSchema = useValidationSchema(labels);

  return (
    <div className={bem()}>
      <div className={bem('image')} style={imageStyle}>
        <Image {...image} className={bem('img')} />
      </div>
      <div className={bem('content')} style={contentStyle}>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          enableReinitialize
        >
          <MonthlyParkingPassesForm
            labels={labels}
            parsedUrlData={parsedUrlData}
            stations={stations}
            stationsData={stationsData}
            options={passesConfig}
            isLoading={loadingStations || loadingOptions}
            fetchParkingPassOptions={fetchParkingPassOptions}
          />
        </Formik>
      </div>
    </div>
  );
};
