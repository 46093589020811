import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { EmptyView } from '@account/components/EmptyView';
import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';

import './WalletDesktop.scss';
import { WalletPassItemDesktop } from './WalletPassItemDesktop';

const bem = cn('account-wallet-desktop');

export const WalletDesktop = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { wallet } = accountStore;
  const { passes, isLoading } = wallet;

  const { account } = cfStore;

  const { wallet: walletCF } = account;

  if (isLoading) return null;

  if (!passes.length)
    return (
      <EmptyView
        icon={walletCF.emptyState.emptyStateIcon}
        pageTitle={walletCF.pageTitle}
        title={walletCF.emptyState.desktopHeader}
        subtitle={walletCF.emptyState.desktopBodyLabel}
        actions={
          <div className={bem('empty-actions')}>
            <Button
              variant="secondary"
              color="yellow"
              size="small"
              label={walletCF.addParkingPassCtaLabel}
              link={{ url: walletCF.addParkingPassCtaLink }}
            />
            <Button
              variant="primary"
              color="yellow"
              size="small"
              label={walletCF.addTrainPassCtaLabel}
              link={{ url: walletCF.addTrainPassCtaLink }}
            />
          </div>
        }
      />
    );

  return (
    <div className={bem()}>
      <div className={bem('title')}>
        <h1 className={bem('wallet-title')}> {account.wallet.pageTitle} </h1>
      </div>
      <ul className={bem('content')}>
        {passes.map((item, index) => (
          <WalletPassItemDesktop key={`wallet-${item.passType}-${index}`} item={item} />
        ))}
      </ul>
      <div className={bem('footer')}>
        <Button
          label={walletCF.addParkingPassCtaLabel}
          link={{ url: walletCF.addParkingPassCtaLink }}
          variant="secondary"
          color="yellow"
          size="medium"
        />
        <Button
          label={walletCF.addTrainPassCtaLabel}
          link={{ url: walletCF.addTrainPassCtaLink }}
          variant="primary"
          color="yellow"
          size="medium"
        />
      </div>
    </div>
  );
});
