import { observer } from 'mobx-react-lite';
import React, { useEffect, useId, useMemo, useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { Modal } from '@molecules/Modal';

import { mediaQueryList } from 'utils/mediaQueries';

import { SeatSelectionDesktop } from './SeatSelectionDesktop';
import { SeatSelectionMobile } from './SeatSelectionMobile';
import './SeatSelectionModal.scss';
import { SeatSelectionModalProps } from './types';

export const SeatSelectionModal = observer((props: SeatSelectionModalProps) => {
  const isMobile = useMediaPredicate(mediaQueryList.maxMobile);
  const isMobileDevice = useMediaPredicate(mediaQueryList.maxTablet);
  const mapRef = useRef<HTMLDivElement>(null);

  const { handleCloseModal, isAccountFlow } = props;
  const [loaded, setLoaded] = useState(false);
  const { bookingStore, cfStore } = useStores();
  const id = useId();

  const seatsCF = isAccountFlow ? cfStore.account.tripDetails.seats : cfStore.rti.seats;

  const { seats, fillSeatsInformation, provisionalBooking } = bookingStore.rti;

  const { travelDirection, setScreenWidth, getSeats, passengersSeats } = seats;

  useEffect(() => {
    setLoaded(true);
    fillSeatsInformation();

    return () => setLoaded(false);
  }, [fillSeatsInformation]);

  useEffect(() => {
    if (loaded)
      if (mapRef && mapRef.current && isMobile) {
        const screenWidth = mapRef.current.offsetWidth - 32;
        if (screenWidth < 358) setScreenWidth(screenWidth);

        if (
          (!provisionalBooking?.tripDetails?.trip?.outboundRoute?.isCancelled &&
            provisionalBooking?.tripDetails?.trip?.outboundRouteStatus !== 'past') ||
          (travelDirection !== 'outbound' && provisionalBooking?.tripDetails?.trip?.inboundRoute)
        )
          getSeats(travelDirection, false, true);
      } else if (
        (!provisionalBooking?.tripDetails?.trip?.outboundRoute?.isCancelled &&
          provisionalBooking?.tripDetails?.trip?.outboundRouteStatus !== 'past') ||
        (travelDirection !== 'outbound' && provisionalBooking?.tripDetails?.trip?.inboundRoute)
      )
        getSeats(travelDirection, false, true);
  }, [getSeats, isMobile, loaded, setScreenWidth, travelDirection, provisionalBooking]);

  const totalCost = useMemo(() => {
    let cost = 0;
    passengersSeats.forEach(el => {
      cost += el.inboundSeat?.selectionFee?.amount || 0;
      cost += el.outboundSeat?.selectionFee?.amount || 0;
    });

    return cost;
  }, [passengersSeats]);

  const saveLabel = useMemo(
    () =>
      totalCost > 0 ? `${seatsCF.saveSelectionsCtaLabel} - $${totalCost.toFixed(2)}` : seatsCF.saveSelectionsCtaLabel,
    [seatsCF.saveSelectionsCtaLabel, totalCost]
  );

  return (
    <Modal variant="no-space" ariaLabelledby={`title-${id}`}>
      {loaded && isMobileDevice ? (
        <SeatSelectionMobile
          handleCloseModal={handleCloseModal}
          innerRef={mapRef}
          saveLabel={saveLabel}
          totalCost={totalCost}
          isAccountFlow={isAccountFlow}
        />
      ) : (
        <SeatSelectionDesktop
          handleCloseModal={handleCloseModal}
          saveLabel={saveLabel}
          id={id}
          isAccountFlow={isAccountFlow}
        />
      )}
    </Modal>
  );
});
