import React from 'react';

import { MobileSelectProps } from '@atoms/Select/types';

import { cn } from 'utils/classNames';

import './ParkingMobileSelect.scss';
import { NumberOfDaysMobileProps } from './types';

const bem = cn('parking-nativeSelect');

export const ParkingMobileSelect = (props: MobileSelectProps & NumberOfDaysMobileProps) => {
  const { name, disabled, increaseNumberOfDays, decreaseNumberOfDays, numberOfDays, numberOfDaysLabel } = props;
  const { handleHeaderClick, displayList } = props;

  return (
    <fieldset className={bem('fieldset')}>
      <legend className={bem('legend')}>{numberOfDaysLabel}Number of Days</legend>
      <button className={bem()} onClick={handleHeaderClick} name={name} disabled={disabled}>
        {numberOfDays}
      </button>
      {displayList && (
        <div className={bem('right')}>
          <button type="button" onClick={decreaseNumberOfDays} />
          <div className={bem('numberOfDays')}>
            <span>{numberOfDays}</span>
          </div>
          <button type="button" onClick={increaseNumberOfDays} />
        </div>
      )}
    </fieldset>
  );
};
