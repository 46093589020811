import React, { useMemo } from 'react';
import { useStores } from 'stores';

import AfterSalesDetailItem from '@account/routes/TripDetails/components/CancellationModal/CancellationDetail/AfterSalesDetailItem';
import Divider from '@atoms/Divider/Divider';

import { cn } from 'utils/classNames';

import './CostSummary.scss';

const bem = cn('cost-summary-content');

export const ModifyCostSummaryContent = () => {
  const { bookingStore, cfStore } = useStores();

  const { provisionalBooking } = bookingStore.rti;
  const { costSummary, bookingRules } = provisionalBooking.tripDetails;
  const { generic, rti } = cfStore;
  const { newTotalLabel, originalTotalLabel, totalDifferenceLabel, modificationFeeLabel } = rti.costSummary;
  const { totalRefundLabel, ridesReturned } = rti.costSummary;

  const originalTotal = costSummary?.bookingTotal?.total;
  const newTotal = costSummary?.bookingTotal?.totalPaid;
  const differenceTotal = costSummary?.bookingTotal?.totalToBePaid || 0;

  const modificationFee =
    (bookingRules?.outBoundRules?.additionalFeesTotal || 0) + (bookingRules?.inBoundRules?.additionalFeesTotal || 0);

  const totalLabel: string = useMemo(() => {
    if (differenceTotal === undefined) return generic.totalLabel;

    return differenceTotal < 0 ? totalRefundLabel : `${generic.totalDueLabel} (USD)`;
  }, [generic, totalRefundLabel, differenceTotal]);

  const numberOfRides = costSummary?.refundMethods.filter(item => item.type == 'travel-pass').length || 0;

  return (
    <>
      <AfterSalesDetailItem label={newTotalLabel} price={`$${originalTotal?.toFixed(2)}`} />
      <AfterSalesDetailItem label={originalTotalLabel} price={`$${newTotal?.toFixed(2)}`} />

      <Divider />
      <AfterSalesDetailItem label={totalDifferenceLabel} price={`$${Math.abs(differenceTotal).toFixed(2)}`} />

      <Divider />
      <AfterSalesDetailItem label={totalLabel} price={`$${Math.abs(differenceTotal).toFixed(2)}`} isBold />
      <AfterSalesDetailItem
        label={modificationFeeLabel}
        price={`$${modificationFee.toFixed(2)}`}
        className="blte-cost-summary_modification-fee-class"
        isGray
      />
      {numberOfRides !== 0 && (
        <AfterSalesDetailItem className={bem('total-returned')} label={ridesReturned} price={numberOfRides} isBold />
      )}
    </>
  );
};
