import { useMemo } from 'react';
import { useStores } from 'stores';
import { ProductConfig } from 'stores/BookingStore/sections/Extras/types';

import { getExtraItemCard } from './../../extrasModalsUtils';
import { CFLabels } from './../Modal/types';
import { ExtrasModalLoaderProps, ItemCardConfig } from './types';

export const useExtrasModalLoaderLogic = (props: ExtrasModalLoaderProps) => {
  const { cfExtra } = props;

  const { bookingStore } = useStores();
  const { provisionalBooking } = bookingStore.rti;
  const { data } = bookingStore.extras;

  const cfModal = cfExtra.modalReference;

  const tripPassengers = provisionalBooking.tripDetails.passengerSeatingDetails;

  const products = useMemo(
    () => data?.find(extraCategory => extraCategory.type === cfModal.s3Code)?.products,
    [data, cfModal]
  );

  const modalConfig = useMemo(() => {
    if (!products) return undefined;

    const initialValues = {};
    const itemCardConfigs: ItemCardConfig[] = [];
    let isUpdateButton = false;

    cfModal?.itemCards?.forEach((itemCard, index) => {
      const foundProducts: ProductConfig[] = [];

      itemCard.productsList?.forEach(cfProduct => {
        const product = products.find(prod => prod.code === cfProduct.s3ProductCode);

        if (product)
          foundProducts.push({
            ...product,
            itemLabel: cfProduct.productName,
          });
      });

      const itemCardConfig: ItemCardConfig = {
        cfLabels: itemCard,
        isUnvailable: false,
        isSoldOut: false,
        passengers: {},
      };
      foundProducts.forEach(product => {
        product.passengers.forEach(passenger => {
          if (provisionalBooking.tripDetails?.trip?.outboundRoute?.isCancelled)
            passenger.outboundSelectionParameters = undefined;

          if (provisionalBooking.tripDetails?.trip?.inboundRoute?.isCancelled)
            passenger.inboundSelectionParameters = undefined;

          const modalConfig = itemCardConfig.passengers[passenger.passengerId] || {
            outboundItems: [],
            inboundItems: [],
          };

          itemCardConfig.passengers[passenger.passengerId] = getExtraItemCard(
            {
              outboundProduct: passenger.outboundSelectionParameters,
              inboundProduct: passenger.inboundSelectionParameters,
              productCode: product.code,
              itemLabel: product.itemLabel,
            },
            modalConfig,
            itemCard
          );
        });
      });

      if (!foundProducts.length) itemCardConfig.isUnvailable = true;
      else {
        itemCardConfig.isSoldOut = Object.values(itemCardConfig.passengers).every(
          passenger => passenger.outboundItems.length <= 1 && passenger.inboundItems.length <= 1
        );

        //APPLY-ALL checkbox
        initialValues[`applyAll_${index}`] = foundProducts.every(product =>
          product.passengers.every(
            passenger =>
              passenger.outboundSelectionParameters &&
              passenger.inboundSelectionParameters &&
              passenger.outboundSelectionParameters.currentQuantitySelected ===
                passenger.inboundSelectionParameters.currentQuantitySelected
          )
        );

        //Passenger individual selected items
        Object.keys(itemCardConfig.passengers).forEach(passengerID => {
          const passengerProducts = foundProducts
            .map(product => product.passengers.find(p => p.passengerId === passengerID))
            .filter(p => !!p);

          const numSelectedOutboundItems = passengerProducts.reduce(
            (acc, curr) => acc + (curr?.outboundSelectionParameters?.currentQuantitySelected || 0),
            0
          );
          const numSelectedInboundItems = passengerProducts.reduce(
            (acc, curr) => acc + (curr?.inboundSelectionParameters?.currentQuantitySelected || 0),
            0
          );

          initialValues[`outbound${index}-${passengerID}`] = numSelectedOutboundItems.toString();
          initialValues[`inbound${index}-${passengerID}`] = numSelectedInboundItems.toString();

          isUpdateButton = isUpdateButton || numSelectedOutboundItems > 0 || numSelectedInboundItems > 0;
        });

        //Passenger names
        Object.entries(itemCardConfig.passengers).forEach(([key, value]) => {
          const foundPassenger = tripPassengers?.find(p => p.passengerId === key)?.passenger;
          value.passengerName = foundPassenger ? `${foundPassenger.firstName} ${foundPassenger.lastName}` : key;
        });
      }

      itemCardConfigs.push(itemCardConfig);
    });

    return {
      itemCardConfigs,
      infoCardArray: cfModal.infoCards || [],
      initialValues,
      isUpdateButton,
    };
  }, [provisionalBooking, products, cfModal, tripPassengers]);

  const cfData: CFLabels = useMemo(
    () => ({
      modalTitle: cfModal.modalTitle,
      title: cfModal.title,
      subtitle: cfModal.subtitle,
      departure: cfModal.departureLabel,
      return: cfModal.returnLabel,
      to: cfModal.toLabel,
      footer: {
        addToTripButtonLabel: cfModal.addToTripButtonLabel,
        cancelButtonLabel: cfModal.cancelButtonLabel,
        totalLabel: cfModal.totalLabel,
        updateButtonLabel: cfModal.updateButtonLabel,
      },
      disclaimer: cfModal.disclaimer,
      errorMessage: cfModal.errorMessageLabel,
      analyticsEventType: cfModal.analyticsEventType,
    }),
    [cfModal]
  );

  return {
    modalConfig,
    cfData,
  };
};
