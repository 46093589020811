import { makeAutoObservable, reaction } from 'mobx';
import moment from 'moment';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { isDelayExperienceEnabled } from 'utils/metas';

import { initialPastPagination, initialUpcomingPagination } from './constants';
import { TripConfig, Pagination, UpcomingPagination } from './types';

export class Trips {
  upcoming: TripConfig[] = [];
  past: TripConfig[] = [];
  isLoading = true;
  pastTripsPagination: Pagination = initialPastPagination;
  pastTripsItems: TripConfig[] = [];
  upcomingTripsPagination: UpcomingPagination = initialUpcomingPagination;
  upcomingTripsItems: TripConfig[] = [];
  tripHasStatus = {};

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    const { cfStore } = rootStore;
    // listen to cfStore and update the initial params in account -> trips store
    reaction(() => cfStore.account.trips.pagination.itemsPerPage, this.setPastTripsItemsPerPage);

    reaction(() => cfStore.account.trips.upcomingTripsCount, this.setUpcomingTripsFirstPageCount);

    reaction(() => cfStore.account.trips.upcomingTripsLoadMoreNumber, this.setUpcomingTripsItemsPerPage);
  }

  resetTripsData() {
    this.upcoming = [];
    this.past = [];
    this.pastTripsItems = [];
    this.upcomingTripsItems = [];
    this.isLoading = true;
    this.pastTripsPagination = initialPastPagination;
    this.upcomingTripsPagination = initialUpcomingPagination;
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setUpcomingTrips(upcomingTrips) {
    this.upcoming = upcomingTrips;
  }

  setPastTrips(pastTrips) {
    this.past = pastTrips;
  }

  setPastTripsItems(items) {
    this.pastTripsItems = items;
  }

  setTripHasStatus(status, hasStatus) {
    this.tripHasStatus = {
      ...this.tripHasStatus,
      [status]: hasStatus,
    };
  }

  setUpcomingTripsFirstPageCount(val) {
    const value = parseInt(val) || initialUpcomingPagination.firstPageCount;

    // firstPageCount changed, so reset pagination and items to show
    this.upcomingTripsPagination.firstPageCount = value;
    const pageCount =
      this.upcoming.length < value
        ? 1
        : Math.ceil((this.upcoming.length - value) / this.upcomingTripsPagination.itemsPerPage) + 1;

    const newPagination = {
      ...this.upcomingTripsPagination,
      firstPageCount: value,
      pageNumber: 1,
      pageCount,
    };

    this.setUpcomingTripsPagination(newPagination);
  }

  setUpcomingTripsPageNumber(value) {
    // page number changed, so set it and pagination accordingly
    const pageNumber = Math.min(value, this.upcomingTripsPagination.pageCount);

    const newPagination = {
      ...this.upcomingTripsPagination,
      pageNumber,
    };

    this.setUpcomingTripsPagination(newPagination);
  }

  loadMoreUpcoming() {
    // show 1 more page
    const { pageNumber } = this.upcomingTripsPagination;
    this.setUpcomingTripsPageNumber(pageNumber + 1);
  }

  setUpcomingTripsItemsPerPage(val) {
    const value = parseInt(val) || initialUpcomingPagination.itemsPerPage;
    this.upcomingTripsPagination.itemsPerPage = value;

    // itemsPerPage changed, so we reset pagination
    const pageCount =
      this.upcoming.length < this.upcomingTripsPagination.firstPageCount
        ? 1
        : Math.ceil((this.upcoming.length - this.upcomingTripsPagination.firstPageCount) / value) + 1;

    const newPagination = {
      ...this.upcomingTripsPagination,
      itemsPerPage: value,
      pageNumber: 1,
      pageCount,
    };

    this.setUpcomingTripsPagination(newPagination);
  }

  setUpcomingTripsPagination(pgn) {
    // set pagination and recalculate the items to be shown
    this.upcomingTripsPagination = pgn;
    this.upcomingTripsItems = this.upcoming.slice(
      0,
      Math.min(pgn.firstPageCount + pgn.itemsPerPage * Math.max(pgn.pageNumber - 1, 0), pgn.total)
    );
  }

  setPastTripsPagination(pgn) {
    // set pagination and recalculate the items to be shown
    this.pastTripsPagination = pgn;
    this.setPastTripsItems(
      this.past.slice((pgn.pageNumber - 1) * pgn.itemsPerPage, pgn.pageNumber * pgn.itemsPerPage - 1)
    );
  }

  setPastTripsItemsPerPage(val) {
    const value = parseInt(val) || initialPastPagination.itemsPerPage;

    // itemsPerPage changed, so recalculate pagination and items to show
    const pageCount = this.past.length < value ? 1 : Math.ceil(this.past.length / value);
    const newPagination = {
      ...this.pastTripsPagination,
      itemsPerPage: value,
      pageNumber: 1,
      pageCount,
    };
    this.setPastTripsPagination(newPagination);
  }

  setPastTripsPageNumber(pageNumber) {
    if (pageNumber >= 1 && pageNumber <= this.pastTripsPagination.pageCount) {
      const newPagination = {
        ...this.pastTripsPagination,
        pageNumber,
      };
      this.setPastTripsPagination(newPagination);
    }
  }

  async fetchAccountTripsData() {
    try {
      this.setLoading(true);
      const response = await api.get(`${requestUrls.getRestUrl(requestUrls.account.trips)}.xjson`);
      const { upcoming, past } = response.data;
      this.setUpcomingTrips(upcoming.trips);
      this.setPastTrips(past.trips);

      const pastPageCount =
        past.trips.length < this.pastTripsPagination.itemsPerPage
          ? 1
          : Math.ceil(past.trips.length / this.pastTripsPagination.itemsPerPage);

      this.setPastTripsPagination({
        ...this.pastTripsPagination,
        total: past.trips.length,
        pageNumber: 1,
        pageCount: pastPageCount,
      });

      if (isDelayExperienceEnabled()) this.tripHasStatus = {};
      for (const trip of this.upcoming) {
        await this.fetchTrainStatus(trip.outboundRoute);
        trip.inboundRoute && (await this.fetchTrainStatus(trip.inboundRoute));
      }

      const upcomingPageCount =
        upcoming.trips.length < this.upcomingTripsPagination.firstPageCount
          ? 1
          : Math.ceil(
              (upcoming.trips.length - this.upcomingTripsPagination.firstPageCount) /
                this.upcomingTripsPagination.itemsPerPage
            ) + 1;

      this.setUpcomingTripsPagination({
        ...this.upcomingTripsPagination,
        total: upcoming.trips.length,
        pageNumber: 1,
        pageCount: upcomingPageCount,
      });

      this.setLoading(false);
    } catch (e) {
      console.error(e);
      this.setLoading(false);
    }
  }

  async fetchTrainStatus(trip) {
    const toCamelCase = word => word?.charAt(0).toLowerCase() + word?.slice(1)?.replace(' ', '');
    const tz = trip.origin.timeZone;
    const isToday = moment(trip.departureDateTime).tz(tz).isSame(moment().tz(tz), 'days');

    if (trip.isReaccommodated) {
      trip.trainStatus = 'updated';
      isToday && this.setTripHasStatus('updated', true);
    }

    if (trip.isCancelled) {
      trip.trainStatus = 'cancelled';
      isToday && this.setTripHasStatus('cancelled', true);

      return;
    }

    if (moment(trip?.arrivalDateTime).tz(tz).isBefore(moment().tz())) trip.trainStatus = 'completed';

    try {
      const tripHasDeparted = moment(trip.departureDateTime).tz(tz).isBefore(moment().tz(tz));

      const params = {
        date: tripHasDeparted
          ? moment(trip.arrivalDateTime).format('YYYY-MM-DD')
          : moment(trip.departureDateTime).format('YYYY-MM-DD'),
        serviceNumber: trip.service.name,
        station: tripHasDeparted ? trip.destination.id : trip.origin.id,
        scheduleType: tripHasDeparted ? 'ARRIVAL' : 'DEPARTURE',
      };

      //if train departs today
      if (isToday) {
        const response = await api.get(requestUrls.getBffUrl(requestUrls.account.trainSchedule), { params });
        const trainHasArrived =
          !!response?.data?.[0].liveTime && moment(response?.data?.[0]?.liveTime).tz(tz).isBefore(moment().tz(tz));

        if (response?.data?.[0].status != 'Arriving Soon') trip.trainStatus = toCamelCase(response?.data?.[0].status);
        if (response?.data?.[0].status == 'Arrived' || trainHasArrived) trip.trainStatus = 'completed';

        if (response?.data?.[0].status == 'Delayed') this.setTripHasStatus('delayed', true);
      }
    } catch (e) {
      console.error('error', e);
    }
  }
}
