import React, { useEffect, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import Carousel, { CarouselInternalState } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { useEventsBrowserLogic } from '../../useEventsBrowserLogic';
import TopPick from './TopPick';

const bem = cn('events-browser');
const font = cn('font');

const TopPicksSection = ({
  topPickEvents,
  topPicksTitle,
  topPicksTitleType,
  topPicksTitleFontStyle,
  topPicksDescription,
  topPicksDescriptionType,
  topPicksDescriptionFontStyle,
}) => {
  const [carouselRef, setCarouselRef] = useState<any>();
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState<boolean>(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState<boolean>(false);
  const [visibleSlides, setVisibleSlides] = useState<number[]>([]);

  const { windowSize } = useEventsBrowserLogic();

  const responsive = {
    desktop: { breakpoint: { max: 5000, min: 1350 }, items: 4, partialVisibilityGutter: 0 },
    tabletLarge: { breakpoint: { max: 1350, min: 1000 }, items: 3, partialVisibilityGutter: 0 },
    tabletMedium: { breakpoint: { max: 1000, min: 751 }, items: 2, partialVisibilityGutter: 0 },
    mobile: { breakpoint: { max: 751, min: 0 }, items: 1, partialVisibilityGutter: (windowSize.width - 48) * 0.25 },
  };

  const isMobile = useMediaPredicate('(max-width: 751px)');

  useEffect(() => {
    if (!carouselRef?.state) return;

    setIsLeftArrowDisabled(carouselRef?.state.currentSlide === 0);
    setIsRightArrowDisabled(
      carouselRef?.state.currentSlide + carouselRef?.state.slidesToShow >= carouselRef?.state.totalItems
    );
  }, [carouselRef?.state.total, carouselRef?.state.currentSlide, carouselRef?.state.slidesToShow, carouselRef?.state]);

  const setCarouselSettings = (_: number, state: CarouselInternalState) => {
    if (!state) return;

    setIsLeftArrowDisabled(state.currentSlide === 0);
    setIsRightArrowDisabled(state.currentSlide + state.slidesToShow >= state.totalItems);

    const newActiveIndexes = [...Array(state.slidesToShow)].map((_, idx) => state.currentSlide + idx);

    setVisibleSlides(newActiveIndexes);
  };

  // Fix for console warning
  const EmptyArrow = arrowProps => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { carouselState, children, ...restArrowProps } = arrowProps;

    return <div {...restArrowProps}> {children} </div>;
  };

  if (!topPickEvents.length) return null;

  return (
    <div className={bem('full-width')}>
      <div className={bem('top-picks-header')}>
        <div className={bem('top-picks-title')}>
          <Text className={font({ variant: topPicksTitleFontStyle })} as={topPicksTitleType} text={topPicksTitle} />
        </div>
        <div className={bem('top-picks-description')}>
          <Text
            className={font({ variant: topPicksDescriptionFontStyle })}
            as={topPicksDescriptionType}
            text={topPicksDescription}
          />
        </div>
      </div>
      <div className={bem('carousel-wrapper')}>
        {!isMobile && (
          <div className={bem('carousel-slider-left', { hidden: isLeftArrowDisabled })}>
            <button
              className={bem('carousel-slider-left--button')}
              onClick={() => {
                const nextSlide = carouselRef?.state?.currentSlide - 1;
                if (carouselRef?.state?.currentSlide === 0) return;

                carouselRef?.goToSlide(nextSlide);
              }}
            >
              <Icon name="chevron-left" className={bem('chevron-icon')} />
            </button>
          </div>
        )}
        <Carousel
          ref={el => setCarouselRef(el)}
          additionalTransfrom={0}
          responsive={responsive}
          transitionDuration={250}
          containerClass={bem('top-picks-carousel-container')}
          itemClass={bem('top-picks-carousel-list')}
          infinite={false}
          centerMode={false}
          partialVisible={isMobile && carouselRef?.state?.totalItems > 1}
          rewind={false}
          rewindWithAnimation={false}
          minimumTouchDrag={80}
          slidesToSlide={1}
          afterChange={setCarouselSettings}
          showDots={isMobile}
          customLeftArrow={<EmptyArrow />}
          customRightArrow={<EmptyArrow />}
          draggable={false}
          keyBoardControl
          pauseOnHover
          swipeable
        >
          {topPickEvents.map((topPickEvent, idx) => {
            const isActive = visibleSlides.includes(idx);

            return <TopPick key={`top-pick-${idx}`} event={topPickEvent} isActive={isActive} />;
          })}
        </Carousel>
        {!isMobile && (
          <div className={bem('carousel-slider-right', { hidden: isRightArrowDisabled })}>
            <button
              className={bem('carousel-slider-right--button')}
              onClick={() => {
                const nextSlide = carouselRef?.state?.currentSlide + 1;
                if (
                  carouselRef?.state?.currentSlide + carouselRef?.state?.slidesToShow ===
                  carouselRef?.state?.totalItems
                )
                  return;

                carouselRef.goToSlide(nextSlide);
              }}
            >
              <Icon name="chevron-right" className={bem('slider-icon')} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopPicksSection;
