import React from 'react';

import { PastTripsListPlaceholder } from '@account/routes/MyTrips/components/PastTripsList/PastTripsListPlaceholder';

import { cn } from 'utils/classNames';

const bem = cn('past-trips');

export const PastTripsPlaceholders = () => (
  <div className={bem('placeholders')}>
    <div />
    <PastTripsListPlaceholder variant="long" />
  </div>
);
