import React, { useMemo } from 'react';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import Image from '@atoms/Image/Image';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';
import { LoyaltyOptInProps } from '@organisms/LoaltyOptInModal/types';

import { cn } from 'utils/classNames';

import './../LoyaltyOptInModal.scss';

const bem = cn('loyalty-optin-modal');

export const LoyaltyOptIn = (props: LoyaltyOptInProps) => {
  const { titleLoyaltyText, brandImage, joinButtonLabel, declineButtonLabel, renderError } = props;
  const { disclaimerAndTosText, loyaltyBenefitsCards, loyaltyBenefitsBackgroundColor } = props;
  const { handleJoinButtonClicked, handleDeclineButtonClicked, modalTitle, joinButtonLoading } = props;

  const styles: Record<string, string> = useMemo(
    () => ({
      '--loyalty-benefits-color': loyaltyBenefitsBackgroundColor || '#FFFFFF',
    }),
    [loyaltyBenefitsBackgroundColor]
  );

  return (
    <div className={bem('loyalty-optin')} style={styles}>
      <div className={bem('loyalty')}>
        {modalTitle && (
          <>
            <Text text={modalTitle} className={bem('loyalty-modal-title')} />
            <div className={bem('loyalty-divider')} />
          </>
        )}
        {brandImage?.src && <Image {...brandImage} className={bem('loyalty-image')} />}
        <div className={bem('loyalty-divider')} />
        <Text text={titleLoyaltyText} className={bem('loyalty-title')} />
        <div className={bem('loyalty-benefits')}>
          {loyaltyBenefitsCards.map((benefit, idx) => (
            <div key={idx} className={bem('loyalty-benefit')}>
              {benefit.startWithCheckmark && <Icon name="check" className={bem('loyalty-benefit-icon')} />}
              <div className={bem('loyalty-benefit-body')}>
                <Text text={benefit.title} className={bem('loyalty-benefit-title')} />
                <Text text={benefit.text} className={bem('loyalty-benefit-text')} />
              </div>
            </div>
          ))}
        </div>
        <Text text={disclaimerAndTosText} className={bem('loyalty-tos')} />
        {!!renderError && renderError}
        <Button
          id="join-loyalty-button-id"
          label={joinButtonLabel}
          color="yellow"
          width="full"
          type="submit"
          loading={!!joinButtonLoading}
          onClick={handleJoinButtonClicked}
        />
        <LinkButton
          id="decline-loyalty-button-id"
          label={declineButtonLabel}
          variant="big"
          className={bem('loyalty-decline')}
          onClick={handleDeclineButtonClicked}
        />
      </div>
    </div>
  );
};
