import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import { DateSelection } from './CalendarFilter/DateSelection';
import './EventsBrowserFiltersMobile.scss';

const bem = cn('events-browser-filters-mobile');

export const EventsBrowserFiltersMobile = observer(({ logic }: any) => {
  const { cfStore } = useStores();
  const { filterItems } = cfStore.events;
  const { filtersLabelMobileDrawer, clearCategoriesLabel } = cfStore.events;

  const {
    cityFilterLabel,
    clearAllFiltersLabel,
    allCitiesLabel,
    allCitiesIcon,
    orlandoLabel,
    orlandoIcon,
    westPalmBeachLabel,
    westPalmBeachIcon,
    bocaRatonLabel,
    bocaRatonIcon,
    fortLauderdaleLabel,
    fortLauderdaleIcon,
    aventuraLabel,
    aventuraIcon,
    miamiLabel,
    miamiIcon,
    clearCitiesLabel,
    categoryFilterLabel,
  } = cfStore.events.cityFilter;

  const { closeButtonLabel, clearButtonLabel } = cfStore.events.calendarFilter;

  const { selectedFilters, handleFilterClicked, selectedStations, handleStationClicked, handleClearFilters } = logic;
  const { cityPopoverOpen, setCityPopoverOpen, isFilterSelected, getFilterLabel, getFilterItem, windowSize } = logic;
  const {
    datesRestrictionsData,
    datePopoverOpen,
    setDatePopoverOpen,
    calendarStartDate,
    setCalendarStartDate,
    calendarEndDate,
    setCalendarEndDate,
    activeButtonFilter,
    setActiveButtonFilter,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    formatDateRange,
  } = logic;

  const [focusedInput, setFocusedInput] = useState<string>('');

  const { width } = windowSize;

  const stations = [
    { label: allCitiesLabel, id: 'All', icon: allCitiesIcon },
    { label: orlandoLabel, id: 'MCO', icon: orlandoIcon },
    { label: westPalmBeachLabel, id: 'WPT', icon: westPalmBeachIcon },
    { label: bocaRatonLabel, id: 'RRN', icon: bocaRatonIcon },
    { label: fortLauderdaleLabel, id: 'FBT', icon: fortLauderdaleIcon },
    { label: aventuraLabel, id: 'AVE', icon: aventuraIcon },
    { label: miamiLabel, id: 'EKW', icon: miamiIcon },
  ];

  const calendarBottomlabels = { closeButtonLabel, clearButtonLabel };

  const handleClearDateIcon = () => {
    setActiveButtonFilter({
      value: '',
      label: '',
    });
    setCalendarStartDate('');
    setCalendarEndDate('');
    setSelectedStartDate('');
    setSelectedEndDate('');
  };

  const handleCloseModal = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setCityPopoverOpen(false);
  };

  if (!cityPopoverOpen || width > 575) return null;

  return (
    <Modal variant="default-no-space" className={bem('modal')}>
      <div className={bem('wrapper')}>
        <div className={bem('modal-heading')}>
          <div className={bem('modal-heading-title')} onClick={handleCloseModal}>
            <Icon name="chevron-left" className={bem('modal-heading-icon')} />
            <Text text={filtersLabelMobileDrawer} className={bem('modal-heading-label')} />
          </div>
          {(!selectedStations.includes('All') ||
            !selectedFilters.includes('All') ||
            activeButtonFilter.value ||
            (calendarStartDate && calendarEndDate)) && (
            <div className={bem('selected-filters')}>
              {selectedFilters
                .filter(filterID => filterID !== 'All')
                .map(filterID => (
                  <div key={filterID} className={bem('selected-filter-pill')}>
                    {getFilterLabel(filterID)}
                    <div
                      key={filterID}
                      className={bem('selected-filter-pill-icon')}
                      onClick={() => handleFilterClicked(getFilterItem(filterID))}
                    >
                      <Icon name="clear" />
                    </div>
                  </div>
                ))}
              {selectedStations
                .filter(stationID => stationID !== 'All')
                .map(stationID => (
                  <div key={stationID} className={bem('selected-filter-pill')}>
                    {stations.find(station => station.id === stationID)?.label}
                    <div
                      key={stationID}
                      className={bem('selected-filter-pill-icon')}
                      onClick={() => handleStationClicked(stationID)}
                    >
                      <Icon name="clear" />
                    </div>
                  </div>
                ))}
              {(activeButtonFilter.value || (calendarStartDate && calendarEndDate)) && (
                <div className={bem('selected-filter-pill')}>
                  {activeButtonFilter.value && activeButtonFilter.label && `${activeButtonFilter.label}`}
                  {calendarStartDate && calendarEndDate && formatDateRange(calendarStartDate, calendarEndDate)}
                  <div className={bem('selected-filter-pill-icon')} onClick={handleClearDateIcon}>
                    <Icon name="clear" />
                  </div>
                </div>
              )}
              <div className={bem('filter-button')}>
                <LinkButton label={clearAllFiltersLabel} variant="big" onClick={handleClearFilters} />
              </div>
            </div>
          )}
        </div>
        <div className={bem('filters-wrapper')}>
          <Text text={categoryFilterLabel} className={bem('filter-title')} />
          <div className={bem('filter-items')}>
            {filterItems.map((filterItem, idx) => (
              <div
                key={idx}
                className={bem('filter-item', { selected: isFilterSelected(filterItem) })}
                onClick={() => handleFilterClicked(filterItem)}
              >
                {filterItem.filterIcon && (
                  <img src={filterItem.filterIcon?.src} alt="" className={bem('filter-icon')} />
                )}
                <Text text={filterItem.filterLabel} className={bem('filter-label')} />
              </div>
            ))}
          </div>
          <div className={bem('filter-button')}>
            <LinkButton label={clearCategoriesLabel} variant="big" onClick={() => handleFilterClicked()} />
          </div>
        </div>
        <div className={bem('filters-wrapper')}>
          <Text text={cityFilterLabel} className={bem('filter-title')} />
          <div className={bem('filter-items')}>
            {stations.map((station, idx) => (
              <div key={idx} className={bem('station-item')} onClick={() => handleStationClicked(station.id)}>
                <div className={bem('station-icon', { selected: selectedStations.includes(station.id) })}>
                  <img className={bem('icons')} src={station.icon.src} alt={station.icon.alt} />
                </div>
                <Text text={station.label} className={bem('filter-label')} />
              </div>
            ))}
          </div>
          <div className={bem('filter-button')}>
            <LinkButton label={clearCitiesLabel} variant="big" onClick={() => handleStationClicked('All')} />
          </div>
        </div>
        <div className={bem('filters-wrapper')}>
          <Text text="Date" className={bem('filter-title')} />
          <div className={bem('calendar-filter')}>
            <DateSelection
              labels={calendarBottomlabels}
              setFocusedInput={setFocusedInput}
              focusedInput={focusedInput}
              datesRestrictions={datesRestrictionsData}
              calendarStartDate={calendarStartDate}
              setCalendarStartDate={setCalendarStartDate}
              calendarEndDate={calendarEndDate}
              setCalendarEndDate={setCalendarEndDate}
              activeButtonFilter={activeButtonFilter}
              setActiveButtonFilter={setActiveButtonFilter}
              datePopoverOpen={datePopoverOpen}
              setDatePopoverOpen={setDatePopoverOpen}
              selectedStartDate={selectedStartDate}
              setSelectedStartDate={setSelectedStartDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndDate={setSelectedEndDate}
            />
          </div>
          <div className={bem('filter-button')}>
            <LinkButton label={clearButtonLabel} variant="big" onClick={handleClearDateIcon} />
          </div>
        </div>
      </div>
    </Modal>
  );
});
