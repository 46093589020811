import React, { useEffect, useId, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Icon } from '@atoms/Icon';

import { cn, cx } from 'utils/classNames';

import './ExtrasSelect.scss';
import { SelectProps } from './types';

const bem = cn('select');

export const ExtrasSelectHeader = (props: SelectProps) => {
  const generatedId = useId();
  const { name, required, leadingIcon, disabled, error, onBlur, onFocus, value } = props;
  const { displayList, handleKeyDown, children, label, onClick, headerRef } = props;
  const { accessibilityFocus, setAccessibilityFocus, errorMessage } = props;
  const { readOnly, showAsterisk, type = 'button', id = `select-${generatedId}` } = props;
  const { labelCN = 'blte-font--variant-body-medium-500', className = '' } = props;
  const { headerLabel, afterErrorFocus } = props;

  const [focus, setFocus] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (afterErrorFocus && error) document?.getElementById(id)?.focus();
  }, [error, afterErrorFocus, id]);

  const handleClickOutsideWrapper = () => {
    if (setAccessibilityFocus) setAccessibilityFocus(false);

    setFocus(false);
  };

  const handleFocus = e => {
    setFocus(true);
    if (setAccessibilityFocus) setAccessibilityFocus(true);

    if (onFocus) onFocus(e);
  };

  const handleBlur = e => {
    setFocus(false);
    if (setAccessibilityFocus) setAccessibilityFocus(false);
    if (onBlur) onBlur(e);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutsideWrapper}>
      <div className="header" id="header">
        <div
          ref={wrapperRef}
          tabIndex={-1}
          role="button"
          id="trigger"
          aria-haspopup="menu"
          aria-labelledby={`header ${id}`}
          aria-label="Open selection menu"
          aria-expanded={displayList}
          aria-controls={`${id}-list`}
          className={cx(
            bem({
              disabled: disabled,
              required: required,
              'has-value': !!value,
              'has-error': !!error,
              'has-focus': focus,
              'read-only': readOnly,
              'accessibility-focus': accessibilityFocus,
            }),
            { [className]: !!className }
          )}
          onKeyDown={handleKeyDown}
          onClick={onClick}
        >
          {label && (
            <label className={cx(bem('label', { hasLeadingIcon: !!leadingIcon }), labelCN)} htmlFor={id} id={`${id}`}>
              {label} {required || (showAsterisk && <span className={bem('label-required')}>*</span>)}
            </label>
          )}
          <input
            ref={headerRef}
            required={required}
            role="combobox"
            aria-haspopup="listbox"
            aria-expanded="false"
            aria-controls={`${id}-list`}
            type={type}
            name={name}
            id={id}
            disabled={disabled}
            value={headerLabel || value || ''}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            aria-labelledby={`header ${id}`}
            aria-invalid={Boolean(error)}
            aria-errormessage={`error-${id}`}
            aria-describedby={`error-${id}`}
            className={cx(
              bem('select', {
                hasLeadingIcon: !!leadingIcon,
              }),
              'blte-font--variant-body-medium-500'
            )}
            readOnly={readOnly}
          />
          {leadingIcon && (
            <span className={bem('leadingIcon')}>
              <Icon name={leadingIcon} />
            </span>
          )}
          <div className={bem('trailingIcon')}>
            <Icon name={displayList ? 'arrow-up' : 'arrow-down'} />
          </div>
          {error && errorMessage && (
            <span id={`error-${id}`} className={cx(bem('error'), 'blte-font--variant-tiny-500')}>
              {errorMessage}
            </span>
          )}
          {displayList && (
            <ul role="listbox" className={bem('listbox')} aria-labelledby="header">
              {React.Children.map(children, (child, index) =>
                React.isValidElement(child) ? (
                  <li role="option" id={`option-${index}`}>
                    {child}
                  </li>
                ) : null
              )}
            </ul>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};
