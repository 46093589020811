export const defaultPassenger = {
  firstName: '',
  lastName: '',
  type: '',
  email: '',
  phoneNumber: {
    countryCode: '+1',
    number: '',
  },
  dateOfBirth: '',
};

export const defaultTripArgs = {
  referenceNumber: 'string',
  outboundRoute: {
    id: 'string',
    service: {
      id: 'string',
      name: 'string',
      type: 'string',
    },
    origin: {
      id: 'West Palm Beach',
      name: 'West Palm Beach',
      abbreviation: 'West Palm Beach',
      city: 'West Palm Beach',
      timeZone: 'string',
      nameLong: 'West Palm Beach',
    },
    destination: {
      id: 'Miami',
      name: 'Miami',
      abbreviation: 'Miami',
      city: 'Miami',
      timeZone: 'string',
      nameLong: 'Miami',
    },
    departureDateTime: '2023-03-22T13:21:08.980Z',
    arrivalDateTime: '2023-03-22T13:21:08.980Z',
    classType: 'smart',
  },
  outboundRouteStatus: 'upcoming',
  inboundRoute: {
    id: 'string',
    service: {
      id: 'string',
      name: 'string',
      type: 'string',
    },
    origin: {
      id: 'Miami',
      name: 'Miami',
      abbreviation: 'Miami',
      city: 'Miami',
      timeZone: 'string',
      nameLong: 'Miami',
    },
    destination: {
      id: 'West Palm Beach',
      name: 'West Palm Beach',
      abbreviation: 'West Palm Beach',
      city: 'West Palm Beach',
      timeZone: 'string',
      nameLong: 'West Palm Beach',
    },
    departureDateTime: '2023-03-22T13:21:08.980Z',
    arrivalDateTime: '2023-03-22T13:21:08.980Z',
    classType: 'smart',
  },
  inboundRouteStatus: 'upcoming',
};

export const defaultSeatSelectionArgs = {
  mode: 'active',
  trip: defaultTripArgs,
  stepNr: 2,

  passengerSeatingDetails: [
    {
      passenger: {
        firstName: 'Mitchell',
        lastName: 'Summers',
        type: 'adult',
        email: 'alex@kid.com',
        phoneNumber: {
          countryCode: '+1',
          number: '111111111',
        },
        dateOfBirth: '2023-03-22T13:21:08.980Z',
      },
      outboundSeat: {
        number: '4A',
        facingDirection: 'backwards',
        inventoryType: 'string',
        isWindow: true,
        coach: {
          coachNumber: '2',
          classType: 'smart',
        },
      },
      inboundSeat: {
        number: '12C',
        facingDirection: 'forwards',
        inventoryType: 'string',
        isWindow: true,
        coach: {
          coachNumber: '4',
          classType: 'smart',
        },
      },
      isPrimaryPassenger: true,
      passengerId: 'string',
    },
    {
      passenger: {
        firstName: 'Jason',
        lastName: 'Thomas',
        type: 'adult',
        email: 'jason@thomas.com',
        phoneNumber: {
          countryCode: '+1',
          number: '111111111',
        },
        dateOfBirth: '2023-03-22T13:21:08.980Z',
      },
      outboundSeat: {
        number: '4B',
        facingDirection: 'forwards',
        inventoryType: 'string',
        isWindow: false,
        coach: {
          coachNumber: '2',
          classType: 'smart',
        },
      },
      inboundSeat: {
        number: '12D',
        facingDirection: 'backwards',
        inventoryType: 'string',
        isWindow: false,
        coach: {
          coachNumber: '4',
          classType: 'smart',
        },
      },
      isPrimaryPassenger: false,
      passengerId: 'string',
    },
  ],
};

export const defaultPaymentInfoArgs = {
  stepNr: 4,
  mode: 'active',
};

export const walletPasses = {
  applicableTravelPasses: [
    {
      currentQuantityApplied: 0,
      isApplicableToBooking: true,
      travelPass: {
        code: 'travelPass',
        passType: 'unlimited_pass',
        passName: 'Weekend Pass',
        classType: 'smart',
        issuedDate: '2023-05-29T10:52:40.593Z',
        endDate: '2023-05-29T10:52:40.593Z',
        isAutoRenew: true,
        originStation: {
          id: 'MIA',
          name: 'Miami',
          abbreviation: 'MIA',
          city: 'Miami',
          timeZone: 'EST',
          nameLong: 'Miami station',
          latitude: 0,
          longitude: 0,
        },
        destinationStation: {
          id: 'WPT',
          abbreviation: 'WPB',
          timeZone: 'America/New_York',
          city: 'West Palm Beach',
          name: 'West Palm Beach',
          nameLong: 'West Palm Beach',
          latitude: 0,
          longitude: 0,
        },
        isRoundTrip: true,
        availableRides: {
          purchased: 12,
          remaining: 3,
        },
        passengerName: 'Passenger Name',
        isShareable: true,
      },
    },
  ],
  applicableParkingPass: {
    passName: 'Parking Miami',
    station: {
      id: 'MIA',
      name: 'Miami',
      abbreviation: 'MIA',
      city: 'Miami',
      timeZone: 'EST',
      nameLong: 'Miami station',
      latitude: 0,
      longitude: 0,
    },
    vehicleLicensePlate: {
      number: 'BV00MAV',
      state: 'RO',
    },
    endDate: '2023-05-29T10:52:40.593Z',
    isAutoRenew: true,
  },
  applicableCreditPasses: [
    {
      code: 'code',
      passName: 'Brightline Credit',
      creditAvailable: 45,
    },
  ],
};

export const defaultExtras = {
  categories: [
    {
      type: 'luggage',
      products: [
        {
          type: 'bags_checked',
          code: 'BAGS',
          name: 'Bags - Checked',
          description: '',
          passengers: [
            {
              passengerId: 'passenger_1',
              passengerName: ' ',
              outboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 2,
                pricePerItem: 10,
                tariffCode: 'BAG_CHECKED',
              },
              inboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 2,
                pricePerItem: 10,
                tariffCode: 'BAG_CHECKED',
              },
            },
          ],
        },
        {
          type: 'bags_checked',
          code: 'PR_FREE_BAG',
          name: 'First bag free in Premium',
          description: '',
          passengers: [
            {
              passengerId: 'passenger_1',
              passengerName: ' ',
              outboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 1,
                pricePerItem: 0,
                tariffCode: 'PR-FREE_BAG',
              },
              inboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 1,
                pricePerItem: 0,
                tariffCode: 'PR-FREE_BAG',
              },
            },
          ],
        },
        {
          type: 'bags_checked',
          code: 'EXTRA_BAG',
          name: 'Bags - Checked Extra',
          description: '',
          passengers: [
            {
              passengerId: 'passenger_1',
              passengerName: ' ',
              outboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 1,
                pricePerItem: 0,
                tariffCode: 'EXTRA-BAG',
              },
              inboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 1,
                pricePerItem: 0,
                tariffCode: 'EXTRA-BAG',
              },
            },
          ],
        },
      ],
    },
    {
      type: 'pet',
      products: [
        {
          type: 'cat',
          code: 'CAT_SEAT',
          name: 'Cat - Carry on (Under the seat)',
          description: '',
          passengers: [
            {
              passengerId: 'passenger_1',
              passengerName: ' ',
              outboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 10,
                pricePerItem: 5,
                tariffCode: 'CAT_SEAT',
              },
            },
          ],
        },
        {
          type: 'dog',
          code: 'DOG_SEAT',
          name: 'Dog - Carry on (Under the seat)',
          description: '',
          passengers: [
            {
              passengerId: 'passenger_1',
              passengerName: ' ',
              outboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 10,
                pricePerItem: 5,
                tariffCode: 'DOG_SEAT',
              },
            },
          ],
        },
      ],
    },
    {
      type: 'specialty',
      products: [
        {
          type: 'bike_carry_on',
          code: 'BIKE_TOWER',
          name: 'Bike - Tower',
          description: '',
          passengers: [
            {
              passengerId: 'passenger_1',
              passengerName: ' ',
              outboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 10,
                pricePerItem: 5,
                tariffCode: 'BIKE_TOWER',
              },
            },
          ],
        },
        {
          type: 'bike_checked',
          code: 'BIKE_CHECKED',
          name: 'Bike - Checked',
          description: '',
          passengers: [
            {
              passengerId: 'passenger_1',
              passengerName: ' ',
              outboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 10,
                pricePerItem: 10,
                tariffCode: 'BIKE_CHECKED',
              },
            },
          ],
        },
        {
          type: 'golf clubs',
          code: 'GOLF_CHECKED',
          name: 'Golf Clubs - Checked',
          description: 'These are golf clubs',
          passengers: [
            {
              passengerId: 'passenger_1',
              passengerName: ' ',
              outboundSelectionParameters: {
                currentQuantitySelected: 0,
                maxQuantitySelectable: 10,
                pricePerItem: 15,
                tariffCode: 'GOLF_CHECKED',
              },
            },
          ],
        },
      ],
    },
  ],
};
