import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores';

import { useRTILogic } from '../useRTILogic';
import './TripDetails.scss';
import { TripDetailsItem } from './TripDetailsItem';
import { editTripDetails } from './utils';

export const TripDetailsItems = observer(() => {
  const { bookingStore, cfStore, ticketsFormStore } = useStores();
  const navigate = useNavigate();
  const { generic } = cfStore;

  const { provisionalBooking, modifyTripType, resetGiftCards, setTpNotApplicableDueToGiftCardPayment } =
    bookingStore.rti;

  const rtiLogic = useRTILogic();
  const { handleDiscardBooking } = rtiLogic;

  const outboundRoute = useMemo(() => provisionalBooking?.tripDetails?.trip?.outboundRoute, [provisionalBooking]);
  const inboundRoute = useMemo(() => provisionalBooking?.tripDetails?.trip?.inboundRoute, [provisionalBooking]);

  const editTrip = async () => {
    if (!modifyTripType) await handleDiscardBooking(false);
    editTripDetails(
      navigate,
      provisionalBooking,
      ticketsFormStore.formValues,
      generic.editLabel,
      resetGiftCards,
      setTpNotApplicableDueToGiftCardPayment
    );
  };

  return (
    <>
      {outboundRoute && !modifyTripType && (
        <TripDetailsItem tripItem={outboundRoute} onEditClick={editTrip} isOutbound />
      )}
      {inboundRoute && !modifyTripType && <TripDetailsItem tripItem={inboundRoute} onEditClick={editTrip} />}
    </>
  );
});
