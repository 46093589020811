import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import useDelaysExperienceLoagic from '@account/hooks/useDelaysExperienceLogic';

import { isDelayExperienceEnabled } from 'utils/metas';

import { Tag } from '../Tag';

export interface TrainStatusPillProps {
  status: string | undefined;
  className?: string;
}

export const TrainStatusPill = observer((props: TrainStatusPillProps) => {
  const { status, className } = props;

  const {
    cfStore: {
      account: {
        tripDetails: { delaysExperienceMessages },
      },
    },
  } = useStores();
  const delayExperienceEnabled = isDelayExperienceEnabled();
  const { getStatusLabel } = useDelaysExperienceLoagic();

  if (!delayExperienceEnabled || !status) return null;

  return (
    <Tag variant={status} label={getStatusLabel(delaysExperienceMessages, status)} size="small" className={className} />
  );
});
