import React from 'react';

import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './Carousel.scss';
import { CardProps } from './types';

const bem = cn('carousel');

export const Card = (props: CardProps) => {
  const { image, text, heightDesktop, heightTabletLg, heightTabletSm, heightMobile } = props;

  const vars: any = {
    '--height-desktop': heightDesktop ? `${heightDesktop || 520}px` : '529px',
    '--height-tablet-lg': heightTabletLg ? `${heightTabletLg || 580}px` : '529px',
    '--height-tablet-sm': heightTabletSm ? `${heightTabletSm || 500}px` : '529px',
    '--height-mobile': heightMobile ? `${heightMobile || 320}px` : 'calc(80vw / 3 * 4)',
  };

  return (
    <>
      {image && <Image {...image} className={bem('card-image')} style={vars} />}
      <div className={bem('slide-description')}>
        <Text as="h3" text={text} className={bem('slide-title')} />
      </div>
    </>
  );
};
