import React from 'react';

import { SeatIconProps } from './types';

export const SmartSeat = (props: SeatIconProps) => {
  const { fillColor = 'transparent', width, height, className } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_23570_12781)">
        <path d="M36.94 0.5L7.06 0.5C6.75072 0.5 6.5 0.750721 6.5 1.06V5.94C6.5 6.24928 6.75072 6.5 7.06 6.5H36.94C37.2493 6.5 37.5 6.24928 37.5 5.94V1.06C37.5 0.750721 37.2493 0.5 36.94 0.5Z" />
        <path d="M7.06032 7.99994C6.7898 7.99994 6.52193 7.94666 6.272 7.84313C6.02207 7.73961 5.79497 7.58787 5.60368 7.39658C5.4124 7.20529 5.26066 6.9782 5.15713 6.72827C5.05361 6.47834 5.00032 6.21046 5.00032 5.93994V3.43994C4.81467 3.48911 4.6471 3.5907 4.51765 3.73256C4.3882 3.87443 4.30233 4.05058 4.27032 4.23994L0.800325 23.8899C0.759886 24.1588 0.820447 24.4331 0.970324 24.6599C1.04646 24.7707 1.14415 24.865 1.25755 24.9372C1.37095 25.0093 1.49773 25.0579 1.63032 25.0799L4.55032 25.5899H4.73032C4.97149 25.5927 5.20551 25.5081 5.38928 25.3519C5.57304 25.1957 5.69416 24.9784 5.73032 24.7399L8.68032 7.99994H7.06032Z" />
        <path d="M39.2699 25H39.4499L42.3699 24.48C42.5025 24.4579 42.6293 24.4094 42.7427 24.3372C42.8561 24.265 42.9538 24.1707 43.0299 24.06C43.18 23.837 43.2407 23.5657 43.1999 23.3L39.7299 3.64998C39.6979 3.46061 39.6121 3.28447 39.4826 3.1426C39.3531 3.00073 39.1856 2.89914 38.9999 2.84998V5.93998C38.9999 6.2105 38.9466 6.47837 38.8431 6.7283C38.7396 6.97823 38.5879 7.20533 38.3966 7.39662C38.2053 7.5879 37.9782 7.73964 37.7283 7.84317C37.4783 7.94669 37.2104 7.99998 36.9399 7.99998H35.4199L38.2699 24.17C38.3104 24.4048 38.4334 24.6174 38.6167 24.7696C38.8001 24.9217 39.0317 25.0034 39.2699 25Z" />
        <path d="M36.79 24.43L33.9 8H10.21L7.21 25C7.14755 25.3634 7.00704 25.7089 6.79814 26.0128C6.58925 26.3166 6.31694 26.5716 6 26.76V29.37C6 30.4653 6.43512 31.5158 7.20965 32.2904C7.98417 33.0649 9.03466 33.5 10.13 33.5H33.87C34.4124 33.5 34.9494 33.3932 35.4505 33.1856C35.9516 32.9781 36.4068 32.6739 36.7904 32.2904C37.1739 31.9068 37.4781 31.4516 37.6856 30.9505C37.8932 30.4494 38 29.9124 38 29.37V26.16C37.6856 25.9758 37.4148 25.7258 37.2059 25.4272C36.9971 25.1287 36.8552 24.7885 36.79 24.43Z" />
      </g>
      <defs>
        <clipPath id="clip0_23570_12781">
          <rect width={width} height={height} fill="white" transform="translate(0.790039 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
