import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';

import './EmptyPassDesktop.scss';

const bem = cn('pass-card-empty');

export const EmptyPassDesktop = observer(() => {
  const { cfStore } = useStores();
  const { account } = cfStore;
  const { wallet } = account;

  return (
    <div className={bem()}>
      <div className={bem('content')}>
        <div className={bem('title')}> {wallet.emptyState.desktopHeader}</div>
        <div className={bem('subtitle')}> {wallet.emptyState.desktopBodyLabel}</div>
        <div className={bem('actions')}>
          <Button
            label={wallet.addParkingPassCtaLabel}
            link={{ url: wallet.addParkingPassCtaLink }}
            variant="secondary"
            color="yellow"
            size="small"
          />
          <Button
            label={wallet.addTrainPassCtaLabel}
            link={{ url: wallet.addTrainPassCtaLink }}
            variant="primary"
            color="yellow"
            size="small"
          />
        </div>
      </div>
    </div>
  );
});
