import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Divider from '@atoms/Divider/Divider';
import { CostSummarySections } from '@booking/routes/RTI/CostSummary/CostSummarySections';

import { cn } from 'utils/classNames';
import { color_success_green } from 'utils/colors';

import { AfterSalesDetailItem } from './AfterSalesDetailItem';

const bem = cn('cost-summary-content');

export const CancellationDetailContent = observer(() => {
  const {
    bookingStore,
    cfStore: { account },
    accountStore,
  } = useStores();

  const { provisionalBooking, getTaxesTotal } = bookingStore.rti;
  const { tripDetails } = accountStore;
  const { bookingRules, costSummary } = tripDetails;
  const { additionalFeesTotal } = bookingRules;
  const {
    navigation: { cancelTrip },
  } = account.tripDetails;

  const taxesTotal: any = useMemo(getTaxesTotal, [
    provisionalBooking?.tripDetails?.costSummary?.bookingTotal,
    getTaxesTotal,
  ]);

  const additionalFees = `${additionalFeesTotal !== 0 ? '-' : ''}$${additionalFeesTotal.toFixed(2)}`;

  const originalTotal = costSummary?.bookingTotal?.totalPaid || 0;
  const totalReturned = Math.abs(costSummary?.bookingTotal?.totalToBePaid || 0);

  const numberOfRides = costSummary?.refundMethods.filter(item => item.type == 'travel-pass').length || 0;

  return (
    <>
      <div className={bem()}>
        <CostSummarySections isCancelation />
        <div className={bem('taxes')}>
          <div>{cancelTrip?.taxesAndFeesLabel}</div>
          <div>${Math.abs(taxesTotal).toFixed(2)}</div>
        </div>
        <Divider />
        <AfterSalesDetailItem label={cancelTrip?.originalTotalLabel} price={`$${originalTotal?.toFixed(2)}`} />
        <AfterSalesDetailItem label={cancelTrip?.totalCancellationFeeLabel} price={additionalFees} isGray />
        <Divider />
        <AfterSalesDetailItem
          className={bem('total-returned')}
          label={cancelTrip?.totalReturnedLabel}
          price={`$${totalReturned?.toFixed(2)}`}
          priceColor={color_success_green}
          isBold
        />
        {numberOfRides !== 0 && (
          <AfterSalesDetailItem
            className={bem('total-returned')}
            label={cancelTrip.ridesReturned}
            price={numberOfRides}
            isBold
          />
        )}
      </div>
    </>
  );
});
