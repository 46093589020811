import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

import { publicApi } from 'utils/api';
import { base64Encode } from 'utils/helpers';
import { getCartToken, getLegacyAPIDomain } from 'utils/metas';
import { camelToSnakeCase } from 'utils/stringUtils';

export const useQueries = () => {
  const navigate = useNavigate();

  const appendQueriesToUrl = (url: string, obj, trip?, encode?) => {
    const newUrl = new URL(url.startsWith('/') ? `${window.location.origin}${url}` : url);

    if (encode) {
      const base64urlParams = base64Encode(JSON.stringify(obj));
      newUrl.searchParams.append('data', base64urlParams);
      newUrl.searchParams.append('rn', obj.reference_number);
    } else
      Object.entries(obj).forEach(([key, val]: [string, string]) => {
        if (val) {
          const value = key.includes('Date') ? moment(val).format('YYYY-MM-DD') : val;
          newUrl.searchParams.append(camelToSnakeCase(key), value);
        }
      });

    if (trip === 0 || trip === 1) newUrl.searchParams.append('trip', trip);

    return decodeURI(newUrl.href);
  };

  const appendPreviousQueriesUrl = async (url: string, obj, trip?, encode?) => {
    const newUrl = new URL(url);
    const user = localStorage.getItem('bl-user');

    const prevKeys = {
      from: 'routes[0][from]',
      to: 'routes[0][to]',
      adults: 'passengers[0][count]',
      kids: 'passengers[1][count]',
      infants: 'passengers[2][count]',
      startDate: 'routes[0][date]',
      endDate: 'routes[1][date]',
      promoCode: 'promoCode',
      // these are manage trips related keys
      lastName: 'lastName',
      referenceNumber: 'confirmation_number',
    };

    if (encode) {
      const base64urlParams = base64Encode(JSON.stringify(obj));
      newUrl.searchParams.append('data', base64urlParams);
      newUrl.searchParams.append('rn', obj.reference_number);
    } else
      Object.entries(obj).forEach(([key, val]: [string, string]) => {
        const value = key.includes('Date') ? moment(val).format('YYYY-MM-DD') : val;
        const newKey = prevKeys[key];

        if (newKey) newUrl.searchParams.append(newKey, value);
      });

    if (typeof obj.adults !== undefined) newUrl.searchParams.append('passengers[0][code]', 'ADT');
    if (typeof obj.kids !== undefined) newUrl.searchParams.append('passengers[1][code]', 'CHD');
    if (typeof obj.infants !== undefined) newUrl.searchParams.append('passengers[2][code]', 'INF');

    if (obj.from) {
      newUrl.searchParams.append('currency', 'USD');
      newUrl.searchParams.append('isMultiCity', 'false');
      newUrl.searchParams.append('lowestFare', 'false');
    }

    if (trip) {
      newUrl.searchParams.append('routes[1][from]', obj.to);
      newUrl.searchParams.append('routes[1][to]', obj.from);
    } else newUrl.searchParams.delete('routes[1][date]');

    if (user) {
      const data = JSON.parse(user);

      if (data.token)
        try {
          const response = await publicApi.get(`${getLegacyAPIDomain()}/api/v1/radixx/tokens`, {
            headers: {
              'x-access-token': data.token,
              authorization: `Basic ${getCartToken()}`,
            },
          });

          const { token } = response.data.data;

          if (token) newUrl.searchParams.append('profileAuthorizationToken', token);
        } catch (e) {
          console.error(e);
        }
    }

    return decodeURI(newUrl.href);
  };

  const appendQueriesToRouter = (page, obj, encode?) => {
    const url = appendQueriesToUrl(window.location.href, obj, '', encode);
    navigate(`${page}?${url.split('?')[1]}`);
  };

  const appendQueries = async (page, deprecatedPage, values, trip?, encode?) => {
    if (document.getElementById('kiosk-root')) appendQueriesToRouter(page, values, encode);
    else
      window.location.href = deprecatedPage
        ? await appendPreviousQueriesUrl(page, values, trip, encode)
        : appendQueriesToUrl(page, values, trip, encode);
  };

  return {
    appendQueriesToRouter,
    appendQueriesToUrl,
    appendPreviousQueriesUrl,
    appendQueries,
  };
};
