import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import { useNavigationLogic } from './../useNavigationLogic';
import './TicketsAndReceipts.scss';

const bem = cn('trip-details-navigation-tickets-and-receipts');

export const TicketsAndReceipts = observer(() => {
  const { cfStore, accountStore } = useStores();
  const { hasReceiptError } = accountStore.tripDetails;
  const { ticketsAndReceiptsSection } = cfStore.account.tripDetails.navigation;
  const [error, setError] = useState(false);
  const { handleClick, handleTicketDownload, handleReceiptDownload } = useNavigationLogic();

  return (
    <div className={bem()}>
      <div className={bem('icon')}>
        <Icon name="receipt" />
      </div>

      <div className={bem('content')}>
        <div className={bem('title')}>{ticketsAndReceiptsSection.title}</div>
        <div className={bem('items')}>
          {ticketsAndReceiptsSection.downloadTickets && (
            <button onClick={handleTicketDownload}>{ticketsAndReceiptsSection.downloadTickets}</button>
          )}
          {ticketsAndReceiptsSection.downloadReceipt && (
            <button
              onClick={() => {
                hasReceiptError ? setError(true) : handleReceiptDownload();
              }}
            >
              {ticketsAndReceiptsSection.downloadReceipt}
            </button>
          )}
          {ticketsAndReceiptsSection.downloadDetails && (
            <button onClick={handleClick}>{ticketsAndReceiptsSection.downloadDetails}</button>
          )}
        </div>
      </div>
      {error && (
        <Modal className={bem('receipt-error')}>
          <div className={bem('receipt-error-text')}>
            <h1>{ticketsAndReceiptsSection.receiptErrorHeading}</h1>
            <p>{ticketsAndReceiptsSection.receiptErrorText}</p>
          </div>
          <Button
            className={bem('receipt-error-button')}
            label={ticketsAndReceiptsSection.receiptErrorButtonLabel}
            onClick={() => {
              setError(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
});
