import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStores } from 'stores';

import { userLoginSuccess } from 'utils/adobeDataLayer';
import {
  clearLoginRedirectURL,
  clearLogoutRedirectURL,
  getLoginRedirectURL,
  getLogoutRedirectURL,
} from 'utils/localstorage';

export const useRootRedirections = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = useStores();
  const { isAuthenticated, auth0Client, user } = authStore;

  useEffect(() => {
    if (location.pathname === '/') {
      if (auth0Client)
        if (isAuthenticated) {
          userLoginSuccess(location, user.email);
          const redirectURL = getLoginRedirectURL();
          if (redirectURL) {
            clearLoginRedirectURL();
            window.location.replace(redirectURL);
          } else navigate('/dashboard/');
        } else {
          const redirectURL = getLogoutRedirectURL();
          if (redirectURL) {
            clearLogoutRedirectURL();
            window.location.replace(redirectURL);
          } else navigate('/login/');
        }
    } else document.body.classList.remove('is-hidden');
  }, [isAuthenticated, location, auth0Client, navigate, user]);
};
