import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import StickyBox from 'react-sticky-box';
import { rootStore, useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Column, Grid } from '@atoms/Grid';
import { Text } from '@atoms/Text';
import { Title } from '@atoms/Title';
import { TpDisabledDueToGCBanner } from '@booking/routes/RTI/TpDisabledDueToGCBanner';
import { Drawer } from '@molecules/Drawer';
import { Modal } from '@molecules/Modal';

import { pageLoadEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import '../TrainSelection/TrainSelection.scss';
import { CardStates } from './Card/constants';
import { CostSummary } from './CostSummary';
import { CostSummaryTotalMobile } from './CostSummary/CostSummaryTotalMobile';
import { Timer } from './CostSummary/Timer/Timer';
import { Extras } from './Extras';
import ModifyTripHeaderRTI from './ModifyTripHeaderRTI';
import { PassengersInfo } from './PassengersInfo';
import { PaymentInfo } from './PaymentInfo';
import './RTI.scss';
import { SeatSelection } from './SeatSelection';
import { TripDetails } from './TripDetails';
import { TripDetailsItems } from './TripDetails/TripDetailsItems';
import { stepNames } from './constants';
import { useRTILogic } from './useRTILogic';

export const bem = cn('rti-step');

export const RTI = observer(() => {
  const { bookingStore, cfStore } = useStores();

  const {
    enableDiscardBookingFeatureModal,
    discardButtonLabel,
    discardButtonColor,
    cancelButtonLabel,
    cancelButtonColor,
    description,
    title,
  } = cfStore.rti.discardBookingModal;

  const { provisionalBooking, updateLoyaltyDisplayStatus, modifyTripType } = bookingStore.rti;

  const isMaxTablet = useMediaPredicate(mediaQueryList.maxTablet);

  const rtiLogic = useRTILogic();

  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const {
    modifyTripHeaderRTIProps,
    getCardMode,
    passengersNumber,
    handleToggleDrawerVisible,
    drawerVisible,
    costSummaryItemsLength,
    minutesRemaining,
    secondsRemaining,
    formattedTime,
    handleDiscardBooking,
  } = rtiLogic;

  const handleCloseDiscardModal = () => {
    setBackButtonClicked(false);
    if (enableDiscardBookingFeatureModal && !modifyTripType) window.history.pushState(null, '', window.location.href);
  };

  useEffect(() => {
    if (!enableDiscardBookingFeatureModal || modifyTripType) return undefined;

    window.history.pushState(null, '', window.location.href);

    const handlePopState = e => {
      e.preventDefault();
      setBackButtonClicked(true);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [enableDiscardBookingFeatureModal, modifyTripType]);

  useEffect(() => {
    updateLoyaltyDisplayStatus();
  }, [updateLoyaltyDisplayStatus]);

  useEffect(() => pageLoadEvent(rootStore), []);

  if (!provisionalBooking || !provisionalBooking.tripDetails || !provisionalBooking.bookingSession) return null;

  return (
    <div className={bem()}>
      {isMaxTablet && (
        <>
          <TpDisabledDueToGCBanner />
          <div className={bem('mobile-timer')}>
            <Timer
              minutesRemaining={minutesRemaining}
              secondsRemaining={secondsRemaining}
              formattedTime={formattedTime}
            />
          </div>
          <ModifyTripHeaderRTI {...modifyTripHeaderRTIProps} />
          <TripDetails mode={CardStates.finished} />
          <PassengersInfo
            stepNr={1}
            mode={getCardMode(stepNames.passengerInfo)}
            passengersTypeNumber={passengersNumber}
          />
          <SeatSelection stepNr={2} mode={getCardMode(stepNames.seatAssignment)} />
          <Extras stepNr={3} mode={getCardMode(stepNames.extras)} />
          <PaymentInfo stepNr={4} mode={getCardMode(stepNames.paymentInfo)} />
          <CostSummaryTotalMobile onClick={handleToggleDrawerVisible} />
          <Drawer
            isVisible={drawerVisible}
            onClose={handleToggleDrawerVisible}
            costSummaryItemsLength={costSummaryItemsLength}
            isMobileRti
            disableAutoZoom
          >
            <CostSummary isRtiDrawer>
              <TripDetailsItems />
            </CostSummary>
          </Drawer>
        </>
      )}
      {!isMaxTablet && (
        <>
          <TpDisabledDueToGCBanner />
          <Grid>
            <ModifyTripHeaderRTI {...modifyTripHeaderRTIProps} />
          </Grid>
          <Grid>
            <Column cols={7} mq={{ desktop: { cols: 6, offset: 1 } }}>
              <PassengersInfo
                stepNr={1}
                mode={getCardMode(stepNames.passengerInfo)}
                passengersTypeNumber={passengersNumber}
              />
              <SeatSelection stepNr={2} mode={getCardMode(stepNames.seatAssignment)} />
              <Extras stepNr={3} mode={getCardMode(stepNames.extras)} />
              <PaymentInfo stepNr={4} mode={getCardMode(stepNames.paymentInfo)} />
            </Column>

            <Column cols={5} mq={{ desktop: { cols: 4 } }}>
              <StickyBox offsetTop={10}>
                <CostSummary
                  minutesRemaining={minutesRemaining}
                  secondsRemaining={secondsRemaining}
                  formattedTime={formattedTime}
                >
                  <TripDetailsItems />
                </CostSummary>
              </StickyBox>
            </Column>
          </Grid>
        </>
      )}
      {enableDiscardBookingFeatureModal && backButtonClicked && !modifyTripType && (
        <Modal variant="mini">
          <div className={bem('discard-booking')}>
            <Title text={title} size="h3" />
            <Text text={description} />
            <div className={bem('discard-booking-buttons')}>
              <Button
                label={discardButtonLabel}
                onClick={() => handleDiscardBooking(true)}
                colors={{ backgroundOrBorder: discardButtonColor }}
              />
              <Button
                label={cancelButtonLabel}
                onClick={handleCloseDiscardModal}
                colors={{ backgroundOrBorder: cancelButtonColor }}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
});
