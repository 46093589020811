import React from 'react';

import { Drawer } from '@molecules/Drawer';

import { cn } from 'utils/classNames';

import { EditPassModal } from '../EditPassModal';
import './EditPassModalMobile.scss';

const bem = cn('wallet-edit-pass-modal-mobile');

export const EditPassModalMobile = ({ useEditPass }) => {
  const { hideModal } = useEditPass;

  return (
    <Drawer onClose={hideModal} className={bem('drawer')} isVisible>
      <EditPassModal useEditPass={useEditPass} bem={bem} />
    </Drawer>
  );
};
