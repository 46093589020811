import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Image from '@atoms/Image/Image';
import Radio from '@atoms/Radio/Radio';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { SaveCardProps } from '../types';
import './SavedCardItem.scss';

const bem = cn('passes-card-item');

export const SavedCardItem = observer((props: SaveCardProps) => {
  const { card, noAmountToPay } = props;

  const { cfStore, passesStore } = useStores();
  const { savedCards } = passesStore.checkout;

  const { selectedCard, setSelectedCard } = passesStore.checkout;
  const cfLabels = cfStore.passes.reviewAndPay.cardInformation;

  const handleOnClick = () => {
    if (noAmountToPay) return;

    setSelectedCard(card);
  };

  const cardConfig = useMemo(
    () => ({
      image: cfLabels.cardImages.find(
        image =>
          image?.title?.toLowerCase() === card.brand?.toLowerCase() ||
          image?.title?.toLowerCase() === card.name?.toLowerCase()
      )?.image,
      name: card.name || card.brand,
      number: card.lastFour ? `${'*'.repeat(4)}${card.lastFour}` : '',
      selected: selectedCard?.tokenId === card.tokenId,
    }),
    [card, cfLabels, selectedCard]
  );

  const isDisabled = savedCards.length >= 5 && passesStore.checkout.urlParams.autoRenews;
  const isRadioButtonDisabled = (card.tokenId === 'new-card' && isDisabled) || noAmountToPay;

  const selectedPaymentItems = () => {
    if (isRadioButtonDisabled) return false;
    if (card.tokenId === 'new-card') return !isDisabled ? cardConfig.selected : false;

    return cardConfig.selected;
  };

  return (
    <div
      className={bem({
        savedCard: card.tokenId !== 'new-card',
        selected: selectedPaymentItems(),
        disabled: isRadioButtonDisabled,
      })}
      onClick={handleOnClick}
    >
      <Image {...cardConfig.image} className={bem('card-image')} />
      <div className={bem('card-details')}>
        <Text
          text={`${cardConfig.name} ${cardConfig.number}`}
          className={bem('card-details-text', { disabled: isRadioButtonDisabled })}
        />
        <Radio
          name="cardID"
          label=""
          onChange={handleOnClick}
          checked={!isRadioButtonDisabled && cardConfig.selected}
          disabled={isRadioButtonDisabled}
          className={bem('card-details-checkbox', { disabled: isRadioButtonDisabled })}
        />
      </div>
    </div>
  );
});
