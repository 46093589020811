import { makeAutoObservable } from 'mobx';

import { ManageTripsFormValues } from '@molecules/ManageTripsForm/types';

export default class ManageTripsFormStore {
  isPanelVisible = false;
  formValues: ManageTripsFormValues = {
    referenceNumber: '',
    lastName: '',
    firstName: '',
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  hidePanel() {
    this.isPanelVisible = false;
  }

  showPanel() {
    this.isPanelVisible = true;
  }

  setFormValues(formValues) {
    this.formValues = formValues;
  }
}
