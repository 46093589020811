import React, { ComponentType } from 'react';

import { cn, cx } from 'utils/classNames';

const font = cn('font');
const padding = cn('padding');
const margin = cn('margin');

// eslint-disable-next-line
export function withStyleSystem<T>(WrappedComponent: ComponentType<T>) {
  return props => {
    const {
      fontVariant,
      verticalPadding,
      horizontalPadding,
      topPadding,
      bottomPadding,
      leftPadding,
      rightPadding,
      verticalMargin,
      horizontalMargin,
      customClassName,
      mediaQuerySpacing,
      ...rest
    } = props;

    const mqClasses: any = [];

    for (const keyBreakpoint in mediaQuerySpacing) {
      mediaQuerySpacing[keyBreakpoint].topPadding &&
        mqClasses.push(cn(`padding--${keyBreakpoint}-top-${mediaQuerySpacing[keyBreakpoint].topPadding}`)());
      mediaQuerySpacing[keyBreakpoint].bottomPadding &&
        mqClasses.push(cn(`padding--${keyBreakpoint}-bottom-${mediaQuerySpacing[keyBreakpoint].bottomPadding}`)());
      mediaQuerySpacing[keyBreakpoint].leftPadding &&
        mqClasses.push(cn(`padding--${keyBreakpoint}-left-${mediaQuerySpacing[keyBreakpoint].leftPadding}`)());
      mediaQuerySpacing[keyBreakpoint].rightPadding &&
        mqClasses.push(cn(`padding--${keyBreakpoint}-right-${mediaQuerySpacing[keyBreakpoint].rightPadding}`)());
    }

    const classNames = cx(
      customClassName,
      font({ variant: fontVariant }),
      padding({
        horizontal: horizontalPadding,
        vertical: verticalPadding,
        top: topPadding,
        bottom: bottomPadding,
        left: leftPadding,
        right: rightPadding,
      }),
      margin({
        horizontal: horizontalMargin,
        vertical: verticalMargin,
      }),
      ...mqClasses
    );

    return (
      <div className={classNames}>
        <WrappedComponent {...rest} />
      </div>
    );
  };
}
