import { FormikProps, FormikValues } from 'formik';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import { useStores } from 'stores';
import { ParkingRequestBody } from 'stores/BookingStore/sections/Parking/types';

export const useParkingModalLogic = (onCloseRef?) => {
  const { bookingStore, cfStore } = useStores();

  const { rti, parking } = bookingStore;
  const {
    data,
    modalSelectedPassengers,
    previouslySelectedPassengers,
    setModalSelectedPassengers,
    setPreviouslySelectedPassengers,
    modalNumberOfDays,
    setModalNumberOfDays,
  } = parking;
  const { provisionalBooking, setProvisionalBooking } = rti;

  const parkingLabels = cfStore.rti.parking;

  const booking = provisionalBooking.tripDetails?.trip;

  const requiredStates = ['RRN', 'AVE', 'WPT', 'FBT'];

  const [isFormModified, setIsFormModified] = useState(false);

  const [buttonClick, setButtonClick] = useState(false);

  const iconsList = useMemo(
    () => [
      { name: 'circle-arrow', label: parkingLabels.open247IconLabel || '' },
      { name: 'car-electric', label: parkingLabels.chargingIconLabel || '' },
      { name: 'lock-shield', label: parkingLabels.securityIconLabel || '' },
      { name: 'walking-right', label: parkingLabels.walkToStationIconLabel || '' },
    ],
    [parkingLabels]
  );

  const startDate = useMemo(() => {
    if (!booking || !booking.outboundRoute) return moment();

    const outboundDepartureTimezone = booking.outboundRoute.origin.timeZone || 'America/New_York';

    const startingParkingTime = moment(booking.outboundRoute.departureDateTime)
      .tz(outboundDepartureTimezone)
      .set({ hour: 4, minute: 0 });
    const departureTime = moment(booking.outboundRoute.departureDateTime).tz(outboundDepartureTimezone);

    return departureTime.isBefore(startingParkingTime) ? startingParkingTime.add({ days: -1 }) : startingParkingTime;
  }, [booking]);

  const endDate = useMemo(() => {
    if (!booking) return moment();
    let numberOfDays = 1;
    if (modalNumberOfDays.length > 0) numberOfDays = modalNumberOfDays[0].numberOfDays;

    const outboundArrivalTimezone = booking.outboundRoute.destination.timeZone || 'America/New_York';

    const endParkingTime = moment(booking?.inboundRoute?.arrivalDateTime || booking?.outboundRoute?.arrivalDateTime)
      .tz(outboundArrivalTimezone)
      .set({
        hour: 3,
        minute: 59,
      });
    booking.inboundRoute ? endParkingTime.add({ days: 1 }) : null;

    return !booking.inboundRoute ? endParkingTime.add({ days: numberOfDays }) : endParkingTime;
  }, [booking, modalNumberOfDays]);

  const daysDifference = useMemo(() => {
    const diff = endDate.diff(startDate, 'days', false);

    return diff + 1;
  }, [startDate, endDate]);

  const totalPrice = () => {
    const passengersData = data?.eligibleDrivers.filter(driver => modalSelectedPassengers.includes(driver.passengerId));

    if (!passengersData || !passengersData.length) return 0;
    const totalNumberOfDays = daysDifference * passengersData.length;

    return passengersData[0].pricePerDay * totalNumberOfDays || 0.0;
  };

  const calculateDays = currentSelections => {
    if (!currentSelections) return 1;

    const startTime = moment.utc(currentSelections.startDate);
    const endTime = moment.utc(currentSelections.endDate);
    const diff = endTime.diff(startTime, 'days', false) + 1;

    return diff < 1 ? 1 : diff;
  };

  const initialFormValues = useMemo(() => {
    const initialValues = {};

    const selectedPassengers: string[] = [];
    data?.eligibleDrivers.forEach(driver => {
      if (driver) {
        initialValues[`${driver.passengerId}_license`] = driver.currentSelections?.licensePlate?.number || '';
        initialValues[`${driver.passengerId}_state`] = driver.currentSelections?.licensePlate?.state || 'Florida';
        initialValues[`${driver.passengerId}_numberOfDays`] = calculateDays(driver.currentSelections);
        !!driver.currentSelections && selectedPassengers.push(driver.passengerId);
      }
    });
    const modalNumberOfDaysArray = selectedPassengers.map(id => ({
      passengerId: id,
      numberOfDays: initialValues[`${id}_numberOfDays`],
    }));

    setModalSelectedPassengers(selectedPassengers);
    setPreviouslySelectedPassengers(selectedPassengers);

    setModalNumberOfDays(modalNumberOfDaysArray);

    setIsFormModified(false);

    return initialValues;
  }, [data?.eligibleDrivers, setModalNumberOfDays, setModalSelectedPassengers, setPreviouslySelectedPassengers]);

  const generateBodyItems = (values, modalSelectedPassengers) => {
    const items: ParkingRequestBody[] = [];

    data?.eligibleDrivers.forEach(driver => {
      const { passengerId } = driver;
      const licensePlateNumber = values[`${passengerId}_license`] || '';
      const stateId = values[`${passengerId}_state`] || '';

      const isSelected = modalSelectedPassengers.includes(passengerId);

      items.push({
        passengerId: passengerId,
        licensePlateInformation: {
          number: licensePlateNumber,
          state: stateId,
        },
        dateSelectionInterval: {
          startDate: startDate.utc().toISOString(),
          endDate: endDate.utc().toISOString(),
        },
        productCode: driver.productCode,
        tariffCode: driver.tariffCode,
        quantity: isSelected ? 1 : 0,
      });
    });

    return items;
  };

  const handleAddParkingToTripButtonClicked = async values => {
    const referenceNumber = provisionalBooking.tripDetails?.trip?.referenceNumber;
    const bookingSession = provisionalBooking.bookingSession;

    const initialItems = generateBodyItems(initialFormValues, previouslySelectedPassengers);
    const items = generateBodyItems(values, modalSelectedPassengers);

    await parking.addBookingParking(items, referenceNumber, bookingSession, setProvisionalBooking, initialItems);

    !parking.addParkingError && parking.hideModal();
    onCloseRef.current.focus();
  };

  const isUpdateButton = useMemo(
    () =>
      data?.eligibleDrivers.some(
        passenger =>
          !!passenger?.currentSelections?.licensePlate?.number ||
          !!passenger?.currentSelections?.licensePlate?.state ||
          !!passenger?.currentSelections?.numberOfDays
      ),
    [data?.eligibleDrivers]
  );

  const validateFormFields = (values, formRef: FormikProps<FormikValues>) => {
    const stationId = data?.station.id;
    let isValid = true;

    if (stationId && requiredStates.includes(stationId))
      modalSelectedPassengers.forEach(passengerId => {
        const state = values[`${passengerId}_state`];
        const license = values[`${passengerId}_license`];
        const numberOfDays = values[`${passengerId}_numberOfDays`];

        if (!state || !license || !numberOfDays) {
          isValid = false;
          !state && formRef.setFieldError(`${passengerId}_state`, cfStore.generic.requiredInputLabel);
          !license && formRef.setFieldError(`${passengerId}_license`, cfStore.generic.requiredInputLabel);
          !numberOfDays && formRef.setFieldError(`${passengerId}_numberOfDays`, cfStore.generic.requiredInputLabel);
        }
      });

    return isValid;
  };

  return {
    handleAddParkingToTripButtonClicked,
    initialFormValues,
    validateFormFields,
    requiredStates,
    totalPrice,
    iconsList,
    startDate,
    endDate,
    daysDifference,
    isUpdateButton,
    isFormModified,
    setIsFormModified,
    buttonClick,
    setButtonClick
  };
};
