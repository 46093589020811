import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { RootStore } from 'stores';

import { Auth0Client, createAuth0Client, User } from '@auth0/auth0-spa-js';
import { Router } from '@remix-run/router/dist/router';

import { getAuth0ClientId, getAuth0Domain, isLoyaltyEnabled } from 'utils/metas';

import {
  clearPublicBookingToken,
  clearShowLoyaltyOptInModalFlag,
  getShowLoyaltyOptInModalFlag,
  setShowLoyaltyOptInModalFlag,
} from '../utils/localstorage';

export default class AuthStore {
  private readonly rootStore: RootStore;

  auth0Client: Auth0Client;
  isAuthenticated = false;
  isLoading = false;
  user: User;
  email: string;
  router: Router;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: 'AuthStore',
      properties: ['auth0Client', 'isAuthenticated', 'isLoading', 'user'],
      stringify: true,
      storage: window.localStorage,
    });

    this.rootStore = rootStore;
  }

  setAuth0Client(auth0Client) {
    this.auth0Client = auth0Client;
  }

  setIsAuthenticated(isAuthenticated) {
    this.isAuthenticated = isAuthenticated;
  }

  setUser(user) {
    this.user = user;
  }

  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  setRouter(router) {
    this.router = router;
  }

  configureClient = async () => {
    this.setLoading(true);

    const query = window.location.search;
    const shouldParseResult = query.includes('code=') && query.includes('state=');

    const auth0Client = await createAuth0Client({
      domain: getAuth0Domain(),
      clientId: getAuth0ClientId(),
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    });

    if (shouldParseResult) {
      await auth0Client.handleRedirectCallback();
      if (isLoyaltyEnabled())
        getShowLoyaltyOptInModalFlag() === 'false'
          ? clearShowLoyaltyOptInModalFlag()
          : setShowLoyaltyOptInModalFlag('true');

      clearPublicBookingToken();
    }
    const isAuthenticated = await auth0Client.isAuthenticated();
    const user = await auth0Client.getUser();

    this.setIsAuthenticated(isAuthenticated);
    this.setUser(user);
    this.setAuth0Client(auth0Client);
    this.setLoading(false);
  };
}
