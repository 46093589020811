import dayjs from "dayjs";

export const initialFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    postalCode: '',
    dateOfBirth: '',
    disclaimerCheckbox: true,
};

export const dateConfig = {
    dateFormat: 'MM/DD/YYYY',
    minDate: '10/10/1900',
    intermediateDate: '10/10/2010',
    maxDate: dayjs().subtract(1, 'day'),
};