import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { isEditMode } from './metas';

const INITIALIZED = new Map();
const REACT_COMPONENT_ATTR = '[data-cmp-react]';

const loadModule = (element: HTMLElement, module, componentData = {}) => {
  const { cmpReact: name, cmpReactHydrate, cmpPreventMount, ...props } = element.dataset;
  const hasComponent = INITIALIZED.get(name);
  const Component = hasComponent ? hasComponent : module;

  // if is true, sightly doesn't add the "true" value; instead adds an empty data attribute
  if ('cmpPreventMount' in element.dataset && cmpPreventMount !== 'false') return;

  if (cmpReactHydrate === 'true') hydrateRoot(element, <Component {...props} {...componentData} />);
  else {
    const portal = element.querySelector('.blte-portal');
    if (portal)
      createRoot(portal).render(
        <BrowserRouter>
          <Component {...props} {...componentData} el={element} />
        </BrowserRouter>
      );
    else
      createRoot(element).render(
        <BrowserRouter>
          <Component {...props} {...componentData} el={element} />
        </BrowserRouter>
      );
  }

  if (!hasComponent) INITIALIZED.set(name, module);
};

const loadModules = (node: HTMLElement, modules) => {
  if (!(node && node.querySelectorAll)) return;

  const scripts = node.querySelectorAll('script[data-cmp-instance]');
  const reactEls = [...node.querySelectorAll(REACT_COMPONENT_ATTR)];

  if (node.dataset.reactComponent) reactEls.push(node);

  const jsonDataList = Array.from(scripts).reduce((acc, el: HTMLElement) => {
    const instance = el.dataset.cmpInstance;
    if (!instance || !el.textContent) return acc;

    return { ...acc, [instance]: JSON.parse(el.textContent) };
  }, {});

  reactEls.forEach((el: HTMLElement) => {
    const { cmpReact, cmpInstance } = el.dataset;
    if (cmpReact && cmpInstance && modules[cmpReact]) loadModule(el, modules[cmpReact], jsonDataList[cmpInstance]);
    else console.debug(`${cmpReact} not found`);
  });
};

const updateOnMutation = modules => {
  const observer = new MutationObserver(mutations => {
    mutations.forEach(({ addedNodes }) => {
      const nodesArray = [].slice.call(addedNodes);
      if (addedNodes.length > 0)
        nodesArray.forEach(addedNode => {
          loadModules(addedNode, modules);
        });
    });
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
};

const initializeModules = components => {
  const modules = components.reduce((acc, cur) => ({ ...acc, ...cur }), {});
  loadModules(document.body, modules);
  // Resets items during authoring
  if ((isEditMode() || process.env.NODE_ENV) && MutationObserver) updateOnMutation(modules);
};

export default initializeModules;
