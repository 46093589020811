import { useMemo } from 'react';
import * as Yup from 'yup';

export const useValidationSchema = data =>
  useMemo(() => {
    const zipCodeRegex = /^\d+$/;
    const alphabeticRegex = /^[A-Za-z\s]+$/;
    const alphabeticWithSpecialCharactersRegex = /^[\p{L}\s.'-]+$/u;

    return Yup.object().shape({
      firstName: Yup.string()
        .matches(alphabeticWithSpecialCharactersRegex, data.invalidInputLabel)
        .required(data.requiredInputLabel),
      lastName: Yup.string()
        .matches(alphabeticWithSpecialCharactersRegex, data.invalidInputLabel)
        .required(data.requiredInputLabel),
      email: Yup.string().email(data.invalidInputLabel).required(data.requiredInputLabel),
      zipCode: Yup.string().matches(zipCodeRegex, data.invalidInputLabel),
      city: Yup.string().matches(alphabeticRegex, data.invalidInputLabel),
    });
  }, [data]);
