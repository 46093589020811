import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { useParkingModalLogic } from '@booking/routes/RTI/Extras/components/Parking/components/Modal/useParkingModalLogic';

import { cn } from 'utils/classNames';

import './ParkingDetails.scss';
import moment from 'moment';

const bem = cn('parking-details');

const DATE_FORMAT = 'ddd, MMM D (h:mm a)';
const DATE_FORMAT_INBOUND = 'ddd, MMM D'
const DATE_FORMAT_PARKING = 'M/D/YY h:mm A';


export const ParkingDetails = observer(() => {
  const { cfStore, bookingStore } = useStores();
  const { provisionalBooking } = bookingStore.rti
  const booking = provisionalBooking.tripDetails?.trip;
  const originalStartDate = moment(booking?.outboundRoute?.departureDateTime)
  const originalEndDate = booking?.inboundRoute?.departureDateTime ? moment(booking?.inboundRoute?.departureDateTime) : false ;
  const { startDate, endDate } = useParkingModalLogic();
  const { data } = bookingStore.parking;
  const parking = cfStore.rti.parking;

  const parkingConfig = useMemo(
    () => ({
      startTime: startDate.format(DATE_FORMAT_PARKING),
      endTime: endDate.format(DATE_FORMAT_PARKING),
    }),
    [startDate, endDate]
  );

  if (!data) return <></>;

  const parkingDetails = data.parkingDetails;
  const station = data.station;

  return (
    <div>
      <Text as="h3" className={bem('parkingTitle')} text={`${parking.parkingAtLabel} ${station?.name}`} />
      <div className={bem()}>
        <Text className={bem('title')} text="Dates" />
        <div className={bem('dates')}>
          <Text text={originalStartDate.format(originalEndDate ? DATE_FORMAT : DATE_FORMAT_INBOUND)} />
          {originalEndDate && (
            <>
              <Icon name="arrow-from-to" className={bem('icon')} />
              <Text text={originalEndDate.format(DATE_FORMAT)} />
            </>
          )}
        </div>

        <Text className={bem('title')} text='Parking' />
        <div className={bem('datesParking')}>
          <Text text={`Valid from ${parkingConfig.startTime} to ${parkingConfig.endTime}`} />
        </div>

        {parkingDetails && (
          <>
            <Text className={bem('title')} text="Location" />
            <Text text={parkingDetails.address} />
          </>
        )}
      </div>
    </div>
  );
});
