import { FormikProps, FormikValues } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useStores } from 'stores';
import { PutExtrasRequest } from 'stores/BookingStore/sections/Extras/types';

import { ItemCardConfig } from './../ModalLoader/types';

export const useExtrasModalLogic = (initialFormValues, itemCards: ItemCardConfig[], analyticsEventType) => {
  const { bookingStore } = useStores();
  const { setActiveModalCF, addBookingExtras } = bookingStore.extras;

  const [isFormModified, setIsFormModified] = useState(false);
  const [total, setTotal] = useState(0);

  const formRef = useRef<FormikProps<FormikValues>>(null);

  const calculateTotal = useCallback(() => {
    if (!formRef.current?.values) return;

    const formValues = formRef.current.values;

    let total = 0;
    itemCards?.forEach((item, index) => {
      const itemTotalPrice = Object.entries(formValues)
        .filter(([key]) => key.startsWith(`inbound${index}`) || key.startsWith(`outbound${index}`))
        .reduce((acc, [key, value]) => {
          const numSelectedItems = parseInt(value);

          const passengerID = key.split('-')[1];

          let price;
          if (key.startsWith('inbound')) price = item.passengers[passengerID]?.inboundItems[numSelectedItems]?.price;
          else price = item.passengers[passengerID]?.outboundItems[numSelectedItems]?.price;

          return acc + (price || 0);
        }, 0);
      total += itemTotalPrice;
    });

    setTotal(total);
  }, [itemCards]);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal]);

  const checkApplyAllCheckbox = useCallback(
    formRef => {
      if (!formRef.current?.values) return;
      const formValues = formRef.current.values;
      itemCards?.forEach((item, index) => {
        let isCheckboxDisabled = false;

        //logic for checking if APPLY-ALL checkbox should be DISABLED
        Object.entries(formValues)
          .filter(([key]) => key.startsWith(`outbound${index}`) || key.startsWith(`inbound${index}`))
          .forEach(([key, value]) => {
            const passengerID = key.split('-')[1];

            const selectedQuantity = parseInt(value as string);

            const maxNumItems = key.startsWith('outbound')
              ? item.passengers[passengerID]?.inboundItems?.length - 1 || 0
              : item.passengers[passengerID]?.outboundItems?.length - 1 || 0;

            if (selectedQuantity > maxNumItems) isCheckboxDisabled = true;
          });

        if (isCheckboxDisabled) {
          formRef.current.setFieldValue(`applyAll_${index}_disabled`, true);
          formRef.current.setFieldValue(`applyAll_${index}`, false);

          return;
        }
        formRef.current.setFieldValue(`applyAll_${index}_disabled`, false);

        //logic for checking if APPLY-ALL checkbox should be checked
        const numSelectedOutboundItems = Object.entries(formValues)
          .filter(([key]) => key.startsWith(`outbound${index}`))
          .reduce((acc, [, value]) => acc + (parseInt(value as string) || 0), 0);

        const numSelectedInboundItems = Object.entries(formValues)
          .filter(([key]) => key.startsWith(`inbound${index}`))
          .reduce((acc, [, value]) => acc + (parseInt(value as string) || 0), 0);

        if (numSelectedOutboundItems === 0 && numSelectedInboundItems === 0) return;

        const applyAllValue = formRef.current.getFieldProps(`applyAll_${index}`).value;
        if (applyAllValue === undefined) formRef.current.setFieldValue(`applyAll_${index}`, true);
      });
    },
    [itemCards]
  );

  const handleFormFieldChanged = useCallback(() => {
    // Ensure formRef.current is defined before proceeding
    if (!formRef.current || !formRef.current.values) return;

    // Filter out "_disabled" formik values as they impact the button enable/disable
    // since "_disabled" values are not part of the initial form values
    let currentFormValues = formRef.current.values;
    currentFormValues = Object.keys(currentFormValues)
      .filter(key => !key.endsWith('disabled'))
      .reduce((obj, key) => {
        obj[key] = currentFormValues?.[key]; // Use non-null assertion operator to avoid TypeScript errors

        return obj;
      }, {});

    setIsFormModified(!_.isEqual(initialFormValues, currentFormValues));

    calculateTotal();
    checkApplyAllCheckbox(formRef);

    itemCards?.forEach((item, index) => {
      const applyAllValue = formRef.current?.getFieldProps(`applyAll_${index}`).value;
      if (applyAllValue)
        Object.entries(currentFormValues)
          .filter(([key]) => key.startsWith(`outbound${index}`))
          .forEach(([key, value]) => {
            const passengerID = key.split('-')[1];
            formRef.current?.setFieldValue(`inbound${index}-${passengerID}`, value);
          });
    });
  }, [formRef, initialFormValues, itemCards, calculateTotal, checkApplyAllCheckbox]);

  const generateRequestBody = (formValues: FormikValues) => {
    const reqBody: PutExtrasRequest = {
      items: [],
    };

    itemCards?.forEach((itemCard, index) => {
      Object.entries(itemCard.passengers).forEach(([key, passenger]) => {
        const numSelectedOutboundItems = parseInt(formValues[`outbound${index}-${key}`]);
        const numSelectedInboundItems = parseInt(formValues[`inbound${index}-${key}`]);

        const distinctOutboundItems = Array.from(
          new Set(passenger.outboundItems.slice(1).map(item => item.tariffCode))
        ).map(item => {
          const foundItem = passenger.outboundItems.find(el => el.tariffCode === item);

          return {
            tariffCode: foundItem?.tariffCode || '',
            productCode: foundItem?.productCode || '',
          };
        });
        const distinctInboundItems = Array.from(
          new Set(passenger.inboundItems.slice(1).map(item => item.tariffCode))
        ).map(item => {
          const foundItem = passenger.inboundItems.find(el => el.tariffCode === item);

          return {
            tariffCode: foundItem?.tariffCode || '',
            productCode: foundItem?.productCode || '',
          };
        });

        const selectedOutboundItemTariffCode = passenger.outboundItems[numSelectedOutboundItems]?.tariffCode;
        const selectedInboundItemTariffCode = passenger.inboundItems[numSelectedInboundItems]?.tariffCode;

        const indexOfOutboundItem = distinctOutboundItems.findIndex(
          item => item.tariffCode === selectedOutboundItemTariffCode
        );
        const indexOfInboundItem = distinctInboundItems.findIndex(
          item => item.tariffCode === selectedInboundItemTariffCode
        );

        const isCumulative = itemCard.cfLabels.cumulativePricing;

        [
          {
            distinctItems: distinctOutboundItems,
            indexOfItem: indexOfOutboundItem,
            numSelectedItems: numSelectedOutboundItems,
            direction: 'outbound',
          },
          {
            distinctItems: distinctInboundItems,
            indexOfItem: indexOfInboundItem,
            numSelectedItems: numSelectedInboundItems,
            direction: 'inbound',
          },
        ].forEach(distinctEntry => {
          distinctEntry.distinctItems.forEach((distinctItem, distinctItemIndex) => {
            if (isCumulative && distinctItemIndex < distinctEntry.indexOfItem)
              reqBody.items.push({
                direction: distinctEntry.direction,
                passengerId: key,
                productCode: distinctItem.productCode,
                quantity: passenger[`${distinctEntry.direction}Items`].filter(
                  el => el.tariffCode === distinctItem.tariffCode
                ).length,
                tariffCode: distinctItem.tariffCode,
              });
            else if (distinctItemIndex === distinctEntry.indexOfItem)
              reqBody.items.push({
                direction: distinctEntry.direction,
                passengerId: key,
                productCode: distinctItem.productCode,
                quantity:
                  distinctEntry.numSelectedItems +
                  1 -
                  passenger[`${distinctEntry.direction}Items`].findIndex(
                    el => el.tariffCode === distinctItem.tariffCode
                  ),
                tariffCode: distinctItem.tariffCode,
              });
            else
              reqBody.items.push({
                direction: distinctEntry.direction,
                passengerId: key,
                productCode: distinctItem.productCode,
                quantity: 0,
                tariffCode: distinctItem.tariffCode,
              });
          });
        });
      });
    });

    return reqBody;
  };

  const handleSubmitExtras = async () => {
    if (!formRef.current?.values) return;

    const formValues = formRef.current.values;

    const reqBody = generateRequestBody(formValues);
    const initialReqBody = generateRequestBody(initialFormValues);

    await addBookingExtras(reqBody, analyticsEventType, hideModal, initialReqBody);
  };

  const hideModal = () => {
    setActiveModalCF(undefined);
  };

  return {
    formRef,
    total,
    isFormModified,
    calculateTotal,
    handleFormFieldChanged,
    handleSubmitExtras,
    hideModal,
  };
};
