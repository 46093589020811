import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';

import { OptionsData } from '@atoms/Select/types';
import { countries } from '@molecules/PaymentForm/countryAndStates';

import { cn } from 'utils/classNames';

import { ModalFormProps } from '../../types';
import './ParkingReview.scss';
import { DriverDetails } from './components/DriverDetails/DriverDetails';
import { ParkingDetails } from './components/ParkingDetails/ParkingDetails';

const bem = cn('parking-review');

export const ParkingReview = observer((props: ModalFormProps) => {
  const { formRef, useParkingModalLogic } = props;
  const { bookingStore, cfStore } = useStores();
  const { handleAddParkingToTripButtonClicked, initialFormValues, validateFormFields, buttonClick, setButtonClick } = useParkingModalLogic;
  const { setIsFormModified } = useParkingModalLogic;

  const { data, modalSelectedPassengers, changeNumberOfDaysPassenger, modalNumberOfDays } = bookingStore.parking;
  const eligibleDrivers = data?.eligibleDrivers;

  const handleOnSubmit = async (values, formRef) => {
    const isValid = validateFormFields(values, formRef);
    isValid && buttonClick && (await handleAddParkingToTripButtonClicked(values));
    setButtonClick(false)
  };

  const [numberOfDays, setNumberOfDays] = useState(
    (modalNumberOfDays.length > 0 && modalNumberOfDays[0]?.numberOfDays) || 1
  );

  const formInitialValues = {
    country: 'United States',
    ...initialFormValues,
  };

  if (!eligibleDrivers || !eligibleDrivers.length) return null;

  return (
    <>
      <ParkingDetails />
      <Formik innerRef={formRef} initialValues={formInitialValues} onSubmit={handleOnSubmit} validateOnChange={false}>
        {({ handleSubmit, values }) => {
          let stateOptions: OptionsData[] = [];
          if (values.country) {
            const countryStates = countries.find(country => country.value === values.country);
            stateOptions =
              countryStates?.states?.map(state => ({
                label: state,
                value: state,
              })) || [];
            stateOptions.length &&
              stateOptions.unshift({
                label: cfStore.rti.parking.statePlaceholderLabel,
                value: '',
                disabled: true,
              });
          }

          useEffect(() => {
            if (!values || !data || !modalSelectedPassengers) {
              setIsFormModified(false);

              return;
            }
            setIsFormModified(
              data.eligibleDrivers.some(
                driver =>
                  (!!driver.currentSelections && !modalSelectedPassengers.includes(driver.passengerId)) ||
                  (!driver.currentSelections && modalSelectedPassengers.includes(driver.passengerId)) ||
                  (!!driver.currentSelections &&
                    driver.currentSelections.licensePlate?.number !== values[`${driver.passengerId}_license`]) ||
                  (!!driver.currentSelections &&
                    driver.currentSelections.licensePlate?.state !== values[`${driver.passengerId}_state`]) ||
                  (!!driver.currentSelections &&
                    driver.currentSelections.numberOfDays !== values[`${driver.passengerId}_numberOfDays`])
              )
            );
          }, [values]);

          const increaseNumberOfDays = () => {
            const max = 90;
            const newValue = Math.min(numberOfDays + 1, max);

            modalNumberOfDays.forEach(driver => {
              values[`${driver.passengerId}_numberOfDays`] = newValue;
            });
            changeNumberOfDaysPassenger(values);
            setNumberOfDays(newValue);
          };

          const decreaseNumberOfDays = () => {
            const min = 1;
            const newValue = Math.max(numberOfDays - 1, min);

            modalNumberOfDays.forEach(driver => {
              values[`${driver.passengerId}_numberOfDays`] = newValue;
            });
            changeNumberOfDaysPassenger(values);
            setNumberOfDays(newValue);
          };

          return (
            <form className={bem('form')} onSubmit={handleSubmit}>
              <fieldset className={bem('fieldset')}>
                <legend className={bem('legend')}>{cfStore.rti.parking.legendDescription}</legend>
                {eligibleDrivers.map(driver => (
                  <DriverDetails
                    key={driver.passengerId}
                    driver={driver}
                    states={stateOptions}
                    useParkingModalLogic={useParkingModalLogic}
                    increaseNumberOfDays={increaseNumberOfDays}
                    decreaseNumberOfDays={decreaseNumberOfDays}
                    numberOfDays={numberOfDays}
                  />
                ))}
              </fieldset>
            </form>
          );
        }}
      </Formik>
    </>
  );
});
