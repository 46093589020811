import { useMemo } from 'react';
import { useStores } from 'stores';

import { GiftCardProps } from './type';

export const useGiftCardLogic = (props: GiftCardProps) => {
  const { setCurrentCard, setIsModalOpen, isChecked, setIsChecked } = props;
  const { cfStore, bookingStore, passesStore } = useStores();
  const { giftCards, setGiftCardError, provisionalBooking } = bookingStore.rti;
  const { rti, passes } = cfStore;
  const { checkout } = passesStore;

  const {
    rti: { applyGiftCard, loading },
  } = bookingStore;

  const reviewAndPay = useMemo(() => {
    if (rti.reviewAndPay.giftCardTitle) return rti.reviewAndPay;

    return passes.reviewAndPay;
  }, [passes, rti]);

  const isAddCardDisabled = useMemo(() => {
    const bookingAmountToBePaid = provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid || 0;
    const cartAmountToBePaid = checkout?.costSummary?.bookingTotal?.totalToBePaid || 0;

    return (
      (!passes.reviewAndPay.giftCardTitle && bookingAmountToBePaid <= 0) ||
      (passes.reviewAndPay.giftCardTitle && cartAmountToBePaid <= 0) ||
      giftCards?.length === 3
    );
  }, [
    provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid,
    checkout?.costSummary?.bookingTotal?.totalToBePaid,
    passes.reviewAndPay.giftCardTitle,
    giftCards?.length,
  ]);

  const handleBoxClick = event => {
    if (
      !event.target.closest('input') &&
      !event.target.closest('button') &&
      !event.target.closest('.blte-gift-card__form') &&
      !isAddCardDisabled
    )
      setIsChecked && setIsChecked(prevState => !prevState);

    setGiftCardError({});
  };

  const openModal = cardNumber => {
    setIsModalOpen && setIsModalOpen(true);
    setCurrentCard && setCurrentCard(cardNumber);
  };

  const message = giftCards.length === 3 ? reviewAndPay.limitNumberLabel : reviewAndPay.giftCardSubtitle;

  const balanceMessage = balance => {
    if (balance === 0) return reviewAndPay.redeemedNoBalanceLabel;

    return reviewAndPay.redeemedLabel.replace('<amount>', balance);
  };

  const handleApply = async values => {
    if (values.accountNumber && values.pin)
      await applyGiftCard(values.accountNumber.toUpperCase(), values.pin, setIsChecked);
  };

  return {
    handleBoxClick,
    openModal,
    message,
    balanceMessage,
    reviewAndPay,
    handleApply,
    loading,
    isAddCardDisabled,
    isChecked,
    setIsChecked,
  };
};
