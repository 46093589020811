import React, { useEffect, useMemo, useRef } from 'react';

import { Icon } from '@atoms/Icon';
import LoadingIcon from '@atoms/LoadingIcon/LoadingIcon';

import { cn, cx } from 'utils/classNames';

import './Button.scss';
import { ButtonProps } from './types';

const bem = cn('btn');

const Button = (props: ButtonProps) => {
  const {
    onClick,
    onKeyDown,
    link,
    icon,
    id,
    className,
    color = 'yellow',
    iconPosition,
    autoFocus,
    addTabIndex,
    externalBtnRef,
    focusColor,
    ariaHidden,
    tabIndex,
  } = props;
  const { disabled = false, loading = false, type = 'button', label, colors, variant = 'primary', role } = props;
  const { url, target } = link || {};
  const { size = 'medium', width = 'normal' } = props;

  const style: any = useMemo(
    () => ({
      '--button-background-or-border-color': colors?.backgroundOrBorder,
      '--button-text-color': colors?.text,
      '--button-border-focus-color': colors?.borderFocus,
      '--button-background-opacity': colors?.backgroundOpacity,
    }),
    [colors]
  );

  const btnRef = useRef<any>(null);

  useEffect(() => {
    if (btnRef && btnRef.current && autoFocus) btnRef.current.focus();
  }, [autoFocus]);

  const buttonColor = useMemo(() => (!colors?.backgroundOrBorder ? color.toLowerCase() : ''), [colors, color]);

  const ButtonWrapper = onClick || !url ? 'button' : 'a';

  const buttonWrapperProps =
    ButtonWrapper === 'a'
      ? { href: url, target, ref: externalBtnRef || btnRef }
      : { 'aria-labelledby': id, onKeyDown, onClick, type, disabled, ref: externalBtnRef || btnRef };

  const renderButtonContent = () =>
    loading ? (
      <>
        <span className={bem('loading-icon-wrapper')}>
          <LoadingIcon className={bem('loading-icon')} />
        </span>
        {label}
      </>
    ) : (
      <>
        {icon && (
          <span className={bem('icon__eye')} aria-hidden={ariaHidden}>
            <Icon name={icon} />
          </span>
        )}
        {label}
      </>
    );

  const classNames = cx(
    bem({ disabled, loading, color: buttonColor, icon: iconPosition, size, width, variant, focusColor: focusColor }),
    className
  );

  return (
    <ButtonWrapper
      {...buttonWrapperProps}
      id={id}
      className={classNames}
      style={style}
      tabIndex={addTabIndex ? (tabIndex ? tabIndex : 0) : undefined}
      role={role}
    >
      {renderButtonContent()}
    </ButtonWrapper>
  );
};

export default Button;
