import React, { useEffect, useMemo, useRef } from 'react';

import { cn, cx } from 'utils/classNames';

import './MenuItem.scss';
import { MenuItemProps } from './types';

const bem = cn('menu-item');

const MenuItem = (props: MenuItemProps) => {
  const { label, link, onClick, itemIndex, active, colors, role, ariaSelected } = props;
  const { focus, focusIndex, setFocus, id, disabled = false, focusedItemRef } = props;
  const { wrapperCN = '', labelCN = 'blte-font--variant-body-medium-500' } = props;
  const ref = useRef<any>(null);

  const styles: any = useMemo(
    () => ({
      '--menu-item-text-color': colors?.text?.inactive,
      '--menu-item-text-hover-color': colors?.text?.hover,
      '--menu-item-text-focus-color': colors?.text?.focus,
      '--menu-item-text-disabled-color': colors?.text?.disabled,
      '--menu-item-text-active-color': colors?.text?.active,
      '--menu-item-background-color': colors?.background?.inactive,
      '--menu-item-background-hover-color': colors?.background?.hover,
      '--menu-item-background-focus-color': colors?.background?.focus,
      '--menu-item-background-disabled-color': colors?.background?.disabled,
      '--menu-item-background-active-color': colors?.background?.active,
      '--menu-item-border-focus-color': colors?.border?.focus,
    }),
    [colors]
  );

  const handleOnClick = e => !disabled && onClick && onClick(e, props, itemIndex);

  useEffect(() => {
    if (focus && ref.current) {
      ref.current.focus();
      if (focusedItemRef) focusedItemRef.current = ref.current;
    }
  }, [focus, focusedItemRef]);

  const handleMouseEnter = () => {
    if (setFocus && itemIndex !== undefined && focusIndex !== itemIndex)
      setFocus({ index: itemIndex, preventScroll: true });
  };

  return (
    <li
      id={id}
      role={role}
      aria-selected={ariaSelected !== undefined ? ariaSelected : active}
      className={cx(bem({ 'is-disabled': disabled, active: active && !focus, focus }), wrapperCN)}
      style={styles}
    >
      {link?.url ? (
        <a
          ref={ref}
          href={link.url || '#'}
          target={link.target}
          onClick={handleOnClick}
          className={bem('link')}
          aria-disabled={disabled}
          aria-selected={active}
        >
          <span className={cx(bem('label'), labelCN)}>
            <label>{label}</label>
          </span>
        </a>
      ) : (
        <button
          ref={ref}
          id={id}
          onClick={handleOnClick}
          className={bem('button')}
          disabled={disabled}
          onMouseEnter={handleMouseEnter}
          style={styles}
        >
          <span className={cx(bem('label'), labelCN)}>
            <label>{label}</label>
          </span>
        </button>
      )}
    </li>
  );
};

export default MenuItem;
