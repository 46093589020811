import { makeAutoObservable } from 'mobx';

import { isLoyaltyEnabled } from 'utils/metas';

import {
  CommissionsStore,
  Passengers,
  PaymentTokens,
  TripDetails,
  Trips,
  UserProfile,
  Wallet,
  RewardsSummary,
  TransactionHistory,
} from './sections';

export default class AccountStore {
  passengersStore: Passengers = new Passengers();
  trips: Trips;
  tripDetails: TripDetails;
  profile: UserProfile;
  paymentTokensStore: PaymentTokens;
  wallet: Wallet = new Wallet();
  commissionsStore: CommissionsStore = new CommissionsStore();
  rewardsSummary: RewardsSummary = new RewardsSummary();
  transactionHistory: TransactionHistory = new TransactionHistory();

  loyaltyEnabled = isLoyaltyEnabled();

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.trips = new Trips(rootStore);
    this.tripDetails = new TripDetails(rootStore);
    this.profile = new UserProfile(rootStore);
    this.paymentTokensStore = new PaymentTokens(rootStore);
  }

  /**
   * Add all the fetch methods necessary for account dashboard and related routes
   */
  fetchAllData() {
    this.trips.fetchAccountTripsData();
    this.profile.fetchUserProfile();
    this.passengersStore.fetchSavedPassengers();
    this.paymentTokensStore.fetchData();
    this.wallet.fetchData();
  }

  /**
   * Add all the clear methods necessary for account dashboard and related routes
   */
  clearAllData() {
    this.profile.clearUserProfile();
    this.passengersStore.clearSavedPassengers();
    this.trips.resetTripsData();
    this.paymentTokensStore.clearData();
    this.wallet.clearData();
    this.commissionsStore.clearCommisionsData();
  }

  fetchLoyaltyData() {
    this.rewardsSummary.fetchRewardsSummaryData();
    this.transactionHistory.fetchTransactionHistoryData();
  }

  clearLoyaltyData() {
    this.rewardsSummary.clearSummaryData();
    this.transactionHistory.clearTransactionHistoryData();
  }
}
