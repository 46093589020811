import { makeAutoObservable } from 'mobx';
import moment from 'moment-timezone';
import { RootStore } from 'stores';

import { ANALYTICS_DATE_FORMAT, searchTripsSuccess, tripManagementAnalyticsEvent } from 'utils/adobeDataLayer';
import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { isDelayExperienceEnabled, isDelayNotificationsEnabled } from 'utils/metas';
import { getFromHomePageFlag, removeFromHomePageFlag } from 'utils/storage';

import { TripConfig } from '../Trips';
import {
  BookingSessionConfig,
  ContactConfig,
  CostSummaryConfig,
  PassengerSeatingDetailsConfig,
  ProductConfig,
  TicketDocumentDetailConfig,
  ReceiptDocumentDetailConfig,
  TripPassengersConfig,
  BookingRulesConfig,
  BookingDiscountConfig,
} from './types';

export class TripDetails {
  private readonly rootStore: RootStore;

  trip: TripConfig | null = null;
  status = '';
  passengerSeatingDetails: PassengerSeatingDetailsConfig[] | null = null;
  contact: ContactConfig | null = null;
  products: ProductConfig[] = [];
  costSummary: CostSummaryConfig | null = null;
  bookingSession: BookingSessionConfig | null = null;
  ticketDocumentDetails: TicketDocumentDetailConfig[] = [];
  receiptDocumentDetails: ReceiptDocumentDetailConfig;
  tripPassengers: TripPassengersConfig[] = [];
  bookingRules: BookingRulesConfig;
  tripDiscounts: BookingDiscountConfig[];
  tripWay: string;
  partialyCanceled: boolean;
  tripLoad: boolean;
  hasReceiptError: boolean;

  showSeatSelectionModal = false;
  showManagePassengersModal = false;
  showCloseNotSavedModal = false;
  isCloseNotSaved = false;

  isLoading = true;
  hasError = false;
  showCancellationModal = false;
  showConfirmationModal = false;
  hasCancelTripError = false;
  showModifyTripWidget = false;

  isEditDailyParkingDialogOpen = false;
  noModificationAllowedModalOpen = false;
  isExtrasInfoModalOpen = false;

  shouldFetchTripDetails = true;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.rootStore = rootStore;
  }

  resetTripData() {
    this.trip = null;
    this.status = '';
    this.passengerSeatingDetails = null;
    this.contact = null;
    this.products = [];
    this.costSummary = null;
    this.bookingSession = null;
    this.ticketDocumentDetails = [];
    this.isLoading = true;
    this.hasError = false;
    this.rootStore.bookingStore.parking.resetParking();
    this.isEditDailyParkingDialogOpen = false;
    this.noModificationAllowedModalOpen = false;
    this.hasReceiptError = false;
    this.tripWay = 'both_legs';
    this.partialyCanceled = false;
    this.tripLoad = false;
  }

  setTripDiscounts(tripDiscounts: BookingDiscountConfig[]) {
    this.tripDiscounts = tripDiscounts;
  }

  setTripWay(wayOfTrip) {
    this.tripWay = wayOfTrip;
  }

  setPartialyCanceled(cancel) {
    this.partialyCanceled = cancel;
  }

  setReceiptError(error) {
    this.hasReceiptError = error;
  }

  setTripLoad(load) {
    this.tripLoad = load;
  }

  setEditDailyParkingDialogOpen() {
    this.isEditDailyParkingDialogOpen = true;
  }

  setEditDailyParkingDialogClose() {
    this.isEditDailyParkingDialogOpen = false;
  }

  setNoModificationAllowedModalOpen() {
    this.noModificationAllowedModalOpen = true;
  }

  setNoModificationAllowedModalClose() {
    this.noModificationAllowedModalOpen = false;
  }

  setExtrasInfoModalOpen() {
    this.isExtrasInfoModalOpen = true;
  }

  setExtrasInfoModalClose() {
    this.isExtrasInfoModalOpen = false;
  }

  setError(hasError) {
    this.hasError = hasError;
  }

  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  setBookingSession(bookingSession) {
    this.bookingSession = bookingSession;
  }

  setTicketDocumentDetails(ticketDocumentDetails) {
    this.ticketDocumentDetails = ticketDocumentDetails;
  }

  setReceiptDocumentDetails(receiptDocumentDetails) {
    this.receiptDocumentDetails = receiptDocumentDetails;
  }

  setCancelTripError(error) {
    this.hasCancelTripError = error;
  }

  setBookingRules(bookingRules) {
    this.bookingRules = bookingRules;
  }

  setTripDetails(tripDetails) {
    this.trip = tripDetails.trip;
    this.status = tripDetails.status;
    this.passengerSeatingDetails = tripDetails.passengerSeatingDetails;
    this.contact = tripDetails.contact;
    this.products = tripDetails.products;
    this.costSummary = tripDetails.costSummary;
    this.bookingRules = tripDetails.bookingRules;
  }

  setSetShowCancellationModal(show) {
    this.showCancellationModal = show;
  }

  setShowConfirmationModal(show) {
    this.showConfirmationModal = show;
  }

  setShowSeatSelectionModal(show) {
    this.showSeatSelectionModal = show;
  }

  setShowManagePassengersModal(show) {
    this.showManagePassengersModal = show;
  }

  setShowModifyTripWidget(show) {
    this.showModifyTripWidget = show;
  }

  setShowCloseNotSavedModal(show) {
    this.showCloseNotSavedModal = show;
  }

  setIsCloseNotSaved(show) {
    this.isCloseNotSaved = show;
  }

  setTripPassengers(passengers) {
    this.tripPassengers = passengers;
  }

  setShouldFetchTripDetails(shouldFetchTripDetails) {
    this.shouldFetchTripDetails = shouldFetchTripDetails;
  }

  async recordAnalyticsEvent(tripData) {
    const { trip } = tripData;
    const { sendDelayEmailNotifiactions } = this.rootStore.bookingStore.rti.notifications;
    const delayNotificationsEnabled = isDelayNotificationsEnabled();

    const eventInfo = {
      searchTrips: {
        originCity: trip.outboundRoute.origin.name,
        originState: null,
        destinationCity: trip.outboundRoute.destination.name,
        destinationState: null,
        dates: {
          departure: moment(trip.outboundRoute.departureDateTime)
            .tz(trip.outboundRoute.origin.timeZone)
            .format(ANALYTICS_DATE_FORMAT),
          return: trip.inboundRoute
            ? moment(trip.inboundRoute.departureDateTime)
                .tz(trip.inboundRoute.origin.timeZone)
                .format(ANALYTICS_DATE_FORMAT)
            : null,
        },

        PNR: trip.referenceNumber,
        tripType: trip.inboundRoute ? 'roundtrip' : 'one-way',
      },
      tripManagement: {
        PNR: trip.referenceNumber,
      },
    };

    searchTripsSuccess(eventInfo, this.rootStore.authStore.isAuthenticated);

    if (delayNotificationsEnabled)
      await sendDelayEmailNotifiactions(
        trip.referenceNumber,
        this.rootStore.authStore.isAuthenticated,
        false,
        this.rootStore.bookingStore.rti.smsNotifications
      );

    removeFromHomePageFlag();
  }

  async fetchTripData(referenceNumber, fn?, ln?) {
    try {
      let response;
      const fromHomePageFlag = getFromHomePageFlag();

      this.setLoading(true);

      const uppercaseReferenceNumber = (referenceNumber || '').toUpperCase();

      if (fn && ln)
        response = await api.get(`${requestUrls.getRestUrl(requestUrls.rti.searchTrip)}.xjson`, {
          params: {
            reference_number: uppercaseReferenceNumber,
            first_name: fn,
            last_name: ln,
          },
        });
      else
        response = await api.get(`${requestUrls.getRestUrl(requestUrls.account.tripDetails)}.xjson`, {
          params: { referenceNumber: uppercaseReferenceNumber },
        });

      if (fromHomePageFlag && response.data) this.recordAnalyticsEvent(response.data.tripDetails);

      const { tripDetails, bookingSession, ticketDocumentDetails } = response.data;
      this.setBookingSession(bookingSession);

      if (isDelayExperienceEnabled()) {
        await this.fetchTrainStatus(tripDetails.trip.outboundRoute);
        tripDetails.trip.inboundRoute && (await this.fetchTrainStatus(tripDetails.trip.inboundRoute));
      }

      const invoiceUrl = requestUrls
        .getBffUrl(requestUrls.account.invoice)
        .replace('{booking_number}', referenceNumber || '');

      try {
        const invoice = await api.get(invoiceUrl, {
          headers: {
            'Booking-Session-Access-Token': this.bookingSession?.accessToken,
            'Booking-Session-Refresh-Token': this.bookingSession?.refreshToken,
          },
        });
        this.setReceiptDocumentDetails(invoice.data);
      } catch {
        this.setReceiptError(true);
      }

      this.setTripDetails(tripDetails);

      this.setBookingRules(tripDetails.bookingRules);

      this.setTripPassengers(
        tripDetails.passengerSeatingDetails.map(passenger => ({
          passenger: passenger.passenger,
          passengerId: passenger.passengerId,
          isPrimaryPassenger: passenger.isPrimaryPassenger,
        }))
      );

      this.setTicketDocumentDetails(ticketDocumentDetails);

      this.rootStore.bookingStore.rti.setProvisionalBooking(response.data);
      if (tripDetails?.costSummary?.items?.some(item => item?.productCode === 'PARKING'))
        await this.rootStore.bookingStore.parking.fetchParking(uppercaseReferenceNumber, bookingSession);

      await this.rootStore.bookingStore.extras.fetchExtras(uppercaseReferenceNumber, bookingSession);

      if (!tripDetails?.trip?.outboundRoute?.isCancelled && tripDetails?.trip?.outboundRouteStatus !== 'past')
        this.rootStore.bookingStore.rti.seats.getSeats('outbound', true, true);
      if (tripDetails?.trip?.inboundRoute) this.rootStore.bookingStore.rti.seats.getSeats('inbound', true, true);

      this.setLoading(false);

      return response.data;
    } catch (e) {
      console.error(e);
      this.setLoading(false);
      this.setError(true);

      return null;
    }
  }

  async cancelTrip(referenceNumber, ignoreConfirmation, type, noLoading?, bookingSessionProp?) {
    const { cancelDelayEmailNotifiactions } = this.rootStore.bookingStore.rti.notifications;
    const delayNotificationsEnabled = isDelayNotificationsEnabled();
    try {
      if (!noLoading) this.setLoading(true);

      const queryParams = new URLSearchParams();
      if (ignoreConfirmation) queryParams.append('ignoreConfirmation', 'true');
      queryParams.append('type', type);

      const url = `${requestUrls.getRestUrl(
        requestUrls.account.tripDetails
      )}.${referenceNumber}.xjson?${queryParams.toString()}`;
      const response = await api.post(
        url,
        {},
        {
          headers: {
            'Booking-Session-Access-Token': bookingSessionProp
              ? bookingSessionProp.accessToken
              : this.bookingSession?.accessToken,
            'Booking-Session-Refresh-Token': bookingSessionProp
              ? bookingSessionProp.refreshToken
              : this?.bookingSession?.refreshToken,
          },
        }
      );

      const { tripDetails, bookingSession, discounts } = response.data;
      this.setTripDetails(tripDetails);
      this.setBookingSession(bookingSession);
      this.setTripDiscounts(discounts);

      tripManagementAnalyticsEvent({
        nameChangeSuccess: false,
        seatChange: false,
        tripCancelSuccess: true,
        upsellClick: false,
      });
      this.setLoading(false);
      this.setCancelTripError(false);
      ignoreConfirmation && this.setBookingRules({ ...tripDetails.bookingRules, hasProvisionalChanges: false });

      delayNotificationsEnabled &&
        cancelDelayEmailNotifiactions(
          referenceNumber,
          this.rootStore.authStore.isAuthenticated,
          this.rootStore.bookingStore.rti.smsNotifications
        );

      return response;
    } catch (e) {
      console.error(e);
      this.setLoading(false);
      this.setCancelTripError(true);

      return null;
    }
  }

  async revertTripProvisionalChanges(referenceNumber, bs?) {
    try {
      this.setLoading(true);
      const uppercaseReferenceNumber = (referenceNumber || '').toUpperCase();

      const url = `${requestUrls.getRestUrl(requestUrls.rti.revertProvisionalChanges)}.${referenceNumber}.xjson`;

      const response = await api.post(
        url,
        {},
        {
          headers: {
            'Booking-Session-Access-Token': this.bookingSession?.accessToken || bs?.accessToken,
            'Booking-Session-Refresh-Token': this.bookingSession?.refreshToken || bs?.refreshToken,
          },
        }
      );

      const { tripDetails, bookingSession, discounts } = response.data;

      this.setTripDetails(tripDetails);
      this.setBookingSession(bookingSession);
      this.setTripDiscounts(discounts);
      this.rootStore.bookingStore.rti.setProvisionalBooking(response.data);

      if (!bs) {
        if (tripDetails?.costSummary?.items?.some(item => item?.productCode === 'PARKING'))
          await this.rootStore.bookingStore.parking.fetchParking(uppercaseReferenceNumber, bookingSession);
        await this.rootStore.bookingStore.extras.fetchExtras(uppercaseReferenceNumber, bookingSession);
      }

      this.setLoading(false);
      this.setError(false);
    } catch (error) {
      console.error(error);
      this.setLoading(false);
      this.setError(error);
    }
  }

  async fetchTrainStatus(trip) {
    const toCamelCase = word => word?.charAt(0).toLowerCase() + word?.slice(1)?.replace(' ', '');
    const tz = trip.origin.timeZone;

    if (trip.isReaccommodated) trip.trainStatus = 'updated';

    if (trip.isCancelled) {
      trip.trainStatus = 'cancelled';

      return;
    }

    if (moment(trip?.arrivalDateTime).tz(tz).isBefore(moment().tz(tz))) trip.trainStatus = 'completed';

    try {
      const tripHasDeparted = moment(trip.departureDateTime).tz(tz).isBefore(moment().tz(tz));

      const params = {
        date: tripHasDeparted
          ? moment(trip.arrivalDateTime).tz(tz).format('YYYY-MM-DD')
          : moment(trip.departureDateTime).tz(tz).format('YYYY-MM-DD'),
        serviceNumber: trip.service.name,
        station: tripHasDeparted ? trip.destination.id : trip.origin.id,
        scheduleType: tripHasDeparted ? 'ARRIVAL' : 'DEPARTURE',
      };

      //if train departs today
      if (moment(trip.departureDateTime).tz(tz).isSame(moment().tz(tz), 'days')) {
        const response = await api.get(requestUrls.getBffUrl(requestUrls.account.trainSchedule), { params });
        const trainHasArrived =
          !!response?.data?.[0].liveTime && moment(response?.data?.[0]?.liveTime).tz(tz).isBefore(moment().tz(tz));

        if (response?.data?.[0].status != 'Arriving Soon') trip.trainStatus = toCamelCase(response?.data?.[0].status);
        if (response?.data?.[0].status == 'Arrived' || trainHasArrived) trip.trainStatus = 'completed';
      }
    } catch (e) {
      console.error('error', e);
    }
  }
}
