import React from 'react';

import LinkButton from '@atoms/LinkButton/LinkButton';

import { cn } from 'utils/classNames';

import './Breadcrumbs.scss';
import { BreadcrumbProps } from './types';

const bem = cn('breadcrumbs');

const BreadcrumbItem = (props: BreadcrumbProps) => {
  const { title, id, active, link, hidden: breadcrumbItemHidden, colors } = props;

  if (breadcrumbItemHidden) return null;

  const linkColors = {
    text: colors?.inactive,
    border: colors?.border,
  };

  return (
    <li key={id} className={bem('item', { active })}>
      {active ? <span>{title}</span> : <LinkButton label={title} variant="big" link={link} colors={linkColors} />}
    </li>
  );
};

export default BreadcrumbItem;
