import React, { useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { Tabs } from '@atoms/Tabs';

import { mediaQueryList } from 'utils/mediaQueries';

import './BookingSearch.scss';
import { formComponents } from './forms/FormComponents';
import { BookingSearchProps, BookingTabProps } from './types';

export const BookingSearch = (props: BookingSearchProps) => {
  const { id, pillButtonsPosition, pillButtons = [], tabs = [], design, fromStorybook } = props;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const visibleTabs: BookingTabProps[] = useMemo(() => tabs.filter(tab => !tab.disabled || !!tab.label), [tabs]);

  const extraPillButton = useMemo(() => {
    const buildYourPass = visibleTabs.find(tab => tab.type === 'tickets')?.data?.tripSelection?.buildYourPass;

    return buildYourPass && isMobile ? buildYourPass : undefined;
  }, [visibleTabs, isMobile]);

  return (
    <Tabs
      id={id}
      labels={visibleTabs.map(tab => tab.label)}
      pillButtons={pillButtons}
      extraPillButton={extraPillButton}
      pillButtonsPosition={pillButtonsPosition}
      design={design}
    >
      {visibleTabs.map((tab, index) => {
        const SpecificForm = formComponents[tab.type];

        return SpecificForm ? (
          <SpecificForm
            key={tab.type}
            ariaLabelledBy={`tab-${index}`}
            id={`panel-${index}`}
            role="tabpanel"
            tabIndex={0}
            {...tab.data}
            title={tab.label}
            fromStorybook={fromStorybook}
          />
        ) : null;
      })}
    </Tabs>
  );
};
