import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { rootStore, useStores } from 'stores';

import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import { usePaymentInfoEntryLogic } from '@booking/routes/RTI/PaymentInfo/components/PaymentInfoEntry/usePaymentInfoEntryLogic';

import { clickAnalyticsEvent, wifiLoginEvent } from 'utils/adobeDataLayer';
import { base64Encode } from 'utils/helpers';
import { isKiosk } from 'utils/isKiosk';
import { clearWifiData, getWifiData } from 'utils/localstorage';

import { usePushLoyaltyDataLayerLogic } from './usePushLoyaltyDataLayerLogic';

export const useCelebrationBannerLogic = props => {
  const { ticketsSentLabel, ticketsModifiedLabel, andOtherEmailsLabel, el, ticketsNotFoundErrorMessage } = props;

  const {
    accountStore: { tripDetails },
  } = useStores();
  const { fetchTripData } = tripDetails;

  const [expanded, setExpanded] = useState(false);
  const { rn, fn, ln, defaultRoute } = useTripDetailsConfig();
  const { pushTransactionDetailsEvent } = usePaymentInfoEntryLogic();
  const { bookingStore, authStore } = useStores();
  const { isAuthenticated } = authStore;
  const navigate = useNavigate();

  const { confirmedBooking, searchTrip } = bookingStore.rti;

  usePushLoyaltyDataLayerLogic();

  const getBookingDetailsAndPushToDataLayer = useCallback(
    async (rn, fn, ln) => {
      const bookingDetails = await fetchTripData(rn, fn, ln);

      pushTransactionDetailsEvent(bookingDetails?.tripDetails);
    },
    [fetchTripData, pushTransactionDetailsEvent]
  );

  useEffect(() => {
    if (!rn) return;

    getBookingDetailsAndPushToDataLayer(rn, fn, ln);
  }, [rn, fn, ln, getBookingDetailsAndPushToDataLayer]);

  // WiFi data submission
  useEffect(() => {
    const wifiData = getWifiData();
    const timeDiff = Date.now() - (wifiData?.submitTime || 0);

    const bannerTitle = document.getElementById('celebrationBannerTitle');
    const bannerBottomText = document.getElementById('celebrationBannerBottomText');

    if (bannerTitle) {
      let titleInnerHTML = bannerTitle.innerHTML;

      titleInnerHTML = titleInnerHTML.replaceAll('$firstNameId', wifiData?.firstName || '');
      titleInnerHTML = titleInnerHTML.replaceAll('$lastNameId', wifiData?.lastName || '');
      titleInnerHTML = titleInnerHTML.replaceAll('$emailId', wifiData?.email || '');

      bannerTitle.innerHTML = titleInnerHTML;
      bannerTitle.classList.remove('hide-on-login');
    }

    if (bannerBottomText) {
      let bottomTextInnerHTML = bannerBottomText.innerHTML;

      bottomTextInnerHTML = bottomTextInnerHTML.replaceAll('$firstNameId', wifiData?.firstName || '');
      bottomTextInnerHTML = bottomTextInnerHTML.replaceAll('$lastNameId', wifiData?.lastName || '');
      bottomTextInnerHTML = bottomTextInnerHTML.replaceAll('$emailId', wifiData?.email || '');

      bannerBottomText.innerHTML = bottomTextInnerHTML;
    }

    // If data is older than 1 minute, it means it is stale, so it gets discarded
    if (!wifiData || timeDiff > 60000) {
      clearWifiData();

      return;
    }

    wifiLoginEvent(
      {
        wifiLogin: {
          firstName: wifiData.firstName,
          lastName: wifiData.lastName,
          email: wifiData.email,
          postalCode: wifiData.postalCode,
        },
      },
      authStore.isAuthenticated
    );

    clearWifiData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleExpand = () => {
    if (sharedData.emails.length && !expanded) setExpanded(true);
    if (expanded) setExpanded(false);
  };

  const sharedData = useMemo(() => {
    const seatingDetails = confirmedBooking?.tripDetails?.passengerSeatingDetails;
    const emails = seatingDetails
      ?.filter(
        detail => !!detail.passenger.email && detail.passenger.email !== confirmedBooking?.tripDetails?.contact?.email
      )
      .map(d => d.passenger.email);

    return { primaryEmail: confirmedBooking?.tripDetails?.contact?.email || '', emails };
  }, [confirmedBooking]);

  const ticketSentMail = useMemo(
    () => ticketsSentLabel?.replace('$primaryEmail', sharedData.primaryEmail),
    [sharedData, ticketsSentLabel]
  );

  const ticketModifiedMail = useMemo(
    () => ticketsModifiedLabel?.replace('$primaryEmail', sharedData.primaryEmail),
    [sharedData, ticketsModifiedLabel]
  );

  const andOtherEmails = useMemo(
    () => andOtherEmailsLabel?.replace('$otherEmailsCount', ` ${sharedData.emails.length}`),
    [sharedData, andOtherEmailsLabel]
  );

  const handleClickShareButton = e => {
    e.preventDefault();
    alert('Share Trip Button clicked');
  };

  const generateAnalyticsClickEvent = useCallback(
    (customLink: string, buttonLabel) => {
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: buttonLabel,
          customLink: customLink,
          externalLink: '',
          linkType: 'linkClick',
        },
        tripManagement: {
          PNR: rn,
        },
      });
    },
    [rn]
  );

  const handleClickSeeTicketsButton = useCallback(
    e => {
      e.preventDefault();

      const buttonLabel = e.target.innerText;
      const pdfUrl = (confirmedBooking?.ticketDocumentDetails || []).filter(t => t.type === 'ticket')[0]?.link || '';

      if (pdfUrl) window.open(pdfUrl, '_blank');
      else alert(ticketsNotFoundErrorMessage);

      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: buttonLabel,
          customLink: pdfUrl,
          externalLink: pdfUrl,
          linkType: 'linkClick',
        },
        tripManagement: {
          PNR: rn,
        },
      });
    },
    [confirmedBooking?.ticketDocumentDetails, rn, ticketsNotFoundErrorMessage]
  );

  const handleClickTripDetailsButton = useCallback(
    e => {
      e.preventDefault();

      const buttonLabel = e.target.innerText;

      const data = {
        reference_number: rn,
        first_name: fn,
        last_name: ln,
      };

      const encodedData = base64Encode(JSON.stringify(data));

      if (isKiosk()) {
        generateAnalyticsClickEvent(
          `${defaultRoute}?reference_number=${rn}&first_name=${fn}&last_name=${ln}`,
          buttonLabel
        );
        navigate(`${defaultRoute}?data=${encodedData}&rn=${rn}`);
      } else if (isAuthenticated) {
        generateAnalyticsClickEvent(`${defaultRoute}?reference_number=${rn}`, buttonLabel);
        window.location.href = `${defaultRoute}?reference_number=${rn}`;
      } else {
        generateAnalyticsClickEvent(
          `${defaultRoute}?reference_number=${rn}&first_name=${fn}&last_name=${ln}`,
          buttonLabel
        );
        window.location.href = `${defaultRoute}?data=${encodedData}&rn=${rn}`;
      }
    },
    [navigate, defaultRoute, rn, fn, ln, isAuthenticated, generateAnalyticsClickEvent]
  );

  useEffect(() => {
    if (rn) searchTrip(rn, fn, ln);
  }, [fn, ln, rn, searchTrip]);

  useEffect(() => {
    const mainEl = el || document.querySelector('.blte-kiosk-celebration-page__celebration-banner');

    if (!mainEl) return;

    const tripDetailsButton = mainEl.querySelector('#button-trip-details');
    const shareTripButton = mainEl.querySelector('#button-share-trips');
    const seeTicketsButton = mainEl.querySelector('#button-see-tickets');

    shareTripButton?.addEventListener('click', handleClickShareButton);
    tripDetailsButton?.addEventListener('click', handleClickTripDetailsButton);
    seeTicketsButton?.addEventListener('click', handleClickSeeTicketsButton);

    seeTicketsButton?.setAttribute('role', 'link');

    // eslint-disable-next-line consistent-return
    return () => {
      shareTripButton?.removeEventListener('click', handleClickShareButton);
      tripDetailsButton?.removeEventListener('click', handleClickShareButton);
      seeTicketsButton?.removeEventListener('click', handleClickSeeTicketsButton);
    };
  }, [el, handleClickTripDetailsButton, handleClickSeeTicketsButton]);

  return {
    ticketSentMail,
    ticketModifiedMail,
    andOtherEmails,
    toggleExpand,
    sharedData,
    expanded,
  };
};
