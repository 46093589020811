import cx from 'classnames';
import React from 'react';

import { cn } from 'utils/classNames';

import './Logo.scss';
import { LogoProps } from './types';

const bem = cn('logo');

const LogoImage = ({ logo, logoAlt = 'Brightline', link = '' }: LogoProps) => {
  if (link)
    return (
      <a id='logoImageID' href={link} className={cx(bem())} tabIndex={0}>
        <img src={logo} alt={logoAlt} />
      </a>
    );

  return (
    <div id='logoImageID' className={cx(bem())} tabIndex={0}>
      <img src={logo} alt={logoAlt} />
    </div>
  );
};

export default LogoImage;
