import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { Icon } from '@atoms/Icon';
import HeroImage from '@molecules/HeroImage/HeroImage';
import { Card } from '@organisms/Carousel/Card';

import { cn } from 'utils/classNames';

import './Carousel.scss';
import { CarouselProps } from './types';

const bem = cn('carousel');

const Carousel = (props: CarouselProps) => {
  const { id, variant = 'full', title, items = [], autoplay } = props;
  const { speed = 1000, infinite = false } = props;
  const { heightDesktop, heightTabletLg, heightTabletSm, heightMobile } = props;
  const [initialMounted, setInitialMounted] = useState(false);
  const slideRef: any = useRef(null);
  const [isPaused, setIsPaused] = useState(!autoplay);
  const [visibleSlides, setVisibleSlides] = useState<number[]>([]);

  const vars: any = {
    '--height-desktop': variant === 'cards' ? `${heightDesktop || 529}px` : `${heightDesktop || 520}px`,
    '--height-tablet-lg': variant === 'cards' ? `${heightTabletLg || 529}px` : `${heightTabletLg || 580}px`,
    '--height-tablet-sm': variant === 'cards' ? `${heightTabletSm || 529}px` : `${heightTabletSm || 500}px`,
    '--height-mobile':
      variant === 'cards' ? `${heightMobile ? `${heightMobile}px` : 'calc(80vw / 3 * 4)'}` : `${heightMobile || 320}px`,
  };

  const handleClickNext = () => {
    if (slideRef && slideRef.current) slideRef.current.slickNext();
  };

  const isFullVariant = variant === 'full';

  const sliderSettings: Slider['props'] = {
    infinite: infinite || !isFullVariant,
    speed,
    slidesToScroll: 1,
    accessibility: false,
    focusOnSelect: false,
    onInit: handleAfterChange,
    afterChange: handleAfterChange,
    variableWidth: !isFullVariant,
    slidesToShow: isFullVariant ? 1 : 3,
    responsive: isFullVariant
      ? undefined
      : [
          {
            breakpoint: 1400,
            settings: { slidesToShow: 2, slidesToScroll: 1 },
          },
          {
            breakpoint: 942,
            settings: { slidesToShow: 1, slidesToScroll: 1 },
          },
        ],
  };

  function handleAfterChange() {
    const slideElems = document.querySelectorAll(`#${id} .slick-slide`);
    const activeIndexes: number[] = [];

    slideElems.forEach(elem => {
      const indexAttr = elem.getAttribute('data-index');
      if (!indexAttr) return;
      const i = parseInt(indexAttr, 10);
      if (elem.classList.contains('slick-active')) activeIndexes.push(i);
    });

    setVisibleSlides(activeIndexes);
  }

  const handleClickPrevious = () => {
    if (slideRef && slideRef.current) slideRef.current.slickPrev();
  };

  const handlePauseResume = () => {
    if (slideRef && slideRef.current) {
      if (isPaused) slideRef.current.slickPlay();
      else slideRef.current.slickPause();

      setIsPaused(!isPaused);
    }
  };

  useEffect(() => {
    setInitialMounted(true);
  }, []);

  return (
    <div id={id} className={bem({ variant })} tabIndex={-1} style={vars}>
      {variant === 'cards' && (
        <div className={bem('header')}>
          {title ? <h2>{title}</h2> : <span />}
          <div>
            <span>
              <button className="slick-prev" onClick={handleClickPrevious}>
                Prev
              </button>
            </span>
            <span>
              <button className="slick-next" onClick={handleClickNext}>
                Next
              </button>
            </span>
          </div>
        </div>
      )}
      <div className={bem('slider-wrapper')}>
        {initialMounted && (
          <div>
            <button id={`rotation-button-${id}`} className={bem('rotation-button')} onClick={handlePauseResume}>
              <span className={bem('pause-container', { isVisible: !isPaused })}>
                <Icon name="pause" ariaHidden />
                <span className="sr-only">Stop slide rotation</span>
              </span>
              <span className={bem('resume-container', { isVisible: isPaused })}>
                <Icon name="play" ariaHidden />
                <span className="sr-only">Start slide rotation</span>
              </span>
            </button>
            <Slider {...props} ref={slideRef} className={bem('slider')} {...sliderSettings}>
              {items.map((item, index) => {
                const isActive = visibleSlides.includes(index);

                return (
                  <div
                    key={item.text}
                    id={item.id}
                    data-index={index}
                    className={bem('slide')}
                    role="group"
                    aria-label={`slide ${index + 1} of ${items.length}`}
                    aria-hidden={!isActive}
                  >
                    {variant === 'full' ? (
                      <HeroImage
                        {...item}
                        isActive={isActive}
                        imageHeightDesktop={heightDesktop}
                        imageHeightTabletLg={heightTabletLg}
                        imageHeightTabletSm={heightTabletSm}
                        imageHeightMobile={heightMobile}
                      />
                    ) : (
                      <Card
                        {...item}
                        heightDesktop={heightDesktop}
                        heightTabletLg={heightTabletLg}
                        heightTabletSm={heightTabletSm}
                        heightMobile={heightMobile}
                      />
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel;
