export const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const useFilteredOptions = (optionsData, searchTerm, phoneNumberList) =>
  optionsData.filter(option => {
    if (!phoneNumberList || searchTerm === '') return true;
    const safeSearchTerm = escapeRegExp(searchTerm);
    const regex = new RegExp(safeSearchTerm, 'i');
    const searchTarget = `${option.label} (${option.value})`;

    return regex.test(searchTarget);
  });
