import React from 'react';
import InputMask from 'react-input-mask';

import { Input } from '@atoms/Input';

import { useInputLogic } from 'utils/useInputLogic';

import { defaultInputName } from './constants';
import { InputFieldProps } from './types';

export const InputFieldMask = (props: InputFieldProps) => {
  const {
    type = 'text',
    errorVariant,
    name,
    helperText = '',
    mask = '99/99/9999',
    maxCharacters,
    placeholder = '',
    ...rest
  } = props;

  const nullCheckName = name || defaultInputName;

  const { handleClickTrailingIcon, field, isInvalid, error } = useInputLogic({
    ...props,
    name: nullCheckName,
  });

  return (
    <InputMask
      {...field}
      {...rest}
      name={nullCheckName}
      maxCharacters={maxCharacters}
      placeholder={placeholder}
      onClickTrailingIcon={handleClickTrailingIcon}
      helperText={helperText}
      type={type}
      mask={mask}
      maskPlaceholder={null}
      errorMessage={error}
      displayTrailingIcon
    >
      <Input
        {...field}
        {...rest}
        errorVariant={errorVariant}
        name={nullCheckName}
        error={isInvalid}
        errorMessage={error}
        type={type}
        helperText={helperText}
        maxCharacters={maxCharacters}
        placeholder={placeholder}
        onClickTrailingIcon={handleClickTrailingIcon}
        displayTrailingIcon
      />
    </InputMask>
  );
};
