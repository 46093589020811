import React, { useMemo } from 'react';


import { cn } from 'utils/classNames';
import { FeatureGridCarouselDotsProps } from '../types';

const bem = cn('features-grid-v2');

export const CarouselDots = (props: FeatureGridCarouselDotsProps) => {
  const { items, rowList, selectedDot } = props;

  const dotsArray = useMemo(
    () => new Array(items?.length || rowList?.length || 0).fill(0).map((_, index) => index + 1 === selectedDot),
    [items, rowList, selectedDot]
  );

  if (items?.length === 0 || rowList?.length === 0) return null;

  return (
    <div className={bem('dots-wrapper')}>
      {dotsArray.map((dot, index) => (
        <div key={index} className={bem('dot', { selected: dot })} />
      ))}
    </div>
  );
};
