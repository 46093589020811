import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import { useCelebrationBannerLogic } from '@molecules/CelebrationBanner/useCelebrationBannerLogic';
import { usePrintingLogic } from '@molecules/CelebrationBanner/usePrintingLogic';

import { cn } from 'utils/classNames';

import './CelebrationBanner.scss';
import { CelebrationBannerProps } from './types';

const bem = cn('celebration-banner');

export const CelebrationBannerEmails = observer((props: CelebrationBannerProps) => {
  const { showLessLabel, otherEmailsJoinSeparator, bottomTextPasses, image, bookingLabel } = props;
  const { ticketSentMail, ticketModifiedMail, andOtherEmails, toggleExpand, sharedData, expanded } =
    useCelebrationBannerLogic(props);

  const { rn } = useTripDetailsConfig();
  const { bookingLabelFormated } = usePrintingLogic({ bookingLabel });

  const { bookingStore } = useStores();
  const { rti } = bookingStore;

  if (bottomTextPasses) return null;

  return (
    <div>
      <h2 className={bem('booking-label')}>
        {bookingLabelFormated}
        <span className={bem('booking-number')}>{rn}</span>
      </h2>
      {sharedData.primaryEmail && (
        <span>
          {rti.modifyTripType === 'extras' ? ticketModifiedMail : ticketSentMail}
          <span className={bem('primary-passenger-email')}> {sharedData.primaryEmail}</span>
        </span>
      )}
      {'    '}
      {!!sharedData.emails.length && !expanded && (
        <button className={bem('expand')} onClick={toggleExpand}>
          {andOtherEmails}
          {sharedData.emails.length === 1 ? '' : 's'}
        </button>
      )}
      {expanded && (
        <div className={bem('expanded', { 'no-image': !image.src })}>
          <span className={bem('all-emails')}>{sharedData.emails.join(otherEmailsJoinSeparator)}</span>
          <button className={bem('collapse')} onClick={toggleExpand}>
            {showLessLabel}
          </button>
        </div>
      )}
    </div>
  );
});
