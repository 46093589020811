import React from 'react';

import { cn } from 'utils/classNames';

import { DateRangePicker } from './DateRangePicker';
import './DateSelection.scss';

const bem = cn('events-date-selection');

export const DateSelection = props => (
  <div className={bem()}>
    <DateRangePicker {...props} />
  </div>
);
