import cx from 'classnames';
import React, { useMemo } from 'react';

import { PictureSource } from '@atoms/Image/PictureSource';

import { cn } from 'utils/classNames';

import { ImageProps } from './types';

const bem = cn('image');

const Image = (props: ImageProps) => {
  const { responsive, lazy, alt, className = '', title } = props;
  const { mobileSrc, tabletSmSrc, tabletLgSrc, src, lazyLoaded, style } = props;

  const { width, height, tabletLgWidth, tabletLgHeight, tabletSmWidth, tabletSmHeight, mobileWidth, mobileHeight } =
    props;

  const imgHydratedProps = useMemo(() => (lazyLoaded ? { src: mobileSrc } : {}), [lazyLoaded, mobileSrc]);

  if (responsive) {
    if (lazy)
      return (
        <picture title={title}>
          <PictureSource media="(min-width: 1024px)" width={width} height={height} src={src} lazyLoaded={lazyLoaded} />
          <PictureSource
            media="(min-width: 768px)"
            width={tabletLgWidth}
            height={tabletLgHeight}
            src={tabletLgSrc}
            lazyLoaded={lazyLoaded}
          />
          <PictureSource
            media="(min-width: 576px)"
            width={tabletSmWidth}
            height={tabletSmHeight}
            src={tabletSmSrc}
            lazyLoaded={lazyLoaded}
          />
          <img
            className={cx(bem(), lazyLoaded ? 'lazyloaded' : 'lazyload', className)}
            style={style}
            alt={alt ? alt : ' '}
            width={mobileWidth}
            height={mobileHeight}
            data-src={mobileSrc}
            {...imgHydratedProps}
          />
        </picture>
      );

    return (
      <picture title={title}>
        <source media="(min-width: 1024px)" srcSet={src} width={width} height={height} />
        <source media="(min-width: 768px)" srcSet={tabletLgSrc} width={tabletLgWidth} height={tabletLgHeight} />
        <source media="(min-width: 576px)" srcSet={tabletSmSrc} width={tabletSmWidth} height={tabletSmHeight} />
        <img
          className={cx(bem(), { [className]: !!className })}
          alt={alt}
          width={mobileWidth}
          height={mobileHeight}
          src={mobileSrc}
        />
      </picture>
    );
  }

  if (lazy) return <img title={title} alt={alt} className={cx(bem(), 'lazyload', className)} data-src={src} />;

  return <img title={title} alt={alt} className={cx(bem(), { [className]: !!className })} src={src} />;
};

export default Image;
