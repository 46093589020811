import React, { useMemo } from 'react';

import { cn } from 'utils/classNames';

import { DividerProps } from './types';

const bem = cn('divider');

const Divider = (props: DividerProps) => {
  const { alignment = 'horizontal', color, width = 1, margin = 24 } = props;
  const styles: any = useMemo(
    () => ({
      '--background-color': color,
      '--width': `${width}px`,
      '--margin': `${margin}px`,
    }),
    [color, width, margin]
  );

  return <div className={bem({ alignment })} style={styles} />;
};

export default Divider;
