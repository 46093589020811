import React from 'react';

import { SeatIconProps } from './types';

export const PremiumSeat = (props: SeatIconProps) => {
  const { fillColor = 'transparent', width, height, className } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_24198_1275)">
        <path d="M43.9046 0.25L8.62465 0.25C8.14968 0.25 7.76465 0.635035 7.76465 1.11V6.39C7.76465 6.86496 8.14968 7.25 8.62465 7.25H43.9046C44.3796 7.25 44.7646 6.86496 44.7646 6.39V1.11C44.7646 0.635035 44.3796 0.25 43.9046 0.25Z" />
        <path d="M6.13472 28.8901L10.4147 8.7501H8.62472C7.32472 8.7501 6.26472 7.6901 6.26472 6.3901V3.8501C5.69472 3.9301 5.21472 4.3401 5.09472 4.9101L0.264718 27.6401C0.184718 28.0001 0.264718 28.3701 0.454718 28.6801C0.654718 28.9901 0.964718 29.2001 1.32472 29.2801L4.49472 29.9501C4.59472 29.9701 4.68472 29.9801 4.78472 29.9801C5.43472 29.9801 5.99472 29.5201 6.13472 28.8901Z" />
        <path d="M47.215 29.98C47.315 29.98 47.405 29.97 47.505 29.95L50.675 29.28C51.415 29.12 51.895 28.39 51.735 27.64L46.905 4.91004C46.825 4.53004 46.585 4.23004 46.265 4.04004V6.40004C46.265 7.70004 45.205 8.76004 43.905 8.76004H41.585L45.865 28.9C46.005 29.53 46.565 29.99 47.215 29.99V29.98Z" />
        <path d="M47.2146 31.48C45.8846 31.48 44.6847 30.55 44.3946 29.2L40.0447 8.75H11.9446L7.60465 29.2C7.31465 30.55 6.12465 31.48 4.78465 31.48C4.78465 31.48 4.77465 31.48 4.76465 31.48V32.74C4.76465 35.51 7.00465 37.75 9.77465 37.75H42.7547C45.5247 37.75 47.7646 35.51 47.7646 32.74V31.42C47.5746 31.46 47.3946 31.48 47.2146 31.48Z" />
      </g>
      <defs>
        <clipPath id="clip0_24198_1275">
          <rect width="51.53" height="37.5" fill="white" transform="translate(0.234863 0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
};
