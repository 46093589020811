import React from 'react';

import { CreditPassCard, PassCard, EmptyPassMobile } from '@account/routes/Wallet/components/PassCards';

import { cn } from 'utils/classNames';

import './PassesCarousel.scss';
import { PassesCarouselProps } from './types';

const bem = cn('passes-carousel');

export const PassesCarousel = (props: PassesCarouselProps) => {
  const { handlers, getRefFromHandlers, items, emptySlides, activeSlide } = props;

  return (
    <div className={bem()}>
      <div className={bem('items')} {...handlers} ref={getRefFromHandlers}>
        {items.length
          ? items.map((item, index) => {
              const { type, station, originStation } = item;
              const cardStation = station || originStation;

              return (
                <div key={`wallet-${type}-${index}`} className={bem('slide', { active: index === activeSlide - 1 })}>
                  {type !== 'credit' ? (
                    <PassCard {...item} originStation={cardStation} />
                  ) : (
                    <CreditPassCard {...item} />
                  )}
                </div>
              );
            })
          : (emptySlides || []).map(slide => <EmptyPassMobile key={slide.description} {...slide} />)}
      </div>
    </div>
  );
};
