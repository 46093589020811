import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import { cn } from 'utils/classNames';

import './AlertBanner.scss';

const bem = cn('tickets-form-footnote');

const Footnote = ({ bookingOffers }) => {
  const { values: formValues } = useFormikContext();
  const values = formValues as any;

  const bannerConfig = useMemo(() => {
    if (!values.from || !values.to || !bookingOffers.length) return undefined;

    return bookingOffers.find(bookingOffer =>
      bookingOffer.originDestinations.some(
        odPair =>
          (odPair.originStation === values.from && odPair.destinationStation === values.to) ||
          (odPair.destinationStation === values.from && odPair.originStation === values.to)
      )
    );
  }, [values, bookingOffers]);

  return <p className={bem()}>{bannerConfig?.footnote}</p>;
};

export default Footnote;
