import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as Yup from 'yup';

import { dateConfig } from './constants';

export const getValidationSchema = i18n =>
  Yup.object().shape({
    passengers: Yup.array().of(
      Yup.object().shape({
        passenger: Yup.object().shape({
          firstName: Yup.string().required(i18n.requiredInputLabel),
          lastName: Yup.string().required(i18n.requiredInputLabel),
          dateOfBirth: Yup.date()
            .test('dateOfBirth', i18n.requiredInputLabel, (date, meta) => {
              if (meta.path.indexOf('passengers[0]') > -1) return !!date;

              return true;
            })
            .test('dateOfBirth', '', (date, meta) =>
              date
                ? dayjs(date).isAfter(getMinBirthDate(meta.parent.type)) &&
                  dayjs(date).isBefore(getMaxBirthDate(meta.parent.type))
                : true
            )
            .transform((_, originalValue) => {
              dayjs.extend(customParseFormat);
              const result = dayjs(originalValue, dateConfig.dateFormat, true);

              return result.toDate();
            })
            .max(dayjs().subtract(1, 'day'), i18n.invalidInputLabel)
            .typeError(i18n.invalidInputLabel),
          email: Yup.string()
            .test('email', i18n.requiredInputLabel, (email, meta) => {
              if (meta.path.indexOf('passengers[0]') > -1) return !!email;

              return true;
            })
            .email(i18n.invalidInputLabel),
          phoneNumber: Yup.object().test('phoneNumber', i18n.requiredInputLabel, (phoneNumber, meta) => {
            if (meta.path.indexOf('passengers[0]') > -1) return !!phoneNumber?.number;

            return true;
          }),
          missingPassenger: Yup.mixed().test('missingPassenger', ' ', function () {
            const { firstName, lastName } = this.parent;

            return !!firstName || !!lastName;
          }),
        }),
      })
    ),
  });

const getMinBirthDate = personType => {
  switch (personType) {
    case 'child':
      return dayjs().subtract(13, 'years');
    case 'infant':
      return dayjs().subtract(3, 'years');
    case 'adult':
      return dayjs().subtract(200, 'years');
    default:
      return dayjs().subtract(200, 'years');
  }
};

const getMaxBirthDate = personType => {
  switch (personType) {
    case 'adult':
      return dayjs().subtract(12, 'years');
    case 'child':
      return dayjs().subtract(2, 'years');
    case 'infant':
      return dayjs();
    default:
      return dayjs();
  }
};
