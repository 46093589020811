import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { dateConfig, passengerTypes } from './constants';
import { PassengerCompleteProps } from './types';

const bem = cn('passenger-info');

const PassengersInfoComplete = observer((props: PassengerCompleteProps) => {
  const { cfStore } = useStores();
  const { passengers } = cfStore.rti;

  const { bookedPassengers = [] } = props;

  return (
    <div className={bem('complete')}>
      {bookedPassengers.map(({ passenger }, index) => (
        <div key={`p-c-${index}`} className={bem('preview', { 'second-column': index % 2 === 1 })}>
          <div className={bem('desc')}>
            {passengers.passengerHeaderLabel.replace('{index}', (index + 1).toString())}
            &#x2022;
            {passenger.type === passengerTypes.adult ? passengers.adultLabel : ''}
            {passenger.type === passengerTypes.child ? passengers.childLabel : ''}
            {passenger.type === passengerTypes.infant ? passengers.infantLabel : ''}
          </div>
          <span className={bem('simple')}>{`${passenger.firstName} ${passenger.lastName}`}</span>
          {passenger.dateOfBirth && (
            <span className={bem('simple')}>{moment(passenger.dateOfBirth).format(dateConfig.dateFormat)}</span>
          )}
          <span className={bem('simple')}>{passenger.email}</span>
          {passenger.phoneNumber?.number && (
            <span className={bem('simple')}>{`${
              passenger.phoneNumber?.countryCode
            }-${passenger.phoneNumber?.number?.slice(0, 3)}-${passenger.phoneNumber?.number?.slice(
              3,
              6
            )}-${passenger.phoneNumber?.number?.slice(6)}`}</span>
          )}
        </div>
      ))}
    </div>
  );
});

export default PassengersInfoComplete;
