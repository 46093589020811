import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { OverlayContainer } from '@account/components/OverlayContainer';

import { EditDetailsTravelAgent } from '../EditDetailsTravelAgent';

export const EditDetailsTravelAgentOverlay = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { settings: i18n } = cfStore.account;
  const { isEditDialogTravelAgentOpen, setEditDialogTravelAgentClose } = accountStore.profile;

  return (
    <OverlayContainer
      title={i18n.travelAgentCodeTitle}
      isVisible={isEditDialogTravelAgentOpen}
      onCancel={setEditDialogTravelAgentClose}
    >
      <EditDetailsTravelAgent />
    </OverlayContainer>
  );
});
