import { ImageProps } from '@atoms/Image/types';

import { AutoRenewConfig, EmptyPassConfig, WalletConfig, WalletParkingEditConfig } from './types.wallet';

const emptyStateDefaultIcon: ImageProps = {
  alt: '',
  height: '',
  lazy: false,
  mobileSrc: '',
  responsive: false,
  sources: [],
  src: '',
  tabletLgSrc: '',
  tabletSmSrc: '',
  width: '',
};

const defaultEmptyConfig: EmptyPassConfig = {
  desktopBodyLabel: '',
  desktopHeader: '',
  emptyStateIcon: emptyStateDefaultIcon,
  parkingPassBodyLabel: '',
  trainPassBodyLabel: '',
};

const defaultAutoRenew: AutoRenewConfig = {
  activateAutoRenewDescription: '',
  activateAutoRenewInfoTitle: '',
  activateAutoRenewTitle: '',
  activateCtaLabel: '',
  cancelCtaLabel: '',
  deactivateAutoRenewDescription: '',
  deactivateAutoRenewTitle: '',
  deactivateCtaLabel: '',
};

const defaultParkingEdit: WalletParkingEditConfig = {
  editParkingPassModalTitle: '',
  chooseGarageLabel: '',
  chooseStateLabel: '',
  enterLicensePlateNumberLabel: '',
  cancelButtonLabel: '',
  updateButtonLabel: '',
  licensePlateTextFieldInvalidLabel: '',
  serverErrorLabel: ' ',
};

export const defaultWalletConfig: WalletConfig = {
  addParkingPassCtaLabel: '',
  addParkingPassCtaLink: '',
  addTrainPassCtaLabel: '',
  addTrainPassCtaLink: '',
  autoRenew: defaultAutoRenew,
  autoRenewDescription: '',
  autoRenewLabel: '',
  brightlineCreditLabel: '',
  creditCtaLabel: '',
  creditCtaLink: '',
  creditRemainingLabel: '',
  creditIdLabel: '',
  dateIssuedLabel: '',
  emptyState: defaultEmptyConfig,
  expiresLabel: '',
  licensePlateStateLabel: '',
  link: '',
  managePassLabel: '',
  modifyPassCtaLabel: '',
  navigationIcon: '',
  noApplicableLabel: '',
  offLabel: '',
  onLabel: '',
  pageTitle: '',
  parkingPassCtaLabel: '',
  parkingPassLabel: '',
  parkingPassLicensePlateLabel: '',
  parkingPassLocation: '',
  passTypeLabel: '',
  expirationDateLabel: '',
  noExpirationDateLabel: '',
  passengerNameLabel: '',
  premiumLabel: '',
  redeemableRidesLabel: '',
  renewsLabel: '',
  renewsOnLabel: '',
  ridesUsedLabel: '',
  shareableLabel: '',
  shareableValueNo: '',
  shareableValueYes: '',
  codeLabel: '',
  showBottomCards: false,
  smartLabel: '',
  trainPassCtaLabel: '',
  trainPassCtaLink: '',
  trainPassLabel: '',
  editParkingPass: defaultParkingEdit,
  remainingLabel: '',
  smartPassMainColor: '',
  smartPassRadialColor: '',
  smartPassTextColor: '',
  premiumPassMainColor: '',
  premiumPassRadialColor: '',
  premiumPassTextColor: '',
  showTrainPassAutoRenewOption: false,
  showParkingPassAutoRenewOption: false,
  lastDayExpiresLabel: '',
};
