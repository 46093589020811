import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { RectShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';

import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';
import { mediaQueryList } from 'utils/mediaQueries';

import './CostSummaryItem.scss';
import { CostSummaryItemProps } from './types';

const bem = cn('passes-cost-summary-item');

export const CostSummaryItem = observer((props: CostSummaryItemProps) => {
  const { itemName, itemPrice, isDiscount, isTotal, isPlaceholder } = props;
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  return (
    <div className={bem()}>
      {!isPlaceholder && (
        <div className={bem('line')}>
          <Text
            text={`${itemName.startsWith('***') ? '1x ' : ''}${itemName}`}
            className={bem('name', { isDiscount, isTotal })}
          />
          <Text
            text={`${isDiscount ? '-' : ''}$${itemPrice.toFixed(2)}`}
            className={bem('price', { isDiscount, isTotal })}
          />
        </div>
      )}
      {isPlaceholder && !isMobile && (
        <div className={bem('line')}>
          <RectShape
            color={color_base_medium_light_grey}
            style={{ width: 100, height: isTotal ? 26 : 24, borderRadius: 8, marginTop: 1, marginBottom: 1 }}
          />
          <RectShape
            color={color_base_medium_light_grey}
            style={{ width: 55, height: isTotal ? 26 : 24, borderRadius: 8, marginTop: 1, marginBottom: 1 }}
          />
        </div>
      )}
      {isPlaceholder && isMobile && (
        <div className={bem('line')}>
          <RectShape
            color={color_base_medium_light_grey}
            style={{ width: 100, height: isTotal ? 24 : 22, borderRadius: 8, marginTop: 1, marginBottom: 1 }}
          />
          <RectShape
            color={color_base_medium_light_grey}
            style={{ width: 55, height: isTotal ? 24 : 22, borderRadius: 8, marginTop: 1, marginBottom: 1 }}
          />
        </div>
      )}
    </div>
  );
});
