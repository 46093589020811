import React, { useEffect, useId, useRef } from 'react';
import Popover from 'react-popover';

import MenuItem from '@atoms/MenuItem/MenuItem';

import { cn } from 'utils/classNames';

import { ExtrasSelectHeader } from '../ExtrasSelectHeader';
import { DesktopSelectProps } from '../types';
import './ExtrasDesktopSelect.scss';

const bem = cn('desktop-select');

export const ExtrasDesktopSelect = (props: DesktopSelectProps) => {
  const generatedId = useId();
  const { displayList, handleHeaderClick, value, optionsData, handleClickOutside } = props;
  const { handleItemClick, focus, setFocus, handleKeyDown, headerRef, disabled, id = `select-${generatedId}` } = props;

  const shouldFocus = itemIndex => focus?.index === itemIndex && !focus?.preventScroll && displayList;

  const currentOptionId = focus ? `list-option-${focus.index}` : undefined;

  const listRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (displayList && listRef.current) listRef.current.focus();
  }, [displayList]);

  return (
    <Popover
      className={bem('popover')}
      onOuterAction={handleClickOutside}
      isOpen={displayList && !disabled}
      tipSize={0.01}
      preferPlace="below"
      enterExitTransitionDurationMs={10}
      body={
        <div
          ref={listRef}
          id="list"
          className={bem('list')}
          style={{ width: headerRef?.current?.offsetWidth }}
          onKeyDown={handleKeyDown}
          tabIndex={-1}
        >
          <ul
            role="listbox"
            id={id}
            aria-labelledby={`header ${id}`}
            className={bem('listItems')}
            aria-activedescendant={currentOptionId}
          >
            {optionsData?.map((option, itemIndex) => (
              <MenuItem
                key={`${option.label}-${itemIndex}`}
                id={`list-option-${itemIndex}`}
                active={option.value === value}
                itemIndex={itemIndex}
                value={option.value}
                label={option.label}
                setFocus={setFocus}
                focus={shouldFocus(itemIndex)}
                onClick={handleItemClick}
                handleKeyDown={handleKeyDown}
                role="option"
              />
            ))}
          </ul>
        </div>
      }
      target={headerRef?.current}
      {...props}
    >
      <ExtrasSelectHeader {...props} headerRef={headerRef} onClick={handleHeaderClick} onKeyDown={handleKeyDown} />
    </Popover>
  );
};
