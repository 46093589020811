import React, { useMemo } from 'react';

import { Text } from '@atoms/Text';
import { UpsellContentDescriptionConfig } from '@organisms/UpsellModal/types';

import { cn } from 'utils/classNames';
import { parseRteIcons } from 'utils/rteUtils';

import './UpsellContentDescription.scss';

const bem = cn('upsell-content-description');

const UpsellContentDescription = (props: UpsellContentDescriptionConfig) => {
  const { description, title, isMobile } = props;

  const descriptionWithIcons = useMemo(() => {
    if (!description) return '';

    return parseRteIcons(description);
  }, [description]);

  return (
    <div className={bem({isMobile})}>
      {title && <Text className={bem('title')} text={title} />}
      <Text text={descriptionWithIcons} className={bem('body-content')} />
    </div>
  );
};

export default UpsellContentDescription;
