import React from 'react';

import { Divider } from '@atoms/Divider';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './Rewards.scss';

const bem = cn('rewards-placeholders');

export const RewardsPlaceholders = () => (
  <div className={bem('container')}>
    <div className={bem('title')} />
    <div className={bem('points')}>
      <div className={bem('available-pts')} />
      <Divider alignment="vertical" color={color_base_medium_light_grey} />
      <div className={bem('pending-pts')} />
    </div>
  </div>
);
