import { observer } from 'mobx-react-lite';
import React from 'react';

import { Icon } from '@atoms/Icon/Icon';
import { Text } from '@atoms/Text/Text';

import { cn } from 'utils/classNames';

import './ErrorBanner.scss';
import { ErrorBannerProps } from './types';

const bem = cn('extras-error-banner');

export const ErrorBanner = observer((props: ErrorBannerProps) => {
  const { errorMessage } = props;

  return (
    <div className={bem()}>
      <Icon name="warning" className={bem('icon')} />
      <Text text={errorMessage} className={bem('text')} />
    </div>
  );
});
