import React from 'react';

import '@molecules/TicketsForm/components/DateSelection/DateSelection.scss';

import { cn } from 'utils/classNames';

import { DateRangePickerModifyTrip } from './components/DateRangePicker/DateRangePickerModifyTrip';

const bem = cn('date-selection');

export const DateSelection = props => (
  <div className={bem()}>
    <DateRangePickerModifyTrip {...props} />
  </div>
);
