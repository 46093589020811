import React from 'react';

import { cn } from 'utils/classNames';

const bem = cn('trip-details-navigation');

export const NavigationPlaceholders = () => {
  const group = (
    <div>
      <div />
      <div>
        <div />
        <div />
        <div />
      </div>
    </div>
  );

  return (
    <div className={bem('placeholders')}>
      {group}
      {group}
      {group}
    </div>
  );
};
