import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useStores } from 'stores';
import { WalletPassItem } from 'stores/AccountStore/sections';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';
import { premium_passes_background_color, smart_passes_background_color } from 'utils/colors';

import { usePassCard } from '../../usePassCard';
import { FooterRightData } from './FooterRightData';
import './PassCard.scss';
import { StationLabel } from './StationLabel';

const bem = cn('pass-card');

export const PassCard = observer((props: WalletPassItem) => {
  const { cfStore } = useStores();
  const { account } = cfStore;
  const { wallet } = account;
  const {
    type,
    passType,
    passName,
    isAutoRenew,
    classType,
    originStation,
    destinationStation,
    showAutoRenewIcon,
    isBlWallet,
  } = props;
  const { isRoundTrip, endDate, availableRides } = props;

  const { cardRef, isTodayLastDay, expireDateTime } = usePassCard({ endDate });

  const trainPassesColors = useMemo(() => {
    if (type === 'train') {
      const cardColor =
        classType === 'smart'
          ? smart_passes_background_color(wallet.smartPassMainColor, wallet.smartPassRadialColor)
          : premium_passes_background_color(wallet.premiumPassMainColor, wallet.premiumPassRadialColor);

      const txtColor = classType === 'smart' ? wallet.smartPassTextColor : wallet.premiumPassTextColor;

      return {
        backgroundColor: cardColor,
        textColor: txtColor,
      };
    }

    return { backgroundColor: '', textColor: '' };
  }, [type, wallet, classType]);

  return (
    <div
      ref={cardRef}
      style={{ background: trainPassesColors.backgroundColor, color: trainPassesColors.textColor }}
      className={bem({ type: type, passType, class: classType, isBlWallet: isBlWallet })}
    >
      <div className={bem('header')}>
        <div className={bem('title')}>{passName}</div>
        {type !== 'credit' && showAutoRenewIcon && (
          <div className={bem('renewal-wrapper', { active: isAutoRenew })}>
            <Icon name="renewal" className={bem('renewal-icon')} />
            {isAutoRenew ? wallet.onLabel : wallet.offLabel}
          </div>
        )}
      </div>
      <div className={bem('details')}>
        {classType && <div className={bem('class', { classType })}>{classType}</div>}
        {originStation && (
          <div className={bem('stations')}>
            <StationLabel
              originStation={originStation}
              destinationStation={destinationStation}
              type={type}
              isRoundTrip={isRoundTrip}
            />
          </div>
        )}
      </div>

      <div className={bem('footer')}>
        <div className={bem('footer-left', { lastDate: isTodayLastDay })}>
          <div className={bem('expires-label')}>
            {isAutoRenew ? wallet.renewsLabel : isTodayLastDay ? wallet.lastDayExpiresLabel : wallet.expiresLabel}
          </div>
          <div className={bem('expires-date')}>
            {isTodayLastDay
              ? `${expireDateTime.format('h:mm a, MMM DD')}`
              : moment.utc(expireDateTime).format('MMM DD, YYYY')}
          </div>
        </div>
        <div className={bem('footer-right')}>
          {availableRides && (
            <FooterRightData
              redeemableRidesLabel={wallet.ridesUsedLabel}
              availableRides={availableRides}
              passType={passType}
            />
          )}
        </div>
      </div>
    </div>
  );
});
