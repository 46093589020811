import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import './PassengerSelection.scss';
import { PassengerSelectionBody } from './components/PassengerSelectionBody';
import { PassengerSelectionProps } from './types';
import { usePassengerSelectionLogic } from './usePassengerSelectionLogic';

const bem = cn('passenger-selection');

export const PassengerSelection = (props: PassengerSelectionProps) => {
  const { isMobile } = props;
  const hookProps = usePassengerSelectionLogic(props);
  const { isOpen, handleClick, hideBody, totalPassengersLabel } = hookProps;

  if (isMobile) return <PassengerSelectionBody {...props} />;

  return (
    <OutsideClickHandler onOutsideClick={hideBody}>
      <div className={bem({ isOpen })}>
        <button
          type="button"
          onClick={handleClick}
          aria-controls="passenger selection content"
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          <span className="sr-only">Number of guests</span>
          <span aria-hidden="true" className={bem('profile-icon')}>
            <Icon name="profile" />
          </span>
          {totalPassengersLabel}
          <span aria-hidden="true" className={bem('arrow-down-icon')}>
            <Icon name={isOpen ? 'arrow-up' : 'arrow-down'} />
          </span>
        </button>
        {isOpen && <PassengerSelectionBody {...props} onClickSubmitButton={hideBody} />}
      </div>
    </OutsideClickHandler>
  );
};
