import { ItemCardItemsConfig } from '@booking/routes/RTI/Extras/components/ExtrasModal/ModalLoader/types';

export const getExtraItemCard = (product, modalConfig, cfItemCard): ItemCardItemsConfig => {
  const { outboundProduct, inboundProduct, productCode, itemLabel } = product;
  const { outboundItems, inboundItems } = modalConfig;

  if (!outboundItems.length)
    outboundItems.push({
      label: cfItemCard?.noItemLabel,
      value: '0',
      tariffCode: '',
      productCode: '',
      price: 0,
    });

  if (!inboundItems.length)
    inboundItems.push({
      label: cfItemCard?.noItemLabel,
      value: '0',
      tariffCode: '',
      productCode: '',
      price: 0,
    });

  if (outboundProduct) {
    const numCurrentOutboundItems = outboundItems.length;

    for (let i = numCurrentOutboundItems; i < numCurrentOutboundItems + outboundProduct.maxQuantitySelectable; i++) {
      const numCurrItems = i - numCurrentOutboundItems + 1;
      const itemPrice = cfItemCard.cumulativePricing
        ? numCurrItems * outboundProduct.pricePerItem + (outboundItems[numCurrentOutboundItems - 1]?.price || 0)
        : numCurrItems * outboundProduct.pricePerItem;

      const legLabel = `${itemPrice > 0 ? itemPrice.toFixed(0) : cfItemCard?.freeLabel} ${cfItemCard?.perLegLabel}`;
      const legPrefix = itemPrice > 0 ? '$' : '';

      const numItemsLabel = cfItemCard.cumulativePricing ? i : i - numCurrentOutboundItems + 1;

      outboundItems.push({
        label: `${numItemsLabel} ${itemLabel} - ${legPrefix}${legLabel}`,
        value: i.toString(),
        tariffCode: outboundProduct.tariffCode,
        productCode: productCode,
        price: itemPrice,
      });
    }
  }

  if (inboundProduct) {
    const numCurrentInboundItems = inboundItems.length;

    for (let i = numCurrentInboundItems; i < numCurrentInboundItems + inboundProduct.maxQuantitySelectable; i++) {
      const numCurrItems = i - numCurrentInboundItems + 1;
      const itemPrice = cfItemCard.cumulativePricing
        ? numCurrItems * inboundProduct.pricePerItem + (inboundItems[numCurrentInboundItems - 1]?.price || 0)
        : numCurrItems * inboundProduct.pricePerItem;

      const legLabel = `${itemPrice > 0 ? itemPrice.toFixed(0) : cfItemCard?.freeLabel} ${cfItemCard?.perLegLabel}`;
      const legPrefix = itemPrice > 0 ? '$' : '';

      const numItemsLabel = cfItemCard.cumulativePricing ? i : i - numCurrentInboundItems + 1;

      inboundItems.push({
        label: `${numItemsLabel} ${itemLabel} - ${legPrefix}${legLabel}`,
        value: i.toString(),
        tariffCode: inboundProduct.tariffCode,
        productCode: productCode,
        price: itemPrice,
      });
    }
  }

  return {
    passengerName: '',
    outboundItems,
    inboundItems,
  };
};
