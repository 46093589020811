import { useQueries } from 'hooks/useQueries';
import { cloneDeep } from 'lodash';
import { rootStore, useStores } from 'stores';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';

export const useModalLogic = props => {
  const {
    searchResultsPage,
    deprecatedSearchResultsPage,
    handleBookingSearch,
    fromStorybook,
    sendSearchAnalyticsEvent,
  } = props;
  const { ticketsFormStore, bookingStore } = useStores();
  const { resetRtiData } = bookingStore.rti;
  const { appendQueries } = useQueries();

  const { setCurrentStep, currentStep, hidePanel, setEditModeData } = ticketsFormStore;
  const { editModeData, trip, setIncompleteSearch, setFormValues, formValues } = ticketsFormStore;

  const { setOutboundTicket } = bookingStore.tickets;

  const handleSubmit = async (data, actions) => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    } else {
      const formikFormValues = cloneDeep(data);
      formikFormValues.promoCode = formValues.promoCode;
      formikFormValues.promoCodeDetails = undefined;

      setIncompleteSearch(false);
      setFormValues(formikFormValues);
      setOutboundTicket(null);

      resetRtiData();
      if (handleBookingSearch) handleBookingSearch(formikFormValues);

      if (fromStorybook) return;

      if (!handleBookingSearch) {
        sendSearchAnalyticsEvent && sendSearchAnalyticsEvent(formikFormValues);
        clickAnalyticsEvent(rootStore, {
          linkClick: {
            pageNameClickTracking: document.title,
            navigationLinkName: 'Modal Submit - Navigate to Trip Details Page',
            customLink: searchResultsPage,
            externalLink: '',
            linkType: 'linkClick',
          },
        });

        await appendQueries(searchResultsPage, deprecatedSearchResultsPage, formikFormValues, trip);
      }
    }
  };

  const doHandleModalClose = (setValues, initialFormValues) => () => {
    setValues(initialFormValues);
    setFormValues(initialFormValues);
    hidePanel();
  };

  const handleEnterEditMode = (values, step) => () => {
    setCurrentStep(step);
    setEditModeData({ lastStep: currentStep, values: values });
  };

  const handleSaveEdit = () => {
    if (editModeData) {
      const { lastStep } = editModeData;
      setCurrentStep(lastStep || 0);
      setEditModeData(null);
    }
  };

  const handleRestoreData = setValues => () => {
    if (editModeData) {
      const { values, lastStep } = editModeData;
      setValues(values);
      setFormValues(values);
      setCurrentStep(lastStep || 0);
      setEditModeData(null);
    }
  };

  return {
    handleSubmit,
    setEditModeData,
    doHandleModalClose,
    handleEnterEditMode,
    handleSaveEdit,
    handleRestoreData,
  };
};
