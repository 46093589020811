import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStores } from 'stores';

import { TicketsForm } from '@molecules/TicketsForm';

import { mapParams } from './utils';

export const BookingTicketsForm = observer(() => {
  const { cfStore } = useStores();
  const { header } = cfStore;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const { bookingStore, ticketsFormStore } = useStores();

  const { tickets } = bookingStore;
  const { searchTickets, resetData, prevSearchBody, outboundTicket } = tickets;

  const { hidePanel, trip } = ticketsFormStore;

  const handleBookingSearch = values => {
    if (!isEqual(prevSearchBody, values) || outboundTicket) {
      const newParams = mapParams({ ...values, trip });

      resetData();
      setSearchParams(newParams);

      searchTickets(values);
    }
    hidePanel();
  };

  return (
    <TicketsForm
      {...header.ticketsForm}
      handleBookingSearch={handleBookingSearch}
      validateOnMount
      hideFormOnMobile
      isBookingFlow
    />
  );
});
