import { Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';

import SafetyPledgeCounterCircle from './SafetyPledgeCounterCircle/SafetyPledgeCounterCircle';
import { SafetyPledgeFormContent } from './SafetyPledgeFormContent/SafetyPledgeFormContent';
import SafetyPledgeFormSuccessMessage from './SafetyPledgeFormSuccessMessage/SafetyPledgeFormSuccessMessage';
import { initialFormValues } from './constants';
import { SafetyPledgeFormProps } from './types';
import { useServerLogic } from './useServerLogic';
import { useValidationSchema } from './useValidationSchema';

const bem = cn('safety-pledge');

const SafetyPledgeForm = (props: SafetyPledgeFormProps) => {
  const {
    title,
    colors,
    description,
    invalidInputLabel,
    requiredInputLabel,
    congratulationsLabel,
    completedPledgeSubtitle,
    pledgeCounter: authoringCounter,
  } = props;

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const validationSchema = useValidationSchema(invalidInputLabel, requiredInputLabel);
  const [checkbox, setCheckbox] = useState(true);

  const { onSubmit, serverStatus, getCounter, pledgeCounter = 0 } = useServerLogic();

  useEffect(() => {
    if (serverStatus === 'success') setIsFormSubmitted(true);
  }, [serverStatus]);

  useEffect(() => {
    getCounter();
  });

  const ref = useRef<HTMLDivElement>(null);

  const handleCheckboxChange = () => {
    setCheckbox(!checkbox);
  };

  const style: any = useMemo(
    () => ({
      '--safety-pledge-background-color': colors?.background?.inactive,
    }),
    [colors]
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    const newValues = {
      ...values,
      disclaimerCheckbox: checkbox,
    };
    await onSubmit(newValues);
    scrollToTop();
    setSubmitting(false);
    if (serverStatus === 'success') setIsFormSubmitted(true);
  };

  const scrollToTop = () => {
    if (ref.current) {
      const offset = -150;
      const top = ref.current.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  };

  return (
    <div ref={ref} className={bem()} style={style}>
      <SafetyPledgeCounterCircle pledges={authoringCounter + pledgeCounter} isFormSubmitted={isFormSubmitted} />
      <div className={bem('content')}>
        {!isFormSubmitted ? (
          <div>
            <div className={bem('row')}>
              <Text text={title} className={bem('title')} as="h2" />
            </div>
            <div className={bem('row')}>
              <div className={cx(bem('column'), bem('description'))}>
                <Text text={description} />
              </div>
              <div className={bem('column')}>
                <Formik
                  initialValues={initialFormValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  <SafetyPledgeFormContent handleCheckboxChange={handleCheckboxChange} checkbox={checkbox} {...props} />
                </Formik>
              </div>
            </div>
          </div>
        ) : (
          <SafetyPledgeFormSuccessMessage
            congratulationsLabel={congratulationsLabel}
            completedPledgeSubtitle={completedPledgeSubtitle}
          />
        )}
      </div>
    </div>
  );
};

export default SafetyPledgeForm;
