import React, { useCallback, useMemo, useState } from 'react';

import Radio from '@atoms/Radio/Radio';
import Tooltip from '@atoms/Tooltip/Tooltip';

import { cn, cx } from 'utils/classNames';
import { useInputLogic } from 'utils/useInputLogic';

import './RadioGroup.scss';
import { RadioGroupProps } from './types';

const bem = cn('radio-group');

const RadioGroup = (props: RadioGroupProps) => {
  const {
    label,
    tooltip,
    disabled,
    items = [],
    className = '',
    maxOptions = 6,
    errorClassName = 'blte-font--variant-tiny-500',
    showMoreLabel = 'Show More',
    showLessLabel = 'Show Less',
  } = props;

  const { field, error, isInvalid } = useInputLogic(props);

  const [allOptionsVisible, setAllOptionsVisible] = useState(false);

  const handleShowMoreClick = () => {
    setAllOptionsVisible(!allOptionsVisible);
  };

  const areOptionsValid = useCallback(
    () => maxOptions > 0 && maxOptions <= items.length - 1,
    [items.length, maxOptions]
  );
  const visibleOptions = useMemo(
    () => (areOptionsValid() ? items.slice(0, maxOptions) : items),
    [areOptionsValid, items, maxOptions]
  );
  const hiddenOptions = useMemo(
    () => (areOptionsValid() ? items.slice(maxOptions) : []),
    [areOptionsValid, items, maxOptions]
  );

  return (
    <div
      className={cx(
        bem({
          'is-disabled': disabled,
        }),
        {
          [className]: !!className,
        }
      )}
    >
      <div className={bem('title-container')}>
        {label && <span className={bem('title')}>{label}</span>}
        {tooltip && <Tooltip text={tooltip} />}
      </div>
      <div className={bem('content')}>
        <div className={bem('visible')}>
          {visibleOptions.map((item, index) => (
            <Radio
              {...field}
              {...item}
              key={`radio-${index}`}
              isInvalid={isInvalid}
              disabled={disabled}
              checked={field.value && field.value === item.value}
            />
          ))}
        </div>
        <div className={allOptionsVisible ? bem('rest') : bem('hidden')}>
          {hiddenOptions.map((item, index) => (
            <Radio
              {...field}
              {...item}
              key={`radio-hidden-${index}`}
              disabled={disabled}
              isInvalid={isInvalid}
              checked={field.value && field.value === item.value}
            />
          ))}
        </div>
        {!!hiddenOptions.length && (
          <span className={bem('show_more')} onClick={handleShowMoreClick}>
            {allOptionsVisible ? showLessLabel : showMoreLabel}
          </span>
        )}
      </div>
      {isInvalid && error && <span className={cx(bem('error'), errorClassName)}>{error}</span>}
    </div>
  );
};

export default RadioGroup;
