import { useAdyen } from 'hooks/useAdyen';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useRef, useState } from 'react';
import { useStores } from 'stores';

import { OverlayContainer } from '@account/components/OverlayContainer';
import PageLoadingPlaceholders from '@account/components/PageLoadingPlaceholder/PageLoadingPlaceholders';
import { useCMSContent } from '@account/hooks/useCMSContent';
import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';

import './PaymentTokens.scss';
import { AddPaymentTokenProps } from './types';

const bemModal = cn('overlay-container');

const AddPaymentToken = observer((props: AddPaymentTokenProps) => {
  const { isOpen, setIsOpen } = props;
  useCMSContent('paymentsCF');
  const { accountStore, cfStore } = useStores();

  const { paymentTokensStore } = accountStore;
  const { account } = cfStore;
  const { paymentsCF } = account;
  const { isLoading, saveToken, hasError, saveTokenErrorMessage } = paymentTokensStore;
  const paymentContainer = useRef(null);
  const [cardInstance, setCardInstance] = useState<any>(null);
  const [cardData, setCardData] = useState<any>(null);

  const { initializeAdyenCard } = useAdyen({
    isOpen,
    paymentContainer,
    setCardInstance,
    setCardData,
  });

  const errorMessage = useMemo(
    () => saveTokenErrorMessage || paymentsCF.cardInformation.invalidCardLabel,
    [saveTokenErrorMessage, paymentsCF]
  );

  const handleToggleAdd = () => {
    setIsOpen(!isOpen);
  };

  const handleSaveCard = () => {
    if (!cardData?.isValid) cardInstance.componentRef.showValidation();
    else
      saveToken(
        {
          ...cardInstance.state.data,
          billingAddress: {
            ...cardInstance.state.billingAddress,
          },
          platform: 'web',
        },
        handleToggleAdd
      );
  };

  if (isLoading) return <PageLoadingPlaceholders />;

  return (
    <OverlayContainer
      onCancel={handleToggleAdd}
      redrawCallback={initializeAdyenCard}
      title={paymentsCF.addNewCardTitle}
      isVisible={isOpen}
      cancelButtonLabel=""
      submitButtonLabel=""
    >
      {hasError && <div className={bemModal('error')}>{errorMessage}</div>}
      <div className="payment-container">
        <div ref={paymentContainer} className="payment" />
      </div>
      <div className={bemModal('buttons')}>
        <Button color="charcoal" variant="secondary" label={paymentsCF.cancelCtaLabel} onClick={handleToggleAdd} />
        <Button color="charcoal" label={paymentsCF.saveCtaLabel} onClick={handleSaveCard} loading={isLoading} />
      </div>
    </OverlayContainer>
  );
});

export default AddPaymentToken;
