import { ImageProps } from '@atoms/Image/types';

import { defaultWalletConfig } from './account/constants.wallet';
import { AccountConfig, RewardsPageConfig } from './types.account';
import { RewardsGeneralConfig } from './types.account';
import { RewardsActivityConfig } from './types.account';
import { RewardsCardConfig } from './types.account';
import { RewardsTrackerConfig } from './types.account';
import { LoyaltyOffersConfig } from './types.account';
import { NavigationItemConfig } from './types.account';
import { ListingPage } from './types.events';
import { GenericConfig } from './types.generic';
import { BookingHeaderConfig } from './types.header';
import { PassesConfig, PassesHeaderConfig } from './types.passes';
import { BrightlineWalletConfig } from './types.payment';
import { ExtrasModalConfig, RTIConfig, SeatsConfig } from './types.rti';
import { FareOptionVariant, TrainSelectionConfig } from './types.trainSelection';

const defaultNavigationItemConfig: NavigationItemConfig = {
  pageTitle: '',
  navigationTitle: '',
  navigationIcon: '',
  link: '',
  actionId: '',
};

const rewardsCardProps: RewardsCardConfig = {
  cardTitleImage: {},
  cardBackgroundImage: {},
  cardIcon: {},
  cardTitle: '',
  cardCountryAbbreviation: '',
  cardTextColor: '',
  downloadCardButtonLabel: '',
  addToAppleWalletImg: {},
  addToGoogleWalletImg: {},
};

const rewardsActivityProps: RewardsActivityConfig = {
  title: '',
  noRewardsActivitiesLabel: '',
  letsChangeThatLabel: '',
  activityLabelTableHeader: '',
  confirmationLabelTableHeader: '',
  rewardsLabelTableHeader: '',
  pendingLabelTableBody: '',
  redeemedLabelTableBody: '',
  ptsLabelTableBody: '',
  tripDetailsPageLink: '',
  paginationTitleLabel: '',
  paginationPageLabel: '',
  paginationNextPageLabel: '',
  paginationPreviousPageLabel: '',
  morePagesLabel: '',
  activePageLabel: '',
  itemsPerPage: '',
};

const rewardsPageProps: RewardsPageConfig = {
  ...defaultNavigationItemConfig,
  merchandisingXfForDesktop: '',
  merchandisingXfForMobile: '',
};

const rewardsTrackerProps: RewardsTrackerConfig = {
  backgroundImage: {},
  progressTooltipText: '',
  availablePointsLabel: '',
  availablePointsLabelColor: '',
  pendingPointsLabel: '',
  pendingLabelColor: '',
  pendingPointsColor: '',
  pointsLabel: '',
  expiresOnLabel: '',
  expiresTooltipText: '',
  redeemPointsButtonLabel: '',
  pendingPointsColorZero: '',
  pendingPointsColorPositive: '',
  firstProgressBarGradientColor: '',
  secondProgressBarGradientColor: '',
  redeemPointsButtonLink: '',
  startEarningButtonLabel: '',
  startEarningButtonLink: '',
};

const loyaltyOffersProps: LoyaltyOffersConfig = {
  title: '',
  firstContainerImage: {},
  firstContainerTitle: '',
  firstContainerDescription: '',
  secondContainerImage: {},
  secondContainerTitle: '',
  secondContainerDescription: '',
};

const rewardsGeneralProps: RewardsGeneralConfig = {
  rewardsCard: rewardsCardProps,
  rewardsActivity: rewardsActivityProps,
  rewardsPage: rewardsPageProps,
  rewardsTracker: rewardsTrackerProps,
  loyaltyOffers: loyaltyOffersProps,
};

const defaultPassIconProps: ImageProps = {
  alt: '',
  lazy: false,
  mobileSrc: '',
  responsive: false,
  sources: [],
  src: '',
  tabletSmSrc: '',
  tabletLgSrc: '',
};

export const defaultBrightlineWalletProps: BrightlineWalletConfig = {
  appliedCreditLabel: '',
  availableCreditLabel: '',
  brightlineCreditPassIcon: defaultPassIconProps,
  disabledBrightlineCreditPassIcon: defaultPassIconProps,
  disabledTravelPassIcon: defaultPassIconProps,
  disabledPremiumPassIcon: defaultPassIconProps,
  multipleTravelPassesAppliedLabel: '',
  multiplePassesApplicableLabel: '',
  noCreditRemainingLabel: '',
  notApplicableLabel: '',
  onePassApplicableLabel: '',
  oneTravelPassAppliedLabel: '',
  title: '',
  travelPassIcon: defaultPassIconProps,
  premiumPassIcon: defaultPassIconProps,
  ridesRemainingLabel: '',
};

export const defaultRtiSeatProps: SeatsConfig = {
  aisleLabel: '',
  backwardFacingLabel: '',
  backwardFacingSeatLabel: '',
  cancelCtaLabel: '',
  cardTitle: '',
  coachTextLabel: '',
  departureLabel: '',
  directionOfTravelLabel: '',
  discardChangesCtaLabel: '',
  discardChangesDescription: '',
  discardChangesTitle: '',
  editBtnLabel: '',
  editLabel: '',
  exitLabel: '',
  forwardFacingLabel: '',
  forwardFacingSeatLabel: '',
  loadingSeatsErrorDescription: '',
  loadingSeatsErrorTitle: '',
  nextTripCtaLabel: '',
  premiumLabel: '',
  premiumSeatLabel: '',
  previousTripCtaLabel: '',
  retryCtaLabel: '',
  returnLabel: '',
  saveLabel: '',
  saveSelectionsCtaLabel: '',
  seatLabel: '',
  seatSelectionSubtitle: '',
  seatSelectionTitle: '',
  selectedSeatLabel: '',
  notModifiableTitle: '',
  notModifiableText: '',
  smartLabel: '',
  smartSeatLabel: '',
  unavailableSeatLabel: '',
  updateSeatsServerErrorMessage: '',
  windowLabel: '',
  seatingNotAvailableMessage: '',
  seatingNotAvailableSmartSaverMessage: '',
};

export const defaultExtraModalConfig: ExtrasModalConfig = {
  s3Code: '',
  analyticsEventType: '',
  modalTitle: '',
  title: '',
  subtitle: '',
  infoCards: [],
  itemCards: [],
  departureLabel: '',
  returnLabel: '',
  toLabel: '',
  disclaimer: '',
  totalLabel: '',
  cancelButtonLabel: '',
  addToTripButtonLabel: '',
  updateButtonLabel: '',
  errorMessageLabel: '',
};

export const defaultRTIProps: RTIConfig = {
  passengers: {
    noLabel: '',
    invalidInputLabel: '',
    dateOfBirthInputLabel: '',
    childLabel: '',
    infantLabel: '',
    firstNameInputLabel: '',
    requiredInputLabel: '',
    yesLabel: '',
    passengerHeaderLabel: '',
    editBtnLabel: '',
    serverErrorLabel: '',
    phoneNumberInputLabel: '',
    needAssistanceLabel: '',
    emailInputLabel: '',
    addSavedPassengerLabel: '',
    needAssistanceLabelShort: '',
    changeLabel: '',
    cardTitle: '',
    adultLabel: '',
    savePassengerLabel: '',
    submitButtonLabel: '',
    serviceAnimalLabel: '',
    generalRequiredError: '',
    passengerPrimaryLabel: '',
    lastNameInputLabel: '',
    wheelchairLabel: '',
    smsNotificationTextLabel: '',
    newsletterDescription: '',
    signUpForNewsletterLabel: '',
    searchCountryCodePlaceholder: '',
    requiredFieldLabel: '',
  },
  costSummary: {
    cardTitle: '',
    cardTitleModifyTrip: '',
    modifySummaryTitle: '',
    newTotalLabel: '',
    originalTotalLabel: '',
    totalDifferenceLabel: '',
    totalRefundLabel: '',
    modificationFeeLabel: '',
    taxesAndFeesLabel: '',
    premiumUpgradeLabel: '',
    premiumUpgradeReason1Label: '',
    premiumUpgradeReason2Label: '',
    premiumUpgradeReason3Label: '',
    upgradeGroupLabel: '',
    premiumIncludedLabel: '',
    promoButtonLabel: '',
    invalidPromoCodeLabel: '',
    promoApplyLabel: '',
    promoCodeLabelInputFloatingLabel: '',
    needHelpLabel: '',
    rewardsIcon: {},
    rewardsTitle: '',
    rewardsSubtext: '',
    rewardsSubtextTabletView: '',
    pointsLabel: '',
    pointLabel: '',
    infoIconText: '',
    hideInfoIcon: false,
    ridesReturned: '',
    modificationRewardsTitle: '',
    modificationRewardsSubtext: '',
    modificationInfoIconText: '',
  },
  tripDetails: {
    cardTitle: '',
    editBtnLabel: '',
  },
  extras: {
    cardTitle: '',
    saveAndContinueLabel: '',
    labelBothDirections: '',
    labelDirectionDeparture: '',
    labelDirectionReturn: '',
    typeLabels: [],
    modalReference: defaultExtraModalConfig,
  },
  reviewAndPay: {
    policyItems: [],
    kioskNotificationText: '',
    brightlineWallet: defaultBrightlineWalletProps,
    brightlineCredit: '',
    brightlineCreditDisclaimerLoggedIn: '',
    brightlineCreditDisclaimerGuest: '',
    cardTitle: '',
    cardTitleNotPaying: '',
    submitBtnLabel: '',
    submitBtnLabelKiosk: '',
    confirmationPageUrl: '',
    giftCardTitle: '',
    giftCardSubtitle: '',
    giftCardIcon: {},
    giftCardAccountNrText: '',
    giftCardPinText: '',
    giftCardButtonText: '',
    giftCardModalText: '',
    giftCardModalSubtitle: '',
    giftCardModalRemoveButton: '',
    giftCardModalCancelButton: '',
    giftCardAppliedFirstText: '',
    redeemedNoBalanceLabel: '',
    redeemedLabel: '',
    limitNumberLabel: '',
    giftCardAppliedSecondText: '',
    giftCardErrorMessageLabel: '',
    giftCardGeneralMessageLabel: '',
    cardInformation: {
      expiresLabel: '',
      newCardLabel: '',
      invalidCardLabel: '',
      cardImages: [],
      cardHolderLabel: '',
      countryLabel: '',
      billingAddressLabel: '',
      cardNumberLabel: '',
      addressLabel: '',
      expirationDateLabel: '',
      zipCodeLabel: '',
      requiredInputLabel: '',
      cityLabel: '',
      saveCardLabel: '',
      stateLabel: '',
      cvcLabel: '',
      cardInfoLabel: '',
      creditCardLabel: '',
      savedCardsLimitLabel: '',
      addNewCreditCardLabel: '',
    },
    travelPassRidesReturnedLabel: '',
    removeGcToAddTravelPassMessage: '',
    availablePointsLabel: '',
    appliedPointsLabel: '',
    applyButtonLabel: '',
    numberOfPointsInputPlaceholder: '',
    rewardPointsGenericErrorMessage: '',
    rewardPointsIcon: {},
    rewardPointsText: '',
    rewardPointsTitle: '',
    insufficientAvailablePointsErrorMessage: '',
  },
  seats: defaultRtiSeatProps,
  parking: {
    modalTitle: '',
    legalDisclaimer: '',
    parkingStationTitle: '',
    parkingStationInfoLabel: '',
    parkingAtLabel: '',
    cancelLabel: '',
    addTripLabel: '',
    updateLabel: '',
    licensePlateLabel: '',
    stateLabel: '',
    statePlaceholderLabel: '',
    legendDescription: '',
    numberOfDaysLabel: '',
    open247IconLabel: '',
    chargingIconLabel: '',
    securityIconLabel: '',
    walkToStationIconLabel: '',
  },
  classTypes: [],
  modifyTripAlertText: '',
  modifyTripText: '',
  timer: {
    dialogueTitleLabel: '',
    minutes: 15,
    okayButtonLabel: '',
    sessionEndsInLabel: '',
  },
  discardBookingModal: {
    enableDiscardBookingFeatureModal: false,
    discardButtonLabel: '',
    discardButtonColor: '',
    cancelButtonLabel: '',
    cancelButtonColor: '',
    description: '',
    title: '',
  },
};

export const defaultTrainSelectionProps: TrainSelectionConfig = {
  faresCompare: {
    submitButtonLabel: '',
    modalTitle: '',
    drawerTitle: '',
    learnMore: '',
    fareUnavailable: '',
    hideDetails: '',
    options: [
      {
        subtitle: '',
        additionalItems: 0,
        title: 'Smart',
        variant: 'smart' as FareOptionVariant,
        shortDescription: '',
        description: '',
        icons: [],
      },
      {
        subtitle: '',
        additionalItems: 0,
        title: 'Premium',
        variant: 'premium' as FareOptionVariant,
        shortDescription: '',
        description: '',
        icons: [],
      },
    ],
  },
  upsell: {
    drawerTitle: '',
    hideModal: false,
    upsellOptions: [
      {
        title: '',
        description: '',
        continueWithButtonLabel: '',
        upgradeToButtonLabel: '',
        option: '',
      },
      {
        title: '',
        description: '',
        continueWithButtonLabel: '',
        upgradeToButtonLabel: '',
        option: '',
      },
    ],
  },
  routeItem: {
    stop: '',
    seatLeft: '',
    direct: '',
    from: '',
    stops: '',
    atPrice: '',
    noAvailableSeats: '',
    seatsLeft: '',
    passEligible: '',
  },
  noResults: {
    description: '',
    title: '',
    buttonLabel: '',
  },
  tripSummary: {
    editSearch: '',
    modify: '',
    selectReturn: '',
    selectDestination: '',
    departure: '',
    trainFound: '',
    trainsFound: '',
    selectDeparture: '',
  },
  searchError: {
    description: '',
    title: '',
    buttonLabel: '',
  },
  sortingLabels: {
    sortByEarliestDepartureTimeLabel: '',
    sortByLatestDepartureTimeLabel: '',
    sortByLowestPriceLabel: '',
    sortByHighestPriceLabel: '',
    optionToSelectByDefault: '',
  },
  promoCodeBanner: {
    enablePromoCodeBanners: false,
    promoCodeMessage: '',
    promoCodeMessageBackgroundColor: '',
    promoCodeMessageTextColor: '',
    promoCodeIcon: {
      src: '',
      sources: [],
      responsive: true,
      lazy: true,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
      title: '',
    },
  },
  brandedFaresSelection: [],
  forcedLogin: {
    modalImage: {},
    brightlineLogoImage: {},
    modalText: '',
    loginButtonLabel: '',
    registerButtonLabel: '',
    continueAsGuestButtonLabel: '',
    errorModalText: '',
    searchAgainButtonLabel: '',
  },
};

export const defaultHeaderProps: BookingHeaderConfig = {
  noTrainsLabel: '',
  logoAlt: '',
  logo: '',
  skipToContentBtnLabel: '',
  skipToCookieBannerBtnLabel: '',
  editSearchButtonLabel: '',
  endSessionLabel: '',
  topNav: {
    data: [],
    colors: {
      nav: { border: {}, background: {}, text: {} },
      menu: { border: {}, background: {}, text: {} },
    },
  },
  ticketsForm: {
    stationsSelection: {
      i18n: {
        toLabel: '',
        fromLabel: '',
        selectOriginError: '',
        fromPlaceholder: '',
        selectDestinationError: '',
        locationHint: '',
        toPlaceholder: '',
        selectFromDropdownError: '',
      },
    },
    tripSelection: {
      trip: 0,
      toggleItems: [],
      orLabel: '',
      buildYourPass: {
        hasIcon: true,
        disabled: false,
        hidden: true,
        colors: {
          border: {},
          shadow: {},
          background: {},
          text: {},
        },
        label: '',
      },
    },
    passengerSelection: {
      maxPassengers: 16,
      i18n: {
        passengers: '',
        submitButtonLabel: '',
        infants: '',
        passenger: '',
        adults: '',
        passengersLimitReached: '',
        infantsDesc: '',
        adultsDesc: '',
        kidsDesc: '',
        infantsLimitReached: '',
        wheelchairSeatsLabel: '',
        wheelchairAccommodationsLabel: '',
        adult: '',
        kids: '',
      },
    },
    promoCode: {
      i18n: {
        label: '',
        applyButtonLabel: '',
        inputFloatingLabel: '',
        invalidPromoCodeErrorMessage: '',
        expiredPromoCodeErrorMessage: '',
      },
    },
    datesSelection: {
      i18n: {
        endDateRequiredError: '',
        startLabel: '',
        closeButtonLabel: '',
        startPlaceholder: '',
        clearButtonLabel: '',
        endPlaceholder: '',
        endLabel: '',
        startDateRequiredError: '',
      },
    },
    i18n: {
      submitFormLabel: '',
      saveLabel: '',
      cancelLabel: '',
      continueLabel: '',
      requiredFieldLabel: '',
    },
    bookingOffers: [],
    endpoint: '',
    searchResultsPage: '',
    deprecatedSearchResultsPage: false,
  },
  brandedFares: {
    mobileFareCompareItems: [],
    desktopFareCompareItems: [],
  },
  gracefulErrors: '',
};

export const defaultGenericProps: GenericConfig = {
  invalidInputLabel: '',
  premiumLabel: '',
  smartLabel: '',
  editLabel: '',
  changeLabel: '',
  serverErrorLabel: '',
  selectPaymentMethodErrorLabel: '',
  paymentFailedErrorMessage: '',
  serverErrorTitle: '',
  serverErrorCTALabel: '',
  requiredInputLabel: '',
  fromLabelDesktopView: '',
  perPassengerLong: '',
  perPassengerShort: '',
  premiumFaresLabel: '',
  tripDetailsLabel: '',
  totalLabel: '',
  totalReturnAsCreditLabel: '',
  totalDueLabel: '',
  removeLabel: '',
  addLabel: '',
  otherLabel: '',
  fromLabel: '',
  atLabel: '',
  toLabel: '',
  nightLabel: '',
  nightsLabel: '',
  welcomeMessage: '',
  passengerSingularLabel: '',
  passengerPluralLabel: '',
  departureLabel: '',
  returnLabel: '',
  bothTripsLabel: '',
  freeLabel: '',
  toCapitalizeLabel: '',
  atCapitalizeLabel: '',
  soldOutLabel: '',
  loginRedirectURL: '',
  earlyMorningResultsLabel: '',
  viewAllLabel: '',
  resultsForLabel: '',
  todayLabel: '',
};

export const defaultAccountProps: AccountConfig = {
  paymentsCF: {
    navigationIcon: 'card',
    showBottomCards: false,
    emptyStateIcon: null,
    pageTitle: '',
    dashboardTitle: '',
    defaultCardLabel: '',
    link: '/account/cards',
    deleteCardLabel: '',
    addNewCardLabel: '',
    addNewCardTitle: '',
    saveCtaLabel: '',
    setDefaultCreditCardLabel: '',
    cancelCtaLabel: '',
    deleteCtaLabel: '',
    editCardLabel: '',
    emptyStateHeader: '',
    showHideBottomCards: false,
    emptyStateBodyLabel: '',
    deleteCardConfirmationText: '',
    deleteCardConfirmationTitle: '',
    customPageTitle: '',
    savedCardsLimitAutoRenewLabel: '',
    cardInformation: {
      creditCardLabel: '',
      invalidCardLabel: '',
      cardImages: [
        {
          image: {
            src: '',
            sources: [],
            responsive: true,
            lazy: true,
            mobileSrc: '',
            tabletSmSrc: '',
            tabletLgSrc: '',
            alt: '',
          },
          title: '',
        },
        {
          image: {
            src: '',
            sources: [],
            responsive: true,
            lazy: true,
            mobileSrc: '',
            tabletSmSrc: '',
            tabletLgSrc: '',
            alt: '',
          },
          title: '',
        },
        {
          image: {
            src: '',
            sources: [],
            responsive: true,
            lazy: true,
            mobileSrc: '',
            tabletSmSrc: '',
            tabletLgSrc: '',
            alt: '',
          },
          title: '',
        },
        {
          image: {
            src: '',
            sources: [],
            responsive: true,
            lazy: true,
            mobileSrc: '',
            tabletSmSrc: '',
            tabletLgSrc: '',
            alt: '',
          },
          title: '',
        },
      ],
      cardHolderLabel: '',
      countryLabel: '',
      billingAddressLabel: '',
      cardNumberLabel: '',
      expirationDateLabel: '',
      zipCodeLabel: '',
      requiredInputLabel: '',
      expiresLabel: '',
      cityLabel: '',
      saveCardLabel: '',
      stateLabel: '',
      cvcLabel: '',
      cardInfoLabel: '',
      newCardLabel: '',
      addressLabel: '',
      savedCardsLimitLabel: '',
      addNewCreditCardLabel: '',
    },
    setDefaultCardLabel: '',
    editCardTitle: '',
  },
  passengersCF: {
    accessibilityLabel: '',
    cancelCtaLabel: '',
    deleteCtaLabel: '',
    createNewPassengerLabel: '',
    dashboardTitle: '',
    dateOfBirthLabel: '',
    deletePassengerLabel: '',
    editPassengerLabel: '',
    editPassengerTitle: '',
    emailLabel: '',
    emptyStateBodyLabel: '',
    emptyStateHeader: '',
    emptyStateIcon: null,
    firstNameLabel: '',
    lastNameLabel: '',
    link: '',
    navigationIcon: '',
    pageTitle: '',
    passengerTypeLabel: '',
    phoneNumberLabel: '',
    requiredInputLabel: '',
    saveCtaLabel: '',
    deletePassengerConfirmationText: '',
    deletePassengerConfirmationTitle: '',
    adultLabel: '',
    childLabel: '',
    infantLabel: '',
    searchCountryCodePlaceholder: '',
  },
  trips: {
    pageTitle: '',
    dashboardTitle: '',
    loadMoreLabel: '',
    noPastDescriptionLabel: '',
    noPastTitleLabel: '',
    noPastTripsButtonLabel: '',
    noPastTripsButtonLink: '',
    noPastTripsLinkLabel: '',
    noUpcomingDescriptionLabel: '',
    noUpcomingOrPastTitleLabel: '',
    addExistingTripCtaLabel: '',
    addExistingTripCtaLink: '',
    noUpcomingTitleLabel: '',
    noUpcomingTripsButtonLabel: '',
    noUpcomingTripsButtonLink: '',
    openNoPastTripsButtonLinkInNewTab: false,
    openAddExistingTripCtaLinkInNewTab: false,
    openNoUpcomingTripsButtonLinkInNewTab: false,
    outOfLabel: '',
    pastItemsLabel: '',
    pastTripsLabel: '',
    seeTicketsLabel: '',
    classTypes: [],
    tripDetailsLabel: '',
    upcomingItemsLabel: '',
    upcomingTripsLabel: '',
    upcomingStatusLabel: '',
    seeAllPast: '',
    seeAllCount: '',
    pastTripsCount: '',
    upcomingTripsCount: '',
    upcomingTripsLoadMoreNumber: '',
    pagination: {
      activePageLabel: '',
      morePagesLabel: '',
      nextPageLabel: '',
      pageLabel: '',
      previousPageLabel: '',
      titleLabel: '',
      itemsPerPage: '',
    },
    tripCancelledLabel: '',
  },
  registration: {
    setupErrors: {
      maxDateError: '',
      fieldEmpty: '',
      minDateError: '',
      connectionError: '',
      dataError: '',
      maxLengthError: '',
      title: '',
      invalidDate: '',
      minLengthError: '',
      requiredError: '',
    },
    setupName: {
      lastName: '',
      note: '',
      firstName: '',
      zipCode: '',
      continueButtonLabel: '',
      subTitle: '',
      tosLabel: '',
      dateOfBirth: '',
      title: '',
      buttonLabel: '',
    },
    setupPhone: {
      phoneLabel: '',
      termsOfServiceConsent: '',
      subTitle: '',
      phonePlaceholder: '',
      automatedTextsConsent: '',
      title: '',
      buttonLabel: '',
      searchCountryCodePlaceholder: '',
    },
    setupLoyalty: {
      brandImage: {},
      declineButtonLabel: '',
      disclaimerAndTosText: '',
      joinButtonLabel: '',
      loyaltyBenefitsBackgroundColor: '',
      loyaltyBenefitsCards: [],
      modalBrandImage: {},
      modalDeclineButtonLabel: '',
      modalDisclaimerAndTosText: '',
      modalJoinButtonLabel: '',
      modalLoyaltyBenefitsBackgroundColor: '',
      modalLoyaltyBenefitsCards: [],
      modalTitle: '',
      modalTitleLoyaltyText: '',
      titleLoyaltyText: '',
      setupErrors: {
        maxDateError: '',
        fieldEmpty: '',
        minDateError: '',
        connectionError: '',
        dataError: '',
        maxLengthError: '',
        title: '',
        invalidDate: '',
        minLengthError: '',
        requiredError: '',
      },
      welcomeModalBrandImage: {},
      welcomeModalButtonLabel: '',
      welcomeModalButtonLink: '',
      welcomeModalSubtitle: '',
      welcomeModalTitle: '',
    },
    setupConfirmation: {
      xsImage: {
        src: '',
        sources: [],
        responsive: false,
        lazy: false,
        mobileSrc: '',
        tabletSmSrc: '',
        tabletLgSrc: '',
        alt: '',
        width: '',
        height: '',
      },
      text: '',
      lgImage: {
        src: '',
        sources: [],
        responsive: false,
        lazy: false,
        mobileSrc: '',
        tabletSmSrc: '',
        tabletLgSrc: '',
        alt: '',
        width: '',
        height: '',
      },
      title: '',
      buttonLabel: '',
    },
  },
  tripDetails: {
    seeTicketsLabel: '',
    printTicketsLabel: '',
    shareTripLabel: '',
    tripsLabel: '',
    departedMessage: '',
    cancelMessage: '',
    boardingCloseLabel: '',
    passengersLabel: '',
    extrasLabel: '',
    adultPassengerTypeLabel: '',
    editSeatsLabel: '',
    addLuggageLabel: '',
    includedCarryOnLabel: '',
    departureLabel: '',
    returnLabel: '',
    coachLabel: '',
    childPassengerTypeLabel: '',
    primaryPassengerTypeLabel: '',
    errorTitle: '',
    errorDescription: '',
    errorCtaLabel: '',
    seats: defaultRtiSeatProps,
    delaysExperienceMessages: {
      completedStatusLabel: '',
      onTimeStatusLabel: '',
      delayedStatusLabel: '',
      cancelledStatusLabel: '',
      updatedStatusLabel: '',
      delayedMessageTitle: '',
      delayedMessageText: '',
      cancelledMessageTitle: '',
      cancelledMessageText: '',
      backOfficeCancelMessage: '',
      reaccomMessageTitle: '',
      reaccomMessageText: '',
    },
    extrasTypes: [],
    editLabel: '',
    genericNoModificationsAllowedLabel: '',
    noModificationAllowedReasons: [],
    classTypes: [],
    fromLabel: '',
    toLabel: '',
    atLabel: '',
    passengerSingularLabel: '',
    passengerPluralLabel: '',
    nightLabel: '',
    nightsLabel: '',
    navigation: {
      ticketsAndReceiptsSection: {
        title: '',
        downloadTickets: '',
        downloadReceipt: '',
        downloadDetails: '',
        receiptErrorHeading: '',
        receiptErrorText: '',
        receiptErrorButtonLabel: '',
      },
      manageTripsSection: {
        requestAssistance: '',
        addEditExtras: '',
        title: '',
        upgradeTrip: '',
        managePassengers: '',
        addEditExtrasModalDescription: '',
        addEditExtrasModalTitle: '',
        addEditExtrasModalButton: '',
        modifyTrip: '',
        modifyTripModalTitle: '',
        modifyTripModalDescription: '',
        modifyTripModalButtonLabel: '',
        modifyTripSmartSaverDisabledOnlyOneLeg: '',
        modifyTripSmartSaverDisabledBothLegs: '',
        timeLimitDisabled: '',
        modifyTripTravelAgent: '',
        contactCustomerServices: '',
      },
      modifyTripSection: {
        bothTabLabel: '',
        calendarClearDatesButtonLabel: '',
        calendarCloseButtonLabel: '',
        departuredatelabel: '',
        fromDropdownLabel: '',
        fromDropdownPlaceholderLabel: '',
        fromPlaceholder: '',
        locationHint: '',
        returnTabLabel: '',
        returndatelabel: '',
        searchTicketsButtonLabel: '',
        selectDestinationError: '',
        selectOriginError: '',
        toDropdownLabel: '',
        toDropdownPlaceholderLabel: '',
        toPlaceholder: '',
        widgetDescriptionText: '',
        widgetTitleLabel: '',
      },
      cancelTrip: {
        title: '',
        text: '',
        loyaltyText: '',
        cancellationGroupMessageText: '',
        confirmButtonLabel: '',
        cancelButtonLabel: '',
        viewUpcomingTripsButtonLabel: '',
        creditCardIcon: {},
        brightlineCreditIcon: {},
        serverErrorLabel: '',
        cancelTripNotPossibleTitle: '',
        cancelTripNotPossibleMessage: '',
        tripCancelledTitle: '',
        tripCancelledMessage: '',
        modalTitle: '',
        returnSummaryLabel: '',
        brightlineCredit: '',
        availableMessage: '',
        returnAmountMessage: '',
        neverMindButtonLabel: '',
        cancellationDetailLabel: '',
        cancellationFeeLabel: '',
        ssCancellationFeeMessage: '',
        smartCancellationFeeMessage: '',
        premiumCancellationFeeMessage: '',
        taxesAndFeesLabel: '',
        originalTotalLabel: '',
        totalCancellationFeeLabel: '',
        totalReturnedLabel: '',
        ridesReturned: '',
        travelPassRidesReturnedLabel: '',
        selectDescription: '',
        discardTitleLabel: '',
        discardDescription: '',
        departedMessage: '',
        cancelMessage: '',
        tripCancellationContinueButton: '',
        tripCancellationBackButton: '',
        discardModalContinueButton: '',
        discardModalBackButton: '',
      },
      revertProvisionalChanges: {
        titleLabel: '',
        textLabel: '',
        continueModificationButtonLabel: '',
        discardChangesButtonLabel: '',
      },
      modifyTrip: '',
      managePassengers: {
        swapGuestLabel: '',
        saveNewGuestLabel: '',
        saveButtonLabel: '',
        closeModalTitle: '',
        closeModalDescription: '',
        discardChangesButtonLabel: '',
        saveAndCloseButtonLabel: '',
        savedChangesLabel: '',
        dateOfBirthErrorLabel: '',
        changeGuestsErrorLabel: '',
        adultLabel: '',
        childLabel: '',
        infantLabel: '',
        invalidInputLabel: '',
        serverErrorLabel: '',
        requiredInputLabel: '',
        passengerHeaderLabel: '',
        generalRequiredError: '',
        dateOfBirthInputLabel: '',
        firstNameInputLabel: '',
        requiredFieldLabel: '',
        phoneNumberInputLabel: '',
        emailInputLabel: '',
        lastNameInputLabel: '',
        savePassengerLabel: '',
        changeLabel: '',
        passengerPrimaryLabel: '',
        passengerCannotBeModifiedLabel: '',
        searchCountryCodePlaceholder: '',
      },
      cancelTripLabel: '',
    },
    cancellationReaccBannerTitle: '',
    cancellationReaccBannerDescription: '',
    cancellationNotReaccBannerTitle: '',
    cancellationNotReaccBannerDescription: '',
    editDailyParkingLicensePlate: '',
    editDailyParkingTitle: '',
    editDailyParkingGarageSelectLabel: '',
    editDailyParkingStateSelectLabel: '',
    editDailyParkingLicensePlateTextFieldPlaceholder: '',
    editDailyParkingCancelCtaLabel: '',
    editDailyParkingSaveCtaLabel: '',
    editDailyParkingLicensePlateTextFieldInvalidLabel: '',
    travelAgentModalDescription: '',
    travelAgentModalCancelCta: '',
    andOtherEmailsLabel: '',
    sendEmailButtonLabel: '',
    closeButtonLabel: '',
    addRecipientLabel: '',
    bottomExperienceFragment: '',
    cancelButtonLabel: '',
    ctaBackgroundColor: '',
    ctaOpenInNewTab: false,
    ctaOpenInNewWindow: false,
    emailInputPlaceholder: '',
    merchandisingBannerBackgroundColor: '',
    merchandisingBannerText: '',
    merchandisingCtaLink: '',
    merchandisingCtaText: '',
    mobilityTitle: '',
    shareTripSubtitle: '',
    shareTripTitle: '',
    tripDetailsHaveBeenSentToLabel: '',
    serverErrorMessage: '',
  },
  sidebarNavigation: {
    navigationMenuLabel: '',
    navigationMenuIcon: {},
    closeSidebarNavigationIcon: {},
    primaryNavigation: [],
    secondaryNavigation: [],
  },
  dashboard: {
    navigationIcon: '',
    pageTitle: '',
    link: '',
    seeAllLabel: '',
    brightlineWallet: defaultWalletConfig,
    myTrips: {
      navigationIcon: '',
      pageTitle: '',
      link: '',
    },
    paymentToken: {
      navigationIcon: '',
      pageTitle: '',
      link: '',
    },
    savedPassengers: {
      navigationIcon: '',
      pageTitle: '',
      link: '',
    },
    accountDashboardTitle: '',
    viewWalletButtonLabel: '',
    merchandisingXfForDesktop: '',
    merchandisingXfForMobile: '',
    profileRedirectionLinkReference: defaultNavigationItemConfig,
    memberSinceLabel: '',
    chevronRightIcon: {},
    rewardsRedirectionLinkReference: defaultNavigationItemConfig,
    rewardsBackgroundImage: {},
    rewardsTitle: '',
    availablePointsLabel: '',
    availableLabelColor: '',
    pendingPointsLabel: '',
    pendingLabelColor: '',
    pendingPointsColor: '',
    pointsLabel: '',
    title: '',
    firstContainerImage: {},
    firstContainerTitle: '',
    firstContainerDescription: '',
    secondContainerImage: {},
    secondContainerTitle: '',
    secondContainerDescription: '',
  },
  wallet: defaultWalletConfig,
  settings: {
    pageTitle: '',
    navigationTitle: '',
    actionId: '',
    invalidInputLabel: '',
    deleteAccountLabel: '',
    deleteAccountConfirm2Text: '',
    deleteAccountConfirmTitle: '',
    profileDetailsTitle: '',
    phoneNumberLabel: '',
    zipCodeLabel: '',
    noAccessibilityDescription: '',
    requiredInputLabel: '',
    passwordLabel: '',
    deleteAccountCtaLabel: '',
    saveCtaLabel: '',
    editProfileDetailsTitle: '',
    wheelchairAccessibilityLabel: '',
    dateOfBirthLabel: '',
    deleteAccountDescription: '',
    deleteInputKeyword: '',
    accessibilityLabel: '',
    nameLabel: '',
    mobilePhoneNumberLabel: '',
    showBottomCards: false,
    deleteCtaLabel: '',
    editDetailsCtaLabel: '',
    securityHeader: '',
    phoneCountryCodeLabel: '',
    lastNameLabel: '',
    cancelCtaLabel: '',
    resetPasswordDescription: '',
    resetCtaLabel: '',
    hideResetPassword: false,
    deleteAccountConfirm1Text: '',
    firstNameLabel: '',
    emailLabel: '',
    travelAgentCodeTitle: '',
    travelAgentCodeLabel: '',
    nineDigitTravelCodeLabel: '',
    cancelCtaTravelAgentLabel: '',
    updateCtaTravelAgentLabel: '',
    travelAgentCodeInvalidDescription: '',
    travelAgentCodeValidDescription: '',
    travelAgentCodeInvalidLabel: '',
    requiredFieldLabel: '',
    searchCountryCodePlaceholder: '',
  },
  rewardsGeneral: rewardsGeneralProps,
  commissions: {
    ...defaultNavigationItemConfig,
    displayBannerPerViewport: '',
    bannerTitle: '',
    bannerSubtext: '',
    bannerSecondSubtext: '',
    bannerLink: '',
    commissionTableTitle: '',
    commissionTableDisclaimer: '',
    downloadIcon: '',
    commissionTableFieldsName: [''],
    emptyTableTitle: '',
    emptyTableMessaging: '',
    emptyTableCtaLabel: '',
    emptyTableCtaLink: '',
    offersForYouTitle: '',
    bottomExperienceFragment: '',
    bottomSecondExperienceFragment: '',
    pageNotFoundLabel: '',
  },
};

export const defaultPassesProps: PassesConfig = {
  passesSummary: {
    autoRenewalLabel: '',
    trainPassLabel: '',
    invalidLicenseNumberMessage: '',
    garageLabel: '',
    monthlyParkingPassLabel: '',
    licensePlateNumberLabel: '',
    stateLabel: '',
    odUpdateMessage: '',
    editLinkToTrainPassPage: '',
    editLinkToTrainPassPageMobile: '',
    editCtaLabel: '',
    editLinkToParkingPassPage: '',
    editLinkToParkingPassPageMobile: '',
  },
  costSummary: {
    totalLabel: '',
    saveWithAutoRenewLabel: '',
    subtotalLabel: '',
    trainPassLabel: '',
    costSummaryTitle: '',
    taxesAndFeesLabel: '',
    promoButtonLabel: '',
    promoCodeLabelInputFloatingLabel: '',
    needHelpLabel: '',
    parkingPassLabel: '',
    rewardsIcon: {},
    rewardsTitle: '',
    rewardsSubtext: '',
    rewardsSubtextTabletView: '',
    pointsLabel: '',
    pointLabel: '',
    infoIconText: '',
    hideInfoIcon: false,
  },
  reviewAndPay: {
    payAndBookButtonLabel: '',
    loginRequiredLabel: '',
    passesAreNotRefundableLabel: '',
    celebrationBannerLink: '',
    celebrationBannerLinkForMobile: '',
    addCreditOrDebitCardLabel: '',
    savedCardsLimitLabel: '',
    loginLabel: '',
    reviewAndPayTitle: '',
    giftCardTitle: '',
    giftCardSubtitle: '',
    giftCardIcon: {},
    giftCardAccountNrText: '',
    giftCardPinText: '',
    giftCardButtonText: '',
    giftCardModalText: '',
    giftCardModalSubtitle: '',
    giftCardModalRemoveButton: '',
    giftCardModalCancelButton: '',
    giftCardAppliedFirstText: '',
    redeemedNoBalanceLabel: '',
    redeemedLabel: '',
    limitNumberLabel: '',
    giftCardAppliedSecondText: '',
    giftCardErrorMessageLabel: '',
    giftCardGeneralMessageLabel: '',
    cardInformation: {
      creditCardLabel: '',
      invalidCardLabel: '',
      invalidInputLabel: '',
      cardImages: [
        {
          image: {
            src: '',
            sources: [],
            responsive: true,
            lazy: true,
            mobileSrc: '',
            tabletSmSrc: '',
            tabletLgSrc: '',
            alt: '',
          },
          title: '',
        },
        {
          image: {
            src: '',
            sources: [],
            responsive: true,
            lazy: true,
            mobileSrc: '',
            tabletSmSrc: '',
            tabletLgSrc: '',
            alt: '',
          },
          title: '',
        },
        {
          image: {
            src: '',
            sources: [],
            responsive: true,
            lazy: true,
            mobileSrc: '',
            tabletSmSrc: '',
            tabletLgSrc: '',
            alt: '',
          },
          title: '',
        },
        {
          image: {
            src: '',
            sources: [],
            responsive: true,
            lazy: true,
            mobileSrc: '',
            tabletSmSrc: '',
            tabletLgSrc: '',
            alt: '',
          },
          title: '',
        },
      ],
      cardHolderLabel: '',
      countryLabel: '',
      billingAddressLabel: '',
      cardNumberLabel: '',
      addressLabel: '',
      expirationDateLabel: '',
      zipCodeLabel: '',
      requiredInputLabel: '',
      expiresLabel: '',
      cityLabel: '',
      saveCardLabel: '',
      stateLabel: '',
      cvcLabel: '',
      newCardlabel: '',
      cardInfoLabel: '',
    },
    disclaimerLabel: '',
    generalErrorLabel: '',
    availablePointsLabel: '',
    appliedPointsLabel: '',
    applyButtonLabel: '',
    numberOfPointsInputPlaceholder: '',
    rewardPointsGenericErrorMessage: '',
    rewardPointsIcon: {},
    rewardPointsText: '',
    rewardPointsTitle: '',
    insufficientAvailablePointsErrorMessage: '',
  },
};

export const defaultPassesHeaderProps: PassesHeaderConfig = {
  gracefulErrors: '',
};

export const defaultEventsProps: ListingPage = {
  activePageLabel: '',
  carousel: '',
  clearCategoriesLabel: '',
  eventItems: [],
  filterItems: [],
  filtersLabelMobileDrawer: '',
  itemsPerPage: '',
  morePagesLabel: '',
  newsletterForm: '',
  nextPageLabel: '',
  noResultsTitle: '',
  noResultsMessage: '',
  noResultsIcon: {
    src: '',
    alt: '',
  },
  noResultsTitleNoFiltersApplied: '',
  noResultsMessageNoFiltersApplied: '',
  noResultsIconNoFiltersApplied: {
    src: '',
    alt: '',
  },
  pageLabel: '',
  previousPageLabel: '',
  titleAndSubtitle: '',
  titleLabel: '',
  upcomingEventsLabel: '',
  cityFilter: {
    cityFilterLabel: '',
    cityFilterIcon: {
      src: '',
      sources: [],
      responsive: false,
      lazy: false,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
    },
    categoryFilterLabel: '',
    categoryFilterIcon: {
      src: '',
      sources: [],
      responsive: false,
      lazy: false,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
    },
    allCitiesLabel: '',
    allCitiesIcon: {
      src: '',
      sources: [],
      responsive: false,
      lazy: false,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
    },
    orlandoLabel: '',
    orlandoIcon: {
      src: '',
      sources: [],
      responsive: false,
      lazy: false,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
    },
    westPalmBeachLabel: '',
    westPalmBeachIcon: {
      src: '',
      sources: [],
      responsive: false,
      lazy: false,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
    },
    bocaRatonLabel: '',
    bocaRatonIcon: {
      src: '',
      sources: [],
      responsive: false,
      lazy: false,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
    },
    fortLauderdaleLabel: '',
    fortLauderdaleIcon: {
      src: '',
      sources: [],
      responsive: false,
      lazy: false,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
    },
    aventuraLabel: '',
    aventuraIcon: {
      src: '',
      sources: [],
      responsive: false,
      lazy: false,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
    },
    miamiLabel: '',
    miamiIcon: {
      src: '',
      sources: [],
      responsive: false,
      lazy: false,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
    },
    clearCitiesLabel: '',
    clearAllFiltersLabel: '',
    closeLabel: '',
  },
  calendarFilter: {
    dateFilterLabel: '',
    dateFilterIcon: {
      src: '',
      sources: [],
      responsive: false,
      lazy: false,
      mobileSrc: '',
      tabletSmSrc: '',
      tabletLgSrc: '',
      alt: '',
    },
    weekdaysFilterLabel: '',
    weekdaysFilterValue: '',
    weekendsFilterLabel: '',
    weekendsFilterValue: '',
    thisWeekFilterLabel: '',
    thisWeekFilterValue: '',
    nextWeekFilterLabel: '',
    nextWeekFilterValue: '',
    thisMonthFilterLabel: '',
    thisMonthFilterValue: '',
    nextMonthFilterLabel: '',
    nextMonthFilterValue: '',
    closeButtonLabel: '',
    clearButtonLabel: '',
  },
  numberOfFiltersTabletLarge: 1,
  numberOfFiltersTabletMedium: 1,
  numberOfFiltersTabletSmall: 1,
  offersCards: '',
  topExperienceFragment: '',
  bottomExperienceFragment: '',
  showTopPicksSection: false,
  topPicksTitle: '',
  topPicksDescription: '',
  topPicksTitleType: 'h2',
  topPicksTitleFontStyle: 'h2',
  topPicksDescriptionType: 'h3',
  topPicksDescriptionFontStyle: 'body-medium-500',
};
