import { useState } from 'react';

import { consentAnalyticsEvent, emailOptInAnalyticsEvent } from 'utils/adobeDataLayer';

export const useServerLogic = () => {
  const [serverStatus, setServerStatus] = useState('initial');

  const onSubmit = data => {
    setServerStatus('loading');

    emailOptInAnalyticsEvent({
      emailOptIn: {
        formName: 'emailOptIn',
        formSubmitAll: true,
        email: data.email,
        postalCode: data.postalCode,
      },
    });
    consentAnalyticsEvent({ email: data.email });

    setServerStatus('success');
  };

  return { onSubmit, serverStatus };
};
