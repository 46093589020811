import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './ErrorBanner.scss';

const bem = cn('passes-error-banner');

export const ErrorBanner = observer(() => {
  const { cfStore, passesStore } = useStores();

  const checkoutCartErrorMessage = passesStore.checkout.checkoutCartErrorMessage;
  const errorLabel = cfStore.passes.reviewAndPay.generalErrorLabel;

  const errorMessage = useMemo(() => checkoutCartErrorMessage || errorLabel, [checkoutCartErrorMessage, errorLabel]);

  return (
    <div className={bem()}>
      <div className={bem('banner')}>
        <Icon name="warning" className={bem('icon')} />
        <Text text={errorMessage} className={bem('text')} />
      </div>
    </div>
  );
});
