import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { Button } from '@atoms/Button';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';
import { TrainPassesOption } from '@organisms/TrainPasses/TrainPassesCard/TrainPassesOptions/components/TrainPassesOption';
import { TrainPassOptionConfig, TrainPassStepsProps } from '@organisms/TrainPasses/types';

import { cn } from 'utils/classNames';

import './TrainPassesOptions.scss';

const bem = cn('train-passes-options');

export const TrainPassesOptions = (props: TrainPassStepsProps) => {
  const { i18n, useTrainPassLogic, passesConfigurations } = props;
  const { setTotal, options, configuredPassOptions, setConfiguredPassOptions } = useTrainPassLogic;
  const { values: formValues, setFieldValue } = useFormikContext();
  const values = formValues as any;

  const handleOptionClick = (option: TrainPassOptionConfig) => {
    setFieldValue('option', option.name);
  };

  useEffect(() => {
    if (!values?.option || !options) {
      setFieldValue('option', '');
      setTotal(0);

      return;
    }

    const option = options.find(option => option.name === values?.option);

    if (!option) {
      setFieldValue('option', '');
      setTotal(0);

      return;
    }

    setTotal(option.priceInCents / 100);
  }, [setFieldValue, setTotal, options, values]);

  useEffect(() => {
    setConfiguredPassOptions(
      options.filter((passItem: TrainPassOptionConfig) =>
        passesConfigurations?.some(configuration => passItem.code === configuration.passItem.pairKey)
      )
    );
  }, [options, passesConfigurations, setConfiguredPassOptions]);

  const ctaLabelOptions = (option: TrainPassOptionConfig): string => {
    const configuration = passesConfigurations?.find(config => config.passItem.pairKey === option.code);

    if (configuration && configuration.passOverrideCtaName) return configuration.passItem.pairValue;

    return option.name;
  };

  const getPassOptionDetails = (code: string) => {
    const configuration = passesConfigurations?.find(config => config.passItem.pairKey === code);

    if (configuration)
      return {
        title: configuration.passItem.passTitle,
        description: configuration.passItem.passDescription,
        tooltip: configuration.passItem.passTooltip,
      };

    return {
      title: '',
      description: '',
      tooltip: '',
    };
  };

  return (
    <div className={bem()}>
      <div className={bem('subtitle')}>
        <Title size="h3" text={i18n?.passTypeTitle} />
      </div>
      <div>
        {!configuredPassOptions.length && (
          <div className={bem('options-container')}>
            <Text text="No options available" />
          </div>
        )}
        {configuredPassOptions.map((passOption: TrainPassOptionConfig, index: number) => (
          <div key={index} className={bem('options-container')}>
            <TrainPassesOption
              title={getPassOptionDetails(passOption.code).title}
              description={getPassOptionDetails(passOption.code).description}
              tooltip={getPassOptionDetails(passOption.code).tooltip}
            />
            <div className={bem('row')}>
              <div className={bem('option-buttons')}>
                <Button
                  key={index}
                  color={values.option === passOption.name ? 'blue' : 'white'}
                  label={ctaLabelOptions(passOption)}
                  onClick={() => handleOptionClick(passOption)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
