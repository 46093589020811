import React from 'react';

import { cn } from 'utils/classNames';

import './CardTitle.scss';
import { TitleProps } from './types';

const bem = cn('trip-details-card');

export const CardTitle = ({ title, noMargin = false }: TitleProps) => <div className={bem('title', {noMargin})}>{title}</div>;
