import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { useRouteItemLogic } from '@booking/routes/TrainSelection/components/RouteItem/useRouteItemLogic';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './RouteItem.scss';
import { PriceItem } from './components/PriceItem';
import { TimeItem } from './components/TimeItem';
import { RouteItemProps } from './types';

const bem = cn('route-item');

export const RouteItem = observer((props: RouteItemProps) => {
  const { journey, footerTags } = props;

  const { origin, destination, duration, numberOfStops } = journey || {};
  const { arrivalTime, departureTime, tags, serviceTypeTag } = journey || {};
  const { defaultPremiumProduct, isUnavailable } = journey || {};

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const { bookingStore } = useStores();
  const { tickets } = bookingStore;
  const { loading } = tickets;

  const { smartProduct, premiumProduct, productConfig, handleTicketSelect, isSmartTravelPassEligible } = useRouteItemLogic(props);

  return (
    <li
      className={bem({ unavailable: isUnavailable })}
      tabIndex={isUnavailable ? -1 : 0}
      role="group"
      aria-label="Train departure"
    >
      <div className={bem('time')}>
        <TimeItem
          origin={origin}
          destination={destination}
          departureTime={departureTime}
          arrivalTime={arrivalTime}
          numberOfStops={numberOfStops}
          duration={duration}
          footerTags={footerTags}
          tags={tags}
          serviceTypeTag={serviceTypeTag}
          smartProduct={smartProduct}
          premiumProduct={premiumProduct}
          loading={loading}
          isMobile={isMobile}
        />
      </div>

      <div className={bem('prices')}>
        <PriceItem
          journey={journey}
          product={productConfig.product}
          isMobile={isMobile}
          defaultProduct={productConfig.defaultProduct}
          isTravelPassEligible={isSmartTravelPassEligible}
          loading={loading}
          onTicketSelect={handleTicketSelect}
          isUnavailable={isUnavailable}
        />
        {!isMobile && (
          <PriceItem
            journey={journey}
            product={premiumProduct}
            isTravelPassEligible={premiumProduct?.isTravelPassEligible || false}
            defaultProduct={defaultPremiumProduct}
            loading={loading}
            onTicketSelect={handleTicketSelect}
            isUnavailable={isUnavailable}
          />
        )}
      </div>
    </li>
  );
});
