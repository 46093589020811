import { useMemo, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { rootStore, useStores } from 'stores';
import { SelectedItemConfig } from 'stores/BookingStore/sections/Tickets/types';
import { SeatProduct } from 'stores/BookingStore/sections/Tickets/types.journey';
import { BrandedFaresSelectionConfig } from 'stores/CFStore/types.trainSelection';

import { Width } from '@atoms/Button/types';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { mediaQueryList } from 'utils/mediaQueries';
import { isBrandedFaresEnabled } from 'utils/metas';

export const useFareSelectionDrawerLogic = () => {
  const navigate = useNavigate();
  const isMaxTablet = useMediaPredicate(mediaQueryList.maxTablet);
  const { cfStore, bookingStore } = useStores();
  const { trainSelection } = cfStore;
  const { faresCompare, brandedFaresSelection } = trainSelection;
  const { submitButtonLabel } = faresCompare;
  const location = useLocation();

  const [smartSaverSelected, setSmartSaverSelected] = useState(false);

  const {
    setDrawerVisibility,
    drawerVisible,
    confirmTicket,
    selectedItem,
    premiumOnly,
    isFareSelected,
    setIsFareSelected,
    selectItem,
    setFareTableDrawerVisible,
    fareTableDrawerVisible,
    farePriceDifference,
    setForcedLoginModal,
    setForcedLoginError,
  } = bookingStore.tickets;
  const { journey } = (selectedItem as SelectedItemConfig) || {};

  const isBFEnabled = isBrandedFaresEnabled();

  const isVisible = isMaxTablet && drawerVisible;

  const handleOpenFareTableDrawer = () => {
    if (isBFEnabled) {
      setDrawerVisibility(false);
      setFareTableDrawerVisible(true);
    }
  };

  const handleCloseFareTableDrawer = () => {
    setFareTableDrawerVisible(false);
    setDrawerVisibility(true);
  };

  const handleClose = () => {
    if (smartSaverSelected) setSmartSaverSelected(false);
    else {
      setDrawerVisibility(false);
      setIsFareSelected(false);
    }
  };

  const fareCompareItems: BrandedFaresSelectionConfig[] = useMemo(() => {
    if (!journey) return [];

    const originAndDestinationPair = [
      `${journey.origin.id}-${journey.destination.id}`,
      `${journey.destination.id}-${journey.origin.id}`,
    ];

    const defaultSelections = brandedFaresSelection.filter(option => option.fareVariant && option.defaultConfiguration);

    const matchingSelections = brandedFaresSelection.filter(option => {
      if (
        !option.fareTypeLabel ||
        !originAndDestinationPair.some(item => option.originAndDestinationStation?.includes(item))
      )
        return false;

      if (premiumOnly) return option.fareVariant === 'PREMIUM_STANDARD';

      if (smartSaverSelected) return option.fareVariant === 'SMART_SAVER';

      return true;
    });

    defaultSelections.forEach(defaultOption => {
      const fareVariant = defaultOption.fareVariant;

      if (premiumOnly && fareVariant !== 'PREMIUM_STANDARD') return;

      if (smartSaverSelected && fareVariant !== 'SMART_SAVER') return;

      if (!matchingSelections.find(option => option.fareVariant === fareVariant))
        matchingSelections.push(defaultOption);
    });

    return matchingSelections;
  }, [brandedFaresSelection, premiumOnly, journey, smartSaverSelected]);

  const getPromoCode = () => {
    const hasLocationState = location.state && location.state.ticket;
    const searchParams = hasLocationState
      ? location.state.ticket
      : Object.fromEntries(new URLSearchParams(location.search));
    const { promo_code } = searchParams;

    return promo_code || '';
  };

  const navigateToRTI = bookingResponse => {
    const promo_code = getPromoCode();
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: fareCompareItems?.[0]?.upgradeCtaLabel || 'Forced Login',
        customLink: promo_code ? `/rti?promo_code=${promo_code}` : '/rti',
        externalLink: '',
        linkType: 'linkClick',
      },
      tripManagement: {
        PNR: bookingResponse?.tripDetails?.trip.referenceNumber,
      },
    });

    setForcedLoginModal(undefined);
    setForcedLoginError(false);

    if (promo_code) navigate(`/rti?promo_code=${promo_code}`);
    else navigate('/rti');
  };

  const handleConfirm = () => {
    if (isFareSelected && selectedItem?.ticket?.productId === 'SMART_SAVER' && !smartSaverSelected)
      setSmartSaverSelected(true);
    else {
      confirmTicket(navigateToRTI);
      handleClose();
    }
  };

  const handleUpgrade = () => {
    if (journey) {
      const { defaultPremiumProduct, seatProducts } = journey;

      const smartSeatProduct = seatProducts.find(el => el.productCode === 'SMART_STANDARD') as SeatProduct | undefined;

      const updatedFare =
        selectedItem?.ticket?.productId == 'SMART_SAVER'
          ? { productId: smartSeatProduct?.productCode }
          : defaultPremiumProduct;
      selectItem(journey, updatedFare);
    }
    confirmTicket(navigateToRTI);
    handleClose();
  };

  const firstButtonProps =
    (isBFEnabled &&
      (isFareSelected && smartSaverSelected
        ? !isNaN(farePriceDifference) && {
            label: fareCompareItems?.[0]?.upgradeCtaLabel.replace(
              '{price}',
              `${farePriceDifference?.toFixed(2)}` || ''
            ),
            width: 'full' as Width,
            onClick: handleUpgrade,
          }
        : {
            label: submitButtonLabel,
            width: 'full' as Width,
            onClick: handleConfirm,
          })) ||
    undefined;

  const secondButtonProps =
    (isBFEnabled &&
      isFareSelected &&
      smartSaverSelected && {
        label: fareCompareItems?.[0]?.continueCtaLabel,
        width: 'full' as Width,
        onClick: handleConfirm,
      }) ||
    undefined;

  const goBackButton = isBFEnabled && {
    label: '',
    width: 'normal' as Width,
    onClick: handleClose,
    icon: 'arrow-to-from',
  };

  const getFareCompareItemAvailability = (item: any) => {
    const fareSelected =
      item?.fareVariant === 'PREMIUM_STANDARD'
        ? selectedItem?.journey?.defaultPremiumProduct
        : item?.fareVariant === 'SMART_SAVER'
        ? selectedItem?.journey?.defaultSmartSaverProduct
        : selectedItem?.journey?.defaultSmartProduct;

    return {
      isAvailable: !!fareSelected?.productId && !fareSelected?.unavailableMessage,
      message: fareSelected?.unavailableMessage,
    };
  };

  return {
    firstButtonProps,
    secondButtonProps,
    fareCompareItems,
    handleConfirm,
    handleClose,
    isVisible,
    goBackButton,
    handleOpenFareTableDrawer,
    fareTableDrawerVisible,
    handleCloseFareTableDrawer,
    isBFEnabled,
    getFareCompareItemAvailability,
    smartSaverSelected,
    navigateToRTI,
  };
};
