import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { PillButton } from '@atoms/PillButton';
import { Toggle } from '@atoms/Toggle';

import { cn } from 'utils/classNames';

import './TripSelection.scss';
import { TripSelectionProps } from './types';

const bem = cn('booking-search__trip-selection');

export const TripSelection = observer((props: TripSelectionProps) => {
  const { toggleItems, orLabel, buildYourPass } = props;
  const { ticketsFormStore } = useStores();
  const { changeTripValue, trip } = ticketsFormStore;

  return (
    <div className={bem()}>
      <Toggle name="trip-selection" defaultChecked={trip} items={toggleItems} onChange={changeTripValue} />
      {buildYourPass && (
        <>
          <span>{orLabel}</span>
          <PillButton {...buildYourPass} />
        </>
      )}
    </div>
  );
});
