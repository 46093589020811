import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import './AutoRenew.scss';
import { AutoRenewProps } from './types';

const bem = cn('auto-renew');

export const AutoRenew = observer((props: AutoRenewProps) => {
  const { cfStore, accountStore } = useStores();
  const { account } = cfStore;
  const { wallet: walletCF } = account;

  const { wallet } = accountStore;
  const { setPassToRenew } = wallet;
  const { selectedPass, empty } = props;

  const handleIndicatorClick = () => {
    setPassToRenew(selectedPass);
  };

  const autoRenewDescription = useMemo(
    () =>
      selectedPass?.isAutoRenew
        ? `${walletCF.renewsOnLabel} ${moment.utc(selectedPass?.endDate).format('MMM DD, YYYY')}`
        : walletCF.autoRenewDescription,
    [selectedPass, walletCF.autoRenewDescription, walletCF.renewsOnLabel]
  );

  const onOffLabel = useMemo(
    () => (selectedPass?.isAutoRenew ? walletCF.onLabel : walletCF.offLabel),
    [selectedPass, walletCF.offLabel, walletCF.onLabel]
  );

  return (
    <div className={bem({ empty })}>
      <div className={bem('left')}>
        <div className={bem('info')}>
          <Icon name="renewal" className={bem('icon')} />
          <span className={bem('info-label')}>{walletCF.autoRenewLabel}</span>
        </div>
        <span className={bem('info-description')}>{autoRenewDescription}</span>
      </div>
      <div className={bem('right')}>
        <Button
          variant="secondary"
          size="small"
          label={onOffLabel}
          onClick={handleIndicatorClick}
          color={selectedPass?.isAutoRenew ? 'green' : 'charcoal'}
        />
      </div>
    </div>
  );
});
