import DOMPurify from 'dompurify';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Checkbox from '@atoms/Checkbox/Checkbox';

import { cn } from 'utils/classNames';
import { isDelayNotificationsEnabled, isSmsDelayNotificationsEnabled } from 'utils/metas';

const bem = cn('newsletter-confirm');

const ConfirmNewsletter = observer((props: any) => {
  const { confirmNewsletter, setConfirmNewsletter, smsNotifications, setSmsNotifications } = props;
  const { cfStore } = useStores();
  const { passengers } = cfStore.rti;
  const { newsletterDescription, signUpForNewsletterLabel, smsNotificationTextLabel } = passengers;

  const delayNotificationsEnabled = isDelayNotificationsEnabled();
  const smsDelayNotificationsEnabled = isSmsDelayNotificationsEnabled();

  return (
    <div className={bem()}>
      <span>{newsletterDescription}</span>

      {delayNotificationsEnabled && smsDelayNotificationsEnabled && (
        <Checkbox
          name="smsNotification"
          label={smsNotificationTextLabel}
          onChange={setSmsNotifications}
          isChecked={smsNotifications && delayNotificationsEnabled && smsDelayNotificationsEnabled}
        />
      )}
      <Checkbox
        name="wheelchairAssistance"
        label={<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(signUpForNewsletterLabel) }} />}
        onChange={setConfirmNewsletter}
        isChecked={confirmNewsletter}
      />
    </div>
  );
});

export default ConfirmNewsletter;
