import { useEffect, useState, useCallback } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { mediaQueryList } from 'utils/mediaQueries';

export const useZendeskLogic = (props: {
  zendeskDesktopVisibility: string | undefined;
  zendeskMobileVisibility: string | undefined;
}) => {
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);
  const [unreadCount, setUnreadCount] = useState(0);
  const { zendeskDesktopVisibility, zendeskMobileVisibility } = props;
  const isHomePage = window.location.pathname === '/';
  const isBookingPage = window.location.pathname.includes('/booking');

  const showZendeskButton =
    (isMobile ? zendeskMobileVisibility : zendeskDesktopVisibility) === 'showAll' ||
    (isHomePage &&
      (zendeskDesktopVisibility === 'showHomePageOnly' || zendeskMobileVisibility === 'showHomePageOnly')) ||
    ((isMobile ? zendeskMobileVisibility : zendeskDesktopVisibility) === 'showAllExceptBooking' && !isBookingPage);

  const closeWidget = useCallback(() => {
    window.zE?.('messenger', 'close');
    const openButton = document.getElementById('open-zendesk-button');
    const closeButton = document.getElementById('close-zendesk-button');
    if (openButton) openButton.style.display = 'flex';

    if (closeButton) closeButton.style.display = 'none';
  }, []);

  const resetUnreadIndicator = () => {
    const unreadIndicator = document.getElementById('unread-indicator');
    if (unreadIndicator) {
      unreadIndicator.style.display = 'none';
      setUnreadCount(0);
    }
    const openButton = document.getElementById('open-zendesk-button');
    if (openButton) openButton.removeAttribute('class');

    return undefined;
  };

  const openWidget = () => {
    window.zE?.('messenger', 'open');
    const openButton = document.getElementById('open-zendesk-button');
    if (openButton) openButton.style.display = 'none';

    const closeButton = document.getElementById('close-zendesk-button');
    if (closeButton) closeButton.style.display = 'block';

    resetUnreadIndicator();
  };

  const populateUnreadIndicator = useCallback(count => {
    if (!count) return resetUnreadIndicator();
    const unreadIndicator = document.getElementById('unread-indicator');
    const openButton = document.getElementById('open-zendesk-button');

    if (unreadIndicator) {
      unreadIndicator.style.display = 'block';
      unreadIndicator.style.background = '#CC3333';
      setUnreadCount(count);
    }
    if (openButton) openButton.setAttribute('class', 'tilt-animation');

    return undefined;
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      (window as any).zESettings = {
        webWidget: {
          offset: {
            horizontal: '16px',
            vertical: '88px',
          },
        },
      };
      window.zE?.('messenger:on', 'close', () => {
        closeWidget();
      });
    }
  }, [closeWidget]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (typeof window !== 'undefined')
        window.zE?.('messenger:on', 'unreadMessages', count => {
          populateUnreadIndicator(count);
        });
    }, 5000);

    return () => clearTimeout(timeout);
  }, [populateUnreadIndicator]);

  return {
    unreadCount,
    showZendeskButton,
    closeWidget,
    openWidget,
    populateUnreadIndicator,
    resetUnreadIndicator,
  };
};
