import React, { useId } from 'react';

import { cn, cx } from 'utils/classNames';

import './Radio.scss';
import { RadioProps } from './types';

const bem = cn('radio');

const Radio = (props: RadioProps) => {
  const {
    name = 'radio',
    value,
    disabled,
    checked,
    defaultChecked,
    label,
    className = '',
    labelClassName = 'blte-font--variant-body-medium-500',
    errorClassName = 'blte-font--variant-tiny-500',
    isInvalid,
    onChange,
    onBlur,
    errorMessage,
  } = props;
  const generatedId = useId();
  const id = props.id || generatedId;

  return (
    <div
      className={cx(bem({ 'is-disabled': disabled, 'has-error': isInvalid }), {
        [className]: !!className,
      })}
    >
      <input
        id={id}
        type="radio"
        onChange={onChange}
        onBlur={onBlur}
        className={bem('input')}
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
      />
      <label htmlFor={id} className={cx(bem('label'), labelClassName)}>
        {label}
      </label>
      {isInvalid && errorMessage && <span className={cx(bem('error'), errorClassName)}>{errorMessage}</span>}
    </div>
  );
};

export default Radio;
