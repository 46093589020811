import React, { useEffect, useMemo, useRef } from 'react';

import TeaserV2 from '@molecules/TeaserV2/TeaserV2';
import { TeaserV2Props } from '@molecules/TeaserV2/types';

import { cn } from 'utils/classNames';

import { DesktopItemsProps } from '../types';
import useCarousel from './../useCarousel';

const bem = cn('teasers-list');

const DesktopItems = ({ items, rowList = [], itemsPerRow, variant, width }: DesktopItemsProps) => {
  const descriptionRefs = useRef<any>([]);
  const hasDescription = useMemo(() => items?.some(item => item?.descriptionTitle || item?.description), [items]);

  const { getRefFromHandlers, handlers, handleSwipe, activeSlide } = useCarousel(items, variant, itemsPerRow);

  useEffect(() => {
    if (hasDescription) {
      const descriptions = descriptionRefs.current;
      let maxHeight = 0;

      descriptions.forEach(description => {
        if (description) {
          const height = description.getBoundingClientRect().height;
          if (height > maxHeight) maxHeight = height;
        }
      });

      descriptions.forEach(description => {
        if (description) description.style.minHeight = `${maxHeight}px`;
      });
    }
  }, [hasDescription, itemsPerRow]);

  return (
    <>
      {variant !== 'carousel' &&
        rowList.map((row: TeaserV2Props[], index: number) => {
          const key = `row-${index}`;

          return (
            <ul
              key={key}
              className={bem('items', { length: itemsPerRow })}
              {...handlers}
              ref={getRefFromHandlers}
              role="list"
            >
              {row.map((item: TeaserV2Props, index: number) => {
                const key = `row-item-${item.id ? item.id : index}`;

                return (
                  <TeaserV2
                    key={key}
                    {...item}
                    descriptionRef={hasDescription ? el => (descriptionRefs.current[index] = el) : undefined}
                  />
                );
              })}
            </ul>
          );
        })}
      {variant === 'carousel' && (
        <>
          <div>
            <span className={bem('desktop-carousel-button', { prev: true, width })}>
              <button
                className="slick-prev"
                disabled={activeSlide <= (itemsPerRow || 0)}
                onClick={handleSwipe('right')}
              >
                Prev
              </button>
            </span>
            <span className={bem('desktop-carousel-button', { next: true, width })}>
              <button className="slick-next" disabled={activeSlide >= items.length} onClick={handleSwipe('left')}>
                Next
              </button>
            </span>
          </div>
          <ul
            className={bem('items', { length: itemsPerRow, carousel: true })}
            {...handlers}
            ref={getRefFromHandlers}
            role="list"
          >
            {items.map((item: TeaserV2Props, index: number) => {
              const key = `row-item-${item.id ? item.id : index}`;

              return (
                <TeaserV2
                  key={key}
                  {...item}
                  descriptionRef={hasDescription ? el => (descriptionRefs.current[index] = el) : undefined}
                />
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};
export default DesktopItems;
