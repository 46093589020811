import { color_brand_blue, color_white } from 'utils/colors';

export const tooltipColors = {
  content: {
    text: {
      inactive: color_white,
    },
    background: {
      inactive: color_brand_blue,
    },
  },
  info: {},
};
