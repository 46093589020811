import cx from 'classnames';
import React from 'react';

import { cn } from 'utils/classNames';

import { VideoPlayerProps } from './types';

const bem = cn('video-player');

const VideoPlayer = (props: VideoPlayerProps) => {
  const { id, className = '', src, type, width, height } = props;

  if (type !== 'dam')
    return (
      <iframe
        id={id}
        className={cx(bem(), { [className]: !!className })}
        width={width}
        height={height}
        src={src}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    );

  return (
    <video id={id} className={cx(bem(), { [className]: !!className })} width={width} height={height} controls>
      <source src={src} />
    </video>
  );
};

export default VideoPlayer;
