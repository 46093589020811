import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStores } from 'stores';

import { CardStates } from '@booking/routes/RTI/Card/constants';
import { passengerTypes } from '@booking/routes/RTI/PassengersInfo/constants';
import { stepNames } from '@booking/routes/RTI/constants';

import { enrichDatadogSessionUserDataWithBookingNumber } from 'utils/datadog';
import { base64Decode } from 'utils/helpers';
import { isKiosk, scrollToTop } from 'utils/isKiosk';
import {
  clearLoginRedirectURL,
  clearLogoutRedirectURL,
  clearRtiData,
  getBookingCreationTime,
  getRtiData,
  setLoginRedirectURL,
  setLogoutRedirectURL,
  setRtiData,
} from 'utils/localstorage';

import { isContinueAsGuestEnabled, isForcedLoginEnabled } from '../../../../utils/metas';

export const useRTILogic = () => {
  const [onloadCompleted, setOnloadCompleted] = useState(false);
  const { bookingStore, authStore, cfStore, accountStore, promoStore, ticketsFormStore, passesStore } = useStores();
  const { profile, passengersStore } = accountStore;
  const { cancelTrip } = accountStore.tripDetails;
  const { resetPromoData } = promoStore;
  const { userProfile } = profile;
  const { fetchSavedPassengers } = passengersStore;
  const { isAuthenticated } = authStore;
  const { rti } = cfStore;
  const { minutes } = rti.timer;

  const { completedSteps, activeStep, provisionalBooking, getExistingBookingInformation } = bookingStore.rti;
  const { modifyTripType, setProvisionalBooking, setLoading, clearError } = bookingStore.rti;
  const { createApplicablePasses, checkSeatingAvailability, setBookedPassengers } = bookingStore.rti;
  const { setLockedSteps, setCompletedSteps, setActiveStep } = bookingStore.rti;

  const { setFormValues } = ticketsFormStore;

  const trip = provisionalBooking.tripDetails.trip;

  const forcedLoginEnabled = isForcedLoginEnabled();
  const continueAsGuestEnabled = isContinueAsGuestEnabled();

  // Doing 2 attempts, until backend fix on their side
  const handleDiscardBooking = async (redirectToHomepage = true) => {
    let isCancelled: any = false;
    try {
      isCancelled = await attemptCancelTrip();

      if (!isCancelled) isCancelled = await attemptCancelTrip();

      if (isCancelled) {
        const url = new URL(window.location.href);
        clearRtiData();
        clearLoginRedirectURL();
        clearLogoutRedirectURL();
        window.history.replaceState({}, '', url.pathname + url.search);

        if (redirectToHomepage) window.location.href = '/';
      } else {
        window.history.pushState(null, '', window.location.href);

        console.error('Cancel trip failed after two attempts.');
      }
    } catch (error) {
      window.history.pushState(null, '', window.location.href);

      console.error('Unexpected error during cancellation:', error);
    }
  };

  const attemptCancelTrip = async () => {
    try {
      const response = await cancelTrip(
        trip.referenceNumber,
        false,
        'both_legs',
        false,
        provisionalBooking.bookingSession
      );

      return response && response.status < 400;
    } catch (error) {
      console.error('Error cancelling booking:', error);

      return false;
    }
  };

  const { getSeats } = bookingStore.rti.seats;

  const { fetchPaymentMethodsAndSavedCards } = passesStore.checkout;

  const { fetchExtras } = bookingStore.extras;
  const { fetchParking } = bookingStore.parking;
  const { drawerVisible, setDrawerVisibility } = bookingStore.tickets;

  const { adults, kids, infants } = ticketsFormStore.formValues;

  const passengerSeatingDetails = provisionalBooking?.tripDetails?.passengerSeatingDetails;

  const [searchParams] = useSearchParams();

  const [showModifyTripAlert, setShowModifyTripAlert] = useState(false);
  const [timerInitiated, setTimerInitiated] = useState(false);
  const [minutesRemaining, setMinutesRemaining] = useState(minutes);
  const [secondsRemaining, setSecondsRemaining] = useState(0);

  useEffect(() => {
    const bookingCreationTime = getBookingCreationTime();

    if (timerInitiated) return;

    try {
      let bookingCreatedAt = Date.now();
      if (
        bookingCreationTime &&
        bookingCreationTime.createdAt &&
        bookingCreationTime.bookingNumber !== provisionalBooking?.tripDetails?.trip?.referenceNumber
      )
        bookingCreatedAt = bookingCreationTime.createdAt;

      const timerTime = bookingCreatedAt + minutes * 60000;

      setMinutesRemaining(Math.floor((timerTime - Date.now()) / 60000));
      setSecondsRemaining(Math.floor(((timerTime - Date.now()) % 60000) / 1000));
    } catch (e) {
      console.error(e);
    }

    setTimerInitiated(true);
  }, [minutes, provisionalBooking?.tripDetails?.trip?.referenceNumber, timerInitiated]);

  useEffect(() => {
    if (!timerInitiated) return undefined;

    const countdown = setInterval(() => {
      if (secondsRemaining > 0) setSecondsRemaining(secondsRemaining - 1);
      else if (secondsRemaining === 0 && minutesRemaining > 0) {
        setMinutesRemaining(minutesRemaining - 1);
        setSecondsRemaining(59);
      } else clearInterval(countdown);
    }, 1000);

    return () => clearInterval(countdown);
  }, [minutesRemaining, secondsRemaining, timerInitiated]);

  const formattedTime = useMemo(
    () => `${minutesRemaining.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`,
    [minutesRemaining, secondsRemaining]
  );

  const passengersNumber = useMemo(() => {
    if (passengerSeatingDetails && modifyTripType)
      return {
        adults:
          passengerSeatingDetails?.filter(seatingDetails => seatingDetails?.passenger?.type === passengerTypes.adult)
            .length || 0,
        kids:
          passengerSeatingDetails?.filter(seatingDetails => seatingDetails?.passenger?.type === passengerTypes.child)
            .length || 0,
        infants:
          passengerSeatingDetails?.filter(seatingDetails => seatingDetails?.passenger?.type === passengerTypes.infant)
            .length || 0,
      };

    return {
      adults: adults,
      kids: kids,
      infants: infants,
    };
  }, [passengerSeatingDetails, adults, kids, infants, modifyTripType]);

  const modifyTripHeaderRTIProps = {
    modifyTripType,
    showModifyTripAlert,
    setShowModifyTripAlert,
  };

  const handleToggleDrawerVisible = () => setDrawerVisibility(!drawerVisible);

  const getCardMode = useCallback(
    stepName => {
      if (modifyTripType === 'extras')
        return [stepNames.extras, stepNames.paymentInfo].includes(stepName) ? CardStates.active : CardStates.passive;

      if (activeStep === 'seatAssignment' || activeStep === stepName || activeStep === 'extras')
        return CardStates.active;
      else if (completedSteps[stepName]) return CardStates.finished;

      return CardStates.passive;
    },
    [modifyTripType, activeStep, completedSteps]
  );

  const costSummaryItemsLength = useMemo(() => {
    let itemsLength = 0;

    provisionalBooking?.tripDetails?.costSummary?.sections.forEach((section: any) => {
      itemsLength += section.items?.length || 0;
    });

    return itemsLength;
  }, [provisionalBooking]);

  useEffect(() => {
    if (isKiosk() || !!modifyTripType || isEmpty(provisionalBooking.tripDetails)) return;

    setRtiData(provisionalBooking);
    setLoginRedirectURL(window.location.href);
    if (!forcedLoginEnabled || continueAsGuestEnabled) setLogoutRedirectURL(window.location.href);
    else setLogoutRedirectURL('/');
  }, [continueAsGuestEnabled, forcedLoginEnabled, modifyTripType, provisionalBooking]);

  useEffect(() => {
    if (provisionalBooking?.tripDetails?.trip) return;

    const data = searchParams.get('data');
    const restoreBookingData = getRtiData();

    if (modifyTripType && modifyTripType === 'trip') setShowModifyTripAlert(true);

    if (data) {
      const parsedData = JSON.parse(base64Decode(searchParams.get('data')));

      const { rn, fn, ln, type } = parsedData;

      if (rn && fn && ln && type) getExistingBookingInformation(rn, fn, ln, type);
    } else if (!provisionalBooking?.tripDetails?.trip && restoreBookingData) {
      setLoading(true);
      clearError();

      createApplicablePasses(restoreBookingData);
      setBookedPassengers(
        restoreBookingData?.tripDetails?.passengerSeatingDetails?.map(passenger => ({
          passenger: passenger.passenger,
        }))
      );
      setFormValues({
        adults:
          restoreBookingData?.tripDetails?.passengerSeatingDetails?.filter(
            seatingDetails => seatingDetails?.passenger?.type === passengerTypes.adult
          ).length || 0,
        kids:
          restoreBookingData?.tripDetails?.passengerSeatingDetails?.filter(
            seatingDetails => seatingDetails?.passenger?.type === passengerTypes.child
          ).length || 0,
        infants:
          restoreBookingData?.tripDetails?.passengerSeatingDetails?.filter(
            seatingDetails => seatingDetails?.passenger?.type === passengerTypes.infant
          ).length || 0,
      });
      setLockedSteps({
        passengerInfo: false,
        seatAssignment: false,
        extras: false,
        paymentInfo: false,
      });
      setActiveStep(stepNames.passengerInfo);
      setCompletedSteps([]);
      checkSeatingAvailability(restoreBookingData);
      setProvisionalBooking(restoreBookingData);

      setLoading(false);
    }
  }, [
    getExistingBookingInformation,
    searchParams,
    modifyTripType,
    setProvisionalBooking,
    provisionalBooking,
    setLoading,
    clearError,
    createApplicablePasses,
    checkSeatingAvailability,
    setBookedPassengers,
    setFormValues,
    setLockedSteps,
    setActiveStep,
    setCompletedSteps,
  ]);

  useEffect(() => {
    if (onloadCompleted) return;

    const { tripDetails, bookingSession } = provisionalBooking;

    const data = searchParams.get('data');
    const restoreBookingData = getRtiData();

    //Throw exception to avoid blank screen
    if (!data && !restoreBookingData && (!tripDetails || !bookingSession)) throw 'Error on RTI';

    if (!tripDetails?.trip?.referenceNumber) return;

    //Pre-fetch seating to enable/disable them in seats card
    if (!tripDetails?.trip?.outboundRoute?.isCancelled && tripDetails?.trip?.outboundRouteStatus !== 'past')
      getSeats('outbound', true);

    if (tripDetails?.trip?.inboundRoute) getSeats('inbound', true);

    if (data || modifyTripType === 'trip') {
      if (
        tripDetails?.trip?.outboundRoute?.origin?.id !== 'MCO' &&
        tripDetails?.trip?.outboundRoute?.destination?.id !== 'MCO'
      )
        fetchParking(tripDetails?.trip?.referenceNumber, bookingSession);
      fetchExtras(tripDetails?.trip?.referenceNumber, bookingSession);
    }

    fetchPaymentMethodsAndSavedCards();

    setOnloadCompleted(true);
  }, [
    provisionalBooking,
    searchParams,
    fetchParking,
    fetchExtras,
    fetchPaymentMethodsAndSavedCards,
    getSeats,
    onloadCompleted,
    modifyTripType,
  ]);

  useEffect(() => {
    resetPromoData();
  }, [resetPromoData]);

  useEffect(() => {
    if (userProfile) fetchSavedPassengers();
  }, [userProfile, fetchSavedPassengers]);

  useEffect(() => {
    scrollToTop();
  }, []);

  // DATADOG user session enrichment with current booking number
  useEffect(() => {
    if (!isAuthenticated || !provisionalBooking?.tripDetails?.trip?.referenceNumber) return;

    enrichDatadogSessionUserDataWithBookingNumber(provisionalBooking.tripDetails.trip.referenceNumber);
  }, [isAuthenticated, provisionalBooking?.tripDetails?.trip?.referenceNumber]);

  return {
    modifyTripHeaderRTIProps,
    getCardMode,
    passengersNumber,
    handleToggleDrawerVisible,
    drawerVisible,
    costSummaryItemsLength,
    minutesRemaining,
    secondsRemaining,
    formattedTime,
    handleDiscardBooking,
  };
};
