import React from 'react';

import { cn } from 'utils/classNames';

import { CircularProgressBarPlaceholder } from '../CircularProgressBar/CircularProgressBarPlaceholder';

const bem = cn('rewards-tracker');

export const RewardsTrackerPlaceholder = () => (
  <div className={bem({ placeholder: true })}>
    <div className={bem('progress-bar')}>
      <CircularProgressBarPlaceholder />
      <div className={bem('points-container')}>
        <div className={bem('progress-bar-rows')}>
          <div className={bem('progress-bar-row')} />
          <div className={bem('progress-bar-row')} />
        </div>
        <div className={bem('progress-bar-rows')}>
          <div className={bem('progress-bar-row')} />
          <div className={bem('progress-bar-row')} />
        </div>
      </div>
    </div>
    <div className={bem('bottom-right-rows')}>
      <div className={bem('bottom-right-row')} />
      <div className={bem('bottom-right-row')} />
    </div>
  </div>
);
