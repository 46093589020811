import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { DateInputs } from '../DateInputs';
import { DesktopDateRangePickerProps } from '../types';
import { DesktopCalendar } from './DesktopCalendar';
import { DEFAULT_I18N } from './constants';

export const DesktopDateRangePicker = observer((props: DesktopDateRangePickerProps) => {
  const { toggleItems, i18n = DEFAULT_I18N } = props;
  const { isOpen, focusedInput, handleCloseButton } = props;
  const { handleFocusChange, handleDatesChange, isOutsideRange } = props;
  const { handleClearDates, isSameDay, datesRestrictions } = props;
  const { ticketsFormStore } = useStores();

  const { trip } = ticketsFormStore;

  const { closeButtonLabel, clearButtonLabel } = i18n;

  return (
    <>
      {isOpen && (
        <DesktopCalendar
          datesRestrictions={datesRestrictions}
          closeButtonLabel={closeButtonLabel}
          clearButtonLabel={clearButtonLabel}
          onFocusChange={handleFocusChange}
          focusedInput={focusedInput}
          handleCloseButton={handleCloseButton}
          onDatesChange={handleDatesChange}
          isOutsideRange={isOutsideRange}
          handleClearDates={handleClearDates}
          toggleItems={toggleItems}
          isSameDay={isSameDay}
          isOpen={isOpen}
        />
      )}
      <DateInputs {...props} isOneWay={trip === 0} />
    </>
  );
});
