import { observer } from 'mobx-react-lite';
import React from 'react';

import Button from '@atoms/Button/Button';
import { Input } from '@atoms/Input';
import LinkButton from '@atoms/LinkButton/LinkButton';

import { cn } from 'utils/classNames';

import './PromoCode.scss';
import { PromoCodeV2Props } from './types';
import { usePromoCodeV2 } from './usePromoCodeV2';

const bem = cn('promo-code');

export const PromoCodeV2 = observer((props: PromoCodeV2Props) => {
  const { promoButton, applyButton, endpoint, requestOptions, errorLabel, onSuccess, isMobileRti, underline } = props;
  const { floatingLabel, required } = props;

  const {
    serverStatus,
    handleAddCodeClick,
    showInput,
    handleInputChange,
    handleApplyCode,
    inputValue,
    appliedPromoCode,
    resetPromoData,
  } = usePromoCodeV2(endpoint, requestOptions, onSuccess);

  return (
    <div className={bem({ input: showInput, status: serverStatus })}>
      {showInput ? (
        <>
          <Input
            className={bem('input')}
            type="text"
            name="promo-code"
            label={floatingLabel}
            errorVariant="inline"
            error={serverStatus === 'error'}
            errorMessage={serverStatus === 'error' ? errorLabel : ''}
            trailingIcon="close2"
            displayTrailingIcon={!!inputValue}
            onChange={handleInputChange}
            onClickTrailingIcon={resetPromoData}
            value={inputValue}
            required={required}
            helperText={
              serverStatus === 'success' ? `Brightline credit '${appliedPromoCode}' has been applied` : undefined
            }
            afterErrorFocus
          />
          <Button
            className={bem('apply')}
            {...applyButton}
            onClick={handleApplyCode}
            disabled={!inputValue.length}
            loading={serverStatus === 'loading'}
          />
        </>
      ) : (
        <>
          {promoButton && (
            <span className={bem('add-code')}>
              <LinkButton
                {...promoButton}
                onClick={handleAddCodeClick}
                isMobileRti={isMobileRti}
                underline={underline}
                hideAriaLabel
              />
            </span>
          )}
        </>
      )}
    </div>
  );
});
