import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { CardPlaceholder } from '@account/components/Card/components';
import { CarouselDots, PassesCarousel } from '@account/components/PassesCarousel';
import { useCarousel } from '@account/components/PassesCarousel/useCarousel';
import { createEmptySlides } from '@account/components/PassesCarousel/utils';
import { EmptyPassProps } from '@account/routes/Wallet/components/PassCards/components/EmptyPass/types';

import { cn } from 'utils/classNames';

import './DashboardWalletMobile.scss';

const bem = cn('dashboard-wallet-mobile');
export const DashboardWalletMobile = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { wallet } = accountStore;
  const { passes, isLoading } = wallet;
  const { account } = cfStore;
  const { wallet: i18n } = account;

  const hasPasses = useMemo(() => !!passes.length, [passes]);

  const emptySlides: EmptyPassProps[] = useMemo(() => createEmptySlides(i18n), [i18n]);

  const { getRefFromHandlers, handlers, activeSlide } = useCarousel(hasPasses ? passes : emptySlides, hasPasses);

  if (isLoading) return <CardPlaceholder variant="large" />;

  return (
    <div className={bem()}>
      <PassesCarousel
        items={passes}
        emptySlides={emptySlides}
        handlers={handlers}
        getRefFromHandlers={getRefFromHandlers}
        activeSlide={activeSlide}
      />
      <div className={bem('dots')}>
        <CarouselDots items={hasPasses ? passes : emptySlides} activeSlide={activeSlide} hasPasses={hasPasses} />
      </div>
    </div>
  );
});
