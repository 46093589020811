import React from 'react';
import { useMediaPredicate } from 'react-media-hook';

import DesktopSelect from '@atoms/Select/Desktop/DesktopSelect';
import SelectHeader from '@atoms/Select/SelectHeader';

import { mediaQueryList } from 'utils/mediaQueries';

import { MobileSelect } from './Mobile/MobileSelect';
import './Select.scss';
import { SelectProps } from './types';
import { useSelectHandlers } from './useSelectHandlers';

export const Select = (props: SelectProps) => {
  const { useDesktopVariant } = props;
  const hookProps = useSelectHandlers(props);
  const isMobileDevice = useMediaPredicate(mediaQueryList.maxMobile);

  if (isMobileDevice && !useDesktopVariant)
    return (
      <SelectHeader {...props} {...hookProps}>
        <MobileSelect {...props} {...hookProps} />
      </SelectHeader>
    );

  return <DesktopSelect {...props} {...hookProps} />;
};
