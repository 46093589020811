import { observer } from 'mobx-react-lite';
import React from 'react';
import { rootStore, useStores } from 'stores';

import Button from '@atoms/Button/Button';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';

import './ErrorView.scss';

const bem = cn('trip-details-error-view');

// TODO replace hardcoded labels with CF data
// TODO fix "find an existing trip" link
export const ErrorView = observer(() => {
  const { kioskStore, cfStore } = useStores();
  const { confirmSessionClearance } = kioskStore;
  const { account } = cfStore;
  const { tripDetails } = account;

  const handleBookTrip = () => {
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: 'Book a trip',
        customLink: '/',
        externalLink: '',
        linkType: 'linkClick',
      },
    });
    if (isKiosk()) confirmSessionClearance();
    else window.location.href = '/';
  };

  return (
    <div className={bem()}>
      <div className={bem('exclamation')}>!</div>
      <div className={bem('title')}>{tripDetails.errorTitle}</div>
      <div className={bem('description')}>{tripDetails.errorDescription}</div>
      <Button className={bem('button')} label="Book a trip" onClick={handleBookTrip} />
      {!isKiosk() && (
        <a className={bem('link')} href="/">
          {tripDetails.errorCtaLabel}
        </a>
      )}
    </div>
  );
});
