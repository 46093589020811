import React from 'react';

import { Icon } from '@atoms/Icon';
import Title from '@atoms/Title/Title';

import { cn } from 'utils/classNames';

import './ErrorContent.scss';
import { ErrorContentProps } from './types';

const bem = cn('passes-error');
const ErrorContent = (props: ErrorContentProps) => {
  const { error, icon = 'warning', color, title } = props;

  return (
    <div className={bem()}>
      <div className={bem('title')}>
        <span className={bem('icon')}>
          <Icon name={icon} />
        </span>
        <Title size="h3" color={color} text={title} />
      </div>
      {error && <span>Something went wrong!</span>}
    </div>
  );
};

export default ErrorContent;
