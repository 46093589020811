import { useQueries } from 'hooks/useQueries';
import { rootStore, useStores } from 'stores';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { setFromHomePageFlag } from 'utils/storage';

export const useModalLogic = props => {
  const { deprecatedManageTripsPage } = props;
  const { manageTripsFormStore } = useStores();
  const { hidePanel } = manageTripsFormStore;
  const { appendQueries } = useQueries();

  const handleSubmit = async data => {
    setFromHomePageFlag();
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: 'Modal Submit - Navigate to Trip Details Page',
        customLink: '/account/trip-details',
        externalLink: '',
        linkType: 'linkClick',
      },
    });
    const formattedValues = {
      reference_number: data.referenceNumber,
      first_name: data.firstName,
      last_name: data.lastName,
    };
    await appendQueries('/account/trip-details', deprecatedManageTripsPage, formattedValues, '', true);
  };

  const doHandleModalClose = (setValues, setTouched, initialFormValues) => () => {
    setValues(initialFormValues);
    setTouched({ referenceNumber: false, lastName: false });
    hidePanel();
  };

  return {
    handleSubmit,
    doHandleModalClose,
  };
};
