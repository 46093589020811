export interface PhoneNumberProps {
  countryCode?: string;
  number?: string;
}

export interface PassengerProps {
  id?: string;
  firstName?: string;
  requiredFieldLabel?: string;
  lastName?: string;
  type?: string;
  email?: string;
  phoneNumber?: PhoneNumberProps;
  dateOfBirth?: string; //'2023-03-17T12:11:02.395Z';
  wheelchairAccommodation?: 'accessible_space' | 'standard_seat';
  picture?: string;
  isPrimaryPassenger?: boolean;
}

export interface BookedPassengerProps {
  passenger: PassengerProps;
  editMode?: boolean;
  id?: string;
}

export interface SavedPassengerAccountProps extends BookedPassengerProps {
  isPrimaryPassenger?: boolean;
}

export interface PassengersInfoDataProps {
  bookedPassengers?: BookedPassengerProps[];
}

export const defaultPassengersInfoData: PassengersInfoDataProps = {
  bookedPassengers: [],
};
