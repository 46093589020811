import { color_base_black, color_brand_blue, color_dark_blue, color_white } from 'utils/colors';

import { defaultArgs as pillArgs } from '../PillButton/constants.storybook';

export const tabColors = {
  background: {
    inactive: color_brand_blue,
    hover: color_dark_blue,
    active: color_white,
  },
  text: {
    inactive: color_white,
    hover: color_white,
    active: color_brand_blue,
  },
  border: {
    focus: color_base_black,
  },
};

export const defaultArgs = {
  labels: ['Train Tickets', 'Manage Trips', 'Train Status'],
  defaultActiveTabIndex: 0,
  pillButtons: [
    {
      label: 'Parking Passes',
      link: {
        url: 'http://google.com',
        target: '_blank',
      },
      colors: {
        background: {
          inactive: 'blue',
        },
        text: {
          inactive: 'white',
        },
      },
    },
    {
      label: 'Corporate Travel',
      link: {
        url: 'http://google.ro',
        target: '_blank',
      },
    },
    {
      label: 'Group Travel',
      link: {
        url: 'http://google.de',
        target: '_blank',
      },
    },
  ],
  extraPillButton: {
    label: 'Extra Pill Button',
    link: {
      url: 'http://google.com',
      target: '_new',
    },
    colors: {
      background: {
        inactive: 'green',
      },
      text: {
        inactive: 'white',
      },
    },
  },
  pillButtonsPosition: 'top',
  design: {
    pillButtons: {
      colors: pillArgs.colors,
      hasIcon: true,
      hasShadow: true,
    },
    tabs: {
      colors: tabColors,
    },
  },
};
