import { useState } from 'react';

export const useSimpleSelectHandlers = ({ toggleMenu, nrItems }) => {
  const [focus, setFocus] = useState({
    index: 0,
  });

  const handleKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown':
        if (focus.index < nrItems - 1) setFocus({ index: focus.index + 1 });
        e.stopPropagation();
        e.preventDefault();
        break;
      case 'ArrowUp':
        if (focus.index > 0) setFocus({ index: focus.index - 1 });

        e.stopPropagation();
        e.preventDefault();
        break;
      case 'Escape':
      case 'Tab':
        toggleMenu();
        break;
    }
  };

  return {
    focus,
    setFocus,
    handleKeyDown,
  };
};
