import moment from 'moment';

const useDelaysExperienceLoagic = () => {
  const getStatusLabel = (delaysExperienceMessages, statusVariant) => {
    if (!delaysExperienceMessages || !statusVariant) return undefined;

    return delaysExperienceMessages[`${statusVariant}StatusLabel`];
  };

  const getStatusMessage = (delaysExperienceMessages, statusVariant, time) => {
    if (!delaysExperienceMessages || !statusVariant) return {};

    return {
      title: delaysExperienceMessages[`${statusVariant}MessageTitle`],
      description: delaysExperienceMessages[`${statusVariant}MessageText`]
        ?.replace('$<hours>', Math.abs(time?.hours))
        .replace('$<minutes>', Math.abs(time?.minutes)),
    };
  };

  const timeDifference = (previousTime, departureDateTime, tz) => {
    if (!previousTime) return {};

    const diff = moment.duration(moment(previousTime).tz(tz).diff(moment(departureDateTime).tz(tz)));
    const hours = diff.hours();
    const minutes = diff.minutes();

    return {
      hours,
      minutes,
    };
  };

  return {
    getStatusLabel,
    getStatusMessage,
    timeDifference,
  };
};

export default useDelaysExperienceLoagic;
