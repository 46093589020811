import cx from 'classnames';
import React from 'react';

import { cn } from 'utils/classNames';

import { IconProps } from './types';

const bem = cn('icon');

export const Icon = ({
  name,
  size,
  theme,
  externalIcon,
  externalIconAlt,
  className,
  ariaHidden,
  ariaLabel,
}: IconProps) => {
  if (name && !externalIcon)
    return (
      <i className={cx(bem({ size, theme }), bem(name), className)} aria-hidden={ariaHidden} aria-label={ariaLabel} />
    );

  if (externalIcon)
    return (
      <img
        src={externalIcon}
        alt={externalIconAlt}
        className={cx(bem({ size, theme }), name && bem(name), className)}
      />
    );

  return null;
};
