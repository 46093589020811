import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './SweepstakesForm.scss';
import { SweepstakesFormContent } from './SweepstakesFormContent/SweepstakesFormContent';
import { SweepstakesFormSubmissionMessage } from './SweepstakesFormSubmissionMessage/SweepstakesFormSubmissionMessage';
import { SweepstakesFormProps } from './types';
import { useServerLogic } from './useServerLogic';
import { useSweepstakesFormLogic } from './useSweepstakesFormLogic';
import { useValidationSchema } from './useValidationSchema';

const bem = cn('sweepstakes-form');

export const SweepstakesForm = (props: SweepstakesFormProps) => {
  const { image, title, subtitle } = props;
  const { onSubmit, serverStatus } = useServerLogic();
  const { initialFormValues } = useSweepstakesFormLogic();
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const validationSchema = useValidationSchema(props);

  useEffect(() => {
    if (serverStatus === 'success') setIsSubmittedSuccessfully(true);
  }, [serverStatus]);

  const handleSubmit = async (values, { setSubmitting }) => {
    await onSubmit(values);
    setSubmitting(false);
    if (serverStatus === 'success') setIsSubmittedSuccessfully(true);
  };

  const backgroundImageStyle = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const resetFormAndSubmitAnother = () => {
    setIsSubmittedSuccessfully(false);
  };

  return (
    <div className={bem()}>
      {image && (
        <div className={bem('image')} style={{ backgroundImage: `url(${image?.src})`, ...backgroundImageStyle }} />
      )}
      {isSubmittedSuccessfully ? (
        <SweepstakesFormSubmissionMessage {...props} onResetForm={resetFormAndSubmitAnother} />
      ) : (
        <>
          <div className={bem('body')}>
            <div className={bem('form-titles')}>
              <Text as="h1" text={title || ''} className={bem('title')} />
              <Text as="h3" text={subtitle} className={bem('subtitle')} />
            </div>
            <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              <SweepstakesFormContent {...props} />
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};
