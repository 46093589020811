import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStores } from 'stores';

import { AppBanner } from '@molecules/AppBanner';
import { CelebrationBanner } from '@molecules/CelebrationBanner/CelebrationBanner';

import { cn } from 'utils/classNames';
import { scrollToTop } from 'utils/isKiosk';

import './CelebrationPage.scss';

const bem = cn('kiosk-celebration-page');
export const CelebrationPage = observer(() => {
  const { kioskStore } = useStores();

  const { celebration, banner } = kioskStore;

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className={bem()}>
      <div className={bem('content')}>
        <div className={bem('celebration-banner')}>
          <CelebrationBanner {...celebration} isKiosk />
        </div>
        <div className={bem('banner')}>
          <AppBanner {...banner} />
        </div>
      </div>
    </div>
  );
});
