import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { Button } from '@atoms/Button';
import { InputField } from '@atoms/Input';

import { cn } from 'utils/classNames';

import { Error } from './Error';
import { useGiftCardLogic } from './useGiftCardLogic';
import { useValidationSchema } from './validator';

const bem = cn('gift-card');

export const GiftCardForm = observer((setIsChecked: any) => {
  const { reviewAndPay, handleApply, loading } = useGiftCardLogic(setIsChecked);

  const validationSchema = useValidationSchema();

  const [inputMode, setInputMode] = useState<any>('text');

  useEffect(() => {
    const isMobile = window.innerWidth <= 768 || /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    setInputMode(isMobile ? 'numeric' : 'text');
  }, []);

  return (
    <Formik
      initialValues={{ accountNumber: '', pin: '' }}
      onSubmit={handleApply}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, values, setFieldValue }) => (
        <Form className={bem('form')}>
          <div className={bem('inputs')}>
            <InputField
              placeholder={reviewAndPay.giftCardAccountNrText}
              maxCharacters={16}
              value={values?.accountNumber?.toUpperCase()}
              showMaxCharacters={false}
              name="accountNumber"
              type="text"
            />
            <InputField
              placeholder={reviewAndPay.giftCardPinText}
              maxCharacters={4}
              showMaxCharacters={false}
              onChange={e => setFieldValue('pin', e.target.value.replace(/[^0-9]/g, ''))}
              type="text"
              name="pin"
              mode={inputMode}
            />
            <Button
              color="charcoal"
              variant="primary"
              loading={loading}
              label={reviewAndPay.giftCardButtonText}
              type="submit"
            />
          </div>
          <Error errors={errors} />
        </Form>
      )}
    </Formik>
  );
});
