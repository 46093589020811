import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { CreditCardItem } from '../components/CreditCardItem';
import './CreditCardItems.scss';
import { SaveCardProps } from './types';

const bem = cn('saved-cards');
const bemPaymentEntry = cn('payment-info-entry');

export const CreditCardItems = observer((props: any) => {
  const { setCardInstance, setCardData, selectedPaymentMethod, setSelectedPaymentMethod, title } = props;
  const [savedCardsItems, setSavedCardsItems] = useState<any>([]);
  const { cfStore, accountStore, bookingStore } = useStores();
  const { applicablePasses } = bookingStore.rti;
  const { paymentTokensStore } = accountStore;
  const { paymentTokens } = paymentTokensStore;
  const { cardImages } = cfStore.cardInformation;

  useEffect(() => {
    const cardsItems: SaveCardProps[] = paymentTokens?.map(card => {
      const { name, brand } = card;
      const cardImage = cardImages.find(
        image =>
          image?.title?.toLowerCase() === brand?.toLowerCase() || image?.title?.toLowerCase() === name?.toLowerCase()
      );
      const title = cardImage?.title || '';

      return { ...card, cardImage, title };
    });

    cardsItems.push({
      brand: 'newcard',
      name: 'newcard',
      isPreferred: true,
      lastFour: '',
      tokenId: '',
      isNewCard: true,
      cardType: 'newcard',
      cardImage: cardImages.find(image => image?.title?.toLowerCase() === 'newcard'),
    });

    setSavedCardsItems(cardsItems);

    const selectedCard = cardsItems.find(el => el.isPreferred) || cardsItems[cardsItems.length - 1];

    if (paymentTokens.length === 0) setSelectedPaymentMethod({ ...selectedCard });
  }, [cardImages, paymentTokens, setSelectedPaymentMethod, applicablePasses]);

  return (
    <div className={bem()}>
      {title && (
        <div className={bemPaymentEntry('section-header')}>
          <span className={bemPaymentEntry('section-header-title')}>{title}</span>
        </div>
      )}
      {savedCardsItems.map(el => (
        <CreditCardItem
          key={`${el.name}-${el.lastFour}`}
          card={el}
          setCardInstance={setCardInstance}
          setCardData={setCardData}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
        />
      ))}
    </div>
  );
});
