import React from 'react';
import { RectShape } from 'react-placeholder/lib/placeholders';

import { cn } from 'utils/classNames';

import './TransactionsTable.scss';

const bem = cn('account-transactions-table');

const TransactionsTablePlaceholders = ({ itemsPerPage, addBottomButton = false }) => (
  <div className={`${bem('loading')} show-loading-animation`}>
    <RectShape color="#f2f2f4" style={{ display: 'block', width: '100%', height: '24px', marginBottom: '24px' }} />
    {Array(Number(itemsPerPage || '4'))
      .fill(0)
      .map((_, idx) => (
        <RectShape
          key={idx}
          color="#f2f2f4"
          style={{ display: 'block', width: '100%', height: '42px', marginBottom: '22px' }}
        />
      ))}
    {addBottomButton && (
      <RectShape color="#f2f2f4" style={{ display: 'block', width: '150px', height: '40px', marginBottom: '-12px' }} />
    )}
  </div>
);
export default TransactionsTablePlaceholders;
