import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Text } from '@atoms/Text';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './ExpiredSessionModal.scss';
import { ExpiredSessionModalProps } from './types';

const bem = cn('expired-session-modal');

const ExpiredSessionModal = ({ buttonAction }: ExpiredSessionModalProps) => {
  const { cfStore } = useStores();
  const { timer } = cfStore.rti;
  const { dialogueTitleLabel, okayButtonLabel } = timer;

  return (
    <Modal variant="mini" top={-35}>
      <Text className={bem('title')} text={dialogueTitleLabel} />
      <div className={bem('button-wrapper')}>
        <Button color="black" label={okayButtonLabel} onClick={buttonAction} />
      </div>
    </Modal>
  );
};

export default ExpiredSessionModal;
