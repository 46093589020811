import { WifiSubmitDataConfig } from '@molecules/WifiSplashForm/types';

import { storageKeys } from './constants';
import { DatadogUserDataConfig } from './types';

export const getLogoutRedirectURL = () => window.localStorage.getItem(storageKeys.logoutUrl);
export const setLogoutRedirectURL = val => window.localStorage.setItem(storageKeys.logoutUrl, val);
export const clearLogoutRedirectURL = () => window.localStorage.removeItem(storageKeys.logoutUrl);

export const getLoginRedirectURL = () => window.localStorage.getItem(storageKeys.loginUrl);
export const setLoginRedirectURL = val => window.localStorage.setItem(storageKeys.loginUrl, val);
export const clearLoginRedirectURL = () => window.localStorage.removeItem(storageKeys.loginUrl);

export const getLoggedOutFlag = () => window.localStorage.getItem(storageKeys.loggedOutFlag);
export const setLoggedOutFlag = () => window.localStorage.setItem(storageKeys.loggedOutFlag, 'true');
export const clearLoggedOutFlag = () => window.localStorage.removeItem(storageKeys.loggedOutFlag);

export const getShowLoyaltyOptInModalFlag = () => window.localStorage.getItem(storageKeys.showLoyaltyOptInModalFlag);
export const setShowLoyaltyOptInModalFlag = val =>
  window.localStorage.setItem(storageKeys.showLoyaltyOptInModalFlag, val);
export const clearShowLoyaltyOptInModalFlag = () =>
  window.localStorage.removeItem(storageKeys.showLoyaltyOptInModalFlag);

export const getWifiData = (): WifiSubmitDataConfig | undefined => {
  try {
    const wifiData = window.localStorage.getItem(storageKeys.wifiData);

    if (!wifiData) return undefined;

    return JSON.parse(wifiData) as WifiSubmitDataConfig;
  } catch (e) {
    console.error(e);

    return undefined;
  }
};
export const setWifiData = (wifiData: WifiSubmitDataConfig) => {
  try {
    const wifiDataJSON = JSON.stringify(wifiData);

    window.localStorage.setItem(storageKeys.wifiData, wifiDataJSON);
  } catch (e) {
    clearWifiData();
  }
};
export const clearWifiData = () => window.localStorage.removeItem(storageKeys.wifiData);

export const getAlertBanners = () => window.localStorage.getItem(storageKeys.alertBanners);
export const setAlertBanners = values => window.localStorage.setItem(storageKeys.alertBanners, values.toString());

export const getHideModalComponent = () => window.localStorage.getItem(storageKeys.hideModalComponent);
export const setHideModalComponent = values =>
  window.localStorage.setItem(storageKeys.hideModalComponent, values.toString());

export const getHafasUserId = () => window.localStorage.getItem(storageKeys.hafasUserId);
export const setHafasUserId = values => window.localStorage.setItem(storageKeys.hafasUserId, values);
export const clearHafasUserId = () => window.localStorage.removeItem(storageKeys.hafasUserId);

export const setPublicBookingToken = tokenData => {
  try {
    window.localStorage.setItem('publicBookingToken', JSON.stringify(tokenData));
  } catch (e) {
    console.error(e);
    clearPublicBookingToken();
  }
};
export const getPublicBookingToken = () => {
  try {
    return JSON.parse(window.localStorage.getItem('publicBookingToken') || 'null');
  } catch (e) {
    return null;
  }
};
export const clearPublicBookingToken = () => window.localStorage.removeItem('publicBookingToken');

export const getDatadogUserData = () => JSON.parse(window.localStorage.getItem(storageKeys.datadogUserData) || 'null');
export const setDatadogUserData = (values: DatadogUserDataConfig) =>
  window.localStorage.setItem(storageKeys.datadogUserData, JSON.stringify(values || {}));
export const clearDatadogUserData = () => window.localStorage.removeItem(storageKeys.datadogUserData);

export const setForcedLoginAnalytics = eventObj =>
  window.localStorage.setItem('forcedLoginAnalytics', JSON.stringify(eventObj));
export const getForcedLoginAnalytics = () => {
  try {
    return JSON.parse(window.localStorage.getItem('forcedLoginAnalytics') || 'null');
  } catch (e) {
    return null;
  }
};
export const clearForcedLoginAnalytics = () => window.localStorage.removeItem('forcedLoginAnalytics');

export const getRtiData = () => {
  try {
    return JSON.parse(window.localStorage.getItem('rtiData') || 'null');
  } catch (e) {
    console.error(e);
    clearRtiData();

    return null;
  }
};
export const setRtiData = provisionalBooking => {
  if (!provisionalBooking) return;

  try {
    window.localStorage.setItem('rtiData', JSON.stringify(provisionalBooking));
  } catch (e) {
    console.error(e);
  }
};
export const clearRtiData = () => {
  if (getLogoutRedirectURL()?.includes('/rti')) clearLogoutRedirectURL();
  if (getLoginRedirectURL()?.includes('/rti')) clearLoginRedirectURL();
  window.localStorage.removeItem('rtiData');
  clearBookingCreationTime();
};

export const getBookingCreationTime = () => {
  try {
    return JSON.parse(window.localStorage.getItem(storageKeys.bookingCreationTime) || 'null');
  } catch (e) {
    console.error(e);
    clearBookingCreationTime();

    return null;
  }
};
export const setBookingCreationTime = value => {
  if (!value) return;

  try {
    window.localStorage.setItem(storageKeys.bookingCreationTime, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
};
export const clearBookingCreationTime = () => window.localStorage.removeItem(storageKeys.bookingCreationTime);
