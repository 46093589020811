import React, { useEffect } from 'react';

import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { WifiSplashFormFooterProps } from './../../types';
import './WifiSplashFormFooter.scss';
import { useWifiSplashFormModals } from './useWifiSplashFormModals';

const bem = cn('wifi-splash-form-footer');

export const WifiSplashFormFooter = (props: WifiSplashFormFooterProps) => {
  const { sponsorship, privacyPolicy, termsOfService, privacyPolicyModal, wifiTermsAndConditionsModal } = props;

  const {
    showModal: showPrivacyPolicyModal,
    renderModal: renderPrivacyPolicyModal,
    fetchXFContent: fetchPrivacyPolicyContent,
  } = useWifiSplashFormModals(privacyPolicyModal);

  const {
    showModal: showTermsOfServiceModal,
    renderModal: renderTermsOfServiceModal,
    fetchXFContent: fetchTermsOfServiceContent,
  } = useWifiSplashFormModals(wifiTermsAndConditionsModal);

  useEffect(() => {
    if (fetchPrivacyPolicyContent) fetchPrivacyPolicyContent();
    if (fetchTermsOfServiceContent) fetchTermsOfServiceContent();
  }, [fetchPrivacyPolicyContent, fetchTermsOfServiceContent]);

  if (!sponsorship && !privacyPolicy && !termsOfService) return null;

  return (
    <div className={bem()}>
      {sponsorship && <Text text={sponsorship} className={bem('sponsorship')} />}
      {privacyPolicy && <Text text={privacyPolicy} className={bem('privacyPolicy')} onClick={showPrivacyPolicyModal} />}
      {renderPrivacyPolicyModal(props.privacyPolicyModal?.title)}
      {termsOfService && (
        <Text text={termsOfService} className={bem('termsOfService')} onClick={showTermsOfServiceModal} />
      )}
      {renderTermsOfServiceModal(props.wifiTermsAndConditionsModal?.title)}
    </div>
  );
};
