import React, { useEffect } from 'react';
import { Portal } from 'react-portal';

import Title from '@atoms/Title/Title';

import { cn } from 'utils/classNames';

import './Modal.scss';

const bem = cn('manage-trips-form-modal');

export const Modal = ({ children, title, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return (
    <Portal>
      <div className={bem()}>
        <div className={bem('header')}>
          <Title size="h2" text={title} />
          <button className={bem('button')} onClick={onClose}>
            Close
          </button>
        </div>
        {children}
      </div>
    </Portal>
  );
};
