import React from 'react';

import { cn } from 'utils/classNames';

import './CarouselDots.scss';
import { CarouselDotsProps } from './types';

const bem = cn('carousel-dots');

export const CarouselDots = (props: CarouselDotsProps) => {
  const { hasPasses, activeSlide, items } = props;

  return (
    <div className={bem()}>
      {items.map((item, index) => {
        let key = `wallet-slide-${index}`;
        if (hasPasses) {
          const { type } = item;
          key = `wallet-${type}-${index}`;
        }

        return <div key={key} className={bem('dot', { active: activeSlide - 1 === index })} />;
      })}
    </div>
  );
};
