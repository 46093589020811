import { api } from './api';
import { requestUrls } from './constants';
import { clearDatadogUserData, getDatadogUserData, setDatadogUserData } from './localstorage';
import { getAuth0ClientId } from './metas';
import { DatadogUserDataConfig } from './types';

export const enrichDatadogSessionUserData = () => {
  window.DD_RUM?.onReady(async () => {
    const ddSessionID = getDdSessionID(window.DD_RUM?.getSessionReplayLink?.() || '');

    if (!ddSessionID) return;

    let ddUserData: DatadogUserDataConfig = getDatadogUserData();

    const userJustAuthenticated = isAuthenticated() && ddUserData && !ddUserData.id;

    if (ddUserData && ddUserData.sessionID === ddSessionID && !userJustAuthenticated)
      window.DD_RUM?.setUser?.({ ...ddUserData, sessionID: undefined });
    else if (isAuthenticated()) {
      // Get detailed user profile data
      const url = `${requestUrls.getRestUrl(requestUrls.userProfile)}.xjson`;
      const response = await api.get(url);

      const profileData = response.data.profile;

      let bookingNumber: string | undefined = undefined;
      if (ddUserData && ddUserData.sessionID === ddSessionID) bookingNumber = ddUserData.bookingNumber;

      ddUserData = {
        sessionID: ddSessionID,
        id: profileData.brightlineId,
        name: `${profileData.firstName} ${profileData.lastName}`,
        email: profileData.email,
        phoneNumber: `${profileData.phoneNumber.countryCode} ${profileData.phoneNumber.number}`,
        bookingNumber,
      };

      window.DD_RUM?.setUser?.({ ...ddUserData, sessionID: undefined });
      setDatadogUserData(ddUserData);
    } else window.DD_RUM?.clearUser?.();
  });
};

export const enrichDatadogSessionUserDataUnauthenticated = (userDetails: any, bookingNumber: string) => {
  const ddSessionID = getDdSessionID(window.DD_RUM?.getSessionReplayLink?.() || '');

  if (!ddSessionID) return;

  const ddUserData = {
    sessionID: ddSessionID,
    id: '',
    name: `${userDetails.firstName} ${userDetails.lastName}`,
    email: userDetails.email,
    phoneNumber: `${userDetails.phoneNumber.countryCode} ${userDetails.phoneNumber.number}`,
    bookingNumber: bookingNumber,
  };

  window.DD_RUM?.setUser?.({ ...ddUserData, sessionID: undefined });
  setDatadogUserData(ddUserData);
};

export const enrichDatadogSessionUserDataWithBookingNumber = (bookingNumber: string) => {
  const ddSessionID = getDdSessionID(window.DD_RUM?.getSessionReplayLink?.() || '');

  if (!ddSessionID) return;

  window.DD_RUM?.setUserProperty?.('bookingNumber', bookingNumber);

  const ddUserData: DatadogUserDataConfig = getDatadogUserData();

  if (ddUserData) {
    ddUserData.bookingNumber = bookingNumber;
    setDatadogUserData(ddUserData);
  }
};

export const stopDatadogSession = () => {
  window.DD_RUM?.stopSession?.();
  clearDatadogUserData();
};

const getDdSessionID = (sessionIdLink: string) => {
  if (!sessionIdLink) return '';

  const isInvalidSession = sessionIdLink.includes('rum-not-tracked');

  if (isInvalidSession) return '';

  const regex = /\/sessions\/([a-f0-9-]{36})/;
  const match = sessionIdLink.match(regex);

  if (match) return match[1];

  return '';
};

const isAuthenticated = () => {
  const clientId = getAuth0ClientId();

  return !!Object.keys(localStorage).find(
    key => key.includes('auth0') && key.includes(clientId) && key.includes('offline_access')
  );
};
