import { useEffect, useState } from 'react';
import { useStores } from 'stores';

import { fetchXfHTML } from 'utils/xfUtils';

export const useRewardsActivityLogic = () => {
  const { cfStore } = useStores();
  const { merchandisingXfForDesktop, merchandisingXfForMobile } = cfStore.account.rewardsGeneral.rewardsPage;

  const [merchandisingXfForDesktopHTML, setMerchandisingXfForDesktopHTML] = useState('');
  const [merchandisingXfForMobileHTML, setMerchandisingXfForMobileHTML] = useState('');

  useEffect(() => {
    const fetchXfs = async () => {
      merchandisingXfForDesktop && setMerchandisingXfForDesktopHTML(await fetchXfHTML(merchandisingXfForDesktop));
      merchandisingXfForMobile && setMerchandisingXfForMobileHTML(await fetchXfHTML(merchandisingXfForMobile));
    };

    fetchXfs();
  }, [merchandisingXfForDesktop, merchandisingXfForMobile]);

  return { merchandisingXfForDesktopHTML, merchandisingXfForMobileHTML };
};
