import React, { useMemo } from 'react';

import { AlertProps } from '@atoms/Alert/types';
import { Icon } from '@atoms/Icon';

import { cn, cx } from 'utils/classNames';

import './Alert.scss';

const bem = cn('alert');

const Alert = (props: AlertProps) => {
  const { summary, description, icon, className, colors, size, iconTheme } = props;

  const styles: any = useMemo(
    () => ({
      '--alert-summary-color': colors?.summary?.inactive,
      '--alert-description-color': colors?.description?.inactive,
      '--alert-background-color': colors?.background?.inactive,
      '--alert-border-focus-color': colors?.border?.focus,
      '--alert-icon-background-color': colors?.icon?.inactive,
      '--alert-icon-text-color': colors?.text?.inactive,
    }),
    [colors]
  );

  return (
    <div className={cx(bem(), className)} style={styles}>
      <Icon name={icon} className={bem('icon')} size={size} theme={iconTheme} />
      <div className={bem('summary')}>{summary}</div>
      {description && <div className={bem('description')}>{description}</div>}
    </div>
  );
};

export default Alert;
