import React, { useMemo, useState } from 'react';
import { useStores } from 'stores';
import { RouteItemConfig } from 'stores/AccountStore/sections';

import useDelaysExperienceLoagic from '@account/hooks/useDelaysExperienceLogic';
import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { AlertIcon } from '@molecules/TicketsForm/components/AlertBanner/Icons/AlertIcon';
import { WarningIcon } from '@molecules/TicketsForm/components/AlertBanner/Icons/WarningIcon';

import { cn, cx } from 'utils/classNames';

import './TrainStatusMessage.scss';

const bem = cn('train-status-messages');

export interface TrainStatusMessageProps {
  variant: string;
  className?: string;
  route: RouteItemConfig;
}

const TrainStatusMessage = (props: TrainStatusMessageProps) => {
  const { variant, className, route } = props;
  const { previousTimes, departureDateTime, origin } = route;

  const [showDelayAlert, setShowDelayAlert] = useState(true);
  const { getStatusMessage, timeDifference } = useDelaysExperienceLoagic();
  const {
    cfStore: {
      account: { tripDetails },
    },
  } = useStores();

  const delayTimeDifference = timeDifference(previousTimes?.departureTime, departureDateTime, origin.timeZone);

  const message = useMemo(
    () => getStatusMessage(tripDetails.delaysExperienceMessages, variant, delayTimeDifference),
    [variant, tripDetails, delayTimeDifference, getStatusMessage]
  );

  if (!variant || !showDelayAlert || !message?.title) return null;

  return (
    <div className={cx(bem({ variant }, className))}>
      <div className={bem('content')}>
        <div className={bem('message-icon')}>{variant == 'cancelled' ? <AlertIcon /> : <WarningIcon />}</div>
        <div>
          <Text className={bem('title')} text={message.title} />
          <Text className={bem('description')} text={message.description} />
        </div>
      </div>
      <button onClick={() => setShowDelayAlert(false)} className={bem('button')}>
        <Icon name="clear" className={bem('icon')} />
      </button>
    </div>
  );
};

export default TrainStatusMessage;
