import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TableSection } from '@organisms/FareCompareModal/v2/types';

import { generateEmptyGrid } from 'utils/helpers';

import { TableArrayElement, TableConfig, TableContentItem, TableProps } from './types';

export const useTableLogic = (props: TableProps) => {
  const { tableHeaders, tableRows, cellRenderer } = props;
  const { minTableWidth, eventListenersAttacher, cleanupListeners } = props;
  const { expandLabel, collapseLabel, isCollapsable } = props;

  const [table, setTable] = useState<TableConfig>({
    colGroupWidths: [],
    headerElements: [],
    bodyElements: [],
  });

  const [isTableCollapsed, setIsTableCollapsed] = useState(!!isCollapsable);

  useEffect(() => {
    if (!isCollapsable) setIsTableCollapsed(false);
    else setIsTableCollapsed(true);
  }, [isCollapsable]);

  useEffect(() => {
    if (!table || !eventListenersAttacher || !cleanupListeners) return undefined;

    const elements = eventListenersAttacher();

    return () => {
      cleanupListeners(...elements);
    };
  }, [table, eventListenersAttacher, cleanupListeners]);

  const tableStyle = useMemo(() => {
    if (!minTableWidth) return {};

    return {
      minWidth: `${minTableWidth}px`,
    };
  }, [minTableWidth]);

  const handleTableCollapseToggle = () => {
    setIsTableCollapsed(val => !val);
  };

  const generateTableColGroupWidths = (tableHeadersArray: TableSection) => {
    const colGroupsArray: number[] = [];

    tableHeadersArray.forEach(row => {
      row.forEach((cell, cellIndex) => {
        if (!cell || cell.isDummyCell) return;

        const cellWidth = (cell.columnWidthPercentage || 100) / cell.colSpan;

        colGroupsArray.push(cell.isDummyCell ? 0 : cellWidth);

        if (cell.colSpan > 1)
          for (const dummyCell of row.slice(cellIndex + 1)) {
            if (!dummyCell?.isDummyCell) break;

            colGroupsArray.push(cellWidth);
          }
      });
    });

    return colGroupsArray;
  };

  const parseTableSection = useCallback(
    (section: TableSection, numTableRows, numTableCols, overridenCellColors, isCollapsable?) => {
      const defaultTableCell: TableArrayElement = {
        rowSpan: 1,
        colSpan: 1,
        contentToRender: cellRenderer(),
        skipGeneration: false,
      };

      const emptyTable: TableArrayElement[][] = generateEmptyGrid(numTableRows, numTableCols, defaultTableCell);

      section.forEach((row, rowIndex) => {
        if (!row) return;

        row.forEach((cell, cellIndex) => {
          if (!cell) return;

          if ((cell.ignoreCellContent || cell.isDummyCell) && emptyTable[rowIndex][cellIndex])
            emptyTable[rowIndex][cellIndex].contentToRender = undefined;

          if (cell.ignoreCellContent || cell.isDummyCell) return;

          if (overridenCellColors?.[cellIndex]) cell.cellColor = overridenCellColors[cellIndex];

          if (isCollapsable && rowIndex === 0 && cellIndex === 0)
            cell = {
              ...cell,
              isCollapseControlCell: true,
              ...isCollapsable,
            } as TableContentItem;

          // account for rowspan cells that need to be styled with rounded borders
          if (cell.rowSpan > 1 && rowIndex + cell.rowSpan === numTableRows)
            if (cellIndex === 0) {
              cell.isBottomLeftElement = true;

              for (const nextRow of section.slice(rowIndex)) {
                const nextCell = nextRow[cellIndex + cell.colSpan];

                if (nextCell) nextCell.isNotBottomLeftElement = true;
              }
            } else if (cellIndex + cell.colSpan === numTableCols) {
              cell.isBottomRightElement = true;

              for (const nextRow of section.slice(rowIndex)) {
                const nextCell = nextRow[cellIndex - cell.colSpan];

                if (nextCell) nextCell.isNotBottomRightElement = true;
              }
            }

          emptyTable[rowIndex][cellIndex] = {
            rowSpan: cell.rowSpan || 1,
            colSpan: cell.colSpan || 1,
            contentToRender: cellRenderer(cell),
          };
        });
      });

      return emptyTable;
    },
    [cellRenderer]
  );

  const adaptTableForRowAndColSpan = useCallback(tableArray => {
    tableArray.forEach((row, rowIndex) => {
      for (let cellIndex = 0; cellIndex < tableArray[rowIndex].length; cellIndex++) {
        const cell = tableArray[rowIndex][cellIndex];

        if (!cell || !cell.rowSpan || !cell.colSpan || cell.isDummyCell) break;

        const colSpanHelperArray = Array.from({ length: cell.colSpan }, (_, index) => index);
        const rowSpanHelperArray = Array.from({ length: cell.rowSpan }, (_, index) => index);

        const emptyCellCoordinates: { row: number; col: number }[] = [];

        rowSpanHelperArray.forEach(rowHelperIndex => {
          colSpanHelperArray.forEach(cellHelperIndex => {
            if (rowHelperIndex === 0 && cellHelperIndex === 0) return;

            emptyCellCoordinates.push({
              row: rowIndex + rowHelperIndex,
              col: cellIndex + cellHelperIndex,
            });
          });
        });

        emptyCellCoordinates.forEach(emptyCellCoordinate => {
          const tableCell = tableArray[emptyCellCoordinate.row]?.[emptyCellCoordinate.col];

          if (tableCell?.ignoreCellContent) {
            tableCell.isDummyCell = true;

            return;
          }

          if (emptyCellCoordinate.row >= tableArray.length) tableArray.push([]);

          if (emptyCellCoordinate.col > tableArray[emptyCellCoordinate.row].length)
            tableArray[emptyCellCoordinate.row].push({
              isDummyCell: true,
              rowSpan: 1,
              colSpan: 1,
            });
          else
            tableArray[emptyCellCoordinate.row].splice(emptyCellCoordinate.col, 0, {
              isDummyCell: true,
              rowSpan: 1,
              colSpan: 1,
            });
        });
      }
    });

    return tableArray;
  }, []);

  const insertEmptyCells = useCallback((tableArray, numCols) => {
    tableArray?.forEach((_, row) => {
      const rowContainsColSpanCell = tableArray[row].some(col => (col?.ignoreCellContent ? 0 : col?.colSpan || 1) > 1);
      const numColsInRow = tableArray[row].reduce(
        (sum, col) => sum + (rowContainsColSpanCell ? 1 : col?.ignoreCellContent ? 1 : col?.colSpan || 0),
        0
      );

      Array.from({ length: numCols - numColsInRow }).forEach(() =>
        tableArray[row].push({
          rowSpan: 1,
          colSpan: 1,
        })
      );
    });

    return tableArray;
  }, []);

  const countTableRowsAndCols = useCallback(tableArray => {
    let numRows = 0;
    let numCols = 0;

    tableArray[0]?.forEach((_, col) => {
      const columnSum = tableArray.reduce(
        (sum, row) => sum + (row?.[col]?.isDummyCell ? 0 : row?.[col]?.rowSpan || 1),
        0
      );

      numRows = Math.max(numRows, columnSum);
    }, 0);

    tableArray?.forEach((_, row) => {
      const rowSum = tableArray[row].reduce(
        (sum, col) => sum + (col?.isDummyCell || col?.ignoreCellContent ? 0 : col?.colSpan || 1),
        0
      );

      numCols = Math.max(numCols, rowSum);
    }, 0);

    return {
      numRows,
      numCols,
    };
  }, []);

  const getColumnColors = useCallback(tableHeader => {
    const columnColors: string[] = [];

    tableHeader.forEach(cell => {
      if (!cell || cell.isDummyCell || cell.ignoreCellContent) return;

      const colSpanHelperArray = Array.from({ length: cell.colSpan }, (_, index) => index);

      colSpanHelperArray.forEach(() => columnColors.push(cell.overrideCellColors ? cell.cellColor || '#FFFFFF' : null));
    });

    return columnColors;
  }, []);

  const parseTableFromProps = useCallback(() => {
    let tableBodyArray = Object.values(cloneDeep(tableRows));

    let tableHeadersArray = adaptTableForRowAndColSpan([tableHeaders]);

    const overriddenColumnColors = getColumnColors(tableHeaders);
    const { numRows: numTableHeaderRows, numCols: numTableHeaderCols } = countTableRowsAndCols(tableHeadersArray);

    tableBodyArray = adaptTableForRowAndColSpan(tableBodyArray);
    const { numRows: numTableBodyRows, numCols: numTableBodyCols } = countTableRowsAndCols(tableBodyArray);

    tableHeadersArray = insertEmptyCells(tableHeadersArray, Math.max(numTableHeaderCols, numTableBodyCols));

    tableBodyArray = insertEmptyCells(tableBodyArray, Math.max(numTableBodyCols, numTableHeaderCols));

    const colGroupWidths = generateTableColGroupWidths(tableHeadersArray);

    const headerElements = parseTableSection(
      tableHeadersArray,
      numTableHeaderRows,
      Math.max(numTableHeaderCols, numTableBodyCols),
      overriddenColumnColors
    );

    const isCollapsableProp = isCollapsable && {
      isCollapsed: isTableCollapsed,
      isExpanded: !isTableCollapsed,
      expandLabel,
      collapseLabel,
      toggleTableCollapse: handleTableCollapseToggle,
    };

    const bodyElements = parseTableSection(
      tableBodyArray,
      numTableBodyRows,
      Math.max(numTableHeaderCols, numTableBodyCols),
      overriddenColumnColors,
      isCollapsableProp
    );

    setTable({
      colGroupWidths,
      headerElements,
      bodyElements,
    });
  }, [
    tableHeaders,
    tableRows,
    adaptTableForRowAndColSpan,
    countTableRowsAndCols,
    getColumnColors,
    insertEmptyCells,
    parseTableSection,
    isCollapsable,
    isTableCollapsed,
    collapseLabel,
    expandLabel,
  ]);

  useEffect(() => {
    parseTableFromProps();
  }, [parseTableFromProps]);

  return {
    table,
    tableStyle,
    isTableCollapsed,
  };
};
