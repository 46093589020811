import { FormikContextType, useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';

import { WidgetInput } from '@atoms/WidgetInput/WidgetInput';
import { PartialFromProps } from '@molecules/TicketsForm/components/OriginAndDestination/types';

import { cn } from 'utils/classNames';
import { useInputLogic } from 'utils/useInputLogic';

import { DATE_FORMAT, DEFAULT_I18N, END_DATE, START_DATE } from './constants';
import { DateInputsProps } from './types';

const bem = cn('date-range-picker');

export const DateInputs = (props: DateInputsProps) => {
  const { i18n = DEFAULT_I18N } = props;
  const { isOneWay } = props;
  const { handleInputClick, handleClearDate, focusedInput, handleCloseButton } = props;
  const { startLabel, startPlaceholder, endPlaceholder, endLabel } = i18n;

  const formikProps: FormikContextType<PartialFromProps> = useFormikContext();
  const { values } = formikProps;

  const { isInvalid: isStartDateInvalid, error: errStartDate } = useInputLogic({
    name: START_DATE,
  });
  const { isInvalid: isEndDateInvalid, error: errEndDate } = useInputLogic({ name: END_DATE });

  const handleClick = (e: any, inputName: string) => {
    handleInputClick(inputName);
  };

  const handleKeyDown = (e: any, inputName: string) => {
    if (['Enter', 'Space'].includes(e.key)) handleInputClick(inputName);

    if (e.key === 'Escape') handleCloseButton();
  };

  const handleClearStart = () => {
    handleClearDate(START_DATE);
  };

  const handleClearEnd = () => {
    handleClearDate(END_DATE);
  };

  return (
    <>
      <div className={bem('inputs', { oneWay: isOneWay })}>
        <div className={bem('input-left')}>
          <WidgetInput
            isInvalid={isStartDateInvalid}
            errorMessage={errStartDate}
            label={startLabel}
            role="combobox"
            placeholder={startPlaceholder}
            onInputClick={handleClick}
            onInputKeyDown={handleKeyDown}
            name={START_DATE}
            active={focusedInput === START_DATE}
            onClearClick={handleClearStart}
            value={values.startDate ? moment(values.startDate).format(DATE_FORMAT) : ''}
            position={isOneWay ? undefined : 'left'}
            renderAs="button"
            readOnly
            isRequired
            addErrorId
          />
        </div>
        {!isOneWay && (
          <div className={bem('input-right')}>
            <WidgetInput
              label={endLabel}
              role="combobox"
              placeholder={endPlaceholder}
              name={END_DATE}
              onInputKeyDown={handleKeyDown}
              onInputClick={handleClick}
              active={focusedInput === END_DATE}
              onClearClick={handleClearEnd}
              value={values.endDate ? moment(values.endDate).format(DATE_FORMAT) : ''}
              isInvalid={isEndDateInvalid}
              errorMessage={errEndDate}
              position="right"
              renderAs="button"
              readOnly
              isRequired
              addErrorId
            />
          </div>
        )}
      </div>
    </>
  );
};
