import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';
import { color_white } from 'utils/colors';

import { PriceItemProps } from '../../types';
import './PriceItem.scss';
import { DesktopLayout, MobileLayout } from './components';

const bem = cn('price-item');

export const PriceItem = observer((props: PriceItemProps) => {
  const { isMobile, product, loading, defaultProduct } = props;
  const { journey, onTicketSelect, isUnavailable } = props;
  const { seatingClassType } = product || {};
  const disabled = loading || !defaultProduct?.productId || isUnavailable;

  const { bookingStore } = useStores();
  const { fareSelectionTable } = bookingStore.tickets;

  const handleItemClick = () => {
    if (onTicketSelect) onTicketSelect(defaultProduct);
  };

  const selected =
    fareSelectionTable &&
    fareSelectionTable?.journeyID === journey?.id &&
    fareSelectionTable?.defaultProduct.productId === product.id;

  return (
    <button
      className={bem({ class: seatingClassType, selected, loading, disabled })}
      disabled={disabled}
      onClick={handleItemClick}
      role="link"
    >
      <span className="sr-only">{seatingClassType} price</span>
      <ReactPlaceholder
        type="text"
        rows={2}
        ready={!loading}
        widths={[70, 20]}
        color={isMobile ? color_white : undefined}
        className={bem('placeholder', { mobile: isMobile })}
        showLoadingAnimation
      >
        {isMobile ? <MobileLayout {...props} /> : <DesktopLayout {...props} />}
      </ReactPlaceholder>
    </button>
  );
});
