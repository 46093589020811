import { useAdyen } from 'hooks/useAdyen';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useStores } from 'stores';

import Radio from '@atoms/Radio/Radio';
import { PaymentCardItem } from '@molecules/PaymentCardItem';

import { cn } from 'utils/classNames';

import './CreditCardItem.scss';

const bem = cn('rti-credit-card-item');

export const CreditCardItem = observer((props: any) => {
  const { card, setCardInstance, setCardData, setSelectedPaymentMethod, selectedPaymentMethod } = props;
  const { cardImage, name, isNewCard, expirationDate, lastFour } = card;

  const { cfStore, accountStore, bookingStore } = useStores();
  const { clearError } = bookingStore.rti;
  const { paymentTokensStore } = accountStore;
  const { paymentTokens } = paymentTokensStore;

  const location = useLocation();
  const { account, cardInformation } = cfStore;
  const { paymentsCF } = account;
  const { rti } = cfStore;
  const { reviewAndPay } = rti;

  const paymentContainer = useRef(null);
  const adyen = useAdyen({ isOpen: true, paymentContainer, setCardInstance, setCardData });
  useEffect(() => {
    if (isNewCard && selectedPaymentMethod?.isNewCard) adyen.initializeAdyenCard();
  }, [isNewCard, selectedPaymentMethod, adyen]);

  const { expiresLabel } = cardInformation;

  const cardNumberMasked = useMemo(() => {
    const masked = '*'.repeat(4);

    return `${name} ${masked}${lastFour}`;
  }, [lastFour, name]);

  const handleSelect = () => {
    clearError();
    setSelectedPaymentMethod(card);
  };

  const isSavedCardsLimitReached = paymentTokens.length >= 5;
  const savedCardsLimitReachedStyle = isNewCard && isSavedCardsLimitReached && location.pathname === '/wallet';

  const addNewCardLabels = () => {
    if (location.pathname === '/rti')
      return {
        savedCardsLimitLabel: reviewAndPay.cardInformation.savedCardsLimitLabel,
        addNewCardLabel: reviewAndPay.cardInformation.addNewCreditCardLabel,
      };

    return {
      savedCardsLimitLabel: paymentsCF.savedCardsLimitAutoRenewLabel,
      addNewCardLabel: paymentsCF.addNewCardLabel,
    };
  };

  const paymentCardItemLabel = () => {
    if (isNewCard) {
      if (isSavedCardsLimitReached && location.pathname === '/wallet') return addNewCardLabels().savedCardsLimitLabel;

      return addNewCardLabels().addNewCardLabel;
    }

    return cardNumberMasked;
  };

  const showPaymentForm = () => {
    if (location.pathname === '/wallet')
      return isNewCard && selectedPaymentMethod?.isNewCard && !isSavedCardsLimitReached;
    else if (location.pathname === '/rti') return isNewCard && selectedPaymentMethod?.isNewCard;

    return '';
  };

  const newCardActive = () => {
    if (location.pathname === '/wallet')
      return isNewCard && selectedPaymentMethod?.isNewCard && !isSavedCardsLimitReached;

    return isNewCard && selectedPaymentMethod?.isNewCard;
  };

  return (
    <div className={bem({ newCardActive: newCardActive() })}>
      <div
        className={bem('option', { active: card.tokenId === selectedPaymentMethod?.tokenId && !isNewCard })}
        onClick={handleSelect}
      >
        <div className={bem('info')}>
          <PaymentCardItem
            cardImage={cardImage}
            expirationDate={expirationDate}
            cardLabel={paymentCardItemLabel()}
            expirationLabel={expiresLabel}
            savedCardsLimitReached={savedCardsLimitReachedStyle}
          />
        </div>
        <Radio
          name={card.tokenId}
          label=""
          className={bem('radio')}
          checked={card?.tokenId === selectedPaymentMethod?.tokenId}
          disabled={isNewCard && location.pathname === '/wallet' && isSavedCardsLimitReached}
          onChange={handleSelect}
        />
      </div>
      {showPaymentForm() && (
        <div className={bem('form')}>
          <div className="payment-container">
            <div ref={paymentContainer} className="payment" />
          </div>
        </div>
      )}
    </div>
  );
});
