import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Voucher } from '@account/routes/TripDetails/components/CancellationModal/ReturnSummary/Voucher/Voucher';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './ReturnAsCredit.scss';

const bem = cn('rti-return-as-credit');
const bemPaymentEntry = cn('payment-info-entry');

export const ReturnAsCredit = observer(() => {
  const { authStore, cfStore, bookingStore } = useStores();
  const { isAuthenticated } = authStore;

  const { rti } = cfStore;
  const { brightlineWallet, brightlineCredit, travelPassRidesReturnedLabel } = rti.reviewAndPay;
  const { brightlineCreditDisclaimerLoggedIn, brightlineCreditDisclaimerGuest } = rti.reviewAndPay;

  const refundMethods = useMemo(() => {
    const refundMethods: any[] = [];

    bookingStore.rti?.provisionalBooking?.tripDetails?.costSummary?.refundMethods?.forEach(refundMethod => {
      refundMethod.numOccurrences = 1;

      const existingRefundMethodIdx = refundMethods.findIndex(
        el => el.type === refundMethod.type && el.title === refundMethod.title
      );
      if (existingRefundMethodIdx === -1) refundMethods.push(refundMethod);
      else refundMethods[existingRefundMethodIdx].numOccurrences++;
    });

    return refundMethods || [];
  }, [bookingStore.rti?.provisionalBooking?.tripDetails?.costSummary?.refundMethods]);

  return (
    <div className={bem()}>
      <div className={bemPaymentEntry('section-header')}>
        <span className={bemPaymentEntry('section-header-title')}>
          {isAuthenticated ? brightlineWallet.title : brightlineCredit}
        </span>
      </div>
      {isAuthenticated && (
        <div className={bem('refund-methods')}>
          {refundMethods.map((item, idx) => (
            <Voucher
              key={`${idx}-${item?.type}`}
              type={item?.type}
              title={item?.title}
              description={item?.description}
              note={item?.note}
              icon={brightlineWallet.brightlineCreditPassIcon}
              kicker={item.kicker}
              numOccurrences={item.numOccurrences}
              travelPassRidesReturnedLabel={travelPassRidesReturnedLabel}
              isSelected
            />
          ))}
        </div>
      )}
      <div className={bem('disclaimer')}>
        {isAuthenticated ? (
          <Text text={brightlineCreditDisclaimerLoggedIn} />
        ) : (
          <Text text={brightlineCreditDisclaimerGuest} />
        )}
      </div>
    </div>
  );
});
