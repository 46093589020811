import React from 'react';

import '@molecules/TicketsForm/components/OriginAndDestination/Select/Mobile/MobileSelect.scss';

import { cn, cx } from 'utils/classNames';

import { MobileSelectProps } from '../types';
import { WidgetInput } from './WidgetInput/WidgetInput';

const bem = cn('od-mobile-select');
const bemField = cn('od-select');

const MobileSelect = (props: MobileSelectProps) => {
  const { selectIndex, value, items, focus, active, label, placeholder } = props;
  const { textInput = '', error, onHeaderClick } = props;
  const { setFocus, position } = props;
  const shouldFocusInput = active && focus?.index === -1;
  const selectedItem = items.find(item => item.id === value);

  const handleHeaderClick = () => {
    if (setFocus) setFocus({ index: -1, preventScroll: false });
    onHeaderClick(selectIndex);
  };

  return (
    <div className={cx(bem(), bemField())}>
      <WidgetInput
        isInvalid={error}
        label={label}
        onInputClick={handleHeaderClick}
        value={selectedItem?.name || textInput}
        placeholder={placeholder}
        focus={shouldFocusInput}
        position={position}
        disabled
      />
    </div>
  );
};

export default MobileSelect;
