import { observer } from 'mobx-react-lite';
import React, { Suspense, useRef } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { MyAccount } from '@account/components/MyAccount';
import PageLoadingPlaceholders from '@account/components/PageLoadingPlaceholder/PageLoadingPlaceholders';
import { useCMSContent } from '@account/hooks/useCMSContent';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { isLoyaltyEnabled } from 'utils/metas';

import './Settings.scss';
import { DeleteAccountOverlay } from './components/DeleteAccountOverlay';
import { EditDetailsOverlay } from './components/EditDetailsOverlay';
import { EditDetailsTravelAgentOverlay } from './components/EditDetailsTravelAgentOverlay';
import { ProfileDetails } from './components/ProfileDetails';

const bem = cn('profile-settings');

const Settings = observer(() => {
  useCMSContent('settings');
  const { accountStore } = useStores();
  const { isLoading } = accountStore.profile;

  const modalOnCloseProfileDetailsRef = useRef<HTMLElement>();

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const loyaltyEnabled = isLoyaltyEnabled();

  if (isLoading) return <PageLoadingPlaceholders />;

  return (
    <Suspense fallback={<div />}>
      <div className={bem()}>
        {loyaltyEnabled && isMobile && (
          <div className={bem('user-account')}>
            <MyAccount />
          </div>
        )}

        <ProfileDetails modalOnCloseRef={modalOnCloseProfileDetailsRef} />

        <EditDetailsOverlay modalOnCloseRef={modalOnCloseProfileDetailsRef} />

        <EditDetailsTravelAgentOverlay />

        <DeleteAccountOverlay />
      </div>
    </Suspense>
  );
});

export default Settings;
