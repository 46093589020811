import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { rootStore, useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './PassesReview.scss';
import { ParkingPassConfig, TravelPassConfig } from './types';

const bem = cn('passes-review');

export const PassesReview = observer(() => {
  const { cfStore, passesStore, authStore } = useStores();
  const cfLabels = cfStore.passes.passesSummary;
  const { travelPass, parkingPass, urlParams, base64Data } = passesStore.checkout;
  const { isAuthenticated: isWebAuthenticated } = authStore;

  const isMobileAuthenticated = urlParams.mobileData.jwt;

  const isAuthenticated = useMemo(
    () => isWebAuthenticated || isMobileAuthenticated,
    [isWebAuthenticated, isMobileAuthenticated]
  );

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const titleConfig = useMemo(() => {
    if (isAuthenticated) return travelPass ? cfLabels.trainPassLabel : cfLabels.monthlyParkingPassLabel;

    return urlParams.travelPass ? cfLabels.trainPassLabel : cfLabels.monthlyParkingPassLabel;
  }, [isAuthenticated, travelPass, urlParams, cfLabels]);

  const generateAnalyticsClickEvent = useCallback(
    (customLink: string) => {
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: titleConfig,
          customLink: customLink,
          externalLink: '',
          linkType: 'linkClick',
        },
      });
    },
    [titleConfig]
  );

  const travelPassConfig: undefined | TravelPassConfig = useMemo(() => {
    if (isAuthenticated && !travelPass) return undefined;
    if (!isAuthenticated && !urlParams.travelPass) return undefined;

    return {
      originStation: isAuthenticated ? travelPass?.originStation.nameLong : urlParams.travelPass?.originName,
      destinationStation: isAuthenticated
        ? travelPass?.destinationStation.nameLong
        : urlParams.travelPass?.destinationName,
      type: isAuthenticated ? travelPass?.productName : urlParams.travelPass?.productName,
      price: `$${isAuthenticated ? travelPass?.originalPrice : urlParams.travelPass?.price}`,
    };
  }, [travelPass, isAuthenticated, urlParams.travelPass]);

  const parkingPassConfig: undefined | ParkingPassConfig = useMemo(() => {
    if (isAuthenticated && !parkingPass) return undefined;
    if (!isAuthenticated && !urlParams.parkingPass) return undefined;

    return {
      garage: isAuthenticated ? parkingPass?.station.nameLong : urlParams.parkingPass?.stationName,
      state: isAuthenticated ? parkingPass?.licensePlate?.state : urlParams.parkingPass?.licenseState,
      licensePlate: isAuthenticated ? parkingPass?.licensePlate?.number : urlParams.parkingPass?.licenseNumber,
      duration: isAuthenticated ? parkingPass?.productName : `${urlParams.parkingPass?.productName} Month`,
      autoRenewal: urlParams.autoRenews ? 'Yes' : 'No',
      price: `$${isAuthenticated ? parkingPass?.originalPrice : urlParams.parkingPass?.price}`,
    };
  }, [parkingPass, isAuthenticated, urlParams.parkingPass, urlParams.autoRenews]);

  const handleEditButtonClicked = () => {
    const travelPassLink = new URL(
      urlParams.mobileData.jwt ? cfLabels.editLinkToTrainPassPageMobile : cfLabels.editLinkToTrainPassPage
    );
    travelPassLink.searchParams.append('data', base64Data);

    const parkingPassLink = new URL(
      urlParams.mobileData.jwt ? cfLabels.editLinkToParkingPassPageMobile : cfLabels.editLinkToParkingPassPage
    );
    parkingPassLink.searchParams.append('data', base64Data);

    if (isAuthenticated) {
      if (travelPass) {
        generateAnalyticsClickEvent(travelPassLink.toString());
        window.location.href = travelPassLink.toString();
      } else if (parkingPass) {
        generateAnalyticsClickEvent(parkingPassLink.toString());
        window.location.href = parkingPassLink.toString();
      }
    } else if (urlParams.travelPass) {
      generateAnalyticsClickEvent(travelPassLink.toString());
      window.location.href = travelPassLink.toString();
    } else if (urlParams.parkingPass) {
      generateAnalyticsClickEvent(parkingPassLink.toString());
      window.location.href = parkingPassLink.toString();
    }
  };

  return (
    <div className={bem()}>
      <div className={bem('title')}>
        <div className={bem('title-icon')}>
          <Icon name="check-circle" />
        </div>
        <Text text={titleConfig} className={bem('title-text')} />
        <Button
          label={cfLabels.editCtaLabel}
          variant="secondary"
          size={isMobile ? 'small' : 'medium'}
          onClick={handleEditButtonClicked}
          color="blue"
        />
      </div>

      {travelPassConfig && (
        <div className={bem('summary')}>
          <div className={bem('summary-travel-pass')}>
            {parkingPassConfig && (
              <div className={bem('summary-subtitle')}>
                <Text text={cfLabels.trainPassLabel} className={bem('summary-subtitle-text')} />
                <Text text={travelPassConfig.price} className={bem('summary-subtitle-price')} />
              </div>
            )}
            <div className={bem('summary-details')}>
              <Text
                text={`${travelPassConfig.originStation} - ${travelPassConfig.destinationStation}`}
                className={bem('summary-details-text')}
              />
              <Text text={travelPassConfig.type} className={bem('summary-details-text')} />
            </div>
          </div>
          {parkingPassConfig && (
            <div className={bem('summary-travel-pass')}>
              <div className={bem('summary-subtitle')}>
                <Text text={cfLabels.monthlyParkingPassLabel} className={bem('summary-subtitle-text')} />
                <Text text={parkingPassConfig.price} className={bem('summary-subtitle-price')} />
              </div>
              <div className={bem('summary-details')}>
                <Text text={parkingPassConfig.garage} className={bem('summary-details-text')} />
                <Text text={parkingPassConfig.duration} className={bem('summary-details-text')} />
              </div>
            </div>
          )}
        </div>
      )}
      {!travelPassConfig && parkingPassConfig && (
        <div className={bem('summary', { isParkingPass: true })}>
          <div className={bem('summary-parking-labels')}>
            <Text text={cfLabels.garageLabel} className={bem('summary-parking-labels-text')} />
            {!!parkingPassConfig.licensePlate && (
              <Text text={cfLabels.stateLabel} className={bem('summary-parking-labels-text')} />
            )}
            {!!parkingPassConfig.state && (
              <Text text={cfLabels.licensePlateNumberLabel} className={bem('summary-parking-labels-text')} />
            )}
            <Text text={cfLabels.autoRenewalLabel} className={bem('summary-parking-labels-text')} />
          </div>
          <div className={bem('summary-parking-labels', { isRight: isMobile })}>
            <Text text={parkingPassConfig.garage} className={bem('summary-parking-labels-text')} />
            {!!parkingPassConfig.licensePlate && (
              <Text text={parkingPassConfig.state} className={bem('summary-parking-labels-text')} />
            )}
            {!!parkingPassConfig.state && (
              <Text text={parkingPassConfig.licensePlate} className={bem('summary-parking-labels-text')} />
            )}
            <Text text={parkingPassConfig.autoRenewal} className={bem('summary-parking-labels-text')} />
          </div>
        </div>
      )}
    </div>
  );
});
