import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import Popover from 'react-popover';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { DateSelection } from './CalendarFilter/DateSelection';
import { CityFilters } from './CityFilters';
import './EventsBrowserFiltersDesktop.scss';

const bem = cn('events-browser-filters-desktop');

export const EventsBrowserFiltersDesktop = observer(({ logic }: any) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { cfStore } = useStores();
  const { filterItems, numberOfFiltersTabletLarge, numberOfFiltersTabletMedium } = cfStore.events;
  const { numberOfFiltersTabletSmall } = cfStore.events;

  const { cityFilterLabel, clearAllFiltersLabel, allCitiesLabel, allCitiesIcon, orlandoLabel } =
    cfStore.events.cityFilter;
  const { orlandoIcon, westPalmBeachLabel, westPalmBeachIcon, bocaRatonLabel } = cfStore.events.cityFilter;
  const { bocaRatonIcon, fortLauderdaleLabel, fortLauderdaleIcon, aventuraLabel } = cfStore.events.cityFilter;
  const { aventuraIcon, miamiLabel, miamiIcon, cityFilterIcon } = cfStore.events.cityFilter;
  const { dateFilterLabel, dateFilterIcon, closeButtonLabel, clearButtonLabel } = cfStore.events.calendarFilter;

  const { handleFilterClicked, selectedStations, handleStationClicked, handleClearFilters } = logic;
  const {
    cityPopoverOpen,
    setCityPopoverOpen,
    isFilterSelected,
    windowSize,
    datesRestrictionsData,
    datePopoverOpen,
    setDatePopoverOpen,
    calendarStartDate,
    setCalendarStartDate,
    calendarEndDate,
    setCalendarEndDate,
    activeButtonFilter,
    setActiveButtonFilter,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    formatDateRange,
  } = logic;

  const { width } = windowSize;

  const stations = [
    { label: allCitiesLabel, id: 'All', icon: allCitiesIcon },
    { label: orlandoLabel, id: 'MCO', icon: orlandoIcon },
    { label: westPalmBeachLabel, id: 'WPT', icon: westPalmBeachIcon },
    { label: bocaRatonLabel, id: 'RRN', icon: bocaRatonIcon },
    { label: fortLauderdaleLabel, id: 'FBT', icon: fortLauderdaleIcon },
    { label: aventuraLabel, id: 'AVE', icon: aventuraIcon },
    { label: miamiLabel, id: 'EKW', icon: miamiIcon },
  ];

  const isTabletMedium = useMediaPredicate('(min-width: 635px) and (max-width: 777px)');
  const isTabletLarge = useMediaPredicate('(min-width: 777px) and (max-width: 1024px)');
  const isDesktop = useMediaPredicate('(min-width: 1025px)');

  const itemsPerPage = useMemo(() => {
    if (isTabletLarge) return numberOfFiltersTabletLarge;
    else if (isTabletMedium) return numberOfFiltersTabletMedium;

    return numberOfFiltersTabletSmall;
  }, [
    isTabletLarge,
    isTabletMedium,
    numberOfFiltersTabletLarge,
    numberOfFiltersTabletMedium,
    numberOfFiltersTabletSmall,
  ]);

  const calendarBottomlabels = { closeButtonLabel, clearButtonLabel };
  const [focusedInput, setFocusedInput] = useState<string>('');
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filterItems.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filterItems.length / itemsPerPage);

  const handleNext = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages - 1));
  };

  const handlePrev = () => {
    setCurrentPage(prev => Math.max(prev - 1, 0));
  };

  useEffect(() => {
    setCityPopoverOpen(false);
    setDatePopoverOpen(false);
  }, [setCityPopoverOpen, setDatePopoverOpen, width]);

  const datePopoverOnOuterAction = () => {
    setFocusedInput('');
    setDatePopoverOpen(false);
  };

  const handleDateFilterOnClick = () => {
    setFocusedInput('startDate');
    setDatePopoverOpen(val => !val);
  };

  const handleClearDateIcon = () => {
    setActiveButtonFilter({
      value: '',
      label: '',
    });
    setCalendarStartDate('');
    setCalendarEndDate('');
    setSelectedStartDate('');
    setSelectedEndDate('');
  };

  return (
    <div className={bem('wrapper')}>
      <div className={bem('filters-wrapper')}>
        {!isDesktop ? (
          <>
            {currentPage !== 0 && (
              <div className={bem('slick-prev')} onClick={handlePrev}>
                <Icon name="chevron-left" className={bem('chevron-icon')} />
              </div>
            )}
            {currentItems.map((filterItem, idx) => (
              <div
                key={startIndex + idx}
                className={bem('filter-item', { selected: isFilterSelected(filterItem) })}
                onClick={() => handleFilterClicked(filterItem)}
              >
                {filterItem.filterIcon && (
                  <img src={filterItem.filterIcon?.src} alt="" className={bem('filter-icon')} />
                )}
                <Text text={filterItem.filterLabel} className={bem('filter-label')} />
              </div>
            ))}
            {currentPage !== totalPages - 1 && (
              <div className={bem('slick-next')} onClick={handleNext}>
                <Icon name="chevron-right" className={bem('chevron-icon')} />
              </div>
            )}
          </>
        ) : (
          filterItems.map((filterItem, idx) => (
            <div
              key={idx}
              className={bem('filter-item', { selected: isFilterSelected(filterItem) })}
              onClick={() => handleFilterClicked(filterItem)}
            >
              {filterItem.filterIcon && <img src={filterItem.filterIcon?.src} alt="" className={bem('filter-icon')} />}
              <Text text={filterItem.filterLabel} className={bem('filter-label')} />
            </div>
          ))
        )}

        {width > 575 && (
          <Popover
            className={bem('popover')}
            onOuterAction={() => setCityPopoverOpen(false)}
            isOpen={cityPopoverOpen}
            tipSize={0.01}
            preferPlace="below"
            enterExitTransitionDurationMs={700}
            body={<CityFilters logic={logic} setCityPopoverOpen={setCityPopoverOpen} stations={stations} />}
          >
            <div
              className={bem('city-filter', { selected: cityPopoverOpen })}
              onClick={() => setCityPopoverOpen(val => !val)}
            >
              <div className={bem('city-filter-label')}>
                <img className={bem('filter-icon')} src={cityFilterIcon.src} alt={cityFilterIcon.alt} />
                <Text text={cityFilterLabel} className={bem('filter-label')} />
              </div>
              <Icon name={cityPopoverOpen ? 'chevron-up' : 'chevron-down'} className={bem('filter-icon')} />
            </div>
          </Popover>
        )}
        {width > 575 && (
          <Popover
            className={bem('popover')}
            onOuterAction={datePopoverOnOuterAction}
            isOpen={datePopoverOpen}
            tipSize={0.01}
            preferPlace="below"
            enterExitTransitionDurationMs={700}
            body={
              <DateSelection
                labels={calendarBottomlabels}
                setFocusedInput={setFocusedInput}
                focusedInput={focusedInput}
                datesRestrictions={datesRestrictionsData}
                calendarStartDate={calendarStartDate}
                setCalendarStartDate={setCalendarStartDate}
                calendarEndDate={calendarEndDate}
                setCalendarEndDate={setCalendarEndDate}
                activeButtonFilter={activeButtonFilter}
                setActiveButtonFilter={setActiveButtonFilter}
                datePopoverOpen={datePopoverOpen}
                setDatePopoverOpen={setDatePopoverOpen}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
              />
            }
          >
            <div className={bem('city-filter', { selected: datePopoverOpen })} onClick={handleDateFilterOnClick}>
              <div className={bem('city-filter-label')}>
                <img className={bem('filter-icon')} src={dateFilterIcon.src} alt={dateFilterIcon.alt} />
                <Text text={dateFilterLabel} className={bem('filter-label')} />
              </div>
              <Icon name={datePopoverOpen ? 'chevron-up' : 'chevron-down'} className={bem('filter-icon')} />
            </div>
          </Popover>
        )}
      </div>
      {(!selectedStations.includes('All') || activeButtonFilter.value || (calendarStartDate && calendarEndDate)) && (
        <div className={bem('selected-city-filters')}>
          {!selectedStations.includes('All') &&
            selectedStations.map(stationID => (
              <div key={stationID} className={bem('selected-city-pill')}>
                {stations.find(station => station.id === stationID)?.label}
                <div
                  key={stationID}
                  className={bem('selected-city-pill-icon')}
                  onClick={() => handleStationClicked(stationID)}
                >
                  <Icon name="clear" />
                </div>
              </div>
            ))}
          {(activeButtonFilter.value || (calendarStartDate && calendarEndDate)) && (
            <div className={bem('selected-city-pill')}>
              {activeButtonFilter.value && activeButtonFilter.label && `${activeButtonFilter.label}`}
              {calendarStartDate && calendarEndDate && formatDateRange(calendarStartDate, calendarEndDate)}
              <div className={bem('selected-city-pill-icon')} onClick={handleClearDateIcon}>
                <Icon name="clear" />
              </div>
            </div>
          )}
          <LinkButton label={clearAllFiltersLabel} variant="big" onClick={handleClearFilters} />
        </div>
      )}
    </div>
  );
});
