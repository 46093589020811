import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { getTicketsFormValidationSchema } from '@organisms/BookingSearch/validator';

import { cn } from 'utils/classNames';
import { base64Encode } from 'utils/helpers';

import './ModifyTripForm.scss';
import { DateSelection } from './components/DateSelection';
import { EditSection } from './components/EditSection';
import { Modal, useModalLogic } from './components/Modal';
import { OriginAndDestinationModifyTrip } from './components/OriginAndDestination';
import { DEFAULT_I18N } from './constants';
import { TicketsFormMobileProps } from './types';

const bem = cn('modify-trip-tickets-form');

export const TicketsFormMobile = observer((props: TicketsFormMobileProps) => {
  const modalLogicProps = useModalLogic(props);

  const { ticketsFormStore, accountStore } = useStores();
  const { tripDetails } = accountStore;
  const { isPanelVisible, setCurrentStep, currentStep, showPanel, hidePanel } = ticketsFormStore;
  const { setIncompleteSearch } = ticketsFormStore;

  const {
    title,
    stationsSelection,
    stations,
    initialFormValues,
    fromCity,
    toCity,
    departureTime,
    returnTime,
    tabType,
    pnr,
    originId,
    destinationId,
    isRoundTrip,
    labels,
    isFirstLegTraveled,
    isSecondLegTraveled,
  } = props;
  const { datesSelection, tripSelection, i18n = DEFAULT_I18N, datesRestrictions } = props;
  const { hideFormOnMobile } = props;
  const { role, ariaLabelledBy, id } = props;

  const { doHandleModalClose } = modalLogicProps;

  useEffect(() => {
    hidePanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOriginDestinationEdit = () => (
    <EditSection disabled>
      <div>
        <span>{stations?.find(s => s.id === originId)?.name}</span>
        <span className={bem('separator')}>
          <Icon name="long-arrow-right" />
        </span>
        <span>{stations?.find(s => s.id === destinationId)?.name}</span>
      </div>
    </EditSection>
  );

  const initialTouchFields = {};

  const sch = getTicketsFormValidationSchema(
    { ...stationsSelection.i18n, ...datesSelection.i18n, stations },
    !isRoundTrip
  )[currentStep];

  const firstActionOnClick = () => {
    showPanel();
    setCurrentStep(1);
  };

  const handleSubmit = (values, actions) => {
    if (currentStep === 0 || (!isRoundTrip && currentStep === 1)) {
      actions.setTouched({});
      actions.setSubmitting(false);
      showPanel();
      setCurrentStep(currentStep + 1);
    } else {
      setIncompleteSearch(false);

      const rn = pnr;
      const startDate = values?.startDate
        ? moment.isMoment(values?.startDate)
          ? values?.startDate.format('YYYY-MM-DD')
          : moment(values?.startDate).format('YYYY-MM-DD')
        : '';
      const endDate = values?.endDate
        ? moment.isMoment(values?.endDate)
          ? values?.endDate?.format('YYYY-MM-DD')
          : moment(values?.endDate)?.format('YYYY-MM-DD')
        : '';

      let data;

      if (tabType === 'departure') {
        if (startDate && !isFirstLegTraveled)
          data = {
            rn: rn,
            start_date: startDate,
          };
      } else if (tabType === 'return') {
        if (endDate && !isSecondLegTraveled)
          data = {
            rn: rn,
            end_date: endDate,
          };
      } else if (tabType === 'both')
        if (!isFirstLegTraveled && !isSecondLegTraveled && startDate && endDate)
          data = {
            rn: rn,
            start_date: startDate,
            end_date: endDate,
          };
        else if (isFirstLegTraveled && !isSecondLegTraveled && endDate)
          data = {
            rn: rn,
            end_date: endDate,
          };

      if (!data) return;

      if (tripDetails?.passengerSeatingDetails?.length) {
        const primaryPassenger = tripDetails?.passengerSeatingDetails[0]?.passenger;

        if (!primaryPassenger) return;

        data = {
          ...data,
          fn: primaryPassenger?.firstName,
          ln: primaryPassenger?.lastName,
        };
      } else return;

      const encodedData = base64Encode(JSON.stringify(data));

      window.location.href = `/booking?data=${encodedData}&rn=${data.rn}`;
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialFormValues || {}}
      validationSchema={sch}
      initialTouched={initialTouchFields}
      validateOnMount
      enableReinitialize
      validateOnChange
    >
      {({ setValues, isValid, touched, handleSubmit: formikSubmit, submitForm }) => (
        <form onSubmit={formikSubmit} role={role} aria-labelledby={ariaLabelledBy} id={id}>
          {!hideFormOnMobile && (
            <>
              <div className={bem('content')}>
                <OriginAndDestinationModifyTrip
                  {...stationsSelection}
                  stations={stations}
                  showPanel={showPanel}
                  originId={originId}
                  destinationId={destinationId}
                  defaultSelectedIndex={0}
                  tabType={tabType}
                  fromCity={fromCity}
                  toCity={toCity}
                  labels={labels}
                  isMobile
                />
              </div>
              <div className={bem('actions')}>
                <Button
                  label={i18n.continueLabel}
                  type="button"
                  color="yellow"
                  width="full"
                  onClick={firstActionOnClick}
                />
              </div>
            </>
          )}

          {isPanelVisible && (
            <Modal title={title} onClose={doHandleModalClose(setValues, initialFormValues)}>
              <div className={bem('content')}>
                {currentStep === 0 && (
                  <OriginAndDestinationModifyTrip
                    {...stationsSelection}
                    stations={stations}
                    defaultSelectedIndex={1}
                    setCurrentStep={setCurrentStep}
                    originId={originId}
                    destinationId={destinationId}
                    tabType={tabType}
                    fromCity={fromCity}
                    toCity={toCity}
                    labels={labels}
                    isMobile
                  />
                )}
                {currentStep < 3 && (
                  <>
                    {renderOriginDestinationEdit()}
                    <DateSelection
                      i18n={datesSelection.i18n}
                      datesRestrictions={datesRestrictions}
                      toggleItems={tripSelection.toggleItems}
                      start_date={departureTime}
                      end_date={returnTime}
                      tabType={tabType}
                      isRoundTrip={isRoundTrip}
                      labels={labels}
                      isFirstLegTraveled={isFirstLegTraveled}
                      isSecondLegTraveled={isSecondLegTraveled}
                      isMobile
                    />
                  </>
                )}
              </div>
              {touched && isValid && !(tabType === 'departure' && isFirstLegTraveled) && (
                <div className={bem('actions')}>
                  <Button
                    label={labels.searchTicketsButtonLabel}
                    type="submit"
                    color="yellow"
                    width="full"
                    onClick={submitForm}
                  />
                </div>
              )}
            </Modal>
          )}
        </form>
      )}
    </Formik>
  );
});
