import React from 'react';

import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';

import './CardActions.scss';
import { CardActionProps } from './types';

const bem = cn('card-actions');

export const CardActions = (props: CardActionProps) => {
  const { onEdit, onSave, showEdit, editLabel, saveLabel = '', autoFocusSecondary, autoFocusPrimary } = props;

  return (
    <div className={bem()}>
      {showEdit && (
        <Button
          autoFocus={autoFocusSecondary}
          variant="secondary"
          onClick={onEdit}
          color="blue"
          size="medium"
          label={editLabel || ''}
          className={bem('action')}
        />
      )}
      {saveLabel !== '' && (
        <Button
          autoFocus={autoFocusPrimary}
          variant="primary"
          onClick={onSave}
          color="blue"
          size="medium"
          label={saveLabel}
          className={bem('action')}
        />
      )}
    </div>
  );
};
