import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { recreateNode } from 'utils/recreateNode';

import { CLASSES, SELECTORS } from './constants';
import { AccordionHandlerProps } from './types';

const AccordionHandler = ({ el, collapseAllText, expandAllText }: AccordionHandlerProps) => {
  const [allExpanded, setAllExpanded] = useState(false);
  const allExpandedRef = useRef(allExpanded);

  const expandElement = useMemo(() => el.querySelector(SELECTORS.expand), [el]);

  const itemGroups = useMemo(() => {
    const items = Array.from(el.querySelectorAll(SELECTORS.item) || []);

    return items.map((item: HTMLDivElement) => ({
      title: item.querySelector(SELECTORS.title),
      content: item.querySelector(SELECTORS.content),
      icon: item.querySelector(SELECTORS.icon),
      id: item.id,
    }));
  }, [el]);

  const expandAccordionItem = useCallback(
    group => {
      if (!group) return;
      group.content.style.display = 'block';
      group.content.style.maxHeight = '';

      group.content.classList.add(CLASSES.autoHeight);
      const testHeight = `${group.content.clientHeight + 8}px`;
      group.content.classList.remove(CLASSES.autoHeight);
      group.content.classList.add(CLASSES.contentExpanded);
      group.content.setAttribute('tabIndex', '-1');
      group.title.setAttribute('aria-expanded', 'true');
      group.title.setAttribute('tabIndex', '-1');

      group.content.style.maxHeight = testHeight;
      group.icon.classList.replace(CLASSES.defaultIcon, CLASSES.iconExpanded);
      group.content.focus();

      const selectedItems = el.querySelectorAll(SELECTORS.contentExpanded);

      if (selectedItems.length === itemGroups.length) {
        const expandElement = el.querySelector(SELECTORS.expand);
        if (expandElement) expandElement.innerHTML = collapseAllText;
        setAllExpanded(val => !val);
      }
    },
    [collapseAllText, el, itemGroups.length]
  );

  const collapseAccordionItem = useCallback(
    group => {
      if (!group) return;
      group.content.classList.remove(CLASSES.contentExpanded);
      group.content.style.maxHeight = 0;
      group.content.setAttribute('tabIndex', '0');
      group.title.setAttribute('aria-expanded', 'false');
      group.title.setAttribute('tabIndex', '0');

      group.icon.classList.replace(CLASSES.iconExpanded, CLASSES.defaultIcon);

      const transitionEndHandler = () => {
        group.content.style.display = 'none';
        group.content.removeEventListener('transitionend', transitionEndHandler);
      };
      group.content.addEventListener('transitionend', transitionEndHandler);

      if (expandElement && expandElement.innerHTML !== expandAllText) {
        expandElement.innerHTML = expandAllText;
        setAllExpanded(false);
      }
    },
    [expandAllText, expandElement]
  );

  const toggleAccordionItem = useCallback(
    group => {
      if (!group) return;
      if (group.content.classList.contains(CLASSES.contentExpanded)) collapseAccordionItem(group);
      else expandAccordionItem(group);
    },
    [collapseAccordionItem, expandAccordionItem]
  );

  const expandItemByHash = useCallback(
    itemGroups => {
      const { hash } = window.location;
      const id = hash?.split('#')[1];
      if (hash) expandAccordionItem(itemGroups.find(el => el.id === id));
    },
    [expandAccordionItem]
  );

  const toggleAllItems = useCallback(
    itemGroups => {
      const expandElement = el.querySelector(SELECTORS.expand);
      if (allExpandedRef.current) {
        itemGroups.forEach(collapseAccordionItem);
        if (expandElement) {
          expandElement.innerHTML = expandAllText;
          expandElement.setAttribute('aria-expanded', 'false');
        }
        setAllExpanded(false);
      } else {
        itemGroups.forEach(expandAccordionItem);
        if (expandElement) {
          expandElement.innerHTML = collapseAllText;
          expandElement.setAttribute('aria-expanded', 'true');
        }
        setAllExpanded(true);
      }
    },
    [collapseAccordionItem, collapseAllText, el, expandAccordionItem, expandAllText]
  );

  const handleClickExpandAll = useCallback(() => {
    toggleAllItems(itemGroups);
  }, [itemGroups, toggleAllItems]);

  const handleKeydownExpandAll = useCallback(
    e => e.key === 'Enter' && toggleAllItems(expandElement),
    [expandElement, toggleAllItems]
  );

  const handleGroupClick = useCallback(
    group => ev => {
      ev.preventDefault();

      return toggleAccordionItem(group);
    },
    [toggleAccordionItem]
  );

  const handleGroupKeyDown = useCallback(
    group => e => e.key === 'Enter' && toggleAccordionItem(group),
    [toggleAccordionItem]
  );

  useEffect(() => {
    // by default the useState doesn't work properly with native events;
    // therefore we need to use a Ref in order to bypass these restrictions
    allExpandedRef.current = allExpanded;
  }, [allExpanded]);

  useEffect(() => {
    itemGroups.forEach(group => {
      group.title?.addEventListener('click', handleGroupClick(group));
      group.title?.addEventListener('keyDown', handleGroupKeyDown(group));
    });

    expandElement?.addEventListener('click', handleClickExpandAll);
    expandElement?.addEventListener('keyDown', handleKeydownExpandAll);

    expandItemByHash(itemGroups);

    // before unmount
    return () => {
      itemGroups.forEach(group => recreateNode(group.title));
      recreateNode(expandElement);
    };
  }, [
    expandElement,
    expandItemByHash,
    handleClickExpandAll,
    handleGroupClick,
    handleGroupKeyDown,
    handleKeydownExpandAll,
    itemGroups,
  ]);

  return null;
};

export default AccordionHandler;
