import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { Text } from '@atoms/Text';
import { GiftCard } from '@booking/routes/RTI/PaymentInfo/components/PaymentInfoEntry/components/GiftCard/GiftCard';
import { Rewards } from '@booking/routes/RTI/PaymentInfo/components/PaymentInfoEntry/components/Rewards/Rewards';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { isGiftCardEnabled, isLoyaltyEnabled } from 'utils/metas';

import { CostSummary } from '../CostSummary';
import './ReviewAndPay.scss';
import { ErrorBanner } from './components/ErrorBanner/ErrorBanner';
import { Footer } from './components/Footer';
import { SavedCards } from './components/PaymentCardSelector';
import { PaymentForm } from './components/PaymentForm';

const bem = cn('passes-payment');

export const ReviewAndPay = observer(() => {
  const { cfStore, passesStore, authStore } = useStores();
  const { isAuthenticated: isWebAuthenticated } = authStore;
  const { urlParams, selectedCard, checkoutCartError } = passesStore.checkout;
  const { costSummary, showPaymentForm, setShowPaymentForm } = passesStore.checkout;
  const { savedCards } = passesStore.checkout;

  const gftCardEnabled = isGiftCardEnabled();

  const cfLabels = cfStore.passes.reviewAndPay;

  const isMobileAuthenticated = urlParams.mobileData.jwt;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const isAuthenticated = useMemo(
    () => isWebAuthenticated || isMobileAuthenticated,
    [isWebAuthenticated, isMobileAuthenticated]
  );

  const noAmountToPay = useMemo(() => costSummary?.bookingTotal?.totalToBePaid === 0, [costSummary]);

  if (passesStore.checkout.urlParams.autoRenews)
    setShowPaymentForm(selectedCard?.tokenId === 'new-card' && savedCards.length < 5 && !noAmountToPay);
  else setShowPaymentForm(selectedCard?.tokenId === 'new-card' && !noAmountToPay);

  return (
    <div className={bem()}>
      <Text text={cfLabels.reviewAndPayTitle} className={bem('title')} />
      {checkoutCartError && <ErrorBanner />}
      {gftCardEnabled && <GiftCard />}
      {isLoyaltyEnabled() && <Rewards />}
      <SavedCards noAmountToPay={noAmountToPay} />
      {selectedCard?.tokenId === 'new-card' && <div className={bem('separator')} />}
      {showPaymentForm && <PaymentForm />}
      {isMobile && (!isAuthenticated || selectedCard?.tokenId !== 'new-card') && <CostSummary />}
      {(selectedCard?.tokenId !== 'new-card' || noAmountToPay) && <div className={bem('separator')} />}
      {(selectedCard?.tokenId !== 'new-card' || noAmountToPay) && <Footer />}
    </div>
  );
});
