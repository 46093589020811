import React, { useMemo } from 'react';

import Divider from '@atoms/Divider/Divider';
import ImageButton from '@atoms/ImageButton/ImageButton';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';
import LinkButtonList from '@molecules/LinkButtonList/LinkButtonList';
import { FooterProps } from '@organisms/Footer/types';

import { cn } from 'utils/classNames';

import './Footer.scss';
import { ZendeskButton } from './ZendeskButton';

const bem = cn('footer');

const Footer = (props: FooterProps) => {
  const {
    topLinks = [],
    socialMediaItems,
    bottomLinks,
    copyrightText,
    zendeskKey,
    zendeskText,
    zendeskDesktopVisibility,
    zendeskMobileVisibility,
  } = props;
  const { appsTitle, appsDescription, appButtons, colors, showFirstRow = true } = props;

  const vars: any = useMemo(
    () =>
      colors
        ? {
            '--background-color': colors?.background?.inactive,
            '--text-color': colors?.text?.inactive,
            '--text-hover-color': colors?.text?.hover,
            '--text-focus-color': colors?.text?.focus,
            '--border-color': colors?.border?.inactive,
            '--border-focus-color': colors?.border?.focus,
            '--border-hover-color': colors?.border?.hover,
            '--divider-color': colors?.divider?.inactive,
            '--icon-color': colors?.icon?.inactive,
            '--icon-hover-color': colors?.icon?.hover,
            '--icon-focus-color': colors?.icon?.focus,
          }
        : {},
    [colors]
  );

  return (
    <div className={bem()} style={vars}>
      <div className={bem('content')}>
        {showFirstRow && (
          <div className={bem('first-row')}>
            <div className={bem('first-row-left')}>
              {topLinks.map((el, index) => {
                const key = `${el?.title}-${index}`;

                return (
                  <div key={key} className={`${bem('first-row-left-column')} hide-bullets-accessibility`}>
                    <LinkButtonList {...el} items={el?.items} />
                  </div>
                );
              })}
            </div>

            <Divider alignment="horizontal" />
            <div className={`${bem('first-row-right')} hide-bullets-accessibility`}>
              {socialMediaItems && (
                <ul className={bem('social-media')}>
                  {socialMediaItems.map((item, index) => {
                    const key = `${item?.socialMediaType}-${index}`;

                    return (
                      <li key={key} className={bem('social-media-item')}>
                        <ImageButton {...item} />
                      </li>
                    );
                  })}
                </ul>
              )}
              {appButtons && (
                <div className={bem('application')}>
                  <span className={bem('application-title')}>
                    <Title size="h3" text={appsTitle} />
                  </span>

                  <Text as="span" text={appsDescription} className={bem('application-description')} />
                  <div className={bem('application-buttons')}>
                    {appButtons.map(el => (
                      <ImageButton key={el?.imageAlt} {...el} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {showFirstRow && <Divider alignment="horizontal" />}
        <div className={bem('second-row')}>
          <Text as="span" text={copyrightText} className={bem('copyright')} />
          <div className={`${bem('bottom-links')} hide-bullets-accessibility`}>
            <LinkButtonList {...bottomLinks} />
          </div>
        </div>
      </div>
      {zendeskKey && (
        <ZendeskButton
          zendeskText={zendeskText}
          zendeskDesktopVisibility={zendeskDesktopVisibility}
          zendeskMobileVisibility={zendeskMobileVisibility}
        />
      )}
    </div>
  );
};

export default Footer;
