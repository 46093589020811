import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';

import { Card } from '../Card';
import { stepNames } from '../constants';
import { SeatSelectionModal } from './components/SeatSelectionModal';
import { TripContent } from './components/TripContent';
import { SeatSelectionProps } from './types';

export const SeatSelection = observer((props: SeatSelectionProps) => {
  const { stepNr, mode } = props;

  const { bookingStore, cfStore } = useStores();

  const { setActiveStep, setLockedStep, activeStep, provisionalBooking } = bookingStore.rti;
  const { isOutboundSeatingAvailable, isInboundSeatingAvailable } = bookingStore.rti.seats;

  const showCardEditButton = isOutboundSeatingAvailable || isInboundSeatingAvailable;
  const [showSeatSelectionModal, setShowSeatSelectionModal] = useState(false);

  const handleEdit = () => {
    setLockedStep(activeStep, true);

    setLockedStep(stepNames.seatAssignment, false);
    setActiveStep(stepNames.seatAssignment);
    setShowSeatSelectionModal(true);
  };

  const handleCloseModal = () => {
    setShowSeatSelectionModal(false);
  };

  const { seats } = cfStore.rti;

  return (
    <>
      <Card
        title={seats?.cardTitle}
        stepNr={stepNr}
        mode={mode}
        editBtnLabel={seats?.editBtnLabel}
        onEdit={handleEdit}
        hideEditBtn={!showCardEditButton}
      >
        <>
          {!!provisionalBooking?.tripDetails?.passengerSeatingDetails?.length && (
            <TripContent
              {...seats}
              mode={mode}
              passengerSeatingDetails={provisionalBooking?.tripDetails?.passengerSeatingDetails}
              trip={provisionalBooking?.tripDetails?.trip}
            />
          )}
          {showSeatSelectionModal && <SeatSelectionModal handleCloseModal={handleCloseModal} />}
        </>
      </Card>
    </>
  );
});
