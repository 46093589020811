import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { CardTitle } from '../CardTitle';
import { TripDetailsRoute } from './TripDetailsRoute';
import './Trips.scss';
import { TripsPlaceholders } from './TripsPlacehoders';

const bem = cn('trip-details-trips');

export const Trips = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { tripDetails } = accountStore;
  const { isLoading } = tripDetails;

  if (isLoading || !tripDetails)
    return (
      <div className={bem()}>
        <TripsPlaceholders />
      </div>
    );

  const { trip } = tripDetails;

  if (!trip) return null;

  const { outboundRoute, inboundRoute } = trip;

  return (
    <div className={bem()}>
      <CardTitle title={cfStore.account.tripDetails.tripsLabel} />
      <div className={bem('details')}>
        {outboundRoute && <TripDetailsRoute route={outboundRoute} />}
        {inboundRoute && <TripDetailsRoute route={inboundRoute} />}
      </div>
    </div>
  );
});
