import React from 'react';
import InputMask from 'react-input-mask';

import { Input } from '@atoms/Input';

import { useInputLogic } from 'utils/useInputLogic';

import { dateFormatDay, defaultInputName } from './constants';
import { InlineDateFieldInputProps } from './types';

export const InlineDateInputField = (props: InlineDateFieldInputProps) => {
  const {
    type = 'text',
    errorVariant,
    name,
    dateFormat = dateFormatDay,
    helperText = '',
    mask = '99/99/9999',
    maxCharacters = 10,
    displayTrailingIcon = true,
    ...rest
  } = props;

  const nullCheckName = name || defaultInputName;

  const { handleClickTrailingIcon, field, isInvalid, error } = useInputLogic({
    ...props,
    name: nullCheckName,
  });

  return (
    <InputMask
      {...field}
      {...rest}
      name={nullCheckName}
      maxCharacters={maxCharacters}
      placeholder={dateFormat}
      onClickTrailingIcon={handleClickTrailingIcon}
      helperText={helperText}
      type={type}
      mask={mask}
      maskPlaceholder={null}
      errorMessage={error}
      displayTrailingIcon={displayTrailingIcon}
    >
      <Input
        {...field}
        {...rest}
        errorVariant={errorVariant}
        name={nullCheckName}
        error={isInvalid}
        errorMessage={error}
        type={type}
        helperText={helperText}
        maxCharacters={maxCharacters}
        placeholder={dateFormat}
        onClickTrailingIcon={handleClickTrailingIcon}
        displayTrailingIcon={displayTrailingIcon}
      />
    </InputMask>
  );
};
