import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

const bem = cn('manage-trips-form-container');

const ManageAction = observer(({ i18n, deprecatedManageTripsPage }: any) => {
  const { authStore } = useStores();
  const { isAuthenticated } = authStore;
  const deprecatedUserId = localStorage.getItem('bl-user');
  const validation = deprecatedManageTripsPage ? deprecatedUserId : isAuthenticated;

  return <Text text={validation ? i18n.goToAccountLabel : i18n.createAccountLabel} className={bem('description')} />;
});

export default ManageAction;
