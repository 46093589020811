import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import './CostSummary.scss';

const bem = cn('cost-summary-detail-item');

export const CostDetailPerTripParking = observer(() => {
  const { cfStore, bookingStore } = useStores();

  const { rti, generic, account: { tripDetails} } = cfStore;
  const { parking } = bookingStore;
  const { extrasTypes, nightLabel } = tripDetails;
  const { provisionalBooking, modifyTripType } = bookingStore.rti;
  const { data } = parking;

  const costSummary = provisionalBooking?.tripDetails?.costSummary;

  const parkingCF = rti.extras.typeLabels.length !== 0 ?  rti.extras.typeLabels : extrasTypes;

  const cfProduct = useMemo(() => parkingCF.find(item => item.type.endsWith('parking')), [parkingCF]);

  const parkingConfig = useMemo(() => {
    const costSummaryItem = costSummary?.items?.find(item => item.productCode === 'PARKING');

    if (
      !data ||
      !costSummaryItem ||
      (modifyTripType && costSummaryItem.totalPrice !== costSummaryItem.totalOriginalPrice)
    )
      return '';

    const eligibleDriver = data.eligibleDrivers.find(driver => driver.currentSelections);

    if (!eligibleDriver || !eligibleDriver.currentSelections) return '';

    const startDate = moment.utc(eligibleDriver.currentSelections.startDate);
    const endDate = moment.utc(eligibleDriver.currentSelections.endDate);

    const daysDifference = endDate.diff(startDate, 'days', false) + 1;

    const nightsLabel = daysDifference > 1 ? generic.nightsLabel || tripDetails.nightsLabel : generic.nightLabel || nightLabel;

    return {
      productName: `${daysDifference} ${nightsLabel}`,
      price: `$${(modifyTripType ? costSummaryItem.totalPrice : costSummaryItem.totalOriginalPrice).toFixed(2)}`,
    };
  }, [data, costSummary, generic, modifyTripType, tripDetails, nightLabel]);

  if (!parkingConfig) return null;

  return (
    <div>
      <div className={bem()}>
        <div>
          <span>{cfProduct?.label}&nbsp;</span>
          <span>{parkingConfig.productName}</span>
        </div>
        <span>{parkingConfig.price}</span>
      </div>
    </div>
  );
});
