import React from 'react';

import XFContainerHandler from '@organisms/XFContainer/XFContainerHandler';

import { cn } from 'utils/classNames';
import { isDelayExperienceEnabled } from 'utils/metas';

import { PastTripsListPlaceholder } from './components/PastTripsList/PastTripsListPlaceholder';
import { UpcomingTripsPlaceholder } from './components/UpcomingTrips/UpcomingTripsPlaceholder';

const bem = cn('my-trips');

export const MyTripsPlaceholders = () => (
  <div className={bem('placeholders')}>
    <div />
    {/* Calculate header height without delay experience banners in account  */}
    {isDelayExperienceEnabled() && <XFContainerHandler scope="header" />}
    <UpcomingTripsPlaceholder />
    <PastTripsListPlaceholder variant="short" />
  </div>
);
