import { makeAutoObservable } from 'mobx';
import { RootStore } from 'stores';

import { TableNode } from '@table-library/react-table-library';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';

export class CommissionsStore {
  private readonly rootStore: RootStore;

  commissionsData: TableNode[] = [];
  commissionsDataCSV = '';

  isLoading = false;
  hasError = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  clearCommisionsData() {
    this.commissionsData = [];
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setError(hasError) {
    this.hasError = hasError;
  }

  setCommissionsData(commissionsData) {
    this.commissionsData = commissionsData;
  }

  async fetchCommissionsData(format?: string) {
    try {
      this.setLoading(true);
      let url = requestUrls.getBffUrl(requestUrls.account.commissionsData);
      if (format) url += `?format=${format}`;

      const response = await api.get(url);

      if (format === 'csv') return response.data;

      const commissionsDataResponse = response.data.commissions || {};

      this.commissionsData = [
        ...(commissionsDataResponse?.map((item, idx) => ({
          id: idx,
          name: item?.booking?.clientName,
          bookDate: item?.booking?.bookingDate,
          confirmation: item?.booking?.bookingNumber,
          travelDate: item?.booking?.travelDate,
          total: item?.booking?.total,
          commission: item?.commission?.total,
        })) || []),
      ];

      this.setLoading(false);

      return null;
    } catch (e: any) {
      console.error('Failed to fetch travel agent commissions data: ', e);
      this.setCommissionsData([]);
      this.setLoading(false);

      return null;
    }
  }
}
