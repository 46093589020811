import moment from 'moment';
import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { rootStore, useStores } from 'stores';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { isBrandedFaresV2Enabled } from 'utils/metas';

export const useCancelTripLogic = () => {
  const {
    accountStore: { tripDetails },
    cfStore: { account },
  } = useStores();

  const { title, text, loyaltyText, confirmButtonLabel, viewUpcomingTripsButtonLabel, tripCancelledTitle } =
    account.tripDetails.navigation.cancelTrip;
  const { tripCancelledMessage, cancelButtonLabel, serverErrorLabel } = account.tripDetails.navigation.cancelTrip;
  const {
    setShowConfirmationModal,
    setSetShowCancellationModal,
    cancelTrip,
    trip,
    revertTripProvisionalChanges,
    tripWay,
    partialyCanceled,
    setPartialyCanceled,
  } = tripDetails;

  const brandedFaresV2Switch = isBrandedFaresV2Enabled();

  const [cancelationConfirmed, setCancelationConfirmed] = useState(trip?.isCancelled || false);

  const [isCancelling, setIsCancelling] = useState(
    trip?.outboundRoute?.isCancelled || trip?.inboundRoute?.isCancelled || false
  );

  useEffect(() => {
    setIsCancelling(trip?.outboundRoute?.isCancelled || trip?.inboundRoute?.isCancelled || false);
  }, [trip]);

  const navigate = useNavigate();

  const origin = useMemo(
    () =>
      tripWay === 'return'
        ? tripDetails?.trip?.inboundRoute?.origin?.city
        : tripDetails?.trip?.outboundRoute?.origin?.city,
    [tripDetails?.trip?.inboundRoute, tripWay, tripDetails?.trip?.outboundRoute]
  );
  const destination = useMemo(
    () =>
      tripWay === 'return'
        ? tripDetails?.trip?.outboundRoute?.origin?.city
        : tripDetails?.trip?.outboundRoute?.destination?.city,
    [tripWay, tripDetails?.trip?.outboundRoute]
  );
  const email = useMemo(() => tripDetails?.contact?.email || '', [tripDetails?.contact]);

  const isGroupTrip = useMemo(() => {
    const filteredPassengers = tripDetails?.passengerSeatingDetails?.filter(
      value => value?.passenger?.type !== 'infant'
    );
    const numPassengers = filteredPassengers?.length || 0;

    return numPassengers >= 30;
  }, [tripDetails]);

  const isTripCancellable = useMemo(
    () =>
      (tripDetails?.trip?.inboundRoute?.departureDateTime &&
        moment().isBefore(tripDetails?.trip?.inboundRoute?.departureDateTime)) ||
      (tripDetails?.trip?.outboundRoute?.departureDateTime &&
        moment().isBefore(tripDetails?.trip?.outboundRoute?.departureDateTime)),
    [tripDetails]
  );

  const confirmCancellation = () => {
    setSetShowCancellationModal(false);
    cancelTrip(trip?.referenceNumber, false, tripWay);
    trip?.outboundRoute?.isCancelled || trip?.inboundRoute?.isCancelled ? setPartialyCanceled(true) : null;
  };

  const onCancel = () => {
    setShowConfirmationModal(false);
  };

  const onViewUpcomingTrips = () => {
    setShowConfirmationModal(false);
    setSetShowCancellationModal(false);
    setCancelationConfirmed(false);
    setIsCancelling(false);
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: account?.tripDetails?.navigation?.cancelTrip?.confirmButtonLabel,
        customLink: '/my-trips',
        externalLink: '',
        linkType: 'linkClick',
      },
    });
    navigate('/my-trips');
    setPartialyCanceled(false);
  };

  const onRevert = () => {
    if (!trip) return;

    setSetShowCancellationModal(false);
    revertTripProvisionalChanges(trip?.referenceNumber);
  };

  const confirmationModalContent =
    isCancelling && !partialyCanceled
      ? {
          title: title,
          text: text,
          loyaltyText: loyaltyText,
          isCancellationConfirmed: !isCancelling,
          buttons: [
            {
              label: cancelButtonLabel,
              onClick: onCancel,
            },
            {
              label: confirmButtonLabel,
              onClick: confirmCancellation,
            },
          ],
        }
      : (partialyCanceled || cancelationConfirmed) && {
          title: tripCancelledTitle
            .replace('<originStation>', `${origin}`)
            .replace('<destinationStation>', `${destination}`),
          text: tripCancelledMessage.replace('<customerEmail>', email),
          isCancellationConfirmed: cancelationConfirmed || partialyCanceled,
          buttons: [
            {
              label: viewUpcomingTripsButtonLabel,
              onClick: onViewUpcomingTrips,
            },
          ],
        };

  const errorModalContent = {
    title: serverErrorLabel,
    buttons: [
      {
        label: cancelButtonLabel,
        onClick: onCancel,
      },
    ],
  };

  return {
    confirmationModalContent,
    isGroupTrip,
    isTripCancellable,
    errorModalContent,
    origin,
    destination,
    onRevert,
    brandedFaresV2Switch,
  };
};
