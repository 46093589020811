import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useSearchParams } from 'react-router-dom';
import { useStores } from 'stores';

import { Column, Grid } from '@atoms/Grid';

import { cn } from 'utils/classNames';
import { setLoginRedirectURL } from 'utils/localstorage';
import { mediaQueryList } from 'utils/mediaQueries';

import './Checkout.scss';
import { CheckoutPlaceholder } from './CheckoutPlaceholder';
import { CostSummary } from './components/CostSummary';
import { LoginPrompt } from './components/LoginPrompt';
import { MobileFooter } from './components/MobileFooter';
import { PassesReview } from './components/PassesReview';
import { ReviewAndPay } from './components/ReviewAndPay';

const bem = cn('passes-checkout');

export const Checkout = observer(() => {
  const [searchParams] = useSearchParams();
  const isMaxTablet = useMediaPredicate(mediaQueryList.maxTablet);

  const { passesStore, authStore } = useStores();
  const { urlParams, parseUrlParams, createCart, loadingCreateCart, createCartError, updateLoyaltyDisplayStatus } =
    passesStore.checkout;
  const { isAuthenticated: isWebAuthenticated } = authStore;

  const isMobileAuthenticated = urlParams.mobileData.jwt;

  const isAuthenticated = useMemo(
    () => isWebAuthenticated || isMobileAuthenticated,
    [isWebAuthenticated, isMobileAuthenticated]
  );

  useEffect(() => {
    const data = searchParams.get('data');
    if (!data) throw 'Invalid URL params';

    parseUrlParams(data);
    if (isAuthenticated) createCart();
    setLoginRedirectURL(window.location.href);
  }, [isAuthenticated, searchParams, createCart, parseUrlParams]);

  useEffect(() => {
    if (!createCartError) return;
    throw 'Create cart error';
  }, [createCartError]);

  useEffect(() => {
    updateLoyaltyDisplayStatus();
  }, [updateLoyaltyDisplayStatus]);

  return (
    <ReactPlaceholder ready={!loadingCreateCart} customPlaceholder={<CheckoutPlaceholder />} showLoadingAnimation>
      <div className={bem()}>
        {isMaxTablet ? (
          <div className={bem('mobile')}>
            <PassesReview />
            {isAuthenticated && <ReviewAndPay />}
            {!isAuthenticated && <CostSummary />}
            {!isAuthenticated && <LoginPrompt />}
            <MobileFooter />
          </div>
        ) : (
          <Grid>
            <Column cols={8} customClassName={bem('left-column')}>
              <div className={bem('wrapper')}>
                <PassesReview />
                {!isAuthenticated && <LoginPrompt />}
                {isAuthenticated && <ReviewAndPay />}
              </div>
            </Column>
            <Column cols={4} customClassName={bem('right-column')}>
              <div className={bem('wrapper')}>
                <CostSummary />
              </div>
            </Column>
          </Grid>
        )}
      </div>
    </ReactPlaceholder>
  );
});
