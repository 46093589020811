import { Formik } from 'formik';
import React, { useMemo } from 'react';

import { cn } from 'utils/classNames';
import { color_white } from 'utils/colors';

import { setWifiData } from '../../../utils/localstorage';
import './WifiSplashForm.scss';
import { WifiSplashFormFooter } from './components/WifiSplashFormFooter';
import { WifiSplashFormFormik } from './components/WifiSplashFormFormik';
import { WifiSplashFormHeader } from './components/WifiSplashFormHeader';
import { WifiSplashFormProps } from './types';
import { useValidationSchema } from './useValidationSchema';

const bem = cn('wifi-splash-form');

const WifiSplashForm = (props: WifiSplashFormProps) => {
  const { colors, cta } = props;

  const validationSchema = useValidationSchema(props);

  const formikInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    postalCode: '',
  };

  const colorsStyle = useMemo(
    () => ({
      backgroundColor: colors?.background?.inactive || color_white,
    }),
    [colors]
  );

  const handleSubmit = async values => {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // Simulate form submission
    await delay(500);

    setWifiData({
      submitTime: Date.now(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      email: values.email,
      postalCode: values.postalCode,
    });

    if (cta.link?.url) window.location.href = cta.link.url;
  };

  return (
    <div className={bem()} style={colorsStyle}>
      <div className={bem('wrapper')}>
        <WifiSplashFormHeader {...props} />
        <Formik initialValues={formikInitialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          <WifiSplashFormFormik {...props} />
        </Formik>
        <WifiSplashFormFooter {...props} />
      </div>
    </div>
  );
};

export default WifiSplashForm;
