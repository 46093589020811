import React from 'react';

import { NavItem } from '@molecules/NavItem';

import { cn } from 'utils/classNames';

const bemGlobal = cn('global-header');
const Language = props => {
  const { subitems = [], isResponsive } = props;

  const languageItem = {
    ...props,
    subItems: subitems,
    displayTrailingIcon: true,
  };

  return (
    <NavItem
      size="small"
      displayTrailingIcon
      {...languageItem}
      customClassName={bemGlobal('language')}
      isResponsive={isResponsive}
    />
  );
};

export default Language;
