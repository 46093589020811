import React from 'react';

import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';

import './EmptyPassMobile.scss';
import { EmptyPassProps } from './types';

const bem = cn('empty-pass-mobile');

export const EmptyPassMobile = (props: EmptyPassProps) => {
  const { description, buttonLabel, buttonLink } = props;

  return (
    <div className={bem()}>
      <div className={bem('description')}>{description}</div>
      <div className={bem('actions')}>
        <Button label={buttonLabel} variant="primary" color="yellow" size="small" link={{ url: buttonLink }} />
      </div>
    </div>
  );
};
