import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { CardTitle } from '../CardTitle';
import './Passengers.scss';
import { PassengersDetails } from './PassengersDetails';
import { PassengersPlaceholders } from './PassengersPlacehoders';

const bem = cn('trip-details-passengers');

export const Passengers = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { tripDetails } = accountStore;
  const { isLoading } = tripDetails;

  const { passengerSeatingDetails } = tripDetails;

  return (
    <div className={bem()}>
      {(isLoading || !tripDetails) && <PassengersPlaceholders />}
      {!isLoading && tripDetails && (
        <>
          <CardTitle title={cfStore.account.tripDetails.passengersLabel} />
          <div className={bem('items')}>
            {passengerSeatingDetails?.map(passenger => (
              <PassengersDetails key={passenger.passengerId} passengerDetails={passenger} />
            ))}
          </div>
        </>
      )}
    </div>
  );
});
