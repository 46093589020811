import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { OverlayContainer } from '@account/components/OverlayContainer';
import { EditDetailsOverlayProps } from '@account/routes/Settings/components/EditDetailsOverlay/types';

import { EditDetailsForm } from '../EditDetailsForm';

export const EditDetailsOverlay = observer((props: EditDetailsOverlayProps) => {
  const { modalOnCloseRef } = props;

  const { accountStore, cfStore } = useStores();
  const { settings: i18n } = cfStore.account;
  const { isEditDialogOpen, setEditDialogClose } = accountStore.profile;

  const onCloseEditDialog = () => {
    if (isEditDialogOpen) modalOnCloseRef.current.focus();

    setEditDialogClose();
  };

  return (
    <OverlayContainer title={i18n.editProfileDetailsTitle} isVisible={isEditDialogOpen} onCancel={onCloseEditDialog}>
      <EditDetailsForm onCancel={onCloseEditDialog} />
    </OverlayContainer>
  );
});
