import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import { SavedCardItemPlaceholder } from './SavedCardItem/SavedCardItemPlaceholder';
import './SavedCards.scss';

const bem = cn('passes-saved-cards');

export const SavedCardsPlaceholder = observer(() => (
  <ReactPlaceholder
    ready={false}
    customPlaceholder={
      <div className={bem()}>
        <RectShape color={color_base_medium_light_grey} style={{ width: 70, height: 24, borderRadius: 8 }} />
        <div className={bem('cards')}>
          <SavedCardItemPlaceholder />
          <SavedCardItemPlaceholder />
          <SavedCardItemPlaceholder />
        </div>
      </div>
    }
    showLoadingAnimation
  >
    <div />
  </ReactPlaceholder>
));
