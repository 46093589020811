import moment from 'moment/moment';
import { startTransition, useMemo, useRef, useState } from 'react';

import { PrivacyRequestFormProps } from '@organisms/PrivacyRequestForm/types';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';

import { useStores } from '../../../stores';

export const usePrivacyRequestFormLogic = (props: PrivacyRequestFormProps) => {
  const { requestTypeDropdownOptions, submitSuccessMessage, loyaltyOptOutSuccessMessage } = props;

  const { authStore, accountStore } = useStores();

  const { deleteLoyaltyProgram, displayLoyaltyComponents } = accountStore.profile;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [submitFormStatus, setSubmitFormStatus] = useState('');
  const [currentStep, setCurrentStep] = useState(1);

  const [submitMessage, setSubmitMessage] = useState('');

  const openModalButtonRef = useRef<any>(null);

  const optionsData = useMemo(
    () =>
      requestTypeDropdownOptions
        .map((option, index) => {
          if (!displayLoyaltyComponents && index === 3) return null;

          return {
            label: option.requestTypeDropdownOption,
            value: option.requestTypeDropdownOption,
          };
        })
        .filter(Boolean),
    [requestTypeDropdownOptions, displayLoyaltyComponents]
  );

  const initialValues = {
    firstName: '',
    lastName: '',
    dob: '',
    email: authStore?.user?.email || '',
    phoneNumber: {
      countryCode: '+1',
      number: '',
    },
    requestType: '',
  };

  const openModal = () => {
    startTransition(() => setIsModalOpen(true));
  };

  const closeModal = () => {
    setIsModalOpen(false);
    openModalButtonRef.current?.focus();
  };

  const submitForm = async formValues => {
    if (currentStep === 1) {
      const reqBody = {
        firstName: formValues?.firstName,
        lastName: formValues?.lastName,
        dateOfBirth: moment(formValues?.dob, 'DD/MM/YYYY').utc().toISOString(),
        phoneNumber: { ...formValues?.phoneNumber },
        accountEmailAddress: formValues?.email,
        requestType: formValues?.requestType,
      };

      try {
        setSubmitFormStatus('');
        setIsFormSubmitting(true);

        if (displayLoyaltyComponents && optionsData?.[3]?.label && reqBody.requestType === optionsData[3].label) {
          await deleteLoyaltyProgram('brightline');
          setSubmitMessage(loyaltyOptOutSuccessMessage ?? '');
        } else {
          await api.post(`${requestUrls.getRestUrl(requestUrls.userProfile)}.xjson`, reqBody);
          setSubmitMessage(submitSuccessMessage);
        }

        setSubmitFormStatus('success');
        setCurrentStep(2);
      } catch (e) {
        console.error(e);
        setSubmitFormStatus('error');
      }

      setIsFormSubmitting(false);
    }
  };

  const resetForm = () => {
    setSubmitFormStatus('');
    setCurrentStep(1);
    openModal();
  };

  return {
    isModalOpen,
    openModal,
    closeModal,
    initialValues,
    isFormSubmitting,
    submitFormStatus,
    optionsData,
    submitForm,
    openModalButtonRef,
    resetForm,
    currentStep,
    submitMessage,
  };
};
