import React from 'react';

import { Icon } from '@atoms/Icon';
import FeatureItem from '@molecules/FeatureItem/v1/FeatureItem';
import { FeatureItemProps } from '@molecules/FeatureItem/v1/types';
import useDesktopCarousel from '@organisms/FeaturesGrid/v1/useDesktopCarousel';

import { cn } from 'utils/classNames';

import { DesktopItemsProps } from '../types';
import { CarouselDots } from './CarouselDots';

const bem = cn('features-grid');

const DesktopItems = ({ rowList = [], itemsPerRow, variant }: DesktopItemsProps) => {
  const carouselLogic = useDesktopCarousel(rowList, itemsPerRow || 0, variant);

  const { getRefFromHandlers, handleCarrotClick, handleCarrotKeyPress, carouselRef } = carouselLogic;
  const { directions, activeSlide, leftCarrotRef, rightCarrotRef } = carouselLogic;

  const isDesktopCarousel = variant === 'carousel';
  const showLeftCarrot = variant === 'carousel' && activeSlide > 1;
  const showRightCarrot = variant === 'carousel' && activeSlide < rowList.length;

  return (
    <div ref={carouselRef} className={bem('desktop-carousel-wrapper')} role="region">
      <div className={bem('desktop-carousel-content')}>
        {showLeftCarrot && (
          <div
            ref={leftCarrotRef}
            className={bem('carrot-wrapper')}
            role="button"
            tabIndex={0}
            aria-label="Move carousel to the left"
            onClick={handleCarrotClick(directions.left)}
            onKeyDown={handleCarrotKeyPress}
          >
            <Icon name="chevron-left" className={bem('carrot')} />
          </div>
        )}
        <div
          ref={getRefFromHandlers}
          className={bem('items-wrapper', { isDesktopCarousel })}
          role="group"
          aria-live="polite"
        >
          {rowList.map((row: FeatureItemProps[], index: number) => {
            const key = `row-${index}`;
            const hasItemsWithPremiumFlag = row.find(el => el.premiumFlag && el.showPremiumFlag);
            const hasItemsWithBackground = row.find(el => el.colors?.background);
            const isCurrentSlide = variant !== 'carousel' || activeSlide === index + 1;

            return (
              <ul
                key={key}
                className={bem('items', {
                  length: itemsPerRow,
                  premium: !!hasItemsWithPremiumFlag,
                  background: !!hasItemsWithBackground,
                  isDesktopCarousel,
                })}
                aria-setsize={rowList.length}
                aria-posinset={index + 1}
              >
                {row.map((item: FeatureItemProps, index: number) => {
                  const key = `row-item-${item.id ? item.id : index}`;

                  return <FeatureItem key={key} {...item} disableFocus={!isCurrentSlide} />;
                })}
              </ul>
            );
          })}
        </div>
        {showRightCarrot && (
          <div
            ref={rightCarrotRef}
            className={bem('carrot-wrapper')}
            role="button"
            tabIndex={0}
            aria-label="Move carousel to the right"
            onClick={handleCarrotClick(directions.right)}
            onKeyDown={handleCarrotKeyPress}
          >
            <Icon name="chevron-right" className={bem('carrot')} />
          </div>
        )}
      </div>

      {variant === 'carousel' && <CarouselDots rowList={rowList} itemsPerRow={itemsPerRow} selectedDot={activeSlide} />}
    </div>
  );
};
export default DesktopItems;
