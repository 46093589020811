import React from 'react';

import { NavItemProps } from '@molecules/NavItem/types';

import { cn } from 'utils/classNames';

import { TopNavMenuProps } from '../TopNav/types';

const bemGlobal = cn('global-header');

export const CustomIcon = (props: TopNavMenuProps | NavItemProps) => {
  const { svgSource, label = '', customIcon } = props;
  if (svgSource) return <img className={bemGlobal('custom-icon')} src={svgSource} alt={label} />;

  return customIcon || null;
};
