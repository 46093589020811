import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { EventsBrowserFiltersMobile } from '../EventsBrowserFilters/EventsBrowserFilters.mobile';
import './EventsBrowserFiltersDrawer.scss';

const bem = cn('events-browser-filters-drawer');

export const EventsBrowserFiltersDrawer = observer(({ logic }: any) => {
  const { cfStore } = useStores();
  const { cityFilterLabel, cityFilterIcon, categoryFilterLabel, categoryFilterIcon } = cfStore.events.cityFilter;
  const { dateFilterLabel, dateFilterIcon } = cfStore.events.calendarFilter;

  const { setCityPopoverOpen } = logic;

  const drawerFilters = [
    { label: categoryFilterLabel, icon: categoryFilterIcon },
    { label: cityFilterLabel, icon: cityFilterIcon },
    { label: dateFilterLabel, icon: dateFilterIcon },
  ];

  return (
    <div className={bem()} onClick={() => setCityPopoverOpen(true)}>
      {drawerFilters.map((filter, idx) => (
        <div key={idx} className={bem('filter')}>
          <div className={bem('filter-label-wrapper')}>
            <img className={bem('filter-icon')} src={filter.icon.src} alt={filter.icon.alt} />
            <Text text={filter.label} className={bem('filter-label')} />
          </div>
          <Icon name="chevron-down" className={bem('filter-icon')} />
        </div>
      ))}
      <EventsBrowserFiltersMobile logic={logic} />
    </div>
  );
});
