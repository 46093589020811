import React, { useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import { useCancelTripLogic } from '../Navigation/CancelTrip/useCancelTripLogic';
import { CancellationDetail } from './CancellationDetail/CancellationDetail';
import './CancellationModal.scss';
import { ConfirmCancellation } from './ConfirmCancellation/ConfirmCancellation';
import { PartialCancellation } from './PartialCancellation';
import { ReturnSummary } from './ReturnSummary/ReturnSummary';

const bem = cn('cancellation-modal');

export const CancellationModal = ({ onCancelTrip }) => {
  const {
    cfStore: { account },
    bookingStore,
    accountStore,
  } = useStores();
  const { setTripWay, trip, tripLoad, cancelTrip: cancelTripAPI, hasCancelTripError } = accountStore.tripDetails;

  const { navigation } = account.tripDetails;
  const { cancelTrip } = navigation;
  const { provisionalBooking } = bookingStore.rti;
  const origin = provisionalBooking?.tripDetails?.trip;
  const [selectedRefundMethod, setSelectedRefundMethod] = useState('Voucher');
  const [showContent, setShowContent] = useState(!origin.outboundRoute || !origin.inboundRoute || tripLoad);
  const [tripType, setTripType] = useState({ departure: false, return: false });
  const [discard, setDiscard] = useState(false);
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);
  const { onRevert } = useCancelTripLogic();
  const [tripWayLoading, setTripWayLoading] = useState(false);
  const handleCloseProgres = () => {
    setDiscard(true);
  };

  const handleContinueClick = async () => {
    setTripWayLoading(true);
    let cancellationType = '';
    if (tripType.departure && tripType.return) cancellationType = 'both_legs';
    else if (tripType.departure) cancellationType = 'departure';
    else if (tripType.return) cancellationType = 'return';
    setTripWay(cancellationType);
    await cancelTripAPI(trip?.referenceNumber, true, cancellationType, true);
    setTripWayLoading(false);
    if (!hasCancelTripError) setShowContent(true);
  };

  return (
    <div className={bem()}>
      <Modal
        title={cancelTrip?.modalTitle}
        className={bem({ isMobile, details: showContent })}
        onClose={!tripType.departure && !tripType.return ? onRevert : handleCloseProgres}
      >
        {!showContent ? (
          <div className={bem('select-cancellation')}>
            <div className={bem('select-trip')}>
              <p className={bem('title')}>{navigation.cancelTrip.selectDescription}</p>
              <div className={bem('partial-cancellation')}>
                <PartialCancellation
                  tripName="Departure"
                  leg={trip?.outboundRoute}
                  tripType={tripType}
                  setTripType={setTripType}
                />
                <PartialCancellation
                  tripName="Return"
                  leg={trip?.inboundRoute}
                  tripType={tripType}
                  setTripType={setTripType}
                />
              </div>
            </div>
            <div className={bem('buttons')}>
              <Button
                label={cancelTrip?.tripCancellationBackButton}
                variant="secondary"
                colors={{ backgroundOrBorder: 'black' }}
                onClick={onRevert}
              />
              <Button
                label={cancelTrip?.tripCancellationContinueButton}
                onClick={handleContinueClick}
                disabled={!tripType.departure && !tripType.return}
                loading={tripWayLoading}
              />
            </div>
          </div>
        ) : (
          <div className={bem()}>
            <ReturnSummary
              closeModal={onCancelTrip}
              selectedRefundMethod={selectedRefundMethod}
              setSelectedRefundMethod={setSelectedRefundMethod}
            />
            <CancellationDetail />
            {isMobile && <ConfirmCancellation closeModal={onCancelTrip} className="mobile-buttons" />}
          </div>
        )}
      </Modal>
      {discard && (
        <Modal className={bem('discard-cancellation')}>
          <div className={bem('discard-text')}>
            <h2>{navigation.cancelTrip.discardTitleLabel}</h2>
            <p>{navigation.cancelTrip.discardDescription}</p>
          </div>
          <div className={bem('discard-buttons')}>
            <Button
              label={cancelTrip?.discardModalBackButton}
              variant="secondary"
              colors={{ backgroundOrBorder: 'black' }}
              onClick={() => {
                setDiscard(false);
              }}
            />
            <Button label={cancelTrip?.discardModalContinueButton} onClick={onRevert} />
          </div>
        </Modal>
      )}
    </div>
  );
};
