import { observer } from 'mobx-react-lite';
import React from 'react';

import { cn } from 'utils/classNames';

import { DateInputs } from '../DateInputs';
import { MobileDesktopDateRangePickerProps } from '../types';
import { MobileCalendar } from './MobileCalendar';

const bem = cn('date-range-picker');

export const MobileDateRangePicker = observer((props: MobileDesktopDateRangePickerProps) => {
  const { focusedInput, isOutsideRange, containerRef, isRoundTrip, tabType, labels, isSameDay } = props;
  const { handleFocusChange, handleDatesChange } = props;

  return (
    <div className={bem('mobile')}>
      <DateInputs {...props} isOneWay={!isRoundTrip} />
      <MobileCalendar
        {...props}
        containerRef={containerRef}
        handleFocusChange={handleFocusChange}
        focusedInput={focusedInput}
        handleDatesChange={handleDatesChange}
        isOutsideRange={isOutsideRange}
        isSameDay={isSameDay}
        isOneWay={!isRoundTrip}
        tabType={tabType}
        labels={labels}
      />
    </div>
  );
});
