import { useThrottle } from 'hooks/useThrottle';
import { useEffect, useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useSwipeable } from 'react-swipeable';
import { WalletPassItem } from 'stores/AccountStore/sections';

import { mediaQueryList } from 'utils/mediaQueries';

const directions = {
  left: 'left',
  right: 'right',
};

export const useCarousel = (passes: any[] = [], hasPasses) => {
  const [currentTranslateValue, setCurrentTranslateValue] = useState(0);

  const [activeSlide, setActiveSlide] = useState(1);
  const [selectedPass, setSelectedPass] = useState<WalletPassItem | null>(hasPasses ? passes[0] : null);
  const [prevMargins, setPrevMargins] = useState(0);

  const isMobile = useMediaPredicate(mediaQueryList.maxMobile);
  const isTablet = useMediaPredicate(mediaQueryList.tablet);

  const innerRef: any = useRef<HTMLDivElement>(null);
  const prevWidthRef = useRef(window.innerWidth);

  const getElementSizeAndSpaces = (element, prevMargins) => {
    const elementWidth: any = element?.offsetWidth;
    const elementStyle = window.getComputedStyle(element);
    const elementMarginRight = parseInt(elementStyle.marginRight);

    const elementSpacing = Math.abs(elementMarginRight) > prevMargins ? elementMarginRight : prevMargins;

    return { elementWidth, elementSpacing };
  };

  const changeSlide = (newSlideIndex: number, movingValue: number) => {
    if (!(isMobile || isTablet) || newSlideIndex > passes.length || newSlideIndex < 1) return;
    innerRef.current.style.transform = `translate3d(${movingValue}px, 0, 0)`;
    setCurrentTranslateValue(movingValue);
  };

  const handleSwipe = direction => () => {
    const element: any = innerRef?.current?.childNodes[activeSlide - 1];
    const { elementWidth, elementSpacing } = getElementSizeAndSpaces(element, prevMargins);

    if (direction === directions.left && activeSlide < passes.length) {
      const newIndex = activeSlide + 1;
      const leftMoveValue = currentTranslateValue - elementWidth - elementSpacing;
      changeSlide(newIndex, leftMoveValue);
      setPrevMargins(elementSpacing);
      setActiveSlide(newIndex);
      if (hasPasses) setSelectedPass(passes[newIndex - 1]);
    } else if (direction === directions.right && activeSlide > 1) {
      const newIndex = activeSlide - 1;
      const rightMoveValue = currentTranslateValue + elementWidth + elementSpacing;
      changeSlide(newIndex, rightMoveValue);
      setActiveSlide(newIndex);
      if (hasPasses) setSelectedPass(passes[newIndex - 1]);
    }
  };
  const handlers = useSwipeable({
    onSwipedRight: handleSwipe(directions.right),
    onSwipedLeft: handleSwipe(directions.left),
    trackMouse: true,
  });

  const getRefFromHandlers = (el: HTMLDivElement) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set myRef el so you can access it yourself
    innerRef.current = el;
  };

  const resetCarousel = () => {
    setCurrentTranslateValue(0);
    setActiveSlide(1);
    setSelectedPass(hasPasses ? passes[0] : null);
    if (innerRef.current && innerRef.current.style) innerRef.current.style.removeProperty('transform');
  };

  const swipeCarouselToSlide = (slideNumber: number) => {
    resetCarousel();
    let leftMoveValue = 0;
    let prevElMargins = 0;
    for (let i = 0; i < slideNumber - 1; i++) {
      const element: any = innerRef?.current?.childNodes[i];
      const { elementWidth, elementSpacing } = getElementSizeAndSpaces(element, prevElMargins);
      prevElMargins = elementSpacing;
      leftMoveValue -= elementWidth + elementSpacing;
    }
    innerRef.current.style.transform = `translate3d(${leftMoveValue}px, 0, 0)`;
    setCurrentTranslateValue(leftMoveValue);

    setPrevMargins(prevElMargins);
    setActiveSlide(slideNumber);
    setSelectedPass(passes[slideNumber - 1]);
  };

  const resizeCarousel = (slideNumber: number) => {
    let leftMoveValue = 0;
    let prevElMargins = 0;
    for (let i = 0; i < slideNumber - 1; i++) {
      const element: any = innerRef?.current?.childNodes[i];
      const { elementWidth, elementSpacing } = getElementSizeAndSpaces(element, prevElMargins);
      prevElMargins = elementSpacing;
      leftMoveValue -= elementWidth + elementSpacing;
    }
    innerRef.current.style.transform = `translate3d(${leftMoveValue}px, 0, 0)`;
    setCurrentTranslateValue(leftMoveValue);

    setPrevMargins(prevElMargins);
    setActiveSlide(slideNumber);
    setSelectedPass(passes[slideNumber - 1]);
  };

  const resetCarouselThrottle = useThrottle(activeSlide => resizeCarousel(activeSlide), 100);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;

      if (currentWidth !== prevWidthRef.current) {
        resetCarouselThrottle(activeSlide);
        prevWidthRef.current = currentWidth;
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [activeSlide, resetCarouselThrottle]);

  return {
    selectedPass,
    activeSlide,
    innerRef,
    handlers,
    getRefFromHandlers,
    swipeCarouselToSlide,
  };
};
