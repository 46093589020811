import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useStores } from 'stores';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import './CoachSelect.scss';
import { CoachSelectProps } from './types';

const bem = cn('coach-select');

export const CoachSelectHeader = observer((props: CoachSelectProps) => {
  const { cfStore } = useStores();
  const {
    rti: { seats: seatsCF },
  } = cfStore;

  const { children, required, value, isAccountFlow, canEditSeatsAccontFlow } = props;
  const { displayList, selectedCoach, disabled, isMobileDevice } = props;

  const wrapperRef = useRef(null);

  return (
    <div
      ref={wrapperRef}
      className={bem({
        disabled: disabled || (isAccountFlow && !canEditSeatsAccontFlow),
        required: required,
        'has-value': !!value,
      })}
    >
      <label htmlFor="coach-select" className={bem('container')}>
        <div className={bem('coach')}>
          <div className={bem('coach-number')}>
            {seatsCF.coachTextLabel} {selectedCoach?.value}
          </div>
          <div className={bem('divider')}>|</div>
          <div className={bem('class')}> {selectedCoach?.label}</div>
        </div>
        {!isAccountFlow && (
          <div className={bem('trailingIcon')}>
            <Icon name={isMobileDevice ? 'arrow-down' : displayList ? 'arrow-up' : 'arrow-down'} />
          </div>
        )}
      </label>
      {children}
    </div>
  );
});
