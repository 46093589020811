import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { getTicketsFormValidationSchema } from '@organisms/BookingSearch/validator';

import { cn } from 'utils/classNames';
import { base64Encode, mergeSchemas } from 'utils/helpers';

import './ModifyTripForm.scss';
import { DateSelection } from './components/DateSelection';
import { OriginAndDestinationModifyTrip } from './components/OriginAndDestination';
import { TicketsFormDesktopProps } from './types';

const bem = cn('modify-trip-tickets-form');

export const TicketsFormDesktop = observer((props: TicketsFormDesktopProps) => {
  const { tripSelection, datesSelection, validateOnMount, fromCity } = props;
  const { toCity, departureTime, returnTime, tabType } = props;
  const { pnr, originId, destinationId, isRoundTrip } = props;
  const { labels, isFirstLegTraveled, isSecondLegTraveled } = props;
  const { stationsSelection, stations, initialFormValues } = props;
  const { datesRestrictions, role, ariaLabelledBy, id } = props;

  const { ticketsFormStore, accountStore } = useStores();
  const { initialTouchFields, setIncompleteSearch } = ticketsFormStore;
  const { tripDetails } = accountStore;

  const handleSubmit = values => {
    setIncompleteSearch(false);

    const rn = pnr;
    const startDate = values?.startDate
      ? moment.isMoment(values?.startDate)
        ? values?.startDate.format('YYYY-MM-DD')
        : moment(values?.startDate).format('YYYY-MM-DD')
      : '';
    const endDate = values?.endDate
      ? moment.isMoment(values?.endDate)
        ? values?.endDate?.format('YYYY-MM-DD')
        : moment(values?.endDate)?.format('YYYY-MM-DD')
      : '';

    let data;

    if (tabType === 'departure') {
      if (startDate && !isFirstLegTraveled)
        data = {
          rn: rn,
          start_date: startDate,
        };
    } else if (tabType === 'return') {
      if (endDate && !isSecondLegTraveled)
        data = {
          rn: rn,
          end_date: endDate,
        };
    } else if (tabType === 'both')
      if (!isFirstLegTraveled && !isSecondLegTraveled && startDate && endDate)
        data = {
          rn: rn,
          start_date: startDate,
          end_date: endDate,
        };
      else if (isFirstLegTraveled && !isSecondLegTraveled && endDate)
        data = {
          rn: rn,
          end_date: endDate,
        };

    if (!data) return;

    if (tripDetails?.passengerSeatingDetails?.length) {
      const primaryPassenger = tripDetails?.passengerSeatingDetails[0]?.passenger;

      if (!primaryPassenger) return;

      data = {
        ...data,
        fn: primaryPassenger?.firstName,
        ln: primaryPassenger?.lastName,
      };
    } else return;

    const encodedData = base64Encode(JSON.stringify(data));

    window.location.href = `/booking?data=${encodedData}&rn=${data.rn}`;
  };

  const schemaArray = getTicketsFormValidationSchema(
    { ...stationsSelection?.i18n, ...datesSelection?.i18n, stations },
    !isRoundTrip
  );

  const joinedSchema = mergeSchemas(schemaArray[0], schemaArray[1], schemaArray[2]);

  return (
    <div className={bem()} role={role} aria-labelledby={ariaLabelledBy} id={id}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialFormValues || {}}
        validationSchema={joinedSchema}
        initialTouched={initialTouchFields}
        validateOnMount={validateOnMount}
        enableReinitialize
      >
        {({ handleSubmit: formikSubmit, submitForm }) => (
          <form onSubmit={formikSubmit}>
            <div>
              <span className={bem('modifyTripTitle')}>{labels.widgetTitleLabel}</span>
              <span className={bem('modifyTripDescription')}>{labels.widgetDescriptionText}</span>
            </div>
            <div className={bem('first-row')}>
              <OriginAndDestinationModifyTrip
                {...stationsSelection}
                stations={stations}
                originId={originId}
                destinationId={destinationId}
                defaultSelectedIndex={0}
                tabType={tabType}
                fromCity={fromCity}
                toCity={toCity}
                labels={labels}
              />
              <DateSelection
                i18n={datesSelection.i18n}
                toggleItems={tripSelection.toggleItems}
                datesRestrictions={datesRestrictions}
                start_date={departureTime}
                end_date={returnTime}
                tabType={tabType}
                isRoundTrip={isRoundTrip}
                labels={labels}
                isFirstLegTraveled={isFirstLegTraveled}
                isSecondLegTraveled={isSecondLegTraveled}
              />
            </div>
            <div className={bem('second-row')}>
              <Button color="yellow" type="submit" label={labels.searchTicketsButtonLabel} onClick={submitForm} />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
});
