export const defaultVariant = 'default';

export const defaultContentSplit = '7-4';
export const defaultContainedContentSplit = '6-6';

export const defaultMobileCols = 12;

export const defaultAspectRatio = '4/3';

export const defaultimageAlignment = {
  default: 'left',
  contained: 'left',
  banner: 'right',
};

export const columnSettings = {
  default: {
    left: {
      '7-4': [
        { tabletLarge: { cols: 6 }, desktop: { cols: 7 } },
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 4 } },
      ],
      '6-5': [
        { tabletLarge: { cols: 6 }, desktop: { cols: 6 } },
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 5 } },
      ],
      '4-5': [
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 4 } },
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 5 } },
      ],
      '4-4': [
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 4 } },
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 4 } },
      ],
      '5-4': [
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 5 } },
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 4 } },
      ],
    },
    right: {
      '7-4': [
        { tabletLarge: { cols: 6 }, desktop: { cols: 4 } },
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 7 } },
      ],
      '6-5': [
        { tabletLarge: { cols: 6 }, desktop: { cols: 5 } },
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 6 } },
      ],
      '4-5': [
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 5 } },
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 4 } },
      ],
      '4-4': [
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 4 } },
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 4 } },
      ],
      '5-4': [
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 5 } },
        { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 4 } },
      ],
    },
  },

  contained: {
    '6-6': [
      { tabletLarge: { cols: 6 }, desktop: { cols: 6 } },
      { tabletLarge: { cols: 6 }, desktop: { cols: 6 } },
    ],
    '5-5': [
      { tabletLarge: { offset: 1, cols: 5 }, desktop: { offset: 1, cols: 5 } },
      { tabletLarge: { cols: 5 }, desktop: { cols: 5 } },
    ],
  },

  banner: {
    left: [
      { tabletLarge: { cols: 5 }, desktop: { offset: 1, cols: 4 } },
      { tabletLarge: { offset: 1, cols: 6 }, desktop: { offset: 1, cols: 5 } },
    ],
    right: [
      { tabletLarge: { cols: 6 }, desktop: { offset: 1, cols: 5 } },
      { tabletLarge: { offset: 1, cols: 5 }, desktop: { offset: 1, cols: 4 } },
    ],
  },
};
