import { AdyenFormConfig } from 'apps/passes/routes/Checkout/types';
import { makeAutoObservable } from 'mobx';

import { TransactionDetailsEventInfo } from '@booking/routes/RTI/PaymentInfo/types';

import { orderSubmission, pushParkingTravelPassEvent, transactionDetails } from 'utils/adobeDataLayer';
import { api, publicApi } from 'utils/api';
import { requestUrls } from 'utils/constants';
import { getPassesCartItems, getTransactionDetailsItems } from 'utils/getPassesCartItems';
import { base64Decode, isAxiosError } from 'utils/helpers';
import { isLoyaltyEnabled } from 'utils/metas';

import { RootStore } from '../../../index';
import { ParkingPass, TravelPass } from '../../types';
import {
  BookingSession,
  CheckoutCartRequest,
  CostSummary,
  CreateCartRequest,
  CreateCartResponse,
  PassesCheckoutURLParams,
  PaymentMethodsResponse,
  RouterURLParams,
  SaveCreditCardRequest,
  SaveCreditCardResponse,
  SavedCreditCard,
} from './types';

export class Checkout {
  private readonly rootStore: RootStore;

  base64Data = '';
  urlParams: PassesCheckoutURLParams = {
    travelPass: undefined,
    parkingPass: undefined,
    totalPrice: 0.0,
    totalPriceDiscounted: 0.0,
    autoRenews: false,
    mobileData: {},
  };

  cartNumber = '';
  travelPass: undefined | TravelPass = undefined;
  parkingPass: undefined | ParkingPass = undefined;
  costSummary: CostSummary = {
    sections: [],
    items: [],
    bookingTotal: {
      total: 0,
      totalPaid: 0,
      totalToBePaid: 0,
    },
  };
  bookingSession: BookingSession = {
    accessToken: '',
    refreshToken: '',
  };
  savedCards: SavedCreditCard[] = [];
  paymentMethods: string[] = ['mc', 'visa', 'amex', 'cup', 'diners', 'discover'];

  loadingCreateCart = false;
  createCartError = false;
  loadingCheckoutCart = false;
  checkoutCartError = false;
  checkoutCartErrorMessage = '';
  showPaymentForm = false;

  selectedCard: undefined | SavedCreditCard = undefined;

  promoCode: undefined | string;
  loadingPromoCode = false;
  promoCodeError = false;

  displayLoyaltyComponents = false;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  setBase64Data(base64Data) {
    this.base64Data = base64Data;
  }

  setUrlParams(urlParams) {
    this.urlParams = urlParams;
  }

  setCartNumber(cartNumber) {
    this.cartNumber = cartNumber;
  }

  setTravelPass(travelPass) {
    this.travelPass = travelPass;
  }

  setParkingPass(parkingPass) {
    this.parkingPass = parkingPass;
  }

  setCostSummary(costSummary) {
    this.costSummary = costSummary;
  }

  setBookingSession(bookingSession) {
    this.bookingSession = bookingSession;
  }

  setSavedCards(savedCards) {
    this.savedCards = savedCards;
  }

  setPaymentMethods(paymentMethods) {
    this.paymentMethods = paymentMethods;
  }

  setLoadingCreateCart(loadingCreateCart) {
    this.loadingCreateCart = loadingCreateCart;
  }

  setCreateCartError(createCartError) {
    this.createCartError = createCartError;
  }

  setLoadingCheckoutCart(loadingCheckoutCart) {
    this.loadingCheckoutCart = loadingCheckoutCart;
  }

  setCheckoutCartError(checkoutCartError) {
    this.checkoutCartError = checkoutCartError;
  }

  setCheckoutCartErrorMessage(checkoutCartErrorMessage) {
    this.checkoutCartErrorMessage = checkoutCartErrorMessage;
  }

  setShowPaymentForm(showPaymentForm) {
    this.showPaymentForm = showPaymentForm;
  }

  setSelectedCard(selectedCard) {
    this.selectedCard = selectedCard;
  }

  setPromoCode(promoCode) {
    this.promoCode = promoCode;
  }

  setLoadingPromoCode(loadingPromoCode) {
    this.loadingPromoCode = loadingPromoCode;
  }

  setPromoCodeError(promoCodeError) {
    this.promoCodeError = promoCodeError;
  }

  setDisplayLoyaltyComponents(displayLoyaltyComponents) {
    this.displayLoyaltyComponents = displayLoyaltyComponents;
  }

  parseUrlParams(base64UrlParam: string) {
    let urlParams: RouterURLParams;
    try {
      urlParams = JSON.parse(base64Decode(base64UrlParam));
    } catch (e) {
      console.error('Error while parsing base64 url params', e);
      throw 'Error while parsing base64 url params';
    }

    this.setBase64Data(base64UrlParam);

    const parsedUrlParams: PassesCheckoutURLParams = {
      travelPass: undefined,
      parkingPass: undefined,
      totalPrice: 0.0,
      totalPriceDiscounted: 0.0,
      autoRenews: false,
      mobileData: {},
    };

    if (!!urlParams.tpOriginID && !!urlParams.tpDestinationID && !!urlParams.tpTariffCode)
      parsedUrlParams.travelPass = {
        originID: urlParams.tpOriginID,
        originName: urlParams.tpOriginName,
        destinationID: urlParams.tpDestinationID,
        destinationName: urlParams.tpDestinationName,
        tariffCode: urlParams.tpTariffCode,
        productName: urlParams.tpProductName,
        category: urlParams.category,
        price: parseFloat(urlParams.tpPrice ? urlParams.tpPrice : '0.00'),
      };
    else parsedUrlParams.travelPass = undefined;

    if (
      !!urlParams.ppStationID &&
      !!urlParams.ppTariffCode &&
      !!urlParams.ppLicenseState &&
      !!urlParams.ppLicenseNumber
    )
      parsedUrlParams.parkingPass = {
        stationID: urlParams.ppStationID,
        stationName: urlParams.ppStationName,
        tariffCode: urlParams.ppTariffCode,
        licenseState: urlParams.ppLicenseState,
        licenseNumber: urlParams.ppLicenseNumber,
        productName: urlParams.ppProductName,
        price: parseFloat(urlParams.ppPrice ? urlParams.ppPrice : '0.00'),
      };
    else parsedUrlParams.parkingPass = undefined;

    parsedUrlParams.totalPrice = parseFloat(urlParams.totalPrice ? urlParams.totalPrice : '0.00');
    parsedUrlParams.totalPriceDiscounted = parseFloat(
      urlParams.totalPriceDiscounted ? urlParams.totalPriceDiscounted : '0.00'
    );
    parsedUrlParams.autoRenews = urlParams.autoRenew === 'true';

    parsedUrlParams.mobileData = {
      jwt: urlParams.jwt,
      mobileEmail: urlParams.mobileEmail,
      mobileFirstName: urlParams.mobileFirstName,
      mobileLastName: urlParams.mobileLastName,
      mobileCallbackURL: urlParams.mobileCallbackURL,
    };

    if (!parsedUrlParams.travelPass && !parsedUrlParams.parkingPass) {
      console.error('Invalid URL params', urlParams);
      throw 'Invalid URL params';
    }

    this.setUrlParams(parsedUrlParams);
  }

  async createCart() {
    const tpParams: any = {
      originId: this.urlParams.travelPass?.originID,
      destinationId: this.urlParams.travelPass?.destinationID,
      tariffCode: this.urlParams.travelPass?.tariffCode,
    };

    const ppParams: any = {
      stationId: this.urlParams.parkingPass?.stationID,
      tariffCode: this.urlParams.parkingPass?.tariffCode,
      licenseState: this.urlParams.parkingPass?.licenseState,
      licenseNumber: this.urlParams.parkingPass?.licenseNumber,
    };

    const reqBody: CreateCartRequest = {
      travelPassItems: [],
      parkingPassItems: [],
    };

    if (Object.values(tpParams).every(el => el !== undefined)) reqBody.travelPassItems = [tpParams];

    if (Object.values(ppParams).every(el => el !== undefined))
      reqBody.parkingPassItems = [
        {
          stationId: ppParams.stationId,
          tariffCode: ppParams.tariffCode,
          licensePlate: {
            state: ppParams.licenseState,
            number: ppParams.licenseNumber,
          },
        },
      ];

    if (!reqBody.travelPassItems.length && !reqBody.parkingPassItems.length) {
      this.setCreateCartError(true);

      return;
    }

    this.setLoadingCreateCart(true);

    try {
      const url = `${requestUrls.getRestUrl(requestUrls.passes.createCart)}.xjson`;
      let response;
      if (this.urlParams.mobileData.jwt)
        response = await publicApi.post<CreateCartResponse>(url, reqBody, {
          headers: {
            Authorization: `Bearer ${this.urlParams.mobileData.jwt}`,
          },
        });
      else response = await api.post<CreateCartResponse>(url, reqBody);

      this.setCartData(response.data);

      this.setCreateCartError(false);

      this.sendAdobeAnalyticsEvent(response);
      await this.fetchPaymentMethodsAndSavedCards();
    } catch (e) {
      console.error(e);
      this.setCreateCartError(true);
    }

    this.setLoadingCreateCart(false);
  }

  sendAdobeAnalyticsEvent = response => {
    pushParkingTravelPassEvent({
      cartId: response.data.cart.cartNumber,
      cartTotal: parseFloat(response.data.cart.costSummary.bookingTotal.total.toFixed(2)),
      autoRenew: this.parkingPass?.isAutoRenew || this.travelPass?.isAutoRenew || false,
      garage: this.parkingPass?.station.name || '',
      licensePlateNumber: this.parkingPass?.licensePlate.number || '',
      licensePlateState: this.parkingPass?.licensePlate.state || '',
      price: this.parkingPass?.price || this.travelPass?.price,
      productName: this.parkingPass?.productName || this.travelPass?.productName,
      productCode: this.travelPass?.productCode || this.parkingPass?.productCode,
      origin: this.travelPass?.originStation.city || '',
      destination: this.travelPass?.destinationStation.city || '',
      category: this.urlParams.travelPass?.category || '',
    });
  };

  async checkoutCart(
    cartNumber,
    savedCreditCard: undefined | SavedCreditCard,
    formValues: undefined | AdyenFormConfig,
    saveCard: undefined | boolean
  ) {
    const noAmountToPay = this.costSummary?.bookingTotal?.totalToBePaid === 0;

    if (!noAmountToPay && !savedCreditCard && !formValues) {
      this.setCheckoutCartError(true);

      return false;
    }

    this.setCheckoutCartError(false);
    this.setLoadingCheckoutCart(true);

    try {
      const reqBody: CheckoutCartRequest = {};

      if (!noAmountToPay) {
        if (!!formValues && this.urlParams.autoRenews) {
          savedCreditCard = await this.saveCreditCard(formValues);
          if (!savedCreditCard) {
            this.setCheckoutCartError(true);
            this.setLoadingCheckoutCart(false);

            return false;
          }
        }

        if (savedCreditCard)
          reqBody.paymentToken = {
            id: savedCreditCard.tokenId,
          };
        else if (formValues) {
          reqBody.encryptedCardInformation = {
            encryptedCardNumber: formValues.data.encryptedCardNumber,
            encryptedExpiryMonth: formValues.data.encryptedExpiryMonth,
            encryptedExpiryYear: formValues.data.encryptedExpiryYear,
            encryptedSecurityCode: formValues.data.encryptedSecurityCode,
          };
          reqBody.billingAddress = {
            street: formValues.billingAddress.street,
            houseNumberOrName: formValues.billingAddress.houseNumberOrName,
            postalCode: formValues.billingAddress.postalCode,
            city: formValues.billingAddress.city,
            stateOrProvince:
              formValues.billingAddress.stateOrProvince !== 'N/A' ? formValues.billingAddress.stateOrProvince : '',
            country: formValues.billingAddress.country,
          };
        } else {
          this.setCheckoutCartError(true);
          this.setLoadingCheckoutCart(false);

          return false;
        }
      }

      const giftCardsPayload =
        this.rootStore?.bookingStore?.rti?.giftCards?.map(card => ({
          cardNumber: card.giftCard.cardNumber,
          pin: card.giftCard.pin,
          amount: card.amountPaid,
        })) || [];

      if (giftCardsPayload && giftCardsPayload.length) reqBody.giftCards = giftCardsPayload;

      const url = `${requestUrls.getRestUrl(requestUrls.passes.checkoutCart)}.${cartNumber}.xjson`;
      if (this.urlParams.mobileData.jwt)
        await publicApi.post(url, reqBody, {
          headers: {
            Authorization: `Bearer ${this.urlParams.mobileData.jwt}`,
            'Booking-Session-Access-Token': this.bookingSession.accessToken,
            'Booking-Session-Refresh-Token': this.bookingSession.refreshToken,
          },
        });
      else
        await api.post(url, reqBody, {
          headers: {
            'Booking-Session-Access-Token': this.bookingSession.accessToken,
            'Booking-Session-Refresh-Token': this.bookingSession.refreshToken,
          },
        });

      orderSubmission(
        this.selectedCard?.name,
        {
          cartID: cartNumber,
          cartTotal: parseFloat(this.costSummary?.bookingTotal?.total.toFixed(2) || ''),
          item: [
            ...getPassesCartItems(
              this.travelPass || this.parkingPass,
              this.costSummary,
              this.urlParams.travelPass?.category
            ),
          ],
        },
        true
      );

      if (!!formValues && !this.urlParams.autoRenews && saveCard) await this.saveCreditCard(formValues);

      this.sendTransactionDetailsEvent(cartNumber);

      this.loadingCheckoutCart = false;
    } catch (e: any) {
      console.error(e);
      this.setCheckoutCartError(true);
      this.setLoadingCheckoutCart(false);

      if (isAxiosError(e) && e?.response?.data?.code === 'payment.error')
        this.setCheckoutCartErrorMessage(e?.response?.data?.message);
      else this.setCheckoutCartErrorMessage('');

      return false;
    }

    return true;
  }

  sendTransactionDetailsEvent(cartNumber) {
    const { bookingTotal } = this.costSummary;

    const eventInfo: TransactionDetailsEventInfo = {
      cart: {},
      tripManagement: {
        PNR: '',
      },
      transaction: {
        cartID: cartNumber,
        item: [...getTransactionDetailsItems(this.travelPass || this.parkingPass, this.costSummary)],
        transactionTotal: parseFloat(bookingTotal.totalToBePaid.toFixed(2)),
        transactionID: cartNumber,
      },
    };

    transactionDetails(eventInfo, true, undefined);
  }

  async fetchPaymentMethodsAndSavedCards() {
    try {
      const url = `${requestUrls.getRestUrl(requestUrls.passes.paymentMethods)}.xjson`;

      let response;
      if (this.urlParams.mobileData.jwt)
        response = await publicApi.get<PaymentMethodsResponse>(url, {
          headers: {
            Authorization: `Bearer ${this.urlParams.mobileData.jwt}`,
          },
        });
      else
        response = await api.get<PaymentMethodsResponse>(url, {
          headers: {
            Authorization: 'Bearer anonymous',
          },
        });

      if (response.data.adyenResponse.paymentMethods.length)
        this.setPaymentMethods([...response.data.adyenResponse.paymentMethods[0].brands]);

      this.setSavedCards([...response.data.storedPaymentTokens]);

      const preferredCards = response.data.storedPaymentTokens.filter(card => card.isPreferred);
      if (preferredCards.length) this.setSelectedCard(preferredCards[0]);
      else if (response.data.storedPaymentTokens.length) this.setSelectedCard(response.data.storedPaymentTokens[0]);
      else
        this.setSelectedCard({
          tokenId: 'new-card',
          name: '',
          brand: '',
          lastFour: '',
          isPreferred: false,
        });
    } catch (e) {
      console.error(e);
      this.setSavedCards([]);
      this.setPaymentMethods([]);
    }
  }

  async saveCreditCard(formValues: AdyenFormConfig) {
    try {
      const reqBody: SaveCreditCardRequest = {
        encryptedCardNumber: formValues.data.encryptedCardNumber,
        encryptedExpiryMonth: formValues.data.encryptedExpiryMonth,
        encryptedExpiryYear: formValues.data.encryptedExpiryYear,
        encryptedSecurityCode: formValues.data.encryptedSecurityCode,
        platform: 'web',
        billingAddress: {
          street: formValues.billingAddress.street,
          houseNumberOrName: formValues.billingAddress.houseNumberOrName,
          postalCode: formValues.billingAddress.postalCode,
          city: formValues.billingAddress.city,
          stateOrProvince: formValues.billingAddress.stateOrProvince,
          country: formValues.billingAddress.country,
        },
      };

      const url = `${requestUrls.getRestUrl(requestUrls.passes.saveCard)}.xjson`;

      let saveCardResponse;
      if (this.urlParams.mobileData.jwt)
        saveCardResponse = await publicApi.post<SaveCreditCardResponse>(url, reqBody, {
          headers: {
            Authorization: `Bearer ${this.urlParams.mobileData.jwt}`,
          },
        });
      else saveCardResponse = await api.post<SaveCreditCardResponse>(url, reqBody);

      return saveCardResponse.data.paymentToken;
    } catch (e: any) {
      console.error(e);

      if (isAxiosError(e) && e?.response?.data?.code === 'payment.error')
        this.setCheckoutCartErrorMessage(e?.response?.data?.message);
      else this.setCheckoutCartErrorMessage('');

      return undefined;
    }
  }

  async applyPromoCode(promoCode) {
    if (!promoCode) {
      this.setPromoCodeError(true);

      return;
    }

    this.setPromoCodeError(false);
    this.setLoadingPromoCode(true);

    try {
      const reqBody = {
        promoCode,
      };
      const url = `${requestUrls.getRestUrl(requestUrls.passes.applyPromoCode)}.${this.cartNumber}.xjson`;

      let promoCodeResponse;

      if (this.urlParams.mobileData.jwt)
        promoCodeResponse = await publicApi.post(url, reqBody, {
          headers: {
            Authorization: `Bearer ${this.urlParams.mobileData.jwt}`,
            'Booking-Session-Access-Token': this.bookingSession.accessToken,
            'Booking-Session-Refresh-Token': this.bookingSession.refreshToken,
          },
        });
      else
        promoCodeResponse = await api.post(url, reqBody, {
          headers: {
            'Booking-Session-Access-Token': this.bookingSession.accessToken,
            'Booking-Session-Refresh-Token': this.bookingSession.refreshToken,
          },
        });

      if (promoCodeResponse?.data) {
        this.setCartData(promoCodeResponse.data);
        this.setPromoCode(promoCode);
      }
    } catch (e) {
      console.error(e);
      this.setPromoCodeError(true);
    }

    this.setLoadingPromoCode(false);
  }

  async deletePromoCode() {
    try {
      const url = `${requestUrls.getRestUrl(requestUrls.passes.applyPromoCode)}.${this.cartNumber}.xjson`;

      let promoCodeResponse;

      if (this.urlParams.mobileData.jwt)
        promoCodeResponse = await publicApi.delete(url, {
          headers: {
            Authorization: `Bearer ${this.urlParams.mobileData.jwt}`,
            'Booking-Session-Access-Token': this.bookingSession.accessToken,
            'Booking-Session-Refresh-Token': this.bookingSession.refreshToken,
          },
          data: {
            promoCode: this.promoCode,
          },
        });
      else
        promoCodeResponse = await api.delete(url, {
          headers: {
            'Booking-Session-Access-Token': this.bookingSession.accessToken,
            'Booking-Session-Refresh-Token': this.bookingSession.refreshToken,
          },
          data: {
            promoCode: this.promoCode,
          },
        });

      if (promoCodeResponse?.data) {
        this.setCartData(promoCodeResponse.data);
        this.setPromoCode('');
      }
    } catch (e) {
      console.error(e);
    }
  }

  private setCartData(cartData: CreateCartResponse) {
    if (cartData.cart.travelPassProducts.length) this.setTravelPass(cartData.cart.travelPassProducts[0]);
    else this.setTravelPass(undefined);

    if (cartData.cart.parkingPassProducts.length) this.setParkingPass(cartData.cart.parkingPassProducts[0]);
    else this.setParkingPass(undefined);

    this.setCartNumber(cartData.cart.cartNumber);

    this.setCostSummary(cartData.cart.costSummary);
    this.setBookingSession(cartData.session);
  }

  async updateLoyaltyDisplayStatus() {
    const { accountStore, authStore } = this.rootStore;
    const { fetchUserProfile } = accountStore.profile;

    const { isAuthenticated } = authStore;
    localStorage.setItem('mobileJwt', this.urlParams?.mobileData?.jwt ? this.urlParams?.mobileData?.jwt : '');
    await fetchUserProfile(this.urlParams?.mobileData?.jwt);

    this.setDisplayLoyaltyComponents(
      isLoyaltyEnabled() &&
        isAuthenticated &&
        !accountStore.profile.userProfile?.travelAgentCode &&
        accountStore.profile.userProfile?.loyaltyPrograms?.some(
          program => program.program.toLowerCase() === 'brightline' && program.active
        )
    );
  }
}
