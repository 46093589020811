import { useMemo } from 'react';
import * as Yup from 'yup';

export const useValidationSchema = props =>
  useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(props.requiredInputLabel),
        lastName: Yup.string().required(props.requiredInputLabel),
        dob: Yup.date().required(props.requiredInputLabel),
        email: Yup.string().email(props.invalidEmailLabel).required(props.requiredInputLabel),
        phoneNumber: Yup.object()
          .test('phoneNumber', props.requiredInputLabel, value => value.countryCode && value.number)
          .required(props.requiredInputLabel),
        requestType: Yup.string().required(props.requiredInputLabel),
      }),
    [props]
  );
