import { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import { TeaserV2Props } from '@molecules/TeaserV2/types';

const directions = {
  left: 'left',
  right: 'right',
};

const useCarousel = (items: TeaserV2Props[], variant: string, itemsPerRow = 0) => {
  const [currentTranslateValue, setCurrentTranslateValue] = useState(0);
  const [activeSlide, setActiveSlide] = useState(itemsPerRow || 1);
  const [prevMargins, setPrevMargins] = useState(0);

  const innerRef: any = useRef<HTMLDivElement>(null);

  const getElementSizeAndSpaces = element => {
    const elementWidth: any = element?.offsetWidth;
    const elementStyle = window.getComputedStyle(element);
    const elementMarginRight = parseInt(elementStyle.marginRight);
    const elementMarginLeft = parseInt(elementStyle.marginLeft);
    const horizontalSpacings = elementMarginLeft + elementMarginRight;
    const elementSpacing = horizontalSpacings > prevMargins ? horizontalSpacings : prevMargins;

    return { elementWidth, elementSpacing };
  };

  const changeSlide = (newSlideIndex: number, movingValue: number) => {
    if (newSlideIndex > items.length || newSlideIndex < 1) return;
    innerRef.current.style.transform = `translate3d(${movingValue}px, 0, 0)`;
    setCurrentTranslateValue(movingValue);
  };

  const handleSwipe = direction => () => {
    if (variant === 'stack') return;

    const element: any = innerRef?.current?.childNodes[activeSlide - 1];
    const { elementWidth, elementSpacing } = getElementSizeAndSpaces(element);
    if (direction === directions.left && activeSlide < items.length) {
      const newIndex = activeSlide + 1;
      const leftMoveValue = currentTranslateValue - elementWidth - elementSpacing;
      changeSlide(newIndex, leftMoveValue);
      setPrevMargins(elementSpacing);
      setActiveSlide(newIndex);
    } else if (direction === directions.right && activeSlide > 1) {
      const newIndex = activeSlide - 1;
      const rightMoveValue = currentTranslateValue + elementWidth + elementSpacing;
      changeSlide(newIndex, rightMoveValue);
      setActiveSlide(newIndex);
    }
  };

  const handlers = useSwipeable({
    onSwiping: ({ deltaX }) => {
      if (variant === 'stack') return;

      if (activeSlide < items.length && activeSlide > 1)
        innerRef.current.style.transform = `translate3d(${currentTranslateValue + deltaX}px, 0, 0)`;
    },
    onSwipedRight: handleSwipe(directions.right),
    onSwipedLeft: handleSwipe(directions.left),
    trackMouse: true,
  });

  const getRefFromHandlers = (el: HTMLUListElement) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set myRef el so you can access it yourself
    innerRef.current = el;
  };

  useEffect(() => {
    if (innerRef?.current?.style?.transform) {
      innerRef?.current?.style?.removeProperty('transform');
      setCurrentTranslateValue(0);
      setActiveSlide(1);
    }
  }, []);

  return {
    innerRef,
    handlers,
    getRefFromHandlers,
    handleSwipe,
    activeSlide,
  };
};

export default useCarousel;
