import _ from 'lodash';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { CostDetailPerTripParking } from '@booking/routes/RTI/CostSummary/CostDetailPerTripParking';

import { cn } from 'utils/classNames';

import { CostSummarySectionItem } from './CostSummarySectionItem';

const bem = cn('cost-summary-sections');

export const CostSummarySections = observer(({ isCancelation, isSelect, tripType, setTripType }: any) => {
  const { bookingStore, accountStore } = useStores();
  const { provisionalBooking } = bookingStore.rti;
  const { tripWay, tripDiscounts } = accountStore.tripDetails;

  const costSummary = provisionalBooking.tripDetails?.costSummary;

  const origin = provisionalBooking?.tripDetails?.trip;

  const sections = costSummary?.sections;

  const filterSections = useMemo(() => {
    runInAction(() => {
      if (isCancelation) {
        tripWay !== 'both_legs' &&
          sections?.[0].name?.toLowerCase() !== 'other' &&
          sections?.[1].name?.toLowerCase() !== 'other' &&
          (tripWay === 'departure'
            ? sections?.[1].name?.toLowerCase() !== 'other' && sections?.splice(1, 1)
            : sections?.[0].name?.toLowerCase() !== 'other' && sections?.splice(0, 1));

        // Will execute in all cases when we are cancelling a trip
        sections
          ?.find(section => section?.name?.toLowerCase() === 'other')
          ?.items?.forEach(item => {
            const discount = tripDiscounts?.find(discount => discount.code === item?.productCode);
            if (item && discount) {
              item.quantity = discount.quantity;
              item.totalPrice = discount.amount;
              item.totalOriginalPrice = discount.amount;
            }
          });
      }
    });

    return _.uniqBy(sections, 'name');
  }, [isCancelation, sections, tripDiscounts, tripWay]);

  return (
    <div className={bem(isSelect ? 'cancellation' : '')}>
      {tripWay === 'both_legs' || origin?.outboundRoute?.isCancelled || origin?.inboundRoute?.isCancelled
        ? _.uniqBy(costSummary?.sections, 'name')?.map((section, index) => {
            const key = `${section.name}-${index}`;

            return (
              <CostSummarySectionItem
                key={key}
                index={index}
                {...section}
                isCancelation={isCancelation}
                isSelect={isSelect}
                tripType={tripType}
                setTripType={setTripType}
              />
            );
          })
        : filterSections?.map((section, index) => {
            const key = `${section.name}-${index}`;

            return (
              <CostSummarySectionItem
                key={key}
                index={index}
                {...section}
                isCancelation={isCancelation}
                isSelect={isSelect}
                tripType={tripType}
                setTripType={setTripType}
              />
            );
          })}
      <CostDetailPerTripParking />
    </div>
  );
});
