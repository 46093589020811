import { observer } from 'mobx-react-lite';
import React, { useMemo, useRef } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';

import { isAxiosError } from 'utils/helpers';

import { bem as rtiBem } from '../RTI';
import './Card.scss';
import { CardStates } from './constants';
import { CardProps } from './types';

export const Card = observer((props: CardProps) => {
  const { title, stepNr, mode, children, onEdit, editBtnLabel = '', hideError, isModifyPassenger } = props;
  const { hideEditBtn } = props;
  const ref = useRef<HTMLDivElement>(null);

  const { bookingStore, cfStore, authStore, accountStore } = useStores();

  const { isAuthenticated } = authStore;

  const { errorState, modifyTripType, selectedPaymentMethod } = bookingStore.rti;
  const { paymentTokens } = accountStore.paymentTokensStore;

  const { generic } = cfStore;

  const shouldShowError = !hideError && errorState.error && errorState.stepNr === stepNr && mode === CardStates.active;
  const hideGuestEditButton = stepNr === 1 && modifyTripType === 'trip';

  const errorMessage = useMemo(() => {
    if (!shouldShowError) return '';

    if (isAxiosError(errorState?.error) && errorState?.error?.response?.data?.code === 'payment.error') {
      if (ref.current)
        ref.current.scrollIntoView({
          behavior: 'smooth',
        });

      return generic.paymentFailedErrorMessage;
    }

    if (
      isAuthenticated &&
      !(modifyTripType && bookingStore.rti?.provisionalBooking?.tripDetails?.costSummary?.refundMethods?.length) &&
      paymentTokens.length > 0 &&
      (!selectedPaymentMethod || Object.keys(selectedPaymentMethod).length === 0)
    ) {
      if (ref.current)
        ref.current.scrollIntoView({
          behavior: 'smooth',
        });

      return generic.selectPaymentMethodErrorLabel;
    }

    return generic.serverErrorLabel;
  }, [
    shouldShowError,
    errorState,
    generic,
    isAuthenticated,
    selectedPaymentMethod,
    paymentTokens,
    bookingStore.rti?.provisionalBooking?.tripDetails?.costSummary?.refundMethods,
    modifyTripType,
  ]);

  return (
    <div ref={ref} className={rtiBem('card')}>
      <div className={rtiBem('wrapper', { state: mode })}>
        {!isModifyPassenger && (
          <h4 className={rtiBem('title')}>
            <span className={rtiBem('step')}>{mode !== CardStates.finished ? stepNr : ''}</span>
            <span className={rtiBem('text')}>{title}</span>
          </h4>
        )}
        {mode !== CardStates.passive &&
          (stepNr == 1 || stepNr == 2 || (stepNr == undefined && modifyTripType == null)) &&
          !hideEditBtn &&
          !hideGuestEditButton && (
            <Button
              className={rtiBem('card-edit')}
              label={editBtnLabel}
              onClick={onEdit}
              variant="secondary"
              size="small"
              color="blue"
            />
          )}
      </div>

      {shouldShowError && (
        <div className={rtiBem('server-error')}>
          <Icon name="warning" />
          <span className={rtiBem('error-description')}>{errorMessage}</span>
        </div>
      )}
      {mode !== CardStates.passive && children}
    </div>
  );
});
