import { useCallback } from 'react';
import { useStores } from 'stores';

import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

import { getAdyenEnvironment, getAdyenKey } from 'utils/metas';

export const useAdyen = props => {
  const { passesStore } = useStores();
  const { paymentMethods } = passesStore.checkout;
  const { isOpen, paymentContainer, setCardInstance, setCardData } = props;

  const initializeAdyenCard = useCallback(async () => {
    if (paymentContainer.current && isOpen) {
      const configuration = {
        environment: getAdyenEnvironment(),
        clientKey: getAdyenKey(),
        analytics: {
          enabled: true,
        },
        onChange: data => {
          setCardData(data);

          return data;
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            billingAddressRequired: true,
          },
        },
      };
      const checkout = await AdyenCheckout(configuration);
      setCardInstance(
        checkout
          .create('card', { brands: paymentMethods, data: { billingAddress: { country: 'US' } } })
          .mount(paymentContainer.current)
      );
    }
  }, [isOpen, paymentContainer, setCardData, setCardInstance, paymentMethods]);

  return {
    initializeAdyenCard,
  };
};
