import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { rootStore, useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';

import './SearchBar.scss';
import { SearchBarProps } from './types';
import { getDateRange, getStationById } from './utils';

const bem = cn('header-search-bar');

export const SearchBar = observer((props: SearchBarProps) => {
  const { colors, editSearchButtonLabel = 'Edit Search' } = props;
  const { ticketsFormStore, bookingStore } = useStores();
  const { isPanelVisible, showPanel, hidePanel, formValues, stationsData } = ticketsFormStore;
  const { modifyTripProps } = bookingStore.tickets;

  const styles: any = useMemo(
    () => ({
      '--header-search-bar-border-color': colors?.border?.inactive,
      '--header-search-bar-border-focus-color': colors?.border?.focus,
      '--header-search-bar-text-color': colors?.text?.inactive,
      '--header-search-bar-link-color': colors?.link?.inactive,
      '--header-search-divider-border-color': colors?.divider?.inactive,
      '--header-search-bullet-border-color': colors?.bullet?.inactive,
      '--header-search-icon-color': colors?.icon?.inactive,
    }),
    [colors]
  );

  const checkAndSendAnalyticsEvent = () => {
    const hasClicked = localStorage.getItem('rewardsClick');
    const rewardButton = localStorage.getItem('rewardsClickButton');

    if (hasClicked) {
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: 'Account',
          navigationLinkName: rewardButton,
          customLink: window.location.href,
          externalLink: '',
          linkType: 'linkClickLoyalty',
        },
      });

      localStorage.removeItem('rewardsClick');
      localStorage.removeItem('rewardsClickButton');
    }
  };

  useEffect(() => {
    if (window.location.pathname === '/booking') checkAndSendAnalyticsEvent();
  }, []);

  const labelsConfig = useMemo(() => {
    if (modifyTripProps) {
      const isModifyReturnTrip = !modifyTripProps.startDate && modifyTripProps.endDate;
      const trip = modifyTripProps.tripDetails?.trip;

      const modifyTripFrom = isModifyReturnTrip ? trip?.inboundRoute?.origin?.name : trip?.outboundRoute?.origin?.name;
      const modifyTripTo = isModifyReturnTrip
        ? trip?.inboundRoute?.destination?.name
        : trip?.outboundRoute?.destination?.name;

      const dateRange = getDateRange(
        isModifyReturnTrip ? modifyTripProps.endDate : modifyTripProps.startDate,
        isModifyReturnTrip ? undefined : modifyTripProps.endDate
      );

      return {
        from: modifyTripFrom,
        to: modifyTripTo,
        isRoundTrip: !!modifyTripProps.startDate && !!modifyTripProps.endDate,
        dateRange,
        numPassengers: modifyTripProps.tripDetails?.passengerSeatingDetails?.length || 0,
      };
    }

    return {
      from: getStationById(formValues.from, stationsData),
      to: getStationById(formValues.to, stationsData),
      isRoundTrip: !!formValues.endDate,
      dateRange: getDateRange(formValues.startDate, formValues.endDate),
      numPassengers: formValues.adults + formValues.kids,
    };
  }, [formValues, stationsData, modifyTripProps]);

  if (!stationsData.length || (modifyTripProps && !modifyTripProps.tripDetails)) return null;

  return (
    <div style={styles} className={bem({ isPanelVisible })}>
      {!!labelsConfig.from && !!labelsConfig.to && (
        <div className={bem('o-d')}>
          <span>{labelsConfig.from}</span>
          <span className={bem('trip-icon')}>
            <Icon name={labelsConfig.isRoundTrip ? 'double-arrow' : 'long-arrow-right'} />
          </span>
          <span>{labelsConfig.to}</span>
          <div className={bem('divider')} />
          <span>{labelsConfig.isRoundTrip ? 'Round Trip' : 'One way'}</span>
        </div>
      )}
      {labelsConfig.dateRange && <div className={bem('date-selection')}>{labelsConfig.dateRange}</div>}
      <div className={bem('passengers')}>
        <span>{labelsConfig.numPassengers || 0}</span>
        <span className="sr-only">{labelsConfig.numPassengers === 1 ? 'guest' : 'guests'}</span>
        <Icon name="profile" />
      </div>

      {(isPanelVisible || !modifyTripProps) && <div className={bem('divider')} />}

      {isPanelVisible ? (
        <Button color="charcoal" icon="close2" size="small" label="Close" iconPosition="right" onClick={hidePanel} />
      ) : (
        !modifyTripProps && (
          <button className={bem('button')} onClick={showPanel}>
            {editSearchButtonLabel}
          </button>
        )
      )}
    </div>
  );
});
