import { useMemo, useState } from 'react';

export const useDropdownHandlers = (props: any) => {
  const { items, onSelect } = props;

  const [focus, setFocus] = useState({
    index: -1,
  });

  const [selectedItem, setSelectedItem] = useState(items[0]);
  const [expanded, setExpanded] = useState(false);

  const itemsWithoutSelected = useMemo(
    () => items?.filter(item => item.value !== selectedItem?.value),
    [items, selectedItem?.value]
  );

  const handleItemClick = item => e => {
    e.preventDefault();
    setFocus({ index: -1 });
    if (onSelect && item.value) onSelect(item.value);
    setExpanded(false);
    setSelectedItem(item);
  };

  const handleKeyDown = e => {
    switch (e.key) {
      case 'Enter':
        handleItemClick(itemsWithoutSelected[focus.index])(e);
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (focus.index < itemsWithoutSelected.length - 1) setFocus({ index: focus.index + 1 });

        break;
      case 'ArrowUp':
        e.preventDefault();
        if (focus.index > 0) setFocus({ index: focus.index - 1 });

        break;
      case 'Escape':
      case 'Tab':
        setExpanded(false);
        setFocus({ index: -1 });
        break;
    }
  };

  const handleKeyDownHeader = e => {
    switch (e.key) {
      case 'Enter':
      case ' ':
        e.preventDefault();
        setExpanded(true);
        setFocus({ index: 0 });
        break;
      case 'ArrowDown':
        setExpanded(true);
        setFocus({ index: 0 });
        break;
      case 'ArrowUp':
      case 'Escape':
        setExpanded(false);
        break;
    }
  };

  return {
    focus,
    setFocus,
    items,
    itemsWithoutSelected,
    handleItemClick,
    handleKeyDown,
    handleKeyDownHeader,
    selectedItem,
    expanded,
    setExpanded,
  };
};
