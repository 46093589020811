import React from 'react';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { Title } from '@atoms/Title';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';

import './CancelTripModal.scss';
import { CancelationDefaultModalsProps } from './types';

const bem = cn('cancel-trip-modal');

export const CancellationDefaultModal = (props: CancelationDefaultModalsProps) => {
  const { title, text, loyaltyText, buttons = [], isCancellationConfirmed = false } = props;
  const { accountStore } = useStores();
  const { displayLoyaltyComponents } = accountStore.profile;

  return (
    <Modal variant="mini">
      <div>
        {isCancellationConfirmed && (
          <div className={bem('icon')}>
            <Icon name="check" size="large" theme="circled" />
          </div>
        )}
        <Title text={title} size="h3" />
        <Text className={bem(isCancellationConfirmed ? 'text' : '')} text={text} />
        {displayLoyaltyComponents && <Text text={loyaltyText} />}
        <div className={bem(isCancellationConfirmed ? 'tripCancelledButton' : 'buttons')}>
          <Button label={buttons[0]?.label} onClick={buttons[0]?.onClick} />
          {buttons[1] && (
            <Button label={buttons[1]?.label} onClick={buttons[1]?.onClick} disabled={!buttons[1].onClick} />
          )}
        </div>
      </div>
    </Modal>
  );
};
