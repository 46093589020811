import React from 'react';

import { cn } from 'utils/classNames';

import './FooterPlaceholder.scss';

const bem = cn('seat-footer-placeholder');

export const FooterPlaceholder = () => (
  <div className={bem()}>
    <div className={bem('legend-section')}>
      <div className={bem('legend')} />
      <div className={bem('legend')} />
      <div className={bem('legend')} />
      <div className={bem('legend')} />
    </div>
    <div className={bem('action')} />
  </div>
);
