import { FormikContextType, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useMemo } from 'react';
import { DayPickerRangeController } from 'react-dates';

import { Icon } from '@atoms/Icon';
import '@molecules/TicketsForm/components/DateSelection/components/DateRangePicker/MobileCalendar/MobileCalendar.scss';
import { PartialFromProps } from '@molecules/TicketsForm/components/OriginAndDestination/types';

import { cn, cx } from 'utils/classNames';

import { END_DATE, START_DATE } from '../constants';

const bem = cn('mobile-calendar');
const bemDateRange = cn('date-range-picker');

export const MobileCalendar = props => {
  const {
    isSameDay,
    isOutsideRange,
    focusedInput,
    containerRef,
    handleDatesChange,
    handleFocusChange,
    datesRestrictions,
    tabType,
    isFirstLegTraveled,
    ...rest
  } = props;

  const formikProps: FormikContextType<PartialFromProps> = useFormikContext();
  const { values } = formikProps;

  const inputToFocus = useMemo(() => {
    if (tabType === 'departure')
      if (isFirstLegTraveled) return '';
      else return START_DATE;
    else if (tabType === 'return') return END_DATE;
    else if (tabType === 'both')
      if (isFirstLegTraveled) return END_DATE;
      else return focusedInput !== '' ? focusedInput : END_DATE;

    return '';
  }, [tabType, isFirstLegTraveled, focusedInput]);

  const containerWidth = containerRef.current?.offsetWidth;
  const daySize = containerWidth ? Math.trunc(containerWidth / 7) : 35;

  const vars: any = {
    '--line-height': `${daySize - 5}px`,
    '--font-size': `${daySize - daySize / 5}px`,
  };

  const maxDate = datesRestrictions ? moment.utc(datesRestrictions.latestDate) : null;

  const startDateMoment = useMemo(() => {
    if (tabType === 'return') return null;

    if (moment.isMoment(values.startDate)) return values.startDate;
    else if (values.startDate) return moment(values.startDate);

    return null;
  }, [values.startDate, tabType]);

  const endDateMoment = useMemo(() => {
    if (tabType === 'departure') return null;

    if (moment.isMoment(values.endDate)) return values.endDate;
    else if (values.endDate) return moment(values.endDate);

    return null;
  }, [values.endDate, tabType]);

  const renderNavButton = (pos = 'prev') => (
    <button className={bemDateRange('navButton', { pos })}>
      <Icon name="arrow-left" />
    </button>
  );

  const renderDayContents = day => <div className={bemDateRange('day', { sameDay: isSameDay })}>{day.date()}</div>;

  return !(tabType === 'departure' && isFirstLegTraveled) ? (
    <div className={cx(bem('calendar'), bemDateRange('calendar'))} style={vars}>
      <DayPickerRangeController
        onFocusChange={() => handleFocusChange(inputToFocus)}
        startDate={startDateMoment}
        endDate={endDateMoment}
        minimumNights={0}
        daySize={daySize}
        minDate={moment()}
        navPrev={renderNavButton()}
        focusedInput={inputToFocus}
        navNext={renderNavButton('next')}
        renderDayContents={renderDayContents}
        onDatesChange={handleDatesChange}
        enableOutsideDays={false}
        isOutsideRange={isOutsideRange(moment(), maxDate)}
        hideKeyboardShortcutsPanel
        {...rest}
      />
    </div>
  ) : (
    <></>
  );
};
