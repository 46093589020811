import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { Icon } from '@atoms/Icon';
import { Table } from '@atoms/Table/Table';
import { Text } from '@atoms/Text';
import { Modal } from '@molecules/Modal';

import { cn } from 'utils/classNames';
import { isAuthorMode } from 'utils/metas';

import './FareCompareModalV2.scss';
import { FareCompareModalV2Props } from './types';
import { useFareCompareModalLogicV2 } from './useFareCompareModalLogicV2';

const bem = cn('fare-compare-modal-v2');

const FareCompareModalV2 = (props: FareCompareModalV2Props) => {
  const { tableTitle, hideTableTitle, renderAs } = props;

  const fareCompareLogic = useFareCompareModalLogicV2(props);

  const { tableConfig, hideModal, visible, tableParentRef } = fareCompareLogic;
  const { renderAsModal, modalVariant } = fareCompareLogic;

  return (
    <>
      {renderAs === 'showAsModal' && isAuthorMode() && (
        <Text text="The table will be rendered as a modal in publish mode" className={bem('modal-notification')} />
      )}
      {renderAsModal && modalVariant && visible && (
        <CSSTransition in={visible} classNames="fade" timeout={500} unmountOnExit>
          <Modal
            title={tableTitle}
            variant={modalVariant as 'default' | 'mini'}
            onClose={hideModal}
            ariaLabel="Class options"
            modalContentRef={tableParentRef}
          >
            {hideTableTitle && (
              <div className={bem('header')}>
                <button className={bem('icon-wrapper')} onClick={hideModal}>
                  <Icon name="close2" className={bem('icon')} />
                </button>
              </div>
            )}
            <Table {...tableConfig} />
          </Modal>
        </CSSTransition>
      )}
      {!renderAsModal && <Table {...tableConfig} />}
    </>
  );
};

export default FareCompareModalV2;
