import { merge } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { fiveMinutesCachedApi, publicApi } from 'utils/api';
import { getFinalStorybookEndpoint } from 'utils/storybookUtils';

import {
  defaultAccountProps,
  defaultEventsProps,
  defaultGenericProps,
  defaultHeaderProps,
  defaultPassesHeaderProps,
  defaultPassesProps,
  defaultRTIProps,
  defaultTrainSelectionProps,
} from './constants';
import { AccountConfig } from './types.account';
import { ListingPage } from './types.events';
import { GenericConfig } from './types.generic';
import { BookingHeaderConfig } from './types.header';
import { PassesConfig, PassesHeaderConfig } from './types.passes';
import { RTIConfig } from './types.rti';
import { TrainSelectionConfig } from './types.trainSelection';

export default class CFStore {
  header: BookingHeaderConfig = defaultHeaderProps;
  trainSelection: TrainSelectionConfig = defaultTrainSelectionProps;
  rti: RTIConfig = defaultRTIProps;
  generic: GenericConfig = defaultGenericProps;
  account: AccountConfig = defaultAccountProps;
  bookingSearch: any = null;
  passes: PassesConfig = defaultPassesProps;
  passesHeader: PassesHeaderConfig = defaultPassesHeaderProps;
  cardInformation: any = defaultRTIProps.reviewAndPay.cardInformation;
  events: ListingPage = defaultEventsProps;

  isLoading = true;
  error = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.init();
  }

  setCFData(data) {
    if (data && data.header) this.header = merge({}, this.header, data.header);
    if (data && data.trainSelection) this.trainSelection = merge({}, this.trainSelection, data.trainSelection);
    if (data && data.rti) this.rti = merge({}, this.rti, data.rti);
    if (data && data.generic) this.generic = merge({}, this.generic, data.generic);
    if (data && data.bookingSearch) this.bookingSearch = data.bookingSearch;

    this.account = merge({}, this.account, {
      trips: data.trips,
      registration: data.registration,
      tripDetails: data.tripDetails,
      passengersCF: data.savedPassengers,
      paymentsCF: data.paymentMethods,
      sidebarNavigation: data.sidebarNavigation,
      dashboard: data.overview,
      wallet: data.brightlineWallet,
      settings: data.profileSettings,
      commissions: data.commissions,
      brightlinePartners: data.brightlinePartners,
      rewardsGeneral: data.rewardsGeneral,
    });

    if (data && data.passesCheckout) this.passes = merge({}, this.passes, data.passesCheckout);
    if (data && data.passesHeader) this.passesHeader = merge({}, this.passesHeader, data.passesHeader);

    if (data && data.listingPage) {
      const updatedListingPage = { ...data.listingPage, eventItems: data.eventItems };
      this.events = merge({}, this.events, updatedListingPage);
    }

    //populate cardInformation
    if (data && data.rti && data.rti.reviewAndPay?.cardInformation)
      this.cardInformation = merge({}, this.cardInformation, data.rti.reviewAndPay.cardInformation);

    if (data && data.paymentMethods && data.paymentMethods?.cardInformation)
      this.cardInformation = merge({}, this.cardInformation, data.paymentMethods.cardInformation);
  }

  async fetchData(id) {
    const el = document.getElementById(id);

    if (!el) return;

    const { endpoint, endpointStorybook } = el.dataset;

    if (endpoint) {
      const result = await fiveMinutesCachedApi.get(endpoint, { id: endpoint });
      this.setCFData(result.data);
    }

    if (endpointStorybook) {
      const finalEndpoint = getFinalStorybookEndpoint(endpointStorybook);
      const result = await publicApi.get(finalEndpoint);
      this.setCFData(result.data);
    }
  }

  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  setError(error) {
    this.error = error;
  }

  async init() {
    try {
      await this.fetchData('booking-root');
      await this.fetchData('account-root');
      await this.fetchData('passes-root');
      await this.fetchData('events-root');
      this.setLoading(false);
    } catch (e) {
      console.error(e);
      this.setLoading(false);
      this.setError(true);
    }
  }
}
