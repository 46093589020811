import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTimer } from 'react-timer-hook';
import { rootStore, useStores } from 'stores';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { publicApi } from 'utils/api';
import { isKiosk } from 'utils/isKiosk';

export const usePrintingLogic = (props: { bookingLabel?: string }) => {
  const { bookingLabel } = props;
  const { bookingStore, kioskStore, accountStore } = useStores();
  const { displayPrintErrorModal } = kioskStore;
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const { confirmedBooking } = bookingStore.rti;
  const confirmedBookingRef = useRef(confirmedBooking);
  const { tripDetails } = accountStore;

  let bookingLabelFormated = bookingLabel || '';

  const location = useLocation();

  const { seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire() {
      setButtonDisabled(false);
    },
  });

  if (bookingLabel?.includes('#')) {
    const splitString = bookingLabel.split('#');
    bookingLabelFormated = `${splitString[0]}#`;
  }

  const startTimer = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 30);

    restart(time);
    setButtonDisabled(true);
  };

  const fetchPDF = async url => {
    try {
      const response = await publicApi.get(url, { responseType: 'arraybuffer' });

      // Convert the ArrayBuffer to a Blob
      return new Blob([response.data], { type: 'application/pdf' });
    } catch (error) {
      console.error('Error fetching PDF:', error);
      displayPrintErrorModal();

      return null;
    }
  };

  const getPdfUrl = () => {
    const isTripDetails = location.pathname.includes('trip-details');
    const ticketDocObj =
      isKiosk() && isTripDetails
        ? tripDetails?.ticketDocumentDetails
        : confirmedBookingRef?.current?.ticketDocumentDetails;

    return (ticketDocObj || []).filter(t => t.type === 'ticket')[0]?.link || '';
  };

  const printTicket = async event => {
    event.preventDefault();

    const buttonLabel = event.target.innerText;
    const pdfUrl = getPdfUrl();

    if (isButtonDisabled) return;

    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: buttonLabel,
        customLink: pdfUrl,
        externalLink: pdfUrl,
        linkType: 'linkClick',
      },
      tripManagement: {
        PNR: confirmedBooking?.tripDetails?.trip?.referenceNumber,
      },
    });

    // Fetch the PDF
    const pdfAsBlob = await fetchPDF(pdfUrl);

    if (!pdfAsBlob) {
      console.error('Error printing PDF');
      displayPrintErrorModal();

      return;
    }

    // Create a Blob URL for the PDF
    const blobUrl = URL.createObjectURL(pdfAsBlob);

    // Create a hidden iframe to print the PDF
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);

    // Print the PDF when the iframe finishes loading
    iframe.onload = function () {
      // @ts-ignore
      iframe.contentWindow.print();
      startTimer();

      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 10000);
    };
  };

  const addPrintButtonProps = args => {
    const isLabelOnly = typeof args === 'string';

    if (args.id === 'button-print' || isLabelOnly)
      return {
        onClick: printTicket,
        disabled: isButtonDisabled,
        label: `${isLabelOnly ? args : args.label}${isButtonDisabled ? ` (${seconds})` : ''}`,
      };

    return args;
  };

  useEffect(() => {
    confirmedBookingRef.current = confirmedBooking;
  }, [confirmedBooking]);

  return { printTicket, isButtonDisabled, seconds, addPrintButtonProps, bookingLabelFormated };
};
