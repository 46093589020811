import { useMemo } from 'react';

export const useNavItemStyles: any = colors =>
  useMemo(
    () => ({
      '--nav-item-text-color': colors?.nav?.text?.inactive,
      '--nav-item-text-hover-color': colors?.nav?.text?.hover,
      '--nav-item-text-focus-color': colors?.nav?.text?.focus,
      '--nav-item-background-color': colors?.nav?.background?.inactive,
      '--nav-item-background-hover-color': colors?.nav?.background?.hover,
      '--nav-item-background-focus-color': colors?.nav?.background?.focus,
      '--nav-item-border-focus-color': colors?.nav?.border?.focus,
      '--nav-item-underline-hover-color': colors?.nav?.underline?.hover,
      '--menu-item-text-color': colors?.menu?.text?.inactive,
      '--menu-item-text-hover-color': colors?.menu?.text?.hover,
      '--menu-item-text-focus-color': colors?.menu?.text?.focus,
      '--menu-item-text-disabled-color': colors?.menu?.text?.disabled,
      '--menu-item-text-active-color': colors?.menu?.text?.active,
      '--menu-item-background-color': colors?.menu?.background?.inactive,
      '--menu-item-background-hover-color': colors?.menu?.background?.hover,
      '--menu-item-background-focus-color': colors?.menu?.background?.focus,
      '--menu-item-background-disabled-color': colors?.menu?.background?.disabled,
      '--menu-item-background-active-color': colors?.menu?.background?.active,
      '--menu-item-border-focus-color': colors?.menu?.border?.focus,
    }),
    [colors]
  );
