import React, { useMemo } from 'react';

import { Icon } from '@atoms/Icon';
import TooltipV2 from '@atoms/Tooltip/v2/TooltipV2';

import { cn } from 'utils/classNames';

import './CircularProgressBar.scss';
import { CircularProgressBarProps } from './types';

const bem = cn('circular-progress-bar');

const CircularProgressBar = (props: CircularProgressBarProps) => {
  const {
    currentProgress,
    totalProgress,
    progressBarBackgroundColor,
    firstProgressBarGradientColor,
    secondProgressBarGradientColor,
    progressTooltipText,
    progressTooltipColors,
  } = props;

  const radius = 80;
  const progressGapAngle = 90;

  const circumference = 2 * Math.PI * radius;
  const totalProgressBarLength = circumference - progressGapAngle * (circumference / 360);
  const currentProgressLength = (totalProgressBarLength / totalProgress) * currentProgress;

  const styles: any = useMemo(
    () => ({
      '--total-progress-bar-length': circumference,
      '--current-progress-bar-length': currentProgressLength,
      '--gap': circumference - currentProgressLength,
    }),
    [currentProgressLength, circumference]
  );

  return (
    <div className={bem()} style={styles}>
      <div className={bem('wrapper')}>
        <svg
          className={bem('container')}
          width="210px"
          height="210px"
          viewBox="0 0 210 210"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
              <stop offset="0%" stopColor={firstProgressBarGradientColor} />
              <stop offset="100%" stopColor={secondProgressBarGradientColor} />
            </linearGradient>
          </defs>
          <circle
            className={bem('progress-border')}
            cx="100"
            cy="105"
            r={radius}
            strokeDasharray={`${totalProgressBarLength}, ${circumference - totalProgressBarLength}`}
            strokeDashoffset={`-${(circumference - totalProgressBarLength) / 2}`}
          />
          <circle
            className={bem('progress-background')}
            cx="100"
            cy="105"
            r={radius}
            stroke={progressBarBackgroundColor}
            strokeDasharray={`${totalProgressBarLength}, ${circumference - totalProgressBarLength}`}
            strokeDashoffset={`-${(circumference - totalProgressBarLength) / 2}`}
          />
          {currentProgress && (
            <>
              <circle
                className={bem('current-progress-border')}
                cx="100"
                cy="105"
                r={radius}
                strokeDasharray={`${currentProgressLength}, ${circumference - currentProgressLength}`}
                strokeDashoffset={`-${(circumference - totalProgressBarLength) / 2}`}
              />
              <circle
                className={bem('current-progress-background')}
                cx="100"
                cy="105"
                r={radius}
                stroke="url(#gradient)"
                strokeDasharray={`${currentProgressLength}, ${circumference - currentProgressLength}`}
                strokeDashoffset={`-${(circumference - totalProgressBarLength) / 2}`}
              />
            </>
          )}
        </svg>
        {progressTooltipText && (
          <TooltipV2
            className={bem('tooltip')}
            text={`
              <div class=${bem('text')}>
                <span class=${bem('total')}>${totalProgress}</span> ${progressTooltipText}
              </div>
            `}
            renderTooltipContent={() => (
              <span>
                <Icon className={bem('total-icon')} name="full-circle" />
              </span>
            )}
            colors={progressTooltipColors}
            popoverPlacement="right"
          />
        )}
      </div>
    </div>
  );
};

export default CircularProgressBar;
