import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import PageLoadingPlaceholders from '@account/components/PageLoadingPlaceholder/PageLoadingPlaceholders';
import { useCMSContent } from '@account/hooks/useCMSContent';
import { AutoRenewPayment } from '@account/routes/Wallet/components/AutoRenewPayment/AutoRenewPayment';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './Wallet.scss';
import { WalletDesktop } from './components/WalletDesktop';
import { WalletMobile } from './components/WalletMobile';

const bem = cn('account-wallet');

const Wallet = observer(() => {
  useCMSContent('wallet');
  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);
  const { accountStore } = useStores();
  const { wallet } = accountStore;
  const { passToRenew, isLoading, scrollPositionToRestore, setScrollPositionToRestore } = wallet;

  useEffect(() => {
    // go to the saved scroll position after submitting auto-renew
    if (scrollPositionToRestore) {
      window.scrollTo(scrollPositionToRestore.x, scrollPositionToRestore.y);
      setScrollPositionToRestore(null);
    }
  }, [scrollPositionToRestore, setScrollPositionToRestore]);

  if (isLoading) return <PageLoadingPlaceholders />;

  return (
    <div className={bem()}>
      <div className={bem('content')}>{isMobile ? <WalletMobile /> : <WalletDesktop />}</div>
      {passToRenew && <AutoRenewPayment />}
    </div>
  );
});

export default Wallet;
