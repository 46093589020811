import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';
import { CostSummaryProps } from 'stores/BookingStore/sections/RTI/types.costSummary';

import { cn, cx } from 'utils/classNames';

import { bem as rtiBem} from '@booking/routes/RTI';
import { CancellationDetailContent } from './CancellationDetailContent';

const bem = cn('cost-summary');

export const CancellationDetail = observer(({ children, isRtiDrawer }: CostSummaryProps) => {
  const { cfStore } = useStores();

  const { navigation } = cfStore.account.tripDetails;
  const { cancellationDetailLabel } = navigation.cancelTrip;

  return (
    <div className={bem()}>
        <div className={cx(rtiBem('card', { isRtiDrawer }))}>
          <h3 className={bem('title')}>
            {cancellationDetailLabel}
          </h3>

          {children}

          <CancellationDetailContent />
        </div>
    </div>
  );
});
