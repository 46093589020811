import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';
import { formatTravelAgentCode } from 'utils/travelAgentCodeUtils';

import './SettingEntryTravelAgent.scss';
import { SettingEntryTravelAgentProps } from './types';

const bem = cn('setting-entry-travel-agent');
export const SettingEntryTravelAgent = (props: SettingEntryTravelAgentProps) => {
  const { label, value } = props;
  const {
    accountStore,
    cfStore: { account },
  } = useStores();
  const { userProfile } = accountStore.profile;
  const elementName: string = !userProfile?.travelAgentCode
    ? 'setting-value-travel-agent-default'
    : 'setting-value-travel-agent';

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const travelAgentCodeLength: number = userProfile?.travelAgentCode?.length || 0;
  const travelAgentCodeInvalid: boolean = travelAgentCodeLength < 9;

  useEffect(() => {
    if (travelAgentCodeLength > 0 && travelAgentCodeInvalid) setShowErrorMessage(true);
  }, [travelAgentCodeLength, travelAgentCodeInvalid]);

  if (!label) return null;

  return (
    <div>
      <div className={bem()} style={showErrorMessage ? { border: '1px solid red' } : {}}>
        <span className={bem('setting-label-travel-agent')}>{label}</span>
        <span className={bem(elementName)}>
          {userProfile?.travelAgentCode ? formatTravelAgentCode(userProfile.travelAgentCode) : value}
        </span>
      </div>
      {showErrorMessage && (
        <div className={bem('setting-error-label-travel-agent')}>
          <span>{account.settings.travelAgentCodeInvalidLabel}</span>
        </div>
      )}
    </div>
  );
};
