import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { rootStore, useStores } from 'stores';
import { TripConfig } from 'stores/AccountStore/sections';

import LinkButton from '@atoms/LinkButton/LinkButton';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';

import './PastTripsList.scss';
import { PastTripItem } from './components/PastTripItem';
import { PastTripsListProps } from './types';

const bem = cn('trips-list-past');

export const PastTripsList = observer((props: PastTripsListProps) => {
  const { variant } = props;
  const { accountStore, cfStore } = useStores();
  const { trips } = accountStore;
  const navigate = useNavigate();
  const [items, setItems] = useState<TripConfig[]>([]);
  const { pastTripsPagination } = trips;
  const { pageNumber, itemsPerPage } = pastTripsPagination;
  const { account } = cfStore;
  const { trips: i18n } = account;

  const pastListItemsLimit = useMemo(() => parseInt(i18n.pastTripsCount) || 5, [i18n.pastTripsCount]);

  const onSeeAllPastClick = () => {
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: `${i18n.seeAllPast} (${trips.past.length})`,
        customLink: '/past-trips',
        externalLink: '',
        linkType: 'linkClick',
      },
    });
    navigate('/past-trips');
  };

  useEffect(() => {
    if (trips.past.length > 0)
      if (variant === 'short') setItems(trips.past.slice(0, pastListItemsLimit));
      else setItems(trips.past.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage));
    else setItems([]);
  }, [variant, pastListItemsLimit, trips.past, pageNumber, itemsPerPage]);

  return (
    <div className={bem()}>
      <div className={bem('header')}>
        <h2 className={bem('title', { variant: variant })}>{i18n.pastTripsLabel}</h2>
        {variant === 'short' && !!trips.past.length && trips.past.length > pastListItemsLimit && (
          <LinkButton
            label={`${i18n.seeAllPast} (${trips.past.length})`}
            onClick={onSeeAllPastClick}
            className={bem('see-all-past')}
          />
        )}
      </div>
      <div className={bem('list')}>
        {!!items.length && items.map((trip, index) => <PastTripItem key={`trip-${pageNumber}-${index}`} {...trip} />)}
      </div>
    </div>
  );
});
