import { withStyleSystem } from 'hocs/withStyleSystem';
import React, { useMemo } from 'react';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import Image from '@atoms/Image/Image';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';
import { SimpleTitleProps } from '@atoms/Title/types';

import { cn, cx } from 'utils/classNames';

import './TeaserV2.scss';
import { TeaserV2Props } from './types';

const bem = cn('teaser-v2');

const ExtendedTitle = withStyleSystem<SimpleTitleProps>(Title);

const TeaserV2 = (props: TeaserV2Props) => {
  const {
    alignment = 'left',
    link,
    colors,
    id,
    descriptionTitle,
    imageText,
    imageText2,
    imageText3,
    imageTextSize,
    imageTextSize2,
    imageTextSize3,
    openLinkInNewWindow,
    addPadingToCard,
    topIconImage,
    titleAboveImage,
    titleAboveImageSize,
    eyebrowAboveImage,
    eyebrowAboveImageSize,
    primaryButtonCta,
    primaryButtonBorderColor,
    primaryButtonBgColor,
    primaryButtonTextColor,
    secondaryButtonCta,
    secondaryButtonBgColor,
    secondaryButtonBorderColor,
    secondaryButtonTextColor,
    descriptionRef,
  } = props;
  const {
    image,
    title,
    button,
    imageButton,
    imageButtonTextColor,
    description,
    imageAspectRatio,
    imageTextColor,
    imageTextColor2,
    imageTextColor3,
    imageTextAlignment,
    imageTextAlignment2,
    imageTextAlignment3,
  } = props;

  const teaserWrapperProps = useMemo(() => (link?.url ? { href: link.url, target: link.target } : {}), [link]);

  const style: any = useMemo(
    () => ({
      '--teaser-background-color': colors?.background,
      '--teaser-border-focus-color': colors?.borderFocus,
      '--teaser-title-color': colors?.title,
      '--teaser-description-color': colors?.description,
      '--teaser-image-aspect-ratio': imageAspectRatio,
      '--teaser-padding-value': addPadingToCard,
      '--teaser-primary-cta-border-color': primaryButtonBorderColor,
      '--teaser-secondary-cta-border-color': secondaryButtonBorderColor,
    }),
    [colors, imageAspectRatio, addPadingToCard, primaryButtonBorderColor, secondaryButtonBorderColor]
  );

  const hasAddPadding = useMemo(
    () => (addPadingToCard ? (addPadingToCard === '0px' ? '' : addPadingToCard) : ''),
    [addPadingToCard]
  );

  const imagetextcolor1: any = {
    '--text-color': imageTextColor,
  };

  const imagetextcolor2: any = {
    '--text-color': imageTextColor2,
  };

  const imagetextcolor3: any = {
    '--text-color': imageTextColor3,
  };

  const redirectOnClick = e => {
    if (link?.url && !e.target.classList.contains('blte-btn')) window.location.href = link?.url;
  };

  const handleNewWindowClick = () => {
    window.open(imageButton?.link?.url, '_blank', 'width=800,height=600');
  };

  const handleNewLinkClick = () => {
    window.open(button?.link?.url, '_blank');
  };

  return (
    <li
      className={bem({ clickable: !!link?.url, rounded: !!colors?.background, alignment })}
      {...teaserWrapperProps}
      aria-current={!link?.url}
      style={style}
      id={id}
      aria-label={title?.text}
      role="link"
      tabIndex={0}
      onKeyDown={e => (e.key === 'Enter' ? redirectOnClick(e) : false)}
    >
      <div role="group" aria-label="item">
        {((topIconImage && topIconImage?.src) || (titleAboveImage && titleAboveImage?.text) || eyebrowAboveImage) && (
          <div className={bem('icon')}>
            {topIconImage && (
              <Icon
                className={bem('top-icon')}
                externalIcon={topIconImage?.src}
                externalIconAlt={topIconImage?.alt}
                size="medium"
              />
            )}
            {titleAboveImage && <Title {...titleAboveImage} size={titleAboveImageSize || 'h3'} />}
            {eyebrowAboveImage && <Text as={eyebrowAboveImageSize || 'h6'} text={eyebrowAboveImage} />}
          </div>
        )}
        {image && (
          <div className={bem({ addPadingToCard: !!hasAddPadding })} onClick={e => redirectOnClick(e)}>
            <div className={bem('image', { addPadingToCard: !!hasAddPadding })}>
              <Image {...image} />
            </div>
            <div className={bem('text', { imageTextAlignment })}>
              <div className={bem('text-wrapper')}>
                <div className={bem('text-group')}>
                  {imageText && (
                    <h1 className={bem('text-value', { imageTextAlignment })}>
                      <Text
                        as="span"
                        text={imageText}
                        className={bem('text-value-span', { heading: imageTextSize ? imageTextSize : 'h4' })}
                        style={imagetextcolor1}
                      />
                    </h1>
                  )}
                  {imageButton && imageButton.label && (
                    <div className={bem('image-button')}>
                      <Button
                        colors={{ text: imageButtonTextColor }}
                        color={imageButton.color ? imageButton.color : 'transparent'}
                        {...imageButton}
                        {...(openLinkInNewWindow && { onClick: handleNewWindowClick })}
                      />
                    </div>
                  )}
                </div>
                <div className={bem('text-group')}>
                  {imageText2 && (
                    <h1 className={bem('text-value', { imageTextAlignment2 })}>
                      <Text
                        as="span"
                        text={imageText2}
                        className={bem('text-value-span', { heading: imageTextSize2 ? imageTextSize2 : 'h4' })}
                        style={imagetextcolor2}
                      />
                    </h1>
                  )}
                  {imageText3 && (
                    <h1 className={bem('text-value', { imageTextAlignment3 })}>
                      <Text
                        as="span"
                        text={imageText3}
                        className={bem('text-value-span', { heading: imageTextSize3 ? imageTextSize3 : 'h4' })}
                        style={imagetextcolor3}
                      />
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {(title?.text ||
          description?.text ||
          (!link?.url && button && button.label) ||
          primaryButtonCta?.label ||
          secondaryButtonCta?.label) && (
          <div className={bem('content', { addPadingToCard: !!hasAddPadding })}>
            {title && title.text && (
              <ExtendedTitle {...title} link={link} tabIndex={-1} customClassName={cx(bem('title'))} />
            )}

            {descriptionTitle && (
              <div className={bem('description-title')}>
                <Text text={descriptionTitle} as="h6" />
              </div>
            )}

            {description && description.text && (
              <div
                ref={hasAddPadding ? descriptionRef : undefined}
                className={bem('description')}
                onClick={e => redirectOnClick(e)}
              >
                <Text {...description} />
              </div>
            )}

            {button && button.label && button.link && (
              <div
                className={bem('cta')}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    handleNewLinkClick();
                  }
                }}
              >
                <LinkButton {...button} variant="big" hideAriaLabel />
              </div>
            )}
            {(primaryButtonCta || secondaryButtonCta) && (
              <div className={bem('cta-buttons')}>
                {primaryButtonCta && primaryButtonCta.label && (
                  <Button
                    className={bem('primaryButtonCta')}
                    colors={{ text: primaryButtonTextColor, backgroundOrBorder: primaryButtonBgColor }}
                    {...primaryButtonCta}
                  />
                )}
                {secondaryButtonCta && secondaryButtonCta.label && (
                  <Button
                    className={bem('secondaryButtonCta')}
                    colors={{ text: secondaryButtonTextColor, backgroundOrBorder: secondaryButtonBgColor }}
                    {...secondaryButtonCta}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default TeaserV2;
