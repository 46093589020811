import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import Logo from '@atoms/Logo/Logo';
import { logos } from '@atoms/Logo/constants';
import { AddToCart } from '@organisms/GlobalHeader/components/AddToCart';

import { cn, cx } from 'utils/classNames';

import './GlobalHeader.scss';
import { BottomNav } from './components/BottomNav';
import { TopNav } from './components/TopNav';
import { GlobalHeaderProps } from './types';
import { useGlobalHeaderStyles } from './useGlobalHeaderStyles';

const bem = cn('global-header');

export const GlobalHeader = observer((props: GlobalHeaderProps) => {
  const { headerStore, accountStore } = useStores();
  const { isMenuVisible, setMenuVisibility, authenticateWithZendesk } = headerStore;
  const { profile } = accountStore;
  const { isAuthenticatedZendesk, setIsAuthenticatedZendesk } = profile;
  const [isSkipToContentPressed, setIsSkipToContentPressed] = useState(false);

  const { bottomNav, topNav, buyTicketsButton, colors, addToCartButton } = props;
  const { logo = logos.charcoal, logoAlt, onClick, skipToContentBtnLabel, skipToCookieBannerBtnLabel } = props;

  const styles = useGlobalHeaderStyles(colors);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        const mainContent = document.getElementById('main');
        const rotationButton = document.getElementById('rotation-button-1');
        if (document.activeElement === mainContent && rotationButton && isSkipToContentPressed) {
          event.preventDefault();
          rotationButton.focus();
          setIsSkipToContentPressed(false);
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isSkipToContentPressed]);

  const toggleButton = () => {
    document.body.classList.toggle('blockScroll');
    setMenuVisibility(!isMenuVisible);
  };

  const handleSkipToMain = () => {
    const mainContent = document.getElementById('main');
    if (mainContent) mainContent?.focus();
    setIsSkipToContentPressed(true);
  };

  useEffect(() => {
    const zendeskToken = localStorage.getItem('zendeskToken');

    if (zendeskToken && !isAuthenticatedZendesk) {
      setIsAuthenticatedZendesk(true);
      authenticateWithZendesk(zendeskToken);
    }
  }, [authenticateWithZendesk, isAuthenticatedZendesk, setIsAuthenticatedZendesk]);

  return (
    <div>
      <header className={bem()} style={styles}>
        <Button
          color="yellow"
          size="small"
          label={skipToContentBtnLabel}
          className={bem('skip-link')}
          onClick={handleSkipToMain}
        />
        <Button
          color="yellow"
          size="small"
          label={skipToCookieBannerBtnLabel}
          link={{ url: '#footer_alert_banner' }}
          className={bem('skip-link')}
        />
        <div className={bem('container', { empty_top_items: !topNav?.data?.length })}>
          <Logo logo={logo} logoAlt={logoAlt} link="/" />
          <div className={cx(bem('menu'), { isMenuVisible })}>
            <div className={bem('navigation')}>
              {topNav && topNav.data && <TopNav {...topNav} />}
              {bottomNav && (
                <BottomNav
                  {...bottomNav}
                  onClick={onClick}
                  buyTicketsButton={buyTicketsButton}
                  addToCartButton={addToCartButton}
                />
              )}
            </div>
            <div className={bem('buttons')}>
              {addToCartButton && addToCartButton.link && <AddToCart {...addToCartButton} />}
              <button onClick={toggleButton} className={bem('menu-button')}>
                Menu
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
});
