import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useStores } from 'stores';

import { AppBanner } from '@molecules/AppBanner';
import { BookingSearch } from '@organisms/BookingSearch';

import { cn } from 'utils/classNames';
import { scrollToTop } from 'utils/isKiosk';

import './HomePage.scss';

const bem = cn('kiosk-home-page');

export const HomePage = observer(() => {
  const { cfStore, kioskStore } = useStores();
  const { bookingSearch } = cfStore;

  const { homepage, banner } = kioskStore;

  const style: any = useMemo(
    () => ({
      '--kiosk-homepage-background-color': homepage.backgroundColor,
      '--kiosk-homepage-welcome-message-text-color': homepage.welcomeMessage.textColor,
      '--kiosk-homepage-welcome-message-background-color': homepage.welcomeMessage.backgroundColor,
    }),
    [homepage.backgroundColor, homepage.welcomeMessage.backgroundColor, homepage.welcomeMessage.textColor]
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className={bem()} style={style}>
      <div className={bem('content')}>
        <div className={bem('welcome')}>{homepage.welcomeMessage.text}</div>
        <div className={bem('widget')}>{bookingSearch && <BookingSearch {...bookingSearch} />}</div>
        <div className={bem('banner')}>
          <AppBanner {...banner} />
        </div>
      </div>
    </div>
  );
});
