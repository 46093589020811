import React, { useEffect, useState } from 'react';

import { cn } from 'utils/classNames';

import './Accordion.scss';
import AccordionItem from './AccordionItem';
import { DEFAULT_FONT_VARIANT, DEFAULT_SIZE } from './constants.storybook';
import { AccordionProps } from './types';

const bem = cn('accordion');

const Accordion = (props: AccordionProps) => {
  const { items = [], RenderedItem = AccordionItem, className = '', size = DEFAULT_SIZE, id, hideExpandAll } = props;
  const { fontVariant = DEFAULT_FONT_VARIANT } = props;
  const { expandAllText = 'Expand all', collapseAllText = 'Collapse all' } = props;
  const [openedItems, setOpenedItems] = useState<string[]>([]);

  const expandAllActive = !items.every(v => openedItems.includes(v.id));

  useEffect(() => {
    const { hash } = window.location;

    if (!hash) return;

    const id = hash.split('#')[1];
    const hashElement = items.find(el => el.id === id);
    if (hashElement) setOpenedItems([id]);
  }, [items]);

  const handleToggle = (item, expanded) => ev => {
    ev.preventDefault();
    setOpenedItems(expanded ? openedItems.filter(el => el !== item.id) : [...openedItems, item.id]);
  };

  const handleClick = () => setOpenedItems(expandAllActive ? items.map(el => el.id) || [] : []);

  const handleKeyDown = e => e.key === 'Enter' && handleClick();

  return (
    <div
      className={bem({
        [className]: !!className,
      })}
      id={id}
    >
      {!hideExpandAll && (
        <button
          type="button"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          className={bem('expand')}
          aria-expanded={!expandAllActive}
          aria-controls="accordion-content-items"
        >
          {expandAllActive ? expandAllText : collapseAllText}
        </button>
      )}
      {items.map((item, index) => {
        const expanded = openedItems.includes(item.id);

        return (
          <div key={`accordion-item-${index}`}>
            <RenderedItem
              {...item}
              size={size}
              fontVariant={fontVariant}
              tabIndex={0}
              expanded={expanded}
              onToggle={handleToggle(item, expanded)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
