import { SeatingDetailsProps } from '@booking/routes/RTI/SeatSelection/types';

import { UserProfileConfig } from '../../../AccountStore/sections/UserProfile/types';
import { SelectedTicket } from '../Tickets/types';
import { Station } from '../Tickets/types.journey';
import { BookingCostSummary } from './types.costSummary';

export interface TripDetailsProps {
  trip?: any;
  status?: string;
  passengerSeatingDetails?: SeatingDetailsProps[];
  contact?: any;
  products?: any;
  costSummary?: BookingCostSummary;
  bookingRules?: BookingRulesProps;
}

export interface BookingRulesProps {
  hasProvisionalChanges: boolean;
  isBookingCancellable: boolean;
  latestBookingCancellableDate: string;
  isModifiable: boolean;
  outBoundRules: any;
  inBoundRules: any;
}

export interface ApplicableTravelPass {
  currentQuantityApplied: number;
  isApplicableToBooking: boolean;
  travelPass: {
    code: string;
    passType: string;
    passName: string;
    classType: string;
    issuedDate: string;
    endDate: string;
    isAutoRenew: boolean;
    originStation: Station;
    destinationStation: Station;
    isRoundTrip: boolean;
    availableRides: {
      purchased: number;
      remaining: number;
    };
    passengerName: string;
    isShareable: boolean;
  };
}

export interface LicensePlateInformation {
  state: string;
  number: string;
}

export interface ApplicableParkingPass {
  passName: string;
  station: Station;
  licensePlateInformation: LicensePlateInformation;
  endDate: string;
  isAutoRenew: boolean;
}

export interface ApplicableCreditPass {
  creditPass: {
    code: string;
    passName: string;
    creditAvailable: number;
  };
  currentAmountApplied: number;
}

export interface ProvisionalBookingProps {
  tripDetails: TripDetailsProps;
  bookingSession?: {
    accessToken: string;
    refreshToken: string;
  };
  applicableTravelPasses?: ApplicableTravelPass[];
  applicableParkingPass?: ApplicableParkingPass[];
  applicableCreditPasses?: ApplicableCreditPass[];
  loyaltyProgramUsed?: string;
}

export interface CreateProvisionalPayload {
  outbound?: SelectedTicket;
  inbound?: SelectedTicket;
  customer?: UserProfileConfig;
  promocode?: string;
}

export interface ModifyTripRequestConfig {
  routes: {
    direction: 'inbound' | 'outbound';
    departureDate: string;
    serviceId: string;
    serviceName: string;
    serviceType: string;
    startValidityDate: string;
    seatProductTariffCode: string;
  }[];
}

const defaultTripDetails: TripDetailsProps = {};

export const defaultProvisionalBooking: ProvisionalBookingProps = { tripDetails: defaultTripDetails };

export const defaultConfirmedBooking: any = {
  bookingSession: {
    accessToken: '',
    refreshToken: '',
  },
  tripDetails: {
    trip: {
      referenceNumber: '',
    },
    status: '',
    passengerSeatingDetails: [],
    contact: {
      email: '',
    },
  },
  ticketDocumentDetails: [],
};
