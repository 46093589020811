import { cloneDeep, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { rootStore, useStores } from 'stores';
import { DefaultProduct, Journey, SeatProduct } from 'stores/BookingStore/sections/Tickets/types.journey';
import { TableConfig } from 'stores/CFStore/types.header';

import { PriceConfig, TableProps } from '@atoms/Table/types';
import { defaultJourney, smartDefaultProduct } from '@organisms/FareSelectionTable/constants.storybook';
import { FareSelectionTableProps } from '@organisms/FareSelectionTable/types';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { findTableConfigByJourney } from 'utils/brandedFaresUtils';
import { parseRteIcons } from 'utils/rteUtils';

import { FareSelectionTableConfig } from '../../../stores/BookingStore/sections/Tickets/types';
import { isAuthorMode } from '../../../utils/metas';
import { TableCellContentRenderer } from './TableCellContentRenderer';

export const useFareSelectionTableLogic = (props: FareSelectionTableProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { bookingStore, cfStore } = useStores();
  const { fareSelectionTable, selectItem, confirmTicket, setFareSelectionTable } = bookingStore.tickets;
  const { upsellModal, setUpsellModal } = bookingStore.tickets;
  const { desktopFareCompareItems } = cfStore.header.brandedFares;

  const authorViewMockData: FareSelectionTableConfig = {
    journeyID: defaultJourney.id,
    journey: {
      ...defaultJourney,
      defaultSmartSaverProduct: undefined,
    },
    defaultProduct: smartDefaultProduct,
  };

  const { journey, defaultProduct } = isAuthorMode() ? authorViewMockData : fareSelectionTable || {};

  const smartSaverTableConfig = findTableConfigByJourney('smart_saver', desktopFareCompareItems, journey);
  const smartTableConfig = findTableConfigByJourney('smart', desktopFareCompareItems, journey);
  const premiumTableConfig = findTableConfigByJourney('premium', desktopFareCompareItems, journey);

  const { defaultSmartSaverProduct, defaultSmartProduct, defaultPremiumProduct } = journey || {};

  const isSmartSaverAvailable = !isEmpty(defaultSmartSaverProduct);
  const isSmartAvailable = !isEmpty(defaultSmartProduct);
  const isPremiumAvailable = !isEmpty(defaultPremiumProduct);

  const isSmartSaverProduct = defaultProduct?.productId === 'SMART_SAVER';
  const isSmartProduct = defaultProduct?.productId === 'SMART_STANDARD';
  const isPremiumProduct = defaultProduct?.productId === 'PREMIUM_STANDARD';

  const parseCellPrice = useCallback(
    (priceText?: string) => {
      if (!priceText) return undefined;

      const smartSaverSeatProduct = journey?.seatProducts.find(el => el.productCode === 'SMART_SAVER');
      const smartSeatProduct = journey?.seatProducts.find(el => el.productCode === 'SMART_STANDARD');
      const premiumSeatProduct = journey?.seatProducts.find(el => el.productCode === 'PREMIUM_STANDARD');

      const priceConfig: PriceConfig = {
        price: '',
        originalPrice: '',
        perGuestLabel: '',
        isUnavailableMessage: undefined,
      };

      const matches = priceText.match(/\{([^}]+)}/g);
      if (matches?.length) {
        const match = matches[0].slice(1, -1);

        switch (match) {
          case 'smartSaverPrice': {
            if (!smartSaverSeatProduct) {
              priceConfig.isUnavailableMessage = defaultSmartSaverProduct?.unavailableMessage || 'Sold out';
              break;
            }

            const displayedPrice = smartSaverSeatProduct.displayedPrice || 0;
            const originalPrice = smartSaverSeatProduct.fares?.[0]?.originalPrice || displayedPrice;

            priceConfig.price = `$${displayedPrice.toFixed(2)}`;
            if (displayedPrice !== originalPrice) priceConfig.originalPrice = `$${originalPrice.toFixed(2)}`;

            break;
          }
          case 'smartPrice': {
            if (!smartSeatProduct) {
              priceConfig.isUnavailableMessage = defaultSmartProduct?.unavailableMessage || 'Sold out';
              break;
            }

            const displayedPrice = smartSeatProduct.displayedPrice || 0;
            const originalPrice = smartSeatProduct.fares?.[0]?.originalPrice || displayedPrice;

            priceConfig.price = `$${displayedPrice.toFixed(2)}`;
            if (displayedPrice !== originalPrice) priceConfig.originalPrice = `$${originalPrice.toFixed(2)}`;

            break;
          }
          case 'premiumPrice': {
            if (!premiumSeatProduct) {
              priceConfig.isUnavailableMessage = defaultPremiumProduct?.unavailableMessage || 'Sold out';
              break;
            }

            const displayedPrice = premiumSeatProduct.displayedPrice || 0;
            const originalPrice = premiumSeatProduct.fares?.[0]?.originalPrice || displayedPrice;

            priceConfig.price = `$${displayedPrice.toFixed(2)}`;
            if (displayedPrice !== originalPrice) priceConfig.originalPrice = `$${originalPrice.toFixed(2)}`;
          }
        }

        priceConfig.perGuestLabel = priceText.slice(matches[0].length);
      }

      return priceConfig;
    },
    [journey?.seatProducts, defaultSmartSaverProduct, defaultSmartProduct, defaultPremiumProduct]
  );

  const parsePassEligibility = useCallback(
    (passEligibilityText?: string) => {
      if (!passEligibilityText) return undefined;

      const smartSaverSeatProduct = journey?.seatProducts.find(el => el.productCode === 'SMART_SAVER');
      const smartSeatProduct = journey?.seatProducts.find(el => el.productCode === 'SMART_STANDARD');
      const premiumSeatProduct = journey?.seatProducts.find(el => el.productCode === 'PREMIUM_STANDARD');

      switch (passEligibilityText) {
        case 'smartSaverPassEligible': {
          return smartSaverSeatProduct;
        }
        case 'smartPassEligible': {
          return smartSeatProduct;
        }
        case 'premiumPassEligible': {
          return premiumSeatProduct;
        }
        default: {
          return undefined;
        }
      }
    },
    [journey?.seatProducts]
  );

  const tableConfig: TableProps | undefined = useMemo(() => {
    let tableConfig: TableConfig | undefined;

    if (!isAuthorMode())
      if ((isSmartProduct || isSmartSaverProduct) && isSmartSaverAvailable) tableConfig = smartSaverTableConfig;
      else if (isSmartProduct) tableConfig = smartTableConfig;
      else tableConfig = premiumTableConfig;
    else tableConfig = { ...props } as TableConfig;

    if (!tableConfig) return undefined;

    const processedTableHeaders = cloneDeep(tableConfig.tableHeaders);
    const processedTableRows = cloneDeep(tableConfig.tableRows);

    if (!processedTableRows) return undefined;

    Object.values(processedTableHeaders).forEach(headerCell => {
      if (!headerCell) return;

      headerCell.passEligibilityData = parsePassEligibility(headerCell.passEligibleText);
    });

    Object.values(processedTableRows).forEach((tableRow, rowIndex, array) => {
      tableRow.forEach((tableCell, cellIndex) => {
        if (!tableCell) return;

        tableCell.cellContent = parseRteIcons(tableCell.cellContent);

        const priceConfig = parseCellPrice(tableCell.farePriceText);

        if (priceConfig && array[rowIndex][cellIndex]) tableCell.farePrice = priceConfig;
      });
    });

    return {
      tableHeaders: processedTableHeaders,
      tableRows: processedTableRows,
      minTableWidth: tableConfig.minTableWidth,
      isStickyHeader: tableConfig.isStickyHeader,
      stickyColIndex: tableConfig.stickyColIndex,
      isCollapsable: tableConfig.isCollapsable,
      expandLabel: tableConfig.expandLabel,
      collapseLabel: tableConfig.collapseLabel,
      cellRenderer: TableCellContentRenderer,
    };
  }, [
    smartSaverTableConfig,
    smartTableConfig,
    premiumTableConfig,
    isSmartProduct,
    isSmartSaverAvailable,
    isSmartSaverProduct,
    parseCellPrice,
    parsePassEligibility,
    props,
  ]);

  const getPromoCode = () => {
    const hasLocationState = location.state && location.state.ticket;
    const searchParams = hasLocationState
      ? location.state.ticket
      : Object.fromEntries(new URLSearchParams(location.search));
    const { promo_code } = searchParams;

    return promo_code || '';
  };

  const navigateToRTI = bookingResponse => {
    const promo_code = getPromoCode();
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: 'Go to RTI page',
        customLink: promo_code ? `/rti?promo_code=${promo_code}` : '/rti',
        externalLink: '',
        linkType: 'linkClick',
      },
      tripManagement: {
        PNR: bookingResponse?.tripDetails?.trip.referenceNumber,
      },
    });
    setFareSelectionTable(undefined);
    if (promo_code) 
      navigate(`/rti?promo_code=${promo_code}`);
     else 
      navigate('/rti');
    
  };

  const handleTicketSelected = (journey: Journey, defaultProduct: DefaultProduct) => {
    selectItem(journey, defaultProduct);

    setFareSelectionTable(undefined);
    setUpsellModal(undefined);

    confirmTicket(navigateToRTI);
  };

  const handleSmartSaverButtonClicked = () => {
    if (!isSmartSaverAvailable || !journey || !defaultSmartSaverProduct) return;

    const smartSaverSeatProduct = journey?.seatProducts.find(el => el.productCode === 'SMART_SAVER') as
      | SeatProduct
      | undefined;
    const smartSeatProduct = journey?.seatProducts.find(el => el.productCode === 'SMART_STANDARD') as
      | SeatProduct
      | undefined;

    const smartSaverProduct: DefaultProduct = {
      productId: smartSaverSeatProduct?.productCode || 'SMART_SAVER',
    };

    const smartProduct: DefaultProduct = {
      productId: smartSeatProduct?.productCode || 'SMART_STANDARD',
    };

    const isSmartSaverCheaper =
      (smartSaverSeatProduct?.displayedPrice &&
        smartSeatProduct?.displayedPrice &&
        smartSaverSeatProduct?.displayedPrice < smartSeatProduct?.displayedPrice) ||
      false;

    if (!upsellModal && smartSeatProduct && isSmartSaverCheaper)
      setUpsellModal({
        selectedFare: 'SMART_SAVER',
        journey,
        showUpsellModal: true,
        handleOnUpgrade: () => handleTicketSelected(journey, smartProduct),
        handleOnContinue: () => handleTicketSelected(journey, smartSaverProduct),
        handleDrawerClose: () => setFareSelectionTable(undefined),
        handleDrawerGoBack: () => setFareSelectionTable(undefined),
      });
    else handleTicketSelected(journey, defaultSmartSaverProduct);
  };

  const handleSmartButtonClicked = () => {
    if (!isSmartAvailable || !journey || !defaultSmartProduct) return;

    const smartSeatProduct = journey?.seatProducts.find(el => el.productCode === 'SMART_STANDARD') as
      | SeatProduct
      | undefined;

    const smartProduct: DefaultProduct = {
      productId: smartSeatProduct?.productCode || 'SMART_STANDARD',
    };

    handleTicketSelected(journey, smartProduct);
  };

  const handlePremiumButtonClicked = () => {
    if (!isPremiumAvailable || !journey || !defaultPremiumProduct) return;
    handleTicketSelected(journey, defaultPremiumProduct);
  };

  const attachEventListeners = () => {
    if (isAuthorMode()) return [];

    const smartSaverButtons = document.querySelectorAll('button[id^="smartSaverSelectButton"]');
    const smartButtons = document.querySelectorAll('button[id^="smartSelectButton"]');
    const premiumButtons = document.querySelectorAll('button[id^="premiumSelectButton"]');

    smartSaverButtons.forEach(button => {
      button.addEventListener('click', handleSmartSaverButtonClicked);
    });
    smartButtons.forEach(button => {
      button.addEventListener('click', handleSmartButtonClicked);
    });
    premiumButtons.forEach(button => {
      button.addEventListener('click', handlePremiumButtonClicked);
    });

    return [smartSaverButtons, smartButtons, premiumButtons];
  };

  const cleanupEventListeners = (smartSaverButtons, smartButtons, premiumButtons) => {
    if (isAuthorMode()) return;

    smartSaverButtons?.forEach(button => {
      button.removeEventListener('click', handleSmartSaverButtonClicked);
    });
    smartButtons?.forEach(button => {
      button.removeEventListener('click', handleSmartButtonClicked);
    });
    premiumButtons?.forEach(button => {
      button.removeEventListener('click', handlePremiumButtonClicked);
    });
  };

  return {
    fareSelectionTable,
    authorViewMockData,
    upsellModal,
    tableConfig,
    isPremiumProduct,
    attachEventListeners,
    cleanupEventListeners,
  };
};
