import { FormikContextType, useFormikContext } from 'formik';
import useFocusTrap from 'hooks/useFocusTrap';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { DayPickerRangeController } from 'react-dates';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { Toggle } from '@atoms/Toggle';
import { PartialFromProps } from '@molecules/TicketsForm/components/OriginAndDestination/types';

import { cn, cx } from 'utils/classNames';

import { DesktopCalendarProps } from '../types';
import './DesktopCalendar.scss';

const bem = cn('desktop-calendar');
const bemDateRange = cn('date-range-picker');

export const DesktopCalendar = observer((props: DesktopCalendarProps) => {
  const {
    onFocusChange,
    handleCloseButton,
    closeButtonLabel,
    clearButtonLabel,
    handleClearDates,
    isOutsideRange,
    toggleItems,
    datesRestrictions,
    isSameDay,
    isOpen,
    ...rest
  } = props;

  const formikProps: FormikContextType<PartialFromProps> = useFormikContext();
  const { values } = formikProps;

  const { ticketsFormStore } = useStores();

  const { changeTripValue, trip } = ticketsFormStore;

  const maxDate = datesRestrictions ? moment.utc(datesRestrictions.latestDate) : null;

  const renderNavButton = (pos = 'prev') => (
    <button className={bemDateRange('navButton', { pos })}>
      <Icon name="arrow-left" />
    </button>
  );

  const startDateMoment = moment.isMoment(values.startDate)
    ? values.startDate
    : values.startDate
    ? moment(values.startDate)
    : null;
  const endDateMoment = moment.isMoment(values.endDate)
    ? values.endDate
    : values.endDate
    ? moment(values.endDate)
    : null;

  const renderDayContents = day => <div className={bemDateRange('day', { sameDay: isSameDay })}>{day.date()}</div>;

  const dialogRef = useFocusTrap(isOpen);

  return (
    <div ref={dialogRef} className={cx(bem('calendar'), bemDateRange('calendar'))} tabIndex={0}>
      <DayPickerRangeController
        numberOfMonths={2}
        onFocusChange={onFocusChange}
        startDate={startDateMoment}
        endDate={endDateMoment}
        daySize={46}
        minimumNights={0}
        minDate={moment()}
        navPrev={renderNavButton()}
        navNext={renderNavButton('next')}
        renderDayContents={renderDayContents}
        enableOutsideDays={false}
        isOutsideRange={isOutsideRange(moment(), maxDate)}
        transitionDuration={0}
        hideKeyboardShortcutsPanel
        {...rest}
      />
      <div className={bem('footer')}>
        <div className={bem('footer-left')}>
          <Toggle name="trip" onChange={changeTripValue} items={toggleItems} defaultChecked={trip} />
        </div>
        <div className={bem('footer-right')}>
          <Button
            className={bem('button-clear')}
            label={clearButtonLabel}
            color="white"
            onClick={handleClearDates}
            size="medium"
          />
          <Button
            className={bem('button-close')}
            label={closeButtonLabel}
            onClick={handleCloseButton}
            size="medium"
            color="blue"
          />
        </div>
      </div>
    </div>
  );
});
