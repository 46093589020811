import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';

import { cn } from 'utils/classNames';

import './DateRangePicker.scss';
import { DesktopDateRangePicker } from './DesktopCalendar';
import { MobileDateRangePicker } from './MobileCalendar';
import { DateRangePickerProps } from './types';
import { useDateRangePicker } from './useDateRangePicker';

const bem = cn('events-date-range-picker');

export const DateRangePicker = observer((props: DateRangePickerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const hookProps = useDateRangePicker(props);

  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) 
      setContainerWidth(containerRef.current.offsetWidth);
    
  }, [containerRef]);

  const isDesktop = window.innerWidth > 575;

  return (
    <div ref={containerRef} className={bem()}>
      {isDesktop ? (
        <DesktopDateRangePicker {...props} {...hookProps} />
      ) : (
        <MobileDateRangePicker containerRef={containerRef} {...props} {...hookProps} containerWidth={containerWidth} />
      )}
    </div>
  );
});
