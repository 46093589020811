import React from 'react';

import { Icon } from '@atoms/Icon';

import { cn, cx } from 'utils/classNames';
import { isBrandedFaresEnabled } from 'utils/metas';

import './FareCompareButtons.scss';
import { FareCompareButtonsProps } from './types';

const bem = cn('fare-compare-buttons');

const FareCompareButtons = ({ buttons, className, id, width = 'full' }: FareCompareButtonsProps) => {
  const handleClick = () => {
    const brandedFaresEnabled = isBrandedFaresEnabled();

    if (brandedFaresEnabled) window.dispatchEvent(new Event('showFareCompareModalV2'));
    else window.dispatchEvent(new Event('showFareCompareModal'));
  };

  return (
    <div id={id} className={cx(bem({ width }), className)}>
      {buttons.map(button => {
        const fareVariant = button.variant == 'SMART_STANDARD' ? 'smart' : 'premium';

        return (
          <button key={button.title} className={bem('button', { variant: fareVariant })} onClick={handleClick}>
            <div className={bem('header')}>
              <div>{button.title}</div>
              {!!button.icons.length && (
                <div className={bem('icons')}>
                  <span className="sr-only">{button.icons}</span>
                  {button.icons.map(name => (
                    <Icon key={name} name={name} />
                  ))}
                  {button.additionalItems && <span>+{button.additionalItems}</span>}
                </div>
              )}
            </div>
            <div className={bem('body')}>{button.subtitle}</div>
          </button>
        );
      })}
    </div>
  );
};

export default FareCompareButtons;
