import React, { useEffect, useId, useMemo, useRef } from 'react';

import { cn, cx } from 'utils/classNames';

import './LocationItem.scss';
import { LocationItemType } from './type';

const bemFont = cn('font');
const bem = cn('location-item');

const LocationItem = (props: LocationItemType) => {
  const generatedID = useId();

  const { option, itemIndex, handleItemClick, focus, locationHint, selected, id } = props;
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (focus && ref.current) ref?.current?.focus();
  }, [focus]);

  const shouldRenderLocationHint = useMemo(
    () => option.distanceFromCurrentLocation && itemIndex === 0,
    [option, itemIndex]
  );

  return (
    <li
      key={option.name}
      ref={ref}
      id={id || generatedID}
      role="option"
      aria-selected={focus}
      onClick={handleItemClick(option, itemIndex)}
      className={bem({ selected: selected })}
      tabIndex={focus ? 0 : undefined}
    >
      <div className={bem('description')}>
        <div className={cx(bem('label'), bemFont({ variant: 'body-large-500' }))}>{option.name}</div>
        {!!shouldRenderLocationHint && (
          <span className={cx(bem('info'), bemFont({ variant: 'tiny-500' }))}>{locationHint}</span>
        )}
      </div>
      {!!option.distanceFromCurrentLocation && (
        <div
          className={cx(bem('units'), bemFont({ variant: 'tiny-500' }))}
        >{`${option.distanceFromCurrentLocation} mi`}</div>
      )}
    </li>
  );
};

export default LocationItem;
