import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { PaymentCardItem } from '@molecules/PaymentCardItem';

import { cn } from 'utils/classNames';

import './PaymentTokens.scss';
import { SavedPaymentTokenProps } from './types';

const bem = cn('account-tokens');

const PaymentToken = observer((props: SavedPaymentTokenProps) => {
  const { token } = props;
  const { cfStore } = useStores();

  const { account } = cfStore;
  const { paymentsCF } = account;

  if (!token) return null;

  const cardImage = paymentsCF.cardInformation.cardImages.find(
    el => el.title.toLowerCase() === token.brand.toLowerCase()
  );

  return (
    <div className={bem('token')}>
      <PaymentCardItem cardImage={cardImage} cardLabel={`${token.name} ****${token.lastFour}`} />
      {token.isPreferred && <div className={bem('default')}>{paymentsCF.defaultCardLabel}</div>}
    </div>
  );
});

export default PaymentToken;
