import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useStores } from 'stores';

import { OverlayContainer } from '@account/components/OverlayContainer';
import PageLoadingPlaceholders from '@account/components/PageLoadingPlaceholder/PageLoadingPlaceholders';
import Alert from '@atoms/Alert/Alert';
import { CreditCardItems } from '@booking/routes/RTI/PaymentInfo/components/PaymentInfoEntry/components/CreditCardItems';

import { cn } from 'utils/classNames';
import { getLocale } from 'utils/metas';

import './AutoRenewPayment.scss';

const bem = cn('auto-renew-payment');
const bemModal = cn('overlay-container');

export const AutoRenewPayment = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { wallet, paymentTokensStore } = accountStore;
  const { passToRenew, setPassToRenew, toggleAutoRenew } = wallet;
  const { selectedRenewPaymentMethod, setSelectedRenewPaymentMethod } = wallet;

  const { isLoading, saveToken, hasError } = paymentTokensStore;
  const { account, cardInformation } = cfStore;
  const { wallet: walletCF } = account;
  const [newCardInstance, setNewCardInstance] = useState<any>(null);
  const [newCardData, setNewCardData] = useState<any>(null);

  moment.locale(getLocale());
  const formattedDay = moment().format('Do');

  const infoDescription = useMemo(
    () => walletCF.autoRenew.activateAutoRenewDescription.replace('{currentMonthDay}', `${formattedDay}`),
    [walletCF.autoRenew.activateAutoRenewDescription, formattedDay]
  );

  const title = useMemo(
    () =>
      passToRenew?.isAutoRenew
        ? walletCF.autoRenew.deactivateAutoRenewTitle
        : walletCF.autoRenew.activateAutoRenewTitle,
    [passToRenew?.isAutoRenew, walletCF.autoRenew.deactivateAutoRenewTitle, walletCF.autoRenew.activateAutoRenewTitle]
  );

  const handleCancel = () => {
    setPassToRenew(false);
  };

  const saveTokenCallback = response => {
    if (response?.data?.paymentToken?.tokenId) toggleAutoRenew(response?.data?.paymentToken.tokenId);
  };

  const handleConfirmPassUpdate = async () => {
    if (!passToRenew?.isAutoRenew)
      if (selectedRenewPaymentMethod?.tokenId) await toggleAutoRenew(selectedRenewPaymentMethod?.tokenId);
      else if (!newCardData?.isValid) newCardInstance.componentRef.showValidation();
      else
        await saveToken(
          {
            ...newCardInstance.state.data,
            platform: 'web',
            billingAddress: {
              ...newCardInstance.state.billingAddress,
            },
          },
          saveTokenCallback
        );
    else await toggleAutoRenew();
  };

  if (isLoading) return <PageLoadingPlaceholders />;

  const isCardSelected = selectedRenewPaymentMethod?.tokenId || newCardInstance || newCardData;
  const isSubmitDisabled = !passToRenew?.isAutoRenew && !isCardSelected;

  return (
    <OverlayContainer
      onCancel={handleCancel}
      onConfirm={handleConfirmPassUpdate}
      title={title}
      isVisible={!!passToRenew}
      cancelButtonLabel={walletCF.autoRenew.cancelCtaLabel}
      submitButtonDisabled={isSubmitDisabled}
      submitButtonLabel={
        passToRenew?.isAutoRenew ? walletCF.autoRenew.deactivateCtaLabel : walletCF.autoRenew.activateCtaLabel
      }
    >
      {!passToRenew?.isAutoRenew ? (
        <div className={bem()}>
          {hasError && <div className={bemModal('error')}>{cardInformation.invalidCardLabel}</div>}
          <div className="payment-container">
            <CreditCardItems
              setCardInstance={setNewCardInstance}
              setCardData={setNewCardData}
              selectedPaymentMethod={selectedRenewPaymentMethod}
              setSelectedPaymentMethod={setSelectedRenewPaymentMethod}
            />
          </div>

          <Alert icon="info" summary={walletCF.autoRenew.activateAutoRenewInfoTitle} description={infoDescription} />
        </div>
      ) : (
        <div className={bem()}>{walletCF.autoRenew.deactivateAutoRenewDescription}</div>
      )}
    </OverlayContainer>
  );
});
