import React from 'react';
import { useTimer } from 'react-timer-hook';

import { cn } from 'utils/classNames';

import { addDigit } from './addDigit';

const bem = cn('timeout-modal');

export const Timer = ({ remainingTime }) => {
  const time = new Date();

  time.setSeconds(time.getSeconds() + remainingTime / 1000);

  const { seconds, minutes } = useTimer({ expiryTimestamp: time });

  return (
    <div className={bem('time')}>
      {addDigit(minutes)}:{addDigit(seconds)}
    </div>
  );
};
