import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { CoachItemProps } from '@booking/routes/RTI/SeatSelection/components/SeatSelectionModal/SeatSelectionMap/types';

import { cn } from 'utils/classNames';

import { SeatMapItem } from '../SeatMapItem';
import { coachDescriptions } from './coachDescriptions';

const bem = cn('seat-selection-map');

export const CoachItem = observer((props: CoachItemProps) => {
  const { pristineSeatSelection, isAccountFlow, canEditSeatsAccontFlow } = props;
  const { bookingStore } = useStores();
  const { seats: seatsStore } = bookingStore.rti;
  const { passengersSeats, activePassenger, travelDirection, displayedCoach } = seatsStore;

  const { coach, floorplanImageUrl, floorplanDimensions, seatDimensions, seats } = displayedCoach || {};

  const description = useMemo(() => coachDescriptions[coach!.coachNumber] || '', [coach]);

  const styles: any = useMemo(
    () => ({
      '--seat-map-background-image': `url('${floorplanImageUrl}')`,
      '--seat-map-width': `${floorplanDimensions?.width}px`,
      '--seat-map-height': `${floorplanDimensions?.height}px`,
      '--seat-height': `${seatDimensions?.width}px`,
      '--seat-width': `${seatDimensions?.height}px`,
    }),
    [
      floorplanDimensions?.height,
      floorplanDimensions?.width,
      floorplanImageUrl,
      seatDimensions?.height,
      seatDimensions?.width,
    ]
  );

  if (!coach) return null;

  const isOutbound = travelDirection === 'outbound';

  return (
    <div
      className={bem('map')}
      style={styles}
      title=" Seating map of the train A B C D EXIT Arrow Up DIRECTION OF TRAVEL Arrow Up EXIT 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 EXIT Arrow Up DIRECTION OF TRAVEL Arrow Up EXIT A B C D "
    >
      {/* Screen reader-only description */}
      <p className="sr-only" role="region" aria-label={`Coach ${coach.coachNumber} description`}>
        {description}
      </p>

      {(seats || []).map(seatElement => {
        if (!seatElement) return null;

        const { seat, xPosition, yPosition, authorizedPassengers } = seatElement;
        const seatStyle: any = {
          width: seatDimensions?.width,
          height: seatDimensions?.height,
          position: 'absolute',
          top: yPosition,
          left: xPosition,
        };

        const unavailable =
          !authorizedPassengers.length ||
          !authorizedPassengers?.some(pass => pass.passengerId === activePassenger?.passengerId) ||
          (!!isAccountFlow && !canEditSeatsAccontFlow);

        const selectedSeat = isOutbound
          ? passengersSeats.some(
              el =>
                el.outboundSeat.number === seat.number && el.outboundSeat.coach.coachNumber === seat.coach.coachNumber
            )
          : passengersSeats.some(
              el => el.inboundSeat.number === seat.number && el.inboundSeat.coach.coachNumber === seat.coach.coachNumber
            );

        const activeSeat = isOutbound
          ? activePassenger?.outboundSeat.number === seat.number &&
            activePassenger?.outboundSeat.coach.coachNumber === seat.coach.coachNumber
          : activePassenger?.inboundSeat.number === seat.number &&
            activePassenger?.inboundSeat.coach.coachNumber === seat.coach.coachNumber;

        return (
          <SeatMapItem
            key={seat.number}
            pristineSeatSelection={pristineSeatSelection}
            seatStyle={seatStyle}
            active={activeSeat}
            selected={selectedSeat}
            unavailable={unavailable}
            seat={seat}
            authorizedPassengers={authorizedPassengers}
            width={seatDimensions?.width}
            height={seatDimensions?.height}
            isAccountFlow={isAccountFlow}
          />
        );
      })}
    </div>
  );
});
