import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';

import CheckboxField from '@atoms/Checkbox/CheckboxField';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';
import { TrainPassStepsProps } from '@organisms/TrainPasses/types';

import { cn } from 'utils/classNames';

import './TrainPassesSummary.scss';

const bem = cn('train-passes-summerize');

const TrainPassesSummerize = (props: TrainPassStepsProps) => {
  const { i18n, passesConfigurations, useTrainPassLogic } = props;

  const { values: formValues } = useFormikContext();
  const values = formValues as any;

  const { setStep, setDiscountedTotal, options } = useTrainPassLogic;

  useEffect(() => {
    const option = options.find(option => option.name === values.option);
    if (!values.autoRenew || !option || !option.autoRenewOption) {
      setDiscountedTotal(0);

      return;
    }
    setDiscountedTotal(option.autoRenewOption.priceInCents / 100);
  }, [values.option, values.autoRenew, options, setDiscountedTotal]);

  const selectedOption = useMemo(() => options.find(option => option.name === values.option), [values.option, options]);

  const selectedOptionConfigOverride = useMemo(
    () => passesConfigurations?.find(config => config.passItem.pairKey === selectedOption.code),
    [passesConfigurations, selectedOption.code]
  );

  const handleEdit = () => {
    setStep(3);
  };

  return (
    <div className={bem()}>
      <div>
        <div className={bem('fields')}>
          <div className={bem('subtitle')}>
            <Title size="h3" text={i18n?.overviewTrainPassTitle} />
          </div>
          <div>
            <LinkButton label={i18n.editLabel} onClick={handleEdit} underline />
          </div>
        </div>
        <div className={bem('details')}>
          <Text text={`${values.travelFrom} - ${values.travelTo}`} />
          <Text text={`${values.category.toUpperCase()}, ${selectedOption.description}`} />
        </div>
      </div>

      {selectedOption && selectedOption.autoRenewOption && (
        <div className={bem('auto-renew')}>
          <CheckboxField
            name="autoRenew"
            label={selectedOptionConfigOverride?.passItem?.autorenewalCheckboxText || i18n.autoRenewCheckboxLabel}
            checked={values.autoRenew}
          />
          <Text
            text={selectedOptionConfigOverride?.passItem?.autorenewalText || i18n.autoRenewNote}
            className={bem('auto-renew-text')}
          />
        </div>
      )}
    </div>
  );
};

export default TrainPassesSummerize;
