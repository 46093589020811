import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';
import { ExtrasPassenger } from 'stores/BookingStore/sections/Extras/types';

import { useTripDetailsConfig } from '@account/hooks/useTripDetailsConfig';
import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import { editTripdetailsRtiRedirect } from '../../utils';
import './Extras.scss';
import { ExtrasItemProps } from './types';

const bem = cn('trip-details-extras');
export const ExtrasItem = observer((props: ExtrasItemProps) => {
  const { type, products, labels } = props;
  const { cfStore, accountStore } = useStores();
  const { setNoModificationAllowedModalOpen, bookingRules } = accountStore.tripDetails;
  const { account } = cfStore;
  const { tripDetails } = account;
  const { isEditable } = useTripDetailsConfig();
  const { isExtrasModifiable } = bookingRules || {};

  const outboundExtras = useMemo(
    () =>
      products.filter(product =>
        product.passengers.find(passenger => {
          if (!passenger.outboundSelectionParameters?.currentQuantitySelected) return false;

          return passenger.outboundSelectionParameters?.currentQuantitySelected > 0;
        })
      ),
    [products]
  );

  const inboundExtras = useMemo(
    () =>
      products.filter(product =>
        product.passengers.find(passenger => {
          if (!passenger.inboundSelectionParameters?.currentQuantitySelected) return false;

          return passenger.inboundSelectionParameters?.currentQuantitySelected > 0;
        })
      ),
    [products]
  );

  const handleEdit = () => {
    if (!isExtrasModifiable) setNoModificationAllowedModalOpen();
    else editTripdetailsRtiRedirect(accountStore, 'extras');
  };

  const getExtraQuantity = (passengers: ExtrasPassenger[], property: string) =>
    passengers.reduce((acc, passenger) => acc + (passenger[property]?.currentQuantitySelected || 0), 0);

  if (!outboundExtras.length && !inboundExtras.length) return null;

  return (
    <div className={bem('item-card', { completed: true })}>
      <div className={bem('item-header')}>
        <div className={bem('item-title-wrapper')}>
          <Icon name={type} />
          <div className={bem('item-info-title')}>{labels?.label}</div>
        </div>
        {isEditable && tripDetails.editLabel && !labels.hideEditLink && (
          <span className={bem('item-edit')} onClick={handleEdit}>
            {tripDetails.editLabel}
          </span>
        )}
      </div>
      <div className={bem('item-content')}>
        {!!outboundExtras.length && (
          <div className={bem('item-content-direction')}>
            <div className={bem('item-content-direction-title')}>{tripDetails.departureLabel}</div>
            {outboundExtras.map((extra, index) => (
              <div key={`${extra.name}-${index}`}>
                {getExtraQuantity(extra.passengers, 'outboundSelectionParameters')} {extra.name}
              </div>
            ))}
          </div>
        )}
        {!!inboundExtras.length && (
          <div className={bem('item-content-direction')}>
            <div className={bem('item-content-direction-title')}>{tripDetails.returnLabel}</div>
            {inboundExtras.map((extra, index) => (
              <div key={`${extra.name}-${index}`}>
                {getExtraQuantity(extra.passengers, 'inboundSelectionParameters')} {extra.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});
