export const stepNames = {
  passengerInfo: 'passengerInfo',
  seatAssignment: 'seatAssignment',
  extras: 'extras',
  paymentInfo: 'paymentInfo',
  tripDetails: 'tripDetails',
};

export const segments = {
  segment1: 'segment_1',
  segment2: 'segment_2',
};

export const classTypes = {
  premium: 'premium',
  smart: 'smart',
};
