import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { CardTitle } from '../CardTitle';
import './Extras.scss';
import { ExtrasItem } from './ExtrasItem';
import { ParkingItem } from './ParkingItem';
import { RevertProvisionalChanges } from './RevertProvisionalChanges/RevertProvisionalChanges';

const bem = cn('trip-details-extras');

export const Extras = observer(() => {
  const { accountStore, cfStore, bookingStore } = useStores();

  const { tripDetails } = accountStore;
  const { isLoading, bookingRules } = tripDetails;
  const { hasProvisionalChanges } = bookingRules || {};
  const { parking, extras } = bookingStore;

  const hasExtras = useMemo(
    () =>
      extras.data.some(el =>
        el.products?.some(product =>
          product?.passengers?.some(
            passenger =>
              (passenger?.outboundSelectionParameters &&
                passenger?.outboundSelectionParameters.currentQuantitySelected > 0) ||
              (passenger?.inboundSelectionParameters &&
                passenger?.inboundSelectionParameters.currentQuantitySelected > 0)
          )
        )
      ) || !!parking.data?.eligibleDrivers.some(driver => !!driver.currentSelections),
    [extras.data, parking.data]
  );

  const extrasItems = useMemo(
    () =>
      cfStore.account.tripDetails.extrasTypes
        .filter(extraType => !extraType.isHidden)
        .map(extraTypeCF => {
          if (
            extraTypeCF.type.endsWith('luggage') ||
            extraTypeCF.type.endsWith('pet') ||
            extraTypeCF.type.endsWith('specialty')
          ) {
            const extraData = extras.data.find(extrasData => extraTypeCF.type.endsWith(extrasData.type));

            if (!extraData) return null;

            return <ExtrasItem key={extraTypeCF.type} {...extraData} labels={extraTypeCF} />;
          } else if (extraTypeCF.type.endsWith('parking'))
            return <ParkingItem key="parking" type="parking" labels={extraTypeCF} />;

          return null;
        }),
    [extras.data, cfStore.account.tripDetails.extrasTypes]
  );

  if (isLoading || parking.loading || extras.loading) return null;

  return (
    <>
      {hasProvisionalChanges && <RevertProvisionalChanges />}
      {!!hasExtras && (
        <div className={bem()}>
          <CardTitle title={cfStore.account.tripDetails.extrasLabel} />
          {extrasItems}
        </div>
      )}
    </>
  );
});
