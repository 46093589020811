import { Form, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { Button } from '@atoms/Button';
import { Checkbox } from '@atoms/Checkbox';
import { InputField } from '@atoms/Input';
import { Text } from '@atoms/Text';
import { InlineDateInputField } from '@molecules/InlineDateInput';

import { cn, cx } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';

import '../NewsletterFormV2.scss';
import { dateConfig } from '../constants';
import { NewsletterFormV2Props } from '../types';
import { NewsletterFormV2Values } from '../types';
import { useServerLogic } from '../useServerLogic';

const bem = cn('newsletter-v2');

export const NewsletterFormV2Content = (props: NewsletterFormV2Props) => {
  const { firstName, lastName, email, postalCode, dateOfBirth, signUpButton, subtitle, disclaimer } = props;
  const [isEmailValid, setIsEmailValid] = useState(false);
  const { serverStatus } = useServerLogic();
  const formik = useFormikContext<NewsletterFormV2Values>();

  useEffect(() => {
    setIsEmailValid(!!formik.values.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formik.values.email));
  }, [formik.values.email]);

  return (
    <div className={bem()}>
      <Form noValidate>
        <div>
          <Text className={bem('subtitle')} text={subtitle} as="h3" />
          <div className={bem('form-row')}>
            <div className={cx(bem('form-column'), bem('field'))}>
              <InputField
                errorVariant="inline"
                error={serverStatus === 'error'}
                name="firstName"
                label={firstName}
                autocomplete={!isKiosk() ? 'given-name' : 'off'}
                ariaLabel="clear first name"
                fieldsetClassName={bem('input-field')}
                displayTrailingIcon
              />
            </div>
            <div className={cx(bem('form-column'), bem('field'))}>
              <InputField
                errorVariant="inline"
                error={serverStatus === 'error'}
                name="lastName"
                label={lastName}
                autocomplete={!isKiosk() ? 'family-name' : 'off'}
                ariaLabel="clear last name"
                fieldsetClassName={bem('input-field')}
                displayTrailingIcon
              />
            </div>
          </div>
          <div className={bem('form-row')}>
            <div className={bem('field')}>
              <InputField
                errorVariant="inline"
                error={serverStatus === 'error'}
                name="email"
                label={email}
                type="email"
                autocomplete={!isKiosk() ? 'email' : 'off'}
                ariaLabel="clear email"
                fieldsetClassName={bem('input-field')}
                displayTrailingIcon
                required
              />
            </div>
          </div>
          <div className={bem('form-row')}>
            <div className={cx(bem('form-column'), bem('field'))}>
              <InputField
                errorVariant="inline"
                error={serverStatus === 'error'}
                autocomplete="postal-code"
                type="text"
                name="postalCode"
                label={postalCode}
                maxCharacters={10}
                fieldsetClassName={bem('input-field')}
                displayTrailingIcon
              />
            </div>
            <div className={cx(bem('form-column'), bem('field'))}>
              <InlineDateInputField
                dateFormat={dateConfig.dateFormat}
                label={dateOfBirth}
                placeholder={dateConfig.dateFormat}
                name="dateOfBirth"
                errorVariant="inline"
                autocomplete={!isKiosk() ? 'bday' : 'off'}
                ariaLabel="clear date"
                fieldsetClassName={bem('input-field')}
                maxCharactersDisabled
              />
            </div>
          </div>
          <div className={bem('disclaimer-row')}>
            <div className={bem('disclaimer-wrapper')}>
              <Checkbox name="disclaimerCheckbox" label="" isChecked disabled />
              <Text text={disclaimer} className={bem('disclaimer')} />
            </div>
          </div>
          <div className={bem('action')}>
            <Button
              type="submit"
              width="full"
              label={signUpButton.label}
              color={signUpButton.color}
              disabled={!isEmailValid}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
