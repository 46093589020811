export const defaultInputName = 'defaultAccordion';

export const CLASSES = {
  item: 'blte-accordion-item',
  title: 'blte-accordion-item__title',
  content: 'blte-accordion-item__content',
  contentExpanded: 'blte-accordion-item__content--expanded',
  icon: 'blte-accordion-item__trailing-icon > .blte-icon',
  iconExpanded: 'blte-icon__arrow-up',
  defaultIcon: 'blte-icon__arrow-down',
  expand: 'blte-accordion__expand',
  collapse: 'blte-accordion__collapse',
  hidden: 'blte-accordion__hidden',
  autoHeight: 'blte-accordion-item__auto-height',
};

export const SELECTORS = {
  item: `.${CLASSES.item}`,
  title: `.${CLASSES.title}`,
  content: `.${CLASSES.content}`,
  contentExpanded: `.${CLASSES.contentExpanded}`,
  icon: `.${CLASSES.icon}`,
  expand: `.${CLASSES.expand}`,
  collapse: `.${CLASSES.collapse}`,
  hidden: `.${CLASSES.hidden}`,
};
