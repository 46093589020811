import moment from 'moment';

export const getStationById = (id, stations) => {
  if (!id) return null;

  const currentStation = stations.find(station => station.id === id);

  return currentStation ? currentStation.name : null;
};

export const getDateRange = (startDate, endDate) => {
  let endDateValue = endDate;

  if (!startDate || !moment.isMoment(startDate)) return null;

  if (!endDate) return startDate.format('MMM D');

  if (!moment.isMoment(endDate)) endDateValue = moment(endDate);

  if (startDate?.format('MM/YY') === moment(endDateValue)?.format('MM/YY'))
    return `${startDate.format('MMM D')} - ${endDateValue.format('D')}`;

  return `${startDate?.format('MMM D')} - ${endDateValue?.format('MMM D')}`;
};
