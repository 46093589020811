import { useStores } from 'stores';

import { stopDatadogSession } from 'utils/datadog';

import { clearPublicBookingToken } from '../../../utils/localstorage';

export const useNavItemHandler = () => {
  const { authStore, headerStore } = useStores();
  const { setMenuVisibility } = headerStore;

  const handleAccountItemsClick = item => e => {
    if (window.location.href.includes('/account'))
      if (authStore.router && item.link) {
        e.preventDefault();

        setMenuVisibility(false);
        authStore.router.navigate(item.link.url);
      }
  };

  const handleLogoutItemClick = (e, item, index) => {
    stopDatadogSession();
    clearPublicBookingToken();
    localStorage.removeItem('zendeskToken');

    window.zE?.('messenger', 'logoutUser');

    item.onClick && item.onClick(e, item, index);
  };

  return { handleAccountItemsClick, handleLogoutItemClick };
};
