import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';
import { CostSummaryTotalProps } from 'stores/BookingStore/sections/RTI/types.costSummary';

import { Icon } from '@atoms/Icon';
import { PromoCodeV2 } from '@molecules/PromoCode';

import { cn } from 'utils/classNames';
import { requestUrls } from 'utils/constants';

import './CostSummary.scss';

const bem = cn('cost-summary-total');

export const CostSummaryTotalMobile = observer((props: CostSummaryTotalProps) => {
  const { onClick } = props;
  const { bookingStore, cfStore } = useStores();

  const { getTotal, provisionalBooking, modifyTripType, setProvisionalBooking } = bookingStore.rti;
  const { generic, rti } = cfStore;

  const costSummary = provisionalBooking?.tripDetails?.costSummary;

  const total = useMemo(getTotal, [
    provisionalBooking?.tripDetails?.costSummary?.bookingTotal?.totalToBePaid,
    getTotal,
  ]);

  const totalLabel: string = useMemo(() => {
    const totalToBePaid = costSummary?.bookingTotal?.totalToBePaid;

    if (!modifyTripType || totalToBePaid === undefined) return generic.totalLabel;

    return totalToBePaid < 0 ? generic.totalReturnAsCreditLabel : generic.totalDueLabel;
  }, [generic, modifyTripType, costSummary]);

  return (
    <div className={bem('wrapper')}>
      <div className={bem('wrapper-total')} onClick={onClick}>
        <div className={bem('total')}>{totalLabel} (USD)</div>
        <div style={{ display: 'flex' }}>
          <div className={bem('amount')}>${total.toFixed(2)}</div>
          <button className={bem('arrow-up-button')} onClick={onClick}>
            <Icon className={bem('arrow-up-icon')} name="arrow-up" />
          </button>
        </div>
      </div>
      <div className={bem('wrapper-promo-code')} onClick={onClick}>
        <PromoCodeV2
          requestOptions={{
            headers: {
              'Booking-Session-Access-Token': provisionalBooking?.bookingSession?.accessToken,
              'Booking-Session-Refresh-Token': provisionalBooking?.bookingSession?.refreshToken,
            },
          }}
          promoButton={{ label: rti?.costSummary.promoButtonLabel }}
          floatingLabel={rti?.costSummary.promoCodeLabelInputFloatingLabel}
          errorLabel={rti?.costSummary.invalidPromoCodeLabel}
          applyButton={{ variant: 'primary', label: rti.costSummary.promoApplyLabel, color: 'blue' }}
          endpoint={`${requestUrls.getRestUrl(requestUrls.rti.bookingPromoCodes)}.${
            provisionalBooking?.tripDetails?.trip?.referenceNumber
          }.xjson`}
          onSuccess={setProvisionalBooking}
          required
          isMobileRti
          underline
        />
      </div>
    </div>
  );
});
