export const getURLParams = () => new URL(window.location.toString()).searchParams;

export const removeURLParam = (param: string) => {
  const url = new URL(window.location.href);
  if (url.searchParams.get(param)) {
    url.searchParams.delete(param);
    history.pushState(null, document.title, url);
  }
};

export const queryParams: any = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop: string) => searchParams.get(prop),
});
