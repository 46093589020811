import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import MenuItem from '@atoms/MenuItem/MenuItem';
import { MenuItemProps } from '@atoms/MenuItem/types';
import NavItemHeader from '@molecules/NavItem/NavItemHeader';
import { NavItemProps } from '@molecules/NavItem/types';

import { cn } from 'utils/classNames';

import './NavItem.scss';
import { useNavItemHandler } from './useNavItemHandler';
import { useNavItemStyles } from './useNavItemStyles';

const bem = cn('nav-item');

export const NavItem = observer((props: NavItemProps) => {
  const { subitems = [], size = 'large', customIcon, label, colors, isResponsive = true, uuid } = props;

  const [expanded, setExpanded] = useState(false);

  const navButtonRef = useRef<any>(null);

  const { handleAccountItemsClick, handleLogoutItemClick } = useNavItemHandler();

  const styles = useNavItemStyles(colors);

  const handleMouseEnter = () => {
    if (!expanded) setExpanded(true);
  };

  const handleMouseLeave = () => {
    setExpanded(false);
    const activeEl = document?.activeElement as HTMLElement | null;
    activeEl?.blur();
  };

  const handleMenuItemKeyDown = event => {
    if (event.key === 'Escape') {
      setExpanded(false);
      navButtonRef?.current?.focus();
    }
  };

  const handleClickOutside = () => {
    setExpanded(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <div
        className={bem({ size, isResponsive, isDesktop: !isResponsive })}
        style={styles}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        tabIndex={-1}
      >
        <div>
          <NavItemHeader
            {...props}
            expanded={expanded}
            setExpanded={setExpanded}
            displayDropDownIcon={!!subitems?.length}
            customIcon={customIcon}
            ariaLabel={label}
            ariaControls={`submenu-${uuid}`}
          />
        </div>
        {!!subitems?.length && (
          <ul id={`submenu-${uuid}`} className={bem('submenu', { expanded })}>
            {subitems?.map((el: MenuItemProps, index) => (
              <span key={index} onKeyDown={handleMenuItemKeyDown} className={bem('submenu-item', { expanded })}>
                <MenuItem
                  key={`top-nav-menu-item${el.label}`}
                  {...el}
                  onClick={e => {
                    if (el.scope === 'account') handleAccountItemsClick(el);
                    else if (el.scope === 'logout') handleLogoutItemClick(e, el, index);
                    else el.onClick && el.onClick(e, el, index);
                  }}
                  labelCN=""
                />
              </span>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
});
