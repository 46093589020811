import axios from 'axios';
import { Buffer } from 'buffer';

import { isDirectBffCallsEnabled } from './metas';

export const mergeSchemas = (...schemas) => {
  const [first, ...rest] = schemas;

  return rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);
};

export const groupArrayObjectsByProps = (arr, f) => {
  const groups = {};
  arr.forEach(o => {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });

  return Object.keys(groups).map(group => groups[group]);
};

export const isAxiosError = (error: unknown) => axios.isAxiosError(error);

export const isAxiosGracefulError = (error: unknown) => isAxiosError(error) && !!(error as any)?.response?.data?.code;

export const base64Decode = encodedString => Buffer.from(encodedString, 'base64').toString('binary');

export const base64Encode = stringToEncode => Buffer.from(stringToEncode, 'binary').toString('base64');

export const isValidURL = url => {
  let isValidUrl;
  try {
    new URL(url);
    isValidUrl = true;
  } catch (error) {
    isValidUrl = false;
  }

  return isValidUrl;
};

export const generateEmptyGrid = (numRows: number, numCols: number, defaultValue: any) =>
  Array.from({ length: numRows }, () => Array.from({ length: numCols }, () => defaultValue));

export const isDirectBffApiCall = window.location.hostname !== 'localhost' && isDirectBffCallsEnabled();
