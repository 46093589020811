import LightLogo from 'assets/images/svg/logo/light.svg';
import React, { Suspense } from 'react';

import Logo from '@atoms/Logo/Logo';

import { cn } from 'utils/classNames';
import { queryParams } from 'utils/queries';

import './RegistrationContainer.scss';
import { RegistrationContainerProps } from './types';

const bem = cn('account-registration');

export const RegistrationContainer = ({ children }: RegistrationContainerProps) => {
  const { is_web } = queryParams;

  // useEffect cannot be used because we need this to happen before first rendering
  document.body.classList.remove('hasVisibleXFContainers', 'hasVisibleCMSContent');

  return (
    <div className={bem('registration-container')}>
      {is_web && (
        <div className={bem('header')}>
          <Logo logo={LightLogo} link="/" />
        </div>
      )}
      <div className={bem('body', { done: location.pathname.includes('done') })}>
        <Suspense fallback={<div />}>{children}</Suspense>
      </div>
    </div>
  );
};
