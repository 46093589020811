import moment from 'moment/moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { rootStore, useStores } from 'stores';

import { editTripdetailsRtiRedirect } from '@account/routes/TripDetails/utils';
import { defaultArgs as pillArgs } from '@atoms/PillButton/constants.storybook';
import { tabColors } from '@atoms/Tabs';
import Tooltip from '@atoms/Tooltip/Tooltip';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { isBrandedFaresEnabled, isBrandedFaresV2Enabled } from 'utils/metas';
import { getTicketsLink } from 'utils/tickets';

import './Navigation.scss';
import { GetModifyTripData } from './helpers';

const bem = cn('trip-details-navigation');

export const useNavigationLogic = () => {
  const [showModifyTripInfoModal, setShowModifyTripInfoModal] = useState(false);

  const { accountStore, cfStore, bookingStore } = useStores();
  const { tripDetails } = accountStore;
  const brandedFaresV2Enabled = isBrandedFaresV2Enabled();

  const { setChangesHaveBeenSaved } = bookingStore.rti;

  const { trip, ticketDocumentDetails, receiptDocumentDetails, setShowSeatSelectionModal, showCancellationModal } =
    tripDetails;
  const { setSetShowCancellationModal, setNoModificationAllowedModalOpen, bookingRules } = tripDetails;
  const { setShowManagePassengersModal, setShowModifyTripWidget } = tripDetails;
  const { showModifyTripWidget, cancelTrip, setShowConfirmationModal, tripWay } = tripDetails;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const { navigation } = cfStore.account.tripDetails;
  const { manageTripsSection, ticketsAndReceiptsSection, modifyTripSection } = navigation;

  const isTripModifiable = useMemo(
    () => bookingRules?.outBoundRules?.isModifiable || bookingRules?.inBoundRules?.isModifiable,
    [bookingRules?.inBoundRules?.isModifiable, bookingRules?.outBoundRules?.isModifiable]
  );

  const cantModifyTicketBankReason =
    bookingRules?.inBoundRules?.cantModifyReason === 'TICKET_BANK' ||
    bookingRules?.outBoundRules?.cantModifyReason === 'TICKET_BANK';

  const isNotBookingCancellable = !bookingRules?.isBookingCancellable;

  const isRoundTrip = useMemo(
    () => !!trip?.outboundRoute.id && !!trip?.inboundRoute?.id,
    [trip?.inboundRoute?.id, trip?.outboundRoute.id]
  );

  const isCancellableTrip = useMemo(
    () => trip && moment(trip?.inboundRoute?.departureDateTime).isSameOrAfter(moment()),
    [trip]
  );

  const isFirstLegTraveled = useMemo(() => {
    if (trip && moment(trip.outboundRoute.departureDateTime).isSameOrAfter(moment())) return false;
    else if (!trip || !trip.outboundRoute.departureDateTime) return undefined;

    return true;
  }, [trip]);

  const isSecondLegTraveled = useMemo(() => {
    if (trip && trip?.inboundRoute?.id && moment(trip.inboundRoute?.departureDateTime).isSameOrAfter(moment()))
      return false;
    else if (!trip || !trip?.inboundRoute?.id) return undefined;

    return true;
  }, [trip]);

  const messageForCantCancelModify = useMemo(() => {
    const outboundRules = bookingRules?.outBoundRules;
    const inboundRules = bookingRules?.inBoundRules;
    const isBfEnabled = isBrandedFaresEnabled();

    const outboundDepartureTime = moment(trip?.outboundRoute?.departureDateTime);
    const timeDifferenceMinutes = outboundDepartureTime.diff(moment(), 'minutes');

    if (timeDifferenceMinutes < 30 && timeDifferenceMinutes >= 0) return manageTripsSection.timeLimitDisabled;

    if (!isRoundTrip) {
      if (isFirstLegTraveled) return '';
      else if (outboundRules?.cantModifyReason === 'SMART_SAVER')
        return isBfEnabled
          ? manageTripsSection.modifyTripSmartSaverDisabledBothLegs
          : manageTripsSection.contactCustomerServices;
      else if (outboundRules?.cantModifyReason === 'TRAVEL_AGENT') return manageTripsSection.modifyTripTravelAgent;
      else if (trip?.outboundRoute?.isCancelled && trip?.inboundRoute?.isCancelled && !showCancellationModal)
        return manageTripsSection.contactCustomerServices;
    } else if (!isFirstLegTraveled && !isSecondLegTraveled) {
      if (outboundRules?.cantModifyReason === 'SMART_SAVER' && inboundRules?.cantModifyReason === 'SMART_SAVER')
        return isBfEnabled
          ? manageTripsSection.modifyTripSmartSaverDisabledBothLegs
          : manageTripsSection.contactCustomerServices;
      else if (outboundRules?.cantModifyReason === 'SMART_SAVER' || inboundRules?.cantModifyReason === 'SMART_SAVER')
        return isBfEnabled
          ? manageTripsSection.modifyTripSmartSaverDisabledOnlyOneLeg
          : manageTripsSection.contactCustomerServices;
      else if (outboundRules?.cantModifyReason === 'TRAVEL_AGENT' || inboundRules?.cantModifyReason === 'TRAVEL_AGENT')
        return manageTripsSection.modifyTripTravelAgent;
      else if (trip?.outboundRoute?.isCancelled && trip?.inboundRoute?.isCancelled && !showCancellationModal)
        return manageTripsSection.contactCustomerServices;

      return '';
    } else if (isFirstLegTraveled && !isSecondLegTraveled) {
      if (inboundRules?.cantModifyReason === 'SMART_SAVER')
        return isBfEnabled
          ? manageTripsSection.modifyTripSmartSaverDisabledBothLegs
          : manageTripsSection.contactCustomerServices;
      else if (inboundRules?.cantModifyReason === 'TRAVEL_AGENT') return manageTripsSection.modifyTripTravelAgent;
      else if (trip?.outboundRoute?.isCancelled && trip?.inboundRoute?.isCancelled && !showCancellationModal)
        return manageTripsSection.contactCustomerServices;

      return '';
    }

    return '';
  }, [
    bookingRules,
    isFirstLegTraveled,
    isRoundTrip,
    isSecondLegTraveled,
    manageTripsSection,
    trip,
    showCancellationModal,
  ]);

  const design = useMemo(
    () => ({
      pillButtons: {
        colors: pillArgs.colors,
        hasIcon: true,
        hasShadow: false,
      },
      tabs: {
        colors: tabColors,
      },
    }),
    []
  );

  const modifyTripData = useMemo(
    () =>
      GetModifyTripData(
        trip,
        modifyTripSection,
        isFirstLegTraveled || !bookingRules?.outBoundRules?.isModifiable,
        isSecondLegTraveled || !bookingRules?.inBoundRules?.isModifiable,
        isRoundTrip,
        design
      ),
    [
      design,
      isFirstLegTraveled,
      isRoundTrip,
      isSecondLegTraveled,
      modifyTripSection,
      trip,
      bookingRules?.outBoundRules?.isModifiable,
      bookingRules?.inBoundRules?.isModifiable,
    ]
  );

  useEffect(() => {
    setShowManagePassengersModal(false);
    setChangesHaveBeenSaved(false);
  }, [setShowManagePassengersModal, setChangesHaveBeenSaved]);

  const handleTicketDownload = () => {
    const link = getTicketsLink(ticketDocumentDetails);
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: ticketsAndReceiptsSection.downloadTickets,
        customLink: link?.url,
        externalLink: link?.url,
        linkType: 'linkClick',
      },
      tripManagement: {
        PNR: trip?.referenceNumber,
      },
    });

    window.open(link?.url, '_blank');
  };

  const handleReceiptDownload = () => {
    const link = receiptDocumentDetails.invoiceUrl;
    clickAnalyticsEvent(rootStore, {
      linkClick: {
        pageNameClickTracking: document.title,
        navigationLinkName: ticketsAndReceiptsSection.downloadReceipt,
        customLink: link,
        externalLink: link,
        linkType: 'linkClick',
      },
      tripManagement: {
        PNR: trip?.referenceNumber,
      },
    });

    window.open(link, '_blank');
  };

  const onAddEditExtras = () => {
    if (cantModifyTicketBankReason) setNoModificationAllowedModalOpen();
    else editTripdetailsRtiRedirect(accountStore, 'extras');
  };

  const onCancelTrip = () => {
    if (!isTripModifiable && isNotBookingCancellable) {
      setNoModificationAllowedModalOpen();

      return;
    }
    if (!brandedFaresV2Enabled) {
      setShowConfirmationModal(true);

      return;
    }
    const origin = tripDetails?.trip;
    if (!origin?.outboundRoute || !origin?.inboundRoute) {
      cancelTrip(trip?.referenceNumber, true, tripWay);
      setSetShowCancellationModal(true);
    } else setSetShowCancellationModal(true);
  };

  const onManagePassengers = () => {
    if (!isTripModifiable) setNoModificationAllowedModalOpen();
    else setShowManagePassengersModal(true);
  };

  const onModifyTrip = () => {
    if (!isTripModifiable) setNoModificationAllowedModalOpen();
    else if (isRoundTrip && isSecondLegTraveled) setShowModifyTripInfoModal(true);
    else if (!isRoundTrip && isFirstLegTraveled) setShowModifyTripInfoModal(true);
    else setShowModifyTripWidget(true);
  };

  const handleClick = () => {
    console.error('Unavailable');
  };

  const handleSeatSelectionModalClose = () => {
    setShowSeatSelectionModal(false);
  };

  const renderUpgradeTripButton = () => {
    if (!manageTripsSection.upgradeTrip) return null;

    const outboundRules = bookingRules?.outBoundRules;
    const inboundRules = bookingRules?.inBoundRules;

    let disableUpgradeTripButton = false;
    let disabledMessage = '';

    const outboundDepartureTime = moment(trip?.outboundRoute?.departureDateTime);
    const timeDifferenceMinutes = outboundDepartureTime.diff(moment(), 'minutes');

    if (timeDifferenceMinutes < 5 && timeDifferenceMinutes >= 0) disableUpgradeTripButton = true;

    if (!isRoundTrip) {
      if (isFirstLegTraveled) disableUpgradeTripButton = true;
      else if (!isFirstLegTraveled && !outboundRules?.isModifiable) {
        disableUpgradeTripButton = true;
        disabledMessage = messageForCantCancelModify;
      }
    } else if (isFirstLegTraveled && isSecondLegTraveled) disableUpgradeTripButton = true;
    else if (
      !isFirstLegTraveled &&
      !outboundRules?.isModifiable &&
      !isSecondLegTraveled &&
      !inboundRules?.isModifiable
    ) {
      disableUpgradeTripButton = true;
      disabledMessage = messageForCantCancelModify;
    } else if (isFirstLegTraveled && !isSecondLegTraveled && !inboundRules?.isModifiable) {
      disableUpgradeTripButton = true;
      disabledMessage = messageForCantCancelModify;
    }

    // true-true & false
    if (disableUpgradeTripButton)
      return (
        <div className={bem('modify-trip-section')}>
          <button onClick={onModifyTrip} className={bem('modify-smart-saver')} disabled>
            {!isMobile && disabledMessage ? (
              <Tooltip text={disabledMessage} renderTooltipContent={() => manageTripsSection.upgradeTrip} />
            ) : (
              manageTripsSection.upgradeTrip
            )}
          </button>
        </div>
      );

    return <button onClick={onModifyTrip}>{manageTripsSection.upgradeTrip}</button>;
  };

  return {
    showModifyTripInfoModal,
    setShowModifyTripInfoModal,
    messageForCantCancelModify,
    renderUpgradeTripButton,
    onAddEditExtras,
    onManagePassengers,
    handleClick,
    handleSeatSelectionModalClose,
    handleTicketDownload,
    isCancellableTrip,
    onCancelTrip,
    showModifyTripWidget,
    modifyTripData,
    isFirstLegTraveled,
    isSecondLegTraveled,
    handleReceiptDownload,
  };
};
