import { withStyleSystem } from 'hocs/withStyleSystem';
import React, { useMemo } from 'react';

import { Icon } from '@atoms/Icon';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';
import { SimpleTitleProps } from '@atoms/Title/types';

import { cn, cx } from 'utils/classNames';

import './FeatureItemV2.scss';
import { FeatureItemProps } from './types';
import { Button } from '@atoms/Button';

const bem = cn('feature-item-v2');

const ExtendedTitle = withStyleSystem<SimpleTitleProps>(Title);

const FeatureItemV2 = (props: FeatureItemProps) => {
  const { id, title, description, button, premiumFlag, icon, descriptionTitle, iconAlignment, openLinkInNewWindow } = props;
  const { alignment, variant, colors, showPremiumFlag, renderAs, linkButton } = props;
  const { disableFocus } = props;
  const url = button?.link?.url

  const handleNewWindowClick = () => {
    window.open(url, '_blank', 'width=800,height=600');
  };

  const listItems = description.replaceAll(
    '<li>',
    "<li><span class='blte-feature-item-v2__description' aria-hidden='true'><i class='blte-icon  blte-icon__check-circle'></i></span>"
  );

  const style: any = useMemo(
    () => ({
      '--feature-item-v2-background-color': colors?.background,
      '--feature-item-v2-title-color': colors?.title,
      '--feature-item-v2-description-color': colors?.description,
      '--feature-item-v2-icon-color': colors?.icon?.icon,
      '--feature-item-v2-icon-background-color': colors?.icon?.background,
      '--feature-item-v2-checkmark-color': colors?.checkmark?.icon,
      '--feature-item-v2-checkmark-background-color': colors?.checkmark?.background,
      '--feature-item-v2-flag-text-color': colors?.flag?.text,
      '--feature-item-v2-flag-background-color': colors?.flag?.background,
    }),
    [colors]
  );

  return (
    <li
      id={id}
      className={bem({ variant, background: !!colors?.background, alignment, premium: !!premiumFlag })}
      style={style}
    >
      {showPremiumFlag && !!premiumFlag && <div className={bem('flag')}>{premiumFlag}</div>}
      <div className={bem('content')} role="group" aria-label="item">
        {(icon?.name || icon?.externalIcon) && (
          <span className={bem('icon', { iconAlignment })} aria-hidden="true">
            <Icon {...icon} />
          </span>
        )}
        <div className={bem('title-content')}>
          {variant === 'checkmark' && (
            <span className={bem('checkmark')} aria-hidden="true">
              <Icon name="check" />
            </span>
          )}
          <ExtendedTitle {...title} customClassName={cx(bem('title'))} />
        </div>
        {descriptionTitle && (
          <div className={bem('description-title')}>
            <Text text={descriptionTitle} as="h6" />
          </div>
        )}
        <div className={bem('description')}>
          <Text text={listItems} />
        </div>
          <div className={bem('cta')}>
            {linkButton && linkButton.label && renderAs !== 'button' ? <LinkButton {...linkButton} variant="big" disableFocus={disableFocus} {...(openLinkInNewWindow && { onClick: handleNewWindowClick })} openLinkInNewWindow={openLinkInNewWindow} />
              : button && button.label && <Button {...button} variant='primary' {...(openLinkInNewWindow && { onClick: handleNewWindowClick })}/>}
          </div>
      </div>
    </li>
  );
};

export default FeatureItemV2;
