import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './LoginPrompt.scss';

const bem = cn('passes-login-prompt');

export const LoginPrompt = observer(() => {
  const { cfStore } = useStores();
  const cfLabels = cfStore.passes.reviewAndPay;

  return (
    <div className={bem()}>
      <Text text={cfLabels.loginLabel} className={bem('title')} />
      <Text text={cfLabels.loginRequiredLabel} className={bem('text')} />
    </div>
  );
});
