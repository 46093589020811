import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { rootStore, useStores } from 'stores';

import LinkButton from '@atoms/LinkButton/LinkButton';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn, cx } from 'utils/classNames';
import { isLoyaltyEnabled } from 'utils/metas';

import './Card.scss';
import { CardPlaceholder } from './components/CardPlaceholder';
import { AccountCardProps } from './types';

const bem = cn('account-card');

export const Card = observer((props: AccountCardProps) => {
  const { cfStore } = useStores();
  const navigate = useNavigate();
  const { account } = cfStore;
  const { dashboard } = account;
  const { title, count, placeholderVariant, isLoading, children, className, routeToNavigate, isWallet } = props;

  const label = useMemo(
    () => dashboard.seeAllLabel.replace('{itemsCount}', `${count}`),
    [dashboard.seeAllLabel, count]
  );

  const loyaltyEnabled = isLoyaltyEnabled();

  if (isLoading) return <CardPlaceholder variant={placeholderVariant} isWallet={isWallet} />;

  const handleClickSeeAll = () => {
    if (routeToNavigate) {
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: label,
          customLink: routeToNavigate,
          externalLink: '',
          linkType: 'linkClick',
        },
      });
      if (routeToNavigate.startsWith('/account')) navigate(routeToNavigate.split('/account')[1]);
      else window.location.href = routeToNavigate;
    }
  };

  return (
    <div className={cx(bem(), className)}>
      <div className={bem('header')}>
        <h3 className={bem('title')}>{title}</h3>
        {!(loyaltyEnabled && title === account.dashboard.brightlineWallet.pageTitle) && (
          <LinkButton label={label} className={bem('action')} onClick={handleClickSeeAll} />
        )}
      </div>
      {children}
    </div>
  );
});
