import React from 'react';

import { cn } from 'utils/classNames';

import './SeatMapPlaceholder.scss';

const bem = cn('seat-map-placeholder');
export const SeatMapPlaceholder = () => (
  <div className={bem()}>
    <div className={bem('title')} />
    <div className={bem('subtitle')} />
    <div className={bem('content')} />
  </div>
);
