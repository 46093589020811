const itemKey = 'fromHomePage';

export const setFromHomePageFlag = () => {
  localStorage.setItem(itemKey, 'true');
};

export const removeFromHomePageFlag = () => {
  localStorage.removeItem(itemKey);
};

export const getFromHomePageFlag = () => localStorage.getItem(itemKey);
