import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';

import { Button } from '@atoms/Button';
import { Input } from '@atoms/Input';

import { cn } from 'utils/classNames';
import { formatTravelAgentCode } from 'utils/travelAgentCodeUtils';

import './EditDetailsTravelAgent.scss';

const bem = cn('edit-details-travel-agent');

export const EditDetailsTravelAgent = observer(() => {
  const { accountStore, cfStore } = useStores();
  const { settings: i18n } = cfStore.account;
  const { userProfile, updateUserProfile, setEditDialogTravelAgentClose, isBadRequest } = accountStore.profile;
  const remainingUserProfile = { ...userProfile };
  delete remainingUserProfile.travelAgentCode;
  const [inputValue, setInputValue] = useState(() => {
    if (isBadRequest) return '';

    return userProfile?.travelAgentCode || '';
  });
  const [isPressed, setIsPressed] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = e => {
    setIsPressed(false);
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');

    setInputValue(numericValue.toString());
  };

  const handleOnUpdate = async () => {
    setIsPressed(false);
    setError(false);
    if (inputValue.length > 0 && inputValue.length < 9) {
      setInputValue('');
      setIsPressed(true);
      setError(true);
    } else {
      setError(false);

      try {
        await updateUserProfile({
          ...remainingUserProfile,
          ...(inputValue !== '' && { travelAgentCode: inputValue }),
        });

        setEditDialogTravelAgentClose();
      } catch (error) {
        if (isBadRequest) setInputValue('');
        setIsPressed(true);
        setError(true);
      }
    }
  };

  return (
    <div>
      <Input
        value={formatTravelAgentCode(inputValue)}
        name="editDetailsTravelAgent"
        placeholder={i18n.nineDigitTravelCodeLabel}
        onChange={handleChange}
        maxCharacters={11}
        showMaxCharacters={false}
        error={(isPressed && error) || isBadRequest}
      />
      <div className={bem('container')}>
        {(isPressed || isBadRequest) && (
          <div className={bem('setting-bad-request-travel-agent')}>
            <span>{i18n.travelAgentCodeInvalidLabel}</span>
          </div>
        )}
        <div className={bem('buttons')}>
          <Button label={i18n.cancelCtaTravelAgentLabel} onClick={setEditDialogTravelAgentClose} />
          <Button label={i18n.updateCtaTravelAgentLabel} onClick={handleOnUpdate} />
        </div>
      </div>
    </div>
  );
});
