import React from 'react';

import { cn } from 'utils/classNames';

import './CircularProgressBar.scss';

const bem = cn('circular-progress-bar');

export const CircularProgressBarPlaceholder = () => {
  const radius = 80;
  const progressGapAngle = 90;

  const circumference = 2 * Math.PI * radius;
  const totalProgressBarLength = circumference - progressGapAngle * (circumference / 360);

  return (
    <div className={bem()}>
      <svg
        className={bem('container')}
        width="210px"
        height="210px"
        viewBox="0 0 210 210"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={bem('progress-background-placeholder')}
          cx="100"
          cy="105"
          r={radius}
          strokeDasharray={`${totalProgressBarLength}, ${circumference - totalProgressBarLength}`}
          strokeDashoffset={`-${(circumference - totalProgressBarLength) / 2}`}
        />
      </svg>
    </div>
  );
};
