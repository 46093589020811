import React, { useId } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { WidgetInput } from '@atoms/WidgetInput/WidgetInput';

import { cn, cx } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';

import LocationItem from '../LocationItem/LocationItem';
import { DesktopSelectProps } from '../types';
import './DesktopSelect.scss';

const bem = cn('od-desktop-select');
const bemField = cn('od-select');

const DesktopSelect = (props: DesktopSelectProps) => {
  const id = useId();

  const { placeholder, locationHint, onChangeTextInput, onClearClick, textInput = '' } = props;
  const { label, error, errorMessage, onHeaderClick, selectIndex, resetActive } = props;
  const { value, items, focus, active, handleItemClick, handleKeyDown, position } = props;
  const { isRequired = false, addErrorId } = props;

  const shouldFocus = itemIndex => focus?.index === itemIndex && !focus?.preventScroll && active;
  const ariaActivedescendant =
    focus?.index !== undefined && focus?.index !== -1 ? `loc-item-${selectIndex}-${items[focus.index]?.id}` : '';
  const shouldFocusInput = active && focus?.index === -1;
  const selectedItem = items.find(item => item.id === value);
  const filteredItems = items.filter(item => item.name.toLowerCase().indexOf(textInput.toLowerCase()) > -1) || [];

  const handleResetIndex = () => {
    if (active) resetActive();
  };

  const handleHeaderClick = () => onHeaderClick(selectIndex);

  const handleClearInHeader = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClearClick && onClearClick(e);
  };

  return (
    <div className={cx(bem(), bemField())}>
      <WidgetInput
        isInvalid={error}
        errorMessage={errorMessage}
        onInputClick={handleHeaderClick}
        onClearClick={handleClearInHeader}
        onInputKeyDown={handleKeyDown}
        label={label}
        placeholder={placeholder}
        value={selectedItem?.name || textInput}
        position={position}
        isRequired={isRequired}
        addErrorId={addErrorId}
        generatedId={id}
        isKiosk={isKiosk()}
        ariaHaspopup="listbox"
        ariaExpanded={active}
        ariaControls={`results-${id}`}
        ariaActivedescendant={ariaActivedescendant}
        readOnly
        noAriaHidden
        includeAriaAttributes
      />

      <div className={bem('list', { active: active })} onKeyDown={handleKeyDown}>
        <OutsideClickHandler onOutsideClick={handleResetIndex}>
          <WidgetInput
            label={label}
            placeholder={placeholder}
            value={selectedItem?.name || textInput}
            onInputChange={onChangeTextInput}
            onClearClick={onClearClick}
            focus={shouldFocusInput}
            position={position}
            addErrorId={addErrorId}
            ariaAutocomplete="list"
            ariaControls={`results-${id}`}
            ariaHaspopup="listbox"
            ariaExpanded={active}
            ariaActivedescendant={ariaActivedescendant}
            generatedId={id}
            readOnly={isKiosk()}
            isKiosk={isKiosk()}
            noAriaHidden
          />

          <div id={`instructions-${id}`} className="sr-only">
            Use up and down arrow keys to navigate results, Enter to select.
          </div>
          <div className="sr-only" aria-live="assertive">
            {active && !filteredItems.length && `No results found`}
            {active && filteredItems.length === 1 && `One result found`}
            {active && filteredItems.length > 1 && `${filteredItems.length} results found`}
          </div>

          <ul className={bem('listItems')} id={`results-${id}`} role="listbox" aria-labelledby={`input-${id}`}>
            {filteredItems.map((option, itemIndex) => (
              <LocationItem
                key={`loc-item-${option.id}`}
                id={`loc-item-${selectIndex}-${option.id}`}
                locationHint={locationHint}
                selected={selectedItem?.id === option.id}
                focus={shouldFocus(itemIndex)}
                option={option}
                itemIndex={itemIndex}
                handleItemClick={handleItemClick}
              />
            ))}
          </ul>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default DesktopSelect;
