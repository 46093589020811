import { ErrorMessage } from 'formik';
import React from 'react';
import InputMask from 'react-input-mask';

import { Input } from '@atoms/Input';
import { Select } from '@atoms/Select';
import { useSelectHandlers } from '@atoms/Select/useSelectHandlers';

import { cn, cx } from 'utils/classNames';
import { isKiosk } from 'utils/isKiosk';

import './PhoneNumber.scss';
import { countryCodesOptions, defaultPhoneName } from './constants';
import { PhoneNumberProps } from './types';
import { usePhoneNumberLogic } from './usePhoneNumberLogic';

const bem = cn('phone-number');

const PhoneNumber = (props: PhoneNumberProps) => {
  const { helperText, label, disabled, required, afterErrorFocus, ariaLabel, searchCountryCodePlaceholder } = props;
  const { className = '', name, showAsterisk, displayTrailingIcon } = props;

  const nullCheckName = name || defaultPhoneName;

  const { handleClickTrailingIcon, onPrefixChange, onSuffixChange, numberFormat, countryCode, meta, number, onBlur } =
    usePhoneNumberLogic({
      ...props,
      name: nullCheckName,
    });

  const hookProps = useSelectHandlers({
    ...props,
    optionsData: countryCodesOptions,
    onChange: onPrefixChange,
  });

  return (
    <div className={cx(bem({ 'is-disabled': disabled }), { [className]: !!className })}>
      <div className={bem('input-wrapper')}>
        <Select
          {...hookProps}
          name={`${name}.countryCode`}
          onChange={onPrefixChange}
          value={countryCode}
          className={bem('country-code')}
          disabled={disabled}
          error={meta.touched && !!meta.error}
          required={required}
          searchCountryCodePlaceholder={searchCountryCodePlaceholder}
          ariaLabel="Select country code"
          phoneNumberList
        />
        <InputMask
          mask={numberFormat}
          onBlur={onBlur}
          value={number}
          onChange={onSuffixChange}
          disabled={disabled}
          required={required}
          maskPlaceholder={null}
          error={meta.touched && !!meta.error}
        >
          <Input
            name={`${name}.number`}
            value={number}
            label={label}
            type="tel"
            className={bem('number')}
            showAsterisk={showAsterisk}
            displayTrailingIcon={displayTrailingIcon}
            onClickTrailingIcon={handleClickTrailingIcon}
            autocomplete={!isKiosk() ? 'tel-national' : 'off'}
            required={required}
            afterErrorFocus={afterErrorFocus}
            ariaLabel={ariaLabel}
          />
        </InputMask>
      </div>
      {!meta.error && <div className={cx(bem('helper'), 'blte-font--variant-body-small-400')}>{helperText}</div>}
      <ErrorMessage name={nullCheckName} className={cx(bem('error'), 'blte-font--variant-tiny-500')} component="span" />
    </div>
  );
};

export default PhoneNumber;
