import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';

import { getTheme } from '@table-library/react-table-library/baseline';
import { SortToggleType, useSort } from '@table-library/react-table-library/sort';
import { useTheme } from '@table-library/react-table-library/theme';

import { cn } from 'utils/classNames';
import { fetchXfHTML } from 'utils/xfUtils';

import './CommissionStatements.scss';

const bem = cn('account-admin-panel');

export const useCommissionStatementsLogic = () => {
  const { cfStore, accountStore } = useStores();
  const { commissions } = cfStore.account;
  const { commissionsStore, profile } = accountStore;
  const { isLoading: isProfileLoading } = profile;
  const travelAgentCode = profile?.userProfile?.travelAgentCode;
  const { commissionsData, fetchCommissionsData } = commissionsStore;
  const { commissionTableFieldsName, bottomExperienceFragment, bottomSecondExperienceFragment } = commissions;
  const { emptyTableCtaLink } = commissions;

  const [isTravelAgent, setIsTravelAgent] = useState(!!travelAgentCode);
  const [bottomExperienceFragmentHTML, setBottomExperienceFragmentHTML] = useState('');
  const [bottomSecondExperienceFragmentHTML, setBottomSecondExperienceFragmentHTML] = useState('');

  const dummyCommissionsData = [
    { id: 1, name: '', bookDate: '', confirmation: '', travelDate: '', total: 0, commission: 0 },
    { id: 2, name: '', bookDate: '', confirmation: '', travelDate: '', total: 0, commission: 0 },
    { id: 3, name: '', bookDate: '', confirmation: '', travelDate: '', total: 0, commission: 0 },
    { id: 4, name: '', bookDate: '', confirmation: '', travelDate: '', total: 0, commission: 0 },
    { id: 5, name: '', bookDate: '', confirmation: '', travelDate: '', total: 0, commission: 0 },
    { id: 6, name: '', bookDate: '', confirmation: '', travelDate: '', total: 0, commission: 0 },
    { id: 7, name: '', bookDate: '', confirmation: '', travelDate: '', total: 0, commission: 0 },
    { id: 8, name: '', bookDate: '', confirmation: '', travelDate: '', total: 0, commission: 0 },
  ];

  useEffect(() => {
    setIsTravelAgent(!!travelAgentCode);
  }, [isProfileLoading, travelAgentCode]);

  useEffect(() => {
    const fetchXfs = async () => {
      bottomExperienceFragment && setBottomExperienceFragmentHTML(await fetchXfHTML(bottomExperienceFragment));
      bottomSecondExperienceFragment &&
        setBottomSecondExperienceFragmentHTML(await fetchXfHTML(bottomSecondExperienceFragment));
    };

    fetchCommissionsData();
    fetchXfs();
  }, [bottomExperienceFragment, bottomSecondExperienceFragment, fetchCommissionsData]);

  const theme = useTheme([
    getTheme(),
    {
      Table: `
          --data-table-library_grid-template-columns: 192px 147px 147px 147px minmax(108px, 1fr) minmax(135px, 1fr);
        `,
    },
  ]);

  const headerConfig = [
    { name: commissionTableFieldsName[0] || '', sortKey: 'NAME', sortable: true },
    { name: commissionTableFieldsName[1] || '', sortKey: 'BOOKDATE', sortable: true },
    { name: commissionTableFieldsName[2] || '', sortKey: '', sortable: false },
    { name: commissionTableFieldsName[3] || '', sortKey: 'TRAVELDATE', sortable: true },
    { name: commissionTableFieldsName[4] || '', sortKey: 'TOTAL', sortable: true },
    { name: commissionTableFieldsName[5] || '', sortKey: 'COMMISSION', sortable: true },
  ];

  const handleCsvDownload = async () => {
    try {
      const commissionsDataCSV: any = await fetchCommissionsData('csv');

      const blob = new Blob([commissionsDataCSV], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'commissions.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error fetching CSV data:', error);
    }
  };

  const sort = useSort(
    { nodes: commissionsData },
    {},
    {
      sortIcon: {
        margin: '0px',
        iconDefault: <div className={bem('table-header-icon-default')} />,
        iconUp: <div className={bem('table-header-icon-up')} />,
        iconDown: <div className={bem('table-header-icon-down')} />,
      },
      sortToggleType: SortToggleType.AlternateWithReset,
      sortFns: {
        NAME: array => array?.sort((a, b) => a.name.localeCompare(b.name)),
        BOOKDATE: array =>
          array?.sort((a, b) => {
            const dateA = new Date(a.bookDate).getTime();
            const dateB = new Date(b.bookDate).getTime();

            return dateA - dateB;
          }),
        TRAVELDATE: array =>
          array?.sort((a, b) => {
            const dateA = new Date(a.travelDate).getTime();
            const dateB = new Date(b.travelDate).getTime();

            return dateA - dateB;
          }),
        TOTAL: array => array?.sort((a, b) => parseFloat(a.total) - parseFloat(b.total)),
        COMMISSION: array => array?.sort((a, b) => parseFloat(a.commission) - parseFloat(b.commission)),
      },
    }
  );

  const formatCurrency = value => {
    const num = parseFloat(value);

    if (num % 1 === 0) return num;

    return num
      .toFixed(2)
      .replace(/\.00$/, '')
      .replace(/(\.\d)0$/, '$1');
  };

  const handleEmptyTableCtaClicked = () => {
    window.location.href = emptyTableCtaLink || '/booking';
  };

  return {
    isTravelAgent,
    bottomExperienceFragmentHTML,
    bottomSecondExperienceFragmentHTML,
    theme,
    headerConfig,
    handleCsvDownload,
    sort,
    formatCurrency,
    handleEmptyTableCtaClicked,
    dummyCommissionsData,
  };
};
