import { useEffect, useRef, useState } from 'react';

import { ToggleProps } from './types';

export const useToggleLogic = (props: ToggleProps) => {
  const { name, items, defaultChecked, onChange } = props;
  const [selectedIndex, setSelectedIndex] = useState(defaultChecked);
  const toggleRef = useRef<HTMLDivElement>(null);

  const handleClick = index => e => {
    e.preventDefault();

    setSelectedIndex(index);
    if (onChange)
      onChange({
        name,
        value: items[index].value,
        selectedIndex: index,
      });
  };

  const clickAndFocus = (next = false) => {
    if (toggleRef && toggleRef.current) {
      if (next) {
        if (selectedIndex === items.length - 1) return;
      } else if (selectedIndex === 0) return;

      const nodeIndex = next ? selectedIndex + 1 : selectedIndex - 1;
      const button = toggleRef.current.childNodes[nodeIndex] as HTMLButtonElement;
      button.click();
      button.focus();
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') clickAndFocus(true);
    if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') clickAndFocus(false);
  };

  useEffect(() => {
    setSelectedIndex(defaultChecked);
  }, [defaultChecked]);

  return { handleClick, handleKeyDown, toggleRef, selectedIndex };
};
