import { useField } from 'formik';

import { cn } from 'utils/classNames';

const bem = cn('textField');

export const useInputLogic = props => {
  const { name } = props;
  const [field, meta, helpers] = useField({ name, className: bem() });

  const { touched } = meta;
  const { setValue } = helpers;
  const isInvalid = touched && (!!meta.error || props.error);

  const handleClickTrailingIcon = () => {
    setValue('');
  };

  return {
    handleClickTrailingIcon,
    field,
    helpers,
    isInvalid,
    error: props.error ? props.helperText : meta.error,
  };
};
