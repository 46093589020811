import { observer } from 'mobx-react-lite';
import React, { Suspense } from 'react';
import { useStores } from 'stores';

import { useLogin } from '@account/hooks/useLogin';
import { TopLoadingBar } from '@atoms/TopLoadingBar';

import { cn } from 'utils/classNames';

import './InfoContainer.scss';
import { InfoContainerProps } from './types';

const bem = cn('account-info');

export const InfoContainer = observer(({ children }: InfoContainerProps) => {
  const { authStore } = useStores();
  const { isAuthenticated } = authStore;

  useLogin();

  // useEffect cannot be used because we need this to happen before first rendering
  if (isAuthenticated) document.body.classList.add('hasVisibleXFContainers', 'hasHiddenSidebar');
  else document.body.classList.remove('hasVisibleXFContainers');

  if (!isAuthenticated) return null;

  return (
    <div className={bem()}>
      <TopLoadingBar />
      <div className={bem('body')}>
        <Suspense fallback={<div />}>{children}</Suspense>
      </div>
    </div>
  );
});
