import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { SavedCardItem } from './SavedCardItem';
import './SavedCards.scss';
import { SavedCardsProps } from './types';

const bem = cn('passes-saved-cards');

export const SavedCards = observer((props: SavedCardsProps) => {
  const { noAmountToPay } = props;

  const { cfStore, passesStore } = useStores();
  const { savedCards } = passesStore.checkout;
  const cfLabels = cfStore.passes.reviewAndPay;

  const newCard = useMemo(() => {
    let newCardLabel: string;
    if (savedCards.length >= 5 && passesStore.checkout.urlParams.autoRenews)
      newCardLabel = cfLabels.savedCardsLimitLabel;
    else newCardLabel = cfLabels.addCreditOrDebitCardLabel;

    return {
      tokenId: 'new-card',
      brand: 'new-card',
      name: newCardLabel,
      lastFour: '',
      isPreferred: false,
    };
  }, [savedCards, cfLabels, passesStore]);

  return (
    <div className={bem()}>
      <Text text={cfLabels.cardInformation.creditCardLabel} className={bem('title')} />
      <div className={bem('cards')}>
        {savedCards.map(card => (
          <SavedCardItem key={card.tokenId} card={card} noAmountToPay={noAmountToPay} />
        ))}
        <SavedCardItem card={newCard} noAmountToPay={noAmountToPay} />
      </div>
    </div>
  );
});
