import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useStores } from 'stores';

import Checkbox from '@atoms/Checkbox/Checkbox';
import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import Tooltip from '@atoms/Tooltip/Tooltip';

import { cn } from 'utils/classNames';
import { color_base_black, color_white } from 'utils/colors';

import './Item.scss';
import { PassengerItem } from './PassengerItem/PassengerItem';
import { ItemProps } from './types';
import { useExtrasModalItemLogic } from './useExtrasModalItemLogic';

const bem = cn('extras-modal-item');

export const Item = observer((props: ItemProps) => {
  const { itemCard, itemCardIndex, modalLabels, extrasModalLogic } = props;

  const { passengers, cfLabels, isUnvailable, isSoldOut } = itemCard;

  const { departure, return: returnLabel, to } = modalLabels;

  const { applyAllMaxQuantityLabel, applyAllNotAvailableLabel, addedLabel } = cfLabels;

  const { handleFormFieldChanged } = extrasModalLogic;

  const { bookingStore } = useStores();
  const { provisionalBooking } = bookingStore.rti;
  const trip = provisionalBooking.tripDetails?.trip;

  const { values: formValues, setFieldValue } = useFormikContext();
  const values = formValues as any;

  const { expanded, handleHeaderClick, config } = useExtrasModalItemLogic(props);

  const checkboxField = values[config.checkboxName];

  useEffect(() => {
    handleFormFieldChanged();
  }, [checkboxField, handleFormFieldChanged]);

  const isItemAdded = useMemo(
    () =>
      Object.entries(values)
        .filter(([key]) => key.startsWith(`outbound${itemCardIndex}`) || key.startsWith(`inbound${itemCardIndex}`))
        .some(([, value]) => !!parseInt(value as string)),
    [values, itemCardIndex]
  );

  const isRoundTrip = useMemo(() => !!trip?.inboundRoute, [trip]);

  const isUnavailableInbound = Object.values(passengers).every(passenger => passenger.inboundItems.length <= 1);

  const isUnavailableOutbound = Object.values(passengers).every(passenger => passenger.outboundItems.length <= 1);

  const odStations = useMemo(
    () => ({
      origin: trip?.outboundRoute?.origin?.abbreviation || '',
      destination: trip?.outboundRoute?.destination?.abbreviation || '',
    }),
    [trip]
  );

  const tooltipConfig = useMemo(
    () => ({
      text: values[`${config.checkboxName}_disabled`] ? applyAllMaxQuantityLabel : applyAllNotAvailableLabel,
      colors: {
        info: {
          text: {
            inactive: color_white,
          },
          background: {
            inactive: color_base_black,
          },
        },
        content: {},
      },
    }),
    [values, config.checkboxName, applyAllNotAvailableLabel, applyAllMaxQuantityLabel]
  );

  const handleCheckboxChanged = e => {
    const isChecked = e.target.checked;
    if (isChecked)
      Object.entries(values)
        .filter(([key]) => key.startsWith(`outbound${itemCardIndex}`))
        .forEach(([key, value]) => {
          const passengerID = key.split('-')[1];

          setFieldValue(`inbound${itemCardIndex}-${passengerID}`, value);
        });
    else
      Object.keys(values)
        .filter(key => key.startsWith(`inbound${itemCardIndex}`))
        .forEach(key => setFieldValue(key, '0'));

    setFieldValue(config.checkboxName, isChecked);
  };

  return (
    <div className={bem({ expanded })}>
      <div className={bem('header', { expanded })} onClick={handleHeaderClick}>
        <div className={bem('header-title-wrapper')}>
          <h2 className={bem('header-title-heading')}>
            <button className={bem('header-title-button')} aria-expanded={expanded} aria-controls={`content-${itemCardIndex}`}>
              <Text text={config.title} className={bem('header-title')} />
            </button>
          </h2>
          {isSoldOut && <Text text={config.soldOut} className={bem('header-soldout')} />}
          {isUnvailable && <Text text={config.notAvailable} className={bem('header-soldout')} />}
        </div>
        {!isUnvailable && !isItemAdded && (
          <div className={bem('header-price')}>
            <Text text={config.from} className={bem('header-price-sub')} />
            <Text text={`$${config.fromPrice.toFixed(2)}`} className={bem('header-price-main')} />
          </div>
        )}
        {!isUnvailable && isItemAdded && (
          <div className={bem('header-added')}>
            <Icon name="check-circle" className={bem('header-added-icon')} />
            <Text text={addedLabel} className={bem('header-added-text')} />
          </div>
        )}
        {!isUnvailable && (
          <div className={bem('icon')}>
            {!expanded && (
              <span aria-hidden="true">
                <Icon name="chevron-down" />
              </span>
            )}
            {expanded && (
              <span aria-hidden="true">
                <Icon name="chevron-up" />
              </span>
            )}
          </div>
        )}
      </div>
      {expanded && (
        <div className={bem('content')}>
          <div className={bem('passenger-list')}>
            {!isUnavailableOutbound && (
              <div className={bem('od-row')}>
                {departure} - {odStations.origin} {to} {odStations.destination}
              </div>
            )}
            {!isUnavailableOutbound &&
              Object.entries(passengers).map(([key, value]) => (
                <PassengerItem
                  key={`${key}-outbound`}
                  itemCardIndex={itemCardIndex}
                  passengerID={key}
                  passenger={value}
                  direction="outbound"
                  extrasModalLogic={extrasModalLogic}
                />
              ))}
            {!checkboxField && !isUnavailableInbound && (
              <div className={bem('od-row')}>
                {returnLabel} - {odStations.destination} {to} {odStations.origin}
              </div>
            )}
            {!checkboxField &&
              !isUnavailableInbound &&
              Object.entries(passengers).map(([key, value]) => (
                <PassengerItem
                  key={`${key}-inbound`}
                  itemCardIndex={itemCardIndex}
                  passengerID={key}
                  passenger={value}
                  direction="inbound"
                  extrasModalLogic={extrasModalLogic}
                />
              ))}
          </div>
          {isRoundTrip && (
            <div className={bem('checkbox')}>
              {isUnavailableInbound || isUnavailableOutbound || !!values[`${config.checkboxName}_disabled`] ? (
                <Tooltip
                  text={tooltipConfig.text}
                  colors={tooltipConfig.colors}
                  renderTooltipContent={() => (
                    <Checkbox
                      name={config.checkboxName}
                      label={config.applyToEntireTrip}
                      isChecked={values[config.checkboxName]}
                      disabled={
                        isUnavailableInbound || isUnavailableOutbound || !!values[`${config.checkboxName}_disabled`]
                      }
                      onChange={handleCheckboxChanged}
                    />
                  )}
                />
              ) : (
                <Checkbox
                  name={config.checkboxName}
                  label={config.applyToEntireTrip}
                  isChecked={values[config.checkboxName]}
                  disabled={
                    isUnavailableInbound || isUnavailableOutbound || !!values[`${config.checkboxName}_disabled`]
                  }
                  onChange={handleCheckboxChanged}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
