import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores, rootStore } from 'stores';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';

import './ProfileV2.scss';
import { ProfileV2Placeholders } from './ProfileV2Placeholders';

const bem = cn('profileV2');

export const ProfileV2 = observer(() => {
  const { accountStore, cfStore } = useStores();

  const { profile } = accountStore;
  const { userProfile, isLoading } = profile;
  const memberSince = userProfile?.memberSince;
  const formattedMemberSinceDate =
    memberSince && moment(memberSince).isValid() ? moment(memberSince).format('MMM D, YYYY') : '';

  const { dashboard } = cfStore.account;
  const { profileRedirectionLinkReference, memberSinceLabel, chevronRightIcon } = dashboard;

  const { link, navigationTitle } = profileRedirectionLinkReference;

  const navigate = useNavigate();

  const handleClick = () => {
    if (link) {
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: navigationTitle,
          customLink: link,
          externalLink: '',
          linkType: 'linkClick',
        },
      });
      if (link.startsWith('/account')) navigate(link.split('/account')[1]);
      else window.location.href = link;
    }
  };

  if (isLoading) return <ProfileV2Placeholders />;

  return (
    <div className={bem('profile-banner')} onClick={handleClick}>
      <div className={bem('user-avatar')}>
        {userProfile?.firstName[0]} {userProfile?.lastName[0]}
      </div>
      <div className={bem('user-info')}>
        <Text className={bem('user-name')} text={`${userProfile?.firstName} ${userProfile?.lastName}`} />
        {formattedMemberSinceDate && (
          <Text
            className={bem('membership')}
            text={`${memberSinceLabel} <span class=${bem('date')}>${formattedMemberSinceDate}</span>`}
          />
        )}
      </div>
      {chevronRightIcon.src && <Icon className={bem('icon')} externalIcon={chevronRightIcon.src} />}
    </div>
  );
});
