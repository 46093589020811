import DOMPurify from 'dompurify';
import React, { forwardRef } from 'react';

import { cn, cx } from 'utils/classNames';

import { TextProps } from './types';

const bem = cn('text');

export const Text = forwardRef(({ as: Tag = 'div', text = '', className, ...rest }: TextProps, ref) => (
  <Tag
    ref={ref}
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text, { ADD_ATTR: ['target'] }) }}
    className={cx(bem(), className)}
    {...rest}
  />
));
