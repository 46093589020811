import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import Image from '@atoms/Image/Image';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import { ExtrasItemProps } from './types';
import { useExtrasLogic } from './useExtrasLogic';

const bem = cn('rti-extras');

export const ExtrasItem = observer((props: ExtrasItemProps) => {
  const { type, products, labels, expanded, onCloseRef } = props;
  const { cfStore } = useStores();
  const { generic } = cfStore;

  const { items, hasItems, minPrice, total, handleShowModal } = useExtrasLogic(products, type, labels);

  const showExtrasModal = () => {
    onCloseRef?.current && onCloseRef.current.focus();
    handleShowModal();
  };

  return (
    <div
      className={bem('item-card', { collapsed: !expanded, completed: hasItems })}
      role="group"
      aria-label="extras item"
    >
      {expanded && (
        <div className={bem('item-card-image')}>
          {labels?.image && <Image {...labels.image} alt={labels.imageAlt} />}
        </div>
      )}

      {expanded && hasItems && (
        <div className={bem('completed-icon')}>
          <p className="sr-only">{`There are currently items of type ${type} added to the cart`}</p>
          <Icon name="check-circle" />
        </div>
      )}
      <div className={bem('item-info', { expanded })}>
        <div>
          <div className={bem('item-info-title')}>
            <div className={bem('item-info-title-row')}>
              <Text as="h5" text={labels?.label} className={bem('item-info-title-text')} />
              {!expanded && !hasItems && (
                <div className={bem('item-offer-price')}>
                  <div>{cfStore.generic.fromLabel}</div>
                  <div className={bem('item-offer-price-amount')}>{`$${minPrice.toFixed(2)}`}</div>
                </div>
              )}
            </div>
            {!expanded && !hasItems && (
              <Button
                externalBtnRef={onCloseRef}
                icon={!hasItems ? 'plus-slim' : undefined}
                label={!hasItems ? cfStore.generic.addLabel : cfStore.generic.editLabel}
                color={expanded || !hasItems ? 'black' : 'blue'}
                variant={expanded || !hasItems ? 'primary' : 'secondary'}
                size="small"
                onClick={showExtrasModal}
              />
            )}
          </div>
          {!hasItems && expanded && <div>{labels?.description}</div>}
          {hasItems && (
            <div className={bem('items-list')}>
              {items
                .filter(item => !!item.numPassengers)
                .map(item => (
                  <div key={item.title} className={bem('item-row')}>
                    <Text text={item.title} className={bem('item-title')} />
                    <div className={bem('item-details-row', { wrapRow: true })}>
                      <div className={bem('item-details')}>
                        <Text
                          text={`${item.legs} × ${item.numPassengers} ${
                            item.numPassengers === 1 ? generic.passengerSingularLabel : generic.passengerPluralLabel
                          }`}
                          className={bem('item-details-text')}
                        />
                      </div>
                      <Text
                        text={item.total ? `$${item.total.toFixed(2)}` : `${generic.freeLabel}`}
                        className={bem('item-details-text')}
                      />
                    </div>
                    {item.isSoldOut && <Text text={generic.soldOutLabel} style={{ color: 'red' }} />}
                  </div>
                ))}
            </div>
          )}
        </div>
        {(expanded || hasItems) && (
          <div className={bem('item-offer', { collapsed: !expanded })}>
            {expanded && !hasItems && (
              <div className={bem('item-offer-price')}>
                <div>{cfStore.generic.fromLabel}</div>
                <div className={bem('item-offer-price-amount')}>{`$${minPrice.toFixed(2)}`}</div>
              </div>
            )}
            {hasItems && (
              <div className={bem('item-offer-price')}>
                <div>{cfStore.generic.totalLabel}</div>
                <div className={bem('item-offer-price-amount')}>{`$${total.toFixed(2)}`}</div>
              </div>
            )}
            {(expanded || hasItems) && (
              <Button
                externalBtnRef={onCloseRef}
                icon={!hasItems ? 'plus-slim' : undefined}
                label={!hasItems ? cfStore.generic.addLabel : cfStore.generic.editLabel}
                color={expanded || !hasItems ? 'black' : 'blue'}
                variant={expanded || !hasItems ? 'primary' : 'secondary'}
                size="small"
                onClick={showExtrasModal}
                ariaHidden
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
});
