import React from 'react';
// import { PolicyItem } from './PolicyItemComponent';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';

import { PolicyItem } from './PolicyItemComponent/PolicyItem';
import './PolicyItems.scss';

const bem = cn('policy-items');

export const PolicyItems = () => {
  const { cfStore } = useStores();
  const { policyItems } = cfStore.rti.reviewAndPay;

  return (
    <div>
      <div className={bem('title')}>{policyItems?.[0]?.policyItemsTitle}</div>
      {policyItems.map((policyItem, idx) => (
        <PolicyItem key={`${policyItem?.title}-${idx}`} {...policyItem} />
      ))}
    </div>
  );
};
