import React from 'react';

import { Text } from '@atoms/Text';
import Tooltip from '@atoms/Tooltip/Tooltip';
import { tooltipColors } from '@organisms/TrainPasses/TrainPassesCard/constants';

import { cn } from 'utils/classNames';

import './TrainPassesOption.scss';

const bem = cn('train-passes-option');

export const TrainPassesOption = props => {
  const { title, description, tooltip } = props;

  return (
    <>
      <Text className={bem('pass-title')} text={title} />
      <div className={bem('text-icon-container')}>
        <span>
          {description}{' '}
          {tooltip && (
            <div className={bem('info-icon', 'container')}>
              <Tooltip text={tooltip || ''} colors={tooltipColors} />
            </div>
          )}
        </span>
      </div>
    </>
  );
};
