import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores, rootStore } from 'stores';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';

export const useRewardsSummaryLogic = () => {
  const { cfStore, accountStore } = useStores();
  const { profile, rewardsSummary, transactionHistory } = accountStore;
  const { rewardsGeneral } = cfStore.account;

  const { addToAppleWalletImg, addToGoogleWalletImg } = rewardsGeneral.rewardsCard;

  const memberSince = profile.userProfile?.memberSince;
  const formattedMemberSinceDate =
    memberSince && moment(memberSince).isValid() ? moment(memberSince).format('MMM D, YYYY') : '';

  const isProfileLoading = profile.isLoading;

  const isSummaryLoading = rewardsSummary.isLoading;
  const { summary } = rewardsSummary;

  const isHistoryLoading = transactionHistory.isLoading;
  const { history } = transactionHistory;

  const navigate = useNavigate();

  const [isCardExpanded, setCardExpanded] = useState(false);
  const [addToWalletLink, setAddToWalletLink] = useState('');
  const [addToWalletImg, setAddToWalletImg] = useState('');

  const handleClick = (link: string) => {
    if (link) {
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: rewardsGeneral.rewardsPage.pageTitle,
          customLink: rewardsGeneral.rewardsPage.link,
          externalLink: '',
          linkType: 'linkClick',
        },
      });
      if (link.startsWith('/account')) navigate(link.split('/account')[1]);
      else window.location.href = link;
    }
  };

  const toggleDrawer = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIphone = /iphone/.test(userAgent) && !/ipad/.test(userAgent);
    const isAndroid = /android/.test(userAgent) && /mobile/.test(userAgent);

    if (isIphone) {
      setAddToWalletImg(addToAppleWalletImg.src || '');
      setAddToWalletLink(summary?.apple_pass_url || '');
    } else if (isAndroid) {
      setAddToWalletImg(addToGoogleWalletImg.src || '');
      setAddToWalletLink(summary?.google_pass_url || '');
    } else {
      setAddToWalletImg('');
      setAddToWalletLink('');
    }

    setCardExpanded(!isCardExpanded);
  };

  return {
    isHistoryLoading,
    isSummaryLoading,
    isProfileLoading,
    handleClick,
    toggleDrawer,
    formattedMemberSinceDate,
    summary,
    history,
    isCardExpanded,
    addToWalletImg,
    addToWalletLink,
  };
};
