import React from 'react';
import InputMask from 'react-input-mask';

import { Input } from '@atoms/Input';

import { dateFormatDay, defaultInputName } from './constants';
import { InlineDateInputProps } from './types';

export const InlineDateInput = (props: InlineDateInputProps) => {
  const { type = 'text', name, dateFormat = dateFormatDay, helperText = '', maxCharacters = 10, ...rest } = props;
  const nullCheckName = name || defaultInputName;

  return (
    <InputMask
      {...rest}
      name={nullCheckName}
      maxCharacters={maxCharacters}
      placeholder={dateFormat}
      helperText={helperText}
      type={type}
      mask="99/99/9999"
      maskPlaceholder={null}
      displayTrailingIcon
    >
      <Input
        {...rest}
        type={type}
        helperText={helperText}
        name={nullCheckName}
        maxCharacters={maxCharacters}
        placeholder={dateFormat}
        displayTrailingIcon
      />
    </InputMask>
  );
};

export default InlineDateInput;
