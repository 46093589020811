import { cloneDeep } from 'lodash';
import { startTransition, useEffect, useMemo, useRef, useState } from 'react';

import { TableProps } from '@atoms/Table/types';
import { TableCellContentRenderer } from '@organisms/FareCompareModal/v2/TableCellContentRenderer';
import { FareCompareModalV2Props } from '@organisms/FareCompareModal/v2/types';

import { isAuthorMode } from 'utils/metas';
import { parseRteIcons } from 'utils/rteUtils';

export const useFareCompareModalLogicV2 = (props: FareCompareModalV2Props) => {
  const { tableHeaders, tableRows, minTableWidth } = props;
  const { stickyColIndex, isStickyHeader, hideTableTitle, renderAs } = props;

  const [visible, setVisibility] = useState(false);

  const tableParentRef = useRef<HTMLDivElement>(null);

  const renderAsModal = renderAs === 'showAsModal' && !isAuthorMode();

  const modalVariant = useMemo(() => {
    if (!renderAsModal) return undefined;

    return hideTableTitle ? 'mini' : 'default';
  }, [renderAsModal, hideTableTitle]);

  const hideModal = () => {
    setVisibility(false);
  };

  const showModal = e => {
    e.stopPropagation();
    startTransition(() => setVisibility(true));
  };

  const tableConfig: TableProps = useMemo(() => {
    const tableBody = cloneDeep(tableRows);

    Object.values(tableBody).forEach(row => {
      row.forEach(cell => {
        if (!cell) return;

        cell.cellContent = parseRteIcons(cell.cellContent);
      });
    });

    return {
      tableHeaders,
      tableRows: tableBody,
      minTableWidth,
      isStickyHeader,
      stickyColIndex,
      modalVariant,
      cellRenderer: TableCellContentRenderer,
    };
  }, [tableHeaders, tableRows, isStickyHeader, stickyColIndex, minTableWidth, modalVariant]);

  useEffect(() => {
    const buttons = document.querySelectorAll('button[id^="fareCompareButtonV2"]');
    window.addEventListener('showFareCompareModalV2', showModal);

    buttons.forEach(button => {
      button.addEventListener('click', showModal);
    });

    return () => {
      window.removeEventListener('showFareCompareModal', showModal);

      buttons.forEach(button => {
        button.removeEventListener('click', showModal);
      });
    };
  }, []);

  return {
    tableConfig,
    hideModal,
    visible,
    tableParentRef,
    renderAsModal,
    modalVariant,
  };
};
