import React from 'react';

import { Image } from '@atoms/Image';
import { Text } from '@atoms/Text';
import Tooltip from '@atoms/Tooltip/v2/TooltipV2';

import { cn } from 'utils/classNames';

import './RewardsPoints.scss';
import { RewardsPointsProps } from './types';

const bem = cn('rewards-points');

export const RewardsPointsTablet = (props: RewardsPointsProps) => {
  const {
    rewardsIcon,
    rewardsTitle,
    modificationRewardsTitle,
    rewardsSubtextTabletView,
    modificationRewardsSubtext,
    infoIconText,
    modificationInfoIconText,
    hideInfoIcon,
    rewardsPoints,
    isPasses,
    isModification,
  } = props;

  return (
    <div className={bem()}>
      {rewardsIcon?.src && (
        <div className={bem('rewards-icon-wrapper')}>
          {rewardsIcon?.src && <Image className={bem('icon')} {...rewardsIcon} alt="" />}
        </div>
      )}
      <div className={bem('content')}>
        {(rewardsTitle || modificationRewardsTitle || (!hideInfoIcon && infoIconText)) && (
          <div className={bem('title-tooltip-container')}>
            {!isModification && rewardsTitle && <Text className={bem('title')} text={rewardsTitle} />}
            {!isModification && !hideInfoIcon && infoIconText && (
              <Tooltip className={`${bem(isPasses ? 'tooltip-passes' : 'tooltip')}`} text={infoIconText} />
            )}

            {isModification && modificationRewardsTitle && <Text text={modificationRewardsTitle} />}
            {isModification && modificationInfoIconText && (
              <Tooltip className={bem('tooltip')} text={modificationInfoIconText} />
            )}
          </div>
        )}
        {isModification && modificationRewardsSubtext && (
          <Text className={bem('subtext')} text={modificationRewardsSubtext} />
        )}
        {!isModification && rewardsSubtextTabletView && (
          <Text
            className={bem('subtext')}
            text={`
                  <span class="${bem('bolder')}">${rewardsPoints?.toFixed(0)}</span>
                  ${rewardsSubtextTabletView}
                `}
          />
        )}
      </div>
    </div>
  );
};
