import { withStyleSystem } from 'hocs/withStyleSystem';
import React from 'react';

import Button from '@atoms/Button/Button';
import { ExtendedButtonProps } from '@atoms/Button/types';
import Image from '@atoms/Image/Image';
import { ExtendedImageProps } from '@atoms/Image/types';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';
import { ExtendedTitleProps } from '@atoms/Title/types';
import VideoPlayer from '@atoms/Video/VideoPlayer';
import { ExtendedVideoPlayerProps } from '@atoms/Video/types';

import { cn } from 'utils/classNames';

import './Teaser.scss';
import { TeaserProps } from './types';

const bem = cn('teaser');
const bemVideoWrap = cn('videoplayer__wrapper');
const bemTextWrap = cn('text__wrapper');
const bemTitleWrap = cn('title__wrapper');
const bemButtonWrap = cn('button__wrapper');

const ExtendedTitle = withStyleSystem<ExtendedTitleProps>(Title);
const ExtendedImage = withStyleSystem<ExtendedImageProps>(Image);
const ExtendedButton = withStyleSystem<ExtendedButtonProps>(Button);
const ExtendedVideoPlayer = withStyleSystem<ExtendedVideoPlayerProps>(VideoPlayer);

const Teaser = (props: TeaserProps) => {
  const { title, image, button, description, video, mediaType } = props;

  return (
    <div className={bem()}>
      {mediaType === 'video' && !!video && (
        <div className={bem('video')}>
          <div className={bemVideoWrap()}>
            <ExtendedVideoPlayer {...video} />
          </div>
        </div>
      )}
      {mediaType !== 'video' && !!image && (
        <div className={bem('image')}>
          <div className="image">
            <ExtendedImage {...image} />
          </div>
        </div>
      )}
      <div className={bem('title')}>
        <div className={bemTitleWrap()}>{title?.text && <ExtendedTitle {...title} text={title.text} />}</div>
      </div>
      <div className={bem('desc')}>
        <div className={bemTextWrap()}>{description?.description && <Text text={description?.description} />}</div>
      </div>
      {button && (
        <div className={bem('button')}>
          <div className={bemButtonWrap()}>
            <ExtendedButton {...button} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Teaser;
