import { observer } from 'mobx-react-lite';
import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useStores } from 'stores';

import { TopLoadingBar } from '@atoms/TopLoadingBar';

import { cn } from 'utils/classNames';

import { clearRtiData } from '../../../../utils/localstorage';
import { Header } from '../../components/Header';

const bem = cn('booking');
const bemPage = cn('booking-page');

export const Root = observer(() => {
  const {
    ticketsFormStore: { isPanelVisible },
  } = useStores();

  useEffect(() => {
    if (isPanelVisible) document.body.classList.add('blockScroll');
    else document.body.classList.remove('blockScroll');

    return () => {
      document.body.classList.remove('blockScroll');
    };
  }, [isPanelVisible]);

  useEffect(() => {
    if (!location.pathname.includes('/rti')) clearRtiData();
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <div className={bem()}>
      <TopLoadingBar />
      <Header validateOnMount />

      <div className={bemPage({ overlay: isPanelVisible })}>
        <Suspense fallback={<div />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
});
