import { useModalTabLogic } from 'hooks/useModalTabLogic';
import React, { useCallback, useEffect, useId, useRef } from 'react';
import { Portal } from 'react-portal';

import { Icon } from '@atoms/Icon';

import { cn, cx } from 'utils/classNames';

import './Modal.scss';
import { ModalProps } from './types';

const bem = cn('modal');

export const Modal = (props: ModalProps) => {
  const { variant = 'default', children, title, onClose, top } = props;
  const { ariaLabel, className = '', ariaLabelledby, modalContentRef, isModalOpened } = props;
  const id = useId();

  const { modalRef } = useModalTabLogic({ playerOpen: true, closePlayer: onClose, children, isModalOpened });
  const closeRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const focusModal = useCallback(
    e => {
      const firstFocusableElement = closeRef.current;
      const lastFocusableElements = (modalContentRef || contentRef)?.current?.getElementsByClassName(
        'blte-btn blte-btn--size-medium blte-btn--width-normal blte-btn--variant-primary'
      ) as HTMLCollectionOf<HTMLButtonElement>;
      const lastFocusableElement = lastFocusableElements?.[1]?.disabled
        ? lastFocusableElements?.[0]
        : lastFocusableElements?.[1];

      if (e.target == closeRef.current && e.key == 'Tab' && e.shiftKey) {
        e.preventDefault();
        lastFocusableElement?.focus();
      } else if (e.target == lastFocusableElement && e.key == 'Tab' && !e.shiftKey) {
        e.preventDefault();
        firstFocusableElement?.focus();
      }
    },
    [modalContentRef, contentRef]
  );

  useEffect(() => {
    if (closeRef && closeRef.current) closeRef.current.focus();
  }, [closeRef]);

  useEffect(() => {
    modalRef.current?.addEventListener('keydown', focusModal);
    const dialog = modalRef.current;

    return () => {
      dialog?.removeEventListener('keydown', focusModal);
    };
  }, [focusModal, modalRef]);

  const vars: any = {
    '--top': top ? `${top}%` : 0,
  };

  return (
    <Portal>
      <div tabIndex={-1} className={cx(bem({ variant }), { [className]: !!className })} style={vars}>
        <div
          ref={modalRef}
          className={bem('body')}
          role="dialog"
          aria-modal="true"
          aria-labelledby={ariaLabelledby}
          aria-label={ariaLabel}
        >
          {variant.includes('default') && title && (
            <div className={bem('header')} id={id}>
              {title}
              <button ref={closeRef} onClick={onClose} aria-label="close">
                <Icon name="close2" />
              </button>
            </div>
          )}
          <div ref={modalContentRef || contentRef} className={bem('content')}>
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};
