import { makeAutoObservable } from 'mobx';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';

import { RewardsSummaryConfig } from './types';

export class RewardsSummary {
  summary: RewardsSummaryConfig | null;
  isLoading = false;
  hasError = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setSummary(summary) {
    this.summary = summary;
  }

  clearSummaryData() {
    this.summary = null;
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setError(hasError: boolean) {
    this.hasError = hasError;
  }

  async fetchRewardsSummaryData() {
    try {
      this.setLoading(true);
      const url = requestUrls.getBffUrl(requestUrls.loyalty.rewardsSummary);
      const response = await api.get(url);
      this.setSummary(response.data);
      this.setLoading(false);

      return response.data;
    } catch (e) {
      console.error('Failed to fetch loyalty summary data ', e);
      this.setSummary(null);
      this.setError(true);
      this.setLoading(false);

      return null;
    }
  }
}
