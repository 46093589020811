import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import { PromoCodeField } from '@molecules/PromoCode';
import { AlertBanner } from '@molecules/TicketsForm/components/AlertBanner/AlertBanner';
import { DateSelection } from '@molecules/TicketsForm/components/DateSelection';
import { getTicketsFormValidationSchema } from '@organisms/BookingSearch/validator';

import { cn } from 'utils/classNames';

import './TicketsForm.scss';
import Footnote from './components/AlertBanner/Footnote';
import { DATE_FORMAT } from './components/DateSelection/components/DateRangePicker/constants';
import { EditSection } from './components/EditSection';
import { Modal, useModalLogic } from './components/Modal';
import { OriginAndDestination } from './components/OriginAndDestination';
import { PassengerSelection } from './components/PassengerSelection';
import { DEFAULT_I18N } from './constants';
import { TicketsFormMobileProps } from './types';

const bem = cn('tickets-form');

export const TicketsFormMobile = observer((props: TicketsFormMobileProps) => {
  const modalLogicProps = useModalLogic(props);
  const { ticketsFormStore } = useStores();

  const { isPanelVisible, trip, setCurrentStep, currentStep, showPanel, editModeData } = ticketsFormStore;
  const { initialTouchFields, formValues } = ticketsFormStore;

  const { title, passengerSelection, stationsSelection, stations, initialFormValues } = props;
  const { datesSelection, tripSelection, i18n = DEFAULT_I18N, datesRestrictions } = props;
  const { hideFormOnMobile, deprecatedSearchResultsPage, promoCode } = props;
  const { role, ariaLabelledBy, id, bookingOffers } = props;

  const { handleSubmit, handleSaveEdit } = modalLogicProps;
  const { handleEnterEditMode, doHandleModalClose, handleRestoreData } = modalLogicProps;

  const renderOriginDestinationEdit = values => (
    <EditSection onClick={handleEnterEditMode(values, 0)}>
      <div>
        <span>{stations?.find(s => s.id === values.from)?.name}</span>
        <span className={bem('separator')}>
          <Icon name="long-arrow-right" />
        </span>
        <span>{stations?.find(s => s.id === values.to)?.name}</span>
      </div>
    </EditSection>
  );

  const renderDateEdit = values => (
    <EditSection onClick={handleEnterEditMode(values, 1)}>
      <div>
        <span>{values?.startDate ? moment(values?.startDate).format(DATE_FORMAT) : ''}</span>
        {values?.endDate && <span className={bem('separator')}>-</span>}
        <span>{values?.endDate ? moment(values?.endDate).format(DATE_FORMAT) : ''}</span>
      </div>
    </EditSection>
  );

  const renderPassengerEdit = values => (
    <EditSection onClick={handleEnterEditMode(values, 2)}>
      <div>
        <span>
          {values.adults} {passengerSelection.i18n.adults}
        </span>
        {!!values.kids && (
          <span>
            , {values.kids} {passengerSelection.i18n.kids}
          </span>
        )}
        {!!values.infants && (
          <span>
            , {values.infants} {passengerSelection.i18n.infants}
          </span>
        )}
      </div>
    </EditSection>
  );

  const sch = getTicketsFormValidationSchema({ ...stationsSelection.i18n, ...datesSelection.i18n, stations }, !trip)[
    currentStep
  ];

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialFormValues || {}}
      validationSchema={sch}
      initialTouched={initialTouchFields}
      enableReinitialize={false}
      validateOnMount
      validateOnChange
    >
      {({ values, setValues, isValid, touched, handleSubmit: formikSubmit, submitForm }) => (
        <form onSubmit={formikSubmit} role={role} aria-labelledby={ariaLabelledBy} id={id}>
          {!hideFormOnMobile && (
            <OriginAndDestination {...stationsSelection} stations={stations} showPanel={showPanel} isMobile />
          )}

          {isPanelVisible && (
            <Modal title={title} onClose={doHandleModalClose(setValues, initialFormValues)}>
              <div className={bem('content')}>
                <div className={bem('banner-wrapper-mobile')}>
                  <AlertBanner bookingOffers={bookingOffers} />
                  <Footnote bookingOffers={bookingOffers} />
                </div>
                {currentStep === 0 && (
                  <>
                    {!isValid && (
                      <p className={bem('selectFromDropDown')}>{stationsSelection.i18n.selectFromDropdownError}</p>
                    )}
                    <OriginAndDestination
                      {...stationsSelection}
                      stations={stations}
                      defaultSelectedIndex={1}
                      setCurrentStep={setCurrentStep}
                      editMode={!!editModeData}
                      isMobile
                    />
                  </>
                )}
                {currentStep === 1 && (
                  <>
                    {renderOriginDestinationEdit(values)}
                    <DateSelection
                      i18n={datesSelection.i18n}
                      datesRestrictions={datesRestrictions}
                      toggleItems={tripSelection.toggleItems}
                      isMobile
                    />
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    {renderOriginDestinationEdit(values)}
                    {renderDateEdit(values)}
                    <PassengerSelection
                      {...passengerSelection}
                      showWheelchair={!deprecatedSearchResultsPage}
                      isMobile
                    />
                  </>
                )}
                {currentStep === 3 && (
                  <>
                    {renderOriginDestinationEdit(values)}
                    {renderDateEdit(values)}
                    {renderPassengerEdit(values)}
                    <div className={bem('promo', { mobile: true })}>
                      <PromoCodeField
                        {...promoCode}
                        value={formValues.promoCode}
                        isRoundTrip={trip === 1}
                        deprecatedSearch={deprecatedSearchResultsPage}
                      />
                    </div>
                  </>
                )}
              </div>
              {(currentStep === 3 || (touched && isValid)) && (
                <div className={bem('actions')}>
                  {editModeData ? (
                    <div className={bem('actions', { edit: true })}>
                      <Button
                        id="cancel"
                        color="white"
                        label={i18n.cancelLabel}
                        onClick={handleRestoreData(setValues)}
                        width="full"
                      />
                      <Button label={i18n.saveLabel} id="save" onClick={handleSaveEdit} color="yellow" width="full" />
                    </div>
                  ) : (
                    <Button
                      label={currentStep === 3 ? i18n.submitFormLabel : i18n.continueLabel}
                      type="submit"
                      color="yellow"
                      width="full"
                      onClick={submitForm}
                    />
                  )}
                </div>
              )}
            </Modal>
          )}
        </form>
      )}
    </Formik>
  );
});
