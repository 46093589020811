import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { mediaQueryList } from 'utils/mediaQueries';

import './RewardsSummary.scss';
import RewardsSummaryDesktop from './RewardsSummaryDesktop';
import RewardsSummaryMobile from './RewardsSummaryMobile';

const RewardsSummary = observer(() => {
  const isMobile = useMediaPredicate(mediaQueryList.maxMobile);

  return isMobile ? <RewardsSummaryMobile /> : <RewardsSummaryDesktop />;
});

export default RewardsSummary;
