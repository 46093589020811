import { Formik } from 'formik';
import { useQueries } from 'hooks/useQueries';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { rootStore, useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { PromoCodeField } from '@molecules/PromoCode';
import { AlertBanner } from '@molecules/TicketsForm/components/AlertBanner/AlertBanner';
import { getTicketsFormValidationSchema } from '@organisms/BookingSearch/validator';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { mergeSchemas } from 'utils/helpers';
import { selectKioskRoute } from 'utils/isKiosk';

import './TicketsForm.scss';
import Footnote from './components/AlertBanner/Footnote';
import { DateSelection } from './components/DateSelection';
import { OriginAndDestination } from './components/OriginAndDestination';
import { PassengerSelection } from './components/PassengerSelection';
import { TripSelection } from './components/TripSelection';
import { TicketsFormDesktopProps } from './types';

const bem = cn('tickets-form');

export const TicketsFormDesktop = observer((props: TicketsFormDesktopProps) => {
  const { tripSelection, datesSelection, passengerSelection, i18n = null, validateOnMount } = props;
  const { stationsSelection, stations, initialFormValues, datesRestrictions, searchResultsPage } = props;
  const { role, ariaLabelledBy, id, tabIndex } = props;
  const { deprecatedSearchResultsPage, handleBookingSearch, fromStorybook } = props;
  const { promoCode, sendSearchAnalyticsEvent, bookingOffers } = props;

  const { ticketsFormStore, bookingStore } = useStores();
  const { appendQueries } = useQueries();

  const { trip, setFormValues, initialTouchFields, setIncompleteSearch, formValues } = ticketsFormStore;
  const { resetRtiData } = bookingStore.rti;
  const { setOutboundTicket } = bookingStore.tickets;

  const handleSubmit = async values => {
    const formikFormValues = cloneDeep(values);
    formikFormValues.promoCode = formValues.promoCode;
    if (!handleBookingSearch) formikFormValues.promoCodeDetails = undefined;

    setIncompleteSearch(false);
    setFormValues(formikFormValues);
    setOutboundTicket(null);
    resetRtiData();

    if (handleBookingSearch) handleBookingSearch(formikFormValues);

    if (fromStorybook) return;

    if (!handleBookingSearch) {
      sendSearchAnalyticsEvent && sendSearchAnalyticsEvent(formikFormValues);
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: 'Tickets form submit - navigate to search results',
          customLink: selectKioskRoute('/train-selection', searchResultsPage),
          externalLink: '',
          linkType: 'linkClick',
        },
      });
      await appendQueries(
        selectKioskRoute('/train-selection', searchResultsPage),
        deprecatedSearchResultsPage,
        formikFormValues,
        trip
      );
    }
  };

  const schemaArray = getTicketsFormValidationSchema(
    { ...stationsSelection.i18n, ...datesSelection?.i18n, stations },
    !trip
  );

  const joinedSchema = mergeSchemas(schemaArray[0], schemaArray[1], schemaArray[2]);

  return (
    <div className={bem()} role={role} aria-labelledby={ariaLabelledBy} id={id} tabIndex={tabIndex}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialFormValues || {}}
        validationSchema={joinedSchema}
        initialTouched={initialTouchFields}
        validateOnMount={validateOnMount}
        enableReinitialize={false}
      >
        {({ handleSubmit: formikSubmit, submitForm }) => (
          <form onSubmit={formikSubmit}>
            <TripSelection {...tripSelection} />
            <div className={bem('first-row')}>
              <OriginAndDestination {...stationsSelection} stations={stations} />
              <DateSelection
                i18n={datesSelection.i18n}
                toggleItems={tripSelection.toggleItems}
                datesRestrictions={datesRestrictions}
              />
            </div>

            <div className={bem('second-row')}>
              <div className={bem('banner-wrapper-desktop')}>
                <AlertBanner bookingOffers={bookingOffers} />
                <Footnote bookingOffers={bookingOffers} />
              </div>
              <div className={bem('second-column-wrapper')}>
                <div className={bem('promo')}>
                  <PromoCodeField
                    {...promoCode}
                    value={formValues.promoCode}
                    isRoundTrip={trip === 1}
                    deprecatedSearch={deprecatedSearchResultsPage}
                  />
                </div>
                <PassengerSelection {...passengerSelection} showWheelchair={!deprecatedSearchResultsPage} />
                <Button
                  color="yellow"
                  type="submit"
                  label={i18n?.submitFormLabel || 'Submit'}
                  className={bem('button')}
                  onClick={submitForm}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
});
