import React, { useMemo } from 'react';

import { Icon } from '@atoms/Icon';

import { cn } from 'utils/classNames';

import './PillButton.scss';
import { PillButtonProps } from './types';

const bem = cn('pill-button');

export const PillButton = (props: PillButtonProps) => {
  const { link, children, label, hasShadow, disabled, hasIcon, hidden } = props;
  const { colors } = props;

  const styles: any = useMemo(
    () => ({
      '--pill-button-background-color': colors?.background?.inactive,
      '--pill-button-background-hover-color': colors?.background?.hover,
      '--pill-button-background-focus-color': colors?.background?.focus,
      '--pill-button-background-disabled-color': colors?.background?.disabled,
      '--pill-button-text-color': colors?.text?.inactive,
      '--pill-button-text-hover-color': colors?.text?.hover,
      '--pill-button-text-focus-color': colors?.text?.focus,
      '--pill-button-text-disabled-color': colors?.text?.disabled,
      '--pill-button-border-focus-color': colors?.border?.focus,
      '--pill-button-shadow-color': colors?.shadow?.inactive,
      '--pill-button-shadow-visibility': hasShadow == null ? null : hasShadow ? 'block' : 'none',
      '--pill-button-icon-visibility': hasIcon == null ? null : hasIcon ? 'inline' : 'none',
    }),
    [colors, hasShadow, hasIcon]
  );

  if (hidden) return null;

  return (
    <a
      href={link?.url || '#'}
      target={link?.target}
      className={bem({ disabled })}
      style={styles}
      aria-disabled={disabled}
    >
      <h4 className={bem('icon-title')}>{children || label}</h4>
      <span className={bem('external-icon')} aria-hidden="true">
        <Icon name="external" />
      </span>
    </a>
  );
};
