import React, { useState } from 'react';

import '@molecules/TicketsForm/components/OriginAndDestination/Select/Select.scss';

import DesktopSelect from './Desktop/DesktopSelect';
import MobileSelect from './Mobile/MobileSelect';
import { SelectProps } from './types';
import { useSelectHandlers } from './useSelectHandlers';

export const Select = (props: SelectProps) => {
  const { setValue, isMobile } = props;
  const [textInput, setTextInput] = useState('');
  const hookProps = useSelectHandlers({ ...props, textInput });

  const handleChangeTextInput = ev => {
    const { setFocus } = hookProps;
    setTextInput(ev.target.value);
    if (setValue) setValue(ev.target.value);
    if (setFocus) setFocus({ index: -1, preventScroll: false });
  };

  const handleClearClick = () => {
    setTextInput('');
    if (setValue) setValue('');
  };

  if (isMobile)
    return (
      <MobileSelect
        {...props}
        {...hookProps}
        onChangeTextInput={handleChangeTextInput}
        onClearClick={handleClearClick}
        textInput={textInput}
      />
    );

  return (
    <DesktopSelect
      {...props}
      {...hookProps}
      onChangeTextInput={handleChangeTextInput}
      onClearClick={handleClearClick}
      textInput={textInput}
    />
  );
};
