import { TripConfig } from 'stores/AccountStore/sections';

import { PillButtonDesignProps, TabsDesignProps } from '@atoms/Tabs';

const GetModifyTripData = (
  trip: TripConfig | null,
  modifyTripSection: {
    bothTabLabel: string;
    calendarClearDatesButtonLabel: string;
    calendarCloseButtonLabel: string;
    departuredatelabel: string;
    fromDropdownLabel: string;
    fromDropdownPlaceholderLabel: string;
    fromPlaceholder: string;
    locationHint: string;
    returnTabLabel: string;
    returndatelabel: string;
    searchTicketsButtonLabel: string;
    selectDestinationError: string;
    selectOriginError: string;
    toDropdownLabel: string;
    toDropdownPlaceholderLabel: string;
    toPlaceholder: string;
    widgetDescriptionText: string;
    widgetTitleLabel: string;
  },
  isFirstLegTraveled: boolean | undefined,
  isSecondLegTraveled: boolean | undefined,
  isRoundTrip: boolean,
  design: {
    pillButtons?: PillButtonDesignProps;
    tabs?: TabsDesignProps;
  }
) =>
  isRoundTrip
    ? {
        id: trip?.outboundRoute?.id,
        design,
        labels: modifyTripSection,
        tabs: [
          {
            label: modifyTripSection.departuredatelabel,
            type: 'departure',
            isFirstLegTraveled,
            isRoundTrip,
            disabled: isFirstLegTraveled,
            data: {
              inboundRoute: trip?.inboundRoute,
              outboundRoute: trip?.outboundRoute,
              pnr: trip?.referenceNumber,
            },
          },
          {
            label: modifyTripSection.returnTabLabel,
            type: 'return',
            isSecondLegTraveled,
            isRoundTrip,
            disabled: isSecondLegTraveled,
            data: {
              inboundRoute: trip?.inboundRoute,
              outboundRoute: trip?.outboundRoute,
              pnr: trip?.referenceNumber,
            },
          },
          {
            label: modifyTripSection.bothTabLabel,
            type: 'both',
            isFirstLegTraveled,
            isSecondLegTraveled,
            isRoundTrip,
            disabled: isFirstLegTraveled || isSecondLegTraveled,
            data: {
              inboundRoute: trip?.inboundRoute,
              outboundRoute: trip?.outboundRoute,
              pnr: trip?.referenceNumber,
            },
          },
        ],
      }
    : {
        id: trip?.outboundRoute?.id,
        design,
        labels: modifyTripSection,
        tabs: [
          {
            label: modifyTripSection.departuredatelabel,
            type: 'departure',
            isFirstLegTraveled,
            isRoundTrip,
            data: {
              outboundRoute: trip?.outboundRoute,
              pnr: trip?.referenceNumber,
            },
          },
        ],
      };
export { GetModifyTripData };
