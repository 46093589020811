import React from 'react';

import Button from '@atoms/Button/Button';
import { usePaginationComponent } from '@molecules/Pagination/hooks/usePaginationComponent';

import { cn } from 'utils/classNames';

import './Pagination.scss';
import { DOTS } from './hooks/usePagination';
import { PaginationProps } from './types';

const bem = cn('pagination');

const generateKey = pre => `${pre}_${new Date().getTime()}`;

export const Pagination = (props: PaginationProps) => {
  const { titleLabel, pageLabel, nextPageLabel, siblingCount } = props;
  const { previousPageLabel, activePageLabel } = props;
  const { total, itemsPerPage, pageCount, pageNumber } = props;
  const { onPageChange } = props;

  const { interval, onPrevious, currentPage, range, changePage, onNext } = usePaginationComponent(
    pageNumber,
    total,
    itemsPerPage,
    pageCount,
    siblingCount || 1,
    onPageChange
  );

  return (
    <div className={bem()}>
      {titleLabel && (
        <div className={bem('title')}>
          {titleLabel.replace('{intervalItems}', interval).replace('{totalItems}', `${total}`)}
        </div>
      )}
      <ul className={bem('list')}>
        <li key={`${generateKey('elem')}-previousPage`} className={bem('item')}>
          <Button
            label={previousPageLabel}
            size="small"
            onClick={onPrevious}
            disabled={currentPage === 1}
            tab-index={0}
            icon="chevron-left"
            className={bem('arrow-button')}
          />
        </li>
        {range &&
          !!range.length &&
          range.map((item, idx) => (
            <li key={`${generateKey('elem')}-${item}-${idx}`} className={bem('item', { active: currentPage === item })}>
              {item !== DOTS ? (
                <Button
                  label={item}
                  size="small"
                  onClick={changePage(item)}
                  aria-label={
                    currentPage === item
                      ? `${pageLabel.replace('{pageNumber}', item)}, ${activePageLabel}`
                      : `${pageLabel.replace('{pageNumber}', item)}`
                  }
                />
              ) : (
                <div className={bem('dots')}>{item}</div>
              )}
            </li>
          ))}
        <li key={`${generateKey('elem')}-nextPage`} className={bem('item')}>
          <Button
            label={nextPageLabel}
            size="small"
            onClick={onNext}
            disabled={currentPage === pageCount}
            icon="chevron-right"
            className={bem('arrow-button')}
          />
        </li>
      </ul>
    </div>
  );
};
