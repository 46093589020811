import React from 'react';

import { useInputLogic } from 'utils/useInputLogic';

import Checkbox from './Checkbox';
import './Checkbox.scss';
import { CheckboxFieldProps } from './types';

const CheckboxField = (props: CheckboxFieldProps) => {
  const { errorMessage, value } = props;
  const { field, isInvalid, error } = useInputLogic(props);
  const isChecked = field.value === true || (field.value && field.value.indexOf(value) > -1);
  if (isChecked) field.checked = isChecked;

  return (
    <Checkbox {...field} {...props} isChecked={isChecked} isInvalid={isInvalid} errorMessage={error || errorMessage} />
  );
};

export default CheckboxField;
