import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { rootStore } from 'stores';

import { Icon } from '@atoms/Icon';
import { Modal } from '@molecules/Modal/Modal';

import { pageLoadEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';

import './ModalComponent.scss';
import { ModalComponentProps } from './types';
import { useModalComponentLogic } from './useModalComponentLogic';

const bem = cn('modal-component');

const ModalComponent = (props: ModalComponentProps) => {
  const { visible, hideModal, xfContent } = useModalComponentLogic(props);
  const { title, assetType } = props;
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (hasMounted && visible) {
      const assetName = title;
      pageLoadEvent(rootStore, assetType, assetName, false, true);
    }
  }, [visible, title, assetType, hasMounted]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const handleCloseModal = () => {
    const assetName = title;
    pageLoadEvent(rootStore, assetType, assetName, true, false);
    hideModal();
  };

  return (
    <CSSTransition in={visible} classNames="fade" timeout={500} unmountOnExit>
      <Modal onClose={handleCloseModal} variant="no-space-scroll" className={bem()} isModalOpened={visible}>
        <div className={bem('modalHeader')}>
          <button className={bem('button')} onClick={handleCloseModal} aria-label="close">
            <Icon name="close2" />
          </button>
        </div>
        <div dangerouslySetInnerHTML={{ __html: xfContent }} className={bem('content')} />
      </Modal>
    </CSSTransition>
  );
};

export default ModalComponent;
