import React, { useMemo, useState } from 'react';
import Pop from 'react-popover';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';
import { TooltipProps } from '@atoms/Tooltip/types';

import { cn, cx } from 'utils/classNames';

import './Tooltip.scss';

const bem = cn('tooltip');

const Tooltip = (props: TooltipProps) => {
  const { text, renderTooltipContent, colors, className } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const styles: any = useMemo(
    () => ({
      '--tooltip-info-text-color': colors?.info?.text?.inactive,
      '--tooltip-info-background-color': colors?.info?.background?.inactive,
      '--tooltip-content-text-color': colors?.content?.text?.inactive,
      '--tooltip-content-background-color': colors?.content?.background?.inactive,
      '--tooltip-content-border-focus-color': colors?.content?.border?.focus,
    }),
    [colors]
  );

  const handleClick = () => setIsPopoverOpen(!isPopoverOpen);

  const showPopover = () => setIsPopoverOpen(true);

  const hidePopover = () => setIsPopoverOpen(false);

  return (
    <Pop
      className={bem('popover')}
      onOuterAction={hidePopover}
      body={<Text className={bem('info')} text={text} />}
      isOpen={isPopoverOpen}
      style={styles}
    >
      <div
        onClick={handleClick}
        onMouseEnter={showPopover}
        onMouseLeave={hidePopover}
        onFocus={showPopover}
        onBlur={hidePopover}
        tabIndex={0}
        className={cx(bem(), className)}
        style={styles}
      >
        <>
          {renderTooltipContent ? (
            renderTooltipContent()
          ) : (
            <div className={bem('content')}>
              <Icon name="info" />
            </div>
          )}
        </>
      </div>
    </Pop>
  );
};

export default Tooltip;
