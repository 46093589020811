import React, { useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import { ConfirmCancellation } from '../ConfirmCancellation/ConfirmCancellation';
import { ReturnSummaryProps } from '../types';
import './ReturnSummary.scss';
import { Voucher } from './Voucher/Voucher';

const bem = cn('cancellation-return-summary');

export const ReturnSummary = (props: ReturnSummaryProps) => {
  const { closeModal } = props;
  const { cfStore, accountStore } = useStores();
  const { costSummary } = accountStore.tripDetails;
  const { navigation } = cfStore.account.tripDetails;
  const { returnSummaryLabel, brightlineCreditIcon, travelPassRidesReturnedLabel } = navigation.cancelTrip;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);

  const refundMethods = useMemo(() => {
    const refundMethods: any[] = [];

    costSummary?.refundMethods?.forEach(refundMethod => {
      refundMethod.numOccurrences = 1;

      const existingRefundMethodIdx = refundMethods.findIndex(
        el => el.type === refundMethod.type && el.title === refundMethod.title
      );
      if (existingRefundMethodIdx === -1) refundMethods.push(refundMethod);
      else refundMethods[existingRefundMethodIdx].numOccurrences++;
    });

    return refundMethods;
  }, [costSummary?.refundMethods]);

  return (
    <div className={bem()}>
      <div>
        <div className={bem('title')}>{returnSummaryLabel}</div>
        {refundMethods.map((item, idx) => (
          <Voucher
            key={`${idx}-${item?.type}`}
            type={item?.type}
            title={item?.title}
            description={item?.description}
            note={item?.note}
            icon={brightlineCreditIcon}
            kicker={item.kicker}
            numOccurrences={item.numOccurrences}
            travelPassRidesReturnedLabel={travelPassRidesReturnedLabel}
            isSelected
          />
        ))}
      </div>
      <div>{!isMobile && <ConfirmCancellation closeModal={closeModal} className="buttons" />}</div>
    </div>
  );
};
