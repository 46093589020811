import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import LinkButton from '@atoms/LinkButton/LinkButton';
import { Text } from '@atoms/Text';

import { cn } from 'utils/classNames';

import './EventsBrowserFiltersDesktop.scss';
import { TrainIcon } from './icons/TrainIcon';

const bem = cn('events-browser-filters-desktop');

export const CityFilters = observer(({ logic, setCityPopoverOpen, stations }: any) => {
  const { cfStore } = useStores();
  const { clearCitiesLabel, closeLabel } = cfStore.events.cityFilter;

  const { selectedStations, handleStationClicked } = logic;

  return (
    <div className={bem('city-popover-wrapper')}>
      <div className={bem('stations-with-train-wrapper')}>
        <div className={bem('stations-wrapper')}>
          {stations.map(station => (
            <div key={station.id} className={bem('station')} onClick={() => handleStationClicked(station.id)}>
              <div className={bem('station-icon', { selected: selectedStations.includes(station.id) })}>
                <img className={bem('icons')} src={station.icon.src} alt={station.icon.alt} />
              </div>
              <Text
                text={station.label}
                className={bem('filter-label', { selected: selectedStations.includes(station.id) })}
              />
            </div>
          ))}
        </div>
        <div className={bem('line')} />
        <div className={bem('train-icon')}>
          <TrainIcon />
        </div>
      </div>
      <div className={bem('city-popover-buttons')}>
        <LinkButton label={clearCitiesLabel} variant="big" onClick={() => handleStationClicked('All')} />
        <Button label={closeLabel} color="blue" onClick={() => setCityPopoverOpen(false)} />
      </div>
    </div>
  );
});
