import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';

import { PassengerConfig } from './types';

export class Passengers {
  passengers: PassengerConfig[] = [];
  isLoading = true;
  hasError = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  resetData() {
    this.passengers = [];
    this.isLoading = true;
    this.hasError = false;
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setError(hasError) {
    this.hasError = hasError;
  }

  setSavedPassengers(savedPassengers) {
    this.passengers = savedPassengers;
  }

  clearSavedPassengers() {
    this.passengers = [];
  }

  async fetchSavedPassengers() {
    try {
      this.setLoading(true);
      const url = `${requestUrls.getRestUrl(requestUrls.account.passengers)}.xjson`;
      const response = await api.get(url);
      this.setSavedPassengers(response.data.passengers);
      this.setLoading(false);
    } catch (e: any) {
      console.error(e);
      this.setError(true);
      this.setLoading(false);
    }
  }

  async savePassenger(payload, cb) {
    const { wheelchairAccommodation, dateOfBirth, email, phoneNumber } = payload;
    try {
      this.setLoading(true);
      const parsedPayload = {
        ...payload,
        wheelchairAccommodation: wheelchairAccommodation ? 'accessible_space' : undefined,
        dateOfBirth: dateOfBirth ? moment.utc(dateOfBirth).format() : undefined,
        email: email || undefined,
        phoneNumber: phoneNumber.number ? phoneNumber : undefined,
      };
      let url;

      if (payload.id) {
        url = `${requestUrls.getRestUrl(requestUrls.account.passengers)}.${payload.id}.xjson`;
        await api.put(url, parsedPayload);
      } else {
        url = `${requestUrls.getRestUrl(requestUrls.account.passengers)}.xjson`;
        await api.post(url, parsedPayload);
      }

      await this.fetchSavedPassengers();
      this.setLoading(false);
      if (cb) {
        cb();

        return;
      }
    } catch (e: any) {
      console.error(e);
      this.setError(true);
      this.setLoading(false);
    }
  }

  async deletePassenger(payload, cb) {
    try {
      this.setLoading(true);
      const url = `${requestUrls.getRestUrl(requestUrls.account.passengers)}.${payload.id}.xjson`;
      await api.delete(url);
      await this.fetchSavedPassengers();
      this.setLoading(false);
      if (cb) {
        cb();

        return;
      }
    } catch (e: any) {
      console.error(e);
      this.setError(true);
      this.setLoading(false);
    }
  }
}
