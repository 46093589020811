import { I18nValues } from './types';

export const DEFAULT_INITIAL_VALUES = {
  referenceNumber: '',
  lastName: '',
  firstName: '',
};

export const DEPRECATED_INITIAL_VALUES = {
  referenceNumber: '',
  lastName: '',
};

export const DEFAULT_I18N: I18nValues = {
  title: 'Manage your upcoming trip',
  description: 'Add extras, see departure times, passenger info, and selected seats.',
  ticketNumberLabel: 'Ticket Number',
  ticketNumberPlaceholderLabel: 'Add ticket number',
  errorInvalidField: 'Invalid input',
  lastNameLabel: 'Last Name',
  firstNameLabel: 'First Name',
  lastNamePlaceholderLabel: 'Add last name',
  firstNamePlaceholderLabel: 'Add first name',
  errorRequiredField: 'Required',
  submitFormLabel: 'Find Trip',
  createAccountLabel: '<a href="/login">Create an account</a> to manage your trips more easily',
  goToAccountLabel: '<a href="/login">Go To</a> your account',
  requiredFieldLabel: '* indicates a required field',
};
