import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';
import { Icon } from '@atoms/Icon';
import LinkButton from '@atoms/LinkButton/LinkButton';

import { cn } from 'utils/classNames';

import { EmptyTripsProps } from '../../types';
import './EmptyTrips.scss';

const bem = cn('empty-trips');

export const EmptyTrips = observer((props: EmptyTripsProps) => {
  const { variant } = props;
  const { cfStore } = useStores();

  const { account } = cfStore;
  const { trips: i18n } = account;

  const title = useMemo(() => {
    if (variant === 'upcoming') return i18n.noUpcomingTitleLabel;
    if (variant === 'past') return i18n.noPastTitleLabel;

    return i18n.noUpcomingOrPastTitleLabel;
  }, [variant, i18n]);

  const config = useMemo(
    () =>
      variant === 'past'
        ? {
            heading: i18n.pastTripsLabel,
            link: {
              url: account.trips.noPastTripsButtonLink,
              target: account.trips.openNoPastTripsButtonLinkInNewTab ? '_blank' : '_self',
            },
            description: i18n.noPastDescriptionLabel,
            buttonLabel: i18n.noPastTripsButtonLabel,
          }
        : {
            heading: i18n.upcomingTripsLabel,
            link: {
              url: account.trips.noUpcomingTripsButtonLink,
              target: account.trips.openNoUpcomingTripsButtonLinkInNewTab ? '_blank' : '_self',
            },
            description: i18n.noUpcomingDescriptionLabel,
            buttonLabel: i18n.noUpcomingTripsButtonLabel,
          },
    [i18n, account, variant]
  );

  return (
    <>
      <h1 className={bem('heading', { variant: variant })}>{config.heading}</h1>
      <div className={bem()}>
        <Icon name="empty-trip" size="large" className={bem('empty-trip')} />
        <div className={bem('title')}>{title}</div>
        <div className={bem('description')}>{config.description}</div>
        <Button label={config.buttonLabel} link={config.link} variant="primary" className={bem('action')} />
        {!variant && !!i18n.addExistingTripCtaLabel && (
          <LinkButton
            label={i18n.addExistingTripCtaLabel}
            link={{
              url: account.trips.addExistingTripCtaLink,
              target: account.trips.openAddExistingTripCtaLinkInNewTab ? '_blank' : '_self',
            }}
            className={bem('link')}
          />
        )}
      </div>
    </>
  );
});
