import { FormikContextType, useFormikContext } from 'formik';
import { useMemo } from 'react';

import { TicketsFormValues } from '@molecules/TicketsForm/types';

import { passengerUrlTypes } from './constants';
import { PassengerSelectionBodyProps } from './types';

export const usePassengerSelectionBodyLogic = (props: PassengerSelectionBodyProps) => {
  const { i18n, maxPassengers = 16 } = props;
  const { values, setFieldValue }: FormikContextType<TicketsFormValues> = useFormikContext();

  const totalPassengers = useMemo(() => values.adults + values.kids, [values]);

  const infoMessage = useMemo(() => {
    if (totalPassengers === maxPassengers) return i18n.passengersLimitReached.replace('%max%', `${maxPassengers}`);
    if (values.adults === values.infants) return i18n.infantsLimitReached;

    return '';
  }, [values, totalPassengers, maxPassengers, i18n]);

  const increasePassengers = type => () => {
    const max = type === passengerUrlTypes.infants ? values.adults : maxPassengers;
    const newValue = values[type] + 1 > max ? max : values[type] + 1;
    setFieldValue(type, newValue);
  };

  const decreasePassengers = type => () => {
    const min = type === passengerUrlTypes.adults ? 1 : 0;
    if (type === passengerUrlTypes.adults && values.infants === values.adults)
      setFieldValue(passengerUrlTypes.infants, values.adults - 1);
    const newValue = values[type] - 1 < min ? min : values[type] - 1;

    setFieldValue(type, newValue);
    if (type !== passengerUrlTypes.infants) {
      const wheelchairType =
        type === passengerUrlTypes.adults ? passengerUrlTypes.wcAdults : passengerUrlTypes.wcChildren;
      if (newValue < values[wheelchairType]) setFieldValue(wheelchairType, newValue);
    }
  };

  const checkDisabledFirstButton = type => {
    if (type === passengerUrlTypes.adults && values.adults === 1) return true;

    return values[type] === 0;
  };

  const checkDisabledSecondButton = type => {
    if (type === passengerUrlTypes.infants) return values.infants === values.adults;

    return totalPassengers === maxPassengers;
  };

  const increaseWeelchairPassengers = type => () => {
    const formFieldName = type === passengerUrlTypes.adults ? passengerUrlTypes.wcAdults : passengerUrlTypes.wcChildren;
    const max = type === passengerUrlTypes.adults ? values.adults : values.kids;
    setFieldValue(formFieldName, values[formFieldName] + 1 > max ? max : values[formFieldName] + 1);
  };

  const decreaseWheelchairPassengers = type => () => {
    const formFieldName = type === passengerUrlTypes.adults ? passengerUrlTypes.wcAdults : passengerUrlTypes.wcChildren;
    const min = 0;
    setFieldValue(formFieldName, values[formFieldName] - 1 < min ? min : values[formFieldName] - 1);
  };

  const checkDisabledWcFirstButton = type => {
    const formFieldName = type === passengerUrlTypes.adults ? passengerUrlTypes.wcAdults : passengerUrlTypes.wcChildren;

    return values[formFieldName] === 0;
  };

  const checkDisabledWcSecondButton = type => {
    if (type === passengerUrlTypes.adults) return values.wcAdults >= values.adults;

    return values.wcChildren >= values.kids;
  };

  return {
    checkDisabledFirstButton,
    checkDisabledSecondButton,
    checkDisabledWcFirstButton,
    checkDisabledWcSecondButton,
    decreasePassengers,
    increasePassengers,
    decreaseWheelchairPassengers,
    increaseWeelchairPassengers,
    infoMessage,
    values,
  };
};
