import { Formik } from 'formik';
import React, { useRef, useState } from 'react';

import Button from '@atoms/Button/Button';
import Image from '@atoms/Image/Image';
import { InputField } from '@atoms/Input';
import { Text } from '@atoms/Text';
import Title from '@atoms/Title/Title';

import { cn } from 'utils/classNames';

import './NewsletterForm.scss';
import { NewsletterFormProps } from './types';
import { useHelperText } from './useHelperText';
import { useServerLogic } from './useServerLogic';
import { useValidationSchema } from './useValidationSchema';

const bem = cn('newsletter-form');

const NewsletterForm = (props: NewsletterFormProps) => {
  const { id, title, image, i18n, disclaimer, hidePostalCodeField, postalCodeFieldRequired } = props;
  const { onSubmit, serverStatus } = useServerLogic();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const helperText = useHelperText({ i18n, serverStatus });

  const validationSchema = useValidationSchema(i18n);
  const formRef = useRef<HTMLFormElement>(null);

  const handleEnterKey = (event: React.KeyboardEvent<HTMLFormElement> | KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      formRef?.current && formRef.current.requestSubmit();
    }
  };

  const isPostalCodeRequiredField = !hidePostalCodeField && postalCodeFieldRequired;

  const handleSubmit = data => {
    onSubmit(data);
    setFormSubmitted(true);
  };

  const handleChangeInput = (e, handleChange) => {
    handleChange(e);
    setFormSubmitted(false);
  };

  return (
    <div id={id} className={bem({ hasImage: !!image, status: serverStatus })}>
      <div className={bem('content')}>
        <div className={bem('title')}>
          <Title {...title} />
        </div>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ handleSubmit, handleChange }) => (
            <form ref={formRef} className={bem('form')} onSubmit={handleSubmit}>
              <Text as="p" text={i18n.requiredFieldLabel} className={bem('required-field-label')} />
              <div className={bem('fields')}>
                <InputField
                  autocomplete="email"
                  type="text"
                  name="email"
                  className={bem('emailField')}
                  label={i18n.defaultInputLabel}
                  helperText={formSubmitted && hidePostalCodeField ? helperText : ''}
                  error={serverStatus === 'error'}
                  onKeyDown={handleEnterKey}
                  onChange={e => handleChangeInput(e, handleChange)}
                  required
                  displayTrailingIcon
                />
                {!hidePostalCodeField && (
                  <InputField
                    autocomplete="postal-code"
                    type="text"
                    name="postalCode"
                    label={i18n.postalCodeInputLabel}
                    helperText={formSubmitted ? helperText : ''}
                    error={serverStatus === 'error'}
                    required={isPostalCodeRequiredField}
                    onChange={e => handleChangeInput(e, handleChange)}
                    maxCharacters={10}
                    displayTrailingIcon
                  />
                )}
                <Button type="submit" color="yellow" label={i18n.buttonLabel} loading={serverStatus === 'loading'} />
              </div>
              <div>
                <Text className={bem('disclaimerText')} text={disclaimer?.text} />
              </div>
            </form>
          )}
        </Formik>
        {image && (
          <div className={bem('image')}>
            <Image {...image} />
          </div>
        )}
      </div>
    </div>
  );
};
export default NewsletterForm;
