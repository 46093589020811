import { makeAutoObservable } from 'mobx';
import AccountStore from 'stores/AccountStore/AccountStore';

import { editTripdetailsRtiRedirect } from '@account/routes/TripDetails/utils';
import { preloadImage } from '@booking/routes/RTI/SeatSelection/components/SeatSelectionModal/SeatSelectionMap/utils';

import { cartEvent, constructAnalyticsItems } from 'utils/adobeDataLayer';
import { api } from 'utils/api';
import { requestUrls } from 'utils/constants';

import { Coach, CoachSelectOption, PassengerSeats } from './types.seats';

const outboundDirection = 'outbound';

export default class SeatsStore {
  private readonly rootStore;
  coaches: Coach[] = [];
  coachOptions: CoachSelectOption[] = [];
  loading = false;
  error = false;
  updateError = false;
  activeCoach = { activeOutboundCoach: null, activeInboundCoach: null };
  activeCoachIndex = -1;
  screenWidth = 358;
  passengersSeats: PassengerSeats[] = [];
  activePassenger: PassengerSeats | null = null;
  travelDirection = outboundDirection;
  selectedCoach: CoachSelectOption | null = null;
  displayedCoach: Coach | null = null;

  isOutboundSeatingAvailable = false;
  isInboundSeatingAvailable = false;
  isOutboundSeatingVisible = false;
  isInboundSeatingVisible = false;

  constructor(rootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.rootStore = rootStore;
  }

  setCoaches(coaches) {
    this.coaches = coaches;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setError(error) {
    this.error = error;
  }

  setUpdateError(updateError) {
    this.updateError = updateError;
  }

  setActiveCoach(activeCoach) {
    this.activeCoach = activeCoach;
  }

  setPassengersSeats(passengersSeats) {
    this.passengersSeats = passengersSeats;
  }

  setActiveCoachIndex(index) {
    this.activeCoachIndex = index;
  }

  setActivePassenger(activePassenger) {
    this.activePassenger = activePassenger;
  }

  setTravelDirection(travelDirection) {
    this.travelDirection = travelDirection;
  }

  setScreenWidth(screenWidth) {
    this.screenWidth = screenWidth;
  }

  setCoachOptions(options) {
    this.coachOptions = options;
  }

  setSelectedCoach(selectedCoach) {
    this.selectedCoach = selectedCoach;
  }

  setDisplayedCoach(displayedCoach) {
    this.displayedCoach = displayedCoach;
  }

  findActivePassengerIndex() {
    return this.passengersSeats.findIndex(passenger => this.activePassenger?.passengerId === passenger.passengerId);
  }

  setOutboundSeatingAvailability(seatingAvailable) {
    this.isOutboundSeatingAvailable = seatingAvailable;
  }

  setInboundSeatingAvailability(seatingAvailable) {
    this.isInboundSeatingAvailable = seatingAvailable;
  }

  setOutboundSeatingVisibility(seatingVisible) {
    this.isOutboundSeatingVisible = seatingVisible;
  }

  setInboundSeatingVisibility(seatingVisible) {
    this.isInboundSeatingVisible = seatingVisible;
  }

  resetData() {
    this.setCoaches([]);
    this.setLoading(false);
    this.setError(null);
    this.setActiveCoach({ activeOutboundCoach: null, activeInboundCoach: null });
    this.setActiveCoachIndex(-1);
    this.setPassengersSeats([]);
    this.setTravelDirection(outboundDirection);
    this.setActivePassenger(null);
    this.setSelectedCoach(null);
    this.setDisplayedCoach(null);
    this.setUpdateError(null);
    this.setCoachOptions([]);
  }

  selectSeat(seat, authorizedPassengers) {
    const autorizedPassenger = authorizedPassengers.find(
      pass => pass.passengerId === this.activePassenger?.passengerId
    );
    let updatedSeat: any = {
      oldOutboundSeat: this.activePassenger?.oldOutboundSeat || this.activePassenger?.outboundSeat,
      outboundSeat: { ...seat, selectionFee: autorizedPassenger?.selectionFee, updated: true },
    };

    if (this.travelDirection !== outboundDirection)
      updatedSeat = {
        oldInboundSeat: this.activePassenger?.oldInboundSeat || this.activePassenger?.inboundSeat,
        inboundSeat: { ...seat, selectionFee: autorizedPassenger?.selectionFee, updated: true },
      };

    const updatedPassengerSeat = {
      ...this.activePassenger,
      ...updatedSeat,
    };

    const updatedPassengersSeats = this.passengersSeats.map(el => {
      if (this.activePassenger?.passengerId === el.passengerId) return updatedPassengerSeat;

      return el;
    });

    const currentPassengerIndex = updatedPassengersSeats.findIndex(
      el => this.activePassenger?.passengerId === el.passengerId
    );

    if (currentPassengerIndex + 1 === updatedPassengersSeats.length)
      this.setActivePassenger(updatedPassengersSeats[currentPassengerIndex]);
    else this.setActivePassenger(updatedPassengersSeats[currentPassengerIndex + 1]);

    this.setPassengersSeats(updatedPassengersSeats);
  }

  handleSelectCoach(coach) {
    this.setSelectedCoach(coach);
    const displayedCoach = this.coaches.find((el: Coach) => el.coach?.coachNumber === coach.value);
    this.setDisplayedCoach(displayedCoach);
  }

  removeSeatSelection(passengerId) {
    const isOutbound = this.travelDirection === outboundDirection;

    const updatedPassengers = this.passengersSeats.map(el => {
      if (el.passengerId === passengerId) {
        const updatedSeat = isOutbound
          ? {
              outboundSeat: el.oldOutboundSeat,
              oldOutboundSeat: null,
            }
          : {
              inboundSeat: el.oldInboundSeat,
              oldInboundSeat: null,
            };

        return {
          ...el,
          ...updatedSeat,
        };
      }

      return el;
    });

    const newActivePassenger = this.passengersSeats.find(el => el.passengerId === passengerId);

    const updatedActivePassenger = isOutbound
      ? {
          ...newActivePassenger,
          outboundSeat: newActivePassenger?.oldOutboundSeat,
          oldOutboundSeat: null,
        }
      : {
          ...newActivePassenger,
          inboundSeat: newActivePassenger?.oldInboundSeat,
          oldInboundSeat: null,
        };

    this.setPassengersSeats(updatedPassengers);
    this.setActivePassenger(updatedActivePassenger);
  }

  async mapSeatSelectionData(data) {
    const activeCoachNumber =
      this.travelDirection === outboundDirection
        ? this.activeCoach.activeOutboundCoach
        : this.activeCoach.activeInboundCoach;
    const activeCoachIndex = data.coaches.findIndex(el => el.coach.coachNumber === activeCoachNumber);
    this.setActiveCoachIndex(activeCoachIndex);

    const coachSelectOptions = data.coaches.map(coachItem => ({
      label: coachItem.coach.classType,
      value: coachItem.coach.coachNumber,
      disabled: !coachItem.seats.some(el => el.authorizedPassengers.length > 0),
    }));

    await preloadImage(data.coaches[activeCoachIndex].floorplanImageUrl);

    this.setSelectedCoach(coachSelectOptions[activeCoachIndex]);
    this.setDisplayedCoach(data.coaches[activeCoachIndex]);

    this.setCoachOptions(coachSelectOptions);
    this.setCoaches(data.coaches);
  }

  async getSeats(travelDirection, isPrefetch?: boolean, isAccountFlow?: boolean) {
    this.setLoading(true);

    try {
      const headers = {
        'Booking-Session-Access-Token': this.rootStore.bookingStore.rti.provisionalBooking?.bookingSession?.accessToken,
        'Booking-Session-Refresh-Token':
          this.rootStore.bookingStore.rti.provisionalBooking?.bookingSession?.refreshToken,
      };

      const params = {
        travelDirection,
        screenWidth: this.screenWidth,
      };
      const response = await api.get(
        `${requestUrls.getRestUrl(requestUrls.rti.seats)}.${
          this.rootStore.bookingStore.rti.provisionalBooking.tripDetails.trip.referenceNumber
        }.xjson`,
        { params, headers }
      );

      !isPrefetch && (await this.mapSeatSelectionData(response.data));

      const trip = this.rootStore.bookingStore.rti.provisionalBooking.tripDetails.trip;

      this.checkSeatingAvailability(travelDirection, response.data.canChangeSeat, !!trip?.inboundRoute);
      this.checkSeatingVisibility(travelDirection, response.data.canViewSeat, !!trip?.inboundRoute, isAccountFlow);

      this.setLoading(false);
    } catch (error) {
      this.setError(error);
      this.setLoading(false);
      console.error(error);
    }
  }

  switchDirection(isAccountFlow?: boolean) {
    const travelDirection = this.travelDirection === 'inbound' ? outboundDirection : 'inbound';
    this.setActivePassenger(this.passengersSeats[0]);
    this.setTravelDirection(travelDirection);
    if (
      this.rootStore.bookingStore.rti.provisionalBooking?.tripDetails?.trip?.outboundRoute?.isModifiable ||
      (travelDirection !== 'outbound' &&
        this.rootStore.bookingStore.rti.provisionalBooking?.tripDetails?.trip?.inboundRoute)
    )
      this.getSeats(travelDirection, false, isAccountFlow);
  }

  mapUpdateSeatsPayload() {
    const updatedOutboundSeats = this.passengersSeats.filter(seat => seat.outboundSeat?.updated) || [];
    const updatedInboundSeats = this.passengersSeats.filter(seat => seat.inboundSeat?.updated) || [];
    const outboundPayload =
      updatedOutboundSeats.map(seat => ({
        itemReference: seat.outboundSeatProduct.itemReference,
        seatNumber: seat.outboundSeat.number,
        coachNumber: seat.outboundSeat.coach.coachNumber,
        seatSelectionTariffCode: seat.outboundSeat?.selectionFee?.tariffCode,
        seatSelectionProductCode: seat.outboundSeat?.selectionFee?.productCode,
      })) || [];

    const inboundPayload =
      updatedInboundSeats.map(seat => ({
        itemReference: seat.inboundSeatProduct.itemReference,
        seatNumber: seat.inboundSeat.number,
        coachNumber: seat.inboundSeat?.coach?.coachNumber,
        seatSelectionTariffCode: seat.inboundSeat?.selectionFee?.tariffCode,
        seatSelectionProductCode: seat.inboundSeat?.selectionFee?.productCode,
      })) || [];

    return [...outboundPayload, ...inboundPayload];
  }

  getSeatProducts(seatAssignments, tripDetails) {
    return seatAssignments.map(seatAssignment => {
      const product = tripDetails.costSummary.sections
        .map(section => section.items)
        .flat()
        .find(summaryItem => summaryItem.productCode === seatAssignment.seatSelectionProductCode);

      return {
        isPromoCode: product?.isPromoCode,
        productCode: seatAssignment.seatSelectionProductCode,
        productName: product?.productName,
        quantity: product?.quantity || 0,
        totalPrice: product?.totalPrice || 0,
      };
    });
  }

  async updateBookingSeats(cb, isAccountFlow = false, accountStore?: AccountStore) {
    try {
      this.setUpdateError(false);
      this.setLoading(true);
      const seatAssignments = this.mapUpdateSeatsPayload();

      if (!seatAssignments.length) {
        cb();

        return;
      }

      const headers = {
        'Booking-Session-Access-Token': this.rootStore.bookingStore.rti.provisionalBooking?.bookingSession?.accessToken,
        'Booking-Session-Refresh-Token':
          this.rootStore.bookingStore.rti.provisionalBooking?.bookingSession?.refreshToken,
      };

      const payload = {
        seatAssignments,
      };

      const response = await api.put(
        `${requestUrls.getRestUrl(requestUrls.rti.seats)}.${
          this.rootStore.bookingStore.rti.provisionalBooking.tripDetails.trip.referenceNumber
        }.xjson`,
        payload,
        { headers }
      );

      // construct the analytics differences between the initial and updated seat products
      const initialSeatProducts = this.getSeatProducts(
        seatAssignments,
        this.rootStore.bookingStore.rti.provisionalBooking.tripDetails
      );
      const updatedSeatProducts = this.getSeatProducts(seatAssignments, response.data.tripDetails);

      const analyticsItems = constructAnalyticsItems(initialSeatProducts, updatedSeatProducts);

      if (analyticsItems.length) cartEvent('updateCart', analyticsItems, response, 'seatSelection');

      this.rootStore.bookingStore.rti.setProvisionalBooking(response.data);

      if (isAccountFlow && accountStore) editTripdetailsRtiRedirect(accountStore, 'seats');
      else if (cb) {
        cb();

        return;
      }
      this.setLoading(false);
    } catch (error) {
      this.setUpdateError(error);
      this.setLoading(false);
    }
  }

  private checkSeatingAvailability(travelDirection, canChangeSeating, isRoundTrip) {
    if (travelDirection === 'outbound') this.setOutboundSeatingAvailability(!!canChangeSeating);
    else this.setInboundSeatingAvailability(isRoundTrip && !!canChangeSeating);
  }

  private checkSeatingVisibility(travelDirection, canViewSeating, isRoundTrip, isAccountFlow?: boolean) {
    if (isAccountFlow) {
      this.setOutboundSeatingVisibility(true);
      isRoundTrip && this.setInboundSeatingVisibility(true);
    } else if (travelDirection === 'outbound') this.setOutboundSeatingVisibility(!!canViewSeating);
    else this.setInboundSeatingVisibility(isRoundTrip && !!canViewSeating);
  }
}
