import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { RectShape, RoundShape } from 'react-placeholder/lib/placeholders';
import { useStores } from 'stores';

import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './PassesReview.scss';

const bem = cn('passes-review');

export const PassesReviewPlaceholderMobile = observer(() => {
  const { passesStore } = useStores();
  const { urlParams } = passesStore.checkout;

  const isTravelPass: boolean = useMemo(() => !!urlParams.travelPass, [urlParams.travelPass]);

  const isParkingPass: boolean = useMemo(() => !!urlParams.parkingPass, [urlParams.parkingPass]);

  return (
    <ReactPlaceholder
      ready={false}
      customPlaceholder={
        <div className={bem()}>
          <div className={bem('title')}>
            <div className={bem('title-icon')}>
              <RoundShape color={color_base_medium_light_grey} style={{ width: 24, height: 24 }} />
            </div>
            <div className={bem('title-placeholder')}>
              <RectShape color={color_base_medium_light_grey} style={{ width: 100, height: 26, borderRadius: 8 }} />
              <RectShape color={color_base_medium_light_grey} style={{ width: 40, height: 26, borderRadius: 8 }} />
            </div>
          </div>

          {isTravelPass && (
            <div className={bem('summary')}>
              <div className={bem('summary-travel-pass')}>
                {isParkingPass && (
                  <div className={bem('summary-subtitle')}>
                    <RectShape
                      color={color_base_medium_light_grey}
                      style={{ width: 100, height: 24, borderRadius: 8 }}
                    />
                    <RectShape
                      color={color_base_medium_light_grey}
                      style={{ width: 60, height: 24, borderRadius: 8 }}
                    />
                  </div>
                )}
                <div className={bem('summary-details')}>
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: 150, height: 22, borderRadius: 8, marginTop: 1, marginBottom: 1 }}
                  />
                  <RectShape
                    color={color_base_medium_light_grey}
                    style={{ width: 150, height: 22, borderRadius: 8, marginTop: 1, marginBottom: 1 }}
                  />
                </div>
              </div>
              {isParkingPass && (
                <div className={bem('summary-travel-pass')}>
                  <div className={bem('summary-subtitle')}>
                    <RectShape
                      color={color_base_medium_light_grey}
                      style={{ width: 100, height: 24, borderRadius: 8 }}
                    />
                    <RectShape
                      color={color_base_medium_light_grey}
                      style={{ width: 60, height: 24, borderRadius: 8 }}
                    />
                  </div>
                  <div className={bem('summary-details')}>
                    <RectShape
                      color={color_base_medium_light_grey}
                      style={{ width: 150, height: 22, borderRadius: 8, marginTop: 1, marginBottom: 1 }}
                    />
                    <RectShape
                      color={color_base_medium_light_grey}
                      style={{ width: 150, height: 22, borderRadius: 8, marginTop: 1, marginBottom: 1 }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {!isTravelPass && isParkingPass && (
            <div className={bem('summary', { isParkingPass: true })}>
              <div className={bem('summary-parking-labels')}>
                <RectShape color={color_base_medium_light_grey} style={{ width: 80, height: 24, borderRadius: 8 }} />
                <RectShape color={color_base_medium_light_grey} style={{ width: 80, height: 24, borderRadius: 8 }} />
                <RectShape color={color_base_medium_light_grey} style={{ width: 80, height: 24, borderRadius: 8 }} />
                <RectShape color={color_base_medium_light_grey} style={{ width: 80, height: 24, borderRadius: 8 }} />
              </div>
              <div className={bem('summary-parking-labels')}>
                <RectShape color={color_base_medium_light_grey} style={{ width: 80, height: 24, borderRadius: 8 }} />
                <RectShape color={color_base_medium_light_grey} style={{ width: 80, height: 24, borderRadius: 8 }} />
                <RectShape color={color_base_medium_light_grey} style={{ width: 80, height: 24, borderRadius: 8 }} />
                <RectShape color={color_base_medium_light_grey} style={{ width: 80, height: 24, borderRadius: 8 }} />
              </div>
            </div>
          )}
        </div>
      }
      showLoadingAnimation
    >
      <div />
    </ReactPlaceholder>
  );
});
