import React, { useEffect, useId, useMemo } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import Button from '@atoms/Button/Button';
import { Drawer } from '@molecules/Drawer';
import { Modal } from '@molecules/Modal';

import { cn, cx } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';

import './OverlayContainer.scss';
import { OverlayContainerProps } from './types';

const bem = cn('overlay-container');

export const OverlayContainer = (props: OverlayContainerProps) => {
  const { isVisible, redrawCallback, title, children, onCancel, className } = props;
  const { onConfirm, cancelButtonLabel, submitButtonLabel, submitButtonDisabled = false, isLoading } = props;

  const isMobile = useMediaPredicate(mediaQueryList.maxTablet);
  const id = useId();

  useEffect(() => {
    if (redrawCallback) redrawCallback();
  }, [isMobile, isVisible, redrawCallback]);

  const content = useMemo(
    () => (
      <div className={cx(bem(), className)}>
        {title && (
          <h1 className={bem('h2-style')} id={`${id}-label`} role="heading" aria-level={2}>
            {title}
          </h1>
        )}
        <div className={bem('description')}>{children}</div>
        {(!!cancelButtonLabel || !!submitButtonLabel) && (
          <div className={bem('buttons')}>
            {!!cancelButtonLabel && (
              <Button color="charcoal" variant="secondary" label={cancelButtonLabel} onClick={onCancel} />
            )}
            {!!submitButtonLabel && (
              <Button
                color="charcoal"
                label={submitButtonLabel}
                onClick={onConfirm}
                loading={isLoading}
                disabled={submitButtonDisabled}
              />
            )}
          </div>
        )}
      </div>
    ),
    [
      submitButtonDisabled,
      cancelButtonLabel,
      children,
      isLoading,
      onCancel,
      onConfirm,
      submitButtonLabel,
      title,
      className,
      id,
    ]
  );

  if (isMobile)
    return (
      <Drawer isVisible={isVisible} onClose={onCancel}>
        {content}
      </Drawer>
    );

  if (isVisible)
    return (
      <Modal variant="mini" onClose={onCancel} ariaLabelledby={`${id}-label`}>
        {content}
      </Modal>
    );

  return null;
};
