import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { useStores } from 'stores';

import { ContentHeader } from '@account/components/ContentHeader';
import { EmptyView } from '@account/components/EmptyView';
import { MyAccount } from '@account/components/MyAccount';
import { useCMSContent } from '@account/hooks/useCMSContent';
import { useRestoreScroll } from '@account/hooks/useRestoreScroll';
import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';
import { mediaQueryList } from 'utils/mediaQueries';
import { isLoyaltyEnabled } from 'utils/metas';

import PageLoadingPlaceholders from '../../components/PageLoadingPlaceholder/PageLoadingPlaceholders';
import AddPassenger from './AddPassenger';
import EditablePassenger from './EditablePassenger';
import './Passengers.scss';

const bem = cn('account-passengers');

const Passengers = observer(() => {
  useCMSContent('passengersCF');
  const { accountStore, cfStore } = useStores();

  const { passengersStore } = accountStore;
  const { account } = cfStore;
  const { passengersCF } = account;
  const { passengers, isLoading } = passengersStore;
  const isTablet = useMediaPredicate(mediaQueryList.maxTablet);
  const { setScrollPosition } = useRestoreScroll();

  const [modalAddOpened, setModalAddOpened] = useState(false);

  const modalOnCloseRef = useRef<HTMLButtonElement>(null);

  const loyaltyEnabled = isLoyaltyEnabled();

  const handleToggleAdd = () => {
    if (!modalAddOpened) setScrollPosition({ x: window.pageXOffset, y: window.pageYOffset });
    else modalOnCloseRef.current && modalOnCloseRef.current.focus();

    setModalAddOpened(!modalAddOpened);
  };

  if (isLoading) return <PageLoadingPlaceholders />;

  if (!passengers.length)
    return (
      <>
        {loyaltyEnabled && isTablet && (
          <div className={bem('user-account')}>
            <MyAccount />
          </div>
        )}

        <AddPassenger isOpen={modalAddOpened} setIsOpen={handleToggleAdd} onCloseRef={modalOnCloseRef} />
        <EmptyView
          icon={passengersCF.emptyStateIcon}
          pageTitle={passengersCF.pageTitle}
          title={passengersCF.emptyStateHeader}
          subtitle={passengersCF.emptyStateBodyLabel}
          actions={
            <Button
              externalBtnRef={modalOnCloseRef}
              color="yellow"
              width={isTablet ? 'full' : 'normal'}
              size="medium"
              label={passengersCF.createNewPassengerLabel}
              onClick={handleToggleAdd}
            />
          }
        />
      </>
    );

  return (
    <>
      {loyaltyEnabled && isTablet && (
        <div className={bem('user-account')}>
          <MyAccount />
        </div>
      )}

      <ContentHeader pageTitle={passengersCF.pageTitle} />
      <div className={bem('list')}>
        {passengers.map(
          (passenger, index) =>
            !!passenger.firstName &&
            !!passenger.lastName && (
              <div key={`passenger-${index}`} className={bem('list-row')}>
                <EditablePassenger size="large" passenger={passenger} />
              </div>
            )
        )}
      </div>
      <AddPassenger isOpen={modalAddOpened} setIsOpen={handleToggleAdd} onCloseRef={modalOnCloseRef} />
      <div className={bem('add-to-list')}>
        <Button
          externalBtnRef={modalOnCloseRef}
          width={isTablet ? 'full' : 'normal'}
          color="yellow"
          size="medium"
          label={passengersCF.createNewPassengerLabel}
          onClick={handleToggleAdd}
        />
      </div>
    </>
  );
});

export default Passengers;
