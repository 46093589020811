import { useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { CoachSelectProps, HookProps } from './types';

export const useSelectHandlers = (props: CoachSelectProps): HookProps => {
  const { optionsData, disabled, onChange } = props;
  const [displayList, setListVisibility] = useState(false);
  const [accessibilityFocus, setAccessibilityFocus] = useState(false);

  const [focus, setFocus] = useState({
    index: 0,
    preventScroll: false,
  });

  const headerRef = useRef<HTMLDivElement>(null);

  const handleHeaderClick = e => {
    e.preventDefault();
    headerRef?.current?.focus();
    if (!disabled) setListVisibility(!displayList);
  };

  const handleItemClick = (e, itemIndex: number) => {
    e.preventDefault();

    setFocus({ index: itemIndex, preventScroll: false });

    setListVisibility(false);
    headerRef?.current?.focus();
  };

  const handleClickOutside = e => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path?.find(el => el === headerRef.current)) setListVisibility(false);
    setAccessibilityFocus(false);
  };

  const handleChangeNativeSelect = ev => {
    if (onChange) onChange(ev.target.value);
  };

  const handleOnClick = () => {
    if (!isMobileOnly) setListVisibility(val => !val);
  };

  const handleKeyDown = e => {
    if (!isMobileOnly)
      switch (e.key) {
        case 'ArrowDown':
          if (!displayList) setListVisibility(true);

          if (optionsData[focus.index + 1]) setFocus({ index: focus.index + 1, preventScroll: false });
          break;
        case 'ArrowUp':
          if (optionsData[focus.index - 1]) setFocus({ index: focus.index - 1, preventScroll: false });
          break;
        case 'Enter':
          if (displayList) {
            const selectedItem = optionsData[focus.index];
            if (selectedItem && onChange) onChange(selectedItem.value);

            setListVisibility(false);
          } else {
            (document.getElementById('coach-select') as HTMLSelectElement)?.showPicker?.();
            setListVisibility(true);
            if (optionsData[focus.index + 1]) setFocus({ index: focus.index + 1, preventScroll: false });
          }

          e.preventDefault();
          break;
        case 'Escape':
          setListVisibility(false);
          if (!displayList) setAccessibilityFocus(false);
          break;
        case 'Tab':
          if (displayList) {
            setAccessibilityFocus(true);
            e.preventDefault();
          } else setAccessibilityFocus(false);

          break;
      }
  };

  return {
    focus,
    setFocus,
    optionsData,
    headerRef,
    handleHeaderClick,
    handleItemClick,
    handleClickOutside,
    handleChangeNativeSelect,
    handleKeyDown,
    handleOnClick,
    displayList,
    accessibilityFocus,
    setAccessibilityFocus,
  };
};
