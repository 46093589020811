import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores, rootStore } from 'stores';

import { Divider } from '@atoms/Divider';
import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { clickAnalyticsEvent } from 'utils/adobeDataLayer';
import { cn } from 'utils/classNames';
import { color_base_medium_light_grey } from 'utils/colors';

import './Rewards.scss';
import { RewardsPlaceholders } from './RewardsPlaceholders';

const bem = cn('rewards');

export const Rewards = observer(() => {
  const { cfStore, accountStore } = useStores();
  const {
    rewardsRedirectionLinkReference,
    rewardsBackgroundImage,
    rewardsTitle,
    availablePointsLabel,
    availableLabelColor,
    pendingPointsLabel,
    pendingLabelColor,
    pendingPointsColor,
    pointsLabel,
    chevronRightIcon,
  } = cfStore.account.dashboard;

  const { link, navigationTitle } = rewardsRedirectionLinkReference;

  const { summary, isLoading } = accountStore.rewardsSummary;
  const availablePoints = summary?.spendable || 0;
  const pendingPoints = summary?.pending || 0;

  const navigate = useNavigate();

  const styles: any = useMemo(
    () => ({
      '--rewards-background-image': `url(${rewardsBackgroundImage.src})`,
      '--available-points-label-color': availableLabelColor,
      '--pending-points-label-color': pendingLabelColor,
      '--pending-points-color': pendingPointsColor,
    }),
    [rewardsBackgroundImage, availableLabelColor, pendingLabelColor, pendingPointsColor]
  );

  const handleClick = () => {
    if (link) {
      clickAnalyticsEvent(rootStore, {
        linkClick: {
          pageNameClickTracking: document.title,
          navigationLinkName: navigationTitle,
          customLink: link,
          externalLink: '',
          linkType: 'linkClick',
        },
      });
      if (link.startsWith('/account')) navigate(link.split('/account')[1]);
      else window.location.href = link;
    }
  };

  if (isLoading) return <RewardsPlaceholders />;

  return (
    <div className={bem('container')} style={styles} onClick={handleClick}>
      <div className={bem('info')}>
        <Text className={bem('title')} text={rewardsTitle} />
        <div className={bem('points')}>
          {availablePoints >= 0 && (
            <div>
              <Text className={bem('available-pts')} text={availablePoints.toFixed(0)} />
              <Text className={bem('available-pts-label')} text={availablePointsLabel} />
            </div>
          )}
          {availablePoints >= 0 && pendingPoints > 0 && (
            <Divider alignment="vertical" color={color_base_medium_light_grey} />
          )}
          {pendingPoints > 0 && (
            <div>
              <Text className={bem('pending-pts')} text={`+${pendingPoints} ${pointsLabel}`} />
              <Text className={bem('pending-pts-label')} text={pendingPointsLabel} />
            </div>
          )}
        </div>
      </div>
      <Icon className={bem('icon')} externalIcon={chevronRightIcon.src} />
    </div>
  );
});
