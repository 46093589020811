import React, { useMemo, useState } from 'react';
import Pop from 'react-popover';

import { Icon } from '@atoms/Icon';
import { Text } from '@atoms/Text';

import { cn, cx } from 'utils/classNames';

import './TooltipV2.scss';
import { TooltipV2Props } from './types';

const bem = cn('tooltip');

const TooltipV2 = (props: TooltipV2Props) => {
  const { text, renderTooltipContent, colors, className, popoverPlacement = 'above' } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const styles: any = useMemo(
    () => ({
      '--tooltip-info-text-color': colors?.info?.text?.inactive,
      '--tooltip-info-background-color': colors?.info?.background?.inactive,
      '--tooltip-content-text-color': colors?.content?.text?.inactive,
      '--tooltip-content-text-hover-color': colors?.content?.text?.hover,
    }),
    [colors]
  );

  const handleClick = () => setIsPopoverOpen(!isPopoverOpen);

  const showPopover = () => setIsPopoverOpen(true);

  const hidePopover = () => setIsPopoverOpen(false);

  return (
    <Pop
      className={bem('popover')}
      onOuterAction={hidePopover}
      body={<Text className={bem('info-text')} text={text} />}
      isOpen={isPopoverOpen}
      style={styles}
      place={popoverPlacement}
    >
      <div
        onClick={handleClick}
        onMouseEnter={showPopover}
        onMouseLeave={hidePopover}
        onFocus={showPopover}
        onBlur={hidePopover}
        tabIndex={0}
        className={cx(bem(), className)}
        style={styles}
      >
        <>{renderTooltipContent ? renderTooltipContent() : <Icon name="circle-info" />}</>
      </div>
    </Pop>
  );
};

export default TooltipV2;
