import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { useStores } from 'stores';

import Button from '@atoms/Button/Button';

import { cn } from 'utils/classNames';
import { scrollToTop } from 'utils/isKiosk';

import './ErrorPage.scss';

const bem = cn('kiosk-error-page');

export const ErrorPage = observer(() => {
  const error: any = useRouteError();
  const navigate = useNavigate();
  const { kioskStore } = useStores();
  const { homepage } = kioskStore;
  const { errorTitle, errorCTA, errorMessage } = homepage;

  if (error) console.error(error);

  const goHome = () => {
    navigate('/');
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className={bem()}>
      <div className={bem('exclamation')}>!</div>
      <div className={bem('title')}>{errorTitle}</div>
      <div className={bem('description')}>{errorCTA}</div>
      <Button className={bem('button')} label={errorMessage} onClick={goHome} />
    </div>
  );
});
