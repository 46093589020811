import React from 'react';

import { cn } from 'utils/classNames';

import { MobileSelectProps } from '../types';
import './ExtrasMobileSelect.scss';

const bem = cn('nativeSelect');

export const ExtrasMobileSelect = (props: MobileSelectProps) => {
  const { name, multiple, disabled, required } = props;
  const { optionsData, handleChangeNativeSelect } = props;

  return (
    <select
      multiple={multiple}
      className={bem()}
      onChange={handleChangeNativeSelect}
      name={name}
      disabled={disabled}
      required={required}
    >
      {optionsData?.map((item, itemIndex) => (
        <option key={`${item.label}-${itemIndex}`} value={item.value}>
          <label>{item.label}</label>
        </option>
      ))}
    </select>
  );
};
