import { getOr } from 'lodash/fp';
import { useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import useDebouncedEffect from 'use-debounced-effect';

import { publicApi } from 'utils/api';
import { mediaQueryList } from 'utils/mediaQueries';
import { getCartAPIDomain, getCartToken } from 'utils/metas';

import { endpoint, LOCALSTORAGE_GUEST_ID, LOCALSTORAGE_USER } from './constants';

export const useCartLogic = preventFetchOnMobile => {
  const [count, setCount] = useState(0);
  const maxTablet = useMediaPredicate(mediaQueryList.maxTablet);

  const endpointWithDomain = getCartAPIDomain() + endpoint;

  useDebouncedEffect(
    () => {
      // prevent calling the endpoint 2 times
      if ((maxTablet && preventFetchOnMobile) || !(maxTablet || preventFetchOnMobile)) return;

      const guestId = localStorage.getItem(LOCALSTORAGE_GUEST_ID);
      const user = localStorage.getItem(LOCALSTORAGE_USER);

      const countProducts = (response: any) => {
        const products = getOr([], 'data.data.cart.products')(response);
        const reservation = getOr(null, 'data.data.cart.reservation')(response);
        setCount(products.length + (reservation ? 1 : 0));
      };

      const headers = {
        authorization: `Basic ${getCartToken()}`,
        channel: 'web',
      };

      if (user) {
        const userDetails = JSON.parse(user);
        const { token, id } = userDetails;

        headers['x-access-token'] = token;

        if (token && id) publicApi.get(`${endpointWithDomain}/${id}`, { headers }).then(countProducts);
      } else if (guestId) publicApi.get(`${endpointWithDomain}/${guestId}`, { headers }).then(countProducts);
    },
    {
      timeout: 500,
      ignoreInitialCall: false,
    },
    [maxTablet]
  );

  return count;
};
